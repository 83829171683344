import React, { Component, Fragment } from "react";
import NavBar from "../Components/navbar";
import Header from "../Components/Header";
import Footer from "../Components/footer";
import SamaLinks from "../Components/samalinks";

class Sama extends Component {
  render() {
    return (
      <Fragment>
        <NavBar />
        <Header
          name={localStorage.getItem("SamaName")}
          usertype="employee"
          history={this.props.history}
        />
        <SamaLinks history={this.props.history} />
        <Footer />
      </Fragment>
    );
  }
}

export default Sama;
