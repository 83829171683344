import React from 'react'
import {  InputLabel, Select as MuiSelect, MenuItem, } from '@material-ui/core';

export default function Select(props) {

    const { name, label, value, onChange, options } = props;

    return (
        <>
            <InputLabel style={{fontWeight:"bold",color:"#212529"}}>{label}</InputLabel>
            <MuiSelect
                name={name}
                value={value}
                style={{width:"100%",marginBottom:"0.5rem"}}
                onChange={onChange}>
                {
                    options.map(
                        (item,idx )=> (<MenuItem key={idx} value={item.id}>{item.title}</MenuItem>)
                    )
                }
            </MuiSelect>
            </>
    )
}
