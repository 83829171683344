import React, { Fragment } from "react";
import { CSVLink } from "react-csv";
import Form from "../Components/Form/index";
import Form2 from "../Components/FormNew/index2";
import axios from "axios";
import settings from "../helpers/Settings";
import Table from "react-bootstrap/Table";
import cloneDeep from "lodash/cloneDeep";
import Cookies from "js-cookie";
import { saveAs } from "file-saver";
import Spinner from "react-bootstrap/Spinner";
import {
  Table as MuiTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button as MuiButton,
  MenuItem,
  Grid,
  TextField,
  Box,
  Tooltip,
  IconButton,
} from "@material-ui/core";

import { Timeline, Events, TextEvent } from "@merc/react-timeline";
import ValidateClaimsFilters from "./ValidateClaimsFilters";
import { mappingClaimStatus } from "./SamaUtils/ValidateClaimHelper";
import FormModal from "../Components/FormModal";
import DialogActions from "../Components/DialogActions";
import SimpleSelect from "../Components/controls/SimpleSelect";
import { Icon } from "@iconify/react";
import NotificationAlert from "../Components/Alert";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    margin: "auto",
    marginTop: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  submitButton: {
    backgroundColor: "#3ab449",
    color: "white",
  },
  tableCell: {
    padding: "0.2rem",
  },
  tableCell1: {
    padding: "0.2rem",
    color: "white",
  },
}));

//import download from "download";
var xml = require("xml");
var parseString = require("xml2js").parseString;

let { KayanURL } = settings;
const moment = require("moment");

//let privileges = localStorage.getItem("privileges");
//let privilegesJson = JSON.parse(privileges);
class ValidateClaims extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsToEdit: {},
      ActivityItems: [],
      ActivityCodes: [],
      diagnosisItems: [],
      DiagnosisCodes: [],
      isContinue: false,
      limit: false,
      diagnosisNow: 0,
      selectedClaims: [],
      selectedCodePrice: [],
      bulkValidationRecords: [],
      isShowmore: false,
      isLoadingDiagnosis: false,
    };
    this.handleInputTextChange = this.handleInputTextChange.bind(this);
    this.markAsDHPOValidated = this.markAsDHPOValidated.bind(this);
    this.onValidateDHPO = this.onValidateDHPO.bind(this);
    this.validateOnSama = this.validateOnSama.bind(this);
    this.markAsReadyToBeSubmitted = this.markAsReadyToBeSubmitted.bind(this);
    this.clickHere = this.clickHere.bind(this);
    this.addNewObservation = this.addNewObservation.bind(this);
    this.handleStartEndDate = this.handleStartEndDate.bind(this);
    this.onScelectPayerName = this.onScelectPayerName.bind(this);
    this.onSelectCaseType = this.onSelectCaseType.bind(this);
    this.onRemoveCaseType = this.onRemoveCaseType.bind(this);
    this.handleInputTextChangeForAddNewActivity =
      this.handleInputTextChangeForAddNewActivity.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.onClickDownload = this.onClickDownload.bind(this);
    this.onRemoveFacilityId = this.onRemoveFacilityId.bind(this);
    this.onSelectFacilityId = this.onSelectFacilityId.bind(this);
    this.handleChangeChk = this.handleChangeChk.bind(this);
    this.handleFromDate = this.handleFromDate.bind(this);
    this.Calculate = this.Calculate.bind(this);
    this.updatePrice = this.updatePrice.bind(this);
    this.handleUploadDate = this.handleUploadDate.bind(this);
    this.onSelectDepartment = this.onSelectDepartment.bind(this);
    this.onRemoveDepartment = this.onRemoveDepartment.bind(this);
  }
  getAllDiagnosis = () => {
    let diagnosisItems = [];
    this.renderDiagnosisProgressBar();
    this.setState({
      isLoadingDiagnosis: true,
    });
    axios
      .get("https://www.kayan-healthcare.com/" + "api/getAllDiagnosis")
      .then(({ data }) => {
        if (data) {
          data.forEach((element) => {
            if (element.shortdesc === "null") {
              element.shortdesc = "";
            }
            diagnosisItems.push(element.code + "-" + element.shortdesc);
          });

          this.setState({ diagnosisItems, isLoadingDiagnosis: false });
        }
      })
      .catch(() => {
        this.setState({ serverError: "There are an server error" });
      });
  };
  getAllActivity = (v) => {
    let ActivityItems = [];

    this.renderActivityProgressBar();

    axios
      .get("https://www.kayan-healthcare.com/" + "api/getAllActivity/" + v)

      .then(({ data }) => {
        if (data) {
          data.forEach((element) => {
            if (
              ActivityItems.indexOf(element.code + "-" + element.shortdesc) ==
              -1
            ) {
              if (element.shortdesc === "null") {
                element.shortdesc = "";
              }

              ActivityItems.push(element.code + "-" + element.shortdesc);
            }
          });

          this.setState({ ActivityItems });
        }
      })
      .catch(() => {
        this.setState({ serverError: "There are an server error" });
      });
  };
  componentDidMount = () => {
    this.getPayersList();
    this.getAllDiagnosis();
    this.getAllDepartments();
  };
  getAllDepartments = async () => {
    let result = await axios.put(KayanURL + "api/sama/getAllDepartments", {
      apiToken: Cookies.get("SamaId"),
    });

    let departments = [];
    if (result) {
      if (result.data && result.data.success) {
        departments = result.data.departments;
      }
    }

    this.setState({ departments });
  };

  async onClickDownload(fileName, base64String) {
    //get PDF File from the server
    /*  let response = await axios.get(KayanURL + `api/sama/download/${fileName}`, {
      fileName: fileName,
      responseType: "blob",
      headers: {
        "Content-Type": "application/pdf",
      },
    }); */
    /*   var reader = new FileReader();
    reader.readAsDataURL(response.data);
    reader.onloadend = function () {
      var base64String = reader.result;
      saveAs( base64String, `${fileName}`);
    }; */

    saveAs(/* response.data */ base64String, `${fileName}`);
  }
  renderDiagnosisProgressBar() {
    let { diagnosisNow, diagnosisItems } = this.state;

    setTimeout(() => {
      this.setState(
        {
          diagnosisNow:
            !diagnosisItems && diagnosisNow < 100 ? diagnosisNow + 20 : 100,
        },
        this.renderDiagnosisProgressBar()
      );
    }, "800");
  }
  renderActivityProgressBar() {
    let { ActivityNow, ActivityItems } = this.state;

    setTimeout(() => {
      this.setState(
        {
          ActivityNow: !ActivityItems ? ActivityNow + 20 : 100,
        },
        this.renderActivityProgressBar()
      );
    }, "800");
  }
  async getPayersList() {
    let response = await axios.put(KayanURL + "api/sama/getPayers", {
      apiToken: Cookies.get("SamaId"),
    });

    if (response) {
      let { data } = response;
      if (data && data.success) {
        let { payers } = data;

        this.setState({ payers });
      }
    }
  }

  // Event fired when the input value is changed in auto complete input text
  onChange = async ({ target: { name, value } }, listName, editIndex) => {
    await this.setState({ editIndex });

    if (value.length <= 1) {
      value = value.trim();
    }

    switch (listName) {
      case "DiagnosisCodes":
        this.setState({
          section: "Diagnosis",
        });
        break;
      default:
        this.setState({
          section: "Activity",
        });
        break;
    }

    if (listName === "DiagnosisCodes" && value === "") {
      this.setState({
        BtnActive: false,
        userInputDiagnosis: "",
        userInputActivity: "",
      });
    } else if (listName === "ActivityCodes" && value === "") {
      this.setState({
        BtnActive: false,
        userInputDiagnosis: "",
        userInputActivity: "",
      });
    } else if (listName === "DiagnosisCodes") {
      this.setState({ attributes: ["Type"], BtnActive: true });
      let suggestions;

      suggestions = this.state.diagnosisItems;

      let filteredSuggestions = [];

      if (value.length > 1) {
        const regex = new RegExp(`${value.toLowerCase()}`); //filter
        filteredSuggestions = suggestions
          .sort()
          .filter((v) => regex.test(v.toLowerCase()));
        if (filteredSuggestions && filteredSuggestions.length == 0) {
          suggestions = this.state.diagnosisItems;
          filteredSuggestions = suggestions
            .sort()
            .filter((v) => regex.test(v.toLowerCase()));
        }
      }

      // Update the user input and filtered suggestions, reset the active
      // suggestion and make sure the suggestions are shown
      this.setState({
        activeSuggestion: 0,
        filteredSuggestionsDiagnosis: filteredSuggestions,
        showDiagnosisSuggestions: true,
        userInputDiagnosis: value,
      });
    } else {
      let suggestions = [];

      this.setState({ BtnActive: true });

      suggestions = this.state.ActivityItems;

      let filteredSuggestions = [];
      if (value.length > 1) {
        const regex = new RegExp(`${value.toLowerCase()}`);
        filteredSuggestions = suggestions
          .sort()
          .filter((v) => regex.test(v.toLowerCase()));

        if (filteredSuggestions && filteredSuggestions.length == 0) {
          suggestions = this.state.ActivityItems;
          filteredSuggestions = suggestions
            .sort()
            .filter((v) => regex.test(v.toLowerCase()));
        }
      }

      // Update the user input and filtered suggestions, reset the active
      // suggestion and make sure the suggestions are shown

      this.setState({
        activeSuggestion: 0,
        filteredSuggestionsActivity: filteredSuggestions,
        showActivitySuggestions: true,
        userInputActivity: value,
      });
    }
  };
  // Event fired when the user clicks on a suggestion
  onClick = (e) => {
    let code = e.currentTarget.innerText;
    let codeSearch = e.currentTarget.innerText;
    this.setState({ codeSearch });
    // Update the user input and reset the rest of the state
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showDiagnosisSuggestions: false,
      showActivitySuggestions: false,
    });

    if (
      this.state.filteredSuggestionsActivity &&
      this.state.filteredSuggestionsActivity.length > 0
    ) {
      this.setState({
        userInputActivity: e.currentTarget.innerText,
        filteredSuggestionsActivity: [],
      });
    }

    if (
      this.state.filteredSuggestionsDiagnosis &&
      this.state.filteredSuggestionsDiagnosis.length > 0
    ) {
      this.setState({
        attributes: ["Type"],

        userInputDiagnosis: e.currentTarget.innerText,
        filteredSuggestionsDiagnosis: [],
      });
    }

    //push to code
    this.setState({ code: { ...this.state.code, Text: code } });
  };

  handleChangeChk() {
    let { limit } = this.state;

    if (!limit) {
      this.setState({ limit: true });
    } else {
      this.setState({ limit: false });
    }
  }
  // Handling Select Actions.
  onSelectOption = (e) => {
    let select = document.getElementById(e);
    let name = select.name;
    let value = select.options[select.selectedIndex].value;
    switch (name) {
      case "Source":
        this.setState({ ActivityItems: [], ActivityNow: 0 });

        switch (value) {
          case "3":
            var attributes = [
              "code",
              "Start",
              "Quantity",
              "Net",
              "Clinician",
              "PriorAuthorizationID",
            ];

            break;
          case "4":
            var attributes = [
              "code",
              "Start",
              "Quantity",
              "Net",
              "Clinician",
              "PriorAuthorizationID",
            ];
            break;
          case "9":
            var attributes = [
              "code",
              "Start",
              "Quantity",
              "Net",
              "Clinician",
              "PriorAuthorizationID",
            ];
            break;
          case "8":
            var attributes = [
              "code",
              "Start",
              "Quantity",
              "Net",
              "Clinician",
              "PriorAuthorizationID",
            ];
            break;
          case "6":
            var attributes = [
              "code",
              "Start",
              "Quantity",
              "Net",
              "Clinician",
              "PriorAuthorizationID",
              "ToothNumber",
            ];
            break;
        }
        if (value !== 0) {
          this.getAllActivity(value);
        }

        this.setState({
          attributes,
          source: value,
          code: { ...this.state.code, [name]: value },
          BtnActive: true,
          section: "Activity",
        });

        break;

      case "DiagnosisType":
        this.setState({ code: { ...this.state.code, [name]: value } });
        break;

      default:
        //this.setState({ data: { ...this.state.data, [name]: value } });
        break;
    }
  };
  handleItemDeletion = (item, listName, idx) => {
    if (listName === "DiagnosisCodes") {
      let diagnosis = this.state.DiagnosisCodes;

      const index = diagnosis.indexOf(item);
      if (index > -1) {
        diagnosis = diagnosis.filter(function (i) {
          return i !== item;
        });

        this.setState({
          DiagnosisCodes: diagnosis,
          data: { ...this.state.data, Diagnosis: diagnosis },
        });
      }
    } else {
      let activity = this.state.ActivityCodes;

      const index = activity.indexOf(item);
      if (index > -1) {
        activity = activity.filter(function (i) {
          return i !== item;
        });

        this.setState({
          ActivityCodes: activity,
          data: { ...this.state.data, Activity: activity },
        });
      }
    }
  };
  //confirm add all new diagnoses
  confirmAddNewDiagnoses = (e) => {
    e.preventDefault();
    let { DiagnosisCodes, fieldsToEdit, newDiagnosisInfo } = this.state;

    let diagnosis;
    if (newDiagnosisInfo) {
      diagnosis = cloneDeep(this.state.newDiagnosisInfo);
    } else {
      diagnosis = cloneDeep(this.state.diagnosisInfo);
    }
    newDiagnosisInfo = diagnosis;
    DiagnosisCodes.forEach((code) => {
      let { Text, DiagnosisType } = code;
      code = Text.split("-")[0];
      let desc = Text.split("-")[1];

      newDiagnosisInfo[[code]] = {
        Desc: desc,
        Type: DiagnosisType,
      };
    });

    fieldsToEdit["diagnosis"] = newDiagnosisInfo;
    this.setState({
      fieldsToEdit,
      DiagnosisCodes: [],
      newDiagnosisInfo,
      addNewDiagScreen: false,
    });
  };

  confirmDeleteDiagnoses = (e) => {
    let { fieldsToEdit, newDiagnosisInfo, DeleteDiagnosisCode } = this.state;

    let diagnosis;
    if (newDiagnosisInfo) {
      diagnosis = cloneDeep(this.state.newDiagnosisInfo);
    } else {
      diagnosis = cloneDeep(this.state.diagnosisInfo);
    }

    newDiagnosisInfo = diagnosis;
    delete newDiagnosisInfo[DeleteDiagnosisCode];

    fieldsToEdit["diagnosis"] = newDiagnosisInfo;
    this.setState({
      fieldsToEdit,
      newDiagnosisInfo,
      DeleteDiagnosis: false,
    });
  };

  confirmDeleteActivity = (e) => {
    let { fieldsToEdit, newActivitiesInfo, DeleteActivityCode } = this.state;
    let Activity;
    if (newActivitiesInfo) {
      Activity = cloneDeep(this.state.newActivitiesInfo);
    } else {
      Activity = cloneDeep(this.state.newActivitiesInfo);
    }
    newActivitiesInfo = Activity;
    delete newActivitiesInfo[DeleteActivityCode];

    fieldsToEdit["activities"] = newActivitiesInfo;
    this.setState({
      fieldsToEdit,
      newActivitiesInfo,
      DeleteActivity: false,
    });
  };

  confirmDeleteObs = (e) => {
    let { fieldsToEdit, newActivitiesInfo, DeleteObsCode, DeleteObsindex } =
      this.state;

    let activities;
    if (newActivitiesInfo) {
      activities = cloneDeep(this.state.newActivitiesInfo);
    } else {
      activities = cloneDeep(this.state.activitiesInfo);
    }
    newActivitiesInfo = activities;

    let D = newActivitiesInfo[DeleteObsCode]["Observation"].splice(
      1,
      DeleteObsindex
    );
    newActivitiesInfo[DeleteObsCode]["Observation"] = D;

    fieldsToEdit["activities"] = newActivitiesInfo;
    this.setState({
      fieldsToEdit,
      newActivitiesInfo,
      DeleteOBS: false,
    });
  };

  confirmDeleteDX = () => {
    let { fieldsToEdit, newDiagnosisInfo, DeleteDXCode, CodeofDX } = this.state;

    let diagnosis;
    if (newDiagnosisInfo) {
      diagnosis = cloneDeep(this.state.newDiagnosisInfo);
    } else {
      diagnosis = cloneDeep(this.state.diagnosisInfo);
    }
    newDiagnosisInfo = diagnosis;

    let D = newDiagnosisInfo[CodeofDX]["DxInfo"].splice(1, DeleteDXCode);
    newDiagnosisInfo[CodeofDX]["DxInfo"] = D;

    fieldsToEdit["diagnosis"] = newDiagnosisInfo;
    //newDiagnosisInfo = diagnosis;
    this.setState({
      fieldsToEdit,
      newDiagnosisInfo,
      DeleteDX: false,
    });
  };
  // add code to codesLists
  addNewCode = (event, listName, inputId) => {
    event.preventDefault();

    this.setState({
      BtnActive: false,
    });
    let { DiagnosisCodes, ActivityCodes, code } = this.state;

    switch (listName) {
      case "DiagnosisCodes":
        if (!code.DiagnosisType) {
          code.DiagnosisType =
            document.getElementById("diagnosisType").options[
              document.getElementById("diagnosisType").options.selectedIndex
            ].value;
        }
        DiagnosisCodes.push(code);

        this.setState({
          DiagnosisCodes,
          code: {},
          data: { ...this.state.data, Diagnosis: DiagnosisCodes },
          userInputDiagnosis: "",
        });
        break;

      default:
        if (!code.Quantity) {
          code.Quantity = "1";
        }

        if (!code.Start) {
          var isodate = document.getElementById("Start").value;
          var datetime = isodate.replace(/\-/g, "/");
          datetime = datetime.replace(/\T/g, " ");
          var datetimeArray = datetime.split(" ");
          var date = datetimeArray[0];
          var time = datetimeArray[1];
          //reverse date string
          var reverseDate = date.split("/").reverse().join("/");

          code.Start = reverseDate + " " + time;
        }

        ActivityCodes.push(code);

        //reset the dropdown list

        let select = document.getElementById("activitySource");
        select.options[0].selected = true;
        //

        this.setState({
          ActivityCodes,
          code: {},
          data: { ...this.state.data, Activity: ActivityCodes },
          userInputActivity: "",
        });
        break;
    }
    //this.setState({ ActivityCodes: [], DiagnosisCodes: [] });
  };
  // update diagnosis code
  addICDsCode = ({ target: { name, value } }, code, section) => {
    this.setState({
      BtnActive: false,
    });
    let { fieldsToEdit, codeSearch } = this.state;

    let diagnosis;
    if (this.state.newDiagnosisInfo) {
      diagnosis = cloneDeep(this.state.newDiagnosisInfo);
    } else {
      diagnosis = cloneDeep(this.state.diagnosisInfo);
    }

    let x = codeSearch.split("-");
    if (code && section == "diagnosis") {
      let info = diagnosis[code];
      info["Desc"] = x[1];
      let diagnosisCodes = Object.keys(diagnosis);

      let codeIndex = diagnosisCodes.indexOf(code);

      diagnosisCodes[codeIndex] = x[0];

      let newDiagnosis = {};
      diagnosisCodes.forEach((c, index) => {
        if (index == codeIndex) {
          newDiagnosis[c] = info;

          // newDiagnosis[c]["Desc"] = x[1];
        } else {
          newDiagnosis[c] = diagnosis[c];
        }
      });

      this.setState({ newDiagnosisInfo: newDiagnosis });
      fieldsToEdit["diagnosis"] = newDiagnosis;
      this.setState({ fieldsToEdit });
    }
  };

  // update Activity Code
  addCPTsCode = ({ target: { name, value } }, code, section) => {
    this.setState({
      BtnActive: false,
    });
    let { fieldsToEdit, codeSearch } = this.state;

    let x = codeSearch.split("-");
    if (code && section == "activities") {
      {
        let activities = cloneDeep(this.state.newActivitiesInfo);
        let info = activities[code];
        info["Desc"] = x[1];
        let activitiesCodes = Object.keys(activities);
        let codeIndex = activitiesCodes.indexOf(code);
        activitiesCodes[codeIndex] = x[0];
        let newActivities = {};

        activitiesCodes.forEach((c, index) => {
          if (index == codeIndex) {
            newActivities[c] = info;
            //newActivities[c]["Desc"] = x[1];
          } else {
            newActivities[c] = activities[c];
          }
        });

        this.setState({ newActivitiesInfo: newActivities });
        fieldsToEdit["activities"] = newActivities;
        this.setState({ fieldsToEdit });
      }
    }
  };
  async search(event) {
    let { limit } = this.state;

    this.setState({ isSearch: true });

    const data = await axios.put(KayanURL + "api/sama/search", {
      claim_status: this.state.claim_status,

      enc_type: this.state.enc_type,
      payer_id: this.state.payer_id,
      claim_id: this.state.claim_id,
      enc_facility_id: this.state.facilityId,
      fromPrice: this.state.fromPrice,
      toPrice: this.state.toPrice,
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
      limit: limit,
      upload_date: this.state.upload_date,
      department_id: this.state.departmentId,
      apiToken: Cookies.get("SamaId"),
    });

    let response = data["data"];

    let claims = [];

    if (response) {
      claims = response["claims"];

      this.setState({ AllClaims: claims, isSearch: false });

      this.setState({
        claim_status: "",
        enc_start: undefined,
        enc_end: undefined,
        enc_type: [],
        payer_id: "",
        claim_id: "",
        fromPrice: "",
        toPrice: "",
        facilityId: "",
      });
    }
  }

  async updatePrice(event) {
    event.preventDefault();
    this.setState({
      updatePriceResponse: false,
      updatePriceFunctionFlag: true,
    });
    let { recordId, selectedCodePrice, missMatch } = this.state;
    let updatePriceResult = await axios.put(
      KayanURL + "api/sama/updateClaimPrice",
      {
        apiToken: Cookies.get("SamaId"),
        claimid: recordId,
        codesPrice: /* selectedCodePrice */ missMatch,
      }
    );

    if (updatePriceResult) {
      let { data } = updatePriceResult;
      if (data && data.success) {
        this.setState({
          updatePriceResponse: true,
          updatePriceFunctionFlag: false,
          updatePriceResponseMessgae: "Prices Updated Successfully",
        });
      }
    }
  }
  async update(event) {
    event.preventDefault();
    let { fieldsToEdit, AllFiles } = this.state;
    //no need to upload file to server
    /*  if (AllFiles) {
      let uploadRes = await axios.post(KayanURL + "api/sama/upload", AllFiles);
    } */
    fieldsToEdit["claim_status"] = "0";
    let fields = Object.keys(fieldsToEdit);
    let { AllClaims } = this.state;

    fieldsToEdit["apiToken"] = Cookies.get("SamaId");
    let { editableRow, recordId } = this.state;
    let reqId;
    if (editableRow) {
      fieldsToEdit["id"] = editableRow["id"];
      reqId = fieldsToEdit["id"];
    } else {
      fieldsToEdit["id"] = recordId;
      reqId = recordId;
    }
    if (fieldsToEdit["diagnosis"]) {
      let details = fieldsToEdit["diagnosis"];

      fieldsToEdit["diagnosis"] = JSON.stringify(details);
    }
    let EncounterInfo;
    if (AllClaims) {
      let claim = AllClaims.filter((c) => c.id == reqId);
      if (claim && claim.length > 0) {
        //Encounter Info for selected claims
        EncounterInfo = claim[0];
        this.setState({ EncounterInfo });
      }
    }
    if (fields && fields.length > 0) {
      for (let index = 0; index < fields.length; index++) {
        if (fields[index] == "claim_id") {
          EncounterInfo.claim_id = fieldsToEdit["claim_id"];
        }
      }
      //EncounterInfo.claim_id = fieldsToEdit["claim_id"];
      fieldsToEdit["claim_id"] = EncounterInfo.claim_id; //
      if (fieldsToEdit["activities"]) {
        let details = fieldsToEdit["activities"];

        fieldsToEdit["activities"] = JSON.stringify(details);
      }

      let { timeline } = EncounterInfo;
      let date = this.generateCurrentDate();
      timeline.push({ updated: date });
      fieldsToEdit["timeline"] = JSON.stringify(timeline);
      fieldsToEdit["functionType"] = "edit";
      let response = await axios.put(
        KayanURL + "api/sama/update",
        fieldsToEdit
      );
      if (response) {
        let { data } = response;
        if (data.success) {
          let { EncounterInfo } = this.state;
          // console.log(this.state);
          delete fieldsToEdit["apiToken"];
          delete fieldsToEdit["id"];
          this.setState({
            isEditMode: false,
            isEditMode1: false,
          });
          if (fieldsToEdit["diagnosis"]) {
            this.setState({ diagnosisInfo: this.state.newDiagnosisInfo });
            this.setState({ newDiagnosisInfo: undefined });
          }
          if (fieldsToEdit["activities"]) {
            this.setState({ activitiesInfo: this.state.newActivitiesInfo });
            this.setState({ newActivitiesInfo: undefined });
          }
          let keys = Object.keys(fieldsToEdit);

          keys.forEach((k) => {
            if (k != "activities" && k != "diagnosis") {
              EncounterInfo[[k]] = fieldsToEdit[k];
            }
          });

          //fieldsToEdit = {};
          this.setState({ fieldsToEdit: {} });

          this.search();
        } else {
          alert(data.error);
        }
      }
    } else {
      console.log("change one value at least");
      alert("Change one value at least");
    }
  }

  async Calculate(event) {
    event.preventDefault();
    this.setState({ calculateResponse: false, claculateFunctionFlag: true });
    //get the xmls of this claim
    let xml = this.jsonToXML();

    let calculatonResponse = await axios.put(KayanURL + "api/sama/calculate", {
      apiToken: Cookies.get("SamaId"),
      data: xml,
      functionType: "calculate",
    });

    //  console.log("calculatonResponse", calculatonResponse);
    if (calculatonResponse) {
      let { data } = calculatonResponse;

      if (data.success == false) {
        this.setState({
          claculateFunctionFlag: false,
        });
        alert(data.error);
      } else {
        let { missMatch, GrossMissMatch } = data;

        this.setState({
          missMatch,
          GrossMissMatch,
          calculateResponse: true,
          claculateFunctionFlag: false,
        });
      }
    }
  }
  //select claim status
  handleSelectChange = (e) => {
    this.setState({ claim_status: e.target.value, isSearch: false });
  };
  //handle start and end date for claims
  onInputTextChange({ target: { name, value } }) {
    this.setState({ responseReady: false, enc_start: value, enc_end: value });
  }
  //handle select encounter type
  onSelectCaseType(selectedList, selectedItem) {
    let encTypeValues = [];
    selectedList.forEach((item) => {
      encTypeValues.push(item.value);
    });

    this.setState({ enc_type: encTypeValues });
  }
  //handle remove encounter type

  onRemoveCaseType(selectedList, removedItem) {
    let encTypeValues = [];
    selectedList.forEach((item) => {
      if (item.value != removedItem.value) {
        encTypeValues.push(item.value);
      }
    });

    this.setState({ enc_type: encTypeValues });
  }

  //enc_facility_id
  //handle select facility id
  onSelectFacilityId(selectedList) {
    this.setState({ facilityId: selectedList });
  }
  onSelectDepartment(selectedList) {
    this.setState({ departmentId: selectedList });
  }
  //Delate claims from table
  DeleteClaimByID = async () => {
    let { rowid, limit } = this.state;
    let response = await axios.put(KayanURL + "api/sama/deleteClaim", {
      apiToken: Cookies.get("SamaId"),
      RowID: rowid,
    });
    if (response) {
      let { data } = response;
      if (data.success) {
        this.setState({
          success: true,
          DeleteClaim: false,
        });
      } else {
        if (!data.success) {
          this.setState({ DeleteClaim: false });
          alert(data.error);
        }
      }
    }
    this.setState({ isSearch: true });

    const data = await axios.put(KayanURL + "api/sama/search", {
      limit: limit,
      apiToken: Cookies.get("SamaId"),
    });

    let NewClaim = data["data"];

    let claims = [];

    if (NewClaim) {
      claims = NewClaim["claims"];

      this.setState({ AllClaims: claims, isSearch: false });
    }
  };
  //handle remove facility id

  onRemoveFacilityId(selectedList, removedItem) {
    let facilityIdValues = [];
    selectedList.forEach((item) => {
      if (item.value != removedItem.value) {
        facilityIdValues.push(item.value);
      }
    });

    this.setState({ facilityId: facilityIdValues });
  }

  onRemoveDepartment(selectedList, removedItem) {
    let departmentIdValues = [];
    selectedList.forEach((item) => {
      if (item.value != removedItem.value) {
        departmentIdValues.push(item.value);
      }
    });

    this.setState({ departmentId: departmentIdValues });
  }

  handleStartEndDate({ target: { name, value } }) {
    if (value) {
      let date_time = value.split("T");
      if (date_time) {
        let date = date_time[0]; // mm-dd-yyyy -----> dd/mm/yyyy
        date = date.replace(/\-/g, "/").split("/");
        let date2 = date[0] + "/" + date["2"] + "/" + date["1"];
        date2 = date2.split("/").reverse().join("/");

        let time = date_time[1];
        let finalDateTime = date2 + " " + time;

        if (name == "enc_start") {
          this.setState({ enc_start: finalDateTime });
        } else if (name == "enc_end") {
          this.setState({ enc_end: finalDateTime });
        }
      }
    }
  }

  handleFromDate({ target: { name, value } }) {
    if (value) {
      let date_time = value.split("T");
      if (date_time) {
        let date = date_time[0]; // mm-dd-yyyy -----> dd/mm/yyyy
        date = date.replace(/\-/g, "/").split("/");
        let date2 = date[0] + "/" + date["2"] + "/" + date["1"];
        date2 = date2.split("/").reverse().join("/");

        let time = date_time[1];
        let finalDateTime = date2 + " " + time;

        if (name == "dateFrom") {
          this.setState({ dateFrom: finalDateTime });
        } else if (name == "dateTo") {
          this.setState({ dateTo: finalDateTime });
        }
      }
    }
  }

  handleUploadDate({ target: { name, value } }) {
    if (value) {
      let date_time = value.split("T");
      if (date_time) {
        let date = date_time[0]; // mm-dd-yyyy -----> dd/mm/yyyy
        date = date.replace(/\-/g, "/").split("/");
        let date2 = date[0] + "/" + date["2"] + "/" + date["1"];
        date2 = date2.split("/").reverse().join("/");

        let time = date_time[1];
        let finalDateTime = date2 + " " + time;

        this.setState({ upload_date: finalDateTime });
      }
    }
  }
  handlePriceChange({ target: { name, value } }) {
    if (name == "fromPrice") {
      this.setState({ fromPrice: value });
    } else {
      this.setState({ toPrice: value });
    }
  }
  onClickShowMore = (row) => {
    this.setState({ editableRow: row });
    const recordId = row.id;

    this.setState({
      //isShowmore: true, //to pop up details screen
      recordId, //id of clicked record
    });

    if (!this.state.isShowmore) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }

    this.setState({ isShowmore: true });

    let { AllClaims } = this.state;
    if (AllClaims) {
      let claim = AllClaims.filter((c) => c.id == recordId);

      if (claim && claim.length > 0) {
        //Encounter Info for selected claims
        let EncounterInfo = claim[0];
        this.setState({ EncounterInfo });
        //diagnosis details for selected claim
        let diagnosisInfo = claim[0]["diagnosis"];

        this.setState({ diagnosisInfo });
        //activity details for selected claim
        let activitiesInfo = claim[0]["activities"];

        let activityCodes = Object.keys(activitiesInfo);

        for (let index = 0; index < activityCodes.length; index++) {
          const code = activityCodes[index];

          if (activitiesInfo[[code]]["Observation"] != undefined) {
            if (!this.state.observationFlag) {
              this.setState({ observationFlag: true });
            }
            break;
          }
        }
        this.setState({ activitiesInfo });
      }
    }
  }; //end fun

  onClickShowTimeLine = (row) => {
    const id = row.id;
    this.setState({
      claimIdToShowTimeLine: id,
      isShowTimeLine: true,
    });
    let { AllClaims } = this.state;
    if (AllClaims) {
      let claim = AllClaims.filter((c) => c.id == id);

      if (claim) {
        let claimDetails = claim[0];
        let { timeline } = claimDetails;
        this.setState({ timeline });
      }
    }
  };

  handleOpenDeleteModal = (row) => {
    this.setState({
      DeleteClaim: true,
      rowid: row["id"],
    });
  };

  //Activate Edit Mode
  changeEditMode = () => {
    this.setState({
      isEditMode: !this.state.isEditMode,
    });
  };

  //deactivate edit mode
  changeEditMode1 = (row) => {
    const recordId = row.id;
    this.setState({ editableRow: row });

    this.setState({
      //isShowmore: true, //to pop up details screen
      recordId, //id of clicked record
    });
    this.setState({
      //isShowmore: true, //to pop up details screen
      recordId, //id of clicked record
    });

    let newActivitiesInfo;

    let { AllClaims } = this.state;
    if (AllClaims) {
      let claim = AllClaims.filter((c) => c.id == recordId);

      if (claim && claim.length > 0) {
        //Encounter Info for selected claims
        let EncounterInfo = claim[0];
        this.setState({ EncounterInfo });
        //diagnosis details for selected claim
        let diagnosisInfo = claim[0]["diagnosis"];

        this.setState({ diagnosisInfo });
        //activity details for selected claim
        let activitiesInfo = claim[0]["activities"];

        let activityCodes = Object.keys(activitiesInfo);

        for (let index = 0; index < activityCodes.length; index++) {
          const code = activityCodes[index];

          if (activitiesInfo[[code]]["Observation"] != undefined) {
            if (!this.state.observationFlag) {
              this.setState({ observationFlag: true });
            }
            break;
          }
        }
        newActivitiesInfo = cloneDeep(activitiesInfo);
        this.setState({ activitiesInfo });
      }
    }
    //let privilegesJson = JSON.parse(privileges);

    //  let { edit } = privilegesJson;

    // if (edit) {
    this.setState({
      isEditMode1: !this.state.isEditMode1,
      observationObj: {},
      newActivitiesInfo,
      DXObj: {},
      DiagObj: {},
    });

    //}  else {
    //alert("You don't have the privilege to edit claim");
    //}
  };

  handleInputTextChange = ({ target: { name, value } }, code, section) => {
    let { fieldsToEdit, newDiagnosisInfo, newActivitiesInfo } = this.state;

    let diagnosis;
    let activities;
    if (newDiagnosisInfo) {
      diagnosis = cloneDeep(this.state.newDiagnosisInfo);
    } else {
      diagnosis = cloneDeep(this.state.diagnosisInfo);
    }

    if (newActivitiesInfo) {
      activities = cloneDeep(this.state.newActivitiesInfo);
    } else {
      activities = cloneDeep(this.state.activitiesInfo);
    }

    if (code && section == "diagnosis") {
      if (name == "Type") {
        diagnosis[code]["Type"] = value;
        this.setState({ newDiagnosisInfo: diagnosis });
        fieldsToEdit["diagnosis"] = diagnosis;
        this.setState({ fieldsToEdit });
      }
    } else if (code && section == "activities") {
      if (name == "Code") {
        let info = activities[code];
        delete activities[code];
        activities[value] = info;
        //this.setState({ activitiesInfo });
        this.setState({ newActivitiesInfo: activities });
        fieldsToEdit["activities"] = activities;
        this.setState({ fieldsToEdit });
      } else {
        if (name == "Type") {
          this.getAllActivity(value);
        }
        activities[code][name] = value;
        fieldsToEdit["activities"] = activities;

        this.setState({ newActivitiesInfo: activities });

        this.setState({ fieldsToEdit });
      }
    } else {
      fieldsToEdit[name] = value;

      this.setState({ fieldsToEdit });
    }

    let fields = Object.keys(fieldsToEdit);

    if (fields.indexOf("patient_share") != -1 && fields.indexOf("net") != -1) {
      //Gross=PatientShare+Net
      fieldsToEdit["gross"] =
        parseInt(fieldsToEdit["patient_share"]) + parseInt(fieldsToEdit["net"]);
    } else if (fields.indexOf("patient_share") != -1) {
      fieldsToEdit["gross"] =
        parseInt(fieldsToEdit["patient_share"]) +
        parseInt(this.state.EncounterInfo["net"]);
    } else if (fields.indexOf("net") != -1) {
      fieldsToEdit["gross"] =
        parseInt(this.state.EncounterInfo["patient_share"]) +
        parseInt(fieldsToEdit["net"]);
    }
  };

  handleInputTextChangeForObservation = async (
    { target: { name, value, files } },
    code,
    section,
    index
  ) => {
    let { fieldsToEdit, activitiesInfo, newActivitiesInfo } = this.state;

    if (newActivitiesInfo) {
      let newObservationInfo = newActivitiesInfo[code]["Observation"];

      if (newObservationInfo && newObservationInfo.length > 0) {
        let details = newObservationInfo[index];
        if (name == "file") {
          let result = await this.readContent(files[0]);

          details["Value"] = result;
          details["Code"] = files[0].name;
          details["ValueType"] = "pdf";
        } else {
          details[name] = value;
        }
        newActivitiesInfo[code]["Observation"][index] = details;
        this.setState({ newActivitiesInfo });
        fieldsToEdit["activities"] = newActivitiesInfo;
        this.setState({ fieldsToEdit });
      }
    } else {
      let activities = cloneDeep(this.state.activitiesInfo);

      if (name == "file") {
        let result = await this.readContent(files[0]);

        activities[code]["Observation"][index][name] = value;
        activities[code]["Observation"][index]["Value"] = result;
        activities[code]["Observation"][index]["Code"] = files[0].name;
        activities[code]["Observation"][index]["ValueType"] = "pdf";
      } else {
        activities[code]["Observation"][index][name] = value;
      }
      fieldsToEdit["activities"] = activities;
      this.setState({ newActivitiesInfo: activities });
      this.setState({ fieldsToEdit });
    }
  };

  handleInputTextChangeForDxInfo = (
    { target: { name, value } },
    code,
    index
  ) => {
    let { fieldsToEdit, newDiagnosisInfo } = this.state;

    if (newDiagnosisInfo) {
      let newDxInfo = newDiagnosisInfo[code]["DxInfo"];

      if (newDxInfo && newDxInfo.length > 0) {
        let details = newDxInfo[index];

        details[name] = value;
        newDiagnosisInfo[code]["DxInfo"][index] = details;
      }
    } else {
      let diagnosis = cloneDeep(this.state.diagnosisInfo);

      diagnosis[code]["DxInfo"][index][name] = value;
      fieldsToEdit["diagnosis"] = diagnosis;
      this.setState({ newDiagnosisInfo: diagnosis });
      this.setState({ fieldsToEdit });
    }
  };

  handleInputTextChangeForAddNewActivity({ target: { name, value } }) {
    let { ActivityCodes, code } = this.state;

    code[name] = value;
    this.setState(code);
  }

  askForReview() {
    /*  let { askForReview } = privileges;

    if (!askForReview) {
      alert("You don't have a privilege to ask for review ");
    } */
  }

  readContent = async (files) => {
    let fileReader = new FileReader();
    let fileToLoad = files;
    let file = "";
    // FileReader function for read the file.
    // Onload of file read the file content

    return new Promise((resolve, reject) => {
      fileReader.onload = async (fileLoadedEvent) => {
        file = fileLoadedEvent.target.result;
        // Print data in console
        // console.log(file);
        resolve(file);
        // await this.setState({ base64: file });
      };

      // Convert data to base64
      fileReader.readAsDataURL(fileToLoad);
    });
  };

  handleInputTextChangeForAddNewObservation = async ({
    target: { name, value, files },
  }) => {
    if (name != "file") {
      this.setState({ observationType: undefined });
    }
    let Obj = {};
    let { codeToAddObs, observationObj } = this.state;
    let thisCodeObsDetails = observationObj[codeToAddObs];

    if (name == "Type" && value == "File") {
      this.setState({ observationType: "File" });
    }

    if (name == "file") {
      this.setState({ fileName: files[0].name });
      this.setState({ fileType: "pdf" });

      if (files.length > 0) {
        //no need to update file to server
        /*   let formData;
        if (!this.state.AllFiles) {
          formData = new FormData();
        }
        formData.append("file", files[0]);
        await this.setState({ AllFiles: formData }); */

        //--------------
        let res = await this.readContent(files[0]);
        this.setState({ base64: res });
        name = "Value";

        value = this.state.base64;
      }
    }

    if (!thisCodeObsDetails) {
      if (this.state.observationType == "File") {
        Obj["Type"] = "File";
        Obj["Code"] = this.state.fileName;
        Obj["ValueType"] = this.state.fileType;
        Obj["Value"] = this.state.base64;
      } else {
        Obj[name] = value;
      }

      observationObj[codeToAddObs] = Obj;
    } else {
      if (this.state.observationType == "File") {
        thisCodeObsDetails["Type"] = "File";
        thisCodeObsDetails["Code"] = this.state.fileName;
        thisCodeObsDetails["ValueType"] = this.state.fileType;
        thisCodeObsDetails["Value"] = this.state.base64;
      } else {
        thisCodeObsDetails[name] = value;
      }

      observationObj[codeToAddObs] = thisCodeObsDetails;
    }
  };

  renderEditView = () => {
    let { editableRow } = this.state;

    return (
      <div>
        <table striped bordered hover>
          <thead style={{ background: "#3ab449", color: " white" }}>
            <tr>
              <th>Claim ID</th>
              <th>Provider ID</th>
              <th>Sender Id</th>
              <th>Receiver ID</th>
              <th>Transaction Date</th>

              <th>Disposition Flag</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  style={{ width: "100%", height: "100%" }}
                  type="text"
                  name="claim_id"
                  defaultValue={editableRow["claim_id"]}
                  onChange={this.handleInputTextChange}
                />
              </td>
              <td>
                <input
                  style={{ width: "100%", height: "100%" }}
                  type="text"
                  name="provider_id"
                  defaultValue={editableRow["provider_id"]}
                  onChange={this.handleInputTextChange}
                />
              </td>
              <td>
                <input
                  style={{ width: "100%", height: "100%" }}
                  type="text"
                  name="sender_id"
                  defaultValue={editableRow["sender_id"]}
                  onChange={this.handleInputTextChange}
                />
              </td>
              <td>
                <input
                  style={{ width: "100%", height: "100%" }}
                  type="text"
                  name="receiver_id"
                  defaultValue={editableRow["receiver_id"]}
                  onChange={this.handleInputTextChange}
                />
              </td>
              <td>
                <input
                  style={{ width: "100%", height: "100%" }}
                  type="text"
                  defaultValue={editableRow["transaction_date"]}
                  name="transaction_date"
                  onChange={this.handleInputTextChange}
                />
              </td>
              <td>
                <input
                  style={{ width: "100%", height: "100%" }}
                  type="text"
                  name="disposition_flag"
                  defaultValue={editableRow["disposition_flag"]}
                  onChange={this.handleInputTextChange}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
  addNewDX = (code) => {
    this.setState({
      addNewDXScreen: true,
      codeToAddDX: code,
      DXObj: {},
    });
  };
  addNewDiag = () => {
    this.setState({
      addNewDiagScreen: true,
      DiagObj: {},
      userInputDiagnosis: "",
      codeSearch: "",
    });
  };
  addNewActivity = () => {
    this.setState({
      addNewActivityScreen: true,
      ActivityObj: {},
      userInputActivity: "",
      codeSearch: "",
    });
  };
  handleInputTextChangeForAddNewDX = async ({ target: { name, value } }) => {
    let Obj = {};

    let { codeToAddDX, DXObj } = this.state;
    let thisCodeDxDetails = DXObj[codeToAddDX];

    if (!thisCodeDxDetails) {
      Obj[name] = value;
      DXObj[codeToAddDX] = Obj;
    } else {
      // Obj[name] = value;
      thisCodeDxDetails[name] = value;
      DXObj[codeToAddDX] = thisCodeDxDetails;
    }

    this.setState({ DXObj });
  };
  confirmAddNewDX = () => {
    let { codeToAddDX, DXObj, diagnosisInfo, fieldsToEdit, newDiagnosisInfo } =
      this.state;
    let diagnosis; /* = cloneDeep(this.state.diagnosisInfo); */
    let codeDX; /* = diagnosisInfo[[codeToAddDX]]["DxInfo"]; */
    if (newDiagnosisInfo) {
      diagnosis = cloneDeep(this.state.newDiagnosisInfo);
      codeDX = newDiagnosisInfo[[codeToAddDX]]["DxInfo"];
    } else {
      diagnosis = cloneDeep(this.state.diagnosisInfo);
      codeDX = diagnosisInfo[[codeToAddDX]]["DxInfo"];
    }

    if (!codeDX) {
      codeDX = [];

      codeDX.push(DXObj[codeToAddDX]);

      let newDiagnosisInfo = diagnosis;

      newDiagnosisInfo[[codeToAddDX]]["DxInfo"] = codeDX;

      fieldsToEdit["diagnosis"] = newDiagnosisInfo;

      this.setState({
        fieldsToEdit,

        addNewDXScreen: false,
        newDiagnosisInfo,
        confirmed: true,
      });
    } else {
      codeDX.push(DXObj[codeToAddDX]);

      let newDiagnosisInfo = diagnosis;

      newDiagnosisInfo[[codeToAddDX]]["DxInfo"] = codeDX;

      fieldsToEdit["diagnosis"] = newDiagnosisInfo;

      this.setState({
        fieldsToEdit,

        addNewDXScreen: false,
        newDiagnosisInfo,
        confirmed: true,
      });
    }
  };

  handleInputTextChangeForAddNewDiag = async ({ target: { name, value } }) => {
    let Obj = {};
    let { DiagObj, codeSearch } = this.state;
    let NewCode = codeSearch.Text.split("-");
    let desc = NewCode[1];
    let thisCodeDxDetails = DiagObj[NewCode[0]];
    if (!thisCodeDxDetails) {
      Obj[name] = value;
      DiagObj[NewCode[0]] = Obj;
      DiagObj[NewCode[0]]["Desc"] = desc;
    } else {
      thisCodeDxDetails[name] = value;
      DiagObj[NewCode[0]] = thisCodeDxDetails;
    }
  };

  confirmAddNewActivity = (e) => {
    e.preventDefault();
    let { ActivityCodes, fieldsToEdit, newActivitiesInfo } = this.state;

    let activities;
    if (newActivitiesInfo) {
      activities = cloneDeep(this.state.newActivitiesInfo);
    } else {
      activities = cloneDeep(this.state.activitiesInfo);
    }
    newActivitiesInfo = activities;

    ActivityCodes.forEach((code) => {
      let {
        Source,
        Text,
        Start,
        Quantity,
        Net,
        Clinician,
        PriorAuthorizationID,
      } = code;
      code = Text.split("-")[0];
      let desc = Text.split("-")[1];
      newActivitiesInfo[[code]] = {
        Desc: desc,
        Type: Source,
        Start: Start,
        Quantity: Quantity,
        Net,
        Clinician,
        PriorAuthorizationID,
      };
    });

    fieldsToEdit["activities"] = newActivitiesInfo;
    this.setState({
      fieldsToEdit,
      ActivityCodes: [],
      newActivitiesInfo,
      addNewActivityScreen: false,
    });
  };
  renderEditView1 = () => {
    let {
      addNewObsScreen,
      addNewDXScreen,
      diagnosisInfo,

      newDiagnosisInfo,
      EncounterInfo,

      newActivitiesInfo,

      addNewDiagScreen,
      addNewActivityScreen,
    } = this.state;

    let {
      handleInputTextChangeForObservation,
      addNewObservation,
      handleInputTextChange,
      handleInputTextChangeForDxInfo,
      mappingEncType,
      addNewDX,
      addNewDiag,
      addNewActivity,
      handleInputTextChangeForAddNewDX,
    } = this;
    let EncouterDetailsFields = [
      "member_id",
      "payer_id",
      "emirates_id_number",
      "gross",
      "patient_share",
      "net",
      "enc_facility_id",
      "enc_type",
      "enc_patient_id",
      "enc_start",
      "enc_end",

      "start_type",
      "end_type",
      "transfer_source",
      "transfer_destination",
    ];
    return (
      <>
        <FormModal
          ref={(node) => {
            this.node = node;
          }}
          open={true}
          maxWidth="xl"
          onClose={() => this.setState({ isEditMode1: false })}
        >
          <Box style={{ width: "95%", margin: "auto" }}>
            <DialogActions
              open={this.state.DeleteDiagnosis}
              onClose={() =>
                this.setState({
                  DeleteDiagnosis: false,
                })
              }
              action={this.confirmDeleteDiagnoses}
              dialog="Are you sure you want to delete this Diagnosis?"
            />
            <DialogActions
              open={this.state.DeleteDX}
              onClose={() =>
                this.setState({
                  DeleteDX: false,
                })
              }
              action={this.confirmDeleteDX}
              dialog="Are you sure you want to delete this DX?"
            />
            <DialogActions
              open={this.state.DeleteOBS}
              onClose={() =>
                this.setState({
                  DeleteOBS: false,
                })
              }
              action={this.confirmDeleteObs}
              dialog="Are you sure you want to delete this Observation?"
            />
            <DialogActions
              open={this.state.DeleteActivity}
              onClose={() =>
                this.setState({
                  DeleteActivity: false,
                })
              }
              action={this.confirmDeleteActivity}
              dialog="Are you sure you want to delete this Activity?"
            />
            <Typography
              style={{ textAlign: "center" }}
              variant="h5"
              gutterBottom
            >
              Edit Claim
            </Typography>
            {this.renderEditView()}
            <Typography
              style={{ textAlign: "center" }}
              variant="h6"
              gutterBottom
            >
              Encounter Details
            </Typography>
            <table striped bordered hover>
              <thead style={{ background: "#3ab449", color: " white" }}>
                <tr>
                  <th>Member ID</th>
                  <th> Payer ID</th>
                  <th>Emirates ID Number</th>
                  <th>Gross</th>
                  <th>Patient Share</th>
                  <th>Net</th>
                  <th>Facility ID</th>
                  <th>Encounter Type</th>
                  <th>Patient ID</th>
                  <th>Start</th>
                  <th>End</th>
                  <th>Encounter Start Type</th>
                  <th>Encounter End Type</th>
                  <th>Transfer Source</th>
                  <th>Transfer Destination</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {EncouterDetailsFields.map(function (field) {
                    return (
                      <td>
                        {field == "start_type" ? (
                          <select
                            style={{ width: "100%", height: "100%" }}
                            name={field}
                            onChange={handleInputTextChange}
                            defaultValue={EncounterInfo[field]}
                          >
                            <option value="1">Elective</option>
                            <option value="2">Emergency</option>
                            <option value="3">Transfer</option>
                            <option value="4">Live birth</option>
                            <option value="5">Still birth</option>
                            <option value="6">Dead On Arrival</option>
                            <option value="7">Continuing Encounter</option>
                          </select>
                        ) : field == "end_type" ? (
                          <select
                            style={{ width: "100%", height: "100%" }}
                            name={field}
                            onChange={handleInputTextChange}
                            defaultValue={EncounterInfo[field]}
                          >
                            <option value="1">Discharged with approval</option>
                            <option value="2">Discharged against advice</option>
                            <option value="3">
                              Discharged absent without leave
                            </option>
                            <option value="4">
                              Discharge transfer to acute care
                            </option>
                            <option value="5">Deceased</option>
                            <option value="6">Not discharged</option>
                            <option value="7">
                              Discharge transfer to non-acute care(Transfer to
                              long term care).
                            </option>
                          </select>
                        ) : (
                          <input
                            style={{ width: "100%", height: "100%" }}
                            name={field}
                            type="text"
                            defaultValue={
                              field == "enc_type"
                                ? mappingEncType(EncounterInfo[field])
                                : EncounterInfo[field]
                            }
                            onChange={handleInputTextChange}
                          />
                        )}
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
            <Typography
              style={{ textAlign: "center", marginTop: "0.3rem" }}
              variant="h6"
              gutterBottom
            >
              Diagnosis Details
            </Typography>
            <MuiButton
              variant="contained"
              startIcon={<Icon icon="carbon:add-alt" />}
              onClick={() => {
                addNewDiag();
              }}
              style={{
                backgroundColor: "#37ab45",
                color: "white",
                marginBottom: "0.5rem",
                minWidth: "20%",
              }}
            >
              Add New Diagnosis
            </MuiButton>
            <table striped bordered hover>
              <thead>
                <tr>
                  <th>Diagnosis Code</th>
                  <th>Type</th>
                  <th>DX Info</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {newDiagnosisInfo && Object.keys(newDiagnosisInfo).length > 0
                  ? Object.keys(newDiagnosisInfo).map(function (code, index) {
                      return (
                        <tr>
                          <td>
                            <Form2
                              formStyle="new-modal-form"
                              key={index}
                              inputsTypes={["SamaDiagnosisList1"]}
                              elementsNames={["DiagnosisCodes"]}
                              submitStyle="BS1"
                              addICDsCode={(e) => {
                                this.addICDsCode(e, code, "diagnosis");
                              }}
                              editIndex={this.state.editIndex}
                              codeIndex={index}
                              placeholder={code}
                              onChange={this.onChange}
                              onClick={this.onClick}
                              BtnActive={this.state.BtnActive}
                              diagnosisNow={100}
                              section={this.state.section}
                              allDiagnosis={this.state.diagnosisItems}
                              userInputDiagnosis={this.state.userInputDiagnosis}
                              filteredSuggestionsDiagnosis={
                                this.state.filteredSuggestionsDiagnosis
                              }
                              showDiagnosisSuggestions={
                                this.state.showDiagnosisSuggestions
                              }
                            />
                          </td>
                          <td>
                            <select
                              name="Type"
                              style={{ width: "100%", height: "100%" }}
                              defaultValue={newDiagnosisInfo[code]["Type"]}
                              onChange={(e) => {
                                this.handleInputTextChange(
                                  e,
                                  code,
                                  "diagnosis"
                                );
                              }}
                            >
                              <option>Select Type</option>
                              <option value="Principal">Principal</option>
                              <option value="Secondary">Secondary</option>
                            </select>
                          </td>

                          {diagnosisInfo &&
                          diagnosisInfo[code] &&
                          diagnosisInfo[code]["DxInfo"] &&
                          diagnosisInfo[code]["DxInfo"].length > 0 ? (
                            <td>
                              <table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Type</th>
                                    <th>Code</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {diagnosisInfo[code] &&
                                    diagnosisInfo[code]["DxInfo"] &&
                                    diagnosisInfo[code]["DxInfo"].length > 0 &&
                                    diagnosisInfo[code]["DxInfo"].map(
                                      (info, index) => {
                                        return (
                                          <tr>
                                            <td>
                                              <input
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                }}
                                                name="Type"
                                                type="text"
                                                defaultValue={
                                                  diagnosisInfo[code]["DxInfo"][
                                                    index
                                                  ]["Type"]
                                                }
                                                onChange={(e) => {
                                                  handleInputTextChangeForDxInfo(
                                                    e,
                                                    code,
                                                    index
                                                  );
                                                }}
                                              />
                                            </td>
                                            <td>
                                              <select
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                }}
                                                name="Code"
                                                onChange={(e) => {
                                                  handleInputTextChangeForDxInfo(
                                                    e,
                                                    code,
                                                    index
                                                  );
                                                }}
                                                defaultValue={
                                                  diagnosisInfo[code]["DxInfo"][
                                                    index
                                                  ]["Code"]
                                                }
                                              >
                                                <option value="Y">Y</option>
                                                <option value="N">N</option>
                                                <option value="U">U</option>
                                                <option value="W">W</option>
                                                <option value="1">1</option>
                                                <option value="OP">OP</option>
                                              </select>
                                            </td>
                                            <td>
                                              <Tooltip title="Delete">
                                                <IconButton
                                                  onClick={() =>
                                                    this.setState({
                                                      DeleteDX: true,
                                                      DeleteDXCode: index,
                                                      CodeofDX: code,
                                                    })
                                                  }
                                                >
                                                  <Icon
                                                    color="red"
                                                    icon="fluent:delete-12-regular"
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                            </td>
                                          </tr>
                                          //
                                        );
                                      }
                                    )}
                                </tbody>
                              </table>
                            </td>
                          ) : newDiagnosisInfo &&
                            newDiagnosisInfo[code] &&
                            newDiagnosisInfo[code]["DxInfo"] &&
                            newDiagnosisInfo[code]["DxInfo"].length > 0 ? (
                            <td>
                              <table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Type</th>
                                    <th>Code</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {newDiagnosisInfo[code] &&
                                    newDiagnosisInfo[code]["DxInfo"] &&
                                    newDiagnosisInfo[code]["DxInfo"].map(
                                      (info, index) => {
                                        return (
                                          <tr>
                                            <td>
                                              <input
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                }}
                                                name="Type"
                                                type="text"
                                                defaultValue={
                                                  newDiagnosisInfo[code][
                                                    "DxInfo"
                                                  ][index]["Type"]
                                                }
                                                onChange={(e) => {
                                                  handleInputTextChangeForDxInfo(
                                                    e,
                                                    code,
                                                    index
                                                  );
                                                }}
                                              />
                                            </td>
                                            <td>
                                              <select
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                }}
                                                name="Code"
                                                onChange={(e) => {
                                                  handleInputTextChangeForDxInfo(
                                                    e,
                                                    code,
                                                    index
                                                  );
                                                }}
                                                defaultValue={
                                                  newDiagnosisInfo[code][
                                                    "DxInfo"
                                                  ][index]["Code"]
                                                }
                                              >
                                                <option value="Y">Y</option>
                                                <option value="N">N</option>
                                                <option value="U">U</option>
                                                <option value="W">W</option>
                                                <option value="1">1</option>
                                                <option value="OP">OP</option>
                                              </select>
                                            </td>
                                            <td>
                                              <Tooltip title="Delete">
                                                <IconButton
                                                  onClick={() =>
                                                    this.setState({
                                                      DeleteDX: true,
                                                      DeleteDXCode: index,
                                                      CodeofDX: code,
                                                    })
                                                  }
                                                >
                                                  <Icon
                                                    color="red"
                                                    icon="fluent:delete-12-regular"
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}{" "}
                                </tbody>
                              </table>
                            </td>
                          ) : (
                            <td></td>
                          )}
                          <td>
                            <Box
                              style={{
                                display: "flex",
                              }}
                            >
                              <Tooltip title="Add Dx">
                                <IconButton onClick={() => addNewDX(code)}>
                                  <Icon color="#37ab45" icon="carbon:add-alt" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete">
                                <IconButton
                                  onClick={() =>
                                    this.setState({
                                      DeleteDiagnosis: true,
                                      DeleteDiagnosisCode: code,
                                    })
                                  }
                                >
                                  <Icon
                                    color="red"
                                    icon="fluent:delete-12-regular"
                                  />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </td>
                        </tr>
                      );
                    }, this)
                  : diagnosisInfo &&
                    Object.keys(diagnosisInfo) &&
                    Object.keys(diagnosisInfo).map((code, index) => {
                      return (
                        <tr>
                          <td>
                            <Form2
                              key={index}
                              inputsTypes={["SamaDiagnosisList1"]}
                              elementsNames={["DiagnosisCodes"]}
                              inputStyle="IT3"
                              submitStyle="BS1"
                              addICDsCode={(e) => {
                                this.addICDsCode(e, code, "diagnosis");
                              }}
                              placeholder={code}
                              onChange={this.onChange}
                              onClick={this.onClick}
                              BtnActive={this.state.BtnActive}
                              diagnosisNow={100}
                              section={this.state.section}
                              allDiagnosis={this.state.diagnosisItems}
                              userInputDiagnosis={this.state.userInputDiagnosis}
                              filteredSuggestionsDiagnosis={
                                this.state.filteredSuggestionsDiagnosis
                              }
                              showDiagnosisSuggestions={
                                this.state.showDiagnosisSuggestions
                              }
                              editIndex={this.state.editIndex}
                              codeIndex={index}
                            />
                          </td>
                          <td>
                            <select
                              name="Type"
                              style={{ width: "100%", height: "100%" }}
                              defaultValue={diagnosisInfo[code]["Type"]}
                              onChange={(e) => {
                                this.handleInputTextChange(
                                  e,
                                  code,
                                  "diagnosis"
                                );
                              }}
                            >
                              <option>Select Type</option>
                              <option value="Principal">Principal</option>
                              <option value="Secondary">Secondary</option>
                            </select>
                          </td>

                          {diagnosisInfo &&
                          diagnosisInfo[code] &&
                          diagnosisInfo[code]["DxInfo"] &&
                          diagnosisInfo[code]["DxInfo"].length > 0 ? (
                            <td>
                              <table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Type</th>
                                    <th>Code</th>
                                    <th>Delete</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {diagnosisInfo[code] &&
                                    diagnosisInfo[code]["DxInfo"] &&
                                    diagnosisInfo[code]["DxInfo"].map(
                                      (info, index) => {
                                        /* console.log("heere");

                                      console.log(code); */
                                        return (
                                          <tr>
                                            <td>
                                              <input
                                                name="Type"
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                }}
                                                type="text"
                                                defaultValue={
                                                  diagnosisInfo[code]["DxInfo"][
                                                    index
                                                  ]["Type"]
                                                }
                                                onChange={(e) => {
                                                  handleInputTextChangeForDxInfo(
                                                    e,
                                                    code,
                                                    index
                                                  );
                                                }}
                                              />
                                            </td>
                                            <td>
                                              <select
                                                name="Code"
                                                onChange={(e) => {
                                                  handleInputTextChangeForDxInfo(
                                                    e,
                                                    code,
                                                    index
                                                  );
                                                }}
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                }}
                                                defaultValue={
                                                  diagnosisInfo[code]["DxInfo"][
                                                    index
                                                  ]["Code"]
                                                }
                                              >
                                                <option value="Y">Y</option>
                                                <option value="N">N</option>
                                                <option value="U">U</option>
                                                <option value="W">W</option>
                                                <option value="1">1</option>
                                                <option value="OP">OP</option>
                                              </select>
                                            </td>
                                            <td>
                                              <Tooltip title="Delete">
                                                <IconButton
                                                  onClick={() =>
                                                    this.setState({
                                                      DeleteDX: true,
                                                      DeleteDXCode: index,
                                                      CodeofDX: code,
                                                    })
                                                  }
                                                >
                                                  <Icon
                                                    color="red"
                                                    icon="fluent:delete-12-regular"
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                </tbody>
                              </table>
                            </td>
                          ) : newDiagnosisInfo &&
                            newDiagnosisInfo[code] &&
                            newDiagnosisInfo[code]["DxInfo"] ? (
                            <td>
                              <table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Type</th>
                                    <th>Code</th>
                                    <th>Delete</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {newDiagnosisInfo[code] &&
                                    newDiagnosisInfo[code]["DxInfo"] &&
                                    newDiagnosisInfo[code]["DxInfo"].map(
                                      function (info, index) {
                                        return (
                                          <tr>
                                            <td>
                                              <input
                                                name="Type"
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                }}
                                                type="text"
                                                defaultValue={
                                                  newDiagnosisInfo[code][
                                                    "DxInfo"
                                                  ][index]["Type"]
                                                }
                                                onChange={(e) => {
                                                  handleInputTextChangeForDxInfo(
                                                    e,
                                                    code,
                                                    index
                                                  );
                                                }}
                                              />
                                            </td>
                                            <td>
                                              <select
                                                name="Code"
                                                onChange={(e) => {
                                                  handleInputTextChangeForDxInfo(
                                                    e,
                                                    code,
                                                    index
                                                  );
                                                }}
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                }}
                                                defaultValue={
                                                  newDiagnosisInfo[code][
                                                    "DxInfo"
                                                  ][index]["Code"]
                                                }
                                              >
                                                <option value="Y">Y</option>
                                                <option value="N">N</option>
                                                <option value="U">U</option>
                                                <option value="W">W</option>
                                                <option value="1">1</option>
                                                <option value="OP">OP</option>
                                              </select>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}{" "}
                                </tbody>
                              </table>
                            </td>
                          ) : (
                            <td></td>
                          )}
                          <td>
                            <Box
                              style={{
                                display: "flex",
                              }}
                            >
                              <Tooltip title="Add Dx">
                                <IconButton onClick={() => addNewDX(code)}>
                                  <Icon color="#37ab45" icon="carbon:add-alt" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete">
                                <IconButton
                                  onClick={() =>
                                    this.setState({
                                      DeleteDiagnosis: true,
                                      DeleteDiagnosisCode: code,
                                    })
                                  }
                                >
                                  <Icon
                                    color="red"
                                    icon="fluent:delete-12-regular"
                                  />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </td>
                        </tr>
                      );
                    }, this)}
              </tbody>
            </table>{" "}
            <Typography
              style={{ textAlign: "center", marginTop: "0.3rem" }}
              variant="h6"
              gutterBottom
            >
              Activities Details
            </Typography>
            <MuiButton
              variant="contained"
              startIcon={<Icon icon="carbon:add-alt" />}
              onClick={() => {
                addNewActivity();
              }}
              style={{
                backgroundColor: "#37ab45",
                color: "white",
                marginBottom: "0.5rem",
                minWidth: "20%",
              }}
            >
              Add New Activity
            </MuiButton>
            <table striped bordered hover>
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Type</th>
                  <th>Start</th>
                  <th>Quantity</th>
                  <th>Net</th>
                  <th>Clinician</th>
                  <th>Prior Authorization ID</th>
                  <th>Observations</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {newActivitiesInfo &&
                  Object.keys(newActivitiesInfo).map(function (code, index) {
                    return (
                      <tr key={index}>
                        <td>
                          {
                            <div>
                              <Form2
                                inputsTypes={["SamaActivityList1"]}
                                elementsNames={["ActivityCodes"]}
                                inputStyle="IT3"
                                submitStyle="BS1"
                                addCPTsCode={(e) => {
                                  this.addCPTsCode(e, code, "activities");
                                }}
                                editIndex={this.state.editIndex}
                                codeIndex={index}
                                placeholder={code}
                                section={this.state.section}
                                onChange={this.onChange}
                                onClick={this.onClick}
                                BtnActive={this.state.BtnActive}
                                activeSuggestion={this.state.activeSuggestion}
                                allActivity={this.state.ActivityItems}
                                userInputActivity={this.state.userInputActivity}
                                filteredSuggestionsActivity={
                                  this.state.filteredSuggestionsActivity
                                }
                                showActivitySuggestions={
                                  this.state.showActivitySuggestions
                                }
                              />
                            </div>
                          }{" "}
                        </td>
                        <td>
                          <select
                            name="Type"
                            defaultValue={newActivitiesInfo[code]["Type"]}
                            onChange={(e) => {
                              this.handleInputTextChange(e, code, "activities");
                            }}
                          >
                            <option value="3">CPT</option>
                            <option value="4">HCPCS</option>
                            <option value="6">Dental</option>
                            <option value="5">Drug</option>
                            <option value="9">DRG</option>
                            <option value="8">Service</option>
                          </select>
                        </td>
                        <td>
                          <input
                            name="Start"
                            type="text"
                            defaultValue={newActivitiesInfo[code]["Start"]}
                            onChange={(e) => {
                              this.handleInputTextChange(e, code, "activities");
                            }}
                          />
                        </td>
                        <td>
                          <input
                            name="Quantity"
                            type="text"
                            defaultValue={newActivitiesInfo[code]["Quantity"]}
                            onChange={(e) => {
                              this.handleInputTextChange(e, code, "activities");
                            }}
                          />
                        </td>
                        <td>
                          <input
                            name="Net"
                            type="text"
                            defaultValue={newActivitiesInfo[code]["Net"]}
                            onChange={(e) => {
                              this.handleInputTextChange(e, code, "activities");
                            }}
                          />
                        </td>
                        <td>
                          <input
                            name="Clinician"
                            type="text"
                            defaultValue={newActivitiesInfo[code]["Clinician"]}
                            onChange={(e) => {
                              this.handleInputTextChange(e, code, "activities");
                            }}
                          />
                        </td>
                        <td>
                          <input
                            name="PriorAuthorizationID"
                            type="text"
                            defaultValue={
                              newActivitiesInfo[code]["PriorAuthorizationID"]
                            }
                            onChange={(e) => {
                              this.handleInputTextChange(e, code, "activities");
                            }}
                          />
                        </td>

                        {newActivitiesInfo &&
                        newActivitiesInfo[code] &&
                        newActivitiesInfo[code]["Observation"] &&
                        newActivitiesInfo[code]["Observation"].length > 0 ? (
                          <td>
                            {" "}
                            <table striped bordered hover>
                              <thead>
                                <tr>
                                  <th>Type</th>
                                  <th>Code</th>
                                  <th>Value</th>
                                  <th>ValueType</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {newActivitiesInfo &&
                                  newActivitiesInfo[code] &&
                                  newActivitiesInfo[code]["Observation"]
                                    .length > 0 &&
                                  newActivitiesInfo[code]["Observation"].map(
                                    (obs, index) => {
                                      //console.log(obs["Code"]);
                                      return (
                                        <tr>
                                          <td>
                                            {
                                              <select
                                                style={{
                                                  width: "115px !important",
                                                }}
                                                name="Type"
                                                defaultValue={obs["Type"]}
                                                onChange={(e) => {
                                                  handleInputTextChangeForObservation(
                                                    e,
                                                    code,
                                                    "activitiesObs",
                                                    index
                                                  );
                                                }}
                                              >
                                                <option value="LOINC">
                                                  LOINC
                                                </option>
                                                <option value="Text">
                                                  Text
                                                </option>
                                                <option value="File">
                                                  File
                                                </option>
                                                <option value="UniversalDental">
                                                  Universal Dental
                                                </option>
                                                <option value="Financial">
                                                  Financial
                                                </option>
                                                <option value="Grouping">
                                                  Grouping
                                                </option>
                                                <option value="ERX">ERX</option>
                                                <option value="Result">
                                                  Result
                                                </option>
                                              </select>
                                            }
                                          </td>
                                          <td>
                                            {
                                              <input
                                                name="Code"
                                                type="text"
                                                defaultValue={obs["Code"]}
                                                onChange={(e) => {
                                                  handleInputTextChangeForObservation(
                                                    e,
                                                    code,
                                                    "activitiesObs",
                                                    index
                                                  );
                                                }}
                                                disabled={
                                                  obs["Type"] == "File"
                                                    ? "disabled"
                                                    : ""
                                                }
                                              />
                                            }
                                          </td>
                                          <td>
                                            <input
                                              name="Value"
                                              type="text"
                                              defaultValue={
                                                obs["Type"] == "File"
                                                  ? "encoding64 string"
                                                  : obs["Value"]
                                              }
                                              onChange={(e) => {
                                                handleInputTextChangeForObservation(
                                                  e,
                                                  code,
                                                  "activitiesObs",
                                                  index
                                                );
                                              }}
                                              disabled={
                                                obs["Type"] == "File"
                                                  ? "disabled"
                                                  : ""
                                              }
                                            />
                                            {obs["Type"] == "File" ? (
                                              <input
                                                type="file"
                                                name="file"
                                                onChange={(e) => {
                                                  handleInputTextChangeForObservation(
                                                    e,
                                                    code,
                                                    "activitiesObs",
                                                    index
                                                  );
                                                }}
                                              />
                                            ) : null}
                                          </td>
                                          <td>
                                            {
                                              <input
                                                name="ValueType"
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                }}
                                                type="text"
                                                defaultValue={
                                                  obs["Type"] == "File"
                                                    ? "pdf"
                                                    : obs["ValueType"]
                                                }
                                                onChange={(e) => {
                                                  handleInputTextChangeForObservation(
                                                    e,
                                                    code,
                                                    "activitiesObs",
                                                    index
                                                  );
                                                }}
                                                disabled={
                                                  obs["Type"] == "File"
                                                    ? "disabled"
                                                    : ""
                                                }
                                              />
                                            }
                                          </td>
                                          <td>
                                            <Tooltip title="Delete">
                                              <IconButton
                                                onClick={() =>
                                                  this.setState({
                                                    DeleteOBS: true,
                                                    DeleteObsCode: code,
                                                    DeleteObsindex: index,
                                                  })
                                                }
                                              >
                                                <Icon
                                                  color="red"
                                                  icon="fluent:delete-12-regular"
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </table>
                          </td>
                        ) : (
                          <td></td>
                        )}
                        <td>
                          <Box
                            style={{
                              display: "flex",
                            }}
                          >
                            <Tooltip title="Add Obs">
                              <IconButton
                                onClick={() => {
                                  addNewObservation(code);
                                }}
                              >
                                <Icon color="#37ab45" icon="carbon:add-alt" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={() =>
                                  this.setState({
                                    DeleteActivity: true,
                                    DeleteActivityCode: code,
                                  })
                                }
                              >
                                <Icon
                                  color="red"
                                  icon="fluent:delete-12-regular"
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </td>
                      </tr>
                    );
                  }, this)}
              </tbody>
            </table>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1.5rem",
                marginBottom: "1rem",
              }}
            >
              <MuiButton
                variant="contained"
                size="large"
                style={{ backgroundColor: "red", color: "white" }}
                onClick={this.changeEditMode1}
              >
                Cancel
              </MuiButton>
              <MuiButton
                variant="contained"
                size="large"
                style={{ backgroundColor: "#37ab45", color: "white" }}
                onClick={this.update.bind(this)}
              >
                Save
              </MuiButton>
            </Box>
          </Box>
        </FormModal>
        <FormModal
          title="Add new Observation"
          open={addNewObsScreen}
          maxWidth="sm"
          onClose={() =>
            this.setState({
              addNewObsScreen: false,
              observationType: undefined,
            })
          }
        >
          <Grid container style={{ width: "80%", margin: "auto" }} spacing={2}>
            <Grid item xs={12}>
              <SimpleSelect
                name="Type"
                onChange={this.handleInputTextChangeForAddNewObservation}
                label="Type"
              >
                <MenuItem value="LOINC">LOINC</MenuItem>
                <MenuItem value="Text">Text</MenuItem>
                <MenuItem value="File">File</MenuItem>
                <MenuItem value="UniversalDental">Universal Dental</MenuItem>
                <MenuItem value="Financial">Financial</MenuItem>
                <MenuItem value="Grouping">Grouping</MenuItem>
                <MenuItem value="ERX">ERX</MenuItem>
                <MenuItem value="Result">Result</MenuItem>
              </SimpleSelect>
            </Grid>
            {this.state.observationType &&
            this.state.observationType == "File" ? (
              <Grid item xs={12}>
                <MuiButton
                  component="label"
                  variant="outlined"
                  startIcon={
                    <Icon icon="material-symbols:upload-file-outline-rounded" />
                  }
                >
                  Upload File
                  <input
                    type="file"
                    name="file"
                    onChange={(e) => {
                      this.handleInputTextChangeForAddNewObservation(e);
                    }}
                  />
                </MuiButton>
              </Grid>
            ) : null}

            {!this.state.observationType ? (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Code"
                  name="Code"
                  onChange={(e) => {
                    this.handleInputTextChangeForAddNewObservation(e);
                  }}
                />
              </Grid>
            ) : null}

            {!this.state.observationType ? (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Value"
                  name="Value"
                  onChange={(e) => {
                    this.handleInputTextChangeForAddNewObservation(e);
                  }}
                />
              </Grid>
            ) : null}

            {!this.state.observationType ? (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="ValueType"
                  name="ValueType"
                  onChange={(e) => {
                    this.handleInputTextChangeForAddNewObservation(e);
                  }}
                />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <MuiButton
                  variant="contained"
                  onClick={() => {
                    this.confirmAddNewObs();
                  }}
                  style={{
                    backgroundColor: "#37ab45",
                    color: "white",
                    marginTop: "0.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  Confirm
                </MuiButton>
              </Box>
            </Grid>
          </Grid>
        </FormModal>
        <FormModal
          title="Add new Dx"
          open={addNewDXScreen}
          maxWidth="sm"
          onClose={() => this.setState({ addNewDXScreen: false })}
        >
          <Grid container style={{ width: "80%", margin: "auto" }} spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Type"
                name="Type"
                onChange={(e) => {
                  this.handleInputTextChangeForAddNewDX(e);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <SimpleSelect
                name="Code"
                onChange={(e) => {
                  handleInputTextChangeForAddNewDX(e);
                }}
                label="Code"
              >
                <MenuItem value="Y">Y</MenuItem>
                <MenuItem value="N">N</MenuItem>
                <MenuItem value="U">U</MenuItem>
                <MenuItem value="W">W</MenuItem>
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="OP">OP</MenuItem>
              </SimpleSelect>
            </Grid>

            <Grid item xs={12}>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <MuiButton
                  variant="contained"
                  onClick={() => {
                    this.confirmAddNewDX();
                  }}
                  style={{
                    backgroundColor: "#37ab45",
                    color: "white",
                    marginTop: "0.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  Confirm
                </MuiButton>
              </Box>
            </Grid>
          </Grid>
        </FormModal>{" "}
        <FormModal
          title="Add New Diagnosis"
          open={addNewDiagScreen}
          maxWidth="sm"
          onClose={() =>
            this.setState({ addNewDiagScreen: false, DiagnosisCodes: [] })
          }
        >
          <Form
            formStyle="new-modal-form"
            onChange={this.onChange}
            onClick={this.onClick}
            onKeyDown={this.onKeyDown}
            allDiagnosis={this.state.diagnosisItems}
            userInputDiagnosis={this.state.userInputDiagnosis}
            filteredSuggestionsDiagnosis={
              this.state.filteredSuggestionsDiagnosis
            }
            showDiagnosisSuggestions={this.state.showDiagnosisSuggestions}
            Style="Search"
            inputStyle="IT3"
            submitStyle="BS1"
            linkStyle="Link2"
            labelStyle="BayanLabelStyle"
            onSubmit={this.confirmAddNewDiagnoses}
            onInputTextChange={this.handleInputTextChange}
            onAddNewCodeInputChange={this.onAddNewCodeInputChange}
            onSelectOption={this.onSelectOption}
            addNewCode={this.addNewCode}
            BtnActive={this.state.BtnActive}
            //isPrincipalChecked={isPrincipalChecked}
            section={this.state.section}
            attributes={this.state.attributes}
            diagnosisNow={this.state.diagnosisNow}
            inputsTypes={["list"]}
            elementsValues={[this.state.DiagnosisCodes, "Confirm"]}
            elementsNames={["DiagnosisCodes"]}
            handleItemDeletion={this.handleItemDeletion}
            SubmitButtonComponent={
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <div>
                  <MuiButton
                    size="large"
                    color="success"
                    variant="contained"
                    onClick={this.confirmAddNewDiagnoses}
                    style={{
                      backgroundColor: "#3ab449",
                      color: "white",
                    }}
                  >
                    Confirm
                  </MuiButton>
                </div>
              </Box>
            }

            // inputsHaveErrors={inputsHaveErrors}
          />
        </FormModal>
        <FormModal
          title="Add New Activity"
          open={addNewActivityScreen}
          maxWidth="sm"
          onClose={() =>
            this.setState({
              addNewActivityScreen: false,
              ActivityCodes: [],
            })
          }
        >
          {" "}
          <Form
            formStyle="new-modal-form"
            onChange={this.onChange}
            onClick={this.onClick}
            onKeyDown={this.onKeyDown}
            allActivity={this.state.ActivityItems}
            userInputActivity={this.state.userInputActivity}
            activeSuggestion={this.state.activeSuggestion}
            filteredSuggestionsActivity={this.state.filteredSuggestionsActivity}
            attributes={this.state.attributes}
            showActivitySuggestions={this.state.showActivitySuggestions}
            handleItemDeletion={this.handleItemDeletion}
            Style="Search"
            inputStyle="IT3"
            submitStyle="BS1"
            onSubmit={this.confirmAddNewActivity}
            //onInputTextChange={this.handleInputTextChange}
            onInputTextChange={this.handleInputTextChangeForAddNewActivity}
            onAddNewCodeInputChange={this.onAddNewCodeInputChange}
            onSelectOption={this.onSelectOption}
            addNewCode={this.addNewCode}
            BtnActive={this.state.BtnActive}
            section={this.state.section}
            ActivityNow={this.state.ActivityNow}
            inputsTypes={["ActivityList"]}
            elementsValues={[this.state.ActivityCodes, "Confirm"]}
            elementsNames={["ActivityCodes"]}
            SubmitButtonComponent={
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <div>
                  <MuiButton
                    size="large"
                    color="success"
                    variant="contained"
                    onClick={this.confirmAddNewActivity}
                    style={{
                      backgroundColor: "#3ab449",
                      color: "white",
                    }}
                  >
                    Confirm
                  </MuiButton>
                </div>
              </Box>
            }
          />
        </FormModal>
      </>
    );
  };
  validateOnSama = async () => {
    //let { localTesting } = privilegesJson;
    //if (localTesting)
    //{
    this.setState({
      samaValidationFlag: true,
      dhpoValidationErrorMessage: NaN,
    });
    let { EncounterInfo } = this.state;
    let { claim_status, id } = this.state.EncounterInfo;
    let new_claim_status = "";

    this.setState({
      dhpoResponseMessage: NaN,
      markAsDHPOValidatedResponseMSG: NaN,
      dhpoRows: NaN,
      SamaRows: NaN,
      validationOnSAMA: true,
    });

    let XML;

    if (this.state.bulkValidationXML) {
      XML = this.state.bulkValidationXML;
    } else {
      XML = await this.jsonToXML();
    }

    const data = await axios.post(KayanURL + "api/sama/request/", {
      data: XML,
      apiToken: Cookies.get("SamaId"),
    });

    if (data && !data.data.success && data.data.type == "privilegesError") {
      this.setState({ stopBulkValidation: true });
      alert(data.data.error);
    } else if (data) {
      let samaResponseXML = data.data;
      let Records = [];
      parseString(samaResponseXML, function (err, result) {
        if (result) {
          let Response_Status = result["Response-Status"];
          let { Status } = Response_Status;

          if (Status && Status.length > 0 && Status[0] == "Failed") {
            let Request_Errors = Response_Status["Request-Errors"][0]["Claim"];
            //let Records = []
            Request_Errors.forEach((claimErrors) => {
              let EncounterId = claimErrors["EncounterId"][0];
              let Line = claimErrors["Line"][0];

              let SchemaErrorsArray = claimErrors["Schema-Errors"];

              if (SchemaErrorsArray != undefined) {
                let SchemaErrors = SchemaErrorsArray[0];

                let schemaErrorsTypes = Object.keys(SchemaErrors);
                schemaErrorsTypes.forEach((type) => {
                  let ErrorsofThisType = SchemaErrors[type]; //array
                  ErrorsofThisType.forEach((err) => {
                    let ClaimId = err["ClaimId"][0];
                    let errorLine = err["Line"][0];
                    let Message = err["Message"][0];
                    let Type = "-";
                    let Level = "-";

                    Records.push({
                      ClaimId,
                      EncounterId,
                      claimLine: Line,
                      errorLine,
                      Message,
                      Type,
                      Level,
                    });
                  });
                });
              }

              let DataErrorsArray = claimErrors["Data-Errors"];

              if (DataErrorsArray) {
                let DataErrors = DataErrorsArray[0];

                if (DataErrors) {
                  let DataErrorsCodes = DataErrors["Code"];
                  if (DataErrorsCodes) {
                    DataErrorsCodes.forEach((err) => {
                      let ClaimId = err["ClaimID"] ? err["ClaimID"][0] : "-";
                      let errorLine = err["Line"] ? err["Line"][0] : "-";
                      let Message = err["Message"] ? err["Message"][0] : "-";
                      let Type = err["Type"] ? err["Type"][0] : "-";
                      let Level = err["Level"] ? err["Level"][0] : "-";
                      let ActivityCode = err["ActivityCode"]
                        ? err["ActivityCode"][0]
                        : "-";

                      Records.push({
                        ClaimId,
                        EncounterId,
                        claimLine: Line,
                        errorLine,
                        ActivityCode,
                        Message,
                        Type,
                        Level,
                      });
                    });
                  }
                }
              }
            });
          }
        }
      });

      this.setState({
        SamaRows: Records,
        SamaResponse: true,
        samaValidationFlag: false,
      });
      if (Records.length == 0) {
        //free of edits ===> sama validated
        this.setState({
          samaResponseMessage: "Claim validated successfully on SAMA",
        });
        new_claim_status = "1";
      } else {
        //sama validated with issue
        new_claim_status = "14";
      }
      let { timeline } = EncounterInfo;

      /*  if (timeline && timeline.length > 0) {
        timeline = JSON.parse(timeline);
      } else */ if (!timeline) {
        timeline = [];
      } else if (timeline && !Array.isArray(timeline)) {
        console.log("timeline");
        console.log(timeline);
        timeline = JSON.parse(timeline);
        timeline.push({ [new_claim_status]: this.generateCurrentDate() });
      }

      //update the status of the claim
      if (new_claim_status != claim_status) {
        let response = await axios.put(KayanURL + "api/sama/update", {
          apiToken: Cookies.get("SamaId"),
          id: id,
          timeline: JSON.stringify(timeline),
          claim_id: EncounterInfo.claim_id,
          claim_status: new_claim_status,
          prev_claim_status: claim_status,
        });
        EncounterInfo["timeline"] = timeline;
        EncounterInfo["claim_status"] = new_claim_status;
      }

      //
      let { bulkValidationRecords } = this.state;
      if (this.state.bulkValidationXML) {
        Records.forEach((r) => {
          bulkValidationRecords.push(r);
        });
        this.setState({ bulkValidationRecords });
      }
    }
  };

  jsonToXML() {
    let { EncounterInfo } = this.state;
    let DateTime = moment().format();
    let Date = DateTime.split("T")[0];
    let Time = DateTime.split("T")[1];
    let TimeArray = (Time = Time.split("+")[0]);
    let TimeHMS = TimeArray.split(":");
    let TimeString = TimeHMS[0] + ":" + TimeHMS[1];
    let DateArray = Date.split("-"); //yyyy mm dd

    let DateString = DateArray[2] + "/" + DateArray[1] + "/" + DateArray[0]; //dd mm yyyy
    let FinalDateTimeString = DateString + " " + TimeString; // dd/mm/yyyy hh:mm

    let jsonObj = {
      "Claim.Submission": [
        {
          Header: [
            { SenderID: EncounterInfo["sender_id"] },
            { ReceiverID: EncounterInfo["receiver_id"] },
            {
              TransactionDate: FinalDateTimeString,
            },
            { RecordCount: "1" },
            { DispositionFlag: "TEST" },
          ],
        },
      ],
    };

    let Encounter = {
      Encounter: [
        { FacilityID: EncounterInfo["enc_facility_id"] },
        { Type: EncounterInfo["enc_type"] },
        { PatientID: EncounterInfo["enc_patient_id"] },
        { Start: EncounterInfo["enc_start"] },
        { End: EncounterInfo["enc_end"] },
        { StartType: EncounterInfo["start_type"] },
        { TransferSource: EncounterInfo["transfer_source"] },
        { TransferDestination: EncounterInfo["transfer_destination"] },
      ],
    };

    let claimID = { ID: EncounterInfo["claim_id"] };
    let MemberID = { MemberID: EncounterInfo["member_id"] };
    let PayerID = { PayerID: EncounterInfo["payer_id"] };
    let ProviderID = {
        ProviderID: EncounterInfo["provider_id"],
      },
      EmiratesIDNumber = {
        EmiratesIDNumber: EncounterInfo["emirates_id_number"],
      };
    let Gross = { Gross: EncounterInfo["gross"] },
      PatientShare = {
        PatientShare: EncounterInfo["patient_share"],
      },
      Net = { Net: EncounterInfo["net"] };

    let claimDetails = [
      claimID,
      MemberID,
      PayerID,
      ProviderID,
      EmiratesIDNumber,
      Gross,
      PatientShare,
      Net,
      Encounter,
    ];
    let { diagnosisInfo, activitiesInfo } = this.state;

    let diagnosisCodes = Object.keys(diagnosisInfo);

    diagnosisCodes.forEach((code) => {
      let Diagnosis = {
        Diagnosis: [
          { Type: diagnosisInfo[code]["Type"] },
          { Code: code },
          //{ Desc: diagnosisInfo[code]["Desc"] },
        ],
      };

      claimDetails.push(Diagnosis);
    });
    let activityCodes = Object.keys(activitiesInfo);
    activityCodes.forEach((code) => {
      //console.log("code", activitiesInfo[code]);
      let Activity = {
        Activity: [
          { ID: activitiesInfo[code]["ID"] },
          { Start: activitiesInfo[code]["Start"] },
          { Type: activitiesInfo[code]["Type"] },
          { Code: code },
          { Quantity: activitiesInfo[code]["Quantity"] },
          { Net: activitiesInfo[code]["Net"] },
          { Clinician: activitiesInfo[code]["Clinician"] },
          {
            PriorAuthorizationID: activitiesInfo[code]["PriorAuthorizationID"],
          },
        ],
      };
      //let Observation=[]
      if (activitiesInfo[code]["Observation"]) {
        activitiesInfo[code]["Observation"].forEach((Obs) => {
          Activity["Activity"].push({
            Observation: [
              Obs["ValueType"] ? { ValueType: Obs["ValueType"] } : {},

              Obs["Value"] ? { Value: Obs["Value"] } : {},
              Obs["Code"] ? { Code: Obs["Code"] } : {},
              Obs["Type"] ? { Type: Obs["Type"] } : {},
            ],
          });
        });
      }

      claimDetails.push(Activity);
    });

    jsonObj["Claim.Submission"].push({ Claim: claimDetails });
    let XML = xml(jsonObj, true);

    return XML;
  }

  onValidateDHPO = async (event) => {
    this.setState({
      samaResponseMessage: undefined,
      markAsSAMAValidatedResponseMSG: NaN,
      SamaRows: NaN,
      dhpoValidationStart: true,
      dhpoValidationErrorMessage: NaN,
      dhpoResponseMessage: NaN,
    });
    let XML = await this.jsonToXML();
    let { claim_status } = this.state.EncounterInfo;
    if (claim_status != "3" && claim_status != "9" && claim_status != "2") {
      const data = await axios.post(
        KayanURL + "api/sama/request/dhpoValidation/",
        {
          data: XML,
          claim_id: this.state.EncounterInfo.claim_id,
          apiToken: Cookies.get("SamaId"),
          filename: `${this.state.EncounterInfo.claim_id}.xml`,
          functionType: "dhpoValidation",
        }
      );
      let response = data["data"];

      if (response) {
        this.setState({ dhpoValidationStart: false });
        if (response.errorMessage && response.UploadTransactionResult != 0) {
          this.setState({
            dhpoValidationErrorMessage: response.errorMessage,
            dhpoResponse: true,
            sent: false,
          });
        } else if (response.UploadTransactionResult == 0) {
          this.setState({
            dhpoResponseMessage: "Claim validated successfully on DHPO",
            dhpoResponse: true,
            sent: false,
          });
        } else {
          this.setState({
            dhpoRows: response.split("\n"),
            dhpoResponse: true,
            sent: false,
          });
        }
      }
    } else {
      this.setState({ dhpoValidationStart: false });
      alert("The claim alreadey validated");
    }
  };

  markAsDHPOValidated = async () => {
    let { recordId } = this.state;
    let { EncounterInfo, AllClaims } = this.state;
    let { claim_status } = EncounterInfo;
    let new_claim_status;
    //if claim status = 0 (new claim) then update the status to 2 (vlaidated on DHPO Successfully)
    if (claim_status == "0") {
      new_claim_status = "2";
    } else if (claim_status == "1") {
      //if claim status = 1 (validated on SAMA successfully) then then update the status to 3 (vlaidated on DHPO and SAMA Successfully)
      new_claim_status = "3";
    }

    let response = await axios.put(KayanURL + "api/sama/update", {
      apiToken: Cookies.get("SamaId"),
      id: recordId,
      claim_id: EncounterInfo.claim_id,
      claim_status: new_claim_status,
      prev_claim_status: claim_status,
    });

    if (response) {
      let { data } = response;
      if (data.success) {
        EncounterInfo["prev_claim_status"] = claim_status;

        let index;
        let claim = AllClaims.filter(function (c, i) {
          if (c.id == recordId) {
            index = i;
            return c;
          }
        });

        AllClaims[index]["claim_status"] = new_claim_status;
        AllClaims[index]["prev_claim_status"] = claim_status;
        this.setState({ AllClaims });
        this.setState({
          markAsDHPOValidatedResponseMSG: `The status of this claim updated to ${mappingClaimStatus(
            new_claim_status
          )}`,
          EncounterInfo,
        });
      } else {
        this.setState({ markAsDHPOValidatedResponseMSG: "failed" });
      }
    }
  };

  markAsSAMAValidated = async () => {
    let { recordId } = this.state;
    let { EncounterInfo, AllClaims } = this.state;
    let { claim_status } = EncounterInfo;
    let new_claim_status;

    //if claim status = 0 (new claim) , then update status to 1 (validated on sama successfully)
    if (claim_status == "0") {
      new_claim_status = "1";
    } else if (claim_status == "2") {
      //if claim status=2 (validated on DHPO) then update status to 3 (validated on DHPO and SAMA successfully)
      new_claim_status = "3";
    }
    let response = await axios.put(KayanURL + "api/sama/update", {
      apiToken: Cookies.get("SamaId"),
      id: recordId,
      claim_status: new_claim_status,
      claim_id: EncounterInfo.claim_id,
      prev_claim_status: claim_status,
    });

    if (response) {
      let { data } = response;
      if (data.success) {
        EncounterInfo["claim_status"] = new_claim_status;
        EncounterInfo["prev_claim_status"] = claim_status;
        this.setState({ EncounterInfo });
        this.setState({
          markAsSAMAValidatedResponseMSG: `The status of this claim updated to ${mappingClaimStatus(
            new_claim_status
          )}`,
          claim_status,
        });

        let index;
        let claim = AllClaims.filter(function (c, i) {
          if (c.id == recordId) {
            index = i;
            return c;
          }
        });

        AllClaims[index]["claim_status"] = new_claim_status;
        AllClaims[index]["prev_claim_status"] = claim_status;
        this.setState({ AllClaims });
      } else {
        this.setState({ markAsSAMAValidatedResponseMSG: "failed" });
      }
    }
  };

  generateCurrentDate() {
    let date_ob = new Date();
    let date = ("0" + date_ob.getDate()).slice(-2);

    // current month
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

    // current year
    let year = date_ob.getFullYear();

    // current hours
    let hours = date_ob.getHours();

    // current minutes
    let minutes = date_ob.getMinutes();

    // prints date & time in YYYY-MM-DD HH:MM:SS format

    let dateFormat =
      date + "/" + month + "/" + year + " " + hours + ":" + minutes;

    return dateFormat;
  }

  markAsReadyToBeSubmitted = async () => {
    this.setState({
      dhpoResponse: NaN,
      SamaResponse: NaN,
      SamaRows: NaN,
      dhpoRows: NaN,
      markAsDHPOValidatedResponseMSG: NaN,
      markAsSAMAValidatedResponseMSG: NaN,
    });
    let { recordId, isContinue, EncounterInfo, AllClaims } = this.state;
    let { claim_status } = this.state.EncounterInfo;
    let { timeline } = this.state.EncounterInfo;
    if (claim_status != "3" && !isContinue && claim_status != "9") {
      this.setState({
        alertMessage:
          "The claim was not validated on SAMA and DHPO. It may contains a validation error. If you want to continue",
      });
    } else if (claim_status == "9") {
      alert("The claim already marked as ready to be submitted");
    } /* if (isContinue) */ else {
      let date_ob = new Date();
      let date = ("0" + date_ob.getDate()).slice(-2);

      // current month
      let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

      // current year
      let year = date_ob.getFullYear();

      // current hours
      let hours = date_ob.getHours();

      // current minutes
      let minutes = date_ob.getMinutes();

      // prints date & time in YYYY-MM-DD HH:MM:SS format

      let dateFormat =
        date + "/" + month + "/" + year + " " + hours + ":" + minutes;

      timeline.push({ 9: dateFormat });

      let response = await axios.put(KayanURL + "api/sama/update", {
        apiToken: Cookies.get("SamaId"),
        id: recordId,
        claim_status: "9",
        claim_id: EncounterInfo.claim_id,
        prev_claim_status: claim_status,
        timeline: JSON.stringify(timeline),
        functionType: "markReadyToBeSubmitted",
      });

      if (response) {
        //  console.log(response);
        let { data } = response;
        this.setState({ alertMessage: NaN });
        if (data && data.success) {
          this.setState({
            markAsReadyToBeSubmittedMsg: "The claim is ready to be submitted",
          });
          EncounterInfo["claim_status"] = 9;
          EncounterInfo["prev_claim_status"] = claim_status;
          EncounterInfo["timeline"] = timeline;
          this.setState({ EncounterInfo });
          let index;
          let claim = AllClaims.filter(function (c, i) {
            if (c.id == recordId) {
              index = i;
              return c;
            }
          });

          AllClaims[index]["claim_status"] = 9;
          AllClaims[index]["prev_claim_status"] = claim_status;
          this.setState({ AllClaims });
        } else {
          alert(data.error);
        }
      }
    }
    this.setState({ isContinue: false });
  };

  markWithDenied = async () => {
    let { recordId } = this.state;
    //let { claim_status } = this.state.EncounterInfo;
    let { EncounterInfo } = this.state;
    let { claim_status } = EncounterInfo;
    let newStatus;
    if (claim_status == "7") {
      //partially paid
      newStatus = "12";
    } else if (claim_status == "8") {
      //fully rejected
      newStatus = "13";
    }
    let { timeline } = EncounterInfo;

    let currentDate = this.generateCurrentDate();
    timeline.push({ [newStatus]: currentDate });
    EncounterInfo["timeline"] = timeline;
    this.setState({ EncounterInfo });

    //update the claim status
    let response = await axios.put(KayanURL + "api/sama/update", {
      apiToken: Cookies.get("SamaId"),
      id: recordId,
      claim_status: newStatus,
      claim_id: this.state.EncounterInfo.claim_id,
      timeline: JSON.stringify(timeline),
      prev_claim_status: claim_status,
      functionType: "markwithdenied",
    });
    let { data } = response;
    if (data && data.success) {
      this.setState({
        markWithDeniedResponseMsg: "The claim marked with denied successfully",
      });
    } else if (data && !data.success && data.type == "privilegesError") {
      alert(data.error);
    }
  };

  clickHere = async () => {
    await this.setState({ isContinue: true });
    this.markAsReadyToBeSubmitted();
  };

  mappingActivityType(type) {
    let typeText = "";

    switch (type) {
      case 3:
      case "3":
        typeText = "CPT";
        break;
      case 4:
      case "4":
        typeText = "HCPCS";
        break;
      case 6:
      case "6":
        typeText = "Dental";
        break;
      case 5:
      case "5":
        typeText = "Drug";
        break;
      case 9:
      case "9":
        typeText = "DRG";
        break;
      case 8:
      case "8":
        typeText = "Service";
        break;

      default:
        break;
    }

    return typeText;
  }

  mappingEncType(type) {
    let EncounterType;
    switch (type) {
      case 1:
        EncounterType = "No Bed + No emergency room";
        break;
      case 2:
        EncounterType = "No Bed + Emergency room";
        break;
      case 3:
        EncounterType = "Inpatient Bed + No emergency room";
        break;
      case 4:
        EncounterType = "Inpatient Bed + Emergency room";
        break;
      case 5:
        EncounterType = "Daycase Bed + No emergency room";
        break;
      case 6:
        EncounterType = "Daycase Bed + Emergency room";
        break;
      case 7:
        EncounterType = "Nationals Screening";
        break;
      case 8:
        EncounterType = "New Visa Screening";
        break;
      case 9:
        EncounterType = "Renewal Visa Screening";
        break;
      case 12:
        EncounterType = "Home";
        break;
      case 13:
        EncounterType = "Assisted Living Facility";
        break;
      case 15:
        EncounterType = "Mobile Unit";
        break;

      case 41:
        EncounterType = "Ambulance - Land";
        break;

      case 42:
        EncounterType = " Ambulance - Air or Water";
        break;

      default:
        break;
    }

    //console.log(EncounterType);
    return EncounterType;
    // this.setState({ EncounterType });
  }
  mappingEncStartType(type) {
    let EncounterType;

    switch (type) {
      case 1:
      case "1":
        EncounterType = "Elective";
        break;
      case 2:
      case "2":
        EncounterType = "Emergency";
        break;
      case 3:
      case "3":
        EncounterType = "Transfer";
        break;
      case 4:
      case "4":
        EncounterType = "Live birth";
        break;
      case 5:
      case "5":
        EncounterType = "Still birth";
        break;
      case 6:
      case "6":
        EncounterType = "Dead On Arrival";
        break;
      case 7:
      case "7":
        EncounterType = "Continuing Encounter";
        break;

      default:
        break;
    }
    /* console.log(EncounterType); */
    return EncounterType;
  }

  mappingEncEndType(type) {
    let EncounterType;

    switch (type) {
      case 1:
      case "1":
        EncounterType = "Discharged with approval";
        break;
      case 2:
      case "2":
        EncounterType = "Discharged against advice";
        break;
      case 3:
      case "3":
        EncounterType = "Discharged absent without leave";
        break;
      case 4:
      case "4":
        EncounterType = "Discharge transfer to acute care";
        break;
      case 5:
      case "5":
        EncounterType = "Deceased";
        break;
      case 6:
      case "6":
        EncounterType = "Not discharged";
        break;
      case 7:
      case "7":
        EncounterType =
          "Discharge transfer to non-acute care(Transfer to long term care).";
        break;

      default:
        break;
    }

    return EncounterType;
  }

  addNewObservation = (code) => {
    this.setState({
      addNewObsScreen: true,
      codeToAddObs: code,
      confirmed: false,
    });
  };

  confirmAddNewObs = () => {
    let {
      codeToAddObs,
      observationObj,
      activitiesInfo,
      fieldsToEdit,
      newActivitiesInfo,
    } = this.state;
    let activities; /* = cloneDeep(this.state.activitiesInfo); */
    let codeObservation; /*  = activitiesInfo[[codeToAddObs]]["Observation"]; */
    if (newActivitiesInfo) {
      activities = cloneDeep(this.state.newActivitiesInfo);
      codeObservation = newActivitiesInfo[[codeToAddObs]]["Observation"];
    } else {
      activities = cloneDeep(this.state.activitiesInfo);
      codeObservation = activitiesInfo[[codeToAddObs]]["Observation"];
    }

    if (!codeObservation) {
      codeObservation = [];

      codeObservation.push(observationObj[codeToAddObs]);

      let newActivitiesInfo = activities;

      newActivitiesInfo[[codeToAddObs]]["Observation"] = codeObservation;

      fieldsToEdit["activities"] = newActivitiesInfo;

      this.setState({
        fieldsToEdit,

        addNewObsScreen: false,
        newActivitiesInfo,
        confirmed: true,
      });
    } else {
      codeObservation.push(observationObj[codeToAddObs]);
      let newActivitiesInfo = activities;
      newActivitiesInfo[[codeToAddObs]]["Observation"] = codeObservation;
      fieldsToEdit["activities"] = newActivitiesInfo;

      this.setState({
        fieldsToEdit,

        addNewObsScreen: false,
        newActivitiesInfo,
        confirmed: true,
        observationType: undefined,
      });
    }
  };

  markAsRessubmission = () => {
    this.setState({ typeOfResubmissionScreen: true });
  };
  handleSelectResubmissionType = (e) => {
    this.setState({ typeOfResubmission: e.target.value });
  };

  markAsResubmissionClaim = async () => {
    let { typeOfResubmission, recordId, EncounterInfo, fieldsToEdit } =
      this.state;
    let { claim_status, timeline } = EncounterInfo;

    let new_claim_status;
    switch (typeOfResubmission) {
      case "Correction":
        new_claim_status = 10;
        break;

      case "Internal Complaint":
        new_claim_status = 11;
        break;

      default:
        break;
    }

    let currentDate = this.generateCurrentDate();
    timeline.push({ [new_claim_status]: currentDate });
    EncounterInfo["timeline"] = timeline;
    //update the status of the claim
    if (new_claim_status) {
      //axios
      let response = await axios.put(KayanURL + "api/sama/update", {
        apiToken: Cookies.get("SamaId"),
        id: recordId,
        claim_status: new_claim_status,
        claim_id: EncounterInfo.claim_id,
        timeline: JSON.stringify(timeline),
        prev_claim_status: claim_status,
        functionType: "markForResubmission",
      });
      let { data } = response;

      if (data && data.success) {
        //  console.log(data);
        this.setState({ markAsResubmissionSuccess: true });
      } else if (data && !data.success && data.type == "privilegesError") {
        this.setState({ typeOfResubmissionScreen: false });
        alert(data.error);
      } else {
        this.setState({ markAsRessubmissionError: data.error[0] });
      }
    } else {
      alert("Select Resubmission Type");
    }
  };
  onClickReverse = async (recordId) => {
    let { AllClaims } = this.state;
    //console.log(" AllClaims ");
    //console.log(AllClaims);
    let index;
    let claim = AllClaims.filter(function (c, i) {
      if (c.id == recordId) {
        index = i;
        return c;
      }
    });

    if (claim && claim.length > 0) {
      let { prev_claim_status, claim_status } = claim[0];

      let response = await axios.put(KayanURL + "api/sama/update", {
        apiToken: Cookies.get("SamaId"),
        id: recordId,
        prev_claim_status: claim_status,
        claim_id: this.state.EncounterInfo.claim_id,
        claim_status: prev_claim_status != null ? prev_claim_status : 0,
      });

      if (response) {
        let { data } = response;
        if (data.success) {
          AllClaims[index]["claim_status"] =
            prev_claim_status != null ? prev_claim_status : 0;
          AllClaims[index]["prev_claim_status"] = claim_status;
          this.setState({ AllClaims });
        }
      }
    }
  };

  onScelectPayerName({ target: { name, value } }) {
    this.setState({ payer_id: value });
  }

  handleClaimIdChange = (e) => {
    this.setState({ claim_id: e.target.value });
  };

  handleFacilityIdChange = (e) => {
    this.setState({ facilityId: e.target.value });
  };

  handleSelectPriceChangeChk = async (e, selected) => {
    let { selectedCodePrice } = this.state;

    if (e.target.checked) {
      //filter the array
      let ele = selectedCodePrice.filter((c) => c.ID == selected.ID);

      if (ele.length == 0) {
        selectedCodePrice.push(selected);
      }
    } else {
      selectedCodePrice.forEach((element, index) => {
        if (element.ID == selected.ID) {
          selectedCodePrice.splice(index, 1);
        }
      });
    }

    this.setState({ selectedCodePrice });
  };

  bulkValidation = async (selectedClaims) => {
    this.setState({
      bulkValidationScreen: true,
      bulkValidationFinished: false,
    });

    for (let index = 0; index < selectedClaims.length; index++) {
      const selectedClaim = selectedClaims[index];
      let EncounterInfo = selectedClaim;
      let { diagnosis, activities } = selectedClaim;
      await this.setState({
        EncounterInfo,
        diagnosisInfo: diagnosis,
        activitiesInfo: activities,
      });
      let XML = await this.jsonToXML();
      this.setState({ bulkValidationXML: XML });
      await this.validateOnSama();
      if (this.state.stopBulkValidation) {
        this.setState({
          bulkValidationScreen: false,
        });
        break;
      }
    }

    this.setState({
      //bulkValidationScreen: true,
      bulkValidationFinished: true,
      SamaRows: this.state.bulkValidationRecords,
    });
  };

  /*  handleCloseClick = () => {
    if (!this.state.isShowmore) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      console.log("remove");
      document.removeEventListener("click", this.handleOutsideClick, false);
    }

    this.setState((prevState) => ({
      isShowmore: !prevState.isShowmore,
    }));
    this.setState({
      isEditMode1: false,
      dhpoResponse: false,
      dhpoRows: false,
      SamaRows: false,
      SamaResponse: false,
      markAsDHPOValidatedResponseMSG: NaN,
      markAsReadyToBeSubmittedMsg: NaN,
      markAsSAMAValidatedResponseMSG: NaN,
      markWithDeniedResponseMsg: NaN,
      addNewObsScreen: false,
      observationType: undefined,
    });
  }; */

  /*  handleOutsideClick = (e) => {
    if (this.node && !this.node.contains(e.target)) {
      this.handleCloseClick();
    }

    //document.removeEventListener("click", this.handleOutsideClick, false);
  }; */

  mappingStatusTimeline(key) {
    let status = "";
    switch (key) {
      case "0":
        status = "Claim upladed.";
        break;
      case "9":
        status = "Claim marked as ready to be submitted.";
        break;

      case "1":
        status = "Claim validated successfully on Sama.";
        break;

      case "14":
        status = "Claim validated on Sama with issue.";
        break;

      case "updated":
        status = "Claim information updated.";
        break;

      case "handleRA":
        status = "Claim RA handled.";
        break;

      case 5:
      case "5":
        status = "Claim fully paid";
        break;

      case 7:
      case "7":
        status = "Claim partially rejected";
        break;
      case 8:
      case "8":
        status = "Claim fully rejected";
        break;

      case "13":
        status = "Claim Finished with Fully Rejected";
        break;

      case "10":
        status =
          /*  "Claim marked for Correction Resubmission" */ "Claim marked for Resubmission";
        break;

      case "11":
        status =
          /* "Claim marked for Internal Complaint Resubmission" */ "Claim marked for Resubmission";
        break;

      case "18":
        status = "Claim submitted to DHPO";
        break;

      case "19":
        status = "Claim re-submitted to DHPO";
        break;

      case "submissionReversed":
        status =
          "Claim reversed from ready to submission to previous claim status";
        break;

      case "resubmissionReversed":
        status =
          "Claim reversed from ready for re-submission to previous claim status";
        break;
      default:
        break;
    }

    return status;
  }

  render() {
    let {
      AllClaims, //search result
      recordId, //id of recordwhen i click show more
      isShowmore, //show more?
      isEditMode, //edit mode for claim header
      isEditMode1, //ediy mode for claim details
      diagnosisInfo,
      activitiesInfo,
      EncounterInfo,
      dhpoRows,
      SamaRows,
      markAsDHPOValidatedResponseMSG,
      markAsSAMAValidatedResponseMSG,
      markWithDeniedResponseMsg,
      samaResponseMessage,
      dhpoResponseMessage,
      alertMessage,
      typeOfResubmissionScreen,
      markAsResubmissionSuccess,
      markAsRessubmissionError,
      isSearch,
      observationFlag,
      payers,
      missMatch,
      selectedCodePrice,
      dhpoResponse,
    } = this.state;
    let items = [];
    let com;
    if (this.state.isShowTimeLine) {
      let { timeline } = this.state;

      com = timeline.map((time) => {
        let keys = Object.keys(time);
        return (
          <TextEvent
            date={time[keys[0]]}
            text={this.mappingStatusTimeline(keys[0])}
          />
        );
      });
      /*  timeline.forEach((time) => {
        let keys = Object.keys(time);

        items.push({
          title: time[keys[0]],

          cardSubtitle: this.mappingStatusTimeline(keys[0]),
        });
      }); */
    }
    let { onClickDownload } = this;
    let payersList;
    if (payers && payers.length > 0) {
      payersList = payers.map((payer) => {
        return (
          <option value={payer["eclaimlink_id"]}>
            {payer["eclaimlink_id"]}
          </option>
        );
      });
    }
    let { mappingActivityType } = this;
    let claim_status;

    if (activitiesInfo) {
      let activityCodes = Object.keys(activitiesInfo);

      for (let index = 0; index < activityCodes.length; index++) {
        const code = activityCodes[index];

        if (activitiesInfo[[code]]["Observation"] != undefined) {
          if (!this.state.observationFlag) {
            this.setState({ observationFlag: true });
          }
          break;
        }
      }
    }

    if (EncounterInfo) {
      claim_status = EncounterInfo["claim_status"];
    }

    //extract activities info and diagnosis info of the the selected claim

    //dhpo response

    let dhpoTable;
    if (dhpoRows && dhpoRows.length > 0) {
      dhpoTable = dhpoRows.map((row, index) => {
        if (index != 0 && index != dhpoRows.length - 1) {
          let elements = row.split(",");
          return (
            <tr key={index}>
              {elements.map((e, i) => {
                return <td key={i}>{e}</td>;
              })}
            </tr>
          );
        }
      });
    }
    //sama response
    let samaTable;

    if (SamaRows && SamaRows.length > 0) {
      samaTable = SamaRows.map((row, index) => {
        //extract diagnosis code from message
        var DiagnosisCode;

        let Type = row["Type"];
        if (Type.includes("ICD") || Type == "Pregnancy Notification") {
          var regExp = /\(([^)]+)\)/;
          var str;
          if (Type == "ICD-ICD") {
            str = row["Message"].split("Diagnosis")[1];
          } else if (
            Type == "Incorrect HCPCS-ICD Relation" ||
            Type == "Incorrect CPT-ICD Relation" ||
            Type == "Incorrect Dental-ICD Relation"
          ) {
            str = row["Message"].split("diagnoses")[1];
          } else {
            str = row["Message"].split("code")[1];
          }
          var matches = regExp.exec(str);
          if (matches && matches.length > 0) {
            DiagnosisCode = matches[1];
          }
        }
        row["DiagnosisCode"] = DiagnosisCode;

        return (
          <TableRow key={index}>
            <TableCell align="center">{row["ClaimId"]}</TableCell>
            <TableCell align="center">{row["EncounterId"]}</TableCell>
            <TableCell align="center">{row["claimLine"]}</TableCell>
            <TableCell align="center">{row["errorLine"]}</TableCell>
            <TableCell align="center">{row["Message"]}</TableCell>
            <TableCell align="center">{row["ActivityCode"]}</TableCell>

            <TableCell align="center">{DiagnosisCode}</TableCell>
            <TableCell align="center">{row["Type"]}</TableCell>
            {row["Level"] === "__" ? (
              <TableCell align="center">{row["Level"]}</TableCell>
            ) : row["Level"] === "Review" ? (
              <TableCell align="center" style={{ color: "#ffa500" }}>
                {row["Level"]}
              </TableCell>
            ) : (
              <TableCell align="center" style={{ color: "#ff0000" }}>
                {row["Level"]}
              </TableCell>
            )}
          </TableRow>
        );
      });
    }

    let license = localStorage.getItem("UserLicenses").split(",");
    // console.log(license);
    let FacilityIdOption = [];
    license.forEach((l) => {
      FacilityIdOption.push({ name: l, value: l });
    });

    let departementsOption = [];
    let { departments } = this.state;
    if (departments && departments.length > 0) {
      departments.forEach((element) => {
        departementsOption.push({
          name: element["department_name"],
          value: element["id"],
        });
      });
    }

    let CalculationResultTable;
    if (missMatch && missMatch.length > 0) {
      CalculationResultTable = missMatch.map((row, index) => {
        return (
          <tr key={index}>
            {/*  <td>
              {" "}
              <input
                type="checkbox"
                onChange={(e) => this.handleSelectPriceChangeChk(e, row)}
              />
            </td> */}
            <td>{row["ID"]}</td>

            <td>{row["Code"]}</td>
            <td>{row["Type"]}</td>
            <td>{row["PriceListPrice"]}</td>
          </tr>
        );
      });
    }

    let GrossMissMatchTable;
    let { GrossMissMatch } = this.state;
    if (GrossMissMatch && GrossMissMatch.length > 0) {
      GrossMissMatchTable = GrossMissMatch.map((row, index) => {
        return (
          <tr key={index}>
            <td>{row["claimID"]}</td>

            <td>{row["PriceListGross"]}</td>

            <td>{row["message"]}</td>
          </tr>
        );
      });
    }

    /*Claim ID	Encounter Sequence	Claim Line	Edit Line	Edit Message	Type	Level*/
    /*ClaimId EncounterId claimLine errorLine Type Level Message*/

    let header = [
      { label: "Claim ID", key: "ClaimId" },
      { label: "Encounter Sequence", key: "EncounterId" },
      { label: "Claim Line", key: "claimLine" },
      { label: "Edit Line", key: "errorLine" },
      { label: "Edit Message", key: "Message" },
      { label: "Activity Code", key: "ActivityCode" },
      { label: "Diagnosis Code", key: "DiagnosisCode" },
      { label: "Type", key: "Type" },
      { label: "Level", key: "Level" },
    ];

    return (
      <div>
        <Fragment>
          <DialogActions
            open={this.state.DeleteClaim}
            dialog="Are you sure you want to delete this Claim?"
            onClose={() =>
              this.setState({
                DeleteClaim: false,
              })
            }
            action={this.DeleteClaimByID}
          />
          {isEditMode1 &&
          claim_status != "8" &&
          claim_status != "7" &&
          claim_status != "5"
            ? this.renderEditView1()
            : null}

          {isShowmore && (
            <>
              <FormModal
                open={true}
                onClose={() =>
                  this.setState({
                    isShowmore: false,
                    dhpoResponse: false,
                    dhpoRows: false,
                    SamaRows: false,
                    SamaResponse: false,
                    markAsDHPOValidatedResponseMSG: NaN,
                    markAsReadyToBeSubmittedMsg: NaN,
                    markAsSAMAValidatedResponseMSG: NaN,
                    markWithDeniedResponseMsg: NaN,
                    missMatch: NaN,
                  })
                }
                maxWidth="xl"
                ref={(node) => {
                  this.node = node;
                }}
              >
                <Box style={{ width: "95%", margin: "auto" }}>
                  <Typography
                    style={{ textAlign: "center" }}
                    variant="h5"
                    gutterBottom
                  >
                    Encounter Details
                  </Typography>
                  <table striped bordered hover>
                    <thead style={{ background: "#3ab449", color: " white" }}>
                      <tr>
                        <th>Member ID</th>
                        <th>Payer ID</th>
                        <th>Emirates ID Number</th>
                        <th>Gross</th>
                        <th>Patient Share</th>
                        <th>Net</th>
                        <th>Facility ID</th>

                        <th>Encounter Type</th>
                        <th>Patient ID</th>
                        <th>Start</th>
                        <th>End</th>
                        <th>Encounter Start Type</th>

                        <th>Encounter End Type</th>

                        <th>Transfer Source</th>
                        <th>Transfer Destination</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{EncounterInfo["member_id"]}</td>

                        <td>{EncounterInfo["payer_id"]}</td>
                        <td>{EncounterInfo["emirates_id_number"]}</td>
                        <td>{EncounterInfo["gross"]}</td>
                        <td>{EncounterInfo["patient_share"]}</td>
                        <td>{EncounterInfo["net"]}</td>

                        <td>{EncounterInfo["enc_facility_id"]}</td>
                        <td>
                          {this.mappingEncType(EncounterInfo["enc_type"])}
                        </td>
                        <td>{EncounterInfo["enc_patient_id"]}</td>

                        <td>{EncounterInfo["enc_start"]}</td>
                        <td>{EncounterInfo["enc_end"]}</td>
                        <td>
                          {this.mappingEncStartType(
                            EncounterInfo["start_type"]
                          )}
                        </td>
                        <td>
                          {this.mappingEncEndType(EncounterInfo["end_type"])}
                        </td>
                        <td>{EncounterInfo["transfer_source"]}</td>
                        <td>{EncounterInfo["transfer_destination"]}</td>
                      </tr>
                    </tbody>
                  </table>
                  {claim_status == "5" ||
                  claim_status == "7" ||
                  claim_status == "8" ? (
                    <Table responsive striped bordered hover size="sm">
                      <thead>
                        <tr>
                          <th>ID Payer</th>
                          <th>PaymentReference</th>
                          <th>DateSettlement</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{EncounterInfo["id_payer"]}</td>
                          <td>{EncounterInfo["payment_reference"]}</td>
                          <td>{EncounterInfo["date_settlement"]}</td>
                        </tr>
                      </tbody>
                    </Table>
                  ) : null}
                  <Typography
                    style={{ textAlign: "center" }}
                    variant="h5"
                    gutterBottom
                  >
                    Disgnosis Details
                  </Typography>
                  <table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Diagnosis Code</th>
                        <th>Code Description</th>
                        <th>Type</th>
                        <th>DX Info</th>
                      </tr>
                    </thead>
                    <tbody>
                      {diagnosisInfo &&
                        Object.keys(diagnosisInfo).map((code, index) => {
                          return (
                            <tr key={index}>
                              <td>{code}</td>
                              <td>{diagnosisInfo[code]["Desc"]}</td>
                              <td>{diagnosisInfo[code]["Type"]}</td>
                              {diagnosisInfo[code]["DxInfo"] &&
                              diagnosisInfo[code]["DxInfo"].length > 0 ? (
                                <td>
                                  <table striped bordered hover>
                                    <thead>
                                      <tr>
                                        <th>Type</th>
                                        <th>Code</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        /* Object.keys( */
                                        /*     diagnosisInfo[code] &&
                                            diagnosisInfo[code]["DxInfo"] &&
                                            diagnosisInfo[code]["DxInfo"]
                                              .length > 0
                                              ? */ diagnosisInfo[code][
                                          "DxInfo"
                                        ].map((info, index) => {
                                          return (
                                            <tr>
                                              <td>
                                                {
                                                  diagnosisInfo[code]["DxInfo"][
                                                    index
                                                  ]["Type"]
                                                }
                                              </td>
                                              <td>
                                                {" "}
                                                {
                                                  diagnosisInfo[code]["DxInfo"][
                                                    index
                                                  ]["Code"]
                                                }
                                              </td>
                                            </tr>
                                          );
                                        })
                                      }
                                    </tbody>
                                  </table>
                                </td>
                              ) : (
                                <td></td>
                              )}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <Typography
                    style={{ textAlign: "center" }}
                    variant="h5"
                    gutterBottom
                  >
                    Activities Details{" "}
                  </Typography>
                  <table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Code Description</th>
                        <th>Type</th>
                        <th>Start</th>
                        <th>Quantity</th>
                        <th>Net</th>
                        <th>Clinician</th>
                        <th>Prior Authorization ID</th>

                        {claim_status &&
                        (claim_status == "5" ||
                          claim_status == "7" ||
                          claim_status == "8") ? (
                          <th>Patient Share</th>
                        ) : null}
                        {claim_status &&
                        (claim_status == "5" ||
                          claim_status == "7" ||
                          claim_status == "8") ? (
                          <th>Payment Amount</th>
                        ) : null}
                        {claim_status &&
                        (claim_status == "5" ||
                          claim_status == "7" ||
                          claim_status == "8") ? (
                          <th>status</th>
                        ) : null}
                        {claim_status &&
                        (claim_status == "7" || claim_status == "8") ? (
                          <th>Denial Code</th>
                        ) : null}

                        {claim_status &&
                        (claim_status == "7" || claim_status == "8") ? (
                          <th>Comment</th>
                        ) : null}
                        {this.state.observationFlag ? (
                          <th colspan="4">Observations</th>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {activitiesInfo &&
                        Object.keys(activitiesInfo).map(function (code, index) {
                          return (
                            <tr key={index}>
                              <td>{code}</td>
                              <td>
                                {activitiesInfo[code]["Desc"]
                                  ? activitiesInfo[code]["Desc"]
                                  : undefined}
                              </td>
                              <td>
                                {mappingActivityType(
                                  activitiesInfo[code]["Type"]
                                )}
                              </td>
                              <td>{activitiesInfo[code]["Start"]}</td>
                              <td>{activitiesInfo[code]["Quantity"]}</td>
                              <td>{activitiesInfo[code]["Net"]}</td>
                              <td>{activitiesInfo[code]["Clinician"]}</td>
                              <td>
                                {activitiesInfo[code]["PriorAuthorizationID"]}
                              </td>

                              {claim_status &&
                              (claim_status == "5" ||
                                claim_status == "7" ||
                                claim_status == "8") ? (
                                <td>{activitiesInfo[code]["PatientShare"]}</td>
                              ) : null}

                              {claim_status &&
                              (claim_status == "5" ||
                                claim_status == "7" ||
                                claim_status == "8") ? (
                                <td>{activitiesInfo[code]["PaymentAmount"]}</td>
                              ) : null}

                              {claim_status &&
                              (claim_status == "5" ||
                                claim_status == "7" ||
                                claim_status == "8") ? (
                                <td>
                                  <td>{activitiesInfo[code]["status"]}</td>
                                </td>
                              ) : null}
                              {claim_status &&
                              (claim_status == "7" || claim_status == "8") ? (
                                <td>{activitiesInfo[code]["DenialCode"]}</td>
                              ) : null}

                              {claim_status &&
                              (claim_status == "7" || claim_status == "8") ? (
                                <td>
                                  {activitiesInfo[code]["comments"]
                                    ? activitiesInfo[code]["comments"]
                                    : ""}
                                </td>
                              ) : null}

                              {activitiesInfo[code]["Observation"] &&
                              activitiesInfo[code]["Observation"].length > 0 ? (
                                <table striped bordered hover>
                                  <thead>
                                    <tr>
                                      <th>Type</th>
                                      <th>Code</th>
                                      <th>Value</th>
                                      <th>ValueType</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Object.keys(
                                      activitiesInfo[code]["Observation"]
                                    ).map(function (obs, index) {
                                      return (
                                        <tr>
                                          <td>
                                            {
                                              activitiesInfo[code][
                                                "Observation"
                                              ][index]["Type"]
                                            }
                                          </td>
                                          <td>
                                            {" "}
                                            {
                                              activitiesInfo[code][
                                                "Observation"
                                              ][index]["Code"]
                                            }
                                          </td>
                                          <td>
                                            {" "}
                                            {activitiesInfo[code][
                                              "Observation"
                                            ][index]["Type"] == "File" ? (
                                              <a
                                                onClick={() => {
                                                  onClickDownload(
                                                    activitiesInfo[code][
                                                      "Observation"
                                                    ][index]["Code"],
                                                    activitiesInfo[code][
                                                      "Observation"
                                                    ][index]["Value"]
                                                  );
                                                }}
                                                style={{
                                                  textDecoration: "underline",
                                                  color: "#007bff",
                                                }}
                                              >
                                                download
                                              </a>
                                            ) : (
                                              activitiesInfo[code][
                                                "Observation"
                                              ][index]["Value"]
                                            )}
                                          </td>
                                          <td>
                                            {
                                              activitiesInfo[code][
                                                "Observation"
                                              ][index]["ValueType"]
                                            }
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              ) : observationFlag ? (
                                <td></td>
                              ) : null}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {typeOfResubmissionScreen ? (
                    <div className="popup">
                      <div
                        className="form5"
                        style={{
                          width: "90%",

                          margin: "auto",
                          backgroundColor: "white",
                        }}
                      >
                        <select
                          onChange={(e) => this.handleSelectResubmissionType(e)}
                        >
                          <option value="">Resubmission Type</option>
                          <option>Correction</option>
                          <option>Internal Complaint</option>
                        </select>

                        <input
                          type="button"
                          className="BS1"
                          value="confirm"
                          onClick={this.markAsResubmissionClaim}
                        />
                        {markAsResubmissionSuccess ? (
                          <h6 style={{ color: "#3ab449" }}>
                            the claim marked as Ressubmission
                          </h6>
                        ) : markAsRessubmissionError ? (
                          <h6 style={{ color: "red" }}>
                            {markAsRessubmissionError}
                          </h6>
                        ) : null}
                        <p
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            textAlign: "right",
                            cursor: "pointer",
                            margin: "0 2%",
                          }}
                          onClick={() =>
                            this.setState({
                              typeOfResubmissionScreen: false,
                              markAsRessubmissionError: NaN,
                              markAsResubmissionSuccess: false,
                              typeOfResubmission: NaN,
                              observationFlag: false,
                              Records: [],
                            })
                          }
                        >
                          {" "}
                          Close{" "}
                        </p>
                      </div>
                    </div>
                  ) : null}
                  <Box
                    justifyContent="space-between"
                    style={{
                      display: "flex",
                      marginTop: "1rem",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      gap: "0.7rem",
                    }}
                  >
                    {claim_status != "7" && claim_status != "8" ? (
                      <>
                        <MuiButton
                          size="medium"
                          color="success"
                          variant="contained"
                          onClick={this.onValidateDHPO}
                          style={{
                            backgroundColor: "#3ab449",
                            color: "white",
                          }}
                        >
                          Validate Post Office
                        </MuiButton>
                        <MuiButton
                          size="medium"
                          color="success"
                          variant="contained"
                          onClick={this.validateOnSama}
                          style={{
                            backgroundColor: "#3ab449",
                            color: "white",
                          }}
                        >
                          Local Testing
                        </MuiButton>
                        <MuiButton
                          size="medium"
                          color="success"
                          variant="contained"
                          onClick={this.markAsReadyToBeSubmitted}
                          style={{
                            backgroundColor: "#3ab449",
                            color: "white",
                          }}
                        >
                          Mark as ready to be submitted
                        </MuiButton>
                      </>
                    ) : null}
                    <MuiButton
                      size="medium"
                      color="success"
                      variant="contained"
                      /* onClick={this.askForReview} */
                      style={{
                        backgroundColor: "#3ab449",
                        color: "white",
                      }}
                    >
                      Ask for review{" "}
                    </MuiButton>
                    <MuiButton
                      size="medium"
                      color="success"
                      variant="contained"
                      onClick={this.Calculate}
                      style={{
                        backgroundColor: "#3ab449",
                        color: "white",
                      }}
                    >
                      Calculate
                    </MuiButton>

                    {claim_status == "7" || claim_status == "8" ? (
                      <MuiButton
                        size="medium"
                        color="success"
                        variant="contained"
                        onClick={this.markAsRessubmission}
                        style={{
                          backgroundColor: "#3ab449",
                          color: "white",
                        }}
                      >
                        Mark For Ressubmission
                      </MuiButton>
                    ) : null}
                    {claim_status == "7" || claim_status == "8" ? (
                      <MuiButton
                        size="medium"
                        color="success"
                        variant="contained"
                        onClick={this.markWithDenied}
                        style={{
                          backgroundColor: "#3ab449",
                          color: "white",
                        }}
                      >
                        Mark with Denied
                      </MuiButton>
                    ) : null}
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      width: "100%",
                      marginTop: "1rem",
                    }}
                  >
                    {this.state.dhpoValidationStart ? (
                      <Spinner animation="border" variant="success" />
                    ) : this.state.dhpoResponse &&
                      dhpoRows &&
                      dhpoRows.length > 0 ? (
                      <div style={{ width: "100%" }}>
                        <Table responsive striped bordered hover size="sm">
                          <thead
                            style={{ background: "#3ab449", color: " white" }}
                          >
                            <tr>
                              {dhpoRows[0].split(",").map((r, index) => {
                                return <th key={index}> {r} </th>;
                              })}
                            </tr>
                          </thead>
                          <tbody>{dhpoTable}</tbody>
                        </Table>
                      </div>
                    ) : this.state.dhpoResponse &&
                      !dhpoRows &&
                      dhpoResponseMessage ? (
                      <div style={{ width: "100%" }}>
                        {dhpoResponseMessage ? (
                          <div>
                            <Typography variant="h6" style={{ color: "green" }}>
                              {dhpoResponseMessage}
                            </Typography>

                            <MuiButton
                              size="medium"
                              color="success"
                              variant="contained"
                              onClick={this.markAsDHPOValidated}
                              style={{
                                backgroundColor: "#3ab449",
                                color: "white",
                                marginTop: "0.5rem",
                              }}
                            >
                              Mark as validated on DHPO"
                            </MuiButton>
                          </div>
                        ) : null}

                        {markAsDHPOValidatedResponseMSG ? (
                          <h6>{markAsDHPOValidatedResponseMSG}</h6>
                        ) : null}
                      </div>
                    ) : this.state.dhpoResponse &&
                      this.state.dhpoValidationErrorMessage ? (
                      <h5 style={{ color: "red" }}>
                        {this.state.dhpoValidationErrorMessage ==
                        "The logged in user is not the same as Sender ID in XML file"
                          ? `${
                              this.state.dhpoValidationErrorMessage
                            }, Your DHA Facility ID is ${localStorage.getItem(
                              "dhafacilityid"
                            )}`
                          : this.state.dhpoValidationErrorMessage}
                      </h5>
                    ) : null}
                    {!this.state.SamaResponse &&
                    this.state.samaValidationFlag ? (
                      <Spinner animation="border" variant="success" />
                    ) : null}
                    {this.state.SamaResponse &&
                    SamaRows &&
                    SamaRows.length > 0 ? (
                      <div style={{ width: "100%" }}>
                        <Table responsive striped bordered hover size="sm">
                          <thead
                            style={{ background: "#3ab449", color: " white" }}
                          >
                            <tr>
                              <th
                                style={{
                                  verticalAlign: "middle",
                                  paddingLeft: "30px",
                                  paddingRight: "30px",
                                }}
                              >
                                Claim ID
                              </th>
                              <th
                                style={{
                                  verticalAlign: "middle",
                                  width: "1px",
                                }}
                              >
                                Encounter Sequence
                              </th>
                              <th style={{ verticalAlign: "middle" }}>
                                Claim Line
                              </th>
                              <th style={{ verticalAlign: "middle" }}>
                                Edit Line
                              </th>
                              <th style={{ verticalAlign: "middle" }}>
                                Edit Message
                              </th>
                              <th style={{ verticalAlign: "middle" }}>
                                Activity Code
                              </th>
                              <th style={{ verticalAlign: "middle" }}>
                                Diagnosis Code
                              </th>{" "}
                              <th style={{ verticalAlign: "middle" }}>Type</th>
                              <th style={{ verticalAlign: "middle" }}>Level</th>
                            </tr>
                          </thead>
                          <tbody>{samaTable}</tbody>
                        </Table>
                      </div>
                    ) : this.state.SamaResponse &&
                      SamaRows &&
                      SamaRows.length == 0 ? (
                      <div style={{ width: "100%" }}>
                        {samaResponseMessage ? (
                          <Typography variant="h6" style={{ color: "green" }}>
                            {samaResponseMessage}
                          </Typography>
                        ) : null}
                      </div>
                    ) : null}
                    <Box
                      style={{
                        marginTop: "1rem",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      {this.state.claculateFunctionFlag &&
                      !this.state.missMatch ? (
                        <Spinner animation="border" variant="success" />
                      ) : null}
                      {this.state.calculateResponse &&
                      missMatch &&
                      missMatch.length > 0 ? (
                        <div style={{ width: "100%" }}>
                          <Table responsive striped bordered hover size="sm">
                            <thead
                              style={{ background: "#3ab449", color: " white" }}
                            >
                              <tr>
                                {/* <th>select</th> */}
                                <th>ID</th>
                                <th style={{ verticalAlign: "middle" }}>
                                  Code
                                </th>
                                <th style={{ verticalAlign: "middle" }}>
                                  Type
                                </th>
                                <th style={{ verticalAlign: "middle" }}>
                                  Price List Net
                                </th>
                              </tr>
                            </thead>
                            <tbody>{CalculationResultTable}</tbody>
                          </Table>
                        </div>
                      ) : this.state.calculateResponse &&
                        missMatch &&
                        missMatch.length == 0 ? (
                        <div>
                          <Typography variant="h6">
                            All the activity prices match with the price list
                          </Typography>
                        </div>
                      ) : null}
                      {/***************************************************** */}
                      {/*   {this.state.claculateFunctionFlag &&
                    !this.state.missMatch ? (
                      <Spinner animation="border" variant="success" />
                    ) : null} */}
                      {this.state.calculateResponse &&
                      this.state.GrossMissMatch &&
                      this.state.GrossMissMatch.length > 0 ? (
                        <div style={{ width: "100%" }}>
                          <Table responsive striped bordered hover size="sm">
                            <thead
                              style={{ background: "#3ab449", color: " white" }}
                            >
                              <tr>
                                {/* <th>select</th> */}
                                <th>Claim ID</th>

                                <th style={{ verticalAlign: "middle" }}>
                                  Price List Gross
                                </th>

                                <th style={{ verticalAlign: "middle" }}>
                                  Error Message
                                </th>
                              </tr>
                            </thead>
                            <tbody>{GrossMissMatchTable}</tbody>
                          </Table>
                        </div>
                      ) : /* this.state.calculateResponse &&
                      missMatch &&
                      missMatch.length == 0 ? (
                      <div>
                        <h5>
                          All the activity prices match with the price list
                        </h5>
                      </div>
                    ) : */ null}
                      {/****************************************************** */}
                      {
                        /* selectedCodePrice && selectedCodePrice.length > 0 */ this
                          .state.missMatch &&
                        this.state.missMatch.length > 0 ? (
                          <MuiButton
                            variant="contained"
                            onClick={this.updatePrice}
                            style={{
                              backgroundColor: "#37ab45",
                              color: "white",
                            }}
                          >
                            Update Price
                          </MuiButton>
                        ) : null
                      }{" "}
                      <br />
                      {this.state.updatePriceFunctionFlag &&
                      !this.state.updatePriceResponse ? (
                        <Spinner animation="border" variant="success" />
                      ) : null}
                      {this.state.updatePriceResponse &&
                      this.state.updatePriceResponseMessgae ? (
                        <>
                          <NotificationAlert
                            message={this.state.updatePriceResponseMessgae}
                            severity="success"
                            open={this.state.updatePriceResponse}
                            onClose={() =>
                              this.setState({ updatePriceResponse: false })
                            }
                          />
                        </>
                      ) : null}
                      {markAsSAMAValidatedResponseMSG ? (
                        <Typography variant="h6">
                          {markAsSAMAValidatedResponseMSG}
                        </Typography>
                      ) : null}
                      {this.state.markAsReadyToBeSubmittedMsg ? (
                        <Typography variant="h6">
                          {this.state.markAsReadyToBeSubmittedMsg}
                        </Typography>
                      ) : null}
                      {markWithDeniedResponseMsg ? (
                        <Typography variant="h6">
                          {markWithDeniedResponseMsg}
                        </Typography>
                      ) : null}
                      {alertMessage ? (
                        <Box style={{ display: "flex" }}>
                          <Typography
                            variant="h6"
                            style={{ color: "red", marginRight: "0.5rem" }}
                          >
                            {alertMessage}
                          </Typography>
                          <MuiButton onClick={this.clickHere}>
                            click here
                          </MuiButton>
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                </Box>
              </FormModal>
            </>
          )}

          <FormModal
            open={this.state.isShowTimeLine}
            maxWidth="md"
            onClose={() =>
              this.setState({
                isShowTimeLine: false,
              })
            }
            title="TimeLine"
          >
            {this.state.timeline && this.state.timeline.length > 0 ? (
              <Timeline>
                <Events>{com}</Events>
              </Timeline>
            ) : (
              <Typography variant="h5" style={{ textAlign: "center" }}>
                There is no time history for this claim
              </Typography>
            )}
          </FormModal>
          {/*Bulk validation screen*/}
          <FormModal
            isLoading={!this.state.bulkValidationFinished}
            open={this.state.bulkValidationScreen}
            maxWidth="xl"
            onClose={() =>
              this.setState({
                bulkValidationScreen: false,
                bulkValidationRecords: [],
                bulkValidationXML: undefined,
                SamaRows: undefined,
                SamaResponse: false,
              })
            }
          >
            {this.state.SamaResponse && SamaRows && SamaRows.length > 0 ? (
              <div>
                {/*Excel sheet*/}
                <CSVLink
                  filename="ValidationResult.CSV"
                  style={{
                    color: "#ff0505",
                    textDecoration: "underline",
                  }}
                  data={this.state.SamaRows}
                  headers={header}
                >
                  Download the edits report
                </CSVLink>{" "}
                <MuiTable size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Claim ID</TableCell>
                      <TableCell align="center">Encounter Sequence</TableCell>
                      <TableCell align="center">Claim Line</TableCell>
                      <TableCell align="center">Edit Line</TableCell>
                      <TableCell align="center">Edit Message</TableCell>
                      <TableCell align="center">Activity Code</TableCell>

                      <TableCell align="center">Dignosis Code</TableCell>
                      <TableCell align="center">Type</TableCell>
                      <TableCell align="center">Level</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{samaTable}</TableBody>
                </MuiTable>
              </div>
            ) : this.state.SamaResponse && SamaRows && SamaRows.length == 0 ? (
              <div>
                {samaResponseMessage ? (
                  <div>
                    <Typography variant="h6" style={{ color: "green" }}>
                      {samaResponseMessage}
                    </Typography>
                  </div>
                ) : null}
              </div>
            ) : null}
          </FormModal>

          <div className="container-fluid">
            <ValidateClaimsFilters
              handlePayerNameChange={this.onScelectPayerName}
              payerName={this.state.payer_id}
              payersList={payersList}
              claimStatus={this.state.claim_status}
              handleClaimStatusChange={this.handleSelectChange}
              FacilityIdOptions={FacilityIdOption}
              handleChangeFacility={this.onSelectFacilityId}
              departmentOptions={departementsOption}
              handleChangeDepartment={this.state.selectedDepartment}
              handleChangeCaseType={this.onSelectCaseType}
              handleDateChange={this.handleFromDate}
              handleUploadDate={this.handleUploadDate}
              claimIdValue={this.state.claim_id}
              handleChangeClaimId={this.handleClaimIdChange}
              handlePriceValueChange={this.handlePriceChange}
              handleSearch={this.search.bind(this)}
              isLoading={isSearch}
              handleSearchCheckBox={this.handleChangeChk}
              allClaimsData={AllClaims}
              handleValidateSelectedClaims={this.bulkValidation}
              handleShowClaimTimeLine={this.onClickShowTimeLine}
              handleClaimShowMore={this.onClickShowMore}
              handleOpenDeleteModal={this.handleOpenDeleteModal}
              handleOpenEditMode={this.changeEditMode1}
            />

            <div className="form-sama1">
              {this.state.selectedClaims.length > 0 ? (
                <div>
                  {" "}
                  <input
                    type="submit"
                    value="validate selected claims"
                    /*   className="GenerateBtnStyle11" */
                    style={{ marginTop: "20px" }}
                    onClick={this.bulkValidation}
                    name="name"
                  />
                </div>
              ) : null}
            </div>
          </div>
        </Fragment>
      </div>
    );
  }
}

export default ValidateClaims;
