//import React from "react";
import React, { Component, Fragment } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import settings from "../helpers/Settings";
import Table from "react-bootstrap/Table";
import Form from "../Components/FormNew/index2";
import cloneDeep from "lodash/cloneDeep";
import Button from "../Components/Button/index";
import { saveAs } from "file-saver";
import Spinner from "react-bootstrap/Spinner";
var xml = require("xml");
let { KayanURL } = settings;
/* let ICDs = require("./DiagnosisItems");
let CPTs = require("./CptItems"); */
const moment = require("moment");
class ReSubmission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldsToEdit: {},
      ActivityItems: [],
      ActivityCodes: [],
      diagnosisItems: [],
      DiagnosisCodes: [],
      diagnosisNow: 0,
      isShowmore: false,
      selectedClaims: [],
      AllClaims: [],
      fieldsToEdit: {},
      ActivityItems: [],
      diagnosisItems: [],
      selectedClaims: [],
    };
    this.handleStartEndDate = this.handleStartEndDate.bind(this);
    this.onScelectPayerName = this.onScelectPayerName.bind(this);
  }
  componentDidMount = () => {
    //this.getResubmissionAllClaims();
    this.getPayersList();
    this.getAllDiagnosis();

    var charset = "abcdefghijklmnopqrstuvwxyz"; //from where to create
    var result = "";
    for (var i = 0; i < 3; i++)
      result += charset[Math.floor(Math.random() * charset.length)];
    //ID
    let str = result.toUpperCase() + "-" + Math.floor(Math.random() * 10000000);
    if (this.state.submissionId == undefined) {
      /* await  */ this.setState({ submissionId: str });
    }
  };
  async getPayersList() {
    let response = await axios.put(KayanURL + "api/sama/getPayers", {
      apiToken: Cookies.get("SamaId"),
    });

    if (response) {
      let { data } = response;
      if (data && data.success) {
        let { payers } = data;

        this.setState({ payers });
      }
    }
  }
  search = (e) => {
    this.setState({ isSearch: true, selectedClaims: [] });

    axios
      .put(KayanURL + "api/sama/search", {
        claim_status: "10",
        enc_start: this.state.enc_start,
        enc_end: this.state.enc_end,
        payer_id: this.state.payer_id,

        apiToken: Cookies.get("SamaId"),
      })
      .then(({ data }) => {
        if (data) {
          this.setState({
            AllClaims: data.claims /* , providerId: data.license */,
          });
          axios
            .put(KayanURL + "api/sama/search", {
              claim_status: "11",
              enc_start: this.state.enc_start,
              enc_end: this.state.enc_end,
              payer_id: this.state.payer_id,

              apiToken: Cookies.get("SamaId"),
            })
            .then(({ data }) => {
              if (data) {
                let internalComplaintClaims = data.claims;
                if (
                  internalComplaintClaims &&
                  internalComplaintClaims.length > 0
                ) {
                  let { AllClaims } = this.state;
                  internalComplaintClaims.forEach((claim) => {
                    AllClaims.push(claim);
                  });
                  this.setState({ AllClaims /*  providerId: data.license */ });
                }
              }
            });
        }
      });
  };
  renderDiagnosisProgressBar() {
    let { diagnosisNow, diagnosisItems } = this.state;

    setTimeout(() => {
      this.setState(
        {
          diagnosisNow:
            !diagnosisItems && diagnosisNow < 100 ? diagnosisNow + 20 : 100,
        },
        this.renderDiagnosisProgressBar()
      );
    }, "800");
  }
  renderActivityProgressBar() {
    let { ActivityNow, ActivityItems } = this.state;

    setTimeout(() => {
      this.setState(
        {
          ActivityNow: !ActivityItems ? ActivityNow + 20 : 100,
        },
        this.renderActivityProgressBar()
      );
    }, "800");
  }

  getAllDiagnosis = () => {
    let diagnosisItems = [];
    this.renderDiagnosisProgressBar();

    axios
      .get("https://www.kayan-healthcare.com/" + "api/getAllDiagnosis")
      .then(({ data }) => {
        if (data) {
          data.forEach((element) => {
            if (element.shortdesc === "null") {
              element.shortdesc = "";
            }
            diagnosisItems.push(element.code + "-" + element.shortdesc);
          });

          this.setState({ diagnosisItems });
        }
      })
      .catch(() => {
        this.setState({ serverError: "There are an server error" });
      });
  };
  getAllActivity = (v) => {
    let ActivityItems = [];

    this.renderActivityProgressBar();

    axios
      .get("https://www.kayan-healthcare.com/" + "api/getAllActivity/" + v)

      .then(({ data }) => {
        if (data) {
          data.forEach((element) => {
            if (
              ActivityItems.indexOf(element.code + "-" + element.shortdesc) ==
              -1
            ) {
              if (element.shortdesc === "null") {
                element.shortdesc = "";
              }

              ActivityItems.push(element.code + "-" + element.shortdesc);
            }
          });

          this.setState({ ActivityItems });
        }
      })
      .catch(() => {
        this.setState({ serverError: "There are an server error" });
      });
  };
  addNewObservation = (code) => {
    this.setState({
      addNewObsScreen: true,
      codeToAddObs: code,
      confirmed: false,
    });
  };
  addNewDX = (code) => {
    this.setState({
      addNewDXScreen: true,
      codeToAddDX: code,
      DXObj: {},
    });
  };
  handleInputTextChangeForAddNewDX = async ({ target: { name, value } }) => {
    let Obj = {};

    let { codeToAddDX, DXObj } = this.state;
    let thisCodeDxDetails = DXObj[codeToAddDX];

    if (!thisCodeDxDetails) {
      Obj[name] = value;
      DXObj[codeToAddDX] = Obj;
    } else {
      thisCodeDxDetails[name] = value;
      DXObj[codeToAddDX] = thisCodeDxDetails;
    }
  };
  handleInputTextChangeForAddNewObservation = async ({
    target: { name, value },
  }) => {
    let Obj = {};
    let { codeToAddObs, observationObj } = this.state;
    let thisCodeObsDetails = observationObj[codeToAddObs];
    if (!thisCodeObsDetails) {
      Obj[name] = value;
      observationObj[codeToAddObs] = Obj;
    } else {
      thisCodeObsDetails[name] = value;
      observationObj[codeToAddObs] = thisCodeObsDetails;
    }
  };
  // add diagnosis code to Claim Table
  addICDsCode = ({ target: { name, value } }, code, section) => {
    this.setState({
      BtnActive: false,
    });
    let { fieldsToEdit, diagnosisInfo, codeSearch } = this.state;
    let diagnosis = cloneDeep(this.state.diagnosisInfo);

    //let { claimDetailsTags } = this.state;
    let x = codeSearch.Text.split("-");
    if (code && section == "diagnosis") {
      let info = diagnosisInfo[code];
      delete diagnosis[code];
      diagnosis[x[0]] = info;

      diagnosis[x[0]]["Desc"] = x[1];
      //this.setState({ diagnosisInfo });
      this.setState({ newDiagnosisInfo: diagnosis });
      fieldsToEdit["diagnosis"] = diagnosis;
      this.setState({ fieldsToEdit });

      //claimDetailsTags["diagnosis"] = diagnosisInfo;
      // this.renderEditView1();
    }
  };

  // add Activity code to Claim Table
  addCPTsCode = ({ target: { name, value } }, code, section) => {
    this.setState({
      BtnActive: false,
    });
    let { fieldsToEdit, activitiesInfo, codeSearch } = this.state;
    let x = codeSearch.Text.split("-");
    if (code && section == "activities") {
      {
        let activities = cloneDeep(activitiesInfo);
        let info = activities[code];
        delete activities[code];
        activities[x[0]] = info;

        activities[x[0]]["Desc"] = x[1];
        this.setState({ newActivitiesInfo: activities });
        fieldsToEdit["activities"] = activities;
        this.setState({ fieldsToEdit });
      }

      // claimDetailsTags["activities"] = activitiesInfo;
      //this.renderEditView1();
    }
  };
  confirmAddNewDX = () => {
    let { codeToAddDX, DXObj, diagnosisInfo, fieldsToEdit, newDiagnosisInfo } =
      this.state;
    let diagnosis; /* = cloneDeep(this.state.diagnosisInfo); */
    let codeDX; /* = diagnosisInfo[[codeToAddDX]]["DxInfo"]; */
    if (newDiagnosisInfo) {
      diagnosis = cloneDeep(this.state.newDiagnosisInfo);
      codeDX = newDiagnosisInfo[[codeToAddDX]]["DxInfo"];
    } else {
      diagnosis = cloneDeep(this.state.diagnosisInfo);
      codeDX = diagnosisInfo[[codeToAddDX]]["DxInfo"];
    }

    if (!codeDX) {
      codeDX = [];

      codeDX.push(DXObj[codeToAddDX]);

      let newDiagnosisInfo = diagnosis;

      newDiagnosisInfo[[codeToAddDX]]["DxInfo"] = codeDX;

      fieldsToEdit["diagnosis"] = newDiagnosisInfo;

      this.setState({
        fieldsToEdit,

        addNewDXScreen: false,
        newDiagnosisInfo,
        confirmed: true,
      });
    } else {
      codeDX.push(DXObj[codeToAddDX]);

      let newDiagnosisInfo = diagnosis;

      newDiagnosisInfo[[codeToAddDX]]["DxInfo"] = codeDX;

      fieldsToEdit["diagnosis"] = newDiagnosisInfo;

      this.setState({
        fieldsToEdit,

        addNewDXScreen: false,
        newDiagnosisInfo,
        confirmed: true,
      });
    }
  };
  confirmAddNewObs = () => {
    let {
      codeToAddObs,
      observationObj,
      activitiesInfo,
      fieldsToEdit,
      newActivitiesInfo,
    } = this.state;
    let activities; /* = cloneDeep(this.state.activitiesInfo); */
    let codeObservation; /*  = activitiesInfo[[codeToAddObs]]["Observation"]; */
    if (newActivitiesInfo) {
      activities = cloneDeep(this.state.newActivitiesInfo);
      codeObservation = newActivitiesInfo[[codeToAddObs]]["Observation"];
    } else {
      activities = cloneDeep(this.state.activitiesInfo);
      codeObservation = activitiesInfo[[codeToAddObs]]["Observation"];
    }

    if (!codeObservation) {
      codeObservation = [];

      codeObservation.push(observationObj[codeToAddObs]);

      let newActivitiesInfo = activities;

      newActivitiesInfo[[codeToAddObs]]["Observation"] = codeObservation;

      fieldsToEdit["activities"] = newActivitiesInfo;

      this.setState({
        fieldsToEdit,

        addNewObsScreen: false,
        newActivitiesInfo,
        confirmed: true,
      });
    } else {
      codeObservation.push(observationObj[codeToAddObs]);
      let newActivitiesInfo = activities;
      newActivitiesInfo[[codeToAddObs]]["Observation"] = codeObservation;
      fieldsToEdit["activities"] = newActivitiesInfo;

      this.setState({
        fieldsToEdit,

        addNewObsScreen: false,
        newActivitiesInfo,
        confirmed: true,
      });
    }
  };

  readContent = async (files) => {
    let fileReader = new FileReader();
    let fileToLoad = files;
    let file = "";
    // FileReader function for read the file.
    // Onload of file read the file content

    return new Promise((resolve, reject) => {
      fileReader.onload = async (fileLoadedEvent) => {
        file = fileLoadedEvent.target.result;
        // Print data in console
        // console.log(file);
        resolve(file);
        // await this.setState({ base64: file });
      };

      // Convert data to base64
      fileReader.readAsDataURL(fileToLoad);
    });
  };

  handleInputTextChange = async (
    { target: { name, value, files } },
    code,
    section
  ) => {
    let { fieldsToEdit } = this.state;
    let diagnosis = cloneDeep(this.state.diagnosisInfo);
    let activities = cloneDeep(this.state.activitiesInfo);

    if (code && section == "diagnosis") {
      if (name == "Type") {
        diagnosis[code]["Type"] = value;

        this.setState({ newDiagnosisInfo: diagnosis });
        fieldsToEdit["diagnosis"] = diagnosis;
        this.setState({ fieldsToEdit });
      }
    } else if (code && section == "activities") {
      if (name == "Code") {
        let info = activities[code];
        delete activities[code];
        activities[value] = info;
        this.setState({ newActivitiesInfo: activities });
        fieldsToEdit["activities"] = activities;
        this.setState({ fieldsToEdit });
      } else {
        activities[code][name] = value;
        fieldsToEdit["activities"] = activities;

        this.setState({ newActivitiesInfo: activities });

        this.setState({ fieldsToEdit });
      }
    } else {
      if (name == "fileComment") {
        if (files.length > 0) {
          let res = await this.readContent(files[0]);
          this.setState({ base64: res });
          /*   name = "Value";

        value = this.state.base64; */
          fieldsToEdit["ressattachement"] = res;
          this.setState({ fieldsToEdit });
        }
      } else {
        fieldsToEdit[name] = value;

        this.setState({ fieldsToEdit });
      }
    }
  };

  async update(event) {
    event.preventDefault();
    let { fieldsToEdit, EncounterInfo } = this.state;

    let { timeline } = EncounterInfo;
    let fields = Object.keys(fieldsToEdit);

    if (fields && fields.length > 0) {
      fieldsToEdit["apiToken"] = Cookies.get("SamaId");
      let { editableRow, recordId } = this.state;

      if (editableRow) {
        fieldsToEdit["id"] = editableRow["id"];
      } else {
        fieldsToEdit["id"] = recordId;
      }
      if (fieldsToEdit["diagnosis"]) {
        let details = fieldsToEdit["diagnosis"];

        fieldsToEdit["diagnosis"] = JSON.stringify(details);
      }

      if (fieldsToEdit["activities"]) {
        let details = fieldsToEdit["activities"];

        fieldsToEdit["activities"] = JSON.stringify(details);
      }

      timeline.push({ updated: this.generateCurrentDate() });
      fieldsToEdit["timeline"] = JSON.stringify(timeline);
      EncounterInfo["timeline"] = timeline;
      this.setState({ EncounterInfo });
      fieldsToEdit["functionType"] = "edit";
      let response = await axios.put(
        KayanURL + "api/sama/update",
        fieldsToEdit
      );
      if (response) {
        let { data } = response;
        if (data.success) {
          let { EncounterInfo } = this.state;
          delete fieldsToEdit["apiToken"];
          delete fieldsToEdit["id"];
          this.setState({
            isEditMode: false,
            isEditMode1: false,
          });
          if (fieldsToEdit["diagnosis"]) {
            this.setState({ diagnosisInfo: this.state.newDiagnosisInfo });
          }
          if (fieldsToEdit["activities"]) {
            this.setState({ activitiesInfo: this.state.newActivitiesInfo });
            this.setState({ newActivitiesInfo: undefined });
          }
          let keys = Object.keys(fieldsToEdit);
          keys.forEach((k) => {
            if (k != "activities" && k != "diagnosis") {
              EncounterInfo[[k]] = fieldsToEdit[k];
            }
          });

          this.setState({ fieldsToEdit: {} });
        } else {
          alert(data.error);
        }
      }
    } else {
      console.log("change one value at least");
      alert("Change one value at least");
    }
  }
  handleInputTextChangeForObservation = (
    { target: { name, value } },
    code,
    section,
    index
  ) => {
    let { fieldsToEdit, newActivitiesInfo } = this.state;

    if (newActivitiesInfo) {
      let newObservationInfo = newActivitiesInfo[code]["Observation"];

      if (newObservationInfo && newObservationInfo.length > 0) {
        let details = newObservationInfo[0];

        details[name] = value;
        newActivitiesInfo[code]["Observation"][0] = details;
      }
    } else {
      let activities = cloneDeep(this.state.activitiesInfo);
      activities[code]["Observation"][index][name] = value;
      fieldsToEdit["activities"] = activities;
      this.setState({ newActivitiesInfo: activities });
      this.setState({ fieldsToEdit });
    }
  };
  handleInputTextChangeForDxInfo = (
    { target: { name, value } },
    code,
    index
  ) => {
    let { fieldsToEdit, newDiagnosisInfo } = this.state;

    if (newDiagnosisInfo) {
      let newDxInfo = newDiagnosisInfo[code]["DxInfo"];

      if (newDxInfo && newDxInfo.length > 0) {
        let details = newDxInfo[index];

        details[name] = value;
        newDiagnosisInfo[code]["DxInfo"][index] = details;
      }
    } else {
      let diagnosis = cloneDeep(this.state.diagnosisInfo);

      diagnosis[code]["DxInfo"][index][name] = value;
      fieldsToEdit["diagnosis"] = diagnosis;
      this.setState({ newDiagnosisInfo: diagnosis });
      this.setState({ fieldsToEdit });
    }
  };

  handleInputTextChangeForAddNewActivity({ target: { name, value } }) {
    let { ActivityCodes, code } = this.state;

    code[name] = value;
    this.setState(code);
  }
  confirmAddNewDiagnoses = (e) => {
    e.preventDefault();
    let { DiagnosisCodes, fieldsToEdit, newDiagnosisInfo } = this.state;

    let diagnosis;
    if (newDiagnosisInfo) {
      diagnosis = cloneDeep(this.state.newDiagnosisInfo);
    } else {
      diagnosis = cloneDeep(this.state.diagnosisInfo);
    }
    newDiagnosisInfo = diagnosis;
    DiagnosisCodes.forEach((code) => {
      let { Text, DiagnosisType } = code;
      code = Text.split("-")[0];
      let desc = Text.split("-")[1];

      newDiagnosisInfo[[code]] = {
        Desc: desc,
        Type: DiagnosisType,
      };
    });

    fieldsToEdit["diagnosis"] = newDiagnosisInfo;
    this.setState({
      fieldsToEdit,
      DiagnosisCodes: [],
      newDiagnosisInfo,
      addNewDiagScreen: false,
    });
  };

  confirmDeleteDiagnoses = (e) => {
    e.preventDefault();
    let { fieldsToEdit, newDiagnosisInfo, DeleteDiagnosisCode } = this.state;

    let diagnosis;
    if (newDiagnosisInfo) {
      diagnosis = cloneDeep(this.state.newDiagnosisInfo);
    } else {
      diagnosis = cloneDeep(this.state.diagnosisInfo);
    }

    newDiagnosisInfo = diagnosis;
    delete newDiagnosisInfo[DeleteDiagnosisCode];

    fieldsToEdit["diagnosis"] = newDiagnosisInfo;
    this.setState({
      fieldsToEdit,
      newDiagnosisInfo,
      DeleteDiagnosis: false,
    });
  };

  confirmDeleteActivity = (e) => {
    e.preventDefault();
    let { fieldsToEdit, newActivitiesInfo, DeleteActivityCode } = this.state;
    let Activity;
    if (newActivitiesInfo) {
      Activity = cloneDeep(this.state.newActivitiesInfo);
    } else {
      Activity = cloneDeep(this.state.newActivitiesInfo);
    }
    newActivitiesInfo = Activity;
    delete newActivitiesInfo[DeleteActivityCode];

    fieldsToEdit["activities"] = newActivitiesInfo;
    this.setState({
      fieldsToEdit,
      newActivitiesInfo,
      DeleteActivity: false,
    });
  };

  confirmDeleteObs = (e) => {
    e.preventDefault();
    let { fieldsToEdit, newActivitiesInfo, DeleteObsCode, DeleteObsindex } =
      this.state;

    let activities;
    if (newActivitiesInfo) {
      activities = cloneDeep(this.state.newActivitiesInfo);
    } else {
      activities = cloneDeep(this.state.activitiesInfo);
    }
    newActivitiesInfo = activities;

    let D = newActivitiesInfo[DeleteObsCode]["Observation"].splice(
      1,
      DeleteObsindex
    );
    newActivitiesInfo[DeleteObsCode]["Observation"] = D;

    fieldsToEdit["activities"] = newActivitiesInfo;
    this.setState({
      fieldsToEdit,
      newActivitiesInfo,
      DeleteOBS: false,
    });
  };

  confirmDeleteDX = (e) => {
    e.preventDefault();
    let { fieldsToEdit, newDiagnosisInfo, DeleteDXCode, CodeofDX } = this.state;

    let diagnosis;
    if (newDiagnosisInfo) {
      diagnosis = cloneDeep(this.state.newDiagnosisInfo);
    } else {
      diagnosis = cloneDeep(this.state.diagnosisInfo);
    }
    newDiagnosisInfo = diagnosis;

    let D = newDiagnosisInfo[CodeofDX]["DxInfo"].splice(1, DeleteDXCode);
    newDiagnosisInfo[CodeofDX]["DxInfo"] = D;

    fieldsToEdit["diagnosis"] = newDiagnosisInfo;
    //newDiagnosisInfo = diagnosis;
    this.setState({
      fieldsToEdit,
      newDiagnosisInfo,
      DeleteDX: false,
    });
  };
  confirmAddNewDX = () => {
    let { codeToAddDX, DXObj, diagnosisInfo, fieldsToEdit, newDiagnosisInfo } =
      this.state;
    let diagnosis; /* = cloneDeep(this.state.diagnosisInfo); */
    let codeDX; /* = diagnosisInfo[[codeToAddDX]]["DxInfo"]; */
    if (newDiagnosisInfo) {
      diagnosis = cloneDeep(this.state.newDiagnosisInfo);
      codeDX = newDiagnosisInfo[[codeToAddDX]]["DxInfo"];
    } else {
      diagnosis = cloneDeep(this.state.diagnosisInfo);
      codeDX = diagnosisInfo[[codeToAddDX]]["DxInfo"];
    }

    if (!codeDX) {
      codeDX = [];

      codeDX.push(DXObj[codeToAddDX]);

      let newDiagnosisInfo = diagnosis;

      newDiagnosisInfo[[codeToAddDX]]["DxInfo"] = codeDX;

      fieldsToEdit["diagnosis"] = newDiagnosisInfo;

      this.setState({
        fieldsToEdit,

        addNewDXScreen: false,
        newDiagnosisInfo,
        confirmed: true,
      });
    } else {
      codeDX.push(DXObj[codeToAddDX]);

      let newDiagnosisInfo = diagnosis;

      newDiagnosisInfo[[codeToAddDX]]["DxInfo"] = codeDX;

      fieldsToEdit["diagnosis"] = newDiagnosisInfo;

      this.setState({
        fieldsToEdit,

        addNewDXScreen: false,
        newDiagnosisInfo,
        confirmed: true,
      });
    }
  };
  confirmAddNewActivity = (e) => {
    e.preventDefault();
    let { ActivityCodes, fieldsToEdit, newActivitiesInfo } = this.state;

    let activities;
    if (newActivitiesInfo) {
      activities = cloneDeep(this.state.newActivitiesInfo);
    } else {
      activities = cloneDeep(this.state.activitiesInfo);
    }
    newActivitiesInfo = activities;

    ActivityCodes.forEach((code) => {
      let {
        Source,
        Text,
        Start,
        Quantity,
        Net,
        Clinician,
        PriorAuthorizationID,
      } = code;
      code = Text.split("-")[0];
      let desc = Text.split("-")[1];
      newActivitiesInfo[[code]] = {
        Desc: desc,
        Type: Source,
        Start: Start,
        Quantity: Quantity,
        Net,
        Clinician,
        PriorAuthorizationID,
      };
    });

    fieldsToEdit["activities"] = newActivitiesInfo;
    this.setState({
      fieldsToEdit,
      ActivityCodes: [],
      newActivitiesInfo,
      addNewActivityScreen: false,
    });
  };
  renderEditView1 = () => {
    let {
      addNewObsScreen,
      addNewDXScreen,

      diagnosisInfo,
      activitiesInfo,
      EncounterInfo,
      newDiagnosisInfo,
      newActivitiesInfo,
    } = this.state;
    let {
      handleInputTextChangeForObservation,
      addNewObservation,
      handleInputTextChange,
      handleInputTextChangeForDxInfo,
      mappingEncType,
      addNewDX,
      handleInputTextChangeForAddNewDX,
    } = this;
    let EncouterDetailsFields = [
      "member_id",
      "payer_id",
      "emirates_id_number",
      "gross",
      "patient_share",
      "net",
      "enc_facility_id",
      "enc_type",
      "enc_patient_id",
      "enc_start",
      "enc_end",

      "start_type",
      "end_type",
      "transfer_source",
      "transfer_destination",
    ];
    return (
      <div className="container">
        <div className="popup">
          <div
            className="form5"
            style={{
              width: "90%",
              margin: "auto",
              backgroundColor: "white",
            }}
          >
            <div className="form5">
              Encounter Details
              <table striped bordered hover>
                <thead style={{ background: "#3ab449", color: " white" }}>
                  <tr>
                    <th>Member ID</th>
                    <th> Payer ID</th>
                    <th>Emirates ID Number</th>
                    <th>Gross</th>
                    <th>Patient Share</th>
                    <th>Net</th>
                    <th>Facility ID</th>
                    <th>Encounter Type</th>
                    <th>Patient ID</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Encounter Start Type</th>
                    <th>Encounter End Type</th>
                    <th>Transfer Source</th>
                    <th>Transfer Destination</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {EncouterDetailsFields.map(function (field) {
                      return (
                        <td>
                          {field == "start_type" ? (
                            <select
                              name={field}
                              onChange={handleInputTextChange}
                              defaultValue={EncounterInfo[field]}
                            >
                              <option value="1">Elective</option>
                              <option value="2">Emergency</option>
                              <option value="3">Transfer</option>
                              <option value="4">Live birth</option>
                              <option value="5">Still birth</option>
                              <option value="6">Dead On Arrival</option>
                              <option value="7">Continuing Encounter</option>
                            </select>
                          ) : field == "end_type" ? (
                            <select
                              name={field}
                              onChange={handleInputTextChange}
                              defaultValue={EncounterInfo[field]}
                            >
                              <option value="1">
                                Discharged with approval
                              </option>
                              <option value="2">
                                Discharged against advice
                              </option>
                              <option value="3">
                                Discharged absent without leave
                              </option>
                              <option value="4">
                                Discharge transfer to acute care
                              </option>
                              <option value="5">Deceased</option>
                              <option value="6">Not discharged</option>
                              <option value="7">
                                Discharge transfer to non-acute care(Transfer to
                                long term care).
                              </option>
                            </select>
                          ) : (
                            <input
                              name={field}
                              className="Input-sama6"
                              type="text"
                              defaultValue={
                                field == "enc_type"
                                  ? mappingEncType(EncounterInfo[field])
                                  : EncounterInfo[field]
                              }
                              onChange={handleInputTextChange}
                            />
                          )}
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
              <p>Disgnosis Details</p>
              <table striped bordered hover>
                <thead>
                  <tr>
                    <th>Diagnosis Code</th>
                    <th>Type</th>
                    <th>DX Info</th>
                  </tr>
                </thead>
                <tbody>
                  {diagnosisInfo &&
                    Object.keys(diagnosisInfo) &&
                    Object.keys(diagnosisInfo).map(function (code, index) {
                      return (
                        <tr key={index}>
                          <td>
                            <div>
                              <Form
                                inputsTypes={["SamaDiagnosisList1"]}
                                elementsNames={["DiagnosisCodes"]}
                                inputStyle="IT3"
                                submitStyle="BS1"
                                addICDsCode={(e) => {
                                  this.addICDsCode(e, code, "diagnosis");
                                }}
                                onInputTextChange={this.handleInputTextChange}
                                onChange={this.onChange}
                                onClick={this.onClick}
                                BtnActive={this.state.BtnActive}
                                diagnosisNow={100}
                                section={this.state.section}
                                allDiagnosis={this.state.diagnosisItems}
                                userInputDiagnosis={
                                  this.state.userInputDiagnosis
                                }
                                /*   activeSuggestion={this.state.activeSuggestion} */
                                filteredSuggestionsDiagnosis={
                                  this.state.filteredSuggestionsDiagnosis
                                }
                                showDiagnosisSuggestions={
                                  this.state.showDiagnosisSuggestions
                                }
                              />
                              {this.state.MSG ? (
                                <h2>{this.state.MSG}</h2>
                              ) : null}
                            </div>
                          </td>
                          <td>
                            <select
                              name="Type"
                              className="Input-sama6"
                              defaultValue={diagnosisInfo[code]["Type"]}
                              onChange={(e) => {
                                this.handleInputTextChange(
                                  e,
                                  code,
                                  "diagnosis"
                                );
                              }}
                            >
                              <option value="Principal">Principal</option>
                              <option value="Secondary">Secondary</option>
                            </select>
                          </td>
                          {diagnosisInfo &&
                          diagnosisInfo[code] &&
                          diagnosisInfo[code]["DxInfo"] ? (
                            <td>
                              <table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Type</th>
                                    <th>Code</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {diagnosisInfo[code] &&
                                    diagnosisInfo[code]["DxInfo"] &&
                                    diagnosisInfo[code]["DxInfo"].map(function (
                                      info,
                                      index
                                    ) {
                                      return (
                                        <tr>
                                          <td>
                                            <input
                                              name="Type"
                                              /* className="Input-sama6" */
                                              type="text"
                                              defaultValue={
                                                diagnosisInfo[code]["DxInfo"][
                                                  index
                                                ]["Type"]
                                              }
                                              onChange={(e) => {
                                                handleInputTextChangeForDxInfo(
                                                  e,
                                                  code,
                                                  index
                                                );
                                              }}
                                            />
                                          </td>
                                          <td>
                                            <select
                                              name="Code"
                                              onChange={(e) => {
                                                handleInputTextChangeForDxInfo(
                                                  e,
                                                  code,
                                                  index
                                                );
                                              }}
                                              defaultValue={
                                                diagnosisInfo[code]["DxInfo"][
                                                  index
                                                ]["Code"]
                                              }
                                            >
                                              <option value="Y">Y</option>
                                              <option value="N">N</option>
                                              <option value="U">U</option>
                                              <option value="W">W</option>
                                              <option value="1">1</option>
                                              <option value="OP">OP</option>
                                            </select>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                              <input
                                type="button"
                                value="Add DX"
                                onClick={() => {
                                  addNewDX(code);
                                }}
                              />
                            </td>
                          ) : newDiagnosisInfo &&
                            newDiagnosisInfo[code] &&
                            newDiagnosisInfo[code]["DxInfo"] ? (
                            <td>
                              <table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Type</th>
                                    <th>Code</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {newDiagnosisInfo[code] &&
                                    newDiagnosisInfo[code]["DxInfo"] &&
                                    newDiagnosisInfo[code]["DxInfo"].map(
                                      function (info, index) {
                                        return (
                                          <tr>
                                            <td>
                                              <input
                                                name="Type"
                                                /* className="Input-sama6" */
                                                type="text"
                                                defaultValue={
                                                  newDiagnosisInfo[code][
                                                    "DxInfo"
                                                  ][index]["Type"]
                                                }
                                                onChange={(e) => {
                                                  handleInputTextChangeForDxInfo(
                                                    e,
                                                    code,
                                                    index
                                                  );
                                                }}
                                              />
                                            </td>
                                            <td>
                                              <select
                                                name="Code"
                                                onChange={(e) => {
                                                  handleInputTextChangeForDxInfo(
                                                    e,
                                                    code,
                                                    index
                                                  );
                                                }}
                                                defaultValue={
                                                  newDiagnosisInfo[code][
                                                    "DxInfo"
                                                  ][index]["Code"]
                                                }
                                              >
                                                <option value="Y">Y</option>
                                                <option value="N">N</option>
                                                <option value="U">U</option>
                                                <option value="W">W</option>
                                                <option value="1">1</option>
                                                <option value="OP">OP</option>
                                              </select>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}{" "}
                                </tbody>
                              </table>
                              <input
                                type="button"
                                value="Add DX"
                                onClick={() => {
                                  addNewDX(code);
                                }}
                              />
                            </td>
                          ) : (
                            <td>
                              <input
                                type="button"
                                value="Add Dx"
                                onClick={() => {
                                  addNewDX(code);
                                }}
                              />
                            </td>
                          )}
                        </tr>
                      );
                    }, this)}
                </tbody>
              </table>
              <p>Activities Details</p>
              <table striped bordered hover>
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Type</th>
                    <th>Start</th>
                    <th>Quantity</th>
                    <th>Net</th>
                    <th>Clinician</th>
                    <th>Prior Authorization ID</th>
                    <th>Observations</th>
                  </tr>
                </thead>
                <tbody>
                  {activitiesInfo &&
                    Object.keys(activitiesInfo).map(function (code, index) {
                      return (
                        <tr key={index}>
                          <td>
                            <div>
                              <Form
                                inputsTypes={["SamaActivityList1"]}
                                elementsNames={["ActivityCodes"]}
                                inputStyle="IT3"
                                submitStyle="BS1"
                                addCPTsCode={(e) => {
                                  this.addCPTsCode(e, code, "activities");
                                }}
                                section={this.state.section}
                                onChange={this.onChange}
                                onClick={this.onClick}
                                BtnActive={this.state.BtnActive}
                                activeSuggestion={this.state.activeSuggestion}
                                allActivity={this.state.ActivityItems}
                                userInputActivity={this.state.userInputActivity}
                                filteredSuggestionsActivity={
                                  this.state.filteredSuggestionsActivity
                                }
                                showActivitySuggestions={
                                  this.state.showActivitySuggestions
                                }
                              />

                              {this.state.MSG ? (
                                <h2>{this.state.MSG}</h2>
                              ) : null}
                            </div>
                          </td>
                          <td>
                            <select
                              className="Input-sama6"
                              /*  style={{
                                width: "115px !important",
                              }} */
                              name="Type"
                              defaultValue={activitiesInfo[code]["Type"]}
                              onChange={(e) => {
                                this.handleInputTextChange(
                                  e,
                                  code,
                                  "activities"
                                );
                              }}
                            >
                              <option value="3">CPT</option>
                              <option value="4">HCPCS</option>
                              <option value="6">Dental</option>
                              <option value="5">Drug</option>
                              <option value="9">DRG</option>
                              <option value="8">Service</option>
                            </select>
                          </td>
                          <td>
                            <input
                              name="Start"
                              className="Input-sama6"
                              type="text"
                              defaultValue={activitiesInfo[code]["Start"]}
                              onChange={(e) => {
                                this.handleInputTextChange(
                                  e,
                                  code,
                                  "activities"
                                );
                              }}
                            />
                          </td>
                          <td>
                            <input
                              name="Quantity"
                              className="Input-sama6"
                              type="text"
                              defaultValue={activitiesInfo[code]["Quantity"]}
                              onChange={(e) => {
                                this.handleInputTextChange(
                                  e,
                                  code,
                                  "activities"
                                );
                              }}
                            />
                          </td>
                          <td>
                            <input
                              name="Net"
                              className="Input-sama6"
                              type="text"
                              defaultValue={activitiesInfo[code]["Net"]}
                              onChange={(e) => {
                                this.handleInputTextChange(
                                  e,
                                  code,
                                  "activities"
                                );
                              }}
                            />
                          </td>
                          <td>
                            <input
                              name="Clinician"
                              className="Input-sama6"
                              type="text"
                              defaultValue={activitiesInfo[code]["Clinician"]}
                              onChange={(e) => {
                                this.handleInputTextChange(
                                  e,
                                  code,
                                  "activities"
                                );
                              }}
                            />
                          </td>
                          <td>
                            <input
                              name="PriorAuthorizationID"
                              className="Input-sama6"
                              type="text"
                              defaultValue={
                                activitiesInfo[code]["PriorAuthorizationID"]
                              }
                              onChange={(e) => {
                                this.handleInputTextChange(
                                  e,
                                  code,
                                  "activities"
                                );
                              }}
                            />
                          </td>

                          {activitiesInfo[code]["Observation"] ? (
                            <td>
                              {" "}
                              <table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Type</th>
                                    <th>Code</th>
                                    <th>Value</th>
                                    <th>ValueType</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.keys(
                                    activitiesInfo[code]["Observation"]
                                  ).map(function (obs, index) {
                                    return (
                                      <tr>
                                        <td>
                                          {
                                            <select
                                              style={{
                                                width: "115px !important",
                                              }}
                                              name="Type"
                                              defaultValue={
                                                activitiesInfo[code][
                                                  "Observation"
                                                ][index]["Type"]
                                              }
                                              onChange={(e) => {
                                                handleInputTextChangeForObservation(
                                                  e,
                                                  code,
                                                  "activitiesObs",
                                                  index
                                                );
                                              }}
                                            >
                                              <option value="LOINC">
                                                LOINC
                                              </option>
                                              <option value="Text">Text</option>
                                              <option value="File">File</option>
                                              <option value="Universal Dental">
                                                Universal Dental
                                              </option>
                                              <option value="Financial">
                                                Financial
                                              </option>
                                              <option value="Grouping">
                                                Grouping
                                              </option>
                                              <option value="ERX">ERX</option>
                                              <option value="Result">
                                                Result
                                              </option>
                                            </select>
                                          }
                                        </td>
                                        <td>
                                          {
                                            <input
                                              name="Code"
                                              className="Input-sama6"
                                              type="text"
                                              defaultValue={
                                                activitiesInfo[code][
                                                  "Observation"
                                                ][index]["Code"]
                                              }
                                              onChange={(e) => {
                                                /* this. */ handleInputTextChangeForObservation(
                                                  e,
                                                  code,
                                                  "activitiesObs",
                                                  index
                                                );
                                              }}
                                            />
                                          }
                                        </td>
                                        <td>
                                          <input
                                            name="Value"
                                            className="Input-sama6"
                                            type="text"
                                            defaultValue={
                                              activitiesInfo[code][
                                                "Observation"
                                              ][index]["Value"]
                                            }
                                            onChange={(e) => {
                                              /* this. */ handleInputTextChangeForObservation(
                                                e,
                                                code,
                                                "activitiesObs",
                                                index
                                              );
                                            }}
                                          />
                                        </td>
                                        <td>
                                          {
                                            <input
                                              name="ValueType"
                                              className="Input-sama6"
                                              type="text"
                                              defaultValue={
                                                activitiesInfo[code][
                                                  "Observation"
                                                ][index]["ValueType"]
                                              }
                                              onChange={(e) => {
                                                /* this. */ handleInputTextChangeForObservation(
                                                  e,
                                                  code,
                                                  "activitiesObs",
                                                  index
                                                );
                                              }}
                                            />
                                          }
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>{" "}
                              <input
                                type="button"
                                value="Add Obs"
                                onClick={() => {
                                  addNewObservation(code);
                                }}
                              />
                            </td>
                          ) : newActivitiesInfo &&
                            newActivitiesInfo[code] &&
                            newActivitiesInfo[code]["Observation"] ? (
                            <td>
                              {" "}
                              <table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Type</th>
                                    <th>Code</th>
                                    <th>Value</th>
                                    <th>ValueType</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {newActivitiesInfo &&
                                    newActivitiesInfo[code] &&
                                    newActivitiesInfo[code]["Observation"].map(
                                      function (obs, index) {
                                        return (
                                          <tr>
                                            <td>
                                              {
                                                <select
                                                  style={{
                                                    width: "115px !important",
                                                  }}
                                                  name="Type"
                                                  defaultValue={obs["Type"]}
                                                  onChange={(e) => {
                                                    handleInputTextChangeForObservation(
                                                      e,
                                                      code,
                                                      "activitiesObs",
                                                      index
                                                    );
                                                  }}
                                                >
                                                  <option value="LOINC">
                                                    LOINC
                                                  </option>
                                                  <option value="Text">
                                                    Text
                                                  </option>
                                                  <option value="File">
                                                    File
                                                  </option>
                                                  <option value="Universal Dental">
                                                    Universal Dental
                                                  </option>
                                                  <option value="Financial">
                                                    Financial
                                                  </option>
                                                  <option value="Grouping">
                                                    Grouping
                                                  </option>
                                                  <option value="ERX">
                                                    ERX
                                                  </option>
                                                  <option value="Result">
                                                    Result
                                                  </option>
                                                </select>
                                              }
                                            </td>
                                            <td>
                                              {
                                                <input
                                                  name="Code"
                                                  className="Input-sama6"
                                                  type="text"
                                                  defaultValue={obs["Code"]}
                                                  onChange={(e) => {
                                                    handleInputTextChangeForObservation(
                                                      e,
                                                      code,
                                                      "activitiesObs",
                                                      index
                                                    );
                                                  }}
                                                />
                                              }
                                            </td>
                                            <td>
                                              <input
                                                name="Value"
                                                className="Input-sama6"
                                                type="text"
                                                defaultValue={obs["Value"]}
                                                onChange={(e) => {
                                                  handleInputTextChangeForObservation(
                                                    e,
                                                    code,
                                                    "activitiesObs",
                                                    index
                                                  );
                                                }}
                                              />
                                            </td>
                                            <td>
                                              {
                                                <input
                                                  name="ValueType"
                                                  className="Input-sama6"
                                                  type="text"
                                                  defaultValue={
                                                    obs["ValueType"]
                                                  }
                                                  onChange={(e) => {
                                                    handleInputTextChangeForObservation(
                                                      e,
                                                      code,
                                                      "activitiesObs",
                                                      index
                                                    );
                                                  }}
                                                />
                                              }
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                </tbody>
                              </table>
                              <input
                                type="button"
                                value="Add Obs"
                                onClick={() => {
                                  addNewObservation(code);
                                }}
                              />
                            </td>
                          ) : (
                            <td>
                              {" "}
                              <input
                                type="button"
                                value="Add Obs"
                                onClick={() => {
                                  addNewObservation(code);
                                }}
                              />
                            </td>
                          )}
                        </tr>
                      );
                    }, this)}
                </tbody>
              </table>
              <h5>Resubmission Comment</h5>
              <input
                name="comment"
                className="Input-sama6"
                type="text"
                defaultValue={EncounterInfo["comment"]}
                onChange={handleInputTextChange}
              />
              <h5>Resubmission Attachement</h5>
              <input
                type="file"
                className="form-control-file"
                name="fileComment"
                onChange={handleInputTextChange}
              />
              <div>
                <button
                  name="name"
                  className="GenerateBtnStyle9"
                  onClick={this.changeEditMode1}
                >
                  Cancel
                </button>
                <button
                  name="name"
                  //className="GenerateBtnStyle10"
                  onClick={this.update.bind(this)}
                >
                  Save
                </button>
              </div>
            </div>

            <p
              style={{
                fontWeight: "bold",
                fontSize: "15px",
                cursor: "pointer",
                margin: "0 2%",
              }}
              onClick={() => this.setState({ isShowmore: false })}
            >
              {" "}
              Close{" "}
            </p>
          </div>
        </div>

        {addNewObsScreen ? (
          <div className="popup">
            <div
              className="form5"
              style={{
                width: "90%",

                margin: "auto",
                backgroundColor: "white",
              }}
            >
              <select
                name="Type"
                className="Input-sama6"
                /*  defaultValue={diagnosisInfo[code]["Type"]} */
                onChange={(e) => {
                  this.handleInputTextChangeForAddNewObservation(e);
                }}
              >
                <option value="LOINC">LOINC</option>
                <option value="Text">Text</option>
                <option value="File">File</option>
                <option value="Universal Dental">Universal Dental</option>
                <option value="Financial">Financial</option>
                <option value="Grouping">Grouping</option>
                <option value="ERX">ERX</option>
                <option value="Result">Result</option>
              </select>
              <br />
              <br />
              <input
                placeholder="Code"
                name="Code"
                onChange={(e) => {
                  this.handleInputTextChangeForAddNewObservation(e);
                }}
              />
              <br />
              <br />
              <input
                placeholder="Value"
                name="Value"
                onChange={(e) => {
                  this.handleInputTextChangeForAddNewObservation(e);
                }}
              />
              <br />
              <br />
              <input
                placeholder="ValueType"
                name="ValueType"
                onChange={(e) => {
                  this.handleInputTextChangeForAddNewObservation(e);
                }}
              />
              <br />
              <br />
              <input
                type="button"
                value="Confirm"
                onClick={() => {
                  this.confirmAddNewObs();
                }}
              />
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  cursor: "pointer",
                  margin: "0 2%",
                }}
                onClick={() => this.setState({ addNewObsScreen: false })}
              >
                {" "}
                Close{" "}
              </p>
            </div>
          </div>
        ) : null}

        {addNewDXScreen ? (
          <div className="popup">
            <div
              className="form5"
              style={{
                width: "90%",

                margin: "auto",
                backgroundColor: "white",
              }}
            >
              <input
                placeholder="Type"
                name="Type"
                onChange={(e) => {
                  this.handleInputTextChangeForAddNewDX(e);
                }}
              />
              <br />
              <br />

              <select
                name="Code"
                onChange={(e) => {
                  handleInputTextChangeForAddNewDX(e);
                }}
              >
                <option value="Y">Y</option>
                <option value="N">N</option>
                <option value="U">U</option>
                <option value="W">W</option>
                <option value="1">1</option>
                <option value="OP">OP</option>
              </select>
              <br />
              <br />

              <br />
              <br />

              <input
                type="button"
                value="Confirm"
                onClick={() => {
                  this.confirmAddNewDX();
                }}
              />
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  cursor: "pointer",
                  margin: "0 2%",
                }}
                onClick={() => this.setState({ addNewDXScreen: false })}
              >
                {" "}
                Close{" "}
              </p>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  mappingAllClaimstatus(type) {
    let EncounterType;

    switch (type) {
      case 0:
      case "0":
        EncounterType = "New Claim";
        break;
      case 1:
      case "1":
        EncounterType = "SAMA Validated";
        break;
      case 3:
      case "3":
        EncounterType = "SAMA and PO validated";
        break;
      case 4:
      case "4":
        EncounterType = "RA with issues";
        break;
      case 5:
      case "5":
        EncounterType = "RA Payed";
        break;
      case 6:
      case "6":
        EncounterType = "Finished due to exceeding submission allowed value";
        break;
      case 7:
      case "7":
        EncounterType = "Finished AllClaims(partially paid)";
        break;
      case 8:
      case "8":
        EncounterType = "Finished AllClaims(fully rejected)";
        break;

      case 9:
      case "9":
        EncounterType = "AllClaims ready to be submitted";
        break;

      case 10:
      case "10":
        EncounterType = "Correction Resubmission";
        break;

      case 11:
      case "11":
        EncounterType = "Internal Complaint Resubmission";
        break;

      case 12:
      case "12":
        EncounterType = "Denied Claim";
        break;

      default:
        break;
    }
    return EncounterType;
  }
  mappingActivityType(type) {
    let typeText = "";

    switch (type) {
      case 3:
      case "3":
        typeText = "CPT";
        break;
      case 4:
      case "4":
        typeText = "HCPCS";
        break;
      case 6:
      case "6":
        typeText = "Dental";
        break;
      case 5:
      case "5":
        typeText = "Drug";
        break;
      case 9:
      case "9":
        typeText = "DRG";
        break;
      case 8:
      case "8":
        typeText = "Service";
        break;

      default:
        break;
    }

    return typeText;
  }

  onClickShowMore(recordId) {
    this.setState({
      isShowmore: true, //to pop up details screen
      recordId, //id of clicked record
    });

    let { AllClaims } = this.state;
    if (AllClaims) {
      let claim = AllClaims.filter((c) => c.id == recordId);

      if (claim && claim.length > 0) {
        //Encounter Info for selected claims
        let EncounterInfo = claim[0];
        this.setState({ EncounterInfo });

        let { claim_status } = EncounterInfo;
        switch (claim_status) {
          case "10":
          case 10:
            this.setState({ ReSubmissionType: "Correction" });

            break;

          case "11":
          case 11:
            this.setState({ ReSubmissionType: "Internal Compalaint" });

            break;

          default:
            break;
        }

        //diagnosis details for selected claim
        let diagnosisInfo = claim[0]["diagnosis"];

        this.setState({ diagnosisInfo });
        //activity details for selected claim
        let activitiesInfo = claim[0]["activities"];

        let activityCodes = Object.keys(activitiesInfo);

        for (let index = 0; index < activityCodes.length; index++) {
          const code = activityCodes[index];

          if (activitiesInfo[[code]]["Observation"] != undefined) {
            if (!this.state.observationFlag) {
              this.setState({ observationFlag: true });
            }
            break;
          }
        }
        this.setState({ activitiesInfo });
      }
    }
  } //end fun

  //deactivate edit mode
  changeEditMode1 = () => {
    let newActivitiesInfo = cloneDeep(this.state.activitiesInfo);
    this.setState({
      isEditMode1: !this.state.isEditMode1,
      newActivitiesInfo,
      observationObj: {},
      DXObj: {},
    });
  };
  mappingEncType(type) {
    let EncounterType;
    switch (type) {
      case 1:
        EncounterType = "No Bed + No emergency room";
        break;
      case 2:
        EncounterType = "No Bed + Emergency room";
        break;
      case 3:
        EncounterType = "Inpatient Bed + No emergency room";
        break;
      case 4:
        EncounterType = "Inpatient Bed + Emergency room";
        break;
      case 5:
        EncounterType = "Daycase Bed + No emergency room";
        break;
      case 6:
        EncounterType = "Daycase Bed + Emergency room";
        break;
      case 7:
        EncounterType = "Nationals Screening";
        break;
      case 8:
        EncounterType = "New Visa Screening";
        break;
      case 9:
        EncounterType = "Renewal Visa Screening";
        break;
      case 12:
        EncounterType = "Home";
        break;
      case 13:
        EncounterType = "Assisted Living Facility";
        break;
      case 15:
        EncounterType = "Mobile Unit";
        break;

      case 41:
        EncounterType = "Ambulance - Land";
        break;

      case 42:
        EncounterType = " Ambulance - Air or Water";
        break;

      default:
        break;
    }

    return EncounterType;
  }
  mappingEncStartType(type) {
    let EncounterType;

    switch (type) {
      case 1:
      case "1":
        EncounterType = "Elective";
        break;
      case 2:
      case "2":
        EncounterType = "Emergency";
        break;
      case 3:
      case "3":
        EncounterType = "Transfer";
        break;
      case 4:
      case "4":
        EncounterType = "Live birth";
        break;
      case 5:
      case "5":
        EncounterType = "Still birth";
        break;
      case 6:
      case "6":
        EncounterType = "Dead On Arrival";
        break;
      case 7:
      case "7":
        EncounterType = "Continuing Encounter";
        break;

      default:
        break;
    }

    return EncounterType;
  }
  mappingEncEndType(type) {
    let EncounterType;

    switch (type) {
      case 1:
      case "1":
        EncounterType = "Discharged with approval";
        break;
      case 2:
      case "2":
        EncounterType = "Discharged against advice";
        break;
      case 3:
      case "3":
        EncounterType = "Discharged absent without leave";
        break;
      case 4:
      case "4":
        EncounterType = "Discharge transfer to acute care";
        break;
      case 5:
      case "5":
        EncounterType = "Deceased";
        break;
      case 6:
      case "6":
        EncounterType = "Not discharged";
        break;
      case 7:
      case "7":
        EncounterType =
          "Discharge transfer to non-acute care(Transfer to long term care).";
        break;

      default:
        break;
    }

    return EncounterType;
  }
  onChange = ({ target: { name, value } }, listName) => {
    if (value.length <= 1) {
      value = value.trim();
    }
    switch (listName) {
      case "DiagnosisCodes":
        this.setState({
          section: "Diagnosis",
        });
        break;
      default:
        this.setState({
          section: "activities",
        });
        break;
    }

    if (listName === "DiagnosisCodes" && value === "") {
      this.setState({
        BtnActive: false,
        userInputDiagnosis: "",
      });
    } else if (listName === "ActivityCodes" && value === "") {
      this.setState({
        BtnActive: false,
        userInputActivity: "",
      });
    } else if (listName === "DiagnosisCodes") {
      this.setState({ BtnActive: true });
      const suggestions = this.state.diagnosisItems;
      let filteredSuggestions = [];
      if (value.length > 1) {
        const regex = new RegExp(`${value.toLowerCase()}`); //filter
        filteredSuggestions = suggestions
          .sort()
          .filter((v) => regex.test(v.toLowerCase()));
      }
      // Update the user input and filtered suggestions, reset the active
      // suggestion and make sure the suggestions are shown
      this.setState({
        activeSuggestion: 0,
        filteredSuggestionsDiagnosis: filteredSuggestions,
        showDiagnosisSuggestions: true,
        userInputDiagnosis: value,
      });
    } else {
      this.setState({ BtnActive: true });
      const suggestions = this.state.ActivityItems;
      let filteredSuggestions = [];
      if (value.length > 1) {
        const regex = new RegExp(`${value.toLowerCase()}`);
        filteredSuggestions = suggestions
          .sort()
          .filter((v) => regex.test(v.toLowerCase()));
      }

      // Update the user input and filtered suggestions, reset the active
      // suggestion and make sure the suggestions are shown
      this.setState({
        activeSuggestion: 0,
        filteredSuggestionsActivity: filteredSuggestions,
        showActivitySuggestions: true,
        userInputActivity: value,
      });
    }
  };
  // Event fired when the user clicks on a suggestion of Auto Complete Component
  onClick = (e, codeSearch) => {
    codeSearch = e.currentTarget.innerText;
    // Update the user input and reset the rest of the state
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showDiagnosisSuggestions: false,
      showActivitySuggestions: false,
    });

    if (
      this.state.filteredSuggestionsActivity &&
      this.state.filteredSuggestionsActivity.length > 0
    ) {
      this.setState({
        // userInputActivity:this.state.filteredSuggestionsActivity[this.state.activeSuggestion]
        userInputActivity: e.currentTarget.innerText,
        filteredSuggestionsActivity: [],
      });
    }

    if (
      this.state.filteredSuggestionsDiagnosis &&
      this.state.filteredSuggestionsDiagnosis.length > 0
    ) {
      this.setState({
        userInputDiagnosis: e.currentTarget.innerText,
        filteredSuggestionsDiagnosis: [],
      });
    }
    //push to code
    this.setState({
      codeSearch: { ...this.state.codeSearch, Text: codeSearch },
    });
  };

  onClickCheckBox = async (e, selected) => {
    //on click on checkbox
    this.setState({
      Message: undefined,
      status: undefined,
      dhpoErrorsReport: NaN,
      dhpoTable: NaN,
      dhpoRows: NaN,
      ErrorMessages: NaN,
      submissionErrors: NaN,
    });
    let { selectedClaims } = this.state;

    if (e.target.checked) {
      selectedClaims.push(selected);
    } else {
      selectedClaims.forEach((element, index) => {
        if (element.id == selected.id) {
          selectedClaims.splice(index, 1);
        }
      });
    }
    this.setState({ selectedClaims });
    let xmlfile = await this.convertJsonToXML(this.state.selectedClaims);
  };
  async convertJsonToXML(selectedClaims) {
    let payersSubmissions = {};
    let SubmissionXmlArray = [];

    if (selectedClaims && selectedClaims.length > 0) {
      selectedClaims.forEach((claim) => {
        let claimDetails = [];
        let claimID = { ID: claim["claim_id"] };
        let MemberID = { MemberID: claim["member_id"] };
        let IDPayer = { IDPayer: claim["id_payer"] };
        let PayerID = { PayerID: claim["payer_id"] };
        let ProviderID = { ProviderID: claim["provider_id"] },
          EmiratesIDNumber = {
            EmiratesIDNumber: claim["emirates_id_number"],
          };
        let Gross = { Gross: claim["gross"] },
          PatientShare = {
            PatientShare: claim["patient_share"],
          },
          Net = { Net: claim["net"] };

        let Encounter = {
          Encounter: [
            { FacilityID: claim["enc_facility_id"] },
            { Type: claim["enc_type"] },
            { PatientID: claim["enc_patient_id"] },
            { Start: claim["enc_start"] },
            { End: claim["enc_end"] },
            { StartType: claim["start_type"] },
            { TransferSource: claim["transfer_source"] },
            { TransferDestination: claim["transfer_destination"] },
          ],
        };
        claimDetails = [
          claimID,
          IDPayer,
          MemberID,

          PayerID,
          ProviderID,
          EmiratesIDNumber,
          Gross,
          PatientShare,
          Net,
          Encounter,
        ];
        let { diagnosis, activities } = claim;
        let diagnosisCodes = Object.keys(diagnosis);
        diagnosisCodes.forEach((code) => {
          let Diagnosis = {
            Diagnosis: [{ Type: diagnosis[code]["Type"] }, { Code: code }],
          };
          let DxInfo = diagnosis[code]["DxInfo"];

          if (DxInfo) {
            DxInfo.forEach((DX) => {
              //let DXTags=[Code,Type]
              let OneDXInfo = [{ Code: DX["Code"] }, { Type: DX["Type"] }];

              Diagnosis["Diagnosis"].push({
                DxInfo: OneDXInfo,
              });
            });
          }
          claimDetails.push(Diagnosis);
        });
        let activityCodes = Object.keys(activities);
        activityCodes.forEach((code) => {
          let Activity = {
            Activity: [
              { ID: activities[code]["ID"] },
              { Start: activities[code]["Start"] },
              { Type: activities[code]["Type"] },
              { Code: code },
              { Quantity: activities[code]["Quantity"] },
              { Net: activities[code]["Net"] },
              { Clinician: activities[code]["Clinician"] },
              {
                PriorAuthorizationID: activities[code]["PriorAuthorizationID"],
              },
            ],
          };
          let Observation = activities[code]["Observation"];

          if (Observation) {
            Observation.forEach((obs) => {
              // let obsKeys = ["Type", "Code", "Value", "ValueType"];
              let OneObservation = [
                { Type: obs["Type"] },
                { Code: obs["Code"] },
                { Value: obs["Value"] },
                { ValueType: obs["ValueType"] },
              ];

              Activity["Activity"].push({ Observation: OneObservation });
            });
          }

          claimDetails.push(Activity);
        });
        let Resubmission = {
          Resubmission: [
            {
              Type:
                claim["claim_status"] == "10"
                  ? "correction"
                  : claim["claim_status"] == "11"
                  ? "internal complaint"
                  : "",
            },
            { Comment: claim["comment"] },
            { Attachment: claim["ressattachement"] },
          ],
        };
        claimDetails.push(Resubmission);

        /*  if (!payersSubmissions[claim["payer_id"]]) {
          payersSubmissions[claim["payer_id"]] = [];
        }
        payersSubmissions[claim["payer_id"]].push({ Claim: claimDetails }); */

        if (!payersSubmissions[claim["receiver_id"]]) {
          payersSubmissions[claim["receiver_id"] ? claim["receiver_id"] : " "] =
            [];
        }

        payersSubmissions[
          claim["receiver_id"] ? claim["receiver_id"] : " "
        ].push({ Claim: claimDetails });
      });

      if (payersSubmissions) {
        let DateTime = moment().format();
        let Date = DateTime.split("T")[0];
        let Time = DateTime.split("T")[1];
        let TimeArray = (Time = Time.split("+")[0]);
        let TimeHMS = TimeArray.split(":");
        let TimeString = TimeHMS[0] + ":" + TimeHMS[1];
        let DateArray = Date.split("-"); //yyyy mm dd

        let DateString = DateArray[2] + "/" + DateArray[1] + "/" + DateArray[0]; //dd mm yyyy
        let FinalDateTimeString = DateString + " " + TimeString; // dd/mm/yyyy hh:mm
        let payers = Object.keys(payersSubmissions);
        payers.forEach((payer) => {
          let jsonObj = {
            "Claim.Submission": [
              {
                Header: [
                  { SenderID: localStorage.getItem("dhafacilityid") },
                  { ReceiverID: payer },
                  { TransactionDate: FinalDateTimeString },
                  { RecordCount: payersSubmissions[payer].length },
                  { DispositionFlag: "PRODUCTION" },
                ],
              },
            ],
          };
          payersSubmissions[payer].forEach((claim) => {
            jsonObj["Claim.Submission"].push({ Claim: claim.Claim });
          });
          let XML = xml(jsonObj, true);
          SubmissionXmlArray.push({ payer, XML });
          this.setState({ submissionXML: SubmissionXmlArray });
        });
      }
    } else {
      this.setState({ submissionXML: [] });
    }
  }

  onSubmitToPO = async (event) => {
    let DateTime = moment().format();
    let Date = DateTime.split("T")[0];
    let Time = DateTime.split("T")[1];
    let TimeArray = (Time = Time.split("+")[0]);
    let TimeHMS = TimeArray.split(":");
    let TimeString = TimeHMS[0] + ":" + TimeHMS[1];
    let DateArray = Date.split("-"); //yyyy mm dd

    let DateString = DateArray[2] + "/" + DateArray[1] + "/" + DateArray[0]; //dd mm yyyy
    let FinalDateTimeString = DateString + " " + TimeString; // dd/mm/yyyy hh:mm

    this.setState({
      samaResponseMessage: NaN,
      markAsSAMAValidatedResponseMSG: NaN,
      SamaRows: NaN,
      submitToPOStart: true,
      ErrorMessages: NaN,

      dhpoErrorsReport: NaN,
    });

    const data = await axios.post(KayanURL + "api/sama/storeSubmission", {
      data: this.state.submissionXML,
      apiToken: Cookies.get("SamaId"),
      submissionDate: FinalDateTimeString,
      functionType: "resubmission",
      submissionId: this.state.submissionId,
    });

    if (data) {
      this.setState({ submitToPOStart: false });

      this.setState({
        submissionXML: undefined,
        defaultValue: false,
      });

      let response = data["data"];

      if (response) {
        if (response.success) {
          this.setState({
            successMessage:
              "The submission file was re-submitted successfully to PO",
          });

          let { selectedClaims } = this.state;
          if (selectedClaims && selectedClaims.length > 0) {
            selectedClaims.forEach(async (claim) => {
              let { id, timeline, claim_status, claim_id } = claim;
              timeline.push({ 19: this.generateCurrentDate() });
              //call update endpoint
              let updateResponse = await axios.put(
                KayanURL + "api/sama/update",
                {
                  apiToken: Cookies.get("SamaId"),
                  id: id,
                  timeline: JSON.stringify(timeline),
                  claim_id,
                  claim_status: "19",
                  prev_claim_status: claim_status,
                  functionType: "edit",
                }
              );

              if (updateResponse) {
                if (!updateResponse.data.success) {
                  alert(updateResponse.data.error);
                  return;
                }
              }
            });
          }
        } else {
          this.setState({
            ErrorMessages: response.errors,
          });

          if (response.errorsReport) {
            this.setState({
              dhpoErrorsReport: response.errorsReport.split("\n"),
            });
          }
        }
      }
    }
  };

  submitClaims = async () => {
    //submit to dhpo
    this.onSubmitToPO();
  };
  downloadTxtFile = (index) => {
    const element = document.createElement("a");

    const file = new Blob([this.state.submissionXML[index]["XML"]], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download =
      `${this.state.submissionId}-` +
      this.state.submissionXML[index]["payer"] +
      ".xml";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  generateCurrentDate() {
    let date_ob = new Date();
    let date = ("0" + date_ob.getDate()).slice(-2);

    // current month
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

    // current year
    let year = date_ob.getFullYear();

    // current hours
    let hours = date_ob.getHours();

    // current minutes
    let minutes = date_ob.getMinutes();

    // prints date & time in YYYY-MM-DD HH:MM:SS format

    let dateFormat =
      date + "/" + month + "/" + year + " " + hours + ":" + minutes;

    return dateFormat;
  }
  onClickReverse = async (recordId) => {
    let { AllClaims } = this.state;
    let index;
    let claim = AllClaims.filter(function (c, i) {
      if (c.id == recordId) {
        index = i;
        return c;
      }
    });

    if (claim && claim.length > 0) {
      let { claim_status, prev_claim_status, timeline } = claim[0];
      //resubmissionReversed

      timeline.push({ resubmissionReversed: this.generateCurrentDate() });
      let response = await axios.put(KayanURL + "api/sama/update", {
        apiToken: Cookies.get("SamaId"),
        id: recordId,
        prev_claim_status: claim_status,
        claim_status: prev_claim_status,
        timeline: JSON.stringify(timeline),
      });

      if (response) {
        let { data } = response;
        if (data.success) {
          this.search();
        }
      }
    }
  };
  handleStartEndDate({ target: { name, value } }) {
    if (value) {
      let date_time = value.split("T");
      if (date_time) {
        let date = date_time[0]; // mm-dd-yyyy -----> dd/mm/yyyy
        date = date.replace(/\-/g, "/").split("/");
        let date2 = date[0] + "/" + date["2"] + "/" + date["1"];
        date2 = date2.split("/").reverse().join("/");

        let time = date_time[1];
        let finalDateTime = date2 + " " + time;

        if (name == "enc_start") {
          this.setState({ enc_start: finalDateTime });
        } else if (name == "enc_end") {
          this.setState({ enc_end: finalDateTime });
        }
      }
    }
  }

  onScelectPayerName({ target: { name, value } }) {
    this.setState({ payer_id: value, isSearch: false, payerId: value });
  }

  deSelect(e) {
    var ele = document.getElementsByName("row");
    for (var i = 0; i < ele.length; i++) {
      if (ele[i].type == "checkbox") {
        ele[i].checked = false;
        ele[i].onChange = this.onClickCheckBox(e, this.state.AllClaims[i]);
      }
    }
  }

  selectAll(e) {
    if (e.target.checked) {
      var ele = document.getElementsByName("row");

      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          if (!ele[i].checked) {
            ele[i].checked = true;
            ele[i].onChange = this.onClickCheckBox(e, this.state.AllClaims[i]);
          }
        }
      }
    } else {
      this.deSelect(e);
    }
  }

  render() {
    let {
      AllClaims,
      isShowmore,
      isEditMode1,
      claim_status,
      ReSubmissionType,
      EncounterInfo,
      activitiesInfo,
      diagnosisInfo,
      defaultValue,
      selectedClaims,
      submissionXML,
      dhpoRows,
      payers,
      isSearch,
      successMessage,
      ErrorMessages,
      dhpoErrorsReport,
    } = this.state;
    let { mappingActivityType } = this;

    if (activitiesInfo) {
      let activityCodes = Object.keys(activitiesInfo);

      for (let index = 0; index < activityCodes.length; index++) {
        const code = activityCodes[index];

        if (activitiesInfo[[code]]["Observation"] != undefined) {
          if (!this.state.observationFlag) {
            this.setState({ observationFlag: true });
          }
          break;
        }
      }
    }
    let payersList;
    if (payers && payers.length > 0) {
      payersList = payers.map((payer, index) => {
        return (
          <option key={index} value={payer["eclaimlink_id"]}>
            {payer["payer_name"]}
          </option>
        );
      });
    }

    let ClaimListTable;
    if (AllClaims && AllClaims.length > 0) {
      ClaimListTable = AllClaims.map((row, index) => {
        return (
          <tr key={index}>
            <td>
              <input
                name="row"
                type="checkbox"
                onChange={(e) => this.onClickCheckBox(e, row)}
                checked={defaultValue}
              ></input>
            </td>
            <td
            /*   onDoubleClick={() => {
                this.setState({ editableRow: row });
                this.changeEditMode();
              }} */
            >
              {row["id"]}
            </td>
            <td>{row["claim_id"]}</td>
            <td>{row["provider_id"]}</td>
            <td>{row["sender_id"]}</td>
            <td>{row["receiver_id"]}</td>
            <td>{row["transaction_date"]}</td>

            <td>{this.mappingAllClaimstatus(row["claim_status"])}</td>
            <td>
              <button
                style={{
                  color: "white",
                  backgroundColor: "#37ab45",
                  border: "none",
                }}
                onClick={() => this.onClickReverse(row["id"])}
              >
                Reverse
              </button>
            </td>
            <td>
              <button
                style={{
                  color: "white",
                  backgroundColor: "#37ab45",
                  border: "none",
                }}
                onClick={() => this.onClickShowMore(row["id"])}
              >
                show more
              </button>
            </td>
          </tr>
        );
      });
    }

    //dhpo response

    let dhpoTable;
    if (dhpoErrorsReport && dhpoErrorsReport.length > 0) {
      dhpoTable = dhpoErrorsReport.map((row, index) => {
        if (index != 0 && index != dhpoErrorsReport.length - 1) {
          let elements = row.split(",");
          return (
            <tr key={index}>
              {elements.map((e, i) => {
                return <td key={i}>{e}</td>;
              })}
            </tr>
          );
        }
      });
    }

    let submissionErrors;
    if (
      !this.state.submitToPOStart &&
      this.state.ErrorMessages &&
      this.state.ErrorMessages.length > 0
    ) {
      submissionErrors = ErrorMessages.map((error) => {
        return <h5 style={{ color: "red" }}>{error}</h5>;
      });
    }
    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-4">
              <div className="row">
                <div className="col-sm-4" style={{ marginTop: "20px" }}>
                  Payer
                </div>
                <div className="col-sm-8">
                  <select onChange={this.onScelectPayerName} required>
                    <option>select</option>
                    {payersList}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="row">
                <div className="col-sm-4" style={{ marginTop: "20px" }}>
                  Start Date
                </div>
                <div className="col-sm-8">
                  <input
                    name="enc_start"
                    style={{ marginTop: "20px" }}
                    onChange={this.handleStartEndDate}
                    type="datetime-local"
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="row">
                <div className="col-sm-4" style={{ marginTop: "20px" }}>
                  End Date
                </div>
                <div className="col-sm-8">
                  <input
                    name="enc_end"
                    style={{ marginTop: "20px" }}
                    onChange={this.handleStartEndDate}
                    type="datetime-local"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div>
            <input
              type="submit"
              value="Search"
              className="BS1"
              onClick={this.search.bind(this)}
              name="name"
            />
          </div>

          {AllClaims && AllClaims.length > 0 ? (
            <Table responsive striped bordered hover size="sm">
              <thead style={{ background: "#3ab449", color: " white" }}>
                <tr>
                  <th>
                    {" "}
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        this.checked ? this.deSelect(e) : this.selectAll(e)
                      }
                      checked={defaultValue}
                    ></input>
                  </th>
                  <th>ID</th>
                  <th>Claim ID</th>
                  <th>Provider ID</th>
                  <th>Sender Id</th>
                  <th>Receiver ID</th>
                  <th>Transaction Date</th>

                  <th>Status</th>
                  <th>Reverse</th>
                  <th>Claim details</th>
                </tr>
              </thead>
              <tbody>{ClaimListTable}</tbody>
            </Table>
          ) : isSearch ? (
            <h6>There is no Claim mark for Resubmitted</h6>
          ) : null}

          <div>
            {submissionXML
              ? submissionXML.map(function (xml, index) {
                  return (
                    <button
                      onClick={() => {
                        this.downloadTxtFile(index);
                      }}
                      style={{
                        backgroundColor: "#ffffff",
                        border: "none",
                        display: "block",
                        textDecoration: "underline",
                        margin: "auto",
                        marginBottom: "15px",
                      }}
                    >
                      {`Click here to download the generated submission file(${this.state.submissionId}-${submissionXML[index]["payer"]}.xml)`}
                    </button>
                  );
                }, this)
              : null}
          </div>

          {selectedClaims && selectedClaims.length > 0 ? (
            <Button
              Value="Submit to PO"
              onClick={this.submitClaims}
              Style="BS1"
            />
          ) : null}
          <br />
          {this.state.submitToPOStart ? (
            <Spinner animation="border" variant="success" />
          ) : null}
          {!this.state.submitToPOStart &&
          this.state.ErrorMessages &&
          submissionErrors
            ? submissionErrors
            : null}

          {!this.state.submitToPOStart && successMessage ? (
            <h5 style={{ color: "green" }}>{successMessage}</h5>
          ) : null}

          {dhpoErrorsReport ? (
            <Table responsive striped bordered hover size="sm">
              <thead style={{ background: "#3ab449", color: " white" }}>
                <tr>
                  {dhpoErrorsReport[0].split(",").map((r, index) => {
                    return <th key={index}> {r} </th>;
                  })}
                </tr>
              </thead>
              <tbody>{dhpoTable}</tbody>
            </Table>
          ) : null}

          {isShowmore ? (
            isEditMode1 && ReSubmissionType == "Correction" ? (
              this.renderEditView1()
            ) : (
              <div>
                <div className="popup">
                  <div
                    onDoubleClick={this.changeEditMode1}
                    className="form5"
                    style={{
                      width: "90%",
                      margin: "auto",
                      backgroundColor: "white",
                    }}
                  >
                    <div className="form5">
                      <h5> Encounter Details</h5>
                      <table striped bordered hover>
                        <thead
                          style={{ background: "#3ab449", color: " white" }}
                        >
                          <tr>
                            <th>Member ID</th>
                            <th> Payer ID</th>
                            <th>Emirates ID Number</th>
                            <th>Gross</th>
                            <th>Patient Share</th>
                            <th>Net</th>
                            <th>Facility ID</th>

                            <th>Encounter Type</th>
                            <th>Patient ID</th>
                            <th>Start</th>
                            <th>End</th>
                            <th>Encounter Start Type</th>

                            <th>Encounter End Type</th>

                            <th>Transfer Source</th>
                            <th>Transfer Destination</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{EncounterInfo["member_id"]}</td>

                            <td>{EncounterInfo["payer_id"]}</td>
                            <td>{EncounterInfo["emirates_id_number"]}</td>
                            <td>{EncounterInfo["gross"]}</td>
                            <td>{EncounterInfo["patient_share"]}</td>
                            <td>{EncounterInfo["net"]}</td>

                            <td>{EncounterInfo["enc_facility_id"]}</td>
                            <td>
                              {this.mappingEncType(EncounterInfo["enc_type"])}
                            </td>
                            <td>{EncounterInfo["enc_patient_id"]}</td>

                            <td>{EncounterInfo["enc_start"]}</td>
                            <td>{EncounterInfo["enc_end"]}</td>
                            <td>
                              {this.mappingEncStartType(
                                EncounterInfo["start_type"]
                              )}
                            </td>
                            <td>
                              {this.mappingEncEndType(
                                EncounterInfo["end_type"]
                              )}
                            </td>
                            <td>{EncounterInfo["transfer_source"]}</td>
                            <td>{EncounterInfo["transfer_destination"]}</td>
                          </tr>
                        </tbody>
                      </table>
                      <h5>Disgnosis Details</h5>
                      <table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Diagnosis Code</th>
                            <th>Code Description</th>
                            <th>Type</th>
                            <th>DX Info</th>
                          </tr>
                        </thead>
                        <tbody>
                          {diagnosisInfo &&
                            Object.keys(diagnosisInfo).map(function (
                              code,
                              index
                            ) {
                              return (
                                <tr key={index}>
                                  <td>{code}</td>
                                  <td>{diagnosisInfo[code]["Desc"]}</td>
                                  <td>{diagnosisInfo[code]["Type"]}</td>
                                  {diagnosisInfo[code]["DxInfo"] ? (
                                    <td>
                                      <table striped bordered hover>
                                        <thead>
                                          <tr>
                                            <th>Type</th>
                                            <th>Code</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {diagnosisInfo[code]["DxInfo"].map(
                                            function (info, index) {
                                              return (
                                                <tr>
                                                  <td>
                                                    {
                                                      diagnosisInfo[code][
                                                        "DxInfo"
                                                      ][index]["Type"]
                                                    }
                                                  </td>
                                                  <td>
                                                    {" "}
                                                    {
                                                      diagnosisInfo[code][
                                                        "DxInfo"
                                                      ][index]["Code"]
                                                    }
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </table>
                                    </td>
                                  ) : (
                                    <td></td>
                                  )}
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                      <h5>Activities Details</h5>
                      <table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Code</th>
                            <th>Code Description</th>
                            <th>Type</th>
                            <th>Start</th>
                            <th>Quantity</th>
                            <th>Net</th>
                            <th>Clinician</th>
                            <th>Prior Authorization ID</th>

                            {claim_status &&
                            (claim_status == "5" ||
                              claim_status == "7" ||
                              claim_status == "8") ? (
                              <th>Patient Share</th>
                            ) : null}
                            {claim_status &&
                            (claim_status == "5" ||
                              claim_status == "7" ||
                              claim_status == "8") ? (
                              <th>Payment Amount</th>
                            ) : null}
                            {claim_status &&
                            (claim_status == "5" ||
                              claim_status == "7" ||
                              claim_status == "8") ? (
                              <th>status</th>
                            ) : null}
                            {claim_status &&
                            (claim_status == "7" || claim_status == "8") ? (
                              <th>Denial Code</th>
                            ) : null}

                            {claim_status &&
                            (claim_status == "7" || claim_status == "8") ? (
                              <th>Comment</th>
                            ) : null}
                            {this.state.observationFlag ? (
                              <th colspan="4">Observations</th>
                            ) : null}
                          </tr>
                        </thead>
                        <tbody>
                          {activitiesInfo &&
                            Object.keys(activitiesInfo).map(function (
                              code,
                              index
                            ) {
                              return (
                                <tr key={index}>
                                  <td>{code}</td>
                                  <td>{activitiesInfo[code]["Desc"]}</td>
                                  <td>
                                    {mappingActivityType(
                                      activitiesInfo[code]["Type"]
                                    )}
                                  </td>
                                  <td>{activitiesInfo[code]["Start"]}</td>
                                  <td>{activitiesInfo[code]["Quantity"]}</td>
                                  <td>{activitiesInfo[code]["Net"]}</td>
                                  <td>{activitiesInfo[code]["Clinician"]}</td>
                                  <td>
                                    {
                                      activitiesInfo[code][
                                        "PriorAuthorizationID"
                                      ]
                                    }
                                  </td>

                                  {claim_status &&
                                  (claim_status == "5" ||
                                    claim_status == "7" ||
                                    claim_status == "8") ? (
                                    <td>
                                      {activitiesInfo[code]["PatientShare"]}
                                    </td>
                                  ) : null}

                                  {claim_status &&
                                  (claim_status == "5" ||
                                    claim_status == "7" ||
                                    claim_status == "8") ? (
                                    <td>
                                      {activitiesInfo[code]["PaymentAmount"]}
                                    </td>
                                  ) : null}

                                  {claim_status &&
                                  (claim_status == "5" ||
                                    claim_status == "7" ||
                                    claim_status == "8") ? (
                                    <td>
                                      <td>{activitiesInfo[code]["status"]}</td>
                                    </td>
                                  ) : null}
                                  {claim_status &&
                                  (claim_status == "7" ||
                                    claim_status == "8") ? (
                                    <td>
                                      {activitiesInfo[code]["DenialCode"]}
                                    </td>
                                  ) : null}

                                  {claim_status &&
                                  (claim_status == "7" ||
                                    claim_status == "8") ? (
                                    <td>{activitiesInfo[code]["comments"]}</td>
                                  ) : null}

                                  {activitiesInfo[code]["Observation"] ? (
                                    <table striped bordered hover>
                                      <thead>
                                        <tr>
                                          <th>Type</th>
                                          <th>Code</th>
                                          <th>Value</th>
                                          <th>ValueType</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {Object.keys(
                                          activitiesInfo[code]["Observation"]
                                        ).map(function (obs, index) {
                                          return (
                                            <tr>
                                              <td>
                                                {
                                                  activitiesInfo[code][
                                                    "Observation"
                                                  ][index]["Type"]
                                                }
                                              </td>
                                              <td>
                                                {" "}
                                                {
                                                  activitiesInfo[code][
                                                    "Observation"
                                                  ][index]["Code"]
                                                }
                                              </td>
                                              <td>
                                                {" "}
                                                {
                                                  activitiesInfo[code][
                                                    "Observation"
                                                  ][index]["Value"]
                                                }
                                              </td>
                                              <td>
                                                {
                                                  activitiesInfo[code][
                                                    "Observation"
                                                  ][index]["ValueType"]
                                                }
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  ) : null}
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                      {isEditMode1 &&
                      ReSubmissionType == "Internal Compalaint" ? (
                        <div>
                          <h5>Resubmission Comment</h5>
                          <input
                            type="text"
                            name="comment"
                            defaultValue={EncounterInfo["comment"]}
                            className="Input-sama6"
                            onChange={this.handleInputTextChange}
                          />
                        </div>
                      ) : (
                        <div>
                          <h5>Resubmission Comment</h5>
                          <p>{EncounterInfo["comment"]}</p>
                        </div>
                      )}

                      {isEditMode1 &&
                      ReSubmissionType == "Internal Compalaint" ? (
                        <div>
                          {" "}
                          <h5>Resubmission Attachement</h5>
                          <input
                            type="file"
                            className="form-control-file"
                            name="fileComment"
                            onChange={this.handleInputTextChange}
                          />{" "}
                        </div>
                      ) : (
                        <div>
                          <h5>Resubmission Attachement</h5>

                          {EncounterInfo["ressattachement"] ? (
                            <a
                              onClick={() =>
                                saveAs(
                                  EncounterInfo["ressattachement"],
                                  "Attachement"
                                )
                              }
                              style={{
                                color: "blue",
                                textDecoration: "underline",
                              }}
                            >
                              Download Resubmission Attachement
                            </a>
                          ) : null}
                        </div>
                      )}

                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                          textAlign: "right",
                          cursor: "pointer",
                          margin: "0 2%",
                        }}
                        onClick={() =>
                          this.setState({
                            isShowmore: false,
                          })
                        }
                      >
                        {" "}
                        Close{" "}
                      </p>

                      {isEditMode1 ? (
                        <div>
                          <button
                            name="name"
                            className="GenerateBtnStyle9"
                            onClick={this.changeEditMode1}
                          >
                            Cancel
                          </button>
                          <button
                            name="name"
                            className="GenerateBtnStyle10"
                            onClick={this.update.bind(this)}
                          >
                            Save
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : null}
        </div>
      </Fragment>
    );
  }
}

export default ReSubmission;
