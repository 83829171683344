import React, { Component } from "react";
import "../App.css";
import Cookies from "js-cookie";

class SayanLinks extends Component {
  render() {
    return (
      <div className="BayanLinks">
        {/*   <a href="/SayanDocumentation">Sayan Documentation</a>
        <a href="#api">API End Points</a> */}
        <a href="/schema">Schema</a>
        <a href="/sample">Sample</a>
        <a href="/login">
          {Cookies.get("id")
            ? localStorage.getItem("name") + " Profile"
            : "Login"}
        </a>
      </div>
    );
  }
}

export default SayanLinks;
