import React, { Component, Fragment } from "react";
import "../Components/ListView/ListView.css";

class SanadNavBar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { selected, onChangeBranch, branchName } = this.props;

    let visitedStyleItem = { backgroundColor: "#3ab449" };
    let visitedStyleRef = { color: "white" };
    return (
      <Fragment>
        {
          !branchName ?
            <ul className="SanadNavBar">
              <li style={selected === "1" ? visitedStyleItem : null}>
                <a style={selected === "1" ? visitedStyleRef : null} href="/Sanad/calculator"> DRG Calculator </a>
              </li>

              <li style={selected === "2" ? visitedStyleItem : null}>
                <a style={selected === "2" ? visitedStyleRef : null} href="/Sanad/priceManagement"> Price Management </a>
              </li>

              <li style={selected === "3" ? visitedStyleItem : null}>
                <a style={selected === "3" ? visitedStyleRef : null} href="/Sanad/factorManagement"> Factor Management </a>
              </li>
              {
                localStorage.getItem("SanadType") &&
                  localStorage.getItem("SanadType") === "admin" ?
                  <li style={selected === "4" ? visitedStyleItem : null}>
                    <a style={selected === "4" ? visitedStyleRef : null} href="/Sanad/userManagement"> User Management</a>
                  </li>
                  :
                  null
              }
            </ul>
            :
            <ul className="SanadNavBar">
              <li style={branchName === "Home" ? visitedStyleItem : null}>
                <a style={branchName === "Home" ? visitedStyleRef : null} href="/Sanad/priceManagement"> Home </a>
              </li>

              <li style={branchName === "Upload Code Price" ? visitedStyleItem : null}>
                <a style={branchName === "Upload Code Price" ? visitedStyleRef : null} onClick={() => onChangeBranch('Upload Code Price')}> Upload Code Price </a>
              </li>

              <li style={branchName === "Import Prices File" ? visitedStyleItem : null}>
                <a style={branchName === "Import Prices File" ? visitedStyleRef : null} onClick={() => onChangeBranch('Import Prices File')}> Import Prices File </a>
                
              </li>
              
              <li style={branchName === "Import Form" ? visitedStyleItem : null}>
                <a style={branchName === "Import Form" ? visitedStyleRef : null}  onClick={() => onChangeBranch('Import Form')}> Import Form </a>
                
              </li>

            </ul>
        }
      </Fragment>
    );
  }
}

export default SanadNavBar;
