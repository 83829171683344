import React, { Component } from "react";
//import axios from "axios";
//import xml from "xml";
import NavBar from "./../Components/navbar";

import Footer from "./../Components/footer";

import Cookies from "js-cookie";

//import BayanLinks from "./../Components/bayanlinks";

class Upload extends Component {
  componentDidMount = () => {
    const { history } = this.props;

    if (Cookies.get("id")) {
      history.push(`/employee`);
    }
  };

  render() {
    return (
      <>
        <NavBar />

        <div className="BayanLinks">
          <a href="/loginAsEmployee">Login As Employee</a>
        </div>

        <div style={{ height: "205px" }}></div>

        <Footer />
      </>
    );
  }
}

export default Upload;
