import React, { Component, Fragment } from "react";
import axios from "axios";
import Header from "../Components/Header";
import InputGroup from "react-bootstrap/InputGroup";
import settings from "../helpers/Settings";
import Spinner from "react-bootstrap/Spinner";
import NavBar from "../Components/navbar";
import SanadNavBar from "../Components/sanadnavbar";
import Input from '../Components/InputText';
import DropList from '../Components/DropList';
import Table from '../Components/Table';
import Button from '../Components/Button'
import Switch from '@material-ui/core/Switch';

let { KayanURL, apiToken } = settings;

class UserManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyType: "",
      compName: localStorage.getItem("SanadCompany"),
      empType: localStorage.getItem("SanadType"),
      empName: "",
      serverError: null,
      error: null,
      data: [],
      leftStyle: "center",
      success: false,
      apiResult: null,
      field: "name",
      isInputErrors: false,

      // Employee Info
      allEmpsInfo: null,
      empInfo: null,
      EmployeesInfoTableRows: null,
      onAdd: false,
      onUpdate: false,
      onActivate: false,
      onStore: false,
      addSuccess: undefined,
      updateSuccess: undefined,
      activateSuccess: undefined,
      storeSuccess: undefined,
      startUpdate: false,
      startAdd: false,
      ids: [],
      compNames: [],
      comp_name: null,
      comp_id: null,
      current_value: null,
      current_empid: null,
      current_empname: null,
      employee: {},

      // Company Info
      onAddCompany: false,
      onCompanyActivate: false,
      addCompanySuccess: false,
      companyActivateSuccess: undefined,
      companyInfo: null,
      CompanyInfoTableRows: [],
      company: {}
    }
  };

  getCompanyById = () => {
    let my_data = {
      companyId: this.state.comp_id
    };

    axios
      .put(KayanURL + 'api/sanad/getCompanyById', { my_data, apiToken })
      .then(({ data }) => {
        if (data.success) {
          let companyInfo = data.data;

          if (this.state.onUpdate || this.state.onCompanyActivate) {
            setTimeout(() => {
              this.setState({
                onUpdate: this.state.onUpdate ? false : this.state.onUpdate,
                updateSuccess: this.state.onUpdate ? this.state.onUpdate : this.state.updateSuccess,
                onCompanyActivate: this.state.onCompanyActivate ? false : this.state.onCompanyActivate,
                companyActivateSuccess: this.state.onCompanyActivate ? this.state.onCompanyActivate : this.state.companyActivateSuccess,
                CompanyInfoTableRows: this.prepareCompanyInfoTable(companyInfo)
              })
            }, '5000');
          } else {
            this.setState({ CompanyInfoTableRows: this.prepareCompanyInfoTable(companyInfo) })
          }
        } else {
          this.setState({ error: data.error })
        }
      })
      .catch((error) => {
        this.setState({ serverError: error })
      })
  }

  getEmployeesById = () => {
    let my_data = {
      companyId: this.state.comp_id
    }
    axios
      .put(KayanURL + "api/sanad/getEmployeesbyCompId", { my_data, apiToken })
      .then(({ data }) => {
        if (data.success) {
          data = data.data;
          let allEmpsInfo = data.sort((employeeA, employeeB) =>
            employeeA.name.localeCompare(employeeB.name)
          );

          if (
            this.state.onUpdate ||
            this.state.onAdd ||
            this.state.onActivate ||
            this.state.onStore
          ) {
            setTimeout(() => {
              this.setState({
                onAdd: this.state.onAdd ? false : this.state.onAdd,
                addSuccess: this.state.onAdd
                  ? this.state.onAdd
                  : this.state.addSuccesss,
                onUpdate: this.state.onUpdate ? false : this.state.onUpdate,
                updateSuccess: this.state.onUpdate
                  ? this.state.onUpdate
                  : this.state.updateSuccess,
                onActivate: this.state.onActivate
                  ? false
                  : this.state.onActivate,
                activateSuccess: this.state.onActivate
                  ? this.state.onActivate
                  : this.state.activateSuccess,
                onStore: this.state.onStore ? false : this.state.onStore,
                storeSuccess: this.state.onStore
                  ? this.state.onStore
                  : this.state.storeSuccess,
                allEmpsInfo,
                EmployeesInfoTableRows: this.prepareEmployeesTable(data)
              })
            }, '5000');
          } else {
            this.setState({ allEmpsInfo, EmployeesInfoTableRows: this.prepareEmployeesTable(data) })
          }
        } else {
          this.setState({ error: data.error });
        }
      })
      .catch((error) => {
        this.setState({ serverError: error })
      })
  }

  getCompaniesInfo = async () => {
    await
      axios
        .put(KayanURL + 'api/sanad/get_compNames', { apiToken })
        .then(({ data }) => {
          if (data.success) {
            data = data.data;
            let companies = data.sort((companyA, companyB) => companyA['id'] - companyB['id']);
            if (this.state.onAddCompany) {
              setTimeout(() => {
                this.setState({
                  onAddCompany: this.state.onAddCompany ? false : this.state.onAddCompany,
                  addCompanySuccess: this.state.onAddCompany ? this.state.onAddCompany : this.state.addCompanySuccess,
                  data: companies
                })
              }, '5000')
            } else {
              this.setState({ data: companies })
            }
            let ids = [], compNames = []
            companies.map(item => {
              ids.push(item.id)
              compNames.push(item.name)
            })
            this.setState({ ids, compNames }, () => {
              this.setState({ comp_id: ids[0], comp_name: compNames[0] }, () => {
                this.getCompanyById()
                this.getEmployeesById()
              })
            })
          } else {
            this.setState({ error: data.error })

          }
        })

        .catch((error) => {
          this.setState({ serverError: "There is a server error!" });
        })
  }

  componentDidMount = () => {
    const { history } = this.props;

    if (!apiToken) {
      history.push(`/Sanad/loginAsEmployee`, { errorMsg: "You need to sign in or sign up" });
    }
    else {
      this.getCompaniesInfo();
    }
  };

  // Employee Actions

  prepareEmployeesTable = (items) => {
    let EmployeesInfoTableRows = [];
    if (items && items.length > 0) {
      items.forEach((item, index) => {
        let row = {};
        row['Id'] = index + 1;
        row['Name'] = item['name'];
        row['Username'] = item['username'];
        row['City'] = item['city'];
        row['DHPO User'] = item['dhpouser'];
        row['DHPO Password'] = item['dhpopassword'];
        row['Token'] = item['token']
        row['Action'] = [
          <div key={index} className="ActionDiv">
            <Button Style="BS12" Value="Update" onClick={() => this.onUserInfoUpdateClick(index)} />
            <Button Style="BS12" Value={item['active'] == true ? "Deactivate" : "Activate"} onClick={() => this.changeActivation(index)} />
          </div>
        ]
        row['Store Transactions'] = [
          <div key={index}>
            <Switch size="small" checked={item['storetransactions']} onChange={() => this.changeStoreTransactions(index)} />
          </div>
        ]
        EmployeesInfoTableRows.push(row);
      })
    }
    return EmployeesInfoTableRows;
  }

  changeActivation = (index) => {
    this.setState({
      onActivate: true,
      activateSuccess: false,
      error: null
    })

    let { allEmpsInfo } = this.state;

    this.setState({
      empInfo: allEmpsInfo[index]
    }, () => {
      this.setState({ current_empid: this.state.empInfo['id'] }, () => {
        let my_data = {}
        if (this.state.empInfo['active'] == true)
          my_data = {
            user_id: this.state.current_empid,
            active_value: false
          }
        else
          my_data = {
            user_id: this.state.current_empid,
            active_value: true
          }

        axios
          .put(KayanURL + 'api/sanad/change_employeeActivation', { my_data, apiToken })
          .then(({ data }) => {
            if (data.success) {
              this.setState({ apiResult: data.result }, () => {
                this.getEmployeesById()
              })
            } else {
              this.setState({
                error: data.error,
                onActivate: false,
                activateSuccess: true
              })
            }
          })
          .catch(() => {
            this.setState({ serverError: "There is a server error!" })
          })
      })
    })
  }

  changeStoreTransactions = (index) => {
    this.setState({
      onStore: true,
      storeSuccess: false,
      error: null
    })

    let { allEmpsInfo } = this.state;

    this.setState({
      empInfo: allEmpsInfo[index]

    }, () => {
      this.setState({ current_empid: this.state.empInfo['id'] }, () => {
        let my_data = {}
        if (this.state.empInfo['storetransactions'] == true)
          my_data = {
            user_id: this.state.current_empid,
            store_value: false
          }
        else
          my_data = {
            user_id: this.state.current_empid,
            store_value: true
          }

        axios
          .put(KayanURL + 'api/sanad/change_userStoringTransactions', { my_data, apiToken })
          .then(({ data }) => {
            if (data.success) {
              this.setState({ apiResult: data.result }, () => {
                this.getEmployeesById()
              })
            } else {
              this.setState({
                error: data.error,
                onStore: false,
                storeSuccess: true
              })
            }
          })
          .catch(() => {
            this.setState({ serverError: "There is a server error!" })
          })
      })


    })


  }

  onUserInfoUpdateClick = (index) => {
    let { allEmpsInfo } = this.state;

    this.setState({
      field: "name",
      companyStartUpdate: false,
      startUpdate: true,
      updateSuccess: undefined,
      error: null,
      empInfo: allEmpsInfo[index]

    }, () => {
      this.setState({ current_empid: this.state.empInfo['id'], current_empname: this.state.empInfo['name'] })
    })
  }

  handleEmployeeInputsChange = (e) => {
    let name = e.target.name
    let value = e.target.value
    this.setState({ employee: { ...this.state.employee, [name]: value } })
  }

  addEmployee = (e) => {
    e.preventDefault();
    this.setState({
      onAdd: true,
      addSuccess: false,
      error: null
    })

    let my_data = { ...this.state.employee, ...{ companyid: this.state.comp_id } };

    axios
      .put(KayanURL + 'api/sanad/add_user', { my_data, usertype: 'employee', apiToken })
      .then(({ data }) => {
        if (data.success) {
          this.setState({ apiResult: data.result }, () => {
            this.getEmployeesById()
          })
        } else {
          this.setState({
            error: data.error,
            onAdd: false,
            addSuccess: true,
            isInputErrors: data.isInputErrors
          })
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ serverError: "There is a server error!" })
      })
  }


  // Company Actions

  prepareCompanyInfoTable = (companyInfo) => {
    let CompanyTableRows = [];
    var formatedActivationEndDate;

    if (companyInfo) {
      let activationEndDate = new Date(companyInfo['endedat']);
      formatedActivationEndDate = activationEndDate.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: "numeric", hour12: false }).replace(/,/g, ' ');

      let row = {};
      row['Name'] = companyInfo['name'];
      row['Username'] = companyInfo['username'];
      row['Email'] = companyInfo['email'];
      row['License'] = companyInfo['license'];
      row['Type'] = companyInfo['type'];
      row['Country'] = companyInfo['country'];
      row['City'] = companyInfo['city'];
      row['Address'] = companyInfo['address'];
      row['Contact Person'] = companyInfo['contactperson'];
      row['Password'] = null;
      row['Activation Status'] = companyInfo['approve'] ? companyInfo['active'] ? "Activated" : "Deactivated" : "Not Approved";
      row['Activation End Date'] = formatedActivationEndDate;
      row['Activation Period'] = companyInfo['activationperiod'] ? `${Object.values(companyInfo['activationperiod'])[0]} ${Object.keys(companyInfo['activationperiod'])[0]}` : null;
      row['Transactions'] = companyInfo['transactions'];
      row['Action'] = [
        <div key={companyInfo['id']} className="ActionDiv">
          <Button Style="BS12" Value="Update" onClick={() => this.onCompanyInfoUpdateClick()} />
          {
            companyInfo['approve'] == true ?
              <Fragment>
                <Button Style="BS12" Value={companyInfo['active'] == true ? "Deactivate" : "Activate"} onClick={() => this.onCompanyActivationChange(companyInfo['id'], 'active', companyInfo['active'])} />
                <Button Style="BS12" Value={companyInfo['approve'] == true ? "Remove Approval" : "Approve"} onClick={() => this.onCompanyActivationChange(companyInfo['id'], 'approve', companyInfo['approve'])} />
              </Fragment>
              :
              <Button Style="BS12" Value={companyInfo['approve'] == true ? "Remove Approval" : "Approve"} onClick={() => this.onCompanyActivationChange(companyInfo['id'], 'approve', companyInfo['approve'])} />
          }
        </div>
      ]

      let companyInfoObject = {};
      Object.keys(row).forEach(key => !['Activation Status', 'Action'].includes(key) ? companyInfoObject[key.toLowerCase().replace(/ /g, '')] = row[key] : null)

      this.setState({ companyInfo: companyInfoObject }, () => {
        CompanyTableRows.push(row);
      })
    }
    return CompanyTableRows;
  }

  onCompanyInfoUpdateClick = () => {
    this.setState({
      field: "name",
      companyStartUpdate: true,
      startUpdate: false,
      updateSuccess: undefined,
      error: null,
    })
  }

  onCompanyActivationChange = (user_id, active_field, active_value) => {

    this.setState({
      onCompanyActivate: true,
      companyActivateSuccess: false,
      error: null
    })

    let my_data = { user_id, active_field, active_value: !active_value }

    axios
      .put(KayanURL + 'api/sanad/change_companyActivation', { my_data, apiToken })
      .then(({ data }) => {
        if (data.success) {
          this.setState({ apiResult: data.result }, () => {
            this.getCompanyById()
          })
        } else {
          this.setState({
            error: data.error,
            onCompanyActivate: false,
            companyActivateSuccess: true
          })
        }
      })
      .catch(() => {
        this.setState({ serverError: "There is a server error!" })
      })
  }

  onCompanyTypeSelection = e => {
    let select = document.getElementById(e)
    let name = select.name
    let value = select.options[select.selectedIndex].value;
    this.setState({ company: { ...this.state.company, [name]: value } })
  }

  handleCompanyInputsChange = e => {
    let name = e.target.name
    let value = e.target.value
    this.setState({ company: { ...this.state.company, [name]: value } })
  }

  addCompany = (e) => {
    e.preventDefault();
    this.setState({
      onAddCompany: true,
      addCompanySuccess: false,
      error: null
    })

    let my_data = this.state.company;

    axios
      .put(KayanURL + 'api/sanad/add_user', { my_data, usertype: 'company', apiToken })
      .then(({ data }) => {
        if (data.success) {
          this.setState({ apiResult: data.result }, () => {
            this.getCompaniesInfo()
          })
        } else {
          this.setState({
            error: data.error,
            onAddCompany: false,
            addCompanySuccess: true,
            isInputErrors: data.isInputErrors
          })
        }
      })
      .catch(() => {
        this.setState({ serverError: "There is a server error!" })
      })
  }

  // Update Actions (Company, Employee)

  handleFieldInputChange = (e) => {
    let { field } = this.state;
    this.setState({
      field: e.target.value,
      error: null,
      onAdd: false,
      addSuccess: false,
      onAddCompany: false,
      addCompanySuccess: false,
      onUpdate: false,
      updateSuccess: false,
      onStore: false,
      storeSuccess: false,
      onActivate: false,
      activateSuccess: false,
      onCompanyActivate: false,
      companyActivateSuccess: false
    })

    document.getElementById(field).value = '';
  }

  handleInputTextChange = ({ target: { name, value } }) => {
    this.setState({
      current_value: value,
      error: null,
      onAdd: false,
      addSuccess: false,
      onAddCompany: false,
      addCompanySuccess: false,
      onUpdate: false,
      updateSuccess: false,
      onStore: false,
      storeSuccess: false,
      onActivate: false,
      activateSuccess: false,
      onCompanyActivate: false,
      companyActivateSuccess: false
    })
  }

  handleCompNameChange = (e) => {
    e.preventDefault();
    let ids = this.state.ids, compNames = this.state.compNames
    this.setState({ comp_name: e.target.value }, () => {
      this.setState({ comp_id: ids[compNames.indexOf(this.state.comp_name)] }, () => {
        this.setState({ EmployeesInfoTableRows: null, CompanyInfoTableRows: null }, () => {
          this.getCompanyById()
          this.getEmployeesById()
        })
      })
    });
  };

  updateUser = async (e, usertype) => {
    e.preventDefault();
    this.setState({
      onUpdate: true,
      updateSuccess: false,
      error: null
    })

    let my_data = {
      field: this.state.field == 'activationenddate' ? "endedat" : this.state.field,
      new_value: this.state.current_value,
      user_id: usertype == 'company' ? this.state.comp_id : this.state.current_empid
    }

    axios
      .put(KayanURL + 'api/sanad/update_user', { my_data, usertype, apiToken })
      .then(({ data }) => {

        if (data.success) {
          this.setState({ apiResult: data.result }, () => {
            usertype == 'company' ?
              this.getCompanyById()
              :
              this.getEmployeesById()
          })
        } else {
          this.setState({
            error: data.error,
            onUpdate: false,
            updateSuccess: true
          })
        }
      })
      .catch(() => {
        this.setState({ serverError: "There is a server error!" });
      })
  }

  render() {
    const { error, serverError, success } = this.state;

    // Company Fields
    let companyInputsTypes = ["text", "select", "text", "text", "text", "text", "email", "tel", "text", "text", "password", "password"];
    let companyInputsValues = ["Name", [["Payer", "Provider", "TPA"], ["Payer", "Provider", "TPA"]], "License", "Country", "City", "Address", "E-Mail", "Phone", "Contact Person", "Username", "Password ", "Re-password"];
    let companyInputsNames = ["name", "type", "license", "country", "city", "address", "email", "phone", "contactperson", "username", "password", "repassword"];

    // Employee Fields
    let employeeInputsTypes = ["text", "text", "password", "password", "text", "text", "text"];
    let employeeInputsValues = ["Name", "Username", "Password ", "Re-password", "City", "DHPO User", "DHPO Password"];
    let employeeInputsNames = ["name", "username", "password", "repassword", "city", "dhpouser", "dhpopassword"];

    // Selected Update Field Name
    var FieldName;
    if (this.state.companyStartUpdate && this.state.companyInfo && this.state.CompanyInfoTableRows && this.state.CompanyInfoTableRows.length > 0) {
      let selectItems = Object.keys(this.state.CompanyInfoTableRows[0]).filter(item => !['Activation Status', 'Action'].includes(item));
      FieldName = selectItems[Object.keys(this.state.companyInfo).indexOf(this.state.field)].toLowerCase();
    } else if (this.state.startUpdate) {
      FieldName = this.state.field.includes('dhpo') ? employeeInputsValues[employeeInputsNames.indexOf(this.state.field)] : this.state.field
    }

    // Hide Actions Messages
    if (success)
      setTimeout(() => {
        this.setState({
          success: false
        })
      }, '6500')

    // Hide Success Messages
    if (!error && (this.state.addSuccess || this.state.addCompanySuccess || this.state.updateSuccess || this.state.activateSuccess || this.state.companyActivateSuccess || this.state.storeSuccess))
      setTimeout(() => {
        this.setState({
          addSuccess: false,
          addCompanySuccess: false,
          updateSuccess: false,
          activateSuccess: false,
          companyActivateSuccess: false,
          storeSuccess: false
        })
      }, '1500')

    // Rendering.
    if (serverError)
      return (
        <div style={{ backgroundColor: "rgb(67, 183, 81)", color: "#ffffff", padding: "10px" }}>
          {serverError}
        </div>
      )
    else
      return (
        <>
          <NavBar />
          <Header
            name={localStorage.getItem("SanadName")}
            usertype="employee"
            history={this.props.history}
          />
          <SanadNavBar selected="4" />
          <div className="user-managment-container" >

            {
              this.state.errorMsg ?
                <div style={{ backgroundColor: "rgb(67, 183, 81)", color: "#ffffff", padding: "10px" }}>
                  {this.state.errorMsg}
                </div>
                : null
            }

            {/* List of companies */}
            <div id="main-registration-container" className="registerDiv">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="toMargin">
                  <InputGroup style={{ alignItems: "center" }}>
                    <label> Company Name:</label>
                    <select className="fullWidthSelect" onChange={this.handleCompNameChange}>
                      {
                        this.state.data.map((item, index) => <option key={index}>{item.name}</option>)
                      }
                    </select>
                  </InputGroup>
                </div>
                <p style={{ "fontWeight": "bold", "fontSize": "15px", "textAlign": "right", "cursor": "pointer", "margin": "2%" }} onClick={() => this.setState({ startAddCompany: true, error: null })}> Create new company </p>
              </form>
            </div>

            {/* Company Info Tables */}
            {
              this.state.CompanyInfoTableRows && this.state.EmployeesInfoTableRows ?

                <div className="tablesContainer">
                  {
                    this.state.CompanyInfoTableRows.length > 0 ?
                      <div style={{ "marginBottom": "2%" }}>
                        <p style={{ "fontWeight": "bold", "fontSize": "15px", "textAlign": "left" }}> The {this.state.comp_name} Info</p>
                        <Table THead={Object.keys(this.state.CompanyInfoTableRows[0])} TBody={this.state.CompanyInfoTableRows} />

                        {
                          !this.state.companyActivateSuccess && this.state.onCompanyActivate && !error ?
                            <div style={{ color: "green", "marginTop": "1%" }}> {`Changing company activation status`}
                              <Spinner size="sm" animation="grow" variant="success" />
                              <Spinner size="sm" animation="grow" variant="success" />
                              <Spinner size="sm" animation="grow" variant="success" />
                            </div>
                            : this.state.companyActivateSuccess && !this.state.onCompanyActivate && error ?
                              <div style={{ color: "red", "marginTop": "1%" }}> {error} </div>
                              :
                              this.state.companyActivateSuccess && !error ?
                                <div style={{ color: "green", "marginTop": "1%" }}> {`The company activation status has been changed`} </div>
                                :
                                null
                        }

                      </div>
                      :
                      null
                  }

                  {
                    this.state.EmployeesInfoTableRows.length > 0 ?
                      <div style={{ "marginBottom": "2%" }}>
                        <p style={{ "fontWeight": "bold", "fontSize": "15px", "textAlign": "left" }}> The {this.state.comp_name} Employees Info</p>
                        <Fragment>
                          <Table THead={Object.keys(this.state.EmployeesInfoTableRows[0])} TBody={this.state.EmployeesInfoTableRows} />
                          {
                            !this.state.activateSuccess && this.state.onActivate && !error ?
                              <div style={{ color: "green", "marginTop": "1%" }}> {`Changing employee activation status`}
                                <Spinner size="sm" animation="grow" variant="success" />
                                <Spinner size="sm" animation="grow" variant="success" />
                                <Spinner size="sm" animation="grow" variant="success" />
                              </div>
                              : this.state.activateSuccess && !this.state.onActivate && error ?
                                <div style={{ color: "red", "marginTop": "1%" }}> {error} </div>
                                :
                                this.state.activateSuccess && !error ?
                                  <div style={{ color: "green", "marginTop": "1%" }}> {`The employee activation status has been changed`} </div>
                                  :
                                  null
                          }

                          {
                            !this.state.storeSuccess && this.state.onStore && !error ?
                              <div style={{ color: "green", "marginTop": "1%" }}> {`Changing employee transactions storing status`}
                                <Spinner size="sm" animation="grow" variant="success" />
                                <Spinner size="sm" animation="grow" variant="success" />
                                <Spinner size="sm" animation="grow" variant="success" />
                              </div>
                              : this.state.storeSuccess && !this.state.onStore && error ?
                                <div style={{ color: "red", "marginTop": "1%" }}> {error} </div>
                                :
                                this.state.storeSuccess && !error ?
                                  <div style={{ color: "green", "marginTop": "1%" }}> {`The employee transactions storing status has been changed`} </div>
                                  :
                                  null
                          }

                        </Fragment>
                        <Button Style="BS7 customWidth" Value="Add New Employee" onClick={() => this.setState({ startAdd: true, error: null })} />
                      </div>
                      : this.state.EmployeesInfoTableRows.length == 0 ?
                        <Fragment>
                          <header>{this.state.comp_name} does't have any employees yet.</header>
                          <Button Style="BS7 customWidth" Value="Add New Employee" onClick={() => this.setState({ startAdd: true, error: null })} />
                        </Fragment>
                        :
                        null
                  }
                </div>
                :
                <div>
                  <span style={{ textAlign: "center", color: "#3ab449" }}> Getting {this.state.comp_name} Info </span>
                  <Spinner size="sm" animation="grow" variant="success" />
                  <Spinner size="sm" animation="grow" variant="success" />
                  <Spinner size="sm" animation="grow" variant="success" />
                </div>
            }

            {/* Add Company */}
            {
              this.state.startAddCompany ?
                <div className="popup" style={{ "paddingTop": "50px" }}>
                  <form className="popupForm" onSubmit={this.addCompany}>
                    <p style={{ "fontWeight": "bold", "fontSize": "15px", "textAlign": "left", "margin": "2% 0" }}> Add New Company</p>

                    {
                      companyInputsTypes.map((type, index) => {
                        if (type == 'select') {
                          return (
                            <DropList key={index}
                              id={companyInputsNames[index]}
                              name={companyInputsNames[index]}
                              Style="fullWidthSelect"
                              Title={companyInputsNames[index]}
                              Options={companyInputsValues[index][0]}
                              Values={companyInputsValues[index][1]}
                              onSelect={this.onCompanyTypeSelection}
                            />)
                        } else {
                          return (
                            <Input key={index}
                              id={companyInputsNames[index]}
                              name={companyInputsNames[index]}
                              Style="IT4"
                              type={type}
                              hint={companyInputsValues[index]}
                              onChange={this.handleCompanyInputsChange}
                            />
                          )
                        }
                      })
                    }

                    <input type="submit" value={`Add`} className="BS7 customWidth" />

                    {

                      !this.state.addCompanySuccess && this.state.onAddCompany && !error ?
                        <div style={{ color: "green", "marginTop": "1%" }}> {` The new company is adding`}
                          <Spinner size="sm" animation="grow" variant="success" />
                          <Spinner size="sm" animation="grow" variant="success" />
                          <Spinner size="sm" animation="grow" variant="success" />
                        </div>
                        : this.state.addCompanySuccess && !this.state.onAddCompany && error ?
                          !this.state.isInputErrors ?
                            <div style={{ color: "red", "marginTop": "1%" }}> {error} </div>
                            :
                            <ul className="errList">
                              {
                                error.map((err, key) => {
                                  return <li key={key}> {err} </li>
                                })
                              }
                            </ul>
                          : this.state.addCompanySuccess && !error ?
                            <div style={{ color: "green", "marginTop": "1%" }}> {`The new company has been added`} </div>
                            :
                            null
                    }

                    <p style={{ "fontWeight": "bold", "fontSize": "15px", "textAlign": "right", "cursor": "pointer", "margin": "0 2%" }} onClick={() => this.setState({ startAddCompany: false })}> Close </p>
                  </form>
                </div>
                : null
            }

            {/* Update Company */}
            {
              this.state.companyStartUpdate && this.state.companyInfo ?
                <div className="popup">
                  <form className="popupForm" onSubmit={(e) => this.updateUser(e, 'company')}>
                    <p style={{ "fontWeight": "bold", "fontSize": "15px", "textAlign": "left", "margin": "2% 0" }}> Change {this.state.comp_name} Info </p>
                    <div style={{ "padding": "1%" }}>
                      <label> Field Name:</label>
                      <select name="Field" id="Field" value={this.state.field} className="UpdateSelect" onChange={(e) => this.handleFieldInputChange(e)}>
                        {
                          Object.keys(this.state.CompanyInfoTableRows[0]).map((key, index) => !['Activation Status', 'Action'].includes(key) ? <option key={index} value={key.toLowerCase().replace(/ /g, '')}> {key} </option> : null)
                        }
                      </select>
                    </div>
                    <div style={{ "padding": "1%" }}>

                      <label>Update {FieldName} field:</label>

                      <input
                        type={this.state.field == "password" ? "password" : this.state.field == 'activationenddate' ? "datetime-local" : "text"}
                        name={this.state.field == 'activationenddate' ? 'endedat' : this.state.field}
                        id={this.state.field}
                        style={{ "width": "100%" }}
                        placeholder={
                          this.state.field != "password" ?
                            this.state.companyInfo[this.state.field] ?
                              `Current ${FieldName} is ${this.state.companyInfo[this.state.field]}`
                              :
                              `Enter new value of ${FieldName}`
                            :
                            'Enter a new password'
                        }
                        onChange={e => this.handleInputTextChange(e)} required />

                      {
                        this.state.field == 'activationenddate' ?
                          <p style={{ "fontWeight": "600", "fontSize": "13px", "textAlign": "left", "margin": "2% 0" }}>
                            Current {FieldName} is {this.state.companyInfo[this.state.field]}
                          </p>
                          :
                          null
                      }

                    </div>

                    <input type="submit" value={this.state.companyInfo[this.state.field] ? `Update` : 'Add'} className="BS7 customWidth" />
                    {
                      !this.state.updateSuccess && this.state.onUpdate && !error ?
                        <div style={{ color: "green", "marginTop": "1%" }}> {` The company ${FieldName} value is updating`}
                          <Spinner size="sm" animation="grow" variant="success" />
                          <Spinner size="sm" animation="grow" variant="success" />
                          <Spinner size="sm" animation="grow" variant="success" />
                        </div>
                        : this.state.updateSuccess && !this.state.onUpdate && error ?
                          <div style={{ color: "red", "marginTop": "1%" }}> {error} </div>
                          :
                          this.state.updateSuccess && !error ?
                            <div style={{ color: "green", "marginTop": "1%" }}> {` The company info has been updated`} </div>
                            :
                            null
                    }
                    <p style={{ "fontWeight": "bold", "fontSize": "15px", "textAlign": "right", "cursor": "pointer", "margin": "0 2%" }} onClick={() => this.setState({ companyStartUpdate: false, field: "name" })}> Close </p>
                  </form>
                </div >
                : null
            }

            {/* Add Employee */}
            {
              this.state.startAdd ?
                <div className="popup" style={{ "paddingTop": "15px" }}>
                  <form className="popupForm" onSubmit={this.addEmployee}>
                    <p style={{ "fontWeight": "bold", "fontSize": "15px", "textAlign": "left", "margin": "2% 0" }}> Add New Employee for {this.state.comp_name} </p>
                    {
                      employeeInputsTypes.map((type, index) => {
                        return (
                          <Input key={index}
                            id={employeeInputsNames[index]}
                            name={employeeInputsNames[index]}
                            Style="IT4"
                            type={type}
                            hint={employeeInputsValues[index]}
                            onChange={this.handleEmployeeInputsChange}
                          />
                        )
                      })
                    }
                    <input type="submit" value="Add" className="BS7 customWidth" />

                    {
                      !this.state.addSuccess && this.state.onAdd && !error ?
                        <div style={{ color: "green", "marginTop": "1%" }}> {` The new employee is adding`}
                          <Spinner size="sm" animation="grow" variant="success" />
                          <Spinner size="sm" animation="grow" variant="success" />
                          <Spinner size="sm" animation="grow" variant="success" />
                        </div>
                        : this.state.addSuccess && !this.state.onAdd && error ?
                          !this.state.isInputErrors ?
                            <div style={{ color: "red", "marginTop": "1%" }}> {error} </div>
                            :
                            <ul className="errList">
                              {
                                error.map((err, key) => {
                                  return <li key={key}> {err} </li>
                                })
                              }
                            </ul>
                          : this.state.addSuccess && !error ?
                            <div style={{ color: "green", "marginTop": "1%" }}> {` The new employee has been added`} </div>
                            :
                            null
                    }

                    <p style={{ "fontWeight": "bold", "fontSize": "15px", "textAlign": "right", "cursor": "pointer", "margin": "0 2%" }} onClick={() => this.setState({ startAdd: false, })}> Close </p>
                  </form>
                </div >
                : null
            }

            {/* Update Employee */}
            {
              this.state.startUpdate ?
                <div className="popup">
                  <form className="popupForm" onSubmit={(e) => this.updateUser(e, 'employee')}>
                    <p style={{ "fontWeight": "bold", "fontSize": "15px", "textAlign": "left", "margin": "2% 0" }}> Change {this.state.current_empname} employee Info</p>
                    <div style={{ "padding": "1%" }}>
                      <label> Field Name:</label>
                      <select name="Field" id="Field" value={this.state.field} className="UpdateSelect" onChange={(e) => this.handleFieldInputChange(e)}>
                        {
                          employeeInputsValues.map((key, index) => key != 'Re-password' ? <option key={index} value={key.toLowerCase().replace(/ /g, '')}> {key} </option> : null)
                        }
                      </select>
                    </div>
                    <div style={{ "padding": "1%" }}>
                      <label>Update {FieldName} field:</label>
                      <input
                        type={this.state.field == "password" ? "password" : "text"}
                        name={this.state.field}
                        id={this.state.field}
                        style={{ "width": "100%" }}
                        placeholder={
                          this.state.field != "password" ?
                            this.state.empInfo[this.state.field] ?
                              `Current ${FieldName} is ${this.state.empInfo[this.state.field]}`
                              :
                              `Enter new value of ${FieldName}`
                            :
                            'Enter a new password'
                        }
                        onChange={e => this.handleInputTextChange(e)} required />
                    </div>

                    <input type="submit" value={this.state.empInfo[this.state.field] ? `Update` : 'Add'} className="BS7 customWidth" />
                    {
                      !this.state.updateSuccess && this.state.onUpdate && !error ?
                        <div style={{ color: "green", "marginTop": "1%" }}> {` The employee ${this.state.field} value is updating`}
                          <Spinner size="sm" animation="grow" variant="success" />
                          <Spinner size="sm" animation="grow" variant="success" />
                          <Spinner size="sm" animation="grow" variant="success" />
                        </div>
                        : this.state.updateSuccess && !this.state.onUpdate && error ?
                          <div style={{ color: "red", "marginTop": "1%" }}> {error} </div>
                          :
                          this.state.updateSuccess && !error ?
                            <div style={{ color: "green", "marginTop": "1%" }}> {` The employee info has been updated`} </div>
                            :
                            null
                    }
                    <p style={{ "fontWeight": "bold", "fontSize": "15px", "textAlign": "right", "cursor": "pointer", "margin": "0 2%" }} onClick={() => this.setState({ startUpdate: false, field: "name" })}> Close </p>
                  </form>
                </div >
                : null
            }
          </div>
        </>
      )
  }
}
export default UserManagement;
