import React, { Fragment } from "react";
import Cookies from "js-cookie";
import Dropdown from "react-bootstrap/Dropdown";
import "./Header.css";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
    };
  }

  handleLogout = (projectType, history) => {
    let tokenName =
      projectType == "Sanad" || projectType == "Huda"
        ? "SanadId"
        : projectType == "Sama" || projectType == "Somod"
        ? "SamaId"
        : projectType == "admin"
        ? "BayanAdminID"
        : projectType == "SamaCompanyAdmin"
        ? "SamaCompanyId"
        : projectType == "samaAdmin"
        ? "SamaAdminId"
        : projectType == "bayanKSA"
        ? "BayanKSAID"
        : "id";

    Cookies.remove(tokenName);

    Object.keys(localStorage).forEach((item) => {
      if (
        projectType !== "Sanad" &&
        projectType !== "Sama" &&
        projectType != "admin"
      ) {
        if (
          !item.includes("Sanad") &&
          !item.includes("Sama") &&
          !item.includes("Admin")
        ) {
          localStorage.removeItem(item);
        }
      } else if (projectType == "Sama") {
        if (item.includes("Sama")) {
          localStorage.removeItem(item);
        }
      } else if (projectType == "admin") {
        if (item.includes("Admin")) {
          localStorage.removeItem(item);
        }
      } else if (projectType == "samaAdmin") {
        if (item.includes("SamaAdminId")) {
          localStorage.removeItem(item);
        }
      } else {
        if (item.includes("Sanad")) {
          localStorage.removeItem(item);
        }
      }
    });
    window.location.href = "/";
    //  history.push("/");
  };

  render() {
    const { name, history, projectType, onClickSeeting, usertype } = this.props;

    return name !== null ? (
      <div
        className="subHeader2"
        style={{
          backgroundColor: "transparent",
          paddingTop: "0px",
          paddingBottom: "7px",
        }}
      >
        <div className="container">
          <div className="header-right">
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
              ></Dropdown.Toggle>
              <Dropdown.Menu>
                {usertype == "employee" &&
                projectType !== "Sanad" &&
                projectType !== "bayanKSA" &&
                projectType !== "Sama" &&
                projectType != "admin" ? (
                  <Fragment>
                    <Dropdown.Item
                      style={{ color: "#28a745" }}
                      onClick={() => onClickSeeting("RulesMgt")}
                    >
                      Rules Management
                    </Dropdown.Item>
                    <Dropdown.Item
                      style={{ color: "#28a745" }}
                      onClick={() => onClickSeeting("PrefCodesMgt")}
                    >
                      Preferred Codes Management
                    </Dropdown.Item>
                  </Fragment>
                ) : null}
                <Dropdown.Item
                  style={{ color: "#28a745" }}
                  onClick={() => this.handleLogout(projectType, history)}
                >
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/*  {projectType=='employee'?

              <a
                style={{ color: "white", backgroundColor: "#28a745" ,marginLeft:"10px" }}
                onClick={() =>onClickSeeting() }
              >
                Rules Management
                </a>
                :
                null}
                <a
                  style={{ color: "white", backgroundColor: "#28a745" ,marginLeft:"10px"}}
                  onClick={() => this.handleLogout(projectType, history)}
                >
                  Logout
                </a>  */}
          </div>
        </div>
      </div>
    ) : null;
  }
}
export default Header;
