import React, { Component, Fragment } from "react";
import axios from "axios";
import AutoComplete from "../Components/AutoComplete";
import Table from "../Components/Table";
import Button from "../Components/Button";
import Header from '../Components/Header';
import NavBar from "../Components/navbar";
import SanadNavBar from "../Components/sanadnavbar";
import Spinner from 'react-bootstrap/Spinner';
import ProgressBar from 'react-bootstrap/ProgressBar';
import readXlsxFile from 'read-excel-file';
import settings from '../helpers/Settings';

let { KayanURL, apiToken } = settings;

class PriceManagement extends Component {

  constructor(props) {
    super(props)
    
    this.state = {

      // Common 
      data: {},
      name: null,
      ishomePage: true,
      isUploadCode: false,
      isImportFile: false,
      ImportForm : false,
      username: "",
      leftStyle: "center",
      type: "HCPCS",
      codePrices: [],
      serverError: null,
      error: null,
      success: false,
      code: "",
      price: "",
      internal_code: "",
     

      // Auto Complete
      items: [],
      codes_desc: [],
      codeACText: '',
      codeACMatched: [],
      codeACStyle: 'AutoCompleteInput',
      addInternalCode: false,
      field: null,
      now: 0,

      // Upload 
      add: null,
      uploading: null,
      wrongCodes: null,
      filename: null,
      excelContent: null,
      showLabel: true,
      WrongTableRows: [],
      FormRows :[],
      uploadSuccess: null,
      datasuccess :null,

      // Payer Price Management
      payerProviders: [],
      companyType: null,
      companyLicense: null,
      providerName: null,
      provider_id: null,
    }
  };
 

  //get value of text
  onCodeChange(event) {
    this.setState({ code: event.target.value });
  }

  onPriceChange(event) {
    this.setState({ price: event.target.value });
  }

  onInternalcodeChange(event) {
    this.setState({ internal_code: event.target.value });
  }

  resetForm() {
    this.setState({ code: "", price: "", internal_code: "" });
  }


 
  // get Employee Info.
  getEmployeeInfo = () => {
    const { history } = this.props;

    axios
      .get(KayanURL + 'api/user/info', { apiToken })
      .then(({ data }) => {
        if (data.success) {
          this.setState({ name: data.name });
        } else {
          if (data.error[0] === 'Invalid request') {
            history.push(`/${data.usertype}`)
          } else {
            history.push({ pathname: "/Sanad/loginAsemployee", errMsg: data.error })
          }
        }
      })
      .catch(() => {
        this.setState({ serverError: "There are an Server error" });
      })
  }

  // get Codes Info.
  getCodesInfo = () => {
    let { type, codeACText, provider_id } = this.state;

    this.renderProgressBar()

    axios
      .put(KayanURL + 'api/sanad/getCodesInfo', { apiToken, provider_id })
      .then(({ data }) => {
        if (data.success) {
          let HCPCS_Items = data.codes_Info.filter(code => code.type === 'HCPCS').map(info => info.codeDescription);
          let Dental_Items = data.codes_Info.filter(code => code.type === 'Dental').map(info => info.codeDescription);
          let Drug_Items = data.codes_Info.filter(code => code.type === 'Drug').map(info => info.codeDescription);
          let items = type == 'HCPCS' ? HCPCS_Items : type == 'Dental' ? Dental_Items: Drug_Items;

          let selectedCode = codeACText.split(',')[0].trim();
          let codePrices = codeACText ? data.codes_Info.filter(info => info.code == selectedCode).map(info => info.codePrices)[0] : null;

          this.setState({
            items,
            HCPCS_Items,
            Dental_Items,
            Drug_Items,
            data: { ...this.state.data, type },
            codes_Info: data.codes_Info,
            codePrices,
            TableRows: this.preparePricesTable(codePrices),
            success: false
          });
        } else {
          this.setState({
            error: data.error[0]
          });
        }
      })
      .catch(() => {
        this.setState({ serverError: "There are an Server error" });
      })
  }
  

  // Get Payer Providers
  getloggedPayerProvidersInfo = () => {
    let { companyLicense } = this.state;
    let payerProviders = [];
    axios
      .put(KayanURL + 'api/sanad/get_loggedPayer_providers', { apiToken, companyLicense })
      .then(({ data }) => {
        if (data.success) {
          let providers = data.data;

          providers.map(provider => {
            payerProviders.push(provider.provider_id + " , " + provider.provider_name);
            this.setState({ payerProviders })
          })

          this.setState({
            providerName: providers[0].provider_name,
            provider_id: providers[0].provider_id,
            data: { ...this.state.data, provider_id: providers[0].provider_id }
          }, () => {
            this.getCodesInfo();
          })

        } else {
          this.setState({
            error: data.error
          })
        }
      })
      .catch(() => {
        this.setState({ serverError: "There is a server error!" });
      })
  }

  componentDidMount = () => {
    const { history } = this.props;
    if (!apiToken) {
      history.push(`/Sanad/loginAsEmployee`, { errorMsg: "You need to sign in or sign up" });
    } else {
      let companyType = localStorage.getItem("SanadCompanyType");
      let companyLicense = localStorage.getItem("SanadCompanyLicense");
      if (companyType != 'Provider') {
        this.setState({ companyLicense }, () => {
          this.getloggedPayerProvidersInfo();
        })
      } else {
        this.getCodesInfo();
      }
    }
  }

  // Handle Field Input Change 
  handleFieldInputChange = (e) => {
    let { field } = this.state;
    this.setState({
      field: e.target.value
    })
    document.getElementById(field).value = '';
  }

  // Handle Input Text Change
  handleInputTextChange = ({ target: { name, value } }) => {
    if (name == 'type') {
      let { Dental_Items, HCPCS_Items, Drug_Items, provider_id } = this.state;
      let items = value == 'HCPCS' ? HCPCS_Items: value == 'Dental' ? Dental_Items: Drug_Items
     
      this.setState({
        [name]: value,
        data: { [name]: value, provider_id },
        items,
        codeACText: '',
        codeACMatched: [],
        codePrices: [],
        TableRows: [],
        WrongTableRows: [],
        FormRows :[],
        showLabel: true,
        uploading: false,
        add: false,
        uploadSuccess: false,
        datasuccess:false,
        error: null
      })

    } else {
      this.setState({ data: { ...this.state.data, [name]: value } });
    }
  }

  handleProviderChange = (e) => {
    e.preventDefault();
    this.setState({
      providerName: e.target.value.split(" , ")[1].trim(),
      provider_id: e.target.value.split(" , ")[0].trim(),
      data: { ...this.stata.data, provider_id: e.target.value.split(" , ")[0].trim() }
    }, () => {
      this.setState({ TableRows: null }, () => {
        this.getCodesInfo()
      })
    })

  }

  // Handle Auto Complete Actions
  handleACInputChange = (e) => {
    const value = e.target.value;
    let matched = [];
    this.setState({ codePrices: [], TableRows: [], onUpdate: false, success: false, error: null });

    if (value.length > 0) {
      let regValue = value.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
      const regex = new RegExp(`${regValue}`);
      matched = this.state.items.sort().filter(v => regex.test(v))
      matched.length > 0 ? this.setState({ codeACStyle: 'ACInputOnChangingStyle' }) : this.setState({ codeACStyle: 'AutoCompleteInput' })
      this.setState({ codeACMatched: matched, codeACText: value });
    } else {
      this.setState({ codeACMatched: matched, codeACText: value, codeACStyle: 'AutoCompleteInput' })
    }
  }

  handleACItemSelection = (value) => {
    let newValue = value.split(',')[0].trim();
    let { codes_Info } = this.state;
    let codePrices = codes_Info.filter(info => info.code == newValue).map(info => info.codePrices)[0];
    this.setState(() => ({
      codeACText: value,
      codeACMatched: [],
      codeACStyle: 'AutoCompleteInput',
      data: { ...this.state.data, "code": newValue },
      codePrices,
      TableRows: this.preparePricesTable(codePrices),
      onUpdate: false
    }))
  }

  prepareWrongCodesTable = (items) => {
    let TableRows = [];
    if (items && items.length > 0) {
      items.forEach((item, index) => {
        let row = {};
        // Change Id to row num in excel file
        row['Id'] = index + 1;
        row['Code'] = item['code'];
        if (this.state.type == 'HCPCS')
          row['Internal Code'] = item['internal_code'];
        row['Price'] = item['price'];
        row['Row No.'] = item['row_num'];
        row['Reason'] = item['reason']
        TableRows.push(row);
      })
    }
    return TableRows;
  }

  prepareWrongCodesTableForm = (items) => {
    let FormRows = [];
    if (items && items.length > 0) {
      items.forEach((item) => {
        let row = {};
        row['Code'] = item['code'];
        if (this.state.type == 'HCPCS')
          row['Internal Code'] = item['internal_code'];
        row['Price'] = item['price'];
        row['Reason'] = item['reason']
        FormRows.push(row);
      })
    }
    return FormRows;
  }

  // Prepare prices table. 
  preparePricesTable = (codePrices) => {
    let TableRows = [];
    if (codePrices && codePrices.length > 0) {
      codePrices.forEach((codePrice, index) => {
        let row = {};
        let type = codePrice['Internal Code'] ? 'Internal' : 'Standard';
        let Code = type == 'Internal' ? codePrice['Internal Code'] : codePrice['Standard Code'];
        row['Id'] = index + 1;
        row['Type'] = type;
        row['Code'] = Code ? Code : '-';
        if (type == 'Standard') {
          row['Description'] = codePrice['Description'] ? codePrice['Description'] : '';
        }
        row['Price'] = codePrice['Price'] ? codePrice['Price'] : '-';
        row['Action'] = [
          <div key={index}>
            <Button Style="BS12" Value="Update" onClick={() => this.onCodeInfoUpdateClick(index)} />
            <Button Style="BS12" Value="Delete" onClick={() => this.deleteCode(index)} />
          </div>
        ]
        TableRows.push(row);
      })
    }
    return TableRows;
  }

  // On update button clicked
  onCodeInfoUpdateClick = (index) => {
    let { codePrices, data } = this.state;
    let { type, code, provider_id } = data;
    let codeInfo = codePrices[index];
    let codeType = codeInfo['Internal Code'] ? 'Internal' : 'Standard';
    let field = codeType == 'Internal' ? 'Internal Code' : 'Price';
    let updatedData = { type, code, provider_id };

    this.setState({
      field,
      onUpdate: true,
      success: false,
      codeType,
      codeInfo,
      data: updatedData,
      error: null
    })
  }

  // On add button clicked
  onAddButtonClick = () => {
    let { type, code, provider_id } = this.state.data;
    this.setState(prevState => ({ addInternalCode: !prevState.addInternalCode }));
    let updatedData = { type, code, provider_id };
    this.setState({ data: updatedData, success: false, error: null })
  }


  // Actions

  // Add new code.
  insertCode = (e) => {
    e.preventDefault();
    let newCodeInfo = this.state.data;
    this.setState({ newCodeValue: newCodeInfo['internal_code'] })

    console.log(newCodeInfo);

    axios
      .put(KayanURL + 'api/sanad/addCodeInfo', { data: newCodeInfo, apiToken })
      .then(({ data }) => {
        if (data.success) {
          this.setState({ success: true });
          this.getCodesInfo();
        } else {
          this.setState({ error: data.error })
        }
      })
  }

  // Update code info.
  changeCodeInfo = (e) => {
    e.preventDefault();
    let updatedCodeInfo = this.state.data;
    let { codeInfo } = this.state;
    updatedCodeInfo['id'] = codeInfo['id'];

    console.log(updatedCodeInfo);

    axios
      .put(KayanURL + 'api/sanad/updateCodeInfo', { data: updatedCodeInfo, apiToken })
      .then(({ data }) => {
        if (data.success) {
          this.setState({ success: true });
          this.getCodesInfo();
        } else {
          this.setState({ error: data.error })
        }
      })
  }

  // Delete Code
  deleteCode = (index) => {
    let { codePrices, data } = this.state;

    let { type, code, provider_id } = data;
    let codeInfo = codePrices[index];

    let deletedCodeInfo = { id: codeInfo['id'], type, code, provider_id };
    if (codeInfo['Internal Code']) {
      deletedCodeInfo['internal_code'] = codeInfo['Internal Code']
    }

    console.log(deletedCodeInfo);

    this.setState({ success: false, error: null, codeType: codeInfo['Internal Code'] ? 'Internal' : 'Standard' });

    axios
      .put(KayanURL + 'api/sanad/deleteCode', { data: deletedCodeInfo, apiToken })
      .then(({ data }) => {
        if (data.success) {
          this.setState({ success: true });
          this.getCodesInfo();
        } else {
          this.setState({ error: data.error })
        }
      })
  }

  renderProgressBar() {
    let { now, success } = this.state;
    setTimeout(() => {
      this.setState({
        now: !success && now < 100 ? now + 20 : 100
      }, this.renderProgressBar())
    }, '800')
  }

  // Handle Input Text Change
  handleFileNameChange = ({ target: { value, files } }) => {

    const schema = {
      'code': {
        prop: 'code',
        type: String,
      },
      'price': {
        prop: 'price',
        type: String
      },
      'internal_code': {
        prop: 'internal_code',
        type: String
      }
    }

    this.setState({ filename: value.split("\\")[2], success: false, error: false, WrongTableRows: [], uploading: false, uploadSuccess: false }, () => {
      if (this.state.filename.substr(this.state.filename.lastIndexOf('.') + 1) != 'xlsx')
        alert("Invalid file type! Only Excel files are allowed.");
      else {
        readXlsxFile(files[0], { schema }).then(({ rows, errors }) => {
          this.setState({ excelContent: rows })
        })
      }
    })

  }

  readExcelFile = (reader) => {
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ excelContent: reader.result })
      }
    }
  }

  // Do request 
  doRequest = event => {
    event.preventDefault();
    this.setState({ uploading: true, error: false, TableRows: null, success: false});

    let my_data = { type: this.state.type, content: this.state.excelContent, provider_id: this.state.provider_id }

    axios
      .put(KayanURL + 'api/sanad/upload_prices', { data: my_data, apiToken })
      .then(({ data }) => {
        console.log(data);
        if (data.success) {

          this.setState({ wrongCodes: data.result.wrongCodes, uploading: false, uploadSuccess: true }, () => {
            if (this.state.wrongCodes.length == 0) {
              this.getCodesInfo()
            }
            else {
              this.setState({ WrongTableRows: this.prepareWrongCodesTable(this.state.wrongCodes) })
            }
          })
        } else {
          this.setState({ error: data.error[0], inputsHaveErrors: data.inputsHaveErrors, uploading: false });
        }
        document.getElementById('requestFile').value = null;
        this.setState({ filename: null })

      })
      .catch(() => {
        this.setState({ serverError: "There is a Server error" });
      });
  };
  

  handleSubmit = event => {
    event.preventDefault();
    this.setState({ add: true, error: false,TableRows: null,success: false});
    let my_data = { type: this.state.type, content :[{code:this.state.code, price: this.state.price, internal_code:this.state.internal_code }], provider_id: this.state.provider_id }
    console.log(my_data);
   const res = axios.put(KayanURL + 'api/sanad/upload_prices', {data: my_data, apiToken })
    .then(({ data }) =>  {
      if (data.success) {
        this.setState({ wrongCodes: data.result.wrongCodes, add: false, datasuccess: true }, () => {
          if (this.state.wrongCodes.length == 0) {
            this.getCodesInfo()
          }
          else {
            this.setState({ FormRows: this.prepareWrongCodesTableForm(this.state.wrongCodes) })
          }
        })
       }
       else {
        this.setState({ error: data.error[0], inputsHaveErrors: data.inputsHaveErrors, add: false });
      }
      })
.catch(() => {
  this.setState({ serverError: "There is a Server error" });
});
this.resetForm();
};

  onPriceManagementBrachesChange = (branchName) => {
    branchName == 'Home' ?
      this.setState({ isUploadCode: false, isImportFile: false,ImportForm: false, type: "HCPCS" })
      :
      this.setState({  type: "HCPCS", codePrices: [], TableRows: [], items: [], onUpdate: false, success: false, error: null, items: null, codeACText: '', branchName, ishomePage: false,
       isImportFile: branchName == "Upload Code Price" ? false : true, isImportFile: branchName !== "Import Prices File" ? false : true,
       isUploadCode: branchName !== "Upload Code Price" ? false : true, ImportForm: branchName !== "Import Form" ? false : true }, () => {
        if (branchName == "Upload Code Price") {
          this.getCodesInfo();
        }
      })
  }

  // Main 
  render() {
    let { ishomePage, isUploadCode, isImportFile,ImportForm,codeACText, codeACMatched, codeACStyle, type, TableRows, codeInfo, codeType, onUpdate, field, addInternalCode, newCodeValue, error, now, success, items, payerProviders, companyLicense } = this.state;
    let codeValue = codeACText.split(',')[0].trim();

    if (this.state.uploadSuccess)
      setTimeout(() => {
        this.setState({
          uploadSuccess: false
        })
      }, '3000');

      if (this.state.datasuccess)
      setTimeout(() => {
        this.setState({
          datasuccess: false
        })
      }, '3000');
      
    return (
      <Fragment>
        <NavBar />
        <Header name={localStorage.getItem('SanadName')} usertype="employee" history={this.props.history} />
        {!isImportFile && !isUploadCode && !ImportForm ? <SanadNavBar selected="2" /> : <SanadNavBar branchName={isUploadCode ? "Upload Code Price" :isImportFile ?"Import Prices File":"Import Form"}onChangeBranch={this.onPriceManagementBrachesChange} />}
        {
          
          ishomePage ?
            <div className="PriceManagementHomePage">
              <Button Style="BS13" Value="Upload Code Price" onClick={() => this.setState({ ishomePage: false, isImportFile: false,ImportForm: false, isUploadCode: true })} />
              <Button Style="BS13" Value="Import Prices File" onClick={() => this.setState({ ishomePage: false, isUploadCode: false,ImportForm: false, isImportFile: true })} />
              <Button Style="BS13" Value="Import Form" onClick={() => this.setState({ ishomePage: false, isUploadCode: false,isImportFile: false, ImportForm: true })} />
            </div>
            :
            <div className="container" style={{ "marginTop": "1%" }}>

              {
                companyLicense && payerProviders && payerProviders.length > 0 ?
                  <div style={{ "padding": "0 1%" }}>
                    <label> Select Provider:</label>
                    <select className="fullWidthSelect" onChange={this.handleProviderChange}>
                      {
                        payerProviders.map((item, index) => <option key={index}>{item}</option>)
                      }
                    </select>
                  </div>
                  :
                  null
              }
              <div  style={{ "padding": "0 1%" },{display:this.state.ImportForm!=false? "none" : "" }}>
                <label>Type:</label>
                <select name="type" value={type}  className="CodesSelect"  onChange={e => this.handleInputTextChange(e)}>
                  <option  value="HCPCS">HCPCS</option>
                  <option   value="Dental" >Dental</option>
                  <option  value="Drug">Drug</option>
                  
                </select>
              </div>
              
              
              {
                isImportFile ?

                  <Fragment>

                    <form style={{ "width": "98%" }} onSubmit={this.doRequest}>
                      <input type="file" style={{ "width": "98%" }} accept=".xlsx" id="requestFile" onChange={this.handleFileNameChange} required />
                      <input type="submit" className="BS7 customWidth" value="Upload" />

                      {
                        this.state.uploading && !this.state.uploadSuccess && !error ?
                          <div style={{ color: "green" }}> {` Uploading ${this.state.filename}`}
                            <Spinner size="sm" animation="grow" variant="success" />
                            <Spinner size="sm" animation="grow" variant="success" />
                            <Spinner size="sm" animation="grow" variant="success" />
                          </div>
                          : this.state.WrongTableRows.length <= 0 ?
                            this.state.uploadSuccess && !error ?
                              <div style={{ color: "green" }}> {`All prices were added successfully.`} </div>
                              :
                              null
                            :
                            !this.state.uploading && this.state.uploadSuccess && error ?
                              <div style={{ color: "red" }}> {error} </div>
                              :
                              null
                      }

                    </form>
                    

                    {
                      !this.state.uploading && this.state.WrongTableRows && this.state.WrongTableRows.length > 0 ?
                        <>
                          <p style={{ "fontWeight": "bold", "fontSize": "15px", "textAlign": "left" }}> All prices were added successfully except these ones:</p>

                          <Table THead={this.state.type == 'HCPCS' ? ["Id", "Code", "Internal Code", "Price", "Row No.", "Reason"] :
                            ["Id", "Code", "Price", "Row No.", "Reason"]} TBody={this.state.WrongTableRows} />

                          <Button Style="BS7" Value="Manage your prices" onClick={() => {
                            this.setState({ WrongTableRows: null, ishomePage: false, isUploadCode: true, isImportFile: false,ImportForm :false })
                          }
                          } />
                        </>
                        : null
                    }



                  </Fragment>

                    :ImportForm ?
                    <Fragment>
                      

                 <form onSubmit={this.handleSubmit} className="form4" style={{ "width": "80%" }} >
                 <div >
                <label className="label1">Type</label>
                <select name="type" value={type} className="CodesSelect1"  onChange={e => this.handleInputTextChange(e)}>
                  <option  value="HCPCS">HCPCS</option>
                  <option   value="Dental" >Dental</option>
                  <option  value="Drug">Drug</option>
                  
                </select>
              </div>
                  
                 
                <div  className="form-group">
                <div>
                <label className="label1">Code</label>
                 </div>
                 <input
                 required
                 type="text"
                 value={this.state.code}
                   onChange={this.onCodeChange.bind(this)}
                  />
                  </div>
                  
                  
                 <div className="form-group">
                   
                  <label className="label1">Price</label>
                  <input
                  required
                   type="text"
                   value={this.state.price}
                    onChange={this.onPriceChange.bind(this)}
                    />
                     </div>

                     
                <div  style={{display:this.state.type!="HCPCS"? "none" : ""}} className="form-group" >
                <label className="label1">Internal Code</label>
                   <input 
                    type="text" 
                    value={this.state.internal_code}
                  onChange={this.onInternalcodeChange.bind(this)}
                     />
                     </div>
                     
                  <div className="form-group">
                    
                  <input type="submit" className="BS7 customWidth" value="Add" />
                    </div>
                    {   this.state.add && !this.state.datasuccess && !error ?
                          <div style={{ color: "green" }}> 
                            <Spinner size="sm" animation="grow" variant="success" />
                            <Spinner size="sm" animation="grow" variant="success" />
                            <Spinner size="sm" animation="grow" variant="success" />
                          </div>

                        
                         : this.state.FormRows.length <= 0 ?
                         this.state.datasuccess && !error ?
                        <div style={{ color: "green" }}> {` added successfully.`} </div>
                        
                              :
                              null
                            :
                            !this.state.add && this.state.datasuccess && error ?
                              <div style={{ color: "red" }}> {error} </div>
                              :
                              null
                      }
          
                       {
                      !this.state.add && this.state.FormRows && this.state.FormRows.length > 0 ?
                        <>
                          <p style={{ "fontWeight": "bold", "fontSize": "15px", "textAlign": "left" }}> Fiald to add:</p>

                          <Table THead={this.state.type == 'HCPCS' ? [ "Code", "Internal Code", "Price", "Reason"] :
                            [ "Code", "Price","Reason"]} TBody={this.state.FormRows} />

                          <Button Style="BS7" Value="Manage your prices" onClick={() => {
                            this.setState({ FormRows: null, ishomePage: false, isUploadCode: true, isImportFile: false,ImportForm :false })
                          }
                          } />
                        </>
                        : null
                    }

                    
                    </form>
                    </Fragment>
                    
                  : isUploadCode ?
                    <Fragment>
                      {
                        items && items.length > 0 ?
                          <div style={{ "padding": "0 1%" }}>
                            <label>Search for the {type} code:</label>
                            <AutoComplete
                              id="CodeAutoCompleteInput"
                              hint="Search by code text or description"
                              name="code"
                              text={codeACText}
                              matched={codeACMatched}
                              Style={codeACStyle}
                              handleAutoCompleteChange={this.handleACInputChange}
                              handleItemSelection={this.handleACItemSelection}
                            />
                          </div>
                          : now ?
                            now < 100 ?
                              < ProgressBar animated variant="success" now={now} label={`${now}%`} />
                              : items && items.length == 0 && this.state.showLabel ?
                                <p style={{ "fontWeight": "bold", "fontSize": "15px", "textAlign": "center", textDecoration: "underline", "cursor": "pointer" }} onClick={() => this.setState({ showLabel: false, isUploadCode: false, isImportFile: true })}> No prices for {localStorage.getItem('SanadCompanyType') == 'Provider' ? localStorage.getItem('SanadCompany') : this.state.providerName} provider, upload {this.state.type} prices? </p>
                                : null
                            : null
                      }

                      {
                        TableRows && TableRows.length > 0 ?
                          <div style={{ "margin": "5% 0" }}>
                            <p style={{ "fontWeight": "bold", "fontSize": "15px", "textAlign": "left" }}> The {type} Code ({codeValue}) Info</p>
                            <Table THead={Object.keys(TableRows[0])} TBody={TableRows} />
                            {
                              !onUpdate && !addInternalCode && error ?
                                <div style={{ color: "red" }}> {error} </div>
                                :
                                success & !onUpdate && !addInternalCode ?
                                  <div style={{ color: "green" }}> {` The ${codeType} code is deleting`}
                                    <Spinner size="sm" animation="grow" variant="success" />
                                    <Spinner size="sm" animation="grow" variant="success" />
                                    <Spinner size="sm" animation="grow" variant="success" />
                                  </div>
                                  :
                                  null
                            }

                            {
                              onUpdate ?
                                <div className="popup">
                                  <form className="popupForm" onSubmit={this.changeCodeInfo}>
                                    <p style={{ "fontWeight": "bold", "fontSize": "15px", "textAlign": "left" }}> Change {type} Code ({codeValue}) Info</p>
                                    <div style={{ "padding": "1%" }}>
                                      <label>Field Name:</label>
                                      <select name="Field" id="Field" value={field} className="UpdateSelect" onChange={(e) => this.handleFieldInputChange(e)}>
                                        {
                                          codeType == 'Internal' ?
                                            < option value="Internal Code">Internal Code</option>
                                            :
                                            null
                                        }
                                        <option value="Price">Price</option>
                                      </select>
                                    </div>
                                    <div style={{ "padding": "1%" }}>
                                      <label>Change {field}:</label>
                                      <input type="text" id={field} name={field == 'Internal Code' ? 'internal_code' : 'price'} placeholder={codeInfo[field] != '-' ? `Current ${field} value is ${codeInfo[field]}` : `Add new ${field} value`} style={{ "width": "100%", "padding": "1vh 0.6vw" }} onChange={e => this.handleInputTextChange(e)} required />
                                    </div>
                                    <input type="submit" value={`Update ${field}`} className="BS7" />
                                    {
                                      onUpdate && error ?
                                        <div style={{ color: "red" }}> {error} </div>
                                        :
                                        success && onUpdate ?
                                          <div style={{ color: "green" }}> {` The ${field} value is updating`}
                                            <Spinner size="sm" animation="grow" variant="success" />
                                            <Spinner size="sm" animation="grow" variant="success" />
                                            <Spinner size="sm" animation="grow" variant="success" />
                                          </div>
                                          :
                                          null
                                    }
                                    <p style={{ "fontWeight": "bold", "fontSize": "15px", "textAlign": "right", "cursor": "pointer", "margin": "0 2%" }} onClick={() => this.setState({ onUpdate: false, success: false, error: null })}> Close </p>
                                  </form>
                                </div >
                                : null
                            }
                            {
                              type == 'HCPCS' ?
                                <Button Style="BS7 customWidth" Value="Add New Internal Code" onClick={() => this.onAddButtonClick()} />
                                :
                                null
                            }
                            {
                              addInternalCode ?
                                <div className="popup">
                                  <form className="popupForm" onSubmit={this.insertCode}>
                                    <p style={{ "fontWeight": "bold", "fontSize": "15px", "textAlign": "left" }}> Add a new Internal Code to the {type} Standard Code ({codeACText.split(',')[0]}) </p>
                                    <div style={{ "padding": "1%" }}>
                                      <label>Internal Code:</label>
                                      <input type="text" id="internalcode" name="internal_code" style={{ "width": "100%", "padding": "1vh 0.6vw" }} onChange={e => this.handleInputTextChange(e)} required />
                                    </div>

                                    <div style={{ "padding": "1%" }}>
                                      <label>Price:</label>
                                      <input type="number" id="price" name="price" min="0" step="0.1" style={{ "width": "100%", "padding": "1vh 0.6vw" }} onChange={e => this.handleInputTextChange(e)} required />
                                    </div>

                                    <input type="submit" value="Add" className="BS7 customWidth" />

                                    {
                                      addInternalCode && error ?
                                        <div style={{ color: "red" }}> {error} </div>
                                        :
                                        success && addInternalCode ?
                                          <div style={{ color: "green" }}> {`The new Internal Code ${newCodeValue} is adding`}
                                            <Spinner size="sm" animation="grow" variant="success" />
                                            <Spinner size="sm" animation="grow" variant="success" />
                                            <Spinner size="sm" animation="grow" variant="success" />
                                          </div>
                                          :
                                          null
                                    }

                                    <p style={{ "fontWeight": "bold", "fontSize": "15px", "textAlign": "right", "cursor": "pointer", "margin": "0 2%" }} onClick={() => this.setState({ addInternalCode: false, success: false, error: null })}> Close </p>
                                  </form>
                                </div>
                                :
                                null
                            }
                          </div>
                          :
                          items && items.length > 0 && codeACText && codeACText.length > 0 && codeACMatched && codeACMatched.length <= 0 ?
                            <Fragment>
                              <p style={{ "fontWeight": "bold", "fontSize": "15px", "textAlign": "center", "margin": "1% 0" }}> No Info available for selected {type} code ({codeValue.trim()})</p>
                            </Fragment>
                            :
                            null
                      }
                    </Fragment>
                    :null     
              }  
             
            </div>
        }
      </Fragment>
    );
  }
}
export default PriceManagement;