import React, { Component } from "react";
// import Footer from "../Components/footer";
import NavBar from "../Components/navbar";
// import KareemSubNavbar from "./KareemSubNavbar";
import KareemBenefits from "./KareemBenefits";
// import KareemRules from "./KareemRules";
import KareemForm from "./KareemForm";
import Cookies from "js-cookie";
import axios from "axios";
import settings from "../helpers/Settings";
import KareemUploadClaim from "./KareemUploadClaim";
import UpdateRule from "./UpdateRule";
import KareemUpdateHistory from "./KareemUpdateHistory";
import "../Components/Styling/navbar.css";
import Sub from "./Sub";
import KareemNetworksModule from "./KareemNetworksModule";
import KareemPriceModule from "./KareemPriceModule";
import UploadPreAuthCodesScreen from "./uploadPreAuthCodesScreen";
import UploadNotAllowedPrincipleIcd from "./UploadNotAllowedPrincipleIcd";
import NonCoveredICD from "./NonCoveredICD";
import Plan from "./plansTables";
let { KayanURL } = settings;
class KareemHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleState: 1,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    if (!Cookies.get("KareemId")) {
      history.push(`/KareemAsEmployee`, {
        errorMsg: "You need to sign in or sign up",
      });
    } else {
      //if logged in ,,
      axios
        .put(KayanURL + "api/KareemBenefits/getUserState", {
          apiToken: Cookies.get("KareemId"),
        })
        .then(({ data }) => {
          if (data.success) {
            // this.setState({ activeProcess: data.activeProcess });
            localStorage.setItem("KareemCompanyType", data.type);
          } else {
            let { error } = data;
            if (
              error &&
              Array.isArray(error) &&
              error.length > 0 &&
              error[0] == "Your Account isn't active"
            ) {
              history.push(`/KareemAsEmployee`, {
                errorMsg: "Your Account isn't active",
              });
            }
          }
        });
    }
  }
  toggleTab = (index) => {
    this.setState({ toggleState: index });
  };
  render() {
    return (
      <>
        <NavBar />
        <Sub
          history={this.props.history}
          projectType="Kareem"
          toggleState={this.state.toggleState}
          usertype="employee"
          toggleTab={this.toggleTab}
          name={localStorage.getItem("KareemUser")}
        >
          {this.state.toggleState === 1 && (
            <KareemBenefits
              toggleState={this.state.toggleState}
              toggleTab={this.toggleTab}
            />
          )}
          {this.state.toggleState === 2 && <KareemUploadClaim />}
          {/* upload claim */}
          {this.state.toggleState === 3 && <KareemForm />}
          {/* claim form */}

          {/* {this.state.toggleState === 4 && <KareemRules />} */}

          {/* upload Rules */}
          {this.state.toggleState === 5 && <UpdateRule />}
          {/* update rules and rule form */}
          {this.state.toggleState === 6 && <KareemUpdateHistory />}
          {/* update history */}
          {this.state.toggleState === 7 && <UploadPreAuthCodesScreen />}
          {/* update history */}
          {this.state.toggleState === 8 && <KareemNetworksModule />}
          {/* Networks module */}
          {this.state.toggleState === 9 && <KareemPriceModule />}
          {/* Networks module */}
          {this.state.toggleState === 10 && <UploadNotAllowedPrincipleIcd />}
          {this.state.toggleState === 11 && <NonCoveredICD />}
          {this.state.toggleState === 12 && <Plan />}
        </Sub>
      </>
    );
  }
}
export default KareemHome;
