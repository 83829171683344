import React from "react";
import { TextField, InputLabel } from "@material-ui/core";

export default function InputNumber(props) {
  const { name, label, value, onChange, min } = props;
  return (
    <>
      <InputLabel style={{ fontWeight: "bold", color: "#212529" }}>
        {label}
      </InputLabel>
      <TextField
        variant="outlined"
        type="number"
        name={name}
        value={value}
        onChange={onChange}
        InputProps={{ inputProps: { min: 0 } }}
        style={{ marginBottom: "0.5rem", width: "100%" }}
      />
    </>
  );
}
