import React from "react";
import "./InputText.css";

function InputText(props) {
  var date = new Date();
  // date.setHours(date.getHours() + 3);
  date.setHours(date.getHours());

  var date = new Date();
  date.setHours(date.getHours());
  var isodate = date.toISOString().substr(0, 16);

  let notRequiredFields = [
    "providerid",
    "payerid",
    "patientid",
    "diagnosiscode",
    "activitycode",
    "diagnosistype",
    "claimid",
    "activityid",
    "toothnumber",
    "dhpouser",
    "dhpopassword",
    "token",
  ];
  if (notRequiredFields.indexOf(props.id.toLowerCase()) !== -1) {
    return (
      <input
        className={props.Style}
        id={props.id}
        value={props.value}
        name={props.name}
        type={props.type}
        disabled={props.disabled}
        onChange={props.onChange}
        placeholder={props.hint}
      />
    );
  } else if (props.id.toLowerCase() == "start") {
    return (
      <input
        className={props.Style}
        id={props.id}
        value={props.value}
        name={props.name}
        type={props.type}
        onChange={props.onChange}
        placeholder={props.hint}
        defaultValue={isodate}
        required
      />
    );
  } else if (props.name == "endedat") {
    return (
      <input
        className={props.Style}
        id={props.id}
        value={props.value}
        name={props.name}
        type={"datetime-local"}
        onChange={props.onChange}
        placeholder={"Select Date"}
        defaultValue={isodate}
        required
      />
    );
  } else if (props.name == "endedat") {
    return (
      <input
        className={props.Style}
        id={props.id}
        value={props.value}
        name={props.name}
        type={"datetime-local"}
        onChange={props.onChange}
        placeholder={"Select Date"}
        defaultValue={isodate}
        required
      />
    );
  } else {
    return (
      <input
        className={props.Style}
        id={props.id}
        value={props.value}
        name={props.name}
        type={props.type}
        onChange={props.onChange}
        placeholder={
          props.name == "enddate" ? "Enter Number of Days" : props.hint
        }
        required
      />
    );
  }
}
export default InputText;
