import React from "react";

import axios from "axios";
import Cookies from "js-cookie";

import Spinner from "react-bootstrap/Spinner";
import settings from "../helpers/Settings";
let { KayanURL } = settings;
class ShowPriceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: NaN,
      Records: [],
      flip: false,
      response: [],
      isEditMode: false,
    };
  }

  handleActivity_type = (e) => {
    this.setState({ activity_type: e.target.value });
  };
  handleActivity_code = (e) => {
    this.setState({ activity_code: e.target.value });
  };
  handleReceiver_plan = (e) => {
    this.setState({ receiver_plan: e.target.value });
  };
  doRequest = async (event) => {
    this.setState({ isSearch: true });
    const data = await axios.put(KayanURL + "api/sama/getPriseList", {
      data: this.state.request,
      apiToken: Cookies.get("SamaId"),
      activity_type: this.state.activity_type,
      activity_code: this.state.activity_code,
      receiver_plan: this.state.receiver_plan,
    });

    this.setState({ response: data["data"] });
    this.setState({ isSearch: false });

    if (this.state.response) {
      this.setState({ AllPrise: this.state.response["price"] });
    }
  };

  async update(event) {
    event.preventDefault();
    let { editableRow } = this.state;

    let fields = Object.keys(editableRow);

    if (fields && fields.length > 0) {
      const response = await axios.put(KayanURL + "api/sama/updatePriseList", {
        parameters: editableRow,
        apiToken: Cookies.get("SamaId"),
      });
      if (response) {
        let { data } = response;
        if (data.success) {
          this.setState({ ed: true });
          delete Cookies.get("SamaId");
          delete editableRow["id"];
          this.changeEditMode();
          this.doRequest();
        }
      }
    } else {
      alert("Change one value at least");
    }
  }

  changeEditMode = () => {
    this.setState({
      isEditMode: !this.state.isEditMode,
    });
  };

  handleNewInputTextChange = ({ target: { name, value } }) => {
    this.setState({
      editableRow: { ...this.state.editableRow, [name]: value },
    });
  };

  renderEditView = () => {
    let { editableRow } = this.state;
    return (
      <div style={{ marginTop: "50px" }}>
        <table
          striped
          bordered
          hover
          style={{ width: " 60%", height: "40%", margin: "auto" }}
        >
          <thead style={{ background: "#3ab449", color: " white" }}>
            <tr>
              <th>Activity Type</th>
              <th>Activity Code</th>
              {/*   <th>Receiver Plan</th>

              <th>Activity Description</th> */}
              <th>Gross Price</th>
              {/*  <th>Patient Share(%)</th> */}
              <th>Patient Share Amount</th>

              {/*   <th>Approved Discount</th> */}
              <th>Net</th>
              {/*   <th>Receiver ID</th>

              <th>Facility ID</th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              {" "}
              <td style={{ width: " 155px" }}>
                <input
                  onChange={(e) => this.handleNewInputTextChange(e)}
                  className="Input-sama6"
                  type="text"
                  name="activity_type"
                  defaultValue={editableRow["activity_type"]}
                />
              </td>
              <td style={{ width: " 155px" }}>
                <input
                  onChange={(e) => this.handleNewInputTextChange(e)}
                  className="Input-sama6"
                  type="text"
                  name="activity_code"
                  defaultValue={editableRow["activity_code"]}
                />
              </td>
              {/*  <td style={{ width: " 155px" }}>
                <input
                  onChange={(e) => this.handleNewInputTextChange(e)}
                  className="Input-sama6"
                  type="text"
                  name="receiver_plan"
                  defaultValue={editableRow["receiver_plan"]}
                />
              </td> */}
              {/*     <td style={{ width: " 155px" }}>
                <input
                  onChange={(e) => this.handleNewInputTextChange(e)}
                  className="Input-sama6"
                  type="text"
                  name="activity_description"
                  defaultValue={editableRow["activity_description"]}
                />
              </td> */}
              <td style={{ width: " 155px" }}>
                <input
                  onChange={(e) => this.handleNewInputTextChange(e)}
                  className="Input-sama6"
                  type="text"
                  name="gross_price"
                  defaultValue={editableRow["gross_price"]}
                />
              </td>
              {/*   <td style={{ width: " 155px" }}>
                <input
                  onChange={(e) => this.handleNewInputTextChange(e)}
                  className="Input-sama6"
                  type="text"
                  name="patient_shareper"
                  defaultValue={editableRow["patient_shareper"]}
                />
              </td> */}
              <td style={{ width: " 155px" }}>
                <input
                  onChange={(e) => this.handleNewInputTextChange(e)}
                  className="Input-sama6"
                  type="text"
                  name="patient_shareamt"
                  defaultValue={editableRow["patient_shareamt"]}
                />
              </td>
              {/*   <td style={{ width: " 155px" }}>
                <input
                  onChange={(e) => this.handleNewInputTextChange(e)}
                  className="Input-sama6"
                  type="text"
                  name="approved_discount_per"
                  defaultValue={editableRow["approved_discount_per"]}
                />
              </td> */}
              <td style={{ width: " 155px" }}>
                <input
                  onChange={(e) => this.handleNewInputTextChange(e)}
                  className="Input-sama6"
                  type="text"
                  name="net_amt"
                  defaultValue={editableRow["net_amt"]}
                />
              </td>
              {/*   <td style={{ width: " 155px" }}>
                <input
                  onChange={(e) => this.handleNewInputTextChange(e)}
                  className="Input-sama6"
                  type="text"
                  name="receiver_iD"
                  defaultValue={editableRow["receiver_iD"]}
                />
              </td>
              <td style={{ width: " 155px" }}>
                <input
                  onChange={(e) => this.handleNewInputTextChange(e)}
                  className="Input-sama6"
                  type="text"
                  name="facility_iD"
                  defaultValue={editableRow["facility_iD"]}
                />
              </td> */}
            </tr>
          </tbody>
        </table>
        <div
          style={{
            margin: "auto",
            width: "200px",
            marginBottom: "20px !important",
          }}
        >
          <input
            style={{ marginTop: "50px" }}
            type="submit"
            value="Cancel"
            className="GenerateBtnStyle7"
            onClick={this.changeEditMode}
            name="name"
          />
          <input
            style={{ marginTop: "50px" }}
            type="submit"
            value="Save"
            className="GenerateBtnStyle8"
            onClick={this.update.bind(this)}
            name="name"
          />
        </div>
      </div>
    );
  };

  render() {
    let { AllPrise, isSearch, isEditMode } = this.state;

    let PriseListTable;
    if (AllPrise && AllPrise.length > 0) {
      PriseListTable = AllPrise.map((row, index) => {
        return (
          <tr key={index}>
            <td
              onDoubleClick={() => {
                this.setState({ editableRow: row });
                this.changeEditMode();
              }}
            >
              {row["id"]}
            </td>
            <td>{row["activity_type"]}</td>
            <td>{row["activity_code"]}</td>
            {/*   <td>{row["receiver_plan"]}</td> */}
            {/*  <td>{row["activity_description"]}</td> */}
            <td>{row["gross_price"]}</td>
            {/*  <td>{row["patient_shareper"]}</td> */}
            <td>{row["patient_shareamt"]}</td>
            {/* <td>{row["approved_discount_per"]}</td> */}
            <td>{row["net_amt"]}</td>
            {/*  <td>{row["receiver_iD"]}</td>
            <td>{row["facility_iD"]}</td> */}
          </tr>
        );
      });
    }
    return (
      <div className="container">
        <div
          className="row"
          style={{ marginTop: "100px", marginBottom: "60px" }}
        >
          <div className="col-sm-6">
            Activity Type
            <input
              type="text"
              name="name"
              onChange={this.handleActivity_type}
              style={{ marginLeft: "20px" }}
            />
          </div>

          <div className="col-sm-6">
            Activity Code
            <input
              type="text"
              name="name"
              onChange={this.handleActivity_code}
              style={{ marginLeft: "20px" }}
            />
          </div>
          {/*       <div className="col-sm-4">
            Receiver Plan
            <input
              type="text"
              name="name"
              onChange={this.handleReceiver_plan}
              style={{ marginLeft: "20px" }}
            />
          </div> */}
        </div>

        <input
          type="submit"
          value="Search"
          className="BS1"
          style={{ marginTop: "5px" }}
          onClick={this.doRequest.bind(this)}
          name="name"
        />

        {isEditMode ? (
          this.renderEditView()
        ) : (
          <div style={{ padding: "30px" }}>
            {isSearch ? <Spinner animation="border" variant="success" /> : null}
            {AllPrise && AllPrise.length > 0 ? (
              <table striped bordered hover style={{ marginTop: "20px" }}>
                <thead
                  style={{
                    background: "#3ab449",
                    color: " white",
                  }}
                >
                  <tr>
                    <th>ID</th>
                    <th>Activity Type</th>
                    <th>Activity Code</th>
                    {/*   <th>Receiver Plan</th>

                    <th>Activity Description</th> */}
                    <th>Gross Price</th>
                    {/*   <th>Patient Share(%)</th> */}
                    <th>Patient Share Amount</th>

                    {/*   <th>Approved Discount(%)</th> */}
                    <th>Net</th>
                    {/*   <th>Receiver ID</th>

                    <th>Facility ID</th> */}
                  </tr>
                </thead>
                <tbody>{PriseListTable}</tbody>
              </table>
            ) : AllPrise && AllPrise.length == 0 ? (
              <h6>There is no Price list in DB</h6>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default ShowPriceList;
