import React from "react";
import "./Codes.css";

import SamaListView from "../SamaListView";
import Button from "../Button";

import Input from "../InputText";

import ProgressBar from "react-bootstrap/ProgressBar";



function defaultClick(e) {
  e.preventDefault();
}

function ActivityCodeList(props) {
  let {
    Items,
    inputStyle,
    addNewCode,
    listName,

    section,
    BtnActive,

    ActivitySuggestions,
    onChange,
    onClick,

    userInputActivity,
    activeSuggestion,

    filteredSuggestionsActivity,
    showActivitySuggestions,
    handleItemDeletion,
    ActivityNow,
  } = props;

  let suggestionsListComponentActivity;

  if (showActivitySuggestions && userInputActivity) {
    if (filteredSuggestionsActivity && filteredSuggestionsActivity.length) {
      suggestionsListComponentActivity = (
        <ul className="suggestions">
          {filteredSuggestionsActivity.map((suggestion, index) => {
            let className;

            // Flag the active suggestion with a class
            if (index === activeSuggestion) {
              className = "suggestion-active";
            }

            return (
              <li className={className} key={suggestion} onClick={onClick}>
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    } else {
      suggestionsListComponentActivity = (
        <div className="no-suggestions">
          <em>No suggestions...</em>
        </div>
      );
    }
  }

  let InputId = "",
    BtnValue = "";
  if (listName === "ActivityCodes") {
    InputId = "activityCode";
    BtnValue = "add new activity";
  }


  return (
    <React.Fragment>
      {Items && Items.length > 0 ? (
        <SamaListView
          Items={Items}
          name="codes"
          handleItemDeletion={handleItemDeletion}
          listName={listName}
        />
      ) : null}

      <div >
        {
          //!suggestions
          ActivitySuggestions.length === 0 ? (
            <ProgressBar
              style={{ width: "70%", margin: "25px auto" }}
              animated
              variant="success"
              now={ActivityNow}
            />
          ) : null
        }

        <Input
          id={InputId}

          name="Text"
          disabled={ActivitySuggestions.length === 0 ? true : false}
          Style={inputStyle}
          onChange={(e) => onChange(e, listName)}
          hint="Code"
          value={userInputActivity}
        />
        {listName === "ActivityCodes"
          ? suggestionsListComponentActivity
          : null}
      </div>



      {listName === "ActivityCodes" && section === "Activity" && BtnActive ? (
        <React.Fragment>
          <Button
            Style="enabledStyle"
            Value={BtnValue}
            onClick={(e) => addNewCode(e, listName, InputId)}
          />
        </React.Fragment>
      ) : (
          <Button
            Style="disabledStyle"
            Value={BtnValue}
            onClick={(e) => defaultClick(e)}
          />
        )}
    </React.Fragment>
  );
}
export default ActivityCodeList;
