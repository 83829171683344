import React from "react";
import "./ListView.css";

function ListView(props) {


    let Items = [], text = '';
    switch (props.name) {
        case 'codes':
            props.Items.forEach((item, index) => {
                text = `Code${index + 1} : ${item.Text}`;
                Items.push(text)
            });
            break;

        case 'encounters':
            props.Items.forEach((item, index) => {
                text = `Encounter${index + 1}, Type : ${item.Encounter.Type}, Visit Date : ${item.Encounter.Date}`;
                Items.push(text)
            });
            break;
        default:
            Items = props.Items;
            break;
    }

    return (
        <ul className={props.Style}>
            {
                Items.map((item, index) =>
                    props.handleItemSelection ?
                        <li key={index} onClick={() => props.handleItemSelection(item)}>
                            {item}
                        </li>
                        :
                        <li key={index} >
                            {item}
                        </li>
                )
            }
        </ul>
    )
}
export default ListView;