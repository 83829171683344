import React, { Component } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Spinner from "react-bootstrap/Spinner";
import settings from "../helpers/Settings";
let { KayanURL } = settings;
var XLSX = require("xlsx");

class UploadNetworkExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount = async () => {};

  handleInputTextChange = ({ target: { name, value, files } }) => {
    //  this.setState({ responseReady: false });
    this.setState({ fileName: files[0].name });

    const fileUpload = document.getElementById("file");
    const regex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
    if (regex.test(fileUpload.value.toLowerCase())) {
      let fileName = fileUpload.files[0].name;
      if (typeof FileReader !== "undefined") {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = (e) => {
            this.processExcel(reader.result);
          };
          reader.readAsBinaryString(fileUpload.files[0]);
        }
      } else {
        console.log("This browser does not support HTML5.");
      }
    } else {
      alert("Please upload a valid Excel file.");
    }
  };

  processExcel(data) {
    const workbook = XLSX.read(data, { type: "binary" });

    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[firstSheet]
    );
    // let excelRows = JSON.stringify(excelRows1);
    this.setState({ excelRows });
  }

  async onSubmit(e) {
    e.preventDefault();
    let { excelRows } = this.state;
    this.setState({ sent: true });

    let networksList = [];
    if (excelRows) {
      excelRows.forEach((row) => {
        let element = {};
        element["active"] = row["active"];
        element["authorizing_amount"] = row["authorizing_amount"];
        element["max_number_of_days_for_resubmissions"] =
          row["max_number_of_days_for_resubmissions"];
        element["max_number_of_days_for_submission"] =
          row["max_number_of_days_for_submission"];
        element["max_number_of_resubmission"] =
          row["max_number_of_resubmission"];
        element["network_description"] = row["network_description"];
        element["network_name"] = row["network_name"];
        element["patient_share_max_amount"] = row["patient_share_max_amount"];
        element["payer_id"] = row["payer_id"];
        element["tpa"] = row["tpa"];

        element["discount"] = {
          consultation_discount: row.consultation_discount,
          laboratory_discount: row.laboratory_discount,
          radiology_discount: row.radiology_discount,
          procedure_discount: row.procedure_discount,
          consumables_discount: row.consumables_discount,
          medication_discount: row.medication_discount,
          dental_discount: row.dental_discount,
        };
        element["patient_share"] = {
          consultation_share: row.consultation_patientshare,
          laboratory_share: row.laboratory_patientshare,
          radiology_share: row.radiology_patientshare,
          procedure_share: row.procedure_patientshare,
          consumables_share: row.consumables_patientshare,
          medication_share: row.medication_patientshare,
          dental_share: row.dental_patientshare,
        };
        networksList.push(element);
      });
    }

    let response;
    if (this.props.project == "Sama") {
      response = await axios.post(
        KayanURL + "api/sama/networks/AddNewNetwork",
        {
          apiToken: Cookies.get("SamaId"),
          networks: networksList,
        }
      );
    } else {
      response = await axios.post(
        KayanURL + "api/KareemBenefits/networks/AddNewNetwork",
        {
          apiToken: Cookies.get("KareemId"),
          networks: networksList,
        }
      );
    }

    if (response) {
      let { data } = response;

      if (data.success) {
        this.setState({ success: true, sent: false, responseReady: true });
        this.props.getAllNetworks();
      } else {
        this.setState({ success: false, error: data.error, sent: false });
      }
    }
  }

  render() {
    let { sent, success, responseReady, error } = this.state;
    let errorMap = [];
    if (error) {
      errorMap = error.map((err, index) => {
        return <h5 style={{ color: "red" }}>{err}</h5>;
      });
    }
    return (
      <>
        <div className="container">
          <form onSubmit={this.onSubmit}>
            <input
              type="file"
              name="file"
              id="file"
              onChange={this.handleInputTextChange}
            />

            <input type="submit" value="Upload" className="BS1" />
          </form>
          {sent ? <Spinner animation="border" variant="success" /> : null}

          {responseReady && success ? (
            <h5 style={{ color: "green" }}>
              Price list was uploaded successfully
            </h5>
          ) : (
            <h5 style={{ color: "red" }}>{errorMap}</h5>
          )}
        </div>
      </>
    );
  }
}

export default UploadNetworkExcel;
