import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";

// Kayan section
import MainPage from "./views/MainPage";
// import ContactUs from "./views/ContactUs";

// Bayan section
import Bayan from "./views/Bayan";
import Sayan from "./views/Sayan";
import BayanDocument from "./views/BayanDoc";
import SayanDocument from "./views/SayanDoc";
import Schema from "./views/BayanSchema";
import Sample from "./views/BayanSample";
import Login from "./views/Login";
import LoginAsEmployee from "./views/loginEmployeeForm";
import Employee from "./views/employee";
import Admin from "./views/Profile/Admin/index";
import RulesActivation from "./views/RulesActivation";

///////////
//BAyan KSA

import BayanKSA from "./BayanKSAviews/BayanKSAHome";
import BayanKSALogin from "./BayanKSAviews/BayanKSALogin";
import BayanKSAEmployee from "./BayanKSAviews/BayanKSAEmployee";

// Sanad section
import Sanad from "./SanadViews/Sanad";
import SanadDocument from "./SanadViews/SanadDoc";
import SanadSchema from "./SanadViews/SanadSchema";
import SanadSample from "./SanadViews/SanadSample";
import SanadLogin from "./SanadViews/Login";
import SanadLoginAsEmployee from "./SanadViews/loginEmployeeForm";
import SanadEmployee from "./SanadViews/employee";
import DRGCalculater from "./SanadViews/DRGCalculater";
import PriceManagement from "./SanadViews/PriceManagement";
import FactorManagement from "./SanadViews/FactorManagement";
import SanadUserManagement from "./SanadViews/UserManagement";

// Sama section
import Sama from "./SamaViews/Sama";
import SamaDocument from "./SamaViews/SamaDoc";
import SamaSchema from "./SamaViews/SamaSchema";
import SamaSample from "./SamaViews/SamaSample";
import SamaLogin from "./SamaViews/Login";
import SamaLoginAdmin from "./SamaViews/LoginAdminForm";
import SamaLoginAsEmployee from "./SamaViews/loginEmployeeForm";

import SamaLoginAsCompany from "./SamaViews/loginCompanyForm";
import SamaEmployee from "./SamaViews/employee";
import SamaCompany from "./SamaViews/profile/company";
import SamaRequest from "./SamaViews/SamaRequest";
import SamaUserManagement from "./SamaViews/UserManagement";
// import SolutionsPage from "./views/solutions";
// Info section
import KayanData from "./Components/KayanData";
// import BayanInfo from "./views/BayanInfo";
// import SayanInfo from "./views/SayanInfo";
// import SanadInfo from "./views/SanadInfo";
// import HudaInfo from './views/HudaInfo'
// import SamaInfo from './views/SamaInfo'
import ResearchPage from "./views/ResearchPage";
// import SomaInfo from './views/SomaInfo'
import ProviderSolutions from "./views/ProviderSolutions";
import PayerSolutions from "./views/PayerSolutions";
import OurTeam from "./views/OurTeam";
import Kareem from "./KareemBenefits/Kareem";
import KareemLogin from "./KareemBenefits/KareemLogin";
import KareemEmployee from "./KareemBenefits/KareemEmployee";
import KareemAdmin from "./KareemBenefits/KareemAdmin";
import KareemHome from "./KareemBenefits/KareemHome";

function App() {
  return (
    <div className="App">
      <Router>
        {/* Kayan Routes */}
        <Route exact path="/" component={MainPage} />

        {/* Kareem Benefits Routes */}
        <Route exact path="/Kareem" component={Kareem} />
        <Route exact path="/KareemSchema" component={Schema} />
        <Route exact path="/KareemSample" component={Sample} />
        <Route exact path="/Kareem/login" component={KareemLogin} />
        <Route exact path="/KareemAsEmployee" component={KareemEmployee} />
        <Route exact path="/KareemHome" component={KareemHome} />
        <Route exact path="/KareemAdmin" component={KareemAdmin} />
        {/* <Route exact path="/KareemAsCompany" component={KareemCompany} /> */}

        {/* Bayan Routes */}
        <Route exact path="/Sayan" component={Sayan} />
        <Route exact path="/Bayan" component={Bayan} />

        <Route exact path="/BayanKSA" component={BayanKSA} />
        <Route exact path="/BayanKSA/login" component={BayanKSALogin} />
        <Route exact path="/BayanKSAAsEmployee" component={BayanKSAEmployee} />

        {/* ////////////////////////////////// */}

        <Route exact path="/SayanDocumentation" component={SayanDocument} />
        <Route exact path="/BayanDocumentation" component={BayanDocument} />
        <Route exact path="/schema" component={Schema} />
        <Route exact path="/sample" component={Sample} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/employee" component={Employee} />
        <Route exact path="/admin" component={Admin} />
        <Route exact path="/loginAsEmployee" component={LoginAsEmployee} />
        <Route exact path="/rulesActivation" component={RulesActivation} />
        <Route exact path="/KayanData" component={KayanData} />
        {/* Sanad Routes */}
        <Route exact path="/Sanad" component={Sanad} />
        <Route exact path="/Huda" component={Sanad} />
        <Route exact path="/Sanad/documentation" component={SanadDocument} />
        <Route exact path="/Sanad/schema" component={SanadSchema} />
        <Route exact path="/Sanad/sample" component={SanadSample} />
        <Route exact path="/Sanad/login" component={SanadLogin} />
        <Route exact path="/Sanad/employee" component={SanadEmployee} />
        <Route
          exact
          path="/Sanad/loginAsEmployee"
          component={SanadLoginAsEmployee}
        />
        <Route exact path="/Sanad/calculator" component={DRGCalculater} />
        <Route
          exact
          path="/Sanad/priceManagement"
          component={PriceManagement}
        />
        <Route
          exact
          path="/Sanad/factorManagement"
          component={FactorManagement}
        />
        <Route
          exact
          path="/Sanad/userManagement"
          component={SanadUserManagement}
        />

        {/* Sama Routes */}
        <Route exact path="/Sama" component={Sama} />
        <Route exact path="/Somod" component={Sama} />
        <Route exact path="/Sama/documentation" component={SamaDocument} />
        <Route exact path="/Sama/schema" component={SamaSchema} />
        <Route exact path="/Sama/sample" component={SamaSample} />
        <Route exact path="/Sama/login" component={SamaLogin} />
        <Route exact path="/Sama/employee" component={SamaEmployee} />
        <Route exact path="/Sama/admin" component={SamaLoginAdmin} />

        <Route exact path="/Sama/company" component={SamaCompany} />
        <Route
          exact
          path="/Sama/loginAsEmployee"
          component={SamaLoginAsEmployee}
        />

        <Route
          exact
          path="/Sama/loginAsCompany"
          component={SamaLoginAsCompany}
        />
        <Route exact path="/Sama/request" component={SamaRequest} />
        <Route
          exact
          path="/Sama/userManagement"
          component={SamaUserManagement}
        />
        {/* <Route exact path="/solutions" component={SolutionsPage} /> */}
        {/* <Route exact path="/bayanInfo" component={BayanInfo} />
        <Route exact path="/sayanInfo" component={SayanInfo} />
        <Route exact path="/sanadInfo" component={SanadInfo} />
        <Route exact path="/hudaInfo" component={HudaInfo} />
        <Route exact path="/samaInfo" component={SamaInfo} />
        <Route exact path="/SomaInfo" component={SomaInfo} /> */}
        <Route exact path="/research" component={ResearchPage} />
        <Route exact path="/providerSolutions" component={ProviderSolutions} />
        <Route exact path="/payerSolutions" component={PayerSolutions} />
        <Route exact path="/info" component={OurTeam} />
      </Router>
    </div>
  );
}

export default App;
