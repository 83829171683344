import React from "react";
import "./Link.css";

function Link(props) {
  return (
    <a
      className={props.Style}
      href={props.URL}
      target="_blank"
      rel="noopener noreferrer"
      onClick={props.onAction}
    >
      {props.Text}
    </a>
  );
}
export default Link;
