import React from "react";
// import MainPageFooter from '../Components/MainPageFooter'
import NavBar from "../Components/navbar";
import "../Components/Styling/OurTeam.css";
import styles from "../Components/Styling/contactPage.module.css";
import { FaLocationArrow } from "react-icons/fa";
import { FaPhone, FaXRay, FaMailBulk } from "react-icons/fa";
import Footer from "../Components/footer";
const OurTeam = () => {
  return (
    <>
      <NavBar />
      <div className="container22">
        <h1 className="heading-team">Management Team</h1>
        <div className="borderBottom-team"></div>
        <div className="profiles-team">
          <div className="row profile-team">
            <div className="col-sm3 member-pic">
              <img
                src={require("../imgs/kayan-ceo.jpg")}
                alt="islam"
                className="profile-img"
              />
            </div>
            <div className="col-sm3 member-contact">
              <ul className="list-unstyled">
                <li className="item-team">
                  <FaLocationArrow className="footer-icon" />
                  UAE,Dubai
                </li>
                {/*  <li className="item-team"><FaMailBulk className="footer-icon"/> <a className="email-team" href="mailto:ihijawi@kayan-healthcare.com">ihijawi@kayan-healthcare.com</a></li> */}
                <li className="item-team">
                  <FaPhone className="footer-icon" /> +971561207460
                </li>
              </ul>
            </div>
            <div className="col-sm6 member-info">
              <h3 className="user-name-team">Islam Hijawi</h3>
              <h5>General Manager</h5>
              <p>
                Islam Hijawi is the Owner and Managing Director
                {/*  of Kayan
                Healthcare */}
                . An effective leader with excellent management skills that
                promote productivity towards achieving project and
                organizational goals. Islam is holding a High Diploma in
                Management, master’s degree in public health and Strategic
                Planning, and Bachelor of Physiotherapy. Finally, Islam speaks
                Arabic and English.
              </p>
            </div>
          </div>
          <div className="row profile-team">
            <div className="col-sm3 member-pic">
              <img
                src={require("../imgs/kareem.jpg")}
                alt="islam"
                className="profile-img"
              />
            </div>
            <div className="col-sm3 member-contact">
              <ul className="list-unstyled">
                <li className="item-team">
                  <FaLocationArrow className="footer-icon-team" />
                  Palestine,Jenin
                </li>
                {/*  <li className="item-team"><FaMailBulk className="footer-icon-team"/> <a className="email-team" href="mailto:kareemkh@kayan-healthcare.com">kareemkh@kayan-healthcare.com</a></li> */}
                <li className="item-team">
                  <FaPhone className="footer-icon-team" />
                  +970599879418
                </li>
              </ul>
            </div>
            <div className="col-sm6 member-info">
              <h3 className="user-name-team">Kareem Khaleel</h3>
              <h5>IT Director</h5>
              <p>
                I’m a computer systems engineer with a master’s degree in
                computer science. I’m in the field of IT for about 10 years. I
                have worked in many positions starting from development, testing
                and now I’m in the management roles. I have worked as a business
                analyst for some time and learned the Gulf market experience in
                the previous years. Since Kayan Healthcare Technologies started,
                I was always there. And we will continue the journey till we
                reach our ambitious goals.
              </p>
            </div>
          </div>
          <div className="row profile-team">
            <div className="col-sm3 member-pic">
              <img
                src={require("../imgs/mohammed.jpg")}
                alt="islam"
                className="profile-img"
              />
            </div>
            <div className="col-sm3 member-contact">
              <ul className="list-unstyled">
                <li className="item-team">
                  <FaLocationArrow className="footer-icon" />
                  India
                </li>
                {/*  <li className="item-team"><FaMailBulk className="footer-icon"/> <a className="email-team" href="mailto:mali@kayan-healthcare.com">mali@kayan-healthcare.com</a></li> */}
                <li className="item-team">
                  <FaPhone className="footer-icon" />
                  +919746945198
                </li>
              </ul>
            </div>
            <div className="col-sm6 member-info">
              <h3 className="user-name-team">Dr.Mohammed Ali</h3>
              <h5>Medical Team Leader</h5>
              <p>
                AAPC certified Medical coder with 6 years of experience. Have
                bachelor's degree in Medicine. Expert in ICD10 CM, CPT, HCPCS.
                Developing clinical edit solutions for Bayan. Having knowledge
                in every aspect of the RCM process.
              </p>
            </div>
          </div>
          {/* <div className="row profile">
                <div className="col-md4 member-pic">
                  <img src={require('../imgs/kayan-ceo.jpg')} alt="islam" className="profile-img"/>
                </div>
                <div className="col-md8 member-info">   
                  <h3 className="user-name">Kareem Khaleel</h3> 
                  <h5>IT Director</h5>      
                  <p>I’m a computer systems engineer with a master’s degree in computer science. I’m in the field of IT for about 10 years. I have worked in many positions starting from development, testing and now I’m in the management roles. I have worked as a business analyst for some time and learned the Gulf market experience in the previous years. Since Kayan Healthcare Technologies started, I was always there. And we will continue the journey till we reach our ambitious goals.</p>
                </div>
              </div>
              <div className="row profile">
                <div className="col-md4 member-pic">
                  <img src={require('../imgs/kayan-ceo.jpg')} alt="islam" className="profile-img"/>
                </div>
                <div className="col-md8 member-info">   
                  <h3 className="user-name">Mohammed Ali</h3> 
                  <h5>Medical Team Leader</h5>      
                  <p>AAPC certified Medical coder with 6 years of experience. Have bachelor's degree in Medicine. Expert in ICD10 CM, CPT, HCPCS. Developing clinical edit solutions for Bayan. Having knowledge in every aspect of the RCM process.</p>
                </div>
              </div> */}
        </div>
        <h1 id="contact" className="heading-team">
          offices
        </h1>
        <div className="borderBottom"></div>
        <div className={styles.row} style={{ textAlign: "left" }}>
          <div className={styles.column}>
            <h3 className={styles.header}>UAE</h3>
            <ul className="list-unstyled">
              <li className={styles.content}>
                <FaLocationArrow className="footer-icon-team" />
                Address
              </li>
              <li>
                Dubai, Sheikh Zayed Road, Aspin Commercial Tower, 44th Floor
              </li>
              <li className={styles.content}>
                <FaMailBulk className="footer-icon-team" />
                Email
              </li>
              {/*  <li>ihijawi@kayan-healthcare.com</li> */}
              <li className={styles.content}>
                <FaPhone className="footer-icon-team" />
                Phone
              </li>
              <li>+971561207460</li>
            </ul>
          </div>
          <div className={styles.column1}>
            <iframe
              className={styles.map}
              title="kayan"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.8296308258423!2d55.27468708567244!3d25.208967337410638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f428853023db3%3A0x6e63a9fe6b2d7cfe!2zQXNwaW4gQ29tbWVyY2lhbCBUb3dlciAtIDEwNiDYtNin2LHYuSDYp9mE2LTZitiuINiy2KfZitivIC0g2KfZhNmF2LHZg9iyINin2YTYqtis2KfYsdmK2KfZhNmF2LHZg9iyINin2YTYqtis2KfYsdmKINin2YTYo9mI2YTZiiAtINiv2KjZiiAtINin2YTYpdmF2KfYsdin2Kog2KfZhNi52LHYqNmK2Kkg2KfZhNmF2KrYrdiv2Kk!5e0!3m2!1sar!2s!4v1631637355304!5m2!1sar!2s"
              allowfullscreen=""
              loading="lazy"
            />
          </div>
        </div>
        <div className={styles.row} style={{ textAlign: "left" }}>
          <div className={styles.column}>
            <h3 className={styles.header}>Palestine</h3>
            <ul className="list-unstyled">
              <li className={styles.content}>
                <FaLocationArrow className="footer-icon-team" />
                Address
              </li>
              <li>Jenin, Haifa street, Al-Bareq Building, 5th floor</li>
              <li className={styles.content}>
                <FaMailBulk className="footer-icon-team" />
                Email
              </li>
              {/*  <li>kareemkh@kayan-healthcare.com</li> */}
              <li className={styles.content}>
                <FaPhone className="footer-icon-team" />
                Phone
              </li>
              <li>+970599879418</li>
            </ul>
          </div>
          <div className={styles.column1}>
            <iframe
              className={styles.map}
              title="kayan"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1683.143984410883!2d35.29468781652818!3d32.46498859783975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzLCsDI3JzU0LjAiTiAzNcKwMTcnMzguMSJF!5e0!3m2!1sar!2s!4v1632145775434!5m2!1sar!2s"
              allowfullscreen=""
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OurTeam;
