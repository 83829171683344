import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import Spinner from "react-bootstrap/Spinner";

const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    backgroundColor: "white",
  },
  title: {
    textAlign: "center",
    paddingTop: 0,
  },
  loader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1rem",
  },
  content: {
    padding: "1rem",
    width: "100%",
    height: "100%",
  },
}));

const FormModal = ({
  isLoading,
  open,
  onClose,
  title,
  maxWidth = "sm",
  children,
}) => {
  const classes = useStyles();

  return (
    <Dialog fullWidth open={open} maxWidth={isLoading ? "lg" : maxWidth}>
      {isLoading ? (
        <Box className={classes.loader}>
          <div>
            <Spinner animation="border" variant="success" />
          </div>
        </Box>
      ) : (
        <>
          <Box className={classes.box}>
            <CancelIcon
              style={{ cursor: "pointer", height: "100%" }}
              color="error"
              onClick={() => onClose()}
            />
          </Box>
          {title && (
            <DialogTitle className={classes.title}>{title}</DialogTitle>
          )}
          <DialogContent className={classes.content}>{children}</DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default FormModal;
