import React from "react";
import Form from "./../Components/Form/index"

let ICDs = require("./DiagnosisItems")
let CPTs = require("./CptItems")


class FormUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            diagnosisItems: [],
            ActivityItems: [],
            DiagnosisCodes: [],
            ActivityCodes: [],
            data: {},
            patient: {}
        };
    }
    componentDidMount = () => {
        if (ICDs) {
            let diagnosisItems = []
            ICDs.forEach((element) => {
                if (element.shortdesc === "null") {
                    element.shortdesc = "";
                }
                diagnosisItems.push(element.code + "-" + element.shortdesc);
            });
            this.setState({ diagnosisItems: diagnosisItems })
        }
        if (CPTs) {

            let cptItems = []
            ICDs.forEach((element) => {
                if (element.shortdesc === "null") {
                    element.shortdesc = "";
                }
                cptItems.push(element.code + "-" + element.shortdesc);
            });
            this.setState({ ActivityItems: cptItems })

        }
    }

    // on click on next button
    next = (e) => {
        e.preventDefault()
        console.log("next");
        this.setState({ "isNext": true })
    }

    // Handling Select Actions.
    //on select element from drop down list like gender
    onSelectOption = (e) => {
        let select = document.getElementById(e);
        let name = select.name;
        let value = select.options[select.selectedIndex].value;
        switch (name) {
            case "Gender":
                this.setState({ patient: { ...this.state.patient, [name]: value } }, () => {
                    console.log(this.state.patient);

                });
                break;


            default:
                //this.setState({ data: { ...this.state.data, [name]: value } });
                break;
        }
    };

    //to handle change in text input like Age,InsuranceID, Insurance Company
    handleInputTextChange = ({ target: { name, value } }) => {

        switch (name) {

            case "Age":

                this.setState({ patient: { ...this.state.patient, [name]: value } }, () => {
                    console.log(this.state.patient);

                });
                break;

            case "InsuranceID":
                this.setState({ patient: { ...this.state.patient, [name]: value } }, () => {
                    console.log(this.state.patient);

                });
                break;

            case "InsuranceCompany":
                this.setState({ patient: { ...this.state.patient, [name]: value } }, () => {
                    console.log(this.state.patient);

                });
                break;


            default:
                this.setState({ data: { ...this.state.data, [name]: value } });
                break;
        }

    }

    //to handle changes in the text input of Auto Complete Component (Diagnosis, Activity) 
    onChange = ({ target: { name, value } }, listName) => {


        if (value.length <= 1) {
            value = value.trim();
        }
        switch (listName) {
            case "DiagnosisCodes":
                this.setState({
                    section: "Diagnosis",
                });
                break;
            default:
                this.setState({
                    section: "Activity",
                });
                break;
        }

        if (listName === "DiagnosisCodes" && value === "") {
            this.setState({
                BtnActive: false,
                userInputDiagnosis: "",
                userInputActivity: "",
            });
        }
        else if (listName === "ActivityCodes" && value === "") {
            this.setState({
                //BtnActive: false,
                userInputDiagnosis: "",
                userInputActivity: "",
            });
        }
        else if (listName === "DiagnosisCodes") {
            this.setState({ BtnActive: true });
            const suggestions = this.state.diagnosisItems;
            let filteredSuggestions = [];
            if (value.length > 1) {
                const regex = new RegExp(`${value.toLowerCase()}`); //filter
                filteredSuggestions = suggestions
                    .sort()
                    .filter((v) => regex.test(v.toLowerCase()));
            }
            // Update the user input and filtered suggestions, reset the active
            // suggestion and make sure the suggestions are shown
            this.setState({
                activeSuggestion: 0,
                filteredSuggestionsDiagnosis: filteredSuggestions,
                showDiagnosisSuggestions: true,
                userInputDiagnosis: value,
            });
        }
        else {
            this.setState({ BtnActive: true });
            const suggestions = this.state.ActivityItems;
            let filteredSuggestions = [];
            if (value.length > 1) {
                const regex = new RegExp(`${value.toLowerCase()}`);
                filteredSuggestions = suggestions
                    .sort()
                    .filter((v) => regex.test(v.toLowerCase()));
            }

            // Update the user input and filtered suggestions, reset the active
            // suggestion and make sure the suggestions are shown
            this.setState({
                activeSuggestion: 0,
                filteredSuggestionsActivity: filteredSuggestions,
                showActivitySuggestions: true,
                userInputActivity: value,
            });
        }


    };

    // Event fired when the user clicks on a suggestion of Auto Complete Component
    onClick = (e) => {
        let code = e.currentTarget.innerText;
        // Update the user input and reset the rest of the state
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showDiagnosisSuggestions: false,
            showActivitySuggestions: false,
        });

        if (
            this.state.filteredSuggestionsActivity &&
            this.state.filteredSuggestionsActivity.length > 0
        ) {
            this.setState({
                // userInputActivity:this.state.filteredSuggestionsActivity[this.state.activeSuggestion]
                userInputActivity: e.currentTarget.innerText,
                filteredSuggestionsActivity: [],
            });
        }

        if (
            this.state.filteredSuggestionsDiagnosis &&
            this.state.filteredSuggestionsDiagnosis.length > 0
        ) {
            this.setState({

                userInputDiagnosis: e.currentTarget.innerText,
                filteredSuggestionsDiagnosis: [],
            });
        }

        //push to code
        this.setState({ code: { ...this.state.code, Text: code } });
    };

    // add code to codesLists
    addNewCode = (event, listName, inputId) => {
        event.preventDefault();

        this.setState({
            BtnActive: false,
        });
        let { DiagnosisCodes, ActivityCodes, code } = this.state;

        switch (listName) {
            case "DiagnosisCodes":

                DiagnosisCodes.push(code);

                this.setState({
                    DiagnosisCodes,
                    code: {},
                    data: { ...this.state.data, Diagnosis: DiagnosisCodes },
                    userInputDiagnosis: "",
                });
                break;

            default:

                ActivityCodes.push(code);
                this.setState({
                    ActivityCodes,
                    code: {},
                    data: { ...this.state.data, Activity: ActivityCodes },
                    userInputActivity: "",
                });
        }
    };

    handleItemDeletion = (item, listName) => {
        if (listName === "DiagnosisCodes") {
            let diagnosis = this.state.DiagnosisCodes;
            const index = diagnosis.indexOf(item);
            if (index > -1) {
                diagnosis = diagnosis.filter(function (i) {
                    return i !== item;
                });
                console.log(diagnosis);

                this.setState({
                    DiagnosisCodes: diagnosis,
                    data: { ...this.state.data, Diagnosis: diagnosis },
                });
            }
        } else {
            let activity = this.state.ActivityCodes;
            const index = activity.indexOf(item);
            if (index > -1) {
                activity = activity.filter(function (i) {
                    return i !== item;
                });
                this.setState({
                    ActivityCodes: activity,
                    data: { ...this.state.data, Activity: activity },
                });
            }
        }
    };


    //on click check for edits button
    checkForEdits = (event) => {
        event.preventDefault();

        let { data, patient } = this.state;

        let Diagnosis = [], Activity = []
        if (data) {
            Diagnosis = data.Diagnosis
            Activity = data.Activity
        }

        console.log((Diagnosis && Diagnosis.length == 0) || (Activity && Activity.length == 0));
        if (Diagnosis == undefined || Activity == undefined) {
            alert("your request should contain at least one Diagnosis and one Activity");
        }
        else {
            alert("success")
        }


    };


    render() {
        let { isNext, BtnActive, section } = this.state
        isNext = true;

        return (
            !isNext ?
                <div>
                    <Form

                        onSubmit={this.next}
                        inputsTypes={['number', 'select', 'text', 'text']}
                        elementsNames={['Age', 'Gender', 'InsuranceID', 'InsuranceCompany']}
                        elementsValues={
                            [
                                [{ min: 0, max: 100, step: "any" }],
                                [
                                    ["Male", "Female"],
                                    ["M", "F"],
                                ],
                                ['Insurance ID'],
                                ['Insurance Company'],
                                "Next"
                            ]
                        }
                        submitStyle="BS1"
                        onInputTextChange={this.handleInputTextChange}
                        onSelectOption={this.onSelectOption}



                    />
                </div>
                :
                <div>
                    <Form
                        onSubmit={this.checkForEdits}
                        inputsTypes={['number', 'select', 'text', 'text', 'section', "SamaDiagnosisList", "section", "SamaActivityList"]}
                        elementsValues={[

                            [{ min: 0, max: 100, step: "any" }],
                            [
                                ["Male", "Female"],
                                ["M", "F"],
                            ],
                            ['Insurance ID'],
                            ['Insurance Company'],
                            'ICD section',
                            this.state.DiagnosisCodes,
                            "CPT section", this.state.ActivityCodes,
                            "Check For Edits"
                        ]}
                        elementsNames={['Age', 'Gender', 'InsuranceID', 'InsuranceCompany', "ICD", "DiagnosisCodes", "CPT", "ActivityCodes"]}
                        inputStyle="IT3"
                        submitStyle="BS1"
                        onChange={this.onChange}
                        onClick={this.onClick}
                        addNewCode={this.addNewCode}
                        onInputTextChange={this.handleInputTextChange}
                        onSelectOption={this.onSelectOption}
                        handleItemDeletion={this.handleItemDeletion}

                        BtnActive={BtnActive}
                        diagnosisNow={100}
                        section={this.state.section}
                        allDiagnosis={this.state.diagnosisItems}
                        userInputDiagnosis={this.state.userInputDiagnosis}
                        activeSuggestion={this.state.activeSuggestion}
                        filteredSuggestionsDiagnosis={
                            this.state.filteredSuggestionsDiagnosis
                        }
                        showDiagnosisSuggestions={
                            this.state.showDiagnosisSuggestions
                        }
                        allActivity={this.state.ActivityItems}
                        userInputActivity={this.state.userInputActivity}
                        filteredSuggestionsActivity={
                            this.state.filteredSuggestionsActivity
                        }
                        showDiagnosisSuggestions={
                            this.state.showDiagnosisSuggestions
                        }
                        showActivitySuggestions={
                            this.state.showActivitySuggestions
                        }




                    />

                    {this.state.MSG ? <h2>{this.state.MSG}</h2> : null}


                </div>

        )

    }
}

export default FormUpload;
