import React, { Component } from 'react'
import NavBar from "./../Components/navbar";
import Footer from "./../Components/footer";
import Cookies from "js-cookie";

class KareemLogin extends Component {
    componentDidMount = () => {
        const { history } = this.props;

        if (Cookies.get("KareemId")) {
          history.push(`/KareemHome`);
        }
      };
    render(){
    return (
        <>
            <NavBar />

            <div className="BayanLinks" >
            <a href="/KareemAsEmployee">Login As Employee</a>
            {/* <a href="/KareemAsCompany">Login As Company</a> */}
            </div>

            <div style={{ height: "205px" }}></div>
            
            <Footer />
        </>
    )
}}

export default KareemLogin
