//import React from "react";
import React, { Component, Fragment } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import settings from "../helpers/Settings";
import Cookies from "js-cookie";
import axios from "axios";

let { KayanURL } = settings;

class Claim extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      claim: {},
      data: {},
    };
  }

  handleInputTextChange = ({ target: { name, value } }) => {
    this.setState({ response: false });
    this.setState({ data: { ...this.state.data, [name]: value } });
  };
  addClaim = async (event) => {
    event.preventDefault();

    let response = await axios.put(KayanURL + "api/sama/storeClaim", {
      data: this.state.data,
      apiToken: Cookies.get("SamaId"),
    });
    console.log("tres");
    console.log(response);
    if (response && response.data && response.data.success) {
      this.setState({ response: true });
    } else if (response && response.data && !response.data.success) {
      alert(response.data.error);
    }
  };

  render() {
    return (
      <Fragment>
        <div className="container">
          <Accordion defaultActiveKey="0" style={{ marginTop: "10px" }}>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                Claim Header
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <Row>
                    <Col style={{}}>
                      <input
                        name="claim_id"
                        placeholder="ID"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                    <Col>
                      <input
                        name="member_id"
                        placeholder="Member ID"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                    <Col>
                      <input
                        name="payer_id"
                        placeholder="Payer ID"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                    <Col>
                      <input
                        name="provider_id"
                        placeholder="Provider ID"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <Col>
                      <input
                        name="emirates_id_number"
                        placeholder="Emirates ID Number"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                    <Col>
                      <input
                        name="gross"
                        placeholder="Gross"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                    <Col>
                      <input
                        name="patient_share"
                        placeholder="Patient Share"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                    <Col>
                      <input
                        name="Net"
                        placeholder="Net"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="1">
                Claim Encounter
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <Row>
                    <Col style={{}}>
                      <input
                        name="enc_facility_id"
                        placeholder="Facility ID"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                    <Col>
                      <input
                        name="enc_type"
                        placeholder="Type"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                    <Col>
                      <input
                        name="enc_patient_id"
                        placeholder="Patient ID"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                    <Col>
                      <input
                        name="enc_start"
                        placeholder="Start"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <Col>
                      <input
                        name="enc_end"
                        placeholder="End"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                    <Col>
                      <input
                        name="start_type"
                        placeholder="Start Type"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                    <Col>
                      <input
                        name="end_type"
                        placeholder="End Type"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                    <Col>
                      <input
                        name="transfer_source"
                        placeholder="Transfer Source"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                    <Col>
                      <input
                        name="transfer_destination"
                        placeholder="Transfer Destination"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="2">
                Diagnosis
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  {/* ////////////////////////////////////////////////////////////////////// */}
                  <Row>
                    <Col style={{}}>
                      {/* type */}
                      <input
                        name="DiagnosisType"
                        placeholder="Type"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                    <Col>
                      <input
                        name="DiagnosisCode"
                        placeholder="Code"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="3">
                Activities
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <Row>
                    <Col style={{}}>
                      <input
                        name="ActivityID"
                        placeholder="ID"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                    <Col>
                      <input
                        name="ActivityStart"
                        placeholder="Start"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                    <Col>
                      <input
                        name="ActivityType"
                        placeholder="Type"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                    <Col>
                      <input
                        name="ActivityCode"
                        placeholder="Code"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <Col>
                      <input
                        name="Quantity"
                        placeholder="Quantity"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                    <Col>
                      <input
                        name="ActivityNet"
                        placeholder="Net"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                    <Col>
                      <input
                        name="Clinician"
                        placeholder="Clinician"
                        onChange={(e) => this.handleInputTextChange(e)}
                      ></input>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <button
            style={{ marginTop: "70px" }}
            type="button"
            className="BS1"
            onClick={this.addClaim}
          >
            Add Claim
          </button>

          <div>
            <h5>
              {this.state.response ? "Claim was uploaded successfully" : null}
            </h5>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default Claim;
