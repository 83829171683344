import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
/////////////

import DiagnosisTable from "./DiagnosisTable";

export default function DiagnosisForm(props) {
  const [ignored, forceUpdate] = React.useReducer((x) => x + 1, 0);
  const [code, setCode] = React.useState(props.diagnosisObj.diagnosisCode);
  const [type, setType] = React.useState(props.diagnosisObj.diagnosisType);
  /*  console.log(props);
  console.log("code", code);
  console.log("type", type); */

  if (!type && props.diagnosisObj.diagnosisType) {
    setType(props.diagnosisObj.diagnosisType);
  }

  if (!code && props.diagnosisObj.diagnosisCode) {
    setCode(props.diagnosisObj.diagnosisCode);
  }
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Diagnosis
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormControl required variant="standard" fullWidth>
            <InputLabel id="diagnosisType">Diagnosis Type</InputLabel>
            <Select
              required
              name="diagnosisType"
              labelId="diagnosisType-label"
              id="diagnosisType"
              onChange={(e) => {
                props.onChange(e);
                forceUpdate();
              }}
              label="Diagnosis Type"
              value={type ? type : ""}
            >
              <MenuItem value="principal">Principal</MenuItem>
              <MenuItem value="secondary">Secondary</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="diagnosisCode"
            label="Diagnosis Code"
            name="diagnosisCode"
            fullWidth
            value={code}
            variant="standard"
            onChange={(e) => {
              props.onChange(e);
              forceUpdate();
            }}
          />
        </Grid>

        <Grid item xs={12} /* md={6} */>
          <Button
            variant="contained"
            onClick={(e) => {
              props.onClickAddDiagnosis();
              forceUpdate();
              setCode("");
              setType("");
            }}
            sx={{ mt: 3, ml: 1 }}
          >
            Add Diagnosis
          </Button>
        </Grid>

        {props.data &&
        props.data.AllDiagnosis &&
        props.data.AllDiagnosis.length > 0 ? (
          <DiagnosisTable
            AllDiagnosis={props.data.AllDiagnosis}
            deleteFlag={true}
            deleteDiagnosisElement={props.deleteDiagnosisElement}
          />
        ) : null}
      </Grid>
    </React.Fragment>
  );
}
