import React, { Component } from "react";
import "../App.css";
class BayanDoc extends Component {
  render() {
    return (
      <div className="BayanDoc">
        <div className="container">
          <h2>Bayan</h2>
          <p style={{ textAlign: "left" }}>
            Bayan is an assistance system that helps in creating a clean claim that adheres
            to medical and coding guidelines. Combines capabilities of Checks
            and Edits to support health sector, reduce the potential mistakes
            and denial rates , and to provide the right health services. Currently
            many providers are using Bayan and are satisfied with outcome.
          </p>
          <p style={{ textAlign: "left" }}>
            Bayan contains 25+ million edit combinations, and more to be
            extended over time.
          </p>

          <h2 id="solutionFeatures">Solution features</h2>

          <ol className="feautersList">
            <li>
              Diagnosis relationship Checks
              <ul>
                <li>Inappropriate principals' diagnosis.</li>
                <li>Code first </li>
                <li>Code also</li>
                <li>Exclude codes edit</li>
                <li>Include codes edit </li>
              </ul>
            </li>
            <li>
              Medical Necessity Check
              <ul>
                <li>Procedure Medical Necessity Check </li>
                <li>Consumables/Disposables Medical Necessity Check </li>
                <li>Dental Medical Necessity Check</li>
                <li>Drug Medical Necessity Check </li>
              </ul>
            </li>
            <li>
              Coding relationship checks
              <ul>
                <li>Add-Ons Edit</li>
                <li>Not allowed all the time </li>
                <li>Observation/ Modifier is requested </li>
                <li>Consumables/Disposables - Procedure edit </li>
                <li>Sequential procedure edits </li>
              </ul>
            </li>
            <li>
              Dental relationship checks
              <ul>
                <li>Valid tooth number </li>
                <li>Dental - age edit</li>
              </ul>
            </li>
            <li>
              Service relationship checks
              <ul>
                <li>Service Relation Error</li>
                <li>Not typical with the encounter type </li>
                <li>Procedure – place of service edits</li>
                <li>Consumables/Disposables – place of service edit </li>
              </ul>
            </li>
            <li>
              Age and gender checks
              <ul>
                <li>Procedure – age</li>
                <li>Procedure – gender</li>
                <li>Consumables/Disposables - age</li>
                <li>Consumables/Disposables- gender </li>
                <li>Diagnosis - age</li>
                <li>Diagnosis – gender </li>
              </ul>
            </li>

            <li>
              Maximum frequency per day
              <ul>
                <li>Procedure - maximum frequency per day</li>
                <li>Consumables/Disposables - maximum frequency per day</li>
                <li>Dental - maximum frequency per day </li>
              </ul>
            </li>

            <li>
              {" "}
              Drug relationship checks
              <ul>
                <li>Drug and maternity </li>
                <li>Drug and dose edit </li>
                <li>Duplicate therapy </li>
                <li>Drug – drug interaction</li>
                <li>Drug – diagnosis contraindication </li>
              </ul>
            </li>
          </ol>

          <h2>Schema Definition</h2>
          <p style={{ textAlign: "left" }}>
            It will depend mainly on generalized transaction schema request
            which will be represented in xml that will consist of the flowing required fields:
          </p>
          <table className="table" style={{ textAlign: "left" }}>
            <thead>
              <tr>
                <th>Field Name</th>
                <th>Type</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&lt;Claim.Submission&gt;</td>
                <td>Structure element Required</td>
                <td>
                The main root of the schema that will contain all the patient visits claims.
                </td>
              </tr>

              <tr>
                <td>&lt;Claim&gt;</td>
                <td>Structure element Required</td>
                <td>
                It represents all the patient visit details, the claim could be repeated in the same request depends on the representation need of the provider.
                </td>
              </tr>

              <tr>
                <td>&lt;ID&gt;</td>
                <td>Data element
                  Required
                  </td>
                <td>
                Represents the ID of the claims, and it's used one time per claim.
                </td>
              </tr>

              <tr>
                <td>&lt;Encounter&gt;</td>
                <td>Structure element
                Required
                </td>
                <td>
                    This is the parent for all the required patient details, and it is used one time per claim.

                </td>
              </tr>

              <tr>
                <td>&lt;Type&gt;</td>
                <td>Data element
Required
 </td>
                <td>The Encounter's Type. Only values allowed are:

                  <ul>
                    <li>1=No Bed + No emergency room (outpatient).</li>
                    <li>2=No Bed + Emergency room (outpatient).</li>
                    <li>3=Inpatient Bed + No emergency room</li>
                    <li>4=Inpatient Bed + Emergency room.</li>
                    <li>5=Daycase Bed + No emergency room.</li>
                    <li>5=Daycase Bed + No emergency room.</li>
                    <li>6=Daycase Bed + Emergency room.</li>
                    <li>7=Nationals Screening.</li>
                    <li>8=New Visa Screening.</li>
                    <li>9=Renewal Visa Screening.</li>
                    <li>12=Home.</li>
                    <li>13=Assisted Living Facility.</li>
                    <li>15 = Mobile Unit.</li>
                    <li>
                    41= Ambulance Land.
                    </li>
                    <li>
                    42= Ambulance Air or Water.

                    </li>
                    


                  </ul>
                </td>
              </tr>

              <tr>
                <td>&lt;PatientAge&gt;</td>
                <td>Data element Optional</td>
                <td>
                Represents the age of the patient.
                </td>
               
              </tr>

              <tr>
                <td>&lt;PatientGender&gt;</td>
                <td>Data element Optional</td>
                <td>Represents the gender of the patient with the possibilities :
                  <ul>
                    <li>M: Male</li>
                    <li>F: Female</li>
                  </ul>


                  Included in the Encounter fields.
                </td>
              
              </tr>

              <tr>
                <td>&lt;Diagnosis&gt;</td>
                <td>Structure element Required</td>
                <td>The main tag of the diagnosis that includes the diagnosis details (code and type). Diagnosis element could be repeated in the same claim.</td>
              </tr>

              <tr>
                <td>&lt;Type&gt;</td>
                <td>Data element Required</td>
                <td>
                   Represents the diagnosis type, only values allowed are:

                  <ul>
                    <li>Principal</li>
                    <li>Secondary</li>
                    <li>Admitting</li>
                  </ul>
                  It is used one time per diagnosis element.

                </td>
              </tr>

              

              <tr>
                <td>&lt;Code&gt;</td>
                <td>Data element Required
                </td>
                <td>
                Represents the diagnosis code and it is used one time per diagnosis element.
                </td>
              </tr>

              <tr>
                <td>&lt;Activity&gt;</td>
                <td>Structure element required</td>
                <td>
                The main element that includes the claim activity details inside it.
Activity element could be repeated in the same claim.

                </td>
              </tr>

              <tr>
                <td>&lt;ID&gt;</td>
                <td>Data element Required</td>
                <td>
                Represents the ID of the activity and it is used one time per activity element.
                </td>
              </tr>

              <tr>
                <td>&lt;Start&gt;</td>
                <td>Data element
Required
Format:
dd/mm/yyyy hh:mm
</td>
                <td>
                  
Represents the start date and time of activity and it is used one per activity element.

                </td>
              </tr>

              <tr>
                <td>
                Type
                </td>
                <td>Data element
Required
</td>
                <td>
                Represents the activity’s type. Only values allowed are:
                <ul>
                  <li>3 = CPT.</li>
                  <li>4 = HCPCS.</li>
                  <li>6 = Dental.</li>
                  <li>8 = Service Code.</li>
                  <li>10 = Scientific Code.</li>
                </ul>
                  It is used one time per activity element.

                </td>
              </tr>

              <tr>
                <td>
                Code
                </td>
                <td>Data element Required</td>
                <td>
                Represents the activity code and it is used one time per activity element.
                </td>
              </tr>
              <tr>
                <td>
                Quantity
                </td>
                <td>Data element Required</td>
                <td>
                Identifies how many activity used per day for the prescribed activity.  It is used one time per activity element.
                </td>
              </tr>
              <tr>
                <td>
                Observation
                </td>
                <td>Structure element Optional</td>
                <td>
                In the case of Activity Type indicated to "Dental”, it is possible to add details related to tooth number.
                Observation element could be repeated in the same activity multiple times.
                </td>
              </tr>
              <tr>
                <td>
                Value
                </td>
                <td>Data element Optional</td>
                <td>
                
                   Represents the type of observation. It is used one time per observation element.


                </td>
              </tr>
              <tr>
                <td>
                ValueType
                </td>
                <td>Data element Optional</td>
                <td>
                Represents the type of observation. It is used one time per observation element.


                </td>
              </tr>
              <tr>
                <td>
                Code
                </td>
                <td>Data element Optional</td>
                <td>
                Represents the Code of observation and it is value fixed “UniversalDental”
 It is used one time per observation element.



                </td>
              </tr>
            
              <tr>
                <td>
                Type
                </td>
                <td>Data element Optional</td>
                <td>
                Represents the Type of observation and it is value fixed and equal “UniversalDental”
 It is used one time per observation element.



                </td>
              </tr>
            
            </tbody>
          </table>

          <h2 id="requestXSD">Request XML Structure</h2>
    <p style={{ textAlign: "left" }}>
       Bayan has a special schema that is used for requests and it must be used to be able to use the API. Follows the XSD structure for the request sent to Bayan API:
    </p>
    <pre>
      
    {`<?xml version="1.0" encoding="UTF-8"?>
      <xs:schema xmlns:tns="http://www.eclaimlink.ae/DHD/ValidationSchema" elementFormDefault="qualified" version="2.0" id="ClaimSubmission" xmlns:xs="http://www.w3.org/2001/XMLSchema">
       <xs:import schemaLocation="CommonTypes.xsd" namespace="http://www.eclaimlink.ae/DHD/ValidationSchema" />
          <xs:element name="Claim.Submission">
              <xs:complexType>
                  <xs:sequence>
                      <xs:element minOccurs="0" maxOccurs="1" name="Header">
                          <xs:complexType>
                              <xs:sequence>
                                  <xs:element minOccurs="0" maxOccurs="1" name="SenderID" type="tns:HeaderSenderID" />
                                  <xs:element minOccurs="0" maxOccurs="1" name="ReceiverID" type="tns:HeaderReceiverID" />
                                  <xs:element minOccurs="0" maxOccurs="1" name="TransactionDate" type="tns:HeaderTransactionDate" />
                                  <xs:element minOccurs="0" maxOccurs="1" name="RecordCount" type="tns:HeaderRecordCount" />
                                  <xs:element minOccurs="0" maxOccurs="1" name="DispositionFlag" type="tns:HeaderDispositionFlag" />
                              </xs:sequence>
                          </xs:complexType>
                      </xs:element>
                      <xs:element minOccurs="1" maxOccurs="unbounded" name="Claim">
                          <xs:complexType>
                              <xs:sequence>
                                  <xs:element minOccurs="1" maxOccurs="1" name="ID" type="tns:ClaimID" />
                                  <xs:element minOccurs="0" maxOccurs="1" name="IDPayer" type="tns:ClaimIDPayer" />
                                  <xs:element minOccurs="0" maxOccurs="1" name="MemberID" type="tns:ClaimMemberID" />
                                  <xs:element minOccurs="0" maxOccurs="1" name="PayerID" type="tns:ClaimPayerID" />
                                  <xs:element minOccurs="0" maxOccurs="1" name="ProviderID" type="tns:ClaimProviderID" />
                                  <xs:element minOccurs="0" maxOccurs="1" name="EmiratesIDNumber" type="tns:ClaimEmiratesIDNumber" />
                                  <xs:element minOccurs="0" maxOccurs="1" name="Gross" type="tns:ClaimGross" />
                                  <xs:element minOccurs="0" maxOccurs="1" name="PatientShare" type="tns:ClaimPatientShare" />
                                  <xs:element minOccurs="0" maxOccurs="1" name="Net" type="tns:ClaimNet" />
                                  <xs:element minOccurs="1" maxOccurs="1" name="Encounter">
                                      <xs:complexType>
                                          <xs:sequence>
                          <xs:element minOccurs="1" maxOccurs="1" name="Type" type="tns:EncounterType" />
                          <xs:element minOccurs="0" maxOccurs="1" name="PatientAge" type="tns:EncounterPatientAge" />
                                              <xs:element minOccurs="0" maxOccurs="1" name="PatientGender" type="tns:EncounterPatientGender" />
                                              <xs:element minOccurs="0" maxOccurs="1" name="FacilityID" type="tns:EncounterFacilityID" />
                                              <xs:element minOccurs="0" maxOccurs="1" name="PatientID" type="tns:EncounterPatientID" />
                                              <xs:element minOccurs="0" maxOccurs="1" name="Start" type="tns:EncounterStart" />
                                              <xs:element minOccurs="0" maxOccurs="1" name="End" type="tns:EncounterEnd" />
                                              <xs:element minOccurs="0" maxOccurs="1"  name="StartType" type="tns:EncounterStartType" />
                                              <xs:element minOccurs="0" maxOccurs="1"  name="EndType" type="tns:EncounterEndType" />
                                              <xs:element minOccurs="0" maxOccurs="1"  name="TransferSource" type="tns:EncounterTransferSource" />
                                              <xs:element minOccurs="0" maxOccurs="1"  name="TransferDestination" type="tns:EncounterTransferDestination" />
                                          </xs:sequence>
                                      </xs:complexType>
                                  </xs:element>
                                  <xs:element minOccurs="1" maxOccurs="unbounded" name="Diagnosis">
                                      <xs:complexType>
                                          <xs:sequence>
                                              <xs:element minOccurs="1" maxOccurs="1" name="Type" type="tns:DiagnosisType" />
                                              <xs:element minOccurs="1" maxOccurs="1" name="Code" type="tns:DiagnosisCode" />										
                                          </xs:sequence>
                                      </xs:complexType>
                                  </xs:element>
                                  <xs:element minOccurs="1" maxOccurs="unbounded" name="Activity">
                                      <xs:complexType>
                                          <xs:sequence>
                                              <xs:element minOccurs="1" maxOccurs="1" name="ID" type="tns:ActivityID"  />
                                              <xs:element minOccurs="1" maxOccurs="1" name="Start" type="tns:ActivityStart" />
                                              <xs:element minOccurs="1" maxOccurs="1" name="Type" type="tns:ActivityType" />
                                              <xs:element minOccurs="1" maxOccurs="1" name="Code" type="tns:ActivityCode" />
                                              <xs:element minOccurs="1" maxOccurs="1" name="Quantity" type="tns:ActivityQuantity" />
                                              <xs:element minOccurs="0" maxOccurs="1" name="Net" type="tns:ActivityNet" />
                                              <xs:element minOccurs="0" maxOccurs="1" name="Clinician" type="tns:ActivityClinician" />								
                                              <xs:element minOccurs="0" maxOccurs="1"  name="PriorAuthorizationID" type="tns:ActivityPriorAuthorizationID" />
                                              <xs:element minOccurs="0" maxOccurs="unbounded" name="Observation">
                                                  <xs:complexType>
                                                      <xs:sequence>
                                                          <xs:element minOccurs="0" maxOccurs="1" name="Type" type="tns:ObservationType" />
                                                          <xs:element minOccurs="0" maxOccurs="1" name="Code" type="tns:ObservationCode" />
                                                          <xs:element minOccurs="0" maxOccurs="1" name="Value" type="tns:ObservationValue" />
                                                          <xs:element minOccurs="0" maxOccurs="1" name="ValueType" type="tns:ObservationValueType" />
                                                      </xs:sequence>
                                                  </xs:complexType>
                                              </xs:element>
                                          </xs:sequence>
                                      </xs:complexType>
                                  </xs:element>
                                  <xs:element minOccurs="0" maxOccurs="1" name="Resubmission">
                                      <xs:complexType>
                                          <xs:sequence>
                                              <xs:element minOccurs="0" maxOccurs="1" name="Type" type="tns:ResubmissionType" />
                                              <xs:element minOccurs="0" maxOccurs="1" name="Comment" type="tns:ResubmissionComment" />
                                              <xs:element minOccurs="0" maxOccurs="1"  name="Attachment" type="tns:ResubmissionAttachments" />
                                          </xs:sequence>
                                      </xs:complexType>
                                  </xs:element>
                                  <xs:element minOccurs="0" maxOccurs="1"  name="Contract">
                                      <xs:complexType>
                                          <xs:sequence>
                                              <xs:element minOccurs="0" maxOccurs="1"  name="PackageName" type="tns:ContractPackageName" />
                                          </xs:sequence>
                                      </xs:complexType>
                                  </xs:element>
                              </xs:sequence>
                          </xs:complexType>
                      </xs:element>
                  </xs:sequence>
              </xs:complexType>
          </xs:element>
      </xs:schema>`}
      



     
    </pre>

    
         <h2 id="requestSample">Request XML Sample</h2>
          <p style={{ textAlign: "left" }}>
          The following example is for a request that contains an issue with the diagnosis code, where this code can’t come alone and need to be supported with another code. The request contains one encounter, one principle diagnosis, and one activity recognized as CPT because it’s from source “3”.

          </p>
          <pre>
            {`
        
        <?xml version="1.0" encoding="UTF-8" standalone="yes"?>
        <Claim.Submission>
            <Header>
                <SenderID>11111111</SenderID>
                <ReceiverID>1111111</ReceiverID>
                <TransactionDate>18/11/2019 11:43</TransactionDate>
                <RecordCount>1</RecordCount>
                <DispositionFlag>PRODUCTION</DispositionFlag>
            </Header>
            <Claim>
                <IDPayer>11</IDPayer>
                <MemberID>1111</MemberID>
                <PayerID>11111</PayerID>
                <ProviderID>1111111</ProviderID>
                <EmiratesIDNumber>999-9999-9999999-9</EmiratesIDNumber>
                <Gross>53.1</Gross>
                <PatientShare>0</PatientShare>
                <Net>53.1</Net>
                <Encounter>
                    <FacilityID>111111</FacilityID>
                    <PatientID>111111</PatientID>
                    <PatientAge>53</PatientAge>
                    <PatientGender>F</PatientGender>
                    <Start>18/12/2018 20:09</Start>
                    <End>18/12/2018 20:49</End>
                    <StartType>1</StartType>
                </Encounter>
                <Diagnosis>
                    <Type>Principal</Type>
                    <Code>I25.10</Code>
                </Diagnosis>
                <Diagnosis>
                    <Type>Secondary</Type>
                </Diagnosis>
                <Activity>
                    <ID>418300</ID>
                    <Start></Start>
                    <Type>3</Type>
                    <Code>33536</Code>
                    <Quantity></Quantity>
                    <Net>8.1</Net>
                    <Clinician>11111111</Clinician>
                </Activity>
                <Activity>
                    <ID>418300</ID>
                    <Start>18/12/2018 20:09</Start>
                    <Type>3</Type>
                    <Code></Code>
                    <Quantity>1</Quantity>
                    <Net>8.1</Net>
                    <Clinician>11111111</Clinician>
                </Activity>
                <Activity>
                    <ID>418300</ID>
                    <Start>18/12/2018 20:09</Start>
                    <Type></Type>
                    <Code>33536</Code>
                    <Quantity>1</Quantity>
                    <Net>8.1</Net>
                    <Clinician>11111111</Clinician>
                </Activity>
                <Activity>
                    <ID>418300</ID>
                    <Start>18/12/2018 20:09</Start>
                    <Code>33536</Code>
                    <Quantity>1</Quantity>
                    <Net>8.1</Net>
                    <Clinician>11111111</Clinician>
                </Activity>
            </Claim>		
        </Claim.Submission>
            `}
          </pre>
          <h2 id="responseXSD">Response XML structure</h2>

          <p style={{ textAlign: "left" }}>
          The following structure represents Bayan response structure. It’s important to understand this structure in order to handle response properly.
          </p>
          <pre>
            {`
          <?xml version="1.0" encoding="UTF-8"?>
          <xs:schema
              xmlns:xs="http://www.w3.org/2001/XMLSchema" elementFormDefault="qualified" attributeFormDefault="unqualified">
              <xs:element name="Response-Status">
                  <xs:complexType>
                      <xs:sequence>
                          <xs:element name="Status" type="xs:string"></xs:element>
                          <xs:element name="Request-Errors">
                              <xs:complexType>
                                  <xs:sequence>
                                      <xs:element name="Claim" minOccurs="0" maxOccurs="unbounded">
                                          <xs:complexType>
                                              <xs:sequence>
                                                  <xs:element minOccurs="1" maxOccurs="1" name="EncounterId" type="xs:int"></xs:element>
                                                  <xs:element minOccurs="1" maxOccurs="1" name="Line" type="xs:int"></xs:element>
                                                  <xs:element minOccurs="0" maxOccurs="1" name="Data-Errors">
                                                      <xs:complexType>
                                                          <xs:sequence>
                                                              <xs:element minOccurs="1" maxOccurs="unbounded" name="Code">
                                                                  <xs:complexType>
                                                                      <xs:sequence>
                                                                          <xs:element minOccurs="0" maxOccurs="1" name="ActivityID" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="ClaimID" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="0" maxOccurs="1" name="ActivityCode" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Level" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Domain" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="0" maxOccurs="1" name="List" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="CodeId" type="xs:int"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Line" type="xs:int"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Type" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Message" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="0" maxOccurs="1" name="Denial" type="xs:string"></xs:element>   
                                                                      </xs:sequence>
                                                                  </xs:complexType>
                                                              </xs:element>
                                                          </xs:sequence>
                                                      </xs:complexType>
                                                  </xs:element>
                                                  <xs:element minOccurs="0" maxOccurs="1" name="Schema-Errors">
                                                      <xs:complexType>
                                                          <xs:sequence>
                                                                  <xs:element minOccurs="0" maxOccurs="unbounded" name="Claim">
                                                                  <xs:complexType>
                                                                      <xs:sequence>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="ClaimId" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Element" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Line" type="xs:int"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Message" type="xs:string"></xs:element>
                                                                      </xs:sequence>
                                                                  </xs:complexType>
                                                              </xs:element>
                                                              <xs:element minOccurs="0" maxOccurs="unbounded" name="Encounter">
                                                                  <xs:complexType>
                                                                      <xs:sequence>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="ClaimId" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Element" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Domain" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Line" type="xs:int"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Message" type="xs:string"></xs:element>
                                                                      </xs:sequence>
                                                                  </xs:complexType>
                                                              </xs:element>
                                                              <xs:element minOccurs="0" maxOccurs="unbounded" name="Activity">
                                                                  <xs:complexType>
                                                                      <xs:sequence>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="ActivityID" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="ClaimId" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Element" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Line" type="xs:int"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Message" type="xs:string"></xs:element>
                                                                      </xs:sequence>
                                                                  </xs:complexType>
                                                              </xs:element>
                                                              <xs:element minOccurs="0" maxOccurs="unbounded" name="Diagnosis">
                                                                  <xs:complexType>
                                                                      <xs:sequence>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="ClaimId" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Element" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="DiagnosisId" type="xs:int"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Line" type="xs:int"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Message" type="xs:string"></xs:element>
                                                                      </xs:sequence>
                                                                  </xs:complexType>
                                                              </xs:element>
                                                              <xs:element minOccurs="0" maxOccurs="unbounded" name="Code">
                                                                  <xs:complexType>
                                                                      <xs:sequence>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="ClaimId" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="0" maxOccurs="1" name="ActivityID" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Element" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Domain" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="CodeId" type="xs:int"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Line" type="xs:int"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Message" type="xs:string"></xs:element>
                                                                      </xs:sequence>
                                                                  </xs:complexType>
                                                              </xs:element>
                                                              <xs:element minOccurs="0" maxOccurs="unbounded" name="Type">
                                                                  <xs:complexType>
                                                                      <xs:sequence>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="ClaimId" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Element" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Domain" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="CodeId" type="xs:int"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Line" type="xs:int"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Message" type="xs:string"></xs:element>
                                                                      </xs:sequence>
                                                                  </xs:complexType>
                                                              </xs:element>
                                                              <xs:element minOccurs="0" maxOccurs="unbounded" name="Start">
                                                                  <xs:complexType>
                                                                      <xs:sequence>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="ActivityID" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="ClaimId" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Element" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Line" type="xs:int"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Message" type="xs:string"></xs:element>
                                                                      </xs:sequence>
                                                                  </xs:complexType>
                                                              </xs:element>
                                                              <xs:element minOccurs="0" maxOccurs="unbounded" name="Quantity">
                                                                  <xs:complexType>
                                                                      <xs:sequence>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="ActivityID" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="ClaimId" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Element" type="xs:string"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Line" type="xs:int"></xs:element>
                                                                          <xs:element minOccurs="1" maxOccurs="1" name="Message" type="xs:string"></xs:element>
                                                                      </xs:sequence>
                                                                  </xs:complexType>
                                                              </xs:element>
                                                        </xs:sequence>
                                                      </xs:complexType>                   
                                                  </xs:element>
                                              </xs:sequence>
                                          </xs:complexType>
                                      </xs:element>
                                  </xs:sequence>
                              </xs:complexType>
                          </xs:element>
                      </xs:sequence>
                  </xs:complexType>
              </xs:element>
          </xs:schema>



          `}
          </pre>

          <h2 id="responseSample">Response Sample</h2>

          <p style={{ textAlign: "left" }}>
          The following XML represent the response for the previous request. The response will contain a status tag that indicates the status of the request. The next tag is for errors list. If the status of the request is success, then the list will be empty, otherwise list of errors per encounter will be shown. The first child tag inside the encounter tag represents the line where does this encounter starts at in the request file. The second tag represents the id of the encounter, or the index of the encounter in the XML request. After that, a list of data errors and/or schema errors show up if there are issues with data or schema. For more information and examples please visit www.kayan-healthcare.com/Bayan.          </p>
          <pre>
            {`
           <Response-Status>
           <Status>Failed</Status>
           <Request-Errors>
               <Claim>
                   <EncounterId>1</EncounterId>
                   <Line>10</Line>
                   <Data-Errors>
                       <Code>
                           <ActivityID>418300</ActivityID>
                           <ClaimID></ClaimID>
                           <ActivityCode>33536</ActivityCode>
                           <Level>Edit</Level>
                           <Domain>Activity</Domain>
                           <List>CPT</List>
                           <CodeId>1</CodeId>
                           <Line>39</Line>
                           <Type>Incorrect CPT-ICD Relation</Type>
                           <Message>The procedure code (33536) does not match with any of the diagnoses (I25.10).Suggested diagnoses(I25.110, I25.111, I25.118).</Message>
                       </Code>
                   </Data-Errors>
                   <Schema-Errors>
                       <Claim>
                           <ClaimId></ClaimId>
                           <Element>Claim</Element>
                           <Line>10</Line>
                           <Message>Element Claim: Missing child element(s). Expected is ( ID ).</Message>
                       </Claim>
                       <Encounter>
                           <ClaimId></ClaimId>
                           <Element>Encounter</Element>
                           <Domain>Claim</Domain>
                           <Line>19</Line>
                           <Message>Element Encounter: Missing child element(s). Expected is ( Type ).</Message>
                       </Encounter>
                       <Diagnosis>
                           <ActivityID></ActivityID>
                           <ClaimId></ClaimId>
                           <Element>Diagnosis</Element>
                           <DiagnosisId>2</DiagnosisId>
                           <Line>32</Line>
                           <Message>Element Diagnosis: Missing child element(s). Expected is ( Code, Type ).</Message>
                       </Diagnosis>
                       <Start>
                           <ActivityID>418300</ActivityID>
                           <ClaimId></ClaimId>
                           <Element>Start</Element>
                           <Line>37</Line>
                           <Message>Element Start: The start date () has incorrect date format</Message>
                       </Start>
                       <Quantity>
                           <ActivityID>418300</ActivityID>
                           <ClaimId></ClaimId>
                           <Element>Quantity</Element>
                           <Line>40</Line>
                           <Message>Element Quantity: The quantity value () is invalid.</Message>
                       </Quantity>
                       <Code>
                           <ActivityID>418300</ActivityID>
                           <ClaimId></ClaimId>
                           <Element>Code</Element>
                           <Domain>Activity</Domain>
                           <CodeId>2</CodeId>
                           <Line>47</Line>
                           <Message>Element Code, Parent activity: Element value () is invalid</Message>
                       </Code>
                       <Type>
                           <ActivityID>418300</ActivityID>
                           <ClaimId></ClaimId>
                           <Element>Type</Element>
                           <Domain>Activity</Domain>
                           <CodeId>3</CodeId>
                           <Line>57</Line>
                           <Message>Element Type, Parent activity: Element value () out of the activity type values range { 3, 4, 5, 6, 8, 9, 10 }</Message>
                       </Type>
                       <Activity>
                           <ActivityID>418300</ActivityID>
                           <ClaimId></ClaimId>
                           <Element>Activity</Element>
                           <ActivityId>4</ActivityId>
                           <Line>62</Line>
                           <Message>Element Activity: Missing child element(s). Expected is ( Code, Type ).</Message>
                       </Activity>
                   </Schema-Errors>
               </Claim>
           </Request-Errors>
       </Response-Status>
        
          `}
          </pre>


                  
                  
        </div>
      </div>
    );
  }
}

export default BayanDoc;
