import React, { Component } from "react";
import axios from "axios";
import Cookies from "js-cookie";

import Spinner from "react-bootstrap/Spinner";

import settings from "../../helpers/Settings";
let { KayanURL } = settings;

class UpdateEmployeeInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = async () => {
    const { history } = this.props;
    //get all employees of this company
    let res = await axios.put(KayanURL + "api/sama/employee/getEmployee", {
      apiToken: Cookies.get("SamaCompanyId"),
    });

    if (res) {
      let { data } = res;

      if (data && data.success) {
        this.setState({ Employees: data.Employees });
      }
    }
  };

  handleInputTextChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    this.setState({ name, value });
  };

  submit = async (e) => {
    e.preventDefault();

    let result = await axios.put(KayanURL + "api/sama/company/updateCompany", {
      apiToken: Cookies.get("SamaCompanyId"),
      facility: this.state.value,
    });
    if (result) {
      let { data } = result;
      if (data) {
        this.setState({ success: data.success, error: data.error });
      }
    }
  };
  render() {
    let { success, error } = this.state;
    return (
      <>
        <div className="container">
          <form style={{ marginTop: "-250px" }}>
            <input
              type="text"
              name="facilityID"
              placeholder="Enter Facility ID"
              onChange={this.handleInputTextChange}
              style={{ width: "70%", padding: "8px" }}
            />

            <br />

            <br />

            <input
              type="submit"
              value="Add Facility"
              onClick={this.submit}
              className="BS1"
            />

            {success ? (
              <h6>The Facility ID was Added Successfully</h6>
            ) : (
              <h6 style={{ color: "red" }}>{error}</h6>
            )}
          </form>
        </div>
      </>
    );
  }
}

export default UpdateEmployeeInfo;
