import React from "react";
import "./Date.css";

import Input from "../InputText";
import Label from "../Label";

function Date(props) {
  const { id, label, Style, onChange, name } = props;

  return (
    <React.Fragment>
      <div>
        <Input
          id={id}
          Style={Style}
          type="datetime-local"
          name={name}
          onChange={onChange}
          required
        />
      </div>
    </React.Fragment>
  );
}
export default Date;
