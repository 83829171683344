import React, { Component } from "react";
import axios from "axios";
import Cookies from "js-cookie";

import Spinner from "react-bootstrap/Spinner";

import settings from "../../helpers/Settings";
import { urlencoded } from "body-parser";
import { Multiselect } from "multiselect-react-dropdown";
import { values } from "lodash";

let { KayanURL } = settings;

class UpdateNetworkModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      discounts: false,
      patientShare: false,
      payerChange:false,
      // payerIdChange:false
    };
    this.handleInputTextChange = this.handleInputTextChange.bind(this);
    this.onScelectPayerName = this.onScelectPayerName.bind(this);
    //this.handleNetGrossSelection = this.handleNetGrossSelection.bind(this);
    this.onClickChekBox = this.onClickChekBox.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleDiscounts = this.handleDiscounts.bind(this);
    this.handlePatientShare = this.handlePatientShare.bind(this);
    this.onSelectPayerUnderTPA = this.onSelectPayerUnderTPA.bind(this);
    this.onRemovePayerUnderTPA = this.onRemovePayerUnderTPA.bind(this);
  }
  componentDidMount = async () => {
    let payers = localStorage.getItem("payers");
   let payers2
    let Payerss=this.props.payerList
    let tpa =this.props.tpa
    let payerr=[]
    let tpaArray=[]
    if(tpa){
    this.setState({Tpa:true,tpa:tpa})
    payers2=JSON.parse(payers)
    for(let j=0;j<Payerss.length;j++){
        for(let i=0;i<payers2.length;i++){
        if(payers2[i].eclaimlink_id==Payerss[j]){
          Payerss[j]=payers2[i]
        payerr.push(Payerss[j])
        }
      }
    }
    // this.setState({payerr})
    for(let i=0;i<payers2.length;i++){
      if(payers2[i].eclaimlink_id.includes("TPA")){
        tpaArray.push(payers2[i])
      }
    }
    this.setState({tpaArray:tpaArray})
    this.onSelectPayerUnderTPA(payerr)
    }else{
      this.setState({Tpa:false})
      // console.log("no tpa")
    }
    if (!payers) {
      this.getPayersList();
    } else {
      this.setState({ payers: JSON.parse(payers) });
    }

    let { network } = this.props;
    if (network) {
      let { discount, patient_share, payer_id } = network;

      let {
        active,
        authorizing_amount,

        id,
        max_number_of_days_for_resubmissions,
        max_number_of_days_for_submission,
        max_number_of_resubmission,
        network_description,
        network_name,

        patient_share_max_amount,
      } = network;

      this.setState({
        active,
        authorizing_amount,

        id,
        max_number_of_days_for_resubmissions,
        max_number_of_days_for_submission,
        max_number_of_resubmission,
        network_description,
        network_name,

        patient_share_max_amount,
        payer_id,
      });

      if (discount) {
        let discountNames = Object.keys(discount);
        if (discountNames && discountNames.length > 0) {
          //check if all values equals
          let discountValues = Object.values(discount);
          const allEqual = (arr) => arr.every((v) => v === arr[0]);

          if (allEqual(discountValues) && discountValues.length == 7) {
            this.setState({
              discounts: true,
              //   discountsEqualValue: discountValues[0],
              discount: discountValues[0],
            });
          }

          this.setState({
            consultation_discount: discount.consultation_discount,
            consumables_discount: discount.consumables_discount,
            dental_discount: discount.dental_discount,
            laboratory_discount: discount.laboratory_discount,
            medication_discount: discount.medication_discount,
            procedure_discount: discount.procedure_discount,
            radiology_discount: discount.radiology_discount,
          });
        } else {
          console.log("no discount entered");
        }
      }

      if (patient_share) {
        let sharesNames = Object.keys(discount);
        if (sharesNames && sharesNames.length > 0) {
          let sharesValues = Object.values(patient_share);
          if (sharesValues && sharesValues.length == 7) {
            //allEqual([ 3, 4, 5, 5, 5])
            const allEqual = (arr) => arr.every((v) => v === arr[0]);
            const allEquals = allEqual(sharesValues);

            if (allEquals) {
              this.setState({
                patientShare: true,
                // patientShareEqualValue: sharesValues[0],
                patient_share_percent: sharesValues[0],
              });
            }
          }
          this.setState({
            consultation_share: patient_share.consultation_share,
            consumables_share: patient_share.consumables_share,
            dental_share: patient_share.dental_share,
            laboratory_share: patient_share.laboratory_share,

            medication_share: patient_share.medication_share,
            procedure_share: patient_share.procedure_share,
            radiology_share: patient_share.radiology_share,
          });
        } else {
          console.log("there is no patient share");
        }
      }
    }
  };
  async getPayersList() {
    let response = await axios.put(KayanURL + "api/sama/getPayers", {
      apiToken: Cookies.get("SamaId"),
    });

    if (response) {
      let { data } = response;
      if (data && data.success) {
        let { payers } = data;
        this.setState({ payers });
        localStorage.setItem("payers", payers);
      }
    }
  }

  onClickChekBox() {
    let { network } = this.props;
    let { active } = network;
    console.log(active)
    if (!active) {
      this.setState({ active: true });
      network["active"] = true;
    } else {
      this.setState({ active: false });
      network["active"] = false;
    }
    console.log(network["active"])
  }

  onScelectPayerName({ target: { name, value } }) {
    this.setState({ payer_id: value ,payerChange:true});
    this.setState({isTPA: false });
    if (value.includes("TPA")) {
      this.setState({ isTPA: true,tpa:value});
    }
  }
  onScelectNetwork({ target: { name, value } }) {
    this.setState({ network_name: value });
  }
  handleInputTextChange({ target: { name, value } }) {
    this.setState({ [name]: value });
  }

  handleDiscounts() {
    this.setState({ discounts: !this.state.discounts });
  }
  handlePatientShare() {
    this.setState({ patientShare: !this.state.patientShare });
  }
  onSelectPayerUnderTPA(selectedList, selectedItem) {
    let Values = [];
    selectedList.forEach((item) => {
      Values.push(item.eclaimlink_id);
    });
    this.setState({ payersUnderTPA: Values });
  }

  onRemovePayerUnderTPA(selectedList, removedItem) {
    let Values = [];
    selectedList.forEach((item) => {
      if (item.eclaimlink_id != removedItem.eclaimlink_id) {
        Values.push(item.eclaimlink_id);
      }
    });
    this.setState({ payersUnderTPA: Values });
  }
  onSubmit = async (e) => {
    let { Networks } = this.props;
    this.setState({ sent: true });

    e.preventDefault();

    if (Networks && Networks.length > 0) {
      let result = Networks.filter(
        (n) =>
          n.payer_id == this.state.payer_id &&
          n.network_name == this.state.network_name
      );

      if (result && result.length > 0) {
        alert("The entered network already exist");
        this.setState({ sent: false });

        return;
      }
    }
    let payers
    if(this.state.payerChange==false){
     payers=this.state.payer_id
    }else{
      if (this.state.isTPA) {
        payers = this.state.payersUnderTPA;
      } else {
        payers=this.state.payer_id
      }
    }
    if(this.state.Tpa){
    payers=this.state.payersUnderTPA
    }
    let discount;
    if (this.state.discounts) {
      discount = {
        consultation_discount: this.state.discount,
        laboratory_discount: this.state.discount,
        radiology_discount: this.state.discount,
        procedure_discount: this.state.discount,
        consumables_discount: this.state.discount,
        medication_discount: this.state.discount,
        dental_discount: this.state.discount,
      };
    } else {
      discount = {
        consultation_discount: this.state.consultation_discount,
        laboratory_discount: this.state.laboratory_discount,
        radiology_discount: this.state.radiology_discount,
        procedure_discount: this.state.procedure_discount,
        consumables_discount: this.state.consumables_discount,
        medication_discount: this.state.medication_discount,
        dental_discount: this.state.dental_discount,
      };
    }
    let patient_share;
    if (this.state.patientShare) {
      patient_share = {
        consultation_share: this.state.patient_share_percent,
        laboratory_share: this.state.patient_share_percent,
        radiology_share: this.state.patient_share_percent,
        procedure_share: this.state.patient_share_percent,
        consumables_share: this.state.patient_share_percent,
        medication_share: this.state.patient_share_percent,
        dental_share: this.state.patient_share_percent,
      };
    } else {
      patient_share = {
        consultation_share: this.state.consultation_share,
        laboratory_share: this.state.laboratory_share,
        radiology_share: this.state.radiology_share,
        procedure_share: this.state.procedure_share,
        consumables_share: this.state.consumables_share,
        medication_share: this.state.medication_share,
        dental_share: this.state.dental_share,
      };
    }

    let response;
    let { project } = this.props;
    if (project && project == "KareemBenifits") {
      response = await axios.put(
        KayanURL+"api/KareemBenefits/networks/update",
        {
          apiToken: Cookies.get("KareemId"),
          tpa:this.state.tpa,
          payer_id: payers,
          network_name: this.state.network_name,
          discount: discount,
          patient_share: patient_share,
          network_description: this.state.network_description,
          patient_share_max_amount: this.state.patient_share_max_amount,
          max_number_of_days_for_submission:
            this.state.max_number_of_days_for_submission,
          max_number_of_days_for_resubmissions:
            this.state.max_number_of_days_for_resubmissions,
          max_number_of_resubmission: this.state.max_number_of_resubmission,
          authorizing_amount: this.state.authorizing_amount,
          active: this.state.active,
          id: this.state.id,
        }
      );
    } else {
      response = await axios.put(KayanURL + "api/sama/networks/update", {
        apiToken: Cookies.get("SamaId"),
        payer_id: payers,
        tpa:this.state.tpa,
        network_name: this.state.network_name,
        discount: discount,
        patient_share: patient_share,
        network_description: this.state.network_description,
        patient_share_max_amount: this.state.patient_share_max_amount,
        max_number_of_days_for_submission:
          this.state.max_number_of_days_for_submission,
        max_number_of_days_for_resubmissions:
          this.state.max_number_of_days_for_resubmissions,
        max_number_of_resubmission: this.state.max_number_of_resubmission,
        authorizing_amount: this.state.authorizing_amount,
        active: this.state.active,
        id: this.state.id,
      });
    }

    if (response) {
      let { data } = response;
      if (data.success) {
        this.setState({ success: true, sent: false });
        this.props.getAllNetworks();
      } else {
        this.setState({ success: false, error: data.error, sent: false });
      }
    }

    if (response) {
      let { data } = response;
      if (data.success) {
        this.setState({ success: true, sent: false });
        this.props.getAllNetworks();
      } else {
        this.setState({ success: false, error: data.error, sent: false });
      }
    }
  };

  render() {
    let { payers, sent,Tpa,tpaArray} = this.state;
    let payersList;
    let tpaList;
    if(Tpa){
      if(tpaArray &&tpaArray.length>0){
        tpaList = tpaArray.map((tpa, index) => {
          return (
            <option value={tpa["eclaimlink_id"]} key={index}>
              {tpa["eclaimlink_id"]}
            </option>
          );
        });
      }
    }else{
    
    if (payers && payers.length > 0) {
      payersList = payers.map((payer, index) => {
        return (
          <option value={payer["eclaimlink_id"]} key={index}>
            {payer["eclaimlink_id"]}
          </option>
        );
      });
    }
  }
    return (
      <>
        <div className="container">
          <form onSubmit={this.onSubmit}>
            <h5>Update Network</h5>
            <div>
              {this.state.Tpa? (
                <div>
                  <label style={{ float: "left", width: "37%" }}>TPA</label>
               <select
               name="tpa"
                required
                onChange={this.onScelectPayerName}
                value={this.state.tpa}
              >
                <option value={this.state.tpa}>{this.state.tpa}</option>
                {tpaList}
              </select>
              <div style={{ width: "72%",margin:"auto"}}>
              {<Multiselect
                 placeholder="Payers that follow the TPA"
                  options={payers} // Options to display in the dropdown
                  selectedValues={this.props.payerList} // Preselected value to persist in dropdown
                  onSelect={this.onSelectPayerUnderTPA} // Function will trigger on select event
                  onRemove={this.onRemovePayerUnderTPA} // Function will trigger on remove event
                  displayValue={"eclaimlink_id"} // Property name to display in the dropdown options
                  
                />}
              </div>
                </div>
              ):this.state.Tpa==false?(
                <div>
                   <label style={{ float: "left", width: "37%" }}>Payer ID</label>

              <select
                name="payer_id"
                required
                onChange={this.onScelectPayerName}
                value={this.state.payer_id ? this.state.payer_id : this.state.payer_id}
              >
                <option value="">Select Insurance Company</option>
                {payersList}
              </select>
              {this.state.isTPA ? (
              <div
                style={{
                  width: "70%",
                  margin: "auto",
                }} /* style={{ marginTop: "1.15rem", marginBottom: "0.9rem" }} */
              >               
                <Multiselect
                  placeholder="Payers that follow the TPA"
                  options={payers} // Options to display in the dropdown
                  selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                  onSelect={this.onSelectPayerUnderTPA} // Function will trigger on select event
                  onRemove={this.onRemovePayerUnderTPA} // Function will trigger on remove event
                  displayValue="eclaimlink_id" // Property name to display in the dropdown options
                  
                />
                <br />
              </div>
            ) : null}
                </div>
              ):null}
             </div>
            <div>
              <label style={{ float: "left", width: "42%" }}>
                Network Name
              </label>
              <br />
              <input
                required
                onChange={this.handleInputTextChange}
                type="text"
                name="network_name"
                placeholder="Network Name"
                style={{ width: "70%", padding: "8px" }}
                value={this.state.network_name}
              />
            </div>
            <br />
            <div>
              <label style={{ float: "left", width: "46%" }}>
                Network Description
              </label>
              <br />

              <input
                required
                onChange={this.handleInputTextChange}
                type="text"
                name="network_description"
                placeholder="Network Description"
                style={{ width: "70%", padding: "8px" }}
                value={this.state.network_description}
              />
            </div>
            <div style={{ width: "70%", padding: "8px", marginLeft: "15%" }}>
              {this.state.discounts ? (
                <input
                  checked
                  type="checkbox"
                  value="discounts"
                  onChange={this.handleDiscounts}
                />
              ) : (
                <input
                  type="checkbox"
                  value="discounts"
                  onChange={this.handleDiscounts}
                />
              )}
              <label
                style={{
                  float: "none",
                  color: "#28a745",
                  marginLeft: "10px",
                  fontSize: "large",
                }}
              >
                Equal Discounts
              </label>
              <br />
            </div>

            {this.state.discounts ? (
              <>
                <input
                  onChange={this.handleInputTextChange}
                  type="number"
                  name="discount"
                  placeholder="Discount(%)"
                  value={this.state.discount}
                  style={{ width: "70%", padding: "8px" }}
                  min="0"
                />
                <br />
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "70%",
                    marginLeft: "15%",
                  }}
                >
                  <label>Consultation Discount(%)</label>
                  <input
                    min="0"
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="consultation_discount"
                    placeholder="Consultation Discount(%)"
                    value={this.state.consultation_discount}
                    style={{ width: "45%", padding: "8px" }}
                  />
                  <label>Laboratory Discount(%)</label>
                  <input
                    min="0"
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="laboratory_discount"
                    value={this.state.laboratory_discount}
                    placeholder="Laboratory Discount(%)"
                    style={{ width: "45%", padding: "8px" }}
                  />
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "70%",
                    marginLeft: "15%",
                  }}
                >
                  <label>Radiology Discount(%)</label>
                  <input
                    min="0"
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="radiology_discount"
                    value={this.state.radiology_discount}
                    placeholder="Radiology Discount(%)"
                    style={{ width: "45%", padding: "8px" }}
                  />
                  <label>Procedure Discount(%)</label>
                  <input
                    min="0"
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="procedure_discount"
                    value={this.state.procedure_discount}
                    placeholder="Procedure Discount(%)"
                    style={{ width: "45%", padding: "8px" }}
                  />
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "70%",
                    marginLeft: "15%",
                  }}
                >
                  <label>Consumables Discount(%)</label>
                  <input
                    min="0"
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="consumables_discount"
                    value={this.state.consumables_discount}
                    placeholder="Consumables Discount(%)"
                    style={{ width: "45%", padding: "8px" }}
                  />
                  <label>Medication Discount(%)</label>
                  <input
                    min="0"
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="medication_discount"
                    value={this.state.medication_discount}
                    placeholder="Medication Discount(%)"
                    style={{ width: "45%", padding: "8px" }}
                  />
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "70%",
                    marginLeft: "15%",
                  }}
                >
                  <label>Dental Discount(%)</label>
                  <input
                    min="0"
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="dental_discount"
                    value={this.state.dental_discount}
                    placeholder="Dental Discount(%)"
                    style={{ width: "100%", padding: "8px", margin: "0 auto" }}
                  />
                </div>
              </>
            )}
            <br />
            <div style={{ width: "70%", padding: "8px", marginLeft: "15%" }}>
              {this.state.patientShare ? (
                <input
                  checked
                  type="checkbox"
                  value="discounts"
                  onChange={this.handlePatientShare}
                />
              ) : (
                <input
                  type="checkbox"
                  value="discounts"
                  onChange={this.handlePatientShare}
                />
              )}
              <label
                style={{
                  float: "none",
                  color: "#28a745",
                  marginLeft: "10px",
                  fontSize: "large",
                }}
              >
                Equal PatientShare
              </label>
              <br />
            </div>
            {this.state.patientShare ? (
              <>
                <input
                  min="0"
                  onChange={this.handleInputTextChange}
                  type="number"
                  name="patient_share_percent"
                  value={this.state.patient_share_percent}
                  placeholder="Patient Share(%)"
                  style={{ width: "70%", padding: "8px" }}
                />
                <br />
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "70%",
                    marginLeft: "15%",
                  }}
                >
                  <label>Consultation Share(%)</label>
                  <input
                    min="0"
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="consultation_share"
                    value={this.state.consultation_share}
                    placeholder="Consultation Share(%)"
                    style={{ width: "45%", padding: "8px" }}
                  />
                  <label>Laboratory Share(%)</label>
                  <input
                    min="0"
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="laboratory_share"
                    value={this.state.laboratory_share}
                    placeholder="Laboratory Share(%)"
                    style={{ width: "45%", padding: "8px" }}
                  />
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "70%",
                    marginLeft: "15%",
                  }}
                >
                  <label>Radiology Share(%)</label>
                  <input
                    min="0"
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="radiology_share"
                    value={this.state.radiology_share}
                    placeholder="Radiology Share(%)"
                    style={{ width: "45%", padding: "8px" }}
                  />
                  <label>Procedure Share(%)</label>
                  <input
                    min="0"
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="procedure_share"
                    value={this.state.procedure_share}
                    placeholder="Procedure Share(%)"
                    style={{ width: "45%", padding: "8px" }}
                  />
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "70%",
                    marginLeft: "15%",
                  }}
                >
                  <label>Consumables Share(%)</label>
                  <input
                    min="0"
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="consumables_share"
                    value={this.state.consumables_share}
                    placeholder="Consumables Share(%)"
                    style={{ width: "45%", padding: "8px" }}
                  />
                  <label>Medication Share(%)</label>
                  <input
                    min="0"
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="medication_share"
                    value={this.state.medication_share}
                    placeholder="Medication Share(%)"
                    style={{ width: "45%", padding: "8px" }}
                  />
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "70%",
                    marginLeft: "15%",
                  }}
                >
                  <label>Dental Share(%)</label>
                  <input
                    min="0"
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="dental_share"
                    value={this.state.dental_share}
                    placeholder="Dental Share(%)"
                    style={{ width: "100%", padding: "8px", margin: "0 auto" }}
                  />
                </div>
              </>
            )}
            <br />
            <div>
              <label style={{ float: "left", width: "56%" }}>
                Patient Share Maximum Amount
              </label>
              <br />

              <input
                min="0"
                onChange={this.handleInputTextChange}
                type="number"
                name="patient_share_max_amount"
                placeholder="Patient Share Maximum Amount"
                style={{ width: "70%", padding: "8px" }}
                value={this.state.patient_share_max_amount}
              />
            </div>
            <br />
            <div>
              <label style={{ float: "left", width: "65%" }}>
                Maximum Number Of Days For Submission
              </label>

              <input
                min="0"
                onChange={this.handleInputTextChange}
                type="number"
                name="max_number_of_days_for_submission"
                placeholder="Maximum Number Of Days For Submission"
                style={{ width: "70%", padding: "8px" }}
                value={this.state.max_number_of_days_for_submission}
              />
            </div>
            <br />
            <div>
              <label style={{ float: "left", width: "68%" }}>
                Maximum Number Of Days For Resubmissions
              </label>

              <input
                min="0"
                onChange={this.handleInputTextChange}
                type="number"
                name="max_number_of_days_for_resubmissions"
                placeholder="Maximum Number Of Days For Resubmissions"
                style={{ width: "70%", padding: "8px" }}
                value={this.state.max_number_of_days_for_resubmissions}
              />
            </div>
            <br />

            <div>
              <label style={{ float: "left", width: "60%" }}>
                Maximum Number Of Resubmissions
              </label>

              <input
                min="0"
                onChange={this.handleInputTextChange}
                type="number"
                name="max_number_of_resubmission"
                placeholder="Maximum Number Of Resubmissions"
                style={{ width: "70%", padding: "8px" }}
                value={this.state.max_number_of_resubmission}
              />
            </div>
            <br />
            <div>
              <label style={{ float: "left", width: "52%" }}>
                Authorizing after x- amount
              </label>

              <input
                min="0"
                onChange={this.handleInputTextChange}
                type="number"
                name="authorizing_amount"
                placeholder="Authorizing after x- amount"
                style={{ width: "70%", padding: "8px" }}
                value={this.state.authorizing_amount}
              />
            </div>

            <br />
            <div
              style={{
                width: "70%",
                marginLeft: "15%",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              {this.state.active ? (
                <div>
                  <input
                    checked
                    type="checkbox"
                    name="active"
                    onChange={this.onClickChekBox}
                  />{" "}
                  De/Activate
                </div>
              ) : (
                <div>
                  <input
                    type="checkbox"
                    name="active"
                    onChange={this.onClickChekBox}
                  />{" "}
                  De/Activate
                </div>
              )}
            </div>
            <br />
            <input
              type="submit"
              value="Update"
              style={{
                padding: "0.5rem 0.5rem",
                backgroundColor: "#4caf50",
                color: "white",
                border: "none",
              }}
            />
            <br />
            {sent ? (
              <Spinner animation="border" variant="success" />
            ) : this.state.success ? (
              <h5 style={{ color: "green" }}>
                Network was updated sucessfully
              </h5>
            ) : this.state.error ? (
              <h5 style={{ color: "red" }}>{this.state.error}</h5>
            ) : null}
          </form>
        </div>
      </>
    );
  }
}

export default UpdateNetworkModule;
