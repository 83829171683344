import React from "react";
//import "./style.css";

function VerticalNavBar(props) {
  let { operations, selected } = props;

  let functions = operations.map((f) => {
    return (
      <li
        /*  style={{ padding: "5px"  }} */ style={
          selected == f
            ? {
                color: "white",
                padding: "5px",
                backgroundColor: "rgb(58, 180, 73)",
              }
            : { padding: "5px" }
        }
        className="active"
      >
        <a
          onClick={() => {
            props.handleSwitch(f);
          }}
        >
          {f}
        </a>
      </li>
    );
  });
  return (
    <ul
      style={{
        width: "25%",
        heigth: "100%",
        textAlign: "center",
        paddingTop: "30px",
      }}
    >
      {functions}
    </ul>
  );
}
export default VerticalNavBar;
