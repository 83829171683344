import React from "react";
import NewSearch from "../SamaDiagnosisCodesList/NewSearch"
import NewSearch1 from "../SamaDiagnosisCodesList/NewSearch1"
import NewSearch2 from "../SamaDiagnosisCodesList/NewSearch2"
import NewSearch3 from "../SamaDiagnosisCodesList/NewSearch3"

function NewForm(props) {
  return (
      <>
      {props.inputsTypes.map((item, index) => {
        switch (item) {
            case "Search":
       
                return (
                  
                  <NewSearch
                    lable={props.lable}
                    className={props.formStyle}
                    key={index}
                    name={props.name}
                    addNewCode={props.addNewCode}
                    listName={props.elementsNames[index]}
                    inputStyle={props.inputStyle}
                    submitStyle={props.submitStyle}
                    // onChange={props.onAddNewCodeInputChange}
                    section={props.section}
                    BtnActive_noncovered={props.BtnActive_noncovered}
                    isPrincipalChecked={props.isPrincipalChecked}
                    onSelect={props.onSelectOption}
                    attributes={props.attributes}
                    onChange={props.onChange}
                    onClick={props.onClick}
                    diagnosisNow={props.diagnosisNow}
                    suggestions={props.allDiagnosis}
                    userInputDiagnosis_noncovred={props.userInputDiagnosis_noncovred}
                    userInputActivity={props.userInputActivity}
                    activeSuggestion={props.activeSuggestion}
                    filteredSuggestionsActivity={props.filteredSuggestionsActivity}
                    filteredSuggestionsDiagnosis_noncovered={
                      props.filteredSuggestionsDiagnosis_noncovered
                    }
                    showActivitySuggestions={props.showActivitySuggestions}
                    showDiagnosisSuggestions_noncovered={props.showDiagnosisSuggestions_noncovered}
                  />
                );
                case "Search1":
                return(
                <NewSearch1
                className={props.formStyle}
                  key={index}
                  name={props.name}
                  addNewCode={props.addNewCode}
                  listName={props.elementsNames[index]}
                  inputStyle={props.inputStyle}
                  formStyle
                  submitStyle={props.submitStyle}
                  onInputTextChange={props.onInputTextChange}
                  handleItemDeletion={props.handleItemDeletion}
                  handleClaimDeletion={props.handleClaimDeletion}
                  // onChange={props.onAddNewCodeInputChange}
                  section={props.section}
                  BtnActive={props.BtnActive}
                  isPrincipalChecked={props.isPrincipalChecked}
                  onSelect={props.onSelectOption}
                  attributes={props.attributes}
                  onChange={props.onChange}
                  onClick={props.onClick}
                  diagnosisNow={props.diagnosisNow}
                  suggestions={props.allDiagnosis}
                  userInputDiagnosis={props.userInputDiagnosis}
                  userInputActivity={props.userInputActivity}
                  activeSuggestion={props.activeSuggestion}
                  filteredSuggestionsActivity={props.filteredSuggestionsActivity}
                  filteredSuggestionsDiagnosis={
                    props.filteredSuggestionsDiagnosis
                  }
                  showActivitySuggestions={props.showActivitySuggestions}
                  showDiagnosisSuggestions={props.showDiagnosisSuggestions}
                />

                );
                case "Search3":
                return(
                <NewSearch3
                key={index}
                /*   Items={props.elementsValues[index]} */
                value={props.defaultValue}
                addNewCode={props.addNewCode}
                listName={props.elementsNames[index]}
                inputStyle={props.inputStyle}
                submitStyle={props.submitStyle}
                placeholder={props.placeholder}
                ActivityNow={props.ActivityNow}
                ActivitySuggestions={props.allActivity}
                //
                section={props.section}
                BtnActive={props.BtnActive}
                onSelect={props.onSelectOption}
                attributes={props.attributes}
                onChange={props.onChange}
                onClick={props.onClick}
                suggestions={props.allDiagnosis}
                userInputDiagnosis={props.userInputDiagnosis}
                userInputActivity={props.userInputActivity}
                activeSuggestion={props.activeSuggestion}
                filteredSuggestionsActivity={props.filteredSuggestionsActivity}
                filteredSuggestionsDiagnosis={
                  props.filteredSuggestionsDiagnosis
                }
                showActivitySuggestions={props.showActivitySuggestions}
                showDiagnosisSuggestions={props.showDiagnosisSuggestions}
                />

                );
               case "Search2":
                return(
                <NewSearch2
                key={index}
                /*   Items={props.elementsValues[index]} */
                value={props.defaultValue}
                addNewCode={props.addNewCode}
                listName={props.elementsNames[index]}
                inputStyle={props.inputStyle}
                submitStyle={props.submitStyle}
                placeholder={props.placeholder}
                ActivityNow={props.ActivityNow}
                ActivitySuggestions={props.allActivity}
                //
                section={props.section}
                BtnActive={props.BtnActive}
                onSelect={props.onSelectOption}
                attributes={props.attributes}
                onChange={props.onChange}
                onClick={props.onClick}
                suggestions={props.allDiagnosis}
                userInputDiagnosis={props.userInputDiagnosis}
                userInputActivity={props.userInputActivity}
                activeSuggestion={props.activeSuggestion}
                filteredSuggestionsActivity={props.filteredSuggestionsActivity}
                filteredSuggestionsDiagnosis={
                  props.filteredSuggestionsDiagnosis
                }
                showActivitySuggestions={props.showActivitySuggestions}
                showDiagnosisSuggestions={props.showDiagnosisSuggestions}
                />

                );
          default:
            return (
              <>
              </>
            );
        }
      })}{" "}
      {null}
    </>
  );
}
export default NewForm;
