import React, { Fragment } from "react";
import "../App.css";
import axios from "axios";
import Form from "../Components/Form/index";
import Table from "../Components/Table/index";
import DownloadLink from "react-download-link";
import { CSVLink } from "react-csv";
import Spinner from "react-bootstrap/Spinner";
import settings from "../helpers/Settings";
let { KayanURL, samaApiToken } = settings;

var X2JS = require("x2js");
var x2js = new X2JS();

class StandAlone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      name: null,
      serverError: null,
      isChoised: false,
      data: {},
      filename: "",
      uploading: false,
      error: null,
      success: false,
      dataRetrieved: false,
      DrgReport: null,
    };
  }

  // Handle Input Text Change
  handleInputTextChange = ({ target: { value, files } }) => {
    let reader = new FileReader();
    reader.readAsText(files[0]);
    this.setState({
      filename: value.split("\\")[2],
      dataRetrieved: false,
      ClaimsTotalsTable: null,
      FileTotalsTable: null,
      DrgReport: null,
      success: false,
      error: false,
    });
    this.readXMLFile(reader);
  };

  // Read XML File content
  readXMLFile = (reader) => {
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ requestXML: reader.result });
      }
    };
  };

  // Prepare report table.
  prepareReportTable = (edits) => {
    edits = Array.isArray(edits) ? edits : [edits];
    let TableRows = [];

    // Prepare Request Errors Table.
    if (edits.length > 0) {
      edits.forEach((edit) => {
        let { Element, Line, ClaimId } = edit;
        let DataErrors = edit["Data-Errors"];
        let SchemaErrors = edit["Schema-Errors"];

        SchemaErrors = SchemaErrors
          ? Array.isArray(SchemaErrors)
            ? SchemaErrors
            : [SchemaErrors]
          : [];
        DataErrors = DataErrors
          ? Array.isArray(DataErrors)
            ? DataErrors
            : [DataErrors]
          : [];
        let requestErrors = [];

        [...SchemaErrors, ...DataErrors].forEach((error) =>
          Object.keys(error).forEach((name) => {
            let errors = Array.isArray(error[name])
              ? error[name]
              : [error[name]];
            errors.forEach((err) => {
              err["Element"] = Element;
              err["Element-Line"] = Line;
              err["ClaimId"] = ClaimId;
              err["Error-Node"] = name;
              err["Error-Type"] =
                SchemaErrors.indexOf(error) !== -1 ? "Schema" : "Data";
              requestErrors.push(err);
            });
          })
        );

        requestErrors.forEach((error) => {
          let row = {};
          row["Element"] = error["Element"];
          row["Element Line"] = error["Element-Line"];
          row["Claim ID"] = error["ClaimId"];
          row["Error Type"] = error["Error-Type"];
          row["Error Node"] = error["Error-Node"];
          row["Domain"] = error["Domain"];
          row["ActivityId"] = error["ActivityId"];
          row["Activity Type"] = error["Type"];
          row["Line"] = error["Line"];
          row["Message"] = error["Message"];

          // Add to table rows.
          TableRows.push(row);
        });
      });

      // Sort Request Errors Table
      TableRows.sort((ErrorA, ErrorB) => ErrorA["Line"] - ErrorB["Line"]);
    }

    return TableRows;
  };

  // Do request
  doRequest = (event) => {
    event.preventDefault();
    this.setState({
      uploading: true,
      error: false,
      TableRows: null,
      success: false,
    });

    // handling any request.
    var request = this.state.requestXML;
    document.getElementById("requestFile").value = null;
    axios
      .post(KayanURL + "api/sama/request", {
        content: request,
        apiToken: samaApiToken,
      })
      .then(({ data }) => {
        if (data.success) {
          if (data.DrgReport) {
            this.setState({ DrgReport: data.DrgReport });
          }

          var response = x2js.xml2js(data.response)["Response-Status"];
          var edits;
          if (response["Status"] === "Failed") {
            edits = response["Request-Errors"]["Element-Errors"];
            if (edits && edits.Error && edits.Error.Message) {
              this.setState({ error: "Invalid XML file", uploading: false });
            } else {
              let TableRows = this.prepareReportTable(edits);
              this.setState({
                dataRetrieved: data.response,
                TableRows,
                uploading: false,
              });
            }
          } else {
            this.setState({
              success: true,
              dataRetrieved: data.response,
              uploading: false,
            });
          }

          var { ClaimsTotalsTable, FileTotalsTable } = data;
          this.setState({ ClaimsTotalsTable, FileTotalsTable });
        } else {
          this.setState({
            error: data.error[0],
            inputsHaveErrors: data.inputsHaveErrors,
            uploading: false,
          });
        }
      })
      .catch(() => {
        this.setState({ serverError: "There is a server error!" });
      });
  };

  // Main
  render() {
    let {
      dataRetrieved,
      DrgReport,
      filename,
      uploading,
      TableRows,
      ClaimsTotalsTable,
      FileTotalsTable,
      error,
      serverError,
      success,
    } = this.state;
    if (serverError) {
      return <h1> {serverError}</h1>;
    } else {
      return (
        <div className="requestContainer">
          <Form
            Style="Search"
            inputStyle="IT3"
            submitStyle="BS7 customWidth"
            onSubmit={this.doRequest}
            onInputTextChange={this.handleInputTextChange}
            inputsTypes={["file"]}
            elementsValues={["request", "Upload"]}
            elementsNames={["requestFile"]}
          />

          {!error ? (
            uploading ? (
              <div>
                <span style={{ textAlign: "center", color: "#3ab449" }}>
                  {" "}
                  Uploading {filename}
                </span>
                <Spinner size="sm" animation="grow" variant="success" />
                <Spinner size="sm" animation="grow" variant="success" />
                <Spinner size="sm" animation="grow" variant="success" />
              </div>
            ) : DrgReport ? (
              <CSVLink
                style={{
                  fontSize: "20px",
                  color: "green",
                  textDecoration: "underline",
                }}
                filename={`${filename.slice(0, -4)} Test Output.csv`}
                target="_blank"
                data={DrgReport}
              >
                Please click here to download your DRG Calculations report
              </CSVLink>
            ) : null
          ) : (
            <div
              style={{
                backgroundColor: "red",
                color: "#ffffff",
                padding: "10px",
                margin: "2vh 30vw",
              }}
            >
              {" "}
              {filename + " " + error}{" "}
            </div>
          )}

          <div
            style={{ display: "flex", flexDirection: "column", margin: "1%" }}
          >
            {FileTotalsTable && FileTotalsTable.length > 0 ? (
              <Fragment>
                <label
                  style={{
                    alignSelf: "baseline",
                    fontSize: "medium",
                    color: "#28a745",
                    fontWeight: 700,
                  }}
                >
                  File Totals:
                </label>
                <Table
                  name="Totals"
                  THead={Object.keys(FileTotalsTable[0])}
                  TBody={FileTotalsTable}
                />
              </Fragment>
            ) : null}
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", margin: "1%" }}
          >
            {ClaimsTotalsTable && ClaimsTotalsTable.length > 0 ? (
              <Fragment>
                <label
                  style={{
                    alignSelf: "baseline",
                    fontSize: "medium",
                    color: "#28a745",
                    fontWeight: 700,
                  }}
                >
                  Claims Totals:
                </label>
                <Table
                  name="Totals"
                  THead={Object.keys(ClaimsTotalsTable[0])}
                  TBody={ClaimsTotalsTable}
                />
              </Fragment>
            ) : null}
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", margin: "1%" }}
          >
            {dataRetrieved && TableRows && TableRows.length > 0 ? (
              <Fragment>
                <div
                  style={{
                    backgroundColor: "red",
                    color: "#ffffff",
                    padding: "10px",
                    margin: "2vh 30vw",
                  }}
                >
                  {"Your Sama request has " + TableRows.length + " warnings"}
                </div>

                <DownloadLink
                  style={{
                    fontSize: "20px",
                    color: "red",
                    textDecoration: "underline",
                    marginBottom: "2vh",
                  }}
                  label="Please click here to download your request report"
                  filename={`${filename.slice(0, -4)}_response.xml`}
                  exportFile={() => dataRetrieved}
                />
                <label
                  style={{
                    alignSelf: "baseline",
                    fontSize: "medium",
                    color: "#28a745",
                    fontWeight: 700,
                  }}
                >
                  Sama request warnings:
                </label>
                <Table THead={Object.keys(TableRows[0])} TBody={TableRows} />
              </Fragment>
            ) : success ? (
              <Fragment>
                <div
                  style={{
                    backgroundColor: "green",
                    color: "#ffffff",
                    padding: "10px",
                    margin: "2vh 30vw",
                  }}
                >
                  {"Great. Your Sama request is free of warnings"}
                </div>

                <DownloadLink
                  style={{
                    fontSize: "20px",
                    color: "green",
                    textDecoration: "underline",
                    marginBottom: "2vh",
                  }}
                  label="Please click here to download your request report"
                  filename={`${filename.slice(0, -4)}_response.xml`}
                  exportFile={() => dataRetrieved}
                />
              </Fragment>
            ) : null}
          </div>
        </div>
      );
    }
  }
}

export default StandAlone;
