import React from "react";
import { Grid, MenuItem, TextField, Card } from "@material-ui/core";
import { CustomAutocomplete } from "../../../Components/AutoComplete/CustomMuiAutocomplete";
import SimpleSelect from "../../../Components/controls/SimpleSelect";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Cookies from "js-cookie";
import settings from "../../../helpers/Settings";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "90%",
    margin: "auto",
    padding: "2rem",
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  submitButton: {
    backgroundColor: "#3ab449",
    color: "white",
  },
}));

const HomeFilters = ({}) => {
  const [departmentOptions, setDepartmentOptions] = React.useState([]);
  const [payersList, setPayersList] = React.useState([]);
  const classes = useStyles();
  let { KayanURL } = settings;

  const caseTypesOptions = [
    { name: "No Bed + No emergency room", value: "1" },
    { name: "No Bed + Emergency room", value: "2" },
    {
      name: "Inpatient Bed + No emergency room",
      value: "3",
    },
    { name: "Inpatient Bed + Emergency room", value: "4" },
    { name: "Daycase Bed + No emergency room", value: "5" },
    { name: "Daycase Bed + Emergency room", value: "6" },
    { name: "Nationals Screening", value: "7" },
    { name: "New Visa Screening", value: "8" },
    { name: "Renewal Visa Screening", value: "9" },
    { name: "Home", value: "12" },
    { name: "Assisted Living Facility", value: "13" },
    { name: "Mobile Unit", value: "15" },
    { name: "Ambulance - Land", value: "41" },
    { name: "Ambulance - Air or Water", value: "42" },
  ];

  let license = localStorage.getItem("UserLicenses").split(",");
  // console.log(license);
  let FacilityIdOptions = [];
  license.forEach((l) => {
    FacilityIdOptions.push({ name: l, value: l });
  });

  React.useEffect(() => {
    const getDepartmentOptions = () => {
      let result = axios
        .put(KayanURL + "api/sama/getAllDepartments", {
          apiToken: Cookies.get("SamaId"),
        })
        .then(() => {
          let departments =
            result && result.data ? result.data.departments : [];
          let options = [];
          if (departments && departments.length > 0) {
            departments.forEach((element) => {
              departmentOptions.push({
                name: element["department_name"],
                value: element["id"],
              });
            });
          }
          setDepartmentOptions(options);
        });
    };
    const getPayersList = () => {
      let result = axios
        .put(KayanURL + "api/sama/getPayers", {
          apiToken: Cookies.get("SamaId"),
        })
        .then((response) => {
          let { data } = response;
          if (data && data.success) {
            let { payers } = data;

            setPayersList(payers);
          }
        });
    };
    getDepartmentOptions();
    getPayersList();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <SimpleSelect label="Payer ID">
            {payersList.map((payer) => {
              return (
                <option value={payer["eclaimlink_id"]}>
                  {payer["eclaimlink_id"]}
                </option>
              );
            })}
          </SimpleSelect>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            variant="outlined"
            type="datetime-local"
            name="dateFrom"
            label="From Date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            variant="outlined"
            type="datetime-local"
            name="dateTo"
            label="To Date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SimpleSelect label="Claim Status">
            <MenuItem value="">All claims</MenuItem>
            <MenuItem value="0">New Claims</MenuItem>
            <MenuItem value="1">SAMA Validated</MenuItem>
            <MenuItem value="14">SAMA validated with issues</MenuItem>
            <MenuItem value="15">
              PO validated and SAMA validated with issues
            </MenuItem>
            <MenuItem value="16">
              SAMA validated and PO validated with issues
            </MenuItem>
            <MenuItem value="17">SAMA and PO validated with issues</MenuItem>
            <MenuItem value="2">PO Validated</MenuItem>
            <MenuItem value="3">SAMA and PO validated</MenuItem>
            {/*   <MenuItem value="4">RA with issues</MenuItem>*/}
            <MenuItem value="5">Fully Paid</MenuItem>
            <MenuItem value="6">
              Finished due to exceeding submission allowed value
            </MenuItem>
            <MenuItem value="7">Partially Rejected</MenuItem>
            <MenuItem value="8">Fully Rejected</MenuItem>
            <MenuItem value="9">Claim ready for submission</MenuItem>
            {/*   <MenuItem value="16">Claim submitted to DHPO </MenuItem>
             */}
            <MenuItem value="10">Correction Resubmission</MenuItem>
            <MenuItem value="11">Internal Complaint Resubmission</MenuItem>
            <MenuItem value="12">Finished with partially paid </MenuItem>
            <MenuItem value="13">Finished with fully rejected</MenuItem>
            <MenuItem value="18">Claim submitted to PO</MenuItem>
            <MenuItem value="19">Claim re-submitted to PO</MenuItem>
          </SimpleSelect>
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomAutocomplete
            multiple
            options={caseTypesOptions}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Case Type" variant="outlined" />
            )}
            variant="contained"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            variant="outlined"
            name="name"
            label="Claim ID"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomAutocomplete
            multiple
            options={FacilityIdOptions}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Facility ID" variant="outlined" />
            )}
            variant="contained"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            name="fromPrice"
            label="From Price"
            variant="outlined"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            style={{ marginBottom: "0.5rem", width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            name="toPrice"
            label="To Price"
            variant="outlined"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            style={{ marginBottom: "0.5rem", width: "100%" }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <CustomAutocomplete
            multiple
            options={departmentOptions}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Department" variant="outlined" />
            )}
            variant="contained"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            variant="outlined"
            type="datetime-local"
            name="upload_date"
            InputLabelProps={{
              shrink: true,
            }}
            label="Uploade Date"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default HomeFilters;
