import React from "react";
import "./Codes.css";
import SamaListView from "../SamaListView";
import Button from "../Button";
import Input from "../InputText";
import DropList from "../DropList";
import ProgressBar from "react-bootstrap/ProgressBar";

function defaultClick(e) {
  e.preventDefault();
}

function SamaDiagnosisCodesList5(props) {


  let {
    Items,
    addNewCode,
    inputStyle,
    listName,
    onSelect,
    section,
    BtnActive_noncovered,
    attributes,
    suggestions,
    onChange,
    onClick,
    userInputDiagnosis_noncovred,
    activeSuggestion,
    filteredSuggestionsDiagnosis_noncovered,
    showDiagnosisSuggestions_noncovered,
    handleItemDeletion,
    diagnosisNow,
    name,
    index
  } = props;
  


  let Options;



  let suggestionsListComponentDiagnosis;

  if (showDiagnosisSuggestions_noncovered && userInputDiagnosis_noncovred) {
    if (filteredSuggestionsDiagnosis_noncovered && filteredSuggestionsDiagnosis_noncovered.length) {
      suggestionsListComponentDiagnosis = (
        <ul className="suggestions">
          {filteredSuggestionsDiagnosis_noncovered.map((suggestion, index) => {
            let className;

            // Flag the active suggestion with a class
            if (index === activeSuggestion) {
              className = "suggestion-active";
            }

            return (
              <li className={className} key={suggestion} onClick={onClick}>
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    } else {
      suggestionsListComponentDiagnosis = (
        <div className="no-suggestions">
          <em>No suggestions...</em>
        </div>
      );
    }
  }

  let InputId = "",
    BtnValue = "";
  if (listName === "DiagnosisCodes1") {
    InputId = "DiagnosisCodes1";
    BtnValue = "Confirm Code";
  }


  return (
    <React.Fragment>
      {Items && Items.length > 0 ? (
        <SamaListView
          Items={Items}
          name="codes"
          handleItemDeletion={handleItemDeletion}
          listName={listName}
        />
      ) : null}

      {!(diagnosisNow === 100 && suggestions) ? (
        <ProgressBar
          style={{ width: "70%", margin: "25px auto" }}
          animated
          variant="success"
          now={diagnosisNow}
        />
      ) : null}

      <Input
       name="noncovered_icds"
      key={index}
        id={InputId}
        disabled={suggestions === undefined ? true : false}
        Style={inputStyle}
         onChange={(e) => onChange(e,listName)} 
        hint="Search For ICD code"
        value={listName === "DiagnosisCodes1"  ? userInputDiagnosis_noncovred : null}
      />


      {listName === "DiagnosisCodes1" ? suggestionsListComponentDiagnosis : null}

      {listName === "DiagnosisCodes1" && section === "Diagnosis" && BtnActive_noncovered ? (

        <React.Fragment>
          {attributes && attributes.length > 0
            ? attributes.map((attribute, index) => (
              <DropList
                key={index}
                id="diagnosisType"
                Title="Diagnosis Type"
                name="DiagnosisType"
                Options={Options}
                Values={Options}
                defaultValue={Options[0]}
                onSelect={onSelect}
                Style="requestSelect"
              />
            ))
            : null}
        <Button
            Style="enabledStyle"
            Value={BtnValue}
            onClick={(e) => addNewCode(e, listName, InputId)}
          />
        </React.Fragment>
      ) : (
         null
        )}
    </React.Fragment>
  );
}
export default SamaDiagnosisCodesList5;
