import React from "react";
import Form from "./../Components/Form/index";
import axios from "axios";
import Cookies from "js-cookie";
import { CSVLink } from "react-csv";
import Spinner from "react-bootstrap/Spinner";
import settings from "../helpers/Settings";
var parseString = require("xml2js").parseString;

let { KayanURL } = settings;

class ClaimUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: NaN,
      Records: [],
      samaValidation: false,
      NetworksNames: [],
      NetworksValues: [],
    };
    this.handleChangeChk = this.handleChangeChk.bind(this);
  }

  componentDidMount = () => {
    let Networks = localStorage.getItem("Networks");

    if (Networks) {
      let NetworksNames = [];
      let NetworksValues = [];
      JSON.parse(Networks).forEach((n) => {
        let { network_name, id } = n;
        NetworksNames.push(network_name);
        NetworksValues.push(id);
      });
      this.setState({
        NetworksNames,
        NetworksValues,
      });
    } else {
      this.getAllNetworks();
    }
  };
  async getAllNetworks() {
    let NetworksNames = [];
    let NetworksValues = [];
    this.setState({ flag: true });
    let response = await axios.put(
      KayanURL + "api/sama/networks/getAllNetworks",
      {
        apiToken: Cookies.get("SamaId"),
      }
    );

    if (response) {
      let { data } = response;
      if (data.success) {
        this.setState({ Networks: data.Networks, flag: false });
        localStorage.setItem("Networks", JSON.stringify(data.Networks));
        data.Networks.forEach((n) => {
          let { network_name, id } = n;
          NetworksNames.push(network_name);
          NetworksValues.push(id);
        });
        this.setState({
          NetworksNames,
          NetworksValues,
        });
      }
    }
  }

  onSelectOption = (e) => {
    let select = document.getElementById(e);
    let name = select.name;
    let value = select.options[select.selectedIndex].value;
    switch (name) {
      case "NetworkID":
        this.setState({ NetworkID: value });
        break;
      default:
        break;
    }
  };

  // Read XML File content
  readXMLFile = (reader) => {
    // To Do: 1- Handling error (reader.onerror); 2- clear Input file for each change on input file.
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ requestXML: reader.result });
      }
    };
  };

  handleInputTextChange = ({ target: { name, value, files } }) => {
    this.setState({ responseReady: false });
    this.setState({ fileName: files[0].name });

    switch (name) {
      case "requestFile":
        let reader = new FileReader();
        if (files.length > 0) {
          reader.readAsText(files[0]);
          this.readXMLFile(reader);
        }

        break;

      default:
        //  this.setState({ data: { ...this.state.data, [name]: value } });
        break;
    }
  };

  downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([this.state.validationResponse], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "response.xml";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  handleChangeChk() {
    let { samaValidation } = this.state;
    this.setState({ Records: [] });
    if (!samaValidation) {
      this.setState({ samaValidation: true });
    } else {
      this.setState({ samaValidation: false });
    }
  }

  doRequest = async (event) => {
    event.preventDefault();

    this.setState({ sent: true });

    const data = await axios.post(KayanURL + "api/sama/store", {
      data: this.state.requestXML,
      apiToken: Cookies.get("SamaId"),
      validationResponse: this.state.samaValidation,
      networkId: this.state.NetworkID,
    });

    let samaResponseXML = data.data;

    if (samaResponseXML) {
      if (samaResponseXML.success === true) {
        let { validationResponse } = samaResponseXML;
        this.setState({
          success: true,
          sent: false,
          validationResponse,
        });

        //convert XML to json (CSV)
        let Records = [];
        parseString(validationResponse, function (err, result) {
          if (result) {
            let Response_Status = result["Response-Status"];
            let { Status } = Response_Status;

            if (Status && Status.length > 0 && Status[0] == "Failed") {
              let Request_Errors =
                Response_Status["Request-Errors"][0]["Claim"];
              //let Records = []
              Request_Errors.forEach((claimErrors) => {
                let EncounterId = claimErrors["EncounterId"][0];
                let Line = claimErrors["Line"][0];

                //   console.log(claimErrors);

                let SchemaErrorsArray = claimErrors["Schema-Errors"];

                if (SchemaErrorsArray != undefined) {
                  let SchemaErrors = SchemaErrorsArray[0];

                  let schemaErrorsTypes = Object.keys(SchemaErrors);
                  schemaErrorsTypes.forEach((type) => {
                    let ErrorsofThisType = SchemaErrors[type]; //array
                    ErrorsofThisType.forEach((err) => {
                      let ClaimId = err["ClaimId"][0];
                      let errorLine = err["Line"][0];
                      let Message = err["Message"][0];
                      let Type = "-";
                      let Level = "-";

                      Records.push({
                        ClaimId,
                        EncounterId,
                        claimLine: Line,
                        errorLine,
                        Message,
                        Type,
                        Level,
                      });
                    });
                  });
                }

                let DataErrorsArray = claimErrors["Data-Errors"];

                if (DataErrorsArray) {
                  let DataErrors = DataErrorsArray[0];

                  if (DataErrors) {
                    let DataErrorsCodes = DataErrors["Code"];
                    if (DataErrorsCodes) {
                      DataErrorsCodes.forEach((err) => {
                        let DiagnosisCode;
                        let ClaimId = err["ClaimID"] ? err["ClaimID"][0] : "-";
                        let errorLine = err["Line"] ? err["Line"][0] : "-";
                        let Message = err["Message"] ? err["Message"][0] : "-";
                        let Type = err["Type"] ? err["Type"][0] : "-";
                        let Level = err["Level"] ? err["Level"][0] : "-";
                        let ActivityCode = err["ActivityCode"]
                          ? err["ActivityCode"][0]
                          : "-";

                        if (
                          Type.includes("ICD") ||
                          (Type == "Pregnancy Notification" && Message != "-")
                        ) {
                          var regExp = /\(([^)]+)\)/;
                          var str;
                          if (Type == "ICD-ICD") {
                            str = Message.split("Diagnosis")[1];
                          } else if (
                            Type == "Incorrect HCPCS-ICD Relation" ||
                            Type == "Incorrect CPT-ICD Relation" ||
                            Type == "Incorrect Dental-ICD Relation"
                          ) {
                            str = Message.split("diagnoses")[1];
                          } else {
                            str = Message.split("code")[1];
                          }
                          var matches = regExp.exec(str);
                          if (matches && matches.length > 0) {
                            DiagnosisCode = matches[1];
                          }
                        }
                        // row["DiagnosisCode"] = DiagnosisCode;

                        Records.push({
                          ClaimId,
                          EncounterId,
                          claimLine: Line,
                          errorLine,
                          ActivityCode,
                          DiagnosisCode,
                          Message,
                          Type,
                          Level,
                        });
                      });
                    }
                  }
                }
              });
            }
          }
        });

        this.setState({ Records });
      } else {
        this.setState({
          success: false,
          sent: false,
          errors: samaResponseXML.error,
        });
      }
    }

    document.getElementById("requestFile").value = null;
    document.getElementById("NetworkID").value = "";
    this.setState({ NetworkID: "" });
  };
  render() {
    let { sent, success, errors, Records } = this.state;
    let Errs;

    if (sent == false && success == false && errors && Array.isArray(errors)) {
      Errs = errors.map((err, index) => {
        return <h6 key={index}>{err}</h6>;
      });
    } else if (
      sent == false &&
      success == false &&
      errors &&
      !Array.isArray(errors)
    ) {
      Errs = <h6>{errors}</h6>;
    }
    let header = [
      { label: "Claim ID", key: "ClaimId" },
      { label: "Encounter Sequence", key: "EncounterId" },
      { label: "Claim Line", key: "claimLine" },
      { label: "Edit Line", key: "errorLine" },
      { label: "Edit Message", key: "Message" },
      { label: "Activity Code", key: "ActivityCode" },
      { label: "Diagnosis Code", key: "DiagnosisCode" },
      { label: "Type", key: "Type" },
      { label: "Level", key: "Level" },
    ];

    return (
      <div className="container">
        <br />
        <input
          type="checkbox"
          // defaultChecked={this.state.chkbox}
          onChange={this.handleChangeChk}
        />
        <label
          style={{
            float: "none",
            color: "#28a745",
            marginLeft: "10px",
            fontSize: "large",
          }}
        >
          Get Sama Validation Response
        </label>

        <Form
          submitStyle="BS10"
          labelStyle="BayanLabelStyle"
          onSubmit={this.doRequest}
          linkStyle="Link2"
          dangerStyle="BS10"
          onInputTextChange={this.handleInputTextChange}
          onSelectOption={this.onSelectOption}
          inputsTypes={["file", "select"]}
          elementsValues={[
            "file",
            [this.state.NetworksNames, this.state.NetworksValues],
            ,
            "claims upload",
          ]}
          elementsNames={["requestFile", "NetworkID"]}
        />

        <br />
        {this.state.sent == undefined &&
        this.state.success == undefined ? null : this.state.sent == true ? (
          <Spinner animation="border" variant="success" />
        ) : this.state.sent == false && this.state.success == true ? (
          <div>
            <h6 style={{ color: "green" }}>Claims uploaded successfully</h6>
            {this.state.samaValidation && Records && Records.length == 0 ? (
              <h4 style={{ color: "green" }}>Free of Edits</h4>
            ) : null}
            {this.state.samaValidation && Records && Records.length > 0 ? (
              <button
                onClick={this.downloadTxtFile}
                style={{
                  backgroundColor: "#ffffff",
                  border: "none",
                  textDecoration: "underline",
                  marginBottom: "15px",
                }}
              >
                Click here to download the generated xml response
              </button>
            ) : null}
            <br />
            {this.state.samaValidation && Records && Records.length > 0 ? (
              <CSVLink
                filename="ValidationResult.CSV"
                style={{
                  color: "#ff0505",
                  textDecoration: "underline",
                }}
                data={Records}
                headers={header}
              >
                Click here to download the generated edits report (CSV)
              </CSVLink>
            ) : null}
          </div>
        ) : this.state.sent == false && this.state.success == false ? (
          Errs
        ) : null}
      </div>
    );
  }
}

export default ClaimUpload;
