import React, { Component } from "react";

class AddNewDepartment extends Component {
  render() {
    return (
      <>
        <form
          onSubmit={this.props.onSubmit}
          style={
            {
              /* marginTop: "-250px" */
            }
          }
        >
          <input
            required
            type="text"
            placeholder="Department Name"
            name="name"
            onChange={this.props.handleInputTextChange}
            required
            style={{ width: "70%", padding: "8px" }}
          />
          <br />
          <br />

          <input type="submit" value="Add New Department" className="BS4" />
        </form>
      </>
    );
  }
}

export default AddNewDepartment;
