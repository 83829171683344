import React from "react";
// import './Number.css';

function Number(props) {
  const { id, min, max, step, hint, Style, onChange, name } = props;


  if (name.toLowerCase() === "quantity") {
    return (
      <input
        id={id}
        type="number"
        className={Style}
        min={min}
        max={max}
        step={step}
        name={name}
        defaultValue="1"
        required
        onChange={onChange}
        placeholder={hint}
        style={{
          margin: " 10px auto",
          width: "70%",
          display: "block",
          padding: "10px",
        }}
      />
    );
    
  } 
  else if (name.toLowerCase() === "Activity_NET") {
    return (
      <input
        id={id}
        type="number"
        className={Style}
        min={min}
        max={max}
        step={step}
        name={name}
        // defaultValue={}
        required
        onChange={onChange}
        placeholder={hint}
        style={{
          margin: " 10px auto",
          width: "70%",
          display: "block",
          padding: "10px",
        }}
      />
    );

  }
  else {
    return (
      <input
        id={id}
        type="number"
        className={Style}
        min={min}
        max={max}
        step={step}
        name={name}
        onChange={onChange}
        placeholder={hint}
        style={{
          margin: "10px auto",
          width: "70%",
          display: "block",
          padding: "10px",
        }}
      />
    );
  }
}
export default Number;
