export const mappingClaimStatus = (type) => {
  let EncounterType;

  switch (type) {
    case 0:
    case "0":
      EncounterType = "New Claim";
      break;
    case 1:
    case "1":
      EncounterType = "SAMA Validated";
      break;
    case 2:
    case "2":
      EncounterType = "PO Validated";
      break;
    case 3:
    case "3":
      EncounterType = "SAMA and PO validated";
      break;
    /*    case 4:
     case "4":
        EncounterType = "RA with issues";
        break; */
    case 5:
    case "5":
      EncounterType = /* "RA Paid" */ "Fully Paid";
      break;
    case 6:
    case "6":
      EncounterType = "Finished due to exceeding submission allowed value";
      break;
    case 7:
    case "7":
      EncounterType = "Partially Rejected";
      break;
    case 8:
    case "8":
      EncounterType = "Fully Rejected";
      break;

    case 9:
    case "9":
      EncounterType = "Claim ready for submission";
      break;

    case 10:
    case "10":
      EncounterType = /* "Correction Resubmission" */ "Resubmission";
      break;

    case 11:
    case "11":
      EncounterType = /* "Internal Complaint Resubmission" */ "Resubmission";
      break;

    case 12:
    case "12":
      EncounterType = "Finished with Partially Rejected ";
      break;

    case 13:
    case "13":
      EncounterType = "Finished with Fully Rejected ";
      break;

    case 14:
    case "14":
      EncounterType = "SAMA validated with issues";
      break;

    case 15:
    case "15":
      EncounterType = "PO validated & SAMA validated with issues";
      break;

    case 16:
    case "16":
      EncounterType = "SAMA validated and PO validated with issues";
      break;

    case 17:
    case "17":
      EncounterType = "SAMA and PO validated with issues";
      break;
    case 18:
    case "18":
      EncounterType = "Claim submitted to DHPO";
      break;

    case 19:
    case "19":
      EncounterType = "Claim re-submitted to DHPO";
      break;

    default:
      break;
  }

  return EncounterType;
};
