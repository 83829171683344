import React from "react";
import "./CheckBox.css";

function CheckBox(props) {
  return (
    <div className="checkBox" style={{width:"30%",margin:"auto",padding:"30px",borderBottom:"1px solid #3ab44947"}}>
     
      <p className="text" style={{color:"#3ab449", display: "inline",textTransform:"uppercase",float:"left",fontWeight:"500"}}>
        {" "}
        {props.text}{" "}
      </p>
     
      <input style={{float:"right",marginTop:"7px"}}
        id={props.text}
        /* value={props.value}
        name={props.name} */
        type="checkbox"
        onChange={props.onChange}
        checked={props.checked}

      />
      <span className="markBox"></span>
    
    </div>
  );
}
export default CheckBox;
