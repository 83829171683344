

import React, { Component } from "react";
import axios from "axios";
import Form from "../Components/Form/index.js";
import Spinner from "react-bootstrap/Spinner";

import Cookies from "js-cookie";
import settings from "../helpers/Settings";

let { KayanURL } = settings;
var XLSX = require("xlsx");
class Uploadpreauthoraization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sent: false,
    };
  }
  readXMLFile = (reader) => {
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ requestXML: reader.result });
      }
    };
  };
  processExcel(data) {
    const workbook = XLSX.read(data, { type: "binary" });

    const firstSheet = workbook.SheetNames[0];
    const excelRows1 = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[firstSheet]
    );
    let excelRows = JSON.stringify(excelRows1);
    this.setState({ excelRows });
  }

  handleInputTextChange = ({ target: { name, value, files } }) => {
    this.setState({ responseReady: false });
    this.setState({ fileName: files[0].name });
    switch (name) {
      case "requestFile":
        const fileUpload = document.getElementById("requestFile");
        const regex =/^.*\.(xls|xlsx|csv)$/;
        ///^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
        if (regex.test(fileUpload.value.toLowerCase())) {
          let fileName = fileUpload.files[0].name;
          if (typeof FileReader !== "undefined") {
            const reader = new FileReader();
            if (reader.readAsBinaryString) {
              reader.onload = (e) => {
                this.processExcel(reader.result);
              };
              reader.readAsBinaryString(fileUpload.files[0]);
            }
          } else {
          }
        } else {
          alert("Please upload a valid Excel file.");
        }

        break;

      default:
        break;
    }
  };

  doRequest = async (event) => {
    event.preventDefault();
    this.setState({ responseReady: false, sent: true, status: false });
        const dataRequest = await axios.post(
          KayanURL+"api/KareemBenefits/uploadPreAuthList",
          {
            data: this.state.excelRows,
            apiToken: Cookies.get("KareemId")
            
          }
        );
   
        if (dataRequest) {
          let response = dataRequest.data;
          let result = {};
          let errors=[];
           if (response.success === true) {
            result = {
              message: "The File uploaded successfully",
              res: true
            };
            this.props.getAllCodeList();
          } 
          else {
            this.setState({success:false})
           if(response.error){
            result = { message: response.error,
             res: false
             };
            // console.log(result.message)
            }else if(response.errors){
              result = { message: response.errors,
                res: false
                };
                 errors =result.message;
              //  console.log(result.message)
            }
      this.props.getAllCodeList();
          }
          this.setState({
            status: result,
            sent: false,
            responseReady: true,
            errors:errors
            // ,success:true
          });
          document.getElementById("requestFile").value = null;
        }
      
  };
  render() {
    let { responseReady, sent, status,errors} = this.state;
    let errorMap = [];
    if (errors) {
      errorMap = errors.map((err, index) => {
        return(
          <tr style={{ color: "red" }} key={index}>
          <td>{err.record}</td>
          <td>{err.error}</td>
     </tr>
        );
      });
    }
    return (
      <>
        <div className="container">
          <Form
            submitStyle="BS10"
            labelStyle="BayanLabelStyle"
            onSubmit={this.doRequest}
            linkStyle="Link2"
            dangerStyle="BS10"
            onInputTextChange={this.handleInputTextChange}
            inputsTypes={["file"]}
            elementsValues={["file", "Upload"]}
            elementsNames={["requestFile"]}
          />
          {sent && !responseReady ? (
            <Spinner animation="border" variant="success" />
          ) : null}
       
        {responseReady && status.res==true ? (
            <h5 style={{ color: "green" }}>{status.message}</h5>
          ): responseReady && status.res==false && status.message=='Data is required' && status.message!='The data is empty' ? (
            <h5 style={{ color: "red" }} >{status.message}</h5>
          ) : responseReady && status.res==false &&status.message !='Data is required' && status.message=='The data is empty'?(
              <div className="w-50 m-auto">
                         <h5 style={{ color: "red" }}>{status.message}</h5>

                </div>
          ):responseReady && status.res==false &&status.message !='Data is required' && status.message!='The data is empty'?(
            <div className="w-50 m-auto">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>row</th>
                  <th>error</th>
                </tr>
                </thead>
              <tbody>
              {errorMap}
              </tbody>
              </table>
              </div>
          ):null
          }
        </div>
      </>
    );
  }
}

export default Uploadpreauthoraization;

