import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { mappingClaimStatus } from "./SamaUtils/ValidateClaimHelper";
import ValidateClaimsTableToolbar from "./ValidateClaimsTableToolbar";
import MoreOptions from "../Components/MoreOptions";
import { Icon } from "@iconify/react";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    margin: "auto",
    marginTop: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  submitButton: {
    backgroundColor: "#3ab449",
    color: "white",
  },
  tableCell: {
    padding: "0.2rem",
  },
  tableCell1: {
    padding: "0.2rem",
    color: "white",
  },
  selectedRow: {
    backgroundColor: "#dee2e6!important",
  },
}));

const ValidateClaimsTable = ({
  data,
  handleValidateSelectedClaims,
  handleShowClaimTimeLine,
  handleClaimShowMore,
  handleOpenDeleteModal,
  handleOpenEditMode,
}) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [isAllRowsSelected, setIsAllRowsSelected] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectClaim = (event, row) => {
    const isRowSelected = selected.find((claim) => claim.id == row.id);
    let newSelected = [];
    if (!isRowSelected) {
      newSelected = [...selected, row];
      setSelected((pre) => [...pre, row]);
    } else {
      newSelected = selected.filter((claim) => claim.id != row.id);
      setSelected(newSelected);
    }
    const selectedClaims = newSelected.length;

    if (selectedClaims == data.length) {
      setIsAllRowsSelected(true);
    } else {
      setIsAllRowsSelected(false);
    }
  };

  const handleSelectAll = (event) => {
    if (!isAllRowsSelected) {
      setSelected(data);
      setIsAllRowsSelected(true);
    } else {
      setSelected([]);
      setIsAllRowsSelected(false);
    }
  };

  return (
    <div className={classes.card}>
      <ValidateClaimsTableToolbar
        selectedClaims={selected}
        handleValidateSelectedClaims={handleValidateSelectedClaims}
      />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell1}>
                <Checkbox
                  style={{ padding: 0 }}
                  color="primary"
                  checked={isAllRowsSelected}
                  onChange={(event) => handleSelectAll(event)}
                />
              </TableCell>
              <TableCell align="center" className={classes.tableCell1}>
                ID
              </TableCell>
              <TableCell align="center" className={classes.tableCell1}>
                Claim ID
              </TableCell>
              <TableCell align="center" className={classes.tableCell1}>
                Provider ID
              </TableCell>
              <TableCell align="center" className={classes.tableCell1}>
                Sender ID
              </TableCell>
              <TableCell align="center" className={classes.tableCell1}>
                Receiver ID
              </TableCell>
              <TableCell align="center" className={classes.tableCell1}>
                Transaction Date
              </TableCell>
              <TableCell align="center" className={classes.tableCell1}>
                Gross
              </TableCell>
              <TableCell align="center" className={classes.tableCell1}>
                Patient Share
              </TableCell>
              <TableCell align="center" className={classes.tableCell1}>
                Net
              </TableCell>
              <TableCell align="center" className={classes.tableCell1}>
                Insurance Payment
              </TableCell>
              <TableCell align="center" className={classes.tableCell1}>
                Status
              </TableCell>
              <TableCell align="center" className={classes.tableCell1}>
                Upload Date
              </TableCell>
              <TableCell align="center" className={classes.tableCell1}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                let summationOfPaymentAmount;
                let activities = row["activities"];
                if (
                  row["claim_status"] == "5" ||
                  row["claim_status"] == "7" ||
                  row["claim_status"] == "8"
                ) {
                  summationOfPaymentAmount = 0.0;
                  let activitiesCodes = Object.keys(activities);
                  activitiesCodes.forEach((code) => {
                    summationOfPaymentAmount += parseFloat(
                      activities[code]["PaymentAmount"]
                    );
                  });
                }
                const isRowSelected = selected.find(
                  (claim) => claim.id == row.id
                );

                return (
                  <TableRow
                    className={isRowSelected ? classes.selectedRow : ""}
                    key={`${row.id} ${row.claim_id}`}
                  >
                    <TableCell align="center" className={classes.tableCell}>
                      <Checkbox
                        style={{ padding: 0 }}
                        color="primary"
                        checked={!!isRowSelected}
                        onChange={(event) => handleSelectClaim(event, row)}
                      />
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {row["id"]}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {row["claim_id"]}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {row["provider_id"]}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {row["sender_id"]}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {row["receiver_id"]}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {row["transaction_date"]}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {row["gross"]}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {row["patient_share"]}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {row["net"]}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {row["claim_status"] != "5" &&
                      row["claim_status"] != "7" &&
                      row["claim_status"] != "8"
                        ? "Pending..."
                        : summationOfPaymentAmount}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {mappingClaimStatus(row["claim_status"])}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {row["upload_date"]}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      <MoreOptions
                        record={row}
                        actions={[
                          {
                            name: "Edit Claim",
                            icon: (
                              <Icon
                                icon="clarity:edit-solid"
                                width={24}
                                height={24}
                                style={{ color: "#37ab45" }}
                              />
                            ),
                            handler: handleOpenEditMode,
                            condition: !(
                              row["claim_status"] != "8" &&
                              row["claim_status"] != "7" &&
                              row["claim_status"] != "5"
                            ),
                          },
                          {
                            name: "Show More",
                            icon: (
                              <Icon
                                icon="fluent:textbox-more-24-regular"
                                width={24}
                                height={24}
                                style={{ color: "#37ab45" }}
                              />
                            ),
                            handler: handleClaimShowMore,
                          },
                          {
                            name: "Timeline",
                            icon: (
                              <Icon
                                icon="clarity:timeline-line"
                                width={24}
                                height={24}
                                style={{ color: "#37ab45" }}
                              />
                            ),
                            handler: handleShowClaimTimeLine,
                          },
                          {
                            name: "Delete",
                            icon: (
                              <Icon
                                icon="fluent:delete-12-regular"
                                width={24}
                                height={24}
                                style={{ color: "red" }}
                              />
                            ),
                            handler: handleOpenDeleteModal,
                          },
                        ]}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};

export default ValidateClaimsTable;
