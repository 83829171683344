import React, { Component } from "react";
import NavBar from "./../Components/navbar";

import Footer from "./../Components/footer";

import BayanDocument from "./../Components/bayandoc";

class BayanDoc extends Component {
  render() {
    return (
      <>
        <NavBar userType={localStorage.getItem("type")} />

        <BayanDocument />

        <Footer />
      </>
    );
  }
}

export default BayanDoc;
