import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
//import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState, useEffect } from "react";
/* import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText"; */
import PatientForm from "./PatientForm";
import DiagnosisForm from "./DiagnosisForm";
import ActivityForm from "./ActivityForm";
/* import ActivityTable from "./ActivityTable";
import DiagnosisTable from "./DiagnosisTable";
import Grid from "@mui/material/Grid"; */

const steps = ["Patient Details", "Diagnosis Details", "Activity Details"];

function getStepContent(step, props, state) {
  switch (step) {
    case 0:
      return <PatientForm onChange={props.onChange} data={state} />;
    case 1:
      return (
        <DiagnosisForm
          onClickAddDiagnosis={props.onClickAddDiagnosis}
          onChange={props.onChange}
          data={state}
          deleteDiagnosisElement={props.deleteDiagnosisElement}
          diagnosisObj={props.diagnosisObj}
        />
      );
    case 2:
      return (
        <ActivityForm
          onChange={props.onChange}
          data={state}
          onClickAddActivity={props.onClickAddActivity}
          deleteActivityElement={props.deleteActivityElement}
          activityObj={props.activityObj}
        />
      );
    default:
      throw new Error("Unknown step");
  }
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function FormUpload(props) {
  const [activeStep, setActiveStep] = React.useState(props.step);

  const [state, setState] = useState(props.claimData);

  useEffect(() => {
    setState(props.claimData);
  }, [props.claimData]);

  const handleNext = (e) => {
    setActiveStep(activeStep + 1);

    if (activeStep === steps.length - 1) {
      props.onValidate(e);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleClear = (e) => {
    setActiveStep(0);
    setState({});
    props.clearResponse(e);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: "relative",
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        {/*  <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Company name
          </Typography>
        </Toolbar> */}
      </AppBar>
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                )}

                <Button
                  variant="contained"
                  onClick={(e) => handleClear(e)}
                  sx={{ mt: 3, ml: 1, backgroundColor: "red" }}
                >
                  Clear
                </Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent(activeStep, props, state)}
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                )}

                <Button
                  variant="contained"
                  onClick={(e) => handleNext(e)}
                  sx={{ mt: 3, ml: 1 }}
                >
                  {activeStep === steps.length - 1 ? "Validate" : "Next"}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Paper>
        {/*   <Copyright /> */}
      </Container>
    </ThemeProvider>
  );
}
