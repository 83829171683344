import { withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";

export const CustomAutocomplete = withStyles({
  tag: {
    backgroundColor: "#0096fb",
    color: "white",
    "& .MuiChip-deleteIcon": {
      color: "white",
    },
  },
})(Autocomplete);
