import React from "react";
// import styles from './Styling/mainFooter.module.css'
import "./Styling/ContactForm.css";
import { FaLocationArrow } from "react-icons/fa";
import { FaPhone, FaMailBulk } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="main-footer">
      <div className="container">
        <div className="row" style={{ width: "95%", marginLeft: "2.5%" }}>
          {/* Column1 */}
          <div className="col">
            <h4>Main Office</h4>
            <ul className="list-unstyled">
              <li>
                <FaLocationArrow className="footer-icon" /> UAE, Dubai, Sheikh
                Zayed Road, Aspin Commercial Tower, 44th Floor
              </li>
              {/*   <li><FaMailBulk className="footer-icon"/> ihijawi@kayan-healthcare.com</li> */}
              <li>
                <FaPhone className="footer-icon" /> +971561207460
              </li>
            </ul>
          </div>
          {/* Column2 */}
          <div className="col">
            <h4>Second Office</h4>
            <ul className="list-unstyled">
              <li>
                <FaLocationArrow className="footer-icon" /> Palestine, Jenin,
                Haifa street, Al-Bareq Building, 5th floor
              </li>
              {/*    <li><FaMailBulk className="footer-icon"/> kareemkh@kayan-healthcare.com</li> */}
              <li>
                <FaPhone className="footer-icon" /> +970599879418
              </li>
            </ul>
          </div>
          {/* Column3 */}
          <div className="col">
            <h4>About Us</h4>
            <ul className="list-unstyled">
              <a href="/info">
                <li>Management Team</li>
              </a>
              <a href="/info/#contact">
                <li>Offices</li>
              </a>
            </ul>
          </div>
        </div>
        <hr />
        <div className="row">
          <p className="col-sm" style={{ textAlign: "center" }}>
            &copy;{new Date().getFullYear()} {/* Kayan Healthcare  */} All
            rights reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;

// import React, { Component } from "react";
// import { FaLocationArrow } from "react-icons/fa";
// import { FaPhone, FaXRay, FaMailBulk } from "react-icons/fa";
// import "../App.css";
// import { Container } from "react-bootstrap";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import sec_bg from "./../imgs/sec_bg.png";
// import Form from "./../Components/form";
// class Footer extends Component {
//   render() {
//     return (
//       <div
//         className="foo"
//         style={{
//           backgroundImage: `url(${sec_bg})`,
//         }}
//       >
//         <br></br>
//         <h3>Kayan Healthcare Technologies Offices</h3>
//         <Row>
//           <Col style={{ minWidth: "300px", margin: "45px" }}>
//             <h4>Main </h4>
//             {/* <p style={{ fontWeight: "bold" ,margin : "20px" }}> Main </p> */}
//             <table style={{ textAlign: "left" }}>
//               <tr style={{ backgroundColor: "transparent" }}>
//                 <td>
//                   <FaLocationArrow />
//                 </td>
//                 <td>
//                   4403-18, 44th Floor, Aspin Commercial Tower, Sheikh Zayed
//                   Road, Dubai
//                 </td>
//               </tr>

//               <tr style={{ backgroundColor: "transparent" }}>
//                 <td>
//                   <FaMailBulk />
//                 </td>
//                 <td> Islam- ihijawi@kayan-healthcare.com</td>
//               </tr>
//               <tr style={{ backgroundColor: "transparent" }}>
//                 <td>
//                   <FaPhone />
//                 </td>
//                 <td>+971 56 120 7460</td>
//               </tr>
//               <tr style={{ backgroundColor: "transparent" }}>
//                 <td>
//                   <FaMailBulk />
//                 </td>
//                 <td> Aditi- asingh@kayan-healthcare.com</td>
//               </tr>
//               <tr style={{ backgroundColor: "transparent" }}>
//                 <td>
//                   <FaPhone />
//                 </td>
//                 <td>+971 588434093</td>
//               </tr>
//             </table>
//           </Col>
//           <Col style={{ minWidth: "300px", margin: "10px" }}>
//             {" "}
//             <h4 style={{ visibility: "hidden" }}>Other Office</h4>
//             <h4>Other </h4>
//             {/* <p style={{ fontWeight: "bold" }}> Other </p> */}
//             <table style={{ textAlign: "left" }}>
//               <tr style={{ backgroundColor: "transparent" }}>
//                 <td>
//                   <FaLocationArrow />
//                 </td>
//                 <td>
//                   Palestine, Jenin, Haifa street, Al-Bareq Building, 5th floor
//                 </td>
//               </tr>
//               <tr style={{ backgroundColor: "transparent" }}>
//                 <td>
//                   <FaPhone />
//                 </td>
//                 <td>+970599879418</td>
//               </tr>
//               <tr style={{ backgroundColor: "transparent" }}>
//                 <td>
//                   <FaMailBulk />
//                 </td>
//                 <td>kareemkh@kayan-healthcare.com</td>
//               </tr>
//               <tr style={{ backgroundColor: "transparent" }}>
//                 {/* <td>
//                   <FaXRay />
//                 </td> */}
//                 <td></td>
//               </tr>
//             </table>
//           </Col>
//           <Col style={{ minWidth: "300px", margin: "50px" }}>
//             <h4>Contact us</h4>
//             <Form style={"test"} />
//           </Col>
//         </Row>
//         <div>
//           <h6>
//             All rights&copy; are reserved for Kayan Healthcare Technologies
//           </h6>
//         </div>
//         <br />
//       </div>
//     );
//   }
// }
// //&copy;
// export default Footer;
