import React from 'react'
import Button from "../Components/Button";

const KareemBenefits = (props) => {
    const {toggleTab}=props
    return (
              <div
                style={{
                  width:"50%",
                  margin: "3rem 0 0 25%",
                  height:"13rem",
                  border: " 0",
                  boxShadow:
                    "0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)",
                  padding: "30px 20px",
                }}
              >
                <Button
                  Style="BS11"
                  Value="File Upload"
                  onClick={() => toggleTab(2)}
                />
                <br></br>
                <br></br>
                <Button
                  Style="BS11"
                  Value="Form Upload"
                  onClick={() => toggleTab(3)}
                />
              </div>
    )
}

export default KareemBenefits
