import React, { Component } from "react";
import axios from "axios";
import NavBar from "./../Components/navbar";
import Footer from "./../Components/footer";
import Cookies from "js-cookie";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import settings from "../helpers/Settings";
let { KayanURL, apiToken, user } = settings;

class loginEmployeeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      adminRole: null,
      Company: "",
      city: "",
      username: "",
      password: "",
      error: null,
      serverError: null,
      usertype: null,
      isLogin: false,
      errorMsg: "",
      loginStatus: false,
      loading: true,
    };

    this.handelLogIn = this.handelLogIn.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = () => {
    const { history, location } = this.props;
    let errorMsg = location.state ? location.state.errorMsg : null;
    if (apiToken) {
      history.push(`/Sanad/employee`, { name: user });
    } else {
      this.setState({
        loading: false,
        error: errorMsg ? errorMsg : null,
      });
    }
  };

  //handle change
  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  //handle login
  handelLogIn = (e) => {
    e.preventDefault();
    this.setState({ loginStatus: true, error: null });

    const { history } = this.props;

    if (this.state.Company === "kayan") {
      this.setState({ usertype: "admin" });

      axios
        .post(KayanURL + "sanad-signing/admin/login", {
          username: this.state.username,
          password: this.state.password,
        })
        .then(({ data }) => {
          if (data.success) {
            localStorage.setItem("SanadIsLogIn", true);
            localStorage.setItem("SanadUserName", this.state.username);
            localStorage.setItem("SanadCompanyType", data.companytype);
            if (localStorage.getItem("SanadAdminRole") !== "Super") {
              localStorage.setItem("SanadPath", "/admin");
              history.push("/admin");
            } else {
              localStorage.setItem("SanadPath", "/superAdmin");
              history.push("/superAdmin");
            }
          } else {
            this.setState({
              error: data.error,
              inputsHaveErrors: data.inputsHaveErrors,
            });
          }
        })
        .catch(() => {
          this.setState({ serverError: "There are an Server error" });
        });
    } else {
      axios
        .post(KayanURL + "sanad-signing/employee/login", {
          companyname: this.state.Company,
          username: this.state.username,
          password: this.state.password,
        })

        .then(({ data }) => {
          if (data.success) {
            localStorage.setItem("SanadName", data.name);
            localStorage.setItem("SanadCompanyLicense", data.companylicense);
            localStorage.setItem("SanadCompanyType", data.companytype);
            localStorage.setItem("SanadCompany", this.state.Company);

            this.state.Company === "Kayan"
              ? localStorage.setItem("SanadType", "admin")
              : localStorage.setItem("SanadType", "normal");
            Cookies.set("SanadId", data.token);
            history.push({ pathname: "/Sanad/employee" });
          } else {
            this.setState({
              error: data.error,
              inputsHaveErrors: data.inputsHaveErrors,
              loginStatus: false,
            });
          }
        })
        .catch(() => {
          this.setState({ serverError: "There is a Server error" });
        });
    }
  };

  // Main
  render() {
    let { loading, loginStatus, error, serverError } = this.state;

    if (serverError) {
      return (
        <div
          style={{ backgroundColor: "red", color: "#ffffff", padding: "10px" }}
        >
          {" "}
          {"Server Error !"}{" "}
        </div>
      );
    } else {
      if (!loading) {
        return (
          <>
            <NavBar />
            <div id="main-registration-container" className="registerDiv">
              <form onSubmit={this.handelLogIn}>
                <div className="ToMargin">
                  <label>Company Name</label>
                  <input
                    type="text"
                    placeholder="Company Name"
                    value={this.state.Company}
                    className="form-control"
                    required
                    onChange={(event) => {
                      this.setState({ Company: event.target.value });
                    }}
                  />
                </div>

                <div className="ToMargin">
                  <label>User Name</label>

                  <input
                    type="text"
                    placeholder="User Name"
                    required
                    className="form-control"
                    onChange={(event) => {
                      this.setState({ username: event.target.value });
                    }}
                  />
                </div>

                <div className="ToMargin">
                  <label>Password</label>
                  <input
                    type="password"
                    placeholder="Password"
                    className="form-control"
                    required
                    onChange={(event) => {
                      this.setState({ password: event.target.value });
                    }}
                  />
                </div>

                {loginStatus === false ? (
                  <input
                    type="submit"
                    style={{
                      backgroundColor: "rgb(67, 183, 81)",
                      color: "#ffffff",
                      border: "none",
                      padding: "5px 38px",
                      marginTop: "20px",
                      borderRadius: "5px",
                    }}
                    value="Login"
                    className="reg"
                  />
                ) : (
                  <Button
                    style={{
                      backgroundColor: "#43b751",
                      color: "white",
                      marginTop: "20px",
                    }}
                    variant="light"
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Loading...
                  </Button>
                )}
              </form>
            </div>
            {error ? (
              <div
                style={{
                  backgroundColor: "red",
                  color: "#ffffff",
                  padding: "10px",
                  margin: "2vh 30vw",
                }}
              >
                {" "}
                {error}{" "}
              </div>
            ) : null}

            <Footer />
          </>
        );
      } else {
        return <h2> Loading ... </h2>;
      }
    }
  }
}

export default loginEmployeeForm;
