import React, { Component } from "react";
import NavBar from "./../Components/navbar";
// import MainPageFooter from "./../Components/MainPageFooter";
import Partners from "./../Components/partners";
import Team from "./../Components/team";
import ImageSlider from "../Components/ImageSlider";
import Services from "../Components/Services";
import Footer from "../Components/footer";

// import MainPageSolutions from '../Components/MainPageSolutions'
class MainPage extends Component {
  render() {
    return (
      <div className="MainPage" >
        {/* NavBar */}
        <NavBar />
        {/* image slider */}
        <ImageSlider />

        {/**/}
        <Services />
        {/* Kayan Description  */}
        {/* <Body /> */}
        {/* New Team Component*/}

        {/* Team */}
        <Team />
        {/* Solution Section */}
        {/* <MainPageSolutions /> */}


        {/* Kayan Features */}
        {/* <CircleBody /> */}

        {/* Partners */}

        <Partners />

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}

export default MainPage;
