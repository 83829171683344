import React, { Component } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Spinner from "react-bootstrap/Spinner";
import settings from "../../helpers/Settings";
let { KayanURL } = settings;

class AddNewCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payer_id: null,
      endWait: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onSelectPayer = this.onSelectPayer.bind(this);
  }

  componentDidMount = async () => {
    let payers = localStorage.getItem("payers");
    if (payers) {
      this.setState({ payers: JSON.parse(payers) });
    } else {
      this.getPayersList();
    }
  };
  async getPayersList() {
    let response = await axios.put(KayanURL + "api/sama/getPayers", {
      apiToken: Cookies.get("SamaId"),
    });

    if (response) {
      let { data } = response;

      if (data && data.success) {
        let { payers } = data;

        this.setState({ payers });
        localStorage.setItem("payers", JSON.stringify(payers));
      }
    }
  }

  onSelectPayer({ target: { name, value } }) {
    this.setState({ payer_id: value });
  }
  handleInputTextChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ responseReady: false, sent: true, status: false });
      const Object = {
        payer_id: this.state.payer_id,
        icd: this.state.ICD_code,
      };
      let dataForm = [Object];
      let dataArrayOfJson = JSON.stringify(dataForm);
      // console.log("dataForm is:",dataArrayOfJson);
      const dataResponse = await axios.post(
        KayanURL + "api/sama/uploadNotAllowedPrincipalICDList",
        {
          data: dataArrayOfJson,
          apiToken: Cookies.get("SamaId"),
        }
      );
      if (dataResponse) {
        let response = dataResponse.data;
        // console.log(response)
        let result = {};
        if (response.success === true) {
          result = {
            message: "ICD added successfully",
            res: true,
          };
          this.props.getAllNotAllowedPrincipalICDList();
        } else {
          if(response.error){
            result = { message: response.error,
             res: false
             };
            }else if(response.errors){
              console.log(response.errors[0].error)
              result = { message: response.errors[0].error,
                res: false
                };
            }
            this.props.getAllNotAllowedPrincipalICDList();
          // result = { message: response.error, res: false };
          // console.log(result.message)
        }
        this.setState({
          status: result,
          sent: false,
          responseReady: true,
        });
      // }
    }
  };
  render() {
    let { sent, payers, status, responseReady, resultCheck } = this.state;
    let payersList;
    if (payers && payers.length > 0) {
      payersList = payers.map((payer) => {
        return (
          <option value={payer["eclaimlink_id"]} key={payer["eclaimlink_id"]}>
            {payer["eclaimlink_id"]}
          </option>
        );
      });
    }
    
    return (
      <>
        <div className="container">
          <form onSubmit={this.onSubmit}>
            <h5>Add New Code Of ICD</h5>
            <select
              required
              onChange={this.onSelectPayer}
              value={this.state.payer_id ? this.state.payer_id : ""}
            >
              <option value="">Select Payer ID</option>
              {payersList}
            </select>
            <input
              required
              onChange={this.handleInputTextChange}
              type="text"
              name="ICD_code"
              placeholder="ICD Code"
              style={{ width: "70%", padding: "8px" }}
            />
            <br />
            <input type="submit" value="Add" className="BS1" />
            <br />

            {sent && !responseReady ? (
              <Spinner animation="border" variant="success" />
            ) : null}
            {responseReady && status.res ? (
              <h5 style={{ color: "green" }}>{status.message}</h5>
            ) : responseReady && status.res == false ? (
              <h5 style={{ color: "red" }}>{status.message}</h5>
            ) : responseReady && status.res == false&&status.message=='This data is exist enter another code!' ? (
              <h5 style={{ color: "red" }}>
                {status.message}
              </h5>
            ) : null}
          </form>
        </div>
      </>
    );
  }
}

export default AddNewCode;
