import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Box } from "@material-ui/core";
import Cookies from "js-cookie";
import { MdDashboard, MdImportContacts, MdSend } from "react-icons/md";
import "../../KareemBenefits/KareemStyle.css";
import {
  FaTools,
  FaStackOverflow,
  FaRegAddressCard,
  FaFileUpload,
  FaClipboardCheck,
  FaFileAlt,
  FaRegUser,
  FaHistory,
  FaFileCsv,
  FaWpforms,
  FaUpload,
  FaUserPlus,
  FaUsers,
  FaCubes,
  FaSignOutAlt,
  FaClock,
  FaTrademark,
  FaInbox,
  FaCodeBranch,
  FaCaretSquareDown,
  FaFileInvoice,
  FaFileSignature,
  FaLevelDownAlt,
  FaNetworkWired,
} from "react-icons/fa";
import IconMenuClose from "../../KareemBenefits/icons/icon-close.svg";
import IconMenuOpen from "../../KareemBenefits/icons/icon-open.svg";
import { Divider } from "@material-ui/core";

const drawerWidth = 260;
const useStyles = makeStyles((theme) => ({
  drawer: {
    zIndex: 1000,
    width: drawerWidth,
    flexShrink: 0,
    // whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(8) + 1,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    marginTop: theme.spacing(6.5),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(10),
    },
  },
}));

export default function SamaSubNavBar({
  toggleState,
  toggleTab,
  name,
  projectType,
  history,
  children,
}) {
  const [showAboutMenu, setShowAboutMenu] = useState(false);
  const [showAboutMenu1, setShowAboutMenu1] = useState(false);
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const handleHover = () => {
    setShowAboutMenu(!showAboutMenu);
  };
  const handleHover1 = () => {
    setShowAboutMenu1(!showAboutMenu1);
  };

  const handleLogout = (projectType, history) => {
    let tokenName =
      projectType == "Sanad" || projectType == "Huda"
        ? "SanadId"
        : projectType == "Kareem"
        ? "KareemId"
        : projectType == "Sama" || projectType == "Somod"
        ? "SamaId"
        : projectType == "admin"
        ? "BayanAdminID"
        : projectType == "SamaCompanyAdmin"
        ? "SamaCompanyId"
        : projectType == "samaAdmin"
        ? "SamaAdminId"
        : "id";

    Cookies.remove(tokenName);

    Object.keys(localStorage).forEach((item) => {
      if (
        projectType !== "Sanad" &&
        projectType !== "Sama" &&
        projectType != "admin"
      ) {
        if (
          !item.includes("Sanad") &&
          !item.includes("Sama") &&
          !item.includes("Admin")
        ) {
          localStorage.removeItem(item);
        }
      } else if (projectType == "Sama") {
        if (item.includes("Sama")) {
          localStorage.removeItem(item);
        } else if (projectType == "Kareem") {
          if (item.includes("Kareem")) {
            localStorage.removeItem(item);
          }
        }
      } else if (projectType == "admin") {
        if (item.includes("Admin")) {
          localStorage.removeItem(item);
        }
      } else if (projectType == "samaAdmin") {
        if (item.includes("SamaAdminId")) {
          localStorage.removeItem(item);
        }
      } else {
        if (item.includes("Sanad")) {
          localStorage.removeItem(item);
        }
      }
    });

    history.push("/");
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const Contracts = () => {
    return (
      <>
        {open ? (
          <>
            <ListItem
              onClick={() => toggleTab(13)}
              className={toggleState === 13 ? "tabActive1" : "tab1"}
            >
              <ListItemIcon>{iconMenu[26]}</ListItemIcon>
              <ListItemText primary="Networks" />
            </ListItem>
            <ListItem
              onClick={() => toggleTab(14)}
              className={toggleState === 14 ? "tabActive1" : "tab1"}
            >
              <ListItemIcon>{iconMenu[1]}</ListItemIcon>
              <ListItemText primary="Price Lists" />
            </ListItem>
          </>
        ) : null}
      </>
    );
  };
  const ClaimMethods = () => {
    return (
      <>
        {open ? (
          <>
            <ListItem
              onClick={() => toggleTab(10)}
              className={toggleState === 10 ? "tabActive1" : "tab1"}
            >
              <ListItemIcon>{iconMenu[21]}</ListItemIcon>
              <ListItemText primary="Claim XML" />
            </ListItem>
            <ListItem
              onClick={() => toggleTab(11)}
              className={toggleState === 11 ? "tabActive1" : "tab1"}
            >
              <ListItemIcon>{iconMenu[1]}</ListItemIcon>
              <ListItemText primary="Claim Form" />
            </ListItem>
            <ListItem
              onClick={() => toggleTab(12)}
              className={toggleState === 12 ? "tabActive1" : "tab1"}
            >
              <ListItemIcon>{iconMenu[3]}</ListItemIcon>
              <ListItemText primary="Claim CSV" />
            </ListItem>
          </>
        ) : null}
      </>
    );
  };

  const iconMenu = [
    <MdImportContacts />,
    <FaWpforms />,
    <MdDashboard />,
    <FaFileCsv />,
    <FaHistory />,
    <FaRegUser />,
    <FaSignOutAlt />,
    <FaUsers />,
    <FaInbox />,
    <FaCubes />,
    <FaTrademark />,
    <FaCodeBranch />,
    <FaClock />,
    <FaUserPlus />,
    <FaFileAlt />,
    <FaClipboardCheck />,
    <MdSend />,
    <FaFileUpload />,
    <FaRegAddressCard />,
    <FaStackOverflow />,
    <FaTools />,
    <FaUpload />,
    <FaCaretSquareDown />,
    <FaFileInvoice />,
    <FaFileSignature />,
    <FaLevelDownAlt style={{ marginLeft: "4.5rem" }} />,
    <FaNetworkWired />,
  ];
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        id="barra-lateral"
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          {open ? (
            <IconButton onClick={handleDrawerClose}>
              <img src={IconMenuClose} alt="close menu" />
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerOpen}>
              <img src={IconMenuOpen} alt="open menu" />
            </IconButton>
          )}
        </div>
        <Divider />
        <List>
          <ListItem>
            <ListItemIcon>{iconMenu[5]}</ListItemIcon>
            <ListItemText primary={name} />
          </ListItem>
          <ListItem
            onClick={() => toggleTab(1)}
            className={toggleState === 1 ? "tabActive" : ""}
          >
            <ListItemIcon>{iconMenu[2]}</ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem onClick={handleHover1}>
            <ListItemIcon>{iconMenu[0]}</ListItemIcon>
            <ListItemText primary="Contract Management" />
            {iconMenu[25]}
          </ListItem>
          {showAboutMenu1 && <Contracts />}
          {/* --- */}
          <ListItem onClick={handleHover}>
            <ListItemIcon>{iconMenu[24]}</ListItemIcon>
            <ListItemText primary="Claims Management" />
            {iconMenu[25]}
          </ListItem>
          {showAboutMenu && <ClaimMethods />}

          {/* --- */}
          <ListItem
            onClick={() => toggleTab(3)}
            className={toggleState === 3 ? "tabActive" : ""}
          >
            <ListItemIcon>{iconMenu[15]}</ListItemIcon>
            <ListItemText primary="Validate Claims" />
          </ListItem>
          <ListItem
            onClick={() => toggleTab(4)}
            className={toggleState === 4 ? "tabActive" : ""}
          >
            <ListItemIcon>{iconMenu[16]}</ListItemIcon>
            <ListItemText primary="Submissions" />
          </ListItem>
          <ListItem
            onClick={() => toggleTab(5)}
            className={toggleState === 5 ? "tabActive" : ""}
          >
            <ListItemIcon>{iconMenu[17]}</ListItemIcon>
            <ListItemText primary="Resubmissions" />
          </ListItem>
          <ListItem
            onClick={() => toggleTab(6)}
            className={toggleState === 6 ? "tabActive" : ""}
          >
            <ListItemIcon>{iconMenu[14]}</ListItemIcon>
            <ListItemText primary="Rules Management" />
          </ListItem>
          <ListItem
            onClick={() => toggleTab(8)}
            className={toggleState === 8 ? "tabActive" : ""}
          >
            <ListItemIcon>{iconMenu[19]}</ListItemIcon>
            <ListItemText primary="Flow Management" />
          </ListItem>
          <ListItem
            onClick={() => toggleTab(9)}
            className={toggleState === 9 ? "tabActive" : ""}
          >
            <ListItemIcon>{iconMenu[20]}</ListItemIcon>
            <ListItemText primary="SAMA Validation" />
          </ListItem>
          <ListItem
            onClick={() => toggleTab(21)}
            className={toggleState === 21 ? "tabActive" : ""}
          >
            <ListItemIcon>{iconMenu[17]}</ListItemIcon>
            <ListItemText primary="Non Covered ICD List" />
          </ListItem>
          <ListItem
            onClick={() => toggleTab(22)}
            className={toggleState === 22 ? "tabActive" : ""}
          >
            <ListItemIcon>{iconMenu[17]}</ListItemIcon>
            <ListItemText primary="Not Allowed Principal ICD List" />
          </ListItem>
          <ListItem
            onClick={() => toggleTab(23)}
            className={toggleState === 23 ? "tabActive" : ""}
          >
            <ListItemIcon>{iconMenu[17]}</ListItemIcon>
            <ListItemText primary="Pre-Authorization codes List" />
          </ListItem>
          <ListItem
            onClick={() => toggleTab(20)}
            className={toggleState === 20 ? "tabActive" : ""}
          >
            <ListItemIcon>{iconMenu[17]}</ListItemIcon>
            <ListItemText primary="Plans" />
          </ListItem>
          <ListItem
            className="sign-out"
            onClick={() => handleLogout(projectType, history)}
          >
            <ListItemIcon>{iconMenu[6]}</ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" flexGrow={1}>
        {children}
      </Box>
    </Box>
  );
}
