import React, { Component } from "react";
import NavBar from "../Components/navbar";
import Footer from "../Components/footer";

import SamaDocument from "../Components/samaDoc";

class SamaDoc extends Component {
  render() {
    return (
      <>
        <NavBar />

        <SamaDocument />

        <Footer />
      </>
    );
  }
}

export default SamaDoc;
