import React, { Component } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Autocomplete from "../SamaViews/NetworkViews/Autocomplete";
import Spinner from "react-bootstrap/Spinner";
import settings from "../helpers/Settings";
let { KayanURL } = settings;

class KareemUpdatePrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payer_id: null,
      endWait: false,
      patient_share: null,
      payer_id:this.props.payer_id,
      network_name:this.props.network_name,
      activity_type:this.props.activity_type,
      activity_code:this.props.activity_code,
      gross:this.props.gross,
      patient_share:this.props.patient_share,
      payerId:false,
      
    };
    this.handleInputTextChange = this.handleInputTextChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onScelectActivityType = this.onScelectActivityType.bind(this);
    this.onScelectNetworkName = this.onScelectNetworkName.bind(this);
    this.onSelectPayer = this.onSelectPayer.bind(this);
  }

  componentDidMount = async () => {
    // console.log(this.props.payer_id)
     if(this.props.activity_type){
      this.setState({activityType:true})
    this.getAllActivitiesSuggestion(this.props.activity_type);
     }else{
      this.setState({activityType:false})
     }
     if(this.props.payer_id){
      // this.setState({networkName:true})
      this.getAllNetworks(this.props.payer_id)
     }
    let payers = localStorage.getItem("payers");
    if (payers) {
      this.setState({ payers: JSON.parse(payers) });
    } else {
      this.getPayersList();
    }
  };
  async getPayersList() {
    let response = await axios.put(KayanURL + "api/KareemBenefits/getPayers", {
      apiToken: Cookies.get("KareemId"),
    });

    if (response) {
      let { data } = response;

      if (data && data.success) {
        let { payers } = data;

        this.setState({ payers });
        localStorage.setItem("payers", JSON.stringify(payers));
      }
    }
  }
  onChange = (e) => {
    const { suggestions } = this.state;
    // console.log(suggestions)
    const userInput = e.currentTarget.value;
    const filteredSuggestions = suggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value,
    });
  };

  onClick = (e) => {
    let code = e.currentTarget.innerText.split(":")[0];
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText,
      activity_code: code,
    });
  };

  onKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion],
      });
    } else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };
  async getAllNetworks(payer_id) {
    let response = await axios.put(
      KayanURL + "api/KareemBenefits/networks/getNetworksByPayerID",
      {
        data: payer_id,
        apiToken: Cookies.get("KareemId")
      }
    );
    // console.log(response)
    if (response) {
      let { data } = response;

      if (data.success) {
        this.setState({ Networks: data.Networks });
        //  this.setState({ Networks: data.Networks });
        localStorage.setItem("Networks", JSON.stringify(data.Networks));
      }
    }
  }
  onSelectPayer({ target: { name, value } }) {
    this.setState({ payer_id: value,payerId:true });
    this.getAllNetworks(value);
    
  }
  onScelectNetworkName({ target: { name, value } }) {
    this.setState({ network_name: value });
     
    let { Networks } = this.state;

    let selectedNetwork = Networks.filter((nw) => nw.id == value);

    if (selectedNetwork) {
      this.setState({ selectedNetwork });
    }
  }
 

  handleInputTextChange({ target: { name, value } }) {
    /*     let { selectedNetwork } = this.state;
    if (name == "patient_share" && selectedNetwork&&selectedNetwork.length>0) {

      let {patient_share_max_amount}=selectedNetwork
      if (value > patient_share_max_amount) {
        
      }
    } */
    this.setState({ [name]: value });
  }

  async onScelectActivityType({ target: { name, value } }) {
    this.setState({ activity_type: value});
    this.setState({ suggestions: undefined });
    await this.getAllActivitiesSuggestion(value);
  }

  async getAllActivitiesSuggestion(type) {
    // console.log(type)
    
    this.setState({ wait: true, endWait: true });
    let suggestions = [];

    let response = await axios.put(
      KayanURL + "api/KareemBenefits/getAllMasterActivities/",
      {
        apiToken: Cookies.get("KareemId"),
        type: type,
      }
    );

    if (response) {
      let { codes } = response["data"];
      if (codes && codes.length > 0) {
        codes.forEach((element) => {
          if (
            suggestions.indexOf(element.code + "-" + element.shortdesc) == -1
          ) {
            if (element.shortdesc === "null") {
              element.shortdesc = "";
            }

            suggestions.push(element.code + ":" + element.shortdesc);
          }
        });
      } else {
        suggestions = [];
      }
      this.setState({ suggestions, wait: false });
    }
  }
  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ sent: true });

    let { selectedNetwork } = this.state;

    if (selectedNetwork && selectedNetwork.length > 0) {
      let { patient_share_max_amount } = selectedNetwork[0];

      if (this.state.patient_share > patient_share_max_amount) {
        alert(
          `The entered patient share value exceeds the allowed value for the selected network (${patient_share_max_amount})`
        );
        this.setState({ sent: false });

        return;
      }
    }

    let response = await axios.put(
      KayanURL + "api/KareemBenefits/pricelist/updateActivity",
      {
        apiToken: Cookies.get("KareemId"),
        id:this.props.id,
        network_name: this.state.network_name,
        payer_id:this.state.payer_id,
        activity_type: this.state.activity_type,
        activity_code: this.state.activity_code,
        gross: this.state.gross,
        patient_share: this.state.patient_share,
      }
    );
    if (response) {
      let { data } = response;

      if (data.success) {
        this.setState({ success: true });
        this.props.getAllPriceLists();
      } else {
        this.setState({ success: false, error: data.error });
      }
    }

    this.setState({ sent: false });
  };

  render() {
    let { sent, Networks, payers,payerId } = this.state;
    let payersList;
    if (payers && payers.length > 0) {
      payersList = payers.map((payer) => {
        return (
          <option value={payer["eclaimlink_id"]}>
            {payer["eclaimlink_id"]}
          </option>
        );
      });
    }
    let NetworkList;
    let Newnetworks
    if(!payerId){
      if (Networks && Networks.length > 0) {
        for(let i=0;i<Networks.length;i++){
          if(this.props.network_name==Networks[i].network_name)
               Networks.splice(i,1)
        }
        Newnetworks = Networks.map((NW, index) => {
          return (
            <option value={NW["id"]} key={index}>
              {NW["network_name"]}
            </option>
          );
        });
      }
      
    }else{
      if (Networks && Networks.length > 0) {
        NetworkList = Networks.map((NW, index) => {
          return (
            <option value={NW["id"]} key={index}>
              {NW["network_name"]}
            </option>
          );
        });
      }
    }
    
    return (
      <>
        <div className="container">
          <form onSubmit={this.onSubmit}>
            <h5>Update Activity</h5>
            <select
              required
              onChange={this.onSelectPayer}
            >
              <option value={this.props.payer_id}>{this.props.payer_id}</option>
              {payersList}
            </select>
            {this.state.payer_id && this.state.payerId==false?(
              <select
                required
                onChange={this.onScelectNetworkName}
              >
                  <option value={this.props.network_name}>{this.props.network_name}</option>
              
                {Newnetworks}
              </select>
            ):this.state.payerId==true?(
            <select
                required
                onChange={this.onScelectNetworkName}
              >
                <option value="">select network_name</option>
              
                {NetworkList}
              </select>
            ):null}
            <select
              required
              onChange={this.onScelectActivityType}
              // value={this.props.activity_type ? this.props.activity_type : ""}
            >
              {/* {!this.state.endWait && this.state.activityType==false ? ( */}
                <option value={this.props.activity_type}>{this.props.activity_type}</option>
              {/* ) : <option value="">select</option>} */}
              <option value="cpt">CPT</option>
              <option value="hcpcs">HCPCS</option>
              <option value="dental">Dental</option>
              <option value="drug">Drug</option>
              <option value="service">Service</option>
            </select>

            <Autocomplete
              wait={this.state.wait}
              endWait={this.state.endWait}
              onChange={this.onChange}
              onClick={this.onClick}
              onKeyDown={this.onKeyDown}
              activeSuggestion={this.state.activeSuggestion}
              filteredSuggestions={this.state.filteredSuggestions}
              showSuggestions={this.state.showSuggestions}
              userInput={this.state.userInput}
              suggestions={this.state.suggestions}
              value={this.props.activity_code}
            />
            <br />
            <br />
            <input
              required
              onChange={this.handleInputTextChange}
              type="number"
              name="gross"
              defaultValue={this.props.gross}
              min="0"
              style={{ width: "70%", padding: "8px" }}
            />
            <br />
            <br />
            <input
              onChange={this.handleInputTextChange}
              type="number"
              name="patient_share"
              defaultValue={this.props.patient_share}
              min="0"
              placeholder="patient_share(optional)"
              style={{ width: "70%", padding: "8px" }}
            />

            <br />
            <input type="submit" value="update Activity" className="BS1" />
            <br />
            {sent ? (
              <Spinner animation="border" variant="success" />
            ) : this.state.success ? (
              <h5 style={{ color: "green" }}>Price was added sucessfully</h5>
            ) : this.state.error ? (
              <h5 style={{ color: "red" }}>{this.state.error}</h5>
            ) : null}
          </form>
        </div>
      </>
    );
  }
}

export default KareemUpdatePrice;
