import React, { Component } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Table from "react-bootstrap/Table";

import Spinner from "react-bootstrap/Spinner";
import cloneDeep from "lodash/cloneDeep";

import settings from "../../helpers/Settings";
import { text } from "body-parser";
let { KayanURL } = settings;

class UpdateEmployeeInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Employees: [],

      dataToUpdate: {},
    };
  }
  componentDidMount = async () => {
    const { history } = this.props;
    //get all employees of this company
    let res = await axios.put(KayanURL + "api/sama/employee/getEmployee", {
      apiToken: Cookies.get("SamaCompanyId"),
    });

    if (res) {
      let { data } = res;

      if (data && data.success) {
        this.setState({ Employees: data.Employees });

        let selectedEmployee = data.Employees.filter(
          (emp) => emp.id == this.state.EmployeeId
        );

        this.setState({ selectedEmployee: selectedEmployee[0] });
      }
    }
  };

  handleSelectChange = (e) => {
    e.preventDefault();

    let { Employees } = this.state;
    let EmployeeId = e.target.value;
    this.setState({ EmployeeId });

    let selectedEmployee = Employees.filter((emp) => emp.id == EmployeeId);

    this.setState({ display: true, selectedEmployee: selectedEmployee[0] });
  };
  onSelectFieldName = (e) => {
    this.setState({ value: "" });
    let fieldName = e.target.value;

    if (!this.state.newPrivileges && fieldName == "allowedPrivileges") {
      let privi = cloneDeep(this.state.selectedEmployee["privileges"]);

      this.setState({ newPrivileges: privi });
    }

    let type = "";
    switch (fieldName) {
      case "password":
      case "dhpopassword":
        type = "password";
        break;
      case "active":
        type = "checkbox";
        break;

      case "allowedPrivileges":
        type = "editPriviligesComponent";
        break;
      default:
        type = "text";
        break;
    }

    this.setState({ fieldName, type, show: true });
  };

  handleInputTextChange = (e) => {
    this.setState({ value: e.target.value });
    this.setState({
      dataToUpdate: {
        ...this.state.dataToUpdate,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleCheckBox = (e) => {
    this.setState({
      dataToUpdate: {
        ...this.state.dataToUpdate,
        [e.target.name]: e.target.checked,
      },
    });
  };

  handlePrivilegesCheckBoxChange = (e) => {
    let { newPrivileges } = this.state;
    newPrivileges[e.target.name] = e.target.checked;
    this.setState({
      dataToUpdate: {
        ...this.state.dataToUpdate,
        privileges: this.state.newPrivileges,
      },
    });
  };

  update = async (e) => {
    e.preventDefault();
    let { selectedEmployee } = this.state;
    this.setState({ finished: false });
    let { dataToUpdate } = this.state;
    document.getElementById("fields").value = "";

    if (Object.keys(dataToUpdate).length > 0) {
      dataToUpdate["apiToken"] = Cookies.get("SamaCompanyId");
      dataToUpdate["employeeId"] = this.state.EmployeeId;
      let result = await axios.put(
        KayanURL + "api/sama/employee/updateEmployee",
        dataToUpdate
      );

      let { data } = result;
      let { success, error } = data;
      if (success) {
        let keys = Object.keys(dataToUpdate);
        keys.forEach((k) => {
          selectedEmployee[k] = dataToUpdate[k];
        });
      }
      this.setState({
        success,
        error,
        finished: true,
        type: "text",
        fieldName: "name",
        dataToUpdate: {},

        show: false,
      });

      this.componentDidMount();
    } else {
      alert("Change one value at least");
    }
  };

  editPriviligesComponent = (e) => {
    let { selectedEmployee } = this.state;
    let { privileges } = selectedEmployee;

    let allowedPrivileges = [];
    if (privileges && Object.keys(privileges.length > 0)) {
      allowedPrivileges = Object.keys(privileges).map((key, index) => {
        return (
          <div>
            <input
              type="checkbox"
              name={key}
              onChange={this.handlePrivilegesCheckBoxChange}
              defaultChecked={privileges[key]}
            />
            {this.mappingPrivilegeName(key)}
          </div>
        );
      });
    }

    return allowedPrivileges;
  };

  mappingPrivilegeName(key) {
    let text = "";

    switch (key) {
      case "edit":
        text = "Edit";
        break;
      case "delete":
        text = "Delete";
        break;

      case "submission":
        text = "Submission";
        break;
      case "askForReview":
        text = "Ask For Review";
        break;

      case "localTesting":
        text = "Local Testing";
        break;

      case "markReadyToBeSubmitted":
        text = "Mark as ready to be submitted";
        break;

      default:
        text = key;
        break;
    }

    return text;
  }

  render() {
    let {
      Employees,
      display,
      selectedEmployee,
      EditScreen,
      success,
      error,
      finished,
      show,
    } = this.state;

    let options = [];
    if (Employees && Employees.length > 0) {
      options = Employees.map((e, index) => {
        return (
          <option key={index} value={e.id}>
            {e.name}
          </option>
        );
      });
    }

    let allowedPrivileges = [];
    if (selectedEmployee) {
      let { privileges } = selectedEmployee;
      if (privileges && Object.keys(privileges).length > 0) {
        allowedPrivileges = Object.keys(privileges).map((key, index) => {
          if (privileges[key]) {
            return <p key={index}>{this.mappingPrivilegeName(key)}</p>;
          }
        });
      }
    }

    return (
      <>
        <div className="container">
          {EditScreen ? (
            <div className="popup">
              <div
                className="form"
                style={{
                  width: "50%",
                  margin: "auto",
                  backgroundColor: "white",
                  paddingTop: "10px",
                }}
              >
                <select onChange={this.onSelectFieldName} id="fields">
                  <option value="">Select</option>
                  <option value="name">Employee Name</option>
                  <option value="username">Username</option>
                  <option value="password">Password</option>
                  <option value="dhpouser">DHPO User</option>
                  <option value="dhpopassword">DHPO Password</option>
                  <option value="active">Status</option>
                  <option value="allowedPrivileges">Allowed Privileges</option>
                </select>

                {show ? (
                  this.state.type == "checkbox" ? (
                    <div>
                      <input
                        type={this.state.type}
                        name={this.state.fieldName}
                        placeholder={this.state.fieldName}
                        onChange={this.handleCheckBox}
                        /*    value={this.state.value} */
                        defaultChecked={selectedEmployee["active"]}
                      />
                      De/Activate
                    </div>
                  ) : this.state.type == "editPriviligesComponent" ? (
                    this.editPriviligesComponent()
                  ) : (
                    <input
                      type={this.state.type}
                      name={this.state.fieldName}
                      placeholder={this.state.fieldName}
                      onChange={this.handleInputTextChange}
                      value={this.state.value}
                      style={{ width: "70%", padding: "8px" }}
                    />
                  )
                ) : null}
                <br />
                <br />
                <input
                  type="submit"
                  value="update"
                  onClick={this.update}
                  className="BS1"
                />

                {finished ? (
                  success ? (
                    <h6>Information was updated successfully</h6>
                  ) : (
                    <h6 style={{ color: "red" }}>{error}</h6>
                  )
                ) : null}
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "right",
                    cursor: "pointer",
                    margin: "0 2%",
                  }}
                  onClick={() =>
                    this.setState({
                      EditScreen: false,
                      type: "text",
                      fieldName: "name",
                      dataToUpdate: {},

                      show: false,
                    })
                  }
                >
                  {" "}
                  Close{" "}
                </p>
              </div>
            </div>
          ) : null}

          <form style={{ marginTop: "-250px" }}>
            <select
              value={this.state.claim_status}
              onChange={(e) => this.handleSelectChange(e)}
            >
              <option>Employee Name</option>
              {options}
            </select>

            {/*  <input
              type="submit"
              onClick={this.getEmployeeInfo}
              value="Display Employee INFO"
            /> */}
          </form>

          {display ? (
            <div>
              <Table
                responsive
                striped
                bordered
                hover
                size="sm"
                onDoubleClick={() => {
                  this.setState({ EditScreen: true });
                }}
              >
                <thead style={{ background: "#3ab449", color: " white" }}>
                  <tr>
                    <th>Employee Name</th>
                    <th>Username</th>
                    <th>DHPO user</th>
                    <th>DHPO password</th>
                    <th>Status</th>
                    <th>Allowed Privileges</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{selectedEmployee["name"]}</td>
                    <td>{selectedEmployee["username"]}</td>
                    <td>{selectedEmployee["dhpouser"]}</td>
                    <td>{selectedEmployee["dhpopassword"]}</td>
                    {selectedEmployee["active"] ? (
                      <td>Active</td>
                    ) : (
                      <td>Not Active</td>
                    )}

                    <td>{allowedPrivileges}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export default UpdateEmployeeInfo;
