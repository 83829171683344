import React, { Component } from "react";
import settings from "../helpers/Settings";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import Cookies from "js-cookie";
import axios from "axios";
import Form from "../Components/Form/index";
import XML from "xml";
import Button from "../Components/Button";
import ListView from "../Components/ListView";
let { KayanURL } = settings;

var parseString = require("xml2js").parseString;

const initialState = {
  flagTemplate: false,
  flag: false, //Bayan flag
  flagClaim: false, //claim flag
  BayanHeader: [], //header for bayan response
  ClaimHeader: [], //header for claim response
  rows: [], //rows for bayan response
  rowsClaim: [], //rows for claim response

  status: "", //success status
  status2: "", //bayan status
  ClaimStatus: "", //claim status
  fileName: "",
  Msg: "",

  SyntaxErrorStatus: "",
  flagSyntaxError: false,
  encounters: [],
  DiagnosisCodes: [],
  ActivityCodes: [],
  Activity: [],
  Diagnosis: [],

  firstTime: true,

  data: {},
};
const initialStateTables = {
  flagTemplate: false,
  flag: false, //Bayan flag
  flagClaim: false, //claim flag
  BayanHeader: [], //header for bayan response
  ClaimHeader: [], //header for claim response
  rows: [], //rows for bayan response
  rowsClaim: [], //rows for claim response

  status: "", //success status
  status2: "", //bayan status
  ClaimStatus: "", //claim status
  fileName: "",
  Msg: "",

  SyntaxErrorStatus: "",
  flagSyntaxError: false,
};

class KareemForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payersName: [],
      payersIDs: [],

      providerIDs: [],
      NetworksNames: [],
      NetworksValues: [],
      MemberID: "",
      PayerID: "",
      ProviderID: "",
      EmiratesIDNumber: "",
      Gross: "",
      PatientShare: "",
      Net: "",
      sent: false,
      Records: [],
      StHistory: false,
      companyType: "", //to show denial for payer and hide denial for bayer
      activeSuggestion: 0, // The active selection's index
      filteredSuggestions: [], // The suggestions that match the user's input
      showActivitySuggestions: false, // Whether or not the suggestion list is shown
      showDiagnosisSuggestions: false,
      // What the user has entered
      userInputActivity: "",
      userInputDiagnosis: "",

      flagTemplate: false,

      //common
      isChoised: false,
      data: {},
      serverError: null,
      dataRetrieved: null,
      inputsHaveErrors: null,
      error: null,
      username: "",
      name: null,

      // Template section
      code: {},
      patient: {},
      diagnosis: {},

      addencounters: false,
      firstTime: true,
      DiagnosisCodes: [],
      ActivityCodes: [],
      encounters: [],
      BtnActive: false,
      isPrincipalChecked: false,
      //uploading:false,
      requestXML: null,
      flag: false, //Bayan flag
      flagClaim: false, //claim flag
      BayanHeader: [], //header for bayan response
      ClaimHeader: [], //header for claim response
      rows: [], //rows for bayan response
      rowsClaim: [], //rows for claim response
      //type:1,
      status: "", //success status
      status2: "", //bayan status
      ClaimStatus: "", //claim status
      fileName: "",
      Msg: "",
      SyntaxErr: [], //related to syntax error in file
      SyntaxErrorStatus: "",
      flagSyntaxError: false,
      //Progress Bar
      diagnosisNow: 0,
      ActivityNow: 0,
    };
    this.handleChangeChk = this.handleChangeChk.bind(this);
  }
  handleChangeChk() {
    this.setState({ StHistory: !this.state.StHistory });
  }
  renderDiagnosisProgressBar() {
    let { diagnosisNow, diagnosisItems } = this.state;

    setTimeout(() => {
      this.setState(
        {
          diagnosisNow:
            !diagnosisItems && diagnosisNow < 100 ? diagnosisNow + 20 : 100,
        },
        this.renderDiagnosisProgressBar()
      );
    }, "800");
  }
  getAllDiagnosis = () => {
    let diagnosisItems = [];
    this.renderDiagnosisProgressBar();

    /*  axios
      .get("http://localhost:4000/" + "api/getAllDiagnosis") */

    axios
      .get("https://www.kayan-healthcare.com/" + "api/getAllDiagnosis")
      .then(({ data }) => {
        if (data) {
          data.forEach((element) => {
            if (element.shortdesc === "null") {
              element.shortdesc = "";
            }
            diagnosisItems.push(element.code + "-" + element.shortdesc);
          });

          this.setState({ diagnosisItems });
        }
      })
      .catch(() => {
        this.setState({ serverError: "There are an server error" });
      });
  };

  renderActivityProgressBar() {
    let { ActivityNow, ActivityItems } = this.state;

    setTimeout(() => {
      this.setState(
        {
          ActivityNow: !ActivityItems ? ActivityNow + 20 : 100,
        },
        this.renderActivityProgressBar()
      );
    }, "800");
  }

  getAllActivity = (v) => {
    let ActivityItems = [];
    this.renderActivityProgressBar();
    console.log(this.state.ProviderID);
    let ProviderID = this.state.ProviderID;

    let city = "";
    if (ProviderID && ProviderID.includes("DHA") && v && v == "3") {
      city = "dubai";
    }
    axios
      .put("https://www.kayan-healthcare.com/" + "api/getAllActivity/" + v, {
        city,
      })
      .then(({ data }) => {
        if (data) {
          data.forEach((element) => {
            if (
              ActivityItems.indexOf(element.code + "-" + element.shortdesc) ==
              -1
            ) {
              if (element.shortdesc === "null") {
                element.shortdesc = "";
              }

              ActivityItems.push(element.code + "-" + element.shortdesc);
            }
          });

          this.setState({ ActivityItems });
        }
      })
      .catch(() => {
        this.setState({ serverError: "There are an server error" });
      });
  };

  componentDidMount = () => {
    // this.getEmployeeInfo();

    this.getAllDiagnosis();
    let providerIDString = localStorage.getItem("UserLicenses");

    let providerIDs = [];
    if (providerIDString) {
      providerIDs = providerIDString.split(",");
      this.setState({ providerIDs });
    }

    let payers = JSON.parse(localStorage.getItem("payers"));

    if (!payers || (payers && payers.length == 0)) {
      this.getPayersList();
    } else {
      let payersName = [];
      let payersIDs = [];
      /* JSON.parse(payers). */ payers.forEach((p) => {
        payersName.push(p["payer_name"]);
        payersIDs.push(p["eclaimlink_id"]);
      });
      this.setState({
        payers /* : JSON.parse(payers) */,
        payersName,
        payersIDs,
      });
    }

    let Networks = localStorage.getItem("Networks");

    if (Networks) {
      let NetworksNames = [
        /*  "ALL",
        "C1",
        "C2",
        "B1",
        "B2",
        "A2",
        "NW1",
        "NW2",
        "NW5", */
      ];
      let NetworksValues = [
        /*   "ALL",
        "C1",
        "C2",
        "B1",
        "B2",
        "A2",
        "NW1",
        "NW2",
        "NW5", */
      ];
      JSON.parse(Networks).forEach((n) => {
        let { network_name, id } = n;
        NetworksNames.push(network_name);
        NetworksValues.push(id);
      });

      this.setState({
        NetworksNames,
        NetworksValues,
      });
    } else {
      this.getAllNetworks();
    }
  };

  async getAllNetworks() {
    this.setState({ flag: true });
    let response = await axios.put(
      KayanURL + "api/KareemBenefits/networks/getAllNetworks",
      {
        apiToken: Cookies.get("KareemId"),
      }
    );

    if (response) {
      let { data } = response;
      if (data.success) {
        this.setState({ Networks: data.Networks, flag: false });
        localStorage.setItem("Networks", JSON.stringify(data.Networks));
      } /*  else {
        let { error } = data;
        if (error) {
          if (Array.isArray(error) && error.length > 0) {
            this.setState({ error: error[0] });
          } else {
            this.setState({ error: error });
          }
        }
      } */
    }
  }

  async getPayersList() {
    let response = await axios.put(KayanURL + "api/KareemBenefits/getPayers", {
      apiToken: Cookies.get("KareemId"),
    });

    if (response) {
      let { data } = response;
      if (data && data.success) {
        let { payers } = data;

        this.setState({ payers: payers });

        localStorage.setItem("payers", JSON.stringify(payers));

        let payersName = [];
        let payersIDs = [];
        payers.forEach((p) => {
          payersName.push(p["payer_name"]);
          payersIDs.push(p["eclaimlink_id"]);
        });
        this.setState({
          payersName,
          payersIDs,
        });
      }
    }
  }

  // Read XML File content
  readXMLFile = (reader) => {
    // To Do: 1- Handling error (reader.onerror); 2- clear Input file for each change on input file.
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ requestXML: reader.result });
      }
    };
  };
  // Handling Select Actions.
  onSelectOption = (e) => {
    let select = document.getElementById(e);
    let name = select.name;
    let value = select.options[select.selectedIndex].value;
    switch (name) {
      case "Source":
        this.setState({ ActivityItems: [], ActivityNow: 0 });

        switch (value) {
          case "3":
            var attributes = [
              "code",
              "Start",
              "Quantity",
              "Activity_NET",

              "PriorAuthorizationID",
            ];
            break;
          case "4":
            var attributes = [
              "code",
              "Start",
              "Quantity",
              "Activity_NET",
              "PriorAuthorizationID",
            ];
            break;
          case "9":
            var attributes = [
              "code",
              "Start",
              "Quantity",
              "Activity_NET",
              "PriorAuthorizationID",
            ];
            break;
          case "8":
            var attributes = [
              "code",
              "Start",
              "Quantity",
              "Activity_NET",
              "PriorAuthorizationID",
            ];
            break;
          case "6":
            var attributes = [
              "code",
              "Start",
              "Quantity",
              "ToothNumber",
              "Activity_NET",
              "PriorAuthorizationID",
            ];
            break;

          case "5":
            var attributes = [
              "code",
              "Start",
              "Quantity",
              "Activity_NET",
              "PriorAuthorizationID",
            ];
            break;
        }
        if (value && value !== "0") {
          // this.getAllPreferedActivity(value);
          this.getAllActivity(value);
        }

        this.setState({
          attributes,
          source: value,
          code: { ...this.state.code, [name]: value },
          BtnActive: true,
          section: "Activity",
        });

        break;

      case "PatientGender":
        this.setState({ patient: { ...this.state.patient, [name]: value } });
        break;

      case "EncounterType":
        this.setState({ patient: { ...this.state.patient, Type: value } });
        break;

      case "DiagnosisType":
        this.setState({ code: { ...this.state.code, [name]: value } });
        break;

      case "PayerID":
        this.setState({ PayerID: value });
        break;
      case "ProviderID":
        this.setState({ ProviderID: value });
        break;

      case "NetworkID":
        this.setState({ NetworkID: value });
        break;
      default:
        this.setState({ data: { ...this.state.data, [name]: value } });
        break;
    }
  };

  onSelectOperation = (e) => {
    this.setState({ isAdded: false, existInDb: false });
    let select = document.getElementById(e);
    let name = select.name;

    let value = select.options[select.selectedIndex].value;

    switch (value) {
      case "add":
        this.setState({ preferedCodesOperation: value });

        break;
      case "delete":
        this.setState({ preferedCodesOperation: value });
        break;
      default:
        this.setState({ preferedCodesType: value });

        break;
    }

    if (value != "icd") {
      //make a request to get codes
      let activityType = "";
      switch (value) {
        case "cpt":
          activityType = 3;
          break;
        case "dental":
          activityType = 6;

          break;
        case "hcpcs":
          activityType = 4;
          break;

        default:
          break;
      }
      this.getAllActivity(activityType);
      this.getAllPreferedActivity(activityType);
    }
  };

  handleInputTextChange = ({ target: { name, value, files } }) => {
    //NETw
    switch (name) {
      case "MemberID":
        this.setState({ MemberID: value });
        break;
      case "PayerID":
        this.setState({ PayerID: value });
        break;
      case "ProviderID":
        this.setState({ ProviderID: value });
        break;
      case "EmiratesIDNumber":
        this.setState({ EmiratesIDNumber: value });
        break;
      case "Gross_AED":
        this.setState({ Gross: value });
        break;
      case "PatientShare_AED":
        this.setState({ PatientShare: value });
        break;
      case "Net_AED":
        this.setState({ Net: value });
        break;

      case "PatientId":
      case "BirthDate":
      case "Weight":
        this.setState({ patient: { ...this.state.patient, [name]: value } });
        break;

      case "requestFile":
        let reader = new FileReader();
        if (files.length > 0) {
          reader.readAsText(files[0]);
          this.readXMLFile(reader);
        }

        break;

      case "ActivityID":
      case "Quantity":
      case "ToothNumber":
        this.setState({ code: { ...this.state.code, [name]: value } });
        break;

      case "Start":
        var datetime = value.replace(/\-/g, "/");
        datetime = datetime.replace(/\T/g, " ");
        var datetimeArray = datetime.split(" ");
        var date = datetimeArray[0];
        var time = datetimeArray[1];
        //reverse date string
        var reverseDate = date.split("/").reverse().join("/");

        this.setState({
          code: { ...this.state.code, [name]: reverseDate + " " + time },
        });
        break;
      case "Activity_NET":
        this.setState({
          code: { ...this.state.code, [name]: value },
        });
        break;

      case "PriorAuthorizationID":
        this.setState({
          code: { ...this.state.code, [name]: value },
        });
        break;

      case "PatientAge":
        this.setState({ patient: { ...this.state.patient, [name]: value } });
        break;

      default:
        this.setState({ data: { ...this.state.data, [name]: value } });
        break;
    }
  };
  /// #2 For Code InputText
  onAddNewCodeInputChange = ({ target: { name, value } }, listName) => {
    switch (listName) {
      case "DiagnosisCodes":
        this.setState({
          section: "Diagnosis",
        });
        break;
      default:
        this.setState({
          section: "Activity",
        });
        break;
    }

    if (value === "") {
      this.setState({
        BtnActive: false,
      });
    } else if (listName === "DiagnosisCodes") {
      this.setState({
        attributes: ["Type"],
        code: { ...this.state.code, [name]: value },
        BtnActive: true,
      });
    } else {
      this.setState({
        code: { ...this.state.code, [name]: value },
        BtnActive: true,
      });
    }
  };

  // add code to codesLists
  addNewCode = (event, listName, inputId) => {
    event.preventDefault();
    // document.getElementById(inputId).value = ''

    this.setState({
      BtnActive: false,
    });
    let { DiagnosisCodes, ActivityCodes, code } = this.state;

    switch (listName) {
      case "DiagnosisCodes":
        if (!code.DiagnosisType && !this.state.seetingView) {
          code.DiagnosisType =
            document.getElementById("diagnosisType").options[
              document.getElementById("diagnosisType").options.selectedIndex
            ].value;
        }
        DiagnosisCodes.push(code);

        this.setState({
          DiagnosisCodes,
          code: {},
          data: { ...this.state.data, Diagnosis: DiagnosisCodes },
          userInputDiagnosis: "",
        });
        break;

      default:
        if (!code.Quantity && !this.state.seetingView) {
          code.Quantity = "1";
        }

        if (!code.Start && !this.state.seetingView) {
          /*  var date = new Date(); 
                date.setHours(date.getHours() + 3);
                
               // var isodate = date.toISOString().substr(0,16); */
          var isodate = document.getElementById("Start").value;
          var datetime = isodate.replace(/\-/g, "/");
          datetime = datetime.replace(/\T/g, " ");
          var datetimeArray = datetime.split(" ");
          var date = datetimeArray[0];
          var time = datetimeArray[1];
          //reverse date string
          var reverseDate = date.split("/").reverse().join("/");

          code.Start = reverseDate + " " + time;
        }
        console.log("push code");
        console.log(code);
        ActivityCodes.push(code);

        //reset the dropdown list
        if (!this.state.seetingView) {
          let select = document.getElementById("activitySource");
          select.options[0].selected = true;
          //
        }

        this.setState({
          ActivityCodes,
          code: {},
          data: { ...this.state.data, Activity: ActivityCodes },
          userInputActivity: "",
        });
        break;
    }
  };
  // Add new encounter
  addNewEncounter = (event) => {
    event.preventDefault();

    let { data, patient, encounters, Gross, Net, PatientShare, NetworkID } =
      this.state;
    if (Net || Gross || PatientShare) {
      let sum = parseFloat(PatientShare) + parseFloat(Net);

      if (parseFloat(Gross) != sum) {
        alert(
          "The vlaue of the claim gross should be equal the summation of the claim's net and patient share."
        );
      }
    }

    if (NetworkID && Gross) {
      //check if the claim gross amount is greater than the limit of the network

      let companyNetworks = localStorage.getItem("Networks");
      if (companyNetworks) {
        let allNetworks = JSON.parse(companyNetworks);
        if (allNetworks && allNetworks.length > 0) {
          let selectedNetwork = allNetworks.filter(
            (network) => network.id == NetworkID
          );

          if (
            selectedNetwork &&
            selectedNetwork.length > 0 &&
            parseFloat(Gross) > selectedNetwork[0]["authorizing_amount"]
          ) {
            alert(
              `According to the selected network, the amount of the claim needs to be authorized authorization after the amount of ${selectedNetwork[0]["authorizing_amount"]} AED.`
            );
          }
        }
      }
    }

    encounters = [];

    let { Diagnosis, Activity } = data;

    let encounter = {};

    encounter.ID = data.ClaimID;

    // Patient Data
    if (Object.keys(patient).length > 0) {
      let PatientData = [];
      let keys = Object.keys(patient);
      let values = Object.values(patient);
      keys.map((p, index) => PatientData.push({ [p]: values[index] }));
      encounter.Patient = PatientData;
      // this.setState({patient:{}})
    }

    // Diagnosis Data
    if (Diagnosis && Diagnosis.length > 0) {
      let DiagnosisTags = [];

      Diagnosis.forEach((d) => {
        let dText = d.Text; //delete d.Text;

        DiagnosisTags.push({ Type: d.DiagnosisType, Code: dText });

        encounter.Diagnosis = DiagnosisTags;
      });
      //this.setState({Diagnosis:[]})
    } else {
      alert("your request should contain at least one Diagnois");
    }

    // Activity Data
    if (Activity && Activity.length > 0) {
      let ActivityData = [];
      Activity.forEach((a) => {
        ActivityData.push({
          ID: a.ActivityID,
          Code: a.Text,
          Type: a.Source,
          Start: a.Start,
          Quantity: a.Quantity,
          NET: a.Activity_NET,
          ToothNumber: a.ToothNumber,
          PriorAuthorizationID: a.PriorAuthorizationID,
        });

        encounter.Activity = ActivityData;
      });
    } else {
      alert("your request should contain at least one Activity");
    }

    if (Activity && Activity.length > 0 && Diagnosis && Diagnosis.length > 0) {
      this.setState(initialStateTables);

      encounters.push({ Claim: encounter });
      this.setState({ encounters });
    }
  };

  handleRadioChange = ({ target: { name, value, files } }) => {
    this.setState({ type: value });
  };

  // Triggers
  /// #1 For redirecting to chosen Requesting method.
  onClickBtn = (e) => {
    this.setState({
      isChoised: true,
      requestType: e.target.value === "file upload" ? "file" : "template",
    });
  };

  /// #2 For allow user to add new encounter.
  onClickEncounterTrigger = () => {
    this.setState((prevState) => ({
      addencounters: !prevState.addencounters,
    }));
  };

  // DoRequest
  doRequest = async (event) => {
    event.preventDefault();
    this.setState({ error: null, dataRetrieved: null });
    this.setState({ upload: true }); //to view the upload sppiner
    this.setState({ status: "" }); //to remove the previous status from the view//Success Or Fail
    this.setState({ status2: "" });
    document.getElementById("uploading").scrollIntoView();
    this.setState({ ClaimStatus: "" });
    this.setState({ SyntaxErrorStatus: "" });
    this.setState({ flagSyntaxError: false });
    this.setState({ performanceStatus: "", performanceflag: false });
    this.setState({ rows: [], responseReady: false });

    //++++++++++++++
    // handling any request.
    let { requestType } = this.state;
    var request;
    let Header;
    const { encounters } = this.state;
    var encountersXML = [];
    let claim = [];
    // console.log(encounters);
    encounters.forEach((encounter) => {
      encountersXML = [];
      var charset = "abcdefghijklmnopqrstuvwxyz"; //from where to create
      var result = "";
      for (var i = 0; i < 3; i++)
        result += charset[Math.floor(Math.random() * charset.length)];
      //claim ID
      let str =
        result.toUpperCase() + "-" + Math.floor(Math.random() * 10000000);
      encountersXML.push({ ID: str });
      encountersXML.push({ MemberID: this.state.MemberID });
      encountersXML.push({ PayerID: this.state.PayerID });
      encountersXML.push({ ProviderID: this.state.ProviderID });
      encountersXML.push({ EmiratesIDNumber: this.state.EmiratesIDNumber });
      encountersXML.push({ Gross: this.state.Gross });
      encountersXML.push({ PatientShare: this.state.PatientShare });
      encountersXML.push({ Net: this.state.Net });
      encountersXML.push({ Encounter: encounter.Claim.Patient });
      if (encounter.Claim.Diagnosis && encounter.Claim.Diagnosis.length > 0) {
        encounter.Claim.Diagnosis.forEach((d) => {
          let code;
          if (d.Code === undefined) {
            code = "";
          } else {
            code = d.Code.split("-")[0];
          }
          encountersXML.push({
            Diagnosis: [{ Type: d.Type }, { Code: code }],
          });
        });
      }
      if (encounter.Claim.Activity && encounter.Claim.Activity.length > 0) {
        encounter.Claim.Activity.forEach((a) => {
          let code;
          if (a.Code === undefined) {
            code = "";
          } else {
            if (a.Type == "5") {
              let codeparts = a.Code.split("-");
              code = codeparts[0] + "-" + codeparts[1] + "-" + codeparts[2];
            } else {
              code = a.Code.split("-")[0];
            }
          }
          // console.log(a);
          if (a.Type === "6") {
            encountersXML.push({
              Activity: [
                { ID: Math.floor(Math.random() * 10000000) },
                { Code: code },
                { Type: a.Type },
                { Start: a.Start },
                { Quantity: a.Quantity },
                { Net: a.NET },
                {
                  Observation: [
                    { Value: a.ToothNumber },
                    { ValueType: "ToothNumber" },
                    { Code: "UniversalDental" },
                    { Type: "UniversalDental" },
                  ],
                },
              ],
            });
          } else {
            encountersXML.push({
              Activity: [
                { ID: Math.floor(Math.random() * 10000000) },
                { Code: code },
                { Type: a.Type },
                { Start: a.Start },
                { Quantity: a.Quantity },
                { Net: a.NET },
                { PriorAuthorizationID: a.PriorAuthorizationID },
              ],
            });
          }
        });
      }
      // let HeaderXml=[]
      // HeaderXml.push(
      //   {SenderID:this.state.SenderID},
      //   {ReceiverID:this.state.ReceiverID}
      // )
      // claim.push({ Header: HeaderXml });
      claim.push({ Claim: encountersXML });
      //clean all data when send the request

      this.setState({ encounters: [] });
    });
    request = XML({ "Claim.Submission": claim }, true);
    this.setState({ templateRequest: request });
    this.setState({ responseReady: false, sent: true, status: false });
    this.setState({ templateRequest: request });
    // console.log(request);
    const content = await axios.post(KayanURL + "api/kareem/benifits", {
      data: request,
      apiToken: Cookies.get("KareemId"),
      storeHistory: this.state.StHistory ? true : null,
      networkId: this.state.NetworkID,
    });

    let samaResponseXML = content.data;

    let Records = [];
    if (samaResponseXML.success == false) {
      let { error } = samaResponseXML;

      this.setState({
        responseReady: true,
        sent: false,
        status: true,
        error,
      });
    } else {
      parseString(samaResponseXML, function (err, result) {
        if (result) {
          let Response_Status = result["Response-Status"];
          let { Status } = Response_Status;

          if (Status && Status.length > 0 && Status[0] == "Failed") {
            let Request_Errors = Response_Status["Request-Errors"][0]["Claim"];
            //let Records = []
            Request_Errors.forEach((claimErrors) => {
              let EncounterId = claimErrors["EncounterId"][0];
              let Line = claimErrors["Line"][0];

              //   console.log(claimErrors);

              let SchemaErrorsArray = claimErrors["Schema-Errors"];

              if (SchemaErrorsArray != undefined) {
                let SchemaErrors = SchemaErrorsArray[0];

                let schemaErrorsTypes = Object.keys(SchemaErrors);
                schemaErrorsTypes.forEach((type) => {
                  let ErrorsofThisType = SchemaErrors[type]; //array
                  ErrorsofThisType.forEach((err) => {
                    let ClaimId = err["ClaimId"][0];
                    let errorLine = err["Line"][0];
                    let Message = err["Message"][0];
                    let Type = "-";
                    let Level = "-";

                    Records.push({
                      ClaimId,
                      EncounterId,
                      claimLine: Line,
                      errorLine,
                      Message,
                      Type,
                      Level,
                    });
                  });
                });
              }

              let DataErrorsArray = claimErrors["Data-Errors"];

              if (DataErrorsArray) {
                let DataErrors = DataErrorsArray[0];

                if (DataErrors) {
                  let DataErrorsCodes = DataErrors["Code"];
                  if (DataErrorsCodes) {
                    DataErrorsCodes.forEach((err) => {
                      let ClaimId = err["ClaimID"] ? err["ClaimID"][0] : "-";
                      let errorLine = err["Line"] ? err["Line"][0] : "-";
                      let Message = err["Message"] ? err["Message"][0] : "-";
                      let Type = err["Type"] ? err["Type"][0] : "-";
                      let Level = err["Level"] ? err["Level"][0] : "-";

                      Records.push({
                        ClaimId,
                        EncounterId,
                        claimLine: Line,
                        errorLine,
                        Message,
                        Type,
                        Level,
                      });
                    });
                  }
                }
              }
            });
          }
        }
      });
      this.setState({
        Rows: Records,
        responseReady: true,
        sent: false,
        status: true,
      });
    }
  };

  // Event fired when the input value is changed
  onChange = ({ target: { name, value } }, listName) => {
    this.setState({ isAdded: false, existInDb: false });
    if (value.length <= 1) {
      value = value.trim();
    }

    switch (listName) {
      case "DiagnosisCodes":
        this.setState({
          section: "Diagnosis",
        });
        break;
      default:
        this.setState({
          section: "Activity",
        });
        break;
    }

    if (listName === "DiagnosisCodes" && value === "") {
      this.setState({
        BtnActive: false,
        userInputDiagnosis: "",
        userInputActivity: "",
      });
    } else if (listName === "ActivityCodes" && value === "") {
      this.setState({
        //BtnActive: false,
        userInputDiagnosis: "",
        userInputActivity: "",
      });
    } else if (listName === "DiagnosisCodes") {
      this.setState({ attributes: ["Type"], BtnActive: true });
      let suggestions;

      if (!this.state.seetingView) {
        if (
          this.state.PrefereddiagnosisItems &&
          this.state.PrefereddiagnosisItems.length > 0
        ) {
          suggestions = this.state.PrefereddiagnosisItems;
        } else {
          suggestions = this.state.diagnosisItems;
        }
      } else {
        let { preferedCodesOperation } = this.state;
        if (preferedCodesOperation == "delete") {
          suggestions = this.state.PrefereddiagnosisItems;
        } else {
          suggestions = this.state.diagnosisItems;
        }
      }

      let filteredSuggestions = [];

      if (value.length > 1) {
        const regex = new RegExp(`${value.toLowerCase()}`); //filter
        filteredSuggestions = suggestions
          .sort()
          .filter((v) => regex.test(v.toLowerCase()));
        if (filteredSuggestions && filteredSuggestions.length == 0) {
          suggestions = this.state.diagnosisItems;
          filteredSuggestions = suggestions
            .sort()
            .filter((v) => regex.test(v.toLowerCase()));
        }
      }

      // Update the user input and filtered suggestions, reset the active
      // suggestion and make sure the suggestions are shown
      this.setState({
        activeSuggestion: 0,
        filteredSuggestionsDiagnosis: filteredSuggestions,
        showDiagnosisSuggestions: true,
        userInputDiagnosis: value,
      });
    } else {
      let suggestions = [];

      this.setState({ BtnActive: true });
      if (!this.state.seetingView) {
        if (
          this.state.preferedActivityCodes &&
          this.state.preferedActivityCodes.length > 0
        ) {
          suggestions = this.state.preferedActivityCodes;
        } else {
          suggestions = this.state.ActivityItems;
        }
      } else {
        let { preferedCodesOperation } = this.state;
        if (preferedCodesOperation == "delete") {
          suggestions = this.state.preferedActivityCodes;
        } else {
          suggestions = this.state.ActivityItems;
        }
      }
      let filteredSuggestions = [];
      if (value.length > 1) {
        const regex = new RegExp(`${value.toLowerCase()}`);
        filteredSuggestions = suggestions
          .sort()
          .filter((v) => regex.test(v.toLowerCase()));

        if (filteredSuggestions && filteredSuggestions.length == 0) {
          suggestions = this.state.ActivityItems;
          filteredSuggestions = suggestions
            .sort()
            .filter((v) => regex.test(v.toLowerCase()));
        }
      }

      // Update the user input and filtered suggestions, reset the active
      // suggestion and make sure the suggestions are shown

      this.setState({
        activeSuggestion: 0,
        filteredSuggestionsActivity: filteredSuggestions,
        showActivitySuggestions: true,
        userInputActivity: value,
      });
    }
  };
  // Event fired when the user clicks on a suggestion
  onClick = (e) => {
    let code = e.currentTarget.innerText;

    // Update the user input and reset the rest of the state
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showDiagnosisSuggestions: false,
      showActivitySuggestions: false,
    });

    if (
      this.state.filteredSuggestionsActivity &&
      this.state.filteredSuggestionsActivity.length > 0
    ) {
      this.setState({
        // userInputActivity:this.state.filteredSuggestionsActivity[this.state.activeSuggestion]
        userInputActivity: e.currentTarget.innerText,
        filteredSuggestionsActivity: [],
      });
    }

    if (
      this.state.filteredSuggestionsDiagnosis &&
      this.state.filteredSuggestionsDiagnosis.length > 0
    ) {
      this.setState({
        attributes: ["Type"],

        userInputDiagnosis: e.currentTarget.innerText,
        filteredSuggestionsDiagnosis: [],
      });
    }

    //push to code
    this.setState({ code: { ...this.state.code, Text: code } });
  };
  downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([this.state.templateRequest], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "request.xml";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  handleItemDeletion = (item, listName, idx) => {
    if (listName === "DiagnosisCodes") {
      let diagnosis = this.state.DiagnosisCodes;
      //let diagnosis=this.state.data.Diagnosis

      const index = diagnosis.indexOf(item);
      if (index > -1) {
        diagnosis = diagnosis.filter(function (i) {
          return i !== item;
        });

        this.setState({
          DiagnosisCodes: diagnosis,
          data: { ...this.state.data, Diagnosis: diagnosis },
        });
      }
    } else {
      let activity = this.state.ActivityCodes;

      const index = activity.indexOf(item);
      if (index > -1) {
        activity = activity.filter(function (i) {
          return i !== item;
        });

        this.setState({
          ActivityCodes: activity,
          data: { ...this.state.data, Activity: activity },
        });
      }
    }
  };

  handleClaimDeletion = (item, index) => {
    let claims = this.state.encounters;

    // const index=claims.indexOf(item)
    if (index > -1) {
      claims = claims.filter(function (i, idx) {
        return idx !== index;
      });

      this.setState({
        encounters: claims,
      });
    }
  };
  handlingLinkAction1 = () => {
    this.setState({
      isChoised: false,
      requestType: undefined,
      seetingView: false,
      rulesUpdateStatus: false,
    });
    // window.location.reload(false);
    this.setState(initialState);
    this.setState({ encounters: [] });
  };
  handlingLinkAction2 = () => {
    let { isChoised, requestType } = this.state;

    if (isChoised && requestType === "template") {
      this.setState({
        requestType: "file",
      });
      //window.location.reload(false);
      this.setState(initialState);
      this.setState({ encounters: [] });
    } else if (isChoised && requestType === "file") {
      this.setState({
        requestType: "template",
      });
      this.setState(initialState);
      this.setState({ encounters: [] });
    } else if (requestType === "RulesMgt") {
      this.setState({
        requestType: "PrefCodesMgt",
      });
    } else if (requestType == "PrefCodesMgt") {
      this.setState({
        requestType: "RulesMgt",
      });
    }
  };

  handleClear = () => {
    this.setState(initialStateTables);
    this.setState({
      encounters: [],
      ActivityCodes: [],
      DiagnosisCodes: [],
      data: {},
    });
    this.setState({ responseReady: false });
    document.getElementById("PatientAge").value = "";
    document.getElementById("PatientGender").value = "";
    document.getElementById("EncounterType").value = "";
    document.getElementById("NetworkID").value = "";

    document.getElementById("activitySource").value = "";
    if (document.getElementById("activityCode")) {
      document.getElementById("activityCode").innerText = "";
    }

    if (document.getElementById("diagnosisCode")) {
      document.getElementById("diagnosisCode").innerText = "";
    }
    if (document.getElementById("Quantity")) {
      document.getElementById("Quantity").value = "";
    }
    if (document.getElementById("Net_AED")) {
      document.getElementById("Net_AED").value = "";
    }
    if (document.getElementById("ToothNumber")) {
      document.getElementById("ToothNumber").value = "";
    }
    if (document.getElementById("PayerID")) {
      document.getElementById("PayerID").value = "";
    }
    if (document.getElementById("ProviderID")) {
      document.getElementById("ProviderID").value = "";
    }
    if (document.getElementById("MemberID")) {
      document.getElementById("MemberID").value = "";
    }
    if (document.getElementById("EmiratesIDNumber")) {
      document.getElementById("EmiratesIDNumber").value = "";
    }
    if (document.getElementById("Gross_AED")) {
      document.getElementById("Gross_AED").value = "";
    }
    if (document.getElementById("PatientShare_AED")) {
      document.getElementById("PatientShare_AED").value = "";
    }
    if (document.getElementById("Net_AED")) {
      document.getElementById("Net_AED").value = "";
    }
  };
  onClickSeeting = (requestType) => {
    this.setState({
      seetingView: true,
      requestType,
      ActivityCodes: [],
      DiagnosisCodes: [],
    });
  };

  onChangeRulesStatus = (e) => {
    let checkBoxId = e.target.id;
    let rulesStatus = this.state.rulesStatus;
    rulesStatus[checkBoxId] = !rulesStatus[checkBoxId];
    this.setState({ rulesStatus });
  };

  handleNetworkIDTextChange = (e) => {
    this.setState({ NetworkID: e.target.value });
  };
  render() {
    let { Rows, responseReady } = this.state;
    let ClaimListTable;
    if (Rows && Rows.length > 0) {
      ClaimListTable = Rows.map((row, index) => {
        /*  row["Level"] = "Failed";
        console.log(row["Level"]); */
        return (
          <tr key={index}>
            <td>{row["ClaimId"]}</td>

            <td>{row["EncounterId"]}</td>
            <td>{row["claimLine"]}</td>
            <td>{row["errorLine"]}</td>
            <td style={{ textAlign: "left" }}>{row["Message"]}</td>

            <td>{row["Type"]}</td>
            {row["Level"] === "__" ? (
              <td>{row["Level"]}</td>
            ) : row["Level"] === "Review" ? (
              <td style={{ color: "#ffa500" }}>{row["Level"]}</td>
            ) : (
              <td style={{ color: "#ff0000" }}>{row["Level"]}</td>
            )}
          </tr>
        );
      });
    }

    const { companyType, fileName } = this.state;

    var fileNameCsv = fileName.split(".")[0] + "-report.csv";

    var BayanHeader;
    var ClaimHeader;
    if (companyType === "Provider") {
      BayanHeader = [
        { label: "Encounter Sequence", key: "EId" },
        { label: "Encounter Line", key: "ELine" },
        // { label: "Error Type", key: "ErrType" },
        { label: "Error Line", key: "ErrLine" },
        { label: "Error Message", key: "Msg" },
        { label: "Element Sequence", key: "CId" },
        { label: "Type", key: "Type" },

        { label: "Edit Level", key: "Level" },
      ];
      ClaimHeader = [
        { label: "Claim ID", key: "ClaimID" },
        { label: "Activity ID", key: "ActivityID" },
        { label: "Encounter Sequence", key: "EId" },
        { label: "Claim Line", key: "ELine" },
        { label: "Edit Line", key: "ErrLine" },
        { label: "Edit Message", key: "Msg" },
        { label: "Activity Code", key: "ActivityCode" },
        { label: "Diagnosis Code", key: "DiagnosisCode" },
        { label: "Type", key: "Type" },

        { label: "Level", key: "Level" },
      ];
    } else {
      BayanHeader = [
        { label: "Encounter Sequence", key: "EId" },
        { label: "Encounter Line", key: "ELine" },

        { label: "Error Line", key: "ErrLine" },
        { label: "Error Message", key: "Msg" },
        { label: "Element Sequence", key: "CId" },
        { label: "Type", key: "Type" },
        { label: "Denial", key: "Denial" },
        { label: "Edit Level", key: "Level" },
      ];
      ClaimHeader = [
        { label: "Claim ID", key: "ClaimID" },
        { label: "Activity ID", key: "ActivityID" },
        { label: "Encounter Sequence", key: "EId" },
        { label: "Claim Line", key: "ELine" },
        { label: "Edit Line", key: "ErrLine" },
        { label: "Edit Message", key: "Msg" },
        { label: "Activity Code", key: "ActivityCode" },
        { label: "Diagnosis Code", key: "DiagnosisCode" },

        { label: "Type", key: "Type" },
        { label: "Denial", key: "Denial" },
        { label: "Level", key: "Level" },
      ];
    }

    var listTable;
    if (this.state.rows.length > 0) {
      listTable = this.state.rows.map((row, index) => {
        return (
          <tr key={index}>
            <td>{row["EId"]}</td>
            <td>{row["ELine"]}</td>
            <td>{row["ErrLine"]}</td>
            <td style={{ textAlign: "left" }}>{row["Msg"]}</td>
            <td>{row["CId"]}</td>
            <td>{row["Type"]}</td>
            {companyType === "Provider" ? null : <td>{row["Denial"]}</td>}

            {row["Level"] === "__" ? (
              <td>{row["Level"]}</td>
            ) : row["Level"] === "Review" ? (
              <td style={{ color: "#ffa500" }}>{row["Level"]}</td>
            ) : (
              <td style={{ color: "#ff0000" }}>{row["Level"]}</td>
            )}
          </tr>
        );
      });
    }

    var SyntaxErrorListTable;
    if (this.state.SyntaxErr.length > 0) {
      SyntaxErrorListTable = this.state.SyntaxErr.map((row, index) => {
        return (
          <tr key={index}>
            <td>{row["Message"]}</td>
            <td>{row["Line"]}</td>
          </tr>
        );
      });
    }

    const {
      inputsHaveErrors,
      DiagnosisCodes,
      ActivityCodes,
      BtnActive,
      isPrincipalChecked,
      section,
      attributes,
      encounters,
      rulesStatus,
      error,
    } = this.state;
    let rulesNames;
    if (rulesStatus) {
      rulesNames = Object.keys(rulesStatus);
      //console.log(rulesNames);
    }

    return (
      <>
        <div className=" container">
          <br />
          <input type="checkbox" onChange={this.handleChangeChk} />
          <label
            style={{
              float: "none",
              color: "#28a745",
              marginLeft: "10px",
              fontSize: "large",
            }}
          >
            Store History
          </label>
          <Form
            formStyle="template"
            onChange={this.onChange}
            onClick={this.onClick}
            onKeyDown={this.onKeyDown}
            allDiagnosis={this.state.diagnosisItems}
            allActivity={this.state.ActivityItems}
            userInputDiagnosis={this.state.userInputDiagnosis}
            userInputActivity={this.state.userInputActivity}
            activeSuggestion={this.state.activeSuggestion}
            filteredSuggestionsActivity={this.state.filteredSuggestionsActivity}
            filteredSuggestionsDiagnosis={
              this.state.filteredSuggestionsDiagnosis
            }
            showDiagnosisSuggestions={this.state.showDiagnosisSuggestions}
            showActivitySuggestions={this.state.showActivitySuggestions}
            handleItemDeletion={this.handleItemDeletion}
            handleClear={this.handleClear}
            Style="Search"
            inputStyle="IT3"
            submitStyle="BS1"
            dangerStyle="dangerBS1"
            linkStyle="Link2"
            labelStyle="BayanLabelStyle"
            onSubmit={this.addNewEncounter}
            linkAction1={this.handlingLinkAction1}
            linkAction2={this.handlingLinkAction2}
            onNetworkIDChange={this.handleNetworkIDTextChange}
            onInputTextChange={this.handleInputTextChange}
            handleNewInputs={this.handleNewInputs}
            onAddNewCodeInputChange={this.onAddNewCodeInputChange}
            onSelectOption={this.onSelectOption}
            addNewCode={this.addNewCode}
            BtnActive={BtnActive}
            isPrincipalChecked={isPrincipalChecked}
            section={section}
            attributes={attributes}
            diagnosisNow={this.state.diagnosisNow}
            ActivityNow={this.state.ActivityNow}
            inputsTypes={[
              "section",
              "text",
              /* "networkIdText", */ "select",
              "select",
              "select",
              "text",
              "number",
              "number",
              "number",
              "section",
              "number",
              "select",
              "select",
              "section",
              "list",
              "section",
              "ActivityList",
              "link",
            ]}
            elementsValues={[
              "Claim Details",
              "",
              /* "", */ [this.state.NetworksNames, this.state.NetworksValues],
              [this.state.payersIDs, this.state.payersIDs],
              [this.state.providerIDs, this.state.providerIDs],
              "",
              [{ min: 0, step: "any" }],
              [{ min: 0 /* , max: "100", */, step: "any" }],
              [{ min: 0, step: "any" }],
              "Patient Information",
              [{ min: 0, step: "any" }],
              [
                ["Male", "Female"],
                ["M", "F"],
              ],
              [
                [
                  "No Bed + No emergency room(outpatient)",
                  "No Bed + Emergency room(outpatient)",
                  "Inpatient Bed + No emergency room",
                  "Inpatient Bed + Emergency room",
                  "Daycase Bed + No emergency room",
                  "Daycase Bed + Emergency room",
                  "Nationals Screening",
                  "New Visa Screening",
                  "Renewal Visa Screening",
                  "Home",
                  "Assisted Living Facility",
                  "Mobile Unit",
                  "Ambulance  Land",
                  "Ambulance  Air or Water",
                ],
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 12, 13, 15, 41, 42],
              ],
              "Diagnosis",
              DiagnosisCodes,
              "Activities",
              ActivityCodes,
              "clear",
              "Generate",
            ]}
            elementsNames={[
              "ClaimDetails",
              "MemberID",
              "NetworkID",

              "PayerID",
              "ProviderID",
              "EmiratesIDNumber",
              "Gross_AED",
              "PatientShare_AED",
              "Net_AED",
              "PatientInformation",
              "PatientAge",
              "PatientGender",
              "EncounterType",
              "Diagnosis",
              "DiagnosisCodes",
              "Activity",
              "ActivityCodes",
              "Clear",
            ]}
            inputsHaveErrors={inputsHaveErrors}
            label1="Go to "
            linkText1=" Home "
            label2="Or"
            linkText2=" File Upload? "
          />

          {encounters.length > 0 ? (
            <React.Fragment>
              <ListView
                Items={encounters}
                name="encounters"
                handleClaimDeletion={this.handleClaimDeletion}
              />
              <div className="BtnsContainer">
                <Button
                  Style="claimOrReq"
                  Value="submit the request"
                  onClick={(e) => this.doRequest(e)}
                />
              </div>
            </React.Fragment>
          ) : null}

          {error &&
          Array.isArray(error) &&
          error.length > 0 &&
          responseReady ? (
            <h5 style={{ color: "red" }}>{error[0]}</h5>
          ) : null}

          <div id="uploading">
            {!this.state.status && this.state.sent ? (
              <Spinner animation="border" variant="success" />
            ) : responseReady && Rows && Rows.length == 0 ? (
              <>
                <h4 style={{ color: "green" }}>
                  Your file uploaded successfully
                </h4>
                <button
                  onClick={this.downloadTxtFile}
                  style={{
                    backgroundColor: "#ffffff",
                    border: "none",
                    textDecoration: "underline",
                    marginBottom: "15px",
                  }}
                >
                  Click here to download the generated xml request
                </button>
                <h5 style={{ color: "green" }}>Free of Edits</h5>
              </>
            ) : responseReady && Rows && Rows.length > 0 ? (
              <>
                <h4 style={{ color: "green" }}>
                  Your file uploaded successfully
                </h4>
                <button
                  onClick={this.downloadTxtFile}
                  style={{
                    backgroundColor: "#ffffff",
                    border: "none",
                    textDecoration: "underline",
                    marginBottom: "15px",
                  }}
                >
                  Click here to download the generated xml request
                </button>
                <Table responsive striped bordered hover size="sm">
                  <thead style={{ background: "#3ab449", color: " white" }}>
                    <tr>
                      <th
                        style={{
                          verticalAlign: "middle",
                          paddingLeft: "30px",
                          paddingRight: "30px",
                        }}
                      >
                        Claim ID
                      </th>
                      <th style={{ verticalAlign: "middle", width: "1px" }}>
                        Encounter Sequence
                      </th>
                      <th style={{ verticalAlign: "middle" }}>Claim Line</th>
                      <th style={{ verticalAlign: "middle" }}>Edit Line</th>
                      <th style={{ verticalAlign: "middle" }}>Edit Message</th>
                      <th style={{ verticalAlign: "middle" }}>Type</th>
                      <th style={{ verticalAlign: "middle" }}>Level</th>
                    </tr>
                  </thead>
                  <tbody>{ClaimListTable}</tbody>
                </Table>
              </>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

export default KareemForm;
