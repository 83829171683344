import React from "react";
import Form from "../../Components/Form/index";
import axios from "axios";
import Cookies from "js-cookie";

import Spinner from "react-bootstrap/Spinner";

import settings from "../../helpers/Settings";
let { KayanURL } = settings;

var XLSX = require("xlsx");
class UploadNonAllowedPrincipalICDsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: NaN,

      usertype: this.props.usertype,
    };
  }

  readXlsxFile = (reader) => {
    // To Do: 1- Handling error (reader.onerror); 2- clear Input file for each change on input file.
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ requestXlsx: reader.result });
      }
    };
  };

  handleInputTextChange = ({ target: { name, value, files } }) => {
    this.setState({ responseReady: false });
    this.setState({ fileName: files[0].name });

    switch (name) {
      case "requestFile":
        const fileUpload = document.getElementById("requestFile");
        const regex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
        if (regex.test(fileUpload.value.toLowerCase())) {
          let fileName = fileUpload.files[0].name;
          if (typeof FileReader !== "undefined") {
            const reader = new FileReader();
            if (reader.readAsBinaryString) {
              reader.onload = (e) => {
                this.processExcel(reader.result);
              };
              reader.readAsBinaryString(fileUpload.files[0]);
            }
          } else {
            console.log("This browser does not support HTML5.");
          }
        } else {
          console.log("Please upload a valid Excel file.");
          alert("Please upload a valid Excel file.");
        }

        break;

      default:
        break;
    }
  };

  processExcel(data) {
    const workbook = XLSX.read(data, { type: "binary" });

    const firstSheet = workbook.SheetNames[0];
    const excelRows1 = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[firstSheet]
    );
    //console.log(excelRows1);
    let excelRows = JSON.stringify(excelRows1);
    this.setState({ excelRows1 });
  }

  doRequest = async (event) => {
    event.preventDefault();

    let { usertype } = this.state;
    this.setState({ responseReady: false, sent: true });

    const data = await axios.post(
      KayanURL + "api/sama/uploadNotAllowedPrincipalICDList",
      {
        data: this.state.excelRows1,

        apiToken:
          usertype == "company"
            ? Cookies.get("SamaCompanyId")
            : Cookies.get("SamaId"),
      }
    );

    if (data) {
      let result = data.data;
      if (result) {
        if (result.success) {
          this.setState({ success: true, sent: false, responseReady: true });
        } else {
          this.setState({
            success: false,
            sent: false,
            errors: result.errors,
            responseReady: true,
          });
        }
      }
    }
  };
  render() {
    let { responseReady, sent, success, errors } = this.state;
    let Errs = [];
    if (errors && responseReady && !sent && !success) {
      Errs = errors.map((err, index) => {
        return (
          <h6
            style={{ color: "red" }}
            key={index}
          >{`the row number(${err.row}) not inserted successfully, ${err["error"]}`}</h6>
        );
      });
    }

    return (
      <div className="container">
        Upload Not-Allowed Principal ICD List
        <Form
          submitStyle="BS10"
          labelStyle="BayanLabelStyle"
          onSubmit={this.doRequest}
          linkStyle="Link2"
          dangerStyle="BS1"
          onInputTextChange={this.handleInputTextChange}
          inputsTypes={["file"]}
          elementsValues={["file", "Upload ICD List"]}
          elementsNames={["requestFile"]}
        />
        {sent && !responseReady ? (
          <Spinner animation="border" variant="success" />
        ) : null}
        {responseReady && success ? (
          <h5 style={{ color: "green" }}>The data was uploaded successfully</h5>
        ) : responseReady ? (
          Errs
        ) : null}
      </div>
    );
  }
}

export default UploadNonAllowedPrincipalICDsForm;
