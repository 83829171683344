import React from "react";
import Form from "./../Components/Form/index";
import axios from "axios";

import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import Cookies from "js-cookie";

import settings from "../helpers/Settings";
let { KayanURL } = settings;

var parseString = require("xml2js").parseString;
class FileUploadSamaValidation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Records: [],
      NetworksNames: [],
      NetworksValues: [],
    };
    this.doRequest = this.doRequest.bind(this);
  }
  componentDidMount = () => {
    let Networks = localStorage.getItem("Networks");

    if (Networks) {
      let NetworksNames = [];
      let NetworksValues = [];
      JSON.parse(Networks).forEach((n) => {
        let { network_name, id } = n;
        NetworksNames.push(network_name);
        NetworksValues.push(id);
      });
      this.setState({
        NetworksNames,
        NetworksValues,
      });
    } else {
      this.getAllNetworks();
    }
  };
  async getAllNetworks() {
    let NetworksNames = [];
    let NetworksValues = [];
    this.setState({ flag: true });
    let response = await axios.put(
      KayanURL + "api/sama/networks/getAllNetworks",
      {
        apiToken: Cookies.get("SamaId"),
      }
    );

    if (response) {
      let { data } = response;
      if (data.success) {
        this.setState({ Networks: data.Networks, flag: false });
        localStorage.setItem("Networks", JSON.stringify(data.Networks));
        data.Networks.forEach((n) => {
          let { network_name, id } = n;
          NetworksNames.push(network_name);
          NetworksValues.push(id);
        });
        this.setState({
          NetworksNames,
          NetworksValues,
        });
      }
    }
  }

  // Read XML File content
  readXMLFile = (reader) => {
    // To Do: 1- Handling error (reader.onerror); 2- clear Input file for each change on input file.
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ requestXML: reader.result });
      }
    };
  };

  handleInputTextChange = ({ target: { name, value, files } }) => {
    this.setState({ responseReady: false, freeOFEdits: false });
    this.setState({ fileName: files[0].name });

    switch (name) {
      case "requestFile":
        let reader = new FileReader();
        if (files.length > 0) {
          reader.readAsText(files[0]);
          this.readXMLFile(reader);
        }

        break;

      default:
        break;
    }
  };

  doRequest = async (event) => {
    event.preventDefault();
    let freeOFEdits = false;
    this.setState({
      responseReady: false,
      sent: true,
    });

    const data = await axios.post(KayanURL + "api/sama/request/", {
      data: this.state.requestXML,
      apiToken: Cookies.get("SamaId"),
      networkId: this.state.NetworkID,
    });

    let samaResponseXML = data.data;
    let Records = [];
    parseString(samaResponseXML, function (err, result) {
      if (result) {
        let Response_Status = result["Response-Status"];
        let { Status } = Response_Status;

        if (Status && Status.length > 0 && Status[0] == "Failed") {
          let Request_Errors = Response_Status["Request-Errors"][0]["Claim"];
          Request_Errors.forEach((claimErrors) => {
            let EncounterId = claimErrors["EncounterId"][0];
            let Line = claimErrors["Line"][0];

            let SchemaErrorsArray = claimErrors["Schema-Errors"];

            if (SchemaErrorsArray != undefined) {
              let SchemaErrors = SchemaErrorsArray[0];

              let schemaErrorsTypes = Object.keys(SchemaErrors);
              schemaErrorsTypes.forEach((type) => {
                let ErrorsofThisType = SchemaErrors[type]; //array
                ErrorsofThisType.forEach((err) => {
                  let ClaimId = err["ClaimId"][0];
                  let errorLine = err["Line"][0];
                  let Message = err["Message"][0];
                  let Type = "-";
                  let Level = "-";

                  Records.push({
                    ClaimId,
                    EncounterId,
                    claimLine: Line,
                    errorLine,
                    Message,
                    Type,
                    Level,
                  });
                });
              });
            }

            let DataErrorsArray = claimErrors["Data-Errors"];

            if (DataErrorsArray) {
              let DataErrors = DataErrorsArray[0];

              if (DataErrors) {
                let DataErrorsCodes = DataErrors["Code"];
                if (DataErrorsCodes) {
                  DataErrorsCodes.forEach((err) => {
                    console.log(err);
                    let ClaimId = err["ClaimID"] ? err["ClaimID"][0] : "-";
                    let errorLine = err["Line"] ? err["Line"][0] : "-";
                    let Message = err["Message"] ? err["Message"][0] : "-";
                    let Type = err["Type"] ? err["Type"][0] : "-";
                    let Level = err["Level"] ? err["Level"][0] : "-";

                    Records.push({
                      ClaimId,
                      EncounterId,
                      claimLine: Line,
                      errorLine,
                      Message,
                      Type,
                      Level,
                    });
                  });
                }
              }
            }
          });
        } else if (Status && Status.length > 0 && Status[0] == "Success") {
          freeOFEdits = true;
        }
      }
    });
    this.setState({
      Rows: Records,
      responseReady: true,
      sent: false,
      freeOFEdits,
    });
    document.getElementById("requestFile").value = null;
    document.getElementById("NetworkID").value = "";
    this.setState({ NetworkID: "" });
  };
  onSelectOption = (e) => {
    let select = document.getElementById(e);
    let name = select.name;
    let value = select.options[select.selectedIndex].value;
    switch (name) {
      case "NetworkID":
        this.setState({ NetworkID: value });
        break;
      default:
        break;
    }
  };

  render() {
    let {
      Rows,
      responseReady,

      sent,
    } = this.state;
    let ClaimListTable;
    if (Rows && Rows.length > 0) {
      ClaimListTable = Rows.map((row, index) => {
        return (
          <tr key={index}>
            <td>{row["ClaimId"]}</td>

            <td>{row["EncounterId"]}</td>
            <td>{row["claimLine"]}</td>
            <td>{row["errorLine"]}</td>
            <td style={{ textAlign: "left" }}>{row["Message"]}</td>

            <td>{row["Type"]}</td>
            {row["Level"] === "__" ? (
              <td>{row["Level"]}</td>
            ) : row["Level"] === "Review" ? (
              <td style={{ color: "#ffa500" }}>{row["Level"]}</td>
            ) : (
              <td style={{ color: "#ff0000" }}>{row["Level"]}</td>
            )}
          </tr>
        );
      });
    }

    return (
      <div className="container">
        {/*   <Form
          submitStyle="BS10"
          labelStyle="BayanLabelStyle"
          onSubmit={this.doRequest}
          linkStyle="Link2"
          dangerStyle="BS10"
          onInputTextChange={this.handleInputTextChange}
          inputsTypes={["file", "select"]}
          elementsValues={[
            "file",
            [this.state.NetworksNames, this.state.NetworksValues],
            ,
            "sama test",
          ]}
          elementsNames={["requestFile", "NetworkID"]}
          onSelectOption={this.onSelectOption}
        /> */}
        <Form
          submitStyle="BS10"
          labelStyle="BayanLabelStyle"
          onSubmit={this.doRequest}
          linkStyle="Link2"
          dangerStyle="BS10"
          onInputTextChange={this.handleInputTextChange}
          onSelectOption={this.onSelectOption}
          inputsTypes={["file", "select"]}
          elementsValues={[
            "file",
            [this.state.NetworksNames, this.state.NetworksValues],
            ,
            "sama test",
          ]}
          elementsNames={["requestFile", "NetworkID"]}
        />

        {responseReady && !this.state.freeOFEdits ? (
          <Table responsive striped bordered hover size="sm">
            <thead style={{ background: "#3ab449", color: " white" }}>
              <tr>
                <th
                  style={{
                    verticalAlign: "middle",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                  }}
                >
                  Claim ID
                </th>
                <th style={{ verticalAlign: "middle", width: "1px" }}>
                  Encounter Sequence
                </th>
                <th style={{ verticalAlign: "middle" }}>Claim Line</th>
                <th style={{ verticalAlign: "middle" }}>Edit Line</th>
                <th style={{ verticalAlign: "middle" }}>Edit Message</th>
                <th style={{ verticalAlign: "middle" }}>Type</th>
                <th style={{ verticalAlign: "middle" }}>Level</th>
              </tr>
            </thead>
            <tbody>{ClaimListTable}</tbody>
          </Table>
        ) : this.state.freeOFEdits ? (
          <h4 style={{ color: "green" }}>Free of Edits</h4>
        ) : !responseReady && sent ? (
          <Spinner
            animation="border"
            variant="success"
            style={{ display: "block", margin: "auto" }}
          />
        ) : null}
      </div>
    );
  }
}

export default FileUploadSamaValidation;
