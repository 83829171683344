import React from "react";
import CheckBox from "./../Components/CheckBox";
import Spinner from "react-bootstrap/Spinner";
import Button from "./../Components/Button";

class ProcessManagementScreen extends React.Component {
  render() {
    let { activeProcess, isUpdated } = this.props;

    let ruleNames = [];
    //console.log(activeProcess);
    if (activeProcess) {
      ruleNames = Object.keys(activeProcess);
    }

    return (
      <div className="container">
        <div /* className="row" */>
          {ruleNames != undefined && ruleNames.length > 0 ? (
            ruleNames.map((rule) => {
              return (
                <CheckBox
                  key={rule}
                  text={
                    rule == "CPT_ICD" || rule == "cpt_icd" ? "CPT ICD" : rule
                  }
                  checked={activeProcess[rule]}
                  onChange={(e) => this.props.onChange(e)}
                />
              );
            })
          ) : (
            <Spinner
              animation="border"
              variant="success"
              style={{ display: "block", margin: "auto" }}
            />
          )}
        </div>

        <Button
          Value="update"
          onClick={(e) => this.props.onClick(e)}
          Style="updateBtn"
        />
      </div>
    );
  }
}

export default ProcessManagementScreen;
