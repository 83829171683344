import Input from "./Input";
import Select from "./Select";
import Checkbox from "./Checkbox";
import Button from "./Button";
import InputNumber from './InputNumber'
import Search from './Search'
import DateInput from "./DateInput";
const Controls = {
    Input,
    Select,
    Checkbox,
    Button,
    InputNumber,
    Search,
    DateInput
}

export default Controls;