import React, { Component } from "react";
import NavBar from "./../Components/navbar";
import Footer from "./../Components/footer";
import Header from "./../Components/Header";
import BayanLinks from "./../Components/bayanlinks";
import Cookies from "js-cookie";

class Bayan extends Component {
  render() {
    let id = Cookies.get("id");
    return (
      <>
        <NavBar userType={localStorage.getItem("type")} />
        <Header
          name={id ? localStorage.getItem("name") : null}
          usertype="employee"
          history={this.props.history}
        />
        <BayanLinks />
        <div style={{ height: "40px" }}></div>
        <Footer />
      </>
    );
  }
}

export default Bayan;
