module.exports=[{"code":"O90.1","shortdesc":"Disruption of perineal obstetric wound"},
{"code":"O91.019","shortdesc":"Infection of nipple associated w pregnancy, unsp trimester"},
{"code":"O91.212","shortdesc":"Nonpurulent mastitis assoc w pregnancy, second trimester"},
{"code":"O92.019","shortdesc":"Retracted nipple associated with pregnancy, unsp trimester"},
{"code":"O92.20","shortdesc":"Unsp disorder of breast assoc w pregnancy and the puerperium"},
{"code":"O98.011","shortdesc":"Tuberculosis complicating pregnancy, first trimester"},
{"code":"O98.112","shortdesc":"Syphilis complicating pregnancy, second trimester"},
{"code":"O98.212","shortdesc":"Gonorrhea complicating pregnancy, second trimester"},
{"code":"O98.32","shortdesc":"Oth infections w sexl mode of transmiss comp childbirth"},
{"code":"O98.43","shortdesc":"Viral hepatitis complicating the puerperium"},
{"code":"O98.62","shortdesc":"Protozoal diseases complicating childbirth"},
{"code":"O90.4","shortdesc":"Postpartum acute kidney failure"},
{"code":"O91.012","shortdesc":"Infection of nipple associated w pregnancy, second trimester"},
{"code":"O91.111","shortdesc":"Abscess of breast associated with pregnancy, first trimester"},
{"code":"O91.219","shortdesc":"Nonpurulent mastitis associated w pregnancy, unsp trimester"},
{"code":"O92.12","shortdesc":"Cracked nipple associated with the puerperium"},
{"code":"O94","shortdesc":"Sequelae of comp of pregnancy, chldbrth, and the puerperium"},
{"code":"O98.312","shortdesc":"Oth infect w sexl mode of transmiss comp preg, second tri"},
{"code":"O98.513","shortdesc":"Other viral diseases complicating pregnancy, third trimester"},
{"code":"O98.811","shortdesc":"Oth maternal infec/parastc diseases comp preg, first tri"},
{"code":"O98.93","shortdesc":"Unsp maternal infec/parastc disease comp the puerperium"},
{"code":"O99.03","shortdesc":"Anemia complicating the puerperium"},
{"code":"O91.02","shortdesc":"Infection of nipple associated with the puerperium"},
{"code":"O91.13","shortdesc":"Abscess of breast associated with lactation"},
{"code":"O92.012","shortdesc":"Retracted nipple associated with pregnancy, second trimester"},
{"code":"O92.113","shortdesc":"Cracked nipple associated with pregnancy, third trimester"},
{"code":"O92.4","shortdesc":"Hypogalactia"},
{"code":"O98.019","shortdesc":"Tuberculosis complicating pregnancy, unspecified trimester"},
{"code":"O98.12","shortdesc":"Syphilis complicating childbirth"}]