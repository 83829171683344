import React from "react";
import "./Codes.css";
import Button from "../Button";
import DropList from "../DropList";
import { TextField,InputLabel } from '@material-ui/core';
function defaultClick(e) {
    e.preventDefault();
  }
  
  function Search1(props) {
  
  
    let {
      Items,
      addNewCode,
      inputStyle,
      listName,
      onSelect,
      section,
      BtnActive,
      attributes,
      suggestions,
      onChange,
      onClick,
      userInputDiagnosis,
      activeSuggestion,
      filteredSuggestionsDiagnosis,
      showDiagnosisSuggestions,
      handleItemDeletion,
      diagnosisNow,
      name,
      index,
      className
    } = props;
    
  
  
    let Options;
  
  
  
    let suggestionsListComponentDiagnosis;
  
    if (showDiagnosisSuggestions && userInputDiagnosis) {
      if (filteredSuggestionsDiagnosis && filteredSuggestionsDiagnosis.length) {
        suggestionsListComponentDiagnosis = (
          <ul className="suggestions">
            {filteredSuggestionsDiagnosis.map((suggestion, index) => {
              let className;
  
              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }
  
              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponentDiagnosis = (
          <div className="no-suggestions">
            <em>No suggestions...</em>
          </div>
        );
      }
    }
  
    let InputId = "",
      BtnValue = "";
    if (listName === "DiagnosisCodes") {
      InputId = "DiagnosisCodes";
      BtnValue = "Confirm Code";
    }
  
  
    return (
      <React.Fragment>
        <InputLabel style={{fontWeight:"bold",color:"#212529"}}>Coverd ICDs</InputLabel>
        <TextField
        type="search"
            variant="outlined"
          name="coverd_icds"
          key={index}
          id={InputId}
          disabled={suggestions === undefined ? true : false}
          style={{marginBottom:"0.5rem",width:"100%"}}
          onChange={(e) => onChange(e,listName)} 
          hint="Search For ICD code"
          value={listName === "DiagnosisCodes"  ? userInputDiagnosis : null}
        />
  
  
        {listName === "DiagnosisCodes" ? suggestionsListComponentDiagnosis : null}
  
        {listName === "DiagnosisCodes" && section === "Diagnosis" && BtnActive ? (
  
          <React.Fragment>
            {attributes && attributes.length > 0
              ? attributes.map((attribute, index) => (
                <DropList
                  key={index}
                  id="diagnosisType"
                  Title="Diagnosis Type"
                  name="DiagnosisType"
                  Options={Options}
                  Values={Options}
                  defaultValue={Options[0]}
                  onSelect={onSelect}
                  Style="requestSelect"
                />
              ))
              : null}
          <Button
              Style="enabledStyle"
              Value={BtnValue}
              onClick={(e) => addNewCode(e, listName, InputId)}
            />
          </React.Fragment>
        ) : (
           null
          )}
      </React.Fragment>
    );
  }
export default Search1;