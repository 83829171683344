import React, { Component } from "react";
import NavBar from "../Components/navbar";
import Footer from "../Components/footer";

import SanadDocument from "../Components/sanadDoc";

class SanadDoc extends Component {
  render() {
    return (
      <>
        <NavBar />

        <SanadDocument />

        <Footer />
      </>
    );
  }
}

export default SanadDoc;
