import * as React from "react";
import Typography from "@mui/material/Typography";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
/////////////

import ActivityTable from "./ActivityTable";

export default function ActivityForm(props) {
  const [ignored, forceUpdate] = React.useReducer((x) => x + 1, 0);
  const [code, setCode] = React.useState(props.activityObj.activityCode);
  const [type, setType] = React.useState(props.activityObj.activityType);
  const [price, setPrice] = React.useState(props.activityObj.activityPrice);

  const [quantity, setQuantity] = React.useState(
    props.activityObj.activityQuantity
  );

  if (!type && props.activityObj.activityType) {
    setType(props.activityObj.activityType);
  }

  if (!code && props.activityObj.activityCode) {
    setCode(props.activityObj.activityCode);
  }

  if (!price && props.activityObj.activityPrice) {
    setPrice(props.activityObj.activityPrice);
  }

  if (!quantity && props.activityObj.activityQuantity) {
    setQuantity(props.activityObj.activityQuantity);
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Activity
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormControl required variant="standard" fullWidth>
            <InputLabel id="activityType-label">Activity Type</InputLabel>
            <Select
              required
              name="activityType"
              labelId="activityType-label"
              id="activityType"
              onChange={(e) => {
                props.onChange(e);
                forceUpdate();
              }}
              label="Activity Type"
              value={type ? type : ""}
            >
              <MenuItem value="SBS">SBS</MenuItem>
              <MenuItem value="SFDA">SFDA</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="activityCode"
            label="Activity Code"
            name="activityCode"
            fullWidth
            variant="standard"
            onChange={(e) => {
              props.onChange(e);
              forceUpdate();
            }}
            value={code ? code : ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            id="price"
            label="Price"
            name="activityPrice"
            fullWidth
            variant="standard"
            onChange={(e) => {
              props.onChange(e);
              forceUpdate();
            }}
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            value={price ? price : ""}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            required
            id="quantity"
            label="Quantity"
            name="activityQuantity"
            fullWidth
            variant="standard"
            onChange={(e) => {
              props.onChange(e);
              forceUpdate();
            }}
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            value={quantity ? quantity : ""}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={(e) => {
              props.onClickAddActivity(e);
              forceUpdate();
              setType("");
              setCode("");
              setPrice("");
              setQuantity("");
            }}
            sx={{ mt: 3, ml: 1 }}
          >
            Add Activity
          </Button>
        </Grid>

        {props.data &&
        props.data.AllActivity &&
        props.data.AllActivity.length > 0 ? (
          <ActivityTable
            AllActivity={props.data.AllActivity}
            deleteActivityElement={props.deleteActivityElement}
            deleteFlag={true}
          />
        ) : null}
      </Grid>
    </React.Fragment>
  );
}
