import React, { Component } from "react";

class AddNewEmployee extends Component {
  componentDidMount = () => {
    const { history } = this.props;
  };

  render() {
    return (
      <>
        <form onSubmit={this.props.onSubmit} style={{ marginTop: "-400px" }}>
          <input
            type="text"
            placeholder="Name"
            name="name"
            onChange={this.props.handleInputTextChange}
            required
            style={{ width: "70%", padding: "8px" }}
          />
          <br />
          <br />

          <input
            type="text"
            placeholder="Username"
            name="username"
            onChange={this.props.handleInputTextChange}
            required
            style={{ width: "70%", padding: "8px" }}
          />
          <br />
          <br />

          <input
            type="text"
            placeholder="City"
            name="city"
            onChange={this.props.handleInputTextChange}
            required
            style={{ width: "70%", padding: "8px" }}
          />

          <br />
          <br />

          <input
            type="password"
            placeholder="Employee Password"
            name="password"
            onChange={this.props.handleInputTextChange}
            required
            style={{ width: "70%", padding: "8px" }}
          />

          <br />
          <br />

          <input
            type="text"
            placeholder="DHPO User"
            name="dhpouser"
            onChange={this.props.handleInputTextChange}
            style={{ width: "70%", padding: "8px" }}
          />

          <br />
          <br />
          <input
            type="password"
            placeholder="DHPO Password"
            name="dhpopassword"
            onChange={this.props.handleInputTextChange}
            style={{ width: "70%", padding: "8px" }}
          />

          <br />

          <input
            type="submit"
            value="Add New Employee"
            className="BS1"
            /*  onSubmit={this.props.onSubmit} */
          />
        </form>
      </>
    );
  }
}

export default AddNewEmployee;
