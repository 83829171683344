import React, { Component } from "react";
import axios from "axios";
import Form from "./../Components/Form/index.js";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import Cookies from "js-cookie";
import settings from "../helpers/Settings";
import { CSVLink } from "react-csv";
import HomeFilters from "./sections/home/HomeFilters";

let { KayanURL } = settings;
var parseString = require("xml2js").parseString;

class KareemUploadClaim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sent: false,
      Records: [],
      StHistory: false,
      NetworksNames: [],
      NetworksValues: [],
    };
    this.handleChangeChk = this.handleChangeChk.bind(this);
  }

  componentDidMount = () => {
    let Networks = localStorage.getItem("Networks");

    if (Networks) {
      let NetworksNames = [];
      let NetworksValues = [];
      JSON.parse(Networks).forEach((n) => {
        let { network_name, id } = n;
        NetworksNames.push(network_name);
        NetworksValues.push(id);
      });
      this.setState({
        NetworksNames,
        NetworksValues,
      });
    } else {
      this.getAllNetworks();
    }
  };

  async getAllNetworks() {
    this.setState({ flag: true });
    let response = await axios.put(
      KayanURL + "api/KareemBenefits/networks/getAllNetworks",
      {
        apiToken: Cookies.get("KareemId"),
      }
    );

    if (response) {
      let { data } = response;
      if (data.success) {
        this.setState({ Networks: data.Networks, flag: false });
        localStorage.setItem("Networks", JSON.stringify(data.Networks));
      } /*  else {
        let { error } = data;
        if (error) {
          if (Array.isArray(error) && error.length > 0) {
            this.setState({ error: error[0] });
          } else {
            this.setState({ error: error });
          }
        }
      } */
    }
  }

  // Read XML File content
  readXMLFile = (reader) => {
    // To Do: 1- Handling error (reader.onerror); 2- clear Input file for each change on input file.
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ requestXML: reader.result });
      }
    };
  };
  handleChangeChk() {
    this.setState({ StHistory: !this.state.StHistory });
  }

  handleInputTextChange = ({ target: { name, value, files } }) => {
    this.setState({ responseReady: false });
    this.setState({ fileName: files[0].name });

    switch (name) {
      case "requestFile":
        let reader = new FileReader();
        if (files.length > 0) {
          reader.readAsText(files[0]);
          this.readXMLFile(reader);
        }

        break;

      default:
        //  this.setState({ data: { ...this.state.data, [name]: value } });
        break;
    }
  };
  downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([this.state.validationResponse], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "response.xml";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  handleNetworkIDTextChange = (e) => {
    this.setState({ NetworkID: e.target.value });
  };
  doRequest = async (event) => {
    event.preventDefault();
    this.setState({ responseReady: false, sent: true, status: false });

    const data = await axios.post(KayanURL + "api/kareem/benifits", {
      data: this.state.requestXML,
      apiToken: Cookies.get("KareemId"),
      storeHistory: this.state.StHistory ? true : null,
      networkId: this.state.NetworkID,
    });
    let samaResponseXML = data.data;
    //console.log(samaResponseXML);
    let Records = [];

    if (samaResponseXML.success == false) {
      let { error } = samaResponseXML;

      this.setState({
        responseReady: true,
        sent: false,
        status: true,
        error,
      });
    } else {
      parseString(samaResponseXML, function (err, result) {
        if (result) {
          let Response_Status = result["Response-Status"];
          let { Status } = Response_Status;

          if (Status && Status.length > 0 && Status[0] == "Failed") {
            let Request_Errors = Response_Status["Request-Errors"][0]["Claim"];
            //let Records = []
            Request_Errors.forEach((claimErrors) => {
              let EncounterId = claimErrors["EncounterId"][0];
              let Line = claimErrors["Line"][0];

              //   console.log(claimErrors);

              let SchemaErrorsArray = claimErrors["Schema-Errors"];

              if (SchemaErrorsArray != undefined) {
                let SchemaErrors = SchemaErrorsArray[0];

                let schemaErrorsTypes = Object.keys(SchemaErrors);
                schemaErrorsTypes.forEach((type) => {
                  let ErrorsofThisType = SchemaErrors[type]; //array
                  ErrorsofThisType.forEach((err) => {
                    let ClaimId = err["ClaimId"][0];
                    let errorLine = err["Line"][0];
                    let Message = err["Message"][0];
                    let Type = "-";
                    let Level = "-";

                    Records.push({
                      ClaimId,
                      EncounterId,
                      claimLine: Line,
                      errorLine,
                      Message,
                      Type,
                      Level,
                    });
                  });
                });
              }

              let DataErrorsArray = claimErrors["Data-Errors"];

              if (DataErrorsArray) {
                let DataErrors = DataErrorsArray[0];

                if (DataErrors) {
                  let DataErrorsCodes = DataErrors["Code"];
                  if (DataErrorsCodes) {
                    DataErrorsCodes.forEach((err) => {
                      let ClaimId = err["ClaimID"] ? err["ClaimID"][0] : "-";
                      let errorLine = err["Line"] ? err["Line"][0] : "-";
                      let Message = err["Message"] ? err["Message"][0] : "-";
                      let Type = err["Type"] ? err["Type"][0] : "-";
                      let Level = err["Level"] ? err["Level"][0] : "-";

                      Records.push({
                        ClaimId,
                        EncounterId,
                        claimLine: Line,
                        errorLine,
                        Message,
                        Type,
                        Level,
                      });
                    });
                  }
                }
              }
            });
          }
        }
      });
      this.setState({
        Rows: Records,
        responseReady: true,
        sent: false,
        status: true,
      });
    }

    document.getElementById("requestFile").value = null;
    document.getElementById("NetworkID").value = "";
    this.setState({ NetworkID: "" });
  };

  // Handling Select Actions.
  onSelectOption = (e) => {
    let select = document.getElementById(e);
    let name = select.name;
    let value = select.options[select.selectedIndex].value;
    switch (name) {
      case "NetworkID":
        this.setState({ NetworkID: value });
        break;
      default:
        //this.setState({ data: { ...this.state.data, [name]: value } });
        break;
    }
  };
  render() {
    let { Rows, responseReady, error } = this.state;

    let ClaimListTable;
    if (Rows && Rows.length > 0) {
      ClaimListTable = Rows.map((row, index) => {
        // row["Level"] = "Failed";
        return (
          <tr key={index}>
            <td>{row["ClaimId"]}</td>

            <td>{row["EncounterId"]}</td>
            <td>{row["claimLine"]}</td>
            <td>{row["errorLine"]}</td>
            <td style={{ textAlign: "left" }}>{row["Message"]}</td>

            <td>{row["Type"]}</td>
            {row["Level"] === "__" ? (
              <td>{row["Level"]}</td>
            ) : row["Level"] === "Review" ? (
              <td style={{ color: "#ffa500" }}>{row["Level"]}</td>
            ) : (
              <td style={{ color: "#ff0000" }}>{row["Level"]}</td>
            )}
          </tr>
        );
      });
    }
    let header = [
      { label: "Claim ID", key: "ClaimId" },
      { label: "Encounter Sequence", key: "EncounterId" },
      { label: "Claim Line", key: "claimLine" },
      { label: "Edit Line", key: "errorLine" },
      { label: "Edit Message", key: "Message" },
      { label: "Activity Code", key: "ActivityCode" },
      { label: "Diagnosis Code", key: "DiagnosisCode" },
      { label: "Type", key: "Type" },
      { label: "Level", key: "Level" },
    ];

    return (
      <>
        <div className="container">
          <br />
          <input type="checkbox" onChange={this.handleChangeChk} />
          <label
            style={{
              float: "none",
              color: "#28a745",
              marginLeft: "10px",
              fontSize: "large",
            }}
          >
            Store History
          </label>
          <Form
            submitStyle="BS10"
            //linkStyle="Link2"
            formStyle="kareemBenefitsFilters"
            labelStyle="BayanLabelStyle"
            onSubmit={this.doRequest}
            /*  linkStyle="Link2" */
            /*  dangerStyle="BS10" */
            onInputTextChange={this.handleInputTextChange}
            /*  onNetworkIDChange={this.handleNetworkIDTextChange} */
            onSelectOption={this.onSelectOption}
            inputsTypes={["file", "select"]}
            elementsValues={[
              "file",
              [this.state.NetworksNames, this.state.NetworksValues],
              "Upload",
            ]}
            elementsNames={["requestFile", "NetworkID"]}
          >
            <HomeFilters />
          </Form>

          {error &&
          Array.isArray(error) &&
          error.length > 0 &&
          responseReady ? (
            <h5 style={{ color: "red" }}>{error[0]}</h5>
          ) : null}

          {!this.state.status && this.state.sent ? (
            <Spinner animation="border" variant="success" />
          ) : responseReady && Rows && Rows.length == 0 ? (
            <>
              <h4 style={{ color: "green" }}>
                Your file uploaded successfully
              </h4>
              <h5 style={{ color: "green" }}>Free of Edits</h5>
            </>
          ) : responseReady && Rows && Rows.length > 0 ? (
            <>
              <h4 style={{ color: "green" }}>
                Your file uploaded successfully
              </h4>
              <CSVLink
                filename="ValidationResult.CSV"
                style={{
                  color: "#ff0505",
                  textDecoration: "underline",
                }}
                data={Rows}
                headers={header}
              >
                Click here to download the generated edits report (CSV)
              </CSVLink>
              <Table responsive striped bordered hover size="sm">
                <thead style={{ background: "#3ab449", color: " white" }}>
                  <tr>
                    <th
                      style={{
                        verticalAlign: "middle",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                      }}
                    >
                      Claim ID
                    </th>
                    <th style={{ verticalAlign: "middle", width: "1px" }}>
                      Encounter Sequence
                    </th>
                    <th style={{ verticalAlign: "middle" }}>Claim Line</th>
                    <th style={{ verticalAlign: "middle" }}>Edit Line</th>
                    <th style={{ verticalAlign: "middle" }}>Edit Message</th>
                    <th style={{ verticalAlign: "middle" }}>Type</th>
                    <th style={{ verticalAlign: "middle" }}>Level</th>
                  </tr>
                </thead>
                <tbody>{ClaimListTable}</tbody>
              </Table>
            </>
          ) : null}
        </div>
      </>
    );
  }
}

export default KareemUploadClaim;
