import React, { Component } from "react";
import axios from "axios";
import Form from "./../Components/Form/index.js";
import Spinner from "react-bootstrap/Spinner";
import Button from "../Components/Button/index";
import Cookies from "js-cookie";
import settings from "../helpers/Settings";
import UploadNotAllowedICD from "./UploadNotAllowedICD";
import UpdateNotAllowedPrincipleIcd from "./updateNotAllowedPrincipalICD";
import AddNewCodeOfICD from "./AddNewCode";
import Table from "react-bootstrap/Table";
let { KayanURL } = settings;
var XLSX = require("xlsx");
class UploadNotAllowedPrincipleIcd extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onClickAddNewCode = this.onClickAddNewCode.bind(this);
    this.onClickUploadICD = this.onClickUploadICD.bind(this);
    this.getAllNotAllowedPrincipalICDList =
      this.getAllNotAllowedPrincipalICDList.bind(this);
  }
  componentDidMount = async () => {
    this.getAllNotAllowedPrincipalICDList();
  };

  async getAllNotAllowedPrincipalICDList() {
    let response = await axios.put(
      KayanURL + "api/KareemBenefits/getNotAllowedPrincipalICDList",
      {
        apiToken: Cookies.get("KareemId"),
      }
    );
    if (response) {
      let { data } = response;
      //  console.log(data);
      if (data && data.success) {
        let { result } = data;
        if (result) {
          this.setState({
            AllNotAllowedPrincipalICD: result
          });
        }
      } else if (data && !data.success) {
        let { error } = data;
        if (error) {
          if (Array.isArray(error) && error.length > 0) {
            this.setState({ error: error[0] });
          } else {
            this.setState({ error: error });
          }
        }
      }
    }
  }
  DeleteNotAllowedPrincipalICDByID = async (e) => {
    e.preventDefault();
    let { NotAllowedPrincipalICDid } = this.state;

    let response = await axios.put(
      KayanURL + "api/KareemBenefits/deleteNotAllowedPrincipalICD",
      {
        apiToken: Cookies.get("KareemId"),
        data: NotAllowedPrincipalICDid,
      }
    );

    if (response) {
      let { data } = response;
      if (data.success) {
        this.setState({
          success: true,
          DeleteNotAllowedPrincipalICD: false,
        });
        this.getAllNotAllowedPrincipalICDList();
      } else {
        let { error } = data;
        if (error) {
          if (Array.isArray(error) && error.length > 0) {
            this.setState({ error: error[0] });
          } else {
            this.setState({ error: error });
          }
        }
      }
    }
  };
  onClickAddNewCode() {
    this.setState({ AddNewCode: true });
  }
  onClickUploadICD() {
    this.setState({ UploadICD: true });
  }
  render() {
    let { AllNotAllowedPrincipalICD, UploadICD, AddNewCode } = this.state;
    let AllNotAllowedPrincipalICDTable;
    if (AllNotAllowedPrincipalICD && AllNotAllowedPrincipalICD.length > 0) {
      AllNotAllowedPrincipalICDTable = AllNotAllowedPrincipalICD.map(
        (row, index) => {
          return (
            <tr key={index}>
              <td>{row.payer_id}</td>
              <td>{row.icd}</td>
              <td>
                <button
                  style={{
                    color: "red",
                    border: "none",
                  }}
                  onClick={() =>
                    this.setState({
                      DeleteNotAllowedPrincipalICD: true,
                      NotAllowedPrincipalICDid: row.id,
                    })
                  }
                >
                  Delete
                </button>
                <button
                  style={{
                    color: "red",
                    border: "none",
                    marginLeft: "15px",
                  }}
                  onClick={() =>
                    this.setState({
                      EditNotAllowedPrincipalICD: true,
                      NotAllowedPrincipalICDid: row.id,
                      payer_id: row.payer_id,
                      icd: row.icd,
                    })
                  }
                >
                  Edit
                </button>
              </td>
            </tr>
          );
        }
      );
    }
    return (
      <>
        {this.state.DeleteNotAllowedPrincipalICD ? (
          <div className="popup">
            <div
              className="form"
              style={{
                width: "50%",
                margin: "10px auto",
                marginLeft: "33%",
                backgroundColor: "white",
              }}
            >
              <form>
                <h4>Are you sure you want to delete this ICD?</h4>
                <Button
                  Style="BS12"
                  Value="Yes"
                  onClick={this.DeleteNotAllowedPrincipalICDByID}
                />
                <Button
                  Style="BS12"
                  Value="No"
                  onClick={() =>
                    this.setState({
                      DeleteNotAllowedPrincipalICD: false,
                    })
                  }
                />
              </form>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  textAlign: "right",
                  cursor: "pointer",
                  margin: "0 2%",
                }}
              ></p>
            </div>
          </div>
        ) : null}
        {this.state.EditNotAllowedPrincipalICD ? (
          <div className="popup">
            <div
              className="form5"
              style={{
                width: "60%",
                marginLeft: "25%",
                backgroundColor: "white",
              }}
            >
              <UpdateNotAllowedPrincipleIcd
                id={this.state.NotAllowedPrincipalICDid}
                payer_id={this.state.payer_id}
                icd={this.state.icd}
                getAllNotAllowedPrincipalICDList={
                  this.getAllNotAllowedPrincipalICDList
                }
              />
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  cursor: "pointer",
                  textAlign: "right",
                }}
                onClick={() => {
                  this.setState({
                    EditNotAllowedPrincipalICD: false,
                  });
                }}
              >
                {" "}
                Close{" "}
              </p>
            </div>
          </div>
        ) : null}
        <div className="container">
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <h3 style={{ color: "green", margin: "1rem 0" }}>
              Not Allowed Principle ICD
            </h3>
          </div>
          <input
            type="button"
            className="BS1"
            value="Add New Code"
            onClick={this.onClickAddNewCode}
          />

          <input
            type="button"
            className="BS1"
            value="Upload File Of ICD"
            onClick={this.onClickUploadICD}
          />
          {UploadICD ? (
            <div className="popup">
              <div
                className="form5"
                style={{
                  width: "60%",
                  marginLeft: "25%",
                  backgroundColor: "white",
                }}
              >
                <UploadNotAllowedICD
                  getAllNotAllowedPrincipalICDList={
                    this.getAllNotAllowedPrincipalICDList
                  }
                />
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    cursor: "pointer",
                    textAlign: "right",
                  }}
                  onClick={() => {
                    this.setState({
                      UploadICD: false,
                    });
                  }}
                >
                  {" "}
                  Close{" "}
                </p>
              </div>
            </div>
          ) : null}
          {AddNewCode ? (
            <div className="popup">
              <div
                className="form5"
                style={{
                  width: "50%",
                  marginLeft: "33%",
                  backgroundColor: "white",
                }}
              >
                <AddNewCodeOfICD
                  getAllNotAllowedPrincipalICDList={
                    this.getAllNotAllowedPrincipalICDList
                  }
                />
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    cursor: "pointer",
                    textAlign: "right",
                  }}
                  onClick={() => {
                    this.setState({
                      AddNewCode: false,
                    });
                  }}
                >
                  {" "}
                  Close{" "}
                </p>
              </div>
            </div>
          ) : null}
          <hr />
          <div style={{ width: "70%", margin: "20px auto" }}>
            {AllNotAllowedPrincipalICD &&
            AllNotAllowedPrincipalICD.length > 0 ? (
              <Table
                responsive
                striped
                bordered
                hover
                size="sm"
                style={{ marginTop: "20px" }}
              >
                <thead style={{ background: "#3ab449", color: " white" }}>
                  <tr>
                    <th
                      style={{
                        verticalAlign: "middle",
                        paddingRight: "10px",
                      }}
                    >
                      Payer ID
                    </th>
                    <th style={{ verticalAlign: "middle" }}>ICD</th>
                    <th style={{ verticalAlign: "middle" }}>Action</th>
                  </tr>
                </thead>
                <tbody>{AllNotAllowedPrincipalICDTable}</tbody>
              </Table>
            ) : (
              <h5>there is no records in DB</h5>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default UploadNotAllowedPrincipleIcd;
