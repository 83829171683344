import React from "react";
import "./Table.css";

function Table(props) {
    const unimportantfields = ['Password'];
    let name = props.name;

    return (
        <table>
            <thead className="Kayanthead">
                <tr>
                    {
                        props.THead.map((item, index) => {
                            if (!unimportantfields.includes(item)) return <th key={index}>{item.replace(/-/g, " ")}</th>
                        })
                    }
                </tr>
            </thead>
            <tbody>
                {
                    props.TBody.map((item, index) => <tr key={index} className={props.TBody.length == 1 ? "singleTrBody" : null}>
                        {
                            Object.keys(item).map((i, index) => {
                                if (!unimportantfields.includes(i)) return <td key={index}> {item[i] ? item[i] : '-'} </td>
                            })
                        }
                    </tr>)
                }
            </tbody>
        </table>
    )
}
export default Table;
