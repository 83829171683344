import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { Box, Divider } from "@material-ui/core";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Cookies from "js-cookie";
import { MdImportContacts } from "react-icons/md";
import "./KareemStyle.css";
import {
  FaFileAlt,
  FaRegUser,
  FaHistory,
  FaFileCsv,
  FaHome,
  FaWpforms,
  FaUpload,
  FaUserPlus,
  FaUsers,
  FaCubes,
  FaSignOutAlt,
  FaClock,
  FaTrademark,
  FaInbox,
  FaCodeBranch,
  FaLevelDownAlt,
  FaNetworkWired,
  FaFileSignature,
} from "react-icons/fa";
import IconMenuClose from "./icons/icon-close.svg";
import IconMenuOpen from "./icons/icon-open.svg";

const drawerWidth = 270;
const useStyles = makeStyles((theme) => ({
  drawer: {
    zIndex: 1000,
    width: drawerWidth,
    flexShrink: 0,
    // whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(8) + 1,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    marginTop: theme.spacing(6.5),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(10),
    },
  },
}));

export default function Sub({
  toggleState,
  toggleTab,
  name,
  projectType,
  history,
  children,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [showAboutMenu1, setShowAboutMenu1] = React.useState(false);
  const handleHover1 = () => {
    setShowAboutMenu1(!showAboutMenu1);
  };
  const handleLogout = (projectType, history) => {
    let tokenName =
      projectType == "Sanad" || projectType == "Huda"
        ? "SanadId"
        : projectType == "Kareem"
        ? "KareemId"
        : projectType == "Sama" || projectType == "Somod"
        ? "SamaId"
        : projectType == "admin"
        ? "BayanAdminID"
        : projectType == "SamaCompanyAdmin"
        ? "SamaCompanyId"
        : projectType == "samaAdmin"
        ? "SamaAdminId"
        : "id";

    Cookies.remove(tokenName);

    Object.keys(localStorage).forEach((item) => {
      if (
        projectType !== "Sanad" &&
        projectType !== "Sama" &&
        projectType != "admin"
      ) {
        if (
          !item.includes("Sanad") &&
          !item.includes("Sama") &&
          !item.includes("Admin")
        ) {
          localStorage.removeItem(item);
        }
      } else if (projectType == "Sama") {
        if (item.includes("Sama")) {
          localStorage.removeItem(item);
        } else if (projectType == "Kareem") {
          if (item.includes("Kareem")) {
            localStorage.removeItem(item);
          }
        }
      } else if (projectType == "admin") {
        if (item.includes("Admin")) {
          localStorage.removeItem(item);
        }
      } else if (projectType == "samaAdmin") {
        if (item.includes("SamaAdminId")) {
          localStorage.removeItem(item);
        }
      } else {
        if (item.includes("Sanad")) {
          localStorage.removeItem(item);
        }
      }
    });

    history.push("/");
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const Contracts = () => {
    return (
      <>
        {open ? (
          <>
            <ListItem
              onClick={() => toggleTab(8)}
              className={toggleState === 8 ? "tabActive1" : "tab1"}
            >
              <ListItemIcon>{iconMenu[17]}</ListItemIcon>
              <ListItemText primary="Networks" />
            </ListItem>
            <ListItem
              onClick={() => toggleTab(9)}
              className={toggleState === 9 ? "tabActive1" : "tab1"}
            >
              <ListItemIcon>{iconMenu[1]}</ListItemIcon>
              <ListItemText primary="Price Lists" />
            </ListItem>
          </>
        ) : null}
      </>
    );
  };

  const iconMenu = [
    <FaUpload />,
    <FaWpforms />,
    <FaHome />,
    <FaFileCsv />,
    <FaHistory />,
    <FaRegUser />,
    <FaSignOutAlt />,
    <FaUsers />,
    <FaInbox />,
    <FaCubes />,
    <FaTrademark />,
    <FaCodeBranch />,
    <FaClock />,
    <FaUserPlus />,
    <FaFileAlt />,
    <MdImportContacts />,
    <FaLevelDownAlt style={{ marginLeft: "4.5rem" }} />,
    <FaNetworkWired />,
    <FaFileSignature />,
  ];
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        id="barra-lateral"
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          {open ? (
            <IconButton onClick={handleDrawerClose}>
              <img src={IconMenuClose} alt="close menu" />
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerOpen}>
              <img src={IconMenuOpen} alt="open menu" />
            </IconButton>
          )}
        </div>
        <Divider />
        <List>
          <ListItem>
            <ListItemIcon>{iconMenu[5]}</ListItemIcon>
            <ListItemText primary={name} />
          </ListItem>
          <ListItem
            onClick={() => toggleTab(1)}
            className={toggleState === 1 ? "tabActive" : ""}
          >
            <ListItemIcon>{iconMenu[2]}</ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem onClick={handleHover1}>
            <ListItemIcon>{iconMenu[0]}</ListItemIcon>
            <ListItemText primary="Contract Management" />
            {iconMenu[16]}
          </ListItem>
          {showAboutMenu1 && <Contracts />}
          {/*    <ListItem onClick={handleHover}>
            <ListItemIcon>{iconMenu[18]}</ListItemIcon>
            <ListItemText primary="Claims Management" />
            {iconMenu[16]}
          </ListItem>
          {showAboutMenu && <ClaimMethods />} */}
          <ListItem
            onClick={() => toggleTab(5)}
            className={toggleState === 5 ? "tabActive" : ""}
          >
            <ListItemIcon>{iconMenu[14]}</ListItemIcon>
            <ListItemText primary="Rules Management" />
          </ListItem>

          {/* <ListItem
            onClick={() => toggleTab(4)}
            className={toggleState === 4 ? "tabActive" : ""}
          >
            <ListItemIcon>{iconMenu[3]}</ListItemIcon>
            <ListItemText primary="Add Rule CSV" />
          </ListItem> */}

          <ListItem
            onClick={() => toggleTab(6)}
            className={toggleState === 6 ? "tabActive" : ""}
          >
            <ListItemIcon>{iconMenu[4]}</ListItemIcon>
            <ListItemText primary="Update History" />
          </ListItem>

          <ListItem
            onClick={() => toggleTab(7)}
            className={toggleState === 7 ? "tabActive" : ""}
          >
            <ListItemIcon>{iconMenu[7]}</ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                style: open ? { whiteSpace: "normal" } : {},
              }}
              primary="Pre-Authorization Codes List"
            />
          </ListItem>
          <ListItem
            onClick={() => toggleTab(10)}
            className={toggleState === 10 ? "tabActive" : ""}
          >
            <ListItemIcon>{iconMenu[0]}</ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                style: open ? { whiteSpace: "normal" } : {},
              }}
              primary="Not allowed Principle ICD List"
            />
          </ListItem>
          <ListItem
            onClick={() => toggleTab(11)}
            className={toggleState === 11 ? "tabActive" : ""}
          >
            <ListItemIcon>{iconMenu[0]}</ListItemIcon>
            <ListItemText
              className={classes.listText}
              primary="Not Covered ICD List"
            />
          </ListItem>
          <ListItem
            onClick={() => toggleTab(12)}
            className={toggleState === 12 ? "tabActive" : ""}
          >
            <ListItemIcon>{iconMenu[0]}</ListItemIcon>
            <ListItemText
              className={classes.listText}
              primary="Plans"
            />
          </ListItem>
          <ListItem
            className="sign-out"
            onClick={() => handleLogout(projectType, history)}
          >
            <ListItemIcon>{iconMenu[6]}</ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" flexGrow={1}>
        {children}
      </Box>
    </Box>
  );
}
