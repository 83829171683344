import React, { Component } from "react";
import styles from "./Styling/partners.module.css";
import "./Styling/partners.css";
import Slider from "react-slick";
const Partners = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const clients = [
    {
      clientURl: "https://www.primehealth.ae/prime-hospital",
      alt: "Prime Hospital",
      clientImage: "Prime.jpg",
    },
    {
      clientURl: "https://zulekhahospitals.com/",
      alt: "Zulekha Hospital",
      clientImage: "zulekha.png",
    },

    {
      clientURl: "https://ngi.ae/",
      alt: "National General Insurance Company",
      clientImage: "NGI.png",
    },
    {
      clientURl: "https://fuh.care/ar/home/",
      alt: "Fakeeh Hospital",
      clientImage: "fakeeh.jpg",
    },
    {
      clientURl: "https://www.righthealth.ae/",
      alt: "Right Health",
      clientImage: "right.PNG",
    },
  ];
  return (
    <div className={styles.clients} style={{ display: "none" }}>
      <h1 className={styles.header}>CLIENTS</h1>
      <div className={styles.borderBottom}></div>
      <div className={styles.Partners} style={{ height: "70%" }}>
        <Slider {...settings}>
          {clients.map((client, idx) => {
            return (
              <div key={idx}>
                <a href={client.clientURl} target="_blank">
                  <img
                    alt={client.alt}
                    style={{
                      width: "14em",
                      height: "11em",
                      padding: 1 + "rem",
                    }}
                    src={require("../imgs/" + client.clientImage)}
                  />
                </a>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default Partners;
