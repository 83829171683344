import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Footer from "../Components/footer";

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {
  const [companyName, setCompanyName] = React.useState("");
  const [username, setUserName] = React.useState("");

  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    // this.setState({ loginStatus: true });

    /*  const { history } = this.props;

    this.setState(
      { username: "test" },
      { companyname: "kayan" },
      { password: "123" }
    ); */

    //this.setState({ Company: "", username: "", password: "" });

    if (companyName == "kayan" && username == "test" && password == "123") {
      window.location.href = "/BayanKSA";
    } else {
      setError("Incorrect Username or Password");
    }
  };

  const handleInputTextChange = ({ target: { name, value } }) => {
    switch (name) {
      case "companyName":
        setCompanyName(value);
        break;

      case "username":
        setUserName(value);
        break;

      case "password":
        setPassword(value);
        break;

      default:
        break;
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" /*  maxWidth="xs" */>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            marginBottom: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/*    */}
          {error ? (
            <Typography component="h1" variant="h5">
              {error}
            </Typography>
          ) : null}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="companyName"
              label="Company Name"
              name="companyName"
              autoFocus
              onChange={(event) => {
                //this.setState({ Company: event.target.value });
                handleInputTextChange(event);
              }}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="userName"
              label="User Name"
              name="username"
              autoFocus
              onChange={(event) => {
                // this.setState({ username: event.target.value });
                handleInputTextChange(event);
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={(event) => {
                // this.setState({ password: event.target.value });
                handleInputTextChange(event);
              }}
            />

            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              /* onSubmit={(e) => {
                handelLogIn();
              }} */
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
      <Footer />
    </ThemeProvider>
  );
}
