import React, { Component } from "react";
//import Nav from "react-bootstrap/Nav";
//import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import "../App.css";
//import Carousel from "react-bootstrap/Carousel";

class Schema extends Component {
  render() {
    return (
      <div className="Schema">
        <Container>
        <h2>Schema Definition</h2>
          <p style={{ textAlign: "left" }}>
            It will depend on mainly on generalized transaction schema request
            which will be represented in xml that will consist of the flowing required fields:
          </p>
          <table className="table" style={{ textAlign: "left" }}>
            <thead>
              <tr>
                <th>Field Name</th>
                <th>Type</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&lt;Claim.Submission&gt;</td>
                <td>Structure element Required</td>
                <td>
                The main root of the schema that will contain all the patient visits claims.
                </td>
              </tr>

              <tr>
                <td>&lt;Claim&gt;</td>
                <td>Structure element Required</td>
                <td>
                It represents all the patient visit details, the claim could be repeated in the same request depends on the representation need of the provider.
                </td>
              </tr>

              <tr>
                <td>&lt;ID&gt;</td>
                <td>Data element
                  Required
                  </td>
                <td>
                Represents the ID of the claims, and it's used one time per claim.
                </td>
              </tr>

              <tr>
                <td>&lt;Encounter&gt;</td>
                <td>Structure element
                Required
                </td>
                <td>
                    This is the parent for all the required patient details, and it is used one time per claim.

                </td>
              </tr>

              <tr>
                <td>&lt;Type&gt;</td>
                <td>Data element
Required
 </td>
                <td>The Encounter's Type. Only values allowed are:

                  <ul>
                    <li>1=No Bed + No emergency room (outpatient).</li>
                    <li>2=No Bed + Emergency room (outpatient).</li>
                    <li>3=Inpatient Bed + No emergency room</li>
                    <li>4=Inpatient Bed + Emergency room.</li>
                    <li>5=Daycase Bed + No emergency room.</li>
                    <li>5=Daycase Bed + No emergency room.</li>
                    <li>6=Daycase Bed + Emergency room.</li>
                    <li>7=Nationals Screening.</li>
                    <li>8=New Visa Screening.</li>
                    <li>9=Renewal Visa Screening.</li>
                    <li>12=Home.</li>
                    <li>13=Assisted Living Facility.</li>
                    <li>15 = Mobile Unit.</li>
                    <li>
                    41= Ambulance Land.
                    </li>
                    <li>
                    42= Ambulance Air or Water.

                    </li>
                    


                  </ul>
                </td>
              </tr>

              <tr>
                <td>&lt;PatientAge&gt;</td>
                <td>Data element Optional</td>
                <td>
                Represents the age of the patient.
                </td>
               
              </tr>

              <tr>
                <td>&lt;PatientGender&gt;</td>
                <td>Data element Optional</td>
                <td>Represents the gender of the patient with the possibilities :
                  <ul>
                    <li>M: Male</li>
                    <li>F: Female</li>
                  </ul>


                  Included in the Encounter fields.
                </td>
              
              </tr>

              <tr>
                <td>&lt;Diagnosis&gt;</td>
                <td>Structure element Required</td>
                <td>The main tag of the diagnosis that includes the diagnosis details (code and type). Diagnosis element could be repeated in the same claim.</td>
              </tr>

              <tr>
                <td>&lt;Type&gt;</td>
                <td>Data element Required</td>
                <td>
                   Represents the diagnosis type, only values allowed are:

                  <ul>
                    <li>Principal</li>
                    <li>Secondary</li>
                    <li>Admitting</li>
                  </ul>
                  It is used one time per diagnosis element.

                </td>
              </tr>

              

              <tr>
                <td>&lt;Code&gt;</td>
                <td>Data element Required
                </td>
                <td>
                Represents the diagnosis code and it is used one time per diagnosis element.
                </td>
              </tr>

              <tr>
                <td>&lt;Activity&gt;</td>
                <td>Structure element required</td>
                <td>
                The main element that includes the claim activity details inside it.
Activity element could be repeated in the same claim.

                </td>
              </tr>

              <tr>
                <td>&lt;ID&gt;</td>
                <td>Data element Required</td>
                <td>
                Represents the ID of the activity and it is used one time per activity element.
                </td>
              </tr>

              <tr>
                <td>&lt;Start&gt;</td>
                <td>Data element
Required
Format:
dd/mm/yyyy hh:mm
</td>
                <td>
                  
Represents the start date and time of activity and it is used one per activity element.

                </td>
              </tr>

              <tr>
                <td>
                Type
                </td>
                <td>Data element
Required
</td>
                <td>
                Represents the activity’s type. Only values allowed are:
                <ul>
                  <li>3 = CPT.</li>
                  <li>4 = HCPCS.</li>
                  <li>6 = Dental.</li>
                  <li>8 = Service Code.</li>
                  <li>10 = Scientific Code.</li>
                </ul>
                  It is used one time per activity element.

                </td>
              </tr>

              <tr>
                <td>
                Code
                </td>
                <td>Data element Required</td>
                <td>
                Represents the activity code and it is used one time per activity element.
                </td>
              </tr>
              <tr>
                <td>
                Quantity
                </td>
                <td>Data element Required</td>
                <td>
                Identifies how many activity used per day for the prescribed activity.  It is used one time per activity element.
                </td>
              </tr>
              <tr>
                <td>
                Observation
                </td>
                <td>Structure element Optional</td>
                <td>
                In the case of Activity Type indicated to "Dental”, it is possible to add details related to tooth number.
                Observation element could be repeated in the same activity multiple times.
                </td>
              </tr>
              <tr>
                <td>
                Value
                </td>
                <td>Data element Optional</td>
                <td>
                
                   Represents the type of observation. It is used one time per observation element.


                </td>
              </tr>
              <tr>
                <td>
                ValueType
                </td>
                <td>Data element Optional</td>
                <td>
                Represents the type of observation. It is used one time per observation element.


                </td>
              </tr>
              <tr>
                <td>
                Code
                </td>
                <td>Data element Optional</td>
                <td>
                Represents the Code of observation and it is value fixed “UniversalDental”
 It is used one time per observation element.



                </td>
              </tr>
            
              <tr>
                <td>
                Type
                </td>
                <td>Data element Optional</td>
                <td>
                Represents the Type of observation and it is value fixed and equal “UniversalDental”
 It is used one time per observation element.



                </td>
              </tr>
            
            </tbody>
          </table>

          <h2>Request XML Structure</h2>
          <p style={{ textAlign: "left" }}>
          Bayan has a special schema that is used for requests and it must be used to be able to use the API. Follows the XSD structure for the request sent to Bayan API:
          </p>
          <pre>
            
          {`<?xml version="1.0" encoding="UTF-8"?>
            <xs:schema xmlns:tns="http://www.eclaimlink.ae/DHD/ValidationSchema" elementFormDefault="qualified" version="2.0" id="ClaimSubmission" xmlns:xs="http://www.w3.org/2001/XMLSchema">
             <xs:import schemaLocation="CommonTypes.xsd" namespace="http://www.eclaimlink.ae/DHD/ValidationSchema" />
                <xs:element name="Claim.Submission">
                    <xs:complexType>
                        <xs:sequence>
                            <xs:element minOccurs="0" maxOccurs="1" name="Header">
                                <xs:complexType>
                                    <xs:sequence>
                                        <xs:element minOccurs="0" maxOccurs="1" name="SenderID" type="tns:HeaderSenderID" />
                                        <xs:element minOccurs="0" maxOccurs="1" name="ReceiverID" type="tns:HeaderReceiverID" />
                                        <xs:element minOccurs="0" maxOccurs="1" name="TransactionDate" type="tns:HeaderTransactionDate" />
                                        <xs:element minOccurs="0" maxOccurs="1" name="RecordCount" type="tns:HeaderRecordCount" />
                                        <xs:element minOccurs="0" maxOccurs="1" name="DispositionFlag" type="tns:HeaderDispositionFlag" />
                                    </xs:sequence>
                                </xs:complexType>
                            </xs:element>
                            <xs:element minOccurs="1" maxOccurs="unbounded" name="Claim">
                                <xs:complexType>
                                    <xs:sequence>
                                        <xs:element minOccurs="1" maxOccurs="1" name="ID" type="tns:ClaimID" />
                                        <xs:element minOccurs="0" maxOccurs="1" name="IDPayer" type="tns:ClaimIDPayer" />
                                        <xs:element minOccurs="0" maxOccurs="1" name="MemberID" type="tns:ClaimMemberID" />
                                        <xs:element minOccurs="0" maxOccurs="1" name="PayerID" type="tns:ClaimPayerID" />
                                        <xs:element minOccurs="0" maxOccurs="1" name="ProviderID" type="tns:ClaimProviderID" />
                                        <xs:element minOccurs="0" maxOccurs="1" name="EmiratesIDNumber" type="tns:ClaimEmiratesIDNumber" />
                                        <xs:element minOccurs="0" maxOccurs="1" name="Gross" type="tns:ClaimGross" />
                                        <xs:element minOccurs="0" maxOccurs="1" name="PatientShare" type="tns:ClaimPatientShare" />
                                        <xs:element minOccurs="0" maxOccurs="1" name="Net" type="tns:ClaimNet" />
                                        <xs:element minOccurs="1" maxOccurs="1" name="Encounter">
                                            <xs:complexType>
                                                <xs:sequence>
                                <xs:element minOccurs="1" maxOccurs="1" name="Type" type="tns:EncounterType" />
                                <xs:element minOccurs="0" maxOccurs="1" name="PatientAge" type="tns:EncounterPatientAge" />
                                                    <xs:element minOccurs="0" maxOccurs="1" name="PatientGender" type="tns:EncounterPatientGender" />
                                                    <xs:element minOccurs="0" maxOccurs="1" name="FacilityID" type="tns:EncounterFacilityID" />
                                                    <xs:element minOccurs="0" maxOccurs="1" name="PatientID" type="tns:EncounterPatientID" />
                                                    <xs:element minOccurs="0" maxOccurs="1" name="Start" type="tns:EncounterStart" />
                                                    <xs:element minOccurs="0" maxOccurs="1" name="End" type="tns:EncounterEnd" />
                                                    <xs:element minOccurs="0" maxOccurs="1"  name="StartType" type="tns:EncounterStartType" />
                                                    <xs:element minOccurs="0" maxOccurs="1"  name="EndType" type="tns:EncounterEndType" />
                                                    <xs:element minOccurs="0" maxOccurs="1"  name="TransferSource" type="tns:EncounterTransferSource" />
                                                    <xs:element minOccurs="0" maxOccurs="1"  name="TransferDestination" type="tns:EncounterTransferDestination" />
                                                </xs:sequence>
                                            </xs:complexType>
                                        </xs:element>
                                        <xs:element minOccurs="1" maxOccurs="unbounded" name="Diagnosis">
                                            <xs:complexType>
                                                <xs:sequence>
                                                    <xs:element minOccurs="1" maxOccurs="1" name="Type" type="tns:DiagnosisType" />
                                                    <xs:element minOccurs="1" maxOccurs="1" name="Code" type="tns:DiagnosisCode" />										
                                                </xs:sequence>
                                            </xs:complexType>
                                        </xs:element>
                                        <xs:element minOccurs="1" maxOccurs="unbounded" name="Activity">
                                            <xs:complexType>
                                                <xs:sequence>
                                                    <xs:element minOccurs="1" maxOccurs="1" name="ID" type="tns:ActivityID"  />
                                                    <xs:element minOccurs="1" maxOccurs="1" name="Start" type="tns:ActivityStart" />
                                                    <xs:element minOccurs="1" maxOccurs="1" name="Type" type="tns:ActivityType" />
                                                    <xs:element minOccurs="1" maxOccurs="1" name="Code" type="tns:ActivityCode" />
                                                    <xs:element minOccurs="1" maxOccurs="1" name="Quantity" type="tns:ActivityQuantity" />
                                                    <xs:element minOccurs="0" maxOccurs="1" name="Net" type="tns:ActivityNet" />
                                                    <xs:element minOccurs="0" maxOccurs="1" name="Clinician" type="tns:ActivityClinician" />								
                                                    <xs:element minOccurs="0" maxOccurs="1"  name="PriorAuthorizationID" type="tns:ActivityPriorAuthorizationID" />
                                                    <xs:element minOccurs="0" maxOccurs="unbounded" name="Observation">
                                                        <xs:complexType>
                                                            <xs:sequence>
                                                                <xs:element minOccurs="0" maxOccurs="1" name="Type" type="tns:ObservationType" />
                                                                <xs:element minOccurs="0" maxOccurs="1" name="Code" type="tns:ObservationCode" />
                                                                <xs:element minOccurs="0" maxOccurs="1" name="Value" type="tns:ObservationValue" />
                                                                <xs:element minOccurs="0" maxOccurs="1" name="ValueType" type="tns:ObservationValueType" />
                                                            </xs:sequence>
                                                        </xs:complexType>
                                                    </xs:element>
                                                </xs:sequence>
                                            </xs:complexType>
                                        </xs:element>
                                        <xs:element minOccurs="0" maxOccurs="1" name="Resubmission">
                                            <xs:complexType>
                                                <xs:sequence>
                                                    <xs:element minOccurs="0" maxOccurs="1" name="Type" type="tns:ResubmissionType" />
                                                    <xs:element minOccurs="0" maxOccurs="1" name="Comment" type="tns:ResubmissionComment" />
                                                    <xs:element minOccurs="0" maxOccurs="1"  name="Attachment" type="tns:ResubmissionAttachments" />
                                                </xs:sequence>
                                            </xs:complexType>
                                        </xs:element>
                                        <xs:element minOccurs="0" maxOccurs="1"  name="Contract">
                                            <xs:complexType>
                                                <xs:sequence>
                                                    <xs:element minOccurs="0" maxOccurs="1"  name="PackageName" type="tns:ContractPackageName" />
                                                </xs:sequence>
                                            </xs:complexType>
                                        </xs:element>
                                    </xs:sequence>
                                </xs:complexType>
                            </xs:element>
                        </xs:sequence>
                    </xs:complexType>
                </xs:element>
            </xs:schema>`}
            
      


           
          </pre>

          <h2>Response XML structure</h2>

<p style={{ textAlign: "left" }}>
The following structure represents Bayan response structure. It’s important to understand this structure in order to handle response properly.
</p>
<pre>
  {`
<?xml version="1.0" encoding="UTF-8"?>
<xs:schema
    xmlns:xs="http://www.w3.org/2001/XMLSchema" elementFormDefault="qualified" attributeFormDefault="unqualified">
    <xs:element name="Response-Status">
        <xs:complexType>
            <xs:sequence>
                <xs:element name="Status" type="xs:string"></xs:element>
                <xs:element name="Request-Errors">
                    <xs:complexType>
                        <xs:sequence>
                            <xs:element name="Claim" minOccurs="0" maxOccurs="unbounded">
                                <xs:complexType>
                                    <xs:sequence>
                                        <xs:element minOccurs="1" maxOccurs="1" name="EncounterId" type="xs:int"></xs:element>
                                        <xs:element minOccurs="1" maxOccurs="1" name="Line" type="xs:int"></xs:element>
                                        <xs:element minOccurs="0" maxOccurs="1" name="Data-Errors">
                                            <xs:complexType>
                                                <xs:sequence>
                                                    <xs:element minOccurs="1" maxOccurs="unbounded" name="Code">
                                                        <xs:complexType>
                                                            <xs:sequence>
                                                                <xs:element minOccurs="0" maxOccurs="1" name="ActivityID" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="ClaimID" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="0" maxOccurs="1" name="ActivityCode" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Level" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Domain" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="0" maxOccurs="1" name="List" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="CodeId" type="xs:int"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Line" type="xs:int"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Type" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Message" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="0" maxOccurs="1" name="Denial" type="xs:string"></xs:element>   
                                                            </xs:sequence>
                                                        </xs:complexType>
                                                    </xs:element>
                                                </xs:sequence>
                                            </xs:complexType>
                                        </xs:element>
                                        <xs:element minOccurs="0" maxOccurs="1" name="Schema-Errors">
                                            <xs:complexType>
                                                <xs:sequence>
                                                        <xs:element minOccurs="0" maxOccurs="unbounded" name="Claim">
                                                        <xs:complexType>
                                                            <xs:sequence>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="ClaimId" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Element" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Line" type="xs:int"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Message" type="xs:string"></xs:element>
                                                            </xs:sequence>
                                                        </xs:complexType>
                                                    </xs:element>
                                                    <xs:element minOccurs="0" maxOccurs="unbounded" name="Encounter">
                                                        <xs:complexType>
                                                            <xs:sequence>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="ClaimId" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Element" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Domain" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Line" type="xs:int"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Message" type="xs:string"></xs:element>
                                                            </xs:sequence>
                                                        </xs:complexType>
                                                    </xs:element>
                                                    <xs:element minOccurs="0" maxOccurs="unbounded" name="Activity">
                                                        <xs:complexType>
                                                            <xs:sequence>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="ActivityID" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="ClaimId" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Element" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Line" type="xs:int"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Message" type="xs:string"></xs:element>
                                                            </xs:sequence>
                                                        </xs:complexType>
                                                    </xs:element>
                                                    <xs:element minOccurs="0" maxOccurs="unbounded" name="Diagnosis">
                                                        <xs:complexType>
                                                            <xs:sequence>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="ClaimId" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Element" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="DiagnosisId" type="xs:int"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Line" type="xs:int"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Message" type="xs:string"></xs:element>
                                                            </xs:sequence>
                                                        </xs:complexType>
                                                    </xs:element>
                                                    <xs:element minOccurs="0" maxOccurs="unbounded" name="Code">
                                                        <xs:complexType>
                                                            <xs:sequence>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="ClaimId" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="0" maxOccurs="1" name="ActivityID" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Element" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Domain" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="CodeId" type="xs:int"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Line" type="xs:int"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Message" type="xs:string"></xs:element>
                                                            </xs:sequence>
                                                        </xs:complexType>
                                                    </xs:element>
                                                    <xs:element minOccurs="0" maxOccurs="unbounded" name="Type">
                                                        <xs:complexType>
                                                            <xs:sequence>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="ClaimId" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Element" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Domain" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="CodeId" type="xs:int"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Line" type="xs:int"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Message" type="xs:string"></xs:element>
                                                            </xs:sequence>
                                                        </xs:complexType>
                                                    </xs:element>
                                                    <xs:element minOccurs="0" maxOccurs="unbounded" name="Start">
                                                        <xs:complexType>
                                                            <xs:sequence>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="ActivityID" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="ClaimId" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Element" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Line" type="xs:int"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Message" type="xs:string"></xs:element>
                                                            </xs:sequence>
                                                        </xs:complexType>
                                                    </xs:element>
                                                    <xs:element minOccurs="0" maxOccurs="unbounded" name="Quantity">
                                                        <xs:complexType>
                                                            <xs:sequence>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="ActivityID" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="ClaimId" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Element" type="xs:string"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Line" type="xs:int"></xs:element>
                                                                <xs:element minOccurs="1" maxOccurs="1" name="Message" type="xs:string"></xs:element>
                                                            </xs:sequence>
                                                        </xs:complexType>
                                                    </xs:element>
                                              </xs:sequence>
                                            </xs:complexType>                   
                                        </xs:element>
                                    </xs:sequence>
                                </xs:complexType>
                            </xs:element>
                        </xs:sequence>
                    </xs:complexType>
                </xs:element>
            </xs:sequence>
        </xs:complexType>
    </xs:element>
</xs:schema>



`}
</pre>


        </Container>
      </div>
    );
  }
}

export default Schema;
