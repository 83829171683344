import React, { Component } from "react";
import axios from "axios";
import NavBar from "../Components/navbar";
import Header from "../Components/Header/index";
import Form from "../Components/Form/index";
import Button from "../Components/Button/index";
import DropList from "../Components/DropList";
import Table from "react-bootstrap/Table";
import Cookies, { set } from "js-cookie";
import settings from "../helpers/Settings";
import Spinner from "react-bootstrap/Spinner";
let { KayanURL } = settings;
/* const cookies = new Cookies();
cookies.set("SamaAdminId", "Admin", { path: "/" }); */
class KareemAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      employees: [],
      fieldType: "text",
      fieldName: "username",
    };
    this.onSubmitAddEmployee = this.onSubmitAddEmployee.bind(this);
    this.getAllCompanies();
  }

  getAllCompanies = async () => {
    const response = await axios.put(
      KayanURL + "api/KareemBenefits/company/getcompany",
      {
        apiToken: Cookies.get("KareemAdminId"),
      }
    );
    let companies = [];
    if (response && response["data"]) {
      if (response["data"]["success"]) {
        companies = response["data"]["companies"];
      }
    }
    if (companies) {
      this.setState({ companies: companies });
    }
  };
  //handle input text change in all forms
  handleInputTextChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    switch (name) {
      case "license":
        this.setState({
          data: { ...this.state.data, [name]: "{" + value + "}" },
          success: false,
          failed: false,
        });
        break;

      default:
        this.setState({
          data: { ...this.state.data, [name]: value },
          success: false,
          failed: false,
        });
        break;
    }
  };

  //on company name selection
  onSelection = (e) => {
    let select = document.getElementById(e);
    let name = select.name;
    let value = select.options[select.selectedIndex].value;

    this.setState({ data: { ...this.state.data, [name]: value } });
  };

  onCompanyNameSelection = async (e) => {
    let select = document.getElementById(e);
    let value = select.options[select.selectedIndex].value;
    this.setState({ selectedCompanyId: value });

    const companyResponse = await axios.put(
      KayanURL + "api/KareemBenefits/company/getbyid",
      {
        apiToken: Cookies.get("KareemAdminId"),
        companyId: value,
      }
    );

    if (companyResponse) {
      let data = companyResponse["data"];

      if (!data.error) {
        let { companyInfo } = data;
        if (companyInfo) {
          this.setState({ selectedCompany: companyInfo });
        }
      }
    }

    const EmployeesResponse = await axios.put(
      KayanURL + "api/KareemBenefits/employee/getempbycompanyid",
      { apiToken: Cookies.get("KareemAdminId"), companyId: value }
    );
    if (EmployeesResponse) {
      let data = EmployeesResponse["data"];
      if (data && !data.error) {
        this.setState({ employees: data.employees });
      } else {
        this.setState({ employees: [] });
      }
    }
  };

  //activate/deactivate company
  onActivationChangeCompany = (e) => {
    let { selectedCompanyId, selectedCompany } = this.state;
    axios
      .put(KayanURL + "api/KareemBenefits/company/changeActivationCompany", {
        apiToken: Cookies.get("KareemAdminId"),
        selectedCompanyId,
        active: !selectedCompany[0].active,
      })
      .then(({ data }) => {
        if (data.success) {
          selectedCompany[0].active = !selectedCompany[0].active;
          this.getAllCompanies();
        }
      });
  };
  //on submit change to company info
  onSubmitUpdateCompanyInfo = (e) => {
    e.preventDefault();
    let { fieldName, fieldValue, selectedCompanyId, selectedCompany } =
      this.state;
    axios
      .put(KayanURL + "api/KareemBenefits/company/updateCompanies", {
        [fieldName]: fieldValue,
        selectedCompanyId,
        apiToken: Cookies.get("KareemAdminId"),
      })
      .then(({ data }) => {
        if (data) {
          selectedCompany[0][fieldName] = fieldValue;
          this.setState({
            selectedCompany: selectedCompany,
            success: true,
          });
        } else {
          this.setState({ failed: true, updateError: data.error });
        }
      });
  };

  onSelectFieldName = (e) => {
    this.setState({
      success: false,
      failed: false,
      flag: false,
    });

    let select = document.getElementById(e);
    let value = select.options[select.selectedIndex].value;
    let fieldType =
      value == "password"
        ? "password"
        : value == "transactions"
        ? "number"
        : "text";
    this.setState({
      fieldType: fieldType,
      fieldName: value,
    });
  };

  //to select which field you want to update in company info
  onChangeCompanyUpdateField = (e) => {
    this.setState({ success: false, failed: false, flag: false });
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    this.setState({ fieldName, fieldValue });
  };
  /*--------                   Employee section             -----------*/

  //Employee Registration
  onSubmitAddEmployee = async (event) => {
    event.preventDefault();

    //user.apiToken = Cookies.get("BayanAdminID");
    let d = this.state.data;
    d["companyid"] = this.state.selectedCompanyId;
    this.setState({ data: d });
    await axios
      .post(KayanURL + "api/KareemBenefits/employee/addNew", {
        apiToken: Cookies.get("KareemAdminId"),
        data: this.state.data,
      })
      .then(async ({ data }) => {
        if (data.success) {
          this.setState({
            success: true,
            startAddNewEmployee: false,
          });
          const emp_data = await axios.put(
            KayanURL + "api/KareemBenefits/employee/getempbycompanyid",
            {
              companyId: this.state.selectedCompanyId,
              apiToken: Cookies.get("KareemAdminId"),
            }
          );

          if (emp_data && emp_data["data"]) {
            if (emp_data["data"].success) {
              this.setState({ employees: emp_data["data"]["employees"] });
            }
          }

          alert("new employee added successfully");
        } else {
          this.setState({ failed: data.error });
        }
      });
  };

  onSubmitDeleteEmployee = async (event) => {
    event.preventDefault();
    let { employeeId, employees } = this.state;
    await axios
      .put(KayanURL + "api/KareemBenefits/employee/deleteemployee", {
        id: employeeId,
        username: employees[0].username,
        companyid: employees[0].companyid,
        apiToken: Cookies.get("KareemAdminId"),
      })
      .then(async ({ data }) => {
        if (data.success) {
          this.setState({
            success: true,
            DeleteEmployee: false,
          });

          const emp_data = await axios.put(
            KayanURL + "api/KareemBenefits/employee/getempbycompanyid",
            {
              companyId: this.state.selectedCompanyId,
              apiToken: Cookies.get("KareemAdminId"),
            }
          );

          this.setState({ employees: emp_data["data"].employees });
        } else {
          this.setState({ failed: data.error });
        }
      });
  };

  onActivationChangeEmployee = (e) => {
    e.preventDefault();
    let { employeeId, selectedEmployee } = this.state;

    axios
      .put(KayanURL + "api/KareemBenefits/employee/changeActivationEmployee", {
        apiToken: Cookies.get("KareemAdminId"),
        employeeId,
        active: !selectedEmployee.active,
      })
      .then(({ data }) => {
        if (data.success) {
          selectedEmployee.active = !selectedEmployee.active;
          this.setState({
            DeactiveEmployee: false,
            ActiveEmployee: false,
          });
          this.getAllCompanies();
        }
      })
      .catch(() => {
        this.setState({ serverError: "There are an server error" });
      });
  };

  onSubmitUpdateEmployeeInfo = (e) => {
    e.preventDefault();
    let { fieldName, fieldValue, employeeId, selectedEmployee } = this.state;

    axios
      .put(KayanURL + "api/KareemBenefits/employee/updateemployeeAdmin", {
        [fieldName]: fieldValue,
        employeeId,
        apiToken: Cookies.get("KareemAdminId"),
      })
      .then(({ data }) => {
        if (data.success) {
          selectedEmployee[fieldName] = fieldValue;
          this.setState({
            selectedEmployee: selectedEmployee,
            success: true,
          });
        } else {
          this.setState({ failed: true, EmployeeError: data.error });
        }
      })
      .catch(() => {
        this.setState({ serverError: "server error" });
      });
  };

  //on submit Company registration
  onSubmit = (event) => {
    event.preventDefault();
    let { data } = this.state;

    this.setState({
      CompanyRegistrationSucccess: false,
      CompanyRegistrationFailed: false,
      flag: true,
    });
    axios
      .post(KayanURL + "api/KareemBenefits/company/addnew", {
        data,
        apiToken: Cookies.get("KareemAdminId"),
      })
      .then(({ data }) => {
        if (data && data.success) {
          this.setState({
            CompanyRegistrationSucccess: true,
            startAddCompany: false,
          });
          alert("company registered successfully");
          this.getAllCompanies();
        } else {
          this.setState({ CompanyRegistrationFailed: data.error });
        }
      });
  };
  render() {
    let { companies, employees, fieldName, fieldType, selectedCompany, flag } =
      this.state;
    //console.log(selectedCompany);
    var companyTable;
    if (selectedCompany && selectedCompany.length > 0) {
      companyTable = selectedCompany.map((row, index) => {
        let ISODateTime = row["endedat"];
        let date = ISODateTime.split("T")[0];
        date = date.split("-").reverse().join("/");
        let time = ISODateTime.split("T")[1].slice(0, -8);
        let datetime = date + " " + time;
        return (
          <tr key={index}>
            <td>{row.name}</td>
            <td>{row.username}</td>
            <td>{row["active"] === true ? "Active" : "Not Active"}</td>
            <td>{row["approve"] === true ? "Approved" : "Not Approved"}</td>

            <td>{datetime}</td>
            <td>{row["transactions"]}</td>
            <td>{row["token"]}</td>
            <td>
              {!row["active"] ? (
                <Button
                  onClick={() => this.onActivationChangeCompany(row.id, index)}
                  Style="BS12"
                  Value="Activate"
                />
              ) : (
                <Button
                  onClick={() => this.onActivationChangeCompany(row.id, index)}
                  Style="BS12"
                  Value="Deactivate"
                />
              )}

              <Button
                Style="BS12"
                Value="Update"
                onClick={() =>
                  this.setState({
                    startUpdateCompany: true,
                    success: false,
                    failed: false,
                  })
                }
              />
            </td>
          </tr>
        );
      });
    }

    let companyNames = [],
      companyIds = [];
    if (companies && companies.length > 0) {
      companies.forEach((c) => {
        companyNames.push(c["name"]);
        companyIds.push(c["id"]);
      });
    }

    //employees table
    var employeesTable;
    if (employees && employees.length > 0) {
      employeesTable = employees.map((row, index) => {
        return (
          <tr key={index}>
            <td>{row.name}</td>
            <td>{row.username}</td>

            <td>{row.active === true ? "Active" : "Not Active"}</td>
            <td>
              {!row["active"] ? (
                <Button
                  onClick={() =>
                    this.setState({
                      ActiveEmployee: true,
                      selectedEmployee: row,
                      employeeId: row.id,
                    })
                  }
                  Style="BS12"
                  Value="Activate"
                />
              ) : (
                <Button
                  onClick={() =>
                    this.setState({
                      DeactiveEmployee: true,
                      selectedEmployee: row,
                      employeeId: row.id,
                    })
                  }
                  Style="BS12"
                  Value="Deactivate"
                />
              )}

              <Button
                onClick={() =>
                  this.setState({
                    startUpdateEmployeeForm: true,
                    selectedEmployee: row,
                    employeeId: row.id,
                  })
                }
                Style="BS12"
                Value="Update"
              />

              <Button
                onClick={() =>
                  this.setState({
                    DeleteEmployee: true,
                    selectedEmployee: row,
                    employeeId: row.id,
                  })
                }
                Style="dangerBS1"
                Value="Delete"
              />
            </td>
          </tr>
        );
      });
    }

    return (
      <>
        <NavBar />

        <Header
          projectType="samaAdmin"
          name={"superAdmin"}
          history={this.props.history}
        />

        <div style={{ height: "60px" }}></div>

        <div className="container">
          {/*main screen*/}
          <div
            style={{
              width: "70%",
              margin: "auto",
              border: "0",
              padding: "50px",
              boxShadow:
                "0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)",
            }}
          >
            <DropList
              id="companyname"
              name="companyid"
              Title="company Name"
              Options={companyNames}
              Values={companyIds}
              onSelect={this.onCompanyNameSelection}
            />

            <p
              style={{
                fontWeight: "bold",
                fontSize: "15px",
                textAlign: "center",
                cursor: "pointer",
                "margin-bottom": "-20px",
              }}
              onClick={() =>
                this.setState({ startAddCompany: true, error: null })
              }
            >
              {" "}
              Create new company{" "}
            </p>
          </div>

          {/*pop up company registration screen */}
          {this.state.startAddCompany ? (
            <div className="popup">
              <div
                className="form"
                style={{
                  width: "50%",
                  margin: "auto",
                  backgroundColor: "white",
                }}
              >
                <Form
                  onSubmit={this.onSubmit}
                  inputStyle="IT3"
                  submitStyle="BS10"
                  onSelectOption={this.onSelection}
                  onInputTextChange={this.handleInputTextChange}
                  inputsTypes={[
                    "text",
                    "text",
                    "select",
                    "text",
                    "text",
                    "text",
                    "text",
                    "email",
                    "tel",
                    "text",
                    "text",
                    "password",
                  ]}
                  elementsValues={[
                    "Api Token",
                    "Company Name",
                    [
                      ["Payer", "Provider", "TPA"],
                      ["Payer", "Provider", "TPA"],
                    ],
                    "License",
                    "Country",
                    "City",
                    "Address",
                    "E-Mail",
                    "Phone",
                    "Contact Person",
                    "User Name",
                    "Password ",
                    "Sign Up",
                  ]}
                  elementsNames={[
                    "token",
                    "name",
                    "type",
                    "license",
                    "country",
                    "city",
                    "address",
                    "email",
                    "phone",
                    "contactPerson",
                    "username",
                    "password",
                  ]}
                />

                {this.state.CompanyRegistrationSucccess &&
                !this.state.CompanyRegistrationFailed ? (
                  <Spinner size="lg" animation="grow" variant="success" />
                ) : this.state.CompanyRegistrationSucccess ? (
                  <p style={{ color: "green" }}>
                    company registered successfully
                  </p>
                ) : this.state.CompanyRegistrationFailed ? (
                  <p style={{ color: "red", width: "70%", margin: "auto" }}>
                    {this.state.CompanyRegistrationFailed}
                  </p>
                ) : null}

                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "right",
                    cursor: "pointer",
                    margin: "-30px 2%",
                  }}
                  onClick={() =>
                    this.setState({
                      startAddCompany: false,
                      CompanyRegistrationFailed: {},
                    })
                  }
                >
                  {" "}
                  Close{" "}
                </p>
              </div>
            </div>
          ) : null}

          {/*pop up employee registration screen*/}
          {this.state.startAddNewEmployee ? (
            <div className="popup">
              <div
                className="form"
                style={{
                  width: "50%",
                  margin: "auto",
                  backgroundColor: "white",
                }}
              >
                <Form
                  onSubmit={this.onSubmitAddEmployee}
                  inputStyle="IT3"
                  submitStyle="BS10"
                  onSelectOption={this.onSelection}
                  onInputTextChange={this.handleInputTextChange}
                  inputsTypes={[
                    "text",
                    "text",
                    "text",
                    "text",
                    "password",
                    "password",
                  ]}
                  elementsValues={[
                    "Name",
                    "User Name",
                    "city",
                    "dhpouser",
                    "dhpopassword",
                    "Password",

                    "Add Employee",
                  ]}
                  elementsNames={[
                    "name",
                    "username",
                    "city",
                    "dhpouser",
                    "dhpopassword",
                    "password",
                  ]}
                />

                {this.state.success && !this.state.failed ? (
                  <Spinner size="lg" animation="grow" variant="success" />
                ) : this.state.success ? (
                  <p style={{ color: "green" }}>
                    new employee added successfully
                  </p>
                ) : this.state.failed ? (
                  <p style={{ color: "red" }}>{this.state.failed}</p>
                ) : null}

                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "right",
                    cursor: "pointer",
                    margin: "0 2%",
                  }}
                  onClick={() =>
                    this.setState({
                      startAddNewEmployee: false,
                      success: false,
                      failed: false,
                    })
                  }
                >
                  {" "}
                  Close{" "}
                </p>
              </div>
            </div>
          ) : null}

          {/*company update screen*/}

          {this.state.startUpdateCompany ? (
            <div className="popup">
              <div
                className="form"
                style={{
                  width: "50%",
                  margin: "auto",
                  backgroundColor: "white",
                }}
              >
                <Form
                  onSubmit={this.onSubmitUpdateCompanyInfo}
                  inputsTypes={["select", fieldType]}
                  inputStyle="IT3"
                  submitStyle="BS10"
                  onSelectOption={this.onSelectFieldName}
                  onInputTextChange={this.onChangeCompanyUpdateField}
                  elementsValues={[
                    [
                      ["username", "password", "transactions", "endedat"],
                      ["username", "password", "transactions", "endedat"],
                    ],
                    fieldName,
                    "Update",
                  ]}
                  elementsNames={["field", fieldName]}
                />

                {!this.state.success && this.state.failed ? (
                  <Spinner size="lg" animation="grow" variant="success" />
                ) : this.state.success ? (
                  <p style={{ color: "green" }}>
                    {fieldName} updated successfully
                  </p>
                ) : this.state.failed ? (
                  <p style={{ color: "red", width: "70%", margin: "auto" }}>
                    {this.state.updateError}
                  </p>
                ) : null}

                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "right",
                    cursor: "pointer",
                    margin: "0 2%",
                  }}
                  onClick={() =>
                    this.setState({
                      startUpdateCompany: false,
                      fieldName: "username",
                      fieldType: "text",
                      success: false,
                      failed: false,
                      flag: false,
                    })
                  }
                >
                  {" "}
                  Close{" "}
                </p>
              </div>
            </div>
          ) : null}

          {/*pop up employee update screen*/}
          {this.state.startUpdateEmployeeForm ? (
            <div className="popup">
              <div
                className="form"
                style={{
                  width: "50%",
                  margin: "auto",
                  backgroundColor: "white",
                }}
              >
                <Form
                  onSubmit={this.onSubmitUpdateEmployeeInfo}
                  inputsTypes={["select", fieldType]}
                  inputStyle="IT3"
                  submitStyle="BS10"
                  onSelectOption={this.onSelectFieldName}
                  onInputTextChange={this.onChangeCompanyUpdateField}
                  elementsValues={[
                    [
                      ["username", "password", "name"],
                      ["username", "password", "name"],
                    ],
                    fieldName,
                    "Update",
                  ]}
                  elementsNames={["field", fieldName]}
                />

                {this.state.success && this.state.failed ? (
                  <Spinner size="lg" animation="grow" variant="success" />
                ) : this.state.success ? (
                  <p style={{ color: "green" }}>
                    {fieldName} updated successfully
                  </p>
                ) : this.state.failed ? (
                  <p style={{ color: "red", width: "70%", margin: "auto" }}>
                    {this.state.EmployeeError}
                  </p>
                ) : null}
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "right",
                    cursor: "pointer",
                    margin: "0 2%",
                  }}
                  onClick={() =>
                    this.setState({
                      startUpdateEmployeeForm: false,
                      fieldName: "username",
                      fieldType: "text",
                      success: false,
                      failed: false,
                    })
                  }
                >
                  {" "}
                  Close{" "}
                </p>
              </div>
            </div>
          ) : null}

          {/*pop up employee update screen*/}
          {this.state.DeactiveEmployee ? (
            <div className="popup">
              <div
                className="form"
                style={{
                  width: "50%",
                  margin: "auto",
                  backgroundColor: "white",
                }}
              >
                <form>
                  <h4>
                    Are you sure to Deavtive {this.state.selectedEmployee.name}?
                  </h4>
                  <Button
                    Style="BS12"
                    Value="Yes"
                    onClick={this.onActivationChangeEmployee}
                  />
                  <Button
                    Style="BS12"
                    Value="No"
                    onClick={() =>
                      this.setState({
                        DeactiveEmployee: false,
                      })
                    }
                  />
                </form>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "right",
                    cursor: "pointer",
                    margin: "0 2%",
                  }}
                ></p>
              </div>
            </div>
          ) : null}

          {this.state.ActiveEmployee ? (
            <div className="popup">
              <div
                className="form"
                style={{
                  width: "50%",
                  margin: "auto",
                  backgroundColor: "white",
                }}
              >
                <form>
                  <h4>
                    Are you sure to Active {this.state.selectedEmployee.name}?
                  </h4>
                  <Button
                    Style="BS12"
                    Value="Yes"
                    onClick={this.onActivationChangeEmployee}
                  />
                  <Button
                    Style="BS12"
                    Value="No"
                    onClick={() =>
                      this.setState({
                        ActiveEmployee: false,
                      })
                    }
                  />
                </form>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "right",
                    cursor: "pointer",
                    margin: "0 2%",
                  }}
                ></p>
              </div>
            </div>
          ) : null}

          {this.state.DeleteEmployee ? (
            <div className="popup">
              <div
                className="form"
                style={{
                  width: "50%",
                  margin: "auto",
                  backgroundColor: "white",
                }}
              >
                <form>
                  <h4>
                    Are you sure to Delete{" "}
                    {this.state.selectedEmployee.username}?
                  </h4>
                  <Button
                    Style="BS12"
                    Value="Yes"
                    onClick={this.onSubmitDeleteEmployee}
                  />
                  <Button
                    Style="BS12"
                    Value="No"
                    onClick={() =>
                      this.setState({
                        DeleteEmployee: false,
                      })
                    }
                  />
                </form>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "right",
                    cursor: "pointer",
                    margin: "0 2%",
                  }}
                ></p>
              </div>
            </div>
          ) : null}

          {/*selected company information*/}
          {selectedCompany && selectedCompany.length > 0 ? (
            <div className="container" style={{ marginTop: "60px" }}>
              <p style={{ textAlign: "left" }}>Company Information:</p>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Company Name</th>
                    <th>User Name</th>
                    <th>Active Status</th>
                    <th>Approve Status</th>
                    <th>Activation End Date</th>
                    <th>Transactions</th>
                    <th>apiToken </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{companyTable}</tbody>
              </Table>
            </div>
          ) : null}

          {/*employees of selected comapny*/}
          {employees.length > 0 ? (
            <div style={{ marginTop: "60px" }}>
              <p style={{ textAlign: "left" }}>
                {" "}
                Company Employees Information:
              </p>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Employee Name</th>
                    <th>User Name</th>

                    <th>Active Status</th>
                    <th>Update</th>
                  </tr>
                </thead>
                <tbody>{employeesTable}</tbody>
              </Table>
            </div>
          ) : selectedCompany && selectedCompany.length > 0 ? (
            <h5 style={{ color: "rgb(58, 180, 73)", margin: "30px" }}>
              There are no employees in this company
            </h5>
          ) : null}

          {selectedCompany && selectedCompany.length > 0 ? (
            <p
              className="BS10"
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.setState({
                  startAddNewEmployee: true,
                  success: false,
                  failed: false,
                })
              }
            >
              Add New Employee{" "}
            </p>
          ) : null}
        </div>

        <div style={{ height: "60px" }}></div>
      </>
    );
  }
}
export default KareemAdmin;
