import React from "react";
import "./Codes.css";
import SamaListView from "../SamaListView";
import Button from "../Button";
import Input from "../InputText";
import DropList from "../DropList";
import ProgressBar from "react-bootstrap/ProgressBar";

function defaultClick(e) {
  e.preventDefault();
}

function SamaDiagnosisCodesList1(props) {
  let {
    Items,
    addICDsCode,
    inputStyle,
    listName,
    onSelect,
    section,
    BtnActive,
    attributes,
    suggestions,
    onChange,
    onClick,
    userInputDiagnosis,
    activeSuggestion,
    filteredSuggestionsDiagnosis,
    showDiagnosisSuggestions,
    handleItemDeletion,
    diagnosisNow,
    code,
    placeholder,
    codeIndex,
    editIndex,
  } = props;

  let Options;

  let suggestionsListComponentDiagnosis;

  if (showDiagnosisSuggestions && userInputDiagnosis) {
    if (filteredSuggestionsDiagnosis && filteredSuggestionsDiagnosis.length) {
      suggestionsListComponentDiagnosis = (
        <ul className="suggestions">
          {filteredSuggestionsDiagnosis.map((suggestion, index) => {
            let className;

            // Flag the active suggestion with a class
            if (index === activeSuggestion) {
              className = "suggestion-active";
            }

            return (
              <li className={className} key={suggestion} onClick={onClick}>
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    } else {
      suggestionsListComponentDiagnosis = (
        <div className="no-suggestions">
          <em>No suggestions...</em>
        </div>
      );
    }
  }

  let InputId = "",
    BtnValue = "";
  if (listName === "DiagnosisCodes") {
    InputId = "DiagnosisCodes";
    BtnValue = "Confirm Code";
  }

  return (
    <React.Fragment>
      {Items && Items.length > 0 ? (
        <SamaListView
          Items={Items}
          name="codes"
          handleItemDeletion={handleItemDeletion}
          listName={listName}
        />
      ) : null}

      {!(diagnosisNow === 100 && suggestions) ? (
        <ProgressBar
          style={{ width: "70%", margin: "25px auto" }}
          animated
          variant="success"
          now={diagnosisNow}
        />
      ) : null}

      <Input
        //key={index}
        id={InputId}
        disabled={suggestions === undefined ? true : false}
        name="Code"
        Style={inputStyle}
        onChange={(e) => onChange(e, listName, codeIndex)}
        handleInputTextChangeForAddNewDiag={(e) => {
          this.handleInputTextChangeForAddNewDiag(e);
        }}
        hint={placeholder}
        value={
          listName === "DiagnosisCodes" && editIndex == codeIndex
            ? userInputDiagnosis
            : null
        }
      />

      {listName === "DiagnosisCodes" && editIndex == codeIndex
        ? suggestionsListComponentDiagnosis
        : null}

      {listName === "DiagnosisCodes" &&
      section === "Diagnosis" &&
      BtnActive &&
      editIndex == codeIndex ? (
        <React.Fragment>
          {attributes && attributes.length > 0
            ? attributes.map((attribute, index) => (
                <DropList
                  key={index}
                  id="diagnosisType"
                  Title="Diagnosis Type"
                  name="DiagnosisType"
                  Options={Options}
                  Values={Options}
                  defaultValue={Options[0]}
                  onSelect={onSelect}
                  Style="requestSelect"
                />
              ))
            : null}
          <Button
            Style="enabledStyle"
            Value={BtnValue}
            onClick={(e) => addICDsCode(e, code, section)}
          />
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}
export default SamaDiagnosisCodesList1;
