module.exports = [
  {
    code: "93976",
    shortdesc: "DUP-SCAN ARTL FLO ABDL/PEL/SCROT&/RPR ORGN LMT",
  },
  { code: "67036", shortdesc: "VITRECTOMY MECHANICAL PARS PLANA" },
  { code: "49440", shortdesc: "INSERT GASTROSTOMY TUBE PERCUTANEOUS" },
  {
    code: "36400",
    shortdesc: "VNPNXR <3 YEARS PHY/QHP SKILL FEMRAL/JUGLAR VEIN",
  },
  { code: "86063", shortdesc: "ANTISTREPTOLYSIN O SCREEN" },
  {
    code: "22102",
    shortdesc: "PRTL EXC PST VRT INTRNSC B1Y LES 1 VRT SGM LMBR",
  },
  {
    code: "36904",
    shortdesc: "PERQ THRMBC/NFS DIALYSIS CIRCUIT IMG DX ANGRPH",
  },
  { code: "33417", shortdesc: "AORTOPLASTY SUPRAVALVULAR STENOSIS" },
  { code: "66770", shortdesc: "DSTRJ CYST/LESION IRIS/CILIARY BODY" },
  { code: "27310", shortdesc: "ARTHRT KNE W/EXPL DRG/RMVL FB" },
  {
    code: "93308",
    shortdesc: "ECHO TRANSTHORC R-T 2D W/WO M-MODE REC F-UP/LMTD",
  },
  {
    code: "86008",
    shortdesc: "ALLERGEN SPEC IGE RECOMBINANT/PURIFIED COMPNT EA",
  },
  {
    code: "29904",
    shortdesc: "ARTHRS SUBTALAR JOINT REMOVE LOOSE/FOREIGN BODY",
  },
  {
    code: "15176",
    shortdesc: "ACLR DRM RPLCMT F/S/N/H/F/G/M/D GT EA 100 CM/EA",
  },
  { code: "96549", shortdesc: "UNLISTED CHEMOTHERAPY PROCEDURE" },
  { code: "81213", shortdesc: "BRCA1&BRCA2 ANAL UNCOMMON DUP/DEL VARIANTS" },
  { code: "3273F", shortdesc: "HIGH RISK OF RECURRENCE PROSTATE CANCER" },
  { code: "76999", shortdesc: "UNLISTED US PROCEDURE" },
  {
    code: "58340",
    shortdesc: "CATH & SALINE/CONTRAST SONOHYSTER/HYSTEROSALPI",
  },
  {
    code: "34820",
    shortdesc: "OPN ILIAC ART EXPOS PROSTH/ILIAC OCCLS EVASC UNI",
  },

  {
    code: "60260",
    shortdesc: "THYROIDECTOMY RMVL REMAINING TISS FLWG PRTL RMVL",
  },
  { code: "0207T", shortdesc: "EVAC MEIBOMIAN GLNDS AUTO HT& INTMT PRESS UNI" },
  { code: "01432", shortdesc: "ANES KNEE & POPLITEAL ARTERY VEIN FISTULA NOS" },
  { code: "86727", shortdesc: "ANTIBODY LYMPHOCYTIC CHORIOMENINGITIS" },
  { code: "78707", shortdesc: "KIDNEY IMG MORPHOLOGY VASCULAR FLOW 1 W/O RX" },
  {
    code: "31578",
    shortdesc: "LARYNGOSCOPY FLEXIBLE RMVL LESION(S) NON-LASER",
  },
  { code: "01840", shortdesc: "ANESTHESIA ARTERIES FOREARM WRIST & HAND NOS" },
  { code: "65757", shortdesc: "BACKBENCH PREPJ CORNEAL ENDOTHELIAL ALLOGRAFT" },
  { code: "27454", shortdesc: "OSTEOT MLT W/RELIGNMT IMED ROD FEM SHFT" },
  { code: "80342", shortdesc: "ANTIPSYCHOTICS NOT OTHERWISE SPECIFIED 1-3" },
  { code: "27599", shortdesc: "UNLISTED PROCEDURE FEMUR/KNEE" },
  { code: "27562", shortdesc: "CLOSED TX PATELLAR DISLOCATION W/ANESTHESIA" },
  {
    code: "87535",
    shortdesc: "IADNA HIV-1 AMPLIFIED PROBE & REVERSE TRANSCRPJ",
  },
  {
    code: "33891",
    shortdesc: "BYP GRF W/DESCENDING THORACIC AORTA RPR NECK INC",
  },
  { code: "88107", shortdesc: "CYTP FLU BR/WA XCPT C/V SMRS&FILTER INTERPJ" },
  {
    code: "86978",
    shortdesc: "PRETX SERUM RBC ANTIBODY ID DIFFIAL EACH ABSRPJ",
  },
  { code: "64895", shortdesc: "NERVE GRAFT MLT STRANDS HAND/FOOT </4 CM" },
  {
    code: "15276",
    shortdesc: "SUB GRFT F/S/N/H/F/G/M/D<100SQ CM EA ADDL25SQ CM",
  },
  { code: "86900", shortdesc: "BLOOD TYPING SEROLOGIC ABO" },
  { code: "99155", shortdesc: "MOD SED OTHER PHYS/QHP INITIAL 15 MINS <5 YRS" },
  { code: "1182F", shortdesc: "NEUROPSYCHIATRIC SYMPTOMS ONE OR MORE PRESENT" },
  {
    code: "44227",
    shortdesc: "LAPS CLSR NTRSTM LG/SM INT W/RESCJ & ANASTOMOSIS",
  },
  { code: "29827", shortdesc: "ARTHROSCOPY SHOULDER ROTATOR CUFF REPAIR" },
  { code: "93458", shortdesc: "CATH PLMT L HRT & ARTS W/NJX & ANGIO IMG S&I" },
  { code: "81355", shortdesc: "VKORC1 GENE ANALYSIS COMMON VARIANT(S)" },
  { code: "63295", shortdesc: "OSTPL RCNSTJ DORSAL SPI ELMNTS FLWG ISPI PX" },
  {
    code: "81535",
    shortdesc: "ONCOLOGY GYNE LIVE TUM CELL CLTR&CHEMO RESP 1ST",
  },
  { code: "10005", shortdesc: "null" },
  { code: "87324", shortdesc: "IAAD IA CLOSTRIDIUM DIFFICILE TOXIN" },
  {
    code: "78135",
    shortdesc: "RBC SURVIVAL STUDY DIFFERNTL ORGAN/TISS KINETICS",
  },
  {
    code: "00792",
    shortdesc: "ANES LAPS PARTIAL HEPATECTOMY W/MGMT LIVER HEMOR",
  },
  { code: "27641", shortdesc: "PARTIAL EXCISION BONE FIBULA" },
  { code: "75625", shortdesc: "AORTOGRAPHY ABDOMINAL SERIALOGRAPHY RS&I" },
  { code: "57600", shortdesc: "null" },
  { code: "92997", shortdesc: "PRQ TRLUML PULMONARY ART BALLOON ANGIOP 1 VSL" },
  { code: "28636", shortdesc: "PRQ SKEL FIXJ METATARSOPHLNGL JT DISLC W/MANJ" },
  { code: "77763", shortdesc: "INTRACAVITARY RADIATION SOURCE APPLIC COMPLEX" },
  {
    code: "42330",
    shortdesc: "SIALOT SUBMNDBLR SUBLNGL/PRTD UNCOMP INTRAORAL",
  },
  {
    code: "0350T",
    shortdesc: "RADIOSTEREOMETRIC ANALYSIS LOWER EXTREMITY EXAM",
  },
  {
    code: "97533",
    shortdesc: "SENSORY INTEGRATIVE TECHNIQUES EACH 15 MINUTES",
  },
  { code: "88313", shortdesc: "SPCL STN 2 I&R EXCPT MICROORG/ENZYME/IMCYT" },
  { code: "86603", shortdesc: "ANTIBODY ADENOVIRUS" },
  { code: "75870", shortdesc: "VENOGRAPHY SUPERIOR SAGITTAL SINUS RS&I" },
  { code: "3216F", shortdesc: "DOCUMENTED IMMUNITY HEPATITIS B" },
  { code: "96412", shortdesc: "null" },
  { code: "80357", shortdesc: "DRUG SCREENING KETAMINE AND NORKETAMINE" },
  { code: "82300", shortdesc: "CADMIUM" },
  { code: "43410", shortdesc: "SUTR ESOPHGL WND/INJ CRV APPR" },
  { code: "80006", shortdesc: "null" },
  {
    code: "90810",
    shortdesc: "INDIV PSYCTX INTERACTIVE OFFICE/OUTPT 20-30 MIN",
  },
  {
    code: "78815",
    shortdesc: "PET IMAGING CT ATTENUATION SKULL BASE MID-THIGH",
  },
  { code: "90672", shortdesc: "LAIV4 VACCINE FOR INTRANASAL USE" },
  { code: "3455F", shortdesc: "TB SCRNG DONE INTRPD </6 MOS START RA THXPY" },
  { code: "21070", shortdesc: "CORONOIDECTOMY SEPARATE PROCEDURE" },
  { code: "80068", shortdesc: "null" },
  { code: "92504", shortdesc: "BINOCULAR MICROSCOPY SEPARATE DX PROCEDURE" },
  { code: "81005", shortdesc: "URINALYSIS QUAL/SEMIQUANT EXCEPT IMMUNOASSAYS" },
  {
    code: "35697",
    shortdesc: "RIMPLTJ VISC ART INFRARNL AORTIC PROSTH EA ART",
  },
  { code: "0455T", shortdesc: "REMVL PERM IMPLT AORTIC VENTR COMPLETE SYSTEM" },
  { code: "27501", shortdesc: "CLTX SPRCNDYLR/TRNSCNDYLR FEM FX W/O MANJ" },
  { code: "56343", shortdesc: "null" },
  {
    code: "77409",
    shortdesc: "RADJ DLVR 2 AREAS 3/>PORTS 1 MLT BLKS 11-19MEV",
  },
  {
    code: "87147",
    shortdesc: "CULTURE TYPING IMMUNOLOGIC OTH/THN IMMUNOFLUORES",
  },
  { code: "27605", shortdesc: "TENOTOMY PRQ ACHILLES TENDON SPX LOCAL ANES" },
  { code: "27792", shortdesc: "OPEN TX DISTAL FIBULAR FRACTURE LAT MALLEOLUS" },
  { code: "78610", shortdesc: "BRAIN IMAGING VASCULAR FLOW ONLY" },
  { code: "38212", shortdesc: "TRNSPL PREPJ HEMATOP PROGEN RED BLD CELL RMVL" },
  { code: "25624", shortdesc: "CLOSED TX CARPAL SCAPHOID FRACTURE W/MANJ" },
  { code: "65286", shortdesc: "RPR LAC APPL TISSUE GLUE WOUND CORNEA&/SCLERA" },
  { code: "27284", shortdesc: "ARTHRODESIS HIP JOINT W/OBTAINING GRAFT" },
  { code: "00540", shortdesc: "ANES THORACOTOMY & THORACOSCOPY NOS" },
  { code: "58920", shortdesc: "WEDGE RESCJ/BISCTJ OVARY UNI/BI" },
  { code: "67343", shortdesc: "RLS XTNSV SCAR TISS W/O DETACHING EO MUSC SPX" },
  { code: "33864", shortdesc: "ASCENDING AORTA GRF VALVE SPARE ROOT REMODEL" },
  { code: "00216", shortdesc: "ANESTHESIA INTRACRANIAL VASCULAR PROCEDURE" },
  { code: "69401", shortdesc: "EUSTACHIAN TUBE NFLTJ TRANSNSL W/O CATHJ" },
  { code: "69905", shortdesc: "LABYRINTHECTOMY TRANSCANAL" },
  { code: "64611", shortdesc: "CHEMODENERV PAROTID&SUBMANDIBL SALIVARY GLNDS" },
  { code: "88029", shortdesc: "NECROPSY GROSS&MCRSCP STILLBORN/NEWBORN BRAIN" },
  { code: "35870", shortdesc: "RPR GRF-ENTERIC FSTL" },
  { code: "26531", shortdesc: "ARTHRP MTCARPHLNGL JT W/PROSTC IMPLT EA JT" },
  {
    code: "23472",
    shortdesc: "ARTHROPLASTY GLENOHUMERAL JOINT TOTAL SHOULDER",
  },
  {
    code: "50690",
    shortdesc: "NJX VISUALIZATION ILEAL CONDUIT&/URETEROPYELOG",
  },
  { code: "81310", shortdesc: "NPM1 NUCLEOPHOSMIN GENE ANAL EXON 12 VARIANTS" },
  { code: "44186", shortdesc: "LAPAROSCOPY SURGICAL JEJUNOSTOMY" },
  {
    code: "26442",
    shortdesc: "TENOLYSIS FLEXOR TENDON PALM&FINGER EACH TENDO",
  },
  { code: "74210", shortdesc: "RADEX PHARYNX&/CERVICAL ESOPHAGUS" },
  {
    code: "33504",
    shortdesc: "RPR ANOM CORONARY ART PULM ART ORIGIN GRF W/BYP",
  },
  { code: "0311T", shortdesc: "null" },
  {
    code: "95905",
    shortdesc: "MOTOR &/SENS NRV CNDJ PRECONF ELTRD ARRAY LIMB",
  },
  {
    code: "78496",
    shortdesc: "CARD BL POOL GATED 1 STDY REST RT VENT EJCT FRCT",
  },
  { code: "31781", shortdesc: "EXC TRACHEAL STENOSIS&ANAST CERVICOTHORACIC" },
  { code: "90653", shortdesc: "IIV ADJUVANTED VACCINE FOR INTRAMUSCULAR USE" },
  {
    code: "87086",
    shortdesc: "CULTURE BACTERIAL QUANTTATIVE COLONY COUNT URINE",
  },
  {
    code: "99080",
    shortdesc: "SPEC REPORTS > USUAL MED COMUNICAJ/STAND RPRTG",
  },
  { code: "95834", shortdesc: "MUSC TSTG MNL W/REPRT TOTAL EVAL BODY W/HANDS" },
  {
    code: "29894",
    shortdesc: "ARTHROSCOPY ANKLE W/REMOVAL LOOSE/FOREIGN BODY",
  },
  { code: "28476", shortdesc: "PRQ SKEL FIXJ METAR FX W/MANJ" },
  { code: "53899", shortdesc: "UNLISTED PROCEDURE URINARY SYSTEM" },
  { code: "80173", shortdesc: "DRUG SCREEN QUANTITATIVE HALOPRIDOL" },
  { code: "85347", shortdesc: "COAGULATION TIME ACTIVATED" },
  { code: "49323", shortdesc: "LAPS SURG W/DRG LYMPHOCELE PRTL CAVITY" },
  {
    code: "89261",
    shortdesc: "SPRM ISOL CPLX PREP INSEMINATION/DX SEMEN ALYS",
  },
  {
    code: "4015F",
    shortdesc: "PRSISTENT ASTHMA LONG TERM CTRL MED PRESCRIBED",
  },
  { code: "36584", shortdesc: "RPLCMT COMPL PRPH CVC W/O SUBQ PORT/PMP" },
  { code: "88180", shortdesc: "null" },
  { code: "0040U", shortdesc: "null" },
  { code: "90720", shortdesc: "DTP-HIB VACCINE INTRAMUSCULAR" },
  { code: "52234", shortdesc: "CYSTO W/REMOVAL OF TUMORS SMALL" },
  { code: "17102", shortdesc: "null" },
  {
    code: "52442",
    shortdesc: "CYSTO INSERTION TRANSPROSTATIC IMPLANT EA ADDL",
  },
  { code: "70170", shortdesc: "DACRYOCSTOGRAPY NASOLACRIMAL DUCT RS&I" },
  {
    code: "00322",
    shortdesc: "ANES ESOPH THYRD LARX TRACH & LYMPH NCK BX THYRD",
  },
  {
    code: "87140",
    shortdesc: "CULTURE TYPING IMMUNOFLUORESCENT EACH ANTISERUM",
  },
  { code: "99454", shortdesc: "null" },
  {
    code: "65805",
    shortdesc: "PARACENTSIS ANT CHAM EYE W/THER REL AQUEOUS SPX",
  },
  { code: "63001", shortdesc: "LAM W/O FACETEC FORAMOT/DSKC 1/2 VRT SEG CRV" },
  { code: "15834", shortdesc: "EXCISION EXCESSIVE SKIN & SUBQ TISSUE HIP" },
  {
    code: "90646",
    shortdesc: "HEMOPHILUS INFLUENZA B VACCINE PRP-D BOOSTER IM",
  },
  { code: "90681", shortdesc: "RV1 VACCINE 2 DOSE SCHEDULE LIVE FOR ORAL USE" },
  { code: "25150", shortdesc: "PARTIAL EXCISION BONE ULNA" },
  {
    code: "4301F",
    shortdesc: "PT NOT RCVNG WARFARIN THXPY NONVALV AFIB/AFLUT",
  },
  { code: "24105", shortdesc: "EXCISION OLECRANON BURSA" },
  { code: "86762", shortdesc: "ANTIBODY RUBELLA" },
  {
    code: "15935",
    shortdesc: "EXC SACRAL PR ULCER W/SKN FLAP CLSR W/OSTECTOMY",
  },
  { code: "25350", shortdesc: "OSTEOTOMY RADIUS DISTAL THIRD" },
  { code: "84146", shortdesc: "ASSAY OF PROLACTIN" },
  { code: "7025F", shortdesc: "INFO SYSTEM ANALYSIS ABNORMAL INTERPRATE" },
  { code: "62321", shortdesc: "NJX DX/THER SBST INTRLMNR CRV/THRC W/IMG GDN" },
  { code: "4167F", shortdesc: "HEAD-BED ELEV 30-45 DEG 1ST VENT DAY ORDERED" },
  { code: "0155T", shortdesc: "LAPAROSCOPIC IMPLTJ/RPLCMT GASTRIC ELECTRODE" },
  { code: "41874", shortdesc: "ALVEOLOPLASTY EACH QUADRANT SPECIFY" },
  {
    code: "46712",
    shortdesc: "RPR ILEOANAL POUCH FSTL/POUCH ADVMNT CMBN APPR",
  },
  { code: "01652", shortdesc: "ANESTHESIA AXILLARY-BRACHIAL ANEURYSM" },
  {
    code: "99327",
    shortdesc: "DOMICIL/REST HOME NEW PT VISIT HI SEVER 60 MIN",
  },
  { code: "56625", shortdesc: "VULVECTOMY SIMPLE COMPLETE" },
  { code: "96522", shortdesc: "REFILL&MAINTENANCE PUMP DRUG DLVR SYSTEMIC" },
  { code: "87476", shortdesc: "IADNA BORRELIA BURGDORFERI AMPLIFIED PROBE TQ" },
  {
    code: "15110",
    shortdesc: "EPIDRM AGRFT T/A/L 1ST 100 CM/&/1% BDY INFT/CHLD",
  },
  {
    code: "36466",
    shortdesc: "NJX NONCMPND SCLEROSANT MULTIPLE INCMPTNT VEINS",
  },
  { code: "25609", shortdesc: "OPTX DSTL RADL I-ARTIC FX/EPIPHYSL SEP 3 FRAG" },
  { code: "85590", shortdesc: "null" },
  { code: "77001", shortdesc: "FLUORO CENTRAL VENOUS ACCESS DEV PLACEMENT" },
  { code: "87449", shortdesc: "IAAD IA MULT STEP METHOD NOS EACH ORGANISM" },
  {
    code: "22845",
    shortdesc: "ANTERIOR INSTRUMENTATION 2-3 VERTEBRAL SEGMENTS",
  },
  { code: "87555", shortdesc: "IADNA MYCOBACTERIA TUBERCULOSIS DIR PRB" },
  {
    code: "81378",
    shortdesc: "HLA I&II HIGH RESOLUTION HLA-A -B -C AND -DRB1",
  },
  { code: "69540", shortdesc: "EXCISION AURAL POLYP" },
  { code: "23450", shortdesc: "CAPSULORRHAPHY ANTERIOR PUTTI-PLATT/MAGNUSON" },
  { code: "6020F", shortdesc: "NOTHING BY MOUTH ORDERED" },
  { code: "75970", shortdesc: "TRANSCATHETER BIOPSY RS&I" },
  {
    code: "99386",
    shortdesc: "INITIAL PREVENTIVE MEDICINE NEW PATIENT 40-64YRS",
  },
  {
    code: "51729",
    shortdesc: "COMPLX CYSTOMETRO W/VOID PRESS & URETHRAL PROFIL",
  },
  {
    code: "22634",
    shortdesc: "ARTHDSIS POST/POSTERLATRL/POSTINTRBDYADL SPC/SEG",
  },
  { code: "0302T", shortdesc: "null" },
  {
    code: "28291",
    shortdesc: "HALLUX RIGIDUS W/CHEILECTOMY 1ST MP JT W/IMPLT",
  },
  {
    code: "75958",
    shortdesc: "PLMT PROX XTN PRSTH EVASC DESC THORAC AORTA RS&I",
  },
  { code: "23100", shortdesc: "ARTHROTOMY GLENOHUMERAL JOINT W/BIOPSY" },
  { code: "21630", shortdesc: "RADICAL RESECTION STERNUM" },
  { code: "55859", shortdesc: "null" },
  { code: "59305", shortdesc: "null" },
  { code: "38571", shortdesc: "LAPS SURG BILATERAL TOTAL PELVIC LMPHADECTOMY" },
  { code: "49905", shortdesc: "OMENTAL FLAP INTRA-ABDOMINAL" },
  { code: "44055", shortdesc: "CORRJ MALROTATION BANDS&/RDCTJ VOLVULUS" },
  { code: "67440", shortdesc: "ORBITOTOMY BONE FLAP/WINDOW LATERAL W/DRG" },
  { code: "99507", shortdesc: "HOME VISIT CARE&MAINT CATH" },
  { code: "76519", shortdesc: "OPH BMTRY US ECHOGRAPY A-SCAN IO LENS PWR CAL" },
  { code: "33824", shortdesc: "RPR PATENT DUXUS ARTERIOSUS DIV 18 YR & OLDER" },
  {
    code: "44125",
    shortdesc: "ENTERECTOMY RESCJ SMALL INTESTINE W/ENTEROSTOMY",
  },
  {
    code: "43117",
    shortdesc: "PRTL ESOPHECT DSTL W/WO PROX GASTRECT/PYLORPLSTY",
  },
  {
    code: "4470F",
    shortdesc: "IMPLANT CARDIOVERT-DEFIB (ICD) COUNSELING PROV",
  },
  { code: "56521", shortdesc: "null" },
  {
    code: "99387",
    shortdesc: "INITIAL PREVENTIVE MEDICINE NEW PATIENT 65YRS&>",
  },
  { code: "0510T", shortdesc: "null" },
  {
    code: "27178",
    shortdesc: "OPTX SLP FEM EPIPHYSIS CLSD MANJ SINGL/MLTPL PIN",
  },
  { code: "39000", shortdesc: "MEDIAST W/EXPL DRG RMVL FB/BX CRV APPR" },
  { code: "3388F", shortdesc: "AJCC COLON CANCER STAGE III DOCD" },
  { code: "75827", shortdesc: "VENOGRAPHY CAVAL SUPERIOR SERIALOGRAPHY RS&I" },
  {
    code: "99384",
    shortdesc: "INITIAL PREVENTIVE MEDICINE NEW PT AGE 12-17 YR",
  },
  { code: "46946", shortdesc: "HRHC NTRNL LIG OTH THAN RBBR BAND 2/> COL/GRP" },
  {
    code: "61567",
    shortdesc: "CRANIOTOMY MULTIPLE SUBPIAL TRANSECTIONS W/ECOG",
  },
  { code: "52353", shortdesc: "CYSTO W/URETEROSCOPY W/LITHOTRIPSY" },
  { code: "00632", shortdesc: "ANESTHESIA LUMBAR REGION LUMBAR SYMPATHECTOMY" },
  { code: "68500", shortdesc: "EXCISION LACRIMAL GLAND XCPT TUMOR TOTAL" },
  { code: "4541F", shortdesc: "TX PSEUDOBULBAR AFFECT SIALORRHEA/ALS SYMP" },
  { code: "0502F", shortdesc: "SUBSEQUENT PRENATAL CARE VISIT" },
  { code: "96542", shortdesc: "CHEMOTX NJX SUBARACHND/INTRAVENTR RSVR 1/MULT" },
  { code: "82164", shortdesc: "ANGIOTENSIN I-CONVERTING ENZYME" },
  { code: "0575F", shortdesc: "HIV RNA CONTROL PLAN OF CARE DOCD" },
  {
    code: "81375",
    shortdesc: "HLA II LOW RESOLUTION HLA-DRB1/3/4/5 AND -DQB1",
  },
  { code: "81433", shortdesc: "HEREDITARY BRST CA-RELATED DUP/DEL ANALYSIS" },
  { code: "70542", shortdesc: "MRI ORBIT FACE & NECK W/CONTRAST MATERIAL" },
  { code: "3060F", shortdesc: "POSITIVE MICROALBUMINURIA TEST RESULT DOC&REV" },
  { code: "98962", shortdesc: "EDUCATION&TRAINING SELF-MGMT NONPHYS 5-8 PTS" },
  { code: "84588", shortdesc: "ASSAY OF VASOPRESSIN ANTI-DIURETIC HORMONE" },
  { code: "27536", shortdesc: "OPTX TIBIAL FX PROX BICONDYLAR W/WO INT FIXJ" },
  {
    code: "43191",
    shortdesc: "ESOPHAGOSCOPY RIGID TRANSORAL DIAGNOSTIC BRUSH",
  },
  {
    code: "94452",
    shortdesc: "HIGH ALTITUDE SIMULATJ TEST W/PHYS INTERP&REPORT",
  },
  { code: "31730", shortdesc: "TTRACH INTRO NDL WIRE DIL/STENT/TUBE O2 THER" },
  { code: "22600", shortdesc: "ARTHRODESIS PST/PSTLAT CERVICAL BELW C2 SGM" },
  { code: "46700", shortdesc: "ANOPLASTY PLASTIC OPERATION STRICTURE ADULT" },
  {
    code: "22534",
    shortdesc: "ARTHRODESIS LAT EXTRACAVITARY EA ADDL THRC/LMBR",
  },
  { code: "97261", shortdesc: "null" },
  { code: "70310", shortdesc: "RADIOLOGIC EXAM TEETH PRTL EXAM < FULL MOUTH" },
  { code: "2018F", shortdesc: "HYDRATION STATUS ASSESSED" },
  {
    code: "25491",
    shortdesc: "PROPH TX N/P/PLTWR W/WO METHYLMETHACRYLATE ULNA",
  },
  {
    code: "0439T",
    shortdesc: "MYOCARDIAL PERFUSION ECHO ISCHM/VIABILITY ASSMT",
  },
  { code: "77326", shortdesc: "BRACHYTHERAPY ISODOSE PLAN SIMPLE" },
  {
    code: "93981",
    shortdesc: "DUP-SCAN ARTL INFL&VEN O/F PEN VSL F-UP/LMTD STD",
  },
  { code: "31528", shortdesc: "LARYNGOSCOPY W/WO TRACHEOSCOPY W/DILATION IN" },
  { code: "92606", shortdesc: "THER SVC N-SP-GENRATJ DEV PRGRMG&MODIFICAJ" },
  { code: "20240", shortdesc: "BIOPSY BONE OPEN SUPERFICIAL" },
  {
    code: "62116",
    shortdesc: "RDCTJ CRANIOMEGALIC SKULL W/SIMPLE CRANIOPLASTY",
  },
  {
    code: "63046",
    shortdesc: "LAM FACETECTOMY & FORAMOTOMY 1 SEGMENT THORACIC",
  },
  { code: "92526", shortdesc: "TX SWALLOWING DYSFUNCTION&/ORAL FUNCJ FEEDING" },
  {
    code: "0298T",
    shortdesc: "EXT ECG > 48HR TO 21 DAY REVIEW AND INTERPRETATN",
  },
  { code: "90863", shortdesc: "PHARMACOLOGIC MANAGEMENT W/PSYCHOTHERAPY" },
  {
    code: "49450",
    shortdesc: "REPLACE GASTROSTOMY/CECOSTOMY TUBE PERCUTANEOUS",
  },
  {
    code: "94727",
    shortdesc: "GAS DILUT/WASHOUT LUNG VOL W/WO DISTRIB VENT&V",
  },
  { code: "74720", shortdesc: "null" },
  { code: "81405", shortdesc: "MOLECULAR PATHOLOGY PROCEDURE LEVEL 6" },
  { code: "64821", shortdesc: "SYMPATHECTOMY RADIAL ARTERY" },
  { code: "42220", shortdesc: "PALATOPLASTY CLEFT PALATE SEC LNGTH PX" },
  {
    code: "26373",
    shortdesc: "RPR/ADVMNT TDN W/NTC SUPFCIS TDN W/O FREE GRF EA",
  },
  { code: "99482", shortdesc: "null" },
  { code: "7010F", shortdesc: "PT INFORMATION ENTERED INTO RECALL SYSTEM" },
  {
    code: "21014",
    shortdesc: "EXC TUMOR SOFT TISS FACE&SCALP SUBFASCIAL 2 CM/>",
  },
  { code: "85305", shortdesc: "CLOTTING INHIBITORS PROTEIN S TOTAL" },
  { code: "39400", shortdesc: "MEDIASTINOSCOPY INCL BIOPSIES WHEN PERFORMED" },
  { code: "17108", shortdesc: "DSTRJ CUTANEOUS VASCULAR LESIONS >50.0 SQ CM" },
  {
    code: "67113",
    shortdesc: "RPR COMPLEX RETINA DETACH VITRECT &MEMBRANE PEEL",
  },
  { code: "54235", shortdesc: "NJX C/P/A CAVERNOSA W/PHARMACOLOGIC AGT" },
  { code: "27075", shortdesc: "RAD RESCT TUMOR WING OF ILIUM 1 PUBIC/ISCHIAL" },
  {
    code: "00626",
    shortdesc: "ANES THORACIC SPINE & CORD ANT APPR W/1 LNG VENT",
  },
  { code: "54000", shortdesc: "SLITTING PREPUCE DORSAL/LATERAL SPX NEWBORN" },
  {
    code: "36012",
    shortdesc: "SLCTV CATH PLMT VEN SYS 2ND ORDER/> SLCTV BRANC",
  },
  { code: "31257", shortdesc: "NASAL/SINUS NDSC TOTAL WITH SPHENOIDOTOMY" },
  { code: "0459T", shortdesc: "RELOCAJ RPLCMT AORTIC VENTR MECHANO-ELECTRODE" },
  {
    code: "61548",
    shortdesc: "HYPOPHYSEC/EXC PITUITARY TUM TRANSNASAL/SEPTAL",
  },
  { code: "34701", shortdesc: "EVASC RPR DPLMNT AORTO-AORTIC NDGFT" },
  { code: "78811", shortdesc: "PET IMAGING LIMITED AREA CHEST HEAD/NECK" },
  {
    code: "33956",
    shortdesc: "ECMO/ECLS INSJ OF CENTRAL CANNULA 6 YRS & OLDER",
  },
  { code: "0141T", shortdesc: "PNCRTC ISLET CELL TRNSPLJ PORTAL PERQ" },
  { code: "00176", shortdesc: "ANESTHESIA INTRAORAL W/BIOPSY RADICAL SURGERY" },
  {
    code: "82136",
    shortdesc: "AMINO ACIDS 2-5 AMINO ACIDS QUANTITATIVE EA SPEC",
  },
  {
    code: "26460",
    shortdesc: "TENOTOMY EXTENSOR HAND/FINGER OPEN EACH TENDON",
  },
  { code: "81311", shortdesc: "NRAS GENE ANALYSIS VARIANTS IN EXON 2&3" },
  { code: "92313", shortdesc: "RX&FITG CORNEOSCLERAL LENS" },
  { code: "88280", shortdesc: "CHRMSM ANALYSIS ADDL KARYOTYP EACH STUDY" },
  { code: "11010", shortdesc: "DBRDMT W/RMVL FM FX&/DISLC SKIN&SUBQ TISSUS" },
  { code: "32815", shortdesc: "OPEN CLOSURE MAJOR BRONCHIAL FISTULA" },
  { code: "4144F", shortdesc: "ALTERNATIVE LONG-TERM CONTROL MEDICATION RXD" },
  { code: "31561", shortdesc: "LARGSC ARYTENOIDECTOMY MICROSCOPE/TELESCOPE" },
  { code: "65855", shortdesc: "TRABECULOPLASTY BY LASER SURGERY" },
  { code: "5020F", shortdesc: "TX SUMM RPRT COMMUN PHYS&PT 1 MO COMPLETE" },
  { code: "00566", shortdesc: "ANES DIRECT CABG W/O PUMP OXYGENATOR" },
  {
    code: "01932",
    shortdesc: "ANESTHESIA INTRATHORACIC/JUGULAR THER IVNTL RAD",
  },
  {
    code: "43887",
    shortdesc: "GSTR RSTCV PX OPN RMVL SUBQ PORT COMPONENT ONLY",
  },
  { code: "81282", shortdesc: "LONG QT SYNDROME GENE ANAL DUP/DEL VARIANTS" },
  { code: "77299", shortdesc: "UNLIS PX THER RADIOL CLINICAL TX PLANNING" },
  { code: "0108T", shortdesc: "QUANT SENSORY TEST&INTERPJ/XTR W/COOL STIMULI" },
  { code: "64907", shortdesc: "NERVE PEDICAL TRANSFER SECOND STAGE" },
  { code: "84244", shortdesc: "ASSAY OF RENIN" },
  { code: "4275F", shortdesc: "HEP B VAC INJECTION ADMIN/PREVIOUSLY RCVD" },
  {
    code: "93050",
    shortdesc: "ART PRESS WAVEFORM ANALYS CENTRAL ART PRESSURE",
  },
  { code: "66940", shortdesc: "REMOVAL LENS MATERIAL EXTRACAPSULAR" },
  { code: "27105", shortdesc: "TR PARASPI MUSC HIP FASC/TDN XTN GRF" },
  { code: "0316T", shortdesc: "REPLACEMENT PULSE GENERATOR VAGUS NERVE" },
  { code: "96370", shortdesc: "SUBCUTANEOUS INFUSION EACH ADDITIONAL HOUR" },
  { code: "28405", shortdesc: "CLOSED TX CALCANEAL FRACTURE W/MANIPULATION" },
  { code: "81540", shortdesc: "ONCOLOGY TUM UNKNOWN ORIGIN MRNA 92 GENES" },
  {
    code: "31551",
    shortdesc: "LARYNGOPLASTY LARYNGEAL STEN W/O STENT < 12 YRS",
  },
  { code: "90670", shortdesc: "PCV13 VACCINE FOR INTRAMUSCULAR USE" },
  { code: "61708", shortdesc: "ARYSM VASC MALFRMJ/ICRA ELECTROTHROMBOSIS" },
  {
    code: "24545",
    shortdesc: "OPEN TX HUMERAL SUPRACONDYLAR FRACTURE W/O XTN",
  },
  {
    code: "0270T",
    shortdesc: "REV/REMVL CARTD SINS BARREFLX ACT DEV LEAD ONLY",
  },
  { code: "0120U", shortdesc: "null" },
  { code: "67250", shortdesc: "SCLERAL REINFORCEMENT SPX W/O GRAFT" },
  { code: "67299", shortdesc: "UNLISTED PROCEDURE POSTERIOR SEGMENT" },
  { code: "0103T", shortdesc: "HOLOTRANSCOBALAMIN QUANTITATIVE" },
  { code: "61154", shortdesc: "BURR HOLE W/EVAC&/DRG HEMATOMA XDRL/SDRL" },
  { code: "37243", shortdesc: "VASCULAR EMBOLIZE/OCCLUDE ORGAN TUMOR INFARCT" },
  {
    code: "33783",
    shortdesc: "A-ROOT TLCJ VSD PULM STNS RPR W/RIMPLTJ C OSTIA",
  },
  { code: "65710", shortdesc: "KERATOPLASTY ANTERIOR LAMELLAR" },
  { code: "98960", shortdesc: "EDUCATION&TRAINING SELF-MGMT NONPHYS 1 PT" },
  { code: "66990", shortdesc: "USE OPHTHALMIC ENDOSCOPE" },
  {
    code: "51585",
    shortdesc: "CYSTECTOMY W/URETEROSIGMOID BI PELV LYMPH NODES",
  },
  { code: "33274", shortdesc: "null" },
  { code: "50576", shortdesc: "RNL NDSC NFROT FULGURATION &/INCISION W/WO BX" },
  {
    code: "99391",
    shortdesc: "PERIODIC PREVENTIVE MED ESTABLISHED PATIENT <1Y",
  },
  {
    code: "49405",
    shortdesc: "IMAGE-GUIDE FLUID COLLXN DRAINAGE CATH VISC PERQ",
  },
  { code: "82397", shortdesc: "CHEMILUMINESCENT ASSAY" },
  { code: "45382", shortdesc: "COLSC FLEXIBLE W/CONTROL BLEEDING ANY METHOD" },
  {
    code: "44156",
    shortdesc: "COLECTOMY TOT ABDL W/PROCTECTOMY W/CONTNT ILEOST",
  },
  {
    code: "35286",
    shortdesc: "RPR BLVSL W/GRF OTHER/THAN VEIN LOWER EXTREMITY",
  },
  { code: "32659", shortdesc: "THRSC CRTJ PRCRD WINDOW/PRTL RESCJ PRCRD SAC" },
  { code: "0532T", shortdesc: "null" },
  { code: "22808", shortdesc: "ARTHRODESIS ANTERIOR SPINAL DFRM 2-3 VRT SEG" },
  { code: "92577", shortdesc: "STENGER TEST SPEECH" },
  {
    code: "84376",
    shortdesc: "SUGARS MONO DI&OLIGOS 1 QUALITATAIVE EACH SPEC",
  },
  { code: "70355", shortdesc: "ORTHOPANTOGRAM" },
  {
    code: "9002F",
    shortdesc: "AORTIC ANEURYSM 5-5.4CM MAX DIAM CTRLN/AXIAL CT",
  },
  { code: "29846", shortdesc: "ARTHRS WRST EXC&/RPR TRIANG FIBROCART&/JOINT" },
  { code: "36550", shortdesc: "null" },
  { code: "56344", shortdesc: "null" },
  { code: "25110", shortdesc: "EXCISION LESION TENDON SHEATH FOREARM&/WRIST" },
  {
    code: "96934",
    shortdesc: "RCM CELULR & SUBCELULR SKN IMGNG IMG ACQ I&R ADD",
  },
  { code: "24149", shortdesc: "RAD RESCJ CAPSL TISS&HTRTPC BONE ELBW CONTRCT" },
  { code: "27680", shortdesc: "TENOLYSIS FLXR/XTNSR TENDON LEG&/ANKLE 1 EACH" },
  { code: "0135T", shortdesc: "null" },
  { code: "43241", shortdesc: "EGD INTRALUMINAL TUBE/CATHETER INSERTION" },
  { code: "63700", shortdesc: "REPAIR MENINGOCELE < 5 CM DIAMETER" },
  { code: "38101", shortdesc: "SPLENECTOMY TOTAL EN BLOC W/OTHER PROCEDURE" },
  { code: "27324", shortdesc: "BIOPSY SOFT TISSUE THIGH/KNEE AREA DEEP" },
  {
    code: "0513F",
    shortdesc: "ELEVATED BLOOD PRESSURE PLAN OF CARE DOCUMENTED",
  },
  { code: "11400", shortdesc: "EXC B9 LESION MRGN XCP SK TG T/A/L 0.5 CM/<" },
  {
    code: "90633",
    shortdesc: "HEPA VACCINE 2 DOSE SCHEDULE PED/ADOLESC IM USE",
  },
  { code: "77317", shortdesc: "BRACHYTX ISODOSE PLN INTERMED W/DOSIMETRY CAL" },
  {
    code: "0448T",
    shortdesc: "RMVL INSJ IMPLTBL GLUC SENSOR DIF ANATOMIC SITE",
  },
  { code: "96361", shortdesc: "IV INFUSION HYDRATION EACH ADDITIONAL HOUR" },
  { code: "55899", shortdesc: "UNLISTED PROCEDURE MALE GENITAL SYSTEM" },
  { code: "85415", shortdesc: "FBRNLYC FACTORS&INHIBITORS PLSMNG ACTIVATOR" },
  { code: "19304", shortdesc: "MASTECTOMY SUBCUTANEOUS" },
  {
    code: "35281",
    shortdesc: "RPR BLVSL W/GRFT OTHER/THAN VEIN INTRA-ABDOMINAL",
  },
  { code: "31530", shortdesc: "LARYNGOSCOPY W/FOREIGN BODY REMOVAL" },
  {
    code: "3140F",
    shortdesc: "UPPER GI ENDO REPORT SHOWS POSS BARRETTS ESOPH",
  },
  {
    code: "33870",
    shortdesc: "TRANSVERSE ARCH GRAFT W/CARDIOPULMONARY BYPASS",
  },
  { code: "67311", shortdesc: "STRABISMUS RECESSION/RESCJ 1 HRZNTL MUSC" },
  { code: "60500", shortdesc: "PARATHYROIDECTOMY/EXPLORATION PARATHYROIDS" },
  { code: "20220", shortdesc: "BIOPSY BONE TROCAR/NEEDLE SUPERFICIAL" },
  {
    code: "00812",
    shortdesc: "ANESTHESIA LOWER INTST ENDOSCOPIC PX SCR COLSC",
  },
  {
    code: "0124T",
    shortdesc: "CONJUNCTVL INC W/POST XTRSCLERA PLCMT PHARM AGNT",
  },
  { code: "26255", shortdesc: "null" },
  {
    code: "96422",
    shortdesc: "CHEMOTHERAPY ADMIN INTRA-ARTERIAL INFUS </1 HR",
  },
  { code: "85175", shortdesc: "CLOT LYSIS TIME WHOLE BLOOD DILUTION" },
  {
    code: "29325",
    shortdesc: "APPL HIP SPICA CAST ONE&ONE-HALF SPICA/BOTH LEGS",
  },
  { code: "99299", shortdesc: "null" },
  {
    code: "27485",
    shortdesc: "ARRST HEMIEPIPHYSL DSTL FEMUR/PROX TIBIA/FIBULA",
  },
  { code: "28436", shortdesc: "PRQ SKELETAL FIXATION TALUS FRACTURE W/MANJ" },
  { code: "71023", shortdesc: "RADEX CH 2 VIEWS FRONTAL&LATERAL W/FLUORO" },
  { code: "29530", shortdesc: "STRAPPING KNEE" },
  { code: "80160", shortdesc: "DRUG SCREEN QUALITATIVE DESIPRAMINE" },
  {
    code: "27427",
    shortdesc: "LIGAMENTOUS RECONSTRUCTION KNEE EXTRA-ARTICULAR",
  },
  { code: "83037", shortdesc: "HGB GLYCOSYLATED DEVICE CLEARED FDA HOME USE" },
  { code: "4017F", shortdesc: "GI PROPHYLAXIS NSAID USE PRESCRIBED" },
  { code: "00174", shortdesc: "ANES INTRAORAL W/BX EXC RETROPHARYNGEAL TUMOR" },
  { code: "0032T", shortdesc: "null" },
  { code: "81379", shortdesc: "HLA CLASS I TYPING HIGH RESOLUTION COMPLETE" },
  {
    code: "61512",
    shortdesc: "CRNEC TREPHINE BONE FLAP MENINGIOMA SUPRATENTOR",
  },
  { code: "28035", shortdesc: "RELEASE TARSAL TUNNEL" },
  {
    code: "29844",
    shortdesc: "ARTHROSCOPY WRIST SURGICAL SYNOVECTOMY PARTIAL",
  },
  { code: "3513F", shortdesc: "HEPATITIS B SCREENING DOCUMENTED AS PERFORMED" },
  { code: "47563", shortdesc: "LAPS SURG CHOLECYSTECTOMY W/CHOLANGIOGRAPHY" },
  {
    code: "01969",
    shortdesc: "ANES CESARN HYST FLWG NEURAXIAL LABOR ANALG/ANES",
  },
  { code: "81189", shortdesc: "null" },
  { code: "21600", shortdesc: "EXCISION RIB PARTIAL" },
  {
    code: "74420",
    shortdesc: "X-RAY URINARY TRACT EXAM WITH CONTRAST MATERIAL",
  },
  {
    code: "31370",
    shortdesc: "PARTIAL LARYNGECTOMY HEMILARYGECTOMY HORIZONTAL",
  },
  { code: "59520", shortdesc: "null" },
  { code: "15431", shortdesc: "ACELLULAR XENOGRAFT IMPLANT EA 100 CM/1 PCT" },
  { code: "83500", shortdesc: "ASSAY OF HYDROXYPROLINE FREE" },
  { code: "33660", shortdesc: "RPR INCPLT/PRTL AV CANAL W/WO AV VALVE RPR" },
  { code: "0370T", shortdesc: "FAMILY BEHAVIOR TREATMENT GUIDANCE" },
  { code: "80101", shortdesc: "DRUG SCR QUAL 1 DRUG CLASS METH EA DRUG CLASS" },
  { code: "86332", shortdesc: "IMMUNE COMPLEX ASSAY" },
  { code: "0105T", shortdesc: "null" },
  { code: "92537", shortdesc: "CALORIC VESTIBULAR TEST W/REC BI BITHERMAL" },
  { code: "35180", shortdesc: "REPAIR CONGENITAL AV FISTULA HEAD & NECK" },
  { code: "77013", shortdesc: "CT GUIDANCE &MONITORING VISC TISS ABLATION" },
  {
    code: "77081",
    shortdesc: "DXA BONE DENSITY STUDY 1/>SITES APPENDICLR SKEL",
  },
  { code: "40805", shortdesc: "RMVL EMBEDDED FB VESTIBULE MOUTH COMP" },
  { code: "36530", shortdesc: "null" },
  { code: "54305", shortdesc: "null" },
  { code: "62355", shortdesc: "RMVL PREVIOUSLY IMPLTED ITHCL/EDRL CATH" },
  { code: "59076", shortdesc: "FETAL SHUNT PLACEMENT W/ULTRASOUND GUIDANCE" },
  { code: "56405", shortdesc: "I&D VULVA/PERINEAL ABSCESS" },
  {
    code: "91112",
    shortdesc: "GI TRANSIT & PRES MEAS WIRELESS CAPSULE W/INTERP",
  },
  { code: "28496", shortdesc: "PRQ SKEL FIXJ FX GRT TOE PHLX/PHLG W/MANJ" },
  {
    code: "90867",
    shortdesc: "REPET TMS TX INITIAL W/MAP/MOTR THRESHLD/DEL&M",
  },
  { code: "30115", shortdesc: "EXCISION NASAL POLYP EXTENSIVE" },
  { code: "17200", shortdesc: "null" },
  { code: "31032", shortdesc: "SINUSOT MAX ANTRT RAD W/RMVL ANTROCH POLYPS" },
  { code: "86329", shortdesc: "IMMUNODIFFUSION NOT ELSEWHERE SPECIFIED" },
  {
    code: "22512",
    shortdesc: "VERTEBROPLASTY EACH ADDL CERVICOTHOR/LUMBOSACRAL",
  },
  { code: "2050F", shortdesc: "WOUND CHARACTERISTICS DOCD PRIOR DEBRIDEMENT" },
  { code: "79200", shortdesc: "RP THERAPY INRACAVITARY ADMINISTRATION" },
  { code: "77336", shortdesc: "CONTINUING MEDICAL PHYSICS CONSLTJ PR WK" },
  {
    code: "59898",
    shortdesc: "UNLISTED LAPAROSCOPY PX MATERNITY CARE&DELIVERY",
  },
  { code: "35907", shortdesc: "EXCISION INFECTED GRAFT ABDOMEN" },
  { code: "42215", shortdesc: "PALATOPLASTY CLEFT PALATE MAJOR REVJ" },
  { code: "3328F", shortdesc: "PERFORMANCE STATUS DOCD RVWD 2 WKS PRIOR SURG" },
  { code: "28086", shortdesc: "SYNOVECTOMY TENDON SHEATH FOOT FLEXOR" },
  { code: "0145T", shortdesc: "null" },
  {
    code: "88235",
    shortdesc: "TISS CUL NON-NEO DISORDERS AMNIOTIC/CHORNC CELLS",
  },
  { code: "3382F", shortdesc: "AJCC COLON CANCER STAGE 0" },
  { code: "46606", shortdesc: "ANOSCOPY W/BX SINGLE/MULTIPLE" },
  {
    code: "90955",
    shortdesc: "ESRD RELATED SVC MONTHLY 2-11 YR OLD 2/3 VISITS",
  },
  { code: "43763", shortdesc: "null" },
  { code: "45334", shortdesc: "SIGMOIDOSCOPY FLX CONTROL BLEEDING" },
  { code: "15824", shortdesc: "RHYTIDECTOMY FOREHEAD" },
  { code: "93271", shortdesc: "XTRNL PT ACTIVATED ECG REC DWNLD 30 DAYS" },
  {
    code: "27884",
    shortdesc: "AMP LEG THRU TIBIA&FIBULA SEC CLOSURE/SCAR REV",
  },
  { code: "90702", shortdesc: "DT VACCINE YOUNGER THAN 7 YRS FOR IM USE" },
  { code: "47135", shortdesc: "LVR ALTRNSPLJ ORTHOTOPIC PRTL/WHL DON ANY AGE" },
  {
    code: "83661",
    shortdesc: "FETAL LUNG MATURITY LECITHIN SPHINGOMYELIN RATIO",
  },
  {
    code: "44111",
    shortdesc: "EXC 1/> SM/LG LESIONS INTESTNE MULT ENTEROTOMIE",
  },
  { code: "93278", shortdesc: "SIGNAL AVERAGED ELECTROCARDIOGRAPHY W/WO ECG" },
  { code: "95977", shortdesc: "null" },
  {
    code: "61539",
    shortdesc: "CRANIOT LOBECTOMY OTH/THN TEMPORAL LOBE W/ECOG",
  },
  { code: "0014T", shortdesc: "null" },
  { code: "32120", shortdesc: "THORACOTOMY POSTOPERATIVE COMPLICATIONS" },
  {
    code: "23155",
    shortdesc: "EXC/CURTG BONE CYST/BENIGN TUM PROX HUM W/AGRFT",
  },
  { code: "28570", shortdesc: "CLOSED TX TALOTARSAL JOINT DISLC W/O ANES" },
  { code: "62274", shortdesc: "null" },
  {
    code: "0002U",
    shortdesc: "ONC CLRCT QUAN 3 UR METABOLITES ALG ADNMTS PLP",
  },
  {
    code: "64636",
    shortdesc: "DSTR NROLYTC AGNT PARVERTEB FCT ADDL LMBR/SACRAL",
  },
  {
    code: "28345",
    shortdesc: "RCNSTJ TOE SYNDACTYLY W/WO SKIN GRAFT EACH WEB",
  },
  {
    code: "21436",
    shortdesc: "OPTX CRNFCL SEP LFT III TYP COMP INT FIXJ W/BONE",
  },
  { code: "76087", shortdesc: "null" },
  { code: "95863", shortdesc: "NDL EMG 3 XTR W/WO RELATED PARASPINAL AREAS" },
  { code: "97610", shortdesc: "LOW FREQUENCY NON-THERMAL ULTRASOUND PER DAY" },
  { code: "95199", shortdesc: "UNLISTED ALLERGY/CLINICAL IMMUNOLOGIC SRVC/PX" },
  { code: "88375", shortdesc: "OPTICAL ENDOMICROSCOPIC IMAGE INTERP & REPORT" },
  {
    code: "93042",
    shortdesc: "RHYTHM ECG 1-3 LEADS INTERPRETATION & REPRT ON",
  },
  {
    code: "37788",
    shortdesc: "PENILE REVASCULARIZATION ARTERY W/WO VEIN GRAFT",
  },
  { code: "21183", shortdesc: "RCNSTJ ORBIT/FHD/NASETHMD EXC BONE GRF>40 <80" },
  { code: "73702", shortdesc: "CT LOWER EXTREMITY W/O & W/CONTRAST MATRL" },
  { code: "27134", shortdesc: "REVJ TOT HIP ARTHRP BTH W/WO AGRFT/ALGRFT" },
  { code: "81303", shortdesc: "MECP2 GENE ANALYSIS KNOWN FAMILIAL VARIANT" },
  { code: "49540", shortdesc: "REPAIR LUMBAR HERNIA" },
  { code: "3510F", shortdesc: "DOCJ TB SCREEN PERFORMED & RESULTS INTERPRET" },
  { code: "26118", shortdesc: "RAD RESCJ TUM SOFT TISSUE HAND/FINGER 3 CM/>" },
  { code: "84252", shortdesc: "ASSAY OF RIBOFLAVIN-VITAMIN B-2" },
  { code: "36583", shortdesc: "RPLCMT COMPL TUN CTR VAD W/SUBQ PMP" },
  { code: "27328", shortdesc: "EXC TUMOR SOFT TISSUE THIGH/KNEE SUBFASC <5CM" },
  { code: "55530", shortdesc: "EXC VARICOCELE/LIGATION SPERMATIC VEINS SPX" },
  { code: "73610", shortdesc: "RADEX ANKLE COMPLETE MINIMUM 3 VIEWS" },
  { code: "15570", shortdesc: "FRMJ DIRECT/TUBED PEDICLE W/WO TRANSFER TRUNK" },
  { code: "46910", shortdesc: "DSTRJ LESION ANUS SMPL ELTRDSICCATION" },
  { code: "92604", shortdesc: "ANALYSIS COCHLEAR IMPLT 7 YR/> SBSQ REPRGRMG" },
  {
    code: "90620",
    shortdesc: "MENB-4C RECOMBNT PROT & OUTER MEMB VESIC VACC IM",
  },
  { code: "42200", shortdesc: "PALATOP CL PALATE SOFT&/HARD PALATE ONLY" },
  { code: "28232", shortdesc: "TX OPEN TENDON FLEXOR TOE 1 TENDON SPX" },
  { code: "11760", shortdesc: "REPAIR NAIL BED" },
  { code: "56720", shortdesc: "null" },
  { code: "70100", shortdesc: "RADIOLOGIC EXAMINATION MANDIPLE PRTL <4 VIEWS" },
  { code: "4053F", shortdesc: "HEMODIALYSIS VIA FUNCTIONING AVGRAFT" },
  {
    code: "61510",
    shortdesc: "CRANIEC TREPHINE BONE FLP BRAIN TUMOR SUPRTENTOR",
  },
  { code: "86777", shortdesc: "ANTIBODY TOXOPLASMA" },
  { code: "0551T", shortdesc: "null" },
  { code: "37145", shortdesc: "VENOUS ANASTOMOSIS OPEN RENOPORTAL" },
  { code: "68750", shortdesc: "CONJUNCTIVORHINOSTOMY INSJ TUBE/STENT" },
  {
    code: "00192",
    shortdesc: "ANES FACIAL BONES/SKULL RAD SURG W/PROGNATHISM",
  },
  { code: "58540", shortdesc: "HYSTEROPLASTY RPR UTERINE ANOMALY" },
  { code: "93931", shortdesc: "DUP-SCAN UXTR ART/ARTL BPGS UNI/LMTD STUDY" },
  { code: "36405", shortdesc: "VNPNXR <3 YEARS PHYS/QHP SKILL SCALP VEIN" },
  { code: "63278", shortdesc: "LAMINECTOMY BX/EXC ISPI NEO XDRL SACRAL" },
  { code: "0043U", shortdesc: "null" },
  { code: "35351", shortdesc: "TEAEC W/WO PATCH GRAFT ILIAC" },
  { code: "74455", shortdesc: "URETHROCYSTOGRAPHY VOIDING RS&I" },
  {
    code: "87653",
    shortdesc: "IADNA STREPTOCOCCUS GROUP B AMPLIFIED PROBE TQ",
  },
  { code: "20527", shortdesc: "INJECTION ENZYME PALMAR FASCIAL CORD" },
  { code: "0058T", shortdesc: "CRYOPRESERVATION REPRODUCTIVE TISSUE OVARIAN" },
  { code: "36834", shortdesc: "null" },
  {
    code: "3091F",
    shortdesc: "MAJOR DESPRESV DISORDER SEVERE W/PSYCHOT FEATURE",
  },
  {
    code: "29826",
    shortdesc: "ARTHROSCOPY SHOULDER W/CORACOACRM LIGMNT RELEASE",
  },
  {
    code: "33272",
    shortdesc: "RMVL OF SUBQ IMPLANTABLE DEFIBRILLATOR ELECTRODE",
  },
  { code: "0122U", shortdesc: "null" },
  { code: "36416", shortdesc: "COLLECTION CAPILLARY BLOOD SPECIMEN" },
  {
    code: "33933",
    shortdesc: "BKBENCH PREPJ CADAVER DONOR HEART/LUNG ALLOGRAFT",
  },
  { code: "01654", shortdesc: "ANES ARTERIES SHOULDER & AXILLA BYPASS GRAFT" },
  { code: "93317", shortdesc: "ECHO TRANSESOPHAG IMAGE ACQUISJ INTERP&REPORT" },
  { code: "58550", shortdesc: "LAPS VAGINAL HYSTERECTOMY UTERUS 250 GM/<" },
  { code: "95926", shortdesc: "SHORT-LATENCY SOMATOSENS EP STD LWR LIMBS" },
  { code: "00922", shortdesc: "ANES SEMINAL VESICLES INCL OPEN URETHRAL PX" },
  {
    code: "27696",
    shortdesc: "RPR PRIM DISRUPTED LIGM ANKLE BTH COLTRL LIGMS",
  },
  { code: "75662", shortdesc: "ANGIOGRAPHY EXTERNAL CAROTID BI SLCTV RS&I" },
  { code: "3095F", shortdesc: "CENTRAL DUAL ENERGY ABSORPTIOMETRY DOCD" },
  {
    code: "65780",
    shortdesc: "OCULAR SURFACE RECONSTRUCTION AMNIOTIC MEMBRANE",
  },
  {
    code: "29450",
    shortdesc: "APPL CLUBFOOT CAST MOLDING/MANJ LONG/SHORT LEG",
  },
  {
    code: "28080",
    shortdesc: "EXCISION INTERDIGITAL MORTON NEUROMA SINGLE EACH",
  },
  { code: "63283", shortdesc: "LAM BX/EXC ISPI NEO IDRL SACRAL" },
  { code: "62361", shortdesc: "IMPLTJ/RPLCMT FS NON-PRGRBL PUMP" },
  { code: "62220", shortdesc: "CRTJ SHUNT VENTRICULO-ATR-JUG-AUR" },
  { code: "43405", shortdesc: "LIG/STAPLING G-ESOP JUNCT PRE-ESOPHGL PRF8J" },
  { code: "3344F", shortdesc: "MAMMO ASSESSMENT CAT SUSPICIOUS DOCD" },
  { code: "35881", shortdesc: "REVJ LXTR ARTL BYP OPN W/SGMTL VEIN INTERPOS" },
  { code: "26020", shortdesc: "DRAINAGE TENDON SHEATH DIGIT&/PALM EACH" },
  { code: "83006", shortdesc: "GROWTH STIMULATION EXPRESSED GENE 2" },
  { code: "26494", shortdesc: "OPPONENSPLASTY HYPOTHENAR MUSC TR" },
  { code: "81306", shortdesc: "null" },
  { code: "82000", shortdesc: "ACETALDEHYDE BLOOD" },
  {
    code: "43881",
    shortdesc: "IMPLTJ/RPLCMT GASTRIC NSTIM ELTRDE ANTRUM OPEN",
  },
  {
    code: "00212",
    shortdesc: "ANESTHESIA INTRACRANIAL PROCEDURE SUBDURAL TAPS",
  },
  { code: "27826", shortdesc: "OPEN TREATMENT FRACTURE DISTAL TIBIA FIBULA" },
  { code: "73701", shortdesc: "CT LOWER EXTREMITY W/CONTRAST MATERIAL" },
  { code: "86310", shortdesc: "HETEROPHILE ANTIBODIES TITER AFTER ABSORPTION" },
  { code: "92511", shortdesc: "NASOPHARYNGOSCOPY W/ENDOSCOPE SPX" },
  {
    code: "49406",
    shortdesc: "IMG-GUIDE FLUID COLLXN DRAINAG CATH PERITON PERQ",
  },
  {
    code: "1101F",
    shortdesc: "PT FALLS ASSESS DOCD W/O FALL/INJURY PAST YEAR",
  },
  { code: "34716", shortdesc: "OPN AXILLARY/SUBCLAVIAN ART EXPOS W/CNDT CRTJ" },
  {
    code: "96004",
    shortdesc: "PHYS/QHP R&I CPTR MTN ALYS WALK/FUNCJL ACTV REPR",
  },
  {
    code: "32601",
    shortdesc: "THORSC DX LUNGS/PERICAR/MED/PLEURAL SPACE W/O BX",
  },
  { code: "17104", shortdesc: "null" },
  { code: "21494", shortdesc: "null" },
  { code: "51797", shortdesc: "VOID PRESSURE STUDIES INTRAABDOMINAL" },
  { code: "77075", shortdesc: "RADIOLOGIC EXAMINATION OSSEOUS SURVEY COMPL" },
  { code: "41000", shortdesc: "INTRAORAL I&D TONGUE/FLOOR LINGUAL" },
  {
    code: "33020",
    shortdesc: "PERICARDIOTOMY REMOVAL CLOT/FOREIGN BODY PRIMARY",
  },
  { code: "93930", shortdesc: "DUP-SCAN UXTR ART/ARTL BPGS COMPL BI STUDY" },
  { code: "20934", shortdesc: "null" },
  { code: "73060", shortdesc: "RADEX HUMERUS MINIMUM 2 VIEWS" },
  { code: "12031", shortdesc: "REPAIR INTERMEDIATE S/A/T/E 2.5 CM/<" },
  {
    code: "64446",
    shortdesc: "INJECTION ANES SCIATIC NERVE CONT INFUSION CATH",
  },
  { code: "36832", shortdesc: "REVJ OPN ARVEN FSTL W/O THRMBC DIAL GRF" },
  { code: "61120", shortdesc: "BURR HOLE VENTRICULAR PUNCTURE" },
  { code: "44379", shortdesc: "ENTEROSCOPY > 2ND PRTN W/ILEUM W/STENT PLMT" },
  {
    code: "55200",
    shortdesc: "VASOTOMY CANNULIZATION W/WO VAS INC UNI/BI SPX",
  },
  { code: "38120", shortdesc: "LAPAROSCOPIC SURGICAL SPLENECTOMY" },
  { code: "21616", shortdesc: "EXCISION 1ST &/CERVICAL RIB W/SYMPATHECTOMY" },
  { code: "44625", shortdesc: "CLSR NTRSTM LG/SM RESCJ & ANAST OTH/THN CLRCT" },
  {
    code: "33261",
    shortdesc: "OPRATIVE ABLTJ VENTR ARRHYTHMOGENIC FOC W/BYPASS",
  },
  {
    code: "00528",
    shortdesc: "ANES MEDIASTINOSCOPY&THORACSCOPY W/O 1 LUNG VNTJ",
  },
  {
    code: "5100F",
    shortdesc: "FX RISK REF PHYS/QHP COMMJ 24 HRS IMAGING STUDY",
  },
  {
    code: "92996",
    shortdesc: "PRQ TRLUML CORON ATHERECT W/WO ANGIOP EA VESSEL",
  },
  {
    code: "31780",
    shortdesc: "EXCISION TRACHEAL STENOSIS&ANASTOMOSIS CERVICA",
  },
  {
    code: "97814",
    shortdesc: "ACUP 1/> NDLS W/ELEC STIMJ EA 15 MIN W/RE-INSJ",
  },
  { code: "62005", shortdesc: "ELVTN DEPRS SKL FX COMPOUND/COMMIND XDRL" },
  { code: "83570", shortdesc: "ISOCITRIC DEHYDROGENASE" },
  {
    code: "33776",
    shortdesc: "RPR TRPOS GRT VSL ATR BAFFLE W/CLSR V-SEPTL DFCT",
  },
  { code: "95954", shortdesc: "RX/PHYSICAL EEG ACTIVAJ PHYS/QHP ATTENDANCE" },
  {
    code: "99308",
    shortdesc: "SBSQ NURSING FACIL CARE/DAY MINOR COMPLJ 15 MIN",
  },
  { code: "90291", shortdesc: "CYTOMEGALOVIRUS IMMUNE GLOBULIN HUMAN IV" },
  { code: "49582", shortdesc: "RPR UMBILICAL HERNIA < 5 YRS INCARCERATED" },
  { code: "48510", shortdesc: "EXTERNAL DRAINAGE PSEUDOCYST OF PANCREAS OPEN" },
  { code: "41120", shortdesc: "GLOSSECTOMY <ONE-HALF TONGUE" },
  { code: "64746", shortdesc: "TRANSECTION/AVULSION PHRENIC NERVE" },
  {
    code: "90819",
    shortdesc: "IND PSYCTX INPT/PRTL HOSP/RES 45-50 MIN MED E/M",
  },
  {
    code: "74247",
    shortdesc: "RADEX UPPER GI W/WO GLUCAGON/DELAY IMAGES W/KUB",
  },
  { code: "92563", shortdesc: "TONE DECAY TEST" },
  { code: "25126", shortdesc: "EXC/CURTG CYST/TUMOR RADIUS/ULNA W/ALLOGRAFT" },
  {
    code: "1090F",
    shortdesc: "PRESENCE/ABSENCE URINARY INCONTINENCE ASSESSED",
  },
  {
    code: "33730",
    shortdesc: "COMPLETE RPR ANOMALOUS PULMONARY VENOUS RETURN",
  },
  { code: "84126", shortdesc: "PORPHYRINS FECES QUANTITATIVE" },
  { code: "27360", shortdesc: "PRTL EXC BONE FEMUR PROX TIBIA&/FIBULA" },
  { code: "47382", shortdesc: "ABLTJ 1/> LVR TUM PRQ RF" },
  { code: "00842", shortdesc: "ANES IPER LOWER ABDOMEN W/LAPS AMNIOCENTESIS" },
  { code: "27479", shortdesc: "ARRST EPIPHYSL CMBN DSTL FEMUR PROX TIBFIB" },
  { code: "86972", shortdesc: "PRETX RBC ANTIBODY INCUBAT W/DENSITY GRAD SEP" },
  { code: "99233", shortdesc: "SBSQ HOSPITAL CARE/DAY 35 MINUTES" },
  { code: "99294", shortdesc: "null" },
  { code: "80440", shortdesc: "THYROTROPIN RELEAS HORMONE HYPRPROLACTINEMIA" },
  {
    code: "67908",
    shortdesc: "RPR BLPOS CONJUNCTIVO-TARSO-MUSC-LEVATOR RESCJ",
  },
  { code: "84480", shortdesc: "ASSAY OF TRIIODOTHYRONINE T3 TOTAL TT3" },
  {
    code: "4037F",
    shortdesc: "INFLUENZA IMMUNIZATION ORDERED OR ADMINISTERED",
  },
  {
    code: "33884",
    shortdesc: "PLMT PROX XTN PROSTH EVASC RPR DTA EA PROX XTN",
  },
  {
    code: "25393",
    shortdesc: "OSTEOPLASTY RADIUS&ULNA LENGTHENING W/AUTOGRAF",
  },
  { code: "49590", shortdesc: "RPR SPIGELIAN HERNIA" },
  { code: "54240", shortdesc: "PENILE PLETHYSMOGRAPHY" },
  {
    code: "97750",
    shortdesc: "PHYSICAL PERFORMANCE TEST/MEAS W/REPRT EA 15 MIN",
  },
  { code: "88399", shortdesc: "UNLISTED SURGICAL PATHOLOGY PROCEDURE" },
  { code: "86510", shortdesc: "SKIN TEST HISTOPLASMOSIS" },
  { code: "29440", shortdesc: "ADDING WALKER PREVIOUSLY APPLIED CAST" },
  { code: "58561", shortdesc: "HYSTEROSCOPY REMOVAL LEIOMYOMATA" },
  { code: "84260", shortdesc: "ASSAY OF SEROTONIN" },
  {
    code: "27176",
    shortdesc: "TX SLP FEM EPIPHYSIS SINGLE/MULTIPL PINNING SITU",
  },
  { code: "26590", shortdesc: "REPAIR MACRODACTYLIA EACH DIGIT" },
  {
    code: "90951",
    shortdesc: "ESRD RELATED SVC MONTHLY & <2 YR OLD 4/> VISITS",
  },
  { code: "35516", shortdesc: "BYPASS W/VEIN SUBCLAVIAN-AXILLARY" },
  {
    code: "20665",
    shortdesc: "REMOVAL TONG/HALO APPLIED BY ANOTHER INDIVIDUAL",
  },
  {
    code: "55874",
    shortdesc: "TRANSPERINEAL PLMT BIODEGRADABLE MATRL 1/MLT NJX",
  },
  {
    code: "90664",
    shortdesc: "LAIV VACCINE PANDEMIC FORMULA FOR INTRANASAL USE",
  },
  {
    code: "53240",
    shortdesc: "MARSUPIALIZATION URTL DIVERTICULUM MALE/FEMALE",
  },
  { code: "72069", shortdesc: "RADEX SPINE THORACOLMBR STANDING SCOLIOSIS" },
  { code: "4164F", shortdesc: "ADJUVANT HORMONAL THXPY RX/ADMIN" },
  { code: "22840", shortdesc: "POSTERIOR NON-SEGMENTAL INSTRUMENTATION" },
  { code: "97606", shortdesc: "NEGATIVE PRESSURE WOUND THERAPY DME >50 SQ CM" },
  { code: "96152", shortdesc: "HLTH&BEHAVIOR IVNTJ EA 15 MIN INDIV" },
  { code: "80338", shortdesc: "ANTIDEPRESSANTS NOT OTHERWISE SPECIFIED" },
  { code: "1036F", shortdesc: "CURRENT TOBACCO NON-USER CAD CAP COPD PV DM" },
  { code: "99217", shortdesc: "OBSERVATION CARE DISCHARGE MANAGEMENT" },
  {
    code: "21142",
    shortdesc: "RCNSTJ MIDFACE LEFORT I 2 PIECES W/O BONE GRAFT",
  },
  { code: "90769", shortdesc: "null" },
  { code: "76645", shortdesc: "US BREAST REAL TIME W/IMAGE DOCUMENTATION" },
  { code: "4095F", shortdesc: "PATIENT NOT RECEIVING ERYTHORPOIETIN THERAPY" },
  {
    code: "17280",
    shortdesc: "DESTRUCTION MALIGNANT LESION F/E/E/N/L/M 0.5CM/<",
  },
  {
    code: "19306",
    shortdesc: "MAST RAD W/PECTORAL MUSC AX INT MAM LYMPH NODES",
  },
  { code: "26105", shortdesc: "ARTHROTOMY BIOPSY MTCARPHLNGL JOINT EACH" },
  { code: "29720", shortdesc: "REPAIR SPICA BODY CAST/JACKET" },
  { code: "25442", shortdesc: "ARTHROPLASTY W/PROSTHETIC RPLCMT DISTAL ULNA" },
  { code: "88372", shortdesc: "PROTEIN ALYS WSTRN BLOT I&R IMMUNOLOGICAL EA" },
  { code: "59840", shortdesc: "INDUCED ABORTION DILATION AND CURETTAGE" },
  {
    code: "33391",
    shortdesc: "VALVULOPLASTY AORTIC VALVE OPEN CARD BYP COMPLEX",
  },
  {
    code: "11011",
    shortdesc: "DBRDMT W/RMVL FM FX&/DISLC SKN SUBQ T/M/F MUSC",
  },
  { code: "90296", shortdesc: "DIPHTHERIA ANTITOXIN EQUINE ANY ROUTE" },
  { code: "84133", shortdesc: "POTASSIUM URINE" },
  {
    code: "76812",
    shortdesc: "US PREG UTERUS DETAIL FETAL ANAT EXAM EA GESTAT",
  },
  { code: "93610", shortdesc: "INTRA-ATRIAL PACING" },
  { code: "32002", shortdesc: "null" },
  { code: "74220", shortdesc: "RADEX ESOPHAGUS" },
  {
    code: "43232",
    shortdesc: "ESOPHAGOSCOPY INTRA/TRANSMURAL NEEDLE ASPIRAT/BX",
  },
  { code: "40812", shortdesc: "EXC LESION MUCOSA & SBMCSL VESTIBULE SMPL RPR" },
  { code: "92564", shortdesc: "SHORT INCREMENT SENSITIVITY INDEX" },
  { code: "70488", shortdesc: "CT MAXILLOFACIAL W/O & W/CONTRAST MATERIAL" },
  { code: "54555", shortdesc: "null" },
  { code: "82760", shortdesc: "ASSAY OF GALACTOSE" },
  { code: "92340", shortdesc: "FITTING SPECTACLES XCPT APHAKIA MONOFOCAL" },
  { code: "3394F", shortdesc: "QUANT HER2 IHC EVAL OF BRST CANCER ASCO/CAP" },
  { code: "81266", shortdesc: "COMPARATIVE ANAL STR MARKERS EA ADDL SPECIMEN" },
  { code: "46615", shortdesc: "ANOSCOPY ABLATION LESION" },
  {
    code: "89060",
    shortdesc: "CRYSTAL ID LIGHT MICROSCOPY ALYS TISS/ANY FLUID",
  },
  {
    code: "41150",
    shortdesc: "GLSSC COMPOSIT W/RESCJ FLOOR & MANDIBULAR RESCJ",
  },
  {
    code: "4042F",
    shortdesc: "DOC PROPHY ANTIBIO NOT GIVEN W/IN 4 HR PRIOR SUR",
  },
  { code: "81299", shortdesc: "MSH6 GENE ANALYSIS KNOWN FAMILIAL VARIANTS" },
  { code: "13121", shortdesc: "REPAIR COMPLEX SCALP/ARM/LEG 2.6-7.5 CM" },
  {
    code: "0286T",
    shortdesc: "NEAR INFRARED SPECTROSCPY STUDIES LOW EXT WOUNDS",
  },
  { code: "44005", shortdesc: "ENTEROLSS FRING INTSTINAL ADHESION SPX" },
  { code: "1504F", shortdesc: "PATIENT HAS RESPIRATORY INSUFFICIENCY" },
  { code: "27475", shortdesc: "ARREST EPIPHYSEAL DISTAL FEMUR" },
  { code: "27158", shortdesc: "OSTEOTOMY PELVIS BILATERAL" },
  { code: "46612", shortdesc: "ANOSC RMVL MULT TUMORS CAUTERY/SNARE" },
  { code: "77048", shortdesc: "null" },
  { code: "35484", shortdesc: "null" },
  {
    code: "36253",
    shortdesc: "SUPSLCTV CATH 2ND+ORD RENAL&ACCESSORY ARTERY/S&I",
  },
  { code: "0031T", shortdesc: "null" },
  { code: "83015", shortdesc: "HEAVY METAL QUALITATIVE ANY ANALYTES" },
  { code: "64470", shortdesc: "null" },
  {
    code: "50360",
    shortdesc: "RENAL ALTRNSPLJ IMPLTJ GRF W/O RCP NEPHRECTOMY",
  },
  {
    code: "90644",
    shortdesc: "HIB-MENCY VACC 4 DOSE SCHED 6 WKS-18 MONTHS IM",
  },
  {
    code: "22802",
    shortdesc: "ARTHRODESIS POSTERIOR SPINAL DFRM 7-12 VRT SEG",
  },
  { code: "26992", shortdesc: "INCISION BONE CORTEX PELVIS&/HIP JOINT" },
  {
    code: "26489",
    shortdesc: "TRANSFER/TRANSPLANT TENDON PALMAR W/GRAFT EACH",
  },
  { code: "90655", shortdesc: "IIV3 VACC PRESRV FREE 0.25 ML DOSAGE IM USE" },
  { code: "88140", shortdesc: "SEX CHROMATIN IDENTJ PERIPHERAL BLOOD SMEAR" },
  {
    code: "4063F",
    shortdesc: "ANTIDEPRESSANT RXTHXY CONSIDER & NOT PRESCRIBE",
  },
  {
    code: "92984",
    shortdesc: "PRQ TRLUML CORONARY BALLOON ANGIOPLASTY EA VSL",
  },
  { code: "35654", shortdesc: "BYP OTH/THN VEIN AXILLARY-FEMORAL-FEMORAL" },
  { code: "90630", shortdesc: "INFLUENZA VACC IIV4 SPLIT VIRUS PRSRV FREE ID" },
  { code: "77522", shortdesc: "PROTON TX DELIVERY SIMPLE W/COMPENSATION" },
  { code: "24565", shortdesc: "CLTX HUMERAL EPICONDYLAR FX MEDIAL/LAT W/MANJ" },
  { code: "01474", shortdesc: "ANESTHESIA GASTROCNEMIUS RECESSION" },
  {
    code: "0200T",
    shortdesc: "PERQ SAC AGMNTJ UNI W/WO BALO/MCHNL DEV 1/> NDL",
  },
  { code: "77523", shortdesc: "PROTON TX DELIVERY INTERMEDIATE" },
  { code: "89254", shortdesc: "OOCYTE ID FROM FOLLICULAR FLU" },
  { code: "27727", shortdesc: "REPAIR CONGENITAL PSEUDARTHROSIS TIBIA" },
  { code: "97151", shortdesc: "null" },
  { code: "54500", shortdesc: "BIOPSY TESTIS NEEDLE SEPARATE PROCEDURE" },
  { code: "76970", shortdesc: "US STUDY FOLLOW UP" },
  { code: "21620", shortdesc: "OSTECTOMY STERNUM PARTIAL" },
  { code: "76706", shortdesc: "US ABDOMINAL AORTA REAL TIME SCREEN STUDY AAA" },
  { code: "33920", shortdesc: "RPR PULMONARY ATRESIA W/CONSTJ/RPLCMT CONDUIT" },
  {
    code: "33366",
    shortdesc: "TRANSCATHETER TRANSAPICAL REPLACEMT AORTIC VALVE",
  },
  {
    code: "69930",
    shortdesc: "COCHLEAR DEVICE IMPLANTATION W/WO MASTOIDECTOMY",
  },
  {
    code: "33982",
    shortdesc: "PLCMT VAD PMP IMPLTBL ICORP 1 VENTR W/O BYPASS",
  },
  { code: "21215", shortdesc: "GRAFT BONE MANDIBLE" },
  { code: "30435", shortdesc: "RHINOPLASTY SECONDARY INTERMEDIATE REVISION" },
  {
    code: "28297",
    shortdesc: "CORRJ HALLUX VALGUS W/SESMDC W/1METAR MEDIAL CNF",
  },
  { code: "81336", shortdesc: "null" },
  { code: "80203", shortdesc: "DRUG SCREEN QUANTITATIVE ZONISAMIDE" },
  { code: "3038F", shortdesc: "PULMONARY FUNC TEST WITHIN 12 MON PRIOR SURG" },
  {
    code: "97110",
    shortdesc: "THERAPEUTIC PX 1/> AREAS EACH 15 MIN EXERCISES",
  },
  {
    code: "27540",
    shortdesc: "OPEN TX INTERCONDYLAR SPINE/TUBRST FRACTURE KNEE",
  },
  {
    code: "43206",
    shortdesc: "ESOPHAGOSCOPY TRANSORAL W/OPTICAL ENDOMICROSCOPY",
  },
  { code: "0333T", shortdesc: "VISUAL EVOKED POTENTIAL ACUITY SCREENING AUTO" },
  { code: "33788", shortdesc: "REIMPLANTATION ANOMALOUS PULMONARY ARTERY" },
  { code: "27140", shortdesc: "OSTEOTOMY&TRANSFER GREATER TROCHANTER SPX" },
  {
    code: "27690",
    shortdesc: "TR/TRNSPL 1 TDN W/MUSC REDIRION/REROUTING SUPFC",
  },
  { code: "25651", shortdesc: "PRQ SKELETAL FIXATION ULNAR STYLOID FRACTURE" },
  {
    code: "0188T",
    shortdesc: "VIDEOCONFERENCED CRITICAL CARE FIRST 30-74 MIN",
  },
  { code: "70015", shortdesc: "CISTERNOGRAPHY POSITIVE CONTRAST RS&I" },
  { code: "57240", shortdesc: "ANTERIOR COLPORRAPHY RPR CYSTOCELE W/CYSTO" },
  {
    code: "20550",
    shortdesc: "INJECTION 1 TENDON SHEATH/LIGAMENT APONEUROSIS",
  },
  { code: "96133", shortdesc: "null" },
  {
    code: "61864",
    shortdesc: "STRTCTC IMPLTJ NSTIM ELTRD W/O RECORD EA ARRAY",
  },
  { code: "44388", shortdesc: "COLONOSCOPY STOMA DX INCLUDING COLLJ SPEC SPX" },
  { code: "40500", shortdesc: "VERMILIONECTOMY LIP SHV W/MUCOSAL ADVMNT" },
  { code: "0393T", shortdesc: "null" },
  { code: "34510", shortdesc: "VENOUS VALVE TRANSPOSITION ANY VEIN DONOR" },
  { code: "57400", shortdesc: "DILATION VAGINA W/ANESTHESIA OTHER THAN LOCAL" },
  { code: "78001", shortdesc: "THYROID UPTAKE MULTIPLE DETERMINATIONS" },
  { code: "36810", shortdesc: "INSJ CANNULA HEMO OTH PURPOSE SPX ARVEN XTRNL" },
  { code: "11601", shortdesc: "EXCISION MAL LESION TRUNK/ARM/LEG 0.6-1.0 CM" },
  { code: "30920", shortdesc: "LIGATION ARTERIES INT MAXILLARY TRANSANTRAL" },
  { code: "43520", shortdesc: "PYLOROMYOTOMY CUTTING PYLORIC MUSC" },
  { code: "12032", shortdesc: "REPAIR INTERMEDIATE S/A/T/E 2.6-7.5 CM" },
  { code: "86688", shortdesc: "ANTIBODY HTLV-II" },
  { code: "49423", shortdesc: "EXCHNG ABSC/CST DRG CATH RAD GID SPX" },
  {
    code: "33935",
    shortdesc: "HEART-LUNG TRNSPL W/RECIPIENT CARDIECTOMY-PNUMEC",
  },
  {
    code: "27659",
    shortdesc: "RPR FLEXOR TENDON LEG SECONDARY W/O GRAFT EACH",
  },
  { code: "21011", shortdesc: "EXCISION TUMOR SOFT TISS FACE/SCALP SUBQ <2CM" },
  { code: "45337", shortdesc: "SGMDSC FLX W/DCMPRN W/PLMT DCMPRN TUBE" },
  { code: "33915", shortdesc: "PULMONARY ARTERY EMBOLECTOMY W/O CARD BYPASS" },
  {
    code: "27396",
    shortdesc: "TRANSPLANT/TRANSFER THIGH XTNSR TO FLXR 1 TENDON",
  },
  { code: "86880", shortdesc: "ANTIHUMAN GLOBULIN DIRECT EACH ANTISERUM" },
  { code: "81479", shortdesc: "UNLISTED MOLELCULAR PATHOLOGY PROCEDURE" },
  { code: "22328", shortdesc: "OPTX&/RDCTJ VRT FX&/DISLC PST 1 VRT SGM EA" },
  {
    code: "81370",
    shortdesc: "HLA CLASS I&II LOW HLA-A -B -C -DRB1/3/4/5&DQB",
  },
  { code: "87151", shortdesc: "null" },
  { code: "58974", shortdesc: "EMBRYO TRANSFER INTRAUTERINE" },
  { code: "86277", shortdesc: "GROWTH HORMONE HUMAN ANTIBODY" },
  { code: "0053U", shortdesc: "null" },
  { code: "32853", shortdesc: "LUNG TRANSPLANT 2 W/O CARDIOPULMONARY BYPASS" },
  {
    code: "17262",
    shortdesc: "DESTRUCTION MAL LESION TRUNK/ARM/LEG 1.1-2.0CM",
  },
  { code: "49581", shortdesc: "null" },
  {
    code: "31572",
    shortdesc: "LARYNGOSCOPY FLEXIBLE ABLATJ DESTJ LESION(S) UNI",
  },
  { code: "20694", shortdesc: "REMOVAL EXTERNAL FIXATION SYSTEM UNDER ANES" },
  { code: "47399", shortdesc: "UNLISTED PROCEDURE LIVER" },
  { code: "83785", shortdesc: "ASSAY OF MANGANESE" },
  {
    code: "76965",
    shortdesc: "US GUIDANCE INTERSTITIAL RADIOELMENT APPLICATION",
  },
  { code: "38746", shortdesc: "THORCOM THRC W/MEDSTNL & REGIONAL LMPHADEC" },
  { code: "35306", shortdesc: "TEAEC W/GRAFT EA ADDL TIBIAL/PERONEAL ART" },
  {
    code: "97532",
    shortdesc: "DEVELOPMENT OF COGNITIVE SKILLS EACH 15 MINUTES",
  },
  { code: "88283", shortdesc: "CHRMSM ANALYSIS ADDL SPECIALIZED BANDING" },
  {
    code: "0254T",
    shortdesc: "EVASC RPR ILAC ART BIFUR ENDGRFT CATHJ RS&I UNI",
  },
  { code: "3551F", shortdesc: "INTERMEDIATE RISK FOR THROMBOEMBOLISM" },
  {
    code: "78121",
    shortdesc: "RED CELL VOLUME DETERMINATION SPX MULT SAMPLINGS",
  },
  { code: "35189", shortdesc: "RPR/TRAUMATIC AV FISTULA THORAX & ABDOMEN" },
  { code: "67915", shortdesc: "REPAIR ECTROPION THERMOCAUTERIZATION" },
  { code: "23210", shortdesc: "RADICAL RESECTION TUMOR SCAPULA" },
  {
    code: "93640",
    shortdesc: "EPHYS EVAL PACG CVDFB LDS INITIAL IMPLAN/REPLACE",
  },
  { code: "49605", shortdesc: "RPR LG OMPHALOCELE/GASTROSCHISIS W/WO PROSTH" },
  { code: "86698", shortdesc: "ANTIBODY HISTOPLASMA" },
  { code: "57260", shortdesc: "CMBND ANTERPOST COLPORRAPHY W/CYSTO" },
  {
    code: "33958",
    shortdesc: "ECMO/ECLS REPOS PERPH CANNULA PRQ 6 YRS & OLDER",
  },
  { code: "57461", shortdesc: "COLPOSCOPY CERVIX VAG ELTRD CONIZATION CERVIX" },
  { code: "32540", shortdesc: "EXTRAPLEURAL ENUCLEATION EMPYEMA EMPYEMECTOMY" },
  { code: "64702", shortdesc: "NEUROPLASTY DIGITAL 1/BOTH SAME DIGIT" },
  { code: "3340F", shortdesc: "MAMMO ASSESSMENT CAT INCOMP ADDTNL IMAGE DOCD" },
  { code: "50693", shortdesc: "PLMT URTRL STENT PRQ PRE-EXISTING NFROS TRACT" },
  { code: "86156", shortdesc: "COLD AGGLUTININ SCREEN" },
  {
    code: "86665",
    shortdesc: "ANTIBODY EPSTEIN-BARR EB VIRUS VIRAL CAPSID VCA",
  },
  {
    code: "92228",
    shortdesc: "REMOTE IMAGING MGT RETINAL DISEASE W/I&R UNI/B",
  },
  { code: "64832", shortdesc: "SUTR DIGITAL NRV HAND/FOOT EA DGTAL NRV" },
  { code: "88188", shortdesc: "FLOW CYTOMETRY INTERPJ 9-15 MARKERS" },
  {
    code: "75959",
    shortdesc: "PLMT DSTL XTN PRSTH EVASC DESC THORAC AORTA RS&I",
  },
  {
    code: "44376",
    shortdesc: "ENTEROSC >2ND PRTN W/ILEUM W/WO COLLJ SPEC SPX",
  },
  {
    code: "33218",
    shortdesc: "RPR 1 TRANSVNS ELTRD PRM PM/PACING IMPLNTBL DFB",
  },
  { code: "81291", shortdesc: "MTHFR GENE ANALYSIS COMMON VARIANTS" },
  { code: "21031", shortdesc: "EXCISION TORUS MANDIBULARIS" },
  { code: "0237T", shortdesc: "TRLUML PERIPH ATHRC W/RS&I BRCHIOCPHL EA VSL" },
  { code: "54690", shortdesc: "LAPAROSCOPY SURGICAL ORCHIECTOMY" },
  { code: "84630", shortdesc: "ASSAY OF ZINC" },
  {
    code: "92961",
    shortdesc: "CARDIOVERSION ELECTIVE ARRHYTHMIA INTERNAL SPX",
  },
  { code: "53230", shortdesc: "EXC URETHRAL DIVERTICULUM SPX FEMALE" },
  { code: "15261", shortdesc: "FTH/GFT FREE W/DIR CLSR N/E/E/L EA 20 SQ CM/<" },
  { code: "80417", shortdesc: "PERIPHERAL VEIN RENIN STIMULATION PANEL" },
  { code: "4189F", shortdesc: "APPROP DIGOXIN THXP MONIT TST ORDRD/DONE" },
  { code: "97761", shortdesc: "PROSTHETICS TRAINING INITIAL ENCTR EA 15 MINS" },
  { code: "95075", shortdesc: "INGESTION CHALLENGE TEST" },
  { code: "0209T", shortdesc: "PURE TONE AUDIOMETRY AUTOMATED AIR & BONE" },
  { code: "4186F", shortdesc: "NO CONTIN 12MON THXPY W/PPI OR H2 H2RA RCVD" },
  { code: "17312", shortdesc: "MOHS MICROGRAPHIC H/N/H/F/G EACH ADDL STAGE" },
  {
    code: "27327",
    shortdesc: "EXCISION TUMOR SOFT TISSUE THIGH/KNEE SUBQ <3CM",
  },
  { code: "0441T", shortdesc: "ABLTJ PERC CRYOABLTJ IMG GDN LXTR/PERPH NERVE" },
  { code: "6101F", shortdesc: "SAFETY COUNSELING DEMENTIA PROVIDED" },
  {
    code: "36247",
    shortdesc: "SLCTV CATHJ 3RD+ ORD SLCTV ABDL PEL/LXTR BRNCH",
  },
  {
    code: "28290",
    shortdesc: "CORRJ HALLUX VALGUS W/WO SESMDC SMPL EXOSTECTOMY",
  },
  { code: "0456T", shortdesc: "REMVL PERM IMPLT AORTIC VENTR VASC HEMO SEAL" },
  {
    code: "27652",
    shortdesc: "RPR PRIMARY OPEN/PRQ RUPTURED ACHILLES W/GRAFT",
  },
  { code: "4159F", shortdesc: "CONTRACEPTION COUNSEL BEFORE ANTIVIRAL TX" },
  { code: "33130", shortdesc: "RESECTION EXTERNAL CARDIAC TUMOR" },
  { code: "80011", shortdesc: "null" },
  { code: "90473", shortdesc: "IM ADM INTRANSL/ORAL 1 VACCINE" },
  { code: "89356", shortdesc: "THAWING CRYOPRESERVED OOCYTES EACH ALIQUOT" },
  { code: "59515", shortdesc: "CESAREAN DELIVERY ONLY W/POSTPARTUM CARE" },
  { code: "82651", shortdesc: "DIHYDROTSTOSTERONE" },
  { code: "21345", shortdesc: "CLOSED TX NASOMAXILLARY COMPLEX FRACTURE" },
  { code: "19101", shortdesc: "BIOPSY BREAST OPEN INCISIONAL" },
  { code: "92546", shortdesc: "SINUSOIDAL VERTICAL AXIS ROTATIONAL TESTING" },
  { code: "44370", shortdesc: "ENTEROSCOPY > 2ND PRTN TNDSC STENT PLMT" },
  { code: "47511", shortdesc: "INTRO PRQ TRANSHEPATC STENT BILIARY DRG" },
  { code: "81416", shortdesc: "EXOME SEQUENCE ANALYSIS EACH COMPARATOR EXOME" },
  { code: "1030F", shortdesc: "INFLUENZA IMMUNIZATION STATUS ASSESSED" },
  { code: "88364", shortdesc: "IN SITU HYBRIDIZATION EA ADDL PROBE STAIN" },
  { code: "21338", shortdesc: "OPEN TX NASOETHMOID FX W/O EXTERNAL FIXATION" },
  { code: "64510", shortdesc: "NJX ANES STELLATE GANGLION CRV SYMPATHETIC" },
  { code: "35536", shortdesc: "BYPASS W/VEIN SPLENORENAL" },
  { code: "23030", shortdesc: "I&D SHOULDER DEEP ABSCESS/HEMATOMA" },
  {
    code: "24126",
    shortdesc: "EXC/CURTG BONE CST/B9 TUM H/N RDS/OLECRN W/ALGRT",
  },
  {
    code: "77306",
    shortdesc: "TELETHX ISODOSE PLN SMPL W/DOSIMETRY CALCULATION",
  },
  { code: "78708", shortdesc: "KIDNEY IMG MORPHOLOGY VASCULAR FLOW 1 W/RX" },
  { code: "67346", shortdesc: "BIOPSY EXTRAOCULAR MUSCLE" },
  { code: "32851", shortdesc: "LUNG TRANSPLANT 1 W/O CARDIOPULMONARY BYPASS" },
  { code: "73500", shortdesc: "RADEX HIP UNILATERAL 1 VIEW" },
  { code: "90689", shortdesc: "null" },
  { code: "0539T", shortdesc: "null" },
  {
    code: "81435",
    shortdesc: "HEREDITARY COLON CA DSRDRS GEN SEQ ANALYS 10 GEN",
  },
  { code: "67710", shortdesc: "SEVERING TARSORRHAPHY" },
  { code: "57130", shortdesc: "EXCISION VAGINAL SEPTUM" },
  { code: "85651", shortdesc: "SEDIMENTATION RATE RBC NON-AUTOMATED" },
  { code: "23221", shortdesc: "null" },
  { code: "54100", shortdesc: "BIOPSY PENIS SEPARATE PROCEDURE" },
  { code: "87501", shortdesc: "INFECTIOUS AGENT DNA/RNA INFLUENZA EA TYPE" },
  {
    code: "26415",
    shortdesc: "EXC XTNSR TDN W/IMPLTJ SYNTH ROD DLYD GRF H/F EA",
  },
  { code: "86910", shortdesc: "BLOOD TYPING PATERNITY PR INDIV ABO RH&MN" },
  { code: "01440", shortdesc: "ANES ARTERIES OF KNEE & POPLITEAL AREA NOS" },
  { code: "24350", shortdesc: "null" },
  { code: "99262", shortdesc: "null" },
  { code: "44366", shortdesc: "ENTEROSCOPY > 2ND PRTN W/CONTROL BLEEDING" },
  { code: "4293F", shortdesc: "PT SCRND HGH-RSK SEXUAL BEHAVIOR" },
  {
    code: "01502",
    shortdesc: "ANES ART LOWER LEG W/BYP GRAFT EMBLC DIR/W/CATH",
  },
  { code: "97531", shortdesc: "null" },
  { code: "87336", shortdesc: "IAAD IA ENTAMOEBA HISTOLYTICA DISPAR GRP" },
  { code: "81404", shortdesc: "MOLECULAR PATHOLOGY PROCEDURE LEVEL 5" },
  { code: "86778", shortdesc: "ANTIBODY TOXOPLASMA IGM" },
  { code: "00800", shortdesc: "ANESTHESIA LOWER ANTERIOR ABDOMINAL WALL NOS" },
  { code: "82370", shortdesc: "CALCULUS XRAY DIFFRACTION" },
  {
    code: "0378T",
    shortdesc: "VISUAL FIELD ASSESSMENT PHYS REVIEW AND REPORT",
  },
  { code: "67900", shortdesc: "REPAIR BROW PTOSIS" },
  { code: "61156", shortdesc: "BURR HOLE W/ASPIR HEMATOMA/CYST INTRACEREBRAL" },
  {
    code: "50974",
    shortdesc: "URETERAL ENDOSCOPY VIA URETEROT W/O IMAGING W/BX",
  },
  { code: "81234", shortdesc: "null" },
  {
    code: "90961",
    shortdesc: "ESRD RELATED SVC MONTHLY 20/>YR OLD 2/3 VISITS",
  },
  { code: "0093T", shortdesc: "null" },
  { code: "90680", shortdesc: "RV5 VACCINE 3 DOSE SCHEDULE LIVE FOR ORAL USE" },
  { code: "11103", shortdesc: "null" },
  { code: "27220", shortdesc: "CLTX ACETABULUM HIP/SOCKT FX W/O MANJ" },
  { code: "27125", shortdesc: "HEMIARTHROPLASTY HIP PARTIAL" },
  { code: "11451", shortdesc: "EXCISION HIDRADENITIS AXILLARY COMPLEX REPAIR" },
  { code: "93738", shortdesc: "null" },
  { code: "49651", shortdesc: "LAPS SURG RPR RECURRENT INGUINAL HERNIA" },
  { code: "0172T", shortdesc: "PST SPINOUS PROCESS DEVICE INSERT LMBR EA LVL" },
  { code: "62142", shortdesc: "RMVL BONE FLAP/PROSTHETIC PLATE SKULL" },
  { code: "99201", shortdesc: "OFFICE OUTPATIENT NEW 10 MINUTES" },
  { code: "51700", shortdesc: "BLDR IRRIGATION SMPL LAVAGE &/INSTLJ" },
  { code: "3374F", shortdesc: "AJCC BREAST CANCER STAGE I T1C" },
  { code: "41252", shortdesc: "RPR LAC TONGUE FLOOR MOUTH > 2.6 CM/CPLX" },
  {
    code: "0451T",
    shortdesc: "INSJ/RPLCMT IMPLTBL AORTIC VENTR COMPLETE SYSTEM",
  },
  {
    code: "65150",
    shortdesc: "REINSERTION OCULAR IMPLT W/WO CONJUNCTIVAL GRAFT",
  },
  {
    code: "17274",
    shortdesc: "DESTRUCTION MALIGNANT LESION S/N/H/F/G 3.1-4.0CM",
  },
  { code: "22614", shortdesc: "ARTHRODESIS POSTERIOR/POSTEROLATERAL EA ADDL" },
  { code: "94760", shortdesc: "NONINVASIVE EAR/PULSE OXIMETRY SINGLE DETER" },
  { code: "79445", shortdesc: "RP THERAPY INTRA-ARTERIAL PARTICULATE ADMN" },
  { code: "35302", shortdesc: "TEAEC W/GRAFT SUPERFICIAL FEMORAL ARTERY" },
  { code: "62225", shortdesc: "RPLCMT/IRRIGATION VENTRICULAR CATHETER" },
  { code: "55801", shortdesc: "PROSTATECTOMY PERINEAL SUBTOTAL" },
  {
    code: "21049",
    shortdesc: "EXC B9 TUM/CST MAXL XTR-ORAL OSTEOT&PRTL MAXLC",
  },
  { code: "31612", shortdesc: "TRACHEAL PNXR PRQ W/TRANSTRACHEAL ASPIR&/NJX" },
  {
    code: "11307",
    shortdesc: "SHAVING SKIN LESION 1 S/N/H/F/G DIAM 1.1-2.0 CM",
  },
  { code: "93272", shortdesc: "XTRNL PT ACTIVTD ECG DWNLD W/R&I </30 DAYS" },
  { code: "44207", shortdesc: "LAPS COLECTOMY PRTL W/COLOPXTSTMY LW ANAST" },
  { code: "21046", shortdesc: "EXC BENIGN TUMOR/CYST MNDBL INTRA-ORAL OSTEOT" },
  { code: "76391", shortdesc: "null" },
  { code: "83704", shortdesc: "LIPOPROTEIN BLOOD QUAN NUMBERS & SUBCLASSES" },
  {
    code: "31267",
    shortdesc: "NSL/SINUS NDSC MAX ANTROST W/RMVL TISS MAX SINUS",
  },
  {
    code: "0291T",
    shortdesc: "INTRAV OPTCL CHERNCE TMGRPHY W/S&I INJ INTL VESL",
  },
  { code: "97002", shortdesc: "PHYSICAL THERAPY RE-EVALUATION" },
  { code: "96137", shortdesc: "null" },
  { code: "58605", shortdesc: "LIG/TRNSXJ FLP TUBE ABDL/VAG POSTPARTUM SPX" },
  { code: "73510", shortdesc: "RADEX HIP UNILATERAL COMPLETE MINIMUM 2 VIEWS" },
  { code: "86641", shortdesc: "ANTIBODY CRYPTOCOCCUS" },
  {
    code: "77423",
    shortdesc: "HIGH ENERGY NEUTRON RADJ TX DLVR 1/> ISOCENTER",
  },
  { code: "66174", shortdesc: "TRLUML DILAT AQUEOUS CANAL W/O DEVICE/STENT" },
  { code: "92590", shortdesc: "HEARING AID EXAMINATION & SELECTION MONAURAL" },
  { code: "64902", shortdesc: "NERVE GRAFT EACH NERVE MULTIPLE STRANDS" },
  { code: "27079", shortdesc: "null" },
  { code: "36520", shortdesc: "null" },
  { code: "44391", shortdesc: "COLONOSCOPY STOMA CONTROL BLEEDING" },
  { code: "99496", shortdesc: "TRANSITIONAL CARE MANAGE SRVC 7 DAY DISCHARGE" },
  { code: "86146", shortdesc: "BETA 2 GLYCOPROTEIN I ANTIBODY EACH" },
  { code: "56352", shortdesc: "null" },
  { code: "78230", shortdesc: "SALIVARY GLAND IMAGING" },
  { code: "0501F", shortdesc: "PRENATAL FLOW SHEET" },
  { code: "35636", shortdesc: "BYP OTH/THN VEIN SPLENORENAL" },
  { code: "00102", shortdesc: "ANESTHESIA CLEFT LIP INVOLVING PLASTIC REPAIR" },
  {
    code: "61210",
    shortdesc: "BURR HOLE IMPLANT VENTRICULAR CATH/OTHER DEVICE",
  },
  { code: "35525", shortdesc: "BYPASS W/VEIN BRACHIAL-BRACHIAL" },
  {
    code: "29805",
    shortdesc: "ARTHROSCOPY SHOULDER DX W/WO SYNOVIAL BIOPSY SPX",
  },
  { code: "80186", shortdesc: "DRUG SCREEN QUANTITATIVE PHENYTOIN FREE" },
  { code: "1490F", shortdesc: "DEMENTIA SEVERITY CLASSIFIED MILD" },
  { code: "10012", shortdesc: "null" },
  {
    code: "70328",
    shortdesc: "RADEX TEMPOROMANDBLE JT OPN & CLSD MOUTH UNILAT",
  },
  {
    code: "90847",
    shortdesc: "FAMILY PSYCHOTHERAPY W/PATIENT PRESENT 50 MINS",
  },
  { code: "49492", shortdesc: "RPR 1ST INGUN HRNA PRETERM INFT INCARCERATED" },
  { code: "92574", shortdesc: "null" },
  { code: "78191", shortdesc: "PLATELET SURVIVAL STUDY" },
  {
    code: "43847",
    shortdesc: "GASTRIC RSTCV W/BYP W/SM INT RCNSTJ LIMIT ABSRPJ",
  },
  { code: "95079", shortdesc: "INGESTION CHALLENGE TEST EACH ADDL 60 MINUTES" },
  { code: "93612", shortdesc: "INTRAVENTRICULAR PACING" },
  { code: "87163", shortdesc: "null" },
  {
    code: "29821",
    shortdesc: "ARTHROSCOPY SHOULDER SURG SYNOVECTOMY COMPLETE",
  },
  { code: "92520", shortdesc: "LARYNGEAL FUNCTION STUDIES" },
  { code: "86684", shortdesc: "ANTIBODY HAEMOPHILUS INFLUENZA" },
  { code: "01460", shortdesc: "null" },
  { code: "25999", shortdesc: "UNLISTED PROCEDURE FOREARM/WRIST" },
  { code: "53025", shortdesc: "MEATOTOMY CUTTING MEATUS SPX INFANT" },
  { code: "65756", shortdesc: "KERATOPLASTY ENDOTHELIAL" },
  {
    code: "88173",
    shortdesc: "CYTP EVAL FINE NEEDLE ASPIRATE INTERP & REPORT",
  },
  { code: "64489", shortdesc: "TAP BLOCK BILATERAL BY CONTINUOUS INFUSION(S)" },
  { code: "63003", shortdesc: "LAMINECTOMY W/O FFD 1/2 VERT SEG THORACIC" },
  { code: "27266", shortdesc: "CLTX POST HIP ARTHRP DISLC REQ ANES" },
  { code: "51520", shortdesc: "CYSTOTOMY SIMPLE EXCISION VESICAL NECK" },
  {
    code: "29900",
    shortdesc: "ARTHROSCOPY METACARPOPHALANGEAL SYNOVIAL BIOPSY",
  },
  {
    code: "0403T",
    shortdesc: "DIABETES PREVENTION PROG STANDARDIZED CURRICULUM",
  },
  { code: "0115U", shortdesc: "null" },
  {
    code: "27620",
    shortdesc: "ARTHRT ANKLE W/EXPL W/WO BX W/WO RMVL LOOSE/FB",
  },
  { code: "78264", shortdesc: "GASTRIC EMPTYING IMAGING STUDY" },
  { code: "11922", shortdesc: "TATTOOING INCL MICROPIGMENTATION EA 20.0 CM" },
  { code: "54402", shortdesc: "null" },
  {
    code: "33612",
    shortdesc: "RPR 2 OUTLET R VNTRC RPR R VENTR O/F TRC OBSTRCJ",
  },
  {
    code: "97127",
    shortdesc: "THERAPEUTIC IVNTJ W/FOCUS ON COGNITIVE FUNCTION",
  },
  { code: "01850", shortdesc: "ANESTHESIA VEINS FOREARM WRIST & HAND NOS" },
  { code: "83520", shortdesc: "IMMUNOASSAY ANALYTE QUANTITATIVE NOS" },
  {
    code: "24340",
    shortdesc: "TENODESIS BICEPS TENDON ELBOW SEPARATE PROCEDURE",
  },
  { code: "90802", shortdesc: "INTERACTIVE PSYCH DX INTERVIEW EXAM W/PLAY" },
  { code: "84080", shortdesc: "ASSAY OF PHOSPHATASE ALKALINE ISOENZYMES" },
  { code: "33720", shortdesc: "RPR SINUS VALSALVA ANEURYSM" },
  { code: "57451", shortdesc: "null" },
  {
    code: "60220",
    shortdesc: "TOTAL THYROID LOBECTOMY UNI W/WO ISTHMUSECTOMY",
  },
  {
    code: "38209",
    shortdesc: "TRNSP PREP HMATOP PROG THAW PREV HRV WSH PER DNR",
  },
  {
    code: "94070",
    shortdesc: "BRNCSPSM PROVOCATION EVAL MLT SPMTRY W/ADMN AGT",
  },
  { code: "84485", shortdesc: "ASSAY OF TRYPSIN DUODENAL FLUID" },
  {
    code: "21395",
    shortdesc: "OPTX ORB FLOOR BLWT FX PRI/BITAL APPR W/BONE GRF",
  },
  {
    code: "23466",
    shortdesc: "CAPSULORRHAPHY GLENOHUMRL JT MULTI-DIRIONAL INS",
  },
  { code: "0232T", shortdesc: "NJX PLTLT PLASMA W/IMG HARVEST/PREPARATION" },
  { code: "92275", shortdesc: "ELECTRORETINOGRAPY W/INTERPRETATION & REPORT" },
  {
    code: "83701",
    shortdesc: "LIPOPROTEIN BLOOD HIGH RESOLTJ&QUANTJ SUBCLASS",
  },
  { code: "62160", shortdesc: "NUNDSC ICRA PLMT/RPLCMT VENTR CATH SHUNT SYS" },
  { code: "28344", shortdesc: "RECONSTRUCTION TOE POLYDACTYLY" },
  {
    code: "62322",
    shortdesc: "NJX DX/THER SBST INTRLMNR LMBR/SAC W/O IMG GDN",
  },
  { code: "0061T", shortdesc: "null" },
  {
    code: "61585",
    shortdesc: "ORBITOCRANIAL ANT CRANIAL FOSSA W/ORBITAL EXNTJ",
  },
  { code: "61619", shortdesc: "SEC RPR DURA CSF LEAK LOCAL/REGIONALIZED FLAP" },
  { code: "61566", shortdesc: "CRANIOTOMY SELECTIVE AMYGDALOHIPPOCAMPECTOMY" },
  {
    code: "1450F",
    shortdesc: "SYMPTOMS IMPROVED/CONSIST W/TXMNT GOAL ASSESSMNT",
  },
  {
    code: "67025",
    shortdesc: "INJ SUBSTITUTE PARS PLANA/LIMBL W/WO ASPIR SPX",
  },
  {
    code: "33227",
    shortdesc: "REMVL PERM PM PLSE GEN W/REPL PLSE GEN SNGL LEAD",
  },
  { code: "0089U", shortdesc: "null" },
  {
    code: "80337",
    shortdesc: "ANTIDEPRESSANTS TRICYCLIC OTHER CYCLICALS 6/MORE",
  },
  {
    code: "1460F",
    shortdesc: "QUALIFYING CARD EVENT/DIAGNOSIS PRIOR 12 MONTHS",
  },
  { code: "71120", shortdesc: "RADEX STERNUM MINIMUM 2 VIEWS" },
  {
    code: "32550",
    shortdesc: "INSERTION INDWELLING TUNNELED PLEURAL CATHETER",
  },
  { code: "77285", shortdesc: "THER RAD SIMULAJ-AIDED FIELD SETTING INTERMED" },
  { code: "52204", shortdesc: "CYSTOURETHROSCOPY WITH BIOPSY" },
  { code: "23195", shortdesc: "RESECTION HUMERAL HEAD" },
  {
    code: "00400",
    shortdesc: "ANES INTEG EXTREMITIES ANT TRUNK & PERINEUM NOS",
  },
  { code: "24371", shortdesc: "REVIS ELBOW ARTHRPLSTY HUMERAL&ULNA COMPNT" },
  { code: "0540F", shortdesc: "GLUCORTICOID MANAGEMENT PLAN DOCUMENTED" },
  { code: "43244", shortdesc: "EGD BAND LIGATION ESOPHGEAL/GASTRIC VARICES" },
  { code: "22855", shortdesc: "REMOVAL ANTERIOR INSTRUMENTATION" },
  {
    code: "99091",
    shortdesc: "COLLJ&INTERPJ PHYS/QHP PHYSIO COMPUTR DATA 30 MI",
  },
  {
    code: "0264T",
    shortdesc: "AUTO BONE MARRW CELL RX COMP W/O BONE MAR HARVST",
  },
  { code: "0167T", shortdesc: "TRANSMYOCARDIAL CLOSURE V-SEPTL DFCT W/BYPASS" },
  {
    code: "93564",
    shortdesc: "NJX SEL HRT ART/GRFT CONGENITAL HRT CATH W/S&I",
  },
  { code: "0245T", shortdesc: "OPEN TX RIB FRACTURE W/INT FIX UNI 1-2 RIBS" },
  { code: "95056", shortdesc: "PHOTO TESTS" },
  { code: "00750", shortdesc: "ANESTHESIA HERNIA REPAIR UPPER ABDOMEN NOS" },
  { code: "27226", shortdesc: "OPTX PST/ANT ACTBLR WALL FX W/INT FIXJ" },
  { code: "70545", shortdesc: "MRA HEAD W/CONTRAST MATERIAL" },
  {
    code: "00866",
    shortdesc: "ANES XTRPRTL LOWER ABD W/URIN TRACT ADRENLECTOMY",
  },
  {
    code: "33813",
    shortdesc: "OBLTRJ AORTOPULMONARY SEPTAL DEFECT W/O BYPASS",
  },
  { code: "32669", shortdesc: "THORACOSCOPY W/SEGMENTECTOMY" },
  { code: "2000F", shortdesc: "BLOOD PRESSURE MEASURED" },
  {
    code: "0014F",
    shortdesc: "COMP PREOP ASSESS CATARACT SURG W/IOL PLACEMNT",
  },
  { code: "44300", shortdesc: "PLACEMENT ENTEROSTOMY/CECOSTOMY TUBE OPEN" },
  { code: "86694", shortdesc: "ANTIBODY HERPES SMPLX NON-SPECIFIC TYPE TEST" },
  { code: "21340", shortdesc: "PERCUTANEOUS TX NASOETHMOID COMPLEX FRACTURE" },
  { code: "76360", shortdesc: "null" },
  { code: "49215", shortdesc: "EXC PRESAC/SACROCOCCYGEAL TUMOR" },
  { code: "38214", shortdesc: "TRNSPL PREPJ HEMATOP PROGEN PLSM VOL DEPLJ" },
  { code: "63307", shortdesc: "VCRPEC LES 1 SEG IDRL LMBR/SAC TRANSPRTL/RPR" },
  { code: "44640", shortdesc: "CLOSURE INTESTINAL CUTANEOUS FISTULA" },
  { code: "65135", shortdesc: "INSJ OC IMPLT AFTER ENCL MUSC X ATTACHED" },
  { code: "51840", shortdesc: "ANT VESICOURETHROPEXY/URETHROPEXY SMPL" },
  { code: "90922", shortdesc: "null" },
  { code: "95983", shortdesc: "null" },
  { code: "44151", shortdesc: "COLCT TOT ABDL W/O PRCTECT W/CONTINENT ILEOST" },
  { code: "80012", shortdesc: "null" },
  { code: "64836", shortdesc: "SUTURE 1 NERVE ULNAR MOTOR" },
  { code: "59810", shortdesc: "null" },
  { code: "85378", shortdesc: "FIBRIN DGRADJ PRODUCTS D-DIMER QUAL/SEMIQUAN" },
  { code: "73600", shortdesc: "RADIOLOGIC EXAMINATION ANKLE 2 VIEWS" },
  { code: "22525", shortdesc: "PERQ VERTEBRAL AUGMENTATION EA ADDL THRC/LMBR" },
  { code: "86710", shortdesc: "ANTIBODY INFLUENZA VIRUS" },
  { code: "35473", shortdesc: "null" },
  { code: "93453", shortdesc: "R & L HRT CATH W/NJX L VENTRICULOG IMG S&I" },
  { code: "61885", shortdesc: "INSJ/RPLCMT CRANIAL NEUROSTIM PULSE GENERATOR" },
  { code: "86382", shortdesc: "NEUTRALIZATION TEST VIRAL" },
  { code: "17284", shortdesc: "DESTRUCTION MAL LESION F/E/E/N/L/M 3.1-4.0CM" },
  { code: "52301", shortdesc: "CYSTO W/RESECJ ECTOPIC URETEROCELE UNI/BI" },
  {
    code: "26170",
    shortdesc: "EXCISION TENDON PALM FLEXOR/EXTENSOR SINGLE EACH",
  },
  {
    code: "81301",
    shortdesc: "MICROSATELLITE INSTAB ANAL MISMATCH REPAIR DEF",
  },
  { code: "63035", shortdesc: "LAMNOTMY W/DCMPRSN NRV EACH ADDL CRVCL/LMBR" },
  {
    code: "3754F",
    shortdesc: "SCREENING TSTS DIABETES MELLITUS RVWD RQSTD ORD",
  },
  { code: "35616", shortdesc: "BYP OTH/THN VEIN SUBCLAVIAN-AXILLARY" },
  { code: "88740", shortdesc: "HEMOGLOBIN QUAN TC PER DAY CARBOXYHEMOGLOBIN" },
  { code: "35001", shortdesc: "DIR RPR ANEURYSM CAROTID-SUBCLAVIAN ARTERY" },
  { code: "0009T", shortdesc: "null" },
  { code: "29815", shortdesc: "null" },
  {
    code: "92937",
    shortdesc: "PRQ TRLUML CORONARY BYP GRFT REVASC ONE VESSEL",
  },
  {
    code: "3079F",
    shortdesc: "MOST RECENT DIASTOLIC BLOOD PRESSURE 80-89 MM HG",
  },
  {
    code: "86975",
    shortdesc: "PRETX SERUM RBC ANTIBODY INCUBATION DRUGS EACH",
  },
  { code: "50785", shortdesc: "URTRONEOCSTOST W/VESICO-PSOAS HITCH/BLDR FLAP" },
  { code: "77784", shortdesc: "null" },
  {
    code: "61000",
    shortdesc: "SUBDURAL TAP FONTANELLE/SUTUR INFANT UNI/BI INIT",
  },
  {
    code: "87901",
    shortdesc: "NFCT GEXYP NUCLEIC ACID HIV REV TRNSCR&PROTEAS",
  },
  { code: "76936", shortdesc: "US CMPRN RPR ARTL PSEUDOARYSM/ARVEN FSTL" },
  { code: "90963", shortdesc: "ESRD SVC HOME DIALYSIS FULL MONTH <2YR OLD" },
  {
    code: "52265",
    shortdesc: "CYSTOURETHROSCOPY W/DIL BLADDER LOCAL ANESTHESIA",
  },
  {
    code: "37252",
    shortdesc: "INTRAVASCULAR US NONCORONARY RS&I INTIAL VESSEL",
  },
  { code: "27784", shortdesc: "OPEN TREATMENT PROXIMAL FIBULA/SHAFT FRACTURE" },
  { code: "3512F", shortdesc: "SYPHILIS SCREENING DOCUMENTED AS DONE" },
  { code: "01234", shortdesc: "ANES UPPER 2/3 FEMUR RADICAL RESCECTION" },
  { code: "4142F", shortdesc: "CORTICOSTEROID SPARING THERAPY PRESCRIBED" },
  {
    code: "92618",
    shortdesc: "EVAL RX N-SP-GEN AUGMT ALT COMMUN DEV ADD 30 MIN",
  },
  { code: "15877", shortdesc: "SUCTION ASSISTED LIPECTOMY TRUNK" },
  {
    code: "63194",
    shortdesc: "LAM CORDOTOMY SCTJ 1 SPINOTHALMIC TRACT CERVICAL",
  },
  { code: "25447", shortdesc: "ARTHRP INTERPOS INTERCARPAL/METACARPAL JOINTS" },
  {
    code: "44386",
    shortdesc: "NDSC EVAL INTSTINAL POUCH W/BX SINGLE/MULTIPLE",
  },
  { code: "75733", shortdesc: "ANGIOGRAPHY ADRENAL BILATERAL SLCTV RS&I" },
  { code: "33240", shortdesc: "INSJ IMPLNTBL DEFIB PULSE GEN W/1 EXISTING LD" },
  { code: "35471", shortdesc: "TRLUML BALLOON ANGIOP PRQ RENAL/VISCERAL ART" },
  {
    code: "55875",
    shortdesc: "TRANSPERINEAL PLMT NDL/CATHS PROSTATE RADJ INSJ",
  },
  { code: "96420", shortdesc: "CHEMOTHERAPY ADMIN INTRA-ARTERIAL PUSH TQ" },
  { code: "86788", shortdesc: "ANTIBODY WEST NILE VIRUS IGM" },
  {
    code: "23802",
    shortdesc: "ARTHRODESIS GLENOHUMERAL JT W/AUTOGENOUS GRAFT",
  },
  { code: "30100", shortdesc: "BIOPSY INTRANASAL" },
  {
    code: "80439",
    shortdesc: "THYROTROPIN RELEASING HORMONE STMLJ PANEL 2 HR",
  },
  { code: "59525", shortdesc: "STOT/TOT HYSTERECTOMY AFTER CESAREAN DELIVERY" },
  {
    code: "27455",
    shortdesc: "OSTEOT PROX TIBIA FIB EXC/OSTEOT BEFORE EPIPHYSL",
  },
  { code: "91012", shortdesc: "null" },
  { code: "4300F", shortdesc: "PT RCVNG WARFARIN THXPY NONVALV AFIB OR AFLUT" },
  { code: "69440", shortdesc: "MIDDLE EAR EXPL THRU POSTAUR/EAR CANAL INC" },
  { code: "29901", shortdesc: "ARTHRS METACARPOPHALANGEAL JOINT DEBRIDEMENT" },
  { code: "29520", shortdesc: "STRAPPING HIP" },
  { code: "90660", shortdesc: "LAIV3 VACCINE LIVE FOR INTRANASAL USE" },
  { code: "38745", shortdesc: "AXILLARY LYMPHADENECTOMY COMPLETE" },
  { code: "65101", shortdesc: "ENUCLEATION OF EYE W/O IMPLANT" },
  { code: "50780", shortdesc: "URETERONEOCYSTOSTOMY ANAST 1 URETER BLADDER" },
  { code: "27780", shortdesc: "CLTX PROX FIBULA/SHFT FX W/O MANJ" },
  { code: "86644", shortdesc: "ANTIBODY CYTOMEGALOVIRUS CMV" },
  {
    code: "19357",
    shortdesc: "BRST RCNSTJ IMMT/DLYD W/TISS EXPANDER SBSQ XPNSJ",
  },
  { code: "90282", shortdesc: "null" },
  { code: "99441", shortdesc: "PHYS/QHP TELEPHONE EVALUATION 5-10 MIN" },
  { code: "37785", shortdesc: "LIGJ DIVJ &/EXCJ VARICOSE VEIN CLUSTER 1 LEG" },
  { code: "1491F", shortdesc: "DEMENTIA SEVERITY CLASSIFIED MODERATE" },
  { code: "91000", shortdesc: "null" },
  {
    code: "58954",
    shortdesc: "BSO W/OMENTECTOMY TAH DEBULKING W/LMPHADECTOMY",
  },
  {
    code: "62320",
    shortdesc: "NJX DX/THER SBST INTRLMNR CRV/THRC W/O IMG GDN",
  },
  { code: "0023T", shortdesc: "null" },
  { code: "0243T", shortdesc: "INTERMIT MEAS WHEEZE RATE BRONCHODIL DX W/I&R" },
  {
    code: "75898",
    shortdesc: "ANGRPH CATH F-UP STD TCAT OTHER THAN THROMBYLSIS",
  },
  { code: "72074", shortdesc: "RADEX SPINE THORACIC MINIMUM 4 VIEWS" },
  {
    code: "87149",
    shortdesc: "CULTURE TYPING NUCLEIC ACID PROBE DIR EA ORGANSM",
  },
  { code: "32852", shortdesc: "LUNG TRANSPLANT 1 W/CARDIOPULMONARY BYPASS" },
  { code: "76775", shortdesc: "US RETROPERITONEAL REAL TIME W/IMAGE LIMITED" },
  { code: "37799", shortdesc: "UNLISTED PROCEDURE VASCULAR SURGERY" },
  { code: "21615", shortdesc: "EXCISION 1ST &/CERVICAL RIB" },
  {
    code: "95867",
    shortdesc: "NEEDLE ELECTROMYOGRAPHY CRANIAL NRV MUSCLE UNI",
  },
  { code: "43846", shortdesc: "GASTRIC RSTCV W/BYP W/SHORT LIMB 150 CM/<" },
  { code: "64642", shortdesc: "CHEMODENERVATION ONE EXTREMITY 1-4 MUSCLE" },
  {
    code: "24006",
    shortdesc: "ARTHRT ELBOW CAPSULAR EXCISION CAPSULAR RLS SPX",
  },
  { code: "47555", shortdesc: "BILIARY NDSC PRQ T-TUBE W/DIL DUCT W/O STENT" },
  { code: "85290", shortdesc: "CLOTTING FACTOR XIII FIBRIN STABILIZING" },
  { code: "84512", shortdesc: "ASSAY OF TROPONIN QUALITATIVE" },
  { code: "64630", shortdesc: "DSTRJ NEUROLYTIC AGENT PUDENDAL NERVE" },
  { code: "93462", shortdesc: "LEFT HEART CATH BY TRANSEPTAL PUNCTURE" },
  { code: "76642", shortdesc: "US BREAST UNI REAL TIME WITH IMAGE LIMITED" },
  { code: "41870", shortdesc: "PERIODONTAL MUCOSAL GRAFTING" },
  {
    code: "63040",
    shortdesc: "LAMOT PRTL FFD EXC DISC REEXPL 1 NTRSPC CERVICAL",
  },
  { code: "77032", shortdesc: "MAMMOGRAPHIC GID NEEDLE PLACEMENTT BREAST" },
  { code: "38220", shortdesc: "DIAGNOSTIC BONE MARROW ASPIRATIONS" },
  { code: "11401", shortdesc: "EXC B9 LESION MRGN XCP SK TG T/A/L 0.6-1.0 CM" },
  { code: "25449", shortdesc: "REVJ ARTHRP W/REMOVAL IMPLANT WRIST JOINT" },
  { code: "26250", shortdesc: "RADICAL RESECTION TUMOR METACARPAL" },
  { code: "47125", shortdesc: "HEPATECTOMY RESCJ TOTAL LEFT LOBECTOMY" },
  { code: "93514", shortdesc: "null" },
  {
    code: "27049",
    shortdesc: "RAD RESECT TUMOR SOFT TISSUE PELVIS & HIP <5 CM",
  },
  { code: "72142", shortdesc: "MRI SPINAL CANAL CERVICAL W/CONTRAST MATRL" },
  {
    code: "97113",
    shortdesc: "THER PX 1/> AREAS EACH 15 MIN AQUA THER W/XERSS",
  },
  {
    code: "41017",
    shortdesc: "XTRORAL I&D ABSC CST/HMTMA FLOOR MOUTH SUBMNDB",
  },
  {
    code: "33228",
    shortdesc: "REMVL PERM PM PLS GEN W/REPL PLSE GEN 2 LEAD SYS",
  },
  { code: "0395T", shortdesc: "HDR ELECTRONIC BRACHYTHERAPY NTRSTL/INTRCAV" },
  { code: "93722", shortdesc: "PLETHYSMOGRAPY TOT BDY I&R ONLY" },
  { code: "3280F", shortdesc: "HEMOGLOBIN LEVEL 11 G/DL-12.9 G/DL" },
  { code: "15630", shortdesc: "DELAY FLAP/SCTJ FLAP EYELIDS NOSE EARS/LIPS" },
  { code: "86580", shortdesc: "SKIN TEST TUBERCULOSIS INTRADERMAL" },
  { code: "44979", shortdesc: "UNLISTED LAPAROSCOPY PROCEDURE APPENDIX" },
  {
    code: "77768",
    shortdesc: "HDR RDNCL SK SRF BRCHYTX LES >2CM&2CHAN/MLT LES",
  },
  { code: "36569", shortdesc: "INSJ PRPH CVC W/O SUBQ PORT/PMP AGE 5 YR/>" },
  { code: "87532", shortdesc: "IADNA HERPES VIRUS-6 AMPLIFIED PROBE TQ" },
  { code: "87145", shortdesc: "null" },
  { code: "62120", shortdesc: "RPR ENCEPHALOCELE SKULL VAULT W/CRANIOPLASTY" },
  {
    code: "3022F",
    shortdesc: "LEFT VENTRICULAR EJECTION FRACTION >/EQUAL 40%",
  },
  { code: "4133F", shortdesc: "ANTIHISTAMINE/DECONGESTANT PRESCRIBED" },
  { code: "54200", shortdesc: "INJECTION PEYRONIE DISEASE" },
  { code: "26516", shortdesc: "CAPSULODESIS MTCARPHLNGL JOINT SINGLE DIGIT" },
  { code: "83012", shortdesc: "ASSAY OF HAPTOGLOBIN PHENOTYPES" },
  { code: "82975", shortdesc: "GLUTAMINE" },
  { code: "82252", shortdesc: "BILIRUBIN FECES QUALITATIVE" },
  { code: "46288", shortdesc: "CLSR ANAL FSTL W/RCT ADVMNT FLAP" },
  {
    code: "99116",
    shortdesc: "ANES COMPLICJ UTILIZATION TOTAL BODY HYPOTHERMIA",
  },
  { code: "16036", shortdesc: "ESCHAROTOMY EACH ADDITIONAL INCISION" },
  { code: "42999", shortdesc: "UNLISTED PROCEDURE PHARYNX ADENOIDS/TONSILS" },
  { code: "88025", shortdesc: "NECROPSY GROSS & MICROSCOPIC W/BRAIN" },
  {
    code: "22901",
    shortdesc: "EXC TUMOR SOFT TISSUE ABDL WALL SUBFASCIAL 5CM/>",
  },
  { code: "50728", shortdesc: "REVJ UR-CUTAN ANAST RPR FSCAL DFCT & HERNIA" },
  { code: "25450", shortdesc: "EPIPHYSL ARRST EPIPHYSIOD/STAPLING DSTL RDS/U" },
  {
    code: "90947",
    shortdesc: "DIALYSIS OTH/THN HEMODIALY REPEAT PHYS/QHP EVALS",
  },
  { code: "65265", shortdesc: "RMVL FB IO FROM POST SEG NONMAGNETIC XTRJ" },
  {
    code: "99148",
    shortdesc: "MOD SEDATJ PHYS OTH/THN PERF DX/THER SVC <5 YRS",
  },
  { code: "77785", shortdesc: "REMOTE AFTLD RADIONUCLIDE BRACHYTX 1 CHANNEL" },
  { code: "40701", shortdesc: "PLSTC RPR CL LIP/NSL DFRM PRIM BI 1 STG PX" },
  {
    code: "58520",
    shortdesc: "HYSTERORRHAPHY REPAIR RUPT UTERUS NONOBSTETRICAL",
  },
  {
    code: "33521",
    shortdesc: "CORONARY ARTERY BYP W/VEIN & ARTERY GRAFT 4 VEIN",
  },
  {
    code: "20936",
    shortdesc: "AUTOGRAFT SPINE SURGERY LOCAL FROM SAME INCISION",
  },
  {
    code: "87106",
    shortdesc: "CULTURE FUNGI DEFINITIVE ID EACH ORGANISM YEAST",
  },
  { code: "33910", shortdesc: "PULMONARY ARTERY EMBOLECTOMY W/CARD BYPASS" },
  { code: "77777", shortdesc: "INTERSTITIAL RADIATION SOURCE APPLIC INTERMED" },
  { code: "52224", shortdesc: "CYSTO W/REMOVAL OF LESIONS SMALL" },
  { code: "32400", shortdesc: "BIOPSY PLEURA PERCUTANEOUS NEEDLE" },
  {
    code: "0479T",
    shortdesc: "FRACTIONAL ABL LSR FENESTRATION FIRST 100 SQCM",
  },
  {
    code: "15120",
    shortdesc: "SPLIT AGRFT F/S/N/H/F/G/M/D GT 1ST 100 CM/</1 %",
  },
  { code: "80048", shortdesc: "BASIC METABOLIC PANEL CALCIUM TOTAL" },
  { code: "15343", shortdesc: "null" },
  {
    code: "99483",
    shortdesc: "ASSMT & CARE PLANNING PT W/COGNITIVE IMPAIRMENT",
  },
  { code: "11006", shortdesc: "DBRDMT SKN SUBQ T/M/F NECRO INFCTJ GENT/ABDL" },
  { code: "80090", shortdesc: "null" },
  { code: "89352", shortdesc: "THAWING CRYOPRESERVED EMBRYO" },
  { code: "87101", shortdesc: "CUL FNGI MOLD/YEAST PRSMPTV ID SKN HAIR/NAIL" },
  { code: "84156", shortdesc: "PROTEIN TOTAL XCPT REFRACTOMETRY URINE" },
  {
    code: "30124",
    shortdesc: "EXCISION DERMOID CYST NOSE SIMPLE SUBCUTANEOUS",
  },
  {
    code: "28020",
    shortdesc: "ARTHRT W/EXPL DRG/RMVL LOOSE/FB NTRTRSL/TARS JT",
  },
  { code: "86784", shortdesc: "ANTIBODY TRICHINELLA" },
  {
    code: "58958",
    shortdesc: "RESECTION RECRT MAL W/OMENTECTOMY PEL LMPHADEC",
  },
  {
    code: "81503",
    shortdesc: "ONCO (OVARIAN) BIOCHEMICAL ASSAY FIVE PROTEINS",
  },
  { code: "67005", shortdesc: "RMVL VITREOUS ANT APPR PARTIAL REMOVAL" },
  { code: "33930", shortdesc: "DONOR CARDIECTOMY-PNEUMONECTOMY" },
  { code: "12057", shortdesc: "REPAIR INTERMEDIATE F/E/E/N/L&/MUC >30.0 CM" },
  { code: "50562", shortdesc: "RENAL NDSC NEPHROS/PYELOSTOMY RESCJ TUMOR" },
  { code: "43325", shortdesc: "ESOPG/GSTR FUNDOPLASTY W/FUNDIC PATCH" },
  { code: "29850", shortdesc: "ARTHROSCOPY AID TX SPINE&/FX KNEE W/O FIXJ" },
  { code: "28193", shortdesc: "REMOVAL FOREIGN BODY FOOT COMPLICATED" },
  { code: "93978", shortdesc: "DUP-SCAN AORTA IVC ILIAC VASCL/BPGS COMPLETE" },
  { code: "0158T", shortdesc: "LAPT REVJ/RMVL GASTRIC ELTRD < CURVATURE" },
  {
    code: "65270",
    shortdesc: "RPR LAC CJNC W/WO NONPERFOR LAC SCLERA DIR CLSR",
  },
  { code: "1026F", shortdesc: "CO-MORBID CONDITIONS ASSESSED" },
  { code: "52450", shortdesc: "TRANSURETHRAL INCISION PROSTATE" },
  {
    code: "35216",
    shortdesc: "RPR BLOOD VESSEL DIRECT INTRATHORACIC W/O BYPASS",
  },
  {
    code: "63064",
    shortdesc: "COSTOVERTEBRAL DCMPRN SPINAL CORD THORACIC 1 SEG",
  },
  { code: "21160", shortdesc: "RCNSTJ MIDFACE LEFORT III W/FHD W/LEFORT I" },
  {
    code: "33617",
    shortdesc: "RPR COMPLEX CARDIAC ANOMALY MODIFIED FONTAN PX",
  },
  {
    code: "0277T",
    shortdesc: "BRONCHSCPY FLUORO W/BRNCHIAL THERMPLASTY />2LOBE",
  },
  { code: "11450", shortdesc: "EXCISION HIDRADENITIS AXILLARY SMPL/INTRM RPR" },
  { code: "70140", shortdesc: "RADEX FACIAL BONES < 3 VIEWS" },
  {
    code: "36591",
    shortdesc: "COLLECT BLOOD FROM IMPLANT VENOUS ACCESS DEVICE",
  },
  {
    code: "29845",
    shortdesc: "ARTHROSCOPY WRIST SURGICAL SYNOVECTOMY COMPLETE",
  },
  { code: "50760", shortdesc: "URETEROURETEROSTOMY" },
  { code: "33025", shortdesc: "CRTJ PERICARDIAL WINDOW/PRTL RESECJ W/DRG/BX" },
  {
    code: "96573",
    shortdesc: "PDT DSTR PRMLG LES SKN ILLUM/ACTIVJ BY PHYS/QHP",
  },
  { code: "33692", shortdesc: "COMPL RPR TETRALOGY FALLOT W/O PULM ATRESIA" },
  { code: "55180", shortdesc: "SCROTOPLASTY COMPLICATED" },
  {
    code: "99056",
    shortdesc: "SVC TYPICAL PRV OFFICE PRV OUT OFFICE REQUEST PT",
  },
  { code: "32160", shortdesc: "THORACOTOMY W/CARDIAC MASSAGE" },
  {
    code: "49999",
    shortdesc: "UNLISTED PROCEDURE ABDOMEN PERITONEUM & OMENTUM",
  },
  { code: "82103", shortdesc: "ALPHA-1-ANTITRYPSIN TOTAL" },
  { code: "54865", shortdesc: "EXPLORATION EPIDIDYMIS W/WO BIOPSY" },
  { code: "32405", shortdesc: "BIOPSY LUNG/MEDIASTINUM PERCUTANEOUS NEEDLE" },
  { code: "26412", shortdesc: "REPAIR EXTENSOR TENDON HAND W/GRAFT EACH" },
  { code: "26011", shortdesc: "DRAINAGE FINGER ABSCESS COMPLICATED" },
  { code: "13102", shortdesc: "REPAIR COMPLEX TRUNK EACH ADDITIONAL 5 CM/<" },
  {
    code: "50970",
    shortdesc: "URETERAL ENDOSCOPY VIA URETEROTOMY W/O IMAGING",
  },
  {
    code: "50770",
    shortdesc: "TRANSURETEROURETEROSTOMY ANAST URETER CLAT URTR",
  },
  { code: "80150", shortdesc: "DRUG SCREEN QUANTITATIVE AMIKACIN" },
  { code: "3006F", shortdesc: "CHEST X-RAY RESULTS DOCUMENTED & REVIEWED" },
  {
    code: "27027",
    shortdesc: "DECOMPRESSION FASCIOTOMY PELVIC COMPARTMENT UNI",
  },
  { code: "90476", shortdesc: "ADENOVIRUS VACCINE TYPE 4 LIVE ORAL" },
  { code: "83735", shortdesc: "ASSAY OF MAGNESIUM" },
  { code: "11308", shortdesc: "SHAVING SKIN LESION 1 S/N/H/F/G DIAM >2.0 CM" },
  { code: "81465", shortdesc: "WHOLE MITOCHONDRIAL GENOME ANALYSIS PANEL" },
  { code: "30540", shortdesc: "REPAIR CHOANAL ATRESIA INTRANASAL" },
  { code: "21235", shortdesc: "GRAFT EAR CRTLG AUTOGENOUS NOSE/EAR" },
  { code: "0498T", shortdesc: "XTRNL PT ACT ECG W/O ATTN MNTR R&I PR 30 DAYS" },
  { code: "68770", shortdesc: "CLOSURE LACRIMAL FISTULA SPX" },
  { code: "0562T", shortdesc: "null" },
  { code: "54115", shortdesc: "REMOVAL FOREIGN BODY DEEP PENILE TISSUE" },
  {
    code: "33365",
    shortdesc: "REPLACE AORTIC VALVE OPEN TRANSAORTIC APPROACH",
  },
  { code: "3084F", shortdesc: "KT/V >/= 1.7" },
  { code: "0120T", shortdesc: "null" },
  { code: "57022", shortdesc: "I&D VAGINAL HEMATOMA OBSTETRICAL/POSTPARTUM" },
  { code: "11404", shortdesc: "EXC B9 LESION MRGN XCP SK TG T/A/L 3.1-4.0 CM" },
  { code: "29200", shortdesc: "STRAPPING THORAX" },
  {
    code: "43201",
    shortdesc: "ESOPHAGOSCOPY FLEXIBLE TRANSORAL W SUBMUCOUS INJ",
  },
  {
    code: "35631",
    shortdesc: "BYP OTH/THN VEIN AORTOCELIAC AORTOMSN AORTORNL",
  },
  { code: "80197", shortdesc: "DRUG SCREEN QUANTITATIVE TACROLIMUS" },
  { code: "88334", shortdesc: "PATH CONSLTJ SURG CYTOLOGIC EXAM ADDL SITE" },
  { code: "70553", shortdesc: "MRI BRAIN BRAIN STEM W/O W/CONTRAST MATERIAL" },
  {
    code: "0214T",
    shortdesc: "NJX DX/THER PARAVER FCT JT W/US CER/THOR 2ND LVL",
  },
  { code: "01742", shortdesc: "ANESTHESIA OPEN/SURG ARTHRS OSTEOTOMY HUMERUS" },
  { code: "58752", shortdesc: "TUBOUTERINE IMPLANTATION" },
  {
    code: "38210",
    shortdesc: "TRNSPL PREPJ HEMATOP PROGEN DEPLJ IN HRV T-CELL",
  },
  { code: "0038U", shortdesc: "null" },
  { code: "15823", shortdesc: "BLEPHAROPLASTY UPPER EYELID W/EXCESSIVE SKIN" },
  {
    code: "92995",
    shortdesc: "PRQ TRLUML CORON ATHERECT W/WO ANGIOP 1 VESSEL",
  },
  { code: "50370", shortdesc: "RMVL TRNSPLED RENAL ALLOGRAFT" },
  {
    code: "50715",
    shortdesc: "URETEROLYSIS W/WORPSG URETER RETROPERIT FIBROSIS",
  },
  { code: "15003", shortdesc: "PREP SITE TRUNK/ARM/LEG ADDL 100 SQ CM/1PCT" },
  { code: "92625", shortdesc: "ASSESSMENT TINNITUS" },
  { code: "54692", shortdesc: "LAPAROSCOPY ORCHIOPEXY INTRA-ABDOMINAL TESTIS" },
  {
    code: "64634",
    shortdesc: "DSTR NROLYTC AGNT PARVERTEB FCT ADDL CRVCL/THORA",
  },
  { code: "24931", shortdesc: "AMPUTATION ARM THRU HUMERUS W/IMPLANT" },
  {
    code: "78606",
    shortdesc: "BRAIN IMAGING MIN 4 STATIC VIEWS W VASCULAR FLOW",
  },
  { code: "36216", shortdesc: "SLCTV CATHJ 1ST 2ND ORD THRC/BRCH/CPHLC BRNCH" },
  { code: "46924", shortdesc: "DSTRJ LESION ANUS EXTENSIVE" },
  {
    code: "61875",
    shortdesc: "CRNEC IMPLTJ NSTIM ELTRD CEREBELLAR SUBCORTICAL",
  },
  { code: "37228", shortdesc: "REVSC OPN/PRQ TIB/PERO W/ANGIOPLASTY UNI" },
  {
    code: "27040",
    shortdesc: "BIOPSY SOFT TISSUE PELVIS&HIP AREA SUPERFICIAL",
  },
  { code: "25635", shortdesc: "CLTX CARPAL BONE FX W/MANJ EACH BONE" },
  { code: "99211", shortdesc: "OFFICE OUTPATIENT VISIT 5 MINUTES" },
  { code: "81326", shortdesc: "PMP22 GENE ANALYSIS KNOWN FAMILIAL VARIANT" },
  {
    code: "37765",
    shortdesc: "STAB PHLEBT VARICOSE VEINS 1 XTR 10-20 STAB INCS",
  },
  { code: "59430", shortdesc: "POSTPARTUM CARE ONLY SEPARATE PROCEDURE" },
  { code: "00940", shortdesc: "ANESTHESIA VAGINAL PROCEDURE W/BIOPSY NOS" },
  { code: "47741", shortdesc: "CHOLECSTONTRSTM ROUX-EN-Y W/GASTRONTRSTM" },
  { code: "30020", shortdesc: "DRAINAGE ABSCESS/HEMATOMA NASAL SEPTUM" },
  { code: "51795", shortdesc: "null" },
  { code: "27268", shortdesc: "CLOSED TX FEMORAL FRACTURE PROX HEAD W/MANJ" },
  { code: "76872", shortdesc: "US TRANSRECTAL" },
  {
    code: "31654",
    shortdesc: "BRNSCHSC TNDSC EBUS DX/TX INTERVENTION PERPH LES",
  },
  {
    code: "95971",
    shortdesc: "ELEC ALYS NSTIM PLS GEN SMPL SC/PERPH W/PRGRMG",
  },
  {
    code: "0287T",
    shortdesc: "NEARINFRED GUIDANCE VASC ACES RL TIME DIG VISU",
  },
  { code: "90773", shortdesc: "null" },
  { code: "81167", shortdesc: "null" },
  {
    code: "99485",
    shortdesc: "SUPERVISION INTERFACILITY TRANSPORT INIT 30 MIN",
  },
  { code: "59031", shortdesc: "null" },
  { code: "97546", shortdesc: "WORK HARDENING/CONDITIONING EACH HOUR" },
  { code: "87496", shortdesc: "IADNA CYTOMEGALOVIRUS AMPLIFIED PROBE TQ" },
  { code: "49520", shortdesc: "RPR RECRT INGUINAL HERNIA ANY AGE REDUCIBLE" },
  {
    code: "27238",
    shortdesc: "CLTX INTER/PERI/SUBTROCHANTERIC FEM FX W/O MANJ",
  },
  { code: "91120", shortdesc: "RECTAL SESATION TONE & COMPLIANCE TEST" },
  { code: "31368", shortdesc: "LARYNGECTOMY STOT SUPRAGLOTTIC W/RAD NCK DSJ" },
  { code: "60650", shortdesc: "LAPAROSCOPY ADRENALECTOMY PRTL/COMPL TABDL" },
  {
    code: "93451",
    shortdesc: "RIGHT HEART CATH O2 SATURATION & CARDIAC OUTPUT",
  },
  { code: "81111", shortdesc: "HPA-9 GENOTYPING GENE ANALYSIS COMMON VARIANT" },
  { code: "82365", shortdesc: "CALCULUS INFRARED SPECTROSCOPY" },
  { code: "54520", shortdesc: "ORCHIECTOMY SIMPLE SCROTAL/INGUINAL APPROACH" },
  { code: "60210", shortdesc: "PRTL THYROID LOBECTOMY UNI W/WO ISTHMUSECTOMY" },
  { code: "28100", shortdesc: "EXCISION/CURETTAGE CYST/TUMOR TALUS/CALCANEUS" },
  {
    code: "52282",
    shortdesc: "CYSTOURETHROSCOPY INSERTION PERM URETHRAL STENT",
  },
  {
    code: "36245",
    shortdesc: "SLCTV CATHJ EA 1ST ORD ABDL PEL/LXTR ART BRNCH",
  },
  { code: "86360", shortdesc: "T CELLS ABSOLUTE CD4&CD8 COUNT RATIO" },
  { code: "50323", shortdesc: "BKBENCH PREPJ CADAVER DONOR RENAL ALLOGRAFT" },
  { code: "97003", shortdesc: "OCCUPATIONAL THERAPY EVALUATION" },
  { code: "41821", shortdesc: "OPRCULECTOMY EXC PRICORONAL TISSUE" },
  { code: "76827", shortdesc: "DOPPLER ECHO FETAL SPECTRAL DISPLAY COMPLETE" },
  { code: "44365", shortdesc: "ENTEROSCOPY > 2ND PRTN W/RMVL LESION CAUTERY" },
  {
    code: "21390",
    shortdesc: "OPTX ORB FLOOR BLWT FX PRI/BITAL APPR W/ALLPLSTC",
  },
  { code: "5200F", shortdesc: "CONSID NEURO EVAL APPROP SURG THXPY EPIL 3YRS" },
  { code: "88250", shortdesc: "null" },
  { code: "87301", shortdesc: "IAAD IA ADENOVIRUS ENTERIC TYP 40/41" },
  { code: "86357", shortdesc: "NATURAL KILLER CELLS TOTAL COUNT" },
  { code: "00950", shortdesc: "ANESTHESIA CULDOSCOPY INCLUDING BIOPSY" },
  { code: "54326", shortdesc: "1 STG DSTL HYPOSPADIAS RPR URTP SKN FLAPS" },
  { code: "73200", shortdesc: "CT UPPER EXTREMITY W/O CONTRAST MATERIAL" },
  { code: "93541", shortdesc: "null" },
  {
    code: "61626",
    shortdesc: "TCAT PERMANT OCCLUSION/EMBOLIZATION PRQ NON-CNS",
  },
  {
    code: "63662",
    shortdesc: "RMVL SPINAL NSTIM ELTRD PLATE/PADDLE INCL FLUOR",
  },
  { code: "58110", shortdesc: "ENDOMETRIAL BX CONJUNCT W/COLPOSCOPY" },
  { code: "77058", shortdesc: "MRI BREAST UNILATERAL" },
  { code: "81295", shortdesc: "MSH2 GENE ANALYSIS FULL SEQUENCE ANALYSIS" },
  { code: "84275", shortdesc: "ASSAY OF SIALIC ACID" },
  { code: "43247", shortdesc: "EGD FLEXIBLE FOREIGN BODY REMOVAL" },
  { code: "96904", shortdesc: "WHOLE BODY INTEGUMENTARY PHOTOGRAPHY" },
  { code: "54506", shortdesc: "null" },
  { code: "99221", shortdesc: "INITIAL HOSPITAL CARE/DAY 30 MINUTES" },
  {
    code: "27339",
    shortdesc: "EXC TUMOR SOFT TISSUE THIGH/KNEE SUBFASC 5 CM/>",
  },
  {
    code: "35241",
    shortdesc: "RPR BLOOD VESSEL VEIN GRAFT INTRATHORACIC W/BYP",
  },
  { code: "35533", shortdesc: "BYPASS W/VEIN AXILLARY-FEMORAL-FEMORAL" },
  { code: "95927", shortdesc: "SHORT-LATENCY SOMATOSENS EP STD TRNK/HEAD" },
  { code: "01656", shortdesc: "ANESTHESIA AXILLARY-FEMORAL BYPASS GRAFT" },
  { code: "0262T", shortdesc: "IMPLTJ CATH-DLVR PRSTHC PULM VLV EVASC APPR" },
  { code: "37187", shortdesc: "PRQ TRANSLUMINAL MECHANICAL THROMBECTOMY VEIN" },
  { code: "73722", shortdesc: "MRI ANY JT LOWER EXTREM W/CONTRAST MATERIAL" },
  { code: "42890", shortdesc: "LIMITED PHARYNGECTOMY" },
  {
    code: "51590",
    shortdesc: "CSTC COMPL W/URTROILEAL CONDUIT/BLDR W/INT ANAST",
  },
  { code: "27330", shortdesc: "ARTHROTOMY KNEE W/SYNOVIAL BIOPSY ONLY" },
  {
    code: "4196F",
    shortdesc: "PT NOT RCVNG 1ST BIOL ANTI-RHEUM DRUG THXPY RA",
  },
  { code: "95044", shortdesc: "PATCH/APPLICATION TEST SPECIFY NUMBER TESTS" },
  { code: "31592", shortdesc: "CRICOTRACHEAL RESECTION" },
  { code: "88148", shortdesc: "CYTP SMRS C/V SCR AUTO SYS MNL RESCR PHYS" },
  { code: "93744", shortdesc: "null" },
  { code: "70492", shortdesc: "CT SOFT TISSUE NECK W/O & W/CONTRAST MATERIAL" },
  { code: "90669", shortdesc: "PNEUMOCOCCAL CONJ VACCINE 7 VALENT IM" },
  {
    code: "40654",
    shortdesc: "RPR LIP FULL THKNS >ONE-HALF VERT HEIGHT/COMPLE",
  },
  { code: "75731", shortdesc: "ANGIOGRAPHY ADRENAL UNILATERAL SLCTV RS&I" },
  { code: "11102", shortdesc: "null" },
  { code: "69805", shortdesc: "ENDOLYMPHATIC SAC W/O SHUNT" },
  { code: "64862", shortdesc: "SUTURE LUMBAR PLEXUS" },
  {
    code: "56637",
    shortdesc: "VULVECTOMY RAD COMPL BI INGUINOFEM LMPHADECTOMY",
  },
  {
    code: "42894",
    shortdesc: "RESCJ PHRNGL WALL CLSR W/FLP OR FLP W/MVASC ANAS",
  },
  { code: "11402", shortdesc: "EXC B9 LESION MRGN XCP SK TG T/A/L 1.1-2.0 CM" },
  {
    code: "83788",
    shortdesc: "MASS SPECT&TANDEM MASS SPECT ANAL QUAL EA SPEC",
  },
  { code: "23170", shortdesc: "SEQUESTRECTOMY CLAVICLE" },
  {
    code: "0427T",
    shortdesc: "INSJ/RPLC NSTIM SYSTEM SLEEP APNEA PLS GENERATOR",
  },
  {
    code: "26075",
    shortdesc: "ARTHRT EXPL DRG/RMVL LOOSE/FB MTCARPHLNGL JT EA",
  },
  { code: "25100", shortdesc: "ARTHROTOMY WRIST JOINT WITH BIOPSY" },
  { code: "76394", shortdesc: "null" },
  {
    code: "87077",
    shortdesc: "CUL BACT AEROBIC ADDL METHS DEFINITIVE EA ISOL",
  },
  { code: "33670", shortdesc: "RPR COMPL AV CANAL W/WO PROSTC VALVE" },
  {
    code: "86826",
    shortdesc: "HLA CROSSMATCH NONCYTOTOXIC ADDL SERUM/DILUTION",
  },
  { code: "99395", shortdesc: "PERIODIC PREVENTIVE MED EST PATIENT 18-39 YRS" },
  { code: "27894", shortdesc: "DCMPRN FASCT LEG ANT&/LAT&PST W/DBRDMT MUS" },
  {
    code: "78494",
    shortdesc: "CARD BL POOL GATED SPECT REST WAL MOTN EJCT FRCT",
  },
  {
    code: "27179",
    shortdesc: "OPTX SLP FEM EPIPHYSIS OSTPL FEM NCK HEYMAN PX",
  },
  { code: "90757", shortdesc: "null" },
  {
    code: "67973",
    shortdesc: "RCNSTJ EYELID FULL THICKNESS LOWER EYELID 1 STG",
  },
  { code: "0113U", shortdesc: "null" },
  { code: "99293", shortdesc: "null" },
  { code: "57057", shortdesc: "null" },
  { code: "97010", shortdesc: "APPLICATION MODALITY 1/> AREAS HOT/COLD PACKS" },
  { code: "57460", shortdesc: "COLPOSCOPY CERVIX VAG LOOP ELTRD BX CERVIX" },
  { code: "46210", shortdesc: "null" },
  { code: "64857", shortdesc: "SUTR PRPH NRV ARM/LEG XCP SCIATIC W/O TRPOS" },
  { code: "23101", shortdesc: "ARTHRT ACROMCLAV/STRNCLAV JT W/BX&/EXC CRTLG" },
  { code: "3021F", shortdesc: "LEFT VENTRICULAR EJECTION FRACTION <40%" },
  {
    code: "97802",
    shortdesc: "MEDICAL NUTRITION ASSMT&IVNTJ INDIV EACH 15 MI",
  },
  { code: "38221", shortdesc: "DIAGNOSTIC BONE MARROW BIOPSIES" },
  { code: "90853", shortdesc: "GROUP PSYCHOTHERAPY" },
  { code: "61703", shortdesc: "ICRA CRV APPL OCCLUDING CLAMP CRV CRTD ART" },
  {
    code: "00562",
    shortdesc: "ANES HRT PERICRD SAC&GRT VSLS W/PMP OXTJ >1MO PO",
  },
  { code: "78075", shortdesc: "ADRENAL IMAGING CORTEX &/MEDULLA" },
  {
    code: "61480",
    shortdesc: "CRNEC SUBOCPTL MESENCEPHAL TRCOTOMY/PEDUNCULOTMY",
  },
  { code: "44500", shortdesc: "INTRODUCTION LONG GI TUBE SEPARATE PROCEDURE" },
  { code: "43502", shortdesc: "GASTROTOMY W/SUTR RPR PRE-ESOPG/GASTRIC LAC" },
  { code: "56821", shortdesc: "COLPOSCOPY VULVA W/BIOPSY" },
  {
    code: "32605",
    shortdesc: "THORACOSCOPY DX MEDIASTINAL SPACE W/O BIOPSY SPX",
  },
  {
    code: "0267T",
    shortdesc: "IM/REPL CARTD SINS BAROREFLX ACTIV DEV LEAD ONLY",
  },
  { code: "53220", shortdesc: "EXC/FULGURATION CARCINOMA URETHRA" },
  {
    code: "50081",
    shortdesc: "PRQ NEPHROSTOLITHOTOMY/PYELOSTOLITHOTOMY > 2 CM",
  },
  {
    code: "33367",
    shortdesc: "REPLACE AORTIC VALVE W/BYP PRQ ART/VENOUS APPRCH",
  },
  { code: "21208", shortdesc: "OSTEOPLASTY FACIAL BONES AUGMENTATION" },
  { code: "25515", shortdesc: "OPEN TREATMENT RADIAL SHAFT FRACTURE" },
  { code: "64910", shortdesc: "NERVE REPAIR W/CONDUIT EACH NERVE" },
  {
    code: "37206",
    shortdesc: "TCAT PLMT IV STENT PERCUTANEOUS EACH ADDL VESSEL",
  },
  { code: "26117", shortdesc: "RAD RESECT TUMOR SOFT TISSUE HAND/FINGER <3CM" },
  { code: "26410", shortdesc: "REPAIR EXTENSOR TENDON HAND W/O GRAFT EACH" },
  { code: "43030", shortdesc: "CRICOPHARYNGEAL MYOTOMY" },
  {
    code: "0356T",
    shortdesc: "INSERT DRUG IMPLANT INTO LACRIMAL CANAL FOR IOP",
  },
  { code: "55100", shortdesc: "DRAINAGE SCROTAL WALL ABSCESS" },
  {
    code: "92522",
    shortdesc: "EVALUATION OF SPEECH SOUND PRODUCTION ARTICULATE",
  },
  { code: "90378", shortdesc: "RESPIRATORY SYNCYTIAL VIRUS IG IM 50 MG E" },
  { code: "83020", shortdesc: "HEMOGLOBIN FRACTJ/QUANTJ ELECTROPHORESIS" },
  { code: "36261", shortdesc: "REVJ IMPLANTED INTRA-ARTERIAL INFUSION PUMP" },
  { code: "00104", shortdesc: "ANESTHESIA ELECTROCONVULSIVE THERAPY" },
  { code: "86648", shortdesc: "ANTIBODY DIPHTHERIA" },
  { code: "1091F", shortdesc: "URINE INCONTINENCE CHARACTERIZED" },
  { code: "85345", shortdesc: "COAGULATION TIME LEE AND WHITE" },
  { code: "45340", shortdesc: "SIGMOIDOSCOPY FLX TNDSC BALO DILAT" },
  { code: "83631", shortdesc: "LACTOFERRIN FECAL QUANTITATIVE" },
  { code: "44720", shortdesc: "BKBENCH RCNSTJ INT ALGRFT VEN ANAST EA" },
  {
    code: "47541",
    shortdesc: "PLMT ACCESS THRU BILIARY TREE INTO SMALL BWL NEW",
  },
  {
    code: "32661",
    shortdesc: "THORACOSCOPY W/EXC PERICARDIAL CYST TUMOR/MASS",
  },
  { code: "94776", shortdesc: "PEDIATRIC APNEA MONITOR ANALYSES COMPUTER" },
  { code: "92391", shortdesc: "null" },
  { code: "99282", shortdesc: "EMERGENCY DEPARTMENT VISIT LOW/MODER SEVERITY" },
  {
    code: "55812",
    shortdesc: "PROSTATECTOMY PERINEAL RADICAL W/LYMPH NODE BX",
  },
  {
    code: "37193",
    shortdesc: "RTRVL INTRVAS VC FILTR W/WO ACS VSL SELXN RS&I",
  },
  {
    code: "20696",
    shortdesc: "XTRNL FIXJ W/STEREOTACTIC ADJUSTMENT 1ST & SUBQ",
  },
  {
    code: "25077",
    shortdesc: "RAD RESECT TUMOR SOFT TISS FOREARM&/WRIST <3 CM",
  },
  {
    code: "64553",
    shortdesc: "PRQ IMPLTJ NEUROSTIMULATOR ELTRD CRANIAL NERVE",
  },
  {
    code: "99461",
    shortdesc: "1ST CARE PR DAY NML NB XCPT HOSP/BIRTHING CENTER",
  },
  { code: "88349", shortdesc: "ELECTRON MICROSCOPY SCANNING" },
  {
    code: "90958",
    shortdesc: "ESRD RELATED SVC MONTHLY 12-19 YR OLD 2/3 VISITS",
  },
  { code: "66740", shortdesc: "CILIARY BODY DESTRUCTION CYCLODIALYSIS" },
  { code: "0212T", shortdesc: "COMPRE AUDIOM THRESHOLD EVAL & SPEECH RECOG" },
  { code: "25924", shortdesc: "DISARTICULATION THRU WRIST RE-AMPUTATION" },
  { code: "17304", shortdesc: "null" },
  { code: "99332", shortdesc: "null" },
  { code: "31637", shortdesc: "BRONCHOSCOPY EACH MAJOR BRONCHUS STENTED" },
  { code: "27137", shortdesc: "REVJ TOT HIP ARTHRP ACTBLR W/WO AGRFT/ALGRFT" },
  {
    code: "57423",
    shortdesc: "PARAVAGINAL DEFECT REPAIR LAPAROSCOPIC APPROACH",
  },
  { code: "75635", shortdesc: "CTA ABDL AORTA&BI ILIOFEM W/CONTRAST&POSTP" },
  { code: "90707", shortdesc: "MEASLES MUMPS RUBELLA VIRUS VACCINE LIVE SUBQ" },
  {
    code: "77078",
    shortdesc: "CT BONE MINERL DENSITY STUDY 1/> SITS AXIAL SKE",
  },
  { code: "26860", shortdesc: "ARTHRODESIS INTERPHALANGEAL JT W/WO INT FIXJ" },
  {
    code: "15740",
    shortdesc: "FLAP ISLAND PEDICLE ANATOMIC NAMED AXIAL ARTERY",
  },
  { code: "58990", shortdesc: "null" },
  { code: "15000", shortdesc: "null" },
  { code: "32997", shortdesc: "TOTAL LUNG LAVAGE UNILATERAL" },
  {
    code: "27516",
    shortdesc: "CLTX DISTAL FEMORAL EPIPHYSL SEPARATION W/O MANJ",
  },
  { code: "61692", shortdesc: "INTRACRANIAL ARVEN MALFRMJ DURAL CMPL" },
  { code: "87560", shortdesc: "IADNA MYCOBACTERIA AVIUM-INTRACLRE DIR PRB" },
  { code: "59812", shortdesc: "TX INCOMPLETE ABORTION ANY TRIMESTER SURGICAL" },
  { code: "97542", shortdesc: "WHEELCHAIR MGMT EA 15 MIN" },
  { code: "88167", shortdesc: "CYTP SLIDES C/V MNL SCR&CPTR RESCR CELL S&I" },
  {
    code: "78110",
    shortdesc: "PLASMA VOL RADIOPHARM VOL DILUTION SPX 1 SAMPLE",
  },
  { code: "0492T", shortdesc: "ABL LASER TX OPEN WND PR DAY ADDL 20 SQCM" },
  {
    code: "81381",
    shortdesc: "HLA I TYPING HIGH RESOLUTION 1 ALLELE/ALLELE GRP",
  },
  { code: "0026U", shortdesc: "null" },
  {
    code: "99382",
    shortdesc: "INITIAL PREVENTIVE MEDICINE NEW PT AGE 1-4 YRS",
  },
  {
    code: "33530",
    shortdesc: "ROPRTJ CAB/VALVE PX > 1 MO AFTER ORIGINAL OPERJ",
  },
  { code: "63741", shortdesc: "CRTJ SHUNT LMBR SARACH-PRTL-PLEURAL PRQ X LAM" },
  { code: "21242", shortdesc: "ARTHROPLASTY TEMPOROMANDIBULAR JT W/ALLOGRAFT" },
  {
    code: "00813",
    shortdesc: "ANESTHESIA COMBINED UPPER&LOWER GI ENDOSCOPIC PX",
  },
  { code: "64771", shortdesc: "TRANSECTION/AVULSION OTH CRANIAL NRV XDRL" },
  { code: "25355", shortdesc: "OSTEOTOMY RADIUS MIDDLE/PROXIMAL THIRD" },
  { code: "29804", shortdesc: "ARTHROSCOPY TEMPOROMANDIBULAR JOINT SURGICAL" },
  { code: "01484", shortdesc: "ANES OPEN OSTEOTOMY/OSTEOPLASTY TIBIA&/FIBULA" },
  { code: "99452", shortdesc: "null" },
  { code: "33321", shortdesc: "SUTR RPR AORTA/GREAT VESSEL W/SHUNT BYPASS" },
  {
    code: "90460",
    shortdesc: "IM ADM THRU 18YR ANY RTE 1ST/ONLY COMPT VAC/TOX",
  },
  { code: "27700", shortdesc: "ARTHROPLASTY ANKLE" },
  { code: "99323", shortdesc: "null" },
  {
    code: "24620",
    shortdesc: "CLOSED TX MONTEGGIA FX DISLOCATION ELBOW W/MANJ",
  },
  { code: "01360", shortdesc: "ANESTHESIA OPEN PROCEDURES LOWER 1/3 FEMUR" },
  {
    code: "23105",
    shortdesc: "ARTHRT GLENOHUMRL JT W/SYNOVECTOMY W/WO BIOPSY",
  },
  {
    code: "56632",
    shortdesc: "VULVECTOMY RAD PRTL BI INGUINOFEM LMPHADECTOMY",
  },
  { code: "53431", shortdesc: "URTP W/TUBULARIZATION POST URT&/LWR BLDR" },
  { code: "43460", shortdesc: "ESOPG/GSTR TAMPONADE W/BALO SENGSTAKEN TYPE" },
  { code: "95028", shortdesc: "IC TSTS W/ALLGIC XTRCS DLYD TYP RXN W/READING" },
  { code: "27740", shortdesc: "ARREST EPIPHYSEAL ANY METHOD TIBIA & FIBULA" },
  {
    code: "12001",
    shortdesc: "SIMPLE REPAIR SCALP/NECK/AX/GENIT/TRUNK 2.5CM/<",
  },
  {
    code: "37222",
    shortdesc: "REVASCULARIZATION ILIAC ART ANGIOP EA IPSI VSL",
  },
  {
    code: "58263",
    shortdesc: "VAG HYST 250 GM/< W/RMVL TUBE OVARY W/RPR NTRCL",
  },
  { code: "55970", shortdesc: "INTERSEX SURG MALE FEMALE" },
  {
    code: "27630",
    shortdesc: "EXCISION LESION TENDON SHEATH/CAPSULE LEG&/ANK",
  },
  {
    code: "44121",
    shortdesc: "ENTERECTOMY RESCJ SMALL INTESTINE EA RESCJ & ANA",
  },
  { code: "38700", shortdesc: "SUPRAHYOID LYMPHADENECTOMY" },
  {
    code: "92929",
    shortdesc: "PRQ TRLUML CORONARY STENT W/ANGIO ADDL ART/BRNCH",
  },
  {
    code: "90812",
    shortdesc: "INDIV PSYCTX INTERACTIVE OFFICE/OUTPT 45-50 MIN",
  },
  {
    code: "00625",
    shortdesc: "ANES THRC SPINE & CORD ANT APPR W/O 1 LUNG VENTJ",
  },
  { code: "45915", shortdesc: "RMVL FECAL IMPACTION/FB SPX UNDER ANES" },
  {
    code: "0252T",
    shortdesc: "BRONCHOSCOPY REMOVAL BRONCH VALVE EA ADDL LOBE",
  },
  { code: "81411", shortdesc: "AORTIC DYSFUNCTION/DILATION DUP/DEL ANALYSIS" },
  {
    code: "15111",
    shortdesc: "EPIDRM AGRFT T/A/L EA 100 CM/EA 1% BDY INFT/CHLD",
  },
  { code: "44397", shortdesc: "COLONOSCOPY STOMA W/TNDSC STENT PLMT" },
  { code: "56501", shortdesc: "DESTRUCTION LESIONS VULVA SIMPLE" },
  { code: "00820", shortdesc: "ANESTHESIA LOWER POSTERIOR ABDOMINAL WALL" },
  {
    code: "46600",
    shortdesc: "ANOSCOPY DX W/COLLJ SPEC BR/WA SPX WHEN PRFRMD",
  },
  { code: "81188", shortdesc: "null" },
  { code: "0121U", shortdesc: "null" },
  { code: "37660", shortdesc: "LIGATION OF COMMON ILIAC VEIN" },
  { code: "73521", shortdesc: "RADEX HIPS BILATERAL WITH PELVIS 2 VIEWS" },
  { code: "0556T", shortdesc: "null" },
  { code: "85555", shortdesc: "OSMOTIC FRAGILITY RBC UNINCUBATED" },
  { code: "66635", shortdesc: "IRDEC CRNLSCLRL/CRNL SCTJ OPTICAL SPX" },
  { code: "40650", shortdesc: "RPR LIP FULL THICKNESS VERMILION ONLY" },
  { code: "78806", shortdesc: "RP LOCLZJ INFLAMMATORY PROCESS WHOLE BODY" },
  { code: "0518F", shortdesc: "FALLS PLAN OF CARE DOCUMENTED" },
  { code: "93202", shortdesc: "null" },
  {
    code: "61796",
    shortdesc: "STEREOTACTIC RADIOSURGERY 1 SIMPLE CRANIAL LES",
  },
  { code: "62351", shortdesc: "IMPLTJ REVJ/RPSG ITHCL/EDRL CATH W/LAM" },
  { code: "99440", shortdesc: "null" },
  { code: "35641", shortdesc: "null" },
  { code: "87337", shortdesc: "IAAD IA ENTAMOEBA HISTOLYTICA GRP" },
  { code: "0328T", shortdesc: "null" },
  { code: "53200", shortdesc: "BIOPSY URETHRA" },
  { code: "47000", shortdesc: "BIOPSY LIVER NEEDLE PERCUTANEOUS" },
  {
    code: "24363",
    shortdesc: "ARTHRP ELBOW W/DISTAL HUM&PROX UR PROSTC RPLCM",
  },
  { code: "60200", shortdesc: "EXC CYST/ADENOMA THYROID/TRANSECTION ISTHMUS" },
  {
    code: "95887",
    shortdesc: "NEEDLE EMG NONEXTREMTY MSCLES W/NERVE CONDUCTION",
  },
  { code: "21810", shortdesc: "TX RIB FRACTURE EXTERNAL FIXATION FLAIL CHEST" },
  {
    code: "31652",
    shortdesc: "BRNCHSC EBUS GUIDED SAMPL 1/2 NODE STATION/STRUX",
  },
  { code: "65860", shortdesc: "SEVERING ADHESIONS ANTERIOR SEGMENT LASER SPX" },
  {
    code: "29836",
    shortdesc: "ARTHROSCOPY ELBOW SURGICAL SYNOVECTOMY COMPLETE",
  },
  { code: "83901", shortdesc: "MOLECULAR DX AMP TARGET MULTIPLEX EA ADDL SEQ" },
  {
    code: "76816",
    shortdesc: "US PREG UTERUS REAL TIME F/U TRNSABDL PER FETUS",
  },
  { code: "94610", shortdesc: "INTRAPULMONARY SURFACTANT ADMINISTJ PHYS/QHP" },
  {
    code: "11950",
    shortdesc: "SUBCUTANEOUS INJECTION FILLING MATERIAL 1 CC/<",
  },
  { code: "24430", shortdesc: "REPAIR NON/MALUNION HUMERUS W/O GRAFT" },
  { code: "3061F", shortdesc: "NEGATIVE MICROALBUMINURIA TEST RESULT DOC&REV" },
  { code: "76083", shortdesc: "null" },
  {
    code: "78481",
    shortdesc: "CARD BL POOL PLANAR 1 STDY WAL MOTN EJECT FRACT",
  },
  { code: "42260", shortdesc: "REPAIR NASOLABIAL FISTULA" },
  { code: "32820", shortdesc: "MAJOR RECONSTRUCTION CHEST WALL POSTTRAUMATIC" },
  {
    code: "01400",
    shortdesc: "ANES OPEN/SURG ARTHROSCOPIC PROC KNEE JOINT NOS",
  },
  { code: "0545F", shortdesc: "PLAN FOR FOLLOW-UP CARE FOR MDD DOCD" },
  { code: "80348", shortdesc: "DRUG SCREENING BUPRENORPHINE" },
  {
    code: "97755",
    shortdesc: "ASSTV TECHNOL ASSMT DIR CNTCT W/REPRT EA 15 MIN",
  },
  { code: "90586", shortdesc: "BACILLUS CALMETTE-GUERIN VACCINE INTRAVESICAL" },
  { code: "81171", shortdesc: "null" },
  {
    code: "25530",
    shortdesc: "CLOSED TX ULNAR SHAFT FRACTURE W/O MANIPULATION",
  },
  {
    code: "61321",
    shortdesc: "CRANIECTOMY/CRANIOTMY DRG ABSCESS INFRATENTORIAL",
  },
  {
    code: "60281",
    shortdesc: "EXCISION THYROGLOSSAL DUCT CYST/SINUS RECURRENT",
  },
  { code: "84540", shortdesc: "ASSAY OF UREA NITROGEN URINE" },
  { code: "23620", shortdesc: "CLTX GREATER HUMERAL TUBEROSITY FX W/O MANJ" },
  { code: "26455", shortdesc: "TENOTOMY FLEXOR FINGER OPEN EACH TENDON" },
  { code: "11041", shortdesc: "null" },
  { code: "37180", shortdesc: "VENOUS ANASTOMOSIS OPEN SPLENORENAL PROXIMAL" },
  { code: "30930", shortdesc: "FRACTURE NASAL INFERIOR TURBINATE THERAPEUTIC" },
  {
    code: "15946",
    shortdesc: "EXC ISCHIAL PR ULCER W/OSTC MUSC/MYOQ FLAP/SKIN",
  },
  { code: "01680", shortdesc: "ANES SHOULDER CAST APPL REMOVAL/REPAIR NOS" },
  { code: "31577", shortdesc: "LARYNGOSCOPY FLX RMVL FOREIGN BODY(S)" },
  { code: "52310", shortdesc: "CYSTO W/SIMPLE REMOVAL STONE & STENT" },
  {
    code: "90911",
    shortdesc: "BIOFDBK TRNG PERINL MUSC ANORECT/URO SPHX W/EMG",
  },
  { code: "87172", shortdesc: "PINWORM EXAMINATION" },
  { code: "99292", shortdesc: "CRITICAL CARE ILL/INJURED PATIENT ADDL 30 MIN" },
  { code: "56500", shortdesc: "null" },
  { code: "79101", shortdesc: "RP THERAPY INTRAVENOUS ADMINISTRATION" },
  { code: "49600", shortdesc: "RPR SMALL OMPHALOCELE W/PRIMARY CLOSURE" },
  { code: "95955", shortdesc: "EEG NONINTRACRANIAL SURGERY" },
  {
    code: "21013",
    shortdesc: "EXC TUMOR SOFT TISS FACE&SCALP SUBFASCIAL <2CM",
  },
  { code: "93233", shortdesc: "null" },
  { code: "80157", shortdesc: "DRUG ASSAY CARBAMAZEPINE FREE" },
  {
    code: "64635",
    shortdesc: "DSTR NROLYTC AGNT PARVERTEB FCT SNGL LMBR/SACRAL",
  },
  { code: "46750", shortdesc: "SPHNCTROP ANAL INCONTINENCE/PROLAPSE ADULT" },
  {
    code: "96110",
    shortdesc: "DEVELOPMENTAL SCREEN W/SCORING & DOC STD INSTRM",
  },
  { code: "90474", shortdesc: "IM ADM INTRANSL/ORAL EA VACCINE" },
  { code: "66625", shortdesc: "IRDEC CRNLSCLRL/CRNL SCTJ PRPH GLC SPX" },
  {
    code: "64585",
    shortdesc: "REVJ/RMVL PERIPHERAL NEUROSTIMULATOR ELECTRODE",
  },
  { code: "41823", shortdesc: "EXC OSS TUBEROSITIES DENTOALVEOLAR STRUXS" },
  { code: "15781", shortdesc: "DERMABRASION SEGMENTAL FACE" },
  { code: "27604", shortdesc: "INCISION & DRAINAGE LEG/ANKLE INFECTED BURSA" },
  { code: "45540", shortdesc: "PROCTOPEXY ABDOMINAL APPROACH" },
  { code: "77620", shortdesc: "HYPERTHERMIA INTRACAVITARY PROBES" },
  {
    code: "0345T",
    shortdesc: "TRANSCATH MITRAL VALVE REPAIR VIA CORONARY SINUS",
  },
  {
    code: "24498",
    shortdesc: "PROPH TX W/WO METHYLMETHACRYLATE HUMERAL SHAFT",
  },
  { code: "54560", shortdesc: "EXPL UNDESCENDED TESTIS W/ABDOMINAL EXPL" },
  { code: "46320", shortdesc: "EXC THROMBOSED HEMORRHOID XTRNL" },
  { code: "12035", shortdesc: "REPAIR INTERMEDIATE S/A/T/E 12.6-20.0CM" },
  { code: "64823", shortdesc: "SYMPATHECTOMY SUPERFICIAL PALMAR ARCH" },
  { code: "85280", shortdesc: "CLOTTING FACTOR XII HAGEMAN" },
  { code: "90918", shortdesc: "null" },
  { code: "86315", shortdesc: "null" },
  { code: "67229", shortdesc: "EXTENSIVE RETINOPATHY 1/> SESS PRETERM INFANT" },
  {
    code: "28295",
    shortdesc: "CORRJ HALLUX VALGUS W/SESMDC W/PROX METAR OSTEOT",
  },
  {
    code: "11971",
    shortdesc: "REMOVAL TISS EXPANDER W/O INSERTION PROSTHESIS",
  },
  { code: "92134", shortdesc: "COMPUTERIZED OPHTHALMIC IMAGING RETINA" },
  { code: "46610", shortdesc: "ANOSCOPY W/RMVL LESION CAUTERY" },
  {
    code: "93975",
    shortdesc: "DUP-SCAN ARTL FLO ABDL/PEL/SCROT&/RPR ORGN COM",
  },
  { code: "3758F", shortdesc: "PULM FUNC TESTING/PEAK COUGH EXPIRATORY FLOW" },
  { code: "92341", shortdesc: "FITTING SPECTACLES XCPT APHAKIA BIFOCAL" },
  {
    code: "31526",
    shortdesc: "LARYNGOSCOPY W/WO TRACHEOSCOPY W/MICRO/TELESCOPE",
  },
  {
    code: "14350",
    shortdesc: "FILLETED FINGER/TOE FLAP W/PREPJ RECIPIENT SITE",
  },
  {
    code: "27177",
    shortdesc: "OPTX SLP FEM EPIPHYSIS SINGLE/MULT PIN/BONE GRFT",
  },
  { code: "11980", shortdesc: "SUBCUTANEOUS HORMONE PELLET IMPLANTATION" },
  { code: "89135", shortdesc: "null" },
  {
    code: "29837",
    shortdesc: "ARTHROSCOPY ELBOW SURGICAL DEBRIDEMENT LIMITED",
  },
  { code: "27550", shortdesc: "CLOSED TX KNEE DISLOCATION W/O ANESTHESIA" },
  { code: "74160", shortdesc: "CT ABDOMEN W/CONTRAST MATERIAL" },
  {
    code: "88106",
    shortdesc: "CYTP FLU BR/WA XCPT C/V FILTER METH ONLY INTERPJ",
  },
  {
    code: "23670",
    shortdesc: "OPEN TX SHOULDER DISLC W/HUMERAL TUBEROSITY FX",
  },
  { code: "85023", shortdesc: "null" },
  { code: "52000", shortdesc: "CYSTOURETHROSCOPY" },
  {
    code: "46280",
    shortdesc: "TX ANAL FSTL TRANS/SUPRA/XTRASPHNCTRC INCL SETON",
  },
  { code: "90923", shortdesc: "null" },
  { code: "24145", shortdesc: "PARTIAL EXCISION BONE RADIAL HEAD/NECK" },
  { code: "27320", shortdesc: "null" },
  { code: "26180", shortdesc: "EXCISION TENDON FINGER FLEXOR/EXTENSOR EACH" },
  { code: "77021", shortdesc: "MR GUIDANCE NEEDLE PLACEMENT" },
  { code: "36566", shortdesc: "INSJ TUN VAD REQ 2 CATH 2 SITS W/SUBQ PORT" },
  { code: "72127", shortdesc: "CT CERVICAL SPINE W/O &W/CONTRAST MATERIAL" },
  {
    code: "33969",
    shortdesc: "ECMO/ECLS RMVL OF PERPH CANNULA OPEN BIRTH-5 YRS",
  },
  { code: "15758", shortdesc: "FREE FASCIAL FLAP W/MICROVASCULAR ANASTOMOSIS" },
  { code: "99244", shortdesc: "OFFICE CONSULTATION NEW/ESTAB PATIENT 60 MIN" },
  { code: "58825", shortdesc: "TRANSPOSITION OVARY" },
  {
    code: "0195T",
    shortdesc: "ARTHRODESIS PRESACRAL INTRBDY W/O INSTRUM L5/S1",
  },
  { code: "00520", shortdesc: "ANESTHESIA CLOSED CHEST W/BRONCHOSCOPY NOS" },
  { code: "36620", shortdesc: "ARTL CATHJ/CANNULJ MNTR/TRANSFUSION SPX PRQ" },
  { code: "33822", shortdesc: "RPR PATENT DUXUS ARTERIOSUS DIV UNDER 18 YR" },
  {
    code: "21060",
    shortdesc: "MENISCECTOMY PRTL/COMPL TEMPOROMANDIBULAR JT SPX",
  },
  {
    code: "83632",
    shortdesc: "LACTOGEN HPL HUMAN CHORIONIC SOMATOMAMMOTROPIN",
  },
  { code: "87517", shortdesc: "IADNA HEPATITIS B VIRUS QUANTIFICATION" },
  { code: "47535", shortdesc: "CONV EXT BIL DRG CATH TO INT-EXT BIL DRG CATH" },
  { code: "25431", shortdesc: "REPAIR NONUNION CARPAL BONE EACH BONE" },
  { code: "25170", shortdesc: "RADICAL RESECTION TUMOR RADIUS OR ULNA" },
  { code: "0549T", shortdesc: "null" },
  {
    code: "33514",
    shortdesc: "CORONARY ARTERY BYPASS 5 CORONARY VENOUS GRAFTS",
  },
  { code: "99366", shortdesc: "TEAM CONFERENCE FACE-TO-FACE NONPHYSICIAN" },
  { code: "42950", shortdesc: "PHARYNGOPLASTY PLSTC/RCNSTV OPRATION PHARYNX" },
  { code: "88273", shortdesc: "MOLECULAR CYTOGENETICS CHRMOML ISH 10-30 CLL" },
  { code: "86590", shortdesc: "STREPTOKINASE ANTIBODY" },
  { code: "62270", shortdesc: "SPINAL PUNCTURE LUMBAR DIAGNOSTIC" },
  {
    code: "87498",
    shortdesc: "IADNA ENTEROVIRUS AMPLIF PROBE & REVRSE TRNSCRIP",
  },
  { code: "56308", shortdesc: "null" },
  { code: "33305", shortdesc: "REPAIR CARDIAC WOUND W/CARDIOPULMONARY BYPASS" },
  { code: "68505", shortdesc: "EXCISION LACRIMAL GLAND XCPT TUMOR PRTL" },
  {
    code: "36555",
    shortdesc: "INSJ NON-TUNNELED CENTRAL VENOUS CATH AGE < 5 Y",
  },
  {
    code: "81437",
    shortdesc: "HEREDTRY NURONDCRN TUM DSRDRS GEN SEQ ANAL 6 GEN",
  },
  {
    code: "27487",
    shortdesc: "REVJ TOT KNEE ARTHRP FEM&ENTIRE TIBIAL COMPONE",
  },
  {
    code: "00834",
    shortdesc: "ANES HERNIA REPAIR LOWER ABDOMEN NOS & 1YR AGE",
  },
  { code: "21077", shortdesc: "IMPRESSION & PREPARATION ORBITAL PROSTHESIS" },
  { code: "30150", shortdesc: "RHINECTOMY PARTIAL" },
  { code: "31511", shortdesc: "LARYNGOSCOPY INDIRECT W/REMOVAL FOREIGN BODY" },
  { code: "66821", shortdesc: "POST-CATARACT LASER SURGERY" },
  { code: "66680", shortdesc: "REPAIR IRIS CILIARY BODY" },
  { code: "51925", shortdesc: "CLSR VESICOUTERINE FISTULA W/HYSTERECTOMY" },
  { code: "86932", shortdesc: "FROZEN BLOOD EACH UNIT FREEZING & THAWING" },
  {
    code: "00862",
    shortdesc: "ANES XTRPRTL LOWER ABD UR TRACT RENAL DON NFRCT",
  },
  { code: "48150", shortdesc: "PNCRTECT PROX STOT W/PANCREATOJEJUNOSTOMY" },
  {
    code: "21147",
    shortdesc: "RCNSTJ MIDFACE LEFORT I 3/> PIECE W/BONE GRAFTS",
  },
  {
    code: "45388",
    shortdesc: "COLONOSCOPY FLX ABLATION TUMOR POLYP/OTHER LES",
  },
  { code: "28455", shortdesc: "TX TARSAL BONE FX XCP TALUS&CALCN W/MANJ" },
  { code: "86302", shortdesc: "null" },
  { code: "84061", shortdesc: "PHOSPHATASE ACID FORENSIC EXAMINATION" },
  { code: "56309", shortdesc: "null" },
  { code: "86940", shortdesc: "HEMOLYSINS&AGGLUTININS AUTO SCREEN EACH" },
  {
    code: "43101",
    shortdesc: "EXC LESION ESOPHAGUS W/PRIM RPR THRC/ABDL APPR",
  },
  { code: "79100", shortdesc: "null" },
  { code: "40702", shortdesc: "PLSTC RPR CL LIP/NSL DFRM PRIM BI 1 2 STGS" },
  {
    code: "3220F",
    shortdesc: "HEP C QUANT RNA TEST 12 WKS AFTER ANTIVIRAL TX",
  },
  { code: "23140", shortdesc: "EXC/CURTG BONE CYST/BENIGN TUMOR CLAV/SCAPULA" },
  {
    code: "4163F",
    shortdesc: "PT COUNSELING TREATMENT OPTIONS PROSTATE CANCER",
  },
  { code: "99511", shortdesc: "HOME VISIT FECAL IMPACTION MGMT&ENEMA ADMN" },
  {
    code: "84166",
    shortdesc: "PROTEIN ELECTROP FXJ&QUAN OTH FLUS CONCENTRATI",
  },
  { code: "80190", shortdesc: "DRUG SCREEN QUANTITATIVE PROCAINAMIDE" },
  { code: "15738", shortdesc: "MUSC MYOCUTANEOUS/FASCIOCUTANEOUS FLAP LXTR" },
  { code: "51715", shortdesc: "NDSC NJX IMPLT MATRL URT&/BLDR NCK" },
  {
    code: "61020",
    shortdesc: "VENTRICULAR PUNCTURE PREVIOUS BURR HOLE W/O NJX",
  },
  { code: "78216", shortdesc: "LIVER & SPLEEN IMAGING W/VASCULAR FLOW" },
  { code: "37224", shortdesc: "REVSC OPN/PRG FEM/POP W/ANGIOPLASTY UNI" },
  { code: "96154", shortdesc: "HLTH&BEHAVIOR IVNTJ EA 15 MIN FAM W/PT" },
  { code: "81280", shortdesc: "LONG QT SYNDROME FULL SEQUENCE ANALYSIS" },
  { code: "76098", shortdesc: "RADIOLOGICAL EXAMINATION SURGICAL SPECIMEN" },
  { code: "15829", shortdesc: "RHYTIDECTOMY SMAS FLAP" },
  { code: "21685", shortdesc: "HYOID MYOTOMY & SUSPENSION" },
  { code: "11731", shortdesc: "null" },
  {
    code: "42815",
    shortdesc: "EXC BRANCHIAL CLEFT CYST BELOW SUBQ TISS&/PHRYNX",
  },
  {
    code: "97161",
    shortdesc: "PHYSICAL THERAPY EVALUATION LOW COMPLEX 20 MINS",
  },
  { code: "58205", shortdesc: "null" },
  { code: "81209", shortdesc: "BLM GENE ANALYSIS 2281DEL6INS7 VARIANT" },
  { code: "31599", shortdesc: "UNLISTED PROCEDURE LARYNX" },
  { code: "77055", shortdesc: "MAMMOGRAPHY UNILATERAL" },
  {
    code: "25312",
    shortdesc: "TDN TRNSPLJ/TR FLXR/XTNSR F/ARM&/WRST 1/TDN GR",
  },
  {
    code: "81528",
    shortdesc: "ONCOLOGY COLORECTAL SCREENING QUAN 10 DNA MARKRS",
  },
  {
    code: "58960",
    shortdesc: "LAPT STG/RESTG OVARIAN TUBAL/PRIM MAL 2ND LOOK",
  },
  { code: "88037", shortdesc: "NECROPSY LIMITD GROSS&/MCRSCP SINGLE ORGAN" },
  {
    code: "63200",
    shortdesc: "LAMINECTOMY RELEASE TETHERED SPINAL CORD LUMBAR",
  },
  { code: "28630", shortdesc: "CLTX METATARSOPHLNGL JT DISLC W/O ANES" },
  { code: "44970", shortdesc: "LAPAROSCOPIC APPENDECTOMY" },
  { code: "39200", shortdesc: "RESECTION OF MEDIASTINAL CYST" },
  { code: "00948", shortdesc: "ANESTHESIA CERVICAL CERCLAGE INCLUDING BIOPSY" },
  { code: "77046", shortdesc: "null" },
  { code: "11040", shortdesc: "null" },
  {
    code: "57307",
    shortdesc: "CLSR RECTOVAG FSTL ABDL APPR W/CONCOMITANT CLST",
  },
  { code: "28226", shortdesc: "TENOLYSIS EXTENSOR FOOT MULTIPLE TENDON" },
  {
    code: "22101",
    shortdesc: "PRTL EXC PST VRT INTRNSC B1Y LES 1 VRT SGM THRC",
  },
  { code: "83525", shortdesc: "ASSAY OF INSULIN TOTAL" },
  {
    code: "24577",
    shortdesc: "CLTX HUMERAL CONDYLAR FX MEDIAL/LATERAL W/MANJ",
  },
  {
    code: "90824",
    shortdesc: "IND PSYCTX IA INPT/PRTL HOS/RESDNT 20-30 MIN E/M",
  },
  { code: "77318", shortdesc: "BRACHYTX ISODOSE PLN CPLX W/DOSIMETRY CAL" },
  {
    code: "67901",
    shortdesc: "RPR BLEPHAROPTOSIS FRONTALIS MUSC SUTR/OTH MATRL",
  },
  { code: "20661", shortdesc: "APPLICATION HALO CRANIAL INCLUDING REMOVAL" },
  {
    code: "99495",
    shortdesc: "TRANSITIONAL CARE MANAGE SRVC 14 DAY DISCHARGE",
  },
  {
    code: "27615",
    shortdesc: "RAD RESECTION TUMOR SOFT TISSUE LEG/ANKLE <5CM",
  },
  {
    code: "95249",
    shortdesc: "CONT GLUC MONITORING PATIENT PROVIDED EQUIPTMENT",
  },
  {
    code: "21932",
    shortdesc: "EXC TUMOR SOFT TISS BACK/FLANK SUBFASCIAL <5CM",
  },
  { code: "77781", shortdesc: "null" },
  { code: "0146T", shortdesc: "null" },
  {
    code: "43276",
    shortdesc: "ERCP BILIARY/PANC DUCT STENT EXCHANGE W/DIL&WIRE",
  },
  { code: "86789", shortdesc: "ANTIBODY WEST NILE VIRUS" },
  { code: "17266", shortdesc: "DESTRUCTION MAL LESION TRUNK/ARM/LEG > 4.0 CM" },
  { code: "64872", shortdesc: "SUTURE NERVE REQ SECONDARY/DELAYED SUTURE" },
  { code: "95012", shortdesc: "NITRIC OXIDE EXPIRED GAS DETERMINATION" },
  {
    code: "0174T",
    shortdesc: "CAD CHEST RADIOGRAPH CONCURRENT W/INTERPRETATION",
  },
  { code: "85044", shortdesc: "BLOOD COUNT RETICULOCYTE AUTOMATED" },
  { code: "27886", shortdesc: "AMP LEG THRU TIBIA&FIBULA RE-AMPUTATION" },
  { code: "45541", shortdesc: "PROCTOPEXY PERINEAL APPROACH" },
  {
    code: "00214",
    shortdesc: "ANES INTRACRANIAL BURR HOLES W/VENTRICULOGRAPHY",
  },
  {
    code: "81003",
    shortdesc: "URNLS DIP STICK/TABLET RGNT AUTO W/O MICROSCOPY",
  },
  {
    code: "21440",
    shortdesc: "CLTX MANDIBULAR/MAXILLARY ALVEOLAR RIDGE FX SPX",
  },
  {
    code: "3028F",
    shortdesc: "OXYGEN SATURATION RESULTS DOCUMENTED & REVIEWE",
  },
  { code: "99311", shortdesc: "null" },
  { code: "92565", shortdesc: "STENGER TEST PURE TONE" },
  { code: "49185", shortdesc: "SCLEROTHERAPY FLUID COLLECTION PRQ W/IMG GID" },
  { code: "73542", shortdesc: "RAD XM SI JT ARTHG RS&I" },
  { code: "93770", shortdesc: "DERMINATION OF VENOUS PRESSUE" },
  { code: "99263", shortdesc: "null" },
  { code: "4481F", shortdesc: "PT RCVNG ACE/ARB AND BETA BLOCKER < 3 MONTHS" },
  { code: "23490", shortdesc: "PROPH TX W/WO METHYLMETHACRYLATE CLAVICLE" },
  { code: "95934", shortdesc: "H-REFLEX AMPLT&LATENCY GASTRCN/SOLEUS MUSC" },
  {
    code: "81323",
    shortdesc: "PTEN GENE ANALYSIS DUPLICATION/DELETION VARIANT",
  },
  { code: "43287", shortdesc: "ESOPHAGECTOMY DISTAL 2/3 W/LAPAROSCOPIC MOBLJ" },
  { code: "93296", shortdesc: "INTERROGATION REMOTE </90 D TECHNICIAN REVIEW" },
  { code: "87003", shortdesc: "ANIMAL INOCULATION SMALL ANIMAL W/OBS&DSJ" },
  { code: "23077", shortdesc: "RAD RESECTION TUMOR SOFT TISSUE SHOULDER <5CM" },
  { code: "71035", shortdesc: "RADEX CHEST SPECIAL VIEWS" },
  { code: "82742", shortdesc: "FLURAZEPAM" },
  { code: "61450", shortdesc: "CRNEC STPL SCTJ COMPRESSION/DCMPRN GANGLION" },
  { code: "35511", shortdesc: "BYPASS W/VEIN SUBCLAVIAN-SUBCLAVIAN" },
  { code: "83065", shortdesc: "HEMOGLOBIN THERMOLABILE" },
  { code: "82943", shortdesc: "ASSAY OF GLUCAGON" },
  { code: "58578", shortdesc: "UNLISTED LAPAROSCOPY PROCEDURE UTERUS" },
  { code: "93875", shortdesc: "N-INVAS PHYSIOLOGIC STD XTRC ART COMPL BI STD" },
  { code: "43312", shortdesc: "ESPHGP THRC APPR W/RPR TRACHEOESOPHGL FSTL" },
  { code: "68360", shortdesc: "CONJUNCTIVAL FLAP BRIDGE/PARTIAL SPX" },
  {
    code: "27397",
    shortdesc: "TRANSPLANT/TRANSFER THIGH XTNSR TO FLXR MULT TDN",
  },
  {
    code: "24116",
    shortdesc: "EXC/CURTG BONE CYST/BENIGN TUM HUMERUS W/ALGRFT",
  },
  { code: "37565", shortdesc: "LIGATION INTERNAL JUGULAR VEIN" },
  { code: "82725", shortdesc: "FATTY ACIDS NONESTERIFIED" },
  {
    code: "3776F",
    shortdesc: "ADENOMA(S)/NEOPLASM NOT DETECTED SCRNG CLNSCPY",
  },
  { code: "92579", shortdesc: "VISUAL REINFORCEMENT AUDIOMETRY" },
  { code: "11005", shortdesc: "DBRDMT SKN SUBQ T/M/F NECRO INFCTJ ABDL WALL" },
  { code: "10010", shortdesc: "null" },
  {
    code: "88184",
    shortdesc: "FLOW CYTOMETRY CELL SURF MARKER TECHL ONLY 1ST",
  },
  {
    code: "37247",
    shortdesc: "TRLML BALO ANGIOP OPEN/PERQ IMG S&I EA ADDL ART",
  },
  { code: "57230", shortdesc: "PLASTIC REPAIR URETHROCELE" },
  { code: "41008", shortdesc: "INTRAORAL I&D TONGUE/FLOOR SUBMNDBLR SPACE" },
  { code: "67999", shortdesc: "UNLISTED PROCEDURE EYELIDS" },
  {
    code: "11444",
    shortdesc: "EXC B9 LES MRGN XCP SK TG F/E/E/N/L/M 3.1-4.0CM",
  },
  { code: "28635", shortdesc: "CLTX METATARSOPHLNGL JT DISLC REQ ANES" },
  { code: "27122", shortdesc: "ACETABULOPLASTY RESECTION FEMORAL HEAD" },
  {
    code: "01215",
    shortdesc: "ANESTHESIA OPEN REVISION TOTAL HIP ARTHROPLASTY",
  },
  { code: "4328F", shortdesc: "PT/CAREGIVER QUERIED SLEEP DISTURBANCES" },
  { code: "85031", shortdesc: "null" },
  { code: "26776", shortdesc: "PRQ SKEL FIXJ IPHAL JT DISLC W/MANJ" },
  {
    code: "0450T",
    shortdesc: "INSJ AQUEOUS DRAIN DEV W/O EO RSVR EACH ADDL DEV",
  },
  { code: "28540", shortdesc: "CLTX TARSAL DISLC OTH/THN TALOTARSAL W/O ANES" },
  {
    code: "81595",
    shortdesc: "CARDIOLOGY HRT TRNSPL MRNA GENE EXPRESS 20 GENES",
  },
  { code: "82485", shortdesc: "CHONDROITIN B SULFATE QUANTITATIVE" },
  { code: "65400", shortdesc: "EXCISION LESION CORNEA XCP PTERYGIUM" },
  {
    code: "27502",
    shortdesc: "CLTX FEM SHFT FX W/MANJ W/WO SKIN/SKELETAL TRACJ",
  },
  {
    code: "87632",
    shortdesc: "IADNA RESPIRATRY PROBE & REV TRNSCR 6-11 TARGETS",
  },
  { code: "59074", shortdesc: "FETAL FLUID DRAINAGE W/ULTRASOUND GUIDANCE" },
  {
    code: "33220",
    shortdesc: "RPR 2 TRANSVNS ELECTRODES PRM PM/IMPLANTABLE DFB",
  },
  { code: "54645", shortdesc: "null" },
  { code: "92598", shortdesc: "null" },
  { code: "75982", shortdesc: "PRQ PLMT INT/EXT BILIARY DRNG CATH/STENT RS&I" },
  { code: "34709", shortdesc: "PLACEMENT XTN PROSTH FOR ENDOVASCULAR RPR" },
  { code: "72156", shortdesc: "MRI SPINAL CANAL CERVICAL W/O & W/CONTR MATRL" },
  { code: "99435", shortdesc: "null" },
  {
    code: "15950",
    shortdesc: "EXC TROCHANTERIC PRESSURE ULCER W/PRIMARY SUTR",
  },
  {
    code: "95903",
    shortdesc: "NRV CNDJ AMPLT&LATENCY EA NRV MOTOR W/F-WAVE STD",
  },
  { code: "00604", shortdesc: "ANES CERVICAL SPINE & CORD W/PATIENT SITTING" },
  { code: "56440", shortdesc: "MARSUPIALIZATION BARTHOLINS GLAND CYST" },
  {
    code: "11312",
    shortdesc: "SHVG SKIN LESION 1 F/E/E/N/L/M DIAM 1.1-2.0 CM",
  },
  {
    code: "0383T",
    shortdesc: "XTRNL HRT RATE EPI SEIZ 15 TO 30 DAYS COMPLETE",
  },
  { code: "4065F", shortdesc: "ANTIPSYCHOTIC PHARMACOTHERAPY PRESCRIBED" },
  { code: "87798", shortdesc: "IADNA NOS AMPLIFIED PROBE TQ EACH ORGANISM" },
  {
    code: "63020",
    shortdesc: "LAMNOTMY INCL W/DCMPRSN NRV ROOT 1 INTRSPC CERVC",
  },
  {
    code: "72114",
    shortdesc: "RADEX SPINE LUMBSCRL COMPL W/BENDING VIEWS MIN 6",
  },
  { code: "43200", shortdesc: "ESOPHAGOSCOPY FLEXIBLE TRANSORAL DIAGNOSTIC" },
  { code: "81402", shortdesc: "MOLECULAR PATHOLOGY PROCEDURE LEVEL 3" },
  { code: "19330", shortdesc: "REMOVAL MAMMARY IMPLANT MATERIAL" },
  {
    code: "33647",
    shortdesc: "RPR ATRIAL & VENTRIC SEPTAL DFCT DIR/PATCH CLS",
  },
  { code: "21339", shortdesc: "OPEN TX NASOETHMOID FX W/EXTERNAL FIXATION" },
  { code: "47560", shortdesc: "LAPS SURG W/GID TRANSHEPATC CHOLANGRPH W/O BX" },
  { code: "80340", shortdesc: "ANTIEPILEPTICS NOT OTHERWISE SPECIFIED 4-6" },
  { code: "70549", shortdesc: "MRA NECK W/O &W/CONTRAST MATERIAL" },
  { code: "0547T", shortdesc: "null" },
  { code: "36475", shortdesc: "ENDOVEN ABLTJ INCMPTNT VEIN XTR RF 1ST VEIN" },
  { code: "67904", shortdesc: "RPR BLEPHAROPTOSIS LEVATOR RESCJ/ADVMNT XTRNL" },
  { code: "21408", shortdesc: "OPEN TX FX ORBIT EXCEPT BLOWOUT W/BONE GRAFT" },
  { code: "38794", shortdesc: "CANNULATION THORACIC DUCT" },
  { code: "23920", shortdesc: "DISARTICULATION SHOULDER" },
  {
    code: "99053",
    shortdesc: "SERVICES PROVIDED BTW 10 PM&8 AM AT 24-HR FACI",
  },
  { code: "29848", shortdesc: "NDSC WRST SURG W/RLS TRANSVRS CARPL LIGM" },
  { code: "54344", shortdesc: "RPR HYPOSPADIAS COMPLCTJS MOBLJ FLAPS & URTP" },
  { code: "23900", shortdesc: "INTERTHORACOSCAPULAR AMPUTATION" },
  {
    code: "21750",
    shortdesc: "CLOSE MEDIAN STERNOTOMY SEP W/WO DEBRIDEMENT SPX",
  },
  { code: "58986", shortdesc: "null" },
  {
    code: "0480T",
    shortdesc: "FRACTIONAL ABL LSR FENESTRATION EA ADDL 100 SQCM",
  },
  { code: "41130", shortdesc: "GLOSSECTOMY HEMIGLOSSECTOMY" },
  {
    code: "17272",
    shortdesc: "DESTRUCTION MALIGNANT LESION S/N/H/F/G 1.1-2.0CM",
  },
  { code: "92572", shortdesc: "STAGGERED SPONDAIC WORD" },
  { code: "46604", shortdesc: "ANOSCOPY W/DILATION" },
  {
    code: "86325",
    shortdesc: "IMMUNOELECTROPHORESIS OTHER FLUIDS CONCENTRATION",
  },
  { code: "96366", shortdesc: "IV INFUSION THERAPY PROPHYLAXIS/DX EA HOUR" },
  { code: "93657", shortdesc: "ABLATE L/R ATRIAL FIBRIL W/ISOLATED PULM VEIN" },
  {
    code: "13153",
    shortdesc: "REPAIR COMPLX EYELID/NOSE/EAR/LIP EA ADDL 5 CM/<",
  },
  { code: "73090", shortdesc: "RADEX FOREARM 2 VIEWS" },
  {
    code: "74241",
    shortdesc: "RADEX GI TRACT UPPER W/WO DELAYED IMAGES W/KUB",
  },
  { code: "58984", shortdesc: "null" },
  { code: "88014", shortdesc: "NECROPSY GROSS EXAM STILLBORN/NEWBORN W/BRAIN" },
  { code: "81371", shortdesc: "HLA I&LI LOW RESOLUTION HLA-A -B&-DRB1" },
  {
    code: "4261F",
    shortdesc: "TECH OTHER THAN SURFACE CULTURE WOUND EXUD USED",
  },
  { code: "43500", shortdesc: "GASTROTOMY W/EXPLORATION/FOREIGN BODY REMOVAL" },
  {
    code: "95852",
    shortdesc: "ROM MEAS&REPRT HAND W/WO COMPARISON NORMAL SID",
  },
  { code: "55725", shortdesc: "PROSTATOTOMY EXTERNAL DRG ABSCESS COMPLICATED" },
  { code: "90857", shortdesc: "INTERACTIVE GROUP PSYCHOTHERAPY" },
  { code: "19030", shortdesc: "INJECTION MAMMARY DUCTOGRAM/GALACTOGRAM" },
  { code: "65426", shortdesc: "EXCISION/TRANSPOSITION PTERYGIUM W/GRAFG" },
  { code: "32445", shortdesc: "REMOVAL LUNG PNEUMONECTOMY EXTRAPLEURAL" },
  { code: "00924", shortdesc: "ANES UNDSCND TESTIS UNI/BI INCL OPEN URTL PX" },
  { code: "96574", shortdesc: "DEBRIDEMENT PRMLG HYPERKERATOTIC LES W/PDT" },
  { code: "43800", shortdesc: "PYLOROPLASTY" },
  { code: "27340", shortdesc: "EXCISION PREPATELLAR BURSA" },
  { code: "45000", shortdesc: "TRANSRECTAL DRAINAGE OF PELVIC ABSCESS" },
  {
    code: "95886",
    shortdesc: "NEEDLE EMG EA EXTREMTY W/PARASPINL AREA COMPLETE",
  },
  { code: "11770", shortdesc: "EXCISION PILONIDAL CYST/SINUS SIMPLE" },
  { code: "67340", shortdesc: "STRABISMUS EXPL&/RPR DETACHED EXTROCULAR MUSC" },
  { code: "87083", shortdesc: "null" },
  { code: "92542", shortdesc: "POSITIONAL NYSTAGMUS TEST" },
  { code: "56305", shortdesc: "null" },
  { code: "35884", shortdesc: "REVISION FEMORAL ANAST OPEN W/AUTOG GRAFT" },
  { code: "33853", shortdesc: "RPR HYPOPLSTC A-ARCH W/AGRFT/PROSTC W/BYPASS" },
  { code: "30160", shortdesc: "RHINECTOMY TOTAL" },
  { code: "93734", shortdesc: "null" },
  { code: "95922", shortdesc: "TSTG ANS FUNCJ VASOMOTOR ADRENERGIC INNERVAJ" },
  { code: "36513", shortdesc: "THERAPEUTIC APHERESIS PLATELETS" },
  {
    code: "85060",
    shortdesc: "BLOOD SMEAR PERIPHERAL INTERP PHYS W/WRIT REPORT",
  },
  { code: "49521", shortdesc: "RPR RECRT INGUN HERNIA ANY AGE INCARCERATED" },
  {
    code: "92588",
    shortdesc: "DISTRT PROD EVOKD OTOACOUSTIC EMSNS COMP/DX EVAL",
  },
  { code: "52330", shortdesc: "CYSTO MANJ W/O RMVL URETERAL STONE" },
  { code: "87516", shortdesc: "IADNA HEPATITIS B VIRUS AMPLIFIED PROBE TQ" },
  {
    code: "58570",
    shortdesc: "LAPAROSCOPY W TOTAL HYSTERECTOMY UTERUS 250 GM/<",
  },
  { code: "0132U", shortdesc: "null" },
  { code: "17201", shortdesc: "null" },
  { code: "63661", shortdesc: "RMVL SPINAL NSTIM ELTRD PRQ ARRAY INCL FLUOR" },
  { code: "62148", shortdesc: "INCISE&RETRIEVAL SUBQ CRANIOPLASTY BONE GRAFT" },
  { code: "31750", shortdesc: "TRACHEOPLASTY CERVICAL" },
  { code: "96445", shortdesc: "null" },
  { code: "4177F", shortdesc: "COUNSEL BENEF/RISK AREDS PREV AGE RELATED AMD" },
  {
    code: "87521",
    shortdesc: "IADNA HEPATITIS C AMPLIFIED PROBE&REVRSE TRANSCR",
  },
  { code: "80354", shortdesc: "DRUG SCREENING FENTANYL" },
  {
    code: "58552",
    shortdesc: "LAPS W/VAG HYSTERECT 250 GM/&RMVL TUBE&/OVARIES",
  },
  { code: "28490", shortdesc: "CLTX FX GRT TOE PHLX/PHLG W/O MANJ" },
  { code: "58942", shortdesc: "null" },
  { code: "81344", shortdesc: "null" },
  { code: "23515", shortdesc: "OPEN TX CLAVICULAR FRACTURE INTERNAL FIXATION" },
  { code: "90835", shortdesc: "null" },
  {
    code: "26727",
    shortdesc: "PRQ SKEL FIXJ PHLNGL SHFT FX PROX/MIDDLE PX/F/T",
  },
  { code: "4100F", shortdesc: "BISPHOS THXPY VENOUS ORDERED OR RECEIVED" },
  { code: "25430", shortdesc: "INSERTION VASCULAR PEDICLE CARPAL BONE" },
  { code: "65220", shortdesc: "RMVL FB XTRNL EYE CORNEAL W/O SLIT LAMP" },
  {
    code: "73523",
    shortdesc: "RADEX HIPS BILATERAL WITH PELVIS MINIMUM 5 VIEWS",
  },
  {
    code: "67121",
    shortdesc: "RMVL IMPLT MATRL POSTERIOR SEGMENT INTRAOCULAR",
  },
  { code: "43313", shortdesc: "ESPHGP CGEN DFCT THRC APPR W/O RPR FSTL" },
  { code: "22590", shortdesc: "ARTHRODESIS POSTERIOR CRANIOCERVICAL" },
  {
    code: "27185",
    shortdesc: "EPIPHYSL ARRST EPIPHYSIOD/STAPLING TRCHNTR FEMUR",
  },
  { code: "0517T", shortdesc: "null" },
  { code: "45111", shortdesc: "PRCTECT PRTL RESCJ RECTUM TABDL APPR" },
  {
    code: "37231",
    shortdesc: "REVSC OPN/PRQ TIB/PERO W/STNT/ATHR/ANGIOP SM VSL",
  },
  {
    code: "27558",
    shortdesc: "OPEN TX KNEE DISLOCATION W/REPAIR/RECONSTRUCTION",
  },
  {
    code: "31527",
    shortdesc: "LARYNGOSCOPY W/WO TRACHEOSCOPY INSERT OBTURATOR",
  },
  {
    code: "93745",
    shortdesc: "1ST SET-UP & PRGRMG PHYS/QHP OF WEARABLE CVDFB",
  },
  { code: "38100", shortdesc: "SPLENECTOMY TOTAL SEPARATE PROCEDURE" },
  { code: "27497", shortdesc: "DCMPRN FASCT THIGH&/KNEE DBRDMT MUSCLE&/NERVE" },
  {
    code: "01442",
    shortdesc: "ANES ART KNEE POPLITEAL TEAEC W/WO PATCH GRAFT",
  },
  { code: "27592", shortdesc: "AMPUTATION THIGH THRU FEMUR OPEN CIRCULAR" },
  { code: "85270", shortdesc: "CLOTTING FACTOR XI PTA" },
  { code: "82016", shortdesc: "ACYLCARNITINES QUALITATIVE EACH SPECIMEN" },
  { code: "78282", shortdesc: "GASTROINTESTINAL PROTEIN LOSS" },
  {
    code: "43774",
    shortdesc: "LAPS GASTRIC RESTRICTIVE PX REMOVE DEVICE & PORT",
  },
  {
    code: "33463",
    shortdesc: "VALVULOPLASTY TRICUSPID VALVE W/O RING INSERTION",
  },
  {
    code: "92521",
    shortdesc: "EVALUATION OF SPEECH FLUENCY (STUTTER CLUTTER)",
  },
  { code: "73020", shortdesc: "RADEX SHOULDER 1 VIEW" },
  { code: "66820", shortdesc: "DISCISSION SECONDARY MEMBRANOUS CATARACT" },
  {
    code: "26554",
    shortdesc: "TR TOE-TO-HAND W/MVASC ANAST OTH/THN GRT TOE 2",
  },
  {
    code: "76700",
    shortdesc: "US ABDOMINAL REAL TIME W/IMAGE DOCUMENTATION, COMPLETE",
  },
  { code: "1121F", shortdesc: "SUBSEQUENT EVALUATION CONDITION" },
  { code: "90376", shortdesc: "RABIES IG HEAT-TREATED HUMAN IM/SUBQ" },
  {
    code: "21123",
    shortdesc: "GENIOP SLIDING AGMNTJ W/INTERPOSAL BONE GRAFTS",
  },
  { code: "55870", shortdesc: "ELECTROEJACULATION" },
  { code: "90783", shortdesc: "null" },
  {
    code: "27538",
    shortdesc: "CLTX INTERCONDYLAR SPI&/TUBRST FX KNE W/WO MAN",
  },
  {
    code: "78597",
    shortdesc: "QUANT DIFFERENTIAL PULM PERFUSION W/WO IMAGING",
  },
  { code: "36514", shortdesc: "THERAPEUTIC APHERESIS PLASMA PHERESIS" },
  {
    code: "26862",
    shortdesc: "ARTHRODESIS IPHAL JT W/WO INT FIXJ W/AUTOGRAFT",
  },
  { code: "19272", shortdesc: "EXC CHEST TUMOR W/RCNSTJ W/MEDSTNL LMPHADEC" },
  { code: "17380", shortdesc: "ELECTROLYSIS EPILATION EACH 30 MINUTES" },
  { code: "90747", shortdesc: "HEPB VACCINE DIALYSIS/IMMUNSUP PAT 4 DOSE IM" },
  { code: "35005", shortdesc: "DIR RPR ANEURYSM VERTEBRAL ARTERY" },
  { code: "27420", shortdesc: "RCNSTJ DISLOCATING PATELLA" },
  {
    code: "64633",
    shortdesc: "DSTR NROLYTC AGNT PARVERTEB FCT SNGL CRVCL/THORA",
  },
  {
    code: "01634",
    shortdesc: "ANESTHESIA ARTHROSCOPIC SHOULDER DISARTICULATION",
  },
  { code: "80183", shortdesc: "DRUG SCREEN QUANTITATIVE OXCARBAZEPINE" },
  { code: "28605", shortdesc: "CLOSED TX TARSOMETATARSAL DISLOCATION W/ANES" },
  {
    code: "59001",
    shortdesc: "AMNIOCENTESIS THER AMNIOTIC FLUID RDCTJ US GUID",
  },
  { code: "89100", shortdesc: "null" },
  { code: "67345", shortdesc: "CHEMODENERVATION EXTRAOCULAR MUSCLE" },
  { code: "23220", shortdesc: "RADICAL RESECTION BONE TUMOR PROXIMAL HUMERUS" },
  { code: "49325", shortdesc: "LAPS W/REVISION INTRAPERITONEAL CATHETER" },
  { code: "43240", shortdesc: "EGD TRANSORAL TRANSMURAL DRAINAGE PSEUDOCYST" },
  { code: "54565", shortdesc: "null" },
  { code: "4181F", shortdesc: "CONFORMAL RADIATION THERAPY RECEIVED" },
  { code: "0324T", shortdesc: "null" },
  { code: "93224", shortdesc: "XTRNL ECG & 48 HR RECORD SCAN STOR W/R&I" },
  { code: "3318F", shortdesc: "PATH REPRT MALIGNANCY DOCD & RVWD INITIA RAD" },
  { code: "49570", shortdesc: "RPR EPIGASTRIC HERNIA REDUCIBLE SPX" },
  { code: "67312", shortdesc: "STRABISMUS RECESSION/RESCJ 2 HRZNTL MUSC" },
  { code: "28116", shortdesc: "OSTECTOMY TARSAL COALITION" },
  { code: "28060", shortdesc: "FASCIECTOMY PLANTAR FASCIA PARTIAL SPX" },
  { code: "97500", shortdesc: "null" },
  { code: "37609", shortdesc: "LIGATION/BIOPSY TEMPORAL ARTERY" },
  { code: "32900", shortdesc: "RESECTION RIBS EXTRAPLEURAL ALL STAGES" },
  {
    code: "22527",
    shortdesc: "PERQ INTRDSCL ELECTROTHRM ANNULOPLASTY ADDL LVL",
  },
  { code: "81252", shortdesc: "GJB2 GENE ANALYSIS FULL GENE SEQUENCE" },
  { code: "98942", shortdesc: "CHIROPRACTIC MANIPULATIVE TX SPINAL 5 REGIONS" },
  { code: "92617", shortdesc: "FLEXIBLE NDSC EVAL SWLNG&LARYN SENS C/V I&R" },
  {
    code: "52001",
    shortdesc: "CYSTO W/IRRIG & EVAC MULTPLE OBSTRUCTING CLOTS",
  },
  {
    code: "74263",
    shortdesc: "CT COLONOGRAPHY SCREENING IMAGE POSTPROCESSING",
  },
  {
    code: "33363",
    shortdesc: "REPLACE AORTIC VALVE OPEN AXILLRY ARTRY APPROACH",
  },
  { code: "91022", shortdesc: "DUODENAL MOTILITY MANOMETRIC STUDY" },
  { code: "86406", shortdesc: "PARTICLE AGGLUTINATION TITER EACH ANTIBODY" },
  { code: "95912", shortdesc: "NERVE CONDUCTION STUDIES 11-12 STUDIES" },
  {
    code: "87541",
    shortdesc: "IADNA LEGIONELLA PNEUMOPHILA AMPLIFIED PROBE TQ",
  },
  { code: "0046U", shortdesc: "null" },
  { code: "66172", shortdesc: "FSTLJ SCLERA GLC TRBEC AB EXTERNO SCARRING" },
  { code: "0021U", shortdesc: "ONC PRST8 DETCJ 8 AUTOANTIBODIES ALG RSK SCOR" },
  {
    code: "31291",
    shortdesc: "NASAL/SINUS NDSC RPR CEREBSP FLUID LEAK SPHENOID",
  },
  {
    code: "78804",
    shortdesc: "RP LOCLZJ TUMOR/DSTRBJ AGT WHOL BDY REQ 2/> DAY",
  },
  { code: "80330", shortdesc: "DRUG SCREEN ANALGESICS NON-OPIOID 3-5" },
  {
    code: "27758",
    shortdesc: "OPTX TIBIAL SHFT FX W/PLATE/SCREWS W/WO CERCLAGE",
  },
  {
    code: "3323F",
    shortdesc: "CLIN TUMOR NODE METASTASES STAGE DOCD PRIOR SURG",
  },
  { code: "42310", shortdesc: "DRG ABSC SUBMAXILLARY/SUBLINGUAL INTRAORAL" },
  { code: "32420", shortdesc: "PNEUMOCENTESIS PUNCTURE LUNG ASPIRATION" },
  {
    code: "33230",
    shortdesc: "INSJ IMPLNTBL DEFIB PULSE GEN W/EXIST DUAL LEADS",
  },
  { code: "21240", shortdesc: "ARTHRP TEMPOROMANDIBULAR JOINT W/WO AUTOGRAFT" },
  {
    code: "21400",
    shortdesc: "CLSD TX FX ORBIT EXCEPT BLOWOUT W/O MANIPULATION",
  },
  { code: "44135", shortdesc: "INTESTINAL ALLOTRANSPLANTATION CADAVER DONOR" },
  { code: "78454", shortdesc: "MYOCARDIAL PERFUSION PLANAR MULTIPLE STUDIES" },
  { code: "50600", shortdesc: "URTROTOMY W/EXPL/DRG SEPARATE PROCEDURE" },
  {
    code: "85390",
    shortdesc: "FIBRINOLYSINS/COAGULOPATHY SCREEN INTERP&REPOR",
  },
  { code: "55810", shortdesc: "PROSTATECTOMY PERINEAL RADICAL" },
  { code: "00147", shortdesc: "ANESTHESIA EYE IRIDECTOMY" },
  { code: "34830", shortdesc: "OPN RPR ARYSM RPR ARTL TRAUMA TUBE PROSTH" },
  { code: "28322", shortdesc: "RPR NON/MALUNION METARSAL W/WO BONE GRAFT" },
  { code: "53852", shortdesc: "TRURL DSTRJ PRSTATE TISS RF THERMOTH" },
  { code: "21087", shortdesc: "IMPRESSION & PREPARATION NASAL PROSTHESIS" },
  { code: "12034", shortdesc: "REPAIR INTERMEDIATE S/A/T/E 7.6-12.5 CM" },
  { code: "81205", shortdesc: "BCKDHB GENE ANALYSIS COMMON VARIANTS" },
  { code: "85525", shortdesc: "HEPARIN NEUTRALIZATION" },
  { code: "81403", shortdesc: "MOLECULAR PATHOLOGY PROCEDURE LEVEL 4" },
  {
    code: "93355",
    shortdesc: "ECHO TEE GUID TCAT ICAR/VESSEL STRUCTURAL INTVN",
  },
  { code: "53270", shortdesc: "EXCISION OR FULGURATION SKENES GLANDS" },
  {
    code: "0398T",
    shortdesc: "MRGFUS STEREOTACTIC ABLATION LESION INTRACRANIAL",
  },
  {
    code: "61798",
    shortdesc: "STEREOTACTIC RADIOSURGERY 1 COMPLEX CRANIAL LES",
  },
  { code: "36470", shortdesc: "INJECTION SCLEROSANT SINGLE INCMPTNT VEIN" },
  { code: "82491", shortdesc: "CHROMATOGRAPHY QUAN COLUMN 1 ANALYTE NES" },
  {
    code: "74262",
    shortdesc: "CT COLONOGRPHY DX IMAGE POSTPROCESS W/CONTRAST",
  },
  { code: "4035F", shortdesc: "INFLUENZA IMMUNIZATION RECOMMENDED" },
  { code: "33611", shortdesc: "RPR 2 OUTLET R VNTRC W/INTRAVENTR TUNNEL RPR" },
  { code: "82785", shortdesc: "ASSAY OF GAMMAGLOBULIN IGE" },
  { code: "19380", shortdesc: "REVISION RECONSTRUCTED BREAST" },
  {
    code: "92132",
    shortdesc: "CMPTR OPHTHALMIC DX IMG ANT SEGMT W/I&R UNI/BI",
  },
  { code: "86625", shortdesc: "ANTIBODY CAMPYLOBACTER" },
  { code: "78013", shortdesc: "THYROID IMAGING WITH VASCULAR FLOW" },
  { code: "54152", shortdesc: "null" },
  { code: "26535", shortdesc: "ARTHROPLASTY INTERPHALANGEAL JOINT EACH" },
  {
    code: "95018",
    shortdesc: "ALLG TEST PERQ & IC DRUG/BIOL IMMED REACT W/I&R",
  },
  {
    code: "15421",
    shortdesc: "XENOGRAFT TEMP CLOSURE F/S/N/H/F/G/M/D EA 100CM",
  },
  {
    code: "95145",
    shortdesc: "PREPJ& ANTIGEN ALLERGEN IMMUNOTHERAPY 1 INSECT",
  },
  { code: "36576", shortdesc: "RPR CTR VAD W/SUBQ PORT/PMP CTR/PRPH INSJ SIT" },
  {
    code: "35626",
    shortdesc: "BYPASS NOT VEIN AORTOSUBCLA/CAROTID/INNOMINATE",
  },
  { code: "24685", shortdesc: "OPEN TREATMENT ULNAR FRACTURE PROXIMAL END" },
  { code: "20526", shortdesc: "INJECTION THERAPEUTIC CARPAL TUNNEL" },
  { code: "52010", shortdesc: "CYSTO W/EJACULATORY DUCT CATHETERIZATION" },
  { code: "0112U", shortdesc: "null" },
  { code: "0535T", shortdesc: "null" },
  { code: "74480", shortdesc: "INTRO URETERAL CATH/STENT PRQ RS&I" },
  { code: "0187T", shortdesc: "null" },
  { code: "93613", shortdesc: "INTRACARDIAC ELECTROPHYSIOLOGIC 3D MAPPING" },
  { code: "85025", shortdesc: "BLOOD COUNT COMPLETE AUTO&AUTO DIFRNTL WBC" },
  { code: "0516T", shortdesc: "null" },
  {
    code: "93893",
    shortdesc: "TRANSCRAN DOPPLER INTRACRAN ART MICROBUBBLE INJ",
  },
  { code: "01160", shortdesc: "ANES CLOSED SYMPHYSIS PUBIS/SACROILIAC JOINT" },
  { code: "81202", shortdesc: "APC GENE ANALYSIS KNOWN FAMILIAL VARIANTS" },
  { code: "50722", shortdesc: "URETEROLYSIS FOR OVARIAN VEIN SYNDROME" },
  { code: "81302", shortdesc: "MECP2 GENE ANALYSIS FULL SEQUENCE" },
  {
    code: "25574",
    shortdesc: "OPEN TX RADIAL&ULNAR SHAFT FX W/FIXJ RADIUS/ULNA",
  },
  { code: "36822", shortdesc: "INSJ CANNULA PROLNG XC-CIRCJ ECMO SPX" },
  {
    code: "33364",
    shortdesc: "REPLACE AORTIC VALVE OPEN ILIAC ARTERY APPROACH",
  },
  { code: "1012F", shortdesc: "ANGINA ABSENT" },
  { code: "51990", shortdesc: "LAPAROSCOPY URETHRAL SUSPENSION STRESS INCONT" },
  { code: "4400F", shortdesc: "REHAB THERAPY OPTIONS DISCUSSED W/PATIENT" },
  { code: "0025U", shortdesc: "null" },
  {
    code: "92973",
    shortdesc: "PRQ TRANSLUMINAL CORONARY MECHANICL THROMBECTOMY",
  },
  { code: "81179", shortdesc: "null" },
  {
    code: "96902",
    shortdesc: "MCRSCP XM HAIR PLUCK/CLIP FOR CNTS/STRUCT ABNORM",
  },
  { code: "81346", shortdesc: "TYMS GENE ANALYSIS COMMON VARIANTS" },
  {
    code: "81000",
    shortdesc: "URINLS DIP STICK/TABLET REAGNT NON-AUTO MICRSCPY",
  },
  {
    code: "27257",
    shortdesc: "TX SPON HIP DISLC ABDCT SPLNT/TRCJ W/MANJ ANES",
  },
  {
    code: "97033",
    shortdesc: "APPL MODALITY 1/> AREAS IONTOPHORESIS EA 15 MIN",
  },
  {
    code: "95990",
    shortdesc: "REFILL&MAINTENANCE PUMP DRUG DLVR SPINAL/BRAIN",
  },
  { code: "83090", shortdesc: "ASSAY OF HOMOCYSTEINE" },
  { code: "58120", shortdesc: "DILATION & CURETTAGE DX&/THER NONOBSTETRIC" },
  { code: "53601", shortdesc: "DILAT URETHRAL STRIX DILATOR MALE SBSQ" },
  { code: "53215", shortdesc: "URETHRECTOMY TOT W/CYSTOST MALE" },
  { code: "43832", shortdesc: "GASTROSTOMY OPN W/CONSTJ GSTR TUBE" },
  { code: "42426", shortdesc: "EXC PRTD TUM/PRTD GLND TOT W/UNI RAD NCK DSJ" },
  { code: "90821", shortdesc: "INDIV PSYCTX INPT/PRTL HOSP/RESDNTL 75-80 MIN" },
  {
    code: "62115",
    shortdesc: "RDCTJ CRANIOMEGALIC SKULL W/O GRAFT/CRANIOPLASTY",
  },
  {
    code: "0431T",
    shortdesc: "RMVL/RPLC NSTIM SYSTEM SLEEP APNEA PLS GENERATOR",
  },
  { code: "82013", shortdesc: "ASSAY OF ACETYLCHOLINESTERASE" },
  {
    code: "44211",
    shortdesc: "LAPS COLCT TTL ABD W/PRCTECT ILEOANAL ANASTOMSIS",
  },
  { code: "31002", shortdesc: "LAVAGE CANNULATION SPHENOID SINUS" },
  { code: "46922", shortdesc: "DSTRJ LESION ANUS SIMPLE SURG EXCISION" },
  { code: "4132F", shortdesc: "SYSTEMIC ANTIMICROBIAL TX NOT PRESCRIBED" },
  { code: "0339T", shortdesc: "TRANSCATHETER RENAL SYMPATH DENERVATION BILAT" },
  { code: "56620", shortdesc: "VULVECTOMY SIMPLE PARTIAL" },
  {
    code: "0414T",
    shortdesc: "RMVL & RPL CARDIAC MODULJ SYS PLS GENERATOR ONLY",
  },
  { code: "99367", shortdesc: "TEAM CONFERENCE NON-FACE-TO-FACE PHYSICIAN" },
  { code: "42820", shortdesc: "TONSILLECTOMY & ADENOIDECTOMY <AGE 12" },
  { code: "51880", shortdesc: "CLOSURE CYSTOSTOMY SEPARATE PROCEDURE" },
  { code: "95857", shortdesc: "CHOLINESTERASE INHIBITOR CHALLENGE TEST" },
  { code: "82435", shortdesc: "CHLORIDE BLD" },
  {
    code: "67030",
    shortdesc: "DISCISSION VITREOUS STRANS PARS PLANA APPROACH",
  },
  {
    code: "0466T",
    shortdesc: "INSRT CH WALL RESPIR ELTRD/RA & CONJ PULSE GEN",
  },
  {
    code: "44110",
    shortdesc: "EXC 1/> SMALL/LARGE LESIONS INTESTINE ENTEROTOM",
  },
  { code: "99273", shortdesc: "null" },
  { code: "44180", shortdesc: "LAPAROSCOPY ENTEROLYSIS SEPARATE PROCEDURE" },
  { code: "28030", shortdesc: "null" },
  { code: "5005F", shortdesc: "COUNSEL NEW/CHANGING MOLES SELF-EXAMINATION" },
  {
    code: "0312T",
    shortdesc: "LAPS IMPLTJ NSTIM ELTRD ARRAY&PLS GEN VAGUS NRV",
  },
  { code: "58760", shortdesc: "FIMBRIOPLASTY" },
  { code: "80195", shortdesc: "DRUG SCREEN QUANTITATIVE SIROLIMUS" },
  { code: "24900", shortdesc: "AMPUTATION ARM THRU HUMERUS W/PRIMARY CLOSURE" },
  {
    code: "3027F",
    shortdesc: "SPIROMETRY TEST RESULTS FEV/FVC >/=70% W/O COPD",
  },
  { code: "99213", shortdesc: "OFFICE OUTPATIENT VISIT 15 MINUTES" },
  { code: "35840", shortdesc: "EXPL PO HEMRRG THROMBOSIS/INFCTJ ABD" },
  {
    code: "27405",
    shortdesc: "RPR PRIMARY TORN LIGM&/CAPSULE KNEE COLLATERAL",
  },
  { code: "35482", shortdesc: "null" },
  { code: "86708", shortdesc: "HEPATITIS A ANTIBODY HAAB" },
  {
    code: "0423T",
    shortdesc: "SECRETORY TYPE II PHOSPHOLIPASE A2 (SPLA2-IIA)",
  },
  { code: "30465", shortdesc: "REPAIR NASAL VESTIBULAR STENOSIS" },
  { code: "67331", shortdesc: "STRABISMUS PREVIOUS EYE X INVOLVE EO MUSC" },
  { code: "81187", shortdesc: "null" },
  {
    code: "26670",
    shortdesc: "CLTX CARPO/METACARPL DISLC THMB MANJ EA W/O ANES",
  },
  {
    code: "88360",
    shortdesc: "M/PHMTRC ALYS TUMOR IMHCHEM EA ANTIBODY MANUAL",
  },
  { code: "28805", shortdesc: "AMPUTATION FOOT TRANSMETARSAL" },
  { code: "28705", shortdesc: "ARTHRODESIS PANTALAR" },
  { code: "38770", shortdesc: "PEL LMPHADEC W/XTRNL ILIAC HYPOGSTR&OBTURATOR" },
  { code: "78201", shortdesc: "LIVER IMAGING STATIC ONLY" },
  { code: "21627", shortdesc: "STERNAL DEBRIDEMENT" },
  { code: "0342T", shortdesc: "THERAPEUTIC APHERESIS W/SELECTIVE HDL DELIP" },
  {
    code: "49020",
    shortdesc: "DRAINAGE PERITON ABSCESS/LOCAL PERITONITIS OPEN",
  },
  { code: "35800", shortdesc: "EXPL PO HEMRRG THROMBOSIS/INFCTJ NCK" },
  { code: "58672", shortdesc: "LAPAROSCOPY FIMBRIOPLASTY" },
  {
    code: "58293",
    shortdesc: "VAG HYST >250 GM COLPOURTCSTOPEXY W/WO NDSC CTR",
  },
  {
    code: "47536",
    shortdesc: "EXCHANGE BILIARY DRG CATHETER PRQ W/IMG GID RS&I",
  },
  { code: "64413", shortdesc: "INJECTION ANESTHETIC AGENT CERVICAL PLEXUS" },
  {
    code: "1007F",
    shortdesc: "ANTI-INFLAMMATORY/ANALGESIC SYMPTOM RELIEF ASSES",
  },
  { code: "92510", shortdesc: "null" },
  { code: "27098", shortdesc: "TRANSFER ADDUCTOR ISCHIUM" },
  { code: "81317", shortdesc: "PMS2 GENE ANALYSIS FULL SEQUENCE" },
  { code: "54336", shortdesc: "1 STG PERINEAL HYPOSPADIAS RPR W/GRF&/FLAP" },
  {
    code: "33737",
    shortdesc: "ATRIAL SEPTECT/SEPTOST OPN HRT W/INFL OCCLUSION",
  },
  { code: "0045U", shortdesc: "null" },
  { code: "83582", shortdesc: "ASSAY OF KETOGENIC STEROIDS FRACTIONATION" },
  {
    code: "64484",
    shortdesc: "NJX ANES&/STRD W/IMG TFRML EDRL LMBR/SAC EA LV",
  },
  { code: "81214", shortdesc: "BRCA1 FULL SEQ ANAL&COMMON DUP/DEL VARIANTS" },
  { code: "58940", shortdesc: "OOPHORECTOMY PARTIAL/TOTAL UNI/BI" },
  { code: "95908", shortdesc: "NERVE CONDUCTION STUDIES 3-4 STUDIES" },
  {
    code: "88154",
    shortdesc: "CYTP SLIDES C/V MNL SCR&CPTR-RESCR CELL S&R PHYS",
  },
  { code: "80410", shortdesc: "CALCITONIN STIMULATION PANEL" },
  { code: "47801", shortdesc: "PLACEMENT CHOLEDOCHAL STENT" },
  { code: "43331", shortdesc: "ESOPHAGOMYOTOMY HELLER TYPE THORACIC APPROACH" },
  {
    code: "35301",
    shortdesc: "TEAEC W/PATCH GRF CAROTID VERTB SUBCLAV NECK INC",
  },
  { code: "3570F", shortdesc: "REPORT BONE SCINTIGRAPHY W/X-RAY SAME REGION" },
  { code: "3550F", shortdesc: "LOW RISK FOR THROMBOEMBOLISM" },
  {
    code: "22861",
    shortdesc: "REVJ RPLCMT DISC ARTHROPLASTY ANT 1 NTRSPC CRV",
  },
  {
    code: "0418T",
    shortdesc: "INTERRO DEVICE EVALUATION CARDIAC MODULJ SYSTEM",
  },
  { code: "20690", shortdesc: "APPLICATION UNIPLANE EXTERNAL FIXATION SYSTEM" },
  { code: "15221", shortdesc: "FTH/GFT FR W/DIR CLSR S/A/L EA ADDL 20 CM/<" },
  { code: "61795", shortdesc: "null" },
  { code: "84065", shortdesc: "null" },
  {
    code: "10180",
    shortdesc: "INCISION & DRAINAGE COMPLEX PO WOUND INFECTION",
  },
  { code: "84466", shortdesc: "ASSAY OF L7383TRANSFERRIN" },
  {
    code: "88344",
    shortdesc: "IMHISTOCHEM/CYTCHM EA MULTIPLEX ANTIBODY SLIDE",
  },
  { code: "36820", shortdesc: "ARVEN ANAST OPN F/ARM VEIN TRPOS" },
  { code: "64834", shortdesc: "SUTURE 1 NERVE HAND/FOOT COMMON SENSORY NERVE" },
  { code: "25020", shortdesc: "DCMPRN FASCT F/ARM&WRST FLXR/XTNSR W/O DBRDMT" },
  { code: "93237", shortdesc: "null" },
  { code: "00210", shortdesc: "ANESTHESIA INTRACRANIAL PROCEDURE NOS" },
  { code: "75953", shortdesc: "PLMT XTN PROSTH EVASC RPR INFRARENAL RS&I" },
  { code: "37766", shortdesc: "STAB PHLEBT VARICOSE VEINS 1 XTR > 20 INCS" },
  {
    code: "01953",
    shortdesc: "ANES 2/3 DGR BRN EXC/DBRDMT W/WO GRF EA 9% TBS",
  },
  { code: "1018F", shortdesc: "DYSPNEA ASSESSED NOT PRESENT" },
  {
    code: "78266",
    shortdesc: "GSTRC EMPTNG IMAG STD W/SM BWL COL TRNST MLT DAY",
  },
  { code: "80059", shortdesc: "null" },
  {
    code: "23650",
    shortdesc: "CLSD TX SHOULDER DISLC W/MANIPULATION W/O ANES",
  },
  {
    code: "11642",
    shortdesc: "EXCISION MALIGNANT LESION F/E/E/N/L 1.1-2.0 CM",
  },
  { code: "96360", shortdesc: "IV INFUSION HYDRATION INITIAL 31 MIN-1 HOUR" },
  { code: "71020", shortdesc: "RADIOLOGIC EXAM CHEST 2 VIEWS FRONTAL&LATERAL" },
  {
    code: "44900",
    shortdesc: "INCISION AND DRAINAGE APPENDICEAL ABSCESS OPEN",
  },
  { code: "54015", shortdesc: "I&D PENIS DEEP" },
  {
    code: "33877",
    shortdesc: "RPR THORACOABDOMINAL AORTIC ANEURYS W/WO BYPASS",
  },
  { code: "82610", shortdesc: "CYSTATIN C" },
  { code: "85540", shortdesc: "WBC ALKALINE PHOSPHATASE COUNT" },
  { code: "65600", shortdesc: "MULTIPLE PUNCTURES ANTERIOR CORNEA" },
  { code: "01780", shortdesc: "ANESTHESIA VEINS UPPER ARM & ELBOW NOS" },
  {
    code: "01464",
    shortdesc: "ANESTHESIA ARTHROSCOPIC PROCEDURE ANKLE & FOOT",
  },
  { code: "0043T", shortdesc: "null" },
  { code: "69200", shortdesc: "RMVL FB XTRNL AUDITORY CANAL W/O ANES" },
  { code: "48120", shortdesc: "EXCISION LESION PANCREAS" },
  {
    code: "94667",
    shortdesc: "MANJ CH WALL FACILITATE LNG FUNCJ 1 DEMO&/EVAL",
  },
  { code: "82232", shortdesc: "BETA-2 MICROGLOBULIN" },
  { code: "80154", shortdesc: "DRUG SCREEN QUALITATIVE BENZODIAZEPINES" },
  {
    code: "94060",
    shortdesc: "BRNCDILAT RSPSE SPMTRY PRE&POST-BRNCDILAT ADMN",
  },
  {
    code: "30802",
    shortdesc: "ABLTJ SOF TISS INF TURBS UNI/BI SUPFC INTRAMURAL",
  },
  { code: "00452", shortdesc: "ANESTHESIA CLAVICLE & SCAPULA RADICAL SURGERY" },
  { code: "53265", shortdesc: "EXC/FULGURATION URETHRAL CARUNCLE" },
  {
    code: "36456",
    shortdesc: "PRTL EXCHANGE TRANSFUSE BLOOD/PLSM/CRYST NEWBORN",
  },
  { code: "56515", shortdesc: "DESTRUCTION LESIONS VULVA EXTENSIVE" },
  {
    code: "35472",
    shortdesc: "TRLUML BALLOON ANGIOPLASTY PERCUTANEOUS AORTIC",
  },
  {
    code: "61343",
    shortdesc: "CRNEC SUBOCCIPITAL CRV LAM DCMPRN MEDULLA & CORD",
  },
  {
    code: "27357",
    shortdesc: "EXCISION/CURETTAGE CYST/TUMOR FEMUR W/AUTOGRAFT",
  },
  { code: "57421", shortdesc: "COLPOSCOPY ENTIRE VAGINA W/VAGINA/CERVIX BX" },
  { code: "58671", shortdesc: "LAPAROSCOPY W/PLMT OCCLUSION DEVICE OVIDUCTS" },
  { code: "66184", shortdesc: "REVJ SHUNT EXTRAOCULAR RESERVOIR W/O GRAFT" },
  { code: "71250", shortdesc: "CT THORAX W/O CONTRAST MATERIAL" },
  { code: "41825", shortdesc: "EXC LESION/TUMOR DENTOALVEOLAR STRUX W/O RPR" },
  { code: "81270", shortdesc: "JAK2 GENE ANALYSIS P.VAL617PHE VARIANT" },
  { code: "63615", shortdesc: "STRTCTC BX ASPIRAT/EXC LESION SPINAL CORD" },
  { code: "66030", shortdesc: "INJX ANTERIOR CHAMBER EYE MEDICATION SPX" },
  {
    code: "20982",
    shortdesc: "ABLATION BONE TUMOR RF PERQ W/IMG GDN WHEN DONE",
  },
  {
    code: "92550",
    shortdesc: "TYMPANOMETRY AND REFLEX THRESHOLD MEASUREMENTS",
  },
  { code: "64646", shortdesc: "CHEMODENERVATION OF TRUNK MUSCLE 1-5 MUSCLES" },
  { code: "3046F", shortdesc: "MOST RECENT HEMOGLOBIN A1C LEVEL >9.0%" },
  { code: "95861", shortdesc: "NDL EMG 2 XTR W/WO RELATED PARASPINAL AREAS" },
  { code: "64788", shortdesc: "EXC NEUROFIBROMA/NEUROLEMMOMA CUTAN NRV" },
  {
    code: "61520",
    shortdesc: "CRNEC TUM INFRATTL/POSTFOSSA CRBLOPNT ANGLE TUM",
  },
  { code: "0457T", shortdesc: "REMVL PERM IMPLT AORTIC VENTR MECHANO-ELEC" },
  { code: "91052", shortdesc: "null" },
  {
    code: "00529",
    shortdesc: "ANES MEDIASTINOSCOPY&THORACOSCOPY W/1 LUNG VNT",
  },
  { code: "27197", shortdesc: "CLSD TX PELVIC RING FX W/O MANIPULATION" },
  { code: "77061", shortdesc: "DIGITAL BREAST TOMOSYNTHESIS UNILATERAL" },
  { code: "0109U", shortdesc: "null" },
  { code: "63301", shortdesc: "VCRPEC LES 1 SGM XDRL THORACIC TTHRC" },
  { code: "96111", shortdesc: "DEVELOPMENTAL TESTING W/INTERP & REPORT" },
  { code: "87534", shortdesc: "IADNA HIV-1 DIRECT PROBE TECHNIQUE" },
  {
    code: "43266",
    shortdesc: "EGD ENDOSCOPIC STENT PLACEMENT W/WIRE& DILATION",
  },
  {
    code: "27658",
    shortdesc: "REPAIR FLEXOR TENDON LEG PRIMARY W/O GRAFT EACH",
  },
  {
    code: "55876",
    shortdesc: "PLMT INTERSTITIAL DEV RADIAT TX PROSTATE 1/MULT",
  },
  { code: "27258", shortdesc: "OPTX SPON HIP DISLC RPLCMT FEM HEAD ACTBLM" },
  { code: "86079", shortdesc: "BLD BANK PHYS SVCS AUTHJ DEVIJ STANDARD REPRT" },
  { code: "24320", shortdesc: "TENOPLASTY ELBOW TO SHOULDER SINGLE" },
  { code: "82378", shortdesc: "CARCINOEMBRYONIC ANTIGEN CEA" },
  { code: "68530", shortdesc: "RMVL FB/DACRYOLITH LACRIMAL PASSAGES" },
  { code: "92596", shortdesc: "EAR PROTECTOR ATTENUATION MEASUREMENTS" },
  { code: "88285", shortdesc: "CHRMSM ANALYSIS ADDL CELLS COUNTED EACH STUDY" },
  {
    code: "64491",
    shortdesc: "NJX DX/THER AGT PVRT FACET JT CRV/THRC 2ND LEVEL",
  },
  { code: "26705", shortdesc: "CLTX METACARPOPHALANGEAL DISLC W/MANJ W/ANES" },
  { code: "99506", shortdesc: "HOME VISIT INTRAMUSCULAR INJECTIONS" },
  { code: "87581", shortdesc: "IADNA MYCOPLSM PNEUMONIAE AMPLIFIED PROBE TQ" },
  { code: "66682", shortdesc: "SUTURE IRIS CILIARY BODY SPX RETRIEVAL SUTURE" },
  { code: "4145F", shortdesc: "2+ ANTI-HYPERTENSIVE AGENTS RXD OR TAKEN" },
  {
    code: "47539",
    shortdesc: "PLMT BILE DUCT STENT PRQ NEW ACCESS W/O SEP CATH",
  },
  {
    code: "0053T",
    shortdesc: "RPLCMT/RPR IMPLTBL COMPNT TOT RPLCMT HRT EX THRC",
  },
  { code: "79900", shortdesc: "null" },
  { code: "96121", shortdesc: "null" },
  { code: "81450", shortdesc: "GEN SEQ ANALYS HEMATOLYMPHOID NEO 5-50 GENE" },
  { code: "87198", shortdesc: "null" },
  { code: "2010F", shortdesc: "VITAL SIGNS RECORDED" },
  {
    code: "15840",
    shortdesc: "GRAFT FACIAL NERVE PARALYSIS FREE FASCIAL GRAFT",
  },
  { code: "62277", shortdesc: "null" },
  {
    code: "34111",
    shortdesc: "EMBLC/THRMBC W/WO CATH RADIAL/ULNAR ART ARM INC",
  },
  { code: "1123F", shortdesc: "ADV CARE PLN TLKD & ALT DCSN MAKER DOCD" },
  {
    code: "42970",
    shortdesc: "CTRL NASOPHARYNGEAL HEMRRG SMPL W/PST NSL PACKS",
  },
  { code: "43280", shortdesc: "LAPS SURG ESOPG/GSTR FUNDOPLASTY" },
  { code: "01480", shortdesc: "ANES OPEN PROC BONES LOWER LEG/ANKLE/FOOT NOS" },
  { code: "41850", shortdesc: "DESTRUCTION LESION DENTOALVEOLAR STRUCTURES" },
  { code: "93264", shortdesc: "null" },
  {
    code: "93562",
    shortdesc: "INDIC DIL STD ARTL&/OR VEN CATHJ SBSQ OUTP MEA",
  },
  { code: "66505", shortdesc: "IRIDOTOMY STAB INC SPX TRANSFIXION" },
  { code: "32320", shortdesc: "DECORTICATION & PARIETAL PLEURECTOMY" },
  {
    code: "22110",
    shortdesc: "PRTL EXC VRT BDY B1Y LES W/O SPI CORD 1 SGM CRV",
  },
  {
    code: "4325F",
    shortdesc: "MEDICAL & SURGICAL TREATMENT OPTION REVIEW W/P",
  },
  { code: "69636", shortdesc: "TYMPP ANTRT/MASTOID W/OSSICULAR CHAIN RECNSTJ" },
  { code: "31640", shortdesc: "BRONCHOSCOPY W/EXCISION TUMOR" },
  { code: "59899", shortdesc: "UNLISTED PROCEDURE MATERNITY CARE & DELIVERY" },
  {
    code: "49654",
    shortdesc: "LAPAROSCOPY REPAIR INCISIONAL HERNIA REDUCIBLE",
  },
  { code: "31660", shortdesc: "BRONCHOSCOPIC THERMOPLASTY ONE LOBE" },
  { code: "27602", shortdesc: "DCMPRN FASCT LEG ANT&/LAT&PST CMPRT" },
  { code: "82415", shortdesc: "CHLORAMPHENICOL" },
  { code: "76778", shortdesc: "null" },
  {
    code: "95938",
    shortdesc: "SHORT-LATENCY SOMATOSENS EP STD UPR & LOW LIMB",
  },
  { code: "4210F", shortdesc: "ACE/ARB MEDICATION THERAPY 6 MONTHS/>" },
  { code: "97608", shortdesc: "NEG PRESSURE WOUND THERAPY NON DME >50 SQ CM" },
  { code: "15040", shortdesc: "HARVEST SKIN TISSUE CLTR SKIN AGRFT 100 CM/<" },
  { code: "83055", shortdesc: "HEMOGLOBIN SULFHEMOGLOBIN QUALITATIVE" },
  {
    code: "38204",
    shortdesc: "MGMT RCP HEMATOP PROGENITOR CELL DONOR &ACQUISJ",
  },
  { code: "81328", shortdesc: "SLCO1B1 GENE ANALYSIS COMMON VARIANTS" },
  { code: "92950", shortdesc: "CARDIOPULMONARY RESUSCITATION" },
  { code: "87283", shortdesc: "IAADI RUBEOLA" },
  {
    code: "28531",
    shortdesc: "OPEN TX SESAMOID FRACTURE W/WO INTERNAL FIXATION",
  },
  { code: "90725", shortdesc: "CHOLERA VACCINE INJECTABLE" },
  {
    code: "33606",
    shortdesc: "ANAST PULMONARY ART AORTA DAMUS-KAYE-STANSEL PX",
  },
  { code: "69799", shortdesc: "UNLISTED PROCEDURE MIDDLE EAR" },
  { code: "69399", shortdesc: "UNLISTED PROCEDURE EXTERNAL EAR" },
  { code: "84999", shortdesc: "UNLISTED CHEMISTRY PROCEDURE" },
  { code: "46258", shortdesc: "HRHC 1 COL/GRP W/FSTULECTMY INCL FSSRECTOMY" },
  {
    code: "55000",
    shortdesc: "PNXR ASPIR HYDROCELE TUNICA VAGIS W/WO NJX MED",
  },
  { code: "26820", shortdesc: "FUSION OPPOSITION THUMB W/AUTOGENOUS GRAFT" },
  { code: "83905", shortdesc: "MOLEC MUTATION ALLELE TRANSCRIPTION 1 SGM EA" },
  {
    code: "31380",
    shortdesc: "PARTIAL LARYNGECTOMY HEMILARYNG ANTEROVERTICAL",
  },
  {
    code: "15400",
    shortdesc: "XENOGRAFT TEMP CLOSURE TRUNK/ARM/LEG 1ST 100CM",
  },
  { code: "83893", shortdesc: "MOLEC DOT/SLOT BLOT EA NUCLEIC ACID PREPJ" },
  { code: "67335", shortdesc: "PLACEMENT ADJUSTABLE SUTURE STRABISMUS" },
  {
    code: "82652",
    shortdesc: "1 25 DIHYDROXY INCLUDES FRACTIONS IF PERFORMED",
  },
  {
    code: "50810",
    shortdesc: "URETEROSIGMOIDOSTOMY W/SIGMOID BLADDER & COLOSTO",
  },
  { code: "84590", shortdesc: "ASSAY OF VITAMIN A" },
  { code: "76975", shortdesc: "GI ENDOSCOPIC US S&I" },
  { code: "85029", shortdesc: "null" },
  { code: "13300", shortdesc: "null" },
  {
    code: "87633",
    shortdesc: "IADNA RESPIRATRY PROBE & REV TRNSCR 12-25 TARGET",
  },
  {
    code: "60212",
    shortdesc: "PRTL THYROID LOBEC UNI W/CONTRATLAT STOT LOBEC",
  },
  {
    code: "92226",
    shortdesc: "OPHTHALMOSCPY EXTENDED RETINAL DRAWING I&R SBS",
  },
  { code: "78457", shortdesc: "VENOUS THROMBOSIS IMAGING VENOGRAM UNILATERAL" },
  { code: "80371", shortdesc: "DRUG SCREENING STIMULANTS SYNTHETIC" },
  {
    code: "27236",
    shortdesc: "OPTX FEM FX PROX END NCK INT FIXJ/PROSTC RPLCMT",
  },
  { code: "25390", shortdesc: "OSTEOPLASTY RADIUS/ULNA SHORTENING" },
  {
    code: "0014U",
    shortdesc: "HEM HMTLMF NEO GENE REARGMT DNA WHL BLD/MARROW",
  },
  { code: "38720", shortdesc: "CERVICAL LYMPHADENECTOMY" },
  { code: "31553", shortdesc: "LARYNGOPLASTY LARYNGEAL STEN W/STENT < 12 YRS" },
  { code: "22220", shortdesc: "OSTEOTOMY SPINE W/DSKC ANT APPR 1 VRT SGM CRV" },
  { code: "96119", shortdesc: "NEUROPSYC TSTG W/PROF I&R ADMN BY TECH PR HR" },
  {
    code: "97162",
    shortdesc: "PHYSICAL THERAPY EVALUATION MOD COMPLEX 30 MINS",
  },
  { code: "92325", shortdesc: "MODIFICAJ CONTACT LENX SPX SUPVJ ADAPTATION" },
  { code: "86930", shortdesc: "FROZEN BLOOD EACH UNIT FREEZING" },
  { code: "0144T", shortdesc: "null" },
  { code: "88142", shortdesc: "CYTP CERV/VAG AUTO THIN LAYER PREP MNL SCREEN" },
  {
    code: "25415",
    shortdesc: "RPR NONUNION/MALUNION RADIUS&ULNA W/O AUTOGRAF",
  },
  {
    code: "21079",
    shortdesc: "IMPRESSION & PREPARATION INTERIM OBTURATOR PROST",
  },
  { code: "31515", shortdesc: "LARYNGOSCOPY W/WO TRACHEOSCOPY ASPIRATION" },
  { code: "71038", shortdesc: "null" },
  { code: "62230", shortdesc: "RPLCMT/REVJ CSF SHUNT VALVE/CATH SHUNT SYS" },
  {
    code: "43752",
    shortdesc: "NASO/ORO-GASTRIC TUBE PLMT REQ PHYS&FLUOR GDNCE",
  },
  { code: "25600", shortdesc: "CLTX DSTL RADIAL FX/EPIPHYSL SEP W/O MANJ" },
  {
    code: "88121",
    shortdesc: "CYTP INSITU HYBRID URNE SPEC 3-5 PROBES CPTR EA",
  },
  {
    code: "4552F",
    shortdesc: "PT OFFERED REFERRAL SPEECH LANGUAGE PATHOLOGIST",
  },
  { code: "97156", shortdesc: "null" },
  {
    code: "12002",
    shortdesc: "SMPL REPAIR SCALP/NECK/AX/GENIT/TRUNK 2.6-7.5CM",
  },
  { code: "86615", shortdesc: "ANTIBODY BORDETELLA" },
  { code: "64463", shortdesc: "PVB THORACIC CONT CATHETER INFUSION W/IMG GID" },
  {
    code: "01470",
    shortdesc: "ANES NRV/MUS/TND/FASC LOWER LEG/ANKLE/FOOT NOS",
  },
  {
    code: "27187",
    shortdesc: "PROPH TX N/P/PLTWR W/WO MMA FEM NCK & PROX FEMUR",
  },
  { code: "59412", shortdesc: "EXTERNAL CEPHALIC VERSION W/WO TOCOLYSIS" },
  { code: "72126", shortdesc: "CT CERVICAL SPINE W/CONTRAST MATERIAL" },
  {
    code: "61533",
    shortdesc: "CRANIOT SUBDURAL IMPLT ELCTRD SEIZURE MONITORING",
  },
  { code: "43289", shortdesc: "UNLISTED LAPAROSCOPIC PROCEDURE ESOPHAGUS" },
  { code: "76362", shortdesc: "null" },
  {
    code: "34847",
    shortdesc: "VISCER AND INFRARENAL ABDOM AORTA 3 PROSTHESIS",
  },
  {
    code: "0401T",
    shortdesc: "MULTI-SPECTRAL DIGITAL SKIN LES ANALYSIS 6+ LES",
  },
  { code: "77427", shortdesc: "RADIATION TREATMENT MANAGEMENT 5 TREATMENTS" },
  {
    code: "99255",
    shortdesc: "INITIAL INPATIENT CONSULT NEW/ESTAB PT 110 MIN",
  },
  { code: "27400", shortdesc: "TRANSFER TENDON/MUSCLE HAMSTRINGS FEMUR" },
  { code: "87625", shortdesc: "IADNA HUMAN PAPILLOMAVIRUS TYPES 16 & 18 ONLY" },
  { code: "4267F", shortdesc: "COMPRESSION THERAPY PRESCRIBED" },
  { code: "90921", shortdesc: "null" },
  {
    code: "75900",
    shortdesc: "EXCHNG CATH THROMBOLYTIC THERAPY W/CONTRAST RS&I",
  },
  { code: "20251", shortdesc: "BIOPSY VERTEBRAL BODY OPEN LUMBAR/CERVICAL" },
  { code: "31700", shortdesc: "null" },
  { code: "64840", shortdesc: "SUTURE POSTERIOR TIBIAL NERVE" },
  { code: "0076U", shortdesc: "null" },
  { code: "82106", shortdesc: "ALPHA-FETOPROTEIN AMNIOTIC FLUID" },
  { code: "50543", shortdesc: "LAPAROSCOPY SURG PARTIAL NEPHRECTOMY" },
  { code: "0542T", shortdesc: "null" },
  {
    code: "82487",
    shortdesc: "CHROMATOGRAPHY QUAL PAPR 1-DIMENSNL ANALYTE NES",
  },
  {
    code: "43278",
    shortdesc: "ERCP TUMOR/POLYP/LESION ABLATION W/DILATION&WIRE",
  },
  {
    code: "74245",
    shortdesc: "RADEX GI TRACT UPR W/SM INT W/MULT SERIAL IMAGES",
  },
  { code: "63709", shortdesc: "RPR DURAL/CSF LEAK/PSEUDOMENINGOCELE W/LAM" },
  {
    code: "46221",
    shortdesc: "HEMORRHOIDECTOMY INTERNAL RUBBER BAND LIGATIONS",
  },
  { code: "34808", shortdesc: "EVASC PLACEMENT ILIAC ARTERY OCCLUSION DEVICE" },
  { code: "37160", shortdesc: "VENOUS ANASTOMOSIS OPEN CAVAL-MESENTERIC" },
  { code: "29874", shortdesc: "ARTHROSCOPY KNEE REMOVAL LOOSE/FOREIGN BODY" },
  { code: "81383", shortdesc: "HLA II HIGH RESOLUTION 1 ALLELE/ALLELE GROUP" },
  { code: "81120", shortdesc: "IDH1 COMMON VARIANTS" },
  {
    code: "4279F",
    shortdesc: "PNEUMOCYSTIS JIROVECI PNEUMONIA PROPHYLAXIS RXD",
  },
  {
    code: "84375",
    shortdesc: "SUGARS CHROMATOGRAPHIC TLC/PAPER CHROMATOGRAPHY",
  },
  { code: "83876", shortdesc: "MYELOPEROXIDASE MPO" },
  { code: "47564", shortdesc: "LAPS SURG CHOLECSTC W/EXPL COMMON DUCT" },
  { code: "96900", shortdesc: "ACTINOTHERAPY ULTRAVIOLET LIGHT" },
  { code: "75736", shortdesc: "ANGIOGRAPHY PELVIC SLCTV/SUPRASLCTV RS&I" },
  { code: "59020", shortdesc: "FETAL CONTRACTION STRESS TEST" },
  {
    code: "21243",
    shortdesc: "ARTHRP TMPRMAND JOINT W/PROSTHETIC REPLACEMENT",
  },
  { code: "83897", shortdesc: "MOLEC NUCLEIC ACID TR EA NUCLEIC ACID PREPJ" },
  {
    code: "99494",
    shortdesc: "1ST/SBSQ PSYCH COLLAB CARE MGMT EA ADDL 30 MINS",
  },
  {
    code: "4004F",
    shortdesc: "PT SCRND TOBACCO USE RCVD TOBACCO CESSATION TALK",
  },
  { code: "15130", shortdesc: "DERMAL AUTOGRAFT TRUNK/ARM/LEG 1ST 100 CM" },
  { code: "49320", shortdesc: "LAPS ABD PRTM&OMENTUM DX W/WO SPEC BR/WA SPX" },
  { code: "27080", shortdesc: "COCCYGECTOMY PRIMARY" },
  { code: "0098U", shortdesc: "null" },
  { code: "99290", shortdesc: "null" },
  { code: "37205", shortdesc: "TCAT PLMT IV STENT PERCUTANEOUS 1ST VESSEL" },
  { code: "90737", shortdesc: "null" },
  { code: "62327", shortdesc: "NJX DX/THER SBST INTRLMNR LMBR/SAC W/IMG GDN" },
  { code: "33750", shortdesc: "SHUNT SUBCLAVIAN PULMONARY ARTERY" },
  { code: "95979", shortdesc: "ELEC ALYS NSTIM PLS GEN CPLX DP BRN EA 30 MIN" },
  { code: "27446", shortdesc: "ARTHRP KNEE CONDYLE&PLATEAU MEDIAL/LAT CMPRT" },
  {
    code: "20939",
    shortdesc: "BONE MARROW ASPIRATION BONE GRFG SPI SURG ONLY",
  },
  { code: "81161", shortdesc: "DMD DUPLICATION/DELETION ANALYSIS" },
  {
    code: "93282",
    shortdesc: "PRGRMNG DEV EVAL IMPLANTABLE IN PERSN 1 LD DFB",
  },
  { code: "33234", shortdesc: "RMVL TRANSVNS PM ELTRD 1 LEAD SYS ATR/VENTR" },
  {
    code: "27499",
    shortdesc: "DCMPRN FASCT THIGH&/KNEE MLT DBRDMT NV MUSC&NRVE",
  },
  { code: "10008", shortdesc: "null" },
  { code: "54641", shortdesc: "null" },
  { code: "93786", shortdesc: "BL BLD PRESS W/TAPE&/DISK 24/> HR REC ONL" },
  { code: "71100", shortdesc: "RADEX RIBS UNILATERAL 2 VIEWS" },
  { code: "0313T", shortdesc: "LAPS REVJ/REPLCMT NSTIM ELTRD ARRAY VAGUS NRV" },
  { code: "82928", shortdesc: "null" },
  { code: "93316", shortdesc: "ECHO TRANSESOPHAG CONGEN PROBE PLCMT ONLY" },
  { code: "0584F", shortdesc: "TRANSFER OF CARE CHECKLIST NOT USED" },
  { code: "82009", shortdesc: "KETONE BODIES SERUM QUALITATIVE" },
  {
    code: "43238",
    shortdesc: "EGD INTRMURAL US NEEDLE ASPIRATE/BIOPSY ESOPHAGS",
  },
  { code: "35820", shortdesc: "EXPL PO HEMRRG THROMBOSIS/INFCTJ CH" },
  { code: "0029T", shortdesc: "null" },
  { code: "81332", shortdesc: "SERPINA1 GENE ANALYSIS COMMON VARIANTS" },
  {
    code: "3319F",
    shortdesc: "1 DX IMG ORDER CHEST XRAY CT US MRI PET/NUC MED",
  },
  { code: "77338", shortdesc: "MLC IMRT DESIGN & CONSTRUCTION PER IMRT PLAN" },
  {
    code: "50694",
    shortdesc: "PLMT URTRL STNT PRQ NEW ACESS W/O SEP NFROS CATH",
  },
  { code: "68135", shortdesc: "DESTRUCTION LESION CONJUNCTIVA" },
  {
    code: "01444",
    shortdesc: "ANES ART KNEE POPLITEAL EXC&GRF/RPR OCCLS/ARYS",
  },
  { code: "90706", shortdesc: "RUBELLA VIRUS VACCINE LIVE SUBCUTANEOUS" },
  {
    code: "72291",
    shortdesc: "RAD S&I PERQ VRTPLS/SACRPLSTY PR VRT BODY FLUOR",
  },
  { code: "92568", shortdesc: "ACOUSTIC REFLEX THRESHOLD" },
  { code: "26476", shortdesc: "LENGTHENING TENDON EXTENSOR HAND/FINGER EACH" },
  {
    code: "99337",
    shortdesc: "DOM/R-HOME E/M EST PT SIGNIF NEW PROB 60 MINUTES",
  },
  { code: "80164", shortdesc: "DRUG ASSAY VALPROIC DIPROPYLACETIC ACID TOTAL" },
  {
    code: "44187",
    shortdesc: "LAPAROSCOPY SURG ILEOSTOMY/JEJUNOSTOMY NON-TUBE",
  },
  {
    code: "77499",
    shortdesc: "UNLISTED PROCEDURE THERAPEUTIC RADIOLOGY TX MGMT",
  },
  { code: "71275", shortdesc: "CT ANGIOGRAPHY CHEST W/CONTRAST/NONCONTRAST" },
  { code: "76930", shortdesc: "US GUIDANCE PERICARDIOCENTESIS RS&I" },
  { code: "75676", shortdesc: "ANGIOGRAPHY CAROTID CERVICAL UNILATERAL RS&I" },
  { code: "87471", shortdesc: "IADNA BARTONELLA AMPLIFIED PROBE TECHNIQUE" },
  { code: "0136U", shortdesc: "null" },
  {
    code: "81455",
    shortdesc: "GEN SEQ ANALYS SOL ORG/HEMTOLMPHOID NEO 51/> GEN",
  },
  { code: "54512", shortdesc: "EXC XTRPARENCHYMAL LESION TESTIS" },
  {
    code: "19281",
    shortdesc: "PERQ DEVICE PLACEMENT BREAST LOC 1ST LES W/GDNCE",
  },
  { code: "78015", shortdesc: "THYROID CARCINOMA METASTASES IMG LMTD AREA" },
  {
    code: "58999",
    shortdesc: "UNLISTED PX FEMALE GENITAL SYSTEM NONOBSTETRICAL",
  },
  { code: "53040", shortdesc: "DRAINAGE DEEP PERIURETHRAL ABSCESS" },
  { code: "45562", shortdesc: "EXPL RPR & PRESACRAL DRG RECTAL INJURY" },
  { code: "1006F", shortdesc: "OSTEOARTHRITIS SYMPTOMS&FUNCJAL STATUS ASSES" },
  { code: "31800", shortdesc: "SUTURE TRACHEAL WOUND/INJURY CERVICAL" },
  {
    code: "1502F",
    shortdesc: "PT QUERIED RE PAIN W/FUNC USING RELIABLE INSTRM",
  },
  { code: "88199", shortdesc: "UNLISTED CYTOPATHOLOGY PROCEDURE" },
  { code: "51725", shortdesc: "SIMPLE CYSTOMETROGRAM" },
  { code: "25246", shortdesc: "INJECTION WRIST ARTHROGRAPHY" },
  { code: "50384", shortdesc: "REMOVAL INDWELLING URETERAL STENT PRQ" },
  { code: "76821", shortdesc: "DOPPLER VELOCIMETRY FETAL MIDDLE CEREBRAL ART" },
  {
    code: "25248",
    shortdesc: "EXPL W/REMOVAL DEEP FOREIGN BODY FOREARM/WRIST",
  },
  { code: "94775", shortdesc: "PEDIATRIC APNEA MONITOR ATTACHMENT" },
  {
    code: "78014",
    shortdesc: "THYROID UPTAKE W/BLOOD FLOW SNGLE/MULT QUAN MEAS",
  },
  { code: "0360T", shortdesc: "OBSERVATIONAL BEHAV ASSESSMENT FIRST 30 MIN" },
  { code: "52285", shortdesc: "CYSTOURETHROSCOPY TX FEMALE URETHRAL SYNDROME" },
  { code: "4064F", shortdesc: "ANTIDEPRESSANT PHARMACOTHERAPY PRESCRIBED" },
  {
    code: "0297T",
    shortdesc: "EXT ECG > 48HR TO 21 DAY SCAN ANALYSIS W/REPORT",
  },
  {
    code: "88377",
    shortdesc: "M/PHMTRC ALYS ISH QUANT/SEMIQ MNL EACH MULTIPRB",
  },
  {
    code: "15330",
    shortdesc: "ACLR DERMAL ALLOGRAFT TRUNK/ARM/LEG 1ST 100 CM",
  },
  { code: "87623", shortdesc: "IADNA HUMAN PAPILLOMAVIRUS LOW-RISK TYPES" },
  { code: "64809", shortdesc: "SYMPATHECTOMY THORACOLUMBAR" },
  { code: "77321", shortdesc: "SPEC TELETHX PORT PLN PARTS HEMIBDY TOT BDY" },
  {
    code: "93583",
    shortdesc: "PERCUTANEOUS TRANSCATHETER SEPTAL REDUCTION THER",
  },
  { code: "92627", shortdesc: "EVALUATION AUDITORY REHAB STATUS EA 15 MIN" },
  { code: "45384", shortdesc: "COLSC FLX W/REMOVAL LESION BY HOT BX FORCEPS" },
  { code: "65770", shortdesc: "KERATOPROSTHESIS" },
  { code: "51710", shortdesc: "CHANGE CYSTOSTOMY TUBE COMPLICATED" },
  { code: "26320", shortdesc: "REMOVAL IMPLANT FROM FINGER/HAND" },
  { code: "87001", shortdesc: "ANIMAL INOCULATION SMALL ANIMAL W/OBSERVATION" },
  { code: "76870", shortdesc: "US SCROTUM & CONTENTS" },
  { code: "99464", shortdesc: "ATTN AT DELIVERY 1ST STABILIZATION OF NEWBORN" },
  { code: "25611", shortdesc: "null" },
  { code: "59140", shortdesc: "TX ECTOPIC PREGNANCY CERVICAL W/EVACUATION" },
  { code: "82265", shortdesc: "null" },
  {
    code: "37217",
    shortdesc: "TCATH STENT PLACEMT RETROGRAD CAROTID/INNOMINATE",
  },
  { code: "25031", shortdesc: "INCISION & DRAINAGE FOREARM&/WRIST BURSA" },
  {
    code: "47543",
    shortdesc: "ENDOLUMINAL BX BILIARY TREE PRQ ANY METH 1/MLT",
  },
  { code: "45108", shortdesc: "ANORECTAL MYOMECTOMY" },
  { code: "81518", shortdesc: "null" },
  { code: "82172", shortdesc: "APOLIPOPROTEIN EACH" },
  { code: "82135", shortdesc: "AMINOLEVULINIC ACID DELTA" },
  { code: "41500", shortdesc: "FIXJ TONGUE MECHANICAL OTHER/THAN SUTURE" },
  {
    code: "0205T",
    shortdesc: "IV CATH CORONARY VESSEL/GRAFT SPECTROSCPY EA VSL",
  },
  { code: "62368", shortdesc: "ELECT ANALYS IMPLT ITHCL/EDRL PUMP W/REPRGRMG" },
  {
    code: "0042T",
    shortdesc: "CEREBRAL PERFUSION ANALYS CT W/BLOOD FLOW&VOLUME",
  },
  {
    code: "33781",
    shortdesc: "RPR TGV AORTIC P-ART RCNSTJ RPR SBPULMC OBSTRCJ",
  },
  { code: "57291", shortdesc: "CONSTRUCTION ARTIFICIAL VAGINA W/O GRAFT" },
  {
    code: "33476",
    shortdesc: "R VENTRIC RESCJ INFUND STEN W/WO COMMISSUROTOMY",
  },
  { code: "2035F", shortdesc: "TYMPANIC MEMBRANE MOBILITY ASSESS" },
  {
    code: "61790",
    shortdesc: "CREATE LESION STRTCTC PRQ NEUROLYTIC GASSERIAN",
  },
  {
    code: "28039",
    shortdesc: "EXCISION TUMOR SOFT TIS FOOT/TOE SUBQ 1.5 CM/>",
  },
  {
    code: "78635",
    shortdesc: "CEREBROSPINAL FLUID FLOW W/O MATL VENTRICLGRAPHY",
  },
  { code: "42955", shortdesc: "PHARYNGOSTOMY FSTLJ PHARYNX XTRNL FEEDING" },
  {
    code: "11951",
    shortdesc: "SUBCUTANEOUS INJECTION FILLING MATRL 1.1-5.0 CC",
  },
  { code: "94005", shortdesc: "HOME VENTILATOR MGMT CARE OVERSIGHT 30 MIN/>" },
  { code: "24345", shortdesc: "REPAIR MEDIAL COLLATERAL LIGAMENT ELBOW" },
  { code: "27290", shortdesc: "INTERPELVIABDOMINAL AMPUTATION" },
  { code: "0307T", shortdesc: "null" },
  { code: "87158", shortdesc: "CULTURE TYPING OTHER METHODS" },
  {
    code: "44955",
    shortdesc: "APPENDEC INDICATED PURPOSE OTH MAJOR PX NOT SPX",
  },
  { code: "16030", shortdesc: "DRS&/DBRDMT PRTL-THKNS BURNS 1ST/SBSQ LARGE" },
  { code: "28054", shortdesc: "ARTHRTOMY W/BX INTERPHALANGEAL JOINT" },
  {
    code: "01782",
    shortdesc: "ANESTHESIA VEINS UPPER ARM & ELBOW PHLEBORRHAPHY",
  },
  { code: "80018", shortdesc: "null" },
  { code: "99469", shortdesc: "SUBQ I/P CRITICAL CARE PR DAY AGE 28 DAYS/<" },
  { code: "99392", shortdesc: "PERIODIC PREVENTIVE MED EST PATIENT 1-4YRS" },
  { code: "72265", shortdesc: "MYELOGRAPY LUMBOSACRAL RS&I" },
  { code: "59420", shortdesc: "null" },
  { code: "33222", shortdesc: "RELOCATION OF SKIN POCKET FOR PACEMAKER" },
  { code: "61543", shortdesc: "CRANIOTOMY PARTIAL/SUBTOTAL HEMISPHERECTOMY" },
  { code: "82784", shortdesc: "ASSAY OF GAMMAGLOBULIN IGA IGD IGG IGM EACH" },
  {
    code: "0017U",
    shortdesc: "ONC HMTLMF NEO JAK2 MUTATION DNA BLD/BNE MARROW",
  },
  { code: "92004", shortdesc: "OPHTH MEDICAL XM&EVAL COMPRE NEW PT 1/> VST" },
  { code: "27005", shortdesc: "TENOTOMY HIP FLEXOR OPEN SEPARATE PROCEDURE" },
  { code: "58102", shortdesc: "null" },
  { code: "24152", shortdesc: "RADICAL RESECTION TUMOR RADIAL HEAD/NECK" },
  { code: "17305", shortdesc: "null" },
  { code: "44238", shortdesc: "UNLISTED LAPAROSCOPY PX INTESTINE XCP RECTUM" },
  { code: "76604", shortdesc: "US CHEST REAL TIME W/IMAGE DOCUMENTATION" },
  {
    code: "73723",
    shortdesc: "MRI ANY JT LOWER EXTREM W/O & W/CONTRAST MATRL",
  },
  { code: "27070", shortdesc: "PARTIAL EXCISION SUPERFICIAL PELVIS" },
  { code: "84165", shortdesc: "PROTEIN ELECTROPHORETIC FRACTJ&QUANTJ SERUM" },
  { code: "32100", shortdesc: "THORACOTOMY WITH EXPLORATION" },
  { code: "86738", shortdesc: "ANTIBODY MYCOPLSM" },
  { code: "0364T", shortdesc: "ADAPTIVE BEHAVIOR TX BY PROTOCOL FIRST 30 MIN" },
  { code: "43256", shortdesc: "UPR GI NDSC TNDSC STENT PLMT W/PREDILAT" },
  { code: "82980", shortdesc: "GLUTETHIMIDE" },
  { code: "31529", shortdesc: "LARYNGOSCOPY W/WO TRACHEOSCOPY DILATION SUBSQ" },
  { code: "78813", shortdesc: "PET IMAGING WHOLE BODY" },
  { code: "0392T", shortdesc: "null" },
  { code: "59105", shortdesc: "null" },
  {
    code: "35701",
    shortdesc: "EXPL N/FLWD SURG RPR W/WO LYSIS CAROTID ARTERY",
  },
  { code: "82101", shortdesc: "ALKALOIDS URINE QUANTITATIVE" },
  { code: "64837", shortdesc: "SUTURE EACH ADDITIONAL NERVE HAND/FOOT" },
  { code: "64755", shortdesc: "TRANSECTION/AVULSION VAGUS NERVES" },
  { code: "64442", shortdesc: "null" },
  { code: "92970", shortdesc: "CARDIOASSIST-METH CIRCULATORY ASSIST INTERNAL" },
  { code: "41140", shortdesc: "GLSSC COMPL/TOT W/WOTRACHS W/O RAD NECK DSJ" },
  {
    code: "21931",
    shortdesc: "EXCISION TUMOR SOFT TIS BACK/FLANK SUBQ 3 CM/>",
  },
  { code: "92613", shortdesc: "FLEXIBLE ENDOSCOPIC EVAL SWALLOW C/V REC I&R" },
  { code: "58350", shortdesc: "CHROMOTUBATION OVIDUCT W/MATERIALS" },
  { code: "99300", shortdesc: "null" },
  { code: "83717", shortdesc: "null" },
  { code: "92355", shortdesc: "FITTING SPECTACLE MOUNTED LW VIS AID TLSCP" },
  { code: "36563", shortdesc: "INSJ TUNNELED CTR VAD W/SUBQ PUMP" },
  { code: "35190", shortdesc: "RPR/TRAUMATIC AV FISTULA EXTREMITIES" },
  { code: "16020", shortdesc: "DRS&/DBRDMT PRTL-THKNS BURNS 1ST/SBSQ SMALL" },
  { code: "01472", shortdesc: "ANES RPR RUPTURED ACHILLES TENDON W/WO GRAFT" },
  {
    code: "01744",
    shortdesc: "ANES OPEN/SURG ARTHRS REPRS NON/MALUNION HUMERUS",
  },
  { code: "85441", shortdesc: "HEINZ BODIES DIRECT" },
  { code: "54112", shortdesc: "EXC PENILE PLAQUE GRAFT > 5 CM LENGTH" },
  { code: "96130", shortdesc: "null" },
  {
    code: "93660",
    shortdesc: "CARDIOVASCULAR FUNCTION EVAL W/TILT TABLE W/MNTR",
  },
  {
    code: "78630",
    shortdesc: "CEREBROSPINAL FLUID FLOW W/O MATL CISTERNOGRAPHY",
  },
  { code: "53275", shortdesc: "EXCISION/FULGURATION URETHRAL PROLAPSE" },
  { code: "63300", shortdesc: "VCRPEC LES 1 SGM XDRL CERVICAL" },
  { code: "81318", shortdesc: "PMS2 GENE ANALYSIS KNOWN FAMILIAL VARIANTS" },
  { code: "72190", shortdesc: "RADIOLOGIC EXAM PELVIS COMPL MINIMUM 3 VIEWS" },
  { code: "92609", shortdesc: "THER SP-GENRATJ DEV PRGRMG&MODIFICAJ" },
  { code: "3066F", shortdesc: "DOCUMENTATION OF TREATMENT FOR NEPHROPATHY" },
  {
    code: "0436T",
    shortdesc: "PRGRMG EVAL NSTIM PLS GEN SYS SLEEP APNEA STUDY",
  },
  { code: "99438", shortdesc: "null" },
  { code: "3040F", shortdesc: "FUNCTIONAL EXPIRATORY VOLUME < 40%" },
  { code: "50830", shortdesc: "URINARY UNIDIVERSION" },
  { code: "25900", shortdesc: "AMPUTATION FOREARM THROUGH RADIUS & ULNA" },
  { code: "0050T", shortdesc: "RMVL VENTR DEV XTRCORP PRQ T-SEPTAL 1/DUAL" },
  {
    code: "30906",
    shortdesc: "CTRL NSL HEMRRG PST NASAL PACKS&/CAUTERY SUBSQ",
  },
  {
    code: "27510",
    shortdesc: "CLTX FEM FX DSTL END MEDIAL/LAT CONDYLE W/MANJ",
  },
  { code: "25085", shortdesc: "CAPSULOTOMY WRIST" },
  { code: "0486T", shortdesc: "OCT MIDDLE EAR WITH I&R BILATERAL" },
  {
    code: "22857",
    shortdesc: "TOT DISC ARTHRP ART DISC ANT APPRO 1 NTRSPC LMBR",
  },
  { code: "92135", shortdesc: "null" },
  {
    code: "32652",
    shortdesc: "THRSC TOT PULM DCRTCTJ INTRAPLEURAL PNEUMONOLSS",
  },
  {
    code: "60225",
    shortdesc: "TOTAL THYROID LOBEC UNI W/CONTRALAT STOT LOBEC",
  },
  { code: "99141", shortdesc: "null" },
  { code: "80422", shortdesc: "GLUCOSE TOLERANCE PANEL INSULINOMA" },
  {
    code: "63196",
    shortdesc: "LAM CORDOTOMY SCTJ BOTH SPINOTHALMIC TRACTS CRV",
  },
  { code: "81248", shortdesc: "G6PD GENE ANALYSIS KNOWN FAMILIAL VARIANTS" },
  { code: "82627", shortdesc: "DEHYDROEPIANDROSTERONE-SULFATE" },
  { code: "29881", shortdesc: "ARTHRS KNE SURG W/MENISCECTOMY MED/LAT W/SHVG" },
  { code: "81256", shortdesc: "HFE HEMOCHROMATOSIS GENE ANAL COMMON VARIANTS" },
  {
    code: "15240",
    shortdesc: "FTH/GFT FR W/DIR CLSR F/C/C/M/N/AX/G/H/F 20 CM/<",
  },
  {
    code: "88347",
    shortdesc: "IMMUNOFLUORESCENT STUDY EA ANTIBODY INDIR METHOD",
  },
  { code: "95824", shortdesc: "ELECTROENCEPHALOGRAM CERE DEATH EVAL ONLY" },
  { code: "92592", shortdesc: "HEARING AID CHECK MONAURAL" },
  { code: "48545", shortdesc: "PANCREATORRHAPHY INJURY" },
  {
    code: "73503",
    shortdesc: "RADEX HIP UNILATERAL WITH PELVIS MINIMUM 4 VIEWS",
  },
  { code: "79440", shortdesc: "RP THERAPY INTRA-ARTICULAR ADMINISTRATION" },
  { code: "82677", shortdesc: "ASSAY OF ESTRIOL" },
  {
    code: "33259",
    shortdesc: "ATRIA ABLTJ & RCNSTJ W/OTHER PX EXTEN W/BYPASS",
  },
  { code: "49561", shortdesc: "RPR 1ST INCAL/VNT HERNIA INCARCERATED" },
  { code: "27732", shortdesc: "ARREST EPIPHYSEAL OPEN DISTAL FIBULA" },
  { code: "92317", shortdesc: "RX CONTACT CORNEOSCLERAL LENS" },
  {
    code: "31633",
    shortdesc: "BRONCHOSCOPY W/TRANSBRONCL NDL ASPIR BX EA LOBE",
  },
  { code: "75600", shortdesc: "AORTOGRAPHY THORACIC W/O SERIALOGRAPHY RS&I" },
  { code: "31513", shortdesc: "LARYNGOSCOPY INDIRECT W/VOCAL CORD INJECTION" },
  { code: "62258", shortdesc: "RMVL COMPLETE CSF SHUNT SYSTEM W/RPLCMT SHUNT" },
  { code: "30901", shortdesc: "CONTROL NASAL HEMORRHAGE ANTERIOR SIMPLE" },
  {
    code: "61800",
    shortdesc: "APPL STRTCTC HEADFRAME STEREOTACTIC RADIOSURGERY",
  },
  {
    code: "43277",
    shortdesc: "ERCP BALLOON DILATE BILIARY/PANC DUCT/AMPULLA EA",
  },
  {
    code: "36906",
    shortdesc: "PERQ THRMBC/NFS DIAL CIRCUIT TCAT PLMT IV STENT",
  },
  {
    code: "31725",
    shortdesc: "CATH ASPIR TRACHEOBRNCL FIBERSCOPE BEDSIDE SPX",
  },
  { code: "96372", shortdesc: "THERAPEUTIC PROPHYLACTIC/DX INJECTION SUBQ/IM" },
  { code: "31825", shortdesc: "SURG CLSR TRACHEOSTOMY/FISTULA W/PLASTIC RPR" },
  { code: "87807", shortdesc: "IAADIADOO RESPIRATORY SYNCTIAL VIRUS" },
  { code: "65930", shortdesc: "RMVL BLOOD CLOT ANTERIOR SEGMENT EYE" },
  { code: "88147", shortdesc: "CYTP SMRS C/V SCR AUTOMATED SYSTEM PHYS SUPV" },
  { code: "44405", shortdesc: "COLONOSCOPY STOMA W/BALLOON DILATION" },
  {
    code: "17271",
    shortdesc: "DESTRUCTION MALIGNANT LESION S/N/H/F/G 0.6-1.0CM",
  },
  { code: "43499", shortdesc: "UNLISTED PROCEDURE ESOPHAGUS" },
  { code: "63620", shortdesc: "STEREOTACTIC RADIOSURGERY 1 SPINAL LESION" },
  { code: "62161", shortdesc: "NUNDSC ICRA DSJ ADS FENESTRATION SEPTUM CSTS" },
  {
    code: "33411",
    shortdesc: "RPLCMT AORTIC VALVE ANNULUS ENLGMENT NONC SINUS",
  },
  {
    code: "26121",
    shortdesc: "FASCT PALM W/WO Z-PLASTY TISSUE REARGMT/SKN GRFT",
  },
  { code: "0016T", shortdesc: "null" },
  { code: "28445", shortdesc: "OPEN TREATMENT TALUS FRACTURE" },
  { code: "99553", shortdesc: "null" },
  { code: "39402", shortdesc: "MEDIASTINOSCOPY WITH LYMPH NODE BIOPSY/IES" },
  { code: "36481", shortdesc: "PRQ PORTAL VEIN CATHETERIZATION ANY METHOD" },
  { code: "81176", shortdesc: "ASXL1 GENE ANALYSIS TARGETED SEQ ANALYSIS" },
  { code: "81251", shortdesc: "GBA GLUCOSIDASE/BETA/ACID ANAL COMM VARIANTS" },
  { code: "83914", shortdesc: "MUTATION ID ENZYMATIC LIG/PRIMER XTN 1 SGM EA" },
  {
    code: "44406",
    shortdesc: "COLONOSCOPY STOMA W/ENDOSCOPIC ULTRASOUND EXAM",
  },
  { code: "86717", shortdesc: "ANTIBODY LEISHMANIA" },
  {
    code: "26390",
    shortdesc: "EXC FLXR TDN W/IMPLTJ SYNTH ROD DLYD TDN GRF H/F",
  },
  { code: "23470", shortdesc: "ARTHROPLASTY GLENOHUMRL JT HEMIARTHROPLASTY" },
  {
    code: "21080",
    shortdesc: "IMPRESSION & PREPJ DEFINITIVE OBTURATOR PROSTHES",
  },
  { code: "01829", shortdesc: "ANESTHESIA DIAGNOSTIC ARTHROSCOPIC PROC WRIST" },
  { code: "86756", shortdesc: "ANTIBODY RESPIRATORY SYNCTIAL VIRUS" },
  { code: "99488", shortdesc: "null" },
  { code: "57268", shortdesc: "REPAIR ENTEROCELE VAGINAL APPROACH SPX" },
  {
    code: "27218",
    shortdesc: "OPTX POST PEL BONE FX&/DISLC INT FIXJ IF PFRMD",
  },
  { code: "76510", shortdesc: "OPH US DX B-SCAN&QUAN A-SCAN SM PT ENCTR" },
  { code: "28130", shortdesc: "TALECTOMY ASTRAGALECTOMY" },
  { code: "45355", shortdesc: "COLSC RGD/FLX TABDL VIA COLOTOMY 1/MLT" },
  {
    code: "81507",
    shortdesc: "FETAL ANEUPLOIDY 21 18 13 SEQ ANALY TRISOM RISK",
  },
  { code: "58291", shortdesc: "VAG HYST > 250 GM RMVL TUBE&/OVARY" },
  {
    code: "96365",
    shortdesc: "IV INFUSION THERAPY/PROPHYLAXIS /DX 1ST TO 1 HR",
  },
  { code: "42500", shortdesc: "PLSTC RPR SALIVARY DUX SIALODOCHOPLASTY PRIM" },
  { code: "60522", shortdesc: "THYMECTOMY PRTL/TOT RAD MEDSTNL DSJ SPX" },
  { code: "63272", shortdesc: "LAM EXC ISPI LES OTH/THN NEO IDRL LUMBAR" },
  { code: "01200", shortdesc: "ANESTHESIA CLOSED HIP JOINT PROCEDURE" },
  {
    code: "3042F",
    shortdesc: "FUNCTJL EXPIR VOLUME >/EQUAL 40% PREDICTED VALUE",
  },
  {
    code: "47540",
    shortdesc: "PLMT BILE DUCT STENT PRQ NEW ACCESS W/SEP CATH",
  },
  { code: "00811", shortdesc: "ANESTHESIA LOWER INTST ENDOSCOPIC PX NOS" },
  { code: "54300", shortdesc: "PENIS STRAIGHTENING CHORDEE" },
  { code: "83014", shortdesc: "HPYLORI DRUG ADMINISTRATION" },
  { code: "15301", shortdesc: "ALGRFT TEMPORARY CLOSURE T/A/L EA 100 CM/EA" },
  { code: "33266", shortdesc: "NDSC ABLATION & RCNSTJ ATRIA EXTEN W/O BYPASS" },
  {
    code: "0072T",
    shortdesc: "US ABLATJ UTERINE LEIOMYOMAT >/EQUAL 200 CC TISS",
  },
  { code: "11106", shortdesc: "null" },
  { code: "76496", shortdesc: "UNLISTED FLUOROSCOPIC PROCEDURE" },
  {
    code: "65779",
    shortdesc: "PLACE AMNIOTIC MEMBRANE OCULAR SURFACE SUTURED",
  },
  { code: "92316", shortdesc: "RX CONTACT CORNEAL LENS APHAKIA BOTH EYES" },
  { code: "69650", shortdesc: "STAPES MOBILIZATION" },
  {
    code: "33619",
    shortdesc: "RPR 1 VNTRC W/O/F OBSTRCJ&AORTIC ARCH HYPOPLAS",
  },
  { code: "51610", shortdesc: "NJX RETROGRADE URETHROCSTOGRAPY" },
  { code: "47630", shortdesc: "BILIARY DUCT STONE XTRJ PRQ VIA BASKET/SNARE" },
  {
    code: "87220",
    shortdesc: "TISS KOH SLIDE SAMPS SKN/HR/NLS FNGI/ECTOPARASIT",
  },
  { code: "0453T", shortdesc: "INSJ/RPLCMT IMPLTBL AORTIC VENTR MECHANO-ELEC" },
  { code: "93799", shortdesc: "UNLISTED CARDIOVASCULAR SERVICE/PROCEDURE" },
  { code: "81470", shortdesc: "X-LINKED INTELLECTUAL DBLT GENOMIC SEQ ANALYS" },
  { code: "21433", shortdesc: "OPEN TX CRANIOFACIAL SEP COMPLICATED MLT APPR" },
  {
    code: "61575",
    shortdesc: "TRNSRAL SKULL BSE/BR STEM/CORD BX/DCOMPR/EXC LES",
  },
  { code: "33764", shortdesc: "SHUNT CENTRAL W/PROSTHETIC GRAFT" },
  { code: "90691", shortdesc: "TYPHOID VACCINE VI CAPSULAR POLYSACCHARIDE IM" },
  { code: "48540", shortdesc: "INT ANAST PANCREATIC CYST GI TRACT ROUX-EN-Y" },
  {
    code: "26340",
    shortdesc: "MANIPULATION FINGER JOINT UNDER ANES EACH JOINT",
  },
  { code: "26490", shortdesc: "OPPONENSPLASTY SUPFCIS TDN TR TYP EA TDN" },
  { code: "89251", shortdesc: "CUL OOCYTE/EMBRYO < 4 D CO-CULT OCYTE/EMBRY" },
  { code: "50120", shortdesc: "PYELOTOMY W/EXPLORATION" },
  { code: "0005T", shortdesc: "null" },
  { code: "28306", shortdesc: "OSTEOT W/WO LNGTH SHRT/CORRJ 1ST METAR" },
  { code: "46083", shortdesc: "INCISION THROMBOSED HEMORRHOID EXTERNAL" },
  { code: "84583", shortdesc: "ASSAY OF UROBILINOGEN URINE SEMIQUANTITATIVE" },
  { code: "58996", shortdesc: "null" },
  { code: "86431", shortdesc: "RHEUMATOID FACTOR QUANTITATIVE" },
  { code: "47425", shortdesc: "CHOLEDOCHOT/OST W/SPHNCTROTOMY/SPHNCTROP" },
  { code: "83864", shortdesc: "MUCOPOLYSACCHARIDES ACID QUANTITATIVE" },
  {
    code: "24120",
    shortdesc: "EXC/CURTG BONE CYST/BENIGN TUMOR H/N RDS/OLECRN",
  },
  { code: "0129U", shortdesc: "null" },
  { code: "21280", shortdesc: "MEDIAL CANTHOPEXY SEPARATE PROCEDURE" },
  { code: "77031", shortdesc: "STRTCTC LOCLZJ GID BREAST BX/NEEDLE PLACEMENT" },
  { code: "92977", shortdesc: "THROMBOLYSIS CORONARY INTRAVENOUS INFUSION" },
  { code: "43314", shortdesc: "ESPHGP CGEN DFCT THRC APPR W/RPR FSTL" },
  { code: "4290F", shortdesc: "PATIENT SCREENED FOR INJECTION DRUG USE" },
  {
    code: "35876",
    shortdesc: "THRMBC ARTL/VEN GRF XCP HEMO GRF/FSTL W/REVJ GRF",
  },
  { code: "23180", shortdesc: "PARTIAL EXCISION BONE CLAVICLE" },
  {
    code: "62282",
    shortdesc: "INJX/INFUS NEUROLYT SBST EPIDURAL LUMBAR/SACRAL",
  },
  { code: "47525", shortdesc: "CHANGE PRQ BILIARY DRAINAGE CATHETER" },
  {
    code: "23465",
    shortdesc: "CAPSULORRHAPHY GLENOHUMERAL JT PST W/WO BONE BLK",
  },
  {
    code: "22112",
    shortdesc: "PRTL EXC VRT BDY B1Y LES W/O SPI CORD 1 SGM THRC",
  },
  { code: "68020", shortdesc: "INCISION CONJUNCTIVA DRAINAGE OF CYST" },
  {
    code: "30120",
    shortdesc: "EXCISION/SURGICAL PLANING SKIN NOSE RHINOPHYMA",
  },
  { code: "86344", shortdesc: "LEUKOCYTE PHAGOCYTOSIS" },
  { code: "87487", shortdesc: "IADNA CHLAMYDIA PNEUMONIAE QUANTIFICATION" },
  {
    code: "29355",
    shortdesc: "APPLICATION LONG LEG CAST WALKER/AMBULATORY TYPE",
  },
  { code: "83018", shortdesc: "HEAVY METAL QUANTIATIVE EACH NES" },
  {
    code: "01230",
    shortdesc: "ANESTHESIA OPEN PROCEDURES UPPER 2/3 FEMUR NOS",
  },
  { code: "69100", shortdesc: "BIOPSY EXTERNAL EAR" },
  {
    code: "75685",
    shortdesc: "ANGIOGRAPHY VERTEBRAL/CERVICAL/&/INTRACRAN RS&I",
  },
  { code: "26560", shortdesc: "REPAIR SYNDACTYLY EACH SPACE W/SKIN FLAPS" },
  {
    code: "32607",
    shortdesc: "THORACOSCOPY W/DX BX OF LUNG INFILTRATE UNILATRL",
  },
  { code: "84577", shortdesc: "ASSAY OF UROBILINOGEN FECES QUANTITATIVE" },
  { code: "0327T", shortdesc: "null" },
  { code: "0170T", shortdesc: "null" },
  { code: "62362", shortdesc: "IMPLTJ/RPLCMT ITHCL/EDRL DRUG NFS PRGRBL PUMP" },
  {
    code: "99477",
    shortdesc: "INITIAL HOSP NEONATE 28 D/< NOT CRITICALLY ILL",
  },
  { code: "83498", shortdesc: "ASSAY OF HYDROXYPROGESTERONE 17-D" },
  {
    code: "31545",
    shortdesc: "LARGSC MICRO/TELESCOPE RMVL LES VOCAL CORD FLAP",
  },
  { code: "97521", shortdesc: "null" },
  { code: "27315", shortdesc: "null" },
  { code: "36430", shortdesc: "TRANSFUSION BLOOD/BLOOD COMPONENTS" },
  { code: "26080", shortdesc: "ARTHRT EXPL DRG/RMVL LOOSE/FB IPHAL JT EA" },
  { code: "51800", shortdesc: "CSTOPLASTY/CSTOURTP PLSTC ANY" },
  {
    code: "44210",
    shortdesc: "LAPS COLECTOMY TOT W/O PRCTECT W/ILEOST/ILEOPXTS",
  },
  { code: "43284", shortdesc: "LAPS ESOPHGL SPHNCTR AGMNTJ PLMT DEV CRRPL" },
  { code: "95829", shortdesc: "ELECTROCORTICOGRAM SURGERY SPX" },
  {
    code: "27698",
    shortdesc: "REPAIR SECONDARY DISRUPTED LIGAMENT ANKLE COLTRL",
  },
  { code: "42280", shortdesc: "MAXILLARY IMPRESJ PALATAL PROSTHESIS" },
  { code: "54308", shortdesc: "URETHROPLASTY 2ND STAGE HYPOSPADIAS RPR <3 CM" },
  {
    code: "53400",
    shortdesc: "URETHROPLASTY 1ST STG FISTULA/DIVERTICULUM/STRIX",
  },
  { code: "64792", shortdesc: "EXC NEUROFIBROMA/NEUROLEMMOMA EXTNSV" },
  {
    code: "69635",
    shortdesc: "TYMPP ANTRT/MASTOID W/O OSSICULAR CHAIN RECNSTJ",
  },
  { code: "0015F", shortdesc: "MELANOMA FOLLOW UP COMPLETED" },
  { code: "90285", shortdesc: "null" },
  { code: "83716", shortdesc: "null" },
  { code: "43621", shortdesc: "GSTRCT TOT W/ROUX-EN-Y RCNSTJ" },
  {
    code: "43257",
    shortdesc: "EGD DELIVER THERMAL ENERGY SPHNCTR/CARDIA GERD",
  },
  { code: "23044", shortdesc: "ARTHRT ACROMCLAV STRNCLAV JT EXPL/DRG/RMVL FB" },
  {
    code: "54410",
    shortdesc: "RMVL & RPLCMT INFLATABLE PENILE PROSTH SAME SESS",
  },
  {
    code: "48551",
    shortdesc: "BKBENCH PREPJ CADAVER DONOR PANCREAS ALLOGRAFT",
  },
  { code: "4079F", shortdesc: "DOC REHAB SERVICES WERE CONSIDERED" },
  {
    code: "27650",
    shortdesc: "REPAIR PRIMARY OPEN/PRQ RUPTURED ACHILLES TENDON",
  },
  { code: "65771", shortdesc: "RADIAL KERATOTOMY" },
  { code: "31535", shortdesc: "LARYNGOSCOPY DIRECT OPERATIVE W/BIOPSY" },
  { code: "27418", shortdesc: "ANTERIOR TIBIAL TUBERCLEPLASTY" },
  { code: "55540", shortdesc: "EXC VARICOCELE/LIGATION VEINS W/HERNIA RPR" },
  {
    code: "26215",
    shortdesc: "EXC/CURETTAGE CYST/TUMOR PHALANX FINGER W/AGRAFT",
  },
  { code: "97145", shortdesc: "null" },
  { code: "38724", shortdesc: "CERVICAL LYMPHADEC MODIFIED RADICAL NECK DSJ" },
  { code: "56510", shortdesc: "null" },
  {
    code: "36909",
    shortdesc: "DIALYIS CIRCUIT VASC EMBOLI OCCLS EVASC IMG S&I",
  },
  {
    code: "90827",
    shortdesc: "IND PSYCTX IA INPT/PRTL HOS/RESDNT 45-50 MIN E/M",
  },
  {
    code: "67010",
    shortdesc: "RMVL VITREOUS ANT APPR SUBTOT RMVL MECH VITRECT",
  },
  { code: "42830", shortdesc: "ADENOIDECTOMY PRIMARY <AGE 12" },
  {
    code: "48105",
    shortdesc: "RESECJ/DBRDMT PANCREAS NECROTIZING PANCREATITIS",
  },
  { code: "27380", shortdesc: "SUTURE INFRAPATELLAR TENDON PRIMARY" },
  { code: "80194", shortdesc: "DRUG SCREEN QUANTITATIVE QUINIDINE" },
  { code: "21270", shortdesc: "MALAR AUGMENTATION PROSTHETIC MATERIAL" },
  {
    code: "19001",
    shortdesc: "PUNCTURE ASPIRATION BREAST EACH ADDITIONAL CYST",
  },
  { code: "89360", shortdesc: "null" },
  {
    code: "97036",
    shortdesc: "APPL MODALITY 1/> AREAS HUBBARD TANK EA 15 MIN",
  },
  { code: "54360", shortdesc: "PLASTIC RPR PENIS CORRECT ANGULATION" },
  {
    code: "00472",
    shortdesc: "ANESTHESIA PARTIAL RIB RESECTION THORACOPLASTY",
  },
  { code: "56700", shortdesc: "PRTL HYMENECTOMY/REVJ HYMENAL RING" },
  {
    code: "33471",
    shortdesc: "VALVOTOMY PULM VALVE CLSD HEART VIA PULM ARTERY",
  },
  { code: "52700", shortdesc: "TRURL DRAINAGE PROSTATIC ABSCESS" },
  {
    code: "50080",
    shortdesc: "PRQ NEPHROSTOLITHOTOMY/PYELOSTOLITHOTOMY </2 CM",
  },
  {
    code: "93464",
    shortdesc: "PHYSIOLOGIC EXERCISE STUDY & HEMODYNAMIC MEASU",
  },
  {
    code: "54417",
    shortdesc: "RMVL & RPLCMT PENILE PROSTHESIS INFECTED FIELD",
  },
  { code: "93542", shortdesc: "null" },
  { code: "54900", shortdesc: "EPIDIDYMOVASOSTOMY ANAST EPIDIDYMIS UNI" },
  { code: "3650F", shortdesc: "ELECTROENCEPHALOGRAM ORDERED RVWD OR REQ" },
  { code: "26471", shortdesc: "TENODESIS PROXIMAL INTERPHALANGEAL JOINT EACH" },
  { code: "0525T", shortdesc: "null" },
  { code: "94160", shortdesc: "null" },
  {
    code: "38206",
    shortdesc: "BLD-DRV HEMATOP PROGEN CELL HRVG TRNSPLJ AUTOL",
  },
  { code: "93641", shortdesc: "EPHYS EVAL PACG CVDFB LDS W/TSTG OF PULSE GEN" },
  { code: "27503", shortdesc: "CLTX SPRCNDYLR/TRNSCNDYLR FEM FX W/MANJ" },
  { code: "35454", shortdesc: "null" },
  { code: "62278", shortdesc: "null" },
  { code: "35011", shortdesc: "DIR RPR ANEURYSM AXIL-BRACHIAL ARM INCISION" },
  {
    code: "31298",
    shortdesc: "NASAL/SINUS NDSC W/FRONTAL & SPHEN SINS DILATION",
  },
  { code: "73092", shortdesc: "RADEX UPPER EXTREMITY INFANT MINIMUM 2 VIEWS" },
  { code: "29820", shortdesc: "ARTHROSCOPY SHOULDER SURG SYNOVECTOMY PARTIAL" },
  {
    code: "31300",
    shortdesc: "LARYNGOTOMY W/RMVL TUMOR/LARYNGOCELE CORDECTOMY",
  },
  { code: "88362", shortdesc: "NERVE TEASING PREPARATIONS" },
  { code: "50860", shortdesc: "URETEROSTOMY TRANSPLANTATION URETER SKIN" },
  { code: "11044", shortdesc: "DEBRIDEMENT BONE MUSCLE &/FASCIA 20 SQ CM/<" },
  {
    code: "92508",
    shortdesc: "TX SPEECH LANGUAGE VOICE COMMJ AUDITRY 2/>INDIV",
  },
  {
    code: "34713",
    shortdesc: "PERQ ACCESS & CLOSURE FEM ART FOR DELIVERY NDGFT",
  },
  { code: "99178", shortdesc: "null" },
  { code: "65410", shortdesc: "BIOPSY CORNEA" },
  { code: "93505", shortdesc: "ENDOMYOCARDIAL BIOPSY" },
  { code: "49610", shortdesc: "RPR OMPHALOCELE GROSS TYP OPRATION 1ST STG" },
  { code: "26502", shortdesc: "RCNSTJ TDN PULLEY EA TDN W/TDN/FSCAL GRF SPX" },
  {
    code: "1160F",
    shortdesc: "RVW ALL MEDS BY RXNG PRCTIONR OR CLIN RPH DOCD",
  },
  {
    code: "80377",
    shortdesc: "DRUG/SUBSTANCE DEFINITIVE QUAL/QUANT NOS 7/MORE",
  },
  { code: "01933", shortdesc: "ANES INTRACRANIAL THER IVNTL RAD VENS/LYMPHTC" },
  { code: "90989", shortdesc: "DIALYSIS TRAINING PATIENT COMPLETED COURSE" },
  { code: "81273", shortdesc: "KIT GENE ANALYSIS D816 VARIANT(S)" },
  { code: "37616", shortdesc: "LIGATION MAJOR ARTERY CHEST" },
  { code: "28264", shortdesc: "CAPSULOTOMY MIDTARSAL" },
  { code: "3132F", shortdesc: "DOC REFERAL FOR UPPER GI ENDOSCOPY" },
  { code: "21275", shortdesc: "SECONDARY REVISION ORBITOCRANIOFACIAL RCNSTJ" },
  {
    code: "86023",
    shortdesc: "ANTIBODY IDENTIFICATION PLATELET IMMUNOGL ASSAY",
  },
  { code: "59136", shortdesc: "TX ECTOPIC PREGNANCY NTRSTL PRTL RESCJ UTER" },
  { code: "66920", shortdesc: "RMVL LENS MATERIAL INTRACAPSULAR" },
  { code: "64864", shortdesc: "SUTURE FACIAL NERVE EXTRACRANIAL" },
  {
    code: "21348",
    shortdesc: "OPTX NASOMAX CPLX FX LEFT II TYPE W/BONE GRAFT",
  },
  { code: "56800", shortdesc: "PLASTIC REPAIR INTROITUS" },
  { code: "29550", shortdesc: "STRAPPING TOES" },
  { code: "00790", shortdesc: "ANES INTRAPERITONEAL UPPER ABDOMEN W/LAPS NOS" },
  {
    code: "74249",
    shortdesc: "RADEX GI UPR W/WO GLUCOSE W/SM INTEST FOLLW-THRU",
  },
  { code: "86386", shortdesc: "NUCLEAR MATRIX PROTEIN 22 NMP22 QUALITATIVE" },
  { code: "29130", shortdesc: "APPLICATION FINGER SPLINT STATIC" },
  {
    code: "00561",
    shortdesc: "ANES HRT PERICARD SAC&GREAT VSLS W/PMP OXTJ <1YR",
  },
  {
    code: "93533",
    shortdesc: "CMBN R HRT T-SEPTAL L HRT CATHJ SEPTAL OPNG CGEN",
  },
  { code: "45020", shortdesc: "I&D DP SUPRALEVATOR PELVIRCT/RETRORCT ABSC" },
  {
    code: "33688",
    shortdesc: "CLSR V-SEPTAL DFCT W/RMVL P-ART BAND W/WO GUSSET",
  },
  { code: "0004M", shortdesc: "null" },
  { code: "84085", shortdesc: "PHOSPHOGLUCONATE 6-DEHYD RBC" },
  { code: "88307", shortdesc: "LEVEL V SURG PATHOLOGY GROSS&MICROSCOPIC EXAM" },
  { code: "86780", shortdesc: "ANTIBODY TREPONEMA PALLIDUM" },
  {
    code: "01382",
    shortdesc: "ANESTH DIAGNOSTIC ARTHROSCOPIC PROC KNEE JOINT",
  },
  { code: "0086U", shortdesc: "null" },
  {
    code: "61591",
    shortdesc: "INFRATEMPO MID CRANIAL FOSSA W/WO DCOMPR&/MOBI",
  },
  { code: "31622", shortdesc: "BRNCHSC INCL FLUOR GDNCE DX W/CELL WASHG SPX" },
  { code: "93236", shortdesc: "null" },
  { code: "34825", shortdesc: "PLMT XTN PROSTH EVASC RPR ARYSM/DSJ 1ST VSL" },
  {
    code: "77610",
    shortdesc: "HYPERTHERMIA INTERSTITIAL PROBE 5/< APPLICATORS",
  },
  {
    code: "61107",
    shortdesc: "TWIST DRILL HOLE IMPLT VENTRICULAR CATH/DEVICE",
  },
  {
    code: "58140",
    shortdesc: "MYOMECTOMY 1-4 MYOMAS W/250 GM/< ABDOMINAL APPR",
  },
  {
    code: "61332",
    shortdesc: "EXPLORATION ORBIT TRANSCRANIAL APPROACH W/BIOPSY",
  },
  { code: "0072U", shortdesc: "null" },
  { code: "11711", shortdesc: "null" },
  { code: "50541", shortdesc: "LAPAROSCOPY SURG ABLATION RENAL CYSTS" },
  { code: "24546", shortdesc: "OPEN TX HUMERAL SUPRACONDYLAR FRACTURE W/XTN" },
  { code: "0544T", shortdesc: "null" },
  { code: "73202", shortdesc: "CT UPPER EXTREMITY W/O & W/CONTRAST MATERIAL" },
  { code: "26392", shortdesc: "RMVL SYNTH ROD & INSJ FLXR TDN GRF H/F EA ROD" },
  { code: "87539", shortdesc: "IADNA HIV-2 QUANT & REVERSE TRANSCRIPTION" },
  { code: "31075", shortdesc: "SINUSOTOMY FRONTAL TRANSORBITAL UNILATERAL" },
  { code: "79005", shortdesc: "RP THERAPY ORAL ADMINISTRATION" },
  { code: "81512", shortdesc: "FETAL CONGENITAL ABNOR ASSAY FIVE ANAL" },
  { code: "42300", shortdesc: "DRAINAGE ABSCESS PAROTID SIMPLE" },
  { code: "42700", shortdesc: "I&D ABSCESS PERITONSILLAR" },
  {
    code: "88314",
    shortdesc: "SPECIAL STAIN I&R HISTOCHEMICAL W/FROZEN TISSU",
  },
  {
    code: "64653",
    shortdesc: "CHEMODENERVATION ECCRINE GLANDS OTH AREA PER DAY",
  },
  {
    code: "27132",
    shortdesc: "CONV PREV HIP TOT HIP ARTHRP W/WO AGRFT/ALGRFT",
  },
  { code: "11720", shortdesc: "DEBRIDEMENT NAIL ANY METHOD 1-5" },
  {
    code: "47544",
    shortdesc: "REMOVAL BILIARY DUCT &/GLBLDR CALCULI PERQ RS&I",
  },
  {
    code: "3470F",
    shortdesc: "RHEUMATOID ARTHRITIS (RA) DISEASE ACTIVITY LOW",
  },
  { code: "27810", shortdesc: "CLOSED TX BIMALLEOLAR ANKLE FRACTURE W/MANJ" },
  { code: "95881", shortdesc: "null" },
  { code: "87279", shortdesc: "IAADI PARAINFLUENZA VIRUS EACH TYPE" },
  { code: "36500", shortdesc: "VEN CATHJ SLCTV ORGAN BLD SAMPLING" },
  { code: "75880", shortdesc: "VENOGRAPHY ORBITAL RS&I" },
  {
    code: "95782",
    shortdesc: "POLYSOM <6 YRS SLEEP STAGE 4/> ADDL PARAM ATTND",
  },
  { code: "28124", shortdesc: "PARTICAL EXCISION BONE PHALANX TOE" },
  {
    code: "15922",
    shortdesc: "EXC COCCYGEAL PR ULC W/COCCYGECTOMY W/FLAP CLSR",
  },
  { code: "84704", shortdesc: "GONADOTROPIN CHORIONIC HCG FREE BETA CHAIN" },
  { code: "0018T", shortdesc: "null" },
  {
    code: "78350",
    shortdesc: "BONE DENSITY 1/> SITES 1 PHOTON ABSORPTIOMETRY",
  },
  { code: "80174", shortdesc: "DRUG SCREEN QUALITATIVE IMIPRAMINE" },
  {
    code: "85009",
    shortdesc: "BLOOD COUNT MANUAL DIFRNTL WBC COUNT BUFFY COAT",
  },
  { code: "53500", shortdesc: "URETHROLSS TRVG SEC OPN W/CSTO" },
  { code: "59010", shortdesc: "null" },
  { code: "83668", shortdesc: "null" },
  { code: "33200", shortdesc: "null" },
  { code: "15860", shortdesc: "IV INJECTION TEST VASCULAR FLOW FLAP/GRAFT" },
  { code: "88325", shortdesc: "CONSLTJ COMPRE REVIEW REPRT REFERRED MATRL" },
  { code: "85366", shortdesc: "FIBRIN DGRADJ SPLT PRODUXS PARACOAGJ" },
  { code: "77778", shortdesc: "INTERSTITIAL RADIATION SOURCE APPLIC COMPLEX" },
  { code: "27888", shortdesc: "AMP ANKLE-MALLI TIBFIB W/PLSTC CLSR&RESCJ NRV" },
  { code: "27424", shortdesc: "RCNSTJ DISLC PATELLA W/PATELLECTOMY" },
  { code: "72133", shortdesc: "CT LUMBAR SPINE W/O & W/CONTRAST MATERIAL" },
  { code: "95052", shortdesc: "PHOTO PATCH TEST SPECIFY NUMBER TSTS" },
  { code: "28001", shortdesc: "INCISION&DRAINAGE BURSA FOOT" },
  {
    code: "26341",
    shortdesc: "MANIPLATN PALAR FASCIAL CRD POST INJ SINGLE CORD",
  },
  { code: "87652", shortdesc: "IADNA STREPTOCOCCUS GROUP A QUANTIFICATION" },
  { code: "78580", shortdesc: "PULMONARY PERFUSION IMAGING PARTICULATE" },
  {
    code: "80362",
    shortdesc: "DRUG SCREENING OPIOIDS AND OPIATE ANALOGS 1 OR 2",
  },
  { code: "90995", shortdesc: "null" },
  { code: "88272", shortdesc: "MOLECULAR CYTOGENETICS CHRMOML ISH 3-5 CELLS" },
  { code: "0116U", shortdesc: "null" },
  { code: "70450", shortdesc: "CT HEAD/BRAIN W/O CONTRAST MATERIAL" },
  { code: "56100", shortdesc: "null" },
  {
    code: "96523",
    shortdesc: "IRRIGAJ IMPLNTD VENOUS ACCESS DRUG DELIVERY SYST",
  },
  { code: "92559", shortdesc: "AUDIOMETRIC TESTING GROUPS" },
  { code: "26499", shortdesc: "CORRECTION CLAW FINGER OTHER METHODS" },
  { code: "0502T", shortdesc: "COR FFR DERIVED CTA DATA PREP & TRANSMIS" },
  { code: "68810", shortdesc: "PROBE NASOLACRIMAL DUCT W/WO IRRIGATION" },
  { code: "41114", shortdesc: "EXC LESION TONGUE W/CLSR W/LOCAL TONGUE FLAP" },
  {
    code: "21480",
    shortdesc: "CLOSED TX TEMPOROMANDIBULAR DISLOCATION 1ST/SBSQ",
  },
  { code: "29010", shortdesc: "APPLICATION RISSER JACKET LOCALIZER BODY ONLY" },
  { code: "33246", shortdesc: "null" },
  { code: "53210", shortdesc: "URETHRECTOMY TOT W/CYSTOST FEMALE" },
  { code: "84600", shortdesc: "ASSAY OF VOLATILES" },
  { code: "23570", shortdesc: "CLOSED TX SCAPULAR FRACTURE W/O MANIPULATION" },
  { code: "0505F", shortdesc: "HEMODIALYSIS PLAN OF CARE DOCUMENTED" },
  {
    code: "10121",
    shortdesc: "INCISION & REMOVAL FOREIGN BODY SUBQ TISS COMPL",
  },
  {
    code: "20974",
    shortdesc: "ELECTRICAL STIMULATION BONE HEALING NONINVASIVE",
  },
  {
    code: "99144",
    shortdesc: "MODERATE SEDATION PHYS PERF DX/THER SVC 5/> YRS",
  },
  {
    code: "81246",
    shortdesc: "FLT3 GENE ANLYS TYROSINE KINASE DOMAIN VARIANTS",
  },
  { code: "12013", shortdesc: "SIMPLE REPAIR F/E/E/N/L/M 2.6CM-5.0 CM" },
  { code: "90690", shortdesc: "TYPHOID VACCINE LIVE ORAL" },
  {
    code: "62280",
    shortdesc: "INJX/INFUSION NEUROLYTIC SUBSTANCE SUBARACHNOID",
  },
  {
    code: "11443",
    shortdesc: "EXC B9 LES MRGN XCP SK TG F/E/E/N/L/M 2.1-3.0CM",
  },
  { code: "88239", shortdesc: "TISS CUL NEO DISORDERS SOLID TUMOR" },
  { code: "26498", shortdesc: "TR TDN RESTORE INTRNSC FUNCJ ALL 4 FNGRS" },
  { code: "3271F", shortdesc: "LOW RISK OF RECURRENCE PROSTATE CANCER" },
  { code: "81316", shortdesc: "PML/RARALPHA SINGLE BREAKPOINT QUAL/QUAN" },
  { code: "81220", shortdesc: "CFTR GENE ANALYSIS COMMON VARIANTS" },
  { code: "90713", shortdesc: "POLIOVIRUS VACCINE INACTIVATED SUBQ/IM" },
  { code: "78232", shortdesc: "SALIVARY GLAND FUNCTION STUDY" },
  { code: "86309", shortdesc: "HETEROPHILE ANTIBODIES TITER" },
  {
    code: "58662",
    shortdesc: "LAPS FULG/EXC OVARY VISCERA/PERITONEAL SURFACE",
  },
  {
    code: "32480",
    shortdesc: "RMVL LUNG OTHER THAN PNEUMONECTOMY 1 LOBE LOBECT",
  },
  { code: "90719", shortdesc: "DIPHTHERIA TOXOID INTRAMUSCULAR" },
  { code: "81330", shortdesc: "SMPD1 GENE ANALYSIS COMMON VARIANTS" },
  {
    code: "33732",
    shortdesc: "RPR COR TRIATM/SUPVALVR RING RESCJ L ATRIAL MEMB",
  },
  { code: "1220F", shortdesc: "PATIENT SCREENED DEPRESSION" },
  {
    code: "93281",
    shortdesc: "PROGRAM EVAL IMPLANTABLE IN PRSN MULTI LD PACER",
  },
  {
    code: "33977",
    shortdesc: "REMOVAL VENTR ASSIST DEVICE XTRCORP 1 VENTRICLE",
  },
  { code: "84150", shortdesc: "ASSAY OF PROSTAGLNDIN EACH" },
  { code: "39561", shortdesc: "RESCJ DIAPHRAGM W/COMPLEX REPAIR" },
  { code: "50555", shortdesc: "RENAL NDSC NEPHROS/PYELOSTOMY BIOPSY" },
  { code: "33802", shortdesc: "DIVISION ABERRANT VESSEL VASCULAR RING" },
  { code: "23550", shortdesc: "OPEN TX ACROMIOCLAVICULAR DISLC ACUTE/CHRONIC" },
  { code: "0162T", shortdesc: "null" },
  { code: "76516", shortdesc: "OPHTHALMIC BIOMETRY US ECHOGRAPY A-SCAN" },
  { code: "35526", shortdesc: "BYPASS W/VEIN AORTOSUBCLAV/CAROTID/INNOMINATE" },
  { code: "86592", shortdesc: "SYPHILIS TEST NON-TREPONEMAL ANTIBODY QUAL" },
  {
    code: "78725",
    shortdesc: "KIDNEY FUNCJ STUDY NON-IMG RADIOISOTOPIC STUDY",
  },
  { code: "90676", shortdesc: "RABIES VACCINE INTRADERMAL" },
  { code: "31554", shortdesc: "LARYNGOPLASTY LARYNGEAL STEN W/STENT 12 YRS >" },
  {
    code: "21401",
    shortdesc: "CLOSED TX FX ORBIT EXCEPT BLOWOUT W/MANIPULATION",
  },
  {
    code: "36625",
    shortdesc: "ARTL CATHJ/CANNULJ MNTR/TRANSFUSION SPX CUTDOWN",
  },
  { code: "0366T", shortdesc: "GROUP BEHAVIOR TREATMENT FIRST 30 MIN" },
  { code: "54065", shortdesc: "DSTRJ LESION PENIS EXTENSIVE" },
  {
    code: "76010",
    shortdesc: "RADEX FROM NOSE RECTUM FOREIGN BODY 1 VIEW CHLD",
  },
  {
    code: "35633",
    shortdesc: "BYPASS GRAFT W/OTHER THAN VEIN ILIO-MESENTERIC",
  },
  { code: "0193T", shortdesc: "null" },
  { code: "69310", shortdesc: "RECONSTRUCTION EXTERNAL AUDITORY CANAL SPX" },
  {
    code: "54401",
    shortdesc: "INSJ PENILE PROSTHESOS INFLATABLE SELF-CONTAINED",
  },
  {
    code: "74712",
    shortdesc: "FETAL MRI W/PLACNTL MATRNL PLVC IMG SING/1ST GES",
  },
  {
    code: "52235",
    shortdesc: "CYSTOURETHROSCOPY W/DEST &/RMVL MED BLADDER TUM",
  },
  { code: "00904", shortdesc: "ANESTHESIA RADICAL PERINEAL PROCEDURE" },
  {
    code: "49465",
    shortdesc: "CONTRAST INJECTION PERQ RADIOLOGIC EVAL GI TUBE",
  },
  {
    code: "33984",
    shortdesc: "ECMO/ECLS RMVL PRPH CANNULA OPEN 6 YRS & OLDER",
  },
  { code: "78223", shortdesc: "HEPATBL DUX SYS IMG GLBLDR" },
  {
    code: "1110F",
    shortdesc: "PT DISCHARGE INPT FACILITY WITHIN LAST 60 DAYS",
  },
  { code: "53854", shortdesc: "null" },
  { code: "97504", shortdesc: "null" },
  {
    code: "0228T",
    shortdesc: "NJX ANES/STEROID TFRML EDRL W/US CER/THOR 1 LVL",
  },
  { code: "0067U", shortdesc: "null" },
  { code: "47802", shortdesc: "U-TUBE HEPATICOENTEROSTOMY" },
  {
    code: "26861",
    shortdesc: "ARTHRODESIS IPHAL JT W/WO INT FIXJ EA IPHAL JT",
  },
  {
    code: "53445",
    shortdesc: "INSJ INFLATABLE URETHRAL/BLADDER NECK SPHINCTER",
  },
  { code: "27060", shortdesc: "EXCISION ISCHIAL BURSA" },
  {
    code: "29882",
    shortdesc: "ARTHROSCOPY KNEE W/MENISCUS RPR MEDIAL/LATERAL",
  },
  {
    code: "27692",
    shortdesc: "TR/TRNSPL 1 TDN W/MUSC REDIRION/REROUTING EA TDN",
  },
  { code: "0003T", shortdesc: "null" },
  { code: "27246", shortdesc: "CLTX GREATER TROCHANTERIC FX W/O MANJ" },
  { code: "21355", shortdesc: "PERCUTANEOUS TX MALAR AREA FRACTURE" },
  { code: "85055", shortdesc: "RETICULATED PLATELET ASSAY" },
  { code: "15401", shortdesc: "XENOGRAFT TEMP CLOSURE TRUNK/ARM/LEG EA 100CM" },
  { code: "88332", shortdesc: "PATH CONSLTJ SURG EA ADDL BLK FROZEN SECTION" },
  { code: "64732", shortdesc: "TRANSECTION/AVULSION SUPRAORBITAL NERVE" },
  { code: "0063U", shortdesc: "null" },
  { code: "99224", shortdesc: "SBSQ OBSERVATION CARE/DAY 15 MINUTES" },
  {
    code: "27228",
    shortdesc: "OPTX ACTBLR FX INVG ANT&POST 2 COLUMNS FX W/INT",
  },
  { code: "64590", shortdesc: "INSERTION/RPLCMT PERIPHERAL/GASTRIC NPGR" },
  { code: "83950", shortdesc: "ONCOPROTEIN HER-2/NEU" },
  { code: "3023F", shortdesc: "SPIROMETRY RESULTS DOCUMENTED AND REVIEWED" },
  { code: "64421", shortdesc: "MULTIPLE NERVE BLOCK INJECTIONS RIB NERVES" },
  { code: "57061", shortdesc: "DESTRUCTION VAGINAL LESIONS SIMPLE" },
  {
    code: "26841",
    shortdesc: "ARTHRD CARPO/METACARPAL JT THUMB W/WO INT FIXJ",
  },
  { code: "29715", shortdesc: "REMOVAL/BIVALVING TURNBUCKLE JACKET" },
  { code: "0084U", shortdesc: "null" },
  {
    code: "93532",
    shortdesc: "CMBN R HRT T-SEPTAL L HRT CATHJ NTC SEPTUM CGEN",
  },
  { code: "61710", shortdesc: "ARYSM VASC MALFRMJ IA EMBOLIZATION" },
  { code: "36410", shortdesc: "VNPNXR 3 YEARS/> PHYS/QHP SKILL" },
  { code: "75805", shortdesc: "LYMPHANGIOGRAPHY PELVIC/ABDOMINAL UNILAT RS&I" },
  {
    code: "24101",
    shortdesc: "ARTHRT ELBOW W/JNT EXPL W/WOBX W/WORMVL LOOSE/FB",
  },
  { code: "19301", shortdesc: "MASTECTOMY PARTIAL" },
  {
    code: "52250",
    shortdesc: "CYSTOURETHROSCOPY INSJ RADIOACT SBST W/WOBX/FULG",
  },
  { code: "44145", shortdesc: "COLECTOMY PRTL W/COLOPROCTOSTOMY" },
  { code: "66825", shortdesc: "REPOSITIONING IO LENS PROSTHESIS REQ INC SPX" },
  { code: "84233", shortdesc: "ASSAY OF RECEPTOR ASSAY ESTROGEN" },
  { code: "26479", shortdesc: "SHORTENING TENDON FLEXOR HAND/FINGER EACH" },
  {
    code: "22853",
    shortdesc: "INSJ BIOMCHN DEV INTERVERTEBRAL DSC SPC W/ARTHRD",
  },
  {
    code: "63055",
    shortdesc: "TRANSPEDICULAR DCMPRN SPINAL CORD 1 SEG THORACIC",
  },
  {
    code: "35570",
    shortdesc: "BYP TIBL-TIBL/PRONEAL-TIBL/TIBL/PRONEAL TRK-TIBL",
  },
  { code: "25820", shortdesc: "ARTHRODESIS WRIST LIMITED W/O BONE GRAFT" },
  { code: "67320", shortdesc: "TRANSPOSITION PROCEDURE EXTRAOCULAR MUSC" },
  {
    code: "68371",
    shortdesc: "HARVESTING CONJUNCIVAL ALLOGRAPHY LIVING DONOR",
  },
  { code: "38999", shortdesc: "UNLISTED PROCEDURE HEMIC OR LYMPHATIC SYSTEM" },
  { code: "83727", shortdesc: "LUTEINIZING RELEASING FACTOR" },
  { code: "0100U", shortdesc: "null" },
  { code: "92392", shortdesc: "null" },
  { code: "81519", shortdesc: "ONCOLOGY BREAST MRNA GENE EXPRESSION 21 GENES" },
  { code: "90663", shortdesc: "null" },
  { code: "86704", shortdesc: "HEPATITIS B CORE ANTIBODY HBCAB TOTAL" },
  { code: "0111T", shortdesc: "LONG-CHAIN OMEGA-3 FATTY ACIDS RBC MEMBS" },
  { code: "83698", shortdesc: "LIPOPROTEIN-ASSOCIATED PHOSPHOLIPASE A2" },
  { code: "81165", shortdesc: "null" },
  { code: "19298", shortdesc: "PLMT RADTHX BRACHYTX BRST FOLLOWING PRTL MAST" },
  {
    code: "99360",
    shortdesc: "PHYS STANDBY SVC PROLNG PHYS ATTN EA 30 MINUTES",
  },
  { code: "43360", shortdesc: "GI RCNSTJ PREV ESPHG/EXCLUSION W/STOMACH" },
  { code: "59000", shortdesc: "AMNIOCENTESIS DIAGNOSIC" },
  {
    code: "15732",
    shortdesc: "MUSC MYOCUTANEOUS/FASCIOCUTANEOUS FLAP HEAD&NCK",
  },
  {
    code: "83013",
    shortdesc: "HPYLORI BREATH ANAL UREASE ACT NON-RADACT ISTOPE",
  },
  { code: "28406", shortdesc: "PRQ SKELETAL FIXJ CALCANEAL FRACTURE W/MANJ" },
  { code: "0557T", shortdesc: "null" },
  {
    code: "41019",
    shortdesc: "PLACEMENT NEEDLE HEAD/NECK RADIOELEMENT APPLICAT",
  },
  {
    code: "93788",
    shortdesc: "AMBL BLD PRESS W/TAPE/DISK 24/>HR ALYS W/REPRT",
  },
  {
    code: "76831",
    shortdesc: "SALINE INFUS SONOHYSTEROGRAPHY W/COLOR DOPPLER",
  },
  { code: "82024", shortdesc: "ADRENOCORTICOTROPIC HORMONE ACTH" },
  { code: "78205", shortdesc: "LIVER IMAGING SPECT" },
  { code: "93572", shortdesc: "IV DOP VEL&/OR PRESS C/FLO RSRV MEAS ADDL VSL" },
  { code: "41110", shortdesc: "EXCISION LESION TONGUE W/O CLOSURE" },
  { code: "27331", shortdesc: "ARTHRT KNE W/JT EXPL BX/RMVL LOOSE/FB" },
  { code: "70496", shortdesc: "CT ANGIOGRAPHY HEAD W/CONTRAST/NONCONTRAST" },
  {
    code: "11101",
    shortdesc: "BIOPSY SKIN SUBQ&/MUCOUS MEMBRANE EA ADDL LESN",
  },
  { code: "69979", shortdesc: "UNLISTED PROCEDURE TEMPORAL BONE MIDDLE FOSSA" },
  {
    code: "99401",
    shortdesc: "PREVENT MED COUNSEL&/RISK FACTOR REDJ SPX 15 MIN",
  },
  { code: "69554", shortdesc: "EXCISION AURAL GLOMUS TUMOR EXTENDED" },
  {
    code: "35682",
    shortdesc: "BYP AUTOG COMPOSIT 2 SEG VEINS FROM 2 LOCATIONS",
  },
  {
    code: "0476T",
    shortdesc: "REC FTL CAR SGL PT REC SCAN W/RAW ELEC TR DATA",
  },
  { code: "88130", shortdesc: "SEX CHROMATIN IDENTIFICATION BARR BODIES" },
  { code: "38242", shortdesc: "ALLOGENEIC LYMPHOCYTE INFUSIONS" },
  { code: "92975", shortdesc: "THROMBOLYSIS INTRACORONARY NFS SLCTV ANGRPH" },
  { code: "84484", shortdesc: "ASSAY OF TROPONIN QUANTITATIVE" },
  {
    code: "33257",
    shortdesc: "ATRIA ABLATE & RCNSTJ W/OTHER PROCEDURE LIMITE",
  },
  { code: "80010", shortdesc: "null" },
  {
    code: "47142",
    shortdesc: "DONOR HEPATECTOMY LIVING DONOR SEG V VI VII &VI",
  },
  { code: "57135", shortdesc: "EXCISION VAGINAL CYST/TUMOR" },
  { code: "25151", shortdesc: "PARTIAL EXCISION BONE RADIUS" },
  { code: "36537", shortdesc: "null" },
  { code: "86060", shortdesc: "ANTISTREPTOLYSIN O TITER" },
  { code: "82668", shortdesc: "ASSAY OF ERYTHROPOIETIN" },
  {
    code: "21450",
    shortdesc: "CLOSED TX MANDIBULAR FRACTURE W/O MANIPULATION",
  },
  {
    code: "43279",
    shortdesc: "LAPS ESOPHAGOMYOTOMY W/FUNDOPLASTY IF PERFORMED",
  },
  { code: "0106U", shortdesc: "null" },
  { code: "33251", shortdesc: "ABLATION ARRHYTHMOGENIC FOCI/PATHWAY W/BYPASS" },
  { code: "64615", shortdesc: "CHEMODERVATE FACIAL/TRIGEM/CERV MUSC MIGRAINE" },
  { code: "93232", shortdesc: "null" },
  { code: "95010", shortdesc: "PERQ TSTS SEQL&INCRL RX/BIOLOGIC/VNM IMMT RXN" },
  { code: "75650", shortdesc: "ANGIOGRAPHY CERVICOCEREBRAL CATHETER RS&I" },
  {
    code: "65285",
    shortdesc: "RPR LAC CORN&/SCLRA PERF W/REPOS/RESCJ UVEAL T",
  },
  { code: "92578", shortdesc: "null" },
  { code: "90692", shortdesc: "TYPHOID VACC H-P INACTIVATED SUBQ/INTRADERMAL" },
  { code: "80375", shortdesc: "DRUG/SUBSTANCE DEFINITIVE QUAL/QUANT NOS 1-3" },
  { code: "34471", shortdesc: "THRMBC DIR/W/CATH SUBCLAVIAN VEIN NECK INC" },
  { code: "33983", shortdesc: "RPLCMT VAD PMP IMPLTBL ICORP 1 VNTR W/BYPASS" },
  {
    code: "26372",
    shortdesc: "RPR/ADVMNT TDN W/NTC SUPFCIS TDN W/FREE GRAFT EA",
  },
  { code: "43425", shortdesc: "CLSR ESOPHAGOSTOMY/FSTL TTHRC/TABDL APPR" },
  { code: "33322", shortdesc: "SUTURE REPAIR AORTA/GREAT VESSEL W/BYPASS" },
  { code: "85461", shortdesc: "HGB/RBCS FETAL FETOMATERNAL HEMRRG ROSETTE" },
  { code: "84431", shortdesc: "THROMBOXANE METABOLITE W/WO THROMBOXANE URINE" },
  {
    code: "0107T",
    shortdesc: "QUANT SENSORY TEST&INTERPJ/XTR W/VIBRJ STIMULI",
  },
  { code: "63655", shortdesc: "LAM IMPLTJ NSTIM ELTRDS PLATE/PADDLE EDRL" },
  { code: "54348", shortdesc: "RPR HYPOSPADIAS COMPLCTJS DSJ & URTP FLAP/GRF" },
  { code: "94657", shortdesc: "null" },
  { code: "0497T", shortdesc: "XTRNL PT ACT ECG W/O ATTN MNTR IN-OFFICE CONN" },
  { code: "85250", shortdesc: "CLOTTING FACTOR IX PTC/CHRISTMAS" },
  { code: "83873", shortdesc: "MYELIN BASIC PROTEIN CEREBROSPINAL FLUID" },
  {
    code: "67334",
    shortdesc: "STRABISMUS POST FIXJ SUTR TQ W/WO MUSC RECESSION",
  },
  { code: "52640", shortdesc: "TRURL RESCJ POSTOP BLADDER NECK CONTRACTURE" },
  { code: "0326T", shortdesc: "null" },
  { code: "12051", shortdesc: "REPAIR INTERMEDIATE F/E/E/N/L&/MUC 2.5 CM/<" },
  { code: "32151", shortdesc: "THORCOM W/RMVL IPUL FB" },
  {
    code: "0437T",
    shortdesc: "IMPLTJ NONBIOL/SYNTH IMPLT FASC RNFCMT ABDL WALL",
  },
  {
    code: "92605",
    shortdesc: "EVAL RX N-SP-GEN AUGMT ALT COMMUN DEV F2F 1ST HR",
  },
  { code: "29505", shortdesc: "APPLICATION LONG LEG SPLINT THIGH ANKLE/TOES" },
  {
    code: "94640",
    shortdesc: "PRESSURIZED/NONPRESSURIZED INHALATION TREATMENT",
  },
  { code: "50400", shortdesc: "PYELOPLASTY SIMPLE" },
  {
    code: "53447",
    shortdesc: "RMVL & RPLCMT NFLTL URETHRAL/BLADDER NECK SPHINC",
  },
  {
    code: "43253",
    shortdesc: "EGD US GUIDED TRANSMURAL INJXN/FIDUCIAL MARKER",
  },
  {
    code: "86805",
    shortdesc: "LYMPHOCYTOTOXICITY ASSAY VIS CROSSMATCH TITRATJ",
  },
  {
    code: "49452",
    shortdesc: "REPLACEMENT GASTRO-JEJUNOSTOMY TUBE PERCUTANEOUS",
  },
  {
    code: "95973",
    shortdesc: "ELEC ALYS NSTIM PLS GEN CPLX SC/PERPH EA 30 MIN",
  },
  { code: "42235", shortdesc: "REPAIR ANTERIOR PALATE W/VOMER FLAP" },
  { code: "49585", shortdesc: "RPR UMBILICAL HRNA 5 YRS/> REDUCIBLE" },
  {
    code: "28112",
    shortdesc: "OSTECTOMY COMPLETE OTHER METATARSAL HEAD 2/3/4",
  },
  { code: "26100", shortdesc: "ARTHROTOMY BIOPSY CARP/MTCRPL JOINT EACH" },
  { code: "96153", shortdesc: "HLTH&BEHAVIOR IVNTJ EA 15 MIN GRP 2/>PTS" },
  { code: "69646", shortdesc: "TYMPANOPLASTY MASTOIDECTOMY RAD/COMPL W/OCR" },
  { code: "51784", shortdesc: "EMG STDS ANAL/URTL SPHNCTR OTH/THN NDL" },
  {
    code: "21700",
    shortdesc: "DIVISION SCALENUS ANTICUS W/O RESCJ CERVICAL RIB",
  },
  { code: "89329", shortdesc: "SPERM EVALUATION HAMSTER PENETRATION TEST" },
  { code: "21150", shortdesc: "RCNSTJ MIDFACE LEFORT II ANTERIOR INTRUSION" },
  { code: "87533", shortdesc: "IADNA HERPES VIRUS-6 QUANTIFICATION" },
  { code: "43121", shortdesc: "PRTL ESOPHAGEC W/WO PROX GASTREC/PYLOROPLASTY" },
  { code: "35563", shortdesc: "BYPASS W/VEIN ILIOILIAC" },
  {
    code: "87153",
    shortdesc: "CULTYP NUCLEIC ACID SEQUENCING METH EA ISOLATE",
  },
  { code: "83858", shortdesc: "METHSUXIMIDE" },
  { code: "00844", shortdesc: "ANES IPER LOWER ABD W/LAPS ABDOMINOPRNL RESCJ" },
  { code: "84443", shortdesc: "ASSAY OF THYROID STIMULATING HORMONE TSH" },
  {
    code: "31573",
    shortdesc: "LARYNGOSCOPY FLEXIBLE THERAPEUTIC INJECTION UNI",
  },
  { code: "92982", shortdesc: "PRQ TRLUML CORONARY BALLOON ANGIOPLASTY 1 VSL" },
  { code: "78072", shortdesc: "PARATHYROID IMAGING W/TOMOGRAPHIC SPECT & CT" },
  {
    code: "44394",
    shortdesc: "COLONOSCOPY STOMA W/RMVL TUM POLYP/OTH LES SNARE",
  },
  {
    code: "33265",
    shortdesc: "NDSC ABLATION & RCNSTJ ATRIA LIMITED W/O BYPAS",
  },
  {
    code: "00218",
    shortdesc: "ANES INTRACRANIAL PROCEDURE IN SITTING POSITION",
  },
  {
    code: "93662",
    shortdesc: "INTRACARD ECHOCARD W/THER/DX IVNTJ INCL IMG S&I",
  },
  {
    code: "00640",
    shortdesc: "ANES MANIPULATE SPINE/CLSD CRV THORC/LUMBR SPINE",
  },
  { code: "0040T", shortdesc: "null" },
  {
    code: "33368",
    shortdesc: "REPLACE AORTIC VALVE W/BYP OPEN ART/VENOUS APRCH",
  },
  { code: "25620", shortdesc: "null" },
  { code: "59025", shortdesc: "FETAL NONSTRESS TEST" },
  { code: "69840", shortdesc: "REVISION FENESTRATION OPERATION" },
  {
    code: "43248",
    shortdesc: "EGD INSERT GUIDE WIRE DILATOR PASSAGE ESOPHAGUS",
  },
  { code: "35501", shortdesc: "BYPASS W/VEIN COMMON-IPSILATERAL CAROTID" },
  { code: "15350", shortdesc: "null" },
  { code: "15430", shortdesc: "ACELLULAR XENOGRAFT IMPLANT 1ST 100 CM/1 PCT" },
  {
    code: "94350",
    shortdesc: "DETER MALDISTRIBJ OF INSPIRED GAS N WSHOT CURVE",
  },
  {
    code: "3492F",
    shortdesc: "HISTORY OF NADIR CD4+ CELL COUNT <350 CELLS/MM3",
  },
  { code: "35548", shortdesc: "BYP W/VEIN AORTOILIOFEM UNI" },
  { code: "92270", shortdesc: "ELECTRO-OCULOGRAPY W/INTERPRETATION & REPORT" },
  { code: "95831", shortdesc: "MUSC TSTG MNL W/REPRT XTR EX HAND/TRNK" },
  { code: "70150", shortdesc: "RADEX FACIAL BONES COMPLETE MINIMUM 3 VIEWS" },
  {
    code: "86153",
    shortdesc: "CELL ENUMERATION IMMUNE SELECTJ & ID PHYS INTERP",
  },
  { code: "78306", shortdesc: "BONE &/JOINT IMAGING WHOLE BODY" },
  {
    code: "33270",
    shortdesc: "INS/RPLCMNT PERM SUBQ IMPLTBL DFB W/SUBQ ELTRD",
  },
  { code: "68760", shortdesc: "CLSR LACRIMAL PUNCTUM THERMOCAUT LIG/LASER" },
  { code: "54135", shortdesc: "AMPUTATION PENIS RADICAL W/LYMPH NODES" },
  { code: "41009", shortdesc: "INTRAORAL I&D TONGUE/FLOOR MASTICATOR SPACE" },
  { code: "28153", shortdesc: "RESECTION CONDYLE DISTAL END PHALANX EACH TOE" },
  { code: "26025", shortdesc: "DRAINAGE OF PALMAR BURSA SINGLE BURSA" },
  {
    code: "84311",
    shortdesc: "SPECTROPHOTOMETRY ANALYT NOT ELSEWHERE SPECIFIED",
  },
  { code: "72275", shortdesc: "EPIDUROGRAPY RS&I" },
  {
    code: "27280",
    shortdesc: "ARTHRODESIS SACROILIAC JOINT W/OBTAINING GRAFT",
  },
  { code: "0555F", shortdesc: "SYMPTOM MANAGEMENT PLAN OF CARE DOCUMENTED" },
  { code: "0490T", shortdesc: "AUTOL REGN CELL TX SCLDR MLT INJ 1/> HANDS" },
  { code: "64802", shortdesc: "SYMPATHECTOMY CERVICAL" },
  {
    code: "32668",
    shortdesc: "THORACOSCOPY W/DX WEDGE RESEXN ANATO LUNG RESEXN",
  },
  { code: "36490", shortdesc: "null" },
  { code: "51580", shortdesc: "CYSTECTOMY W/URETEROSIGMOIDOSTOMY W/NODES" },
  { code: "48152", shortdesc: "PNCRTECT WHIPPLE W/O PANCREATOJEJUNOSTOMY" },
  { code: "33928", shortdesc: "REMOVAL & RPLCMT TOTAL RPLCMT HEART SYS" },
  { code: "81110", shortdesc: "HPA-6 GENOTYPING GENE ANALYSIS COMMON VARIANT" },
  { code: "65750", shortdesc: "KERATOPLASTY PENETRAING APHAKIA" },
  { code: "15786", shortdesc: "ABRASION 1 LESION" },
  { code: "15850", shortdesc: "REMOVAL SUTURES UNDER ANESTHESIA SAME SURGEON" },
  {
    code: "87641",
    shortdesc: "IADNA S AUREUS METHICILLIN RESIST AMP PROBE TQ",
  },
  {
    code: "88172",
    shortdesc: "CYTP FINE NDL ASPIRATE IMMT CYTOHIST STD DX 1ST",
  },
  { code: "99226", shortdesc: "SBSQ OBSERVATION CARE/DAY 35 MINUTES" },
  {
    code: "26675",
    shortdesc: "CLTX CARPO/MTCRPL DISLC THUMB MANJ EA JT W/ANES",
  },
  { code: "82728", shortdesc: "ASSAY OF FERRITIN" },
  { code: "48020", shortdesc: "REMOVAL PANCREATIC CALCULUS" },
  { code: "27466", shortdesc: "OSTEOPLASTY FEMUR LENGTHENING" },
  {
    code: "29889",
    shortdesc: "ARTHRS AIDED PST CRUCIATE LIGM RPR/AGMNTJ/RCNSTJ",
  },
  {
    code: "61330",
    shortdesc: "DECOMPRESSION ORBIT ONLY TRANSCRANIAL APPROACH",
  },
  {
    code: "82042",
    shortdesc: "OTHER SOURCE ALBUMIN QUANTITATIVE EACH SPECIMEN",
  },
  { code: "4450F", shortdesc: "SELF-CARE EDUCATION PROVIDED TO PATIENT" },
  {
    code: "81297",
    shortdesc: "MSH2 GENE ANALYSIS DUPLICATION/DELETION VARIANTS",
  },
  { code: "25265", shortdesc: "RPR TDN/MUSC FLXR F/ARM&/WRISTSEC FR GRF EA" },
  { code: "36215", shortdesc: "SLCTV CATHJ EA 1ST ORD THRC/BRCH/CPHLC BRNCH" },
  { code: "34839", shortdesc: "PLNNING PT SPEC FENEST VISCERAL AORTIC GRAFT" },
  { code: "0012F", shortdesc: "COMMUNITY-ACQUIRED BACTERIAL PNEUMONIA ASSMT" },
  { code: "0012M", shortdesc: "null" },
  { code: "3056F", shortdesc: "LVEF GREATER THAN 35%" },
  {
    code: "67825",
    shortdesc: "CORRECTION TRICHIASIS EPILATION OTH/THAN FORCEPS",
  },
  { code: "50540", shortdesc: "SYMPHYSIOTOMY HORSESHOE KDN W/WO PLOP UNI/BI" },
  { code: "6150F", shortdesc: "PT NOT RCVNG 1ST COURSE OF ANTI-TNF THERAPY" },
  { code: "92571", shortdesc: "FILTERED SPEECH TEST" },
  { code: "42145", shortdesc: "PALATOPHARYNGOPLASTY" },
  { code: "81325", shortdesc: "PMP22 GENE ANALYSIS FULL SEQUENCE ANALYSIS" },
  {
    code: "27381",
    shortdesc: "SUTR INFRAPATELLAR TDN 2 RCNSTJ W/FSCAL/TDN GRF",
  },
  {
    code: "12007",
    shortdesc: "SIMPLE REPAIR SCALP/NECK/AX/GENIT/TRUNK >30.0CM",
  },
  { code: "63308", shortdesc: "VERTEBRAL CORPECTOMY EXC INDRL LES EACH SEG" },
  {
    code: "92620",
    shortdesc: "EVAL CENTRAL AUDITORY FUNCJ W/REPRT 1ST 60 MIN",
  },
  { code: "87505", shortdesc: "NFCT AGENT DNA/RNA GASTROINTESTINAL PATHOGEN" },
  {
    code: "61556",
    shortdesc: "CRANIEC CRANIOSYNOSTOSIS FRONT/PARIET BONE FLAP",
  },
  { code: "64760", shortdesc: "TRANSECTION/AVULSION VAGUS NERVE ABDOMINAL" },
  { code: "46045", shortdesc: "I&D INTRAMURAL IM/ABSC TRANSANAL ANES" },
  {
    code: "99328",
    shortdesc: "DOM/R-HOME E/M NEW PT SIGNIF NEW PROB 75 MINUTES",
  },
  { code: "24356", shortdesc: "null" },
  { code: "49060", shortdesc: "DRAINAGE OF RETROPERITONEAL ABSCESS OPEN" },
  { code: "93561", shortdesc: "INDIC DIL STD ARTL&/OR VEN CATHJ W/OUTP MEAS" },
  {
    code: "36252",
    shortdesc: "SLCTV CATH 1STORD W/WO ART PUNCT/FLUOR/S&I BIL",
  },
  { code: "50660", shortdesc: "URETERECTOMY TOT ECTOPIC URETER CMBN APPR" },
  { code: "82642", shortdesc: "null" },
  {
    code: "87150",
    shortdesc: "CULTYP NUC ACID AMP PRB CULT/ISOLATE EA ORGNISM",
  },
  { code: "3321F", shortdesc: "AJCC CANCER STAGE 0 OR IA MELANOMA" },
  { code: "22630", shortdesc: "ARTHRODESIS POSTERIOR INTERBODY LUMBAR" },
  { code: "51960", shortdesc: "ENTEROCYSTOPLASTY W/INTESTINAL ANASTOMOSIS" },
  {
    code: "0215T",
    shortdesc: "NJX PARAVERTBRL FACET JT W/US CER/THOR 3RD&> LVL",
  },
  { code: "56353", shortdesc: "null" },
  { code: "86757", shortdesc: "ANTIBODY RICKETTSIA" },
  { code: "3452F", shortdesc: "DYSPNEA NOT SCREENED" },
  { code: "33285", shortdesc: "null" },
  { code: "37209", shortdesc: "EXCHNG PREV PLACED IV CATH THROMBOLYTIC THER" },
  { code: "25525", shortdesc: "OPEN RDL SHAFT FX CLOSED RAD/ULN JT DISLOCATE" },
  { code: "90849", shortdesc: "MULTIPLE FAMILY GROUP PSYCHOTHERAPY" },
  {
    code: "26863",
    shortdesc: "ARTHRODESIS IPHAL JT W/WO INT FIXJ W/AGRFT EA JT",
  },
  { code: "82373", shortdesc: "CARBOHYDRATE DEFICIENT TRANSFERRIN" },
  { code: "26030", shortdesc: "DRAINAGE OF PALMAR BURSA MULTIPLE BURSA" },
  {
    code: "95958",
    shortdesc: "WADA ACTIVATION TEST HEMISPHERIC FUNCTION W/EEG",
  },
  {
    code: "75980",
    shortdesc: "PRQ TRANSHEPATC BILIARY DRG W/CONTRAST MNTR RS&I",
  },
  { code: "25111", shortdesc: "EXCISION GANGLION WRIST DORSAL/VOLAR PRIMARY" },
  {
    code: "43330",
    shortdesc: "ESOPHAGOMYOTOMY HELLER TYPE ABDOMINAL APPROACH",
  },
  { code: "45379", shortdesc: "COLONOSCOPY FLX W/REMOVAL OF FOREIGN BODY(S)" },
  {
    code: "97763",
    shortdesc: "ORTHOTICS/PROSTH MGMT &/TRAINJ SBSQ ENCTR 15 MIN",
  },
  { code: "53080", shortdesc: "DRG PERINEAL URINARY XTRVASATION UNCOMP SPX" },
  {
    code: "28675",
    shortdesc: "OPEN TREATMENT INTERPHALANGEAL JOINT DISLOCATION",
  },
  { code: "86632", shortdesc: "ANTIBODY CHLAMYDIA IGM" },
  { code: "90920", shortdesc: "null" },
  { code: "43830", shortdesc: "GASTROSTOMY OPN W/O CONSTJ GSTR TUBE SPX" },
  { code: "86485", shortdesc: "SKIN TEST CANDIDA" },
  { code: "51720", shortdesc: "BLADDER INSTILLATION ANTICARCINOGENIC AGENT" },
  { code: "92990", shortdesc: "PRQ BALLOON VALVULOPLASTY PULMONARY VALVE" },
  { code: "43647", shortdesc: "LAPS IMPLTJ/RPLCMT GASTRIC NSTIM ELTRD ANTRUM" },
  {
    code: "95904",
    shortdesc: "NRV CNDJ AMPLITUDE & LATENCY EACH NERVE SENSORY",
  },
  { code: "97012", shortdesc: "APPL MODALITY 1/> AREAS TRACTION MECHANICAL" },
  {
    code: "9003F",
    shortdesc: "AORTIC ARYSM 5.5-5.9CM MAX DIAM CTRLN/AXIAL CT",
  },
  {
    code: "43755",
    shortdesc: "GASTRIC INTUBATION DX & ASPIRATJ MULTIPLE SPEC",
  },
  { code: "67500", shortdesc: "RETROBULBAR INJECTION MEDICATION SPX" },
  {
    code: "25370",
    shortdesc: "MLT OSTEOTOMIES W/RELIGNMT IMED ROD RADIUS/ULNA",
  },
  {
    code: "0276T",
    shortdesc: "BRONCHSCPY FLUORO W/BRONCHIAL THERMPLASTY 1 LOBE",
  },
  {
    code: "95027",
    shortdesc: "INTRACUTANEOUS TESTS W/ALLERGENIC XTRCS AIRBORNE",
  },
  {
    code: "74340",
    shortdesc: "INTRO LONG GI TUBE W/MULT FLUORO & IMAGES RS&I",
  },
  { code: "86813", shortdesc: "HLA TYPING A/B/C MULTIPLE ANTIGENS" },
  { code: "63078", shortdesc: "DISCECTOMY ANT DCMPRN CORD THORACIC EA NTRSPC" },
  {
    code: "81162",
    shortdesc: "BRCA1&BRCA2 FULL SEQ ANALYS/FULL DUP/DEL ANALYS",
  },
  { code: "74235", shortdesc: "RMVL FB ESOPHAGEAL W/USE BALLOON CATH RS&I" },
  {
    code: "99315",
    shortdesc: "NURSING FACILITY DISCHARGE MANAGEMENT 30 MINUTES",
  },
  {
    code: "11311",
    shortdesc: "SHVG SKIN LESION 1 F/E/E/N/L/M DIAM 0.6-1.0 CM",
  },
  { code: "88016", shortdesc: "NECROPSY GROSS EXAM MACERATED STILLBORN" },
  { code: "21344", shortdesc: "OPEN TX COMPLICATED FRONTAL SINUS FRACTURE" },
  { code: "87320", shortdesc: "IAAD IA CHLAMYDIA TRACHOMATIS" },
  {
    code: "36140",
    shortdesc: "INTRO OF NEEDLE OR INTRACATHETER UPR/LXTR ARTERY",
  },
  { code: "31830", shortdesc: "REVISION TRACHEOSTOMY SCAR" },
  { code: "1135F", shortdesc: "EPISODE BACK PAIN LASTING >SIX WEEKS" },
  { code: "64440", shortdesc: "null" },
  { code: "0015U", shortdesc: "RX METAB ADVRS RX RXN DNA 22 GENES BLD/BUCCAL" },
  { code: "81293", shortdesc: "MLH1 GENE ANALYSIS KNOWN FAMILIAL VARIANTS" },
  { code: "70190", shortdesc: "RADEX OPTIC FORAMINA" },
  { code: "27870", shortdesc: "ARTHRODESIS ANKLE OPEN" },
  { code: "25331", shortdesc: "null" },
  { code: "85660", shortdesc: "SICKLING RBC REDUCTION" },
  {
    code: "23473",
    shortdesc: "REVIS SHOULDER ARTHRPLSTY HUMERAL/GLENOID COMPNT",
  },
  { code: "90788", shortdesc: "null" },
  {
    code: "94662",
    shortdesc: "CONTINUOUS NEGATIVE PRESSURE VENTJ INITIAT&MGM",
  },
  { code: "85303", shortdesc: "CLOTTING INHIBITORS PROTEIN C ACTIVITY" },
  {
    code: "3266F",
    shortdesc: "HEPATITIS C GENOTYPE PRIOR ANTIVIRAL TREATMENT",
  },
  {
    code: "42425",
    shortdesc: "EXCISION PAROTID TUMOR/GLAND TOTAL EN BLOC RMVL",
  },
  {
    code: "93923",
    shortdesc: "NON-INVASIVE PHYSIOLOGIC STUDY EXTREMITY 3 LEVLS",
  },
  { code: "81431", shortdesc: "HEARING LOSS DUP/DEL ANALYSIS" },
  {
    code: "78699",
    shortdesc: "UNLISTED NERVOUS SYSTEM PX DX NUCLEAR MEDICINE",
  },
  { code: "80092", shortdesc: "null" },
  { code: "63075", shortdesc: "DISCECTOMY ANT DCMPRN CORD CERVICAL 1 NTRSPC" },
  {
    code: "84235",
    shortdesc: "RECEPTOR ASSAY ENDOCRINE OTH/THN ESTRGN/PROGST",
  },
  {
    code: "98943",
    shortdesc: "CHIROPRACTIC MANIPLTV TX EXTRASPINAL 1/> REGION",
  },
  { code: "28005", shortdesc: "INCISION BONE CORTEX FOOT" },
  {
    code: "29856",
    shortdesc: "ARTHRS AID TIBIAL FX PROX UNICONDYLAR BICONDYLAR",
  },
  { code: "92538", shortdesc: "CALORIC VESTIBULAR TEST W/REC BI MONOTHERMAL" },
  { code: "96100", shortdesc: "null" },
  { code: "90703", shortdesc: "TETANUS TOXOID ADSORBED INTRAMUSCULAR" },
  {
    code: "38747",
    shortdesc: "ABDL LMPHADEC REG CELIAC GSTR PORTAL PRIPNCRTC",
  },
  {
    code: "37241",
    shortdesc: "VASCULAR EMBOLIZATION OR OCCLUSION VENOUS RS&I",
  },
  { code: "3353F", shortdesc: "MILD TO MOD DEP SYMP BY STAND DEP ASSESS TOOL" },
  { code: "35541", shortdesc: "null" },
  {
    code: "87635",
    shortdesc:
      "Infectious agent detection by nucleic acid (DNA or RNA); severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2) (Coronavirus disease [COVID-19]), amplified probe technique",
  },
  {
    code: "93622",
    shortdesc: "COMPRE ELECTROPHYSIOL XM W/LEFT VENTR PACNG/REC",
  },
  {
    code: "55920",
    shortdesc: "PLACEMENT NEEDLE PELVIC ORGAN RADIOELEMENT APPL",
  },
  { code: "26437", shortdesc: "REALIGNMENT EXTENSOR TENDON HAND EACH TENDON" },
  { code: "80163", shortdesc: "DRUG SCREEN QUANTITATIVE DIGOXIN FREE" },
  { code: "49084", shortdesc: "PERITONEAL LAVAGE W/WO IMAGING GUIDANCE" },
  {
    code: "15274",
    shortdesc: "APP SKN SUB GRFT T/A/L AREA>/=100SCM ADL 100SQCM",
  },
  {
    code: "21180",
    shortdesc: "RCNSTJ FOREHEAD &/ SUPRAORBITAL RIMS W/AUTOGRAFT",
  },
  { code: "85041", shortdesc: "BLOOD COUNT RED BLOOD CELL AUTOMATED" },
  {
    code: "49204",
    shortdesc: "EXC/DESTRUCTION OPEN ABDMNL TUMORS 5.1-10.0 CM",
  },
  {
    code: "33223",
    shortdesc: "RELOCATE SKIN POCKET IMPLANTABLE DEFIBRILLATOR",
  },
  {
    code: "99383",
    shortdesc: "INITIAL PREVENTIVE MEDICINE NEW PT AGE 5-11 YRS",
  },
  { code: "0487T", shortdesc: "TRANSVAGINAL BIOMECHANICAL MAPPING W/REPORT" },
  {
    code: "36147",
    shortdesc: "INTRO NDL/CATH AV SHUNT IST ACCESS W/ RAD EVAL",
  },
  { code: "0317T", shortdesc: "ELEC ALYS NSTIM PLS GEN VAGUS NRV W/REPRGRMG" },
  {
    code: "33880",
    shortdesc: "EVASC RPR DTA COVERAGE ART ORIGIN 1ST ENDOPROSTH",
  },
  {
    code: "35506",
    shortdesc: "BYPASS W/VEIN CAROTID-SUBCLV/SUBCLAVIAN CAROTID",
  },
  { code: "45349", shortdesc: "SGMDSC FLX WITH ENDOSCOPIC MUCOSAL RESECTION" },
  {
    code: "92597",
    shortdesc: "EVAL&/FITG VOICE PROSTC DEV SUPLMNT ORAL SPEEC",
  },
  {
    code: "0213T",
    shortdesc: "NJX DX/THER PARAVER FCT JT W/US CER/THOR 1 LVL",
  },
  { code: "88157", shortdesc: "null" },
  { code: "27691", shortdesc: "TR/TRNSPL 1 TDN W/MUSC REDIRION/REROUTING DP" },
  { code: "43400", shortdesc: "LIGATION DIRECT ESOPHAGEAL VARICES" },
  { code: "0160T", shortdesc: "null" },
  {
    code: "94453",
    shortdesc: "HIGH ALTITUDE SIMULATJ W/PHYS I&R W/O2 TITRATION",
  },
  { code: "81284", shortdesc: "null" },
  {
    code: "64487",
    shortdesc: "TAP BLOCK UNILATERAL BY CONTINUOUS INFUSION(S)",
  },
  { code: "93307", shortdesc: "ECHO TRANSTHORAC R-T 2D W/WO M-MODE REC COMP" },
  { code: "52332", shortdesc: "CYSTO W/INSERT URETERAL STENT" },
  {
    code: "28122",
    shortdesc: "PRTL EXC B1 TARSAL/METAR B1 XCP TALUS/CALCANEUS",
  },
  {
    code: "34845",
    shortdesc: "VISCER AND INFRARENAL ABDOM AORTA 1 PROSTHESIS",
  },
  {
    code: "45346",
    shortdesc: "SIGMOIDOSCOPY FLX ABLATION TUMOR POLYP/OTH LES",
  },
  { code: "90727", shortdesc: "PLAGUE VACCINE INTRAMUSCULAR" },
  {
    code: "34843",
    shortdesc: "ENDOVASC VISCER AORTA REPAIR FENEST 3 ENDOGRAFT",
  },
  { code: "84134", shortdesc: "PREALBUMIN" },
  { code: "24151", shortdesc: "null" },
  {
    code: "36100",
    shortdesc: "INTRO NEEDLE/INTRACATH CAROTID/VERTEBRAL ARTERY",
  },
  {
    code: "0206T",
    shortdesc: "CPTR DBS ALYS MLT CYCLS CAR ELEC DTA 2/> ECG LDS",
  },
  { code: "34530", shortdesc: "SAPHENOPOPLITEAL VEIN ANASTOMOSIS" },
  {
    code: "0109T",
    shortdesc: "QUANT SENAORY TEST&INTERPJ/XTR W/HT-PN STIMULI",
  },
  {
    code: "2024F",
    shortdesc: "7 STANDARD FIELD STEREOSCOPIC PHOTOS W/INTERPJ",
  },
  {
    code: "89280",
    shortdesc: "ASSTD FERTILIZATION MICROTQ </EQUAL 10 OOCYTES",
  },
  {
    code: "11057",
    shortdesc: "PARING/CUTTING BENIGN HYPERKERATOTIC LESION >4",
  },
  { code: "66984", shortdesc: "CATARACT REMOVAL INSERTION OF LENS" },
  { code: "86835", shortdesc: "ANTIBODY HLA CLASS II SEMIQUANTITATIVE PANEL" },
  { code: "47765", shortdesc: "ANAST INTRAHEPATC DUCTS & GI TRACT" },
  { code: "43197", shortdesc: "ESOPHAGOSCOPY FLEXIBLE TRANSNASAL DIAGNOSTIC" },
  {
    code: "87389",
    shortdesc: "IAAD IA HIV-1 AG W/HIV-1 & HIV-2 ANTBDY SINGLE",
  },
  { code: "44407", shortdesc: "COLONOSCOPY STOMA W/US GID NDL ASPIR/BX" },
  { code: "67921", shortdesc: "REPAIR ENTROPION SUTURE" },
  { code: "37607", shortdesc: "LIG/BANDING ANGIOACCESS ARTERIOVENOUS FISTULA" },
  {
    code: "87451",
    shortdesc: "IAAD IA POLYV MLT ORGANISMS EA POLYV ANTISERUM",
  },
  { code: "76641", shortdesc: "US BREAST UNI REAL TIME WITH IMAGE COMPLETE" },
  { code: "0019U", shortdesc: "ONC RNA WHL TRANSCIPTOME SEQ TISS PREDCT ALG" },
  {
    code: "4553F",
    shortdesc: "PT OFFERED ASSISTANCE PLANNING END LIFE ISSUES",
  },
  { code: "87327", shortdesc: "IAAD IA CRYPTOCOCCUS NEOFORMANS" },
  { code: "4013F", shortdesc: "STATIN THERAPY RXD/CURRENTLY TAKEN" },
  { code: "46705", shortdesc: "ANOPLASTY PLASTIC OPERATION STRICTURE INFANT" },
  {
    code: "15920",
    shortdesc: "EXC COCCYGEAL PR ULC W/COCCYGECTOMY W/PRIM SUTR",
  },
  { code: "60512", shortdesc: "PARATHYROID AUTOTRANSPLANTATION ADD-ON" },
  { code: "93571", shortdesc: "IV DOP VEL&/OR PRESS C/FLO RSRV MEAS 1ST VSL" },
  { code: "54860", shortdesc: "EPIDIDYMECTOMY UNILATERAL" },
  {
    code: "71022",
    shortdesc: "RADEX CH 2 VIEWS FRONTAL&LATERAL OBLIQUE PRJCJ",
  },
  { code: "97703", shortdesc: "null" },
  { code: "50610", shortdesc: "URTROLITHOTOMY UPPER ONE-THIRD URETER" },
  { code: "42806", shortdesc: "BX NASOPHARYNX SURVEY UNKNOWN PRIMARY LESION" },
  { code: "82666", shortdesc: "EPIANDROSTERONE" },
  {
    code: "99409",
    shortdesc: "ALCOHOL/SUBSTANCE SCREEN & INTERVENTION >30 MIN",
  },
  { code: "84702", shortdesc: "GONADOTROPIN CHORIONIC QUANTITATIVE" },
  { code: "65430", shortdesc: "CORNEA SCRAPING DIAGNOSTIC SMEAR &/CULTURE" },
  {
    code: "26676",
    shortdesc: "PRQ SKEL FIXJ CARPO/MTCRPL DISLC THMB MANJ EA JT",
  },
  { code: "81298", shortdesc: "MSH6 GENE ANALYSIS FULL SEQUENCE ANALYSIS" },
  { code: "3045F", shortdesc: "MOST RECENT HEMOGLOBIN A1C LEVEL GT 7.0-9.0 %" },
  {
    code: "72090",
    shortdesc: "RADEX SPINE SCOLIOS STUDY W/SUPINE&ERECT STUDIES",
  },
  { code: "81406", shortdesc: "MOLECULAR PATHOLOGY PROCEDURE LEVEL 7" },
  { code: "29049", shortdesc: "APPLICATION CAST FIGURE-OF-8" },
  { code: "57620", shortdesc: "null" },
  { code: "3018F", shortdesc: "PRE-PRX RISK ASSESS DEPTH&QUAL BOWEL PREP" },
  { code: "21740", shortdesc: "REPAIR PECTUS EXCAVATUM/CARINATUM OPEN" },
  { code: "81272", shortdesc: "KIT GENE ANALYSIS TARGETED SEQUENCE ANALYSIS" },
  {
    code: "33263",
    shortdesc: "RMVL IMPLTBL DFB PLSE GEN W/RPLCMT PLSE GEN 2 LD",
  },
  { code: "73592", shortdesc: "RADEX LOWER EXTREMITY INFANT MINIMUM 2 VIEWS" },
  { code: "4115F", shortdesc: "BETA BLOCKER GIVEN W/IN 24 HRS PRIOR SURG INC" },
  {
    code: "20670",
    shortdesc: "REMOVAL IMPLANT SUPERFICIAL SEPARATE PROCEDURE",
  },
  { code: "11976", shortdesc: "REMOVAL IMPLANTABLE CONTRACEPTIVE CAPSULES" },
  { code: "87624", shortdesc: "IADNA HUMAN PAPILLOMAVIRUS HIGH-RISK TYPES" },
  { code: "37615", shortdesc: "LIGATION MAJOR ARTERY NECK" },
  { code: "15341", shortdesc: "TISS CLTR ALGC SKN EA 25 CM" },
  { code: "50020", shortdesc: "DRAINAGE PERIRENAL/RENAL ABSCESS OPEN" },
  { code: "20986", shortdesc: "null" },
  { code: "43198", shortdesc: "ESOPHAGOSCOPY FLEXIBLE TRANSNASAL WITH BIOPSY" },
  { code: "32215", shortdesc: "PLEURAL SCARIFICATION REPEAT PNEUMOTHORAX" },
  { code: "54340", shortdesc: "RPR HYPOSPADIAS COMPLCTJS CLSR INC/EXC SIMPLE" },
  { code: "33722", shortdesc: "CLOSURE AORTICO-LEFT VENTRICULAR TUNNEL" },
  {
    code: "49496",
    shortdesc: "RPR 1ST INGUN HRNA FULL TERM INFT <6 MO INCARCER",
  },
  { code: "25250", shortdesc: "REMOVAL WRIST PROSTHESIS SEPARATE PROCEDURE" },
  { code: "87211", shortdesc: "null" },
  {
    code: "33522",
    shortdesc: "CORONARY ARTERY BYP W/VEIN & ARTERY GRAFT 5 VEIN",
  },
  { code: "00532", shortdesc: "ANESTHESIA ACCESS CENTRAL VENOUS CIRCULATION" },
  {
    code: "11624",
    shortdesc: "EXCISION MALIGNANT LESION S/N/H/F/G 3.1-4.0 CM",
  },
  {
    code: "88274",
    shortdesc: "MOLECULAR CYTOGENETICS INTERPHASE ISH 25-99 CLL",
  },
  { code: "0042U", shortdesc: "null" },
  { code: "87799", shortdesc: "IADNA NOS QUANTIFICATION EACH ORGANISM" },
  {
    code: "43235",
    shortdesc: "ESOPHAGOGASTRODUODENOSCOPY TRANSORAL DIAGNOSTIC",
  },
  { code: "4269F", shortdesc: "APPROP METHOD OFFLOADING PRESCRIBED" },
  { code: "90782", shortdesc: "null" },
  { code: "44363", shortdesc: "ENTEROSCOPY > 2ND PRTN W/RMVL FOREIGN BODY" },
  { code: "10011", shortdesc: "null" },
  { code: "92547", shortdesc: "USE VERTICAL ELECTRODES" },
  { code: "63250", shortdesc: "LAM EXC/OCCLUSION AVM SPINAL CORD CERVICAL" },
  { code: "47147", shortdesc: "BKBENCH RCNSTJ LVR GRF ARTL ANAST EA" },
  { code: "26685", shortdesc: "OPEN TX CARPOMETACARPAL DISLOCATE NOT THUMB" },
  {
    code: "27232",
    shortdesc: "CLTX FEM FX PROX END NCK W/MANJ W/WO SKEL TRACJ",
  },
  { code: "33572", shortdesc: "CORONARY ENDARTERCOMY OPEN ANY METHOD" },
  { code: "28305", shortdesc: "OSTEOT TARSAL OTH/THN CALCANEUS/TALUS W/AGRFT" },
  { code: "0503F", shortdesc: "POSTPARTUM CARE VISIT" },
  {
    code: "0100T",
    shortdesc: "PLMT SCJNCL RTA PROSTH&PLS&IMPLTJ INTRA-OC RTA",
  },
  { code: "3083F", shortdesc: "KT/V EQUAL/>1.2 & <1.7" },
  { code: "93209", shortdesc: "null" },
  { code: "29065", shortdesc: "APPLICATION CAST SHOULDER HAND LONG ARM" },
  { code: "3073F", shortdesc: "DOCUMENTED LENGTH CORNEAL POWER & LENS POWER" },
  {
    code: "22905",
    shortdesc: "RAD RESECTION TUMOR SOFT TISSUE ABDL WALL 5 CM/>",
  },
  {
    code: "20822",
    shortdesc: "RPLJ DGT EXCLUDING THMB SUBLIMIS TDN COMPL AMP",
  },
  { code: "90465", shortdesc: "null" },
  { code: "64761", shortdesc: "TRANSECTION/AVULSION PUDENDAL NERVE" },
  { code: "74355", shortdesc: "PERCUTANEOUS PLACEMENT ENTEROCLYSIS TUBE RS&I" },
  {
    code: "29830",
    shortdesc: "ARTHROSCOPY ELBOW DIAG W/WO SYNOVIAL BIOPSY SPX",
  },
  {
    code: "11424",
    shortdesc: "EXC B9 LESION MRGN XCP SK TG S/N/H/F/G 3.1-4.0CM",
  },
  { code: "26420", shortdesc: "REPAIR EXTENSOR TENDON FINGER W/GRAFT EACH" },
  {
    code: "1505F",
    shortdesc: "PATIENT DOES NOT HAVE RESPIRATORY INSUFFICIENCY",
  },
  { code: "83150", shortdesc: "ASSAY OF HOMOVANILLIC ACID" },
  {
    code: "27486",
    shortdesc: "REVJ TOTAL KNEE ARTHRP W/WO ALGRFT 1 COMPONENT",
  },
  { code: "92593", shortdesc: "HEARING AID CHECK BINAURAL" },
  {
    code: "21320",
    shortdesc: "CLOSED TREATMENT NASAL FRACTURE W/STABILIZATION",
  },
  { code: "10022", shortdesc: "FINE NEEDLE ASPIRATION WITH IMAGING GUIDANCE" },
  { code: "43350", shortdesc: "ESOPHAGOSTOMY FSTLJ ESOPH XTRNL ABDL APPR" },
  {
    code: "46730",
    shortdesc: "RPR HI IMPRF ANUS W/O FSTL PRNL/SACROPRNL APPR",
  },
  { code: "85210", shortdesc: "CLOTTING FACTOR II PROTHROMBIN SPECIFIC" },
  { code: "32850", shortdesc: "DONOR PNEUMONECTOMY FROM CADAVER DONOR" },
  { code: "77328", shortdesc: "BRACHYTHERAPY ISODOSE PLAN COMPLEX" },
  { code: "91105", shortdesc: "null" },
  { code: "2040F", shortdesc: "PHYS EXAM ON DATE OF INIT VST FOR LBP DONE" },
  { code: "3117F", shortdesc: "HF DISEASE SPECIFIC ASSESSMENT TOOL COMPLETED" },
  { code: "95921", shortdesc: "TSTG ANS FUNCJ CARDIOVAGAL INNERVAJ PARASYMP" },
  { code: "90283", shortdesc: "IMMUNE GLOBULIN IGIV HUMAN IV USE" },
  { code: "29834", shortdesc: "ARTHROSCOPY ELBOW SURGICAL W/REMOVAL LOOSE/FB" },
  {
    code: "22847",
    shortdesc: "ANTERIOR INSTRUMENTATION 8/> VERTEBRAL SEGMENTS",
  },
  { code: "86645", shortdesc: "ANTIBODY CYTOMEGALOVIRUS CMV IGM" },
  { code: "67415", shortdesc: "FINE NEEDLE ASPIRATION ORBITAL CONTENTS" },
  { code: "78231", shortdesc: "SALIVARY GLAND IMAGING SERIAL IMAGES" },
  { code: "36406", shortdesc: "VNPNXR <3 YEARS PHYS/QHP SKILL OTHER VEIN" },
  {
    code: "96001",
    shortdesc: "COMPRE CPTR MTN ALYS W/DYN PLNTR PRES MEAS WALKG",
  },
  { code: "82726", shortdesc: "VERY LONG CHAIN FATTY ACIDS" },
  { code: "26508", shortdesc: "RELEASE THENAR MUSCLE" },
  { code: "64560", shortdesc: "PRQ IMPLTJ NSTIM ELTRDS AUTONOMIC NRV" },
  { code: "29799", shortdesc: "UNLISTED PROCEDURE CASTING/STRAPPING" },
  { code: "93461", shortdesc: "R& L HRT CATH W/INJEC HRT ART/GRFT& L VENT I" },
  { code: "41135", shortdesc: "GLOSSECTOMY PRTL W/UNI RADICAL NECK DSJ" },
  { code: "3085F", shortdesc: "SUICIDE RISK ASSESSED" },
  { code: "29891", shortdesc: "ARTHRS ANKLE EXC OSTCHNDRL DFCT W/DRLG DFCT" },
  { code: "27050", shortdesc: "ARTHROTOMY W/BIOPSY SACROILIAC JOINT" },
  { code: "78591", shortdesc: "PULM VI GASEOUS 1 PRJCJ" },
  { code: "80162", shortdesc: "DRUG SCREEN QUANTITATIVE DIGOXIN TOTAL" },
  { code: "66840", shortdesc: "RMVL LENS MATERIAL ASPIR TQ 1/> STAGES" },
  { code: "68335", shortdesc: "RPR SYMBLEPHARON FR GRF CJNC/BUCCAL MUC MEMB" },
  {
    code: "17311",
    shortdesc: "MOHS MICROGRAPHIC H/N/H/F/G 1ST STAGE 5 BLOCKS",
  },
  { code: "98912", shortdesc: "null" },
  {
    code: "33971",
    shortdesc: "RMVL I-AORT BALO ASST DEV W/RPR FEM ART W/WO GRF",
  },
  {
    code: "77406",
    shortdesc: "RADJ DLVR 1 AREA 1/PRLL OPSD PORTS SMPL 20MEV/<",
  },
  { code: "76947", shortdesc: "null" },
  { code: "53442", shortdesc: "RMVL/REVJ SLING MALE URINARY INCONTINENCE" },
  { code: "3142F", shortdesc: "BARIUM SWALLOW TEST ORDERED" },
  { code: "86927", shortdesc: "FRESH FROZEN PLASMA THAWING EACH UNIT" },
  { code: "86185", shortdesc: "CNTERIMMUNOELECTROPHORESIS EACH ANTIGEN" },
  {
    code: "88374",
    shortdesc: "M/PHMTRC ALYS ISH QUANT/SEMIQ CPTR EACH MULTIPRB",
  },
  { code: "85002", shortdesc: "BLEEDING TIME TEST" },
  { code: "60254", shortdesc: "THYROIDECTOMY TOTAL/SUBTOTAL RAD NECK DISSECT" },
  { code: "00541", shortdesc: "ANES THORACOTOMY & THORACOSCOPY W/1 LUNG VNTJ" },
  { code: "64870", shortdesc: "ANASTOMOSIS FACIAL-PHRENIC" },
  { code: "33420", shortdesc: "VALVOTOMY MITRAL VALVE CLOSED HEART" },
  { code: "73560", shortdesc: "RADIOLOGIC EXAMINATION KNEE 1/2 VIEWS" },
  { code: "40652", shortdesc: "RPR LIP FULL THICKNESS HALF/< VERTICAL HEIGHT" },
  { code: "64858", shortdesc: "SUTURE SCIATIC NERVE" },
  { code: "81219", shortdesc: "CALR GENE ANALYSIS COMMON VARIANTS IN EXON 9" },
  {
    code: "88007",
    shortdesc: "NECROPSY GROSS EXAMINATION W/BRAIN&SPINAL CORD",
  },
  { code: "59011", shortdesc: "null" },
  { code: "92555", shortdesc: "SPEECH AUDIOMETRY THRESHOLD" },
  {
    code: "51565",
    shortdesc: "CSTC PRTL W/RIMPLTJ URTR IN BLDR URTRONEOCSTOST",
  },
  { code: "81254", shortdesc: "GJB6 GENE ANALYSIS COMMON VARIANTS" },
  {
    code: "25078",
    shortdesc: "RAD RESCJ TUM SOFT TISSUE FOREARM&/WRIST 3 CM/>",
  },
  { code: "87503", shortdesc: "NFCT AGENT DNA/RNA INFLUENZA >2 TYPES EA ADDL" },
  { code: "67923", shortdesc: "REPAIR ENTROPION EXCISION TARSAL WEDGE" },
  { code: "4066F", shortdesc: "ELECTROCONVULSIVE THERAPY (ECT) PROVIDED" },
  { code: "49412", shortdesc: "PLACEMENT INTRSTL DEV OPN W/IMG GUID 1/MLT" },
  {
    code: "32553",
    shortdesc: "PLMT NTRSTL DEV RADJ THX GID PRQ INTRATHRC 1/MLT",
  },
  { code: "89257", shortdesc: "SPRM ID FROM ASPIR OTH/THN SEMINAL" },
  { code: "28261", shortdesc: "CAPSULOTOMY MIDFOOT W/TENDON LENGTHENING" },
  { code: "58572", shortdesc: "LAPAROSCOPY TOTAL HYSTERECTOMY UTERUS >250 GM" },
  {
    code: "0253T",
    shortdesc: "INSERT ANT SGM DRAINAGE DEV W/O RESERVR INT APPR",
  },
  {
    code: "21552",
    shortdesc: "EXC TUMOR SOFT TIS NECK/ANT THORAX SUBQ 3 CM/>",
  },
  { code: "81261", shortdesc: "IGH@ REARRANGE ABNORMAL CLONAL POP AMPLIFIED" },
  {
    code: "93621",
    shortdesc: "COMPRE ELECTROPHYSIOL XM W/LEFT ATRIAL PACNG/REC",
  },
  {
    code: "27822",
    shortdesc: "OPEN TX TRIMALLEOLAR ANKLE FX W/O FIXJ PST LIP",
  },
  { code: "27816", shortdesc: "CLTX TRIMALLEOLAR ANKLE FX W/O MANIPULATION" },
  { code: "25680", shortdesc: "CLTX TRANS-SCAPHOPRILUNAR TYP FX DISLC W/MANJ" },
  { code: "59426", shortdesc: "ANTEPARTUM CARE ONLY 7/> VISITS" },
  { code: "54150", shortdesc: "CIRCUMCISION W/CLAMP/OTH DEV W/BLOCK" },
  { code: "20808", shortdesc: "REPLANTATION HAND COMPLETE AMPUTATION" },
  { code: "99333", shortdesc: "null" },
  {
    code: "24073",
    shortdesc: "EXC TUMOR SOFT TISS UPPER ARM/ELBW SUBFASC 5CM/>",
  },
  {
    code: "49656",
    shortdesc: "LAPS RPR RECURRENT INCISIONAL HERNIA REDUCIBLE",
  },
  { code: "90379", shortdesc: "null" },
  { code: "4135F", shortdesc: "SYSTEMIC CORTICOSTEROIDS PRESCRIBED" },
  {
    code: "0391T",
    shortdesc: "INTERROG DEVICE EVAL IN PERSON LEADLESS PM SYST",
  },
  { code: "0528T", shortdesc: "null" },
  { code: "94725", shortdesc: "MEMB DIFFUSION CAP" },
  { code: "27095", shortdesc: "INJECTION HIP ARTHROGRAPHY W/ANESTHESIA" },
  { code: "26580", shortdesc: "REPAIR CLEFT HAND" },
  { code: "82953", shortdesc: "GLUC TOLBUTAMIDE TOLERANCE TST" },
  { code: "82190", shortdesc: "ATOMIC ABSRPJ SPECTROSCOPY EA ANALYTE" },
  { code: "40801", shortdesc: "DRG ABSC CST HMTMA VESTIBULE MOUTH COMP" },
  {
    code: "68816",
    shortdesc: "PROBE NASOLACRIMAL DUCT WITH CATHETER DILATION",
  },
  { code: "43610", shortdesc: "EXC LOCAL ULCER/BENIGN TUMOR STOMACH" },
  { code: "74329", shortdesc: "ENDOSCOPIC CATHJ PANCREATIC DUCTAL SYS RS&I" },
  {
    code: "87506",
    shortdesc: "IADNA-DNA/RNA GI PTHGN MULTIPLEX PROBE TQ 6-11",
  },
  {
    code: "21193",
    shortdesc: "RCNSTJ MNDBLR RAMI HRZNTL/VER/C/L OSTEOT W/O GRF",
  },
  {
    code: "53605",
    shortdesc: "DILAT URETHRAL STRIX/VESICAL NCK DILAT MALE ANES",
  },
  { code: "4220F", shortdesc: "DIGOXIN MEDICATION THERAPY 6 MONTHS/>" },
  { code: "00921", shortdesc: "ANES VASECTOMY UNI/BI INCL OPEN URETHRAL PX" },
  { code: "47553", shortdesc: "BILIARY NDSC PRQ T-TUBE W/BX SINGLE/MULTIPLE" },
  { code: "73225", shortdesc: "MRA UPPER EXTREMITY W/WO CONTRAST MATERIAL" },
  {
    code: "65280",
    shortdesc: "RPR LAC CORNEA&/SCLERA PERFOR X INVG UVEAL TIS",
  },
  {
    code: "92081",
    shortdesc: "VISUAL FIELD XM UNI/BI W/INTERPRETJ LIMITED EXAM",
  },
  { code: "92971", shortdesc: "CARDIOASSIST-METH CIRCULATORY ASSIST EXTERNAL" },
  { code: "66850", shortdesc: "RMVL LENS MATERIAL PHACOFRAGMENTATION ASPIR" },
  {
    code: "51500",
    shortdesc: "EXC URACHAL CYST/SINUS W/WO UMBILICAL HERNIA RPR",
  },
  { code: "19240", shortdesc: "null" },
  { code: "85384", shortdesc: "FIBRINOGEN ACTIVITY" },
  { code: "99152", shortdesc: "MOD SED SAME PHYS/QHP INITIAL 15 MINS 5/> YRS" },
  {
    code: "25073",
    shortdesc: "EXC TUMOR SFT TISS FOREARM&/WRIST SUBFASC 3CM/>",
  },
  { code: "95913", shortdesc: "NERVE CONDUCTION STUDIES 13/> STUDIES" },
  {
    code: "26135",
    shortdesc: "SYNVCT MTCARPHLNGL JT W/INTRNSC RLS&XTNSR HOOD",
  },
  { code: "81180", shortdesc: "null" },
  {
    code: "77372",
    shortdesc: "RADIATION DELIVERY STEREOTACTIC CRANIAL LINEAR",
  },
  { code: "31040", shortdesc: "PTERYGOMAXILLARY FOSSA SURGERY ANY APPROACH" },
  { code: "76880", shortdesc: "null" },
  { code: "90477", shortdesc: "ADENOVIRUS VACCINE TYPE 7 LIVE FOR ORAL" },
  {
    code: "41805",
    shortdesc: "RMVL EMBEDDED FB FROM DENTALVLR STRUXS SOFT TISS",
  },
  { code: "01522", shortdesc: "ANES VEINS LOWER LEG VENOUS THRMBC DIR/W/CATH" },
  {
    code: "20555",
    shortdesc: "PLACEMENT NEEDLES MUSCLE SUBSEQUENT RADIOELEMENT",
  },
  {
    code: "0413T",
    shortdesc: "REMOVAL CARDIAC MODULJ SYS TRANSVENOUS ELECTRODE",
  },
  {
    code: "86970",
    shortdesc: "PRETX RBC ANTIBODY INCUBAT W/CHEM AGNTS/DRUGS EA",
  },
  {
    code: "29892",
    shortdesc: "ARTHRS AID RPR LES/TALAR DOME FX/TIBL PLAFOND FX",
  },
  { code: "98969", shortdesc: "NONPHYSICIAN ONLINE ASSESSMENT AND MANAGEMENT" },
  { code: "84437", shortdesc: "ASSAY OF THYROXINE REQUIRING ELUTION" },
  { code: "36821", shortdesc: "ARTERIOVENOUS ANASTOMOSIS OPEN DIRECT" },
  { code: "84105", shortdesc: "ASSAY OF PHOSPHORUS INORGANIC URINE" },
  { code: "54840", shortdesc: "EXCISION SPERMATOCELE W/WO EPIDIDYMECTOMY" },
  { code: "3378F", shortdesc: "AJCC BREAST CANCER STAGE III" },
  { code: "0038T", shortdesc: "null" },
  { code: "0110T", shortdesc: "QUANT SENSORY TEST&INTERPJ/XTR OTHER STIMULI" },
  { code: "88185", shortdesc: "FLOW CYTOMETRY CELL SURF MARKER TECHL ONLY EA" },
  {
    code: "29044",
    shortdesc: "APPLICATION BODY CAST SHOULDER HIPS W/ONE THIGH",
  },
  {
    code: "62223",
    shortdesc: "CRTJ SHUNT VENTRICULO-PERITNEAL-PLEURAL TERMINUS",
  },
  { code: "68840", shortdesc: "PROBE LACRIMAL CANALICULI W/WO IRRIGATION" },
  {
    code: "33369",
    shortdesc: "REPLACE AORTA VALVE W/BYP CNTRL ART/VENOUS APRCH",
  },
  { code: "28108", shortdesc: "EXC/CURTG CST/B9 TUM PHALANGES FOOT" },
  { code: "01700", shortdesc: "null" },
  { code: "83051", shortdesc: "ASSAY OF HEMOGLOBIN PLASMA" },
  { code: "44345", shortdesc: "REVJ COLOSTOMY COMP RCNSTJ IN-DEPTH SPX" },
  { code: "65103", shortdesc: "ENUCLEATION EYE IMPLT MUSC X ATTACHED IMPLT" },
  { code: "36570", shortdesc: "INSJ PRPH CTR VAD W/SUBQ PORT UNDER 5 YR" },
  { code: "27305", shortdesc: "FASCIOTOMY ILIOTIBIAL OPEN" },
  { code: "92287", shortdesc: "ANT SGM IMAGING W/FLUOROSCEIN ANGIO & I&R" },
  { code: "0088T", shortdesc: "null" },
  {
    code: "57160",
    shortdesc: "FIT&INSJ PESSARY/OTH INTRAVAGINAL SUPPORT DEVI",
  },
  { code: "21422", shortdesc: "OPEN TREATMENT PALATAL/MAXILLARY FRACTURE" },
  { code: "26556", shortdesc: "TRANSFER FREE TOE JOINT W/MVASC ANASTOMOSIS" },
  { code: "0096T", shortdesc: "null" },
  { code: "68440", shortdesc: "SNIP INCISION LACRIMAL PUNCTUM" },
  {
    code: "32484",
    shortdesc: "RMVL LUNG OTHER THAN PNEUMONECT 1 SEGMENTECTOMY",
  },
  { code: "56805", shortdesc: "CLITOROPLASTY INTERSEX STATE" },
  { code: "31287", shortdesc: "NASAL/SINUS ENDOSCOPY W/SPHENOIDOTOMY" },
  { code: "49327", shortdesc: "LAPS W/INSERTION NTRSTL DEV W/IMG GUID 1/MLT" },
  { code: "45130", shortdesc: "EXC RCT PROCIDENTIA W/ANAST PERINEAL APPROACH" },
  { code: "36246", shortdesc: "SLCTV CATHJ 2ND ORDER ABDL PEL/LXTR ART BRNCH" },
  { code: "42000", shortdesc: "DRAINAGE ABSCESS PALATE UVULA" },
  { code: "82646", shortdesc: "DIHYDROCODEINONE" },
  {
    code: "29710",
    shortdesc: "RMVL/BIVALV SHO/HIP SPICA MINERVA/RISSER JACKET",
  },
  { code: "33545", shortdesc: "RPR POSTINFRCJ VENTRICULAR SEPTAL DEFECT" },
  {
    code: "86480",
    shortdesc: "TB CELL MEDIATED ANTIGN RESPNSE GAMMA INTERFERON",
  },
  { code: "21366", shortdesc: "OPEN TX COMP FRACTURE MALAR AREA W/BONE GRAFT" },
  { code: "80327", shortdesc: "DRUG SCREEN QUANT ANABOLIC STEROID 1 OR 2" },
  {
    code: "33967",
    shortdesc: "INSERTION INTRA-AORTIC BALLOON ASSIST DEV PERQ",
  },
  { code: "61334", shortdesc: "EXPL ORBIT TRANSCRANIAL W/RMVL FOREIGN BODY" },
  { code: "00142", shortdesc: "ANESTHESIA EYE LENS SURGERY" },
  { code: "35112", shortdesc: "DIR RPR RUPTD ANEURYSM SPLENIC ARTERY" },
  { code: "96115", shortdesc: "null" },
  { code: "4016F", shortdesc: "ANTI-INFLAMMATORY/ANALGESIC AGT PRESCRIBED" },
  { code: "45331", shortdesc: "SIGMOIDOSCOPY FLX W/BIOPSY SINGLE/MULTIPLE" },
  { code: "43202", shortdesc: "ESOPHAGOSCOPY FLEXIBLE TRANSORAL WITH BIOPSY" },
  { code: "53235", shortdesc: "EXC URETHRAL DIVERTICULUM SPX MALE" },
  {
    code: "37239",
    shortdesc: "OPEN/PERQ PLACEMENT INTRAVASC STENT SAME EA ADDL",
  },
  { code: "96371", shortdesc: "SUBQ INFUSION ADDITIONAL PUMP INFUSION SITE" },
  {
    code: "01402",
    shortdesc: "ANESTH OPEN/SURG ARTHRS TOTAL KNEE ARTHROPLASTY",
  },
  { code: "82550", shortdesc: "CREATINE KINASE TOTAL" },
  {
    code: "67835",
    shortdesc: "CORRJ TRICHIASIS INC LID MRGN W/FR MUC MEMB GRF",
  },
  { code: "61541", shortdesc: "CRANIOTOMY TRANSECTION CORPUS CALLOSUM" },
  { code: "49550", shortdesc: "RPR 1ST FEM HRNA ANY AGE REDUCIBLE" },
  { code: "25660", shortdesc: "CLTX RDCRPL/INTERCARPL DISLC 1/> BONES W/MANJ" },
  { code: "99261", shortdesc: "null" },
  { code: "50398", shortdesc: "CHANGE NEPHROSTOMY/PYELOSTOMY TUBE" },
  { code: "87274", shortdesc: "IAADI HERPES SMPLX VIRUS TYPE 1" },
  { code: "42600", shortdesc: "CLOSURE SALIVARY FISTULA" },
  {
    code: "90714",
    shortdesc: "TD VACCINE PRSRV FREE 7 YRS OR OLDER FOR IM USE",
  },
  {
    code: "69715",
    shortdesc: "IMPLJ OSSEOINTEGRATED TEMPORAL BONE W/O MASTOID",
  },
  { code: "57800", shortdesc: "DILATION CERVICAL CANAL INSTRUMENTAL SPX" },
  {
    code: "63173",
    shortdesc: "LAM W/DRG INTRMEDULRY CYST/SYRINX PRTL/PLEURAL",
  },
  { code: "56605", shortdesc: "BIOPSY VULVA/PERINEUM 1 LESION SPX" },
  { code: "87015", shortdesc: "CONCENTRATION INFECTIOUS AGENTS" },
  {
    code: "22554",
    shortdesc: "ARTHRD ANT MIN DISCECT INTERBODY CERV BELOW C2",
  },
  { code: "92283", shortdesc: "COLOR VISION XM EXTENDED ANOMALOSCOPE/EQUIV" },
  {
    code: "33284",
    shortdesc: "RMVL IMPLANTABLE PT-ACTIVATED CAR EVENT RECORDER",
  },
  { code: "24802", shortdesc: "ARTHRODESIS ELBOW JOINT W/AUTOGENOUS GRAFT" },
  { code: "15775", shortdesc: "PUNCH GRAFT HAIR TRANSPLANT 1-15 PUNCH GRAFTS" },
  { code: "0047U", shortdesc: "null" },
  {
    code: "58400",
    shortdesc: "UTERINE SUSPENSION W/WO SHORTENING LIGAMENTS SPX",
  },
  { code: "21632", shortdesc: "RADICAL RESECTION STERNUM W/MEDSTNL LMPHADEC" },
  { code: "82952", shortdesc: "GLUCOSE TOLERANCE EA ADDL BEYOND 3 SPECIMENS" },
  { code: "72194", shortdesc: "CT PELVIS W/O & W/CONTRAST MATERIAL" },
  {
    code: "67930",
    shortdesc: "SUTR WND EYELID/MARGIN/TARSUS/CONJUNC PRTL THICK",
  },
  { code: "0123U", shortdesc: "null" },
  { code: "0500F", shortdesc: "INITIAL PRENATAL CARE VISIT" },
  { code: "50021", shortdesc: "DRAINAGE PERIRENAL/RENAL ABSCESS PRW" },
  { code: "96375", shortdesc: "THERAPEUTIC INJECTION IV PUSH EACH NEW DRUG" },
  { code: "67141", shortdesc: "PROPH RTA DTCHMNT W/O DRG 1/> SESS CRTX DTHRM" },
  { code: "46060", shortdesc: "I&D ISCHIORCT/INTRAMURAL ABSC W/WO SETON" },
  {
    code: "4180F",
    shortdesc: "ADJVNT CHEMO RFRRD RXD/RCVD STAGE III COLON CA",
  },
  {
    code: "31630",
    shortdesc: "BRNCHSC W/TRACHEAL/BRONCHIAL DILAT/CLSD RDCTJ FX",
  },
  { code: "35661", shortdesc: "BYP OTH/THN VEIN FEMORAL-FEMORAL" },
  {
    code: "11970",
    shortdesc: "REPLACEMENT TISS EXPANDER PERMANENT PROSTHESIS",
  },
  { code: "56318", shortdesc: "null" },
  { code: "31254", shortdesc: "NASAL/SINUS NDSC W/PARTIAL ETHMOIDECTOMY" },
  { code: "67570", shortdesc: "OPTIC NERVE DECOMPRESSION" },
  { code: "3044F", shortdesc: "MOST RECENT HEMOGLOBIN A1C LEVEL < 7.0%" },
  { code: "82777", shortdesc: "GALECTIN-3" },
  { code: "32800", shortdesc: "REPAIR LUNG HERNIA THROUGH CHEST WALL" },
  { code: "0021T", shortdesc: "null" },
  { code: "93990", shortdesc: "DUPLEX SCAN HEMODIALYSIS ACCESS" },
  { code: "82286", shortdesc: "BRADYKININ" },
  { code: "44404", shortdesc: "COLONOSCOPY STOMA W/SUBMUCOSAL INJECTION" },
  {
    code: "15100",
    shortdesc: "SPLIT AGRFT T/A/L 1ST 100 CM/&/1% BDY INFT/CHLD",
  },
  { code: "29280", shortdesc: "STRAPPING HAND/FINGER" },
  { code: "00802", shortdesc: "ANES LOWER ANT ABDOMINAL WALL PANNICULECTOMY" },
  { code: "85097", shortdesc: "BONE MARROW SMEAR INTERPRETATION" },
  { code: "83045", shortdesc: "HEMOGLOBIN METHEMOGLOBIN QUALITATIVE" },
  {
    code: "47460",
    shortdesc: "TRANSDUOL SPHINCTEROT/PLASTY W/WO RMVL CALCULUS",
  },
  { code: "29851", shortdesc: "ARTHROSCOPY AID TX SPINE&/FX KNEE W/FIXJ" },
  {
    code: "64420",
    shortdesc: "INJECTION ANESTHETIC AGENT 1 INTERCOSTAL NERVE",
  },
  { code: "62194", shortdesc: "RPLCMT/IRRG SUBARACHNOID/SUBDURAL CATHETER" },
  {
    code: "11420",
    shortdesc: "EXC B9 LESION MRGN XCP SK TG S/N/H/F/G 0.5 CM/<",
  },
  { code: "36561", shortdesc: "INSJ TUNNELED CTR VAD W/SUBQ PORT AGE 5 YR/>" },
  { code: "93924", shortdesc: "N-INVAS PHYSIOLOGIC STD LXTR ART COMPL BI" },
  {
    code: "0299T",
    shortdesc: "ESW H ENERGY W/TOPCAL APP &DRESNG CARE 1ST WOUND",
  },
  { code: "99223", shortdesc: "INITIAL HOSPITAL CARE/DAY 70 MINUTES" },
  {
    code: "65436",
    shortdesc: "RMVL CORNEAL EPITHELIUM W/APPL CHELATING AGENT",
  },
  {
    code: "15777",
    shortdesc: "IMPLNT BIO IMPLNT FOR SOFT TISSUE REINFORCEMENT",
  },
  {
    code: "3756F",
    shortdesc: "PT HAS PSEUDOBULBAR AFFECT/SIALORRHEA/ALS SYMP",
  },
  {
    code: "4560F",
    shortdesc: "ANESTH DID NOT INVOLVE GENERAL/NEURAXIAL ANESTH",
  },
  { code: "90804", shortdesc: "INDIV PSYCTX OFFICE/OUTPATIENT 20-30 MIN" },
  { code: "54670", shortdesc: "SUTURE/REPAIR TESTICULAR INJURY" },
  { code: "94260", shortdesc: "THRC GAS VOL" },
  { code: "87197", shortdesc: "SERUM BACTERICIDAL TITER" },
  { code: "80323", shortdesc: "ALKALOIDS NOT OTHERWISE SPECIFIED" },
  { code: "57110", shortdesc: "VAGINECTOMY COMPLETE REMOVAL VAGINAL WALL" },
  { code: "15171", shortdesc: "ACLR DRM RPLCMT T/A/L EA 100 CM/EA 1 % BDY" },
  {
    code: "99467",
    shortdesc: "CRITICAL CARE INTERFACILITY TRANSPORT EA 30 MIN",
  },
  { code: "64859", shortdesc: "SUTURE EACH ADDITIONAL PERIPHERAL NERVE" },
  { code: "38380", shortdesc: "SUTR&/LIG THORACIC DUCT CERVICAL APPROACH" },
  { code: "4188F", shortdesc: "APPROP ACE/ARB THXP MONIT TEST ORDRD/DONE" },
  { code: "99443", shortdesc: "PHYS/QHP TELEPHONE EVALUATION 21-30 MIN" },
  { code: "0084T", shortdesc: "null" },
  {
    code: "33929",
    shortdesc: "REMOVAL TOTAL RPLCMT HEART SYS FOR HEART TRNSPL",
  },
  { code: "29405", shortdesc: "APPLICATION SHORT LEG CAST BELOW KNEE-TOE" },
  {
    code: "26125",
    shortdesc: "FASCT PRTL PALMR ADDL DGT PROX IPHAL JT W/WO RPR",
  },
  { code: "94400", shortdesc: "BREATHING RESPONSE TO CO2" },
  { code: "48000", shortdesc: "PLACE DRAIN PERIPANCREATIC ACUTE PANCREATITIS" },
  {
    code: "00546",
    shortdesc: "ANES THORACOTOMY & THORACOSCOPY PULMONARY RESC",
  },
  {
    code: "99190",
    shortdesc: "ASSEMBLY&OPERJ PUMP OXYGENATOR/HEAT EXCH EA HR",
  },
  {
    code: "63066",
    shortdesc: "COSTOVERTEBRAL DCMPRN SPINE CORD THORACIC EA SEG",
  },
  { code: "99212", shortdesc: "OFFICE OUTPATIENT VISIT 10 MINUTES" },
  {
    code: "99480",
    shortdesc: "SUBSEQUENT INTENSIVE CARE INFANT 2501-5000 GRAMS",
  },
  { code: "81182", shortdesc: "null" },
  { code: "77525", shortdesc: "PROTON TX DELIVERY COMPLEX" },
  { code: "99251", shortdesc: "INITIAL INPATIENT CONSULT NEW/ESTAB PT 20 MIN" },
  {
    code: "3751F",
    shortdesc: "ELECTRODIAG STUDIES DSP DOCD RVWD W/IN 6 MONTHS",
  },
  { code: "64886", shortdesc: "NERVE GRAFT HEAD/NECK >4 CM" },
  { code: "0182T", shortdesc: "HDR ELECTRONIC BRACHYTHERAPY PER FRACTION" },
  { code: "81401", shortdesc: "MOLECULAR PATHOLOGY PROCEDURE LEVEL 2" },
  {
    code: "01772",
    shortdesc: "ANESTHESIA ARTERIES UPPER ARM&ELBOW EMBOLECTOM",
  },
  { code: "01910", shortdesc: "null" },
  { code: "6010F", shortdesc: "DYSPHAGIA SCREENING PRIOR ORAL INTAKE" },
  { code: "3284F", shortdesc: "INTRAOCULAR PRESS REDUCED >/EQUAL 15%" },
  { code: "24100", shortdesc: "ARTHROTOMY ELBOW W/SYNOVIAL BIOPSY ONLY" },
  {
    code: "81414",
    shortdesc: "CAR ION CHNNLPATH DUP/DEL GN ALYS PANEL 2 GENES",
  },
  {
    code: "87502",
    shortdesc: "INFECTIOUS AGENT DNA/RNA INFLUENZA 1ST 2 TYPES",
  },
  { code: "90809", shortdesc: "INDIV PSYCTX OFFICE/OUTPT 75-80 MIN W/MED E/M" },
  { code: "92608", shortdesc: "RX SP-GENRATJ AUGMNT&COMUNICAJ DEV EA 30 MIN" },
  { code: "81320", shortdesc: "null" },
  { code: "28505", shortdesc: "OPEN TX FRACTURE GREAT TOE/PHALANX/PHALANGES" },
  { code: "64488", shortdesc: "TAP BLOCK BILATERAL BY INJECTION(S)" },
  { code: "80055", shortdesc: "OBSTETRIC PANEL" },
  { code: "77056", shortdesc: "MAMMOGRAPHY BILATERAL" },
  {
    code: "90811",
    shortdesc: "INDIV PSYCTX INTERACT OFF/OP 20-30 MIN MED E/M",
  },
  {
    code: "11000",
    shortdesc: "DBRDMT EXTENSV ECZEMA/INFECT SKN UP 10% BDY SURF",
  },
  { code: "65920", shortdesc: "RMVL IMPLANTED MATERIAL ANTERIO SEGMENT EYE" },
  { code: "01800", shortdesc: "null" },
  {
    code: "0244T",
    shortdesc: "CONT MEAS WHEEZE RATE BRONCHODIL SLEEP 3-24 HRS",
  },
  { code: "86005", shortdesc: "ALLERGEN SPEC IGE QUAL MULTIALLERGEN SCREEN" },
  { code: "77370", shortdesc: "SPEC MEDICAL RADJ PHYSICS CONSLTJ" },
  { code: "30600", shortdesc: "REPAIR FISTULA ORONASAL" },
  {
    code: "27626",
    shortdesc: "ARTHROTOMY W/SYNOVECTOMY ANKLE TENOSYNOVECTOMY",
  },
  {
    code: "49419",
    shortdesc: "INSERTION TUNNEL INTRAPERITONEAL CATH SUBQ PORT",
  },
  { code: "36415", shortdesc: "COLLECTION VENOUS BLOOD VENIPUNCTURE" },
  { code: "35645", shortdesc: "BYP OTH/THN VEIN SUBCLAVIAN-VERTEBRAL" },
  { code: "39560", shortdesc: "RESCJ DIAPHRAGM W/SIMPLE REPAIR" },
  { code: "58545", shortdesc: "LAPS MYOMECTOMY EXC 1-4 MYOMAS 250 GM/<" },
  {
    code: "86833",
    shortdesc: "ANTIBODY HLA CLASS II HIGH DEFINITION PANEL QUAL",
  },
  { code: "33800", shortdesc: "AORTIC SUSPENSION TRACHEAL DECOMPRESSION SPX" },
  {
    code: "93040",
    shortdesc: "RHYTHM ECG 1-3 LEADS W/INTERPRETATION & REPORT",
  },
  { code: "31586", shortdesc: "null" },
  { code: "41520", shortdesc: "FRENOPLASTY SURG REVJ FRENUM EG W/Z-PLASTY" },
  {
    code: "10120",
    shortdesc: "INCISION & REMOVAL FOREIGN BODY SUBQ TISS SIMPLE",
  },
  {
    code: "33916",
    shortdesc: "PULMONARY ENDARTERCOMY W/WO EMBOLECTOMY W/BYPASS",
  },
  { code: "49255", shortdesc: "OMNTC EPIPLOECTOMY RESCJ OMENTUM SPX" },
  { code: "0432T", shortdesc: "REPOS NSTIM SYSTEM SLEEP APNEA STIMJ LEAD" },
  {
    code: "73565",
    shortdesc: "RADIOLOGIC EXAM BOTH KNEES STANDING ANTEROPOST",
  },
  {
    code: "50605",
    shortdesc: "URETEROTOMY INSERTION INDWELLING STENT ALL TYPES",
  },
  { code: "17003", shortdesc: "DESTRUCTION PREMALIGNANT LESION 2-14 EA" },
  {
    code: "64776",
    shortdesc: "EXC NEUROMA DIGITAL NERVE 1 OR BOTH SAME DIGIT",
  },
  {
    code: "12045",
    shortdesc: "REPAIR INTERMEDIATE N/H/F/XTRNL GENT 12.6-20 CM",
  },
  {
    code: "94016",
    shortdesc: "PATIENT-INITIATED SPIROMETRIC PHYS/QHP R&I ONLY",
  },
  { code: "74183", shortdesc: "MRI ABDOMEN W/O & W/CONTRAST MATERIAL" },
  { code: "26991", shortdesc: "I&D PELVIS/HIP JOINT AREA INFECTED BURSA" },
  {
    code: "55845",
    shortdesc: "PROSTECT RETROPUB RAD W/WO NRV SPAR & BI PLV LYM",
  },
  {
    code: "25671",
    shortdesc: "PRQ SKELETAL FIXJ DISTAL RADIOULNAR DISLOCATION",
  },
  { code: "0178T", shortdesc: "64 LEAD ECG W/INTERPRETATION & REPORT" },
  { code: "86619", shortdesc: "ANTIBODY BORRELIA RELAPSING FEVER" },
  {
    code: "67961",
    shortdesc: "EXCISION & REPAIR EYELID < ONE-FOURTH LID MARGIN",
  },
  { code: "45116", shortdesc: "PRCTECT PRTL W/ANAST TRANSSAC APPR ONLY" },
  { code: "3555F", shortdesc: "PT HAD INR MEASUREMENT PERFORMED" },
  {
    code: "96413",
    shortdesc: "CHEMOTX ADMN IV NFS TQ UP 1 HR 1/1ST SBST/DRUG",
  },
  { code: "3351F", shortdesc: "NEG DEP SYMP CAT USING STAND DEP ASSESS TOOL" },
  { code: "63047", shortdesc: "LAM FACETECTOMY & FORAMOTOMY 1 SEGMENT LUMBAR" },
  { code: "99607", shortdesc: "MEDICATION THERAPY EACH ADDITIONAL 15 MIN" },
  {
    code: "34203",
    shortdesc: "EMBLC/THRMBC POPLITEAL-TIBIO-PRONEAL ART LEG INC",
  },
  {
    code: "33501",
    shortdesc: "RPR CORONARY AV/ARTERIOCAR CHMBR FSTL W/O BYPASS",
  },
  { code: "49495", shortdesc: "RPR 1ST INGUN HRNA FULL TERM INFT <6 MO RDC" },
  {
    code: "92974",
    shortdesc: "TCAT PLACEMENT RADJ DLVR DEV SBSQ C IV BRACHYTX",
  },
  { code: "0523T", shortdesc: "null" },
  {
    code: "95149",
    shortdesc: "PREPJ& ANTIGEN ALLERGEN IMMUNOTHERAPY 5 INSECT",
  },
  { code: "29425", shortdesc: "APPLICATION SHORT LEG CAST WALKING/AMBULATORY" },
  { code: "90970", shortdesc: "ESRD RELATED SVC <FULL MONTH 20/>YR OLD" },
  { code: "87810", shortdesc: "CHLAMYDIA TRACHOMATIS" },
  { code: "75978", shortdesc: "TRANSLUMINAL BALLOON ANGIOPLASTY VENOUS RS&I" },
  { code: "62201", shortdesc: "VENTRICULOCISTERNOSTOMY 3RD VNTRC NEURONDSC" },
  { code: "65205", shortdesc: "REMOVAL FB EYE CONJUNCTIVAL SUPERFICIAL" },
  { code: "66761", shortdesc: "IRIDOTOMY/IRRIDECTOMY LASER SURG PER SESSION" },
  {
    code: "36823",
    shortdesc: "INSJ CNULA ISLTD XC-CIRCJ REG CHEMOTX XTR RMVL",
  },
  { code: "12041", shortdesc: "REPAIR INTERMEDIATE N/H/F/XTRNL GENT 2.5CM/<" },
  {
    code: "21179",
    shortdesc: "RCNSTJ FOREHEAD &/ SUPRAORB RIMS W/ALGRF/PROSTC",
  },
  {
    code: "63600",
    shortdesc: "CREATION LES SPINAL CORD STEREOTACTIC METHOD PRQ",
  },
  {
    code: "21246",
    shortdesc: "RCNSTJ MNDBL/MAXL SUBPRIOSTEAL IMPLANT COMPLETE",
  },
  { code: "41822", shortdesc: "EXC FIBROUS TUBEROSITIES DENTOALVEOLAR STRUXS" },
  { code: "54680", shortdesc: "TRANSPLANTATION TESTIS TO THIGH" },
  { code: "28046", shortdesc: "RAD RESECTION TUMOR SOFT TISSUE FOOT/TOE <3CM" },
  { code: "33875", shortdesc: "DESCENDING THORACIC AORTA GRAFT W/WO BYPASS" },
  {
    code: "47144",
    shortdesc: "BKBENCH PREPJ CADAVER WHOLE LIVER GRF I&IV VII",
  },
  { code: "12017", shortdesc: "SIMPLE REPAIR F/E/E/N/L/M 20.1CM-30.0 CM" },
  { code: "93299", shortdesc: "INTERROGATION EVAL REMOTE </30 D TECH REVIEW" },
  {
    code: "99357",
    shortdesc: "PROLONGED SVC I/P REQ UNIT/FLOOR TIME EA 30 MIN",
  },
  { code: "49400", shortdesc: "INJECTION AIR/CONTRAST PERITONEAL CAVITY SPX" },
  { code: "76150", shortdesc: "null" },
  {
    code: "88367",
    shortdesc: "M/PHMTRC ALYS ISH CPTR-ASST TECH 1ST PROBE STAIN",
  },
  { code: "44701", shortdesc: "INTRAOPERATIVE COLONIC LAVAGE" },
  { code: "78315", shortdesc: "BONE &/JOINT IMAGING 3 PHASE STUDY" },
  {
    code: "73218",
    shortdesc: "MRI UPPER EXTREMITY OTH THAN JT W/O CONTR MATRL",
  },
  { code: "27648", shortdesc: "INJECTION ANKLE ARTHROGRAPHY" },
  { code: "95165", shortdesc: "PREPJ& ALLERGEN IMMUNOTHERAPY 1/MLT ANTIGEN" },
  { code: "82331", shortdesc: "CALCIUM AFTER CALCIUM INFUSION TEST" },
  { code: "57550", shortdesc: "EXCISION CERVICAL STUMP VAGINAL APPROACH" },
  { code: "27889", shortdesc: "ANKLE DISARTICULATION" },
  { code: "43652", shortdesc: "LAPS SURG TRNSXJ VAGUS NRV SLCTV/HILY SLCTV" },
  {
    code: "22862",
    shortdesc: "REVJ RPLCMT DISC ARTHROPLASTY ANT 1 NTRSPC LMBR",
  },
  {
    code: "0185T",
    shortdesc: "MULTIVARIATE ALYS PT FINDINGS CPTR PROBABILITY",
  },
  { code: "88738", shortdesc: "HGB QUANTITATIVE TRANSCUTANEOUS" },
  { code: "93228", shortdesc: "XTRNL MOBILE CV TELEMETRY W/I&REPORT 30 DAYS" },
  { code: "76873", shortdesc: "US TRANSRCT PRSTATE VOL BRACHYTX PLNNING SPX" },
  { code: "0001M", shortdesc: "null" },
  { code: "12053", shortdesc: "REPAIR INTERMEDIATE F/E/E/N/L&/MUC 5.1-7.5 CM" },
  { code: "42340", shortdesc: "SIALOLITHOTOMY PRTD XTRORAL/COMP INTRAORAL" },
  { code: "88312", shortdesc: "SPECIAL STAIN GROUP 1 MICROORGANISMS I&R" },
  { code: "99186", shortdesc: "null" },
  { code: "95858", shortdesc: "null" },
  { code: "29875", shortdesc: "ARTHROSCOPY KNEE SYNOVECTOMY LIMITED SPX" },
  { code: "92603", shortdesc: "ANALYSIS COCHLEAR IMPLT 7 YR/> PRGRMG" },
  { code: "26497", shortdesc: "TR TDN RESTORE INTRNSC FUNCJ RING&SM FNGR" },
  { code: "27438", shortdesc: "ARTHROPLASTY PATELLA W/PROSTHESIS" },
  {
    code: "33513",
    shortdesc: "CORONARY ARTERY BYPASS 4 CORONARY VENOUS GRAFTS",
  },
  { code: "81362", shortdesc: "HBB KNOWN FAMILIAL VARIANTS" },
  { code: "23334", shortdesc: "PROSTHESIS REMOVAL HUMERAL/GLENOID COMPONENT" },
  {
    code: "4195F",
    shortdesc: "PT RCVNG 1ST BIOL ANTI-RHEUM DRUG THXRPY FOR RA",
  },
  {
    code: "21461",
    shortdesc: "OPEN TX MANDIBULAR FX W/O INTERDENTAL FIXATION",
  },
  {
    code: "90621",
    shortdesc: "MENB-FHBP RECOMBNT LIPOPROTEIN VACC 2/3 DOSE IM",
  },
  { code: "84206", shortdesc: "ASSAY OF PROINSULIN" },
  { code: "0110U", shortdesc: "null" },
  {
    code: "82657",
    shortdesc: "NZYM ACTIV BLD CELLS/TISS NONRADACT SUBSTRATE EA",
  },
  { code: "82105", shortdesc: "ALPHA-FETOPROTEIN SERUM" },
  {
    code: "99404",
    shortdesc: "PREVENT MED COUNSEL&/RISK FACTOR REDJ SPX 60 MIN",
  },
  {
    code: "27323",
    shortdesc: "BIOPSY SOFT TISSUE THIGH/KNEE AREA SUPERFICIAL",
  },
  { code: "53444", shortdesc: "INSERTION TANDEM CUFF" },
  {
    code: "33120",
    shortdesc: "EXC INTRACARDIAC TUMOR RESCJ CARDIOPULMONARY BYP",
  },
  { code: "25630", shortdesc: "CLTX CARPAL BONE FX W/O MANJ EACH BONE" },
  { code: "41830", shortdesc: "ALVEOLECTOMY W/CURTG OSTEITIS/SEQUESTRECTOMY" },
  { code: "73085", shortdesc: "RADEX ELBOW ARTHROGRAPHY RS&I" },
  { code: "50949", shortdesc: "UNLISTED LAPAROSCOPY PROCEDURE URETER" },
  { code: "90889", shortdesc: "PREP REPORT PT PSYCH STATUS AGENCY/PAYER" },
  { code: "63740", shortdesc: "CRTJ SHUNT LMBR SARACH-PRTL-PLEURAL/OTH W/LAM" },
  { code: "83690", shortdesc: "ASSAY OF LIPASE" },
  { code: "3160F", shortdesc: "DOC IRON STORES PRIOR START EPO THERAPY" },
  { code: "25535", shortdesc: "CLOSED TX ULNAR SHAFT FRACTURE W/MANIPULATION" },
  { code: "15135", shortdesc: "DERMAL AUTOGRAFT F/S/N/H/F/G/M/D GT 1ST 100" },
  {
    code: "01951",
    shortdesc: "ANES 2/3 DGR BRN EXC/DBRDMT W/WO GRFT 4 % TBSA",
  },
  { code: "43328", shortdesc: "ESOPG/GSTR FUNDOPLASTY W/THORACOTOMY" },
  {
    code: "80307",
    shortdesc: "DRUG TST PRSMV INSTRMNT CHEM ANALYZERS PR DATE",
  },
  { code: "75810", shortdesc: "SPLENOPORTOGRAPY RS&I" },
  { code: "3112F", shortdesc: "CT/MRI BRAIN DONE 24 HRS AFTER HOSP ARRIVAL" },
  { code: "74410", shortdesc: "UROGRAPHY INFUSION DRIP &/BOLUS TECHNIQUE" },
  { code: "44015", shortdesc: "TUBE/NEEDLE CATH JEJUNOSTOMY ANY METHOD" },
  { code: "42100", shortdesc: "BIOPSY PALATE UVULA" },
  { code: "92601", shortdesc: "ANALYSIS COCHLEAR IMPLT PT <7 YR PRGRMG" },
  { code: "81250", shortdesc: "G6PC GENE ANALYSIS COMMON VARIANTS" },
  {
    code: "43233",
    shortdesc: "EGD ESOPHAGUS BALLOON DILATION 30 MM OR LARGER",
  },
  {
    code: "92934",
    shortdesc: "PRQ TRLUML CORONARY STENT/ATH/ANGIO ADDL BRANCH",
  },
  { code: "39501", shortdesc: "REPAIR LACERATION DIAPHRAGM ANY APPROACH" },
  { code: "25446", shortdesc: "ARTHRP W/PROSTC RPLCMT DSTL RDS&PRTL/CARPUS" },
  { code: "11620", shortdesc: "EXCISION MALIGNANT LESION S/N/H/F/G 0.5 CM/<" },
  { code: "3150F", shortdesc: "FORCEPS ESOPHAGEAL BIOPSY PERFORMED" },
  { code: "81163", shortdesc: "null" },
  { code: "0179T", shortdesc: "64 LEAD ECG W/TRACING & GRAPHICS" },
  {
    code: "67974",
    shortdesc: "RCNSTJ EYELID FULL THICKNESS UPPER EYELID 1 STG",
  },
  { code: "42106", shortdesc: "EXC LESION PALATE UVULA W/SMPL PRIM CLOSURE" },
  { code: "85170", shortdesc: "BLOOD CLOT RETRACTION" },
  { code: "72240", shortdesc: "MYELOGRAPHY CERVICAL RS&I" },
  {
    code: "23700",
    shortdesc: "MANJ W/ANES SHOULDER JOINT W/FIXATION APPARATUS",
  },
  { code: "72040", shortdesc: "RADEX SPINE CERVICAL 2 OR 3 VIEWS" },
  {
    code: "86235",
    shortdesc: "EXTRACTABLE NUCLEAR ANTIGEN ANTIBODY ANY METHOD",
  },
  {
    code: "79403",
    shortdesc: "RP THER RADIOLBLD MONOCLONAL ANTIBODY IV INFUS",
  },
  { code: "21295", shortdesc: "REDUCTION MASSETER MUSCLE & BONE EXTRAORAL" },
  { code: "64784", shortdesc: "EXC NEUROMA MAJOR PERIPHERAL NRV XCP SCIATIC" },
  { code: "25520", shortdesc: "CLTX RDL SHFT FX&CLTX DISLC DSTL RAD/ULN JT" },
  { code: "00539", shortdesc: "ANESTHESIA TRACHEOBRONCHIAL RECONSTRUCTION" },
  { code: "55862", shortdesc: "EXPOS PROSTATE INSJ RADIOACT SBST W/LYMPH BX" },
  {
    code: "63265",
    shortdesc: "LAM EXC/EVAC ISPI LES OTH/THN NEO XDRL CERVICAL",
  },
  {
    code: "26844",
    shortdesc: "ARTHRD CARP/MTCRPL JT DGT OTH/THN THMB W/AGRFT",
  },
  { code: "13132", shortdesc: "REPAIR COMPLEX F/C/C/M/N/AX/G/H/F 2.6-7.5 CM" },
  {
    code: "4248F",
    shortdesc: "COUNSEL INIT BACK PAIN AGNST BED REST 4 DAYS/>",
  },
  { code: "64744", shortdesc: "TRANSECTION/AVULSION GREATER OCCIPITAL NERVE" },
  {
    code: "97039",
    shortdesc: "UNLIST MODALITY SPEC TYPE&TIME CONSTANT ATTEND",
  },
  {
    code: "78492",
    shortdesc: "MYOCRD IMAGE PET PERFUS MULTPL STUDY REST/STRESS",
  },
  {
    code: "28470",
    shortdesc: "CLOSED TX METATARSAL FRACTURE W/O MANIPULATION",
  },
  {
    code: "99284",
    shortdesc: "EMERGENCY DEPARTMENT VISIT HIGH/URGENT SEVERITY",
  },
  {
    code: "33970",
    shortdesc: "INSJ INTRA-AORT BALO ASSIST DEV VIA FEM ART OPEN",
  },
  { code: "89321", shortdesc: "SEMEN ANALYSIS SPERM PRESENCE&/MOTILITY SPRM" },
  { code: "4060F", shortdesc: "PSYCHOTHERAPY SERVICES PROVIDED" },
  { code: "52341", shortdesc: "CYSTO W/TX URETERAL STRICTURE" },
  { code: "90939", shortdesc: "null" },
  { code: "50551", shortdesc: "RENAL ENDOSCOPY NEPHROSTOMY W/WO IRRIGATION" },
  {
    code: "92507",
    shortdesc: "TX SPEECH LANG VOICE COMMJ &/AUDITORY PROC IND",
  },
  { code: "54437", shortdesc: "REPAIR OF TRAUMATIC CORPOREAL TEAR(S)" },
  { code: "26746", shortdesc: "OPEN TX ARTICULAR FRACTURE MCP/IP JOINT EA" },
  {
    code: "43118",
    shortdesc: "PRTL ESOPH DSTL W/WO PROX GASTRC W/COLON NTRPSTJ",
  },
  {
    code: "28304",
    shortdesc: "OSTEOTOMY TARSAL BONES OTH/THN CALCANEUS/TALUS",
  },
  { code: "45505", shortdesc: "PROCTOPLASTY PROLAPSE MUCOUS MEMBRANE" },
  { code: "59101", shortdesc: "null" },
  { code: "60545", shortdesc: "ADRENALECTOMY EXPL W/EXC RETROPERTINEAL TUMOR" },
  {
    code: "33518",
    shortdesc: "CORONARY ARTERY BYP W/VEIN & ARTERY GRAFT 2 VEIN",
  },
  { code: "87254", shortdesc: "VIRUS CENTRIFUGE ENHNCD ID IMFLUOR STAIN EA" },
  { code: "59614", shortdesc: "VAGINAL DELIVERY & POSTPARTUM CARE VBAC" },
  { code: "90838", shortdesc: "PSYCHOTHERAPY W/PATIENT W/E&M SRVCS 60 MIN" },
  {
    code: "90993",
    shortdesc: "DIALYSIS TRAINING PATIENT PER TRAINING SESSION",
  },
  {
    code: "54162",
    shortdesc: "LYSIS/EXCISION PENILE POSTCIRCUMCISION ADHESIONS",
  },
  {
    code: "34151",
    shortdesc: "EMBLC/THRMBC RNL CELIAC MESENTRY AORTO-ILIAC ART",
  },
  {
    code: "81504",
    shortdesc: "ONCOLOGY TISSUE OF ORIGIN SIMILAR SCOR ALGORITHM",
  },
  { code: "99475", shortdesc: "INITIAL PED CRITICAL CARE 2 THRU 5 YEARS" },
  { code: "35565", shortdesc: "BYPASS W/VEIN ILIOFEMORAL" },
  { code: "85293", shortdesc: "CLOTTING HI MOLEC WEIGHT KININOGEN ASSAY" },
  { code: "45825", shortdesc: "CLOSURE RECTOURETHRAL FISTULA W/COLOSTOMY" },
  { code: "35102", shortdesc: "DIR RPR ANEURYSM ABDOM AORTA W/ILIAC VESSELS" },
  { code: "62294", shortdesc: "NJX ARTERIAL OCCLUSION ARVEN MALFRMJ SPINAL" },
  {
    code: "45330",
    shortdesc: "SIGMOIDOSCOPY FLX DX W/COLLJ SPEC BR/WA IF PFRMD",
  },
  { code: "28315", shortdesc: "SESAMOIDECTOMY FIRST TOE SPX" },
  { code: "15175", shortdesc: "ACLR DRM RPLCMT F/S/N/H/F/G/M/D GT 1ST 100 CM" },
  {
    code: "34451",
    shortdesc: "THRMBC DIR/W/CATH V/C ILIAC FEMPOP VEIN ABDL&LEG",
  },
  {
    code: "44660",
    shortdesc: "CLSR ENTEROVES FSTL W/O INTSTINAL/BLADDER RESCJ",
  },
  {
    code: "20552",
    shortdesc: "INJECTION SINGLE/MLT TRIGGER POINT 1/2 MUSCLES",
  },
  { code: "19180", shortdesc: "null" },
  {
    code: "35671",
    shortdesc: "BYP OTH/THN VEIN POPLITEAL-TIBIAL/-PERONEAL ART",
  },
  { code: "35571", shortdesc: "BYP W/VEIN POP-TIBL-PRONEAL ART/OTH DSTL VSL" },
  {
    code: "11732",
    shortdesc: "AVULSION NAIL PLATE PARTIAL/COMP SIMPLE EA ADDL",
  },
  { code: "82107", shortdesc: "AFP-L3 FRACTION ISOFORM & TOTAL AFP W/RATIO" },
  {
    code: "26123",
    shortdesc: "FASCT PRTL PALMAR 1 DGT PROX IPHAL JT W/WO RPR",
  },
  { code: "0203T", shortdesc: "null" },
  {
    code: "19125",
    shortdesc: "EXC BREAST LES PREOP PLMT RAD MARKER OPEN 1 LES",
  },
  {
    code: "11313",
    shortdesc: "SHAVING SKIN LESION 1 F/E/E/N/L/M DIAM >2.0 CM",
  },
  {
    code: "81438",
    shortdesc: "HEREDTRY NURONDCRN TUM DSRDRS DUP/DEL ANALYSIS",
  },
  { code: "82654", shortdesc: "DIMETHADIONE" },
  { code: "33416", shortdesc: "VENTRICULOMYOTOMY-MYECTOMY" },
  { code: "58994", shortdesc: "null" },
  { code: "80321", shortdesc: "DRUG SCREEN QUANT ALCOHOLS BIOMARKERS 1 OR 2" },
  { code: "27369", shortdesc: "null" },
  { code: "80352", shortdesc: "DRUG SCREENING CANNABINOIDS SYNTHETIC 7/MORE" },
  { code: "48552", shortdesc: "BKBENCH RCNSTJ CDVR PNCRS ALGRFT VEN ANAST EA" },
  { code: "86593", shortdesc: "SYPHILIS TEST QUANTITATIVE" },
  {
    code: "67228",
    shortdesc: "TREATMENT EXTENSIVE RETINOPATHY PHOTOCOAGULATION",
  },
  { code: "22852", shortdesc: "REMOVAL POSTERIOR SEGMENTAL INSTRUMENTATION" },
  { code: "50951", shortdesc: "URETERAL ENDOSCOPY VIA URETEROSTOMY" },
  { code: "35485", shortdesc: "null" },
  { code: "36568", shortdesc: "INSJ PRPH CVC W/O SUBQ PORT/PMP UNDER 5 YR" },
  { code: "80003", shortdesc: "null" },
  { code: "49500", shortdesc: "RPR 1ST INGUN HRNA AGE 6 MO-5 YRS REDUCIBLE" },
  { code: "47550", shortdesc: "BILIARY NDSC INTRAOPERATIVE" },
  { code: "69450", shortdesc: "TYMPANOLYSIS TRANSCANAL" },
  { code: "86705", shortdesc: "HEPATITIS B CORE ANTIBODY HBCAB IGM ANTIBODY" },
  {
    code: "57300",
    shortdesc: "CLSR RECTOVAGINAL FISTULA VAGINAL/TRANSANAL APPR",
  },
  { code: "40842", shortdesc: "VESTIBULOPLASTY POSTERIOR UNILATERAL" },
  { code: "43135", shortdesc: "DIVERTICULECTOMY HYPOPHARYNX/ESOPH THRC APPR" },
  { code: "3050F", shortdesc: "MOST RECENT LDL-C >/EQUAL 130 MG/DL" },
  { code: "58267", shortdesc: "VAG HYST 250 GM/< W/COLPO-URTCSTOPEXY" },
  { code: "44361", shortdesc: "ENDOSCOPY UPPER SMALL INTESTINE W/BIOPSY" },
  { code: "92018", shortdesc: "OPHTH XM&EVAL ANES W/WO MANJ GLOBE COMPL" },
  { code: "69949", shortdesc: "UNLISTED PROCEDURE INNER EAR" },
  { code: "46614", shortdesc: "ANOSCOPY CONTROL BLEEDING" },
  { code: "4120F", shortdesc: "ANTIBIOTIC PRESCRIBED OR DISPENSED" },
  { code: "90762", shortdesc: "null" },
  {
    code: "86886",
    shortdesc: "ANTIHUMAN GLOBULIN INDIRECT EACH ANTIBODY TITER",
  },
  { code: "69005", shortdesc: "DRAINAGE EXTERNAL EAR ABSCESS/HEMATOMA CMPLX" },
  {
    code: "72080",
    shortdesc: "RADEX SPINE THORACOLUMBAR JUNCTION MIN 2 VIEWS",
  },
  { code: "1005F", shortdesc: "ASTHMA SYMPTOMS EVALUATED" },
  { code: "83030", shortdesc: "HEMOGLOBIN F FETAL CHEMICAL" },
  {
    code: "99078",
    shortdesc: "PHYS/QHP EDUCATION SVCS RENDERED PTS GRP SETTING",
  },
  { code: "80061", shortdesc: "LIPID PANEL" },
  { code: "17999", shortdesc: "UNLISTED PX SKIN MUC MEMBRANE & SUBQ TISSUE" },
  { code: "50549", shortdesc: "UNLISTED LAPAROSCOPY PROCEDURE RENAL" },
  { code: "26756", shortdesc: "PRQ SKEL FIXJ DSTL PHLNGL FX FNGR/THMB EA" },
  { code: "0365T", shortdesc: "ADAPTIVE BEHAVIOR TX BY PROTOCOL ADDL 30 MIN" },
  { code: "65900", shortdesc: "RMVL EPITHELIAL DOWNGROWTH ANT CHAMBER EYE" },
  { code: "27477", shortdesc: "ARREST EPIPHYSEAL TIBIA & FIBULA PROXIMAL" },
  { code: "82247", shortdesc: "BILIRUBIN TOTAL" },
  { code: "92120", shortdesc: "TNGRPHY I&R REC INDENTAJ TNMTR SUCJ" },
  {
    code: "88350",
    shortdesc: "IMMUNOFLUORESCENCE PER SPEC ADD SINGL ANTB STAIN",
  },
  { code: "22899", shortdesc: "UNLISTED PROCEDURE SPINE" },
  { code: "15365", shortdesc: "TISS CLTR ALGC DRM F/S/N/H/F/G/M/D 1ST 100 CM" },
  { code: "26210", shortdesc: "EXCISION/CURETTAGE CYST/TUMOR PHALANX FINGER" },
  { code: "85445", shortdesc: "HEINZ BODIES INDUCED ACETYL PHENYLHYDRAZINE" },
  { code: "38570", shortdesc: "LAPS SURG RETROPERITONEAL LYMPH NODE BX 1/MLT" },
  {
    code: "11300",
    shortdesc: "SHAVING SKIN LESION 1 TRUNK/ARM/LEG DIAM 0.5CM/<",
  },
  { code: "69552", shortdesc: "EXCISION AURAL GLOMUS TUMOR TRANSMASTOID" },
  {
    code: "38232",
    shortdesc: "BONE MARROW HARVEST TRANSPLANTATION AUTOLOGOUS",
  },
  { code: "1158F", shortdesc: "ADVNC CARE PLANNING TLK DOCD IN MED RCRD" },
  { code: "59400", shortdesc: "OB CARE ANTEPARTUM VAG DLVR & POSTPARTUM" },
  {
    code: "58953",
    shortdesc: "BSO W/OMENTECTOMY TAH&RAD DEBULKING DISSECTION",
  },
  { code: "93655", shortdesc: "ICAR CATHETER ABLATION ARRHYTHMIA ADD ON" },
  { code: "65130", shortdesc: "INSJ OC IMPLT SEC AFTER EVSC SCLL SHELL" },
  { code: "21029", shortdesc: "REMOVAL CONTOURING BENIGN TUMOR FACIAL BONE" },
  { code: "56355", shortdesc: "null" },
  {
    code: "57500",
    shortdesc: "BIOPSY CERVIX SINGLE/MULT/EXCISION OF LESION SPX",
  },
  {
    code: "84182",
    shortdesc: "PROTEIN WESTRN BLOT BLOOD/OTH FLU IMMUNOLOGICAL",
  },
  { code: "43338", shortdesc: "ESOPHAGUS LENGTHENING" },
  { code: "44378", shortdesc: "ENTEROSCOPY > 2ND PRTN ILEUM CONTROL BLEEDING" },
  { code: "01996", shortdesc: "DAILY HOSP MGMT EDRL/SARACH CONT DRUG ADMN" },
  { code: "3511F", shortdesc: "CHLAMYDIA/GONORRHEA TSTS DOCD AS DONE" },
  { code: "85576", shortdesc: "PLATELET AGGREGATION IN VITRO EACH AGENT" },
  { code: "61641", shortdesc: "BALLOON DILAT INCRNL VASOSPSM PRQ EA VESSEL" },
  { code: "46020", shortdesc: "PLACEMENT SETON" },
  { code: "98968", shortdesc: "NONPHYSICIAN TELEPHONE ASSESSMENT 21-30 MIN" },
  {
    code: "92265",
    shortdesc: "NEEDLE OCULOGRAPHY 1/ XOC MUSC 1/BOTH EYE W/I&R",
  },
  { code: "89253", shortdesc: "ASSTD EMBRYO HATCHING MICROTQS ANY METH" },
  {
    code: "27448",
    shortdesc: "OSTEOTOMY FEMUR SHAFT/SUPRACONDYLAR W/O FIXATION",
  },
  {
    code: "99235",
    shortdesc: "OBSERVATION/INPATIENT HOSPITAL CARE 50 MINUTES",
  },
  { code: "85380", shortdesc: "FIBRIN DGRADJ PRODUCTS D-DIMER ULTRASENSITIVE" },
  { code: "73206", shortdesc: "CT ANGIOGRAPHY UPPER EXTREMITY" },
  { code: "1011F", shortdesc: "ANGINA PRESENT" },
  { code: "96936", shortdesc: "RCM CELULR & SUBCELULR SKN IMGNG I&R EA ADDL" },
  { code: "90650", shortdesc: "2VHPV VACCINE 3 DOSE SCHEDULE FOR IM USE" },
  { code: "20225", shortdesc: "BIOPSY BONE TROCAR/NEEDLE DEEP" },
  { code: "50945", shortdesc: "LAPAROSCOPY URTROLITHOTOMY" },
  {
    code: "81599",
    shortdesc: "UNLISTED MULTIANALYTE ASSAY ALGORITHMIC ANALYSIS",
  },
  { code: "68100", shortdesc: "BIOPSY CONJUNCTIVA" },
  { code: "67255", shortdesc: "SCLERAL REINFORCEMENT SPX W/GRAFT" },
  { code: "68720", shortdesc: "DACRYOCSTORHINOSTOMY" },
  { code: "86343", shortdesc: "LEUKOCYTE HISTAMINE RELEASE TEST LHR" },
  { code: "35470", shortdesc: "null" },
  {
    code: "27664",
    shortdesc: "RPR EXTENSOR TENDON LEG PRIMARY W/O GRAFT EACH",
  },
  { code: "0238T", shortdesc: "TRLUML PERIPHERAL ATHERECTOMY ILIAC ARTERY EA" },
  {
    code: "64495",
    shortdesc: "NJX DX/THER AGT PVRT FACET JT LMBR/SAC 3+ LEVEL",
  },
  { code: "26546", shortdesc: "RPR NON-UNION MTCRPL/PHALANX" },
  { code: "42699", shortdesc: "UNLISTED PX SALIVARY GLANDS/DUCTS" },
  { code: "24354", shortdesc: "null" },
  { code: "33255", shortdesc: "ABLATION & RCNSTJ ATRIA EXTNSV W/O BYPASS" },
  {
    code: "63056",
    shortdesc: "TRANSPEDICULAR DCMPRN SPINAL CORD 1 SEG LUMBAR",
  },
  {
    code: "3294F",
    shortdesc: "GBS SCRNING DOCD DONE DURING WK 35-37 GESTATION",
  },
  {
    code: "0405T",
    shortdesc: "OVERSIGHT CARE OF XTRCORP LIVER ASSIST SYS PAT",
  },
  { code: "86750", shortdesc: "ANTIBODY PLASMODIUM MALARIA" },
  { code: "0227T", shortdesc: "ANOSCOPY HIGH RESOLUTION W/BIOPSY" },
  { code: "82160", shortdesc: "ANDROSTERONE" },
  { code: "70010", shortdesc: "MYELOGRAPY POST FOSSA RS&I" },
  { code: "68850", shortdesc: "INJECTION CONTRAST MEDIUM DACRYOCYSTOGRAPY" },
  { code: "73530", shortdesc: "RADEX HIP OPERATIVE PROCEDURE" },
  {
    code: "60502",
    shortdesc: "PARATHYROIDECTOMY/EXPLOR PARATHYROIDS RE-EXPLOR",
  },
  { code: "25230", shortdesc: "RADICAL STYLOIDECTOMY SEPARATE PROCEDURE" },
  { code: "99082", shortdesc: "UNUSUAL TRAVEL" },
  {
    code: "49040",
    shortdesc: "DRAINAGE SUBDIAPHRAGMATIC/SUBPHREN ABSCESS OPEN",
  },
  { code: "81275", shortdesc: "KRAS GENE ANALYSIS VARIANTS IN EXON 2" },
  { code: "69535", shortdesc: "RESCJ TEMPORAL BONE EXTERNAL APPROACH" },
  { code: "72285", shortdesc: "DISKOGRAPY CERVICAL/THORACIC RS&I" },
  { code: "45320", shortdesc: "PROCTOSGMDSC RIGID ABLATION LESION" },
  {
    code: "44206",
    shortdesc: "LAPS COLECTOMY PRTL W/END CLST & CLSR DSTL SGM",
  },
  { code: "4276F", shortdesc: "POTENT ANTIRETROVIRAL THERAPY PRESCRIBED" },
  { code: "87275", shortdesc: "IAADI INFLUENZA B VIRUS" },
  { code: "80346", shortdesc: "DRUG SCREENING BENZODIAZEPINES 1-12" },
  { code: "54325", shortdesc: "null" },
  { code: "69000", shortdesc: "DRAINAGE EXTERNAL EAR ABSCESS/HEMATOMA SIMPLE" },
  { code: "83903", shortdesc: "MOLEC MUTATION SCANNING PROPERTIES 1 SGM EACH" },
  {
    code: "58150",
    shortdesc: "TOTAL ABDOMINAL HYSTERECT W/WO RMVL TUBE OVARY",
  },
  {
    code: "64480",
    shortdesc: "NJX ANES&/STRD W/IMG TFRML EDRL CRV/THRC EA LV",
  },
  { code: "62276", shortdesc: "null" },
  { code: "58310", shortdesc: "null" },
  { code: "63252", shortdesc: "LAM EXC/OCCLUSION AVM SPI CORD THORACOLUMBAR" },
  { code: "3514F", shortdesc: "HEPATITIS C SCREENING DOCUMENTED AS PERFORMED" },
  { code: "35207", shortdesc: "REPAIR BLOOD VESSEL DIRECT HAND FINGER" },
  {
    code: "86353",
    shortdesc: "LYMPHOCYTE TR MITOGEN/AG INDUCED BLASTOGENESIS",
  },
  {
    code: "85362",
    shortdesc: "FIBRIN DGRADJ SPLT PRODUXS AGGLUJ SLIDE SEMIQUAN",
  },
  { code: "89281", shortdesc: "ASSTD FERTILIZATION MICROTQ > 10 OOCYTES" },
  {
    code: "15951",
    shortdesc: "EXC TRCHNTRIC PR ULCER W/PRIM SUTR W/OSTECTOMY",
  },
  { code: "90736", shortdesc: "ZOSTER VACCINE HZV LIVE FOR SUBCUTANEOUS USE" },
  {
    code: "25445",
    shortdesc: "ARTHROPLASTY W/PROSTHETIC REPLACEMENT TRAPEZIUM",
  },
  {
    code: "0230T",
    shortdesc: "NJX ANES/STEROID TFRML EDRL W/US LUM/SAC 1 LVL",
  },
  { code: "0093U", shortdesc: "null" },
  {
    code: "81262",
    shortdesc: "IGH@ REARRANGE ABNORMAL CLONAL POP DIRECT PROBE",
  },
  { code: "40814", shortdesc: "EXC LESION MUCOSA & SBMCSL VESTIBULE CPLX RPR" },
  { code: "82486", shortdesc: "CHROMATOGRAPHY QUAL COLUMN ANALYTE NES" },
  { code: "3476F", shortdesc: "DISEASE PROGNOSIS RA ASSESSED GOOD PROG DOCD" },
  { code: "33771", shortdesc: "RPR TRPOS GREAT VSLS W/ENLGMNT V-SEPTL DFCT" },
  { code: "90287", shortdesc: "BOTULINUM ANTITOXIN EQUINE ANY ROUTE" },
  { code: "4055F", shortdesc: "PATIENT RECEIVING PERITONEAL DIALYSIS" },
  {
    code: "86807",
    shortdesc: "SERUM SCREENING % REACTIVE ANTIBODY STANDRD METH",
  },
  { code: "83662", shortdesc: "FETAL LUNG MATURITY FOAM STABILITY TEST" },
  {
    code: "21557",
    shortdesc: "RAD RESECT TUMOR SOFT TISS NECK/ANT THORAX <5CM",
  },
  { code: "81425", shortdesc: "GENOME SEQUENCE ANALYSIS" },
  { code: "65775", shortdesc: "CRNL WEDGE RESCJ CORRJ INDUCED ASTIGMATISM" },
  {
    code: "78451",
    shortdesc: "MYOCARDIAL SPECT SINGLE STUDY AT REST OR STRESS",
  },
  { code: "86860", shortdesc: "ANTIBODY ELUTION RBC EACH ELUTION" },
  { code: "4090F", shortdesc: "PATIENT RECEIVING ERYTHROPOIETIN THERAPY" },
  {
    code: "85301",
    shortdesc: "CLOTTING INHIBITRS ANTITHROMBN III ANTIGEN ASSAY",
  },
  {
    code: "35091",
    shortdesc: "DIR RPR ANEURYSM ABDOM AORTA W/VISCERAL VESSELS",
  },
  {
    code: "64435",
    shortdesc: "INJECTION ANESTHETIC PARACERVICAL UTERINE NERVE",
  },
  { code: "63011", shortdesc: "LAMINECTOMY W/O FFD 1/2 VERT SEG SACRAL" },
  {
    code: "0332T",
    shortdesc: "MYOCRD SYMP INNERVAJ IMG PLNR QUAL&QUANT W/SPECT",
  },
  { code: "70546", shortdesc: "MRA HEAD W/O & W/CONTRAST MATERIAL" },
  { code: "49565", shortdesc: "RPR RECRT INCAL/VNT HERNIA REDUCIBLE" },
  {
    code: "01630",
    shortdesc: "ANES ARTHRS HUMERAL H/N STRNCLAV & SHOULDER NOS",
  },
  {
    code: "44025",
    shortdesc: "COLOTOMY EXPLORATION/BIOPSY/FOREIGN BODY REMOVAL",
  },
  { code: "76942", shortdesc: "US GUIDANCE NEEDLE PLACEMENT IMG S&I" },
  {
    code: "45333",
    shortdesc: "SIGMOIDOSCOPY FLX W/RMVL TUMOR BY HOT BX FORCEPS",
  },
  {
    code: "47552",
    shortdesc: "BILIARY ENDO PRQ T-TUBE DX W/COLLECT SPEC BRUSH",
  },
  { code: "76820", shortdesc: "DOPPLER VELOCIMETRY FETAL UMBILICAL ARTERY" },
  { code: "90763", shortdesc: "null" },
  {
    code: "35363",
    shortdesc: "TEAEC W/WO PATCH GRAFT COMBINED AORTOILIOFEMORAL",
  },
  { code: "46500", shortdesc: "INJECTION SCLEROSING SOLUTION HEMORRHOIDS" },
  { code: "60300", shortdesc: "ASPIRATION AND/OR INJECTION THYROID CYST" },
  { code: "30560", shortdesc: "LYSIS INTRANASAL SYNECHIA" },
  {
    code: "99378",
    shortdesc: "SUPERVISION HOSPICE PATIENT/MONTH 30 MINUTES/>",
  },
  { code: "89325", shortdesc: "SPERM ANTIBODIES" },
  { code: "74445", shortdesc: "CORPORA CAVERNOSOGRAPY RS&I" },
  { code: "55060", shortdesc: "RPR TUNICA VAGINALIS HYDROCELE BOTTLE TYPE" },
  { code: "67805", shortdesc: "EXCISION CHALAZION MULTIPLE DIFFERENT LIDS" },
  { code: "1400F", shortdesc: "PARKINSON DISEASE DIAGNOSIS REVIEWED" },
  { code: "95965", shortdesc: "MAGNETOENCEPHALOGRAPHY SPON BRAIN ACTIVITY" },
  { code: "01924", shortdesc: "ANESTHESIA THER IVNTL RADIOLOGICAL ARTERIAL" },
  { code: "86816", shortdesc: "HLA TYPING DR/DQ SINGLE ANTIGEN" },
  { code: "82626", shortdesc: "DEHYDROEPIANDROSTERONE" },
  { code: "0006M", shortdesc: "null" },
  { code: "64716", shortdesc: "NEUROPLASTY &/TRANSPOSITION CRANIAL NERVE" },
  { code: "27052", shortdesc: "ARTHROTOMY W/BIOPSY HIP JOINT" },
  { code: "80076", shortdesc: "HEPATIC FUNCTION PANEL" },
  {
    code: "15944",
    shortdesc: "EXC ISCHIAL PRESSURE ULCER W/SKIN FLAP CLOSURE",
  },
  {
    code: "23415",
    shortdesc: "CORACOACROMIAL LIGAMENT RELEAS W/WOACROMIOPLASTY",
  },
  { code: "01202", shortdesc: "ANESTHESIA ARTHROSCOPIC HIP JOINT PROCEDURE" },
  { code: "59015", shortdesc: "CHORIONIC VILLUS SAMPLING" },
  {
    code: "26553",
    shortdesc: "TR TOE-TO-HAND W/MVASC ANAST OTH/THN GRT TOE 1",
  },
  { code: "92019", shortdesc: "OPHTH XM&EVAL ANES W/WO MANJ GLOBE LMTD" },
  {
    code: "6090F",
    shortdesc: "PATIENT SAFETY COUNSEL DISEASE STAGE APPROPRIATE",
  },
  { code: "90687", shortdesc: "IIV4 VACC SPLIT VIRUS 0.25 ML DOS FOR IM USE" },
  {
    code: "81541",
    shortdesc: "ONC PRST8 MRNA GENE XPRSN PRFL RT-PCR 46 GENES",
  },
  { code: "38211", shortdesc: "TRNSPL PREPJ HEMATOP PROGEN TUM CELL DEPLJ" },
  { code: "86817", shortdesc: "HLA TYPING DR/DQ MULTIPLE ANTIGENS" },
  { code: "92071", shortdesc: "FIT CONTACT LENS TX OCULAR SURFACE DISEASE" },
  { code: "89125", shortdesc: "FAT STAIN FECES URINE/RESPIR SECRETIONS" },
  { code: "27006", shortdesc: "TENOTOMY ABDUCTORS&/EXTENSOR HIP OPEN SPX" },
  { code: "85045", shortdesc: "BLOOD COUNT RETICULOCYTE AUTOMATED" },
  { code: "0224T", shortdesc: "ACOUSTIC CARDIOGRAPHY MULT ANALYSIS W/I&R" },
  {
    code: "25575",
    shortdesc: "OPEN TX RADIAL&ULNAR SHAFT FX W/FIXJ RADIUS&ULNA",
  },
  { code: "31090", shortdesc: "SINUSOT UNI 3/> PARANSL SINUSES" },
  {
    code: "54415",
    shortdesc: "RMVL NON-NFLTBL/NFLTBL PENILE PROSTH W/O RPLCMT",
  },
  { code: "11730", shortdesc: "AVULSION NAIL PLATE PARTIAL/COMPLETE SIMPLE 1" },
  { code: "33675", shortdesc: "CLOSURE MULTIPLE VENTRICULAR SEPTAL DEFECTS" },
  { code: "62269", shortdesc: "BIOPSY SPINAL CORD PERCUTANEOUS NEEDLE" },
  {
    code: "23146",
    shortdesc: "EXC/CURTG BONE CST/B9 TUM CLAV/SCAPULA W/ALGRFT",
  },
  { code: "32671", shortdesc: "THORACOSCOPY W/PNEUMONECTOMY" },
  { code: "87290", shortdesc: "IAADI VARICELLA ZOSTER VIRUS" },
  {
    code: "61600",
    shortdesc: "RESCJ/EXC LES BASE ANT CRANIAL FOSSA EXTRADURAL",
  },
  { code: "28415", shortdesc: "OPEN TREATMENT CALCANEAL FRACTURE" },
  {
    code: "67043",
    shortdesc: "VITRECTOMY PARS PLANA REMOVE SUBRETINAL MEMBRANE",
  },
  {
    code: "27725",
    shortdesc: "RPR NON/MAL TIBIA SYNOSTOSIS W/FIBULA ANY METH",
  },
  { code: "11470", shortdesc: "EXCISION H/P/P/U SIMPLE/INTERMEDIATE REPAIR" },
  { code: "81506", shortdesc: "ENDOCRINOLOGY BIOCHEMICAL ASSAY SEVEN ANAL" },
  { code: "35683", shortdesc: "BYP AUTOG COMPOSIT 3/> SEG FROM 2/> LOCATION" },
  { code: "13131", shortdesc: "REPAIR COMPLEX F/C/C/M/N/AX/G/H/F 1.1-2.5 CM" },
  { code: "84270", shortdesc: "ASSAY OF SEX HORMONE BINDING GLOBULIN" },
  {
    code: "31646",
    shortdesc: "BRONCHOSCOPY W/THER ASPIR TRACHBRNCL TREE SBSQ",
  },
  { code: "24358", shortdesc: "TNOT ELBOW LATERAL/MEDIAL DEBRIDE OPEN" },
  { code: "87803", shortdesc: "IAADIADOO CLOSTRIDIUM DIFFICILE TOXIN" },
  {
    code: "22552",
    shortdesc: "ARTHRD ANT INTERDY CERVCL BELW C2 EA ADDL NTRSPC",
  },
  { code: "0054T", shortdesc: "CPTR-ASST MUSCSKEL NAVIGJ ORTHO FLUOR IMAGES" },
  {
    code: "90682",
    shortdesc: "RIV4 VACC RECOMBINANT DNA PRSRV ANTIBIO FREE IM",
  },
  { code: "82150", shortdesc: "ASSAY OF AMYLASE" },
  { code: "98967", shortdesc: "NONPHYSICIAN TELEPHONE ASSESSMENT 11-20 MIN" },
  { code: "93293", shortdesc: "TRANSTELEPHONIC RHYTHM STRIP PACEMAKER EVAL" },
  { code: "71030", shortdesc: "RADEX CHEST COMPLETE MINIMUM 4 VIEWS" },
  { code: "76513", shortdesc: "OPH US DX ANT SGM US IMMERSION B-SCAN/HR BIOM" },
  {
    code: "34813",
    shortdesc: "PLMT FEM-FEM PROSTC GRF EVASC AORTIC ARYSM RPR",
  },
  {
    code: "28103",
    shortdesc: "EXC/CURETTAGE CYST/TUMOR TALUS/CALCANEUS ALGRFT",
  },
  {
    code: "63086",
    shortdesc: "VERTEBRAL CORPECTOMY DCMPRN CORD THORACIC EA SEG",
  },
  { code: "43283", shortdesc: "LAPS ESOPHAGEAL LENGTHENING ADDL" },
  {
    code: "9005F",
    shortdesc: "ASYMPT CAROT STEN NO ISCHEM/STRK CAROT/VRTBROBAS",
  },
  { code: "37184", shortdesc: "PRIM PRQ TRLUML MCHNL THRMBC N-COR N-ICRA 1ST" },
  { code: "93654", shortdesc: "EPHYS EVAL W/ABLATION VENTRICULAR TACHYCARDIA" },
  { code: "87475", shortdesc: "IADNA BORRELIA BURGDORFERI DIRECT PROBE TQ" },
  { code: "54160", shortdesc: "CIRCUMCISION NEONATE" },
  { code: "42804", shortdesc: "BIOPSY NASOPHARYNX VISIBLE LESION SIMPLE" },
  { code: "37730", shortdesc: "null" },
  {
    code: "99342",
    shortdesc: "HOME VISIT NEW PATIENT MOD SEVERITY 30 MINUTES",
  },
  { code: "81500", shortdesc: "ONCO (OVARIAN) BIOCHEMICAL ASSAY TWO PROTEINS" },
  {
    code: "59610",
    shortdesc: "ROUTINE OB CARE VAG DLVRY & POSTPARTUM CARE VB",
  },
  { code: "31231", shortdesc: "NASAL ENDOSCOPY DIAGNOSTIC UNI/BI SPX" },
  { code: "90882", shortdesc: "ENVIRONMENTAL IVNTJ MGMT PURPOSES PSYC PT" },
  { code: "00910", shortdesc: "ANES TRANSURETHRAL W/URETHROCYSTOSCOPY NOS" },
  { code: "26500", shortdesc: "RCNSTJ TENDON PULLEY EACH W/LOCAL TISSUES SPX" },
  { code: "57170", shortdesc: "DIAPHRAGM/CERVICAL CAP FITTING W/INSTRUCTIONS" },
  {
    code: "2044F",
    shortdesc: "DOC MNTL HLTH ASSES PRIOR INTVN BACK PAIN 6WKS",
  },
  {
    code: "77065",
    shortdesc: "DIAGNOSTIC MAMMOGRAPHY COMPUTER-AIDED DETCJ UNI",
  },
  {
    code: "38520",
    shortdesc: "BX/EXC LYMPH NODE OPN DP CRV NODE W/EXC FAT PAD",
  },
  { code: "50783", shortdesc: "URETERONEOCYSTOSTOMY W/URETERAL TAILORING" },
  { code: "67850", shortdesc: "DESTRUCTION LESION LID MARGIN </ 1 CM" },
  { code: "84510", shortdesc: "ASSAY OF TYROSINE" },
  { code: "86628", shortdesc: "ANTIBODY CANDIDA" },
  {
    code: "0434T",
    shortdesc: "INTERRO DEV EVAL NSTIM PLS GEN SYS SLEEP APNEA",
  },
  {
    code: "45389",
    shortdesc: "COLONOSCOPY FLX WITH ENDOSCOPIC STENT PLACEMENT",
  },
  { code: "64856", shortdesc: "SUTR PRPH NRV ARM/LEG XCP SCIATIC W/TRPOS" },
  { code: "3281F", shortdesc: "HEMOGLOBIN LEVEL <11 G/DL" },
  { code: "31760", shortdesc: "TRACHEOPLASTY INTRATHORACIC" },
  { code: "64912", shortdesc: "NERVE REPAIR W/NERVE ALLOGRAFT FIRST STRAND" },
  { code: "01520", shortdesc: "ANESTHESIA VEINS OF LOWER LEG NOS" },
  {
    code: "80322",
    shortdesc: "DRUG SCREEN QUANT ALCOHOLS BIOMARKERS 3 OR MORE",
  },
  {
    code: "70482",
    shortdesc: "CT ORBIT SELLA/POST FOSSA/EAR W/O & W/CONTR MATR",
  },
  {
    code: "78472",
    shortdesc: "CARD BLOOD POOL GATED PLANAR 1 STUDY REST/STRESS",
  },
  { code: "44141", shortdesc: "COLECTOMY PRTL W/SKIN LEVEL CECOST/COLOSTOMY" },
  { code: "35572", shortdesc: "HARVEST FEMPOP VEIN 1 SGM VASC RCNSTJ PX" },
  { code: "51050", shortdesc: "CYSTOLITHOTOMY CYSTOTOMY W/RMVL CALCULUS" },
  { code: "80168", shortdesc: "DRUG SCREEN QUANTITATIVE ETHOSUXIMIDE" },
  { code: "83499", shortdesc: "HYDROXYPROGESTERONE 20-" },
  {
    code: "57109",
    shortdesc: "VAGNC PRTL RMVL VAG WALL W/BI TOT PEL LMPHADEC",
  },
  {
    code: "87809",
    shortdesc: "INFECTIOUS AGENT IMMUNOASSAY OPTICAL ADENOVIRUS",
  },
  { code: "26596", shortdesc: "EXC CONSTRICTING RING FNGR W/MLT Z-PLASTIES" },
  { code: "95004", shortdesc: "PERCUTANEOUS TESTS W/ALLERGENIC EXTRACTS" },
  {
    code: "01760",
    shortdesc: "ANESTH OPEN/SURG ARTHRS TOTAL ELBOW REPLACEMENT",
  },
  { code: "33940", shortdesc: "DONOR CARDIECTOMY" },
  {
    code: "26742",
    shortdesc: "CLTX ARTCLR FX INVG MTCARPHLNGL/IPHAL JT W/MANJ",
  },
  {
    code: "0220T",
    shortdesc: "PLMT POST FACET IMPLT UNI/BI W/IMG & GRFT THOR",
  },
  {
    code: "29840",
    shortdesc: "ARTHROSCOPY WRIST DIAG W/WO SYNOVIAL BIOPSY SPX",
  },
  { code: "75840", shortdesc: "VENOGRAPHY ADRENAL UNILATERAL SELECTIVE RS&I" },
  {
    code: "21181",
    shortdesc: "RCNSTJ CONTOURING BENIGN TUMOR CRNL BONES XTRC",
  },
  { code: "82693", shortdesc: "ASSAY OF ETHYLENE GLYCOL" },
  { code: "50430", shortdesc: "NJX PX ANTEGRDE NFROSGRM &/URTRGRM NEW ACCESS" },
  { code: "80081", shortdesc: "OBSTETRIC PANEL" },
  { code: "80170", shortdesc: "DRUG SCREEN QUANTITATIVE GENTAMICIN" },
  { code: "78579", shortdesc: "PULMONARY VENTILATION IMAGING" },
  {
    code: "97762",
    shortdesc: "CHECKOUT ORTHOTIC/PROSTHETIC ESTAB PT EA 15 MIN",
  },
  { code: "50240", shortdesc: "NEPHRECTOMY PARTIAL" },
  {
    code: "95951",
    shortdesc: "LOCALIZE CEREBRAL SEIZURE CABLE/RADIO EEG/VIDEO",
  },
  { code: "43227", shortdesc: "ESOPHAGOSCOPY FLEXIBLE W/BLEEDING CONTROL" },
  { code: "72149", shortdesc: "MRI SPINAL CANAL LUMBAR W/CONTRAST MATERIAL" },
  { code: "56680", shortdesc: "null" },
  { code: "36534", shortdesc: "null" },
  { code: "53060", shortdesc: "DRG OF SKENES GLAND ABSCESS OR CYST" },
  { code: "32999", shortdesc: "UNLISTED PROCEDURE LUNGS & PLEURA" },
  { code: "47715", shortdesc: "EXCISION CHOLEDOCHAL CYST" },
  { code: "15002", shortdesc: "PREP SITE TRUNK/ARM/LEG 1ST 100 SQ CM/1PCT" },
  { code: "76978", shortdesc: "null" },
  {
    code: "63304",
    shortdesc: "VERTEBRAL CORPECTOMY EXC LES 1 SEG IDRL CERVICAL",
  },
  {
    code: "33502",
    shortdesc: "RPR ANOM CORONARY ART PULM ART ORIGIN LIGATION",
  },
  { code: "32673", shortdesc: "THORACOSCOPY RESEXN THYMUS UNI/BILATERAL" },
  { code: "28104", shortdesc: "EXC/CURTG BONE CYST/B9 TUMORTARSAL/METATARSAL" },
  { code: "53460", shortdesc: "URETHROMEATOPLASTY W/PRTL EXC DSTL URTL SGM" },
  {
    code: "15830",
    shortdesc: "EXCISION SKIN ABD INFRAUMBILICAL PANNICULECTOMY",
  },
  {
    code: "11200",
    shortdesc: "REMOVAL SKN TAGS MLT FIBRQ TAGS ANY AREA UPW/15",
  },
  {
    code: "0379T",
    shortdesc: "VISUAL FIELD ASSESSMENT TECH SUPPORT W/INSTRUCT",
  },
  { code: "54161", shortdesc: "CIRCUMCISION AGE >28 DAYS" },
  {
    code: "70481",
    shortdesc: "CT ORBIT SELLA/POST FOSSA/EAR W/CONTRAST MATRL",
  },
  {
    code: "33214",
    shortdesc: "UPG PACEMAKER SYS CONVERT 1CHMBR SYS 2CHMBR SYS",
  },
  { code: "82710", shortdesc: "FAT/LIPIDS FECES QUANTITATIVE" },
  { code: "23066", shortdesc: "BIOPSY SOFT TISSUE SHOULDER DEEP" },
  { code: "48140", shortdesc: "PNCRTECT DSTL STOT W/O PNCRTCOJEJUNOSTOMY" },
  {
    code: "61564",
    shortdesc: "EXC BENIGN TUM CRANIAL BONE W/OPTIC NRV DCMPRN",
  },
  { code: "88027", shortdesc: "NECROPSY GROSS&MCRSCP BRAIN & SPINAL CORD" },
  { code: "50620", shortdesc: "URTROLITHOTOMY MIDDLE ONE-THIRD URETER" },
  {
    code: "93351",
    shortdesc: "ECHO TTHRC R-T 2D W/WO M-MODE REST&STRS CONT ECG",
  },
  { code: "47420", shortdesc: "CHOLEDOCHOT/OST W/O SPHNCTROTOMY/SPHNCTROP" },
  { code: "86021", shortdesc: "ANTIBODY IDENTIFICATION LEUKOCYTE ANTIBODIES" },
  {
    code: "21255",
    shortdesc: "RCNSTJ ZYGMTC ARCH/GLENOID FOSSA W/BONE CARTLG",
  },
  { code: "90468", shortdesc: "null" },
  { code: "86638", shortdesc: "ANTIBODY COXIELLA BURNETII Q FEVER" },
  {
    code: "90813",
    shortdesc: "INDIV PSYCTX INTERACT OFF/OP 45-50 MIN MED E/M",
  },
  { code: "01999", shortdesc: "UNLISTED ANESTHESIA PROCEDURE" },
  { code: "19325", shortdesc: "MAMMAPLASTY AUGMENTATION W/PROSTHETIC IMPLANT" },
  { code: "27347", shortdesc: "EXCISION LESION MENISCUS/CAPSULE KNEE" },
  {
    code: "82731",
    shortdesc: "FTL FIBRONECTIN CERVICOVAG SECRETIONS SEMI-QUAN",
  },
  { code: "67039", shortdesc: "VITRECTOMY MCHNL PARS PLNA FOCAL ENDOLASER PC" },
  { code: "26615", shortdesc: "OPEN TX METACARPAL FRACTURE SINGLE EA BONE" },
  { code: "3475F", shortdesc: "DISEASE PROGNOSIS RA ASSESSED POOR PROG DOCD" },
  { code: "35651", shortdesc: "BYP OTH/THN VEIN AORTOFEMPOP" },
  {
    code: "3125F",
    shortdesc: "ESOPH BX RPRT W/ DYSPL INFO (PRSNT/ABSNT/UNKNWN)",
  },
  {
    code: "52287",
    shortdesc: "CYSTOURETHROSCOPY INJ CHEMODENERVATION BLADDER",
  },
  { code: "37225", shortdesc: "REVSC OPN/PRQ FEM/POP W/ATHRC/ANGIOP SM VSL" },
  { code: "66225", shortdesc: "REPAIR SCLERAL STAPHYLOMA W/GRAFT" },
  { code: "46211", shortdesc: "null" },
  {
    code: "77787",
    shortdesc: "REMOTE AFTLD RADIONUCLIDE BRACHYTX > 12 CHANNEL",
  },
  {
    code: "29860",
    shortdesc: "ARTHROSCOPY HIP DIAGNOSTIC W/WO SYNOVIAL BYP SPX",
  },
  { code: "31576", shortdesc: "LARYNGOSCOPY FLEXIBLE W/BIOPSY(IES)" },
  { code: "87425", shortdesc: "IAAD IA ROTAVIRUS" },
  { code: "35700", shortdesc: "ROPRTJ > 1 MO AFTER ORIGINAL OPRATION" },
  {
    code: "93321",
    shortdesc: "DOP ECHOCARD PULSE WAVE W/SPECTRAL F-UP/LMTD STD",
  },
  { code: "80202", shortdesc: "DRUG SCREEN QUANTITATIVE VANCOMYCIN" },
  { code: "70210", shortdesc: "RADEX SINUSES PARANASAL <3 VIEWS" },
  { code: "0159T", shortdesc: "COMPUTER AIDED DETECTION BREAST MRI" },
  { code: "41018", shortdesc: "XTRORAL I&D FLOOR MASTICATOR SPACE" },
  {
    code: "20824",
    shortdesc: "RPLJ THMB CARP/MTCRPL JT MP JT COMPL AMPUTATION",
  },
  { code: "82383", shortdesc: "CATECHOLAMINES BLOOD" },
  {
    code: "72082",
    shortdesc: "RADEX ENTIR THRC LMBR CRV SAC SPI W/SKULL 2/3 VW",
  },
  {
    code: "29888",
    shortdesc: "ARTHRS AIDED ANT CRUCIATE LIGM RPR/AGMNTJ/RCNSTJ",
  },
  { code: "28220", shortdesc: "TENOLYSIS FLEXOR FOOT SINGLE TENDON" },
  {
    code: "51865",
    shortdesc: "CYSTORRHAPHY SUTR BLDR WND INJ/RPT COMPLICATED",
  },
  {
    code: "63267",
    shortdesc: "LAM EXC/EVAC ISPI LESION OTH/THN NEO XDRL LUMBAR",
  },
  {
    code: "92941",
    shortdesc: "PRQ TRLUML CORONRY TOT OCCLUS REVASC MI ONE VSL",
  },
  { code: "84445", shortdesc: "THYROID STIMULATING IMMUNE GLOBULINS TSI" },
  {
    code: "33496",
    shortdesc: "RPR NON-STRUCT PROSTC VALVE DYSFUNCTION W/BYPASS",
  },
  { code: "37203", shortdesc: "TCAT RETRIEVAL PRQ INTRAVASCULAR FOREIGN BODY" },
  { code: "82946", shortdesc: "GLUCOSE TOLERANCE TEST" },
  { code: "81361", shortdesc: "HBB COMMON VARIANTS" },
  {
    code: "31237",
    shortdesc: "NASAL/SINUS NDSC SURG W/BX POLYPECT/DBRDMT SPX",
  },
  { code: "49507", shortdesc: "RPR 1ST INGUN HRNA AGE 5 YRS/> INCARCERATED" },
  {
    code: "36222",
    shortdesc: "SLCTV CATH CAROTID/INNOM ART ANGIO XTRCRANL ART",
  },
  {
    code: "0582F",
    shortdesc: "PT NOT TRANSFERRED FROM ANESTHETIZING TO CC UNIT",
  },
  {
    code: "33206",
    shortdesc: "INS NEW/RPLCMT PRM PACEMAKR W/TRANS ELTRD ATRIAL",
  },
  {
    code: "89051",
    shortdesc: "CELL COUNT MISC BODY FLUIDS W/DIFFERENTIAL COUNT",
  },
  {
    code: "00756",
    shortdesc: "ANES HRNA REPAIR UPR ABD TABDL RPR DIPHRG HRNA",
  },
  { code: "62141", shortdesc: "CRANIOPLASTY SKULL DEFECT >5 CM DIAMETER" },
  { code: "55831", shortdesc: "PROSTATECTOMY RETROPUBIC SUBTOTAL" },
  {
    code: "21936",
    shortdesc: "RAD RESECTION TUMOR SOFT TISSUE BACK/FLANK 5CM/>",
  },
  { code: "30410", shortdesc: "RHINP PRIM COMPLETE XTRNL PARTS" },
  {
    code: "21337",
    shortdesc: "CLOSED TX NASAL SEPTAL FRACT W/WO STABILIZATION",
  },
  { code: "96402", shortdesc: "CHEMOTX ADMN SUBQ/IM HORMONAL ANTI-NEO" },
  {
    code: "87305",
    shortdesc: "IAAD IA QUAL/SEMIQUAN MULTIPLE STEP ASPERGILLUS",
  },
  { code: "52351", shortdesc: "CYSTO W/URTROSCOPY&/PYELOSCOPY DX" },
  { code: "22585", shortdesc: "ARTHRODESIS ANTERIOR INTERBODY EA ADDL NTRSPC" },
  { code: "21145", shortdesc: "RCNSTJ MIDFACE LEFORT I 1 PIECE W/BONE GRAFTS" },
  {
    code: "37197",
    shortdesc: "PRQ TRANSCATHETER RTRVL INTRVAS FB WITH IMAGING",
  },
  { code: "0445T", shortdesc: "SBSQ PLMT DRUG ELUTING OCULAR INSERT UNI/BI" },
  { code: "0133U", shortdesc: "null" },
  { code: "76140", shortdesc: "CONSLTJ X-RAY XM MADE ELSEWHERE WRTTN REPRT" },
  { code: "0027U", shortdesc: "null" },
  { code: "0509T", shortdesc: "null" },
  { code: "49321", shortdesc: "LAPAROSCOPY SURG W/BX SINGLE/MULTIPLE" },
  { code: "37600", shortdesc: "LIGATION EXTERNAL CAROTID ARTERY" },
  { code: "15776", shortdesc: "PUNCH GRAFT HAIR TRANSPLANT >15 PUNCH GRAFTS" },
  { code: "80184", shortdesc: "DRUG SCREEN QUANTITATIVE PHENOBARBITAL" },
  {
    code: "0421T",
    shortdesc: "TRANSURETHRAL WATERJET ABLATION PROSTATE COMPL",
  },
  {
    code: "33361",
    shortdesc: "REPLACE AORTIC VALVE PERQ FEMORAL ARTRY APPROACH",
  },
  {
    code: "35458",
    shortdesc: "TRLUML BALO ANGIOP OPN BRCH/CPHLC TRNK/BRNCH EA",
  },
  { code: "3552F", shortdesc: "HIGH RISK FOR THROMBOEMBOLISM" },
  { code: "46285", shortdesc: "SURG TX ANAL FISTULA 2ND STAGE" },
  {
    code: "27892",
    shortdesc: "DCMPRN FASCT LEG ANT&/LAT W/DBRDMT MUSC&/NERVE",
  },
  {
    code: "61571",
    shortdesc: "CRANIECTOMY/CRANIOTOMY TX PENETRATNG WOUND BRAIN",
  },
  { code: "29915", shortdesc: "ARTHROSCOPY HIP W/ACETABULOPLASTY" },
  {
    code: "11421",
    shortdesc: "EXC B9 LESION MRGN XCP SK TG S/N/H/F/G 0.6-1.0CM",
  },
  { code: "77082", shortdesc: "DXA BONE DENSITY STUDY VERTEBRAL FRACTURE" },
  { code: "31715", shortdesc: "TRANSTRACHEAL INJECTION BRONCHOGRAPHY" },
  {
    code: "45381",
    shortdesc: "COLSC FLX WITH DIRECTED SUBMUCOSAL NJX ANY SBST",
  },
  { code: "28820", shortdesc: "AMPUTATION TOE METATARSOPHALANGEAL JOINT" },
  {
    code: "21453",
    shortdesc: "CLOSED TX MANDIBULAR FX W/INTERDENTAL FIXATION",
  },
  { code: "90371", shortdesc: "HEPATITIS B IMMUNE GLOBULIN HBIG HUMAN IM" },
  { code: "59414", shortdesc: "DELIVERY PLACENTA SEPARATE PROCEDURE" },
  { code: "21610", shortdesc: "COSTOTRANSVERSECTOMY SEPARATE PROCEDURE" },
  { code: "83497", shortdesc: "ASSAY OF HYDROXYINDOLACETIC ACID 5-HIAA" },
  {
    code: "0452T",
    shortdesc: "INSJ/RPLCMT IMPLTBL AORTIC VENTR VASC HEMO SEAL",
  },
  { code: "42842", shortdesc: "RADICAL RESECTION TONSIL W/O CLOSURE" },
  { code: "70370", shortdesc: "RADEX PHARYNX/LARX W/FLUOR&/MAGNIFICATION TQ" },
  {
    code: "82270",
    shortdesc: "BLOOD OCCULT PEROXIDASE ACTV QUAL FECES 1 DETER",
  },
  { code: "49083", shortdesc: "ABDOM PARACENTESIS DX/THER W/IMAGING GUIDANCE" },
  { code: "97155", shortdesc: "null" },
  { code: "87529", shortdesc: "IADNA HERPES SOMPLX VIRUS AMPLIFIED PROBE TQ" },
  { code: "27306", shortdesc: "TENOTOMY PRQ ADDUCTOR/HAMSTRING 1 TENDON SPX" },
  { code: "15780", shortdesc: "DERMABRASION TOTAL FACE" },
  { code: "92235", shortdesc: "FLUORESCEIN ANGRPH W/MULTIFRAME I&R UNI/BI" },
  { code: "38129", shortdesc: "UNLISTED LAPAROSCOPY PROCEDURE SPLEEN" },
  { code: "47122", shortdesc: "HEPATECTOMY RESCJ TRISEGMENTECTOMY" },
  { code: "20912", shortdesc: "CARTILAGE GRAFT NASAL SEPTUM" },
  { code: "85247", shortdesc: "CLOTTING FACTOR VIII MULTIMETRIC ANALYSIS" },
  { code: "86316", shortdesc: "IMMUNOASSAY TUMOR ANTIGEN QUANTITATIVE" },
  { code: "3322F", shortdesc: "MELANOMA THAN AJCC STAGE 0" },
  { code: "85095", shortdesc: "null" },
  {
    code: "22521",
    shortdesc: "PERCUTANEOUS VERTEBROPLASTY LUMBAR W/WO BNE BX",
  },
  { code: "92556", shortdesc: "SPEECH AUDIOMETRY THRESHOLD SPEECH RECOGNIJ" },
  { code: "43659", shortdesc: "UNLISTED LAPAROSCOPIC PROCEDURE STOMACH" },
  { code: "15792", shortdesc: "CHEMICAL PEEL NONFACIAL EPIDERMAL" },
  { code: "96127", shortdesc: "BEHAV ASSMT W/SCORE & DOCD/STAND INSTRUMENT" },
  { code: "99296", shortdesc: "null" },
  {
    code: "11423",
    shortdesc: "EXC B9 LESION MRGN XCP SK TG S/N/H/F/G 2.1-3.0CM",
  },
  {
    code: "69210",
    shortdesc: "REMOVAL IMPACTED CERUMEN INSTRUMENTATION UNILAT",
  },
  { code: "52614", shortdesc: "null" },
  { code: "68420", shortdesc: "INCISION DRAINAGE LACRIMAL SAC" },
  {
    code: "24586",
    shortdesc: "OPTX PERIARTICULAR FRACTURE &/DISLOCATION ELBO",
  },
  { code: "32095", shortdesc: "THORACOTOMY LIMITED BIOPSY LUNG/PLEURA" },
  { code: "95813", shortdesc: "ELECTROENCEPHALOGRAM EXTND MNTR >1 HR" },
  { code: "81210", shortdesc: "BRAF GENE ANALYSIS V600 VARIANT(S)" },
  { code: "29126", shortdesc: "APPLICATION SHORT ARM SPLINT DYNAMIC" },
  { code: "27200", shortdesc: "CLOSED TREATMENT COCCYGEAL FRACTURE" },
  { code: "59012", shortdesc: "CORDOCENTESIS INTRAUTERINE" },
  { code: "81255", shortdesc: "HEXA GENE ANALYSIS COMMON VARIANTS" },
  { code: "32522", shortdesc: "null" },
  { code: "60605", shortdesc: "EXC CAROTID BODY TUMOR W EXC CAROTID ARTERY" },
  {
    code: "24359",
    shortdesc: "TNOT ELBOW LATERAL/MEDIAL DEBRIDE OPEN TDN RPR",
  },
  {
    code: "52300",
    shortdesc: "CYSTO W/RESCJ/FULG ORTHOPIC URETEROCELE UNI/BI",
  },
  {
    code: "25400",
    shortdesc: "RPR NONUNION/MALUNION RADIUS/ULNA W/O AUTOGRAFT",
  },
  {
    code: "12004",
    shortdesc: "SIMPLE RPR SCALP/NECK/AX/GENIT/TRUNK 7.6-12.5CM",
  },
  { code: "0024U", shortdesc: "null" },
  {
    code: "28262",
    shortdesc: "CAPSUL MIDFOOT W/PST TALOTIBL CAPSUL&TDN LNGTH",
  },
  { code: "43020", shortdesc: "ESOPHAGOTOMY CERVICAL APPR W/RMVL FB" },
  {
    code: "95807",
    shortdesc: "SLEEP STD REC VNTJ RESPIR ECG/HRT RATE&O2 ATTN",
  },
  { code: "38305", shortdesc: "DRG LYMPH NODE ABSC/LYMPHADENITIS EXTNSV" },
  {
    code: "27416",
    shortdesc: "OSTEOCHONDRAL AUTOGRAFT KNEE OPEN MOSAICPLASTY",
  },
  { code: "41005", shortdesc: "INTRAORAL I&D TONGUE/FLOOR SUBLNGL SUPFC" },
  { code: "86702", shortdesc: "ANTIBODY HIV-2" },
  { code: "92560", shortdesc: "BEKESY AUDIOMETRY SCREENING" },
  { code: "87385", shortdesc: "IAAD IA HISTOPLASM CAPSULATUM" },
  { code: "69620", shortdesc: "MYRINGOPLASTY" },
  {
    code: "42962",
    shortdesc: "CTRL OROPHARYNGEAL HEMORRHAGE W/SEC SURG IVNTJ",
  },
  {
    code: "23474",
    shortdesc: "REVIS SHOULDER ARTHRPLSTY HUMERAL&GLENOID COMPNT",
  },
  {
    code: "81112",
    shortdesc: "HPA-15 GENOTYPING GENE ANALYSIS COMMON VARIANT",
  },
  { code: "0130U", shortdesc: "null" },
  { code: "38542", shortdesc: "DISSECTION DEEP JUGULAR NODE" },
  { code: "51860", shortdesc: "CYSTORRHAPHY SUTR BLDR WND INJ/RPT SIMPLE" },
  {
    code: "42210",
    shortdesc: "PALATOP CLSR ALVEOLAR RIDGE GRF ALVEOLAR RIDGE",
  },
  {
    code: "70554",
    shortdesc: "MRI BRAIN FUNCTIONAL W/O PHYSICIAN ADMNISTRATION",
  },
  {
    code: "81430",
    shortdesc: "HEARING LOSS GENOMIC SEQUENCE ANALYSIS 60 GENES",
  },
  {
    code: "78469",
    shortdesc: "MYOCRD INFARCT AVID PLNR TOMOG SPECT W/WO QUANTJ",
  },
  { code: "0505T", shortdesc: "null" },
  { code: "68761", shortdesc: "CLSR LACRIMAL PUNCTUM PLUG EACH" },
  { code: "4271F", shortdesc: "PT RCVNG POT ANTI R-VIRAL THX <6 MON/NOT RCVN" },
  {
    code: "26145",
    shortdesc: "SYNVCT TDN SHTH RAD FLXR TDN PALM&/FNGR EA TDN",
  },
  { code: "31649", shortdesc: "BRNCHSC REMOVAL BRONCHIAL VALVE EA ADDL" },
  { code: "00702", shortdesc: "ANES UPR ANT ABDL WALL PERCUTANEOUS LIVER BX" },
  { code: "93501", shortdesc: "null" },
  { code: "87118", shortdesc: "CULTURE MYCOBACTERIAL DEFINITIVE ID EA ISOL" },
  {
    code: "67882",
    shortdesc: "CONSTJ INTERMARGIN ADHES/TARSOR/CANTHOR W/TRPOS",
  },
  {
    code: "81436",
    shortdesc: "HEREDITARY COLON CA DSRDRS DUP/DEL ANALYS 5 GEN",
  },
  { code: "82552", shortdesc: "CREATINE KINASE ISOENZYMES" },
  { code: "50060", shortdesc: "NEPHROLITHOTOMY REMOVAL STAGE 1" },
  {
    code: "37212",
    shortdesc: "THROMBOLYSIS VENOUS INFUSION W/IMAGING INIT TX",
  },
  { code: "90754", shortdesc: "null" },
  { code: "33987", shortdesc: "ARTERY EXPOS/GRAFT ARTERY PERFUSION ECMO/ECLS" },
  { code: "33247", shortdesc: "null" },
  { code: "33855", shortdesc: "null" },
  {
    code: "99150",
    shortdesc: "MOD SEDATJ PHY OTH/THN PER DX/THER SVC EA 15 MIN",
  },
  {
    code: "62267",
    shortdesc: "PRQ ASPIR PULPOSUS/INTERVERTEBRAL DISC/PVRT TISS",
  },
  { code: "58805", shortdesc: "DRAINAGE OVARIAN CYST UNI/BI SPX ABDOMINAL" },
  { code: "45339", shortdesc: "SIGMOIDOSCOPY FLX ABLTJ LESION" },
  { code: "21138", shortdesc: "RDCTJ FHD CNTRG & PROSTHETIC MATRL/BONE GRAFT" },
  {
    code: "64490",
    shortdesc: "NJX DX/THER AGT PVRT FACET JT CRV/THRC 1 LEVEL",
  },
  {
    code: "44160",
    shortdesc: "COLECTOMY PRTL W/RMVL TERMINAL ILEUM & ILEOCOLOS",
  },
  {
    code: "4067F",
    shortdesc: "PT REFERRAL ELECTROCONVULSIVE THXPY (ECT) DOCD",
  },
  { code: "00620", shortdesc: "ANESTHESIA THORACIC SPINE & CORD NOS" },
  { code: "90284", shortdesc: "IMMUNE GLOBULIN HUMAN SUBQ INFUSION 100 MG EA" },
  { code: "67318", shortdesc: "STRABISMUS ANY SUPERIOR OBLIQUE MUSCLE" },
  { code: "15822", shortdesc: "BLEPHAROPLASTY UPPER EYELID" },
  { code: "19182", shortdesc: "null" },
  { code: "57510", shortdesc: "CAUTERY CERVIX ELECTRO/THERMAL" },
  {
    code: "33244",
    shortdesc: "RMVL1/DUAL CHMBR IMPLTBL DFB ELTRD TRANSVNS XTRJ",
  },
  {
    code: "33511",
    shortdesc: "CORONARY ARTERY BYPASS 2 CORONARY VENOUS GRAFTS",
  },
  { code: "01486", shortdesc: "ANESTHESIA OPEN TOTAL ANKLE REPLACEMENT" },
  {
    code: "90869",
    shortdesc: "REPET TMS TX SUBSEQ MOTR THRESHLD W/DELIV & MN",
  },
  {
    code: "15574",
    shortdesc: "FRMJ DIR/TUBE PEDCL W/WOTR FH/CH/CH/M/N/AX/G/H/F",
  },
  {
    code: "49446",
    shortdesc: "CONVERT GASTROSTOMY-GASTRO-JEJUNOSTOMY TUBE PERQ",
  },
  { code: "99396", shortdesc: "PERIODIC PREVENTIVE MED EST PATIENT 40-64YRS" },
  {
    code: "64605",
    shortdesc: "DSTRJ NEUROLYTIC TRIGEMINAL NRV 2/3 DIV BRANCH",
  },
  { code: "35582", shortdesc: "null" },
  {
    code: "78499",
    shortdesc: "UNLISTED CARDIOVASCULAR PX DX NUCLEAR MEDICINE",
  },
  {
    code: "43842",
    shortdesc: "GASTRIC RSTCV W/O BYP VERTICAL-BANDED GASTROPLY",
  },
  { code: "70320", shortdesc: "RADIOLOGIC EXAM TEETH COMPLETE FULL MOUTH" },
  {
    code: "97164",
    shortdesc: "PHYSICAL THERAPY RE-EVAL EST PLAN CARE 20 MINS",
  },
  { code: "68330", shortdesc: "RPR SYMBLEPHARON CONJUNCTIVOPLASTY W/O GRAFT" },
  { code: "49557", shortdesc: "RPR RECRT FEM HRNA INCARCERATED" },
  {
    code: "24077",
    shortdesc: "RAD RESECT TUMOR SOFT TISS UPPER ARM/ELBOW <5CM",
  },
  { code: "78456", shortdesc: "ACUTE VENOUS THROMBOSIS IMAGING PEPTIDE" },
  {
    code: "65110",
    shortdesc: "EXENTERATION ORBIT REMVL ORBITAL CONTENTS ONLY",
  },
  { code: "36819", shortdesc: "ARVEN ANAST OPN UPR ARM BASILIC VEIN TRPOS" },
  { code: "25652", shortdesc: "OPEN TREATMENT ULNAR STYLOID FRACTURE" },
  {
    code: "0279T",
    shortdesc: "CELL ENUMRTION W/IMMUN SELXN & ID FLUID SPECIMN",
  },
  { code: "24066", shortdesc: "BIOPSY SOFT TISSUE UPPER ARM/ELBOW AREA DEEP" },
  { code: "77333", shortdesc: "TX DEVICES DESIGN & CONSTRUCTION INTERMEDIATE" },
  {
    code: "49329",
    shortdesc: "UNLISTED LAPAROSCOPIC PX ABD PERTONEUM & OMENTUM",
  },
  { code: "3372F", shortdesc: "AJCC BREAST CANCER STAGE I T1MIC T1A/T1B" },
  { code: "20501", shortdesc: "INJECTION SINUS TRACT DIAGNOSTIC" },
  {
    code: "22586",
    shortdesc: "ARTHRODESIS PRESACRAL INTRBDY W/INSTRUMENT L5-S1",
  },
  {
    code: "33621",
    shortdesc: "TRANSTHORACIC CATHETER INSERTION FOR STENT PLMT",
  },
  {
    code: "1100F",
    shortdesc: "PT FALLS ASSESS DOCD 2/> FALLS/FALL W/INJURY/YR",
  },
  { code: "33924", shortdesc: "LIG&TKDN SYSIC-TO-PULM ART SHUNT W/CGEN HEART" },
  {
    code: "00868",
    shortdesc: "ANES XTRPRTL LWR ABD W/URIN TRACT RENAL TRANSPL",
  },
  {
    code: "21143",
    shortdesc: "RCNSTJ MIDFACE LEFORT I 3/> PIECE W/O BONE GRAFT",
  },
  {
    code: "0443T",
    shortdesc: "R-T SPCTRL ALYS PRST8 TISS FLUORESCENC SPCTRSCPY",
  },
  { code: "24365", shortdesc: "ARTHROPLASTY RADIAL HEAD" },
  { code: "68510", shortdesc: "BIOPSY LACRIMAL GLAND" },
  { code: "99202", shortdesc: "OFFICE OUTPATIENT NEW 20 MINUTES" },
  { code: "64415", shortdesc: "SINGLE NERVE BLOCK INJECTION ARM NERVE" },
  {
    code: "44202",
    shortdesc: "LAPS ENTERECT RESCJ 1 SMALL INTEST RESCJ & ANA",
  },
  { code: "69802", shortdesc: "LABYRINTHOTOMY MASTOIDECTOMY" },
  { code: "58290", shortdesc: "VAGINAL HYSTERECTOMY UTERUS > 250 GM" },
  { code: "96120", shortdesc: "NEUROPSYCHOLOG TESTING COMPUTER W/PROF I&R" },
  {
    code: "44800",
    shortdesc: "EXC MECKELS DIVERTICULUM/OMPHALOMESENTERIC DUCT",
  },
  { code: "82979", shortdesc: "ASSAY OF GLUTATHIONE REDUCTASE RBC" },
  {
    code: "85291",
    shortdesc: "CLOTTING FACTOR XIII FIBRN STABILIZ SCREEN SOLUB",
  },
  {
    code: "82128",
    shortdesc: "AMINO ACIDS MULTIPLE QUALITATIVE EACH SPECIMEN",
  },
  { code: "77077", shortdesc: "JOINT SURVEY SINGLE VIEW 2 OR MORE JOINTS" },
  { code: "52402", shortdesc: "CSTO W/TRURL RESCJ/INC EJACULATORY DUXS" },
  { code: "31605", shortdesc: "TRACHEOSTOMY EMERGENCY CRICOTHYROID MEMBRANE" },
  { code: "0094T", shortdesc: "null" },
  { code: "28150", shortdesc: "PHALANGECTOMY TOE EACH TOE" },
  {
    code: "13133",
    shortdesc: "REPAIR COMPLEX F/C/C/M/N/AX/G/H/F EA ADDL 5 CM/<",
  },
  { code: "92594", shortdesc: "ELECTROACOUS EVAL HEARING AID MONAURAL" },
  { code: "86945", shortdesc: "IRRADIATION BLOOD PRODUCT EACH UNIT" },
  {
    code: "42505",
    shortdesc: "PLSTC RPR SALIVARY DUX SIALODOCHOPLASTY SEC/COMP",
  },
  { code: "15879", shortdesc: "SUCTION ASSISTED LIPECTOMY LOWER EXTREMITY" },
  { code: "28107", shortdesc: "EXC/CURTG CST/B9 TUM TARSAL/METAR W/ALGRFT" },
  {
    code: "59051",
    shortdesc: "FETAL MONITR LABOR PHYS WRTTN REPRT INTERPJ ONLY",
  },
  { code: "63285", shortdesc: "LAM BX/EXC ISPI NEO IDRL IMED CERVICAL" },
  { code: "24201", shortdesc: "REMOVAL FOREIGN BODY UPPER ARM/ELBOW DEEP" },
  {
    code: "36221",
    shortdesc: "NONSLCTV CATH THOR AORTA ANGIO INTR/XTRCRANL ART",
  },
  { code: "0090U", shortdesc: "null" },
  { code: "86340", shortdesc: "INTRINSIC FACTOR ANTIBODIES" },
  {
    code: "81521",
    shortdesc: "ONC BREAST MRNA MICRORA GENE XPRSN PRFL 70 GENES",
  },
  { code: "49420", shortdesc: "null" },
  { code: "43845", shortdesc: "GASTRIC RSTCV W/PRTL GASTRECTOMY 50-100 CM" },
  { code: "0323T", shortdesc: "null" },
  {
    code: "93260",
    shortdesc: "PRGRMG DEV EVAL IMPLANTABLE SUBQ LEAD DFB SYSTEM",
  },
  {
    code: "0296T",
    shortdesc: "EXT ECG > 48HR TO 21 DAY RCRD W/CONECT INTL RCRD",
  },
  { code: "90953", shortdesc: "ESRD RELATED SVC MONTHLY <2 YR OLD 1 VISIT" },
  {
    code: "34421",
    shortdesc: "THRMBC DIR/W/CATH V/C ILIAC FEMPOP VEIN LEG INC",
  },
  {
    code: "23073",
    shortdesc: "EXC TUMOR SOFT TISSUE SHOULDER SUBFASCIAL 5 CM/>",
  },
  { code: "57155", shortdesc: "INSERTION UTERINE TANDEM&/VAGINAL OVOIDS" },
  {
    code: "22903",
    shortdesc: "EXC TUMOR SOFT TISSUE ABDOMINAL WALL SUBQ 3 CM/>",
  },
  {
    code: "44377",
    shortdesc: "ENTEROSC >2ND PRTN W/ILEUM W/BX SINGLE/MULTIPLE",
  },
  {
    code: "43251",
    shortdesc: "EGD REMOVAL TUMOR POLYP/OTHER LESION SNARE TECH",
  },
  { code: "78452", shortdesc: "MYOCARDIAL SPECT MULTIPLE STUDIES" },
  {
    code: "0335T",
    shortdesc: "EXTRA-OSSEOUS JOINT IMPLANT TALOTARSAL STABILIZE",
  },
  { code: "43271", shortdesc: "ERCP W/BALO DILAT AMPULLA BILIARY&/PNCRTC DUX" },
  {
    code: "43760",
    shortdesc: "CHANGE GASTROSTOMY TUBE PERCUTANEOUS W/O GDNCE",
  },
  { code: "15821", shortdesc: "BLEPHAROPLASTY LOWER EYELID HERNIATED FAT PAD" },
  { code: "90755", shortdesc: "null" },
  { code: "88150", shortdesc: "CYTP SLIDES C/V MNL SCR UNDER PHYS" },
  { code: "85024", shortdesc: "null" },
  { code: "85348", shortdesc: "COAGULATION TIME OTHER METHODS" },
  {
    code: "35390",
    shortdesc: "ROPRTJ CRTD TEAEC > 1 MO AFTER ORIGINAL OPRATIO",
  },
  { code: "0515T", shortdesc: "null" },
  {
    code: "81374",
    shortdesc: "HLA I LOW RESOLUTION ONE ANTIGEN EQUIVALENT EACH",
  },
  {
    code: "81206",
    shortdesc: "BCR/ABL1 MAJOR BREAKPNT QUALITATIVE/QUANTITATIVE",
  },
  { code: "57452", shortdesc: "COLPOSCOPY CERVIX UPPER/ADJACENT VAGINA" },
  { code: "96003", shortdesc: "DYN FINE WIRE EMG WALKG/FUNCJAL ACTV 1 MUSC" },
  { code: "27198", shortdesc: "CLSD TX PELVIC RING FX W/MANIPULATION W/ANES" },
  { code: "93000", shortdesc: "ECG ROUTINE ECG W/LEAST 12 LDS W/I&R" },
  { code: "51820", shortdesc: "CSTOURTP W/UNI/BI URTRONEOCSTOST" },
  {
    code: "66165",
    shortdesc: "FSTLJ SCLERA GLAUCOMA IRIDENCLEISIS/IRIDOTASIS",
  },
  { code: "87332", shortdesc: "IAAD IA CYTOMEGALOVIRUS" },
  {
    code: "86317",
    shortdesc: "IMMUNOASSAY INFECTIOUS AGENT ANTIBODY QUAN NOS",
  },
  {
    code: "33851",
    shortdesc: "EXC COARCJ AORTA W/L SUBCLAV ART/PROSTC GUSSET",
  },
  { code: "53505", shortdesc: "URETHRORRHAPHY SUTR URETHRAL WOUND/INJ PENILE" },
  {
    code: "83907",
    shortdesc: "MOLEC DX LYSIS CELLS PRIOR NUCLEIC ACID EXTRTJ",
  },
  { code: "19290", shortdesc: "PREOP PLACEMENT LOCALIZATION WIRE BREAST" },
  { code: "89050", shortdesc: "CELL COUNT MISCELLANEOUS BODY FLUIDS" },
  { code: "0076T", shortdesc: "TCAT PLMT XTRC VRT CRTD STENT RS&IPRQ EA VSL" },
  { code: "30545", shortdesc: "REPAIR CHOANAL ATRESIA TRANSPALATINE" },
  {
    code: "83009",
    shortdesc: "HPYLORI BLOOD ANAL UREASE ACT NON-RADACT ISOTOPE",
  },
  { code: "29058", shortdesc: "APPLICATION CAST PLASTER VELPEAU" },
  { code: "43215", shortdesc: "ESOPHAGOSCOPY FLEXIBLE REMOVAL FOREIGN BODY" },
  { code: "3760F", shortdesc: "PT W/DYSPHAG/WT LOSS/IMPAIRED NUTRITION" },
  {
    code: "36575",
    shortdesc: "RPR TUN/NON-TUN CTR VAD CATH W/O SUBQ PORT/PMP",
  },
  { code: "61501", shortdesc: "CRANIECTOMY OSTEOMYELITIS" },
  { code: "3288F", shortdesc: "FALLS RISK ASSESSMENT DOCUMENTED" },
  {
    code: "61526",
    shortdesc: "CRNEC TRANSTEMPOR EXC CEREBELLOPONTINE ANGLE TUM",
  },
  {
    code: "90674",
    shortdesc: "CCIIV4 VACCINE PRESERVATIVE FREE 0.5 ML IM USE",
  },
  { code: "93735", shortdesc: "null" },
  { code: "84143", shortdesc: "17-HYDROXYPREGNENOLONE" },
  { code: "92541", shortdesc: "SPONTANEOUS NYSTAGMUS TEST" },
  { code: "1128F", shortdesc: "SUBS EPISODE FOR CONDITION" },
  { code: "90632", shortdesc: "HEPA VACCINE ADULT DOSE FOR INTRAMUSCULAR USE" },
  { code: "47712", shortdesc: "EXC BILE DUX TUM W/WO PRIM RPR INTRAHEPATC" },
  { code: "35508", shortdesc: "BYPASS W/VEIN CAROTID-VERTEBRAL" },
  { code: "81221", shortdesc: "CFTR GENE ANALYSIS KNOWN FAMILIAL VARIANTS" },
  { code: "84181", shortdesc: "PROTEIN WESTRN BLOT I&R BLOOD/OTHER FLUID" },
  { code: "27781", shortdesc: "CLTX PROX FIBULA/SHFT FX W/MANJ" },
  {
    code: "4084F",
    shortdesc: "ASPIRIN RECVD W/IN 24 HRS PRIOR ED ARRIVAL/STAY",
  },
  { code: "72196", shortdesc: "MRI PELVIS W/CONTRAST MATERIAL" },
  { code: "99218", shortdesc: "INITIAL OBSERVATION CARE/DAY 30 MINUTES" },
  {
    code: "78111",
    shortdesc: "PLASMA VOL RADIOPHARM VOL DILUTE SPX MULT SMPLES",
  },
  { code: "52325", shortdesc: "CYSTO FRAGMENTATION URETERAL STONE" },
  {
    code: "72083",
    shortdesc: "RADEX ENTIR THRC LMBR CRV SAC SPI W/SKULL 4/5 VW",
  },
  {
    code: "68326",
    shortdesc: "CJP RCNSTJ CUL-DE-SAC BUCCAL GRF/XTNSV REARRGMT",
  },
  { code: "0003M", shortdesc: "null" },
  { code: "33845", shortdesc: "EXCISION COARCTATION AORTA W/WO PDA W/GRAFT" },
  { code: "93010", shortdesc: "ECG ROUTINE ECG W/LEAST 12 LDS I&R ONLY" },
  { code: "50300", shortdesc: "DONOR NEPHRECTOMY CADAVER DONOR UNI/BILATERAL" },
  { code: "67924", shortdesc: "REPAIR ENTROPION EXTENSIVE" },
  { code: "31255", shortdesc: "NASAL/SINUS NDSC W/TOTAL ETHOIDECTOMY" },
  {
    code: "42508",
    shortdesc: "PAROTID DUCT DIVERSION BI W/EXC 1 SUBMNDBLR GLND",
  },
  { code: "64647", shortdesc: "CHEMODENERVATION OF TRUNK 6 OR MORE MUSCLES" },
  { code: "54060", shortdesc: "DSTRJ LESION PENIS SIMPLE SURG EXCISION" },
  { code: "1150F", shortdesc: "DOC PT W/SUBSTANTIAL RISK DEATH WITHIN 1 YEAR" },
  { code: "74185", shortdesc: "MRA ABDOMEN W/WO CONTRAST MATERIAL" },
  {
    code: "0442T",
    shortdesc: "ABLTJ PERC CRYOABLTJ IMG GDN NRV PLEX/TRNCL NRV",
  },
  {
    code: "30125",
    shortdesc: "EXC DERMOID CYST NOSE COMPLEX UNDER BONE/CRTLG",
  },
  { code: "3017F", shortdesc: "COLORECTAL CANCER SCREENING RESULTS DOC&REV" },
  { code: "27445", shortdesc: "ARTHROPLASTY KNEE HINGE PROSTHESIS" },
  {
    code: "44130",
    shortdesc: "ENTEROENTEROST ANAST INT W/WO CUTAN NTRSTM SPX",
  },
  {
    code: "87045",
    shortdesc: "CUL BACT STOOL AEROBIC ISOL SALMONELLA&SHIGELL",
  },
  { code: "0083T", shortdesc: "null" },
  {
    code: "71010",
    shortdesc: "RADIOLOGIC EXAMINATION CHEST SINGLE VIEW FRONTAL",
  },
  {
    code: "22515",
    shortdesc: "PERQ VERT AGMNTJ CAVITY CRTJ UNI/BI CANNULJ EACH",
  },
  {
    code: "81229",
    shortdesc: "CYTOGENOM CONST MICROARRAY COPY NUMBER&SNP VAR",
  },
  { code: "27385", shortdesc: "SUTURE QUADRICEPS/HAMSTRING RUPTURE PRIMARY" },
  {
    code: "37192",
    shortdesc: "REPSNG INTRVAS VC FILTR W/WO ACS VSL SELXN RS&I",
  },
  { code: "15321", shortdesc: "ALGRFT TEMP CLOSURE F/S/N/H/F/G/M/D EA 100CM" },
  {
    code: "11643",
    shortdesc: "EXCISION MALIGNANT LESION F/E/E/N/L 2.1-3.0 CM",
  },
  { code: "58679", shortdesc: "UNLISTED LAPAROSCOPY PROCEDURE OVIDUCT/OVARY" },
  { code: "27146", shortdesc: "OSTEOTOMY ILIAC ACETABULAR/INNOMINATE BONE" },
  { code: "99451", shortdesc: "null" },
  { code: "47300", shortdesc: "MARSUPIALIZATION CST/ABSC LVR" },
  { code: "99408", shortdesc: "ALCOHOL/SUBSTANCE SCREEN & INTERVEN 15-30 MIN" },
  { code: "17314", shortdesc: "MOHS TRUNK/ARM/LEG EA STAGE AFTER 1ST STAGE" },
  { code: "88161", shortdesc: "CYTP SMRS ANY OTH SRC PREPJ SCR&INTERPJ" },
  { code: "73222", shortdesc: "MRI ANY JT UPPER EXTREMITY W/CONTRAST MATRL" },
  { code: "88187", shortdesc: "FLOW CYTOMETRY INTERPJ 2-8 MARKERS" },
  { code: "87520", shortdesc: "IADNA HEPATITIS C DIRECT PROBE TECHNIQUE" },
  { code: "32654", shortdesc: "THORACOSCOPY CONTROL TRAUMATIC HEMORRHAGE" },
  { code: "61868", shortdesc: "STRTCTC IMPLTJ NSTIM ELTRD W/RECORD EA ARRAY" },
  { code: "99433", shortdesc: "null" },
  { code: "89141", shortdesc: "null" },
  { code: "47360", shortdesc: "MGMT LVR HEMRRG CPLX SUTR WND/INJ" },
  { code: "26478", shortdesc: "LENGTHENING TENDON FLEXOR HAND/FINGER EACH" },
  {
    code: "27616",
    shortdesc: "RAD RESECTION TUMOR SOFT TISSUE LEG/ANKLE 5 CM/>",
  },
  { code: "0373T", shortdesc: "EXPOSURE BEHAVIOR TREATMENT FIRST 60 MIN" },
  { code: "90384", shortdesc: "RHO(D) IMMUNE GLOBULIN HUMAN FULL-DOSE IM" },
  { code: "15150", shortdesc: "CLTR SKIN AUTOGRAFT T/A/L 1ST 25 CM/<" },
  { code: "83887", shortdesc: "NICOTINE" },
  {
    code: "52260",
    shortdesc: "CYSTOURETHROSCOPY W/DIL BLADDER GENERAL ANESTH",
  },
  {
    code: "20937",
    shortdesc: "AUTOGRAFT SPINE SURGERY MORSELIZED SEP INCISION",
  },
  {
    code: "98940",
    shortdesc: "CHIROPRACTIC MANIPULATIVE TX SPINAL 1-2 REGIONS",
  },
  { code: "28446", shortdesc: "OPEN OSTEOCHONDRAL AUTOGRAFT TALUS" },
  { code: "61645", shortdesc: "PERQ ART TRLUML M-THROMBEC &/NFS INTRACRANIAL" },
  { code: "90805", shortdesc: "INDIV PSYCTX OFFICE/OUTPT 20-30 MIN W/MED E/M" },
  { code: "11043", shortdesc: "DEBRIDEMENT MUSCLE & FASCIA 20 SQ CM/<" },
  {
    code: "25316",
    shortdesc: "FLEXOR ORIGIN SLIDE F/ARM&/WRST TENDON TRANSFE",
  },
  {
    code: "24200",
    shortdesc: "RMVL FOREIGN BODY UPPER ARM/ELBOW SUBCUTANEOUS",
  },
  { code: "37188", shortdesc: "PRQ TRLUML MCHNL THRMBC VEIN REPEAT TX" },
  {
    code: "87253",
    shortdesc: "VIRUS TISSUE CULTURE ADDL STDY/ID EACH ISOLATE",
  },
  { code: "27618", shortdesc: "EXC TUMOR SOFT TISSUE LEG/ANKLE SUBQ <3CM" },
  { code: "12015", shortdesc: "SIMPLE REPAIR F/E/E/N/L/M 7.6CM-12.5 CM" },
  {
    code: "01756",
    shortdesc: "ANESTHESIA OPEN/SURG ARTHRS RADICAL PROC ELBOW",
  },
  { code: "2022F", shortdesc: "DILAT RETINAL EYE EXAM W/INTERP OPHTHAL/OPTOM" },
  {
    code: "47612",
    shortdesc: "CHOLECYSTECTOMY EXPL DUCT CHOLEDOCHOENTEROSTOMY",
  },
  { code: "43888", shortdesc: "GSTR RSTCV OPN RMVL & RPLCMT SUBQ PORT" },
  {
    code: "64645",
    shortdesc: "CHEMODENERVATION 1 EXTREMITY EA ADDL 5/> MUSCLES",
  },
  {
    code: "47533",
    shortdesc: "PRQ PLMT BILIARY DRG CATH W/IMG GID RS&I EXTERNL",
  },
  { code: "36469", shortdesc: "1/MLT NJXS SCLRSG SLNS SPIDER VEINS FACE" },
  { code: "97016", shortdesc: "APPL MODALITY 1/> AREAS VASOPNEUMATIC DEVICES" },
  { code: "92575", shortdesc: "SENSORINEURAL ACUITY LEVEL" },
  { code: "99222", shortdesc: "INITIAL HOSPITAL CARE/DAY 50 MINUTES" },
  {
    code: "15004",
    shortdesc: "PREP SITE F/S/N/H/F/G/M/D GT 1ST 100 SQ CM/1PCT",
  },
  {
    code: "33140",
    shortdesc: "TRANSMYOCARDIAL LASER REVASCULAR THORACOTOMY SPX",
  },
  {
    code: "27447",
    shortdesc: "ARTHRP KNE CONDYLE&PLATU MEDIAL&LAT COMPARTMENTS",
  },
  {
    code: "92242",
    shortdesc: "FLUORESCEIN ICG ANGRPH W/MULTIFRAME I&R UNI/BI",
  },
  { code: "25920", shortdesc: "DISARTICULATION THROUGH WRIST" },
  { code: "95978", shortdesc: "ELEC ALYS NSTIM PLS GEN CPLX DP BRN 1ST HR" },
  {
    code: "63088",
    shortdesc: "VCRPEC THORACOLMBR DCMPRN LWR THRC/LMBR EA SEG",
  },
  { code: "95874", shortdesc: "NEEDLE EMG GUID W/CHEMODENERVATION" },
  {
    code: "94680",
    shortdesc: "O2 UPTK EXP GAS ANALYSIS REST&XERS DIRECT SIMP",
  },
  { code: "64581", shortdesc: "INC IMPLTJ NEUROSTIMULATOR ELTRD SACRAL NERVE" },
  { code: "54057", shortdesc: "DSTRJ LESION PENIS SIMPLE LASER" },
  { code: "27520", shortdesc: "CLOSED TX PATELLAR FRACTURE W/O MANIPULATION" },
  { code: "52318", shortdesc: "LITHOLAPAXY COMP/LG > 2.5 CM" },
  { code: "87179", shortdesc: "null" },
  { code: "90734", shortdesc: "MCV4/MENACWY CONJ VACC GRPS ACYW-135 IM USE" },
  { code: "33600", shortdesc: "CLOSURE ATRIOVENTRICULAR VALVE SUTURE/PATCH" },
  { code: "35483", shortdesc: "null" },
  { code: "87252", shortdesc: "VIRUS TISS CUL INOCULATION CYTOPATHIC EFFECT" },
  { code: "27076", shortdesc: "RAD RESCT TUMOR ILIUM ACETABULUM BOTH PUBIC" },
  { code: "3215F", shortdesc: "DOCUMENTED IMMUNITY HEPATITIS A" },
  { code: "96131", shortdesc: "null" },
  {
    code: "85410",
    shortdesc: "FBRNLYC FACTORS&INHIBITORS ALPHA-2 ANTIPLASMIN",
  },
  { code: "53405", shortdesc: "URETHROPLASTY 2ND STAGE W/URINARY DIVERSION" },
  { code: "84307", shortdesc: "ASSAY OF SOMATOSTATIN" },
  { code: "80200", shortdesc: "DRUG SCREEN QUANTITATIVE TOBRAMYCIN" },
  { code: "4151F", shortdesc: "NO CURRENT HEPATITIS C ANTIVIRAL TREATMENT" },
  { code: "00170", shortdesc: "ANESTHESIA INTRAORAL WITH BIOPSY NOS" },
  { code: "0580F", shortdesc: "MULTIDISCIPLINARY CARE PLAN DEVELOPED/UPDATED" },
  { code: "88329", shortdesc: "PATHOLOGY CONSULTATION DURING SURGERY" },
  { code: "0474T", shortdesc: "INSJ ANT SEG AQUEOUS DRG DEV W/IO RSVR" },
  {
    code: "27768",
    shortdesc: "CLOSED TREATMENT PST MALLEOLUS FRACTURE W/MANJ",
  },
  {
    code: "91034",
    shortdesc: "GASTROESOPHAG REFLX TEST W/CATH PH ELTRD PLCMT",
  },
  {
    code: "32505",
    shortdesc: "THORACOTOMY W/THERAPEUTIC WEDGE RESEXN INITIAL",
  },
  { code: "24435", shortdesc: "REPAIR NON/MALUNION HUMERUS W/ILIAC/OTH AGRFT" },
  { code: "87208", shortdesc: "null" },
  { code: "30420", shortdesc: "RHINOPLASTY PRIMARY W/MAJOR SEPTAL REPAIR" },
  {
    code: "65815",
    shortdesc: "PARACEN ANT CHAM RMVL BLOOD W/WO IRRIG&/AIR IN",
  },
  {
    code: "33224",
    shortdesc: "INSJ ELTRD CAR VEN SYS ATTCH PREV PM/DFB PLS GEN",
  },
  { code: "57065", shortdesc: "DESTRUCTION VAGINAL LESIONS EXTENSIVE" },
  {
    code: "62303",
    shortdesc: "MYELOGRAPHY VIA LUMBAR INJECTION RS&I THORACIC",
  },
  {
    code: "25492",
    shortdesc: "PROPH TX N/P/PLTWR W/WO METHYLMECRYLATE RAD&UL",
  },
  { code: "3260F", shortdesc: "TUMOR/NODES/HISTO GRADE DOCUMENTED" },
  { code: "25825", shortdesc: "ARTHRODESIS WRIST LIMITED W/AUTOGRAFT" },
  { code: "67225", shortdesc: "DSTRJ LESION CHOROID PDT 2ND EYE 1 SESSION" },
  { code: "29580", shortdesc: "STRAPPING UNNA BOOT" },
  { code: "22326", shortdesc: "OPTX&/RDCTJ VRT FX&/DISLC PST 1 VRT SGM CR" },
  {
    code: "29847",
    shortdesc: "ARTHROSCOPY WRIST SURG INT FIXJ FX/INSTABILITY",
  },
  { code: "16025", shortdesc: "DRS&/DBRDMT PRTL-THKNS BURNS 1ST/SBSQ MEDIUM" },
  { code: "3090F", shortdesc: "MDD SEVERE WITHOUT PSYCHOTIC FEATURES" },
  { code: "10140", shortdesc: "I&D HEMATOMA SEROMA/FLUID COLLECTION" },
  { code: "56320", shortdesc: "null" },
  { code: "0318T", shortdesc: "null" },
  {
    code: "55865",
    shortdesc: "EXPOS PROSTATE INSJ RADIOAC SBST W/BI PELV LYMPH",
  },
  {
    code: "50900",
    shortdesc: "URETERORRHAPHY SUTURE URETER SEPARATE PROCEDURE",
  },
  {
    code: "25116",
    shortdesc: "RAD EXC BURSA SYNVA WRST/F/ARM TDN SHTHS XTNSRS",
  },
  { code: "88108", shortdesc: "CYTP CONCENTRATION SMEARS & INTERPRETATION" },
  { code: "45335", shortdesc: "SGMDSC FLX DIRED SBMCSL NJX ANY SBST" },
  {
    code: "63090",
    shortdesc: "VCRPEC TRANSPRTL/RPR DCMPRN THRC LMBR/SAC 1 SEG",
  },
  { code: "64772", shortdesc: "TRANSECTION/AVULSION OTH SPINAL NRV XDRL" },
  { code: "45990", shortdesc: "ANRCT XM SURG REQ ANES GENERAL SPI/EDRL DX" },
  {
    code: "36228",
    shortdesc: "SLCTV CATH INTRCRNL BRNCH ANGIO INTRL CAROT/VERT",
  },
  { code: "77605", shortdesc: "HYPERTHERMIA EXTERNAL GENERATED DEEP" },
  {
    code: "99285",
    shortdesc: "EMERGENCY DEPT VISIT HIGH SEVERITY&THREAT FUNCJ",
  },
  {
    code: "95870",
    shortdesc: "NEEDLE EMG LMTD STD MUSC 1 XTR/NON-LIMB UNI/BI",
  },
  {
    code: "32940",
    shortdesc: "PNEUMONOLYSIS XTRPRIOSTEAL W/FILLING/PACKING PX",
  },
  {
    code: "92944",
    shortdesc: "PRQ TRLUML CORONRY CHRNIC OCCLUS REVASC ADDL VSL",
  },
  { code: "96411", shortdesc: "CHEMOTX ADMN IV PUSH TQ EA SBST/DRUG" },
  {
    code: "95148",
    shortdesc: "PREPJ& ANTIGEN ALLERGEN IMMUNOTHERAPY 4 INSECT",
  },
  { code: "31755", shortdesc: "TRACHEOPLASTY TRACHEOPHARYNGEAL FSTLJ EA STG" },
  {
    code: "0351T",
    shortdesc: "INTRAOP OCT BREAST OR AXILL NODE EACH SPECIMEN",
  },
  { code: "88720", shortdesc: "BILIRUBIN TOTAL TRANSCUTANEOUS" },
  {
    code: "14040",
    shortdesc: "ADJT TIS TRNS/REARGMT F/C/C/M/N/A/G/H/F 10SQCM/<",
  },
  {
    code: "32653",
    shortdesc: "THORACOSCOPY RMVL INTRAPLEURAL FB/FIBRIN DEPOSIT",
  },
  {
    code: "31627",
    shortdesc: "BRONCHOSCOPY W/CPTR-ASST IMAGE-GUIDED NAVIGATION",
  },
  { code: "33256", shortdesc: "ABLATION & RCNSTJ ATRIA EXTNSV W/BYPASS" },
  {
    code: "22015",
    shortdesc: "I&D DEEP ABSCESS PST SPINE LUMBAR SAC/LUMBOSAC",
  },
  { code: "57288", shortdesc: "SLING OPERATION STRESS INCONTINENCE" },
  {
    code: "33517",
    shortdesc: "CORONARY ARTERY BYP W/VEIN & ARTERY GRAFT 1 VEIN",
  },
  { code: "43214", shortdesc: "ESOPHAGOSCOPY DILATE ESOPHAGUS BALLOON 30 MM" },
  { code: "4136F", shortdesc: "SYSTEMIC CORTICOSTEROIDS NOT PRESCRIBED" },
  { code: "0008M", shortdesc: "null" },
  {
    code: "49442",
    shortdesc: "INSERT CECOSTOMY/OTHER COLONIC TUBE PERCUTANEOUS",
  },
  { code: "90732", shortdesc: "PPSV23 VACCINE 2 YRS OR OLDER FOR SUBQ/IM USE" },
  { code: "32960", shortdesc: "PNEUMOTHORAX THER INTRAPLEURAL INJECTION AIR" },
  { code: "27090", shortdesc: "REMOVAL HIP PROSTHESIS SEPARATE PROCEDURE" },
  { code: "21432", shortdesc: "OPEN TX CRANIOFACIAL SEP W/WIRING&/INT FIXJ" },
  { code: "23350", shortdesc: "INJECTION SHOULDER ARTHROGRAPHY/ CT/MRI ARTHG" },
  { code: "24150", shortdesc: "RADICAL RESECTION TUMOR SHAFT/DISTAL HUMERUS" },
  { code: "63287", shortdesc: "LAM BX/EXC ISPI NEO IDRL IMED THORACOLMBR" },
  { code: "22315", shortdesc: "CLTX VRT FX&/DISLC CSTING/BRACING MANJ/TRCJ" },
  { code: "75954", shortdesc: "EVASC RPR ILIAC ART W/ILIO-ILIAC PROSTH RS&I" },
  {
    code: "26520",
    shortdesc: "CAPSULECTOMY/CAPSULOTOMY MTCARPHLNGL JOINT EACH",
  },
  { code: "78215", shortdesc: "LIVER & SPLEEN IMAGING STATIC ONLY" },
  { code: "83872", shortdesc: "MUCIN SYNOVIAL FLUID ROPES TEST" },
  { code: "86141", shortdesc: "C-REACTIVE PROTEIN HIGH SENSITIVITY" },
  {
    code: "46930",
    shortdesc: "DESTRUCTION INTERNAL HEMORRHOID THERMAL ENERGY",
  },
  {
    code: "87184",
    shortdesc: "SUSCEPTIBILITY STUDY ANTIMICROBIAL DISK METHOD",
  },
  { code: "47400", shortdesc: "HEPATCOTOMY/HEPATCOSTOMY W/EXPL DRG/RMVL ST1" },
  { code: "31717", shortdesc: "CATHETERIZATION W/BRONCHIAL BRUSH BIOPSY" },
  { code: "23065", shortdesc: "BIOPSY SOFT TISSUE SHOULDER SUPERFICIAL" },
  { code: "15789", shortdesc: "CHEMICAL PEEL FACIAL DERMAL" },
  {
    code: "88233",
    shortdesc: "TISS CUL NON-NEO DISORDERS SKN/OTH SOLID TISS BX",
  },
  { code: "97152", shortdesc: "null" },
  { code: "47383", shortdesc: "ABLATION 1/> LIVER TUMOR PERQ CRYOABLATION" },
  { code: "45820", shortdesc: "CLOSURE RECTOURETHRAL FISTULA" },
  { code: "65093", shortdesc: "EVISCERATION OCULAR CONTENTS W/IMPLANT" },
  {
    code: "59121",
    shortdesc: "TX ECTOPIC PREGNANCY W/O SALPING&/OOPHORECTOMY",
  },
  {
    code: "78003",
    shortdesc: "PLASMA VOL RADIOPHARM VOL DILUTION SPX 1 SAMPLE",
  },
  { code: "75705", shortdesc: "ANGIOGRAPHY SPINAL SELECTIVE RS&I" },
  {
    code: "36556",
    shortdesc: "INSJ NON-TUNNELED CENTRAL VENOUS CATH AGE 5 YR/>",
  },
  {
    code: "27035",
    shortdesc: "DNRVTJ HIP JT INTRAPEL/XTRPEL INTRA-ARTCLR BRNCH",
  },
  {
    code: "0447T",
    shortdesc: "RMVL IMPLTBL GLUCOSE SENSOR SUBQ POCKET VIA INC",
  },
  { code: "64620", shortdesc: "DSTRJ NEUROLYTIC AGENT INTERCOSTAL NERVE" },
  { code: "82533", shortdesc: "CORTISOL TOTAL" },
  {
    code: "29835",
    shortdesc: "ARTHROSCOPY ELBOW SURGICAL SYNOVECTOMY PARTIAL",
  },
  {
    code: "83516",
    shortdesc: "IMMUNOASSAY ANALYTE QUAL/SEMIQUAL MULTIPLE STEP",
  },
  { code: "81509", shortdesc: "FETAL CONGENITAL ABNOR ASSAY 3 PROTEINS" },
  { code: "56351", shortdesc: "null" },
  { code: "25120", shortdesc: "EXCISION/CURETTAGE CYST/TUMOR RADIUS/ULNA" },
  {
    code: "27422",
    shortdesc: "RCNSTJ DISLC PATELLA W/XTNSR RELIGNMT&/MUSC RL",
  },
  {
    code: "62318",
    shortdesc: "NJXS INFUS/BOLUS DX/SBST EDRL/SUBARACH CRV/THRC",
  },
  { code: "35515", shortdesc: "BYPASS W/VEIN SUBCLAVIAN-VERTEBRAL" },
  { code: "84585", shortdesc: "ASSAY OF VANILLYLMANDELIC ACID URINE" },
  { code: "98966", shortdesc: "NONPHYSICIAN TELEPHONE ASSESSMENT 5-10 MIN" },
  { code: "77080", shortdesc: "DXA BONE DENSITY STUDY 1/> SITES AXIAL SKEL" },
  {
    code: "61650",
    shortdesc: "EVASC INTRACRANIAL PROLNG ADMN RX AGENT ART 1ST",
  },
  { code: "22523", shortdesc: "PERCUTANEOUS VERTEBRAL AUGMENTATION THORACIC" },
  { code: "81172", shortdesc: "null" },
  { code: "52327", shortdesc: "CYSTO W/SUBURTRIC NJX IMPLT MATRL" },
  { code: "99304", shortdesc: "INITIAL NURSING FACILITY CARE/DAY 25 MINUTES" },
  { code: "95957", shortdesc: "DIGITAL ANALYSIS ELECTROENCEPHALOGRAM" },
  { code: "35901", shortdesc: "EXCISION INFECTED NECK GRAFT" },
  {
    code: "81257",
    shortdesc: "HBA1/HBA2 GENE ANALYSIS COMMON DELETIONS/VARIANT",
  },
  { code: "0514T", shortdesc: "null" },
  { code: "82261", shortdesc: "BIOTINIDASE EACH SPECIMEN" },
  { code: "91200", shortdesc: "LIVER ELASTOGRAPHY W/O IMAG W/I&R" },
  { code: "46753", shortdesc: "GRAFT THIERSCH RCT INCONTINENCE &/PROLAPSE" },
  { code: "36580", shortdesc: "RPLCMT COMPL NON-TUN CVC W/O SUBQ PORT/PMP" },
  { code: "97170", shortdesc: "ATHLETIC TRAINING EVAL MOD COMPLEX 30 MINS" },
  { code: "77385", shortdesc: "INTENSITY MODULATED RADIATION TX DLVR SIMPLE" },
  { code: "64627", shortdesc: "DSTRJ NULYT PVRT FACET JT NRV CRV/THRC EA LVL" },
  { code: "99214", shortdesc: "OFFICE OUTPATIENT VISIT 25 MINUTES" },
  {
    code: "28114",
    shortdesc: "OSTC COMPL ALL METAR HEADS W/PRTL PROX PHALANGC",
  },
  { code: "76776", shortdesc: "US TRNSPLNT KIDNEY REAL TIME W/IMAGE DOCMTN" },
  { code: "67101", shortdesc: "RPR RETINAL DTCHMNT DRG SUBRETINAL FLUID CRTX" },
  {
    code: "62304",
    shortdesc: "MYELOGRAPHY VIA LUMBAR INJECT RS&I LUMBOSACRAL",
  },
  { code: "25295", shortdesc: "TNOLS FLXR/XTNSR TENDON FOREARM&/WRIST 1 EA" },
  { code: "55040", shortdesc: "EXCISION HYDROCELE UNILATERAL" },
  { code: "21300", shortdesc: "null" },
  { code: "86287", shortdesc: "null" },
  { code: "87046", shortdesc: "CUL BACT STOOL AEROBIC ADDL PATHOGENS&ID EA" },
  {
    code: "63081",
    shortdesc: "VERTEBRAL CORPECTOMY ANT DCMPRN CERVICAL 1 SEG",
  },
  { code: "73721", shortdesc: "MRI ANY JT LOWER EXTREM W/O CONTRAST MATRL" },
  { code: "80005", shortdesc: "null" },
  { code: "27580", shortdesc: "ARTHRODESIS KNEE ANY TECHNIQUE" },
  { code: "56350", shortdesc: "null" },
  { code: "42845", shortdesc: "RADICAL RESCJ TONSIL CLOSURE W/OTHER FLAP" },
  {
    code: "67840",
    shortdesc: "EXC LESION EYELID W/O CLSR/W/SIMPLE DIR CLOSURE",
  },
  { code: "0430T", shortdesc: "REMOVAL NSTIM SYSTEM SLEEP APNEA STIMJ LEAD" },
  { code: "50433", shortdesc: "PLMT NEPHROURETERAL CATH PRQ NEW ACCESS RS&I" },
  { code: "64913", shortdesc: "NERVE REPAIR W/NERVE ALLOGRAFT EA ADDL STRAND" },
  { code: "29705", shortdesc: "REMOVAL/BIVALVING FULL ARM/FULL LEG CAST" },
  { code: "99295", shortdesc: "null" },
  { code: "99500", shortdesc: "HOME VISIT PRENATAL MONITORING & ASSESSMENT" },
  {
    code: "69320",
    shortdesc: "RCNSTJ XTRNL AUD CANAL CONGENITAL ATRESIA 1 STG",
  },
  { code: "3451F", shortdesc: "DYSPNEA SCRND MOD-SEVERE DYSPNEA" },
  {
    code: "28420",
    shortdesc: "OPEN TREATMENT CALCANEAL FRACTURE W BONE GRAFT",
  },
  { code: "81241", shortdesc: "F5 COAGULATION FACTOR V ANAL LEIDEN VARIANT" },
  { code: "72197", shortdesc: "MRI PELVIS W/O & W/CONTRAST MATERIAL" },
  { code: "3386F", shortdesc: "AJCC COLON CANCER STAGE II" },
  { code: "17105", shortdesc: "null" },
  {
    code: "38115",
    shortdesc: "RPR RPTD SPLEEN SPLENORRHAPHY W/WO PRTL SPLENECT",
  },
  { code: "21550", shortdesc: "BIOPSY SOFT TISSUE NECK/THORAX" },
  { code: "42227", shortdesc: "LENGTHENING PALATE W/ISLAND FLAP" },
  { code: "3014F", shortdesc: "SCREENING MAMMOGRAPHY RESULTS DOC&REV" },
  {
    code: "63664",
    shortdesc: "REVJ INCL RPLCMT NSTIM ELTRD PLT/PDLE INCL FLUOR",
  },
  { code: "42650", shortdesc: "DILATION SALIVARY DUCT" },
  {
    code: "42810",
    shortdesc: "EXC BRANCHIAL CLEFT CYST CONFINED SKN&SUBQ TIS",
  },
  { code: "0017T", shortdesc: "null" },
  { code: "99058", shortdesc: "SVC PRV EMER BASIS IN OFFICE DISRUPTING SVCS" },
  { code: "91032", shortdesc: "null" },
  { code: "4070F", shortdesc: "DEEP VEIN THROMB PROPHYL RECVD BY HOSP DAY 2" },
  {
    code: "0520F",
    shortdesc: "RAD DOSE LIMTS EST PRIOR3D RAD FOR MIN 2 TIS/ORG",
  },
  { code: "83071", shortdesc: "HEMOSIDERIN QUANTITATIVE" },
  {
    code: "34490",
    shortdesc: "THRMBC DIR/W/CATH AXILL&SUBCLAVIAN VEIN ARM IN",
  },
  { code: "88040", shortdesc: "NECROPSY FORENSIC EXAMINATION" },
  { code: "52317", shortdesc: "LITHOLAPAXY SMPL/SM <2.5 CM" },
  { code: "27372", shortdesc: "REMOVAL FOREIGN BODY DEEP THIGH/KNEE" },
  {
    code: "19086",
    shortdesc: "BX BREAST W/DEVICE ADDL LESION MAGNET RES GUID",
  },
  { code: "81300", shortdesc: "MSH6 GENE ANALYSIS DUPLICATION/DELETION VARIA" },
  {
    code: "15838",
    shortdesc: "EXC EXCSV SKIN & SUBQ TISSUE SUBMENTAL FAT PAD",
  },
  {
    code: "21081",
    shortdesc: "IMPRESSION & PREPJ MANDIBULAR RESECTION PROSTHES",
  },
  { code: "96912", shortdesc: "PHOTOCHEMOTX PSORALENS&ULTRAVIOLET PUVA" },
  { code: "83010", shortdesc: "ASSAY OF HAPTOGLOBIN QUANTITATIVE" },
  {
    code: "31288",
    shortdesc: "NSL/SINUS NDSC SPHENDT RMVL TISS SPHENOID SINUS",
  },
  { code: "29867", shortdesc: "ARTHROSCOPY KNEE OSTEOCHONDRAL ALLOGRAFT" },
  { code: "23615", shortdesc: "OPEN TREATMENT PROXIMAL HUMERAL FRACTURE" },
  {
    code: "50557",
    shortdesc: "RENAL NDSC NEPHROS/PYELOSTOMY FULG&/INC W/WO BI",
  },
  {
    code: "32560",
    shortdesc: "INSTLJ VIA CHEST TUBE/CATH AGENT FOR PLEURODESIS",
  },
  { code: "95937", shortdesc: "NEUROMUSCULAR JUNCT TSTG EA NRV ANY 1 METH" },
  { code: "86720", shortdesc: "ANTIBODY LEPTOSPIRA" },
  { code: "82672", shortdesc: "ASSAY OF ESTROGENS TOTAL" },
  {
    code: "49424",
    shortdesc: "CNTRST NJX ASSMT ABSC/CST VIA DRG CATH/TUBE SPX",
  },
  { code: "43229", shortdesc: "ESOPHAGOSCOPY FLEX TRANSORAL LESION ABLATION" },
  {
    code: "93724",
    shortdesc: "ELECTRONIC ANALYSIS ANTITACHY PACEMAKER SYSTEM",
  },
  { code: "44133", shortdesc: "DONOR ENTERECTOMY OPEN LIVING DONOR" },
  {
    code: "38230",
    shortdesc: "BONE MARROW HARVEST TRANSPLANTATION ALLOGENEIC",
  },
  {
    code: "36479",
    shortdesc: "ENDOVEN ABLTJ INCMPTNT VEIN XTR LASER 2ND+ VEINS",
  },
  {
    code: "21247",
    shortdesc: "RCNSTJ MNDBLR CONDYLE W/BONE CARTLG AUTOGRAFTS",
  },
  { code: "64577", shortdesc: "INC IMPLTJ NSTIM ELTRDS AUTONOMIC NRV" },
  { code: "75979", shortdesc: "null" },
  {
    code: "27695",
    shortdesc: "RPR PRIMARY DISRUPTED LIGAMENT ANKLE COLLATERAL",
  },
  { code: "90697", shortdesc: "DTAP-IPV-HIB-HEPB VACCINE INTRAMUSCULAR" },
  { code: "27267", shortdesc: "CLOSED TX FEMORAL FRACTURE PROX HEAD W/O MANJ" },
  { code: "64866", shortdesc: "ANASTOMOSIS FACIAL-SPINAL ACCESSORY" },
  { code: "87277", shortdesc: "IAADI LEGIONELLA MICDADEI" },
  { code: "85557", shortdesc: "OSMOTIC FRAGILITY RBC INCUBATED" },
  { code: "13100", shortdesc: "REPAIR COMPLEX TRUNK 1.1-2.5 CM" },
  { code: "00635", shortdesc: "ANES DIAGNOSTIC/THERAPEUTIC LUMBAR PUNCTURE" },
  { code: "97250", shortdesc: "null" },
  {
    code: "45386",
    shortdesc: "COLSC FLEXIBLE W/TRANSENDOSCOPIC BALLOON DILAT",
  },
  {
    code: "21015",
    shortdesc: "RAD RESECTION TUMOR SOFT TISS FACE/SCALP < 2CM",
  },
  { code: "20951", shortdesc: "null" },
  { code: "35481", shortdesc: "null" },
  {
    code: "97607",
    shortdesc: "NEG PRESSURE WOUND THERAPY NON DME </= 50 SQ CM",
  },
  { code: "3218F", shortdesc: "HEP C RNA TEST 6 MOS BEFORE ANTIVIRAL TX" },
  { code: "97032", shortdesc: "APPL MODALITY 1/> AREAS ELEC STIMJ EA 15 MIN" },
  { code: "55705", shortdesc: "BIOPSY PROSTATE INCISIONAL ANY APPROACH" },
  {
    code: "71101",
    shortdesc: "RADEX RIBS UNI W/POSTEROANT CH MINIMUM 3 VIEWS",
  },
  { code: "76979", shortdesc: "null" },
  { code: "62275", shortdesc: "null" },
  { code: "11047", shortdesc: "DEBRIDEMENT BONE EACH ADDITIONAL 20 SQ CM" },
  { code: "67911", shortdesc: "CORRECTION LID RETRACTION" },
  { code: "94729", shortdesc: "CO DIFFUSING CAPACITY" },
  {
    code: "59072",
    shortdesc: "FETAL UMBILICAL CORD OCCLUSION W/ULTRSND GUIDNCE",
  },
  { code: "64786", shortdesc: "EXCISION NEUROMA SCIATIC NERVE" },
  { code: "87299", shortdesc: "IAADI NOT OTHERWISE SPECIFIED EACH ORGANISM" },
  { code: "28171", shortdesc: "RAD RESCJ TUMOR TARSAL EXCEPT TALUS/CALCANEUS" },
  { code: "47505", shortdesc: "INJ PX CHOLANGIOGRAPHY VIA CATHETER/T-TUBE" },
  { code: "57308", shortdesc: "CLSR RECTOVAG FSTL TPRNL PRNL BDY RCNSTJ" },
  { code: "20664", shortdesc: "APPL HALO 6/> PINS THIN SKULL OSTEOLOGY" },
  { code: "72255", shortdesc: "MYELOGRAPHY THORACIC RS&I" },
  { code: "28645", shortdesc: "OPEN TX METATARSOPHALANGEAL JOINT DISLOCATION" },
  { code: "93556", shortdesc: "null" },
  { code: "0137U", shortdesc: "null" },
  { code: "57555", shortdesc: "EXC CRV STUMP VAG APPR W/ANT &/POST REPAIR" },
  { code: "95907", shortdesc: "NERVE CONDUCTION STUDIES 1-2 STUDIES" },
  {
    code: "43773",
    shortdesc: "LAPS GASTRIC RESTRICTIVE PX REMOVE&RPLCMT DEVICE",
  },
  {
    code: "3471F",
    shortdesc: "RHEUMATOID ARTHRITIS (RA) DISEASE ACTIVITY MOD",
  },
  {
    code: "81025",
    shortdesc: "URINE PREGNANCY TEST VISUAL COLOR CMPRSN METHS",
  },
  { code: "27500", shortdesc: "CLOSED TX FEMORAL SHAFT FX W/O MANIPULATION" },
  { code: "27654", shortdesc: "REPAIR SECONDARY ACHILLES TENDON W/WO GRAFT" },
  {
    code: "32220",
    shortdesc: "DECORTICATION PULMONARY TOTAL SEPARATE PROCEDURE",
  },
  { code: "76080", shortdesc: "RADEX ABSCESS/FISTULA/SINUS TRACT RS&I" },
  { code: "55980", shortdesc: "INTERSEX SURG FEMALE MALE" },
  {
    code: "21933",
    shortdesc: "EXC TUMOR SOFT TISS BACK/FLANK SUBFASCIAL 5 CM/>",
  },
  {
    code: "15941",
    shortdesc: "EXC ISCHIAL PR ULC W/PRIM SUTR W/OSTC ISCHIECT",
  },
  { code: "84410", shortdesc: "ASSAY BIOVLBL TESTOSTERONE DIRECT MEASUREMENT" },
  { code: "87620", shortdesc: "IADNA PAPILLOMAVIRUS HUMAN DIRECT PROBE TQ" },
  { code: "69670", shortdesc: "MASTOID OBLITERATION SEPARATE PROCEDURE" },
  { code: "69610", shortdesc: "TYMPANIC MEMB RPR W/WO PREPJ PERFOR PATCH" },
  {
    code: "92227",
    shortdesc: "REMOTE IMG DX RETINL DIS W/ALYS & REPORT UNI/B",
  },
  { code: "93270", shortdesc: "XTRNL PT ACTIVATED ECG RECORD MONITOR 30 DAYS" },
  { code: "2016F", shortdesc: "ASTHMA RISK ASSESSED" },
  {
    code: "11442",
    shortdesc: "EXC B9 LES MRGN XCP SK TG F/E/E/N/L/M 1.1-2.0CM",
  },
  { code: "78761", shortdesc: "TESTICULAR IMAGING WITH VASCULAR FLOW" },
  { code: "68040", shortdesc: "EXPRESSION CONJUNCTIVAL FOLLICLES" },
  { code: "4230F", shortdesc: "ANTICONVUL MED THERAPY 6 MOS/>" },
  { code: "38765", shortdesc: "INGUINOFEM LMPHADEC SUPFC W/PEL LMPHADEC" },
  {
    code: "25272",
    shortdesc: "RPR TDN/MUSC XTNSR F/ARM&/WRIST SEC 1 EA TDN/MU",
  },
  { code: "59120", shortdesc: "TX ECTOPIC PREGNANCY ABDOMINAL/VAGINAL APPR" },
  { code: "26060", shortdesc: "TENOTOMY PERCUTANEOUS SINGLE EACH DIGIT" },
  { code: "0189T", shortdesc: "VIDEOCONFERENCED CRITICAL CARE EA ADDL 30 MIN" },
  { code: "3093F", shortdesc: "DOC NEW DIAG DX INIT/RECURRENT EPISODE OF MDD" },
  { code: "97026", shortdesc: "APPLICATION MODALITY 1/> AREAS INFRARED" },
  { code: "25028", shortdesc: "I&D FOREARM&/WRIST DEEP ABSCESS/HEMATOMA" },
  { code: "68745", shortdesc: "CONJUNCTIVORHINOSTOMY W/O TUBE" },
  { code: "21122", shortdesc: "GENIOPLASTY 2/> SLIDING OSTEOTOMIES" },
  { code: "85046", shortdesc: "BLOOD COUNT RETICULOCYTES AUTO 1/> CELL MEAS" },
  { code: "91100", shortdesc: "null" },
  { code: "10080", shortdesc: "INCISION & DRAINAGE PILONIDAL CYST SIMPLE" },
  { code: "82044", shortdesc: "URINE ALBUMIN SEMIQUANTITATIVE" },
  { code: "82384", shortdesc: "CATECHOLAMINES FRACTIONATED" },
  { code: "62164", shortdesc: "NEUROENDOSCOPY ICRA W/RETRIEVAL FOREIGN BODY" },
  { code: "11463", shortdesc: "EXCISION HIDRADENITIS INGUINAL COMPLEX REPAIR" },
  { code: "58103", shortdesc: "null" },
  { code: "0240T", shortdesc: "ESOPH MOTILITY 3D PRESSURE TOPOGRAPHY W/I&R" },
  {
    code: "90937",
    shortdesc: "HEMODIALYSIS PX REPEAT EVAL W/WO REVJ DIALYS RX",
  },
  {
    code: "11100",
    shortdesc: "BX SKIN SUBCUTANEOUS&/MUCOUS MEMBRANE 1 LESION",
  },
  { code: "28010", shortdesc: "TENOTOMY PERCUTANEOUS TOE SINGLE TENDON" },
  {
    code: "92230",
    shortdesc: "FLUORESCEIN ANGIOSCOPY INTERPRETATION & REPORT",
  },
  { code: "54535", shortdesc: "ORCHIECTOMY RADICAL TUMOR W/ABDOMINAL EXPL" },
  {
    code: "22844",
    shortdesc: "POSTERIOR SEGMENTAL INSTRUMENTATION 13/> VRT SE",
  },
  { code: "88000", shortdesc: "NECROPSY GROSS EXAMINATION ONLY W/O CNS" },
  {
    code: "47145",
    shortdesc: "BKBENCH PREPJ CADAVER DONOR WHL LVR GRF I&V VI",
  },
  {
    code: "93530",
    shortdesc: "R HRT CATHETERIZATION CONGENITAL CARDIAC ANOMALY",
  },
  {
    code: "76811",
    shortdesc: "US PREG UTERUS W/DETAIL FETAL ANAT 1ST GESTATION",
  },
  {
    code: "21084",
    shortdesc: "IMPRESSION & PREPARATION SPEECH AID PROSTHESIS",
  },
  {
    code: "31296",
    shortdesc: "NASAL/SINUS NDSC SURG W/DILATION FRONTAL SINUS",
  },
  {
    code: "11310",
    shortdesc: "SHAVING SKIN LESION 1 F/E/E/N/L/M DIAM 0.5 CM/<",
  },
  { code: "97122", shortdesc: "null" },
  { code: "70551", shortdesc: "MRI BRAIN BRAIN STEM W/O CONTRAST MATERIAL" },
  { code: "87400", shortdesc: "IAAD IA INFLUENZA A/B EACH" },
  { code: "66710", shortdesc: "CILIARY BODY DSTRJ CYCLOPHOTOCOAG TRANSSCERAL" },
  { code: "14021", shortdesc: "ADJT/REARRGMT SCALP/ARM/LEG 10.1-30.0 SQ CM" },
  { code: "80341", shortdesc: "ANTIEPILEPTICS NOT OTHERWISE SPECIFIED 7/MORE" },
  { code: "68550", shortdesc: "EXC LACRIMAL GLAND TUMOR W/OSTEOTOMY" },
  { code: "26034", shortdesc: "INCISION BONE CORTEX HAND/FINGER" },
  { code: "47120", shortdesc: "HEPATECTOMY RESCJ PARTIAL LOBECTOMY" },
  { code: "17307", shortdesc: "null" },
  { code: "21120", shortdesc: "GENIOPLASTY AUGMENTATION" },
  { code: "90832", shortdesc: "PSYCHOTHERAPY W/PATIENT 30 MINUTES" },
  { code: "63017", shortdesc: "LAMINECTOMY W/O FFD > 2 VERT SEG LUMBAR" },
  { code: "53415", shortdesc: "URTP TRANSPUBIC/PRNL 1 STG RCNSTJ/RPR URT" },
  { code: "0533T", shortdesc: "null" },
  { code: "88189", shortdesc: "FLOW CYTOMETRY INTERPRETATION 16/> MARKERS" },
  { code: "83951", shortdesc: "ONCOPROTEIN DES-GAMMA-CARBOXY-PROTHROMBIN DCP" },
  { code: "52005", shortdesc: "CYSTO BLADDER W/URETERAL CATHETERIZATION" },
  { code: "86651", shortdesc: "ANTIBODY ENCEPHALITIS CALIFORNIA LA CROSSE" },
  { code: "0367T", shortdesc: "GROUP BEHAVIOR TREATMENT ADDL 30 MIN" },
  {
    code: "26357",
    shortdesc: "RPR/ADVMNT FLXR TDN ZONE 2 W/O FR GRFT EA TENDON",
  },
  {
    code: "21705",
    shortdesc: "DIVISION SCALENUS ANTICUS RESECTION CERVICAL RIB",
  },
  { code: "20551", shortdesc: "INJECTION SINGLE TENDON ORIGIN/INSERTION" },
  { code: "42821", shortdesc: "TONSILLECTOMY & ADENOIDECTOMY AGE 12/>" },
  {
    code: "66185",
    shortdesc: "REVJ AQUEOUS SHUNT EXTRAOCULAR RESERVOIR W/GRAFT",
  },
  { code: "21244", shortdesc: "RCNSTJ MNDBL XTRORAL W/TRANSOSTEAL BONE PLATE" },
  { code: "73140", shortdesc: "RADEX FINGR MINIMUM 2 VIEWS" },
  {
    code: "63663",
    shortdesc: "REVJ INCL RPLCMT NSTIM ELTRD PRQ RA INCL FLUOR",
  },
  { code: "22812", shortdesc: "ARTHRODESIS POSTERIOR SPINAL DFRM 8/> VRT SEG" },
  { code: "4168F", shortdesc: "PT RCVG CARE ICU & RCVNG MECH VENT 24 HRS/<" },
  { code: "82045", shortdesc: "ALBUMIN ISCHEMIA MODIFIED" },
  { code: "37195", shortdesc: "THROMBOLYSIS CEREBRAL IV INFUSION" },
  { code: "34705", shortdesc: "EVASC RPR DPLMNT AORTO-BI-ILIAC NDGFT" },
  {
    code: "21188",
    shortdesc: "RCNSTJ MDFC OTH/THN LEFORT OSTEOT & BONE GRAFTS",
  },
  { code: "50394", shortdesc: "INJECTION PROCEDURE PYELOGRAPHY VIA TUBE/CATH" },
  {
    code: "32701",
    shortdesc: "THORAX STEREOTACTIC RADIATION TARGET W/TX COURSE",
  },
  { code: "54901", shortdesc: "EPIDIDYMOVASOSTOMY ANAST EPIDIDYMIS BI" },
  { code: "80326", shortdesc: "DRUG SCREEN QUANT AMPHETAMINES 5 OR MORE" },
  {
    code: "43204",
    shortdesc: "ESOPHAGOSCOPY FLEX TRANSORAL INJECTION VARICES",
  },
  { code: "20987", shortdesc: "null" },
  { code: "0010T", shortdesc: "null" },
  {
    code: "37619",
    shortdesc: "INS INTRVAS VC FILTR W/WO VAS ACS VSL SELXN RS&I",
  },
  { code: "94250", shortdesc: "EXPIRED GAS COLLECTION QUANT 1 PROCEDURE SPX" },
  { code: "50815", shortdesc: "URETEROCOLON CONDUIT INTESTINE ANASTOMOSIS" },
  { code: "47721", shortdesc: "CHOLECYSTOENTEROSTOMY W/GASTROENTEROSTOMY" },
  {
    code: "59855",
    shortdesc: "INDUCED ABORT 1/> VAG SUPPOSITORIES DLVR FETUS",
  },
  { code: "42405", shortdesc: "BIOPSY SALIVARY GLAND INCISIONAL" },
  { code: "20663", shortdesc: "APPLICATION HALO FEMORAL INCLUDING REMOVAL" },
  { code: "54320", shortdesc: "null" },
  { code: "86160", shortdesc: "COMPLEMENT ANTIGEN EACH COMPONENT" },
  { code: "49555", shortdesc: "RPR RECRT FEM HERNIA REDUCIBLE" },
  { code: "1501F", shortdesc: "NOT INITIAL EVALUATION FOR CONDITION" },
  { code: "11771", shortdesc: "EXCISION PILONIDAL CYST/SINUS EXTENSIVE" },
  {
    code: "19286",
    shortdesc: "PERQ BREAST LOC DEVICE PLACEMT EACH LES US IMAGE",
  },
  { code: "78291", shortdesc: "PERITONEAL-VENOUS SHUNT PATENCY TEST" },
  {
    code: "76814",
    shortdesc: "US FETAL NUCHAL TRANSLUCENCY EA ADDL GESTATION",
  },
  { code: "47538", shortdesc: "PLMT BILE DUCT STENT PRQ EXISTING ACCESS" },
  { code: "40799", shortdesc: "UNLISTED PROCEDURE LIPS" },
  { code: "86403", shortdesc: "PARTICLE AGGLUTINATION SCREEN EACH ANTIBODY" },
  {
    code: "36224",
    shortdesc: "SLCTV CATH INTRNL CAROTID ART ANGIO INTRCRNL ART",
  },
  {
    code: "34832",
    shortdesc: "OPN RPR ARYSM RPR ARTL TRMA AORTO-BIFEM PROSTH",
  },
  {
    code: "75957",
    shortdesc: "EVASC RPR DESCND THORCIC AORTA CELIAC ORIG RS&I",
  },
  { code: "78593", shortdesc: "PULM VI GASEOUS RBRTHING&WSHOT 1 PRJCJ" },
  { code: "92140", shortdesc: "PROVOCATIVE TESTS GLAUCOMA I&R W/O TONOGRAPHY" },
  { code: "82387", shortdesc: "CATHEPSIN-D" },
  {
    code: "19126",
    shortdesc: "EXC BRST LES PREOP PLMT RAD MARKER OPN EA ADDL",
  },
  { code: "32503", shortdesc: "RESCJ APICAL LUNG TUMOR W/O CHEST WALL RCNSTJ" },
  { code: "96545", shortdesc: "null" },
  {
    code: "75964",
    shortdesc: "TRLUML BALOON ANGIOP PERIPHER EA ADDL ARTERY RSI",
  },
  { code: "11052", shortdesc: "null" },
  { code: "81170", shortdesc: "ABL1 GENE ANALYSIS KINASE DOMAIN VARIANTS" },
  {
    code: "23540",
    shortdesc: "CLSD TX ACROMIOCLAVICULAR DISLC W/O MANIPULATION",
  },
  { code: "26770", shortdesc: "CLTX IPHAL JT DISLC W/MANJ W/O ANES" },
  { code: "78647", shortdesc: "CEREBROSPINAL FLUID FLOW W/O MATL TOMOG SPECT" },
  {
    code: "0256T",
    shortdesc: "EVASC CATHETER-DELIVRD PROSTH AORTIC HEART VALVE",
  },
  { code: "64517", shortdesc: "INJECTION ANES SUPERIOR HYPOGASTRIC PLEXUS" },
  { code: "3008F", shortdesc: "BODY MASS INDEX DOCUMENTED" },
  {
    code: "77432",
    shortdesc: "STERETCTC RADIATION TX MANAGEMENT CRANIAL LESION",
  },
  { code: "4012F", shortdesc: "WARFARIN THERAPY PRESCRIBED" },
  { code: "55120", shortdesc: "REMOVAL FOREIGN BODY SCROTUM" },
  {
    code: "28615",
    shortdesc: "OPEN TREATMENT TARSOMETATARSAL JOINT DISLOCATION",
  },
  { code: "33212", shortdesc: "INS PM PLS GEN W/EXIST SINGLE LEAD" },
  { code: "50380", shortdesc: "RENAL AUTOTRNSPLJ REIMPLANTATION KIDNEY" },
  { code: "80199", shortdesc: "DRUG SCREEN QUANTITATIVE TIAGABINE" },
  {
    code: "76856",
    shortdesc: "US PELVIC NONOBSTETRIC REAL-TIME IMAGE COMPLETE",
  },
  { code: "43272", shortdesc: "ERCP W/ABLTJ LES X RMVL FORCEPS/CAUT/SNARE" },
  { code: "99352", shortdesc: "null" },
  { code: "27552", shortdesc: "CLOSED TX KNEE DISLOCATION W/ANESTHESIA" },
  { code: "11600", shortdesc: "EXCISION MAL LESION TRUNK/ARM/LEG 0.5 CM/<" },
  { code: "54640", shortdesc: "ORCHIOPEXY INGUINAL APPROACH W/WO HERNIA RPR" },
  { code: "0171T", shortdesc: "PST SPINOUS PROCESS DEVICE INSERT LMBR 1 LVL" },
  { code: "57270", shortdesc: "REPAIR ENTEROCELE ABDOMINAL APPROACH SPX" },
  {
    code: "19287",
    shortdesc: "PERQ BREAST LOC DEVICE PLACEMT 1ST LESIO MR GUID",
  },
  { code: "0095U", shortdesc: "null" },
  {
    code: "0250T",
    shortdesc: "AIRWAY SIZING & INSERT BRONCHIAL VALVE EA LOBE",
  },
  { code: "58980", shortdesc: "null" },
  {
    code: "96913",
    shortdesc: "PHOTOCHEMOTHERAPY DERMATOSES 4-8 HRS SUPERVISION",
  },
  { code: "51940", shortdesc: "CLOSURE EXSTROPHY BLADDER" },
  {
    code: "00865",
    shortdesc: "ANES XTRPRTL LWR ABD W/URINARY TRACT RAD PRSTECT",
  },
  { code: "43269", shortdesc: "ERCP W/RTRGR RMVL FB&/CHNG TUBE/STENT" },
  {
    code: "19295",
    shortdesc: "IMG GID PLMT MTLC LOCLZJ CLIP PRQ BRST BX/ASPIR",
  },
  { code: "81364", shortdesc: "HBB FULL GENE SEQUENCE" },
  { code: "0473T", shortdesc: "DEV INTERR REPRGRMG IO RTA ELTRD RA W/REPRT" },
  { code: "33474", shortdesc: "VALVOTOMY PULMONARY VALVE OPEN HEART W/BYPASS" },
  { code: "15837", shortdesc: "EXC EXCESSIVE SKIN &SUBQ TISSUE FOREARM/HAND" },
  { code: "76998", shortdesc: "ULTRASONIC GUIDANCE INTRAOPERATIVE" },
  { code: "58970", shortdesc: "FOLLICLE PUNCTURE OOCYTE RETRIEVAL ANY METHOD" },
  { code: "43510", shortdesc: "GSTRT W/ESOPHGL DILAT&INSJ PRM INTRAL TUBE" },
  { code: "0037T", shortdesc: "null" },
  { code: "28555", shortdesc: "OPEN TREATMENT TARSAL BONE DISLOCATION" },
  { code: "70552", shortdesc: "MRI BRAIN BRAIN STEM W/CONTRAST MATERIAL" },
  {
    code: "91037",
    shortdesc: "GASTROESOPHAG REFLX TEST W/INTRLUML IMPED ELTRD",
  },
  { code: "63185", shortdesc: "LAMINECTOMY W/RHIZOTOMY 1/2 SEGMENTS" },
  { code: "26565", shortdesc: "OSTEOTOMY METACARPAL EACH" },
  { code: "70300", shortdesc: "RADIOLOGIC EXAMINATION TEETH 1 VIEW" },
  { code: "81312", shortdesc: "null" },
  { code: "76881", shortdesc: "US COMPL JOINT R-T W/IMAGE DOCUMENTATION" },
  { code: "53000", shortdesc: "URTT/URTS XTRNL SPX PENDULOUS URETHRA" },
  { code: "53853", shortdesc: "null" },
  { code: "25145", shortdesc: "SEQUESTRECTOMY FOREARM &/WRIST" },
  {
    code: "61538",
    shortdesc: "CRANIOT LOBEC TEMPORAL LOBE W/ELECTROCORTCOGRPHY",
  },
  { code: "85999", shortdesc: "UNLISTED HEMATOLOGY & COAGULATION PROCEDURE" },
  { code: "53502", shortdesc: "URETHRORRHAPHY SUTR URETHRAL WOUND/INJ FEMALE" },
  {
    code: "96374",
    shortdesc: "THER PROPH/DX NJX IV PUSH SINGLE/1ST SBST/DRUG",
  },
  {
    code: "90815",
    shortdesc: "INDIV PSYCTX INTERACT OFF/OP 75-80 MIN MED E/M",
  },
  { code: "87640", shortdesc: "IADNA S AUREUS AMPLIFIED PROBE TQ" },
  { code: "65091", shortdesc: "EVISCERATION OCULAR CONTENTS W/O IMPLANT" },
  { code: "45303", shortdesc: "PROCTOSGMDSC RIGID W/DILATION" },
  { code: "4033F", shortdesc: "PULMONARY REHABILITATION RECOMMENDED" },
  {
    code: "96935",
    shortdesc: "RCM CELULR & SUBCELULR SKN IMGNG IMG ACQ EA ADDL",
  },
  { code: "96567", shortdesc: "PDT DSTR PRMLG LES SKN ILLUM/ACTIVJ PER DAY" },
  { code: "89136", shortdesc: "null" },
  { code: "76000", shortdesc: "FLUOROSCOPY UP TO 1 HOUR PHYSICIAN/QHP TIME" },
  { code: "76350", shortdesc: "null" },
  { code: "44212", shortdesc: "LAPS COLECTOMY ABDL W/PROCTECTOMY W/ILEOSTOMY" },
  {
    code: "21141",
    shortdesc: "RCNSTJ MIDFACE LEFORT I 1 PIECE W/O BONE GRAFT",
  },
  {
    code: "33991",
    shortdesc: "INSJ PERQ VAD TRNSPTAL W/IMAGE ART&VENOUS ACCESS",
  },
  {
    code: "15101",
    shortdesc: "SPLIT AGRFT T/A/L EA 100 CM/EA 1% BDY INFT/CHLD",
  },
  { code: "83625", shortdesc: "LACTATE DEHYDROGENASE ISOENZYMES SEP&QUAN" },
  { code: "46947", shortdesc: "HEMORRHOIDOPEXY STAPLING" },
  { code: "3520F", shortdesc: "CLOSTRIDIUM DIFFICILE TESTING PERFORMED" },
  {
    code: "61863",
    shortdesc: "STRTCTC IMPLTJ NSTIM ELTRD W/O RECORD 1ST ARRAY",
  },
  { code: "73630", shortdesc: "RADEX FOOT COMPLETE MINIMUM 3 VIEWS" },
  {
    code: "77418",
    shortdesc: "NTSTY MODUL DLVR 1/MLT FLDS/ARCS PR TX SESSION",
  },
  { code: "54205", shortdesc: "NJX PEYRONIE W/SURG EXPOS PLAQUE" },
  { code: "42860", shortdesc: "EXCISION TONSIL TAGS" },
  {
    code: "10081",
    shortdesc: "INCISION & DRAINAGE PILONIDAL CYST COMPLICATED",
  },
  { code: "27756", shortdesc: "PRQ SKELETAL FIXATION TIBIAL SHAFT FRACTURE" },
  { code: "27441", shortdesc: "ARTHRP KNEE TIBIAL PLATEAU DBRDMT&PRTL SYNVCT" },
  { code: "0049U", shortdesc: "null" },
  { code: "29824", shortdesc: "ARTHROSCOPY SHOULDER DISTAL CLAVICULECTOMY" },
  {
    code: "64569",
    shortdesc: "REVISION/REPLMT NEUROSTIMLATOR ELTRD CRANIAL NRV",
  },
  {
    code: "50606",
    shortdesc: "ENDOLUMINAL BX URTR &/RNL PELVIS NONENDOSCOPIC",
  },
  { code: "35535", shortdesc: "BYPASS W/VEIN HEPATORENAL" },
  {
    code: "3100F",
    shortdesc: "CAROTID IMAGNG REPORT DIR/INDIR MEAS VESSEL DIAM",
  },
  {
    code: "25505",
    shortdesc: "CLOSED TX RADIAL SHAFT FRACTURE W/MANIPULATION",
  },
  { code: "99368", shortdesc: "TEAM CONFERENCE NON-FACE-TO-FACE NONPHYSICIAN" },
  { code: "25685", shortdesc: "OPEN TX TRANS-SCAPHOPERILUNAR FRACTURE DISLC" },
  { code: "82554", shortdesc: "CREATINE KINASE ISOFORMS" },
  { code: "78801", shortdesc: "RP LOCLZJ TUMOR/DSTRBJ AGENT MULTIPLE AREAS" },
  { code: "42660", shortdesc: "DILAT&CATHJ SALIVARY DUCT W/WO INJECTION" },
  { code: "20102", shortdesc: "EXPL PENETRATING WOUND SPX ABDOMEN/FLANK/BACK" },
  { code: "43310", shortdesc: "ESPHGP THRC APPR W/O RPR TRACHEOESOPHGL FSTL" },
  {
    code: "75807",
    shortdesc: "LYMPHANGIOGRAPHY PELVIC/ABDOMINAL BILATERAL RS&I",
  },
  { code: "28192", shortdesc: "REMOVAL FOREIGN BODY FOOT DEEP" },
  {
    code: "27033",
    shortdesc: "ARTHROTOMY HIP EXPLORATION/REMOVAL FOREIGN BODY",
  },
  {
    code: "23525",
    shortdesc: "CLOSED TX STERNOCLAVICULAR DISLC W/MANIPULATION",
  },
  {
    code: "84155",
    shortdesc: "PROTEIN XCPT REFRACTOMETRY SERUM PLASMA/WHL BLD",
  },
  { code: "88291", shortdesc: "CYTOGENETICS&MOLEC CYTOGENETICS INTERP&REP" },
  {
    code: "3572F",
    shortdesc: "PT POTENTIAL RISK FRACTURE WEIGHT-BEARING SITE",
  },
  { code: "24420", shortdesc: "OSTEOPLASTY HUMERUS" },
  { code: "82180", shortdesc: "ASSAY OF ASCORBIC ACID BLOOD" },
  {
    code: "50686",
    shortdesc: "MANOMETRIC STDS THRU URTROST/NDWELLG URTRL CATH",
  },
  { code: "71555", shortdesc: "MRA CHEST W/O & W/CONTRAST MATERIAL" },
  { code: "70390", shortdesc: "SIALOGRAPHY RS&I" },
  { code: "87542", shortdesc: "IADNA LEGIONELLA PNEUMOPHILA QUANTIFICATION" },
  { code: "49081", shortdesc: "PRITONEOCNTS ABDL PCNTS/PRTL LVG SBSQ" },
  { code: "13101", shortdesc: "REPAIR COMPLEX TRUNK 2.6-7.5 CM" },
  { code: "61531", shortdesc: "SUBDURAL IMPLTJ ELECTRODES SEIZURE MONITORING" },
  {
    code: "34805",
    shortdesc: "EVASC RPR AAA AORTO-UNIILIAC/AORTO-UNIFEM PROSTH",
  },
  { code: "4561F", shortdesc: "PATIENT HAS A CORONARY ARTERY STENT" },
  {
    code: "50825",
    shortdesc: "CONTINENT DVRJ W/INT ANAST ANY SGM SM&/LG INTSTN",
  },
  {
    code: "31626",
    shortdesc: "BRONCHOSCOPY W/PLMT FIDUCIAL MARKERS SINGLE/MULT",
  },
  { code: "77407", shortdesc: "RADIATION TX DELIVERY 1 MEV >= INTERMEDIATE" },
  { code: "99490", shortdesc: "CHRON CARE MANAGEMENT SRVC 20 MIN PER MONTH" },
  { code: "78272", shortdesc: "VITAMIN B-12 ABSRPJ STDY W/WO INTRINSIC FACT" },
  { code: "69090", shortdesc: "EAR PIERCING" },
  { code: "15811", shortdesc: "null" },
  { code: "25025", shortdesc: "DCMPRN FASCT F/ARM&/WRST FLXR&XTNSR DBRDMT" },
  { code: "82947", shortdesc: "GLUCOSE QUANTITATIVE BLOOD XCPT REAGENT STRIP" },
  { code: "29825", shortdesc: "ARTHROSCOPY SHOULDER AHESIOLYSIS W/WO MANIPJ" },
  {
    code: "81304",
    shortdesc: "MECP2 GENE ANALYSIS DUPLICATION/DELETION VARIANT",
  },
  {
    code: "33241",
    shortdesc: "REMOVAL IMPLANTABLE DEFIB PULSE GENERATOR ONLY",
  },
  { code: "75605", shortdesc: "AORTOGRAPHY THORACIC SERIALOGRAPHY RS&I" },
  {
    code: "0472T",
    shortdesc: "DEV INTERR PRGRMG IO RTA ELTRD RA W/ADJ & REPRT",
  },
  { code: "95875", shortdesc: "ISCHEMIC LIMB XERS TST SPEC ACQUISJ METAB" },
  { code: "76086", shortdesc: "null" },
  {
    code: "33952",
    shortdesc: "ECMO/ECLS INSJ OF PRPH CANNULA 6 YRS&OLDER PERQ",
  },
  {
    code: "3115F",
    shortdesc: "QUANT RESULTS EVAL CURR LEVEL ACTIVITY CLIN SYMP",
  },
  { code: "11954", shortdesc: "SUBCUTANEOUS INJECTION FILLING MATRL >10.0 CC" },
  { code: "87380", shortdesc: "IAAD IA HEPATITIS DELTA ANTIGEN" },
  { code: "15620", shortdesc: "DELAY FLAP/SECTIONING FLAP F/C/C/N/AX/G/H/F" },
  {
    code: "93284",
    shortdesc: "PRGRMG EVAL IMPLANTABLE IN PERSON MULTI LEAD DFB",
  },
  {
    code: "12056",
    shortdesc: "REPAIR INTERMEDIATE F/E/E/N/L&/MUC 20.1-30.0CM",
  },
  { code: "54800", shortdesc: "BIOPSY EPIDIDYMIS NEEDLE" },
  { code: "90693", shortdesc: "TYPHOID VACCINE AKD SUBQ U.S. MILITARY" },
  { code: "3384F", shortdesc: "AJCC COLON CANCER STAGE I" },
  { code: "11765", shortdesc: "WEDGE EXCISION SKIN NAIL FOLD" },
  { code: "0059T", shortdesc: "CRYOPRESERVATION OOCYTES" },
  { code: "85549", shortdesc: "MURAMIDASE" },
  {
    code: "27390",
    shortdesc: "TENOTOMY OPEN HAMSTRING KNEE HIP SINGLE TENDON",
  },
  {
    code: "11622",
    shortdesc: "EXCISION MALIGNANT LESION S/N/H/F/G 1.1-2.0 CM",
  },
  { code: "96408", shortdesc: "null" },
  { code: "27386", shortdesc: "SUTR QUADRICEPS/HAMSTRING MUSC RPT RCNSTJ" },
  { code: "96103", shortdesc: "PSYCHOLOGICAL TESTING COMPUTER W/PROF I&R" },
  { code: "63077", shortdesc: "DISCECTOMY ANT DCMPRN CORD THORACIC 1 NTRSPC" },
  { code: "33030", shortdesc: "PRICARDIECTOMY STOT/COMPL W/O CARDPULM BYPASS" },
  {
    code: "32651",
    shortdesc: "THORACOSCOPY W/PARTIAL PULMONARY DECORTICATION",
  },
  { code: "76825", shortdesc: "ECHO FETAL CARDIOVASC W/WO M-MODE RECORDING" },
  { code: "25526", shortdesc: "OPEN RDL SHAFT FX OPEN RAD/ULN JT DISLOCATE" },
  { code: "20200", shortdesc: "BIOPSY MUSCLE SUPERFICIAL" },
  { code: "1493F", shortdesc: "DEMENTIA SEVERITY CLASSIFIED SEVERE" },
  { code: "84132", shortdesc: "POTASSIUM SERUM PLASMA/WHOLE BLOOD" },
  { code: "45395", shortdesc: "LAPS PROCTECTOMY ABDOMINOPERINEAL W/COLOSTOMY" },
  { code: "76005", shortdesc: "null" },
  { code: "82120", shortdesc: "AMINES VAGINAL FLUID QUALITATIVE" },
  { code: "00732", shortdesc: "ANESTHESIA UPPER GI ENDOSCOPIC PX ERCP" },
  { code: "99075", shortdesc: "MEDICAL TESTIMONY" },
  { code: "93971", shortdesc: "DUP-SCAN XTR VEINS UNILATERAL/LIMITED STUDY" },
  { code: "20956", shortdesc: "BONE GRAFT MICROVASCULAR ANAST ILIAC CREST" },
  {
    code: "34848",
    shortdesc: "VISCER AND INFRARENAL ABDOM AORTA 4+ PROSTHESIS",
  },
  { code: "49061", shortdesc: "DRG RETROPERITONEAL ABSCESS PERCUTANEOUS" },
  { code: "12018", shortdesc: "SIMPLE REPAIR F/E/E/N/L/M >30.0 CM" },
  { code: "75665", shortdesc: "ANGIOGRAPHY CAROTID CEREBRAL UNILATERAL RS&I" },
  {
    code: "32491",
    shortdesc: "RMVL LUNG OTH/THN PNUMEC RESXN-PLCTJ EMPHY LUNG",
  },
  { code: "25650", shortdesc: "CLOSED TREATMENT ULNAR STYLOID FRACTURE" },
  { code: "47740", shortdesc: "CHOLECYSTOENTEROSTOMY ROUX-EN-Y" },
  { code: "0485T", shortdesc: "OCT MIDDLE EAR WITH I&R UNILATERAL" },
  { code: "95078", shortdesc: "null" },
  { code: "49250", shortdesc: "UMBILECTOMY OMPHALECTOMY EXC UMBILICUS SPX" },
  {
    code: "36248",
    shortdesc: "SLCTV CATHJ EA 2ND+ ORD ABDL PEL/LXTR ART BRNCH",
  },
  {
    code: "64612",
    shortdesc: "CHEMODNRVTJ MUSC MUSC INNERVATED FACIAL NRV UNIL",
  },
  { code: "01902", shortdesc: "null" },
  { code: "01740", shortdesc: "ANES OPEN/SURG ARTHROSCOPIC ELBOW PROC NOS" },
  { code: "87880", shortdesc: "IAADIADOO STREPTOCOCCUS GROUP A" },
  { code: "43192", shortdesc: "ESOPHAGOSCOPY RIGID TRANSORAL INJ SUBMUCOSAL" },
  { code: "31299", shortdesc: "UNLISTED PROCEDURE ACCESSORY SINUSES" },
  { code: "15787", shortdesc: "ABRASION EACH ADDITIONAL 4 LESIONS OR LESS" },
  { code: "20615", shortdesc: "ASPIRATION & INJECTION TREATMENT BONE CYST" },
  { code: "65875", shortdesc: "SEVERING ADS ANT SEG INCAL SPX POST SYNECHIAE" },
  { code: "82310", shortdesc: "CALCIUM TOTAL" },
  { code: "36299", shortdesc: "UNLISTED PROCEDURE VASCULAR INJECTION" },
  { code: "87278", shortdesc: "IAADI LEGIONELLA PNEUMOPHILA" },
  {
    code: "24640",
    shortdesc: "CLTX RDL HEAD SUBLXTJ CHLD NURSEMAID ELBW W/MANJ",
  },
  { code: "78120", shortdesc: "RED CELL VOLUME DETERMINATION SPX 1 SAMPLING" },
  { code: "47140", shortdesc: "DONOR HEPATECTOMY LIVING DONOR SEG II & III" },
  {
    code: "99444",
    shortdesc: "PHYS/QHP ONLINE EVALUATION & MANAGEMENT SERVICE",
  },
  { code: "99363", shortdesc: "ANTICOAGULANT MGMT OUTPATIENT INIT 90 DAYS" },
  { code: "27590", shortdesc: "AMPUTATION THIGH THROUGH FEMUR ANY LEVEL" },
  { code: "77083", shortdesc: "RADIOGRAPHIC ABSORPTIOMETRY 1+ SITS" },
  { code: "99215", shortdesc: "OFFICE OUTPATIENT VISIT 40 MINUTES" },
  { code: "74710", shortdesc: "PELVIMETRY W/WOPLACENTAL LOCALIZATION" },
  { code: "15845", shortdesc: "GRF FACIAL NERVE PARALYSIS REGIONAL MUSCLE TR" },
  {
    code: "01390",
    shortdesc: "ANES CLOSED PROC UPPER END TIBIA FIBULA/PATELLA",
  },
  { code: "85240", shortdesc: "CLOTTING FACTOR VIII AHG 1 STAGE" },
  {
    code: "58285",
    shortdesc: "VAGINAL HYSTERECTOMY RADICAL SCHAUTA OPERATION",
  },
  { code: "69222", shortdesc: "DEBRIDEMENT MASTOIDECTOMY CAVITY CMPLX" },
  { code: "48145", shortdesc: "PNCRTECT DSTL STOT W/PNCRTCOJEJUNOSTOMY" },
  {
    code: "44127",
    shortdesc: "ENTRC RESCJ ATRESIA RESCJ & ANAST SGM W/TAPRING",
  },
  { code: "71036", shortdesc: "null" },
  { code: "42226", shortdesc: "LENGTHENING PALATE & PHARYNGEAL FLAP" },
  { code: "29868", shortdesc: "ARTHROSCOPY KNEE MENISCAL TRNSPLJ MED/LAT" },
  { code: "29105", shortdesc: "APPLICATION LONG ARM SPLINT SHOULDER HAND" },
  { code: "85244", shortdesc: "CLOTTING FACTOR VIII RELATED ANTIGEN" },
  { code: "96910", shortdesc: "PHOTOCHEMOTX TAR&UVB/PETROLATUM/UVB" },
  { code: "93225", shortdesc: "XTRNL ECG & 48 HR RECORDING" },
  { code: "43651", shortdesc: "LAPS SURG TRNSXJ VAGUS NRV TRUNCAL" },
  { code: "75559", shortdesc: "CARDIAC MRI W/O CONTRAST W/STRESS IMAGING" },
  { code: "72129", shortdesc: "CT THORACIC SPINE W/CONTRAST MATERIAL" },
  {
    code: "33403",
    shortdesc: "VLVP AORTIC VALVE W/TRANSVENTR DILAT W/CARD BYP",
  },
  { code: "0143T", shortdesc: "LAPS PNCRTC ISLET CELL TRNSPLJ" },
  { code: "21155", shortdesc: "RCNSTJ MIDFACE LEFORT III W/LEFORT I" },
  { code: "75680", shortdesc: "ANGIOGRAPHY CAROTID CERVICAL BILATERAL RS&I" },
  { code: "78709", shortdesc: "KIDNEY IMG MORPHOLOGY VASCULAR FLOW MULTIPLE" },
  { code: "21386", shortdesc: "OPEN TX ORBITAL FLOOR BLOWOUT FX PERIORBITAL" },
  {
    code: "29899",
    shortdesc: "ARTHROSCOPY ANKLE SURGICAL W/ANKLE ARTHRODESIS",
  },
  { code: "87273", shortdesc: "IAADI HERPES SMPLX VIRUS TYPE 2" },
  {
    code: "23552",
    shortdesc: "OPTX ACROMCLAV DISLC ACUTE/CHRONIC W/FASCIAL GRF",
  },
  {
    code: "69641",
    shortdesc: "TMPP MASTOIDECTOMY W/O OSSICULAR CHAIN RECNSTJ",
  },
  { code: "95865", shortdesc: "NEEDLE ELECTROMYOGRAPHY LARYNX" },
  {
    code: "0384T",
    shortdesc: "XTRNL HRT RATE EPI SEIZ 15 TO 30 DAYS R&I ONLY",
  },
  { code: "72020", shortdesc: "RADEX SPINE 1 VIEW SPECIFY LEVEL" },
  {
    code: "86001",
    shortdesc: "ALLERGEN SPECIFIC IGG QUAN/SEMIQUAN EA ALLERGEN",
  },
  {
    code: "64425",
    shortdesc: "INJECTION ANES ILIOINGUINAL ILIOHYPOGASTRIC NRVS",
  },
  { code: "19303", shortdesc: "MASTECTOMY SIMPLE COMPLETE" },
  { code: "0506T", shortdesc: "null" },
  { code: "58124", shortdesc: "null" },
  {
    code: "69633",
    shortdesc: "TYMPANOPLASTY W/O MASTOIDEC 1ST/REVJ PROSTH TORP",
  },
  { code: "87269", shortdesc: "IAADI GIARDIA" },
  { code: "35642", shortdesc: "BYP OTH/THN VEIN CAROTID-VERTEBRAL" },
  {
    code: "33470",
    shortdesc: "VALVOTOMY PULMONARY VALVE CLSD HEART TRANSVENTR",
  },
  { code: "23130", shortdesc: "PARTIAL REPAIR OR REMOVAL OF SHOULDER BONE" },
  {
    code: "31603",
    shortdesc: "TRACHEOSTOMY EMERGENCY PROCEDURE TRANSTRACHEAL",
  },
  { code: "37790", shortdesc: "PENILE VENOUS OCCLUSIVE PROCEDURE" },
  { code: "87085", shortdesc: "null" },
  {
    code: "82271",
    shortdesc: "BLOOD OCCULT PEROXIDASE ACTV QUAL OTHER SOURCES",
  },
  { code: "31365", shortdesc: "LARYNGECTOMY TOTAL W/RADICAL NECK DISSECTION" },
  {
    code: "21558",
    shortdesc: "RAD RESECT TUMOR SOFT TISS NECK/ANT THORAX 5CM/>",
  },
  { code: "78458", shortdesc: "VENOUS THROMBOSIS IMAGING VENOGRAM BILATERAL" },
  {
    code: "58573",
    shortdesc: "LAPAROSCOPY TOT HYSTERECTOMY >250 G W/TUBE/OVAR",
  },
  { code: "00946", shortdesc: "null" },
  { code: "3330F", shortdesc: "IMAGING STUDY ORDERED" },
  {
    code: "61582",
    shortdesc: "CRANFCL ANT CRANIAL FOSSA UNI/BI CRANIOT/OSTEOT",
  },
  { code: "89160", shortdesc: "MEAT FIBERS FECES" },
  { code: "84295", shortdesc: "SODIUM SERUM PLASMA OR WHOLE BLOOD" },
  { code: "11107", shortdesc: "null" },
  {
    code: "61597",
    shortdesc: "TRNSCONDLR POST CRNL FOSSA DCOMPR ART W/WO MOBIL",
  },
  { code: "76981", shortdesc: "null" },
  { code: "82638", shortdesc: "ASSAY OF DIBUCAINE NUMBER" },
  { code: "75891", shortdesc: "HEPATC VNGRPH WDG/FR W/O HEMODYN EVAL RS&I" },
  { code: "33010", shortdesc: "PERICARDIOCENTESIS INITIAL" },
  { code: "96118", shortdesc: "NUROPSYC TESTING PR HR W/PT & INTERPJ TIME" },
  { code: "87329", shortdesc: "IAAD IA GIARDIA" },
  {
    code: "27165",
    shortdesc: "OSTEOT INTERTRCHNTRIC/SUBTRCHNTRIC W/INT/XTRNL",
  },
  { code: "44602", shortdesc: "ENTERORRHAPHY 1PERFORATION" },
  { code: "43831", shortdesc: "GASTROSTOMY OPN NEONATAL FEEDING" },
  { code: "83857", shortdesc: "METHEMALBUMIN" },
  { code: "2026F", shortdesc: "EYE IMAGING VALIDATED MATCH PHOTOS DIAGNOSIS" },
  { code: "77062", shortdesc: "DIGITAL BREAST TOMOSYNTHESIS BILATERAL" },
  { code: "87802", shortdesc: "IAADIADOO STREPTOCOCCUS GROUP B" },
  { code: "44382", shortdesc: "ILEOSCOPY STOMA W/BX SINGLE/MULTIPLE" },
  { code: "24310", shortdesc: "TENOTOMY OPEN ELBOW TO SHOULDER EACH TENDON" },
  {
    code: "37202",
    shortdesc: "TCAT THER INFUSION OTH/THN THROMBOLYSIS ANY TYPE",
  },
  {
    code: "0404T",
    shortdesc: "TRANSCERVICAL UTERINE FIBROID ABLTJ W/US GDN RF",
  },
  {
    code: "15945",
    shortdesc: "EXC ISCHIAL PR ULC W/SKN FLAP CLSR W/OSTECTOMY",
  },
  { code: "65273", shortdesc: "RPR LAC CJNC MOBLJ & REARGMT W/HOSPIZATION" },
  { code: "88356", shortdesc: "MORPHOMETRIC ANALYSIS NERVE" },
  { code: "49451", shortdesc: "REPLACE DUODENOSTOMY/JEJUNOSTOMY TUBE PERQ" },
  {
    code: "87538",
    shortdesc: "IADNA HIV-2 AMPLIFIED PROBE & REVERSE TRANSCRIPJ",
  },
  { code: "67922", shortdesc: "REPAIR ENTROPION THERMOCAUTERIZATION" },
  { code: "55840", shortdesc: "PROSTATECTOMY RETROPUBIC W/WO NERVE SPARING" },
  { code: "28606", shortdesc: "PRQ SKEL FIXJ TARS JT DISLC W/MANJ" },
  {
    code: "25260",
    shortdesc: "RPR TDN/MUSC FLXR F/ARM&/WRST PRIM 1 EA TDN/MU",
  },
  { code: "01214", shortdesc: "ANESTHESIA OPEN TOTAL HIP ARTHROPLASTY" },
  {
    code: "34401",
    shortdesc: "THRMBC DIR/W/CATH VENA CAVA ILIAC VEIN ABDL INC",
  },
  { code: "59561", shortdesc: "null" },
  { code: "77059", shortdesc: "MRI BREAST BILATERAL" },
  {
    code: "64568",
    shortdesc: "INC IMPLTJ CRNL NRV NSTIM ELTRDS & PULSE GENER",
  },
  {
    code: "33427",
    shortdesc: "VLVP MITRAL VALVE W/BYPASS RAD RCNSTJ W/WO RING",
  },
  {
    code: "95975",
    shortdesc: "ELEC ALYS NSTIM PLS GEN CPLX CRNL NRV EA 30 MIN",
  },
  { code: "92531", shortdesc: "SPONTANEOUS NYSTAGMUS W/GAZE" },
  { code: "28510", shortdesc: "CLTX FX PHLX/PHLG OTH/THN GRT TOE W/O MANJ" },
  { code: "25125", shortdesc: "EXC/CURTG CYST/TUMOR RADIUS/ULNA W/AUTOGRAFT" },
  { code: "51570", shortdesc: "CYSTECTOMY COMPLETE SEPARATE PROCEDURE" },
  { code: "90833", shortdesc: "PSYCHOTHERAPY W/PATIENT W/E&M SRVCS 30 MIN" },
  { code: "97770", shortdesc: "null" },
  { code: "82143", shortdesc: "AMNIOTIC FLU SCAN" },
  { code: "20206", shortdesc: "BIOPSY MUSCLE PERCUTANEOUS NEEDLE" },
  {
    code: "95980",
    shortdesc: "ELEC ALYS NSTIM PLS GEN GASTRIC INTRAOP W/PRGRMG",
  },
  { code: "43124", shortdesc: "TOT/PRTL ESPHG W/O RCNSTJ W/CRV ESOPHAGOSTOMY" },
  { code: "61340", shortdesc: "SUBTEMPORAL CRANIAL DECOMPRESSION" },
  {
    code: "37211",
    shortdesc: "THROMBOLYSIS ARTERIAL INFUSION ICRA RS&I INIT TX",
  },
  { code: "3035F", shortdesc: "OXYGEN SATUR </EQUAL 88%/PAO2 </EQUAL 55 MM" },
  {
    code: "37214",
    shortdesc: "CESSATION THROMBOLYTIC THER W/CATHETER REMOVAL",
  },
  {
    code: "1061F",
    shortdesc: "DOC ABSENCE PERM&PERSISTENT&PAROXYSM ATRIAL FIB",
  },
  { code: "79999", shortdesc: "RP THERAPY UNLISTED PROCEDURE" },
  { code: "76090", shortdesc: "null" },
  { code: "97018", shortdesc: "APPL MODALITY 1/> AREAS PARAFFIN BATH" },
  { code: "82759", shortdesc: "ASSAY OF GALACTOKINASE RBC" },
  { code: "31294", shortdesc: "NASAL/SINUS NDSC SURG W/OPTIC NERVE DCMPRN" },
  { code: "27506", shortdesc: "OPTX FEM SHFT FX W/INSJ IMED IMPLT W/WO SCREW" },
  { code: "69220", shortdesc: "DEBRIDEMENT MASTOIDECTOMY CAVITY SIMPLE" },
  { code: "4172F", shortdesc: "PATIENT NOT RECEIVING (ESA) THERAPY" },
  { code: "85049", shortdesc: "BLOOD COUNT PLATELET AUTOMATED" },
  { code: "84207", shortdesc: "ASSAY OF PYRIDOXAL PHOSPHATE" },
  { code: "81259", shortdesc: "HBA1/HBA2 GENE ANALYSIS FULL GENE SEQUENCE" },
  { code: "28475", shortdesc: "CLTX METAR FX W/MANJ" },
  {
    code: "27825",
    shortdesc: "CLTX FX W8 BRG ARTCLR PRTN DSTL TIB W/SKEL TRACJ",
  },
  { code: "26990", shortdesc: "I&D PELVIS/HIP JT AREA DEEP ABSCESS/HEMATOMA" },
  { code: "3720F", shortdesc: "COGNITIVE IMPAIRMENT/DYSFUNCTION ASSESSED" },
  {
    code: "25440",
    shortdesc: "RPR NONUNION SCAPHOID CARPAL BNE W/WO RDL STYLEC",
  },
  { code: "21805", shortdesc: "OPEN TX RIB FRACTURE W/O FIXATION EACH" },
  {
    code: "44626",
    shortdesc: "CLSR NTRSTM LG/SM RESCJ & COLORECTAL ANASTOMOSIS",
  },
  { code: "51992", shortdesc: "LAPAROSCOPY SLING OPERATION STRESS INCONT" },
  {
    code: "33505",
    shortdesc: "RPR ANOM CORON ART W/CONSTJ INTRAPULM ART TUNNEL",
  },
  { code: "0358T", shortdesc: "BIA WHOLE BODY COMPOSITION ASSESSMENT W/I&R" },
  { code: "87272", shortdesc: "IAADI CRYPTOSPORIDIUM" },
  { code: "33690", shortdesc: "BANDING PULMONARY ARTERY" },
  { code: "21206", shortdesc: "OSTEOTOMY MAXILLA SEGMENTAL" },
  { code: "85597", shortdesc: "PHOSPHOLIPID NEUTRALIZATION PLATELET" },
  { code: "73615", shortdesc: "RADEX ANKLE ARTHROGRAPHY RS&I" },
  { code: "32005", shortdesc: "null" },
  { code: "82800", shortdesc: "GASES BLOOD PH ONLY" },
  { code: "57556", shortdesc: "EXC CRV STUMP VAG APPR W/RPR NTRCL" },
  { code: "3496F", shortdesc: "CD4+ CELL COUNT >= 500 CELLS/MM" },
  { code: "45112", shortdesc: "PRCTECT CMBN ABDOMINOPRNL PULL-THRU PX" },
  { code: "78730", shortdesc: "URINARY BLADDER RESIDUAL STUDY" },
  { code: "52343", shortdesc: "CYSTO W/TX INTRA-RENAL STRICTURE" },
  { code: "19110", shortdesc: "NIPPLE EXPLORATION" },
  { code: "27734", shortdesc: "ARREST EPIPHYSEAL OPEN DISTAL TIBIA&FIBULA" },
  { code: "76937", shortdesc: "US VASC ACCESS SITS VSL PATENCY NDL ENTRY" },
  { code: "35537", shortdesc: "BYPASS W/VEIN AORTOILIAC" },
  {
    code: "00534",
    shortdesc: "ANES TRANSVENOUS INSJ/REPLACEMENT PACING CVDFB",
  },
  {
    code: "63621",
    shortdesc: "STEREOTACTIC RADIOSURGERY EA ADDL SPINAL LESION",
  },
  {
    code: "69209",
    shortdesc: "REMOVAL IMPACTED CERUMEN IRRIGATION/LVG UNILAT",
  },
  { code: "96414", shortdesc: "null" },
  { code: "69700", shortdesc: "CLOSURE POSTAURICULAR FISTULA MASTOID SPX" },
  { code: "99242", shortdesc: "OFFICE CONSULTATION NEW/ESTAB PATIENT 30 MIN" },
  { code: "31512", shortdesc: "LARYNGOSCOPY INDIRECT W/REMOVAL LESION" },
  {
    code: "90823",
    shortdesc: "INDIV PSYCTX IA INPT/PRTL HOSP/RESDNTL 20-30 MIN",
  },
  { code: "96446", shortdesc: "CHEMOTX ADMN PRTL CAVITY PORT/CATH" },
  { code: "45910", shortdesc: "DILAT RCT STRIX SPX UNDER ANES OTH/THN LOCAL" },
  { code: "47570", shortdesc: "LAPAROSCOPY SURG CHOLECYSTOENETEROSTOMY" },
  { code: "36160", shortdesc: "INTRO NEEDLE/INTRACATH AORTIC TRANSLUMBAR" },
  {
    code: "99412",
    shortdesc: "PREV MED COUNSEL & RISK FACTOR REDJ GRP SPX 60 M",
  },
  {
    code: "29581",
    shortdesc: "APPL MLTLAYR COMPRES LEG BELOW KNEE W/ANKLE FOOT",
  },
  {
    code: "27612",
    shortdesc: "ARTHRT PST CAPSUL RLS ANKLE W/WO ACHLL TDN LNGTH",
  },
  { code: "93880", shortdesc: "DUPLEX SCAN EXTRACRANIAL ART COMPL BI STUDY" },
  {
    code: "11983",
    shortdesc: "RMVL W/RINSJ NON-BIODEGRADABLE DRUG DLVR IMPLT",
  },
  { code: "83633", shortdesc: "LACTOSE URINE QUALITATIVE" },
  { code: "80072", shortdesc: "null" },
  { code: "49491", shortdesc: "RPR 1ST INGUN HRNA PRETERM INFT RDC" },
  { code: "4150F", shortdesc: "CURRENT HEPATITIS C ANTIVIRAL TREATMENT" },
  { code: "87477", shortdesc: "IADNA BORRELIA BURGDORFERI QUANTIFICATION" },
  { code: "87556", shortdesc: "IADNA MYCOBACTERIA TUBERCULOSIS AMP PRB" },
  { code: "54120", shortdesc: "AMPUTATION PENIS PARTIAL" },
  { code: "51792", shortdesc: "STIMULUS EVOKED RESPONSE" },
  { code: "48001", shortdesc: "PLACE DRAIN PERIPANCREATIC W/CHOLECYSTOSTOMY" },
  {
    code: "33973",
    shortdesc: "INSJ I-AORT BALO ASSIST DEV VIA ASCENDING AORTA",
  },
  { code: "68325", shortdesc: "CONJUNCTIVOPLASTY W/BUCCAL MUC MEMB GRAFT" },
  { code: "96406", shortdesc: "CHEMOTHERAPY ADMINISTRATION INTRALESIONAL >7" },
  { code: "81322", shortdesc: "PTEN GENE ANALYSIS KNOWN FAMILIAL VARIANT" },
  {
    code: "78299",
    shortdesc: "UNLISTED GASTROINTESTINAL PX DX NUCLEAR MEDICINE",
  },
  { code: "92545", shortdesc: "OSCILLATING TRACKING TEST W/RECORDING" },
  {
    code: "33840",
    shortdesc: "EXC COARCJ AORTA W/WO PDA W/DIRECT ANASTOMOSIS",
  },
  { code: "00916", shortdesc: "ANES TRURL POST-TRURL RESECTION BLEEDING" },
  { code: "74000", shortdesc: "RADEX ABDOMEN 1 ANTEROPOSTERIOR VIEW" },
  { code: "90385", shortdesc: "RHO(D) IMMUNE GLOBULIN HUMAN MINI-DOSE IM" },
  { code: "81200", shortdesc: "ASPA GENE ANALYSIS COMMON VARIANTS" },
  {
    code: "33986",
    shortdesc: "ECMO/ECLS RMVL OF CENTRAL CANNULA 6 YRS & OLDER",
  },
  { code: "72198", shortdesc: "MRA PELVIS W/WO CONTRAST MATERIAL" },
  { code: "27325", shortdesc: "NEURECTOMY HAMSTRING MUSCLE" },
  {
    code: "99050",
    shortdesc: "SERVICES PROVIDED OFFICE OTH/THN REG SCHED HOURS",
  },
  { code: "69720", shortdesc: "DCMPRN FACIAL NRV INTRATEMPORAL LAT GANGLION" },
  { code: "3498F", shortdesc: "CD4+ CELL PERCENTAGE >= 15% HIV" },
  {
    code: "43757",
    shortdesc: "DUODENAL INTUBAT W/IMAG GUIDED MULTIPLE SPECIMEN",
  },
  { code: "74425", shortdesc: "UROGRAPHY ANTEGRADE RS&I" },
  { code: "64704", shortdesc: "NEUROPLASTY NERVE HAND/FOOT" },
  { code: "88036", shortdesc: "NECROPSY LIMITED GROSS&/MCRSCP REGIONAL" },
  { code: "28485", shortdesc: "OPEN TREATMENT METATARSAL FRACTURE EACH" },
  {
    code: "99397",
    shortdesc: "PERIODIC PREVENTIVE MED EST PATIENT 65YRS& OLDER",
  },
  {
    code: "96373",
    shortdesc: "THERAPEUTIC PROPHYLACTIC/DX NJX INTRA-ARTERIAL",
  },
  { code: "87209", shortdesc: "SMR PRIM SRC CPLX SPEC STAIN OVA&PARASITS" },
  { code: "86803", shortdesc: "HEPATITIS C ANTIBODY" },
  { code: "24635", shortdesc: "OPEN TX MONTEGGIA FRACTURE DISLOCATION ELBOW" },
  { code: "95925", shortdesc: "SHORT-LATENCY SOMATOSENS EP STD UPR LIMBS" },
  {
    code: "62145",
    shortdesc: "CRANIOPLASTY SKULL DEFECT REPARATIVE BRAIN SURG",
  },
  { code: "36425", shortdesc: "VENIPUNCTURE CUTDOWN AGE 1 YR/>" },
  { code: "92250", shortdesc: "FUNDUS PHOTOGRAPHY W/INTERPRETATION & REPORT" },
  {
    code: "31584",
    shortdesc: "LARYNGOPLASTY W/OPEN REDUCTION FRACTURE W/TRACHS",
  },
  {
    code: "4155F",
    shortdesc: "HEPATITIS A VACCINE SERIES PREVIOUSLY RECEIVED",
  },
  { code: "87427", shortdesc: "IAAD IA SHIGA-LIKE TOXIN" },
  { code: "27840", shortdesc: "CLOSED TX ANKLE DISLOCATION W/O ANESTHESIA" },
  {
    code: "36901",
    shortdesc: "INTRO CATH DIALYSIS CIRCUIT DX ANGRPH FLUOR S&I",
  },
  {
    code: "33775",
    shortdesc: "RPR TRPOS GREAT VSLS ATR BAFFLE W/RMVL PULM BAND",
  },
  { code: "63707", shortdesc: "RPR DURAL/CEREBROSPINAL FLUID LEAK X REQ LAM" },
  { code: "77435", shortdesc: "STEREOTACTIC BODY RADIATION MANAGEMENT" },
  { code: "11700", shortdesc: "null" },
  { code: "53860", shortdesc: "TRURL RF FEMALE BLADDER NECK STRS URIN INCONT" },
  { code: "64622", shortdesc: "DSTRJ NULYT PVRT FACET JT NRV LMBR/SAC 1 LVL" },
  { code: "11104", shortdesc: "null" },
  {
    code: "0382T",
    shortdesc: "XTRNL HRT RATE EPI SEIZ UP TO 14 DAYS R&I ONLY",
  },
  { code: "56506", shortdesc: "null" },
  { code: "27830", shortdesc: "CLTX PROX TIBFIB JT DISLC W/O ANES" },
  { code: "0051U", shortdesc: "null" },
  { code: "51741", shortdesc: "COMPLEX UROFLOMETRY" },
  { code: "01918", shortdesc: "null" },
  { code: "0131U", shortdesc: "null" },
  { code: "3370F", shortdesc: "AJCC BREAST CANCER STAGE 0 DOCUMENTED" },
  { code: "27215", shortdesc: "OPTX ILIAC TUBRST AVLS/WING FX FIXJ IF PRFRMD" },
  { code: "58992", shortdesc: "null" },
  {
    code: "99358",
    shortdesc: "PROLNG E/M SVC BEFORE&/AFTER DIR PT CARE 1ST HR",
  },
  { code: "35522", shortdesc: "BYPASS W/VEIN AXILLARY-BRACHIAL" },
  {
    code: "43641",
    shortdesc: "VGTMY W/PYLOROPLASTY W/WO GASTROST PARIETAL CELL",
  },
  { code: "55740", shortdesc: "null" },
  {
    code: "92523",
    shortdesc: "EVAL SPEECH SOUND PRODUCT LANGUAGE COMPREHENSION",
  },
  { code: "0463T", shortdesc: "INTERROG EVAL IMPLT AORTIC VENTR SYS PER DAY" },
  { code: "46945", shortdesc: "HRHC NTRNL LIG OTH THAN RBBR BAND 1 COL/GRP" },
  { code: "84478", shortdesc: "ASSAY OF TRIGLYCERIDES" },
  {
    code: "00124",
    shortdesc: "ANES EXTERNAL MIDDLE & INNER EAR W/BX OTOSCOPY",
  },
  { code: "85030", shortdesc: "null" },
  { code: "69950", shortdesc: "VESTIBULAR NRV SECTION TRANSCRANIAL APPROACH" },
  { code: "77316", shortdesc: "BRACHYTX ISODOSE PLN SMPL W/DOSIMETRY CAL" },
  { code: "27077", shortdesc: "RADICAL RESCTION TUMOR INNOMINATE BONE TOTAL" },
  {
    code: "35311",
    shortdesc: "TEAEC W/WO PATCH GRF SUBCLAV INNOM THORACIC INC",
  },
  { code: "83069", shortdesc: "ASSAY OF HEMOGLOBIN URINE" },
  { code: "31575", shortdesc: "LARYNGOSCOPY FLEXIBLE DIAGNOSTIC" },
  { code: "26750", shortdesc: "CLTX DSTL PHLNGL FX FNGR/THMB W/O MANJ EA" },
  { code: "15819", shortdesc: "CERVICOPLASTY" },
  {
    code: "0268T",
    shortdesc: "IM/REPL CARTD SINS BARREFLX ACT DEV PLS GEN ONLY",
  },
  { code: "0070U", shortdesc: "null" },
  {
    code: "87806",
    shortdesc: "IAADIADOO HIV1 ANTIGEN W/HIV1 & HIV2 ANTIBODIES",
  },
  { code: "35021", shortdesc: "DIR RPR ANEURYSM INNOMINATE/SUBCLAVIAN ARTERY" },
  {
    code: "95933",
    shortdesc: "ORBICULARIS OCULI REFLX ELECTRODIAGNOSTIC TEST",
  },
  { code: "01110", shortdesc: "null" },
  { code: "81020", shortdesc: "URINALYSIS 2/3 GLASS TEST" },
  {
    code: "95885",
    shortdesc: "NEEDLE EMG EA EXTREMITY W/PARASPINL AREA LIMITED",
  },
  {
    code: "69631",
    shortdesc: "TYMPANOPLASTY W/O MASTOIDECT W/O OSSICLE RECNSTJ",
  },
  { code: "71110", shortdesc: "RADEX RIBS BILATERAL 3 VIEWS" },
  { code: "46751", shortdesc: "SPHNCTROP ANAL INCONTINENCE/PROLAPSE CHLD" },
  { code: "85613", shortdesc: "RUSSELL VIPER VENOM TIME DILUTED" },
  { code: "54220", shortdesc: "IRRIGATION CORPORA CAVERNOSA PRIAPISM" },
  { code: "27252", shortdesc: "CLTX HIP DISLOCATION TRAUMATIC REQ ANESTHESIA" },
  { code: "36512", shortdesc: "THERAPEUTIC APHERESIS RED BLOOD CELLS" },
  { code: "35587", shortdesc: "IN-SITU VEIN BYP POP-TIBL PRONEAL" },
  { code: "95015", shortdesc: "IQ TSTS SEQL&INCRL RX/BIOLOGIC/VNM IMMT RXN" },
  { code: "81340", shortdesc: "TRB@ REARRANGEMENT ANAL AMPLIFICATION METHOD" },
  { code: "69637", shortdesc: "TMPP ANTRT/MASTOIDOTOMY PROSTHESIS TORP" },
  { code: "50396", shortdesc: "MANOMETRIC STDS THRU TUBE/NDWELLG URTRL CATH" },
  {
    code: "24538",
    shortdesc: "PRQ SKEL FIXJ SPRCNDYLR/TRANSCNDYLR HUMERAL FX",
  },
  { code: "73130", shortdesc: "RADEX HAND MINIMUM 3 VIEWS" },
  {
    code: "61524",
    shortdesc: "CRNEC INFRATNTOR/POSTFOSSA EXC/FENESTRATION CYST",
  },
  { code: "50700", shortdesc: "URETEROPLASTY PLASTIC OPERATION URETER" },
  { code: "54820", shortdesc: "null" },
  { code: "64874", shortdesc: "SUTURE NERVE REQ XTNSV MOBIL/TRPOS NERVE" },
  { code: "54001", shortdesc: "SLITTING PREPUCE DORSAL/LAT SPX XCP NEWBORN" },
  { code: "90751", shortdesc: "null" },
  { code: "95822", shortdesc: "ELECTROENCEPHALOGRAM REC COMA/SLEEP ONLY" },
  {
    code: "19120",
    shortdesc: "EXC CYST/ABERRANT BREAST TISSUE OPEN 1/> LESION",
  },
  { code: "82570", shortdesc: "CREATININE OTHER SOURCE" },
  { code: "87168", shortdesc: "MACROSCOPIC EXAMINATION ARTHROPOD" },
  { code: "69105", shortdesc: "BIOPSY EXTERNAL AUDITORY CANAL" },
  { code: "92553", shortdesc: "PURE TONE AUDIOMETRY AIR & BONE" },
  { code: "99151", shortdesc: "MOD SED SAME PHYS/QHP INITIAL 15 MINS <5 YRS" },
  { code: "20000", shortdesc: "null" },
  { code: "45136", shortdesc: "EXC ILEOANAL RSVR W/ILEOSTOMY" },
  { code: "61870", shortdesc: "CRNEC IMPLTJ NSTIM ELTRD CEREBELLAR CORTICAL" },
  { code: "45380", shortdesc: "COLONOSCOPY W/BIOPSY SINGLE/MULTIPLE" },
  {
    code: "29897",
    shortdesc: "ARTHROSCOPY ANKLE SURGICAL DEBRIDEMENT LIMITED",
  },
  {
    code: "39503",
    shortdesc: "RPR NEONATAL DIPHRG HERNIA W/WO CHEST TUBE INSJ",
  },
  {
    code: "24115",
    shortdesc: "EXC/CURTG BONE CYST/BENIGN TUMOR HUMERUS W/AGRFT",
  },
  { code: "62190", shortdesc: "CRTJ SHUNT SARACH/SDRL-ATR-JUG-AUR" },
  {
    code: "15241",
    shortdesc: "FTH/GT FR W/DIR CLSR F/C/C/M/N/AX/G/H/F EA20CM/<",
  },
  {
    code: "87903",
    shortdesc: "NFCT PHEXYP RESIST TISS CUL HIV FIRST 1-10 DRUGS",
  },
  { code: "0460T", shortdesc: "REPOS AORTIC VENTR DEV SUBCUTANEOUS ELECTRODE" },
  {
    code: "0010U",
    shortdesc: "NFCT DS STRN TYP WHL GENOME SEQUENCING PR ISOL",
  },
  { code: "77387", shortdesc: "GUIDANCE FOR LOC OF TARGET VOL RADIAJ TX DLVR" },
  {
    code: "11426",
    shortdesc: "EXC B9 LESION MRGN XCP SK TG S/N/H/F/G > 4.0CM",
  },
  { code: "96112", shortdesc: "null" },
  { code: "78585", shortdesc: "PULM PI PART VNTJ RBRTHING&WSHOT +-1 BRTH" },
  {
    code: "62319",
    shortdesc: "NJX INFUS/BOLUS DX/SBST EDRL/SUBARACH LUM/SACRAL",
  },
  {
    code: "33602",
    shortdesc: "CLOSURE SEMILUNAR VALVE AORTIC/PULM SUTURE/PATCH",
  },
  { code: "78220", shortdesc: "LVR FUNCJ STD HEPATBL AGT SRL IMAGES" },
  { code: "33883", shortdesc: "PLMT PROX XTN PROSTH EVASC RPR DTA 1ST XTN" },
  { code: "96139", shortdesc: "null" },
  { code: "86162", shortdesc: "COMPLEMENT TOTAL HEMOLYTIC" },
  { code: "82482", shortdesc: "CHOLINESTERASE RBC" },
  { code: "73719", shortdesc: "MRI LOWER EXTREM OTH/THN JT W/CONTRAST MATRL" },
  { code: "73010", shortdesc: "RADEX SCAPULA COMPLETE" },
  { code: "22819", shortdesc: "KYPHECTOMY 3 OR MORE SEGMENTS" },
  { code: "51736", shortdesc: "SIMPLE UROFLOMETRY" },
  { code: "99453", shortdesc: "null" },
  { code: "45387", shortdesc: "COLSC FLX PROX SPLENIC FLXR TNDSC STENT PLMT" },
  { code: "24351", shortdesc: "null" },
  { code: "4030F", shortdesc: "LONG-TERM OXYGEN THERAPY PRESCRIBED" },
  { code: "80069", shortdesc: "RENAL FUNCTION PANEL" },
  { code: "4134F", shortdesc: "ANTIHISTAMINE/DECONGESTANT NOT PRESCRIBED" },
  {
    code: "81263",
    shortdesc: "IGH@ VARIABLE REGION SOMATIC MUTATION ANALYSIS",
  },
  { code: "21325", shortdesc: "OPEN TREATMENT NASAL FRACTURE UNCOMPLICATED" },
  { code: "01462", shortdesc: "ANESTHESIA CLOSED PROC LOWER LEG ANKLE & FOOT" },
  { code: "0355T", shortdesc: "GI TRACT IMAGING INTRALUMINAL COLON WITH I&R" },
  { code: "67820", shortdesc: "CORRECTION TRICHIASIS EPILATION FORCEPS ONLY" },
  { code: "49580", shortdesc: "RPR UMBILICAL HERNIA < 5 YRS REDUCIBLE" },
  { code: "44205", shortdesc: "LAPS COLECTOMY PRTL W/RMVL TERMINAL ILEUM" },
  {
    code: "25645",
    shortdesc: "OPEN TX CARPAL BONE FRACTURE OTH/THN SCAPHOID EA",
  },
  {
    code: "99184",
    shortdesc: "INITIAT SELECTIVE HEAD/BODY HYPOTHERMIA NEONATE",
  },
  {
    code: "63043",
    shortdesc: "LAMOT PRTL FFD EXC DISC REEXPL 1 NTRSPC EA CRV",
  },
  { code: "76089", shortdesc: "null" },
  {
    code: "22841",
    shortdesc: "INTERNAL SPINAL FIXATION WIRING SPINOUS PROCESS",
  },
  { code: "00926", shortdesc: "ANES RAD ORCHIECTOMY INGUN INCL OPEN URTL PX" },
  { code: "78103", shortdesc: "BONE MARROW IMAGING MULTIPLE AREAS" },
  { code: "6030F", shortdesc: "ALL ELEM OF MAX STERILE BARRIER TECHNQ FLWD" },
  { code: "52441", shortdesc: "CYSTO INSERTION TRANSPROSTATIC IMPLANT SINGLE" },
  { code: "48548", shortdesc: "PANCREATICOJEJUNOSTOMY SIDE-TO-SIDE ANAST" },
  { code: "49904", shortdesc: "OMENTAL FLAP EXTRA-ABDOMINAL" },
  { code: "86677", shortdesc: "ANTIBODY HELICOBACTER PYLORI" },
  { code: "73110", shortdesc: "RADEX WRIST COMPLETE MINIMUM 3 VIEWS" },
  {
    code: "88386",
    shortdesc: "RA-BASED EVAL MLT MOLEC PROBES 251 THRU 500 PRBS",
  },
  {
    code: "37236",
    shortdesc: "OPEN/PERQ PLACEMENT INTRAVASCULAR STENT INITIAL",
  },
  {
    code: "27100",
    shortdesc: "TR XTRNL OBLQ MUSC TRCHNTR W/FSCAL/TDN XTN GRF",
  },
  { code: "24410", shortdesc: "MLT OSTEOT W/RELIGNMT IMED ROD HUMERAL SHAFT" },
  { code: "43865", shortdesc: "REVJ GSTR/JJ ANAST W/RCNSTJ W/VGTMY" },
  {
    code: "87515",
    shortdesc: "IADNA HEPATITIS B VIRUS DIRECT PROBE TECHNIQUE",
  },
  { code: "87205", shortdesc: "SMR PRIM SRC GRAM/GIEMSA STAIN BCT FUNGI/CELL" },
  {
    code: "53410",
    shortdesc: "URETHROPLASTY 1 STG RECNST MALE ANTERIOR URETHRA",
  },
  { code: "92082", shortdesc: "VISUAL FIELD XM UNI/BI W/INTERP INTERMED EXAM" },
  {
    code: "61521",
    shortdesc: "CRNEC TUM INFRATTL/PFOSSA MIDLINE TUM BASE SKULL",
  },
  {
    code: "59857",
    shortdesc: "INDUCED ABORT 1/> VAG SUPPOS DLVR FETUS HYSTOT",
  },
  {
    code: "97760",
    shortdesc: "ORTHOTICS MGMT & TRAINJ INITIAL ENCTR EA 15 MINS",
  },
  { code: "0325T", shortdesc: "null" },
  { code: "82634", shortdesc: "DEOXYCORTISOL 11-" },
  { code: "0334T", shortdesc: "null" },
  { code: "0081U", shortdesc: "null" },
  { code: "43850", shortdesc: "REVJ GASTRODUOL ANAST W/RCNSTJ W/O VAGOTOMY" },
  { code: "56306", shortdesc: "null" },
  {
    code: "23675",
    shortdesc: "CLTX SHOULDER DISLC W/SURG/ANTMCL NECK FX W/MANJ",
  },
  { code: "43100", shortdesc: "EXC LESION ESOPHOGUS W/PRIM RPR CERVICAL APPR" },
  {
    code: "86976",
    shortdesc: "PRETX SERUM RBC ANTIBODY IDENTIFICATION DILUTION",
  },
  { code: "41116", shortdesc: "EXCISION LESION FLOOR MOUTH" },
  { code: "78258", shortdesc: "ESOPHAGEAL MOTILITY" },
  { code: "28715", shortdesc: "ARTHRODESIS TRIPLE" },
  { code: "26445", shortdesc: "TENOLYSIS EXTENSOR TENDON HAND/FINGER EACH" },
  { code: "81240", shortdesc: "F2 GENE ANALYSIS 20210G >A VARIANT" },
  { code: "4006F", shortdesc: "BETA-BLKER THER PRESCRIBED" },
  { code: "85475", shortdesc: "HEMOLYSIN ACID" },
  {
    code: "70260",
    shortdesc: "RADIOLOGIC EXAM SKULL COMPLETE MINIMUM 4 VIEWS",
  },
  { code: "44385", shortdesc: "NDSC EVAL INTSTINAL POUCH DX W/COLLJ SPEC SPX" },
  {
    code: "93792",
    shortdesc: "PT/CAREGIVER TRAINJ FOR INITIATION HOME INR MNTR",
  },
  {
    code: "74291",
    shortdesc: "CHOLECYST ORAL CNTRST ADDL/REPEAT XM/MULT DAY XM",
  },
  { code: "85306", shortdesc: "CLOTTING INHIBITORS PROTEIN S FREE" },
  { code: "0091U", shortdesc: "null" },
  { code: "0239T", shortdesc: "BIOIMPEDANCE SPECTROSCOPY LIMB DIFFERENCES" },
  { code: "49322", shortdesc: "LAPS SURG W/ASPIR CAVITY/CYST SINGLE/MULTIPLE" },
  { code: "29086", shortdesc: "APPLICATION CAST FINGER" },
  { code: "75671", shortdesc: "ANGIOGRAPHY CAROTID CEREBRAL BILATERAL RS&I" },
  { code: "89331", shortdesc: "SPERM EVALUATION RETROGRADE EJACULATION URINE" },
  { code: "32664", shortdesc: "THORACOSCOPY W/THORACIC SYMPATHECTOMY" },
  { code: "58700", shortdesc: "SALPINGECTOMY COMPLETE/PARTIAL UNI/BI SPX" },
  { code: "46742", shortdesc: "RPR HI IMPRF ANUS W/FSTL TABDL & SACROPRNL" },
  { code: "80074", shortdesc: "ACUTE HEPATITIS PANEL" },
  {
    code: "78267",
    shortdesc: "UREA BREATH TEST C-14 ISOTOPIC ACQUISJ ANALYSIS",
  },
  { code: "50329", shortdesc: "BKBENCH RCNSTJ ALGRFT URETERAL ANAST EA" },
  {
    code: "0469T",
    shortdesc: "RTA POLARIZE SCAN OC SCR W/ONSITE AUTO RSLT BI",
  },
  {
    code: "21261",
    shortdesc: "PERIORBITAL OSTEOTOMIES W/BONE GRAFTS ICRA & XTR",
  },
  { code: "83036", shortdesc: "HEMOGLOBIN GLYCOSYLATED A1C" },
  { code: "33766", shortdesc: "SHUNT SUPERIOR VENA CAVA PULMONARY ART 1 LUNG" },
  { code: "90778", shortdesc: "null" },
  { code: "51841", shortdesc: "ANT VESICOURETHROPEXY/URETHROPEXY COMP" },
  { code: "01964", shortdesc: "null" },
  { code: "88112", shortdesc: "CYTP SLCTV CELL ENHANCEMENT INTERPJ XCPT C/V" },
  { code: "27557", shortdesc: "OPEN TX KNEE DISLOCATION W/LIGAMENTOUS REPAIR" },
  { code: "83050", shortdesc: "HEMOGLOBIN METHEMOGLOBIN QUANTITATIVE" },
  { code: "27442", shortdesc: "ARTHROPLASTY FEM CONDYLES/TIBIAL PLATEAU KNEE" },
  { code: "21360", shortdesc: "OPEN TX DEPRESSED MALAR FRACTURE" },
  { code: "0344T", shortdesc: "null" },
  { code: "82239", shortdesc: "BILE ACIDS TOTAL" },
  {
    code: "95961",
    shortdesc: "FUNCJAL CORT&SUBCORT MAPG PHYS/QHP ATTND INIT HR",
  },
  { code: "37246", shortdesc: "TRLML BALO ANGIOP OPEN/PERQ IMG S&I 1ST ART" },
  { code: "84066", shortdesc: "ASSAY OF PHOSPHATASE ACID PROSTATIC" },
  { code: "24147", shortdesc: "PARTIAL EXCISION BONE OLECRANON PROCESS" },
  { code: "35303", shortdesc: "TEAEC W/GRAFT POPLITEAL ARTERY" },
  { code: "80325", shortdesc: "DRUG SCREEN QUANT AMPHETAMINES 3 OR 4" },
  { code: "80016", shortdesc: "null" },
  {
    code: "99402",
    shortdesc: "PREVENT MED COUNSEL&/RISK FACTOR REDJ SPX 30 MIN",
  },
  { code: "36473", shortdesc: "ENDOVEN ABLTJ INCMPTNT VEIN MCHNCHEM 1ST VEIN" },
  {
    code: "31382",
    shortdesc: "PARTIAL LARYNG HEMILARYNG ANTERO-LATERO-VERTICAL",
  },
  { code: "24935", shortdesc: "STUMP ELONGATION UPPER EXTREMITY" },
  {
    code: "95940",
    shortdesc: "IONM 1 ON 1 IN OR W/ATTENDANCE EACH 15 MINUTES",
  },
  { code: "66711", shortdesc: "CILIARY BODY DSTRJ CYCLOPHOTOCOAG ENDOSCOPIC" },
  {
    code: "27194",
    shortdesc: "CLTX PEL RING FX DISLC DIAST/SUBLXTJ W/MANJ ANES",
  },
  { code: "81363", shortdesc: "HBB DUPLICATION/DELETION VARIANTS" },
  { code: "0477T", shortdesc: "REC FTL CAR SGL 3 CH SGL XTRJ TECHL ALYS" },
  { code: "65450", shortdesc: "DSTRJ LESION CRYOTHER PHOTO/THERMOCAUTZATION" },
  { code: "94004", shortdesc: "VENTILATION ASSIST & MGMT NURSING FAC PR DAY" },
  { code: "57200", shortdesc: "COLPORRHAPHY SUTURE INJURY VAGINA" },
  {
    code: "44143",
    shortdesc: "COLECTOMY PRTL W/END COLOSTOMY & CLSR DSTL SGMT",
  },
  { code: "81181", shortdesc: "null" },
  { code: "92534", shortdesc: "OPTOKINETIC NYSTAGMUS TEST" },
  {
    code: "63048",
    shortdesc: "LAM FACETECTOMY&FORAMTOMY 1 SGM EA CRV THRC/LMBR",
  },
  { code: "44372", shortdesc: "ENTEROSCOPY > 2ND PRTN W/PLMT PRQ TUBE" },
  { code: "35903", shortdesc: "EXCISION INFECTED GRAFT EXTREMITY" },
  { code: "0531T", shortdesc: "null" },
  {
    code: "28230",
    shortdesc: "TX OPN TENDON FLEXOR FOOT SINGLE/MULT TENDON SPX",
  },
  { code: "28011", shortdesc: "TENOTOMY PERCUTANEOUS TOE MULTIPLE TENDON" },
  {
    code: "22520",
    shortdesc: "PERCUTANEOUS VERTEBROPLSTY THORACIC W/WO BONE BX",
  },
  { code: "99239", shortdesc: "HOSPITAL DISCHARGE DAY MANAGEMENT > 30 MIN" },
  { code: "3517F", shortdesc: "HBV STATUS ASSESSED W/ RESULTS IN 1 YR" },
  { code: "52281", shortdesc: "CYSTO CALIBRATION DILAT URTL STRIX/STENOSIS" },
  { code: "36907", shortdesc: "TRLUML BALO ANGIOP CTR DIALYSIS SEG W/IMG S&I" },
  { code: "27560", shortdesc: "CLOSED TX PATELLAR DISLOCATION W/O ANESTHESIA" },
  { code: "82085", shortdesc: "ASSAY OF ALDOLASE" },
  { code: "87087", shortdesc: "null" },
  { code: "21050", shortdesc: "CONDYLECTOMY TEMPOROMANDIBULAR JOINT SPX" },
  { code: "92583", shortdesc: "SELECT PICTURE AUDIOMETRY" },
  { code: "59030", shortdesc: "FETAL SCALP BLOOD SAMPLING" },
  {
    code: "43242",
    shortdesc: "EGD INTRMURAL NEEDLE ASPIR/BIOP ALTERED ANATOMY",
  },
  { code: "64905", shortdesc: "NERVE PEDICLE TRANSFER FIRST STAGE" },
  {
    code: "50948",
    shortdesc: "LAPS URTRONEOCSTOST W/O CSTSC&URTRL STENT PLMT",
  },
  { code: "44700", shortdesc: "EXCLUSION SM INT FROM PELVIS MESH/PROSTH/TISS" },
  { code: "33201", shortdesc: "null" },
  { code: "99449", shortdesc: "INTERPROF PHONE/INTERNET ASSESS/MANAGE 31/>" },
  {
    code: "40831",
    shortdesc: "CLOSURE LACERATION VESTIBULE MOUTH > 2.5 CM/CPL",
  },
  { code: "4124F", shortdesc: "ANTIBIOTIC NEITHER PRESCRIBED NOR DISPENSED" },
  {
    code: "76885",
    shortdesc: "US INFT HIPS R-T IMG DYNAMIC REQ PHYS/QHP MANJ",
  },
  { code: "25023", shortdesc: "DCMPRN FASCT F/ARM&/WRST FLXR/XTNSR W/DBRDMT" },
  { code: "92562", shortdesc: "LOUDNESS BALANCE BINAURAL/MONAURAL" },
  { code: "51702", shortdesc: "INSJ TEMP NDWELLG BLADDER CATHETER SIMPLE" },
  { code: "50327", shortdesc: "BKBENCH RCNSTJ RENAL ALGRFT VENOUS ANAST EA" },
  { code: "11719", shortdesc: "TRIMMING NONDYSTROPHIC NAILS ANY NUMBER" },
  {
    code: "01420",
    shortdesc: "ANES CAST APPLICATION REMOVAL/REPAIR KNEE JOINT",
  },
  { code: "29843", shortdesc: "ARTHROSCOPY WRIST INFECTION LAVAGE&DRAINAGE" },
  { code: "40804", shortdesc: "RMVL EMBEDDED FB VESTIBULE MOUTH SMPL" },
  { code: "74742", shortdesc: "TRANSCERVICAL CATHJ FALLOPIAN TUBE RS&I" },
  {
    code: "15273",
    shortdesc: "APP SKN SUBGRFT T/A/L AREA/100SQ CM 1ST 100SQ CM",
  },
  { code: "77327", shortdesc: "BRACHYTHERAPY ISODOSE PLAN INTERMEDIATE" },
  { code: "42725", shortdesc: "I&D ABSC RTRPHRNGL/PARAPHARYNGEAL XTRNL APPR" },
  { code: "0002M", shortdesc: "null" },
  { code: "19360", shortdesc: "null" },
  { code: "89272", shortdesc: "EXTND CUL OOCYTE/EMBRYO 4-7 DAYS" },
  { code: "27686", shortdesc: "LNGTH/SHRT TDN LEG/ANKLE MLT TDN SAME INC EA" },
  { code: "82941", shortdesc: "ASSAY OF GASTRIN" },
  {
    code: "57455",
    shortdesc: "COLPOSCOPY CERVIX UPPR/ADJCNT VAGINA W/CERVIX BX",
  },
  { code: "3757F", shortdesc: "NO PSEUDOBULBAR AFFECT/SIALORRHEA/ALS SYMP" },
  { code: "01860", shortdesc: "ANES FOREARM WRIST/HAND CAST APPL RMVL/REPAIR" },
  { code: "0035U", shortdesc: "null" },
  {
    code: "0259T",
    shortdesc: "CARDIAC EXPOSURE FOR AORTIC VALVE W/CARD BYPASS",
  },
  { code: "90899", shortdesc: "UNLISTED PSYCHIATRIC SERVICE/PROCEDURE" },
  { code: "80091", shortdesc: "null" },
  {
    code: "59070",
    shortdesc: "TRANSABDOMINAL AMNIOINFUSION W/ULTRSND GUIDANCE",
  },
  { code: "80201", shortdesc: "DRUG SCREEN QUANTITATIVE TOPIRAMATE" },
  { code: "74178", shortdesc: "CT ABDOMEN & PELVIS W/O CONTRST 1/> BODY RE" },
  { code: "27161", shortdesc: "OSTEOTOMY FEMORAL NECK SEPARATE PROCEDURE" },
  { code: "61490", shortdesc: "CRANIOTOMY LOBOTOMY W/CINGULOTOMY" },
  { code: "42408", shortdesc: "EXC SUBLINGUAL SALIVARY CYST RANULA" },
  { code: "81175", shortdesc: "ASXL1 GENE ANALYSIS FULL GENE SEQUENCE" },
  { code: "64783", shortdesc: "EXC NEUROMA HAND/FOOT EA NRV XCP SM DGT" },
  {
    code: "0499T",
    shortdesc: "CYSTO W/DIL & URTL RX DEL F/URTL STRIX/STENOSIS",
  },
  { code: "93600", shortdesc: "BUNDLE OF HIS RECORDING" },
  { code: "90940", shortdesc: "HEMODIALYSIS ACCESS FLOW STUDY" },
  { code: "88144", shortdesc: "null" },
  {
    code: "43237",
    shortdesc: "ESOPHAGOGASTRODUODENOSCOPY US SCOPE W/ADJ STRXRS",
  },
  { code: "92561", shortdesc: "BEKESY AUDIOMETRY DIAGNOSTIC" },
  {
    code: "67041",
    shortdesc: "VITRECTOMY PARS PLANA REMOVE PRERETINAL MEMBRANE",
  },
  { code: "69660", shortdesc: "STAPEDECTOMY/STAPEDOTOMY" },
  {
    code: "28043",
    shortdesc: "EXCISION TUMOR SOFT TISSUE FOOT/TOE SUBQ <1.5CM",
  },
  {
    code: "90587",
    shortdesc: "DENGUE VACC QUAD LIVE 3 DOSE SCHEDULE SUBQ USE",
  },
  { code: "24138", shortdesc: "SEQUESTRECTOMY OLECRANON PROCESS" },
  { code: "3075F", shortdesc: "MOST RECENT SYSTOLIC BLOOD PRESS 130-139MM HG" },
  {
    code: "87910",
    shortdesc: "NFCT AGT GENOTYPE NUCLEIC ACID CYTOMEGALOVIRUS",
  },
  { code: "72128", shortdesc: "CT THORACIC SPINE W/O CONTRAST MATERIAL" },
  { code: "43620", shortdesc: "GSTRCT TOT W/ESOPHAGOENTEROSTOMY" },
  { code: "85022", shortdesc: "null" },
  { code: "91030", shortdesc: "ESOPHAGUS ACID PERFUSION TEST ESOPHAGITIS" },
  { code: "93566", shortdesc: "NJX SEL R VENT/ATRIAL ANGIO HRT CATH W/S&I" },
  { code: "31585", shortdesc: "null" },
  {
    code: "65210",
    shortdesc: "RMVL FB XTRNL EYE EMBED SCJNCL/SCLERAL NONPERFOR",
  },
  { code: "33412", shortdesc: "REPLACEMENT AORTIC VALVE KONNO PROCEDURE" },
  { code: "76393", shortdesc: "null" },
  { code: "15366", shortdesc: "TISS CLTR ALGC DRM F/S/N/H/F/G/M/D EA 100 CM" },
  { code: "82945", shortdesc: "GLUCOSE BODY FLUID OTHER THAN BLOOD" },
  { code: "26530", shortdesc: "ARTHROPLASTY METACARPOPHALANGEAL JOINT EACH" },
  { code: "82379", shortdesc: "CARNITINE QUANTITATIVE EACH SPECIMEN" },
  { code: "80188", shortdesc: "DRUG SCREEN QUANTITATIVE PRIMIDONE" },
  { code: "43254", shortdesc: "EGD TRANSORAL ENDOSCOPIC MUCOSAL RESECTION" },
  { code: "77054", shortdesc: "MAMMARY DUCTOGRAM OR GALACTOGRAM MULTIPLE" },
  {
    code: "28296",
    shortdesc: "CORRJ HALLUX VALGUS W/SESMDC W/DIST METAR OSTEOT",
  },
  { code: "54700", shortdesc: "I&D EPIDIDYMIS TSTIS&/SCROTAL SPACE" },
  {
    code: "37761",
    shortdesc: "LIG PRFRATR VEIN SUBFSCAL OPEN INCL US GID 1 LEG",
  },
  { code: "19350", shortdesc: "NIPPLE/AREOLA RECONSTRUCTION" },
  { code: "80336", shortdesc: "ANTIDEPRESSANTS TRICYCLIC OTHER CYCLICALS 3-5" },
  {
    code: "70030",
    shortdesc: "RADIOLOGIC EXAMINATION EYE DETECT FOREIGN BODY",
  },
  { code: "93328", shortdesc: "null" },
  {
    code: "67912",
    shortdesc: "CORRJ LAGOPHTHALMOS IMPLTJ UPR EYELID LID LOAD",
  },
  { code: "28120", shortdesc: "PARTIAL EXCISION BONE TALUS/CALCANEUS" },
  {
    code: "29807",
    shortdesc: "ARTHROSCOPY SHOULDER SURGICAL REPAIR SLAP LESION",
  },
  { code: "89140", shortdesc: "null" },
  { code: "45110", shortdesc: "PRCTECT COMPL CMBN ABDOMINOPRNL W/CLST" },
  {
    code: "37700",
    shortdesc: "LIG&DIV LONG SAPH VEIN SAPHFEM JUNCT/INTERRUPJ",
  },
  { code: "1032F", shortdesc: "CURRENT SMOKER/EXPOSED TO SECONDHAND SMOKE" },
  { code: "50405", shortdesc: "PYELOPLASTY COMPLICATED" },
  { code: "23491", shortdesc: "PROPH TX W/WO METHYLMETHACRYLATE PROX HUMERUS" },
  { code: "86148", shortdesc: "ANTI-PHOSPHATIDYLSERINE ANTIBODY" },
  { code: "11640", shortdesc: "EXCISION MALIGNANT LESION F/E/E/N/L 0.5 CM/<" },
  {
    code: "21470",
    shortdesc: "OPTX COMP MANDIBULAR FX MLT APPR W/INT FIXATION",
  },
  {
    code: "36908",
    shortdesc: "STENT PLMT CENTRAL DIAYLSIS SEG PFRMD DIAL CIR",
  },
  { code: "64790", shortdesc: "EXC NEUROFIBROMA/NEUROLEMMOMA MAJOR PRPH NRV" },
  {
    code: "99173",
    shortdesc: "SCREENING TEST VISUAL ACUITY QUANTITATIVE BILAT",
  },
  { code: "15836", shortdesc: "EXCISION EXCESSIVE SKIN & SUBQ TISSUE ARM" },
  { code: "41153", shortdesc: "GLSSC COMPOSIT RESCJ FLOOR SUPRAHYOID NCK DSJ" },
  {
    code: "57320",
    shortdesc: "CLOSURE VESICOVAGINAL FISTULA VAGINAL APPROACH",
  },
  { code: "46230", shortdesc: "EXCISION MULTIPLE EXTERNAL PAPILLAE/TAGS ANUS" },
  {
    code: "85245",
    shortdesc: "CLOTTING FACTOR VIII VW FACTOR RISTOCETIN COFACT",
  },
  {
    code: "27457",
    shortdesc: "OSTEOT PROX TIBIA FIB EXC/OSTEOT AFTER EPIPHYSL",
  },
  {
    code: "27307",
    shortdesc: "TENOTOMY PRQ ADDUCTOR/HAMSTRING MULTIPLE TENDON",
  },
  {
    code: "19085",
    shortdesc: "BX BREAST W/DEVICE 1ST LESION MAGNETIC RES GUID",
  },
  { code: "63087", shortdesc: "VCRPEC THORACOLMBR DCMPRN LWR THRC/LMBR 1 SEG" },
  { code: "01906", shortdesc: "null" },
  { code: "31600", shortdesc: "TRACHEOSTOMY PLANNED SEPARATE PROCEDURE" },
  { code: "27613", shortdesc: "BIOPSY SOFT TISSUE LEG/ANKLE AREA SUPERFICIAL" },
  { code: "90696", shortdesc: "DTAP-IPV VACCINE CHILD 4-6 YRS FOR IM USE" },
  {
    code: "00851",
    shortdesc: "ANES IPER LWR ABD W/LAPS TUBAL LIGATION/TRANSECT",
  },
  { code: "51102", shortdesc: "ASPIRATION BLADDER INSERT SUPRAPUBIC CATHETER" },
  {
    code: "28665",
    shortdesc: "CLTX INTERPHALANGEAL JOINT DISLOCATION REQ ANES",
  },
  { code: "97265", shortdesc: "null" },
  {
    code: "93616",
    shortdesc: "ESOPHGL REC ATRIAL W/WO VENTR ELECTRGRAMS W/PACG",
  },
  { code: "80434", shortdesc: "INSULIN TOLERANCE PANEL ACTH INSUFFICIENCY" },
  { code: "15833", shortdesc: "EXCISION EXCESSIVE SKIN & SUBQ TISSUE LEG" },
  { code: "31708", shortdesc: "null" },
  {
    code: "76805",
    shortdesc: "US PREG UTERUS AFTER 1ST TRIMEST 1/1ST GESTATION",
  },
  { code: "59821", shortdesc: "TX MISSED ABORTION SECOND TRIMESTER SURGICAL" },
  { code: "11046", shortdesc: "DEBRIDEMENT MUSCLE &/FASCIA EA ADDL 20 SQ CM" },
  {
    code: "95811",
    shortdesc: "POLYSOM 6/>YRS SLEEP W/CPAP 4/> ADDL PARAM ATTND",
  },
  {
    code: "43196",
    shortdesc: "ESOPHAGOSCOPY RIG TRANSORAL GUIDE WIRE DILATION",
  },
  { code: "84392", shortdesc: "ASSAY OF SULFATE URINE" },
  {
    code: "28292",
    shortdesc: "CORRJ HALLUX VALGUS W/SESMDC W/RESCJ PROX PHAL",
  },
  {
    code: "4266F",
    shortdesc: "USE WET TO DRY DRESSINGS NEITHER RXD NOR RECMD",
  },
  { code: "71090", shortdesc: "INSJ PM FLUOR&RADIOGRAPY RS&I" },
  { code: "92551", shortdesc: "SCREENING TEST PURE TONE AIR ONLY" },
  { code: "90968", shortdesc: "ESRD RELATED SVC <FULL MONTH 2-11 YR OLD" },
  { code: "78428", shortdesc: "CARDIAC SHUNT DETECTION" },
  { code: "30220", shortdesc: "INSERTION NASAL SEPTAL PROSTHESIS BUTTON" },
  { code: "89300", shortdesc: "SEMEN ALYS PRESENCE&/MOTILITY SPRM HUHNER" },
  { code: "00470", shortdesc: "ANESTHESIA PARTIAL RIB RESECTION NOS" },
  { code: "92586", shortdesc: "AUDITORY EVOKED POTENTIALS LIMITED" },
  { code: "69433", shortdesc: "TYMPANOSTOMY LOCAL/TOPICAL ANESTHESIA" },
  { code: "81443", shortdesc: "null" },
  { code: "52510", shortdesc: "null" },
  { code: "0304T", shortdesc: "null" },
  {
    code: "28119",
    shortdesc: "OSTECTOMY CALCANEUS SPUR W/WO PLNTAR FASCIAL RLS",
  },
  { code: "89105", shortdesc: "null" },
  { code: "21431", shortdesc: "CLOSED TX CRANIOFACIAL SEPARATION" },
  { code: "23605", shortdesc: "CLTX PROX HUMRL FX W/MANJ W/WO SKELETAL TRACJ" },
  { code: "72292", shortdesc: "RAD S&I PERQ VRTPLS/SACRPLSTY PER VRT BODY CT" },
  { code: "16041", shortdesc: "null" },
  {
    code: "26434",
    shortdesc: "REPAIR EXTENSOR TENDON DISTAL INSERTION W/GRAFT",
  },
  { code: "86335", shortdesc: "IMMUNOFIXJ ELECTROPHORESIS OTHER FLUIDS" },
  { code: "36510", shortdesc: "CATHJ UMBILICAL VEIN DX/THER NB" },
  {
    code: "86904",
    shortdesc: "BLOOD TYPING ANTIGEN SCREEN PATIENT SERUM/UNIT",
  },
  { code: "94621", shortdesc: "CARDIOPULMONARY EXERCISE TESTING" },
  { code: "40525", shortdesc: "EXC LIP FULL THKNS RCNSTJ W/LOCAL FLAP" },
  { code: "93292", shortdesc: "INTERROGATION EVAL IN PERSON WR DEFIBRILLATOR" },
  { code: "87341", shortdesc: "IAAD IA HEPATITIS B SURFACE AG NEUTRALIZATION" },
  { code: "47143", shortdesc: "BKBENCH PREP CADAVER DONOR" },
  {
    code: "27334",
    shortdesc: "ARTHROTOMY W/SYNOVECTOMY KNEE ANTERIOR/POSTERIOR",
  },
  { code: "55520", shortdesc: "EXC LESION SPERMATIC CORD SEPARATE PROCEDURE" },
  { code: "80004", shortdesc: "null" },
  { code: "0294T", shortdesc: "INS LT ATRL HEMDYN MTR PRSR SENSR LEAD W/S&I" },
  {
    code: "33229",
    shortdesc: "REMVL PERM PM PLS GEN W/REPL PLSE GEN MULT LEAD",
  },
  { code: "0086T", shortdesc: "null" },
  { code: "45393", shortdesc: "COLONOSCOPY FLEXIBLE WITH DECOMPRESSION" },
  { code: "15360", shortdesc: "TISSUE CLTR ALGC DRM TRUNK/ARM/LEG 1ST 100 CM" },
  { code: "49655", shortdesc: "LAPS RPR INCISIONAL HERNIA NCRC8/STRANGULATED" },
  { code: "81050", shortdesc: "VOLUME MEASUREMENT TIMED COLLECTION EACH" },
  { code: "81281", shortdesc: "LONG QT SYNDROME ANAL KNOWN FAMILIAL SEQUENCE" },
  { code: "67830", shortdesc: "CORRECTION TRICHIASIS INCCISION LID MARGIN" },
  {
    code: "27428",
    shortdesc: "LIGAMENTOUS RECONSTRUCTION KNEE INTRA-ARTICULAR",
  },
  { code: "89258", shortdesc: "CRYOPRSRV EMBRYO" },
  {
    code: "19282",
    shortdesc: "PERQ DEVICE PLACEMT BREAST LOC EA LESION W/GDNCE",
  },
  { code: "20245", shortdesc: "BIOPSY BONE OPEN DEEP" },
  { code: "54861", shortdesc: "EPIDIDYMECTOMY BILATERAL" },
  {
    code: "95801",
    shortdesc: "SLP STDY UNATND W/MIN HRT RATE/O2 SAT/RESP ANAL",
  },
  {
    code: "0075T",
    shortdesc: "TCAT PLMT XTRC VRT CRTD STENT RS&I PRQ 1ST VSL",
  },
  { code: "0060U", shortdesc: "null" },
  { code: "86308", shortdesc: "HETEROPHILE ANTIBODIES SCREEN" },
  { code: "50725", shortdesc: "URTROLSS RETROCAVAL URTR W/REANAST" },
  {
    code: "77404",
    shortdesc: "RADJ DLVR 1 AREA 1/PRLL OPSD PORTS SMPL 11-19MEV",
  },
  { code: "0102U", shortdesc: "null" },
  { code: "96570", shortdesc: "PDT NDSC ABL ABNOR TISS VIA ACTIVJ RX 30 MIN" },
  { code: "63191", shortdesc: "LAMINECTOMY W/SECTION SPINAL ACCESSORY NERVE" },
  {
    code: "36254",
    shortdesc: "SUPSLCTV CATH 2ND+ORD RENAL&ACCESSORY ARTERY/S&I",
  },
  { code: "24301", shortdesc: "MUSCLE/TENDON TRANSFER UPPER ARM/ELBOW SINGLE" },
  { code: "93970", shortdesc: "DUP-SCAN XTR VEINS COMPLETE BILATERAL STUDY" },
  { code: "57000", shortdesc: "COLPOTOMY W/EXPLORATION" },
  {
    code: "49657",
    shortdesc: "LAPS RPR RECURRENT INCAL HRNA NCRC8/STRANGULATED",
  },
  { code: "87250", shortdesc: "VIRUS INOCULATION EGGS/SM ANIMAL OBS&DSJ" },
  {
    code: "50650",
    shortdesc: "URETRECECTOMY W/BLADDER CUFF SEPARATE PROCEDURE",
  },
  { code: "86711", shortdesc: "ANTIBODY JOHN CUNNINGHAM VIRUS" },
  { code: "31295", shortdesc: "NASAL/SINUS NDSC SURG W/DILAT MAXILLARY SINUS" },
  { code: "87527", shortdesc: "IADNA HEPATITIS G QUANTIFICATION" },
  { code: "3265F", shortdesc: "RNA TESTING FOR HEP C VIREMIA ORDERED/DOCD" },
  { code: "70250", shortdesc: "RADIOLOGIC EXAMINATION SKULL 4/> VIEWS" },
  { code: "45321", shortdesc: "PROCTOSGMDSC RIGID DCMPRN VOLVULUS" },
  { code: "64708", shortdesc: "NEURP MAJOR PRPH NRV ARM/LEG OPN OTH/THN SPEC" },
  { code: "27860", shortdesc: "MANIPULATION ANKLE UNDER GENERAL ANESTHESIA" },
  { code: "15170", shortdesc: "ACLR DRM RPLCMT T/A/L 1ST 100 CM/</1 % BDY" },
  {
    code: "58548",
    shortdesc: "LAPS W/RAD HYST W/BILAT LMPHADEC RMVL TUBE/OVARY",
  },
  { code: "59830", shortdesc: "TX SEPTIC ABORTION SURGICAL" },
  { code: "0363T", shortdesc: "EXPOSURE BEHAV ASSESSMENT ADDL 30 MIN" },
  { code: "76092", shortdesc: "null" },
  { code: "17111", shortdesc: "DESTRUCTION BENIGN LESIONS 15/>" },
  { code: "66605", shortdesc: "IRDEC CRNLSCLRL/CRNL SCTJ CYCLECTOMY" },
  { code: "47532", shortdesc: "NJX CHOLANGIO PRQ W/IMG GID RS&I NEW ACCESS" },
  { code: "74400", shortdesc: "UROGRAPHY IV W/WO KUB W/WO TOMOGRAPHY" },
  { code: "58146", shortdesc: "MYOMECTOMY 5/> MYOMAS &/>250 GM ABDOMINA" },
  { code: "73725", shortdesc: "MRA LOWER EXTREMITY W/WO CONTRAST MATERIAL" },
  { code: "82010", shortdesc: "KETONE BODIES SERUM QUANTITATIVE" },
  { code: "4500F", shortdesc: "REFERRED TO OUTPT CARD REHABILITATION PROGRAM" },
  { code: "58311", shortdesc: "null" },
  { code: "88749", shortdesc: "UNLISTED IN VIVO LABORTORY SERVICE" },
  {
    code: "86960",
    shortdesc: "VOLUME REDUCTION BLOOD/BLOOD PRODUCT EACH UNIT",
  },
  {
    code: "99466",
    shortdesc: "CRITICAL CARE INTERFACILITY TRANSPORT 30-74 MIN",
  },
  {
    code: "50695",
    shortdesc: "PLMT URTRL STENT PRQ NEW ACCESS W/SEP NFROS CATH",
  },
  { code: "58983", shortdesc: "null" },
  { code: "0517F", shortdesc: "GLAUCOMA PLAN OF CARE DOCUMENTED" },
  { code: "69820", shortdesc: "FENESTRATION SEMICIRCULAR CANAL" },
  { code: "74260", shortdesc: "DUODENOGRAPY HYPOTONIC" },
  { code: "65105", shortdesc: "ENUCLEATION EYE IMPLT MUSC ATTACHED IMPLT" },
  { code: "84081", shortdesc: "PHOSPHATIDYLGLYCEROL" },
  {
    code: "3493F",
    shortdesc: "NO HIST NADIR CD4+ CELL CNT <350&AIDS CONDITION",
  },
  {
    code: "82542",
    shortdesc: "COL-CHR/MS NONDRUG ANALYTE NES QUAL/QUAN EA SPEC",
  },
  { code: "93793", shortdesc: "ANTICOAGULANT MGMT FOR PT TAKING WARFARIN" },
  { code: "21296", shortdesc: "REDUCTION MASSETER MUSCLE & BONE INTRAORAL" },
  {
    code: "61797",
    shortdesc: "STRTCTC RADIOSURGERY EA ADDL CRANIAL LES SIMPLE",
  },
  {
    code: "35666",
    shortdesc: "BYP OTH/THN VEIN FEM-ANT TIBL PST TIBL/PRONEAL",
  },
  {
    code: "99335",
    shortdesc: "DOM/R-HOME E/M EST PT LW MOD SEVERITY 25 MINUTES",
  },
  { code: "0513T", shortdesc: "null" },
  { code: "83921", shortdesc: "ORGANIC ACID 1 QUANTITATIVE" },
  {
    code: "95982",
    shortdesc: "ELEC ALYS NSTIM PLS GEN GASTRIC SBSQ W/REPRGRMG",
  },
  { code: "4554F", shortdesc: "PT RECEIVED INHALATIONAL ANESTHETIC AGENT" },
  { code: "23331", shortdesc: "REMOVAL FOREIGN BODY SHOULDER DEEP" },
  {
    code: "53855",
    shortdesc: "INSERT TEMP PROSTATIC URETH STENT W/MEASUREMENT",
  },
  { code: "63268", shortdesc: "LAM EXC/EVAC ISPI LES OTH/THN NEO XDRL SACRAL" },
  { code: "00500", shortdesc: "ANESTHESIA ESOPHAGUS" },
  { code: "38760", shortdesc: "INGUINOFEM LMPHADEC SUPFC W/CLOQUETS NODE SPX" },
  { code: "0554T", shortdesc: "null" },
  { code: "73115", shortdesc: "RADEX WRIST ARTHROGRAPHY RS&I" },
  { code: "17286", shortdesc: "DESTRUCTION MAL LESION F/E/E/N/L/M >4.0 CM" },
  { code: "84127", shortdesc: "PORPHYRINS FECES QUALITATIVE" },
  { code: "80329", shortdesc: "DRUG SCREEN ANALGESICS NON-OPIOID 1 OR 2" },
  { code: "82735", shortdesc: "ASSAY OF FLUORIDE" },
  { code: "90738", shortdesc: "JAPANESE ENCEPHALITIS VACCINE INACTIVATED IM" },
  { code: "66700", shortdesc: "CILIARY BODY DESTRUCTION DIATHERMY" },
  { code: "27720", shortdesc: "REPAIR NONUNION/MALUNION TIBIA W/O GRAFT" },
  { code: "97022", shortdesc: "APPLICATION MODALITY 1/> AREAS WHIRLPOOL" },
  {
    code: "53425",
    shortdesc: "URTP 2-STG RCNSTJ/RPR PROSTAT/URETHRA 2ND STAGE",
  },
  { code: "46744", shortdesc: "RPR CLOACAL ANOMALY SACROPERINEAL" },
  {
    code: "38241",
    shortdesc: "TRNSPLJ AUTOLOGOUS HEMATOPOIETIC CELLS PER DONOR",
  },
  {
    code: "86808",
    shortdesc: "SERUM SCREENING % REACTIVE ANTIBODY QUICK METH",
  },
  { code: "0115T", shortdesc: "null" },
  { code: "77086", shortdesc: "VERTEBRAL FRACTURE ASSESSMENT VIA DXA" },
  {
    code: "99149",
    shortdesc: "MOD SEDATJ PHYS OTH/THN PERF DX/THER SVC 5/> YRS",
  },
  {
    code: "99143",
    shortdesc: "MODERATE SEDATION PHYS PERF DX/THER SVC < 5 YRS",
  },
  {
    code: "33472",
    shortdesc: "VALVOTOMY PULM VALVE OPEN HRT W/INFLOW OCCLUSION",
  },
  {
    code: "0258T",
    shortdesc: "TTHRC EXPOS CATH-DELIV AORTIC VALVE W/O CARD BYP",
  },
  {
    code: "99172",
    shortdesc: "VISUAL FUNCT SCRNG AUTO SEMI-AUTO BI QUAN DETERM",
  },
  { code: "49525", shortdesc: "RPR INGUN HERNIA SLIDING ANY AGE" },
  { code: "26055", shortdesc: "TENDON SHEATH INCISION" },
  {
    code: "88267",
    shortdesc: "CHRMSM ALYS AMNIOTIC/VILLUS 15 CELL 1KARYOTYPE",
  },
  { code: "45150", shortdesc: "DIVISION STRICTURE RECTUM" },
  { code: "82656", shortdesc: "ELASTASE PANCREATIC FECAL QUAL/SEMI-QUAN" },
  { code: "94450", shortdesc: "BREATHING RESPONSE TO HYPOXIA" },
  {
    code: "93285",
    shortdesc: "PROGRAM EVAL IMPLANTABLE DEV IN PRSN ILR SYSTEM",
  },
  { code: "92133", shortdesc: "COMPUTERIZED OPHTHALMIC IMAGING OPTIC NERVE" },
  { code: "60699", shortdesc: "UNLISTED PROCEDURE ENDOCRINE SYSTEM" },
  { code: "43252", shortdesc: "EGD FLEX TRANSORAL W/OPTICAL ENDOMICROSCOPY" },
  { code: "25130", shortdesc: "EXCISION/CURETTAGE CYST/TUMOR CARPAL BONES" },
  {
    code: "42510",
    shortdesc: "PAROTID DUCT DVRJ BILATERAL WITH LIG BOTH DUCTS",
  },
  { code: "26540", shortdesc: "RPR COLTRL LIGM MTCARPHLNGL/IPHAL JT" },
  { code: "83003", shortdesc: "ASSAY OF GROWTH HORMONE HUMAN" },
  {
    code: "3016F",
    shortdesc: "PT SCRND UNHLTHY OH USE BY SYSTMTC SCRNG METHD",
  },
  { code: "84703", shortdesc: "GONADOTROPIN CHORIONIC QUALITATIVE" },
  { code: "24305", shortdesc: "TENDON LENGTHENING UPPER ARM/ELBOW EA TENDON" },
  { code: "36465", shortdesc: "NJX NONCMPND SCLEROSANT SINGLE INCMPTNT VEIN" },
  {
    code: "22548",
    shortdesc: "ARTHRD ANT TRANSORL/XTRORAL C1-C2 W/WO EXC ODNTD",
  },
  {
    code: "20500",
    shortdesc: "INJECTION SINUS TRACT THERAPEUTIC SEPARATE PROC",
  },
  { code: "42320", shortdesc: "DRAINAGE ABSCESS SUBMAXILLARY INTRAORAL" },
  { code: "2021F", shortdesc: "DILATED MACULAR OR FUNDUS EXAM PERFORMED" },
  {
    code: "11446",
    shortdesc: "EXC B9 LESION MRGN XCP SK TG F/E/E/N/L/M > 4.0CM",
  },
  { code: "92390", shortdesc: "null" },
  { code: "67975", shortdesc: "RCNSTJ EYELID FULL THICKNESS SECOND STAGE" },
  { code: "96151", shortdesc: "HLTH&BEHAVIOR ASSMT EA 15 MIN W/PT RE-ASSMT" },
  { code: "27880", shortdesc: "AMPUTATION LEG THROUGH TIBIA&FIBULA" },
  { code: "65222", shortdesc: "RMVL FB XTRNL EYE CORNEAL W/SLIT LAMP" },
  {
    code: "58951",
    shortdesc: "RESCJ PRIM PRTL MAL W/BSO & OMNTC TAH & LMPHAD",
  },
  { code: "31648", shortdesc: "BRNCHSC REMOVAL BRONCHIAL VALVE INITIAL" },
  { code: "51530", shortdesc: "CYSTOTOMY EXCISION BLADDER TUMOR" },
  { code: "30310", shortdesc: "REMOVAL FOREIGN BODY INTRANASAL GENERAL ANES" },
  {
    code: "25455",
    shortdesc: "EPIPHYSL ARRST EPIPHYSIOD/STAPLING DSTL RDS&ULNA",
  },
  { code: "23929", shortdesc: "UNLISTED PROCEDURE SHOULDER" },
  { code: "0402T", shortdesc: "COLLAGEN CROSS-LINKING OF CORNEA" },
  {
    code: "15933",
    shortdesc: "EXC SACRAL PRESSURE ULC W/PRIM SUTR W/OSTECTOMY",
  },
  { code: "84300", shortdesc: "ASSAY OF URINE SODIUM" },
  { code: "98961", shortdesc: "EDUCATION&TRAINING SELF-MGMT NONPHYS 2-4 PTS" },
  { code: "65772", shortdesc: "CRNL RELAXING INC CORRJ INDUCED ASTIGMATISM" },
  { code: "20606", shortdesc: "ARTHROCENTESIS ASPIR&/INJ INTERM JT/BURS W/US" },
  { code: "37226", shortdesc: "REVSC OPN/PRQ FEM/POP W/STNT/ANGIOP SM VSL" },
  {
    code: "68815",
    shortdesc: "PROBE NASOLACRIMAL DUCT W/WO IRRG INSJ TUBE/STNT",
  },
  { code: "0197T", shortdesc: "IFXJ LOCLZ&TRAKG TRGT/PT MTN DUR RADTX EA FXJ" },
  {
    code: "94240",
    shortdesc: "FUNCTIONAL RESIDUAL CAPACITY OR RESIDUAL VOLUME",
  },
  { code: "34715", shortdesc: "OPN AX/SUBCLA ART EXPOS DLVR EVASC PROSTH UNI" },
  {
    code: "0081T",
    shortdesc: "PLMT VISC XTN PROSTH EVASC RPR AAA EA VISC RS&I",
  },
  { code: "0337T", shortdesc: "ENDOTHELIAL FUNCTION ASSESSMENT NON-INVASIVE" },
  {
    code: "36226",
    shortdesc: "SLCTV CATH VERTEBRAL ART ANGIO VERTEBRAL ARTERY",
  },
  { code: "57522", shortdesc: "CONIZATION CERVIX W/WO D&C RPR ELTRD EXC" },
  { code: "17282", shortdesc: "DESTRUCTION MAL LESION F/E/E/N/L/M 1.1-2.0CM" },
  { code: "0007U", shortdesc: "RX TEST PRESUMPTIVE URINE W/DEF CONFIRMATION" },
  { code: "56507", shortdesc: "null" },
  {
    code: "00848",
    shortdesc: "ANES IPER LOWER ABD W/LAPS PELVIC EXENTERATION",
  },
  {
    code: "99318",
    shortdesc: "E/M ANNUAL NURSING FACILITY ASSESS STABLE 30 MIN",
  },
  {
    code: "33512",
    shortdesc: "CORONARY ARTERY BYPASS 3 CORONARY VENOUS GRAFTS",
  },
  { code: "23800", shortdesc: "ARTHRODESIS GLENOHUMERAL JOINT" },
  { code: "93721", shortdesc: "PLETHYSMOGRAPY TOT BDY TRCG ONLY W/O I&R" },
  { code: "44314", shortdesc: "REVJ ILEOSTOMY COMPLIC RCNSTJ IN-DEPTH SPX" },
  { code: "58822", shortdesc: "DRAINAGE OVARIAN ABSCESS ABDOMINAL APPROACH" },
  { code: "92610", shortdesc: "EVAL ORAL&PHARYNGEAL SWLNG FUNCJ" },
  {
    code: "36660",
    shortdesc: "CATHETERIZATION UMBILICAL NEWBORN ART DX/THERAPY",
  },
  { code: "28222", shortdesc: "TENOLYSIS FLEXOR FOOT MULTIPLE TENDONS" },
  {
    code: "27846",
    shortdesc: "OPTX ANKLE DISLOCATION W/O REPAIR/INTERNAL FIXJ",
  },
  { code: "61050", shortdesc: "CISTERNAL/LATERAL C1-C2 PUNCTURE W/O INJ SPX" },
  { code: "78265", shortdesc: "GASTRIC EMPTYNG IMAG STD W/SM BWL TRANSIT" },
  {
    code: "50382",
    shortdesc: "RMVL & RPLCMT INTLY DWELLING URETERAL STENT PRQ",
  },
  { code: "28111", shortdesc: "OSTECTOMY COMPLETE 1ST METATARSAL HEAD" },
  {
    code: "33966",
    shortdesc: "ECMO/ECLS RMVL OF PRPH CANNULA PRQ 6 YRS & OLDER",
  },
  { code: "94650", shortdesc: "null" },
  { code: "83880", shortdesc: "NATRIURETIC PEPTIDE" },
  { code: "77331", shortdesc: "SPEC DOSIM ONLY PRESCRIBED TREATING PHYS" },
  {
    code: "59150",
    shortdesc: "LAPS TX ECTOPIC PREG W/O SALPING&/OOPHORECTOMY",
  },
  { code: "25119", shortdesc: "SYNVCT XTNSR TDN SHTH WRST 1 RESCJ DSTL ULNA" },
  { code: "01340", shortdesc: "ANESTHESIA CLOSED PROCEDURES LOWER 1/3 FEMUR" },
  { code: "86885", shortdesc: "ANTIHUMAN GLOBULIN INDIR QUAL EA REAGENT CELL" },
  { code: "90654", shortdesc: "INFLUENZA VACC IIV3 SPLIT VIRUS PRSRV FREE ID" },
  { code: "4010F", shortdesc: "ACE INHIBITOR/ARB THERAPY RXD/CURRENTLY TAKEN" },
  {
    code: "99001",
    shortdesc: "HANDLG&/OR CONVEY OF SPEC FOR TR FROM PT TO LAB",
  },
  { code: "81232", shortdesc: "DYPD GENE ANALYSIS COMMON VARIANTS" },
  { code: "77776", shortdesc: "INTERSTITIAL RADIATION SOURCE APPLIC SIMPLE" },
  {
    code: "76810",
    shortdesc: "US PREG UTERUS > 1ST TRIMESTER ABDL EA GESTATIO",
  },
  { code: "57292", shortdesc: "CONSTRUCTION ARTIFICIAL VAGINA W/GRAFT" },
  { code: "11045", shortdesc: "DBRDMT SUBCUTANEOUS TISSUE EA ADDL 20 SQ CM" },
  {
    code: "85730",
    shortdesc: "THROMBOPLASTIN TIME PARTIAL PLASMA/WHOLE BLOOD",
  },
  { code: "82930", shortdesc: "GASTRIC ACID ANALYIS W/PH EACH SPECIMEN" },
  {
    code: "99456",
    shortdesc: "WORK RELATED/MED DBLT XM OTH/THN TREATING PHYS",
  },
  { code: "88174", shortdesc: "CYTP C/V AUTO THIN LYR PREPJ SCR SYS PHYS" },
  { code: "29025", shortdesc: "APPLICATION TURNBUCKLE JACKET BODY W/HEAD" },
  { code: "73120", shortdesc: "RADEX HAND 2 VIEWS" },
  {
    code: "29870",
    shortdesc: "ARTHROSCOPY KNEE DIAGNOSTIC W/WO SYNOVIAL BX SPX",
  },
  { code: "94761", shortdesc: "NONINVASIVE EAR/PULSE OXIMETRY MULTIPLE DETER" },
  { code: "10009", shortdesc: "null" },
  {
    code: "21742",
    shortdesc: "REPAIR PECTUS EXCAVATM/CARINATM MINLY W/O THRSC",
  },
  {
    code: "01842",
    shortdesc: "ANES ARTERIES FOREARM WRIST & HAND EMBOLECTOMY",
  },
  { code: "28313", shortdesc: "RCNSTJ ANGULAR DFRM TOE SOFT TISS PX ONLY" },
  {
    code: "0501T",
    shortdesc: "COR FFR DERIVED CTA DATA ASSESS COR ART DISEASE",
  },
  { code: "58555", shortdesc: "HYSTEROSCOPY DIAGNOSTIC SEPARATE PROCEDURE" },
  { code: "84138", shortdesc: "PREGNANETRIOL" },
  {
    code: "22212",
    shortdesc: "OSTEOTOMY SPINE PST/PSTLAT APPR 1 VRT SGM THRC",
  },
  {
    code: "00752",
    shortdesc: "ANES HRNA RPR UPR ABD LMBR&VENTRAL HERNIA&DEHISC",
  },
  { code: "92580", shortdesc: "null" },
  {
    code: "37223",
    shortdesc: "REVSC OPN/PRQ ILIAC ART W/STNT & ANGIOP IPSILATL",
  },
  {
    code: "6015F",
    shortdesc: "PATIENT OK FOR PER ORAL INTAKE (FOOD/MEDICATION)",
  },
  {
    code: "4018F",
    shortdesc: "THERAPEUTIC EXERCISE INVOLVED JTS INST/PRESCRIBE",
  },
  { code: "97154", shortdesc: "null" },
  { code: "35875", shortdesc: "THRMBC ARTL/VEN GRF OTH/THN HEMO GRF/FSTL" },
  {
    code: "75960",
    shortdesc: "TCAT STENT ILIAC/LOW EXT ART PRQ/OPN RSI EA VSL",
  },
  { code: "99446", shortdesc: "INTERPROF PHONE/INTERNET ASSESS/MANAGE 5-10" },
  { code: "94750", shortdesc: "PULMONARY COMPLIANCE STUDY" },
  { code: "46608", shortdesc: "ANOSCOPY W/RMVL FOREIGN BODY" },
  { code: "99407", shortdesc: "TOBACCO USE CESSATION INTENSIVE >10 MINUTES" },
  { code: "99090", shortdesc: "ANALYSIS CLINICAL DATA STORED IN COMPUTERS" },
  {
    code: "27222",
    shortdesc: "CLTX ACETABULM HIP/SOCKT FX MANJ W/WO SKEL TRACJ",
  },
  { code: "76091", shortdesc: "null" },
  {
    code: "55873",
    shortdesc: "CRYOSURGICAL ABLATION PROSTATE W/US & MONITORI",
  },
  {
    code: "93459",
    shortdesc: "CATH PLMT L HRT/ARTS/GRFTS WNJX & ANGIO IMG S&I",
  },
  {
    code: "33215",
    shortdesc: "RPSG PREV IMPLTED PM/DFB R ATR/R VENTR ELECTRODE",
  },
  { code: "21085", shortdesc: "IMPRESSION & PREPARATION ORAL SURGICAL SPLINT" },
  { code: "93741", shortdesc: "null" },
  { code: "11602", shortdesc: "EXCISION MAL LESION TRUNK/ARM/LEG 1.1-2.0 CM" },
  {
    code: "22800",
    shortdesc: "ARTHRODESIS POSTERIOR SPINAL DFRM UP 6 VRT SEG",
  },
  { code: "82715", shortdesc: "FAT DIFFIAL FECES QUANTITATIVE" },
  { code: "89355", shortdesc: "null" },
  {
    code: "13160",
    shortdesc: "SECONDARY CLOSURE SURG WOUND/DEHSN EXTSV/COMPLIC",
  },
  { code: "01600", shortdesc: "null" },
  { code: "99283", shortdesc: "EMERGENCY DEPARTMENT VISIT MODERATE SEVERITY" },
  { code: "50940", shortdesc: "DELIGATION URETER" },
  { code: "82360", shortdesc: "CALCULUS QUANTITATIVE CHEMICAL" },
  {
    code: "31525",
    shortdesc: "LARYNGOSCOPY W/WO TRACHEOSCOPY DX EXCEPT NEWBORN",
  },
  {
    code: "62310",
    shortdesc: "NJX DX/THER SBST EPIDURAL/SUBRACH CERV/THORACIC",
  },
  { code: "32402", shortdesc: "BIOPSY PLEURA OPEN" },
  { code: "67405", shortdesc: "ORBITOTOMY W/O BONE FLAP EXPL W/DRAINAGE ONLY" },
  { code: "21110", shortdesc: "APPL INTERDENTAL FIXATION DEVICE NON-FX/DISLC" },
  { code: "30580", shortdesc: "REPAIR FISTULA OROMAXILLARY" },
  { code: "81223", shortdesc: "CFTR GENE ANALYSIS FULL GENE SEQUENCE" },
  {
    code: "90656",
    shortdesc: "IIV3 VACC PRESERVATIVE FREE 0.5 ML DOSAGE IM USE",
  },
  { code: "58262", shortdesc: "VAG HYST 250 GM/< W/RMVL TUBE&/OVARY" },
  { code: "17260", shortdesc: "DESTRUCTION MALIGNANT LESION T/A/L 0.5 CM/<" },
  { code: "78130", shortdesc: "RED CELL SURVIVAL STUDY" },
  { code: "35549", shortdesc: "BYP W/VEIN AORTOILIOFEM BI" },
  { code: "58800", shortdesc: "DRAINAGE OVARIAN CYST UNI/BI SPX VAGINAL APPR" },
  { code: "89132", shortdesc: "null" },
  { code: "95984", shortdesc: "null" },
  { code: "99481", shortdesc: "null" },
  { code: "4274F", shortdesc: "FLU IMMUNO ADMIND/PREVIOUSLY RCVD" },
  { code: "0096U", shortdesc: "null" },
  {
    code: "92558",
    shortdesc: "EVOKED OTOACOUSTIC EMISSIONS SCREEN AUTO ANALYS",
  },
  {
    code: "81001",
    shortdesc: "URNLS DIP STICK/TABLET REAGENT AUTO MICROSCOPY",
  },
  { code: "62180", shortdesc: "VENTRICULOCISTERNOSTOMY" },
  {
    code: "35142",
    shortdesc: "DIR RPR RUPTD ANEURYSM & GRF COMMON FEMORAL ART",
  },
  { code: "93623", shortdesc: "PROGRAMMED STIMJ & PACG AFTER IV DRUG NFS" },
  { code: "90784", shortdesc: "null" },
  { code: "52650", shortdesc: "null" },
  { code: "9007F", shortdesc: "OTHER CAROTID STENT IPSIL TIA/STRK 120 DAYS/>" },
  {
    code: "33406",
    shortdesc: "RPLCMT AORTIC VALVE OPN ALLOGRAFT VALVE FREEHAND",
  },
  { code: "92310", shortdesc: "RX&FITG C-LENS SUPVJ CRNL LENS OU XCPT APHK" },
  { code: "98926", shortdesc: "OSTEOPATHIC MANIPULATIVE TX 3-4 BODY REGIONS" },
  {
    code: "4001F",
    shortdesc: "TOBACCO USE CESSATION IVNTJ PHARMACOLOGIC THER",
  },
  {
    code: "11301",
    shortdesc: "SHVG SKIN LESION 1 TRUNK/ARM/LEG DIAM 0.6-1.0 CM",
  },
  { code: "0548T", shortdesc: "null" },
  { code: "24357", shortdesc: "TENOTOMY ELBOW LATERAL/MEDIAL PERCUTANEOUS" },
  { code: "91033", shortdesc: "null" },
  {
    code: "27638",
    shortdesc: "EXC/CURETTAGE CYST/TUMOR TIBIA/FIBULA W/ALGRAFT",
  },
  { code: "93297", shortdesc: "INTERROGATION EVAL REMOTE </30 D CV MNTR SYS" },
  { code: "86931", shortdesc: "FROZEN BLOOD EACH UNIT THAWING" },
  { code: "27705", shortdesc: "OSTEOTOMY TIBIA" },
  {
    code: "0375T",
    shortdesc: "TOTAL DISC ARTHRP ANT APPR W/DISCECTOMY CRV 3+",
  },
  {
    code: "53510",
    shortdesc: "URETHRORRHAPHY SUTR URETHRAL WOUND/INJ PERINEAL",
  },
  { code: "80050", shortdesc: "GENERAL HEALTH PANEL" },
  { code: "83528", shortdesc: "ASSAY OF INTRINSIC FACTOR" },
  { code: "64898", shortdesc: "NERVE GRAFT MLT STRANDS ARM/LEG >4 CM" },
  {
    code: "12044",
    shortdesc: "REPAIR INTERMEDIATE N/H/F/XTRNL GENT 7.6-12.5CM",
  },
  {
    code: "90846",
    shortdesc: "FAMILY PSYCHOTHERAPY W/O PATIENT PRESENT 50 MINS",
  },
  { code: "47371", shortdesc: "LAPS SURG ABLTJ 1 > LVR TUM CRYOSURG" },
  { code: "27823", shortdesc: "OPEN TX TRIMALLEOLAR ANKLE FX W/FIXJ PST LIP" },
  { code: "31766", shortdesc: "CARINAL RECONSTRUCTION" },
  { code: "86687", shortdesc: "ANTIBODY HTLV-I" },
  { code: "78805", shortdesc: "RP LOCLZJ INFLAMMATORY PROCESS LIMITED AREA" },
  { code: "91065", shortdesc: "BREATH HYDROGEN/METHANE TEST" },
  { code: "43634", shortdesc: "GSTRCT PRTL DSTL W/FRMJ INTSTINAL POUCH" },
  { code: "26775", shortdesc: "CLTX IPHAL JT DISLC W/MANJ REQ ANES" },
  { code: "92591", shortdesc: "HEARING AID EXAMINATION & SELECTION BINAURAL" },
  {
    code: "92544",
    shortdesc: "OPTKINETIC NYSTAG BIDIR/FOVEAL/PERIPH STIM W/REC",
  },
  { code: "36560", shortdesc: "INSJ TUNNELED CTR VAD W/SUBQ PORT UNDER 5 YR" },
  {
    code: "35246",
    shortdesc: "RPR BLOOD VESSEL VEIN GRF INTRATHORACIC W/O BYP",
  },
  { code: "84119", shortdesc: "PORPHYRINS URINE QUALITATAIVE" },
  { code: "0059U", shortdesc: "null" },
  {
    code: "38792",
    shortdesc: "INJ RADIOACTIVE TRACER FOR ID OF SENTINEL NODE",
  },
  { code: "33922", shortdesc: "TRANSECTION PULMONARY ARTERY W/CARD BYPASS" },
  { code: "27625", shortdesc: "ARTHROTOMY W/SYNOVECTOMY ANKLE" },
  {
    code: "99406",
    shortdesc: "TOBACCO USE CESSATION INTERMEDIATE 3-10 MINUTES",
  },
  { code: "99353", shortdesc: "null" },
  {
    code: "87651",
    shortdesc: "IADNA STREPTOCOCCUS GROUP A AMPLIFIED PROBE TQ",
  },
  {
    code: "4178F",
    shortdesc: "ANTI-D IMMUNE GLOBULIN RCVD 26-30 WKS GESTATION",
  },
  {
    code: "29855",
    shortdesc: "ARTHRS AID TIBIAL FRACTURE PROXIMAL UNICONDYLAR",
  },
  {
    code: "90288",
    shortdesc: "BOTULISM IMMUNE GLOBULIN HUMAN INTRAVENOUS USE",
  },
  { code: "15956", shortdesc: "EXC TROCHANTERIC PR ULCER MUSC/MYOQ FLAP/SKIN" },
  {
    code: "93888",
    shortdesc: "TRANSCRANIAL DOPPLER STDY INTRACRANIAL ART LMTD",
  },
  {
    code: "28307",
    shortdesc: "OSTEOT W/WO LNGTH SHRT/CORRJ METAR XCP 1ST TOE",
  },
  { code: "59866", shortdesc: "MULTIFETAL PREGNANCY REDUCTION" },
  { code: "99510", shortdesc: "HOME VISIT INDIV FAM/MARRIAGE COUNSELING" },
  { code: "28735", shortdesc: "ARTHRD MIDTARSL/TARS MLT/TRANSVRS W/OSTEOT" },
  { code: "61642", shortdesc: "BALLOON DILAT INCRNL VASOSPSM PRQ EA VESSEL" },
  { code: "99600", shortdesc: "UNLISTED HOME VISIT SERVICE/PROCEDURE" },
  { code: "86658", shortdesc: "ANTIBODY ENTEROVIRUS" },
  { code: "69915", shortdesc: "VESTIBULAR NRV SECTION TRANSLABYRINTHINE APPR" },
  { code: "37251", shortdesc: "IV US NON-C VSL DX EVAL&/THER IVNTJ EA VSL" },
  { code: "27250", shortdesc: "CLTX HIP DISLOCATION TRAUMATIC W/O ANESTHESIA" },
  {
    code: "0023U",
    shortdesc: "ONC AML DNA GNTYP INT TANDEM DUP DETCJ/NONDETCJ",
  },
  {
    code: "25443",
    shortdesc: "ARTHROPLASTY W/PROSTHETIC RPLCMT SCAPHOID CARPAL",
  },
  { code: "40843", shortdesc: "VESTIBULOPLASTY POSTERIOR BILATERAL" },
  { code: "22206", shortdesc: "OSTEOTOMY SPINE POSTERIOR 3 COLUMN THORACIC" },
  { code: "61253", shortdesc: "BURR HOLE/TREPHINE INFRATENTORIAL UNI/BI" },
  { code: "46050", shortdesc: "I&D PERIANAL ABSCESS SUPERFICIAL" },
  { code: "31645", shortdesc: "BRONCHOSCOPY W/THER ASPIR TRACHBRNCL TREE 1ST" },
  { code: "81345", shortdesc: "null" },
  {
    code: "37185",
    shortdesc: "PRIM PRQ TRLUML MCHNL THRMBC N-COR N-ICRA SBSQ",
  },
  { code: "59160", shortdesc: "CURETTAGE POSTPARTUM" },
  { code: "4182F", shortdesc: "CONFORMAL RADIATION THERAPY NOT RECEIVED" },
  {
    code: "22513",
    shortdesc: "PERQ VERT AGMNTJ CAVITY CRTJ UNI/BI CANNULATION",
  },
  {
    code: "26607",
    shortdesc: "CLTX METACARPAL FX W/MANJ W/XTRNL FIXJ EA BONE",
  },
  {
    code: "88304",
    shortdesc: "LEVEL III SURG PATHOLOGY GROSS&MICROSCOPIC EXAM",
  },
  {
    code: "99145",
    shortdesc: "MODERAT SEDATION PHYS PERF DX/THER SVC EA 15 MIN",
  },
  { code: "83992", shortdesc: "ASSAY OF PHENCYCLIDINE" },
  { code: "70371", shortdesc: "CPLX DYNAMIC PHARYNGEAL&SP EVAL C/V REC" },
  {
    code: "20979",
    shortdesc: "LOW INTENSITY US STIMJ BONE HEALING NONINVASIVE",
  },
  { code: "95920", shortdesc: "INTRAOP NEUROPHYSIOLOGY TSTG PR HR" },
  { code: "42305", shortdesc: "DRAINAGE ABSCESS PAROTID COMPLICATED" },
  { code: "27606", shortdesc: "TENOTOMY PRQ ACHILLES TENDON SPX GENERAL ANES" },
  { code: "24800", shortdesc: "ARTHRODESIS ELBOW JOINT LOCAL" },
  { code: "00567", shortdesc: "ANES DIRECT CABG W/PUMP OXYGENATOR" },
  { code: "93226", shortdesc: "EXTERNAL ECG SCANNING ANALYSIS REPORT" },
  {
    code: "32655",
    shortdesc: "THORACOSCOPY W/RESECTION BULLAE W/WO PLEURAL PX",
  },
  { code: "73080", shortdesc: "RADEX ELBOW COMPLETE MINIMUM 3 VIEWS" },
  { code: "96117", shortdesc: "null" },
  {
    code: "42335",
    shortdesc: "SIALOLITHOTOMY SUBMNDBLR SUBMAX COMP INTRAORAL",
  },
  {
    code: "95146",
    shortdesc: "PREPJ& ANTIGEN ALLERGEN IMMUNOTHERAPY 2 INSECT",
  },
  {
    code: "65290",
    shortdesc: "RPR WND EXTRAOCULAR MUSCLE TENDON&/TENON CAPSU",
  },
  {
    code: "64570",
    shortdesc: "REMOVAL CRNL NRV NSTIM ELTRDS & PULSE GENERATO",
  },
  {
    code: "64447",
    shortdesc: "INJECTION ANESTHETIC AGENT FEMORAL NERVE SINGLE",
  },
  { code: "31081", shortdesc: "SINUSOT FRNT OBLIT W/O OSTPL FLAP CORONAL INC" },
  { code: "49611", shortdesc: "RPR OMPHALOCELE GROSS TYP OPRATION 2ND STG" },
  {
    code: "49203",
    shortdesc: "EXCISION/DESTRUCTION OPEN ABDOMINAL TUMOR 5 CM/<",
  },
  {
    code: "50328",
    shortdesc: "BKBENCH RCNSTJ RENAL ALLOGRAFT ARTERIAL ANAST EA",
  },
  {
    code: "93531",
    shortdesc: "CMBN R HRT & RETROGRADE L HRT CATHJ CGEN ANOMA",
  },
  {
    code: "99411",
    shortdesc: "PREV MED COUNSEL & RISK FACTOR REDJ GRP SPX 30 M",
  },
  { code: "99509", shortdesc: "HOME VISIT ASSISTANCE DAILY LIV&PRSONAL CARE" },
  { code: "65112", shortdesc: "EXENTERATION ORBIT RMVL ORBIT CONTENTS & BONE" },
  { code: "50840", shortdesc: "RPLCMT ALL/PART URETER INTESTINE SGM W/ANAST" },
  { code: "83915", shortdesc: "ASSAY OF NUCLEOTIDASE 5-" },
  {
    code: "43886",
    shortdesc: "GSTR RSTCV PX OPN REVJ SUBQ PORT COMPONENT ONLY",
  },
  { code: "0411T", shortdesc: "INSJ/RPLC CAR MODULJ SYS VENTR ELECTRODE ONLY" },
  {
    code: "24670",
    shortdesc: "CLOSED TX ULNAR FRACTURE PROXIMAL END W/O MANJ",
  },
  { code: "82375", shortdesc: "CARBOXYHEMOGLOBIN QUANTITATIVE" },
  { code: "00850", shortdesc: "null" },
  {
    code: "95131",
    shortdesc: "PROF SVCS ALLG IMMNTX W/PRV XTRC 2 STING INSECT",
  },
  { code: "30140", shortdesc: "SUBMUCOUS RESCJ INFERIOR TURBINATE PRTL/COMPL" },
  {
    code: "27450",
    shortdesc: "OSTEOTOMY FEMUR SHAFT/SUPRACONDYLAR W/FIXATION",
  },
  { code: "19140", shortdesc: "null" },
  { code: "82055", shortdesc: "ALCOHOL ANY SPECIMEN EXCEPT BREATH" },
  { code: "29131", shortdesc: "APPLICATION FINGER SPLINT DYNAMIC" },
  {
    code: "95125",
    shortdesc: "PROF SVCS ALLG IMMNTX W/PRV ALLGIC XTRC 2/> NJX",
  },
  {
    code: "86255",
    shortdesc: "FLUORESCENT NONNFCT AGT ANTB SCREEN EA ANTIBODY",
  },
  {
    code: "29819",
    shortdesc: "ARTHROSCOPY SHOULDER SURGICAL REMOVAL LOOSE/FB",
  },
  { code: "92353", shortdesc: "FITTING SPECTACLE PROSTH APHAKIA MULTIFOCAL" },
  { code: "84100", shortdesc: "ASSAY OF PHOSPHORUS INORGANIC" },
  {
    code: "61552",
    shortdesc: "CRANIECT CRANIOSYNOSTOSIS MULT CRANIAL SUTURES",
  },
  { code: "64736", shortdesc: "TRANSECTION/AVULSION MENTAL NERVE" },
  {
    code: "25076",
    shortdesc: "EXC TUMOR SOFT TISS FOREARM&/WRIST SUBFASC <3CM",
  },
  { code: "86376", shortdesc: "MICROSOMAL ANTIBODIES EACH" },
  { code: "67115", shortdesc: "RELEASE ENCIRCLING MATERIAL POSTERIOR SEGMENT" },
  {
    code: "87522",
    shortdesc: "IADNA HEPATITIS C QUANT & REVERSE TRANSCRIPTION",
  },
  { code: "76882", shortdesc: "US LMTD JOINT/OTH NONVASC XTR STRUX R-T W/IMG" },
  { code: "3279F", shortdesc: "HEMOGLOBIN LEVEL>/EQUAL 13 G/DL" },
  { code: "10040", shortdesc: "ACNE SURGERY" },
  { code: "86941", shortdesc: "HEMOLYSINS&AGGLUTININS INCUBATED" },
  {
    code: "51595",
    shortdesc: "CSTC COMPL W/CONDUIT/SIGMOID BLDR PEL LMPHADEC",
  },
  { code: "27724", shortdesc: "RPR NON/MAL TIBIA W/ILIAC/OTH AGRFT" },
  {
    code: "42972",
    shortdesc: "CTRL NASOPHARYNGEAL HEMORRHAGE W/SEC SURG IVNTJ",
  },
  { code: "00955", shortdesc: "null" },
  { code: "74182", shortdesc: "MRI ABDOMEN W/CONTRAST MATERIAL" },
  { code: "61517", shortdesc: "IMPLTJ BRAIN INTRACAVITARY CHEMOTHERAPY AGENT" },
  { code: "25405", shortdesc: "RPR NONUNION/MALUNION RADIUS/ULNA W/AUTOGRAFT" },
  {
    code: "53420",
    shortdesc: "URTP 2-STG RCNSTJ/RPR PROSTAT/URETHRA 1ST STAGE",
  },
  { code: "42835", shortdesc: "ADENOIDECTOMY SECONDARY<AGE 12" },
  {
    code: "19283",
    shortdesc: "PERQ BREAST LOC DEVICE PLACEMT 1ST STRTCTC GDNCE",
  },
  {
    code: "86294",
    shortdesc: "IMMUNOASSAY TUMOR ANTIGEN QUAL/SEMIQUANTITATIVE",
  },
  {
    code: "01682",
    shortdesc: "ANES SHOULDER SPICA APPLICATION REMOVAL/REPAIR",
  },
  { code: "43458", shortdesc: "DILAT ESOPH BALLOON 30 MM DIAM/> ACHALASIA" },
  {
    code: "21555",
    shortdesc: "EXC TUMOR SOFT TISSUE NECK/ANT THORAX SUBQ <3CM",
  },
  {
    code: "85420",
    shortdesc: "FBRNLYC FACTORS&INHIBITRS PLSMNG XCPT AGIC ASS",
  },
  { code: "23530", shortdesc: "OPEN TX STERNOCLAVICULAR DISLC ACUTE/CHRONIC" },
  { code: "35494", shortdesc: "null" },
  { code: "35556", shortdesc: "BYPASS W/VEIN FEMORAL-POPLITEAL" },
  {
    code: "25391",
    shortdesc: "OSTEOPLASTY RADIUS/ULNA LENGTHENING W/AUTOGRAFT",
  },
  { code: "68540", shortdesc: "EXC LACRIMAL GLAND TUMOR FRONTAL APPROACH" },
  { code: "63190", shortdesc: "LAMINECTOMY W/RHIZOTOMY > 2 SEGMENTS" },
  { code: "27640", shortdesc: "PARTIAL EXCISION BONE TIBIA" },
  {
    code: "22214",
    shortdesc: "OSTEOTOMY SPINE PST/PSTLAT APPR 1 VRT SGM LMBR",
  },
  { code: "27130", shortdesc: "ARTHRP ACETBLR/PROX FEM PROSTC AGRFT/ALGRFT" },
  { code: "76802", shortdesc: "US PREG UTERUS 14 WK TRANSABDL EACH GESTATION" },
  { code: "0056U", shortdesc: "null" },
  { code: "59514", shortdesc: "CESAREAN DELIVERY ONLY" },
  { code: "38531", shortdesc: "null" },
  { code: "32557", shortdesc: "PERQ DRAINAGE PLEURA INSERT CATH W/IMAGING" },
  { code: "86652", shortdesc: "ANTIBODY ENCEPHALITIS EASTERN EQUINE" },
  { code: "01921", shortdesc: "null" },
  { code: "97028", shortdesc: "APPL MODALITY 1/> AREAS ULTRAVIOLET" },
  {
    code: "51065",
    shortdesc: "CYSTOTOMY W/CALCULUS BASKET XTRJ&/FRAGMENTATIO",
  },
  { code: "78600", shortdesc: "BRAIN IMAGING <4 STATIC VIEWS" },
  {
    code: "37722",
    shortdesc: "LIGJ DIVJ&STRIP LONG SAPH SAPHFEM JUNCT KNE/BELW",
  },
  { code: "31588", shortdesc: "LARYNGOPLASTY NOT OTHERWISE SPECIFIED" },
  { code: "0535F", shortdesc: "DYSPNEA MANAGEMENT PLAN DOCUMENTED" },
  { code: "87176", shortdesc: "HOMOGENIZATION TISSUE CULTURE" },
  {
    code: "4157F",
    shortdesc: "HEPATITIS B VACCINE SERIES PREVIOUSLY RECEIVED",
  },
  { code: "69604", shortdesc: "REVJ MASTOIDECTOMY RSLTG TYMPANOPLASTY" },
  { code: "70130", shortdesc: "RADEX MASTOIDS COMPL MINIMUM 3 VIEWS PR SIDE" },
  { code: "51597", shortdesc: "PELVIC EXENTERATION COMPLETE MALIGNANCY" },
  { code: "58322", shortdesc: "ARTIFICIAL INSEMINATION INTRA-UTERINE" },
  { code: "46715", shortdesc: "RPR LW IMPERFORATE ANUS W/ANOPRNL FSTL CUT-BK" },
  { code: "92311", shortdesc: "RX&FITG CONTACT CORNEAL LENS APHAKIA 1 EYE" },
  { code: "43772", shortdesc: "LAPS GASTRIC RESTRICTIVE PX REMOVE DEVICE" },
  {
    code: "99487",
    shortdesc: "CMPLX CHRON CARE MGMT W/O PT VST 1ST HR PER MO",
  },
  { code: "93460", shortdesc: "R & L HRT CATH WINJX HRT ART& L VENTR IMG" },
  { code: "4056F", shortdesc: "APPROPRIATE ORAL REHYD SOLUTION RECOMMENDED" },
  { code: "50280", shortdesc: "EXCISION/UNROOFING CYST KIDNEY" },
  { code: "33506", shortdesc: "RPR ANOM CORONARY ART FROM PULM ART TO AORTA" },
  {
    code: "61720",
    shortdesc: "CRTJ LES STRTCTC BURR GLOBUS PALLIDUS/THALAMUS",
  },
  { code: "54163", shortdesc: "REPAIR INCOMPLETE CIRCUMCISION" },
  {
    code: "14000",
    shortdesc: "ADJACENT TISSUE TRANSFER/REARGMT TRUNK 10 SQCM/<",
  },
  {
    code: "27637",
    shortdesc: "EXC/CURETTAGE CYST/TUMOR TIBIA/FIBULA W/AGRAFT",
  },
  { code: "58673", shortdesc: "LAPAROSCOPY SALPINGOSTOMY" },
  {
    code: "93965",
    shortdesc: "N-INVAS PHYSIOLOGIC STD XTR VEINS COMPL BI STD",
  },
  {
    code: "92925",
    shortdesc: "PRQ TRLUML CORONARY ANGIO/ATHEREC ADDL ART/BRNCH",
  },
  {
    code: "43122",
    shortdesc: "PRTL ESOPHG THORACOABD W/WO PROXGASTREC/PYLOROPL",
  },
  { code: "49422", shortdesc: "REMOVAL TUNNELED INTRAPERITONEAL CATHETER" },
  { code: "56200", shortdesc: "null" },
  {
    code: "10030",
    shortdesc: "IMAGE-GUIDED CATHETER FLUID COLLECTION DRAINAGE",
  },
  { code: "93925", shortdesc: "DUP-SCAN LXTR ART/ARTL BPGS COMPL BI STUDY" },
  {
    code: "40761",
    shortdesc: "PLSTC RPR CL LIP/NSL DFRM W/CROSS LIP PEDCL FLAP",
  },
  { code: "82810", shortdesc: "GASES BLOOD O2 SATURATION ONLY DIRECT MEAS" },
  {
    code: "27635",
    shortdesc: "EXCISION/CURETTAGE BONE CYST/TUMOR TIBIA/FIBULA",
  },
  { code: "67914", shortdesc: "REPAIR ECTROPION SUTURE" },
  {
    code: "31290",
    shortdesc: "NASAL/SINUS NDSC RPR CEREBRSP FLUID LEAK ETHMOID",
  },
  { code: "61867", shortdesc: "STRTCTC IMPLTJ NSTIM ELTRD W/RECORD 1ST ARRAY" },
  { code: "87801", shortdesc: "IADNA MULTIPLE ORGANISMS AMPLIFIED PROBE TQ" },
  { code: "78584", shortdesc: "PULM PI PART VNTJ 1 BRTH" },
  { code: "86490", shortdesc: "SKIN TEST COCCIDIOIDOMYCOSIS" },
  { code: "83519", shortdesc: "IMMUNOASSAY ANALYTE QUANT RADIOIMMUNOASSAY" },
  {
    code: "27337",
    shortdesc: "EXCISON TUMOR SOFT TISSUE THIGH/KNEE SUBQ 3 CM/>",
  },
  { code: "58300", shortdesc: "INSERTION INTRAUTERINE DEVICE IUD" },
  {
    code: "33516",
    shortdesc: "CORONARY ARTERY BYPASS 6/+ CORONARY VENOUS GRAFT",
  },
  { code: "29700", shortdesc: "REMOVAL/BIVALVING GAUNTLET BOOT/BODY CAST" },
  { code: "42410", shortdesc: "EXC PRTD TUM/PRTD GLND LAT LOBE W/O NRV DSJ" },
  { code: "0126T", shortdesc: "COMMON CAROTID INTIMA MEDIA THICKNESS STUDY" },
  {
    code: "0274T",
    shortdesc: "PERC LAMINO-/LAMINECTOMY IMAGE GUIDE CERV/THORAC",
  },
  { code: "27329", shortdesc: "RAD RESECT TUMOR SOFT TISSUE THIGH/KNEE <5CM" },
  {
    code: "88371",
    shortdesc: "PROTEIN ANAL TISSUE WESTERN BLOT W/INTERP&REPO",
  },
  { code: "94774", shortdesc: "PEDIATRIC APNEA MONITOR ATTACHMENT PHYS I&R" },
  {
    code: "42971",
    shortdesc: "CTRL NASOPHARYNGEAL HEMRRG COMP REQ HOSPIZATION",
  },
  { code: "1127F", shortdesc: "NEW EPISODE FOR CONDITION" },
  { code: "0357T", shortdesc: "CRYOPRESERVATION IMMATURE OOCYTE(S)" },
  { code: "74450", shortdesc: "URETHROCYSTOGRAPHY RETROGRADE RS&I" },
  {
    code: "68340",
    shortdesc: "RPR & DIV SYMBLEPHARON W/WO CONFORM/CONTACT LE",
  },
  {
    code: "43236",
    shortdesc: "ESOPHAGOGASTRODUODENOSCOPY SUBMUCOSAL INJECTION",
  },
  { code: "0003U", shortdesc: "ONC OVARIAN ASSAY 5 PROTEINS SERUM ALG SCOR" },
  { code: "90807", shortdesc: "INDIV PSYCTX OFFICE/OUTPT 45-50 MIN W/MED E/M" },
  {
    code: "96000",
    shortdesc: "COMPRE CPTR MTN ALYS VIDEO TAPING 3D KINEMATICS",
  },
  {
    code: "43640",
    shortdesc: "VGTMY W/PYLORPLSTY W/WO GASTROST TRUNCAL/SLCTV",
  },
  {
    code: "64680",
    shortdesc: "DSTRJ NEUROLYTIC W/WO RAD MONITOR CELIAC PLEXUS",
  },
  { code: "27730", shortdesc: "ARREST EPIPHYSEAL OPEN DISTAL TIBIA" },
  {
    code: "93350",
    shortdesc: "ECHO TTHRC R-T 2D W/WO M-MODE COMPLETE REST&ST",
  },
  {
    code: "99135",
    shortdesc: "ANES COMPLICJ UTILIZATION CONTROLLED HYPOTENSION",
  },
  {
    code: "97163",
    shortdesc: "PHYSICAL THERAPY EVALUATION HIGH COMPLEX 45 MINS",
  },
  { code: "84106", shortdesc: "PORPHOBILINOGEN URINE QUALITATIVE" },
  { code: "58544", shortdesc: "LAPS SUPRACRV HYSTEREC >250 G RMVL TUBE/OVARY" },
  { code: "64400", shortdesc: "NJX ANES TRIGEMINAL NRV ANY DIV/BRANCH" },
  { code: "58275", shortdesc: "VAGINAL HYSTERECTOMY W/TOT/PRTL VAGINECTOMY" },
  { code: "21343", shortdesc: "OPEN TX DEPRESSED FRONTAL SINUS FRACTURE" },
  { code: "01714", shortdesc: "ANESTHESIA TENOPLASTY ELBOW TO SHOULDER" },
  { code: "58560", shortdesc: "HYSTEROSCOPY DIV/RESCJ INTRAUTERINE SEPTUM" },
  {
    code: "88269",
    shortdesc: "CHRMSM SITU AMNIOTIC CLL 6-12 COLONIES 1KARYOTYP",
  },
  {
    code: "86920",
    shortdesc: "COMPATIBILITY EACH UNIT IMMEDIATE SPIN TECHNIQUE",
  },
  { code: "36600", shortdesc: "ARTERIAL PUNCTURE WITHDRAWAL BLOOD DX" },
  { code: "92960", shortdesc: "CARDIOVERSION ELECTIVE ARRHYTHMIA EXTERNAL" },
  { code: "87075", shortdesc: "CULTURE BACTERIAL ANY SOURCE ANAEROBIC ISO&ID" },
  {
    code: "23532",
    shortdesc: "OPTX STRNCLAV DISLC ACUTE/CHRONIC W/FASCIAL GRF",
  },
  {
    code: "0454T",
    shortdesc: "INSJ/RPLCMT IMPLTBL AORTIC VENTR SUBQ ELECTRODE",
  },
  {
    code: "01967",
    shortdesc: "NEURAXIAL LABOR ANALG/ANES PLND VAGINAL DELIVERY",
  },
  {
    code: "93609",
    shortdesc: "INTRA-VENTRIC&/ATRIAL MAPG TACHYCARD W/CATH MA",
  },
  {
    code: "58210",
    shortdesc: "RAD ABDL HYSTERECTOMY W/BI PELVIC LMPHADENECTOMY",
  },
  {
    code: "35121",
    shortdesc: "DIR RPR ANEURYSM HEPATIC/CELIAC/RENAL/MESENTERIC",
  },
  { code: "0074U", shortdesc: "null" },
  {
    code: "3352F",
    shortdesc: "NO SIGNIF DEP SYMP CAT BY STAND DEP ASSESS TOOL",
  },
  {
    code: "22804",
    shortdesc: "ARTHRODESIS POSTERIOR SPINAL DFRM 13/> VRT SEG",
  },
  {
    code: "22858",
    shortdesc: "TOT DISC ARTHRP ANT APPR DISC 2ND LEVEL CERVICAL",
  },
  { code: "00796", shortdesc: "ANES LAPAROSCOPIC LIVER TRANSPLANT" },
  {
    code: "0282T",
    shortdesc: "PERC/OPEN IMPLNT NEUROSTIM ELECTRODE SUBQ TRIAL",
  },
  { code: "23660", shortdesc: "OPEN TX ACUTE SHOULDER DISLOCATION" },
  {
    code: "0217T",
    shortdesc: "NJX DX/THER PARAVER FCT JT W/US LUMB/SAC LVL 2",
  },
  {
    code: "43243",
    shortdesc: "EGD INJECTION SCLEROSIS ESOPHGL/GASTRIC VARICES",
  },
  { code: "25040", shortdesc: "ARTHRT RDCRPL/MIDCARPL JT W/EXPL DRG/RMVL FB" },
  {
    code: "34841",
    shortdesc: "ENDOVASC VISCER AORTA REPAIR FENEST 1 ENDOGRAFT",
  },
  {
    code: "33641",
    shortdesc: "RPR ATRIAL SEPTAL DFCT SECUNDUM W/BYP W/WO PATCH",
  },
  {
    code: "33273",
    shortdesc: "REPOS PREVIOUSLY IMPLANTED SUBQ IMPLANTABLE DFB",
  },
  {
    code: "61534",
    shortdesc: "CRANIOT EPILEPTOGENIC FOC W/O ELECTRCORTICOGRPHY",
  },
  {
    code: "50391",
    shortdesc: "INSTLJ THER AGENT RENAL PELVIS&/URETER VIA TUB",
  },
  { code: "50200", shortdesc: "RENAL BIOPSY PRQ TROCAR/NEEDLE" },
  { code: "50544", shortdesc: "LAPAROSCOPY SURG PYELOPLASTY" },
  { code: "43286", shortdesc: "ESOPHAGECTOMY TOTAL NEAR TOTAL W/LAPS MOBLJ" },
  { code: "54250", shortdesc: "NOCTURNAL PENILE TUMESCENCE &/RIGIDITY TEST" },
  { code: "77051", shortdesc: "COMPUTER-AIDED DETECTION DX MAMMOGRAPHY" },
  { code: "80054", shortdesc: "null" },
  {
    code: "77421",
    shortdesc: "STRSC X-RAY GDN LOCLZJ TARGET VOL DLVR RADJ THER",
  },
  {
    code: "43264",
    shortdesc: "ERCP REMOVE CALCULI/DEBRIS BILIARY/PANCREAS DUCT",
  },
  { code: "92981", shortdesc: "TCAT PLMT INTRACORONARY STENT PRQ EA VESSEL" },
  { code: "86334", shortdesc: "IMMUNOFIXJ ELECTROPHORESIS SERUM" },
  { code: "00622", shortdesc: "ANES THORACIC SPINE&CORD THORACOLMBR SYMPTH" },
  { code: "87590", shortdesc: "IADNA NEISSERIA GONORRHOEAE DIRECT PROBE TQ" },
  {
    code: "15841",
    shortdesc: "GRAFT FACIAL NERVE PARALYSIS FREE MUSCLE GRAFT",
  },
  { code: "6040F", shortdesc: "USE APPROP RAD DOSE RDXN DEV/MAN TECHS DOCD" },
  { code: "55150", shortdesc: "RESECTION SCROTUM" },
  { code: "35491", shortdesc: "null" },
  { code: "88249", shortdesc: "CHRMSM BREAKAGE SYNDS SCORE 100 CLL" },
  {
    code: "56634",
    shortdesc: "VULVECTOMY RAD COMPL UNI INGUINOFEM LMPHADECTOMY",
  },
  { code: "0181T", shortdesc: "CORNEAL HYSTERESIS AIR IMPULSE STIMJ BI W/I&R" },
  {
    code: "32603",
    shortdesc: "THORACOSCOPY DX PERICARDIAL SAC W/O BIOPSY SPX",
  },
  { code: "20900", shortdesc: "BONE GRAFT ANY DONOR AREA MINOR/SMALL" },
  {
    code: "0396T",
    shortdesc: "INTRAOP KINETIC BALANCE SENSR KNEE RPLCMT ARTHRP",
  },
  { code: "93979", shortdesc: "DUP-SCAN AORTA IVC ILIAC VASCL/BPGS UNI/LMTD" },
  {
    code: "50434",
    shortdesc: "CONVERT NEPHROSTOMY CATH TO NEPHROURTRL CATH PRQ",
  },
  { code: "88355", shortdesc: "MORPHOMETRIC ANALYSIS SKELETAL MUSCLE" },
  { code: "0399T", shortdesc: "MYOCARDIAL STRAIN IMAGING QUAN ASSMT" },
  { code: "62284", shortdesc: "INJECTION PROCEDURE MYELOGRAPHY/CT LUMBAR" },
  { code: "43825", shortdesc: "GASTROJEJUNOSTOMY W/VAGOTOMY ANY TYPE" },
  {
    code: "91117",
    shortdesc: "COLON MOTILITY STDY MIN 6 HR CONT RECORD W/I&R",
  },
  {
    code: "93294",
    shortdesc: "INTERROGATION EVAL REMOTE </90 D 1/2/MLT LEAD PM",
  },
  {
    code: "21030",
    shortdesc: "EXC BENIGN TUMOR/CYST MAXL/ZYGOMA ENCL & CURTG",
  },
  { code: "93456", shortdesc: "CATH PLMT R HRT & ARTS W/NJX & ANGIO IMG S&I" },
  { code: "95999", shortdesc: "UNLIS NEUROLOGICAL/NEUROMUSCULAR DX PX" },
  {
    code: "61799",
    shortdesc: "STRTCTC RADIOSURGERY EA ADDL CRANIAL LES COMPLEX",
  },
  { code: "01180", shortdesc: "ANESTHESIA OBTURATOR NEURECTOMY EXTRAPELVIC" },
  { code: "58565", shortdesc: "HYSTEROSCOPY BI TUBE OCCLUSION W/PERM IMPLNTS" },
  { code: "25320", shortdesc: "CAPSL-RHPHY/RCNSTJ WRST OPN CARPL INS" },
  { code: "59580", shortdesc: "null" },
  { code: "27355", shortdesc: "EXCISION/CURETTAGE CYST/TUMOR FEMUR" },
  { code: "97005", shortdesc: "ATHLETIC TRAINING EVALUATION" },
  { code: "99349", shortdesc: "HOME VISIT EST PT MOD-HI SEVERITY 40 MINUTES" },
  {
    code: "92630",
    shortdesc: "AUDITORY REHABILITATION PRELINGUAL HEARING LOSS",
  },
  { code: "3118F", shortdesc: "NEW YORK HEART ASSOCIATION (NYHA) CLASS DOCD" },
  {
    code: "11641",
    shortdesc: "EXCISION MALIGNANT LESION F/E/E/N/L 0.6-1.0 CM",
  },
  { code: "39010", shortdesc: "MEDIAST W/EXPL DRG RMVL FB/BX TTHRC APPR" },
  { code: "84238", shortdesc: "RECEPTOR ASSAY NON-ENDOCRINE SPECIFY RECEPTOR" },
  {
    code: "33852",
    shortdesc: "RPR HYPOPLSTC A-ARCH W/AGRFT/PROSTC W/O BYPASS",
  },
  {
    code: "21502",
    shortdesc: "I&D DP ABSC/HMTMA SOFT TISS NCK/THORAX PRTL RI",
  },
  { code: "0107U", shortdesc: "null" },
  { code: "88125", shortdesc: "CYTOPATHOLOGY FORENSIC" },
  {
    code: "99307",
    shortdesc: "SBSQ NURSING FACILITY CARE/DAY E/M STABLE 10 MIN",
  },
  { code: "87797", shortdesc: "IADNA NOS DIRECT PROBE TQ EACH ORGANISM" },
  { code: "77520", shortdesc: "PROTON TX DELIVERY SIMPLE W/O COMPENSATION" },
  {
    code: "11008",
    shortdesc: "REMOVAL PROSTHETIC MATRL ABDL WALL FOR INFECTION",
  },
  { code: "96921", shortdesc: "LASER SKIN DISEASE PSORIASIS 250-500 SQ CM" },
  { code: "93014", shortdesc: "null" },
  {
    code: "00120",
    shortdesc: "ANESTHESIA EXTERNAL MIDDLE & INNER EAR W/BX NOS",
  },
  { code: "32810", shortdesc: "CLSR CH WALL FLWG OPN FLAP DRG EMPYEMA" },
  { code: "78278", shortdesc: "ACUTE GASTROINTESTINAL BLOOD LOSS IMAGING" },
  {
    code: "78414",
    shortdesc: "CARD-VASC HEMODYNAM W/WO PHARM/EXER 1/MLT DETERM",
  },
  { code: "83001", shortdesc: "GONADOTROPIN FOLLICLE STIMULATING HORMONE" },
  { code: "0048U", shortdesc: "null" },
  { code: "90885", shortdesc: "PSYCHIATRIC EVAL HOSPITAL RECORDS DX PURPOSES" },
  {
    code: "35002",
    shortdesc: "DIR RPR RUPTD ANEURYSM CAROTID-SUBCLAVIAN ARTERY",
  },
  { code: "0210T", shortdesc: "SPEECH AUDIOMETRY THRESHOLD AUTOMATED" },
  { code: "21125", shortdesc: "AGMNTJ MNDBLR BODY/ANGLE PROSTHETIC MATERIAL" },
  { code: "3500F", shortdesc: "CD4+CELL CNT/CD4+CELL % DOCD AS DONE" },
  {
    code: "3472F",
    shortdesc: "RHEUMATOID ARTHRITIS (RA) DISEASE ACTIVITY HIGH",
  },
  {
    code: "47015",
    shortdesc: "LAPT W/ASPIR &/NJX HEPATC PARASITIC CYST/ABSCESS",
  },
  { code: "3700F", shortdesc: "PSYCHIATRIC DISORDERS/DISTURBANCES ASSESSED" },
  {
    code: "0175T",
    shortdesc: "CAD CHEST RADIOGRAPH REMOTE FROM PRIMARY INTERPJ",
  },
  { code: "74230", shortdesc: "SWALLOWING FUNCJ W/CINERADIOGRAPY/VIDRADIOG" },
  { code: "59151", shortdesc: "LAPS TX ECTOPIC PREG W/SALPING&/OOPHORECTOMY" },
  { code: "17315", shortdesc: "MOHS TRUNK/ARM/LEG EA ADDL BLOCK ANY STAGE" },
  { code: "3130F", shortdesc: "UPPER GI ENDOSCOPY PERFORMED" },
  { code: "58321", shortdesc: "ARTIFICIAL INSEMINATION INTRA-CERVICAL" },
  { code: "99499", shortdesc: "UNLISTED EVALUATION AND MANAGEMENT SERVICE" },
  { code: "77011", shortdesc: "CT GUIDANCE STEREOTACTIC LOCALIZATION" },
  {
    code: "34714",
    shortdesc: "OPN FEM ART EXPOS W/CNDT CRTJ DLVR EVASC PROSTH",
  },
  { code: "72052", shortdesc: "RADEX SPINE CERVICAL 6 OR MORE VIEWS" },
  {
    code: "69661",
    shortdesc: "STAPEDECTOMY/STAPEDOTOMY W/FOOTPLATE DRILL OUT",
  },
  { code: "31591", shortdesc: "LARYNGOPLASTY MEDIALIZATION UNLIATERAL" },
  { code: "33419", shortdesc: "TCAT MITRAL VALVE REPAIR ADDL PROSTHESIS" },
  { code: "00600", shortdesc: "ANESTHESIA CERVICAL SPINE & CORD NOS" },
  { code: "34802", shortdesc: "EVASC RPR AAA W/MDLR BFRC PROSTH 1 LIMB" },
  {
    code: "56631",
    shortdesc: "VULVECTOMY RAD PRTL UNI INGUINOFEM LMPHADECTOMY",
  },
  { code: "15220", shortdesc: "FTH/GFT FREE W/DIRECT CLOSURE S/A/L 20 CM/<" },
  {
    code: "23078",
    shortdesc: "RAD RESECTION TUMOR SOFT TISSUE SHOULDER 5 CM/>",
  },
  { code: "21025", shortdesc: "EXCISION BONE MANDIBLE" },
  { code: "86618", shortdesc: "ANTIBODY BORRELIA BURGDORFERI LYME DISEASE" },
  { code: "3350F", shortdesc: "MAMMO ASSESSMENT CAT BIOPSY PROVEN MALIG DOCD" },
  {
    code: "33519",
    shortdesc: "CORONARY ARTERY BYP W/VEIN & ARTERY GRAFT 3 VEIN",
  },
  { code: "0020U", shortdesc: "RX TEST PRESUMPTIVE URINE W/DEF CONFIRMATION" },
  { code: "33465", shortdesc: "REPLACEMENT TRICUSPID VALVE W/CARD BYPASS" },
  {
    code: "67515",
    shortdesc: "INJECTION MEDICATION/OTHER SUBST TENON CAPSULE",
  },
  { code: "23182", shortdesc: "PARTIAL EXCISION BONE SCAPULA" },
  { code: "90704", shortdesc: "MUMPS VIRUS VACCINE LIVE SUBCUTANEOUS" },
  { code: "4562F", shortdesc: "PATIENT DOES NOT HAVE A CORONARY ARTERY STENT" },
  { code: "32663", shortdesc: "THORACOSCOPY W/LOBECTOMY SINGLE LOBE" },
  { code: "25607", shortdesc: "OPTX DSTL RADL X-ARTIC FX/EPIPHYSL SEP" },
  { code: "27091", shortdesc: "RMVL HIP PROSTH COMP W/TOT HIP PROSTH MMA" },
  { code: "26130", shortdesc: "SYNOVECTOMY CARPOMETACARPAL JOINT" },
  { code: "58987", shortdesc: "null" },
  {
    code: "0280T",
    shortdesc: "CELL ENUMRATION W/IMMUN SELXN ID FLUID SPEC I&R",
  },
  { code: "0471T", shortdesc: "OCT SKN IMG ACQUISJ I&R EA ADDL LES" },
  { code: "80176", shortdesc: "DRUG SCREEN QUANTITATIVE LIDOCAINE" },
  {
    code: "61584",
    shortdesc: "ORBITOCRANIAL ANT CRANIAL FOSSA W/O ORBIT EXNTJ",
  },
  { code: "25301", shortdesc: "TENODESIS WRIST EXTENSORS FINGERS" },
  {
    code: "25337",
    shortdesc: "RCNSTJ STABLJ DSTL U/DSTL JT 2 SOFT TISS STABLJ",
  },
  { code: "0374T", shortdesc: "EXPOSURE BEHAVIOR TREATMENT ADDL 30 MIN" },
  { code: "84030", shortdesc: "ASSAY OF PHENYLALANINE BLOOD" },
  { code: "83586", shortdesc: "ASSAY OF KETOSTEROIDS 17- TOTAL" },
  {
    code: "58976",
    shortdesc: "GAMETE ZYGOTE/EMBRYO FALLOPIAN TRANSFER ANY METH",
  },
  { code: "50546", shortdesc: "LAPAROSCOPY NEPHRECTOMY W/PARTIAL URETERECT" },
  { code: "63282", shortdesc: "LAM BX/EXC ISPI NEO IDRL XMED LUMBAR" },
  { code: "11772", shortdesc: "EXCISION PILONIDAL CYST/SINUS COMPLICATED" },
  { code: "12047", shortdesc: "REPAIR INTERMEDIATE N/H/F/XTRNL GENT >30.0 CM" },
  { code: "70460", shortdesc: "CT HEAD/BRAIN W/CONTRAST MATERIAL" },
  { code: "63650", shortdesc: "PRQ IMPLTJ NSTIM ELECTRODE ARRAY EPIDURAL" },
  { code: "81204", shortdesc: "null" },
  { code: "32608", shortdesc: "THORACOSCOPY W/DX BX OF LUNG NODULES UNILATRL" },
  {
    code: "35256",
    shortdesc: "REPAIR BLOOD VESSEL VEIN GRAFT LOWER EXTREMITY",
  },
  {
    code: "22900",
    shortdesc: "EXC TUMOR SOFT TISSUE ABDL WALL SUBFASCIAL <5CM",
  },
  { code: "61558", shortdesc: "XTN CRANIECT MULTIPLE SUTURE CRANIOSYNOSTOSIS" },
  { code: "31770", shortdesc: "BRONCHOPLASTY GRAFT REPAIR" },
  {
    code: "01274",
    shortdesc: "ANES UPPER LEG W/BYPASS GRFT FEM ART EMBOLECTOMY",
  },
  { code: "67042", shortdesc: "VITRECTOMY PARS PLANA REMOVE INT MEMB RETINA" },
  { code: "1205F", shortdesc: "ETIOLOGY OF EPILEPSY SYNDROME RVWD & DOCD" },
  { code: "90647", shortdesc: "HIB PRP-OMP VACCINE 3 DOSE SCHEDULE IM USE" },
  { code: "87486", shortdesc: "IADNA CHLAMYDIA PNEUMONIAE AMPLIFIED PROBE TQ" },
  { code: "0581F", shortdesc: "PT TRANSFERRED FROM ANESTHETIZING TO CC UNIT" },
  { code: "84035", shortdesc: "ASSAY OF PHENYLKETONES QUALITATIVE" },
  { code: "92566", shortdesc: "null" },
  {
    code: "4008F",
    shortdesc: "BETA BLOCKER THERAPY RXD/CURRENTLY BEING TAKEN",
  },
  { code: "28072", shortdesc: "SYNOVECTOMY METATARSOPHALANGEAL JOINT EACH" },
  {
    code: "27086",
    shortdesc: "RMVL FOREIGN BODY PELVIS/HIP SUBCUTANEOUS TISS",
  },
  { code: "74328", shortdesc: "ENDOSCOPIC CATHJ BILIARY DUCTAL SYSTEM RS&I" },
  {
    code: "34712",
    shortdesc: "TRANSCATHETER DLVR ENHNCD FIXATION DEVICES RS&I",
  },
  {
    code: "43753",
    shortdesc: "GASTRIC INTUBATJ & ASPIRAJ W/PHYS SKILL/LAVAGE",
  },
  { code: "21501", shortdesc: "I&D DEEP ABSC/HMTMA SOFT TISSUE NECK/THORAX" },
  { code: "56505", shortdesc: "null" },
  { code: "75565", shortdesc: "CARDIAC MRI FOR VELOCITY FLOW MAPPING" },
  {
    code: "37760",
    shortdesc: "LIG PRFRATR VEIN SUBFSCAL RAD INCL SKN GRF 1 LEG",
  },
  { code: "68525", shortdesc: "BIOPSY LACRIMAL SAC" },
  { code: "94013", shortdesc: "MEASUREMENT LUNG VOLUMES INFANT/CHILD/2 YRS" },
  {
    code: "88363",
    shortdesc: "EXAM & SELECT ARCHIVE TISSUE MOLECULAR ANALYSI",
  },
  { code: "92569", shortdesc: "null" },
  { code: "0394T", shortdesc: "HDR ELECTRONIC BRACHYTHERAPY SKIN SURFACE" },
  { code: "87391", shortdesc: "IAAD IA HIV-2" },
  { code: "85635", shortdesc: "REPTILASE TEST" },
  { code: "43193", shortdesc: "ESOPHAGOSCOPY RIGID TRANSORAL WITH BIOPSY" },
  { code: "85598", shortdesc: "PHOSPHOLIPID NEUTRALIZATION HEXAGONAL" },
  { code: "84490", shortdesc: "TRYPSIN FECES QUANTITATIVE 24-HR COLLECTION" },
  { code: "0099U", shortdesc: "null" },
  { code: "87482", shortdesc: "IADNA CANDIDA SPECIES QUANTIFICATION" },
  {
    code: "62291",
    shortdesc: "INJECTION PX DISCOGRPHY EA LVL CERVICAL/THORACIC",
  },
  {
    code: "97116",
    shortdesc: "THER PX 1/> AREAS EA 15 MIN GAIT TRAINJ W/STAIR",
  },
  { code: "19328", shortdesc: "REMOVAL INTACT MAMMARY IMPLANT" },
  {
    code: "32507",
    shortdesc: "THORACOTOMY W/DX WEDGE RESEXN & ANTOM LUNG RESE",
  },
  { code: "15788", shortdesc: "CHEMICAL PEEL FACIAL EPIDERMAL" },
  { code: "87528", shortdesc: "IADNA HERPES SIMPLX VIRUS DIRECT PROBE TQ" },
  { code: "56606", shortdesc: "BIOPSY VULVA/PERINEUM EACH ADDL LESION" },
  { code: "28113", shortdesc: "OSTECTOMY COMPLETE 5TH METATARSAL HEAD" },
  { code: "23020", shortdesc: "CAPSULAR CONTRACTURE RELEASE" },
  { code: "33468", shortdesc: "TRICUSPID VALVE RPSG&PLCTJ EBSTEIN ANOMALY" },
  {
    code: "33217",
    shortdesc: "INSJ 2 TRANSVNS ELTRD PERM PACEMAKER/IMPLTBL DFB",
  },
  { code: "28530", shortdesc: "CLOSED TREATMENT SESAMOID FRACTURE" },
  { code: "76948", shortdesc: "US GUIDANCE ASPIRATION OVA IMG S&I" },
  { code: "21159", shortdesc: "RCNSTJ MIDFACE LEFORT III W/FHD W/O LEFORT I" },
  { code: "78601", shortdesc: "BRAIN IMAGING <4 STATIC VIEWS W/VASCULAR FLOW" },
  { code: "1004F", shortdesc: "CLINICAL SYMPTOMS VOL OVERLOAD ASSESSED" },
  { code: "81343", shortdesc: "null" },
  {
    code: "64561",
    shortdesc: "PRQ IMPLTJ NEUROSTIM ELTRD SACRAL NRVE W/IMAGING",
  },
  { code: "62290", shortdesc: "INJECTION PX DISCOGRAPHY EACH LEVEL LUMBAR" },
  {
    code: "58100",
    shortdesc: "ENDOMETRIAL BX W/WO ENDOCERVIX BX W/O DILAT SPX",
  },
  { code: "37618", shortdesc: "LIGATION MAJOR ARTERY EXTREMITY" },
  { code: "48511", shortdesc: "XTRNL DRG PSEUDOCYST PANCREAS PRQ" },
  { code: "32660", shortdesc: "THORACOSCOPY W/TOTAL PERICARDIECTOMY" },
  {
    code: "62121",
    shortdesc: "CRANIOTOMY FOR ENCEPHALOCELE REPAIR SKULL BASE",
  },
  { code: "51980", shortdesc: "CUTANEOUS VESICOSTOMY" },
  {
    code: "01500",
    shortdesc: "ANESTHESIA ARTERIES LOWER LEG W/BYPASS GRAFT NOS",
  },
  { code: "67040", shortdesc: "VTRECTOMY MCHNL PARS PLNA ENDOLASER PANRTA PC" },
  { code: "0173T", shortdesc: "MONITOR INTRAOCULAR PRESS DURING VITRECTOMY" },
  {
    code: "33968",
    shortdesc: "REMOVAL INTRA-AORTIC BALLOON ASSIST DEVICE PRQ",
  },
  {
    code: "21182",
    shortdesc: "RCNSTJ ORBIT/FHD/NASETHMD EXCBONE TUM GRF<40SQCM",
  },
  { code: "85013", shortdesc: "BLOOD COUNT SPUN MICROHEMATOCRIT" },
  { code: "33665", shortdesc: "RPR INTRM/TRANSJ AV CANAL W/WO AV VALVE RPR" },
  { code: "27097", shortdesc: "RELEASE/RECESSION HAMSTRING PROXIMAL" },
  { code: "1040F", shortdesc: "DSM-5 CRITERIA MDD DOCD AT THE INITIAL EVAL" },
  { code: "93740", shortdesc: "TEMPRATURE GRADIENT STUDY" },
  {
    code: "58410",
    shortdesc: "UTERINE SUSP W/WO SHORT LIGAMNTS W/SYMPATHECTOMY",
  },
  { code: "01300", shortdesc: "null" },
  { code: "84481", shortdesc: "ASSAY OF TRIIODOTHYRONINE T3 FREE" },
  {
    code: "86825",
    shortdesc: "HLA CROSSMATCH NONCYTOTOXIC 1ST SERUM/DILUTION",
  },
  {
    code: "63042",
    shortdesc: "LAMOT PRTL FFD EXC DISC REEXPL 1 NTRSPC LUMBAR",
  },
  { code: "43631", shortdesc: "GSTRCT PRTL DSTL W/GASTRODUODENOSTOMY" },
  { code: "0555T", shortdesc: "null" },
  { code: "27345", shortdesc: "EXCISION SYNOVIAL CYST POPLITEAL SPACE" },
  { code: "31656", shortdesc: "BRNCHSC W/NJX CONTRAST SGMTL BRONCHOG" },
  {
    code: "97602",
    shortdesc: "RMVL DEVITAL TISS N-SLCTV DBRDMT W/O ANES 1 SESS",
  },
  { code: "65420", shortdesc: "EXCISION/TRANSPOSITION PTERYGIUM W/O GRAFT" },
  { code: "99321", shortdesc: "null" },
  { code: "35490", shortdesc: "null" },
  { code: "54409", shortdesc: "null" },
  { code: "85004", shortdesc: "BLOOD COUNT AUTOMATED DIFFERENTIAL WBC COUNT" },
  { code: "97501", shortdesc: "null" },
  { code: "76946", shortdesc: "US GUIDANCE AMNIOCENTESIS IMG S&I" },
  {
    code: "58952",
    shortdesc: "RESCJ PRIM PRTL MAL W/BSO & OMNTC RAD DEBULKING",
  },
  {
    code: "85220",
    shortdesc: "CLOTTING FACTOR V ACG/PROACCELERIN LABILE FACTOR",
  },
  { code: "69436", shortdesc: "TYMPANOSTOMY GENERAL ANESTHESIA" },
  { code: "28024", shortdesc: "ARTHRT W/EXPL DRG/RMVL LOOSE/FB IPHAL JT" },
  { code: "80367", shortdesc: "DRUG SCREENING PROPOXYPHENE" },
  {
    code: "34806",
    shortdesc: "TCAT PLACEMENT PHYSIOLOGIC SENSOR ANEURYSMAL SAC",
  },
  {
    code: "45160",
    shortdesc: "EXC RCT TUM PROCTOTOMY TRANSSAC/TRANSCOCCYGEAL",
  },
  { code: "50684", shortdesc: "INJ PX URETEROGRAPHY/URETEROPYLOGRAPHY CATH" },
  { code: "86787", shortdesc: "ANTIBODY VARICELLA-ZOSTER" },
  { code: "82492", shortdesc: "CHROMATOGRAPHY QUAN COLUMN MULTIPLE ANALYTES" },
  { code: "45805", shortdesc: "CLSR RECTOVESICAL FISTULA W/COLOSTOMY" },
  { code: "0062T", shortdesc: "null" },
  { code: "99254", shortdesc: "INITIAL INPATIENT CONSULT NEW/ESTAB PT 80 MIN" },
  { code: "64722", shortdesc: "DECOMPRESSION UNSPECIFIED NERVE" },
  {
    code: "65781",
    shortdesc: "OCULAR SURFACE RECONSTRUCTION LIMBAL ALLOGRAFT",
  },
  {
    code: "26356",
    shortdesc: "RPR/ADVMNT FLXR TDN ZONE 2 W/O FR GRFT EA TENDON",
  },
  {
    code: "22904",
    shortdesc: "RAD RESECTION TUMOR SOFT TISSUE ABDL WALL <5CM",
  },
  { code: "37140", shortdesc: "VENOUS ANASTOMOSIS OPEN PORTOCAVAL" },
  { code: "96002", shortdesc: "DYN SURF EMG WALKG/FUNCJAL ACTV 1-12 MUSC" },
  { code: "80049", shortdesc: "null" },
  {
    code: "86806",
    shortdesc: "LMPHOCYTOTOXICITY ASSAY VIS CROSSMTCH W/O TITRAT",
  },
  { code: "26037", shortdesc: "DECOMPRESSIVE FASCIOTOMY HAND" },
  {
    code: "17264",
    shortdesc: "DESTRUCTION MAL LESION TRUNK/ARM/LEG 3.1-4.0CM",
  },
  { code: "0168T", shortdesc: "RHINOPHOTOTHERAPY APPLICATION LIGHT BILATERAL" },
  { code: "11055", shortdesc: "PARING/CUTTING BENIGN HYPERKERATOTIC LESION 1" },
  { code: "97545", shortdesc: "WORK HARDENING/CONDITIONING 1ST 2 HR" },
  {
    code: "59870",
    shortdesc: "UTERINE EVACUATION & CURETTAGE HYDATIDIFORM MOLE",
  },
  {
    code: "33310",
    shortdesc: "CARDIOT EXPL W/RMVL FB ATR/VENTR THRMB W/O BYP",
  },
  { code: "31805", shortdesc: "SUTURE TRACHEAL WOUND/INJURY INTRATHORACIC" },
  {
    code: "29885",
    shortdesc: "ARTHRS KNEE DRILL OSTEOCHONDRITIS DISSECANS GRFG",
  },
  { code: "33242", shortdesc: "null" },
  {
    code: "80438",
    shortdesc: "THYROTROPIN RELEASING HORMONE STMLJ PANEL 1 HR",
  },
  {
    code: "76817",
    shortdesc: "US PREG UTERUS REAL TIME W/IMAGE DCMTN TRANSVAG",
  },
  { code: "19396", shortdesc: "PREPARATION MOULAGE CUSTOM BREAST IMPLANT" },
  {
    code: "20962",
    shortdesc: "BONE GRF W/MVASC ANAST OTH/THN ILIAC CREST/METAR",
  },
  { code: "92273", shortdesc: "null" },
  { code: "33768", shortdesc: "ANASTOMOSIS CAVOPULMARY 2ND SUPRIOR VENA CAVA" },
  { code: "0292T", shortdesc: "INTRAV OPTCAL COHRNCE TMGRPHY W/S&I ADL VESL" },
  { code: "23150", shortdesc: "EXC/CURTG BONE CYST/BENIGN TUMOR PROX HUMERUS" },
  { code: "91055", shortdesc: "null" },
  { code: "81106", shortdesc: "HPA-2 GENOTYPING GENE ANALYSIS COMMON VARIANT" },
  { code: "4058F", shortdesc: "PAG PROVIDED TO CAREGIVER" },
  { code: "57310", shortdesc: "CLOSURE URETHROVAGINAL FISTULA" },
  { code: "0348T", shortdesc: "RADIOSTEREOMETRIC ANALYSIS SPINE EXAM" },
  { code: "57108", shortdesc: "null" },
  { code: "33254", shortdesc: "ABLATION & RECONSTRUCTION ATRIA LIMITED" },
  {
    code: "64742",
    shortdesc: "TRANSECTION/AVULSION FACIAL NRV DIFFERENT/CMPL",
  },
  {
    code: "86890",
    shortdesc: "AUTOL BLD/COMPONENT COLLJ STORAGE PREDEPOSITED",
  },
  { code: "80436", shortdesc: "METYRAPONE PANEL" },
  { code: "92326", shortdesc: "REPLACEMENT CONTACT LENS" },
  {
    code: "61581",
    shortdesc: "CRANIOFACIAL ANT CRANIAL FOSSA W/ORBITAL EXNTJ",
  },
  {
    code: "93298",
    shortdesc: "INTERROGATION EVALUATION REMOTE </30 D ILR SYS",
  },
  { code: "15876", shortdesc: "SUCTION ASSISTED LIPECTOMY HEAD & NECK" },
  {
    code: "3395F",
    shortdesc: "QUANT NON-HER2 IHC EVAL OF BRST CANCER PERFORMED",
  },
  { code: "27681", shortdesc: "TNOLS FLXR/XTNSR TDN LEG&/ANKLE MLT TDN" },
  {
    code: "0283T",
    shortdesc: "PERC/OPEN IMPLNT NEUROSTIM ELECTRODE SUBQ PERM",
  },
  { code: "59820", shortdesc: "TX MISSED ABORTION FIRST TRIMESTER SURGICAL" },
  { code: "81230", shortdesc: "CYP3A4 GENE ANALYSIS COMMON VARIANTS" },
  { code: "21088", shortdesc: "IMPRESSION & PREPARATION FACIAL PROSTHESIS" },
  {
    code: "28546",
    shortdesc: "PRQ SKEL FIXJ TARSL DISLC XCP TALOTARSAL W/MANJ",
  },
  { code: "45120", shortdesc: "PRCTECT COMPL W/PULL-THRU PX & ANASTOMOSIS" },
  { code: "60000", shortdesc: "I&D THYROGLOSSAL DUCT CYST INFECTED" },
  { code: "45121", shortdesc: "PRCTECT COMPL W/STOT/TOT COLCT W/MLT BXS" },
  { code: "38525", shortdesc: "BX/EXC LYMPH NODE OPEN DEEP AXILLARY NODE" },
  {
    code: "33243",
    shortdesc: "RMVL 1/DUAL CHAMBER DEFIB ELECTRODE BY THORACOM",
  },
  {
    code: "94762",
    shortdesc: "NONINVASIVE EAR/PULSE OXIMETRY OVERNIGHT MONITOR",
  },
  { code: "78582", shortdesc: "PULMONARY VENTILATION & PERFUSION IMAGING" },
  {
    code: "15277",
    shortdesc: "SUB GRFT F/S/N/H/F/G/M/D >/= 100SCM 1ST 100SQ CM",
  },
  {
    code: "97530",
    shortdesc: "THERAPEUT ACTVITY DIRECT PT CONTACT EACH 15 MIN",
  },
  { code: "76800", shortdesc: "ULTRASOUND SPINAL CANAL & CONTENTS" },
  {
    code: "33953",
    shortdesc: "ECMO/ECLS INSJ OF PRPH CANNULA BIRTH-5 YRS OPEN",
  },
  {
    code: "46748",
    shortdesc: "RPR CLOACAL ANOMALY CMBN ABDL & SACROPRNL W/GRF",
  },
  { code: "86612", shortdesc: "ANTIBODY BLASTOMYCES" },
  { code: "44369", shortdesc: "ENTEROSCOPY > 2ND PRTN ABLTJ LESION" },
  { code: "0470T", shortdesc: "OCT SKN IMG ACQUISJ I&R 1ST LES" },
  { code: "21127", shortdesc: "AGMNTJ MNDBLR BDY/ANGL W/GRF ONLAY/INTERPOSAL" },
  { code: "36835", shortdesc: "INSERTION THOMAS SHUNT SEPARATE PROCEDURE" },
  {
    code: "53440",
    shortdesc: "SLING OPRATION CORRJ MALE URINARY INCONTINENCE",
  },
  {
    code: "19342",
    shortdesc: "DLYD INSJ BRST PROSTH FLWG MASTOPEXY MAST/RCNSTJ",
  },
  { code: "82607", shortdesc: "CYANOCOBALAMIN VITAMIN B-12" },
  { code: "81460", shortdesc: "WHOLE MITOCHONDRIAL GENOME" },
  {
    code: "75887",
    shortdesc: "PRQ TRANSHEPATC PORTOGRAPY W/O HEMODYN EVL INTRP",
  },
  { code: "28312", shortdesc: "OSTEOT SHRT CORRJ OTH PHALANGES ANY TOE" },
  { code: "36870", shortdesc: "THRMBC PRQ ARVEN FSTL AUTOG/NONAUTOG GRF" },
  { code: "75901", shortdesc: "MECHANICAL RMVL PERICATHETER OBSTR MATRL RS&I" },
  { code: "01730", shortdesc: "ANESTHESIA CLOSED PROCEDURES HUMERUS & ELBOW" },
  { code: "65767", shortdesc: "EPIKERATOPLASTY" },
  { code: "86003", shortdesc: "ALLERGEN SPEC IGE CRUDE ALLERGEN EXTRACT EACH" },
  { code: "88245", shortdesc: "CHRMSM BREAKAGE BASELINE SISTER 20-25 CLL" },
  { code: "25927", shortdesc: "TRANSMETACARPAL AMPUTATION" },
  {
    code: "61536",
    shortdesc: "CRANIOT EPILEPTOGENIC FOCUS W/ELECTROCORTCOGRPHY",
  },
  { code: "00670", shortdesc: "ANESTHESIA EXTENSIVE SPINE & SPINAL CORD" },
  { code: "29305", shortdesc: "APPLICATION HIP SPICA CAST 1 LEG" },
  { code: "35632", shortdesc: "BYPASS GRAFT W/OTHER THAN VEIN ILIO-CELIAC" },
  {
    code: "81267",
    shortdesc: "CHIMERISM W/COMP TO BASELINE W/O CELL SELECTION",
  },
  { code: "0520T", shortdesc: "null" },
  {
    code: "15005",
    shortdesc: "PREP SITE F/S/N/H/F/G/M/D GT ADDL 100 SQ CM/1PCT",
  },
  { code: "27350", shortdesc: "PATELLECTOMY/HEMIPATELLECTOMY" },
  {
    code: "61607",
    shortdesc: "RESCJ/EXC LES PARASELLAR SINUS CLIVUS/MSB XDRL",
  },
  { code: "35456", shortdesc: "null" },
  { code: "82525", shortdesc: "ASSAY OF COPPER" },
  { code: "73552", shortdesc: "RADIOLOGIC EXAMINATION FEMUR MINIMUM 2 VIEWS" },
  { code: "87551", shortdesc: "IADNA MYCOBACTERIA SPECIES AMPLIFIED PROBE TQ" },
  { code: "0387T", shortdesc: "TRANSCATH INSERT OR REPLACE LEADLESS PM VENTR" },
  {
    code: "63051",
    shortdesc: "LAMOPLASTY CERVICAL DCMPRN CORD 2/> SEG RCNSTJ",
  },
  { code: "80345", shortdesc: "DRUG SCREENING BARBITURATES" },
  { code: "73040", shortdesc: "RADEX SHOULDER ARTHROGRAPHY RS&I" },
  { code: "39499", shortdesc: "UNLISTED PROCEDURE MEDIASTINUM" },
  { code: "35221", shortdesc: "RPR BLOOD VESSEL DIRECT INTRA-ABDOMINAL" },
  { code: "00145", shortdesc: "ANESTHESIA EYE VITREORETINAL SURGERY" },
  { code: "90765", shortdesc: "null" },
  { code: "76941", shortdesc: "US INTRAUTERINE FTL TFUJ/CORDOCNTS IMG S&I" },
  { code: "0134U", shortdesc: "null" },
  { code: "49436", shortdesc: "DELAYED CREATION EXIT SITE EMBEDDED CATHETER" },
  { code: "27786", shortdesc: "CLTX DSTL FIBULAR FX LAT MALLS W/O MANJ" },
  { code: "27535", shortdesc: "OPEN TX TIBIAL FRACTURE PROXIMAL UNICONDYLAR" },
  {
    code: "33390",
    shortdesc: "VALVULOPLASTY AORTIC VALVE OPEN CARD BYP SIMPLE",
  },
  { code: "28285", shortdesc: "CORRECTION HAMMERTOE" },
  { code: "01272", shortdesc: "ANES ART UPPER LEG W/BYPASS GRAFT FEM ART LIG" },
  {
    code: "62281",
    shortdesc: "INJX/INFUS NEUROLYT SUBST EPIDURAL CERV/THORACIC",
  },
  { code: "61500", shortdesc: "CRANIECTOMY W/EXCISION TUMOR/LESION SKULL" },
  { code: "63016", shortdesc: "LAMINECTOMY W/O FFD > 2 VERT SEG THORACIC" },
  { code: "46255", shortdesc: "HEMORRHOIDECTOMY NTRNL & XTRNL 1 COLUMN/GROUP" },
  { code: "33990", shortdesc: "INSJ PERQ VAD W/IMAGING ARTERY ACCESS ONLY" },
  { code: "32650", shortdesc: "THORACOSCOPY W/PLEURODESIS" },
  {
    code: "54406",
    shortdesc: "RMVL INFLATABLE PENILE PROSTH W/O RPLCMT PROSTH",
  },
  { code: "55300", shortdesc: "VASOTOMY VASOGRAMS UNI/BI" },
  { code: "51550", shortdesc: "CYSTECTOMY PARTIAL SIMPLE" },
  { code: "93581", shortdesc: "PRQ TCAT CLSR CGEN VENTR SEPTAL DFCT W/IMPLT" },
  { code: "71551", shortdesc: "MRI CHEST W/CONTRAST MATERIAL" },
  { code: "42104", shortdesc: "EXC LESION PALATE UVULA W/O CLOSURE" },
  {
    code: "57415",
    shortdesc: "REMOVAL IMPACTED VAG FB SPX W/ANES OTH/THN LOCAL",
  },
  {
    code: "3274F",
    shortdesc: "PROST CANCER RSK RECUR NOT DETER/LOW/INTERMED/HI",
  },
  { code: "93208", shortdesc: "null" },
  {
    code: "31552",
    shortdesc: "LARYNGOPLASTY LARYNGEAL STEN W/O STENT 12 YRS >",
  },
  { code: "20931", shortdesc: "ALLOGRAFT FOR SPINE SURGERY ONLY STRUCTURAL" },
  {
    code: "87166",
    shortdesc: "DARK FIELD EXAM ANY SOURCE W/O SPECIMEN COLLECT",
  },
  { code: "77469", shortdesc: "INTRAOPERATIVE RADIATION TREATMENT MANAGEMENT" },
  { code: "57105", shortdesc: "BIOPSY VAGINAL MUCOSA EXTENSIVE" },
  { code: "93731", shortdesc: "null" },
  { code: "31085", shortdesc: "SINUSOT FRNT OBLIT W/OSTPL FLAP CORONAL INC" },
  {
    code: "22633",
    shortdesc: "ARTHDSIS POST/POSTEROLATRL/POSTINTERBODY LUMBAR",
  },
  { code: "27036", shortdesc: "CAPSLCTOMY/CAPSUL HIP W/RLS HIP FLXR MUSC" },
  { code: "0149T", shortdesc: "null" },
  { code: "97020", shortdesc: "null" },
  { code: "46080", shortdesc: "SPHINCTEROTOMY ANAL DIVISION SPHINCTER SPX" },
  {
    code: "17263",
    shortdesc: "DESTRUCTION MAL LESION TRUNK/ARM/LEG 2.1-3.0CM",
  },
  { code: "25805", shortdesc: "ARTHRODESIS WRIST W/SLIDING GRAFT" },
  { code: "82747", shortdesc: "ASSAY OF FOLIC ACID RBC" },
  { code: "64719", shortdesc: "NEUROPLASTY &/TRANSPOSITION ULNAR NERVE WRIST" },
  {
    code: "21082",
    shortdesc: "IMPRESSION & PREPJ PALATAL AUGMENTATION PROSTHES",
  },
  { code: "88348", shortdesc: "ELECTRON MICROSCOPY DIAGNOSTIC" },
  {
    code: "26205",
    shortdesc: "EXC/CURETTAGE CYST/TUMOR METACARPAL W/AUTOGRAFT",
  },
  { code: "96450", shortdesc: "CHEMOTX ADMN CNS REQ SPINAL PUNCTURE" },
  { code: "86671", shortdesc: "ANTIBODY FUNGUS NOT ELSEWHERE SPECIFIED" },
  { code: "0500T", shortdesc: "IADNA HPV 5+ SEP REPRT HIGH RISK HPV TYPES" },
  { code: "99351", shortdesc: "null" },
  {
    code: "94772",
    shortdesc: "CIRCADIAN RESPIRATRY PATTERN REC 12-24 HR INFANT",
  },
  {
    code: "22514",
    shortdesc: "PERQ VERT AGMNTJ CAVITY CRTJ UNI/BI CANNULJ LMBR",
  },
  {
    code: "93798",
    shortdesc: "OUTPATIENT CARDIAC REHAB W/CONT ECG MONITORING",
  },
  { code: "51845", shortdesc: "ABDOMINO-VAG VESICAL NCK SSP W/WO NDSC CTRL" },
  { code: "90806", shortdesc: "INDIV PSYCTX OFFICE/OUTPATIENT 45-50 MIN" },
  {
    code: "29125",
    shortdesc: "APPLICATION SHORT ARM SPLINT FOREARM-HAND STATIC",
  },
  {
    code: "43259",
    shortdesc: "EDG US EXAM SURGICAL ALTER STOM DUODENUM/JEJUNUM",
  },
  { code: "93545", shortdesc: "null" },
  {
    code: "26665",
    shortdesc: "OPEN TX CARPOMETACARPAL FRACTURE DISLOCATE THUMB",
  },
  { code: "11603", shortdesc: "EXCISION MAL LESION TRUNK/ARM/LEG 2.1-3.0 CM" },
  {
    code: "25500",
    shortdesc: "CLOSED TX RADIAL SHAFT FRACTURE W/O MANIPULATION",
  },
  {
    code: "01112",
    shortdesc: "ANES BONE MARROW ASPIR&/BX ANT/PST ILIAC CREST",
  },
  {
    code: "90718",
    shortdesc: "TETANUS & DIPHTHERIA TOXOIDS ADSORBED 7/>YR IM",
  },
  { code: "83008", shortdesc: "GUANOSINE MONOPHOSPHATE CYCLIC" },
  { code: "33400", shortdesc: "VALVULOPLASTY AORTIC VALVE OPEN W/BYPASS" },
  {
    code: "84449",
    shortdesc: "ASSAY OF TRANSCORTIN CORTISOL BINDING GLOBULIN",
  },
  {
    code: "0263T",
    shortdesc: "AUTO BONE MARRW CELL RX COMPLT BONE MARRW HARVST",
  },
  { code: "58820", shortdesc: "DRAINAGE OVARIAN ABSCESS VAGINAL APPR OPEN" },
  { code: "92567", shortdesc: "TYMPANOMETRY" },
  { code: "61150", shortdesc: "BURR HOLE/TREPHINE W/DRG BRAIN ABSCESS/CYST" },
  { code: "55170", shortdesc: "null" },
  {
    code: "87177",
    shortdesc: "OVA&PARASITES DIRECT SMEARS CONCENTRATION & ID",
  },
  { code: "63710", shortdesc: "DURAL GRAFT SPINAL" },
  { code: "52620", shortdesc: "null" },
  { code: "3380F", shortdesc: "AJCC BREAST CANCER STAGE IV" },
  { code: "73660", shortdesc: "RADEX TOE MINIMUM 2 VIEWS" },
  { code: "71046", shortdesc: "RADIOLOGIC EXAM CHEST 2 VIEWS" },
  { code: "0008T", shortdesc: "null" },
  { code: "82436", shortdesc: "CHLORIDE URINE" },
  { code: "80332", shortdesc: "ANTIDEPRESSANTS SEROTONERGIC CLASS 1 OR 2" },
  { code: "77301", shortdesc: "NTSTY MODUL RADTHX PLN DOSE-VOL HISTOS" },
  {
    code: "57111",
    shortdesc: "VAGINECTOMY COMPL RMVL VAG WALL & PARAVAG TISS",
  },
  { code: "3155F", shortdesc: "CYTOGEN TEST DONE MARROW DIAG OR PRIOR TXMNT" },
  { code: "44603", shortdesc: "ENTERORRHAPHY MULTIPLE PERFORATIONS" },
  {
    code: "58554",
    shortdesc: "LAPS VAGINAL HYSTERECT > 250 GM RMVL TUBE&/OVAR",
  },
  {
    code: "33774",
    shortdesc: "RPR TRPOS GREAT VSLS ATRIAL BAFFLE PX W/BYPASS",
  },
  { code: "1022F", shortdesc: "PNEUMOCOCCUS IMMUNIZATION STATUS ASSESSED" },
  { code: "53665", shortdesc: "DILAT FEMALE URETHRA GENERAL/CNDJ SPINAL ANES" },
  { code: "82977", shortdesc: "ASSAY OF GLUTAMYLTRASE GAMMA" },
  { code: "85048", shortdesc: "BLOOD COUNT LEUKOCYTE WBC AUTOMATED" },
  { code: "82330", shortdesc: "CALCIUM IONIZED" },
  {
    code: "75573",
    shortdesc: "CT HRT CONTRST CARDIAC STRUCT&MORPH CONG HRT D",
  },
  {
    code: "42509",
    shortdesc: "PAROTID DUCT DVRJ BI W/EXC BOTH SUBMNDBLR GLANDS",
  },
  { code: "27646", shortdesc: "RADICAL RESECTION TUMOR BONE FIBULA" },
  { code: "64445", shortdesc: "INJECTION ANESTHETIC AGENT SCIATIC NRV SINGLE" },
  { code: "44126", shortdesc: "ENTRC RESCJ ATRESIA RESCJ & ANAST W/O TAPRING" },
  { code: "3110F", shortdesc: "CT/MRI HMRHG/MASS LESION/ACUTE INFRC DOC" },
  {
    code: "38308",
    shortdesc: "LYMPHANGIOTOMY/OTH OPRATIONS LYMPHATIC CHANNELS",
  },
  { code: "78018", shortdesc: "THYROID CARCINOMA METASTASES IMG WHOLE BODY" },
  { code: "35646", shortdesc: "BYP OTH/THN VEIN AORTOBIFEMORAL" },
  { code: "0030T", shortdesc: "ANTIPROTHROMBIN ANTIBODY EACH IG CLASS" },
  {
    code: "41015",
    shortdesc: "XTRORAL I&D ABSC CST/HMTMA FLOOR MOUTH SUBLNGL",
  },
  { code: "17306", shortdesc: "null" },
  { code: "0516F", shortdesc: "ANEMIA PLAN OF CARE DOCUMENTED" },
  { code: "59350", shortdesc: "HYSTERORRHAPHY RUPTURED UTERUS" },
  {
    code: "82139",
    shortdesc: "AMINO ACIDS 6/> AMINO ACIDS QUANTITATIVE EA SPE",
  },
  { code: "47370", shortdesc: "LAPS SURG ABLTJ 1/> LVR TUM RF" },
  { code: "0054U", shortdesc: "null" },
  {
    code: "27217",
    shortdesc: "OPTX ANT PELVIC BONE FX&/DISLC INT FIXJ IF PFR",
  },
  {
    code: "90826",
    shortdesc: "INDIV PSYCTX IA INPT/PRTL HOSP/RESDNTL 45-50 MIN",
  },
  { code: "99220", shortdesc: "INITIAL OBSERVATION CARE/DAY 70 MINUTES" },
  { code: "23460", shortdesc: "CAPSULORRHAPHY ANTERIOR WITH BONE BLOCK" },
  { code: "64712", shortdesc: "NEURP MAJOR PRPH NRV OPN ARM/LEG SCIATIC NRV" },
  { code: "33508", shortdesc: "NDSC SURG W/VIDEO-ASSISTED HARVEST VEIN CABG" },
  { code: "83080", shortdesc: "ASSAY OF B-HEXOSAMINIDASE EACH ASSAY" },
  {
    code: "29898",
    shortdesc: "ARTHROSCOPY ANKLE SURGICAL DEBRIDEMENT EXTENSIVE",
  },
  { code: "2015F", shortdesc: "ASTHMA IMPAIRMENT ASSESSED" },
  {
    code: "86328",
    shortdesc:
      "Immunoassay for infectious agent antibody(ies), qualitative or semiquantitative, single step method (eg, reagent strip); severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2) (Coronavirus disease [COVID-19])",
  },
  { code: "89343", shortdesc: "STORAGE PER YEAR SPERM/SEMEN" },
  { code: "47379", shortdesc: "UNLIS LAPAROSCOPIC PROCEDURE LIVER" },
  { code: "1200F", shortdesc: "SEIZURE TYPE FREQUENCY DOCUMENTED" },
  { code: "11975", shortdesc: "INSERTION IMPLANTABLE CONTRACEPTIVE CAPSULES" },
  { code: "93041", shortdesc: "RHYTHM ECG 1-3 LEADS TRACING ONLY W/O I&R" },
  { code: "70373", shortdesc: "LARYNGOGRAPY CONTRAST RS&I" },
  { code: "27335", shortdesc: "ARTHRT W/SYNVCT KNE ANT&POST W/POP AREA" },
  { code: "15756", shortdesc: "FREE MUSCLE/MYOCUTANEOUS FLAP W/MVASC ANAST" },
  { code: "96933", shortdesc: "RCM CELULR & SUBCELULR SKN IMGNG I&R 1ST LES" },
  { code: "77022", shortdesc: "MR GUIDANCE &MONITORING TISSUE ABLATION" },
  { code: "0012T", shortdesc: "null" },
  { code: "80320", shortdesc: "DRUG SCREEN QUANTITATIVE ALCOHOLS" },
  { code: "90753", shortdesc: "null" },
  { code: "4322F", shortdesc: "CRGVR PROVIDED W/ED REFERRED ADDL RESOURCES" },
  {
    code: "99188",
    shortdesc: "APPLICATION TOPICAL FLUORIDE VARNISH BY PHS/QHP",
  },
  { code: "45300", shortdesc: "PROCTOSGMDSC RGD DX W/WO COLLJ SPEC BR/WA SPX" },
  { code: "80361", shortdesc: "DRUG SCREENING OPIATES 1 OR MORE" },
  { code: "32225", shortdesc: "DECORTICATION PULMONARY PARTIAL SEPARATE PROC" },
  { code: "76940", shortdesc: "US &MNTR PARENCHYMAL TISSUE ABLATION" },
  {
    code: "15937",
    shortdesc: "EXC SAC PR ULC PREPJ MUSC/MYOQ FLAP/SKN GRF OSTC",
  },
  {
    code: "3230F",
    shortdesc: "HEARING TEST 6 MOS PRIOR TO EAR TUBE INSERTION",
  },
  {
    code: "21195",
    shortdesc: "RCNSTJ MNDBLR RAMI&/BODY SGTL SPLT W/O INT RGD",
  },
  { code: "99297", shortdesc: "null" },
  { code: "23332", shortdesc: "REMOVAL FOREIGN BODY SHOULDER COMPLICATED" },
  { code: "99478", shortdesc: "SUBSEQUENT INTENSIVE CARE INFANT < 1500 GRAMS" },
  { code: "21920", shortdesc: "BIOPSY SOFT TISSUE BACK/FLANK SUPERFICIAL" },
  { code: "69155", shortdesc: "RAD EXC XTRNL AUDITORY CANAL LES NCK DSJ" },
  { code: "27827", shortdesc: "OPEN TREATMENT FRACTURE DISTAL TIBIA ONLY" },
  { code: "82088", shortdesc: "ASSAY OF ALDOSTERONE" },
  { code: "33542", shortdesc: "MYOCARDIAL RESECTION" },
  { code: "29750", shortdesc: "WEDGING CLUBFOOT CAST" },
  { code: "86849", shortdesc: "UNLISTED IMMUNOLOGY" },
  { code: "58323", shortdesc: "SPERM WASHING ARTIFICIAL INSEMINATION" },
  { code: "54530", shortdesc: "ORCHIECTOMY RADICAL TUMOR INGUINAL APPROACH" },
  { code: "0336T", shortdesc: "null" },
  {
    code: "64616",
    shortdesc: "CHEMODENERVATION MUSCLE NECK UNILAT FOR DYSTONIA",
  },
  { code: "70333", shortdesc: "null" },
  {
    code: "53620",
    shortdesc: "DILAT URETHRAL STRIX FILIFORM & FOLLWR MALE 1ST",
  },
  {
    code: "84580",
    shortdesc: "UROBILINOGEN URINE QUANTITATIVE TIMED SPECIMEN",
  },
  { code: "99476", shortdesc: "SUBSEQUENT PED CRITICAL CARE 2 THRU 5 YEARS" },
  { code: "65850", shortdesc: "TRABECULOTOMY AB EXTERNO" },
  {
    code: "77762",
    shortdesc: "INTRACAVITARY RADIATION SOURCE APPLIC INTERMED",
  },
  {
    code: "15842",
    shortdesc: "GRF FACIAL NRV PALYSS FR MUSCLE FLAP MICROSURG",
  },
  { code: "0013M", shortdesc: "null" },
  { code: "28800", shortdesc: "AMPUTATION FOOT MIDTARSAL" },
  { code: "47700", shortdesc: "EXPL CONGENITAL ATRESIA BILE DUCTS" },
  { code: "25240", shortdesc: "EXCISION DISTAL ULNA PARTIAL/COMPLETE" },
  {
    code: "77786",
    shortdesc: "REMOTE AFTLD RADIONUCLIDE BRACHYTX 2-12 CHANNEL",
  },
  {
    code: "26433",
    shortdesc: "REPAIR EXTENSOR TENDON DISTAL INSERTION W/O GRF",
  },
  {
    code: "15335",
    shortdesc: "ACLR DRM ALLOGRAFT F/S/N/H/F/G/M/D GT 1ST 100CM",
  },
  { code: "59135", shortdesc: "TX ECTOPIC PREGNANCY NTRSTL REQ TOT HYST" },
  { code: "76949", shortdesc: "null" },
  { code: "81015", shortdesc: "URINALYSIS MICROSCOPIC ONLY" },
  {
    code: "50525",
    shortdesc: "CLSR NEPHROVISCERAL FISTULA W/VISC RPR ABDL APPR",
  },
  {
    code: "50236",
    shortdesc: "NEPHRECTOMY TOT URETEREC&BLDR CUFF SEPAR INCISN",
  },
  { code: "55700", shortdesc: "PROSTATE NEEDLE BIOPSY ANY APPROACH" },
  { code: "33770", shortdesc: "RPR TRPOS GREAT VSLS W/O ENLGMNT V-SEPTL DFCT" },
  { code: "92025", shortdesc: "COMPUTERIZED CORNEAL TOPOGRAPHY UNI/BI" },
  {
    code: "0408T",
    shortdesc: "INSJ/RPLC CAR MODULJ SYS PLS GEN TRANSVNS ELTRD",
  },
  {
    code: "75630",
    shortdesc: "AORTOGRAPHY ABDL BI ILIOFEM LOW EXTREM CATH RS&I",
  },
  {
    code: "45563",
    shortdesc: "EXPL RPR & PRESACRAL DRG RECTAL INJ W/COLOSTOMY",
  },
  {
    code: "92633",
    shortdesc: "AUDITORY REHABILITATION POSTLINGUAL HEARING LOSS",
  },
  { code: "60520", shortdesc: "THYMECTOMY PRTL/TOT TRANSCERVICAL APPR SPX" },
  {
    code: "36013",
    shortdesc: "INTRO CATHETER RIGHT HEART/MAIN PULMONARY ARTERY",
  },
  { code: "73502", shortdesc: "RADEX HIP UNILATERAL WITH PELVIS 2-3 VIEWS" },
  {
    code: "75801",
    shortdesc: "LYMPHANGIOGRAPHY EXTREMITY ONLY UNILATERAL RS&I",
  },
  {
    code: "33975",
    shortdesc: "INSJ VENTRIC ASSIST DEV XTRCORP SINGLE VENTRICLE",
  },
  { code: "0553T", shortdesc: "null" },
  { code: "66720", shortdesc: "CILIARY BODY DESTRUCTION CRYOTHERAPY" },
  { code: "76076", shortdesc: "null" },
  { code: "69667", shortdesc: "REPAIR ROUND WINDOW FISTULA" },
  { code: "98928", shortdesc: "OSTEOPATHIC MANIPULATIVE TX 7-8 BODY REGIONS" },
  { code: "35184", shortdesc: "RPR CONGENITAL AV FISTULA EXTREMITIES" },
  { code: "00855", shortdesc: "null" },
  { code: "77002", shortdesc: "FLUOROSCOPIC GUIDANCE NEEDLE PLACEMENT ADD ON" },
  {
    code: "95928",
    shortdesc: "CTR MOTOR EP STD TRANSCRNL MOTOR STIMJ UPR LIMBS",
  },
  { code: "81178", shortdesc: "null" },
  { code: "49659", shortdesc: "UNLIS LAPS PX HRNAP HERNIORRHAPHY HERNIOTOMY" },
  { code: "44799", shortdesc: "UNLISTED PROCEDURE SMALL INTESTINE" },
  { code: "86064", shortdesc: "null" },
  { code: "00350", shortdesc: "ANESTHESIA MAJOR VESSELS NECK NOS" },
  { code: "0426T", shortdesc: "INSJ/RPLC NSTIM SYSTEM SLEEP APNEA STIMJ LEAD" },
  { code: "1031F", shortdesc: "SMOKING & 2ND HAND SMOKE IN THE HOME ASSESSED" },
  { code: "51726", shortdesc: "BLADDER PRESSURE MEASUREMENT DURING FILLING" },
  {
    code: "99379",
    shortdesc: "SUPERVISION NURS FACILITY PATIENT MO 15-29 MIN",
  },
  { code: "73070", shortdesc: "RADEX ELBOW 2 VIEWS" },
  { code: "69740", shortdesc: "SUTR NRV ITPRL W/WO GRF/DCMPRN LAT GENICULATE" },
  { code: "52315", shortdesc: "CYSTO W/COMPLEX REMOVAL STONE & STENT" },
  {
    code: "81376",
    shortdesc: "HLA CLASS II TYPING LOW RESOLUTION ONE LOCUS EA",
  },
  {
    code: "42205",
    shortdesc: "PALATOPLASTY W/CLSR ALVEOLAR RIDGE SOFT TISSUE",
  },
  { code: "21089", shortdesc: "UNLISTED MAXILLOFACIAL PROSTHETIC PROCEDURE" },
  {
    code: "33231",
    shortdesc: "INSJ IMPLNTBL DEFIB PULSE GEN W/EXIST MULTILEADS",
  },
  { code: "94652", shortdesc: "null" },
  {
    code: "54650",
    shortdesc: "ORCHIOPEXY ABDL APPROACH INTRA-ABDOMINAL TESTIS",
  },
  { code: "24495", shortdesc: "DECOMPRESSION FASCT F/ARM W/BRACH ART EXPL" },
  { code: "19105", shortdesc: "ABLTJ CRYOSURGICAL W/US GID EA FIBROADENOMA" },
  { code: "83891", shortdesc: "MOLEC ISOL/XTRJ HP NUCLEIC ACID EA TYPE" },
  { code: "4265F", shortdesc: "USE OF WET TO DRY DRESSINGS PRESCRIBED RECMD" },
  { code: "92612", shortdesc: "FLEXIBLE ENDOSCOPIC EVAL SWALLOW C/V REC" },
  {
    code: "78351",
    shortdesc: "BONE DENSTY 1/> SITES DUAL PHOTON ABSORPTIOMETR",
  },
  { code: "74019", shortdesc: "RADIOLOGIC EXAM ABDOMEN 2 VIEWS" },
  { code: "67110", shortdesc: "RPR RETINAL DTCHMNT INJECTION AIR/OTHER GAS" },
  {
    code: "33414",
    shortdesc: "RPR VENTR O/F TRC OBSTRCJ PATCH ENLGMENT O/F TRC",
  },
  { code: "33946", shortdesc: "ECMO/ECLS INITIATION VENO-VENOUS" },
  { code: "0020T", shortdesc: "null" },
  { code: "90399", shortdesc: "UNLISTED IMMUNE GLOBULIN" },
  { code: "57010", shortdesc: "COLPOTOMY W/DRAINAGE PELVIC ABSCESS" },
  { code: "92358", shortdesc: "PROSTHESIS SERVICE APHAKIA TEMPORARY" },
  { code: "00912", shortdesc: "ANES TRANSURETHRAL RESECTION OF BLADDER TUMOR" },
  { code: "25931", shortdesc: "TRANSMETACARPAL AMPUTATION RE-AMPUTATION" },
  { code: "99100", shortdesc: "ANESTHESIA EXTREME AGE PATIENT UNDER 1 YR/<" },
  { code: "24346", shortdesc: "RCNSTJ MEDIAL COLTRL LIGM ELBW W/TDN GRF" },
  { code: "33947", shortdesc: "ECMO/ECLS INITIATION VENO-ARTERIAL" },
  { code: "99448", shortdesc: "INTERPROF PHONE/INTERNET ASSESS/MANAGE 21-30" },
  {
    code: "25275",
    shortdesc: "RPR TENDON SHEATH EXTENSOR F/ARM&/WRIST W/GRAFT",
  },
  { code: "23120", shortdesc: "CLAVICULECTOMY PARTIAL" },
  { code: "29880", shortdesc: "ARTHRS KNEE W/MENISCECTOMY MED&LAT W/SHAVING" },
  { code: "52647", shortdesc: "LASER COAGULATION OF PROSTATE FOR URINE FLOW" },
  {
    code: "93892",
    shortdesc: "TRANSCRANIAL DOPPLER INTRACRAN ART EMBOLI DETECT",
  },
  {
    code: "01490",
    shortdesc: "ANES LOWER LEG CAST APPLICATION REMOVAL/REPAIR",
  },
  { code: "30400", shortdesc: "RHINP PRIM LAT&ALAR CRTLGS&/ELVTN NASAL TI" },
  {
    code: "87152",
    shortdesc: "CULTURE TYPING IDENTIFJ PULSE FIELD GEL TYPING",
  },
  {
    code: "33645",
    shortdesc: "DIR/PTCH CLS SINUS VENOSUS W/WO ANOM PUL VEN DRG",
  },
  {
    code: "33430",
    shortdesc: "REPLACEMENT MITRAL VALVE W/CARDIOPULMONARY BYP",
  },
  { code: "01935", shortdesc: "ANESTHESIA PERQ IMAGE GUIDED SPINE DIAGNOSTIC" },
  { code: "17010", shortdesc: "null" },
  { code: "86735", shortdesc: "ANTIBODY MUMPS" },
  {
    code: "54385",
    shortdesc: "PLASTIC PENIS EPISPADIAS DSTL SPHNCTR W/INCONT",
  },
  { code: "77431", shortdesc: "RADIATION THERAPY MGMT 1/2 FRACTIONS ONLY" },
  { code: "30430", shortdesc: "RHINOPLASTY SECONDARY MINOR REVISION" },
  { code: "94665", shortdesc: "null" },
  { code: "6100F", shortdesc: "VERIFY CORRECT PT SITE PXD DOCUMENTED" },
  { code: "81292", shortdesc: "MLH1 GENE ANALYSIS FULL SEQUENCE ANALYSIS" },
  {
    code: "31651",
    shortdesc: "BRNCHSC OCCLUSION&INSERT BRONCH VALVE ADDL LOBE",
  },
  { code: "72158", shortdesc: "MRI SPINAL CANAL LUMBAR W/O & W/CONTR MATRL" },
  { code: "33011", shortdesc: "PERICARDIOCENTESIS SUBSEQUENT" },
  {
    code: "45392",
    shortdesc: "COLSC FLX W/US GUID NDL ASPIR/BX W/US RCTM ET AL",
  },
  {
    code: "21554",
    shortdesc: "EXC TUMOR SOFT TISSUE NECK/THORAX SUBFASC 5 CM/>",
  },
  { code: "21454", shortdesc: "OPEN TX MANDIBULAR FX W/EXTERNAL FIXATION" },
  { code: "47556", shortdesc: "BILIARY NDSC PRQ T-TUBE DILAT STRIX W/STENT" },
  { code: "92002", shortdesc: "OPHTH MEDICAL XM&EVAL INTERMEDIATE NEW PT" },
  { code: "25606", shortdesc: "PERQ SKEL FIXJ DISTAL RADIAL FX/EPIPHYSL SEP" },
  { code: "87199", shortdesc: "null" },
  { code: "96102", shortdesc: "PSYCHOLOGICAL TESTING ADMN BY TECH PR HR" },
  {
    code: "93454",
    shortdesc: "CATH PLACEMENT & NJX CORONARY ART ANGIO IMG S&I",
  },
  {
    code: "67880",
    shortdesc: "CONSTJ INTERMARGIN ADHES/TARSORRH/CANTHORRHAPY",
  },
  { code: "66170", shortdesc: "FSTLJ SCLERA GLAUCOMA TRABECULECT AB EXTERNO" },
  { code: "0165T", shortdesc: "REVJ TOT DISC ARTHRP ANT APPR LMBR EA NTRSPC" },
  { code: "90839", shortdesc: "PSYCHOTHERAPY FOR CRISIS INITIAL 60 MINUTES" },
  { code: "19364", shortdesc: "BREAST RECONSTRUCTION FREE FLAP" },
  { code: "19361", shortdesc: "BRST RCNSTJ W/LATSMS D/SI FLAP WO PRSTHC IMPL" },
  { code: "62100", shortdesc: "CRX RPR DURAL/CSF LEAK RHINORRHEA/OTORRHEA" },
  { code: "43213", shortdesc: "ESOPHAGOSCOPY RETROGRADE DILATE BALLOON/OTHER" },
  {
    code: "21336",
    shortdesc: "OPEN TX NASAL SEPTAL FRACTURE W/WO STABILIZATION",
  },
  { code: "58543", shortdesc: "LAPS SUPRACERVICAL HYSTERECTOMY >250" },
  { code: "26200", shortdesc: "EXCISION/CURETTAGE CYST/TUMOR METACARPAL" },
  { code: "42180", shortdesc: "REPAIR LACERATION PALATE </2 CM" },
  { code: "75998", shortdesc: "null" },
  { code: "50800", shortdesc: "URETEROENTEROSTOMY ANAST URETER INTESTINE" },
  { code: "36596", shortdesc: "MCHNL RMVL INTRAL OBSTR CV DEV THRU DEV LUMEN" },
  { code: "33320", shortdesc: "SUTR RPR AORTA/GRT VSL W/O SHUNT/CARD BYP" },
  { code: "31634", shortdesc: "BRONCHOSCOPY BALLOON OCCLUSION" },
  { code: "35663", shortdesc: "BYP OTH/THN VEIN ILIOILIAC" },
  {
    code: "35475",
    shortdesc: "TRLUML BALO ANGIOP PRQ BRCH/CPHLC TRNK/BRNCH EA",
  },
  { code: "87073", shortdesc: "CUL BACT QUAN ANAERC ISOL XCPT UR BLOOD/STOOL" },
  {
    code: "87143",
    shortdesc: "CULTURE TYPING GAS/HIGH PRES LIQ CHROMATOGRAPHY",
  },
  { code: "88299", shortdesc: "UNLISTED CYTOGENETIC STUDY" },
  { code: "20802", shortdesc: "REPLANTATION ARM COMPLETE AMPUTATION" },
  { code: "82465", shortdesc: "CHOLESTEROL SERUM/WHOLE BLOOD TOTAL" },
  { code: "62273", shortdesc: "INJECTION EPIDURAL BLOOD/CLOT PATCH" },
  { code: "44950", shortdesc: "APPENDECTOMY" },
  { code: "61862", shortdesc: "null" },
  { code: "33535", shortdesc: "CABG W/ARTERIAL GRAFT THREE ARTERIAL GRAFTS" },
  { code: "86753", shortdesc: "ANTIBODY PROTOZOA NES" },
  {
    code: "47554",
    shortdesc: "BILIARY ENDOSCOPY PRQ VIA T-TUBE W/RMVL CALCULUS",
  },
  {
    code: "33974",
    shortdesc: "RMVL ASCENDING-AORTA BALO DEV W/RPR ASCEND-AORTA",
  },
  { code: "01120", shortdesc: "ANESTHESIA ON BONY PELVIS" },
  { code: "92533", shortdesc: "CALORIC VESTIBULAR TEST EACH IRRIGATION" },
  {
    code: "0080T",
    shortdesc: "EVASC RPR AAA PSEUDOARYSM ABDL AORTA VISC RS&I",
  },
  { code: "38790", shortdesc: "INJECTION PROCEDURE LYMPHANGIOGRAPHY" },
  { code: "51999", shortdesc: "UNLISTED LAPAROSCOPY PROCEDURE BLADDER" },
  {
    code: "33964",
    shortdesc: "ECMO/ECLS ECLS REPOS CENTRAL CNULA 6YRS & OLDER",
  },
  { code: "43239", shortdesc: "EGD TRANSORAL BIOPSY SINGLE/MULTIPLE" },
  {
    code: "4200F",
    shortdesc: "EXTRNL BM RADIOTHXPY TO PROST W/WO NODAL IRRAD",
  },
  { code: "31235", shortdesc: "NASAL/SINUS ENDOSCOPY DX SPHENOID SINUSOSCOPY" },
  { code: "1111F", shortdesc: "DISCHRG MEDS RECONCILED W/CURRENT MED LIST" },
  {
    code: "00215",
    shortdesc: "ANES INTRACRANIAL/ELEVATION DEPRSD SKULL FX XDRL",
  },
  {
    code: "27566",
    shortdesc: "OPTX PATELLAR DISLC W/WO PRTL/TOT PATELLECTOMY",
  },
  { code: "35521", shortdesc: "BYPASS W/VEIN AXILLARY-FEMORAL" },
  { code: "45378", shortdesc: "COLONOSCOPY FLX DX W/COLLJ SPEC WHEN PFRMD" },
  { code: "33253", shortdesc: "null" },
  {
    code: "95873",
    shortdesc: "ELECTRICAL STIMULATION GUID W/CHEMODENERVATION",
  },
  {
    code: "69710",
    shortdesc: "IMPLTJ/RPLCMT EMGNT BONE CNDJ DEV TEMPORAL BONE",
  },
  { code: "59540", shortdesc: "null" },
  { code: "92020", shortdesc: "GONIOSCOPY SEPARATE PROCEDURE" },
  { code: "23930", shortdesc: "I&D UPPER ARM/ELBOW DEEP ABSCESS/HEMATOMA" },
  { code: "89220", shortdesc: "SPUTUM OBTAINING SPEC AEROSOL INDUCED TX SPX" },
  { code: "33238", shortdesc: "RMVL PRM TRANSVENOUS ELECTRODE THORACOTOMY" },
  {
    code: "53660",
    shortdesc: "DILAT FEMALE URETHRA W/SUPPOSITORY&/INSTLJ INI",
  },
  {
    code: "21267",
    shortdesc: "ORBITAL REPOSITIONING W/BONE GRAFTS EXTRACRANIAL",
  },
  { code: "0069T", shortdesc: "null" },
  { code: "67105", shortdesc: "RPR RETINAL DTCHMNT DRG SUBRETINAL FLUID PC" },
  { code: "80058", shortdesc: "null" },
  { code: "31320", shortdesc: "LARYNGOTOMY THYROTOMY LARYNGOFISSURE DX" },
  { code: "50750", shortdesc: "URETEROCALYCOSTOMY ANAST URETER RENAL CALYX" },
  { code: "0538T", shortdesc: "null" },
  { code: "21086", shortdesc: "IMPRESSION & PREPARATION AURICULAR PROSTHESIS" },
  { code: "85536", shortdesc: "IRON STAIN PERIPHERAL BLOOD" },
  { code: "32556", shortdesc: "PERQ DRAINAGE PLEURA INSERT CATH W/O IMAGING" },
  {
    code: "61557",
    shortdesc: "CRANIECTOMY CRANIOSYNOSTOSIS BIFRONTAL BONE FLAP",
  },
  {
    code: "58346",
    shortdesc: "INSERTION HEYMAN CAPSULES CLINICAL BRACHYTHERAPY",
  },
  { code: "57530", shortdesc: "TRACHELECTOMY CERVICECTOMY AMP CERVIX SPX" },
  { code: "0034T", shortdesc: "null" },
  { code: "54324", shortdesc: "1 STG DSTL HYPOSPADIAS RPR W/URTP SKIN FLAPS" },
  {
    code: "87071",
    shortdesc: "CUL BACT QUAN AEROBIC ISOL XCPT UR BLOOD/STOOL",
  },
  {
    code: "93312",
    shortdesc: "ECHO TRANSESOPHAG R-T 2D W/PRB IMG ACQUISJ I&R",
  },
  { code: "24576", shortdesc: "CLTX HUMERAL CONDYLAR FX MEDIAL/LAT W/O MANJ" },
  {
    code: "0381T",
    shortdesc: "XTRNL HRT RATE EPI SEIZ UP TO 14 DAYS COMPLETE",
  },
  {
    code: "19288",
    shortdesc: "PERQ BREAST LOC DEVICE PLACEMT ADD LESIO MR GUID",
  },
  { code: "97001", shortdesc: "PHYSICAL THERAPY EVALUATION" },
  { code: "21800", shortdesc: "CLOSED TX RIB FRACTURE UNCOMPLICATED EACH" },
  { code: "00144", shortdesc: "ANESTHESIA EYE CORNEAL TRANSPLANT" },
  { code: "81216", shortdesc: "BRCA2 GENE ANALYSIS FULL SEQUENCE ANALYSIS" },
  { code: "88263", shortdesc: "CHRMSM COUNT 45 CELL MOSAICISM 2KARYOTYPE" },
  { code: "55720", shortdesc: "PROSTATOTOMY EXTERNAL DRG ABSCESS SIMPLE" },
  { code: "87510", shortdesc: "IADNA GARDNERELLA VAGINALIS DIRECT PROBE TQ" },
  {
    code: "22526",
    shortdesc: "PERQ INTRDSCL ELECTROTHRM ANNULOPLASTY 1 LEVEL",
  },
  {
    code: "0435T",
    shortdesc: "PRGRMG EVAL NSTIM PLS GEN SYS SLEEP APNEA 1 SESS",
  },
  { code: "31775", shortdesc: "BRONCHOPLASTY EXCISION STENOSIS & ANASTOMOSIS" },
  {
    code: "99343",
    shortdesc: "HOME VST NEW PATIENT MOD-HI SEVERITY 45 MINUTES",
  },
  {
    code: "00794",
    shortdesc: "ANES LAPAROSCOPIC PARTIAL/TOTAL PANCREATECTOMY",
  },
  {
    code: "32506",
    shortdesc: "THORACOTOMY W/THERAP WEDGE RESEXN ADDL IPSILATRL",
  },
  {
    code: "52601",
    shortdesc: "TRURL ELECTROSURG RESCJ PROSTATE BLEED COMPLETE",
  },
  { code: "34704", shortdesc: "EVASC RPR DPLMNT AORTO-UN-ILIAC NDGFT RPT" },
  { code: "62163", shortdesc: "NEUROENDOSCOPY ICRA W/RETRIEVAL FOREIGN BODY" },
  { code: "24352", shortdesc: "null" },
  { code: "90834", shortdesc: "PSYCHOTHERAPY W/PATIENT 45 MINUTES" },
  {
    code: "62264",
    shortdesc: "PRQ LYSIS EPIDURAL ADHESIONS MULT SESSIONS 1 DAY",
  },
  { code: "95816", shortdesc: "ELECTROENCEPHALOGRAM W/REC AWAKE&DROWSY" },
  { code: "00908", shortdesc: "ANESTHESIA PERINEAL PROSTATECTOMY" },
  { code: "95923", shortdesc: "TESTING AUTONOMIC NERVOUS SYSTEM FUNCTION" },
  { code: "3092F", shortdesc: "MAJOR DEPRESSIVE DISORDER REMISSION" },
  { code: "77295", shortdesc: "3-D RADIOTHERAPY PLAN DOSE-VOLUME HISTOGRAMS" },
  { code: "64623", shortdesc: "DSTRJ NULYT PVRT FACET JT NRV LMBR/SAC EA LVL" },
  {
    code: "28092",
    shortdesc: "EXC LESION TENDON SHEATH/CAPSULE W/SYNVCT TOE EA",
  },
  { code: "26542", shortdesc: "RCNSTJ COLTRL LIGM MTCARPHLNGL 1 W/LOCAL TISS" },
  {
    code: "95939",
    shortdesc: "CTR MOTR EP STD TRANSCRNL MOTR STIM UPR&LOW LI",
  },
  { code: "22610", shortdesc: "ARTHRODESIS POSTERIOR/POSTEROLATERAL THORACIC" },
  { code: "87276", shortdesc: "IAADI INFFLUENZA A VIRUS" },
  {
    code: "76819",
    shortdesc: "FETAL BIOPHYSICAL PROFILE W/O NON-STRESS TESTING",
  },
  { code: "30462", shortdesc: "RHINP DFRM COLUM LNGTH TIP SEPTUM OSTEOT" },
  { code: "25394", shortdesc: "OSTEOPLASTY CARPAL BONE SHORTENING" },
  { code: "76813", shortdesc: "US FETAL NUCHAL TRANSLUCENCY 1ST GESTATION" },
  { code: "49010", shortdesc: "EXPL RETROPERITONEUM W/WO BX SPX" },
  { code: "82820", shortdesc: "HGB-O2 AFFINITY PO2 50% SATURATION OXYGEN" },
  { code: "0440T", shortdesc: "ABLTJ PERC CRYOABLTJ IMG GDN UXTR/PERPH NERVE" },
  { code: "88400", shortdesc: "null" },
  { code: "92987", shortdesc: "PRQ BALLOON VALVULOPLASTY MITRAL VALVE" },
  { code: "73522", shortdesc: "RADEX HIPS BILATERAL WITH PELVIS 3-4 VIEWS" },
  { code: "27509", shortdesc: "PRQ SKELETAL FIXJ FEMORAL FX DISTAL END" },
  { code: "00872", shortdesc: "ANES LITHOTRP XTRCORP SHOCK WAVE W/WATER BATH" },
  { code: "11201", shortdesc: "REMOVAL SK TGS MLT FIBRQ TAGS ANY AREA EA 10" },
  { code: "87210", shortdesc: "SMR PRIM SRC WET MOUNT NFCT AGT" },
  { code: "83937", shortdesc: "ASSAY OF OSTEOCALCIN" },
  { code: "97158", shortdesc: "null" },
  { code: "26045", shortdesc: "FASCIOTOMY PALMAR OPEN PARTIAL" },
  { code: "77750", shortdesc: "NFS/INSTLJ RADIOELMNT SLN 3 MO FOLLOW-UP CARE" },
  { code: "27647", shortdesc: "RADICAL RESECTION OF TUMOR TALUS OR CALCANEUS" },
  { code: "34812", shortdesc: "OPN FEM ART EXPOS DLVR EVASC PROSTH UNI" },
  { code: "21820", shortdesc: "CLOSED TREATMENT STERNUM FRACTURE" },
  {
    code: "81319",
    shortdesc: "PMS2 GENE ANALYSIS DUPLICATION/DELETION VARIANTS",
  },
  {
    code: "62380",
    shortdesc: "NDSC DCMPRN SPINAL CORD 1 W/LAMOT NTRSPC LUMBAR",
  },
  { code: "29877", shortdesc: "ARTHRS KNEE DEBRIDEMENT/SHAVING ARTCLR CRTLG" },
  {
    code: "78190",
    shortdesc: "KINETICS PLATELET W/WO DIFFRNTL ORGAN/TIS LOCLZJ",
  },
  {
    code: "29085",
    shortdesc: "APPLICATION CAST HAND & LOWER FOREARM GAUNTLET",
  },
  { code: "45398", shortdesc: "COLONOSCOPY FLEXIBLE WITH BAND LIGATION(S)" },
  { code: "4086F", shortdesc: "ASPIRIN OR CLOPIDOGREL PRESCRIBED" },
  { code: "99472", shortdesc: "SUBSQ PED CRITICAL CARE 29 DAYS THRU 24 MO" },
  { code: "37605", shortdesc: "LIGATION INTERNAL/COMMON CAROTID ARTERY" },
  {
    code: "43195",
    shortdesc: "ESOPHAGOSCOPY RIGID TRANSORAL BALLOON DILATION",
  },
  { code: "69676", shortdesc: "TYMPANIC NEURECTOMY" },
  { code: "65870", shortdesc: "SEVERING ADS ANT SEG INCAL SPX ANT SYNECHIAE" },
  {
    code: "31571",
    shortdesc: "LARGSC W/NJX VOCAL CORD THER W/MICRO/TELESCOPE",
  },
  { code: "93015", shortdesc: "CV STRS TST XERS&/OR RX CONT ECG W/SI&R" },
  {
    code: "01758",
    shortdesc: "ANESTH OPEN/SURG ARTHRS EXC CYST/TUMOR HUMERUS",
  },
  { code: "0097U", shortdesc: "null" },
  { code: "4014F", shortdesc: "DSCHRG INSTRUCTIONS HRT FAILURE XCP PTS 18 YR" },
  {
    code: "90735",
    shortdesc: "JAPANESE ENCEPHALITIS VIRUS VACCINE SUBCUTANEOUS",
  },
  { code: "37244", shortdesc: "VASCULAR EMBOLIZATION OR OCCLUSION HEMORRHAGE" },
  { code: "62146", shortdesc: "CRANIOPLASTY W/AUTOGRAFT </ 5 CM DIAMETER" },
  { code: "92499", shortdesc: "UNLISTED OPHTHALMOLOGICAL SERVICE/PROCEDURE" },
  {
    code: "61596",
    shortdesc: "TRANSCOCHLR POST CRNL FOSSA W/WO MOBIL NRV/ART",
  },
  {
    code: "23655",
    shortdesc: "CLSD TX SHOULDER DISLC W/MANIPULATION REQ ANES",
  },
  { code: "38564", shortdesc: "LMTD LMPHADEC STAGING SPX RPR AORTIC&/SPLENIC" },
  { code: "31500", shortdesc: "INTUBATION ENDOTRACHEAL EMERGENCY PROCEDURE" },
  { code: "95936", shortdesc: "H-REFLEX AMPLT&LATENCY OTH/THN GASTRCN/SOLEUS" },
  { code: "2002F", shortdesc: "CLINICAL SIGNS VOLUME OVERLOAD ASSESSED" },
  { code: "12014", shortdesc: "SIMPLE REPAIR F/E/E/N/L/M 5.1CM-7.5 CM" },
  { code: "28140", shortdesc: "METATARSECTOMY" },
  { code: "21493", shortdesc: "null" },
  { code: "77063", shortdesc: "SCREENING DIGITAL BREAST TOMOSYNTHESIS BI" },
  { code: "96521", shortdesc: "REFILLING & MAINTENANCE PORTABLE PUMP" },
  { code: "15999", shortdesc: "UNLISTED PROCEDURE EXCISION PRESSURE ULCER" },
  {
    code: "33264",
    shortdesc: "RMVL IMPLTBL DFB PLS GEN W/RPLCMT PLS GEN MLT LD",
  },
  { code: "23500", shortdesc: "CLSD TX CLAVICULAR FRACTURE W/O MANIPULATION" },
  {
    code: "61886",
    shortdesc: "INSJ/RPLCMT CRANIAL NEUROSTIM GENER 2/> ELTRDS",
  },
  { code: "28052", shortdesc: "ARTHRTOMY W/BX METATARSOPHALANGEAL JOINT" },
  { code: "21151", shortdesc: "RCNSTJ MIDFACE LEFORT II W/BONE GRAFTS" },
  { code: "0090T", shortdesc: "null" },
  { code: "95910", shortdesc: "NERVE CONDUCTION STUDIES 7-8 STUDIES" },
  { code: "65114", shortdesc: "EXNTJ ORBIT RMVL ORB CNTS W/MUSC/MYOQ FLAP" },
  { code: "83970", shortdesc: "ASSAY OF PARATHORMONE" },
  { code: "82017", shortdesc: "ACYLCARNITINES QUANTIATIVE EACH SPECIMEN" },
  { code: "76983", shortdesc: "null" },
  { code: "95805", shortdesc: "MLT SLEEP LATENCY/MAINT OF WAKEFULNESS TSTG" },
  { code: "81274", shortdesc: "null" },
  { code: "32670", shortdesc: "THORACOSCOPY W/BILOBECTOMY" },
  { code: "54505", shortdesc: "BIOPSY TESTIS INCISIONAL SEPARATE PROCEDURE" },
  {
    code: "4280F",
    shortdesc: "PNEUMOCYS JIROVECI PNEUMO PRPHYLXS PRSCRBD 3 MON",
  },
  { code: "85547", shortdesc: "MECHANICAL FRAGILITY RBC" },
  { code: "31620", shortdesc: "ENDOBRNCL US BRONCHOSCOPIC DX/THER IVNTJ" },
  { code: "81510", shortdesc: "FETAL CONGENITAL ABNOR ASSAY THREE ANAL" },
  {
    code: "28737",
    shortdesc: "ARTHRD W/TDN LNGTH&ADVMNT TARSL NVCLR-CUNEIFOR",
  },
  { code: "81596", shortdesc: "null" },
  { code: "74150", shortdesc: "CT ABDOMEN W/O CONTRAST MATERIAL" },
  { code: "95803", shortdesc: "ACTIGRAPHY TESTING RECORDING ANALYSIS I&R" },
  { code: "83861", shortdesc: "MICROFLUIDIC ANALYSIS TEAR OSMOLARITY" },
  { code: "65760", shortdesc: "KERATOMILEUSIS" },
  { code: "35132", shortdesc: "DIR RPR RUPTD ANEURYSM & GRAFT ILIAC ARTERY" },
  {
    code: "30520",
    shortdesc: "SEPTOPLASTY/SUBMUCOUS RESECJ W/WO CARTILAGE GRF",
  },
  { code: "64734", shortdesc: "TRANSECTION/AVULSION INFRAORBITAL NERVE" },
  { code: "29220", shortdesc: "null" },
  { code: "80053", shortdesc: "COMPREHENSIVE METABOLIC PANEL" },
  { code: "01636", shortdesc: "ANES ARTHRS INTERTHORACOSCAPULAR AMPUTATION" },
  {
    code: "00836",
    shortdesc: "ANES HRNA RPR LWR ABD NOS INFTS <37WK BRTH/50WK",
  },
  { code: "89049", shortdesc: "CAFFEINE HALOTHANE CONTRACTURE TEST" },
  {
    code: "26735",
    shortdesc: "OPEN TX PHALANGEAL SHAFT FRACTURE PROX/MIDDLE EA",
  },
  { code: "55401", shortdesc: "null" },
  {
    code: "62305",
    shortdesc: "MYELOGRAPHY VIA LUMBAR INJECTION RS&I 2+ REGIONS",
  },
  { code: "50592", shortdesc: "ABLTJ 1/> RENAL TUMOR PRQ UNI RADIOFREQUENCY" },
  { code: "83993", shortdesc: "ASSAY OF CALPROTECTIN FECAL" },
  { code: "90764", shortdesc: "null" },
  { code: "62279", shortdesc: "null" },
  {
    code: "93702",
    shortdesc: "BIS EXTRACELLULAR FLUID ALYS LYMPHEDEMA ASSMNT",
  },
  { code: "4194F", shortdesc: "PATIENT RCVNG >= 10 MG DAILY PREDNISONE" },
  { code: "57283", shortdesc: "COLPOPEXY VAGINAL INTRAPERITONEAL APPROACH" },
  { code: "43420", shortdesc: "CLSR ESOPHAGOSTOMY/FSTL CRV APPR" },
  { code: "20680", shortdesc: "REMOVAL IMPLANT DEEP" },
  {
    code: "79300",
    shortdesc: "RP THERAPY INTERSTITIAL RADIOACTIVE COLLOID ADMN",
  },
  {
    code: "99326",
    shortdesc: "DOMICIL/REST HOME NEW PT HI-MOD SEVER 45 MINUTES",
  },
  { code: "84087", shortdesc: "ASSAY OF PHOSPHOHEXOSE ISOMERASE" },
  { code: "0534T", shortdesc: "null" },
  { code: "29583", shortdesc: "APPL MLTLAYR COMPRES SYSTEM UPPER & LOWER ARM" },
  { code: "26518", shortdesc: "CAPSULODESIS MTCARPHLNGL JOINT 3/4 DIGITS" },
  { code: "99364", shortdesc: "ANTICOAGULANT MGMT OUTPATIENT EA SBSQ 90 DAYS" },
  {
    code: "43258",
    shortdesc: "UPR GI NDSC ABLTJ LES X RMVL FORCEPS/CAUT/SNARE",
  },
  {
    code: "86331",
    shortdesc: "IMMUNODIFFUSION GEL DIFFUSION QUAL EA AG/ANTBDY",
  },
  {
    code: "84479",
    shortdesc: "THYROID HORM UPTK/THYROID HORMONE BINDING RATIO",
  },
  {
    code: "28298",
    shortdesc: "CORRJ HALLUX VALGUS W/SESMDC W/PROX PHLNX OSTEOT",
  },
  { code: "56301", shortdesc: "null" },
  {
    code: "95962",
    shortdesc: "FUNCJAL CORT&SUBCORT MAPG PHYS/QHP ATTND ADDL HR",
  },
  { code: "0147T", shortdesc: "null" },
  {
    code: "99355",
    shortdesc: "PROLNG E&M/PSYCTX SVC OFFICE O/P DIR CON ADDL 30",
  },
  { code: "40520", shortdesc: "EXC LIP V-EXC W/PRIM DIR LINR CLSR" },
  { code: "23222", shortdesc: "null" },
  { code: "33860", shortdesc: "ASCENDING AORTA GRF W/CARD BYP & VALVE SSP" },
  {
    code: "20827",
    shortdesc: "RPLJ THUMB DISTAL TIP MP JOINT COMPL AMPUTATION",
  },
  { code: "77310", shortdesc: "TELETHERAPY ISODOSE PLAN INTERMEDIATE" },
  { code: "4171F", shortdesc: "PATIENT RECEIVING (ESA) THERAPY" },
  { code: "0041U", shortdesc: "null" },
  {
    code: "77003",
    shortdesc: "FLUOR NEEDLE/CATH SPINE/PARASPINAL DX/THER ADDON",
  },
  { code: "74170", shortdesc: "CT ABDOMEN W/O & W/CONTRAST MATERIAL" },
  { code: "31899", shortdesc: "UNLISTED PROCEDURE TRACHEA BRONCHI" },
  { code: "62165", shortdesc: "NUNDSC ICRA EXC PITUITRY TUM TRNSNSL/SPHENOID" },
  {
    code: "73223",
    shortdesc: "MRI ANY JT UPPER EXTREMITY W/O & W/CONTR MATRL",
  },
  {
    code: "29886",
    shortdesc: "ARTHRS KNEE DRILLING OSTEOCHOND DISSECANS LESION",
  },
  { code: "27814", shortdesc: "OPEN TREATMENT BIMALLEOLAR ANKLE FRACTURE" },
];
