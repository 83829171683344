import React from "react";
import Form from "./../Components/Form/index";
import axios from "axios";
import Cookies from "js-cookie";
import { CSVLink } from "react-csv";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";

import settings from "../helpers/Settings";
let { KayanURL } = settings;

var XLSX = require("xlsx");
class UploadPriseList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: NaN,
      Records: [],
      usertype: this.props.usertype,
    };
  }

  readXlsxFile = (reader) => {
    // To Do: 1- Handling error (reader.onerror); 2- clear Input file for each change on input file.
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ requestXlsx: reader.result });
      }
    };
  };

  handleInputTextChange = ({ target: { name, value, files } }) => {
    this.setState({ responseReady: false });
    this.setState({ fileName: files[0].name });

    switch (name) {
      case "requestFile":
        const fileUpload = document.getElementById("requestFile");
        const regex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
        if (regex.test(fileUpload.value.toLowerCase())) {
          let fileName = fileUpload.files[0].name;
          if (typeof FileReader !== "undefined") {
            const reader = new FileReader();
            if (reader.readAsBinaryString) {
              reader.onload = (e) => {
                this.processExcel(reader.result);
              };
              reader.readAsBinaryString(fileUpload.files[0]);
            }
          } else {
            console.log("This browser does not support HTML5.");
          }
        } else {
          console.log("Please upload a valid Excel file.");
          alert("Please upload a valid Excel file.");
        }

        break;

      default:
        //  this.setState({ data: { ...this.state.data, [name]: value } });
        break;
    }
  };

  processExcel(data) {
    const workbook = XLSX.read(data, { type: "binary" });

    const firstSheet = workbook.SheetNames[0];
    const excelRows1 = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[firstSheet]
    );
    let excelRows = JSON.stringify(excelRows1);
    this.setState({ excelRows });
  }

  doRequest = async (event) => {
    event.preventDefault();
    let { usertype } = this.state;
    //console.log(this.state.excelRows);
    this.setState({ responseReady: false, sent: true });

    const data = await axios.post(KayanURL + "api/sama/addPriseList", {
      data: this.state.excelRows,

      apiToken:
        usertype == "company"
          ? Cookies.get("SamaCompanyId")
          : Cookies.get("SamaId"),
    });
    let samaResponseXML = data.data;

    let result = {};

    if (samaResponseXML.success === true) {
      result = {
        message: "The price list was uploaded successfully",
        res: true,
      };
    } else {
      result = { message: samaResponseXML.error, res: false };
    }

    this.setState({ status: result, responseReady: true, sent: false });
    document.getElementById("requestFile").value = null;
  };
  render() {
    let { responseReady, status, sent } = this.state;

    return (
      <div className="container">
        <Form
          submitStyle="BS10"
          labelStyle="BayanLabelStyle"
          onSubmit={this.doRequest}
          linkStyle="Link2"
          dangerStyle="BS10"
          onInputTextChange={this.handleInputTextChange}
          inputsTypes={["file"]}
          elementsValues={["file", "Upload Price List"]}
          elementsNames={["requestFile"]}
        />

        {sent ? <Spinner animation="border" variant="success" /> : null}

        {responseReady && status.res ? (
          <h5 style={{ color: "green" }}>{status.message}</h5>
        ) : (
          <h5 style={{ color: "red" }}>{status.message}</h5>
        )}
      </div>
    );
  }
}

export default UploadPriseList;
