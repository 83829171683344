import React, { Component, Fragment } from "react";
import "../App.css";
import settings from "../helpers/Settings";
let { apiToken } = settings;

class SanadLinks extends Component {
  render() {
    let name = localStorage.getItem("SanadName");
    let projectType = this.props.history.location.pathname.split("/")[1];

    return (
      <Fragment>
        <div className="BayanLinks">
          {/*  <a href="/Sanad/#documentation"> {projectType} Documentation</a>
          <a href="/Sanad/#api">API End Points</a> */}
          <a href="/Sanad/#schema">Schema</a>
          <a href="/Sanad/#sample">Sample</a>
          {apiToken ? (
            <a href="/Sanad/employee" style={{ color: "black" }}>
              {name} Profile
            </a>
          ) : (
            <a href="/Sanad/login">Login</a>
          )}
        </div>
      </Fragment>
    );
  }
}

export default SanadLinks;
