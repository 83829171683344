import React, { Component } from "react";
import NavBar from "./../Components/navbar";
import Footer from "./../Components/footer";
import settings from "../helpers/Settings";
let { samaApiToken, samaUser } = settings;

class Login extends Component {
  componentDidMount = () => {
    const { history } = this.props;

    if (samaApiToken) {
      history.push(`/Sama/employee`, { name: samaUser });
    }
  };

  render() {
    return (
      <>
        <NavBar />
        <div className="BayanLinks">
          <a href="/Sama/loginAsEmployee">Login As Employee</a>
        </div>

           <div className="BayanLinks" style={{marginTop:"-70px"}}>
          <a href="/Sama/loginAsCompany">Login As Company</a>
        </div>
        <Footer />
      </>
    );
  }
}

export default Login;
