import React, { useState } from "react";
import Footer from "../Components/footer";
// import MainPageFooter from '../Components/MainPageFooter'
import NavBar from "../Components/navbar";
import "../Components/Styling/providerSolutions.css";
const PayerSolutions = () => {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };
  return (
    <>
      <NavBar />
      <div
        className="container allSol"
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          width: "100%",
          fontFamily: "Poppins,sans-serif",
        }}
      >
        <div className="bloc-tabs">
          <button
            className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(1)}
          >
            SAYAN
          </button>
          <button
            className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(2)}
          >
            HUDA
          </button>
          <button
            className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(3)}
          >
            SOMA
          </button>
        </div>

        <div className="content-tabs">
          <div
            className={
              toggleState === 1 ? "content  active-content" : "content"
            }
          >
            <div className="flexinfo">
              <div className="left-provider" style={{ display: "none" }}>
                <p className="text">
                  Sayan is an assistance system helps the payers to revise the
                  clinical claims and link the rejection with the closest denial
                  codes as per Post office regulations and create clean claims
                  that adhere to medical and coding guidelines Sayan contains
                  25+ million edit combinations, and more to be extended over
                  time.
                </p>
                <span className="spantext">Sayan's Features:</span>
                <u className="features" style={{ textDecoration: "none" }}>
                  <li>
                    Excessively reduces waste, fraud and abuse cases due to the
                    enhanced medical coding accuracy.
                  </li>
                  <li>Processes claims more accurately.</li>
                  <li>
                    Gives rejection messages linked with the Post Office denial
                    codes.
                  </li>
                  <li>
                    Sends alerts to inform the payer of possible errors due to
                    incorrect coding for the services performed.
                  </li>
                </u>
              </div>

              <div className="left-provider">
                <p className="text">
                  Sayan is an assistance system helps the payers to revise the
                  clinical claims.
                </p>
              </div>
              <div className="right-provider">
                <img
                  className="img"
                  alt="Sayan Logo"
                  src={require("../imgs/Sayan_logo1.png")}
                />
              </div>
            </div>
            <div className="btns">
              <a href="/" className="leftBtn">
                <button style={{ float: "left" }} className="btn movement">
                  Back Home
                </button>
              </a>
              <a href="/loginAsEmployee" className="rightBtn">
                <button style={{ float: "right" }} className="btn movement">
                  Go to Solution
                </button>
              </a>
            </div>
          </div>

          <div
            className={
              toggleState === 2 ? "content  active-content" : "content"
            }
          >
            <div className="flexinfo">
              <div className="left-provider" style={{ display: "none" }}>
                <p className="text">
                  HUDA is a technical tool to calculate the DRG payment as per
                  the defined guidelines by the regulators. The solution has the
                  information and schema linked with all the data elements. It
                  is built with complete APIs for integration with HIS.
                </p>
                <span className="spantext">Huda's Features:</span>
                <u className="features" style={{ textDecoration: "none" }}>
                  <li>
                    Finding the errors by giving clean calculations for every
                    IP-DRG claims.
                  </li>
                  <li>
                    Providing information to the Payer for claim mismatches.
                  </li>
                  <li>
                    HUDA gives a response to the Payer about the details of
                    outcomes against the input data.
                  </li>
                  <li>
                    Calculates the DRG based on the input format (*.xml or
                    *.xls/xlsx) to provide the following:
                    <ol style={{ marginLeft: "1.2rem" }}>
                      <li>
                        DRG values in terms of inlier, outlier, high cost items
                        & high cost drugs.
                      </li>
                      <li>Calculates the value for transfer cases.</li>
                      <li>
                        Gives errors and information to Provider (e.g.,
                        duplicate services, a discrepancy in the length of
                        stay).
                      </li>
                    </ol>
                  </li>
                </u>
              </div>

              <div className="left-provider">
                <p className="text">
                  HUDA is a technical tool to calculate the DRG payment as per
                  the defined guidelines by the regulators.
                </p>
              </div>
              <div className="right-provider">
                <img
                  className="img"
                  alt="Huda Logo"
                  src={require("../imgs/Huda_logo1.png")}
                />
              </div>
            </div>
            <div className="btns">
              <a href="/" className="leftBtn">
                <button style={{ float: "left" }} className="btn movement">
                  Back Home
                </button>
              </a>
              <a href="/Sanad/loginAsEmployee" className="rightBtn">
                <button style={{ float: "right" }} className="btn movement">
                  Go to Solution
                </button>
              </a>
            </div>
          </div>

          <div
            className={
              toggleState === 3 ? "content  active-content" : "content"
            }
          >
            <div className="flexinfo">
              <div className="left-provider" style={{ display: "none" }}>
                <p className="text">
                  SOMA is a multi-level technical business intelligence tool
                  that provides logical references to claims based on various
                  clinical and technical parameters SOMA has 4 Phases.
                </p>
                <span className="spantext">Soma's Features:</span>
                <u className="features" style={{ textDecoration: "none" }}>
                  <li>RCM with insurance Benefits edits.</li>
                  <li>Use of historical data at a claim level.</li>
                  <li>
                    Machine Learning process to analyze historical data and
                    start giving predictions (Financial and medical prediction).
                  </li>
                  <li>
                    Enhances the process of denial management, giving complete
                    information that will reduce the effort in correcting the
                    claim, thus making the claim stronger for reconciliation.
                  </li>
                  <li>
                    Complies with all authority guidelines and audit parameters
                    set by the authorities (DHA/DOH) and customizable for any
                    audit requirements.
                  </li>
                  <li>
                    Gives operational statistics by all categories to improve
                    the process which provides operational excellence in patient
                    care management and revenue cycle management.
                  </li>
                  <li>
                    Effective utilization makes the best outcome of any process
                    and SOMA provides all the information that will be useful
                    for every level of processing in SOMA.
                  </li>
                  <li>
                    Gives statistical analysis and reports that are highly
                    recommended for payment analysis, denial analysis,
                    negotiations, pricing, quality reporting, etc.
                  </li>
                  <li>
                    Highly proficient and talented team comprising of Coders,
                    Management and IT Developers available 24 / 7 for client
                    requirements.
                  </li>
                </u>
              </div>

              <div className="left-provider">
                <p className="text">
                  SOMA is a multi-level technical business intelligence tool
                  that provides logical references to claims based on various
                  clinical and technical parameters.
                </p>
              </div>
              <div className="right-provider">
                <img
                  className="img"
                  alt="Soma Logo"
                  src={require("../imgs/Soma.jpg")}
                />
              </div>
            </div>
            <div className="btns">
              <a href="/" className="leftBtn">
                <button style={{ float: "left" }} className="btn movement">
                  Back Home
                </button>
              </a>
              <a
                /* href="/Somod"  */ href="/Sama/loginAsEmployee"
                className="rightBtn"
              >
                <button style={{ float: "right" }} className="btn movement">
                  Go to Solution
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PayerSolutions;
