import React, { Component } from "react";
import Footer from "../Components/footer";
import NavBar from "../Components/navbar";
import axios from "axios";
import Cookies from "js-cookie";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import settings from "../helpers/Settings";
// import { useHistory } from "react-router-dom";
let { KayanURL } = settings;

class KareemEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      adminRole: null,
      Company: "",
      city: "",
      username: "",
      password: "",
      error: null,
      serverError: null,
      usertype: null,
      isLogin: false,
      errorMsg: "",
      loginStatus: false,
      loading: true,
    };
  }
  componentDidMount = () => {
    const { history, location } = this.props;
    let errorMsg = location.state ? location.state.errorMsg : null;
    if (Cookies.get("KareemId") && !errorMsg) {
      history.push(`/KareemHome`);
    } else {
      this.setState({
        loading: false,
        error: errorMsg ? errorMsg : null,
      });
    }
  };
  //handle change
  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  //handle login
  handelLogIn = async (e) => {
    e.preventDefault();
    this.setState({ loginStatus: true });

    const { history } = this.props;
    if (this.state.Company === "Admin") {
      let adminLoginResult = await axios.post(
        KayanURL + "api/KareemBenefits/admin/login",
        {
          companyname: this.state.Company,
          username: this.state.username,
          password: this.state.password,
        }
      );

      if (adminLoginResult) {
        if (adminLoginResult["data"] && adminLoginResult["data"]["success"]) {
          Cookies.set("KareemAdminId", adminLoginResult["data"].apitoken);
          // localStorage.setItem("KareemUser", data.name);

          //localStorage.setItem("UserLicenses", data.license);
          history.push("/KareemAdmin");
        } else {
          this.setState({
            error: adminLoginResult["data"].error,
            inputsHaveErrors: adminLoginResult["data"].inputsHaveErrors,
            loginStatus: false,
          });
        }
      }
    } else {
      axios
        .post(KayanURL + "api/KareemBenefits/employee/login", {
          companyname: this.state.Company,
          username: this.state.username,
          password: this.state.password,
        })

        .then(({ data }) => {
          if (data.success) {
            Cookies.set("KareemId", data.apitoken);
            localStorage.setItem("KareemUser", data.name);

            localStorage.setItem("UserLicenses", data.license);
            history.push("/KareemHome");
          } else {
            this.setState({
              error: data.error,
              inputsHaveErrors: data.inputsHaveErrors,
              loginStatus: false,
            });
          }
        })
        .catch(() => {
          this.setState({ serverError: "There is a server error!" });
        });
    }

    this.setState({ Company: "", username: "", password: "" });
  };

  // Main
  render() {
    let { loading, loginStatus, error, serverError } = this.state;

    if (serverError) {
      return (
        <div
          style={{ backgroundColor: "red", color: "White", padding: "10px" }}
        >
          {" "}
          {"Server Error !"}{" "}
        </div>
      );
    } else {
      if (!loading) {
        return (
          <>
            <NavBar />
            {error ? (
              <div
                style={{
                  width: "100%",
                  backgroundColor: "red",
                  color: "#ffffff",
                  padding: "10px",
                }}
              >
                {" "}
                {error}{" "}
              </div>
            ) : null}
            <div id="main-registration-container" className="registerDiv">
              <form onSubmit={this.handelLogIn}>
                <div className="ToMargin">
                  <label>Company Name</label>
                  <input
                    type="text"
                    placeholder="Company Name"
                    value={this.state.Company}
                    className="form-control"
                    required
                    onChange={(event) => {
                      this.setState({ Company: event.target.value });
                    }}
                  />
                </div>

                <div className="ToMargin">
                  <label>User Name</label>

                  <input
                    type="text"
                    placeholder="User Name"
                    value={this.state.username}
                    required
                    className="form-control"
                    onChange={(event) => {
                      this.setState({ username: event.target.value });
                    }}
                  />
                </div>

                <div className="ToMargin">
                  <label style={{ marginRight: "10px" }}>Password</label>
                  <input
                    type="password"
                    value={this.state.password}
                    placeholder="Password"
                    className="form-control"
                    required
                    onChange={(event) => {
                      this.setState({ password: event.target.value });
                    }}
                  />
                </div>

                {loginStatus === false ? (
                  <input
                    type="submit"
                    style={{
                      backgroundColor: "rgb(67, 183, 81)",
                      color: "#ffffff",
                      border: "none",
                      padding: "5px 38px",
                      marginTop: "20px",
                      borderRadius: "5px",
                    }}
                    value="Login"
                    className="reg"
                  />
                ) : (
                  <Button
                    style={{
                      backgroundColor: "#43b751",
                      color: "white",
                      marginTop: "20px",
                    }}
                    variant="light"
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Loading...
                  </Button>
                )}
              </form>
            </div>

            <Footer />
          </>
        );
      } else {
        return <h2> Loading ... </h2>;
      }
    }
  }
}

export default KareemEmployee;
