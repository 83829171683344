import React, { Component, Fragment } from "react";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Table from '../Components/Table';
import Button from '../Components/Button'
import AutoComplete from "../Components/AutoComplete";
import settings from '../helpers/Settings'
import ReactTooltip from 'react-tooltip'
let { KayanURL, apiToken } = settings;


class ProviderFactorManagement extends Component {

  constructor(props) {
    super(props)
    this.state = {
      compName: localStorage.getItem("SanadCompany"),
      providerName: "",
      empType: localStorage.getItem('SanadType'),
      empName: "",
      payerName: "",
      payNames: [],
      provNames: [],
      provLicenses: [],
      payLicenses: [],
      provider_id: "",
      payer_id: "",
      license: "",
      factor: "",
      fixedPayerName: "",
      fixedPayerLicense: "",
      providers: [],
      payers: [],
      provFactors: [],
      provPayers: [],
      serverError: null,
      error: null,
      data: [],
      allFactors: [],
      licenses: [],
      payer_names: [],
      factors: [],
      username: "",
      name: null,
      leftStyle: "center",
      success: false,
      apiResult: null,
      allPayers: [],
      TableRows: null,
      onAdd: false,
      onUpdate: false,
      onDelete: false,
      startUPdate: false,
      addSuccess: false,
      updateSuccess: false,
      deleteSuccess: false,

      // Auto Complete
      items: [],
      payerACText: '',
      payerACMatched: [],
      payerACStyle: 'AutoCompleteInput',
      now: 0
    }

  };

  getPayersInfo = async () => {
    let { data } = this.state;
    let items = [];

    data.map(item => {
      if (item.name == this.state.providerName) {
        this.setState({ provider_id: item.license }, () => {
          axios
            .put(KayanURL + 'api/sanad/get_payers', { apiToken })
            .then(({ data }) => {
              let d = data;
              if (d.success) {
                this.setState({
                  allPayers: d.data
                })
                d.data.map(item => {
                  items.push(item.payer_id + " , " + item.payer_name);
                  this.setState({ items })
                })

              } else {
                this.setState({
                  error: d.error
                })
              }
            })
            .catch(() => {
              this.setState({ serverError: "There is a server error!" });
            })

          axios
            .put(KayanURL + 'api/sanad/get_factors', { apiToken })
            .then(({ data }) => {
              let d = data;
              if (d.success) {
                let allFactors = d.data.filter(provider => provider.provider_id == this.state.provider_id).sort((factorA, factorB) => factorA.payer_name.localeCompare(factorB.payer_name));
                let licenses = allFactors.map(payer => payer.payer_id);
                let payer_names = allFactors.map(payer => payer.payer_name);
                let factors = allFactors.map(payer => payer.factor);

                if (this.state.onDelete || this.state.onUpdate || this.state.onAdd) {
                  setTimeout(() => {
                    this.setState({
                      onDelete: this.state.onDelete ? false : this.state.onDelete,
                      deleteSuccess: this.state.onDelete ? this.state.onDelete : this.state.deleteSuccess,
                      onAdd: this.state.onAdd ? false : this.state.onAdd,
                      addSuccess: this.state.onAdd ? this.state.onAdd : this.state.addSuccesss,
                      onUpdate: this.state.onUpdate ? false : this.state.onUpdate,
                      updateSuccess: this.state.onUpdate ? this.state.onUpdate : this.state.updateSuccess,
                      licenses,
                      payer_names,
                      factors,
                      allFactors,
                      TableRows: this.prepareFactorsTable(allFactors)
                    })
                  }, '5000');
                } else {
                  this.setState({ licenses, payer_names, factors, allFactors, TableRows: this.prepareFactorsTable(allFactors) })
                }
              } else {
                this.setState({ error: d.error })
              }
            })
            .catch(() => {
              this.setState({ serverError: "There is a server error!" });
            })
        })
      }
    })
  }

  getCompaniesInfo = async () => {
    let { empType, items } = this.state;


    await axios
      .put(KayanURL + 'api/sanad/get_kayan_providers', { apiToken })
      .then(({ data }) => {
        if (data.success) {
          data = data.data;
          this.setState({ data }, () => {
            if (empType == 'admin')
              this.setState({
                providerName: this.state.data[0]['name'],
                provider_id: this.state.data[0]['license']
              }, () => {
                this.getPayersInfo()
              })

            else {
              this.getPayersInfo()
            }

          })

        } else {
          this.setState({ error: data.error })

        }
      })

      .catch(() => {
        this.setState({ serverError: "There is a server error!" });
      })
  }

  componentDidMount = () => {
    const { history } = this.props;
    let companyType = localStorage.getItem("SanadCompanyType");

    if (!apiToken) {
      history.push(`/Sanad/loginAsEmployee`, { errorMsg: "You need to sign in or sign up" });
    }
    else {
      if (this.state.compName != null && this.state.compName != "" && this.state.compName != "Kayan"
        && this.state.compName != undefined) {
        this.setState({ providerName: this.state.compName })
      }

      this.getCompaniesInfo();

    }
  }

  prepareFactorsTable = (items) => {
    let TableRows = [];
    if (items && items.length > 0) {
      items.forEach((item, index) => {
        let row = {};
        row['Id'] = index + 1;
        row['License'] = item['payer_id'];
        row['Name'] = item['payer_name'];
        row['Factor'] = item['factor'];
        row['Action'] = [
          <div key={index}>
            <Button Style="BS12" Value="Update" onClick={() => this.onPayerInfoUpdateClick(index)} />
            <Button Style="BS12" Value="Delete" onClick={() => this.deleteFactor(index)} />
          </div>
        ]
        TableRows.push(row);
      })
    }
    return TableRows;
  }

  handleProviderChange = (e) => {
    e.preventDefault();
    this.setState({
      providerName: e.target.value.split(" , ")[1].trim(),
      provider_id: e.target.value.split(" , ")[0].trim()
    }, () => {
      this.setState({ TableRows: null }, () => {
        this.getPayersInfo()

      })
    })

  }

  onPayerInfoUpdateClick = (index) => {
    this.setState({
      startUPdate: true,
      payer_id: this.state.licenses[index],
      payerName: this.state.payer_names[index],
      factor: this.state.factors[index],
      error: null
    })
  }

  addFactor = async (e) => {
    e.preventDefault();

    this.setState({
      onAdd: true,
    })

    let my_data = {
      provider_id: this.state.provider_id,
      payer_id: this.state.payer_id,
      payer_name: this.state.payerName,
      factor: this.state.factor
    }

    await
      axios
        .put(KayanURL + 'api/sanad/add_factor', { my_data, apiToken })
        .then(({ data }) => {

          if (data.success) {
            this.setState({
              apiResult: data.result,
              success: true,
              payer_id: "",
              payerName: "",
              factor: ""
            }, () => {
              this.getPayersInfo()
            })
          } else {
            this.setState({
              error: data.error,
              onAdd: false,
              addSuccess: true
            })
          }
        })
        .catch(() => {
          this.setState({ serverError: "There's a server error!" })
        })
  }

  updateFactor = async (e) => {
    e.preventDefault();
    this.setState({
      onUpdate: true
    })

    let my_data = {
      provider_id: this.state.provider_id,
      payer_id: this.state.payer_id,
      factor: this.state.factor
    }

    axios
      .put(KayanURL + 'api/sanad/update_factor', { my_data, apiToken })
      .then(({ data }) => {

        if (data.success) {
          this.setState({
            apiResult: data.result,
            payer_id: "",
            factor: ""
          }, () => {
            this.getPayersInfo()

          })
        } else {
          this.setState({
            error: data.error,
            onUpdate: false,
            updateSuccess: true
          })
        }
      })
      .catch(() => {
        this.setState({ error: "There is a server error" });

      })

  }

  deleteFactor = (index) => {

    this.setState({
      onDelete: true
    })

    let my_data = {
      provider_id: this.state.provider_id,
      payer_id: this.state.licenses[index]
    }

    axios
      .put(KayanURL + 'api/sanad/delete_factor', { my_data, apiToken })
      .then(({ data }) => {
        if (data.success) {
          this.setState({
            apiResult: data.result,
            payer_id: ""
          }, () => {
            this.getPayersInfo()

          })
        } else {
          this.setState({
            error: data.error,
            onDelete: false,
            deleteSuccess: true
          })
        }
      })
  }

  // Handle Auto Complete Actions
  handleACInputChange = (e) => {
    const value = e.target.value;
    let matched = [];
    this.setState({
      success: false,
      error: null,
      onAdd: false,
      addSuccess: false,
      onDelete: false,
      deleteSuccess: false,
      onUpdate: false,
      updateSuccess: false
    });

    if (value.length > 0) {
      let regValue = value.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&').toLowerCase();
      const regex = new RegExp(`${regValue}`, 'g');
      matched = this.state.items.sort().filter(v => regex.test(v.toLowerCase()))
      matched.length > 0 ? this.setState({ payerACStyle: 'ACInputOnChangingStyle' }) : this.setState({ payerACStyle: 'AutoCompleteInput' })
      this.setState({ payerACMatched: matched, payerACText: value });
    } else {
      this.setState({ payerACMatched: matched, payerACText: value, payerACStyle: 'AutoCompleteInput' })
    }
  }

  handleACItemSelection = (value) => {
    let payer_license = value.split(',')[0].trim();
    let payer_name = value.split(',')[1].trim();
    this.setState(() => ({
      payerACText: value,
      payerACMatched: [],
      payerACStyle: 'AutoCompleteInput',
      payer_id: payer_license,
      payerName: payer_name
    }))
  }

  // Render Progress Bar
  renderProgressBar() {
    let { now, success } = this.state;
    setTimeout(() => {
      this.setState({
        now: !success && now < 100 ? now + 20 : 100
      }, this.renderProgressBar())
    }, '800')
  }

  render() {
    const { payerACText, payerACMatched, payerACStyle, error, serverError, now, success } = this.state;

    if (success)
      setTimeout(() => {
        document.getElementById("factor").value = '';
        this.setState({
          success: false,
          payerACText: ""
        })
      }, '6500')


    if (!error && (this.state.addSuccess || this.state.updateSuccess || this.state.deleteSuccess))
      setTimeout(() => {
        this.setState({
          addSuccess: false,
          updateSuccess: false,
          deleteSuccess: false
        })
      }, '1500')

    if (serverError)
      return (
        <div style={{ backgroundColor: "rgb(67, 183, 81)", color: "#ffffff", padding: "10px" }}>
          {serverError}
        </div>
      )

    else
      return (
        <Fragment>
          {
            this.state.errorMsg ?
              <div style={{ backgroundColor: "rgb(67, 183, 81)", color: "#ffffff", padding: "10px" }}>
                {this.state.errorMsg}
              </div>
              :
              null
          }


          < div style={{ "padding": "1%" }}>
            <form style={{ "margin": "1% auto" }} onSubmit={this.addFactor}>
              {
                this.state.empType == 'admin' ?

                  <div>
                    <label className="factorLabel">Provider:</label>
                    <br></br>
                    <select className="fullWidthSelect"

                      defaultValue={this.state.providerName}
                      onChange={this.handleProviderChange}>
                      {
                        this.state.data.map((item, index) => {
                          return <option key={index}>{item.license + " , " + item.name}</option>
                        })
                      }
                    </select>
                  </div>
                  :
                  null
              }


              {
                this.state.allPayers && this.state.allPayers.length > 0 ?
                  <Fragment>
                    < div>
                      <label className="factorLabel">Search for payer:</label>
                      <AutoComplete
                        id="PayerAutoCompleteInput"
                        hint="Search by payer name or license"
                        name="payerInfo"
                        text={payerACText}
                        matched={payerACMatched}
                        Style={payerACStyle}
                        handleAutoCompleteChange={this.handleACInputChange}
                        handleItemSelection={this.handleACItemSelection}
                      />
                    </ div>
                    < div style={{ "padding": "0.5%" }}>
                      <label className="factorLabel">Factor:</label>
                      <input
                        name="factor"
                        id="factor"
                        type="number"
                        min="1"
                        max="3.25"
                        step="0.01"
                        data-tip="This value must be between 1 and 3.25"
                        required
                        className="form-control"
                        placeholder="Enter factor value"
                        onChange={event => {
                          this.setState({ factor: event.target.value });
                        }}
                      />
                      <ReactTooltip />

                    </div>
                    <input type="submit" value="Add" className="BS7 customWidth" />
                  </Fragment>

                  : now ?
                    now < 100 ?
                      < div style={{ "padding": "0.5%" }}>
                        < ProgressBar animated variant="success" now={now} label={`${now}% of Payers Info are gotten`} />
                      </div>
                      : this.state.allPayers && now == 100 && this.state.allPayers.length == 0 ?
                        <p>No Payers for {localStorage.getItem('SanadName')} provider </p>
                        :
                        null
                    : null

              }

              {
                this.state.onAdd && !error ?
                  <div style={{ color: "green" }}> {` The factor is adding`}
                    <Spinner size="sm" animation="grow" variant="success" />
                    <Spinner size="sm" animation="grow" variant="success" />
                    <Spinner size="sm" animation="grow" variant="success" />
                  </div>
                  : this.state.addSuccess && !this.state.onAdd && error ?
                    <div style={{ color: "red" }}> {error} </div>
                    : this.state.addSuccess && !error ?
                      <div style={{ color: "green" }}> {` The factor has been added`} </div>
                      :
                      null
              }
            </form>
          </div>


          {this.state.startUPdate ?
            <div className="popup">
              <form className="popupForm" onSubmit={this.updateFactor}>
                <p style={{ "fontWeight": "bold", "fontSize": "15px", "textAlign": "left" }}> Change {this.state.payerName} Info</p>

                <div style={{ "padding": "1%" }}>
                  <label className="factorLabel">New Factor:</label>
                  <input
                    type="number"
                    id="newfactor"
                    name='factor'
                    placeholder={`Current value is ${this.state.factor}`}
                    style={{ "width": "100%", "padding": "1vh 0.6vw" }}
                    onChange={e => this.setState({ factor: e.target.value })}
                    min="1"
                    max="3.25"
                    step="0.01"
                    data-tip="This value must be between 1 and 3.25"
                    required />
                  <ReactTooltip />

                </div>
                <input type="submit" value={`Update`} className="BS7" />
                {
                  this.state.onUpdate && !error ?
                    <div style={{ color: "green" }}> {` The factor is updating`}
                      <Spinner size="sm" animation="grow" variant="success" />
                      <Spinner size="sm" animation="grow" variant="success" />
                      <Spinner size="sm" animation="grow" variant="success" />
                    </div>
                    : this.state.updateSuccess && !this.state.onUpdate && error ?
                      <div style={{ color: "red" }}> {error} </div>
                      :
                      this.state.updateSuccess && !error ?
                        <div style={{ color: "green" }}> {` The factor has been updated`} </div>
                        :
                        null
                }

                <p style={{ "fontWeight": "bold", "fontSize": "15px", "textAlign": "right", "cursor": "pointer", "margin": "0 2%" }} onClick={() => this.setState({ startUPdate: false })}> Close </p>
              </form>
            </div >
            : null}

          {
            this.state.TableRows && this.state.allPayers ?
              this.state.TableRows.length > 0 && this.state.allPayers.length > 0
                ?
                <div style={{ "marginBottom": "2%" }}>
                  <p style={{ "fontWeight": "bold", "fontSize": "15px", "textAlign": "left" }}> The {this.state.providerName} Payers Info</p>
                  <Table THead={["Id", "License", "Name", "Factor", "Action"]} TBody={this.state.TableRows} />
                  <br></br>
                  {
                    this.state.onDelete && !error ?
                      <div style={{ color: "green" }}> {` The factor is deleting`}
                        <Spinner size="sm" animation="grow" variant="success" />
                        <Spinner size="sm" animation="grow" variant="success" />
                        <Spinner size="sm" animation="grow" variant="success" />
                      </div>
                      :
                      this.state.deleteSuccess && !this.state.onDelete && error ?
                        <div style={{ color: "red" }}> {error} </div>
                        :
                        this.state.deleteSuccess && !error ? <div style={{ color: "green" }}> {` The factor has been deleted`} </div>
                          :
                          null
                  }
                </div>
                :
                this.state.TableRows.length == 0 ? <>
                  <header>{this.state.providerName} doesn't have any payers yet.</header>
                  <br></br>
                </>
                  : null
              :
              <div>
                <span style={{ textAlign: "center", color: "#3ab449" }}> Getting {this.state.providerName} Payers Info </span>
                <Spinner size="sm" animation="grow" variant="success" />
                <Spinner size="sm" animation="grow" variant="success" />
                <Spinner size="sm" animation="grow" variant="success" />
              </div>
          }
        </Fragment>
      );
  }
}
export default ProviderFactorManagement;
