import React from "react";
import {
  Grid,
  MenuItem,
  TextField,
  Card,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { CustomAutocomplete } from "../Components/AutoComplete/CustomMuiAutocomplete";
import SimpleSelect from "../Components/controls/SimpleSelect";
import { makeStyles } from "@material-ui/core/styles";
import Spinner from "react-bootstrap/Spinner";
import ValidateClaimsTable from "./ValidateClaimsTable";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "90%",
    margin: "auto",
    padding: "2rem",
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  submitButton: {
    backgroundColor: "#3ab449",
    color: "white",
  },
}));

const ValidateClaimsFilters = ({
  handlePayerNameChange,
  payerName,
  payersList,
  claimStatus,
  handleClaimStatusChange,
  FacilityIdOptions,
  handleChangeFacility,
  departmentOptions,
  handleChangeDepartment,
  handleChangeCaseType,
  handleDateChange,
  handlePriceValueChange,
  handleUploadDate,
  claimIdValue,
  handleChangeClaimId,
  handleSearch,
  handleSearchCheckBox,
  isLoading,
  allClaimsData,
  handleValidateSelectedClaims,
  handleShowClaimTimeLine,
  handleClaimShowMore,
  handleOpenDeleteModal,
  handleOpenEditMode,
}) => {
  const classes = useStyles();

  const caseTypesOptions = [
    { name: "No Bed + No emergency room", value: "1" },
    { name: "No Bed + Emergency room", value: "2" },
    {
      name: "Inpatient Bed + No emergency room",
      value: "3",
    },
    { name: "Inpatient Bed + Emergency room", value: "4" },
    { name: "Daycase Bed + No emergency room", value: "5" },
    { name: "Daycase Bed + Emergency room", value: "6" },
    { name: "Nationals Screening", value: "7" },
    { name: "New Visa Screening", value: "8" },
    { name: "Renewal Visa Screening", value: "9" },
    { name: "Home", value: "12" },
    { name: "Assisted Living Facility", value: "13" },
    { name: "Mobile Unit", value: "15" },
    { name: "Ambulance - Land", value: "41" },
    { name: "Ambulance - Air or Water", value: "42" },
  ];
  return (
    <Card className={classes.card}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <SimpleSelect
            onChange={handlePayerNameChange}
            value={payerName || ""}
            label="Payer ID"
          >
            {payersList}
          </SimpleSelect>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            variant="outlined"
            type="datetime-local"
            name="dateFrom"
            label="From Date"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleDateChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            variant="outlined"
            type="datetime-local"
            name="dateTo"
            label="To Date"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleDateChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SimpleSelect
            value={claimStatus}
            onChange={(e) => handleClaimStatusChange(e)}
            label="Claim Status"
          >
            <MenuItem value="">All claims</MenuItem>
            <MenuItem value="0">New Claims</MenuItem>
            <MenuItem value="1">SAMA Validated</MenuItem>
            <MenuItem value="14">SAMA validated with issues</MenuItem>
            <MenuItem value="15">
              PO validated and SAMA validated with issues
            </MenuItem>
            <MenuItem value="16">
              SAMA validated and PO validated with issues
            </MenuItem>
            <MenuItem value="17">SAMA and PO validated with issues</MenuItem>
            <MenuItem value="2">PO Validated</MenuItem>
            <MenuItem value="3">SAMA and PO validated</MenuItem>
            {/*   <MenuItem value="4">RA with issues</MenuItem>*/}
            <MenuItem value="5">Fully Paid</MenuItem>
            <MenuItem value="6">
              Finished due to exceeding submission allowed value
            </MenuItem>
            <MenuItem value="7">Partially Rejected</MenuItem>
            <MenuItem value="8">Fully Rejected</MenuItem>
            <MenuItem value="9">Claim ready for submission</MenuItem>
            {/*   <MenuItem value="16">Claim submitted to DHPO </MenuItem>
             */}
            <MenuItem value="10">Correction Resubmission</MenuItem>
            <MenuItem value="11">Internal Complaint Resubmission</MenuItem>
            <MenuItem value="12">Finished with partially paid </MenuItem>
            <MenuItem value="13">Finished with fully rejected</MenuItem>
            <MenuItem value="18">Claim submitted to PO</MenuItem>
            <MenuItem value="19">Claim re-submitted to PO</MenuItem>
          </SimpleSelect>
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomAutocomplete
            multiple
            options={caseTypesOptions}
            getOptionLabel={(option) => option.name}
            onChange={(ev, newValue) => {
              handleChangeCaseType(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Case Type" variant="outlined" />
            )}
            variant="contained"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            variant="outlined"
            name="name"
            value={claimIdValue}
            onChange={handleChangeClaimId}
            label="Claim ID"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomAutocomplete
            multiple
            options={FacilityIdOptions}
            getOptionLabel={(option) => option.name}
            onChange={(ev, newValue) => {
              handleChangeFacility(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Facility ID" variant="outlined" />
            )}
            variant="contained"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            name="fromPrice"
            onChange={handlePriceValueChange}
            label="From Price"
            variant="outlined"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            style={{ marginBottom: "0.5rem", width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            name="toPrice"
            onChange={handlePriceValueChange}
            label="To Price"
            variant="outlined"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            style={{ marginBottom: "0.5rem", width: "100%" }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <CustomAutocomplete
            multiple
            options={departmentOptions}
            getOptionLabel={(option) => option.name}
            onChange={(ev, newValue) => {
              handleChangeDepartment(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Department" variant="outlined" />
            )}
            variant="contained"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            variant="outlined"
            type="datetime-local"
            name="upload_date"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleUploadDate}
            label="Uploade Date"
          />
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            {" "}
            <Box
              flexGrow={1}
              style={{
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleSearchCheckBox}
                    name="checkedA"
                    color="primary"
                  />
                }
                label="Show First 200 Claims"
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box flexGrow={1} style={{ marginBottom: "1.5rem" }}>
              <Button
                size="large"
                onClick={handleSearch}
                color="success"
                variant="contained"
                className={classes.submitButton}
              >
                {isLoading ? (
                  <>
                    {" "}
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Loading...
                  </>
                ) : (
                  "Search"
                )}
              </Button>
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box flexGrow={1} style={{ marginBottom: "1.5rem" }}>
              {allClaimsData && allClaimsData.length > 0 ? (
                <ValidateClaimsTable
                  data={allClaimsData}
                  handleValidateSelectedClaims={handleValidateSelectedClaims}
                  handleShowClaimTimeLine={handleShowClaimTimeLine}
                  handleClaimShowMore={handleClaimShowMore}
                  handleOpenDeleteModal={handleOpenDeleteModal}
                  handleOpenEditMode={handleOpenEditMode}
                />
              ) : !isLoading && allClaimsData && allClaimsData.length == 0 ? (
                <Typography variant="h6" color="error">
                  There is no claim with this status
                </Typography>
              ) : null}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ValidateClaimsFilters;
