import React from "react";
// import './Number.css';

function Text(props) {
  const { id, hint, Style, onChange, name } = props;


    return (
      <input
        id={id}
        type="text"
        className={Style}
        name={name}
        required
        onChange={onChange}
        placeholder={hint}
        style={{
          margin: " 10px auto",
          width: "70%",
          display: "block",
          padding: "10px",
        }}
      />
    );
  
  
}
export default Text;
