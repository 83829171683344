import React, { Component } from "react";
import axios from "axios";
import Uploadpreauthoraization from "./uploadPreAuthCodes"
import Cookies from "js-cookie";
import settings from "../../helpers/Settings";
import Button from "../../Components/Button/index";
import AddNewCodeOfPreAuth from "./addPreAuthCode";
import Table from "react-bootstrap/Table";
let { KayanURL } = settings;
console.log(KayanURL)
var XLSX = require("xlsx");
class PreAuthCodesScreen extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   sent: false, };
    this.state = {};
    this.onClickAddNewCode = this.onClickAddNewCode.bind(this);
    this.onClickUploadCode = this.onClickUploadCode.bind(this);
    this.getAllCodeList = this.getAllCodeList.bind(this);

  }
  componentDidMount = async () => {
    this.getAllCodeList();
  };
 //to get data
  async getAllCodeList() {
    let response = await axios.put(
    KayanURL+"api/sama/getPreAuthList",
      {
        apiToken: Cookies.get("SamaId"),
      }
    );
    if (response) {
    let {data}=response
      if (data&&data.success) {
        let {result}=data;
        this.setState({ AllPreAthuCodes:  result});
        // console.log(result)
      }
      else if(data&&!data.success){
        let { error } = data;
        if (error) {
          if (Array.isArray(error) && error.length > 0) {
            this.setState({ error: error[0] });
          } else {
            this.setState({ error: error });
          }
        }

      }
    }
  };
  DeletePreAuthCodeByID = async (e) => {
    e.preventDefault();
    let { PreAthuId} = this.state;

    let response = await axios.put(
      KayanURL+"api/sama/deletePreAuthCode",
      {
        apiToken: Cookies.get("SamaId"),
        data: PreAthuId,
      }
    );

    if (response) {
      let { data } = response;
      if (data.success) {
        this.setState({
          success: true,
          DeletePreAthuCode: false,
        });
        this.getAllCodeList();
      }else{
        let { error } = data;
        if (error) {
          if (Array.isArray(error) && error.length > 0) {
            this.setState({ error: error[0] });
          } else {
            this.setState({ error: error });
          }
        }
      }
    }
  };
  onClickAddNewCode() {
    this.setState({ AddNewCode: true});
  }
  onClickUploadCode() {
    this.setState({ UploadCodes: true });
  }
  render() {
    let {AllPreAthuCodes,UploadCodes,AddNewCode} = this.state;
    let AllPreAuthCodesTable;
    if (AllPreAthuCodes && AllPreAthuCodes.length > 0) {
        AllPreAuthCodesTable = AllPreAthuCodes.map((row, index) => {
        return (
          <tr key={index}>
            <td>{row.code}</td>
            <td>
              <button
                style={{
                  color: "red",
                  border: "none",
                }}
                onClick={() =>
                  this.setState({
                    DeletePreAthuCode: true,
                    PreAthuId: row.id,
                  })
                }
              >
                Delete
              </button>
            </td>
          </tr>
        );
      });
    }
    return (
      <>
      {this.state.DeletePreAthuCode ? (
          <div className="popup">
            <div
              className="form"
              style={{
                width: "50%",
                margin: "10px auto",
                marginLeft:"33%",
                backgroundColor: "white",
              }}
            >
              <form>
                <h4>Are you sure you want to delete this ICD?</h4>
                <Button
                  Style="BS12"
                  Value="Yes"
                  onClick={this.DeletePreAuthCodeByID}
                />
                <Button
                  Style="BS12"
                  Value="No"
                  onClick={() =>
                    this.setState({
                        DeletePreAthuCode: false,
                    })
                  }
                />
              </form>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  textAlign: "right",
                  cursor: "pointer",
                  margin: "0 2%",
                }}
              ></p>
            </div>
          </div>
        ) : null}
        <div className="container">
        <div style={{ marginTop: "20px", textAlign: "center" }}>
            <h3 style={{ color: "green", margin: "1rem 0" }}>
            Pre-Authorization Codes List</h3>
            </div>
            <input
              type="button"
              className="BS1"
              value="Add New Code"
              onClick={this.onClickAddNewCode}
            />

            <input
              type="button"
              className="BS1"
              value="Upload File "
              onClick={this.onClickUploadCode}
            />
         {UploadCodes ? (
            <div className="popup">
            <div
              className="form5"
              style={{
                width: "60%",
                marginLeft: "25%",
                backgroundColor: "white",
              }}
            >
              
              <Uploadpreauthoraization getAllCodeList={this.getAllCodeList}
             
              />
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  cursor: "pointer",
                  textAlign: "right",
                }}
                onClick={() => {
                  this.setState({
                    UploadCodes: false,
                  });
                }}
              >
                {" "}
                Close{" "}
              </p>
            </div>
          </div>
          ) : null}
          {AddNewCode ? (
            <div className="popup">
              <div
                className="form5"
                style={{
                  width: "50%",
                  marginLeft: "33%",
                  backgroundColor: "white",
                }}
              >
                <AddNewCodeOfPreAuth getAllCodeList={this.getAllCodeList}
               
                />
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    cursor: "pointer",
                    textAlign: "right",
                  }}
                  onClick={() => {
                    this.setState({
                      AddNewCode: false,
                    });
                  }}
                >
                  {" "}
                  Close{" "}
                </p>
              </div>
            </div>
          ) : null}
          <hr />
          <div style={{width: "50%", margin: "20px auto"}}>
          {AllPreAthuCodes && AllPreAthuCodes.length > 0 ? (
           <Table
              responsive
              striped
              bordered
              hover
              size="sm"
              style={{ marginTop: "20px" }}
            >
              <thead style={{ background: "#3ab449", color: " white" }}>
                <tr>
                  <th style={{ verticalAlign: "middle",}}>
                    Code
                  </th>
                  <th style={{ verticalAlign: "middle"}}>
                    Action
                  </th>
                  </tr>
              </thead>
              <tbody>{AllPreAuthCodesTable}</tbody>
            </Table>
          ):<h5>there is no records in DB</h5>}
            </div>
        </div>
      </>
    );
  }



  
}

export default PreAuthCodesScreen;
