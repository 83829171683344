import React, { useEffect } from "react";
import styles from "./Styling/home.module.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Carousel } from "react-bootstrap";
// import { dark } from '@material-ui/core/styles/createPalette';
const ImageSlider = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <>
      <Carousel controls={false} className={styles.active}>
        <Carousel.Item interval={null}>
          <img
            className="d-block w-100"
            src={require("./../imgs/services.jpg")}
            alt="First slide"
            style={{ height: 600 + "px", width: "100%" }}
          />
          <Carousel.Caption>
            <div className={styles.header} data-aos="fade-left">
              <h1 className={styles.header1}>LEADERS IN HEALTHCARE SERVICES</h1>
              {/*   <p className={styles.provider}>
                  Kayan Healthcare provides a comprehensive suite of modular
                  software systems tailored to meet the healthcare requirements
                  for the Healthcare and Insurance sector{" "}
                </p> */}

              <p className={styles.provider}>
                We provide a comprehensive suite of modular software systems
                tailored to meet the healthcare requirements for the Healthcare
                and Insurance sector{" "}
              </p>

              <a href="/#services">
                <img alt="sctoll logo" src={require("./../imgs/scroll.webp")} />
              </a>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default ImageSlider;
