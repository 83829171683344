import React, { Component } from "react";
import "../App.css";
import Media from "react-bootstrap/Media";

import NavBar from "./navbar";
import Footer from "./footer";
import { Container, Row, Col, Image } from "react-bootstrap";
class Solutions extends Component {
  render() {
    const mainFeatures = [
      "Primary Market Research",
      "Market Access Consultancy",
      "New Product selection",
      "Healthcare analytics",
      "Benchmarking Reports",
      "Business Intelligence Improvement",
      "Business Performance Management",
      "Projects Management",
      "Marketing Plan Preparation",
      "Geographic Analysis for current and new store locations",
    ];
    return (
      <>
        <div style={{ backgroundColor: "#dce3f4" }}>
          <NavBar />
          <div>
            <div
              style={{
                height: "100px",
                margin: "0px 10px",
                background: "#e7eaf7",
              }}
            >
              <hr />
              <h2 id="solutions" className="ourSol">
                Pharmaceutical Market Research
              </h2>
            </div>
            <Media className="sol_prov_stl">
              <Media.Body>
                <hr />
                <img
                  height={500}
                  className="ml-3"
                  src={require("../imgs/img2.jpg")}
                  alt="Bayan Logo"
                />

                <hr></hr>
                <p>
                  Kayan Solutions have a deep understanding of the drug market
                  and offers the following top quality services to their
                  clients:
                </p>
                <hr />
                <div style={{ display: "flex", width: "100%" }}>
                  <div style={{ width: "50%", overflow: "hidden" }}>
                    <h5>Main features</h5>
                    <ul>
                      {mainFeatures.map((feature, index) => (
                        <>
                          <li>
                            {index + 1}: {feature}
                          </li>
                        </>
                      ))}
                    </ul>
                  </div>
                  <div style={{ width: "50%", overflow: "hidden" }}>
                    <h5>Let us Help you in..</h5>
                    <ul style={{ margin: "0" }}>
                      <li>launching new products</li>
                      <li>
                        ensure opportunities for products already in the market
                        are optimize
                      </li>
                      <li>expand into new markets</li>
                      <li>
                        develop and refine marketing, advertising and
                        communication plans ​
                      </li>
                      <li> all DONE USING  wisely-made business decisions.</li>
                    </ul>
                  </div>
                </div>

                <div style={{ textAlign: "center", marginBottom: "1rem" }}>
                  as well as any other specific services that need to be
                  tailored specifically for our clients.
                </div>
              </Media.Body>
            </Media>
          </div>
          {/* dsds */}
          <Footer />
        </div>
      </>
    );
  }
}

export default Solutions;
