import React, { Component } from "react";
import "../Components/ListView/ListView.css";

class SamaNavBar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { selected } = this.props;
    let visitedStyleItem = { backgroundColor: "#3ab449" };
    let visitedStyleRef = { color: "white" };
    return (
      <ul className="SanadNavBar">
        <li style={selected === "1" ? visitedStyleItem : null}>
          {" "}
          <a
            style={selected === "1" ? visitedStyleRef : null}
            href="/Sama/request"
          >
            Sama Request
          </a>{" "}
        </li>
        {localStorage.getItem("SamaType") &&
        localStorage.getItem("SamaType") === "admin" ? (
          <li style={selected === "2" ? visitedStyleItem : null}>
            {" "}
            <a
              style={selected === "2" ? visitedStyleRef : null}
              href="/Sama/userManagement"
            >
              User Management
            </a>{" "}
          </li>
        ) : null}
      </ul>
    );
  }
}

export default SamaNavBar;
