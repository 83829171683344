import React, { Component } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Spinner from "react-bootstrap/Spinner";
import Form from "../Components/Form/index";
import settings from "../helpers/Settings";
let { KayanURL } = settings;

class KareemUpdateHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: NaN,
    };
  }

  // Read XML File content
  readXMLFile = (reader) => {
    // To Do: 1- Handling error (reader.onerror); 2- clear Input file for each change on input file.
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ requestXML: reader.result });
      }
    };
  };
  handleInputTextChange = ({ target: { name, value, files } }) => {
    this.setState({ responseReady: false });
    this.setState({ fileName: files[0].name });

    switch (name) {
      case "requestFile":
        let reader = new FileReader();
        if (files.length > 0) {
          reader.readAsText(files[0]);
          this.readXMLFile(reader);
        }

        break;

      default:
        //  this.setState({ data: { ...this.state.data, [name]: value } });
        break;
    }
  };
  doRequest = async (event) => {
    event.preventDefault();
    this.setState({ responseReady: false, sent: true });

    // change the end point for Kareem Benefit's
    const data = await axios.put(
      KayanURL + "api/KareemBenefits/updateHistory",
      {
        data: this.state.requestXML,
        apiToken: Cookies.get("KareemId"),
      }
    );
    if (data) {
      let samaResponseXML = data.data;

      let result = {};

      if (samaResponseXML.success === true) {
        result = {
          message: "History has been updated successfully",
          res: true,
        };
      } else {
        result = { message: samaResponseXML.error, res: false };
      }
      this.setState({
        status: result,
        sent: false,
        responseReady: true,
      });
      document.getElementById("requestFile").value = null;
    }
  };
  render() {
    let { responseReady, sent, status } = this.state;
    return (
      <>
        <div className="container">
          <br />
          <h5 style={{ color: "green" }}>Update Existing History</h5>
          <Form
            submitStyle="BS10"
            //linkStyle="Link2"
            labelStyle="BayanLabelStyle"
            onSubmit={this.doRequest}
            linkStyle="Link2"
            dangerStyle="BS10"
            onInputTextChange={this.handleInputTextChange}
            inputsTypes={["file"]}
            elementsValues={["file", "Upload"]}
            elementsNames={["requestFile"]}
          />
          {sent && !responseReady ? (
            <Spinner animation="border" variant="success" />
          ) : null}

          {responseReady && status.res ? (
            <h5 style={{ color: "green" }}>{status.message}</h5>
          ) : responseReady ? (
            status.message.map((err,idx)=>{
              return(
                <h5 style={{ color: "red" }} key={idx}>{err}</h5>
              )
            })
            
          ) :null}
        </div>
      </>
    );
  }
}
export default KareemUpdateHistory;
