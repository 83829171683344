import React, { Component } from "react";
import Cookies from "js-cookie";
import NavBar from "../../Components/navbar";
import AddEmployeeScreen from "../companyViews/addNewEmp";
import AddNewDepartmentScreen from "../companyViews/addNewDepartment";
import AddNewClinicianScreen from "../companyViews/addNewClinicians";
import VerticalNavBar from "../../Components/verticalNavBar";
import Alert from "react-bootstrap/Alert";
import Header from "../../Components/Header";
import axios from "axios";
import UploadExclusionList from "../uploadEcxludedICDsForm";
import UploadNonAllowedPrincipalICDsForm from "../companyViews/uploadNonAllowedPrincipalICDsForm";
import Spinner from "react-bootstrap/Spinner";
import PriceFileUpload from "../priseListUpload";
import BenifitsUpload from "../uploadcustomroles";
import SearchAndUpdateEmployeeInfo from "../companyViews/updateEmployeeInfo";
import AddFacility from "../companyViews/addFacility";
import FlowMgtScreen from "../ProcessManagementScreen";
import settings from "../../helpers/Settings";
let { KayanURL } = settings;
class Company extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screenName: "Add Employee",
    };
  }

  componentDidMount() {
    const { history } = this.props;
    if (!Cookies.get("SamaCompanyId")) {
      history.push(`/Sama/loginAsCompany`, {
        errorMsg: "You need to sign in or sign up",
      });
    } else {
      axios
        .put(KayanURL + "api/sama/getUserState", {
          apiToken: Cookies.get("SamaCompanyId"),
        })
        .then(({ data }) => {
          if (data.success) {
            this.setState({ activeProcess: data.activeProcess });
            localStorage.setItem("SamaCompanyType", data.type);
          }
        });
      this.getAllDepartments();
    }
  }

  getAllDepartments = async () => {
    let result = await axios.put(KayanURL + "api/sama/getAllDepartments", {
      apiToken: Cookies.get("SamaCompanyId"),
    });

    let departments = [];
    if (result) {
      if (result.data && result.data.success) {
        departments = result.data.departments;
      }
    }
    console.log(departments);
    this.setState({ departments });
  };

  handleSwitch = (screenName) => {
    this.setState({ screenName });
  };

  handleInputTextChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      data: { ...this.state.data, [name]: value },
    });
  };

  onChangeRulesStatus = (e) => {
    //on click on each check box
    this.setState({ isUpdated: false });
    let checkBoxId = e.target.id;
    let activeProcess = this.state.activeProcess;
    activeProcess[checkBoxId] = !activeProcess[checkBoxId];
    this.setState({ activeProcess });
  };
  onUpdateRulesStatus = (e) => {
    //on click on update Button in Process Flow Management screen
    let activeProcess = this.state.activeProcess;
    axios
      .post(KayanURL + "api/sama/updateProcessState", {
        apiToken: Cookies.get("SamaId"),
        ProcessStatus: JSON.stringify(activeProcess),
      })
      .then(({ data }) => {
        let { success } = data;
        //if success veiw the Success Alert div
        if (success) {
          this.setState({ isUpdated: true });
          document.getElementById("Alert").scrollIntoView();
        }
      });
  };

  onSubmit = async (e) => {
    this.setState({ sent: true });
    e.preventDefault();
    let user = this.state.data;
    user["apiToken"] = Cookies.get("SamaCompanyId");

    let result = await axios.put(
      KayanURL + "api/sama/employee/addNewEmployee",
      user
    );
    result = result["data"];

    if (result) {
      this.setState({
        success: result.success,
        error: result.error,
        finished: true,
        sent: false,
      });
    }
  };

  handleInputTextChangeDepartmentName = (e) => {
    this.setState({ departmentName: e.target.value, finished: false });
  };

  onAddNewDept = async (e) => {
    this.setState({ addNewDepartmentrequestSent: true, finished: false });
    e.preventDefault();
    let { departmentName } = this.state;
    let response = await axios.post(KayanURL + "api/sama/AddDepartment", {
      departmentName,
      apiToken: Cookies.get("SamaCompanyId"),
    });

    if (response) {
      this.setState({ addNewDepartmentrequestSent: false });
      if (response.data.success) {
        this.getAllDepartments();
        this.setState({ addNewDepartmentrequestSuccess: true });
      } else {
        this.setState({
          addNewDepartmentrequestSuccess: false,
          addNewDepartmentrequestError: response.data.error,
        });
      }
      this.setState({ finished: true });
    }
  };

  onSelectDepartmentName = (e) => {
    console.log(e.target.value);
    this.setState({ departmentId: e.target.value });
  };

  onChangeClinicianId = (e) => {
    this.setState({ clinicianId: e.target.value });
  };

  onAddNewClinician = async (e) => {
    e.preventDefault();
    this.setState({ addNewClinicianrequestSent: true, finished: false });

    let result = await axios.post(KayanURL + "api/sama/addNewClinician", {
      apiToken: Cookies.get("SamaCompanyId"),
      clinicianId: this.state.clinicianId,
      departmentId: this.state.departmentId,
    });

    if (result) {
      this.setState({ addNewClinicianrequestSent: false });
      if (result.data && result.data.success) {
        this.setState({ addNewClinicianrequestSuccess: true });
      } else if (result.data && !result.data.success) {
        this.setState({
          addNewClinicianrequestSuccess: false,
          addNewClinicianrequestError: result.data.error,
        });
      }
      this.setState({ finished: true });
    }
  };

  render() {
    let {
      screenName,
      success,
      error,
      finished,
      sent,
      activeProcess,
      isUpdated,
    } = this.state;

    return (
      <>
        <NavBar />

        <Header
          name={localStorage.getItem("SamaCompanyUser")}
          usertype="company"
          history={this.props.history}
          projectType="SamaCompanyAdmin"
        />

        <VerticalNavBar
          operations={[
            "Add Employee",
            "Add New Department",
            "Add New Clinician",
            "Upload Price List",
            "Upload Benefits",
            "Upload ICD Exclusion List",
            "Upload Not Allowed Principal Diagnosis List",
            "Update Employee Info",
            "Add Facility",
            "Change Settings",
          ]}
          handleSwitch={this.handleSwitch}
          selected={this.state.screenName}
        />
        <div
          style={{
            marginLeft: "20%",
            width: "75%",
          }}
        >
          {screenName == "Add Employee" ? (
            <div>
              {" "}
              <AddEmployeeScreen
                handleInputTextChange={this.handleInputTextChange}
                onSubmit={this.onSubmit}
              />
              {!finished && sent ? (
                <Spinner animation="border" variant="success" />
              ) : finished && success ? (
                <h5 style={{ color: "rgb(58 180 73)" }}>
                  Employee was added successsfully
                </h5>
              ) : finished && error ? (
                <h5 style={{ color: "red" }}>{error}</h5>
              ) : null}
            </div>
          ) : screenName == "Upload Price List" ? (
            <div style={{ marginTop: "-250px" }}>
              {" "}
              <PriceFileUpload usertype="company" />
            </div>
          ) : screenName == "Upload Benefits" ? (
            <div style={{ marginTop: "-250px" }}>
              {" "}
              <BenifitsUpload usertype="company" />
            </div>
          ) : screenName == "Update Employee Info" ? (
            <SearchAndUpdateEmployeeInfo />
          ) : screenName == "Add Facility" ? (
            <AddFacility />
          ) : screenName == "Change Settings" ? (
            <div>
              <div>
                {isUpdated ? (
                  <Alert id="Alert" variant="success">
                    Process flow status updated Successfully
                  </Alert>
                ) : null}
              </div>
              <div style={{ marginTop: "-270px" }}>
                <FlowMgtScreen
                  activeProcess={activeProcess}
                  onChange={this.onChangeRulesStatus}
                  onClick={this.onUpdateRulesStatus}
                />
              </div>
            </div>
          ) : screenName == "Upload ICD Exclusion List" ? (
            <div style={{ marginTop: "-250px" }}>
              <UploadExclusionList />
            </div>
          ) : screenName == "Upload Not Allowed Principal Diagnosis List" ? (
            <div style={{ marginTop: "-250px" }}>
              <UploadNonAllowedPrincipalICDsForm />
            </div>
          ) : screenName == "Add New Department" ? (
            <div style={{ marginTop: "-300px" }}>
              <AddNewDepartmentScreen
                handleInputTextChange={this.handleInputTextChangeDepartmentName}
                onSubmit={this.onAddNewDept}
              />

              {!this.state.finished &&
              this.state.addNewDepartmentrequestSent ? (
                <Spinner animation="border" variant="success" />
              ) : null}

              {this.state.finished &&
              this.state.addNewDepartmentrequestSuccess ? (
                <h5 style={{ color: "green" }}>
                  The department was added successfully
                </h5>
              ) : this.state.finished &&
                !this.state.addNewDepartmentrequestSuccess ? (
                <h5 style={{ color: "red" }}>
                  {this.state.addNewDepartmentrequestError}
                </h5>
              ) : null}
            </div>
          ) : screenName == "Add New Clinician" ? (
            <div style={{ marginTop: "-300px" }}>
              <AddNewClinicianScreen
                departments={this.state.departments}
                onSelectDepartmentName={this.onSelectDepartmentName}
                handleInputTextChange={this.onChangeClinicianId}
                onSubmit={this.onAddNewClinician}
              />

              {!this.state.finished && this.state.addNewClinicianrequestSent ? (
                <Spinner animation="border" variant="success" />
              ) : null}

              {this.state.finished &&
              this.state.addNewClinicianrequestSuccess ? (
                <h5 style={{ color: "green" }}>
                  The clinician was added successfully
                </h5>
              ) : this.state.finished &&
                !this.state.addNewClinicianrequestSuccess ? (
                <h5 style={{ color: "red" }}>
                  {this.state.addNewClinicianrequestError}
                </h5>
              ) : null}
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export default Company;
