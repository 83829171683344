import React, { Component } from "react";
import NavBar from "./../Components/navbar";
import Header from "./../Components/Header";

//import "../App.css"

import Cookies from "js-cookie";

class RulesActivation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      relation1: null,
    };

    // this.doRequest = this.doRequest.bind(this)
  }

  componentDidMount = () => {
    const { history } = this.props;

    if (Cookies.get("user") == null) {
      var errorMsg = "You need to sign in or sign up";
      history.push(`/loginAsEmployee`, errorMsg);
    } else {
      var name = Cookies.get("user");
      this.setState({ username: name });
    }
  };

  handleCheckboxChange = (event) => {
    //handle change

    //this.setState({value: event.target.value});
    this.setState({ [event.target.name]: event.target.checked });
    console.log(event.target.name);
    console.log(event.target.checked);
  };
  /*this.setState({
          
          checked: event.target.checked 
        
        })*/

  render() {
    return (
      <>
        <NavBar />
        <Header
          name={this.state.username}
          usertype="employee"
          history={this.props.history}
        />

        <div style={{ height: "60px" }}>
          <form>
            <label>
              <input
                type="checkbox"
                name="relation1"
                defaultChecked={this.state.relation1}
                onChange={this.handleCheckboxChange}
              />

              <span>Check Text</span>
            </label>
          </form>
        </div>
      </>
    );
  }
}

export default RulesActivation;
