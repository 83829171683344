import React, { Component } from "react";
import axios from "axios";
import NavBar from "./../Components/navbar";
import Footer from "./../Components/footer";
import Cookies from "js-cookie";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

import settings from "../helpers/Settings";
let { KayanURL } = settings;

class loginEmployeeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      adminRole: null,
      Company: "",
      username: "",
      password: "",
      error: null,
      serverError: null,
      usertype: null,
      isLogin: false,
      errorMsg: "",
      loginStatus: false,
    };

    this.handelLogIn = this.handelLogIn.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  checkState = () => {
    const { history } = this.props;
    axios
      .get(KayanURL + "api/user/state", { apiToken: Cookies.get("id") })
      .then(({ data }) => {
        if (data.success) {
          history.push(`/${data.usertype}`);
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(() => {
        this.setState({ serverError: "There are an Server error" });
      });
  };

  componentDidMount = () => {
    //this.checkState();
    const { location,history } = this.props;
    let errMsg=location.errMsg
    if (!errMsg && Cookies.get("id") != null) {
      history.push(`/employee`);
    }
    else if(errMsg){
      this.setState({errorMsg:errMsg[0]})
    }

  };

  //handle change
  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  //handle login
  handelLogIn = (e) => {
    e.preventDefault();
    this.setState({ loginStatus: true });

    this.setState({ error: null });
    const { history } = this.props;

    if (this.state.Company === "kayan") {
      console.log("admin ");
      this.setState({ usertype: "admin" });

      axios
        .post(KayanURL + "api/admin/login", {
          username: this.state.username.trim(),
          password: this.state.password.trim(),
        })
        .then(({ data }) => {
          this.setState({ errorMsg: data["error"] });
          if (data.success) {
            console.log(data);
            Cookies.set("BayanAdminID", data.token);

            history.push({
              pathname: "/admin",
            });
          } else {
            console.log("Error: " + data.error);
            this.setState({
              error: data.error,
              inputsHaveErrors: data.inputsHaveErrors,
            });
            this.setState({ loginStatus: false });
          }
        })
        .catch((e) => {
          console.log("Here is the err" + e);
          this.setState({ serverError: "There are an Server error" });
        });
    } else {
     
      axios
        .post(KayanURL + "api/employee/login", {
          companyname: this.state.Company.trim(),
          username: this.state.username.trim(),
          password: this.state.password.trim(),
        })
        .then(({ data }) => {
         
          this.setState({ errorMsg: data["error"] });
          if (data.success) {
            this.setState({ loginStatus: false });

            localStorage.setItem("type", data.type);
            //localStorage.setItem("bayanSchema", data.schema);

            Cookies.set("id", data.token);

            history.push({
              pathname: "/employee",
            });
          } else {
            this.setState({
              error: data.error,
              inputsHaveErrors: data.inputsHaveErrors,
            });
            this.setState({ loginStatus: false });
          }
        })
        .catch(() => {
          this.setState({ serverError: "There are an Server error" });
        });
    }
  };

  render() {
    const { history } = this.props;

    const loginStatus = this.state.loginStatus;

    return (
      <>
        <NavBar />

        {this.state.errorMsg ? (
          <div
            style={{
              backgroundColor: "rgb(67, 183, 81)",
              color: "#ffffff",
              padding: "10px",
            }}
          >
            {this.state.errorMsg}
          </div>
        ) : null}

        <div id="main-registration-container" className="registerDiv">
          <div>
            <form onSubmit={this.handelLogIn}>
              <div className="ToMargin">
                <label>Company Name</label>
                <input
                  type="text"
                  placeholder="Company Name"
                  value={this.state.Company}
                  className="form-control"
                  required
                  onChange={(event) => {
                    
                    this.setState({ Company: event.target.value });
                   console.log(event.target.value);
                   
                  }}
                />
              </div>

              <div className="ToMargin">
                <label>User Name</label>

                <input
                  type="text"
                  placeholder="User Name"
                  required
                  className="form-control"
                  onChange={(event) => {
                    this.setState({ username: event.target.value });
                   
                  }}
                />
              </div>

              <div className="ToMargin">
                <label>Password</label>
                <input
                  type="password"
                  placeholder="Password"
                  className="form-control"
                  required
                  onChange={(event) => {
                    this.setState({ password: event.target.value });
                    console.log(event.target.value);
                  }}
                />
              </div>

              {loginStatus === false ? (
                <input
                  type="submit"
                  style={{
                    backgroundColor: "rgb(67, 183, 81)",
                    color: "#ffffff",
                    border: "none",
                    padding: "5px 38px",
                    marginTop: "20px",
                    borderRadius: "5px",
                  }}
                  value="Login"
                  className="reg"
                />
              ) : (
                <Button
                  style={{
                    backgroundColor: "#43b751",
                    color: "white",
                    marginTop: "20px",
                  }}
                  variant="light"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Loading...
                </Button>
              )}
            </form>
          </div>
        </div>

        <div style={{ height: "70px" }}></div>

        <Footer />
      </>
    );
  }
}

export default loginEmployeeForm;
