import React, { Component } from "react";

//import Container from "react-bootstrap/Container";
import "../App.css";

class SamaDoc extends Component {
  render() {
    return (
      //TODO: ClassName
      <div className="BayanDoc">
        <div className="container">
          <h2>Sama</h2>
          <p style={{ textAlign: "left" }}>
            SAMA is a technical business intelligence tool that provides logical
            references to claims based on various clinical and technical
            parameters. SAMA reads the claims and provides outcomes based on
            various audit references and identifies the claims that has potential
            risk in claim processing. In addition, SAMA provides interactive
            information on each claim based on the content of the claims. this includes:
             • Provides potential outcome of the submitted claims as received.
             • Provides the claim information on basis of technical  issues followed by Clinical and Coding
            • Provides suggestions to   improve the claim quality and maximizes payments
          
          </p>
          <p style={{ textAlign: "left" }}>....</p>

          <h2>Solution features</h2>

          <ol className="feautersList">
            <li>
              A
              <ul>
                <li>L</li>
                <li>A</li>
                <li>'</li>
                <li>A</li>
                <li>B</li>
              </ul>
            </li>
            <li>
              U
              <ul>
                <li>S</li>
                <li>H</li>
                <li>O</li>
                <li>M</li>
              </ul>
            </li>
            <li>
              E
              <ul>
                <li>R</li>
                <li>...</li>
                <li>...</li>
                <li>...</li>
                <li>...</li>
              </ul>
            </li>
            <li>
              ...
              <ul>
                <li>...</li>
                <li>...</li>
              </ul>
            </li>
            <li>
              ...
              <ul>
                <li>...</li>
                <li>...</li>
                <li>...</li>
                <li>...</li>
              </ul>
            </li>
            <li>
              ...
              <ul>
                <li>...</li>
                <li>...</li>
                <li>...</li>
                <li>...</li>
                <li>...</li>
                <li>...</li>
              </ul>
            </li>

            <li>
              ...
              <ul>
                <li>...</li>
                <li>...</li>
                <li>...</li>
              </ul>
            </li>

            <li>
              {" "}
              ...
              <ul>
                <li>...</li>
                <li>...</li>
                <li>...</li>
                <li>...</li>
                <li>...</li>
              </ul>
            </li>
          </ol>

          <h2>Schema Definition</h2>
          <p style={{ textAlign: "left" }}>
            It will depend mainly on generalized transaction schema requests
            which will be represented in xml. that XML will consist of the flowing
            fields:
          </p>
          <table class="table" style={{ textAlign: "left" }}>
            <thead>
              <tr>
                <th>Field Name</th>
                <th>Type</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&lt;Request&gt;</td>
                <td>Structure element Required</td>
                <td>
                  It's the main root of the schema that will contains all the
                  patient visits encounters
                </td>
              </tr>

              <tr>
                <td>&lt;Encounter&gt;</td>
                <td>Structure element Required </td>
                <td>
                  It represents all the patient visit details, the encounter
                  could be repeated in the same request depends on the
                  representation need of the provider
                </td>
              </tr>

              <tr>
                <td>&lt;Date&gt;</td>
                <td>Data element Format: dd/mm/yyyy required</td>
                <td>
                  The encounter\transaction date if preferred to be specified
                </td>
              </tr>

              <tr>
                <td>&lt;Type&gt;</td>
                <td>Data element required</td>
                <td>1: Inpatient 2: Outpatient Etc…</td>
              </tr>

              <tr>
                <td>&lt;ProviderId&gt;</td>
                <td>Data element Optional </td>
                <td>The registered license of the provider</td>
              </tr>

              <tr>
                <td>&lt;PayerId&gt;</td>
                <td>Data element Optional</td>
                <td>The registered license of the Insurance or TPA</td>
              </tr>

              <tr>
                <td>&lt;Patient&gt;</td>
                <td>Structure elementrequired</td>
                <td>
                  This is the parent for all the required patient details, and
                  it's used one time per encounter since the encounter covers
                  only one patient
                </td>
              </tr>

              <tr>
                <td>&lt;PatientId&gt;</td>
                <td>Data element Optional</td>
                <td>The patient member Id Included in the patient field</td>
              </tr>

              <tr>
                <td>&lt;BirthDate&gt;</td>
                <td>Data element Format: dd/mm/yyyy required</td>
                <td>
                  The patient date of birth Included in the patient fields
                </td>
              </tr>

              <tr>
                <td>&lt;Gender&gt;</td>
                <td>Data element required</td>
                <td>
                  <p>The gender of the patient with the possibilities</p>
                  <p>M: male</p>
                  <p>F: female</p>
                  Included in the patient fields
                </td>
              </tr>

              <tr>
                <td>&lt;Weight&gt;</td>
                <td>Data element Optional</td>
                <td>
                  It's an decimal value in kilogram represents the patient
                  weight{" "}
                </td>
              </tr>

              <tr>
                <td>&lt;Diagnosis&gt;</td>
                <td>Structure element required</td>
                <td>
                  It's the main tag of the diagnosis that will include multiple
                  codes inside it specifying which one is the principal code.
                </td>
              </tr>

              <tr>
                <td>&lt;Code "Principal"&gt;</td>
                <td>Data element required </td>
                <td>
                  If the diagnosis is principal then should attach the double
                  quoted word "Principal" as attribute to the code field but if
                  secondary then no need for it, the diagnosis could be multiple
                  inside the parent tag &lt;Diagnosis&gt;
                </td>
              </tr>

              <tr>
                <td>&lt;Activity&gt;</td>
                <td>Data element required </td>
                <td>
                  It's the main element that will include all the encounter
                  activity codes with the required attributes for each type of
                  activities
                </td>
              </tr>

              <tr>
                <td>
                  <p>&lt;Code source="5" Frequency="3" Duration="30"&gt;</p>
                  <p>&lt;Code source="3" Frequency="3"&gt;</p>
                  <p>&lt;Code source="6" ToothNumber="3"&gt;</p>
                </td>
                <td>Data element </td>
                <td>
                  <p>
                    Each activity in the activities list will has a
                    corresponding code and base on the corresponding code will
                    decide which attributes to attach
                    <p>3 = CPT</p>
                    <p>4 = HCPCS</p>
                    <p>5 = Drug</p>
                    <p>6 = Dental</p>
                    <p>8 = Service Code</p>
                    <p>9 = DRG</p>
                    <p>10 = Scientific Code</p>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>

          <h2>Response</h2>

          <p style={{ textAlign: "left" }}>
            The response will contain all the alerted checks based on the
            millions of combinations , and they will be represented in a simple
            easy to  read error message per the below formats either on the
            level of structure or the level of clinical checks:
          </p>
          <pre>
            {`
       <Validation-Errors> 

        <Error> 

            <Message> 

              Element Code, attribute Source: The attribute Source is not allowed. 

            </Message> 

            <Line>16</Line> 

        </Error> 

        <Error> 

          <Message> 

           Element Code, attribute Frequency: The attribute Frequency is not allowed. 

          </Message> 

          <Line>16</Line> 

        </Error> 

        <Error> 

          <Message> 

            Element Code, attribute Type: The attribute Type is not allowed. 

          </Message> 

          <Line>21</Line> 

        </Error> 

        <Error> 

          <Message> 

            Element Code: The attribute Source is required but missing. 

          </Message> 

          <Line>21</Line> 

        </Error> 

</Validation-Errors> 
 
       `}
          </pre>

          <h2>Request XML Structure</h2>
          <pre>
            {`
       <xs:schema
    xmlns:xs="http://www.w3.org/2001/XMLSchema" elementFormDefault="qualified">
    <xs:simpleType name="DateForm">
        <xs:restriction base="xs:string">
            <xs:minLength value="0" />
            <xs:pattern value="\d{2}/\d{2}/\d{4}" />
        </xs:restriction>
    </xs:simpleType>
    <xs:simpleType name="Gender">
        <xs:restriction base="xs:NCName">
            <xs:enumeration value="M" />
            <xs:enumeration value="F" />
        </xs:restriction>
    </xs:simpleType>
    <xs:element name="Request">
        <xs:complexType>
            <xs:sequence>
                <xs:element minOccurs="0" maxOccurs="1" name="Encounter">
                    <xs:complexType>
                        <xs:sequence>
                            <xs:element minOccurs="1" maxOccurs="1" name="Date" type="DateForm"/>
                            <xs:element minOccurs="1" maxOccurs="1" name="Type" type="xs:integer"/>
                            <xs:element minOccurs="1" maxOccurs="1" name="ProviderId" type="xs:NCName"/>
                            <xs:element minOccurs="1" maxOccurs="1" name="PayerId" type="xs:NCName"/>
                            <xs:element minOccurs="1" maxOccurs="1" name="Patient">
                                <xs:complexType>
                                    <xs:sequence>
                                        <xs:element minOccurs="1" maxOccurs="1" name="PatientId" type="xs:NCName"/>
                                        <xs:element minOccurs="1" maxOccurs="1" name="BirthDate" type="DateForm"/>
                                        <xs:element minOccurs="1" maxOccurs="1" name="Gender" type="Gender"/>
                                        <xs:element minOccurs="1" maxOccurs="1" name="Weight" type="xs:decimal"/>
                                    </xs:sequence>
                                </xs:complexType>
                            </xs:element>
                            <xs:element minOccurs="1" maxOccurs="1" name="Diagnosis">
                                <xs:complexType>
                                    <xs:sequence>
                                        <xs:element minOccurs="1" maxOccurs="unbounded" name="Code">
                                            <xs:complexType>
                                                <xs:simpleContent>
                                                    <xs:extension base="xs:NCName">
                                                        <xs:attribute name="Type" type="xs:NCName"/>
                                                    </xs:extension>
                                                </xs:simpleContent>
                                            </xs:complexType>
                                        </xs:element>
                                    </xs:sequence>
                                </xs:complexType>
                            </xs:element>
                            <xs:element minOccurs="1" maxOccurs="1" name="Activity">
                                <xs:complexType>
                                    <xs:sequence>
                                        <xs:element minOccurs="1" maxOccurs="unbounded" name="Code">
                                            <xs:complexType>
                                                <xs:simpleContent>
                                                    <xs:extension base="xs:NCName">
                                                        <xs:attribute name="Source" type="xs:integer" use="required"/>
                                                        <xs:attribute name="Duration" type="xs:integer"/>
                                                        <xs:attribute name="Frequency" type="xs:integer"/>
                                                        <xs:attribute name="ToothNumber" type="xs:integer"/>
                                                    </xs:extension>
                                                </xs:simpleContent>
                                            </xs:complexType>
                                        </xs:element>
                                    </xs:sequence>
                                </xs:complexType>
                            </xs:element>
                        </xs:sequence>
                    </xs:complexType>
                </xs:element>
            </xs:sequence>
        </xs:complexType>
    </xs:element>
</xs:schema>


       `}
          </pre>

          <h2>Request XML Sample</h2>
          <pre>
            {`
        
<Request
xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
  <Encounter>
      <Date>05/07/2018</Date>
      <Type>1</Type>
      <ProviderId>prov32434</ProviderId>
      <PayerId>pay23456</PayerId>
      <Patient>
          <PatientId>p1212</PatientId>
          <BirthDate>05/07/2018</BirthDate>
          <Gender>M</Gender>
          <Weight>12.55</Weight>
      </Patient>
      <Diagnosis>
          <Code Type="Principal">k1.0</Code>
          <Code>e0.01</Code>
          <Code>k2.0</Code>
      </Diagnosis>
      <Activity>
          <Code Source="3" Frequency="3">cpt1234</Code>
          <Code Source="5" Frequency="3" Duration="30">drug453456</Code>
          <Code Source="6" ToothNumber="3">cpt1234</Code>
      </Activity>
  </Encounter>
</Request>

        `}
          </pre>
        </div>
      </div>
    );
  }
}

export default SamaDoc;
