import React from "react";
import { Pie, Bar } from "react-chartjs-2";
import settings from "../helpers/Settings";
import axios from "axios";
import Cookies from "js-cookie";
//import ReactApexChart from "ReactApexChart";
//import { CSVLink } from "react-csv";
import { Multiselect } from "multiselect-react-dropdown";
//import Chart from "react-apexcharts";
import Controls from "../Components/controls/Controls";

//import ApexCharts from "apexcharts";
import {
  Grid,
  InputLabel,
  Select as MuiSelect,
  /*   MenuItem,
  FormControl,
  Input, */
} from "@material-ui/core";
/////
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

let { KayanURL } = settings;

class SetupOptionScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chart: [],
      price: [],
      rows: [],
      response: [],
      age: [],
      listTable: undefined,
    };

    //  this.getPayersList();

    this.handleFromDate = this.handleFromDate.bind(this);
    this.onScelectPayerName = this.onScelectPayerName.bind(this);
    this.onRemoveFacilityId = this.onRemoveFacilityId.bind(this);
    this.onSelectFacilityId = this.onSelectFacilityId.bind(this);
    this.handleInputTextChange = this.handleInputTextChange.bind(this);
  }

  componentDidMount = () => {
    this.getClaimsStatusChart();
    this.getAgeChart();
    this.getPriceChart();
    let payers = localStorage.getItem("payers");
    if (!payers) {
      this.getPayersList();
    } else {
      this.setState({ payers: JSON.parse(payers) });
    }
  };

  async getPayersList() {
    let response = await axios.put(KayanURL + "api/sama/getPayers", {
      apiToken: Cookies.get("SamaId"),
    });

    if (response) {
      let { data } = response;
      if (data && data.success) {
        let { payers } = data;

        this.setState({ payers });
        localStorage.setItem("payers", payers);
      }
    }
  }

  getAgeChart = async () => {
    this.setState({ ageFlag: false });
    const data = await axios.put(KayanURL + "api/sama/getAge", {
      apiToken: Cookies.get("SamaId"),
      payerid: this.state.payer_id,
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
    });
    if (data) {
      let response = data["data"];
      if (response) {
        this.setState({ age: response, ageFlag: true });
      }

      let { res } = response;
      const labels = Object.keys(res);

      let dataSet1 = [];
      let dataSet2 = [];
      let dataSet3 = [];
      let dataSet4 = [];
      let dataSet5 = [];
      let dataSet6 = [];

      labels.forEach((label, index) => {
        let info = res[[label]];

        dataSet1.push(info["dataset1"]);
        dataSet2.push(info["dataset2"]);
        dataSet3.push(info["dataset3"]);
        dataSet4.push(info["dataset4"]);
        dataSet5.push(info["dataset5"]);
        dataSet6.push(info["dataset6"]);
      });

      const dataNew = {
        labels: labels,
        datasets: [
          {
            label: "1-30 Days",
            data: dataSet1,
            backgroundColor: "#0d9e33",
          },
          {
            label: "31-45 Days",
            data: dataSet2,
            backgroundColor: "#0d759e",
          },
          {
            label: "46-60 Days",
            data: dataSet3,
            backgroundColor: "#9e0d9e",
          },
          {
            label: "61-90 Days",
            data: dataSet4,
            backgroundColor: "#9e0d4e",
          },
          {
            label: "91-120 Days",
            data: dataSet5,
            backgroundColor: "yellow",
          },
          {
            label: "121-More Days",
            data: dataSet6,
            backgroundColor: "#9e5d0d",
          },
        ],
      };

      const config = {
        type: "bar",
        data: dataNew,
        options: {
          indexAxis: "y",
          plugins: {
            title: {
              display: true,
              text: "Submission Aging Chart",
            },
          },
          responsive: true,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
        },
      };

      const myChart = new ChartJS(document.getElementById("myChart"), config);
    }
  };

  getPriceChart = async () => {
    const data = await axios.put(KayanURL + "api/sama/getPrice", {
      payer_id: this.state.payer_id,
      enc_facility_id: this.state.facilityId,
      activities_code: this.state.activities_code,
      clinicion_id: this.state.clinicion_id,
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,

      apiToken: Cookies.get("SamaId"),
    });

    if (data) {
      if (data["data"].success) {
        this.setState({ price: data["data"].amounts, priceFlag: true });
        //  console.log(this.state.price.activityCount);
      }
    }
  };

  onRemoveFacilityId(selectedList, removedItem) {
    let facilityIdValues = [];
    selectedList.forEach((item) => {
      if (item.value != removedItem.value) {
        facilityIdValues.push(item.value);
      }
    });

    this.setState({ facilityId: facilityIdValues });
  }
  onClickSearch = async () => {
    this.getClaimsStatusChart();
    this.getAgeChart();
  };
  getClaimsStatusChart = async () => {
    this.setState({ ErrorMessgae: true });

    const res = await axios.put(KayanURL + "api/sama/chart", {
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
      payerid: this.state.payer_id,
      apiToken: Cookies.get("SamaId"),
    });
    if (res) {
      if (res["data"]) {
        let response = res["data"];
        this.setState({ response: res["data"] });
        this.setState({ chart: response });

        //////////
        let values = Object.values(response);
        //    console.log(values);

        let checkValues = values.filter((value) => value > 0);

        if (checkValues && checkValues.length == 0) {
          this.setState({
            ErrorMessgae: "There are no results to be shown",
          });
        }
      }
    }
  };
  downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([this.state.listTable], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "request.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  onSelectFacilityId(selectedList, selectedItem) {
    let facilityIdValues = [];
    selectedList.forEach((item) => {
      facilityIdValues.push(item.value);
    });

    this.setState({ facilityId: facilityIdValues });
  }
  handleFacilityIdChange = (e) => {
    this.setState({ facilityId: e.target.value });
  };
  onScelectPayerName({ target: { name, value } }) {
    this.setState({ payer_id: value });
  }
  handleFacilityIdChange = (e) => {
    this.setState({ facilityId: e.target.value });
  };
  onRemoveFacilityId(selectedList, removedItem) {
    let facilityIdValues = [];
    selectedList.forEach((item) => {
      if (item.value != removedItem.value) {
        facilityIdValues.push(item.value);
      }
    });

    this.setState({ facilityId: facilityIdValues });
  }
  handleFacilityIdChange = (e) => {
    this.setState({ facilityId: e.target.value });
  };
  handleInputTextChange = (e) => {
    this.setState({ activities_code: e.target.value });
  };
  handleInputTextChangeClinicion = (e) => {
    this.setState({ clinicion_id: e.target.value });
  };

  handleFromDate({ target: { name, value } }) {
    if (value) {
      let date_time = value.split("T");
      if (date_time) {
        let date = date_time[0]; // mm-dd-yyyy -----> dd/mm/yyyy
        date = date.replace(/\-/g, "/").split("/");
        let date2 = date[0] + "/" + date["2"] + "/" + date["1"];
        date2 = date2.split("/").reverse().join("/");

        let time = date_time[1];
        let finalDateTime = date2 + " " + time;

        if (name == "dateFrom") {
          this.setState({ dateFrom: finalDateTime });
        } else if (name == "dateTo") {
          this.setState({ dateTo: finalDateTime });
        }
      }
    }
  }

  mapping(status) {
    let statusString = "";
    switch (status) {
      case "X0":
        statusString = "New Claim";

        break;
      case "X1":
        statusString = "SAMA Validated";

        break;
      case "X2":
        statusString = "PO Validated";

        break;
      case "X3":
        statusString = "SAMA and PO validated";

        break;
      case "X4":
        statusString = "RA with issues";

        break;
      case "X5":
        statusString = "RA Paid";

        break;
      case "X6":
        statusString = "Finished due to exceeding submission allowed value";

        break;
      case "X7":
        statusString = "Partially Paid";

        break;
      case "X8":
        statusString = "Fully Rejected";

        break;
      case "X9":
        statusString = "Claims ready to be submitted";

        break;
      case "X10":
        statusString = "Correction Resubmission";

        break;
      case "X11":
        statusString = "Internal Complaint Resubmission";

        break;
      case "X12":
        statusString = "Finished with Partially Paid";

        break;
      case "X13":
        statusString = "Finished with Fully Rejected";

        break;

      case "X14":
        statusString = "SAMA validated with issues";

        break;

      default:
        break;
    }

    return statusString;
  }
  render() {
    let { payers } = this.state;

    let payersList;
    if (payers && payers.length > 0) {
      payersList = payers.map((payer) => {
        return (
          <option value={payer["eclaimlink_id"]}>
            {payer["eclaimlink_id"]}
          </option>
        );
      });
    }

    let { chart, age, ageFlag, price, priceFlag } = this.state;
    let { ErrorMessgae, response, flag } = this.state;

    //let { price } = this.state;

    var SamaHeader;

    SamaHeader = [
      { label: "Status", key: "Status" },
      { label: "Number Of Claim", key: "NumberOfClaim" },
    ];

    let data = [];
    if (chart && Object.keys(chart).length > 0) {
      let keys = Object.keys(chart);

      keys.forEach((k) => {
        let status = this.mapping(k);

        data.push({ Status: status, NumberOfClaim: chart[k] });
      });
    }
    let license = localStorage.getItem("UserLicenses"); /* .split(",") */
    let FacilityIdOption = [];
    if (license) {
      license = license.split(",");
      license.forEach((l) => {
        FacilityIdOption.push({ name: l, value: l });
      });
    }

    return (
      <div className="container">
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-sm-4">
            {" "}
            <div className="row">
              <div className="col-sm-2">
                {" "}
                <InputLabel style={{ marginTop: "14px", color: "#212529" }}>
                  From
                </InputLabel>
              </div>
              <div className="col-sm-10">
                <input
                  style={{ padding: "10px" }}
                  name="dateFrom"
                  onChange={this.handleFromDate}
                  type="datetime-local"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            {" "}
            <div className="row">
              <div className="col-sm-2">
                {" "}
                <InputLabel style={{ marginTop: "14px", color: "#212529" }}>
                  To
                </InputLabel>
              </div>
              <div className="col-sm-10">
                <input
                  style={{ padding: "10px" }}
                  name="dateTo"
                  onChange={this.handleFromDate}
                  type="datetime-local"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="row">
              <div className="col-sm-10">
                <div>
                  <InputLabel style={{ marginTop: "14px", color: "#212529" }}>
                    Payer ID
                  </InputLabel>

                  <MuiSelect
                    variant="outlined"
                    style={{
                      width: "70%",
                      /*  marginBottom: "0.7rem", */
                      padding: "15px !important",
                    }}
                    onChange={this.onScelectPayerName}
                    value={this.state.payer_id ? this.state.payer_id : ""}
                  >
                    {payersList}
                  </MuiSelect>
                </div>
              </div>
              <div className="col-sm-2">
                <input
                  type="submit"
                  value="Search"
                  //  className="GenerateBtnStyle11"
                  style={{
                    backgroundColor: "#026f7d",
                    color: "white",
                    padding: "13px 30px",
                    border: "none",
                    borderRadius: "10px",
                    marginTop: "5px",
                  }}
                  onClick={this.onClickSearch.bind(this)}
                  name="name"
                />
              </div>
            </div>
          </div>
        </div>
        {/* ------------------------------ */}
        <div
          className="row"
          style={{
            borderBottom: "1px solid #00000030",
            paddingBottom: "15px",
            marginBottom: "10px",
          }}
        >
          <div className="col-sm-6" style={{ marginTop: "100px" }}>
            {" "}
            <div className="row">
              <Pie
                data={{
                  labels: [
                    "New Claim",
                    "SAMA validated with issues",
                    "SAMA Validated",
                    "Ready for submission",
                    "Submitted to PO",
                    "Fully Rejected",
                    "Fully Paid",
                    "Partially Rejected",
                    "Ready for Resubmission",
                    "PO Validated",
                    "SAMA and PO validated",
                  ],
                  datasets: [
                    {
                      backgroundColor: [
                        "#0d9e33",
                        "#0d759e",
                        "#9e0d9e",
                        "#9e0d4e",
                        "#9e0d14",
                        "#9e5d0d",
                        "#979e0d",
                        "#656b66",
                        "#7eaecc",
                        "#03fcdb",
                        "#d61ed6",
                      ],

                      data: [
                        chart.X0,
                        chart.X14,
                        chart.X1,
                        chart.X9,
                        chart.X18,
                        chart.X8,
                        chart.X5,
                        chart.X7,
                        chart.X10 + chart.X11,
                        chart.X2,
                        chart.X3,
                      ],
                    },
                  ],
                }}
              />
              {/*  <Pie
                  data={{
                    labels: [
                      "Fully Rejected",
                      "Partially Paid",
                      "Internal Complaint Resubmission",
                     
                      "PO Validated",
                      "SAMA and PO validated",
                      "Finished with Partially Paid",
                      "Finished with Fully Rejected",
                      "RA Paid",
                      "SAMA validated with issues",
                      "Finished due to exceeding submission allowed value",

                      "Claims ready to be submitted",
                      "Correction Resubmission",

                     
                    ],
                    datasets: [
                      {
                        backgroundColor: [
                       
                         ,
                          ",
                          "",
                          ,
                          "",
                          "",
                        ,
                          "#0bb092",
                          "#dede2c",
                          "#e8aee6",
                          "#e8aeae",
                          "#aeafe8",
                         
                        ],

                        data: [
                          chart.X8,
                          chart.X7,
                          chart.X13,
                          chart.X0,
                          chart.X1,
                          chart.X2,
                          chart.X3,
                          chart.X11,
                          chart.X12,
                          chart.X4,
                          chart.X14,
                          chart.X6,

                         
                          chart.X10,

                         
                        ],
                      },
                    ],
                  }}
                /> */}

              {/*  <div className="col-sm-4">
                <Bar
                  style={{ marginTop: "60px" }}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "top",
                      },
                      title: {
                        display: false,
                        text: "Claimss Payment Chart",
                      },
                    },
                  }}
                  data={
                    (data = {
                      labels: [
                        "Claim Gross",
                        "Claim Net",
                        "Claim Patient Share",
                      ],
                      datasets: [
                        {
                          label: "Claims",
                          data: [
                            chart.gross_sum,
                            chart.net_sum,
                            chart.patient_share_sum,
                          ],
                          backgroundColor: "#1a6e7a",
                        },
                      ],
                    })
                  }
                />
              </div> */}
            </div>
          </div>

          <div className="col-sm-6" style={{ marginTop: "20px" }}>
            <Bar
              style={{ marginTop: "60px" }}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "top",
                  },
                  title: {
                    display: false,
                    text: "Claimss Payment Chart",
                  },
                },
              }}
              data={
                (data = {
                  labels: ["Claim Gross", "Claim Net", "Claim Patient Share"],
                  datasets: [
                    {
                      label: "Claims",
                      data: [
                        chart.gross_sum,
                        chart.net_sum,
                        chart.patient_share_sum,
                      ],
                      backgroundColor: "#1a6e7a",
                    },
                  ],
                })
              }
            />

            <div style={{ margin: "50px auto" }}>
              {ageFlag && age ? (
                <div style={{ width: "120%" }}>
                  <canvas id="myChart"></canvas>
                </div>
              ) : (
                <p>loading...</p>
              )}
            </div>
          </div>
        </div>
        {/* ------------------------------ */}
        <div>
          <Grid
            container
            style={{ marginTop: "0px", width: "90%", marginLeft: "10%" }}
          >
            <Grid item xs={3}>
              <InputLabel style={{ fontWeight: "bold", color: "#212529" }}>
                Payer ID
              </InputLabel>
              <MuiSelect
                variant="outlined"
                style={{ width: "100%", marginBottom: "0.7rem" }}
                onChange={this.onScelectPayerName}
                value={this.state.payer_id ? this.state.payer_id : ""}
              >
                {payersList}
              </MuiSelect>
              {/*   <InputLabel style={{ fontWeight: "bold", color: "#212529" }}>
                Clinician ID
              </InputLabel> */}
              <Controls.Input
                name="clinicianid"
                value={this.state.clinicion_id}
                onChange={this.handleInputTextChangeClinicion}
                label="Clinician ID"
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <Controls.DateInput
                name="dateFrom"
                label="From Date"
                onChange={this.handleFromDate}
              />

              <Controls.Input
                name="activitycode"
                value={this.state.activities_code}
                onChange={this.handleInputTextChange}
                label="Activity Code"
              />

              <div>
                <input
                  type="submit"
                  value="Search"
                  style={{
                    marginTop: "20px",
                    border: "none",
                    backgroundColor: "#026f7d",
                    padding: "0.8rem 2.3rem",
                    color: "white",
                    fontSize: "1rem",
                    borderRadius: "10px",
                  }}
                  onClick={this.getPriceChart.bind(this)}
                  name="name"
                />
              </div>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <Controls.DateInput
                name="dateTo"
                label="To Date"
                onChange={this.handleFromDate}
              />

              <InputLabel
                style={{
                  fontWeight: "bold",
                  color: "#212529",
                  marginBottom: "1.2rem",
                }}
              >
                Facility ID
              </InputLabel>
              <div style={{ marginTop: "1.15rem", marginBottom: "0.9rem" }}>
                <Multiselect
                  options={FacilityIdOption} // Options to display in the dropdown
                  selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                  onSelect={this.onSelectFacilityId} // Function will trigger on select event
                  onRemove={this.onRemoveFacilityId} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                />
              </div>
            </Grid>
          </Grid>
        </div>

        {priceFlag && price ? (
          <div className="row">
            {" "}
            <div className="col-sm-6">
              <Bar
                style={{ marginTop: "60px" }}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: "top",
                    },
                    title: {
                      display: true,
                      text: "Claims Total",
                    },
                  },
                }}
                data={
                  (data = {
                    labels: ["Total", "Net", "Patients Payment"],
                    datasets: [
                      {
                        label: "Amount(AED)",
                        data: [
                          price.claimsGross,
                          price.claimsNet,
                          price.claimsPatientShares,
                        ],
                        backgroundColor: "#026f7d",
                      },
                    ],
                  })
                }
              />
            </div>
            {/* --------------------- */}
            <div className="col-sm-6" style={{ marginTop: "111px" }}>
              <Bar
                options={{
                  responsive: true,
                  maintainAspectRatio: true,

                  plugins: {
                    legend: {
                      position: "top",
                    },
                    title: {
                      display: true,
                      text: "Activities Total",
                    },
                  },
                }}
                data={{
                  labels: [
                    "All Activities",
                    "Activities done by the clinician",
                    "Fully Rejected Activities",
                    "Fully Paid Activities",
                    "Partially Paid Activities",
                    "Pending Activities",
                  ],
                  datasets: [
                    {
                      label: "Activities",
                      data: [
                        price.activityCount,
                        price.activityDoneByClinicianCount,
                        price.fullyRejectedActivityCount,
                        price.paidActivityCount,
                        price.partiallyPaidActivityCount,
                        price.pendingActivityCount,
                      ],

                      backgroundColor: "#741a7a",
                    },
                    {
                      label: "Amount(AED)",
                      data: [
                        price.activityAmount,
                        price.activityDoneByClinicianAmount,
                        price.fullyRejectedActivityAmount,
                        price.paidActivityAmount,
                        price.partiallyPaidActivityAmount,
                        price.pendingActivityAmount,
                      ],

                      backgroundColor: "#026f7d",
                    },
                  ],
                }}
              />
            </div>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    );
  }
}

export default SetupOptionScreen;
