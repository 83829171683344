import React from "react";
import Footer from "../Components/footer";
// import MainPageFooter from '../Components/MainPageFooter'
import NavBar from "../Components/navbar";
import styles from "../Components/Styling/researchPage.module.css";
const ResearchPage = () => {
  return (
    <>
      <NavBar />
      <div id="research" className={styles.container}>
        <div
          className={styles.top}
          style={{
            backgroundImage: "url(" + require("./../imgs/market.jpg") + ")",
            padding: 0,
          }}
        >
          <div className={styles.right}>
            <h2 className={styles.header3}>Pharmaceutical market research</h2>
            <p className={styles.info}>
              {/* Kayan */}Our Solutions have a deep understanding of the drug
              market and offers the following top quality services to their
              clients:
            </p>
            <ol className={styles.text3}>
              <li>Primary Market Research</li>
              <li>Market Access Consultancy</li>
              <li>New Product selection</li>
              <li>Healthcare analytics</li>
              <li>Benchmarking Reports</li>
              <li>Business Intelligence Improvement</li>
              <li>Business Performance Management</li>
              <li>Projects Management</li>
              <li>Marketing Plan Preparation</li>
              <li>Geographic Analysis for current and new store locations</li>
            </ol>
            <p className={styles.text3}>
              as well as any other specific services that need to be tailored
              specifically for our clients.
            </p>
          </div>
        </div>
      </div>
      <div
        className={styles.bottom}
        style={{
          backgroundImage: "url(" + require("./../imgs/2research.jpg") + ")",
          padding: 0,
        }}
      >
        <div className={styles.textImage}>
          <h2 className={styles.header2}>Primary Market Research</h2>
          <ul className={styles.text2}>
            <span>let us help you in....</span>
            <li>
              ensure opportunities for products already in the market are
              optimized
            </li>
            <li>expand into new markets</li>
            <li>
              develop and refine marketing, advertising and communication plans
            </li>
            <br />
            <span>all DONE USING wisely-made business decisions.</span>
          </ul>
          <br />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ResearchPage;
