import React from "react";
import "./Codes.css";

import ListView from "../ListView";
import Button from "../Button";

import Input from "../InputText";
import Number from "../Number";
import DropList from "../DropList";
import ProgressBar from "react-bootstrap/ProgressBar";

import Date from "../Date";
//const randomstring = require('randomstring')

function defaultClick(e) {
  e.preventDefault();
}

function ActivityCodeList(props) {
  let {
    Items,
    inputStyle,
    addNewCode,
    listName,
    onSelect,
    section,
    BtnActive,
    attributes,
    ActivitySuggestions,
    onChange,
    onClick,
    onInputTextChange,
    userInputActivity,
    activeSuggestion,

    filteredSuggestionsActivity,
    showActivitySuggestions,
    handleItemDeletion,
    ActivityNow,
  } = props;

  let suggestionsListComponentActivity;

  if (showActivitySuggestions && userInputActivity) {
    if (filteredSuggestionsActivity && filteredSuggestionsActivity.length) {
      suggestionsListComponentActivity = (
        <ul className="suggestions">
          {filteredSuggestionsActivity.map((suggestion, index) => {
            let className;

            // Flag the active suggestion with a class
            if (index === activeSuggestion) {
              className = "suggestion-active";
            }

            return (
              <li className={className} key={suggestion} onClick={onClick}>
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    } else {
      suggestionsListComponentActivity = (
        <div className="no-suggestions">
          <em>No suggestions...</em>
        </div>
      );
    }
  }

  let InputId = "",
    BtnValue = "";
  if (listName === "ActivityCodes") {
    InputId = "activityCode";
    BtnValue = "add new activity";
  }

  return (
    <React.Fragment>
      {Items && Items.length > 0 ? (
        <ListView
          Items={Items}
          name="codes"
          handleItemDeletion={handleItemDeletion}
          listName={listName}
        />
      ) : null}

      <DropList
        id="activitySource"
        Title="Source"
        name="Source"
        Options={[
          "CPT",
          "HCPCS",
          "Dental",
          "Drug",
          "Service" /* , "Service", "DRG" */,
          "DRG",
        ]}
        Values={[3, 4, 6, 5, 8, 9 /* , 8, 9 */]}
        onSelect={onSelect}
        Style="requestSelect"
      />

      {listName === "ActivityCodes" && section === "Activity" && BtnActive ? (
        <React.Fragment>
          {attributes && attributes.length > 0
            ? attributes.map((attribute, index) =>
                attribute === "ToothNumber" ? (
                  <Input
                    key={index}
                    id={attribute}
                    name={attribute}
                    Style={inputStyle}
                    onChange={(e) => onInputTextChange(e, listName)}
                    hint={attribute}
                  />
                ) : attribute === "ActivityID" ? (
                  <Input
                    key={index}
                    id={attribute}
                    name={attribute}
                    Style={inputStyle}
                    onChange={(e) => onInputTextChange(e, listName)}
                    hint={attribute}
                  />
                ) : attribute === "Start" ? (
                  <Date
                    key={index}
                    id={attribute}
                    name={attribute}
                    Style={inputStyle}
                    onChange={(e) => onInputTextChange(e, listName)}
                  />
                ) : attribute === "Quantity" ? (
                  <Number
                    key={index}
                    id={attribute}
                    name={attribute}
                    Style={inputStyle}
                    min={1}
                    onChange={(e) => onInputTextChange(e, listName)}
                    hint={attribute}
                  />
                ) : attribute === "Activity_NET" ? (
                  <Number
                    key={index}
                    id={attribute}
                    name={attribute}
                    Style={inputStyle}
                    min={1}
                    onChange={(e) => onInputTextChange(e, listName)}
                    hint={attribute}
                  />
                ) : attribute === "code" ? (
                  <div key={index}>
                    {
                      //!suggestions
                      ActivitySuggestions &&
                      ActivitySuggestions.length === 0 ? (
                        <ProgressBar
                          style={{ width: "70%", margin: "25px auto" }}
                          animated
                          variant="success"
                          now={ActivityNow}
                        />
                      ) : null
                    }

                    <Input
                      id={InputId}
                      key={index}
                      name="Text"
                      disabled={
                        ActivitySuggestions && ActivitySuggestions.length === 0
                          ? true
                          : false
                      }
                      Style={inputStyle}
                      onChange={(e) => onChange(e, listName)}
                      hint="Code"
                      value={userInputActivity}
                    />
                    {listName === "ActivityCodes"
                      ? suggestionsListComponentActivity
                      : null}
                  </div>
                ) : (
                  <Input
                    key={index}
                    id={attribute}
                    name={attribute}
                    Style={inputStyle}
                    onChange={(e) => onInputTextChange(e, listName)}
                    hint={attribute}
                  />
                )
              )
            : null}

          <Button
            Style="enabledStyle"
            Value={BtnValue}
            onClick={(e) => addNewCode(e, listName, InputId)}
          />
        </React.Fragment>
      ) : (
        <Button
          Style="disabledStyle"
          Value={BtnValue}
          onClick={(e) => defaultClick(e)}
        />
      )}
    </React.Fragment>
  );
}
export default ActivityCodeList;
