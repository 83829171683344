"use strict";
import React, { Component } from "react";
import axios from "axios";
import XML from "xml";
import { CSVLink } from "react-csv";
import NavBar from "./../Components/navbar";
import Form from "./../Components/Form/index.js";
//import Header from "./../Components/Header";
import CheckBox from "./../Components/CheckBox";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import Button from "./../Components/Button";
import Cookies from "js-cookie";
import ListView from "./../Components/ListView";
//import BayanSubNavBar from "./../Components/BayanSubNavBar";
import settings from "../helpers/Settings";
import PreferedCodesScreen from "./PreferedCodesScreen";
import Alert from "react-bootstrap/Alert";
import Sub from "./BayanSubNavbar";
import MainScreenBayan from "./MainScreenBayan";
var XLSX = require("xlsx");
var parseString = require("xml2js").parseString;
let { KayanURL } = settings;
function sortObject(o) {
  var sorted = {},
    key,
    a = [];

  for (key in o) {
    if (o.hasOwnProperty(key)) {
      a.push(key);
    }
  }

  a.sort();

  for (key = 0; key < a.length; key++) {
    sorted[a[key]] = o[a[key]];
  }
  return sorted;
}

const initialState = {
  flagTemplate: false,
  flag: false, //Bayan flag
  flagClaim: false, //claim flag
  BayanHeader: [], //header for bayan response
  ClaimHeader: [], //header for claim response
  rows: [], //rows for bayan response
  rowsClaim: [], //rows for claim response

  status: "", //success status
  status2: "", //bayan status
  ClaimStatus: "", //claim status
  fileName: "",
  Msg: "",

  SyntaxErrorStatus: "",
  flagSyntaxError: false,
  encounters: [],
  DiagnosisCodes: [],
  ActivityCodes: [],
  Activity: [],
  Diagnosis: [],

  firstTime: true,

  data: {},
};
const initialStateTables = {
  flagTemplate: false,
  flag: false, //Bayan flag
  flagClaim: false, //claim flag
  BayanHeader: [], //header for bayan response
  ClaimHeader: [], //header for claim response
  rows: [], //rows for bayan response
  rowsClaim: [], //rows for claim response

  status: "", //success status
  status2: "", //bayan status
  ClaimStatus: "", //claim status
  fileName: "",
  Msg: "",

  SyntaxErrorStatus: "",
  flagSyntaxError: false,
};

class Employee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleState: 1,
      companyType: "", //to show denial for payer and hide denial for bayer
      activeSuggestion: 0, // The active selection's index
      filteredSuggestions: [], // The suggestions that match the user's input
      showActivitySuggestions: false, // Whether or not the suggestion list is shown
      showDiagnosisSuggestions: false,
      // What the user has entered
      userInputActivity: "",
      userInputDiagnosis: "",

      flagTemplate: false,

      //common
      isChoised: false,
      data: {},
      serverError: null,
      dataRetrieved: null,
      inputsHaveErrors: null,
      error: null,
      username: "",
      name: null,

      // Template section
      code: {},
      patient: {},
      diagnosis: {},

      addencounters: false,
      firstTime: true,
      DiagnosisCodes: [],
      ActivityCodes: [],
      encounters: [],
      BtnActive: false,
      isPrincipalChecked: false,
      //uploading:false,
      requestXML: null,
      flag: false, //Bayan flag
      flagClaim: false, //claim flag
      BayanHeader: [], //header for bayan response
      ClaimHeader: [], //header for claim response
      rows: [], //rows for bayan response
      rowsClaim: [], //rows for claim response
      //type:1,
      status: "", //success status
      status2: "", //bayan status
      ClaimStatus: "", //claim status
      fileName: "",
      Msg: "",
      SyntaxErr: [], //related to syntax error in file
      SyntaxErrorStatus: "",
      flagSyntaxError: false,
      //Progress Bar
      diagnosisNow: 0,
      ActivityNow: 0,
    };
  }
  toggleTab = (index) => {
    this.setState({ toggleState: index });
    this.setState(initialState);
    this.setState({ encounters: [] });
  };
  // get Employee Info.
  getEmployeeInfo = () => {
    const { history } = this.props;

    axios
      .put(KayanURL + "api/user/info", { apiToken: Cookies.get("id") })
      .then(({ data }) => {
        if (data.success) {
          localStorage.setItem("name", data.name);
          /* localStorage.setItem("BayanUserLicense", data.license); */
          this.setState({ loading: false });

          this.setState({ name: data.name });
          // this.setState({ license: data.license });

          //  console.log(this.state.license);
        } else {
          if (data.error[0] === "Invalid request") {
            history.push(`/${data.usertype}`);
          } else {
            history.push({ pathname: "/loginAsemployee", errMsg: data.error });
          }
        }
      })
      .catch(() => {
        this.setState({ serverError: "There are an Server error" });
      });
  };

  renderDiagnosisProgressBar() {
    let { diagnosisNow, diagnosisItems } = this.state;

    setTimeout(() => {
      this.setState(
        {
          diagnosisNow:
            !diagnosisItems && diagnosisNow < 100 ? diagnosisNow + 20 : 100,
        },
        this.renderDiagnosisProgressBar()
      );
    }, "800");
  }
  getAllDiagnosis = () => {
    let diagnosisItems = [];
    this.renderDiagnosisProgressBar();

    axios
      .get(KayanURL + "api/getAllDiagnosis")
      .then(({ data }) => {
        if (data) {
          data.forEach((element) => {
            if (element.shortdesc === "null") {
              element.shortdesc = "";
            }
            diagnosisItems.push(element.code + "-" + element.shortdesc);
          });

          this.setState({ diagnosisItems });
        }
      })
      .catch(() => {
        this.setState({ serverError: "There are an server error" });
      });
  };
  getAllPreferedDiagnosis = () => {
    let diagnosisItems = [];
    this.renderDiagnosisProgressBar();

    axios
      .put(KayanURL + "api/user/getPreferedCodes/icd", {
        apiToken: Cookies.get("id"),
      })
      .then((res) => {
        let { data } = res;
        if (data.success) {
          data = data["result"]["prefered_icd"];
          if (data && data.length > 0) {
            data = data.split(",");
          } else {
            data = undefined;
          }

          this.setState({ PrefereddiagnosisItems: data });
        }

        /*    data = data.result['prefered_icd']
           console.log("data");
           console.log(data);
           if (data) {
             data = data.split(',')
   
             data.forEach((element) => {
               if (diagnosisItems && diagnosisItems.indexOf(element) == -1) {
                 diagnosisItems.push(element);
   
               }
   
             });
   
             this.setState({ PrefereddiagnosisItems: diagnosisItems });
           } */
      })
      .catch(() => {
        this.setState({ serverError: "There are an server error" });
      });
  };

  renderActivityProgressBar() {
    let { ActivityNow, ActivityItems } = this.state;

    setTimeout(() => {
      this.setState(
        {
          ActivityNow: !ActivityItems ? ActivityNow + 20 : 100,
        },
        this.renderActivityProgressBar()
      );
    }, "800");
  }

  getAllActivity = (v) => {
    let ActivityItems = [];
    this.renderActivityProgressBar();

    let ProviderID = this.state.data.ProviderID;
    // console.log("provide id", ProviderID);
    let city = "";
    if (ProviderID && ProviderID.includes("DHA") && v && v == "3") {
      city = "dubai";
    }

    axios
      .put(KayanURL + "api/getAllActivity/" + v, {
        city,
      })

      .then(({ data }) => {
        if (data) {
          data.forEach((element) => {
            if (
              ActivityItems.indexOf(element.code + "-" + element.shortdesc) ==
              -1
            ) {
              if (element.shortdesc === "null") {
                element.shortdesc = "";
              }

              ActivityItems.push(element.code + "-" + element.shortdesc);
            }
          });

          this.setState({ ActivityItems });
        }
      })
      .catch(() => {
        this.setState({ serverError: "There are an server error" });
      });
  };

  getAllPreferedActivity = (v) => {
    let ActivityItems = [];
    this.renderActivityProgressBar();
    let fieldName = "";

    console.log("get all prefered activity");
    console.log(v);

    switch (v) {
      case 3:
      case "3":
        fieldName = "cpt";
        break;
      case 4:
      case "4":
        fieldName = "hcpcs";
        break;

      case 6:
      case "6":
        fieldName = "dental";
        break;
      case 5:
      case "5":
        fieldName = "drug";
        break;

      default:
        break;
    }

    axios
      .put(KayanURL + "api/user/getPreferedCodes/" + v, {
        apiToken: Cookies.get("id"),
      })

      .then((res) => {
        let { data } = res;
        if (data.success) {
          data = data["result"]["prefered_" + fieldName];
          if (data && data.length > 0) {
            data = data.split(",");
          } else {
            data = undefined;
          }

          console.log("dataaaaa");
          console.log(data);

          if (data) {
            data.forEach((element) => {
              if (ActivityItems.indexOf(element) == -1) {
                ActivityItems.push(element);
              }
            });
            console.log("ActivityItems");
            console.log(ActivityItems);
            this.setState({ ActivityItems });
            this.setState({ preferedActivityCodes: ActivityItems });
          } else {
            this.setState({ ActivityItems: undefined });
            this.setState({ preferedActivityCodes: undefined });
          }
        }
      })
      .catch(() => {
        this.setState({ serverError: "There are an server error" });
      });
  };

  getRulesStatus = () => {
    axios
      .put(KayanURL + "api/user/rules", { apiToken: Cookies.get("id") })
      .then(({ data }) => {
        if (data) {
          let rulesStatus = sortObject(data.RulesStatus.status);
          console.log(rulesStatus);
          this.setState({ rulesStatus: rulesStatus });
        }
      })
      .catch(() => {
        this.setState({ serverError: "There are an server error" });
      });
  };

  componentDidMount = () => {
    this.getEmployeeInfo();

    this.getAllDiagnosis();
    // this.getAllPreferedDiagnosis();

    const { history } = this.props;

    if (Cookies.get("id") == null) {
      var errorMsg = "You need to sign in or sign up";
      history.push(`/loginAsEmployee`, errorMsg);
    } else {
      this.setState({ companyType: localStorage.getItem("type") });
      this.getRulesStatus();
    }
  };

  // Read XML File content
  readXMLFile = (reader) => {
    // To Do: 1- Handling error (reader.onerror); 2- clear Input file for each change on input file.
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ requestXML: reader.result });
      }
    };
  };
  /* readCSVFile = (reader) => {
    // To Do: 1- Handling error (reader.onerror); 2- clear Input file for each change on input file.
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ requestCSV: reader.result });
      }
    };
  }; */
  // Handling Select Actions.
  onSelectOption = (e) => {
    let select = document.getElementById(e);
    let name = select.name;
    let value = select.options[select.selectedIndex].value;
    switch (name) {
      case "Source":
        this.setState({ ActivityItems: [], ActivityNow: 0 });

        switch (value) {
          case "3":
            //var attributes = ['ActivityID','Start','Quantity'];
            var attributes = ["code", "Start", "Quantity"];

            break;
          case "4":
            //var attributes = ['ActivityID','Start','Quantity'];
            var attributes = ["code", "Start", "Quantity"];
            break;
          case "9":
            // var attributes = ['ActivityID','Start','Quantity'];
            var attributes = ["code", "Start", "Quantity"];
            break;
          case "8":
            var attributes = ["code", "Start", "Quantity"];
            break;
          case "6":
            // var attributes = ['ActivityID','Start','Quantity','ToothNumber'];
            var attributes = ["code", "Start", "Quantity", "ToothNumber"];
            break;

          case "5":
            var attributes = ["code", "Start", "Quantity"];
            break;
        }
        if (value !== 0) {
          // this.getAllPreferedActivity(value);
          this.getAllActivity(value);
        }

        this.setState({
          attributes,
          source: value,
          code: { ...this.state.code, [name]: value },
          BtnActive: true,
          section: "Activity",
        });

        break;

      case "PatientGender":
        this.setState({ patient: { ...this.state.patient, [name]: value } });
        break;

      case "EncounterType":
        this.setState({ patient: { ...this.state.patient, Type: value } });
        break;

      case "DiagnosisType":
        this.setState({ code: { ...this.state.code, [name]: value } });
        break;

      default:
        this.setState({ data: { ...this.state.data, [name]: value } });
        break;
    }
  };

  onSelectOperation = (e) => {
    this.setState({ isAdded: false, existInDb: false });
    let select = document.getElementById(e);
    let name = select.name;

    let value = select.options[select.selectedIndex].value;

    switch (value) {
      case "add":
        this.setState({ preferedCodesOperation: value });

        break;
      case "delete":
        this.setState({ preferedCodesOperation: value });
        break;
      default:
        this.setState({ preferedCodesType: value });

        break;
    }

    if (value != "icd") {
      //make a request to get codes
      let activityType = "";
      switch (value) {
        case "cpt":
          activityType = 3;
          break;
        case "dental":
          activityType = 6;

          break;
        case "hcpcs":
          activityType = 4;
          break;

        default:
          break;
      }
      this.getAllActivity(activityType);
      //this.getAllPreferedActivity(activityType);
    }
  };

  processExcel(data) {
    const workbook = XLSX.read(data, { type: "binary" });

    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[firstSheet]
    );
    console.log(" excelRows", excelRows);

    this.setState({ excelRows });
  }

  handleInputTextChange = ({ target: { name, value, files } }) => {
    switch (name) {
      case "PatientId":
      case "BirthDate":
      case "Weight":
        this.setState({ patient: { ...this.state.patient, [name]: value } });
        break;

      case "requestFile":
        let reader = new FileReader();
        if (files.length > 0) {
          if (files[0] && files[0]["name"]) {
            let fileName = files[0]["name"];

            let fileExtension = fileName.split(".")[1];
            if (fileExtension == "csv" || fileExtension == "xlsx") {
              this.setState({ requestType: "fileCSV" });
              if (reader.readAsBinaryString) {
                reader.onload = async (e) => {
                  await this.processExcel(reader.result);
                };
                reader.readAsBinaryString(
                  document.getElementById("requestFile").files[0]
                );
              }
            } else if (fileExtension == "xml") {
              this.setState({ requestType: "file" });
              reader.readAsText(files[0]);

              this.readXMLFile(reader);
            }
          }
        }

        break;

      case "ActivityID":
      case "Quantity":
      case "ToothNumber":
        this.setState({ code: { ...this.state.code, [name]: value } });
        break;

      case "Start":
        var datetime = value.replace(/\-/g, "/");
        datetime = datetime.replace(/\T/g, " ");
        var datetimeArray = datetime.split(" ");
        var date = datetimeArray[0];
        var time = datetimeArray[1];
        //reverse date string
        var reverseDate = date.split("/").reverse().join("/");

        this.setState({
          code: { ...this.state.code, [name]: reverseDate + " " + time },
        });
        break;

      case "PatientAge":
        this.setState({ patient: { ...this.state.patient, [name]: value } });
        break;

      default:
        this.setState({ data: { ...this.state.data, [name]: value } });
        break;
    }
  };
  /// #2 For Code InputText
  onAddNewCodeInputChange = ({ target: { name, value } }, listName) => {
    switch (listName) {
      case "DiagnosisCodes":
        this.setState({
          section: "Diagnosis",
        });
        break;
      default:
        this.setState({
          section: "Activity",
        });
        break;
    }

    if (value === "") {
      this.setState({
        BtnActive: false,
      });
    } else if (listName === "DiagnosisCodes") {
      this.setState({
        attributes: ["Type"],
        code: { ...this.state.code, [name]: value },
        BtnActive: true,
      });
    } else {
      this.setState({
        code: { ...this.state.code, [name]: value },
        BtnActive: true,
      });
    }
  };

  // add code to codesLists
  addNewCode = (event, listName, inputId) => {
    event.preventDefault();
    // document.getElementById(inputId).value = ''

    this.setState({
      BtnActive: false,
    });
    let { DiagnosisCodes, ActivityCodes, code } = this.state;

    switch (listName) {
      case "DiagnosisCodes":
        if (!code.DiagnosisType && !this.state.seetingView) {
          code.DiagnosisType =
            document.getElementById("diagnosisType").options[
              document.getElementById("diagnosisType").options.selectedIndex
            ].value;
        }
        DiagnosisCodes.push(code);

        this.setState({
          DiagnosisCodes,
          code: {},
          data: { ...this.state.data, Diagnosis: DiagnosisCodes },
          userInputDiagnosis: "",
        });
        break;

      default:
        if (!code.Quantity && !this.state.seetingView) {
          code.Quantity = "1";
        }

        if (!code.Start && !this.state.seetingView) {
          /*  var date = new Date(); 
                date.setHours(date.getHours() + 3);
                
               // var isodate = date.toISOString().substr(0,16); */
          var isodate = document.getElementById("Start").value;
          var datetime = isodate.replace(/\-/g, "/");
          datetime = datetime.replace(/\T/g, " ");
          var datetimeArray = datetime.split(" ");
          var date = datetimeArray[0];
          var time = datetimeArray[1];
          //reverse date string
          var reverseDate = date.split("/").reverse().join("/");

          code.Start = reverseDate + " " + time;
        }

        ActivityCodes.push(code);

        //reset the dropdown list
        if (!this.state.seetingView) {
          let select = document.getElementById("activitySource");
          select.options[0].selected = true;
          //
        }

        this.setState({
          ActivityCodes,
          code: {},
          data: { ...this.state.data, Activity: ActivityCodes },
          userInputActivity: "",
        });
        break;
    }
  };
  // Add new encounter
  addNewEncounter = (event) => {
    event.preventDefault();

    // this.setState(({ addencounters: false, DiagnosisCodes: [], ActivityCodes: [], firstTime: false }));
    let { data, patient, encounters } = this.state;

    encounters = [];

    let { Diagnosis, Activity, ProviderID } = data;

    let encounter = {};

    encounter.ID = data.ClaimID;
    encounter.ProviderId = ProviderID;
    // Patient Data
    if (Object.keys(patient).length > 0) {
      let PatientData = [];
      let keys = Object.keys(patient);
      let values = Object.values(patient);
      keys.map((p, index) => PatientData.push({ [p]: values[index] }));
      encounter.Patient = PatientData;
      // this.setState({patient:{}})
    }

    // Diagnosis Data
    if (Diagnosis && Diagnosis.length > 0) {
      let DiagnosisTags = [];

      Diagnosis.forEach((d) => {
        let dText = d.Text; //delete d.Text;

        DiagnosisTags.push({ Type: d.DiagnosisType, Code: dText });

        encounter.Diagnosis = DiagnosisTags;
      });
      //this.setState({Diagnosis:[]})
    } else {
      alert("your request should contain at least one Diagnois");
    }

    // Activity Data
    if (Activity && Activity.length > 0) {
      let ActivityData = [];
      Activity.forEach((a) => {
        ActivityData.push({
          ID: a.ActivityID,
          Code: a.Text,
          Type: a.Source,
          Start: a.Start,
          Quantity: a.Quantity,
          ToothNumber: a.ToothNumber,
        });

        encounter.Activity = ActivityData;
      });
      // this.setState({Activity:[]})
    } else {
      alert("your request should contain at least one Activity");
    }

    if (Activity && Activity.length > 0 && Diagnosis && Diagnosis.length > 0) {
      this.setState(initialStateTables);
      /*  this.setState({Diagnosis:[]})
        this.setState({Activity:[]}) 
        this.setState({patient:{}}) */
      encounters.push({ Claim: encounter });
      document.getElementById("BtnsContainer").scrollIntoView();
      this.setState({ encounters /* ,data:{},isPrincipalChecked:false */ });
      /*  document.getElementById('PatientAge').value=""
        document.getElementById("PatientGender").value=0
        document.getElementById("EncounterType").value="" */
      this.setState({
        /* addencounters: false,  */
        /* DiagnosisCodes: [], ActivityCodes: [] */
        /* , firstTime: false  */
      });
    }
  };

  handleRadioChange = ({ target: { name, value, files } }) => {
    this.setState({ type: value });
  };

  // Triggers
  /// #1 For redirecting to chosen Requesting method.
  /*   onClickBtn = (e) => {
    this.setState({
      isChoised: true,
      requestType: e.target.value === "file upload" ? "file" : "template",
    });
  };
 */
  /// #2 For allow user to add new encounter.
  onClickEncounterTrigger = () => {
    this.setState((prevState) => ({
      addencounters: !prevState.addencounters,
    }));
  };

  // DoRequest
  doRequest = async (event) => {
    event.preventDefault();
    var records = [];

    this.setState({ error: null, dataRetrieved: null });
    this.setState({ upload: true }); //to view the upload sppiner
    document.getElementById("uploading").scrollIntoView();
    this.setState({ status: "" }); //to remove the previous status from the view//Success Or Fail
    this.setState({ status2: "" });
    this.setState({ ClaimStatus: "" });
    this.setState({ SyntaxErrorStatus: "" });
    this.setState({ flag: false }); //to hide the previous download link and table
    this.setState({ flagSyntaxError: false });
    this.setState({ flagClaim: false });
    this.setState({ performanceStatus: "", performanceflag: false });

    //++++++++++++++
    // handling any request.
    let { requestType } = this.state;
    var request;
    var fileNamePath;

    switch (requestType) {
      case "template":
        const { encounters } = this.state;

        var encountersXML = [];
        let claim = [];

        encounters.forEach((encounter) => {
          console.log("encounter");
          console.log(encounter);
          encountersXML = [];
          var charset = "abcdefghijklmnopqrstuvwxyz"; //from where to create
          var result = "";
          for (var i = 0; i < 3; i++)
            result += charset[Math.floor(Math.random() * charset.length)];
          //claim ID
          let str =
            result.toUpperCase() + "-" + Math.floor(Math.random() * 10000000);
          encountersXML.push({ ID: str });
          encountersXML.push({ ProviderID: encounter["Claim"]["ProviderId"] });

          encountersXML.push({ Encounter: encounter.Claim.Patient });
          if (
            encounter.Claim.Diagnosis &&
            encounter.Claim.Diagnosis.length > 0
          ) {
            encounter.Claim.Diagnosis.forEach((d) => {
              let code;
              if (d.Code === undefined) {
                code = "";
              } else {
                code = d.Code.split("-")[0];
              }
              encountersXML.push({
                Diagnosis: [{ Type: d.Type }, { Code: code }],
              });
            });
          }
          if (encounter.Claim.Activity && encounter.Claim.Activity.length > 0) {
            encounter.Claim.Activity.forEach((a) => {
              let code;
              if (a.Code === undefined) {
                code = "";
              } else {
                if (a.Type == "5") {
                  let codeparts = a.Code.split("-");
                  code = codeparts[0] + "-" + codeparts[1] + "-" + codeparts[2];
                } else {
                  code = a.Code.split("-")[0];
                }
              }

              if (a.Type === "6") {
                encountersXML.push({
                  Activity: [
                    /*{ID:a.ID},*/ { ID: Math.floor(Math.random() * 10000000) },
                    { Code: code },
                    { Type: a.Type },
                    { Start: a.Start },
                    { Quantity: a.Quantity },
                    {
                      Observation: [
                        { Value: a.ToothNumber },
                        { ValueType: "ToothNumber" },
                        { Code: "UniversalDental" },
                        { Type: "UniversalDental" },
                      ],
                    },
                  ],
                });
              } else {
                encountersXML.push({
                  Activity: [
                    /*{ID:a.ID},*/ { ID: Math.floor(Math.random() * 10000000) },
                    { Code: code },
                    { Type: a.Type },
                    { Start: a.Start },
                    { Quantity: a.Quantity },
                  ],
                });
              }
            });
          }
          claim.push({ Claim: encountersXML });
          //clean all data when send the request

          this.setState({ encounters: [] });
        });

        request = XML({ "Claim.Submission": claim }, true);
        this.setState({ templateRequest: request });
        break;

      case "file":
        request = this.state.requestXML;
        //extract the uploaded file name

        fileNamePath = "" + document.getElementById("requestFile").value;
        this.setState({ fileName: fileNamePath.split("\\")[2] });

        break;

      case "fileCSV":
        request = this.state.excelRows;
        //extract the uploaded file name

        fileNamePath = "" + document.getElementById("requestFile").value;
        this.setState({ fileName: fileNamePath.split("\\")[2] });

        break;

      default:
        request = this.state.data.request;
        break;
    }
    let data;
    if (requestType != "fileCSV") {
      data = await axios.post(KayanURL + "api/request/", {
        content: request,
        apiToken: Cookies.get("id"),
      });
    } else {
      data = await axios.post(KayanURL + "api/csv/", {
        content: request,
        apiToken: Cookies.get("id"),
      });
    }

    //response from bayan

    if (data) {
      const DataXML = data["data"];
      var xml = DataXML;

      var resultStatus = "";
      var RequestError;
      var Encounters = [];
      var ErrorEncounter = [];
      var Claims = [];
      if (!xml.success && xml.error) {
        this.setState({ upload: false });
        this.setState({ performanceflag: false });
        this.setState({ performanceStatus: xml.error });
        document.getElementById("requestFile").value = null;
        return;
      }

      parseString(xml, function (err, result) {
        if (result["Response-Status"]["Status"][0] === "Success") {
          resultStatus = "success";
        } else if (
          result["Response-Status"]["Status"][0] === "Failed" ||
          result["Response-Status"]["Status"][0] === "Hint"
        ) {
          //console.log("Failed");

          RequestError = result["Response-Status"]["Request-Errors"][0];
          let RequestErrorKey = Object.keys(RequestError)[0];

          if (RequestErrorKey === "Error") {
            // console.log('Error');
            ErrorEncounter =
              result["Response-Status"]["Request-Errors"][0]["Error"];
            //console.log(ErrorEncounter);
          } else if (RequestErrorKey === "Encounter") {
            //console.log('Encounter');
            Encounters =
              result["Response-Status"]["Request-Errors"][0]["Encounter"];
            //console.log(Encounters);
          } else if (RequestErrorKey === "Claim") {
            //console.log('Claim');
            Claims = result["Response-Status"]["Request-Errors"][0]["Claim"];
          }
        }
      });

      //if result status = success stop the uploading and pring the message

      if (resultStatus === "success") {
        this.setState({ upload: false });
        this.setState({ flag: false });
        this.setState({ status: "Success" });
        ////
        console.log(requestType);
        if (requestType === "file" || requestType === "fileCSV") {
          document.getElementById("requestFile").value = null; //delete file name when finished
        }
        if (requestType === "template") {
          this.setState({ flagTemplate: true }); //to view the download button(xml generated file)
        }

        return;
      } else if (ErrorEncounter.length > 0) {
        console.log("syntax error");
        let arr = [];

        ErrorEncounter.forEach((err) => {
          //console.log(err['Message'][0]);
          //console.log(err['Line'][0]);
          arr.push({ Message: err["Message"][0], Line: err["Line"][0] });
        });

        this.setState({ SyntaxErr: arr });
        this.setState({ upload: false });

        this.setState({ flagSyntaxError: true });
        this.setState({ SyntaxErrorStatus: "syntax" });
        ////
        document.getElementById("requestFile").value = null; //delete file name when finished
        if (requestType === "template") {
          this.setState({ flagTemplate: true }); //to view the download button(xml generated file)
        }

        return;
      } else if (Encounters.length > 0) {
        console.log("bayan request faild");
        console.log(Encounters);

        Encounters.forEach(function (Encounter) {
          //console.log(Encounter);

          //for each Encounter
          var EncounterId = Encounter["EncounterId"];
          //console.log("encounter ID : "+EncounterId  );
          var line = Encounter["Line"];
          //console.log("encounter line : "+ line);
          /*----------########Data Error########----------------*/
          if (Encounter["Data-Errors"] !== undefined) {
            //console.log("inside data errors encounter");
            var dataErrorsArray = Encounter["Data-Errors"][0]["Code"];

            dataErrorsArray.forEach(function (dataErr) {
              var EncounterID = EncounterId;
              //console.log("Encounter Id : "+ EncounterID)

              var EncounterLine = line;
              //console.log("Encounter Line: "+EncounterLine);

              var ErrorType = "Data-Error";
              //console.log("Error Type :" + ErrorType);

              var ErrorLine = dataErr["Line"];
              //console.log("Error Line : "+ ErrorLine);

              var ErrorMessage = dataErr["Message"];
              //console.log("Error Message : "+ ErrorMessage);

              /*var Domain=dataErr['Domain'];
                    console.log("Domain : "+ Domain);*/

              var Type = dataErr["Type"];
              //console.log("Type : "+ Type);

              var CodeId = dataErr["CodeId"];
              //console.log("code ID : "+ CodeId);

              var Level = dataErr["Level"];
              //console.log("Level : "+ Level);

              var Denial;
              //console.log("Level : "+ Level);
              if (dataErr["Denial"] !== undefined) {
                Denial = dataErr["Denial"];
              } else {
                Denial = "__";
              }

              //here fill the record with all above data
              //record object

              var record = {
                EId: EncounterID,
                ELine: EncounterLine,
                ErrType: ErrorType,
                ErrLine: ErrorLine,
                Msg: ErrorMessage,
                CId: CodeId,
                Type: Type,
                Level: Level,
                Denial: Denial,
              };
              records.push(record);
            }); //parse data errors Codes
          } //parse dataerrors

          /* ----------###########Schema Error##########---------*/
          if (Encounter["Schema-Errors"] !== undefined) {
            // console.log("inside schema errors of encounter");

            var schemaErrorsArray = Encounter["Schema-Errors"][0];
            //console.log(schemaErrorsArray);

            if (schemaErrorsArray["Date"] !== undefined) {
              var EncounterID = EncounterId;
              //console.log("Encounter ID : "+ EncounterID);

              var EncounterLine = line;
              //console.log("Encounter Line : "+ EncounterLine);

              var ErrorLine = schemaErrorsArray["Date"][0]["Line"];
              //console.log("Error Line : "+ ErrorLine);

              var ErrorMessage = schemaErrorsArray["Date"][0]["Message"];
              //console.log("Error Message : "+ ErrorMessage);

              var ErrorType = "Schema-Error";
              //console.log("Error Type:" +ErrorType);

              var Type = 0;
              //console.log("Type : "+ Type);

              var CodeId = 0;
              //console.log("code ID : "+ CodeId);

              var Level;
              //console.log("Level : "+ Level);
              if (schemaErrorsArray["Date"][0]["Level"] !== undefined) {
                Level = schemaErrorsArray["Date"][0]["Level"];
              } else {
                Level = "__";
              }

              //add this record to file
              var record = {
                EId: EncounterID,
                ELine: EncounterLine,
                ErrType: ErrorType,
                ErrLine: ErrorLine,
                Msg: ErrorMessage,
                CId: CodeId,
                Type: Type,
                Level: Level,
                Denial: "__",
              };
              records.push(record);
            } ////////////

            if (schemaErrorsArray["Weight"] !== undefined) {
              //have date error

              EncounterID = EncounterId;
              // console.log("Encounter ID : "+ EncounterID);

              EncounterLine = line;
              //console.log("Encounter Line : "+ EncounterLine);

              ErrorLine = schemaErrorsArray["Weight"][0]["Line"];
              //console.log("Error Line : "+ ErrorLine);

              ErrorMessage = schemaErrorsArray["Weight"][0]["Message"];
              //console.log("Error Message : "+ ErrorMessage);

              ErrorType = "Schema-Error";
              //console.log("Error Type:" +ErrorType);

              Type = 0;
              //console.log("Type : "+ Type);

              CodeId = 0;
              //console.log("code ID : "+ CodeId);

              //console.log("Level : "+ Level);
              if (schemaErrorsArray["Weight"][0]["Level"] !== undefined) {
                Level = schemaErrorsArray["Weight"][0]["Level"];
              } else {
                Level = "__";
              }

              //add this record to file
              record = {
                EId: EncounterID,
                ELine: EncounterLine,
                ErrType: ErrorType,
                ErrLine: ErrorLine,
                Msg: ErrorMessage,
                CId: CodeId,
                Type: Type,
                Level: Level,
                Denial: "__",
              };
              records.push(record);
            } ///////

            if (schemaErrorsArray["BirthDate"] !== undefined) {
              //have date error

              EncounterID = EncounterId;
              //console.log("Encounter ID : "+ EncounterId);

              EncounterLine = line;
              //console.log("Encounter Line : "+ EncounterLine);

              ErrorLine = schemaErrorsArray["BirthDate"][0]["Line"];
              //console.log("Error Line : "+ ErrorLine);

              ErrorMessage = schemaErrorsArray["BirthDate"][0]["Message"];
              //console.log("Error Message : "+ ErrorMessage);

              ErrorType = "Schema-Error";
              //console.log("Error Type:" +ErrorType);

              Type = 0;
              //console.log("Type : "+ Type);

              CodeId = 0;
              //console.log("code ID : "+ CodeId);

              //console.log("Level : "+ Level);
              if (schemaErrorsArray["BirthDate"][0]["Level"] !== undefined) {
                Level = schemaErrorsArray["BirthDate"][0]["Level"];
              } else {
                Level = "__";
              }

              //add this record to file

              record = {
                EId: EncounterID,
                ELine: EncounterLine,
                ErrType: ErrorType,
                ErrLine: ErrorLine,
                Msg: ErrorMessage,
                CId: CodeId,
                Type: Type,
                Level: Level,
                Denial: "__",
              };
              records.push(record);
            } ///////

            if (schemaErrorsArray["Gender"] !== undefined) {
              //have date error

              EncounterID = EncounterId;
              //console.log("Encounter ID : "+ EncounterId);

              EncounterLine = line;
              //console.log("Encounter Line : "+ EncounterLine);

              ErrorLine = schemaErrorsArray["Gender"][0]["Line"];
              //console.log("Error Line : "+ ErrorLine);

              ErrorMessage = schemaErrorsArray["Gender"][0]["Message"];
              //console.log("Error Message : "+ ErrorMessage);

              ErrorType = "Schema-Error";
              //console.log("Error Type:" +ErrorType);

              Type = 0;
              //console.log("Type : "+ Type);

              CodeId = 0;
              //console.log("code ID : "+ CodeId);

              //console.log("Level : "+ Level);
              if (schemaErrorsArray["Gender"][0]["Level"] !== undefined) {
                Level = schemaErrorsArray["Gender"][0]["Level"];
              } else {
                Level = "__";
              }

              //add this record to file

              record = {
                EId: EncounterID,
                ELine: EncounterLine,
                ErrType: ErrorType,
                ErrLine: ErrorLine,
                Msg: ErrorMessage,
                CId: CodeId,
                Type: Type,
                Level: Level,
                Denial: "__",
              };
              records.push(record);
            } ///////

            if (schemaErrorsArray["Code"] !== undefined) {
              //have date error

              var CodeSchemaErrorArray = schemaErrorsArray["Code"];
              //console.log(CodeSchemaErrorArray);

              CodeSchemaErrorArray.forEach(function (codeErr) {
                //console.log(codeErr);

                EncounterID = EncounterId;
                //console.log("Encounter ID : "+ EncounterID);

                EncounterLine = line;
                //console.log("Encounter Line : "+ EncounterLine);

                ErrorType = "Schema-Error";
                //console.log("Error Type:" +ErrorType);

                ErrorLine = codeErr["Line"];
                //console.log("Error Line : "+ ErrorLine);

                ErrorMessage = codeErr["Message"];
                //console.log("Error Message : "+ ErrorMessage);

                Type = 0;
                //
                //console.log("Type : "+ Type);

                CodeId = codeErr["CodeId"];
                //console.log("code ID : "+ CodeId);

                var Level;
                Level = codeErr["Level"];
                //console.log("Error Line : "+ ErrorLine);

                //console.log("Level : "+ Level);
                if (codeErr["Level"] !== undefined) {
                  Level = codeErr["Level"];
                } else {
                  Level = "__";
                }

                //here fill the record with all above data

                var record = {
                  EId: EncounterID,
                  ELine: EncounterLine,
                  ErrType: ErrorType,
                  ErrLine: ErrorLine,
                  Msg: ErrorMessage,
                  CId: CodeId,
                  Type: Type,
                  Level: Level,
                  Denial: "__",
                };
                records.push(record);
              });
            } ///////
          } //parse schema errors
        }); //parsing ready;

        this.setState({ rows: records });

        //console.log("finished");
        this.setState({ upload: false }); //stop the spinner
        this.setState({ flag: true }); //to view the download button and table
        this.setState({ status2: "true" });

        this.setState({ leftStyle: "left" }); //margin the div left
        if (requestType === "file") {
          document.getElementById("requestFile").value = null; //delete file name when finished
        }
        if (requestType === "template") {
          this.setState({ flagTemplate: true }); //to view the download button(xml generated file)
        }
      } else if (Claims.length > 0) {
        console.log("claim request faild");

        console.log(Claims);

        Claims.forEach(async function (Encounter) {
          //if the request not contain any record
          if (Encounter["Message"] != undefined) {
            var line = Encounter["Line"];
            var ErrorMessage = Encounter["Message"];

            var record = {
              EId: "-",
              VisitNumber: "-",
              ELine: "-",
              ErrType: "Schema-Error",
              ErrLine: line,
              Msg: ErrorMessage,
              CId: "-",
              Type: "-",
              Level: "-",
              Denial: "-",
              ClaimID: "-",
              ActivityID: "-",
            };

            records.push(record);
          }
          //for each Encounter
          var EncounterId = Encounter["EncounterId"];

          var line = Encounter["Line"];

          var VisitNumber = Encounter["VisitNumber"];

          /*----------########Data Error########----------------*/
          if (Encounter["Data-Errors"] !== undefined) {
            var dataErrorsArray = Encounter["Data-Errors"][0]["Code"];

            dataErrorsArray.forEach(async function (dataErr) {
              var EncounterID = EncounterId;
              var EncounterLine = line;
              var ErrorType = "Data-Error";
              var ErrorLine = dataErr["Line"];
              var ErrorMessage = dataErr["Message"];
              var Type = dataErr["Type"];
              var CodeId = dataErr["CodeId"];
              var Level = dataErr["Level"];
              var Denial;
              if (dataErr["Denial"] !== undefined) {
                Denial = dataErr["Denial"];
              } else {
                Denial = "-";
              }
              var ClaimID;
              if (dataErr["ClaimID"][0] !== "") {
                ClaimID = dataErr["ClaimID"];
              } else {
                ClaimID = "-";
              }

              var ActivityID;

              if (dataErr["ActivityID"] && dataErr["ActivityID"][0] !== "") {
                ActivityID = dataErr["ActivityID"];
              } else {
                ActivityID = "-";
              }

              var ActivityCode;

              if (
                dataErr["ActivityCode"] &&
                dataErr["ActivityCode"][0] !== ""
              ) {
                ActivityCode = dataErr["ActivityCode"];
              } else {
                ActivityCode = "__";
              }
              //extract diagnosis code from message
              var DiagnosisCode;
              if (
                Type[0].includes("ICD") ||
                Type[0] == "Pregnancy Notification"
              ) {
                var regExp = /\(([^)]+)\)/;
                var str;
                if (Type[0] == "ICD-ICD") {
                  str = ErrorMessage[0].split("Diagnosis")[1];
                } else if (
                  Type[0] == "Incorrect HCPCS-ICD Relation" ||
                  Type[0] == "Incorrect CPT-ICD Relation" ||
                  Type[0] == "Incorrect Dental-ICD Relation" ||
                  Type[0] == "Incorrect Drug-ICD Relation"
                ) {
                  str = ErrorMessage[0].split("diagnoses")[1];
                } else {
                  str = ErrorMessage[0].split("code")[1];
                }
                var matches = regExp.exec(str);
                if (matches && matches.length > 0) {
                  DiagnosisCode = matches[1];
                }
              }

              //here fill the record with all above data
              //record object

              var record = {
                EId: EncounterID,
                VisitNumber,
                ELine: EncounterLine,
                ErrType: ErrorType,
                ErrLine: ErrorLine,
                Msg: ErrorMessage,
                CId: CodeId,
                Type: Type,
                Level: Level,
                Denial: Denial,
                ClaimID: ClaimID,
                ActivityID: ActivityID,
                ActivityCode: ActivityCode,
                DiagnosisCode: DiagnosisCode,
              };
              records.push(record);
            }); //parse data errors Codes
          } //parse dataerrors

          /* ----------###########Schema Error##########---------*/

          if (Encounter["Schema-Errors"] !== undefined) {
            var schemaErrorsArray = Encounter["Schema-Errors"][0];

            //schema errors related to CLaim

            if (schemaErrorsArray["Claim"] !== undefined) {
              var ClaimSchemaErrorArray = schemaErrorsArray["Claim"];
              // console.log("chemaErrorsArray['Claim']");

              //console.log(ClaimSchemaErrorArray);

              ClaimSchemaErrorArray.forEach(function (codeErr) {
                var EncounterID = EncounterId;
                //console.log("Encounter ID : "+ EncounterID);

                var EncounterLine = line;
                //console.log("Encounter Line : "+ EncounterLine);

                var ErrorType = "Schema-Error";
                //console.log("Error Type:" +ErrorType);

                var ErrorLine = codeErr["Line"];
                //console.log("Error Line : "+ ErrorLine);

                var ErrorMessage = codeErr["Message"];
                //console.log("Error Message : "+ ErrorMessage);

                var Type = 0;
                //
                //console.log("Type : "+ Type);

                var CodeId = 0;
                //console.log("code ID : "+ CodeId);

                var Level;
                Level = codeErr["Level"];
                //console.log("Error Line : "+ ErrorLine);

                //console.log("Level : "+ Level);
                if (codeErr["Level"] !== undefined) {
                  Level = codeErr["Level"];
                } else {
                  Level = "__";
                }

                var ClaimID;

                if (codeErr["ClaimId"][0] !== "") {
                  ClaimID = codeErr["ClaimId"];
                } else {
                  ClaimID = "__";
                }

                var ActivityID;

                if (codeErr["ActivityID"] && codeErr["ActivityID"][0] !== "") {
                  ActivityID = codeErr["ActivityID"][0];
                } else {
                  ActivityID = "__";
                }

                //here fill the record with all above data

                var record = {
                  EId: EncounterID,
                  VisitNumber,
                  ELine: EncounterLine,
                  ErrType: ErrorType,
                  ErrLine: ErrorLine,
                  Msg: ErrorMessage,
                  CId: CodeId,
                  Type: Type,
                  Level: Level,
                  Denial: "__",
                  ClaimID: ClaimID,
                  ActivityID: ActivityID,
                };

                records.push(record);
              });
            }

            if (schemaErrorsArray["Diagnosis"] !== undefined) {
              var DiagnosisSchemaErrorArray = schemaErrorsArray["Diagnosis"];
              // console.log("chemaErrorsArray['Diagnosis']");

              //console.log(DiagnosisSchemaErrorArray);

              DiagnosisSchemaErrorArray.forEach(function (codeErr) {
                var EncounterID = EncounterId;
                //console.log("Encounter ID : "+ EncounterID);

                var EncounterLine = line;
                //console.log("Encounter Line : "+ EncounterLine);

                var ErrorType = "Schema-Error";
                //console.log("Error Type:" +ErrorType);

                var ErrorLine = codeErr["Line"];
                //console.log("Error Line : "+ ErrorLine);

                var ErrorMessage = codeErr["Message"];
                //console.log("Error Message : "+ ErrorMessage);

                var Type = 0;
                //console.log("Type : "+ Type);

                var CodeId = 0;
                //console.log("code ID : "+ CodeId);

                var Level;
                Level = codeErr["Level"];
                //console.log("Error Line : "+ ErrorLine);

                //console.log("Level : "+ Level);
                if (codeErr["Level"] !== undefined) {
                  Level = codeErr["Level"];
                } else {
                  Level = "__";
                }

                var ClaimID;

                if (codeErr["ClaimId"] !== undefined) {
                  ClaimID = codeErr["ClaimId"];
                } else {
                  ClaimID = "__";
                }

                //here fill the record with all above data

                var record = {
                  EId: EncounterID,
                  VisitNumber,
                  ELine: EncounterLine,
                  ErrType: ErrorType,
                  ErrLine: ErrorLine,
                  Msg: ErrorMessage,
                  CId: CodeId,
                  Type: Type,
                  Level: Level,
                  Denial: "__",
                  ClaimID: ClaimID,
                  ActivityID: "__",
                };
                records.push(record);
              });
            }

            if (schemaErrorsArray["Activity"] !== undefined) {
              DiagnosisSchemaErrorArray = schemaErrorsArray["Activity"];

              //console.log(DiagnosisSchemaErrorArray);

              DiagnosisSchemaErrorArray.forEach(function (codeErr) {
                var EncounterID = EncounterId;
                //console.log("Encounter ID : "+ EncounterID);

                var EncounterLine = line;
                //console.log("Encounter Line : "+ EncounterLine);

                var ErrorType = "Schema-Error";
                //console.log("Error Type:" +ErrorType);

                var ErrorLine = codeErr["Line"];
                //console.log("Error Line : "+ ErrorLine);

                var ErrorMessage = codeErr["Message"];
                //console.log("Error Message : "+ ErrorMessage);

                var Type = 0;

                //console.log("Type : "+ Type);

                var CodeId = 0;
                //console.log("code ID : "+ CodeId);

                var Level;
                Level = codeErr["Level"];
                //console.log("Error Line : "+ ErrorLine);

                //console.log("Level : "+ Level);
                if (codeErr["Level"] !== undefined) {
                  Level = codeErr["Level"];
                } else {
                  Level = "__";
                }

                var ClaimID;

                if (codeErr["ClaimId"] !== undefined) {
                  ClaimID = codeErr["ClaimId"];
                } else {
                  ClaimID = "__";
                }

                var ActivityID;

                if (codeErr["ActivityID"] !== undefined) {
                  ActivityID = codeErr["ActivityID"];
                } else {
                  ActivityID = "__";
                }

                var ActivityCode;

                if (codeErr["ActivityCode"] !== undefined) {
                  ActivityCode = codeErr["ActivityCode"];
                } else {
                  ActivityCode = "__";
                }

                //here fill the record with all above data

                var record = {
                  EId: EncounterID,
                  VisitNumber,
                  ELine: EncounterLine,
                  ErrType: ErrorType,
                  ErrLine: ErrorLine,
                  Msg: ErrorMessage,
                  CId: CodeId,
                  Type: Type,
                  Level: Level,
                  Denial: "__",
                  ClaimID: ClaimID,
                  ActivityID: ActivityID,
                  ActivityCode: ActivityCode,
                };
                records.push(record);
              });
            }

            if (schemaErrorsArray["Start"] !== undefined) {
              DiagnosisSchemaErrorArray = schemaErrorsArray["Start"];

              //console.log(DiagnosisSchemaErrorArray);

              DiagnosisSchemaErrorArray.forEach(function (codeErr) {
                var EncounterID = EncounterId;
                //console.log("Encounter ID : "+ EncounterID);

                var EncounterLine = line;
                //console.log("Encounter Line : "+ EncounterLine);

                var ErrorType = "Schema-Error";
                //console.log("Error Type:" +ErrorType);

                var ErrorLine = codeErr["Line"];
                //console.log("Error Line : "+ ErrorLine);

                var ErrorMessage = codeErr["Message"];
                //console.log("Error Message : "+ ErrorMessage);

                var Type = 0;

                //console.log("Type : "+ Type);

                var CodeId = 0;
                //console.log("code ID : "+ CodeId);

                var Level;
                Level = codeErr["Level"];
                //console.log("Error Line : "+ ErrorLine);

                //console.log("Level : "+ Level);
                if (codeErr["Level"] !== undefined) {
                  Level = codeErr["Level"];
                } else {
                  Level = "__";
                }

                var ClaimID;

                if (codeErr["ClaimId"] !== undefined) {
                  ClaimID = codeErr["ClaimId"];
                } else {
                  ClaimID = "__";
                }

                var ActivityID;

                if (codeErr["ActivityID"] !== undefined) {
                  ActivityID = codeErr["ActivityID"];
                } else {
                  ActivityID = "__";
                }

                var ActivityCode;

                if (codeErr["ActivityCode"] !== undefined) {
                  ActivityCode = codeErr["ActivityCode"];
                } else {
                  ActivityCode = "__";
                }

                //here fill the record with all above data

                var record = {
                  EId: EncounterID,
                  VisitNumber,
                  ELine: EncounterLine,
                  ErrType: ErrorType,
                  ErrLine: ErrorLine,
                  Msg: ErrorMessage,
                  CId: CodeId,
                  Type: Type,
                  Level: Level,
                  Denial: "__",
                  ClaimID: ClaimID,
                  ActivityID: ActivityID,
                  ActivityCode: ActivityCode,
                };
                records.push(record);
              });
            }

            if (schemaErrorsArray["CodeTerm"] !== undefined) {
              DiagnosisSchemaErrorArray = schemaErrorsArray["CodeTerm"];

              //console.log(DiagnosisSchemaErrorArray);

              DiagnosisSchemaErrorArray.forEach(function (codeErr) {
                var EncounterID = EncounterId;
                //console.log("Encounter ID : "+ EncounterID);

                var EncounterLine = line;
                //console.log("Encounter Line : "+ EncounterLine);

                var ErrorType = "Schema-Error";
                //console.log("Error Type:" +ErrorType);

                var ErrorLine = codeErr["Line"];
                //console.log("Error Line : "+ ErrorLine);

                var ErrorMessage = codeErr["Message"];
                //console.log("Error Message : "+ ErrorMessage);

                var Type = 0;

                //console.log("Type : "+ Type);

                var CodeId = 0;
                //console.log("code ID : "+ CodeId);

                var Level;
                Level = codeErr["Level"];
                //console.log("Error Line : "+ ErrorLine);

                //console.log("Level : "+ Level);
                if (codeErr["Level"] !== undefined) {
                  Level = codeErr["Level"];
                } else {
                  Level = "__";
                }

                var ClaimID;

                if (codeErr["ClaimId"] !== undefined) {
                  ClaimID = codeErr["ClaimId"];
                } else {
                  ClaimID = "__";
                }

                var ActivityID;

                if (codeErr["ActivityID"] !== undefined) {
                  ActivityID = codeErr["ActivityID"];
                } else {
                  ActivityID = "__";
                }

                var ActivityCode;

                if (codeErr["ActivityCode"] !== undefined) {
                  ActivityCode = codeErr["ActivityCode"];
                } else {
                  ActivityCode = "__";
                }

                //here fill the record with all above data

                var record = {
                  EId: EncounterID,
                  VisitNumber,
                  ELine: EncounterLine,
                  ErrType: ErrorType,
                  ErrLine: ErrorLine,
                  Msg: ErrorMessage,
                  CId: CodeId,
                  Type: Type,
                  Level: Level,
                  Denial: "__",
                  ClaimID: ClaimID,
                  ActivityID: ActivityID,
                  ActivityCode: ActivityCode,
                };
                records.push(record);
              });
            }

            if (schemaErrorsArray["Encounter"] !== undefined) {
              DiagnosisSchemaErrorArray = schemaErrorsArray["Encounter"];
              // console.log("chemaErrorsArray['Encounter']");

              //console.log(DiagnosisSchemaErrorArray);

              DiagnosisSchemaErrorArray.forEach(function (codeErr) {
                var EncounterID = EncounterId;
                //console.log("Encounter ID : "+ EncounterID);

                var EncounterLine = line;
                //console.log("Encounter Line : "+ EncounterLine);

                var ErrorType = "Schema-Error";
                //console.log("Error Type:" +ErrorType);

                var ErrorLine = codeErr["Line"];
                //console.log("Error Line : "+ ErrorLine);

                var ErrorMessage = codeErr["Message"];
                //console.log("Error Message : "+ ErrorMessage);

                var Type = 0;
                //
                //console.log("Type : "+ Type);

                var CodeId = 0;
                //console.log("code ID : "+ CodeId);

                var Level;
                Level = codeErr["Level"];
                //console.log("Error Line : "+ ErrorLine);

                //console.log("Level : "+ Level);
                if (codeErr["Level"] !== undefined) {
                  Level = codeErr["Level"];
                } else {
                  Level = "__";
                }

                var ClaimID;

                if (codeErr["ClaimId"] !== undefined) {
                  ClaimID = codeErr["ClaimId"];
                } else {
                  ClaimID = "__";
                }

                //here fill the record with all above data

                var record = {
                  EId: EncounterID,
                  VisitNumber,
                  ELine: EncounterLine,
                  ErrType: ErrorType,
                  ErrLine: ErrorLine,
                  Msg: ErrorMessage,
                  CId: CodeId,
                  Type: Type,
                  Level: Level,
                  Denial: "__",
                  ClaimID: ClaimID,
                  ActivityID: "__",
                };
                records.push(record);
              });
            }

            if (schemaErrorsArray["Type"] !== undefined) {
              var EncounterID = EncounterId;
              //console.log("Encounter ID : "+ EncounterID);

              var EncounterLine = line;
              //console.log("Encounter Line : "+ EncounterLine);

              var ErrorLine = schemaErrorsArray["Type"][0]["Line"];
              //console.log("Error Line : "+ ErrorLine);

              var ErrorMessage = schemaErrorsArray["Type"][0]["Message"];
              //console.log("Error Message : "+ ErrorMessage);

              var ErrorType = "Schema-Error";
              //console.log("Error Type:" +ErrorType);

              var Type = 0;
              //console.log("Type : "+ Type);

              var CodeId = 0;
              //console.log("code ID : "+ CodeId);

              //console.log("Level : "+ Level);
              if (schemaErrorsArray["Type"][0]["Level"] !== undefined) {
                Level = schemaErrorsArray["Type"][0]["Level"];
              } else {
                Level = "__";
              }

              var ClaimID;

              if (schemaErrorsArray["Type"][0]["ClaimId"] !== undefined) {
                ClaimID = schemaErrorsArray["Type"][0]["ClaimId"];
              } else {
                ClaimID = "__";
              }

              var ActivityID;

              if (schemaErrorsArray["Type"][0]["ActivityID"] !== undefined) {
                ActivityID = schemaErrorsArray["Type"][0]["ActivityID"][0];
              } else {
                ActivityID = "__";
              }

              //add this record to file
              record = {
                EId: EncounterID,
                VisitNumber,
                ELine: EncounterLine,
                ErrType: ErrorType,
                ErrLine: ErrorLine,
                Msg: ErrorMessage,
                CId: CodeId,
                Type: Type,
                Level: Level,
                Denial: "__",
                ClaimID: ClaimID,
                ActivityID: ActivityID,
              };
              records.push(record);
            }

            if (schemaErrorsArray["Code"] !== undefined) {
              var CodeSchemaErrorArray = schemaErrorsArray["Code"];

              CodeSchemaErrorArray.forEach(function (codeErr) {
                EncounterID = EncounterId;
                //console.log("Encounter ID : "+ EncounterID);

                EncounterLine = line;
                //console.log("Encounter Line : "+ EncounterLine);

                ErrorType = "Schema-Error";
                //console.log("Error Type:" +ErrorType);

                ErrorLine = codeErr["Line"];
                //console.log("Error Line : "+ ErrorLine);

                ErrorMessage = codeErr["Message"];
                //console.log("Error Message : "+ ErrorMessage);

                Type = 0;
                //
                //console.log("Type : "+ Type);

                CodeId = 0;
                //console.log("code ID : "+ CodeId);

                var Level;
                Level = codeErr["Level"];
                //console.log("Error Line : "+ ErrorLine);

                //console.log("Level : "+ Level);
                if (codeErr["Level"] !== undefined) {
                  Level = codeErr["Level"];
                } else {
                  Level = "__";
                }

                var ClaimID;

                if (codeErr["ClaimId"] !== undefined) {
                  ClaimID = codeErr["ClaimId"];
                } else {
                  ClaimID = "__";
                }

                var ActivityID;

                if (codeErr[" ActivityID"] !== undefined) {
                  ClaimID = codeErr[" ActivityID"];
                } else {
                  ClaimID = "__";
                }

                //here fill the record with all above data

                var record = {
                  EId: EncounterID,
                  VisitNumber,
                  ELine: EncounterLine,
                  ErrType: ErrorType,
                  ErrLine: ErrorLine,
                  Msg: ErrorMessage,
                  CId: CodeId,
                  Type: Type,
                  Level: Level,
                  Denial: "__",
                  ClaimID: ClaimID,
                  ActivityID: ActivityID,
                };
                records.push(record);
              });
            }
            if (schemaErrorsArray["Quantity"] !== undefined) {
              var CodeSchemaErrorArray = schemaErrorsArray["Quantity"];

              CodeSchemaErrorArray.forEach(function (codeErr) {
                EncounterID = EncounterId;
                //console.log("Encounter ID : "+ EncounterID);

                EncounterLine = line;
                //console.log("Encounter Line : "+ EncounterLine);

                ErrorType = "Schema-Error";
                //console.log("Error Type:" +ErrorType);

                ErrorLine = codeErr["Line"];
                //console.log("Error Line : "+ ErrorLine);

                ErrorMessage = codeErr["Message"];
                //console.log("Error Message : "+ ErrorMessage);

                Type = 0;
                //
                //console.log("Type : "+ Type);

                CodeId = 0;
                //console.log("code ID : "+ CodeId);

                var Level;
                Level = codeErr["Level"];
                //console.log("Error Line : "+ ErrorLine);

                //console.log("Level : "+ Level);
                if (codeErr["Level"] !== undefined) {
                  Level = codeErr["Level"];
                } else {
                  Level = "__";
                }

                var ClaimID;

                if (codeErr["ClaimId"] !== undefined) {
                  ClaimID = codeErr["ClaimId"];
                } else {
                  ClaimID = "__";
                }

                var ActivityID;

                if (codeErr["ActivityID"] !== undefined) {
                  ActivityID = codeErr["ActivityID"];
                } else {
                  ActivityID = "__";
                }

                //here fill the record with all above data

                var record = {
                  EId: EncounterID,
                  VisitNumber,
                  ELine: EncounterLine,
                  ErrType: ErrorType,
                  ErrLine: ErrorLine,
                  Msg: ErrorMessage,
                  CId: CodeId,
                  Type: Type,
                  Level: Level,
                  Denial: "__",
                  ClaimID: ClaimID,
                  ActivityID: ActivityID,
                };
                records.push(record);
              });
            }

            //"=========" //not required
            if (schemaErrorsArray["Date"] !== undefined) {
              EncounterID = EncounterId;
              //console.log("Encounter ID : "+ EncounterID);

              EncounterLine = line;
              //console.log("Encounter Line : "+ EncounterLine);

              var ErrorLine = schemaErrorsArray["Date"][0]["Line"];
              //console.log("Error Line : "+ ErrorLine);

              var ErrorMessage = schemaErrorsArray["Date"][0]["Message"];
              //console.log("Error Message : "+ ErrorMessage);

              var ErrorType = "Schema-Error";
              //console.log("Error Type:" +ErrorType);

              var Type = 0;
              //console.log("Type : "+ Type);

              var CodeId = 0;
              //console.log("code ID : "+ CodeId);

              var Level;
              //console.log("Level : "+ Level);
              if (schemaErrorsArray["Date"][0]["Level"] !== undefined) {
                Level = schemaErrorsArray["Date"][0]["Level"];
              } else {
                Level = "__";
              }

              //add this record to file
              var record = {
                EId: EncounterID,
                ELine: EncounterLine,
                ErrType: ErrorType,
                ErrLine: ErrorLine,
                Msg: ErrorMessage,
                CId: CodeId,
                Type: Type,
                Level: Level,
                Denial: "__",
              };
              records.push(record);
            }

            if (schemaErrorsArray["Weight"] !== undefined) {
              //have date error

              EncounterID = EncounterId;
              // console.log("Encounter ID : "+ EncounterID);

              EncounterLine = line;
              //console.log("Encounter Line : "+ EncounterLine);

              ErrorLine = schemaErrorsArray["Weight"][0]["Line"];
              //console.log("Error Line : "+ ErrorLine);

              ErrorMessage = schemaErrorsArray["Weight"][0]["Message"];
              //console.log("Error Message : "+ ErrorMessage);

              ErrorType = "Schema-Error";
              //console.log("Error Type:" +ErrorType);

              Type = 0;
              //console.log("Type : "+ Type);

              CodeId = 0;
              //console.log("code ID : "+ CodeId);

              //console.log("Level : "+ Level);
              if (schemaErrorsArray["Weight"][0]["Level"] !== undefined) {
                Level = schemaErrorsArray["Weight"][0]["Level"];
              } else {
                Level = "__";
              }

              //add this record to file
              record = {
                EId: EncounterID,
                ELine: EncounterLine,
                ErrType: ErrorType,
                ErrLine: ErrorLine,
                Msg: ErrorMessage,
                CId: CodeId,
                Type: Type,
                Level: Level,
                Denial: "__",
              };
              records.push(record);
            }

            if (schemaErrorsArray["BirthDate"] !== undefined) {
              //have date error

              EncounterID = EncounterId;
              //console.log("Encounter ID : "+ EncounterId);

              EncounterLine = line;
              //console.log("Encounter Line : "+ EncounterLine);

              ErrorLine = schemaErrorsArray["BirthDate"][0]["Line"];
              //console.log("Error Line : "+ ErrorLine);

              ErrorMessage = schemaErrorsArray["BirthDate"][0]["Message"];
              //console.log("Error Message : "+ ErrorMessage);

              ErrorType = "Schema-Error";
              //console.log("Error Type:" +ErrorType);

              Type = 0;
              //console.log("Type : "+ Type);

              CodeId = 0;
              //console.log("code ID : "+ CodeId);

              //console.log("Level : "+ Level);
              if (schemaErrorsArray["BirthDate"][0]["Level"] !== undefined) {
                Level = schemaErrorsArray["BirthDate"][0]["Level"];
              } else {
                Level = "__";
              }

              //add this record to file

              record = {
                EId: EncounterID,
                ELine: EncounterLine,
                ErrType: ErrorType,
                ErrLine: ErrorLine,
                Msg: ErrorMessage,
                CId: CodeId,
                Type: Type,
                Level: Level,
                Denial: "__",
              };
              records.push(record);
            }

            if (schemaErrorsArray["Gender"] !== undefined) {
              //have date error

              EncounterID = EncounterId;
              //console.log("Encounter ID : "+ EncounterId);

              EncounterLine = line;
              //console.log("Encounter Line : "+ EncounterLine);

              ErrorLine = schemaErrorsArray["Gender"][0]["Line"];
              //console.log("Error Line : "+ ErrorLine);

              ErrorMessage = schemaErrorsArray["Gender"][0]["Message"];
              //console.log("Error Message : "+ ErrorMessage);

              ErrorType = "Schema-Error";
              //console.log("Error Type:" +ErrorType);

              Type = 0;
              //console.log("Type : "+ Type);

              CodeId = 0;
              //console.log("code ID : "+ CodeId);

              //console.log("Level : "+ Level);
              if (schemaErrorsArray["Gender"][0]["Level"] !== undefined) {
                Level = schemaErrorsArray["Gender"][0]["Level"];
              } else {
                Level = "__";
              }

              //add this record to file

              record = {
                EId: EncounterID,
                ELine: EncounterLine,
                ErrType: ErrorType,
                ErrLine: ErrorLine,
                Msg: ErrorMessage,
                CId: CodeId,
                Type: Type,
                Level: Level,
                Denial: "__",
              };
              records.push(record);
            }
          } //parse schema errors
        }); //parsing ready;

        this.setState({ rowsClaim: records });
        this.setState({ upload: false }); //stop the do
        this.setState({ flagClaim: true }); //to view the download button and table
        this.setState({ ClaimStatus: "true" });
        this.setState({ leftStyle: "left" }); //margin the div left

        if (requestType === "file") {
          document.getElementById("requestFile").value = null; //delete file name when finished
        }
        if (requestType === "template") {
          this.setState({ flagTemplate: true }); //to view the download button(xml generated file)
        }
      }
    }
  };

  // Event fired when the input value is changed
  onChange = ({ target: { name, value } }, listName) => {
    this.setState({ isAdded: false, existInDb: false });
    if (value.length <= 1) {
      value = value.trim();
    }

    switch (listName) {
      case "DiagnosisCodes":
        this.setState({
          section: "Diagnosis",
        });
        break;
      default:
        this.setState({
          section: "Activity",
        });
        break;
    }

    if (listName === "DiagnosisCodes" && value === "") {
      this.setState({
        BtnActive: false,
        userInputDiagnosis: "",
        userInputActivity: "",
      });
    } else if (listName === "ActivityCodes" && value === "") {
      this.setState({
        //BtnActive: false,
        userInputDiagnosis: "",
        userInputActivity: "",
      });
    } else if (listName === "DiagnosisCodes") {
      this.setState({ attributes: ["Type"], BtnActive: true });
      let suggestions;

      if (!this.state.seetingView) {
        if (
          this.state.PrefereddiagnosisItems &&
          this.state.PrefereddiagnosisItems.length > 0
        ) {
          suggestions = this.state.PrefereddiagnosisItems;
        } else {
          suggestions = this.state.diagnosisItems;
        }
      } else {
        let { preferedCodesOperation } = this.state;
        if (preferedCodesOperation == "delete") {
          suggestions = this.state.PrefereddiagnosisItems;
        } else {
          suggestions = this.state.diagnosisItems;
        }
      }

      let filteredSuggestions = [];

      if (value.length > 1) {
        const regex = new RegExp(`${value.toLowerCase()}`); //filter
        filteredSuggestions = suggestions
          .sort()
          .filter((v) => regex.test(v.toLowerCase()));
        if (filteredSuggestions && filteredSuggestions.length == 0) {
          suggestions = this.state.diagnosisItems;
          filteredSuggestions = suggestions
            .sort()
            .filter((v) => regex.test(v.toLowerCase()));
        }
      }

      // Update the user input and filtered suggestions, reset the active
      // suggestion and make sure the suggestions are shown
      this.setState({
        activeSuggestion: 0,
        filteredSuggestionsDiagnosis: filteredSuggestions,
        showDiagnosisSuggestions: true,
        userInputDiagnosis: value,
      });
    } else {
      let suggestions = [];

      this.setState({ BtnActive: true });
      if (!this.state.seetingView) {
        if (
          this.state.preferedActivityCodes &&
          this.state.preferedActivityCodes.length > 0
        ) {
          suggestions = this.state.preferedActivityCodes;
        } else {
          suggestions = this.state.ActivityItems;
        }
      } else {
        let { preferedCodesOperation } = this.state;
        if (preferedCodesOperation == "delete") {
          suggestions = this.state.preferedActivityCodes;
        } else {
          suggestions = this.state.ActivityItems;
        }
      }
      let filteredSuggestions = [];
      if (value.length > 1) {
        console.log("suggestions");
        console.log(suggestions);
        const regex = new RegExp(`${value.toLowerCase()}`);
        filteredSuggestions = suggestions
          .sort()
          .filter((v) => regex.test(v.toLowerCase()));

        if (filteredSuggestions && filteredSuggestions.length == 0) {
          console.log("here ");
          suggestions = this.state.ActivityItems;
          filteredSuggestions = suggestions
            .sort()
            .filter((v) => regex.test(v.toLowerCase()));
        }
      }

      // Update the user input and filtered suggestions, reset the active
      // suggestion and make sure the suggestions are shown

      this.setState({
        activeSuggestion: 0,
        filteredSuggestionsActivity: filteredSuggestions,
        showActivitySuggestions: true,
        userInputActivity: value,
      });
    }
  };
  // Event fired when the user clicks on a suggestion
  onClick = (e) => {
    let code = e.currentTarget.innerText;

    // Update the user input and reset the rest of the state
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showDiagnosisSuggestions: false,
      showActivitySuggestions: false,
    });

    if (
      this.state.filteredSuggestionsActivity &&
      this.state.filteredSuggestionsActivity.length > 0
    ) {
      this.setState({
        // userInputActivity:this.state.filteredSuggestionsActivity[this.state.activeSuggestion]
        userInputActivity: e.currentTarget.innerText,
        filteredSuggestionsActivity: [],
      });
    }

    if (
      this.state.filteredSuggestionsDiagnosis &&
      this.state.filteredSuggestionsDiagnosis.length > 0
    ) {
      this.setState({
        attributes: ["Type"],

        userInputDiagnosis: e.currentTarget.innerText,
        filteredSuggestionsDiagnosis: [],
      });
    }

    //push to code
    this.setState({ code: { ...this.state.code, Text: code } });
  };
  downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([this.state.templateRequest], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "request.xml";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  handleItemDeletion = (item, listName, idx) => {
    if (listName === "DiagnosisCodes") {
      let diagnosis = this.state.DiagnosisCodes;
      //let diagnosis=this.state.data.Diagnosis

      const index = diagnosis.indexOf(item);
      if (index > -1) {
        diagnosis = diagnosis.filter(function (i) {
          return i !== item;
        });

        this.setState({
          DiagnosisCodes: diagnosis,
          data: { ...this.state.data, Diagnosis: diagnosis },
        });
      }
    } else {
      let activity = this.state.ActivityCodes;

      const index = activity.indexOf(item);
      if (index > -1) {
        activity = activity.filter(function (i) {
          return i !== item;
        });

        this.setState({
          ActivityCodes: activity,
          data: { ...this.state.data, Activity: activity },
        });
      }
    }
  };

  handleClaimDeletion = (item, index) => {
    let claims = this.state.encounters;

    // const index=claims.indexOf(item)
    if (index > -1) {
      claims = claims.filter(function (i, idx) {
        return idx !== index;
      });

      this.setState({
        encounters: claims,
      });
    }
  };
  handlingLinkAction1 = () => {
    this.setState({
      isChoised: false,
      requestType: undefined,
      seetingView: false,
      rulesUpdateStatus: false,
    });
    // window.location.reload(false);
    this.setState(initialState);
    this.setState({ encounters: [] });
  };
  handlingLinkAction2 = () => {
    let { isChoised, requestType } = this.state;

    if (isChoised && requestType === "template") {
      this.setState({
        requestType: "file",
      });
      //window.location.reload(false);
      this.setState(initialState);
      this.setState({ encounters: [] });
    } else if (isChoised && requestType === "file") {
      this.setState({
        requestType: "template",
      });
      this.setState(initialState);
      this.setState({ encounters: [] });
    } else if (requestType === "RulesMgt") {
      this.setState({
        requestType: "PrefCodesMgt",
      });
    } else if (requestType == "PrefCodesMgt") {
      this.setState({
        requestType: "RulesMgt",
      });
    }
  };

  handleClear = () => {
    this.setState(initialStateTables);
    this.setState({
      encounters: [],
      ActivityCodes: [],
      DiagnosisCodes: [],
      data: {},
    });

    document.getElementById("PatientAge").value = "";
    document.getElementById("PatientGender").value = 0;
    document.getElementById("EncounterType").value = "";

    document.getElementById("activitySource").value = 0;
    if (document.getElementById("activityCode")) {
      document.getElementById("activityCode").innerText = "";
    }

    if (document.getElementById("diagnosisCode")) {
      document.getElementById("diagnosisCode").innerText = "";
    }
    if (document.getElementById("Quantity")) {
      document.getElementById("Quantity").value = "";
    }
    if (document.getElementById("ToothNumber")) {
      document.getElementById("ToothNumber").value = "";
    }
  };
  onClickSeeting = (requestType) => {
    this.setState({
      seetingView: true,
      requestType,
      ActivityCodes: [],
      DiagnosisCodes: [],
    });
  };

  onChangeRulesStatus = (e) => {
    let checkBoxId = e.target.id;
    let rulesStatus = this.state.rulesStatus;
    rulesStatus[checkBoxId] = !rulesStatus[checkBoxId];
    this.setState({ rulesStatus });
  };

  onUpdateRulesStatus = (e) => {
    let rulesStatus = this.state.rulesStatus;

    axios
      .put(KayanURL + "api/user/updateRule", {
        apiToken: Cookies.get("id"),
        ruleName: JSON.stringify(rulesStatus),
      })
      .then(({ data }) => {
        if (data.success) {
          this.setState({ rulesUpdateStatus: true });
          document.getElementById("rulesUpdateAlert").scrollIntoView();
        }
      });
  };

  addPreferedCode = () => {
    let { preferedCodesOperation } = this.state;
    let code = "";
    let type = this.state.preferedCodesType;

    if (preferedCodesOperation == "add") {
      if (type == "icd") {
        let preferedIcds = this.state.PrefereddiagnosisItems;
        let DiagnosisCodes = this.state.DiagnosisCodes;
        DiagnosisCodes.forEach((c) => {
          if (preferedIcds == undefined) {
            if (code.length == 0) {
              code += "'" + c.Text + "'";
            } else {
              code += ",'" + c.Text + "'";
            }
          } else if (preferedIcds && preferedIcds.indexOf(c.Text) == -1) {
            if (code.length == 0) {
              code += "'" + c.Text + "'";
            } else {
              code += ",'" + c.Text + "'";
            }
          }
        });
      } else {
        let ActivityCodes = this.state.ActivityCodes;
        let preferedActivity = this.state.preferedActivityCodes;
        ActivityCodes.forEach((c) => {
          if (preferedActivity == undefined) {
            if (code.length == 0) {
              code += "'" + c.Text + "'";
            } else {
              code += ",'" + c.Text + "'";
            }
          } else if (
            preferedActivity &&
            preferedActivity.indexOf(c.Text) == -1
          ) {
            if (code.length == 0) {
              code += "'" + c.Text + "'";
            } else {
              code += ",'" + c.Text + "'";
            }
          }
        });
      }
      if (code && code.length > 0 && code != "") {
        axios
          .post(KayanURL + "api/user/addPreferedCodes/" + type, {
            codes: code,
            apiToken: Cookies.get("id"),
          })
          .then(({ data }) => {
            if (data.success) {
              this.setState({
                isAdded: true,
                preferedCodesType: null,
                DiagnosisCodes: [],
                ActivityCodes: [],
              });

              // this.getAllPreferedDiagnosis();
            }
          });
      } else {
        console.log("codes exists in db");
        this.setState({ isAdded: true, DiagnosisCodes: [], ActivityCodes: [] });
        this.setState({ existInDb: true, preferedCodesType: null });
      }
    } else if (preferedCodesOperation == "delete") {
      let newCodes = "";
      if (type == "icd") {
        let preferedIcds = this.state.PrefereddiagnosisItems;

        let AddedCodes = this.state.DiagnosisCodes;
        let DiagnosisCodes = [];
        AddedCodes.forEach((c) => {
          DiagnosisCodes.push(c.Text.trim());
        });

        if (preferedIcds) {
          preferedIcds.forEach((c) => {
            if (DiagnosisCodes.indexOf(c.trim()) == -1) {
              newCodes += "," + c;
            }
          });
        }
      } else {
        let preferedActivityCode = this.state.preferedActivityCodes;
        let AddedActivityCodes = this.state.ActivityCodes;
        let ActivityCodes = [];
        AddedActivityCodes.forEach((c) => {
          ActivityCodes.push(c.Text);
        });

        if (preferedActivityCode) {
          preferedActivityCode.forEach((c) => {
            if (ActivityCodes.indexOf(c) == -1) {
              newCodes += "," + c;
            }
          });
        }
      }

      //axios

      //all codes deleted from prefered codes
      if (newCodes == undefined || newCodes == "") {
        newCodes = null;
      } else if (newCodes.length > 0 && newCodes[0] == ",") {
        newCodes = "'" + newCodes.substring(1) + "'";
        //newCodes = newCodes.substring(1)
      }

      axios
        .post(KayanURL + "api/user/updatePreferedCodes/" + type, {
          apiToken: Cookies.get("id"),
          codes: newCodes,
        })
        .then(({ data }) => {
          if (data.success) {
            this.setState({
              isAdded: true,
              DiagnosisCodes: [],
              ActivityCodes: [],
              preferedCodesType: null,
            });
            //this.getAllPreferedDiagnosis();
          }
        });
    }

    //reset the dropdown list
    let CodeTypeSelect = document.getElementById("codeType");
    CodeTypeSelect.options[0].selected = true;
    let operationTypeSelect = document.getElementById("operationType");
    operationTypeSelect.options[0].selected = true;
  };

  render() {
    const {
      companyType,
      flag,
      flagClaim,
      flagSyntaxError,
      upload,
      status,
      status2,
      ClaimStatus,
      SyntaxErrorStatus,
      performanceStatus,
      preferedCodesOperation,
      preferedCodesType,
      fileName,
      PrefereddiagnosisItems,
    } = this.state;

    var fileNameCsv = fileName.split(".")[0] + "-report.csv";

    var BayanHeader;
    var ClaimHeader;
    if (companyType === "Provider") {
      /*     BayanHeader = [
        { label: "Encounter Sequence", key: "EId" },
        { label: "Visit Number", key: "VisitNumber" },
        { label: "Encounter Line", key: "ELine" },
        // { label: "Error Type", key: "ErrType" },
        { label: "Error Line", key: "ErrLine" },
        { label: "Error Message", key: "Msg" },
        { label: "Element Sequence", key: "CId" },
        { label: "Type", key: "Type" },

        { label: "Edit Level", key: "Level" },
      ]; */
      ClaimHeader = [
        { label: "Claim ID", key: "ClaimID" },
        { label: "Activity ID", key: "ActivityID" },
        { label: "Encounter Sequence", key: "EId" },
        { label: "Visit Number", key: "VisitNumber" },
        { label: "Claim Line", key: "ELine" },
        { label: "Edit Line", key: "ErrLine" },
        { label: "Edit Message", key: "Msg" },
        { label: "Activity Code", key: "ActivityCode" },
        { label: "Diagnosis Code", key: "DiagnosisCode" },
        { label: "Type", key: "Type" },

        { label: "Level", key: "Level" },
      ];
    } else {
      /*  BayanHeader = [
        { label: "Encounter Sequence", key: "EId" },
        { label: "Encounter Line", key: "ELine" },

        { label: "Error Line", key: "ErrLine" },
        { label: "Error Message", key: "Msg" },
        { label: "Element Sequence", key: "CId" },
        { label: "Type", key: "Type" },
        { label: "Denial", key: "Denial" },
        { label: "Edit Level", key: "Level" },
      ]; */
      ClaimHeader = [
        { label: "Claim ID", key: "ClaimID" },
        { label: "Activity ID", key: "ActivityID" },
        { label: "Encounter Sequence", key: "EId" },
        { label: "Visit Number", key: "VisitNumber" },
        { label: "Claim Line", key: "ELine" },
        { label: "Edit Line", key: "ErrLine" },
        { label: "Edit Message", key: "Msg" },
        { label: "Activity Code", key: "ActivityCode" },
        { label: "Diagnosis Code", key: "DiagnosisCode" },

        { label: "Type", key: "Type" },
        { label: "Denial", key: "Denial" },
        { label: "Level", key: "Level" },
      ];
    }

    /*  var listTable;
    if (this.state.rows.length > 0) {
      listTable = this.state.rows.map((row, index) => {
        return (
          <tr key={index}>
            <td>{row["EId"]}</td>
            <td>{row["VisitNumber"]}</td>
            <td>{row["ELine"]}</td>
            <td>{row["ErrLine"]}</td>
            <td style={{ textAlign: "left" }}>{row["Msg"]}</td>
            <td>{row["CId"]}</td>
            <td>{row["Type"]}</td>
            {companyType === "Provider" ? null : <td>{row["Denial"]}</td>}

            {row["Level"] === "__" ? (
              <td>{row["Level"]}</td>
            ) : row["Level"] === "Review" ? (
              <td style={{ color: "#ffa500" }}>{row["Level"]}</td>
            ) : (
              <td style={{ color: "#ff0000" }}>{row["Level"]}</td>
            )}
          </tr>
        );
      });
    } */

    var SyntaxErrorListTable;
    if (this.state.SyntaxErr.length > 0) {
      SyntaxErrorListTable = this.state.SyntaxErr.map((row, index) => {
        return (
          <tr key={index}>
            <td>{row["Message"]}</td>
            <td>{row["Line"]}</td>
          </tr>
        );
      });
    }

    var ClaimListTable;

    if (this.state.rowsClaim.length > 0) {
      ClaimListTable = this.state.rowsClaim.map((row, index) => {
        return (
          <tr key={index}>
            <td>{row["ClaimID"]}</td>
            <td>{row["ActivityID"]}</td>
            <td>{row["EId"]}</td>
            <td>{row["VisitNumber"]}</td>
            <td>{row["ELine"]}</td>
            <td>{row["ErrLine"]}</td>
            <td
              style={
                {
                  /* textAlign: "left" */
                }
              }
            >
              {row["Msg"]}
            </td>
            <td>{row["ActivityCode"] === null ? "__" : row["ActivityCode"]}</td>
            <td>
              {row["DiagnosisCode"] == undefined ? "-" : row["DiagnosisCode"]}
            </td>
            <td>{row["Type"]}</td>
            {companyType === "Provider" ? null : <td>{row["Denial"]}</td>}
            {row["Level"] === "__" ? (
              <td>{row["Level"]}</td>
            ) : row["Level"] === "Review" ? (
              <td style={{ color: "#ffa500" }}>{row["Level"]}</td>
            ) : (
              <td style={{ color: "#ff0000" }}>{row["Level"]}</td>
            )}
          </tr>
        );
      });
    }

    const {
      flagTemplate,
      inputsHaveErrors,
      isChoised,
      requestType,
      DiagnosisCodes,
      ActivityCodes,
      BtnActive,
      isPrincipalChecked,
      section,
      attributes,
      encounters,
      seetingView,
      rulesStatus,
      rulesUpdateStatus,
    } = this.state;
    let rulesNames;
    if (rulesStatus) {
      rulesNames = Object.keys(rulesStatus);
      //console.log(rulesNames);
    }

    /*     if (
      this.state.toggleState === 5 &&
      this.state.toggleState != 2 &&
      this.state.requestType != "file"
    ) {
      this.setState({ requestType: "file" });
    }

    if (
      this.state.toggleState === 2 &&
      this.state.toggleState != 5 &&
      this.state.requestType != "CSVfile"
    ) {
      this.setState({ requestType: "CSVfile" });
    } */

    if (this.state.toggleState === 6 && this.state.requestType != "template") {
      this.setState({ requestType: "template" });
    }

    return (
      <>
        <NavBar userType={companyType} />
        <div style={{ width: "80%", marginLeft: "20%" }}>
          {/*  <Header
            onClickSeeting={this.onClickSeeting}
            name={localStorage.getItem("name")}
            usertype="employee"
            history={this.props.history}
          /> */}
        </div>
        <div>
          <Sub
            history={this.props.history}
            projectType="Kareem"
            toggleState={this.state.toggleState}
            usertype="employee"
            toggleTab={this.toggleTab}
            name={localStorage.getItem("name")}
          >
            {this.state.toggleState === 1 && <MainScreenBayan />}

            {this.state.toggleState === 6 && (
              <React.Fragment>
                {
                  <Form
                    formStyle="template"
                    onChange={this.onChange}
                    onClick={this.onClick}
                    onKeyDown={this.onKeyDown}
                    allDiagnosis={this.state.diagnosisItems}
                    allActivity={this.state.ActivityItems}
                    /*   allDiagnosis={this.state.PrefereddiagnosisItems}
                              allActivity={this.state.preferedActivityCodes} */
                    userInputDiagnosis={this.state.userInputDiagnosis}
                    userInputActivity={this.state.userInputActivity}
                    activeSuggestion={this.state.activeSuggestion}
                    filteredSuggestionsActivity={
                      this.state.filteredSuggestionsActivity
                    }
                    filteredSuggestionsDiagnosis={
                      this.state.filteredSuggestionsDiagnosis
                    }
                    showDiagnosisSuggestions={
                      this.state.showDiagnosisSuggestions
                    }
                    showActivitySuggestions={this.state.showActivitySuggestions}
                    handleItemDeletion={this.handleItemDeletion}
                    handleClear={this.handleClear}
                    Style="Search"
                    inputStyle="IT3"
                    submitStyle="BS1"
                    dangerStyle="dangerBS1"
                    linkStyle="Link2"
                    labelStyle="BayanLabelStyle"
                    onSubmit={this.addNewEncounter}
                    linkAction1={this.handlingLinkAction1}
                    linkAction2={this.handlingLinkAction2}
                    onInputTextChange={this.handleInputTextChange}
                    onAddNewCodeInputChange={this.onAddNewCodeInputChange}
                    onSelectOption={this.onSelectOption}
                    addNewCode={this.addNewCode}
                    BtnActive={BtnActive}
                    isPrincipalChecked={isPrincipalChecked}
                    section={section}
                    attributes={attributes}
                    diagnosisNow={this.state.diagnosisNow}
                    ActivityNow={this.state.ActivityNow}
                    inputsTypes={[
                      "section",
                      "text",
                      "number",
                      "select",
                      "select",

                      "section",
                      "list",
                      "section",
                      "ActivityList",
                      "link",
                    ]}
                    elementsValues={[
                      "Patient Information",
                      "Provider ID" /* [this.state.license, this.state.license] */,
                      [{ min: 0, step: "any" }],
                      [
                        ["Male", "Female"],
                        ["M", "F"],
                      ],
                      [
                        [
                          "No Bed + No emergency room(outpatient)",
                          "No Bed + Emergency room(outpatient)",
                          "Inpatient Bed + No emergency room",
                          "Inpatient Bed + Emergency room",
                          "Daycase Bed + No emergency room",
                          "Daycase Bed + Emergency room",
                          "Nationals Screening",
                          "New Visa Screening",
                          "Renewal Visa Screening",
                          "Home",
                          "Assisted Living Facility",
                          "Mobile Unit",
                          "Ambulance  Land",
                          "Ambulance  Air or Water",
                        ],
                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 12, 13, 15, 41, 42],
                      ],
                      "Diagnosis",
                      DiagnosisCodes,
                      "Activities",
                      ActivityCodes,
                      "clear",
                      "Generate",
                    ]}
                    elementsNames={[
                      "PatientInformation",
                      "ProviderID",
                      "PatientAge",
                      "PatientGender",
                      "EncounterType",
                      "Diagnosis",
                      "DiagnosisCodes",
                      "Activity",
                      "ActivityCodes",
                      "Clear",
                    ]}
                    inputsHaveErrors={inputsHaveErrors}
                    label1="Go to "
                    linkText1=" Home "
                    label2="Or"
                    linkText2=" File Upload? "
                  />
                }

                <div id="BtnsContainer"></div>
                {
                  encounters.length > 0 ? (
                    <React.Fragment>
                      <ListView
                        Items={encounters}
                        name="encounters"
                        handleClaimDeletion={this.handleClaimDeletion}
                      />
                      <div className="BtnsContainer">
                        <Button
                          Style="claimOrReq"
                          Value="submit the request"
                          onClick={(e) => this.doRequest(e)}
                        />
                      </div>
                    </React.Fragment>
                  ) : null
                  //<Button Style="BS1" Value="Add Claim" onClick={this.onClickEncounterTrigger} />
                }
              </React.Fragment>
            )}

            {this.state.toggleState === 5 && (
              <Form
                submitStyle="BS10"
                linkStyle="Link2"
                labelStyle="BayanLabelStyle"
                onSubmit={this.doRequest}
                /*  linkAction1={this.handlingLinkAction1}
                linkAction2={this.handlingLinkAction2} */
                onInputTextChange={this.handleInputTextChange}
                /*  onRadioChange={this.handleRadioChange} */
                inputsTypes={["file"]}
                elementsValues={["Upload"]}
                elementsNames={["requestFile"]}
                /*  label1="Go to "
                linkText1=" Home "
                label2="Or"
                linkText2=" Form Upload? " */
              />
            )}

            {/*     {this.state.toggleState === 2 && (
              <Form
                hint="CSV file"
                submitStyle="BS10"
                linkStyle="Link2"
                labelStyle="BayanLabelStyle"
                onSubmit={this.doRequest}
                onInputTextChange={this.handleInputTextChange}
                inputsTypes={["file"]}
                elementsValues={["Upload"]}
                elementsNames={["requestCSVFile"]}
              />
            )} */}
          </Sub>
        </div>

        <div className=" container">
          {seetingView && requestType == "RulesMgt" ? (
            <div style={{ margin: "100px 0 100px 286px" }}>
              {rulesUpdateStatus ? (
                <Alert id="rulesUpdateAlert" variant="success">
                  Rules updated Successfully
                </Alert>
              ) : null}

              <div className="row">
                {rulesStatus != undefined ? (
                  rulesNames.map((rule) => {
                    return (
                      <CheckBox
                        key={rule}
                        text={rule}
                        checked={rulesStatus[rule]}
                        onChange={(e) => this.onChangeRulesStatus(e)}
                      />
                    );
                  })
                ) : (
                  <Spinner
                    animation="border"
                    variant="success"
                    style={{ display: "block", margin: "auto" }}
                  />
                )}
              </div>
              <Button
                Value="update"
                onClick={this.onUpdateRulesStatus}
                Style="updateBtn"
              />
            </div>
          ) : seetingView && requestType == "PrefCodesMgt" ? (
            <PreferedCodesScreen
              status={this.state.isAdded}
              existInDb={this.state.existInDb}
              onClick={this.onClick}
              /*  allDiagnosis={this.state.PrefereddiagnosisItems}
                 allActivity={this.state.preferedActivityCodes} */
              onSelect={this.onSelectOperation}
              operation={preferedCodesOperation}
              allPreferedDiagnosis={this.state.PrefereddiagnosisItems}
              allPreferedActivity={this.state.preferedActivityCodes}
              allDiagnosis={
                this.state.preferedCodesType == "icd"
                  ? this.state.diagnosisItems
                  : this.state.ActivityItems
              }
              codeType={preferedCodesType}
              addNewCode={this.addNewCode}
              onChange={this.onChange}
              onInputTextChange={this.handleInputTextChange}
              userInputDiagnosis={
                this.state.preferedCodesType == "icd"
                  ? this.state.userInputDiagnosis
                  : this.state.userInputActivity
              }
              showDiagnosisSuggestions={
                this.state.preferedCodesType == "icd"
                  ? this.state.showDiagnosisSuggestions
                  : this.state.showActivitySuggestions
              }
              filteredSuggestionsDiagnosis={
                this.state.preferedCodesType == "icd"
                  ? this.state.filteredSuggestionsDiagnosis
                  : this.state.filteredSuggestionsActivity
              }
              listName={
                this.state.preferedCodesType == "icd"
                  ? "DiagnosisCodes"
                  : "ActivityCodes"
              }
              diagnosisNow={
                this.state.preferedCodesType == "icd"
                  ? this.state.diagnosisNow
                  : this.state.ActivityNow
              }
              activeSuggestion={this.state.activeSuggestion}
              Items={
                this.state.preferedCodesType == "icd"
                  ? DiagnosisCodes
                  : ActivityCodes
              }
              BtnActive={BtnActive}
              addPreferedCode={this.addPreferedCode}
              handleItemDeletion={this.handleItemDeletion}
              submitStyle="BS1"
            />
          ) : null}

          {!seetingView ? (
            <div style={{ margin: "0 0 100px 286px" }}>
              {requestType === "template" && flagTemplate ? (
                <button
                  onClick={this.downloadTxtFile}
                  style={{
                    backgroundColor: "#ffffff",
                    border: "none",
                    textDecoration: "underline",
                    marginBottom: "15px",
                  }}
                >
                  Click here to download the generated xml request
                </button>
              ) : null}

              {status2 || ClaimStatus ? (
                <h5 style={{ color: "red" }}>Your file {fileName} has edits</h5>
              ) : null}

              {/*    {flag ? (
                <div style={{ marginBottom: "20px" }}>
                  <CSVLink
                    filename={fileNameCsv}
                    style={{ color: "#ff0505", textDecoration: "underline" }}
                    data={this.state.rows}
                   // data={listTable} headers={this.state.BayanHeader}
                    headers={BayanHeader}
                  >
                    Please click here to download the edits report
                  </CSVLink>{" "}
                </div>
              ) : null} */}
              {flagClaim ? (
                <div style={{ marginBottom: "20px" }}>
                  <CSVLink
                    filename={fileNameCsv}
                    style={{ color: "#ff0505", textDecoration: "underline" }}
                    data={this.state.rowsClaim}
                    /*headers={this.state.ClaimHeader}*/ headers={ClaimHeader}
                  >
                    Please click here to download the edits report
                  </CSVLink>{" "}
                </div>
              ) : null}

              <div id="uploading">
                {upload ? (
                  <div>
                    <span style={{ textAlign: "center", color: "#3ab449" }}>
                      Uploading {fileName}
                    </span>{" "}
                    <Spinner size="sm" animation="grow" variant="success" />
                    <Spinner animation="grow" variant="success" size="sm" />
                    <Spinner animation="grow" variant="success" size="sm" />
                  </div>
                ) : null}
              </div>

              {requestType === "file" && status ? (
                <h5 style={{ color: "#3ab449" }}>
                  Your file {fileName} is free of edits
                </h5>
              ) : null}

              {requestType === "fileCSV" && status ? (
                <h5 style={{ color: "#3ab449" }}>
                  Your file {fileName} is free of edits
                </h5>
              ) : null}
              {requestType === "template" && flagTemplate && status ? (
                <h5 style={{ color: "#3ab449" }}>
                  Your request is free of edits
                </h5>
              ) : null}

              {SyntaxErrorStatus ? (
                <h5 style={{ color: "red" }}>
                  Your file {fileName} have syntax error
                </h5>
              ) : null}

              {performanceStatus ? (
                <h5 style={{ color: "red" }}>{performanceStatus}</h5>
              ) : null}

              {flagSyntaxError ? (
                <Table
                  responsive
                  striped
                  bordered
                  hover
                  size="sm"
                  style={{ width: "50%", margin: "10px auto" }}
                >
                  <thead style={{ background: "#3ab449", color: " white" }}>
                    <tr>
                      <th style={{ width: "15%", verticalAlign: "middle" }}>
                        Error Message
                      </th>
                      <th style={{ width: "15%", verticalAlign: "middle" }}>
                        Error Line
                      </th>
                    </tr>
                  </thead>
                  <tbody>{SyntaxErrorListTable}</tbody>
                </Table>
              ) : null}

              {/*        {flag ? (
                <Table responsive striped bordered hover size="sm">
                  <thead style={{ background: "#3ab449", color: " white" }}>
                    <tr>
                      <th style={{ width: "15%", verticalAlign: "middle" }}>
                        Encounter Sequence
                      </th>
                      <th style={{ width: "12%", verticalAlign: "middle" }}>
                        Encounter Line
                      </th>

                      <th style={{ verticalAlign: "middle", width: "8%" }}>
                        Error Line
                      </th>
                      <th style={{ verticalAlign: "middle" }}>Error Message</th>
                      <th style={{ verticalAlign: "middle", width: "14%" }}>
                        Element Sequence
                      </th>
                      <th style={{ verticalAlign: "middle" }}>Type</th>
                      {companyType === "Provider" ? null : (
                        <th
                          style={{
                            paddingLeft: "25px",
                            paddingRight: "25px",
                            verticalAlign: "middle",
                          }}
                        >
                          Denial
                        </th>
                      )}

                      <th style={{ verticalAlign: "middle", width: "8%" }}>
                        Edit Level
                      </th>
                    </tr>
                  </thead>
                  <tbody>{listTable}</tbody>
                </Table>
              ) : null} */}

              {flagClaim && this.state.requestType != "fileCSV" ? (
                <Table responsive striped bordered hover size="sm">
                  <thead style={{ background: "#3ab449", color: " white" }}>
                    <tr>
                      <th
                        style={{
                          verticalAlign: "middle",
                          paddingLeft: "30px",
                          paddingRight: "30px",
                        }}
                      >
                        Claim ID
                      </th>
                      <th style={{ verticalAlign: "middle" }}>Activity ID</th>
                      <th style={{ verticalAlign: "middle", width: "1px" }}>
                        Encounter Sequence
                      </th>
                      <th style={{ verticalAlign: "middle", width: "1px" }}>
                        VisitNumber
                      </th>
                      <th style={{ verticalAlign: "middle" }}>Claim Line</th>
                      <th style={{ verticalAlign: "middle" }}>Edit Line</th>
                      <th style={{ verticalAlign: "middle" }}>Edit Message</th>
                      <th style={{ verticalAlign: "middle" }}>Activity Code</th>
                      <th style={{ verticalAlign: "middle" }}>
                        Diagnosis Code
                      </th>

                      <th style={{ verticalAlign: "middle" }}>Type</th>
                      {companyType === "Provider" ? null : (
                        <th
                          style={{
                            paddingLeft: "25px",
                            paddingRight: "25px",
                            verticalAlign: "middle",
                          }}
                        >
                          Denial
                        </th>
                      )}

                      <th style={{ verticalAlign: "middle" }}>Level</th>
                    </tr>
                  </thead>
                  <tbody>{ClaimListTable}</tbody>
                </Table>
              ) : null}
            </div>
          ) : null}
        </div>

        <div style={{ height: "410px" }}></div>
      </>
    );
  }
}

export default Employee;
