import React, { Component } from "react";

class AddNewClinicians extends Component {
  render() {
    /*


 <select onChange={this.onSelectFieldName} id="fields">
                  <option value="">Select</option>
                  <option value="name">Employee Name</option>
                  <option value="username">Username</option>
                  <option value="password">Password</option>
                  <option value="dhpouser">DHPO User</option>
                  <option value="dhpopassword">DHPO Password</option>
                  <option value="active">Status</option>
                  <option value="allowedPrivileges">Allowed Privileges</option>
                </select>

*/

    let { departments } = this.props;
    let options;
    if (departments && departments.length > 0) {
      options = departments.map((element) => {
        return (
          <option value={element["id"]}>{element["department_name"]}</option>
        );
      });
    }

    return (
      <>
        <form
          onSubmit={this.props.onSubmit}
          style={
            {
              /* marginTop: "-250px" */
            }
          }
        >
          <select
            onChange={this.props.onSelectDepartmentName}
            id="fields"
            required
          >
            <option value="">Department Name</option>
            {options}
          </select>

          {departments && departments.length == 0 ? (
            <span style={{ color: "red" }}></span>
          ) : null}

          <input
            required
            type="text"
            placeholder="Clinician ID"
            name="name"
            onChange={this.props.handleInputTextChange}
            required
            style={{ width: "70%", padding: "8px" }}
          />
          <br />
          <br />

          <input type="submit" value="Add New Department" className="BS4" />
        </form>
      </>
    );
  }
}

export default AddNewClinicians;
