import React from "react";
import Form from "./../Components/Form/index"
import axios from "axios";
import Cookies from "js-cookie";
import { CSVLink } from "react-csv";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";



import settings from "../helpers/Settings";
let { KayanURL } = settings;

var parseString = require("xml2js").parseString;
class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Records: []
        };
    }



    // Read XML File content
    readXMLFile = (reader) => {
        // To Do: 1- Handling error (reader.onerror); 2- clear Input file for each change on input file.
        reader.onload = () => {
            if (reader.readyState === 2) {
                this.setState({ requestXML: reader.result });
            }
        };
    };

    handleInputTextChange = ({ target: { name, value, files } }) => {
        this.setState({ responseReady: false })
        this.setState({ fileName: files[0].name })

        switch (name) {

            case "requestFile":
                let reader = new FileReader();
                if (files.length > 0) {
                    reader.readAsText(files[0]);
                    this.readXMLFile(reader);
                }

                break;


            default:
                //  this.setState({ data: { ...this.state.data, [name]: value } });
                break;
        }
    };

    doRequest = async (event) => {
        event.preventDefault()
        console.log(this.state.requestXML);
        this.setState({dhpoResponse:false,responseReady:false,dhpoValidationErrorMessgae:null,sent:true})

       // this.setState({ MSG: "your file uploaded successfully" })
        const data = await axios.post(KayanURL + "api/sama/request/", {
            data: this.state.requestXML,
            apiToken:/*  Cookies.get("SamaId") */'eyJhbGciOiJIUzI1NiJ9.ZW1wbG95ZWU6MQ.CLEKBu7BpY9VxZVXhGR6BMFYWPJ1FWMR3-s9kwkcHXs',
        });
        let samaResponseXML = data.data
        //console.log(samaResponseXML);
        let Records = []
        parseString(samaResponseXML, function (err, result) {
            if (result) {
                let Response_Status = result['Response-Status']
                let { Status } = Response_Status

                if (Status && Status.length > 0 && Status[0] == 'Failed') {
                    let Request_Errors = Response_Status['Request-Errors'][0]['Claim']
                    //let Records = []
                    Request_Errors.forEach(claimErrors => {

                        let EncounterId = claimErrors['EncounterId'][0]
                        let Line = claimErrors['Line'][0]
                        console.log("Encounter ID");
                        console.log(EncounterId);
                        console.log("claim line");
                        console.log(Line);

                        //   console.log(claimErrors);

                        let SchemaErrorsArray = claimErrors['Schema-Errors']

                        if (SchemaErrorsArray != undefined) {
                            let SchemaErrors = SchemaErrorsArray[0]

                            let schemaErrorsTypes = Object.keys(SchemaErrors)
                            schemaErrorsTypes.forEach(type => {
                                let ErrorsofThisType = SchemaErrors[type]//array
                                ErrorsofThisType.forEach(err => {
                                    let ClaimId = err['ClaimId'][0]
                                    let errorLine = err['Line'][0]
                                    let Message = err['Message'][0]
                                    let Type = '-'
                                    let Level = '-'

                                    Records.push({ ClaimId, EncounterId, claimLine: Line, errorLine, Message, Type, Level })
                                })



                            })


                        }

                        let DataErrorsArray = claimErrors['Data-Errors']

                        if (DataErrorsArray) {
                            let DataErrors = DataErrorsArray[0]

                            if (DataErrors) {
                                let DataErrorsCodes = DataErrors['Code']
                                if (DataErrorsCodes) {

                                    DataErrorsCodes.forEach(err => {
                                        console.log(err);
                                        let ClaimId = err['ClaimID'] ? err['ClaimID'][0] : "-"
                                        let errorLine = err['Line'] ? err['Line'][0] : "-"
                                        let Message = err['Message'] ? err['Message'][0] : "-"
                                        let Type = err['Type'] ? err['Type'][0] : "-"
                                        let Level = err['Level'] ? err['Level'][0] : "-"

                                        Records.push({ ClaimId, EncounterId, claimLine: Line, errorLine, Message, Type, Level })

                                    })

                                }

                            }
                        }


                    });
                    // console.log(Records);
                    if (Records) {

                    }
                }
            }

        })
        this.setState({ Rows: Records, responseReady: true,sent:false })
        document.getElementById("requestFile").value = null;


    }
    onValidateDHPO = async (event) => {
        console.log("validate dhpo");
        this.setState({dhpoResponse:false,responseReady:false,dhpoValidationErrorMessgae:null,sent:true})
        event.preventDefault()
        const data = await axios.post(KayanURL + "api/sama/request/dhpoValidation/", {
            data: this.state.requestXML,
            apiToken:/*  Cookies.get("SamaId") */'eyJhbGciOiJIUzI1NiJ9.ZW1wbG95ZWU6MQ.CLEKBu7BpY9VxZVXhGR6BMFYWPJ1FWMR3-s9kwkcHXs',
            filename: this.state.fileName
        });

        let response = data['data']
        // console.log(response.split("\n"));
        console.log(response);
        if (response.errorMessage) {
            this.setState({dhpoValidationErrorMessgae:response.errorMessage,dhpoResponse: true,sent:false})
        }
        else{
            this.setState({ dhpoRows: response.split("\n"), dhpoResponse: true })

        }

        document.getElementById("requestFile").value = null;


    }


    render() {

        let { Rows, responseReady, dhpoRows, dhpoResponse,dhpoValidationErrorMessgae ,sent} = this.state
        let ClaimListTable
        if (Rows && Rows.length > 0) {
            ClaimListTable = Rows.map((row, index) => {
                
                return (
                    <tr key={index}>
                        <td>{row["ClaimId"]}</td>

                        <td>{row["EncounterId"]}</td>
                        <td>{row["claimLine"]}</td>
                        <td>{row["errorLine"]}</td>
                        <td style={{ textAlign: "left" }}>{row["Message"]}</td>

                        <td>{row["Type"]}</td>
                        {row["Level"] === "__" ? (
                            <td>{row["Level"]}</td>
                        ) : row["Level"] === "Review" ? (
                            <td style={{ color: "#ffa500" }}>{row["Level"]}</td>
                        ) : (
                                    <td style={{ color: "#ff0000" }}>{row["Level"]}</td>
                                )}
                    </tr>
                );
            });
        }
        let dhpoTable
        if (dhpoRows && dhpoRows.length > 0) {
            dhpoTable = dhpoRows.map((row, index) => {
             
                if (index!=0&&index!=dhpoRows.length-1) {
                    let elements=row.split(",")
                    return (
                        <tr key={index}>
                            {elements.map((e,i)=>{
                            return (<td key={i}>{e}</td>)

                            })}
                            
    
                        </tr>
                    );
                }
               
            })
        }



        return (
            <div className="container" >
                <Form
                    submitStyle="BS10"
                    //linkStyle="Link2"
                    labelStyle="BayanLabelStyle"
                    onSubmit={this.doRequest}
                    linkStyle="Link2"
                    handleClear={this.onValidateDHPO}
                    dangerStyle="BS10"

                    onInputTextChange={this.handleInputTextChange}
                    inputsTypes={["file", "link"]}
                    elementsValues={["file", "validate DHPO", "sama test"]}
                    elementsNames={["requestFile", "validateDHPO"]}

                />

               

                { responseReady ?
                 <Table responsive striped bordered hover size="sm" >
                    <thead style={{ background: "#3ab449", color: " white" }}>
                        <tr>
                            <th
                                style={{
                                    verticalAlign: "middle",
                                    paddingLeft: "30px",
                                    paddingRight: "30px",
                                }}
                            >
                                Claim ID
                            </th>
                            <th style={{ verticalAlign: "middle", width: "1px" }}>
                                Encounter Sequence
                            </th>
                            <th style={{ verticalAlign: "middle" }}>Claim Line</th>
                            <th style={{ verticalAlign: "middle" }}>Edit Line</th>
                            <th style={{ verticalAlign: "middle" }}>Edit Message</th>
                            <th style={{ verticalAlign: "middle" }}>Type</th>
                            <th style={{ verticalAlign: "middle" }}>Level</th>
                        </tr>
                    </thead>
                    <tbody>{ ClaimListTable }</tbody>
                </Table>

                    : dhpoResponse&&!dhpoValidationErrorMessgae ?

                    <div>
                        <Table responsive striped bordered hover size="sm" >
                        <thead style={{ background: "#3ab449", color: " white" }}>
                        <tr>
                            {dhpoRows[0].split(",").map((r,index)=>{
                                return (
                                    <th key={index}> {r} </th>
                                )
                            }

                             )}
                        </tr>
                        </thead>
                         <tbody>{dhpoTable }</tbody>
                  </Table>
                    </div> :
                     dhpoValidationErrorMessgae?<h6 style={{color:"red"}}>{dhpoValidationErrorMessgae}</h6>:!responseReady&&!dhpoValidationErrorMessgae&&sent
                     ?
                     <Spinner
                   animation="border"
                   variant="success"
                   style={{ display: "block", margin: "auto" }}
                 />:
                 null
                   }


            </div >


        );
    }
}

export default FileUpload;
