import React, { Component } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Table from "react-bootstrap/Table";
import Button from "../../Components/Button/index";
import Spinner from "react-bootstrap/Spinner";
import KareemNetworks from "./AddNewNetwork";
import settings from "../../helpers/Settings";
import UpdateNetworkModule from "./UpdateNetworkModule";
import UploadNetworkExcel from "../../KareemBenefits/uploadNetworksExcel";
let { KayanURL } = settings;

class KareemNetworksModule extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onClickAddNewNetwork = this.onClickAddNewNetwork.bind(this);
    this.getAllNetworks = this.getAllNetworks.bind(this);
    this.onClickUploadNetworks = this.onClickUploadNetworks.bind(this);
  }
  componentDidMount = async () => {
    this.getAllNetworks();

    /* let Networks = localStorage.getItem("Networks");

    if (!Networks) {
      this.getAllNetworks();
    } else {
      this.setState({ Networks: JSON.parse(Networks) });
    } */
  };

  //get All NEtworks

  async getAllNetworks() {
    this.setState({ flag: true });
    let response = await axios.put(
      KayanURL+"api/sama/networks/getAllNetworks",
      {
        apiToken: Cookies.get("SamaId"),
      }
    );

    if (response) {
      let { data } = response;
      if (data.success) {
        this.setState({ Networks: data.Networks, flag: false });
        localStorage.setItem("Networks", JSON.stringify(data.Networks));
      } else {
        let { error } = data;
        if (error) {
          if (Array.isArray(error) && error.length > 0) {
            this.setState({ error: error[0] });
          } else {
            this.setState({ error: error });
          }
        }
      }
    }
  }

  DeleteNetwork = async (event) => {
    event.preventDefault();
    let { Networkid } = this.state;
    console.log(Networkid);
    let response = await axios.put(
      KayanURL+"api/sama/networks/deletenetwork",
      {
        apiToken: Cookies.get("SamaId"),
        networkid: Networkid,
      }
    );
    if (response) {
      let { data } = response;
      if (data.success) {
        this.setState({
          success: true,
          DeleteNetwork: false,
          flag: false,
        });
      }
    }
    let Newnetworks = await axios.put(
      KayanURL+"api/sama/networks/getAllNetworks",
      {
        apiToken: Cookies.get("SamaId"),
      }
    );
    let { data } = Newnetworks;
    if (data.success) {
      this.setState({ Networks: data.Networks, flag: false,DeleteNetwork: false });
      localStorage.setItem("Networks", JSON.stringify(data.Networks));
    }
  };

  onClickAddNewNetwork() {
    this.setState({ AddNewNetworkModule: true });
  }
  onClickUploadNetworks() {
    this.setState({ UploadNetworksScreen: true });
  }
  render() {
    let {
      Networks,
      AddNewNetworkModule,
      flag,
      error,
      updateNetworkScreen,
      networkToUpdate,
      UploadNetworksScreen,
    } = this.state;
    let NetworksTable;
// console.log(Networks)
    if (Networks && Networks.length > 0) {
      NetworksTable = Networks.map((row, index) => {
        let discount = [];

        if (row["discount"]) {
          let discountKeys = Object.keys(row["discount"]);
          discount = discountKeys.map((key, index) => {
            return (
              <tr key={index}>
                <td>{key}</td>
                <td>{row["discount"][key]}%</td>
              </tr>
            );
          });
        }

        let patientShare = [];
        if (row["patient_share"]) {
          let discountKeys = Object.keys(row["patient_share"]);
          patientShare = discountKeys.map((key, index) => {
            return (
              <tr key={index}>
                <td>{key}</td>
                <td>{row["patient_share"][key]}%</td>
              </tr>
            );
          });
        }
        let payers = "";
        let p3="";
        let p2=[]
        if (row["payer_id"]) {
          row["payer_id"].forEach((id, index) => {
            if (index != row["payer_id"].length - 1) {
              payers += id + ",";
            } else {
              payers += id;
            }
          });
        }
        let payerUnderTPA
        let checkEclaimlink_id
        if(row.tpa){
          payerUnderTPA= JSON.stringify(row.payer_id)
          if(payerUnderTPA.includes("eclaimlink_id")){
            checkEclaimlink_id=true
            let p=JSON.parse(payerUnderTPA)
            for(let i=0;i<p.length;i++){
              p2.push(p[i].eclaimlink_id)
            }
            p2.forEach((id, index) => {
              if (index != p2.length - 1) {
                p3 += id + ",";
              } else {
                p3 += id;
              }
            });
          }else{
            checkEclaimlink_id=false
          }
        }
        return (
          <tr key={index}>
            <td>{row["tpa"]}</td>
            {row.tpa && checkEclaimlink_id==true?(
            <td>{p3}</td>):
            checkEclaimlink_id==false?(
              <td>{payers}</td>
            ):<td>{payers}</td>
            }
            <td>{row["network_name"]}</td>
            <td>{row["network_description"]}</td>
            <td>{discount}</td>
            <td>{patientShare}</td>
            <td>{row["patient_share_max_amount"]}</td>
            <td>{row["max_number_of_days_for_submission"]}</td>

            <td>{row["max_number_of_days_for_resubmissions"]}</td>
            <td>{row["max_number_of_resubmission"]}</td>
            <td>{row["authorizing_amount"]}</td>
            {/*    <td>{row["gross_net"]}</td> */}
            <td>{row["active"] ? "Active" : "Not Active"}</td>
            <td>
              <button
                style={{
                  color: "red",
                  border: "none",
                }}
                onClick={() =>
                  this.setState({
                    DeleteNetwork: true,
                    Networkid: row["id"],
                  })
                }
              >
                Delete
              </button>
            </td>
            <td>
            <button
                style={{
                  color: "red",
                  border: "none",
                }}
               
              onClick={() => {
                this.setState({
                  updateNetworkScreen: true,
                  networkToUpdate: row,
                  tpa:row.tpa,
                  payerList: row.payer_id
                });
              }}
              >
                Edit
              </button>
            </td>
          </tr>
        );
      });
    }

    return (
      <>
        {this.state.DeleteNetwork ? (
          <div className="popup">
            <div
              className="form"
              style={{
                width: "50%",
                margin: "10px auto",
                marginLeft:"33%",
                backgroundColor: "white",
              }}
            >
              <form>
                <h4>Are you sure you want to delete this network?</h4>
                 <h6
                   style={{
                     color: "red",
                   }}
               >
                  all price list related to this network will be removed
                 </h6>
                <Button
                  Style="BS12"
                  Value="Yes"
                  onClick={this.DeleteNetwork}
                />
                <Button
                  Style="BS12"
                  Value="No"
                  onClick={() =>
                  this.setState({
                    DeleteNetwork: false,
                 })
               }
                />
              </form>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  textAlign: "right",
                  cursor: "pointer",
                  margin: "0 2%",
                }}
              ></p>
            </div>
          </div>
        ) : null}

        <div className="container">
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <h3 style={{ color: "green", margin: "1rem 0" }}>
              Networks Management
            </h3>
            <hr />

            {error ? <h5 style={{ color: "red" }}>{error}</h5> : null}
            <input
              type="button"
              className="BS1"
              value="Add Network"
              onClick={this.onClickAddNewNetwork}
            />

            <input
              type="button"
              className="BS1"
              value="Upload Networks"
              onClick={this.onClickUploadNetworks}
            />
          </div>
          {UploadNetworksScreen ? (
            <div className="popup">
              <div
                className="form5"
                style={{
                  width: "60%",

                  marginLeft: "25%",
                  backgroundColor: "white",
                }}
              >
                <UploadNetworkExcel getAllNetworks={this.getAllNetworks}
                project="Sama" />
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    cursor: "pointer",
                    textAlign: "right",
                  }}
                  onClick={() => {
                    this.setState({
                      UploadNetworksScreen: false,
                    });
                  }}
                >
                  {" "}
                  Close{" "}
                </p>
              </div>
            </div>
          ) : null}
          {Networks && Networks.length > 0 ? (
            <Table
              responsive
              striped
              bordered
              hover
              size="sm"
              style={{ marginTop: "20px" }}
            >
              <thead style={{ background: "#3ab449", color: " white" }}>
                <tr>
                  
                  <th
                    style={{
                      verticalAlign: "middle",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                    }}
                  >
                    TPA
                  </th>
                  <th
                    style={{
                      verticalAlign: "middle",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                    }}
                  >
                    Payer ID
                  </th>
                  <th style={{ verticalAlign: "middle", width: "1px" }}>
                    Network Name
                  </th>
                  <th style={{ verticalAlign: "middle" }}>
                    Network Description
                  </th>
                  <th style={{ verticalAlign: "middle" }}>Discount(%)</th>
                  <th style={{ verticalAlign: "middle" }}>Patient Share(%)</th>
                  <th style={{ verticalAlign: "middle" }}>
                    Patient Share Maximum Amount
                  </th>

                  <th style={{ verticalAlign: "middle" }}>
                    Max number of days for submission
                  </th>
                  <th style={{ verticalAlign: "middle" }}>
                    {" "}
                    Max number of days for resubmission
                  </th>
                  <th style={{ verticalAlign: "middle" }}>
                    max number of resubmissions
                  </th>

                  <th style={{ verticalAlign: "middle" }}>
                    Authorizing Amount
                  </th>
                  <th style={{ verticalAlign: "middle" }}>Status</th>
                  <th style={{ verticalAlign: "middle" }}>Delete Network</th>
                  <th style={{ verticalAlign: "middle" }}>Update Network</th>
                </tr>
              </thead>
              <tbody>{NetworksTable}</tbody>
            </Table>
          ) : Networks && Networks.length == 0 && flag == false ? (
            <h5 style={{ marginTop: "30px" }}>There is no network in DB</h5>
          ) : this.state.flag && !error ? (
            <div style={{ marginTop: "30px" }}>
              <Spinner animation="border" variant="success" />
            </div>
          ) : null}
          {AddNewNetworkModule ? (
            <div className="popup">
              <div
                className="form5"
                style={{
                  width: "60%",

                  marginLeft: "25%",
                  backgroundColor: "white",
                }}
              >
                <KareemNetworks
                  Networks={this.state.Networks}
                  getAllNetworks={this.getAllNetworks}
                />

                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    cursor: "pointer",
                    textAlign: "right",
                  }}
                  onClick={() => {
                    this.setState({
                      AddNewNetworkModule: false,
                    });
                  }}
                >
                  {" "}
                  Close{" "}
                </p>
              </div>
            </div>
          ) : null}
          {updateNetworkScreen ? (
            <div className="popup">
              <div
                className="form5"
                style={{
                  width: "60%",

                  marginLeft: "25%",
                  backgroundColor: "white",
                }}
              >
                <UpdateNetworkModule
                  project="sama"
                  network={networkToUpdate}
                  getAllNetworks={this.getAllNetworks}
                  tpa={this.state.tpa}
                  payerList={this.state.payerList}
                />

                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    cursor: "pointer",
                    textAlign: "right",
                  }}
                  onClick={() => {
                    this.setState({
                      updateNetworkScreen: false,
                    });
                  }}
                >
                  Close
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export default KareemNetworksModule;
