import React from "react";
import NavBar from "../Components/navbar";
import Footer from "../Components/footer";
import Cookies from "js-cookie";
import styles from "../Components/Styling/Kareem.module.css";
const Kareem = () => {
  let name = localStorage.getItem("KareemUser");
  const kareem = Cookies.get("KareemId");
  return (
    <>
      <NavBar userType={localStorage.getItem("type")} />
      <div className={styles.KareemLinks} style={{ height: "75vh" }}>
        {/*   <a href="">Documentation</a>
            <a href="">API End Points</a> */}
        {/* <a href="/KareemSchema">Schema</a>
        <a href="/KareemSample">Sample</a> */}
        {kareem ? (
          <a href="/KareemHome" style={{ color: "black" }}>
            {name} Profile
          </a>
        ) : (
          <a href="/Kareem/login">Login</a>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Kareem;
