import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

export default function AddressForm(props) {
  let { data } = props;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Patient Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            required
            id="age"
            name="age"
            label="Age"
            fullWidth
            value={data.age}
            variant="standard"
            onChange={(e) => props.onChange(e)}
            InputProps={{
              inputProps: {
                min: 1,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl required variant="standard" fullWidth>
            <InputLabel id="demo-simple-select-standard-label">
              Age Unit
            </InputLabel>
            <Select
              required
              name="ageUnit"
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={data.ageUnit}
              onChange={(e) => props.onChange(e)}
              label="Age Unit"
            >
              <MenuItem value="Month">Month</MenuItem>
              <MenuItem value="Year">Year</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl required variant="standard" fullWidth>
            <InputLabel id="gender">Gender</InputLabel>
            <Select
              required
              name="gender"
              labelId="gender"
              id="gender"
              value={data.gender}
              onChange={(e) => props.onChange(e)}
              label="Gender"
            >
              <MenuItem value="M">Male</MenuItem>
              <MenuItem value="F">Female</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
