import React, { Component } from "react";
import NetworksModule from "./NetworkViews/NetworksModule";
import PriceListModule from "./NetworkViews/PriceListModule";

class ContractManagementChoice extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.onClickNetwork = this.onClickNetwork.bind(this);
    this.onClickPriceList = this.onClickPriceList.bind(this);
  }

  onClickNetwork() {
    this.setState({ Networks: true, PriceList: false });

    // this.props.parentCallback({ Networks: true });
  }

  onClickPriceList() {
    this.setState({ PriceList: true, Networks: false });

    //this.props.parentCallback({ PriceList: true });
  }

  render() {
    let { Networks, PriceList } = this.state;

    return (
      <>
        <div className="container " style={{marginTop:"2rem"}}>
          <hr/>
          {Networks || PriceList ? (
            <a
              style={{ textAlign: "left" ,fontSize:"1rem",fontWeight:630}}
              onClick={() => {
                this.setState({ Networks: false, PriceList: false });
              }}
            >
              Back To Home
            </a>
          ) : null}
          {Networks /* && !this.state.selectedAgain */ ? (
            <NetworksModule />
          ) : PriceList /* && !this.state.selectedAgain */ ? (
            <PriceListModule />
          ) : (
            <form style={{ marginTop: "10vh",padding:"2rem 0.5rem" }}>
              <input
                type="button"
                value="Networks"
                className="BS1"
                onClick={this.onClickNetwork}
              />

              <input
                type="button"
                value="Price List"
                className="BS1"
                onClick={this.onClickPriceList}
              />
            </form>
          )}
        </div>
      </>
    );
  }
}

export default ContractManagementChoice;
