import React, { Component } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Spinner from "react-bootstrap/Spinner";
import settings from "../helpers/Settings";
let { KayanURL } = settings;

class AddNewCodeOfPreAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endWait: false
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleInputTextChange = (e) =>{
    this.setState({
      [e.target.name]: e.target.value
  })
  }
  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ responseReady: false, sent: true, status: false,success:false});
    const Object = {
      code: this.state.code
  };
  let dataForm = [Object];
    let dataArrayOfJson = JSON.stringify(dataForm);
  const dataResponse = await axios.post(
    KayanURL+"api/KareemBenefits/uploadPreAuthList",
  {
    data: dataArrayOfJson,
    apiToken: Cookies.get("KareemId")
  }
);
  if (dataResponse) {
    let response = dataResponse.data;
    let result = {};
    if (response.success === true) {
      result = {
        message: "Code added successfully",
        res: true,
      };
      this.props.getAllCodeList();
    } else {
      if(response.error){
        result = { message: response.error,
         res: false
         };
        }else if(response.errors){
          console.log(response.errors[0].error)
          result = { message: response.errors[0].error,
            res: false
            };
        }
        this.props.getAllCodeList();
    }
    this.setState({
      status: result,
      sent: false,
      responseReady: true
    });
  
  }   
  };
  render() {
    let { sent,status,responseReady} = this.state;
    return (
      <>
        <div className="container">
          <form onSubmit={this.onSubmit}>

            <h5>Add New Code </h5>
              <input
              required
              onChange={this.handleInputTextChange}
              type="text"
              name="code"
              placeholder="Code"
              style={{ width: "70%", padding: "8px" }}
            />
            <br />
            <input type="submit" value="Add" className="BS1" />
            <br />
            
          {sent && !responseReady? (
          <Spinner animation="border" variant="success" />
          ) : null}

          {responseReady && status.res ? (
               <h5 style={{ color: "green" }}>{status.message}</h5>
          ): responseReady && status.res==false ? (
                <h5 style={{ color: "red" }} >{status.message}</h5>
          ) : responseReady && status.res==false && status.message=='This code is exist enter another code!' ?(
               <h5 style={{ color: "red" }}>{status.message}</h5>
          ):null
          }
          
          </form>
        </div>
      </>
    );
  }
}

export default AddNewCodeOfPreAuth;
