import React, { Component } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Table from "react-bootstrap/Table";
import Button from "../Components/Button/index";
import Spinner from "react-bootstrap/Spinner";
import settings from "../helpers/Settings";
import UploadExcelPrice from "./UploadExcelPrice";
import KareemAddPrice from "./KareemAddPrice";
import KareemUpdatePrice from "./KareemUpdatePrice";
let { KayanURL } = settings;

class KareemPriceModule extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onClickAddNewPricelist = this.onClickAddNewPricelist.bind(this);
    this.onClickUploadPricelist = this.onClickUploadPricelist.bind(this);
    this.getAllPriceLists = this.getAllPriceLists.bind(this);
  }
  componentDidMount = async () => {
    this.getAllPriceLists();
  };

  //get All NEtworks

  async getAllPriceLists() {
    let response = await axios.put(
      KayanURL + "api/KareemBenefits/pricelist/getAllActivity",
      {
        apiToken: Cookies.get("KareemId"),
      }
    );

    if (response) {
      let { data } = response;
      // console.log('all pricelist:',data.pricelist)
      if (data.success) {
        this.setState({ pricelist: data.pricelist });
      } else {
        let { error } = data;
        if (error) {
          if (Array.isArray(error) && error.length > 0) {
            this.setState({ error: error[0] });
          } else {
            this.setState({ error: error });
          }
        }
      }
    }
  }
  DeletePriceByID = async (event) => {
    event.preventDefault();
    let { priceid } = this.state;

    let response = await axios.put(
      KayanURL + "api/KareemBenefits/pricelist/deleteActivity",
      {
        apiToken: Cookies.get("KareemId"),
        PriceID: priceid,
      }
    );

    if (response) {
      let { data } = response;
      if (data.success) {
        this.setState({
          success: true,
          DeletePrice: false,
        });
      }
    }
    let NewPrice = await axios.put(
      KayanURL + "api/KareemBenefits/pricelist/getAllActivity",
      {
        apiToken: Cookies.get("KareemId"),
      }
    );

    if (NewPrice) {
      let { data } = NewPrice;
      if (data.success) {
        this.setState({ pricelist: data.pricelist });
      }
    }
  };
  onClickAddNewPricelist() {
    this.setState({ AddNewPriceListForm: true, UploadPriceListForm: false });
  }

  onClickUploadPricelist() {
    this.setState({ UploadPriceListForm: true, AddNewPriceListForm: false });
  }

  render() {
    let { pricelist, AddNewPriceListForm, UploadPriceListForm, error } =
      this.state;

    let pricelistTable;

    if (pricelist && pricelist.length > 0) {
      pricelistTable = pricelist.map((row, index) => {
        return (
          <tr key={index}>
            <td>{row["payer_id"]}</td>

            <td>{row["network_name"]}</td>
            <td>{row["activity_type"].toUpperCase()}</td>

            <td>{row["activity_code"]}</td>
            <td>{row["gross"]}</td>
            <td>{row["patient_share"]}</td>
            <td>{row["net"]}</td>
            <td>
              <button
                style={{
                  color: "red",
                  border: "none",
                }}
                onClick={() =>
                  this.setState({
                    DeletePrice: true,
                    priceid: row["id"],
                  })
                }
              >
                Delete
              </button>
            </td>
            <td>
              <button
                style={{
                  color: "red",
                  border: "none",
                }}
                onClick={() =>
                  this.setState({
                    UpdatePrice: true,
                    priceid: row["id"],
                    network_name: row.network_name,
                    payer_id: row.payer_id,
                    activity_type: row.activity_type,
                    activity_code: row.activity_code,
                    gross: row.gross,
                    patient_share: row.patient_share,
                  })
                }
              >
                Edit
              </button>
            </td>
          </tr>
        );
      });
    }

    return (
      <>
        {this.state.DeletePrice ? (
          <div className="popup">
            <div
              className="form"
              style={{
                width: "50%",
                margin: "auto",
                backgroundColor: "white",
              }}
            >
              <form>
                <h4>Are you sure you want to delete this price list?</h4>
                <Button
                  Style="BS12"
                  Value="Yes"
                  onClick={this.DeletePriceByID}
                />
                <Button
                  Style="BS12"
                  Value="No"
                  onClick={() =>
                    this.setState({
                      DeletePrice: false,
                    })
                  }
                />
              </form>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  textAlign: "right",
                  cursor: "pointer",
                  margin: "0 2%",
                }}
              ></p>
            </div>
          </div>
        ) : null}
        {this.state.UpdatePrice ? (
          <div className="popup">
            <div
              className="form5"
              style={{
                width: "60%",
                marginLeft: "25%",
                backgroundColor: "white",
              }}
            >
              <KareemUpdatePrice
                getAllPriceLists={this.getAllPriceLists}
                id={this.state.priceid}
                payer_id={this.state.payer_id}
                network_name={this.state.network_name}
                activity_type={this.state.activity_type}
                activity_code={this.state.activity_code}
                gross={this.state.gross}
                patient_share={this.state.patient_share}
              />
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  cursor: "pointer",
                  textAlign: "right",
                }}
                onClick={() => {
                  this.setState({
                    UpdatePrice: false,
                  });
                }}
              >
                {" "}
                Close{" "}
              </p>
            </div>
          </div>
        ) : null}
        <div className="container">
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <h3 style={{ color: "green", margin: "1rem 0" }}>
              Price List Management
            </h3>
            <hr />
            {error ? <h5 style={{ color: "red" }}>{error}</h5> : null}
            <input
              type="button"
              className="BS1"
              value="Add Price List"
              onClick={this.onClickAddNewPricelist}
            />

            <input
              type="button"
              className="BS1"
              value="Upload Price List"
              onClick={this.onClickUploadPricelist}
            />
          </div>
          {pricelist && pricelist.length > 0 ? (
            <Table
              responsive
              striped
              bordered
              hover
              size="sm"
              style={{ marginTop: "20px" }}
            >
              <thead style={{ background: "#3ab449", color: " white" }}>
                <tr>
                  <th
                    style={{
                      verticalAlign: "middle",
                    }}
                  >
                    Payer ID
                  </th>
                  <th
                    style={{
                      verticalAlign: "middle",
                    }}
                  >
                    Network Name
                  </th>
                  <th
                    style={{
                      verticalAlign: "middle",
                    }}
                  >
                    Activity Type
                  </th>
                  <th style={{ verticalAlign: "middle", width: "1px" }}>
                    Activity Code
                  </th>
                  <th style={{ verticalAlign: "middle" }}>Gross</th>
                  <th style={{ verticalAlign: "middle" }}>Patient Share</th>
                  <th style={{ verticalAlign: "middle" }}>Net</th>
                  <th style={{ verticalAlign: "middle" }}>Delete</th>
                  <th style={{ verticalAlign: "middle" }}>Update</th>
                </tr>
              </thead>
              <tbody>{pricelistTable}</tbody>
            </Table>
          ) : pricelist && pricelist.length == 0 ? (
            <h5 style={{ marginTop: "30px" }}>There is no pricelist in DB</h5>
          ) : (
            <div style={{ marginTop: "30px" }}>
              <Spinner animation="border" variant="success" />
            </div>
          )}

          {AddNewPriceListForm ? (
            <div className="popup">
              <div
                className="form5"
                style={{
                  width: "40%",

                  margin: "20px auto",
                  backgroundColor: "white",
                }}
              >
                <KareemAddPrice
                  /* Networks={this.state.Networks} */ getAllPriceLists={
                    this.getAllPriceLists
                  }
                />

                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    cursor: "pointer",
                    textAlign: "right",
                  }}
                  onClick={() => {
                    this.setState({
                      AddNewPriceListForm: false,
                    });
                  }}
                >
                  {" "}
                  Close{" "}
                </p>
              </div>
            </div>
          ) : UploadPriceListForm ? (
            <div className="popup">
              <div
                className="form5"
                style={{
                  width: "40%",

                  margin: "20px auto",
                  backgroundColor: "white",
                }}
              >
                <UploadExcelPrice getAllPriceLists={this.getAllPriceLists} />

                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    cursor: "pointer",
                    textAlign: "right",
                  }}
                  onClick={() => {
                    this.setState({
                      UploadPriceListForm: false,
                    });
                  }}
                >
                  {" "}
                  Close{" "}
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export default KareemPriceModule;
