import React, { Component, Fragment } from "react";
import NavBar from "../Components/navbar";
import Header from "../Components/Header";
import Footer from "../Components/footer";
import SanadLinks from "../Components/sanadlinks";

class Sanad extends Component {
  render() {
    return (
      <Fragment>
        <NavBar />
        <Header
          name={localStorage.getItem("SanadName")}
          usertype="employee"
          history={this.props.history}
        />
        <SanadLinks history={this.props.history} />
        <Footer />
      </Fragment>
    );
  }
}

export default Sanad;
