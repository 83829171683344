import React, { Component } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Autocomplete from "../SamaViews/NetworkViews/Autocomplete";
import Spinner from "react-bootstrap/Spinner";
import settings from "../helpers/Settings";
let { KayanURL } = settings;

class UpdateNotAllowedPrincipleIcd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endWait: false,
      payer_id: this.props.payer_id,
      ICD_code: this.props.icd,
      InputTextChange:false,
      onSelectPayer: false
    };
    this.onSelectPayer = this.onSelectPayer.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount = async () => {
    // console.log(this.state.payer_id)
    // console.log(this.state.ICD_code)
    let payers = localStorage.getItem("payers");
    if (payers) {
      this.setState({ payers: JSON.parse(payers) });
    } else {
      this.getPayersList();
    }
  };
  async getPayersList() {
    let response = await axios.put(KayanURL + "api/KareemBenefits/getPayers", {
      apiToken: Cookies.get("KareemId"),
    });

    if (response) {
      let { data } = response;

      if (data && data.success) {
        let { payers } = data;

        this.setState({ payers });
        localStorage.setItem("payers", JSON.stringify(payers));
      }
    }
  }

  onSelectPayer({ target: { name, value } }) {
    this.setState({ payer_id: value,
      onSelectPayer: true });
    
  }
  handleInputTextChange = (e) =>{
    this.setState({
      ICD_code: e.target.value,
      InputTextChange:true
  })
  }
  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ responseReady: false, sent: true, status: false });
    if(this.state.InputTextChange==false && this.state.onSelectPayer==false){
      this.setState({warningMessege:true})
    } else{
    const Object = {
      payer_id: this.state.payer_id,
      icd: this.state.ICD_code
  };
  let dataForm = [Object];
  let dataArrayOfJson = JSON.stringify(dataForm);
  // console.log("dataForm is:",dataArrayOfJson);
  const dataResponse = await axios.put(
   KayanURL+ "api/KareemBenefits/updateNotAllowedPrincipalICD",
  {
    data: dataArrayOfJson,
    apiToken: Cookies.get("KareemId"),
    id:this.props.id
  }
);
  if (dataResponse) {
    let response = dataResponse.data;
      // console.log(response)
    let result = {};
    if (response.success === true) {
      result = {
        message: "ICD updated successfully",
        res: true,
      };
      this.props.getAllNotAllowedPrincipalICDList();
    } else {
      result = { message:response.error,
       res: false
       };
      // console.log(result.message)
    }
    this.setState({
      status: result,
      sent: false,
      responseReady: true
    });
  // }  
}
}
  };
  render() {
    let { sent,payers,status,responseReady,warningMessege} = this.state;
    let payersList;
    if (payers && payers.length > 0) {
      payersList = payers.map((payer) => {
        return (
          <option value={payer["eclaimlink_id"]} key={payer["eclaimlink_id"]}>
            {payer["eclaimlink_id"]}
          </option>
        );
      });
    }
    return (
      <>
        <div className="container">
          <form onSubmit={this.onSubmit}>
            <h5>Update Code Of ICD</h5>
            <select
              required
              onChange={this.onSelectPayer}
              // defaultValue={this.props.payer_id}
            >
              <option value={this.props.payer_id}>{this.props.payer_id}</option>
              {payersList}
            </select>
              <input
              required
              onChange={this.handleInputTextChange}
              type="text"
              name="ICD_code"
              defaultValue={this.props.icd}
              style={{ width: "70%", padding: "8px" }}
            />
            <br />
            <input type="submit" value="Save" className="BS1" />
            <br />
            
          {sent && !responseReady ? (
          <Spinner animation="border" variant="success" />
          ) : null}
          {responseReady && status.res ? (
            <h5 style={{ color: "green" }}>{status.message}</h5>
          ): responseReady && status.res==false ? (
                <h5 style={{ color: "red" }} >{status.message}</h5>
          ) :responseReady==false && status==false && sent==true && warningMessege == true ?(
                <h5 style={{ color: "red" }} >you must change one value at least!</h5>
          ):null
          }
          
          </form>
        </div>
      </>
    );
  }
}

export default UpdateNotAllowedPrincipleIcd;
