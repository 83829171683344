import React from "react";
import "./AutoComplete.css";
import Input from "../InputText";
import ListView from "../ListView/index2";

class AutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      id,
      name,
      hint,
      matched,
      text,
      Style,
      handleAutoCompleteChange,
      handleItemSelection,
    } = this.props;

    return (
      <React.Fragment>
        <Input
          id={id}
          type="text"
          name={name}
          value={text}
          Style={Style}
          hint={hint}
          onChange={handleAutoCompleteChange}
        />
        {matched.length === 0 ? null : (
          <ListView
            Style="ProviderCodesMatched"
            Items={matched}
            handleItemSelection={handleItemSelection}
          />
        )}
      </React.Fragment>
    );
  }
}
export default AutoComplete;
