import React, { Component } from "react";
import "../App.css";
import axios from "axios";
import FormSearch from "../Components/FormNew/index2";
import NewForm from "../Components/FormNew/NewForm";
import Cookies from "js-cookie";
import cloneDeep from "lodash/cloneDeep";
import Spinner from "react-bootstrap/Spinner";
import settings from "../helpers/Settings";
import { Multiselect } from "multiselect-react-dropdown";
import Controls from "../Components/controls/Controls";
import Table from "react-bootstrap/Table";
import FileUploadRule from "./uploadcustomroles";
import TextField from "@material-ui/core/TextField";
import { Icon } from "@iconify/react";
import { CustomAutocomplete } from "../Components/AutoComplete/CustomMuiAutocomplete";

import {
  Grid,
  Button as MuiButton,
  InputLabel,
  Typography,
  Select as MuiSelect,
  MenuItem,
  FormControl,
  Input,
  Button,
  Tooltip,
  IconButton,
} from "@material-ui/core";
let { KayanURL } = settings;
const dCodes = [
  "ELIG-+P2:P93001",
  "ELIG-002",
  "ELIG-003",
  "ELIG-004",
  "ELIG-005",
  "ELIG-006",
  "ELIG-007",
  "AUTH-001",
  "AUTH-002",
  "AUTH-003",
  "AUTH-004",
  "AUTH-005",
  "AUTH-006",
  "AUTH-007",
  "AUTH-008",
  "AUTH-009",
  "AUTH-010",
  "AUTH-011",
  "BENX-001",
  "BENX-002",
  "BENX-003",
  "BENX-004",
  "BENX-005",
  "CLAI-007",
  "CLAI-008",
  "CLAI-009",
  "CLAI-010",
  "CLAI-011",
  "CLAI-012",
  "CLAI-013",
  "CLAI-014",
  "CLAI-015",
  "CLAI-016",
  "CLAI-017",
  "CLAI-018",
  "CODE-001",
  "CODE-002",
  "CODE-003",
  "CODE-004",
  "CODE-005",
  "CODE-006",
  "CODE-007",
  "CODE-008",
  "CODE-009",
  "CODE-010",
  "CODE-011",
  "CODE-012",
  "CODE-013",
  "CODE-014",
  "CODE-015",
  "DUPL-001",
  "DUPL-002",
  "MNEC-001",
  "MNEC-002",
  "MNEC-003",
  "MNEC-004",
  "MNEC-005",
  "MNEC-006",
  "NCOV-001",
  "NCOV-002",
  "NCOV-003",
  "NCOV-004",
  "NCOV-005",
  "NCOV-006",
  "NCOV-007",
  "NCOV-008",
  "NCOV-009",
  "NCOV-010",
  "NCOV-011",
  "NCOV-012",
  "NCOV-013",
  "NCOV-014",
  "NCOV-015",
  "NCOV-016",
  "NCOV-017",
  "NCOV-018",
  "NCOV-019",
  "NCOV-020",
  "NCOV-021",
  "NCOV-022",
  "NCOV-023",
  "NCOV-024",
  "NCOV-025",
  "NCOV-0026",
  "PRCE-001",
  "PRCE-002",
  "PRCE-003",
  "PRCE-004",
  "PRCE-005",
  "PRCE-006",
  "PRCE-007",
  "PRCE-008",
  "PRCE-009",
  "PRCE-010",
  "TIME-001",
  "TIME-002",
  "TIME-003",
  "AUDT-001",
  "TKBK-001",
  "TKBK-002",
  "COPY-001",
];

const dCodes1 = [];
class UpdateRule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddNewRule: false,
      isUpdateCoverdicd: false,
      isUpdatenonCoverdicd: false,
      isLoadingDiagnosis: false,
      ActivityItems: [],
      ActivitySearch: [],
      ActivityItems_Search: [],
      ActivityCodes: [],
      diagnosisItems: [],
      diagnosisItems_noncovered: [],
      DiagnosisCodes: [],
      DiagnosisCodes1: [],
      updatecoverd_icds: [],
      updatenoncoverd_icds: [],
      unique: [],
      unique_non: [],
      ActivityNow1: 0,
    };
    this.handleInputTextChange = this.handleInputTextChange.bind(this);
    //  this.getAllActivity();
    this.getAllDiagnosis();
    this.selectDenileID = this.selectDenileID.bind(this);
    this.removeDenileID = this.removeDenileID.bind(this);
    this.onClickUploadRule = this.onClickUploadRule.bind(this);
  }
  componentDidMount() {
    dCodes.forEach((l) => {
      dCodes1.push({ name: l, value: l });
    });
    this.setState({ dCodes1 });
    this.getPayersList();
  }
  onClickUploadRule() {
    this.setState({ UploadRule: true });
  }
  selectDenileID(selectedList, selectedItem) {
    let denileValues = [];
    selectedList.forEach((item) => {
      denileValues.push(item.value);
    });
    this.setState({
      denileValues: denileValues,
      data: { ...this.state.data, denail_codes: "{" + denileValues + "}" },
    });
  }
  removeDenileID(selectedList, removedItem) {
    let denileValues = [];
    selectedList.forEach((item) => {
      if (item.value != removedItem.value) {
        denileValues.push(item.value);
      }
    });

    this.setState({
      denileValues: denileValues,
      data: { ...this.state.data, denail_codes: "{" + denileValues + "}" },
    });
  }
  getAllActivity = (v) => {
    let ActivityItems = [];
    this.renderActivityProgressBar_Search();
    let typeText;
    switch (v) {
      case "cpt":
        typeText = 3;
        break;
      case "hcpcs":
        typeText = 4;
        break;
      case "dental":
        typeText = 6;
        break;
      case "drug":
        typeText = 5;
        break;
      case "drg":
        typeText = 9;
        break;
      case "service":
        typeText = 8;
        break;

      default:
        break;
    }
    axios
      .get(
        "https://www.kayan-healthcare.com/" + "api/getAllActivity/" + typeText
      )
      .then(({ data }) => {
        if (data) {
          data.forEach((element) => {
            if (
              ActivityItems.indexOf(element.code + "-" + element.shortdesc) ==
              -1
            ) {
              if (element.shortdesc === "null") {
                element.shortdesc = "";
              }

              ActivityItems.push(element.code + "-" + element.shortdesc);
            }
          });

          this.setState({ ActivityItems: ActivityItems });
        }
      })
      .catch(() => {
        this.setState({ serverError: "There are an server error" });
      });
  };
  DeleteRuleByID = async (event) => {
    event.preventDefault();
    let { Ruleid } = this.state;
    let response = await axios.put(KayanURL + "api/sama/deleteRule", {
      apiToken: Cookies.get("SamaId"),
      RuleID: Ruleid,
    });
    if (response) {
      let { data } = response;
      if (data.success) {
        this.setState({
          success: true,
          DeleteRule: false,
        });
      }
    }
    this.setState({ isSearch: true });

    const data = await axios.put(KayanURL + "api/sama/getRules", {
      apiToken: Cookies.get("SamaId"),
    });
    let NewRule = data["data"];

    if (NewRule) {
      this.setState({ AllRules: NewRule, isSearch: false });
    }
  };

  getAllActivity_Search = (Type) => {
    let ActivityItems_Search = [];
    this.renderActivityProgressBar_Search();
    let typeText;
    switch (Type) {
      case "cpt":
        typeText = 3;
        break;
      case "hcpcs":
        typeText = 4;
        break;
      case "dental":
        typeText = 6;
        break;
      case "drug":
        typeText = 5;
        break;
      case "drg":
        typeText = 9;
        break;
      case "service":
        typeText = 8;
        break;

      default:
        break;
    }
    axios
      .get(
        "https://www.kayan-healthcare.com/" + "api/getAllActivity/" + typeText
      )
      .then(({ data }) => {
        if (data) {
          data.forEach((element) => {
            if (element.shortdesc === "null") {
              element.shortdesc = "";
            }
            ActivityItems_Search.push(element.code + "-" + element.shortdesc);
          });

          this.setState({ ActivityItems_Search: ActivityItems_Search });
        }
      })
      .catch(() => {
        this.setState({ serverError: "There are an server error" });
      });
  };
  getAllDiagnosis = () => {
    let diagnosisItems = [];
    let diagnosisItems_noncovered = [];
    // this.renderDiagnosisProgressBar();
    this.setState({
      isLoadingDiagnosis: true,
    });
    axios
      .get("https://www.kayan-healthcare.com/" + "api/getAllDiagnosis")

      .then(({ data }) => {
        if (data) {
          data.forEach((element) => {
            if (element.shortdesc === "null") {
              element.shortdesc = "";
            }
            diagnosisItems.push(element.code + "-" + element.shortdesc);
          });

          this.setState({ diagnosisItems: diagnosisItems });
        }
        if (data) {
          data.forEach((element) => {
            if (element.shortdesc === "null") {
              element.shortdesc = "";
            }
            diagnosisItems_noncovered.push(
              element.code + "-" + element.shortdesc
            );
          });

          this.setState({
            diagnosisItems_noncovered: diagnosisItems_noncovered,
            isLoadingDiagnosis: false,
          });
        }
      })
      .catch(() => {
        this.setState({ serverError: "There are an server error" });
      });
  };

  renderActivityProgressBar() {
    let { ActivityNow, ActivityItems } = this.state;

    setTimeout(() => {
      this.setState(
        {
          ActivityNow: !ActivityItems ? ActivityNow + 20 : 100,
        },
        this.renderActivityProgressBar()
      );
    }, "800");
  }

  renderActivityProgressBar_Search() {
    let { ActivityNow1, ActivityItems_Search } = this.state;

    setTimeout(() => {
      this.setState(
        {
          ActivityNow1: !ActivityItems_Search ? ActivityNow1 + 20 : 100,
        },
        this.renderActivityProgressBar_Search()
      );
    }, "800");
  }

  mappingActivityType(type) {
    let typeText = "";

    switch (type) {
      case "3":
        typeText = "cpt";
        break;
      case "4":
        typeText = "hcpcs";
        break;
      case "6":
        typeText = "dental";
        break;
      case "5":
        typeText = "drug";
        break;
      case "9":
        typeText = "drg";
        break;
      case "8":
        typeText = "service";
        break;
      default:
        break;
    }

    return typeText;
  }

  onChange = ({ target: { name, value } }, listName) => {
    if (value.length <= 1) {
      value = value.trim();
    }

    switch (listName) {
      case "DiagnosisCodes":
        this.setState({
          section: "Diagnosis",
        });
        break;
      case "DiagnosisCodes1":
        this.setState({
          section: "Diagnosis",
        });
        break;
      case "ActivityCodes1":
        this.setState({
          section: "activities",
        });
        break;
      default:
        this.setState({
          section: "activities",
        });
        break;
    }

    if (listName === "DiagnosisCodes" && value === "") {
      this.setState({
        BtnActive: false,
        userInputDiagnosis: "",
      });
    } else if (listName === "DiagnosisCodes1" && value === "") {
      this.setState({
        BtnActive_noncovered: false,
        userInputDiagnosis_noncovred: "",
      });
    } else if (listName === "ActivityCodes" && value === "") {
      this.setState({
        BtnActive: false,
        userInputActivity: "",
      });
    } else if (listName === "ActivityCodes1" && value === "") {
      this.setState({
        BtnActive_search: false,
        userInputActivity_search: "",
      });
    } else if (listName === "DiagnosisCodes") {
      /* this.setState({ BtnActive: true }); */
      const suggestions = this.state.diagnosisItems;
      let filteredSuggestions = [];
      if (value.length > 1) {
        const regex = new RegExp(`${value.toLowerCase()}`); //filter
        filteredSuggestions = suggestions
          .sort()
          .filter((v) => regex.test(v.toLowerCase()));
      }
      // Update the user input and filtered suggestions, reset the active
      // suggestion and make sure the suggestions are shown
      this.setState({
        activeSuggestion: 0,
        filteredSuggestionsDiagnosis: filteredSuggestions,
        showDiagnosisSuggestions: true,
        userInputDiagnosis: value,
      });
    } else if (listName === "DiagnosisCodes1") {
      /*  this.setState({ BtnActive_noncovered: true }); */
      const suggestions = this.state.diagnosisItems_noncovered;
      let filteredSuggestions = [];
      if (value.length > 1) {
        const regex = new RegExp(`${value.toLowerCase()}`); //filter
        filteredSuggestions = suggestions
          .sort()
          .filter((v) => regex.test(v.toLowerCase()));
      }
      // Update the user input and filtered suggestions, reset the active
      // suggestion and make sure the suggestions are shown
      this.setState({
        activeSuggestion: 0,
        filteredSuggestionsDiagnosis_noncovered: filteredSuggestions,
        showDiagnosisSuggestions_noncovered: true,
        userInputDiagnosis_noncovred: value,
      });
    } else if (listName === "ActivityCodes1") {
      const suggestions = this.state.ActivityItems_Search;
      let filteredSuggestions = [];
      if (value.length > 1) {
        const regex = new RegExp(`${value.toLowerCase()}`); //filter
        filteredSuggestions = suggestions
          .sort()
          .filter((v) => regex.test(v.toLowerCase()));
      }
      // Update the user input and filtered suggestions, reset the active
      // suggestion and make sure the suggestions are shown
      this.setState({
        activeSuggestion: 0,
        filteredSuggestionsActivity_search: filteredSuggestions,
        showActivitySuggestions_search: true,
        userInputActivity_search: value,
      });
    } else {
      /* this.setState({ BtnActive: true }); */
      const suggestions = this.state.ActivityItems;
      let filteredSuggestions = [];
      if (value.length > 1) {
        const regex = new RegExp(`${value.toLowerCase()}`);
        filteredSuggestions = suggestions
          .sort()
          .filter((v) => regex.test(v.toLowerCase()));
      }

      // Update the user input and filtered suggestions, reset the active
      // suggestion and make sure the suggestions are shown
      this.setState({
        activeSuggestion: 0,
        filteredSuggestionsActivity: filteredSuggestions,
        showActivitySuggestions: true,
        userInputActivity: value,
      });
    }
  };

  onClick = (e, code) => {
    code = e.currentTarget.innerText;
    // Update the user input and reset the rest of the state

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showDiagnosisSuggestions: false,
      showActivitySuggestions: false,
      showDiagnosisSuggestions_noncovered: false,
      showActivitySuggestions_search: false,
    });

    if (
      this.state.filteredSuggestionsActivity &&
      this.state.filteredSuggestionsActivity.length > 0
    ) {
      this.setState({
        // userInputActivity:this.state.filteredSuggestionsActivity[this.state.activeSuggestion]
        userInputActivity: e.currentTarget.innerText,
        filteredSuggestionsActivity: [],
        BtnActive: true,
      });
    }
    if (
      this.state.filteredSuggestionsActivity_search &&
      this.state.filteredSuggestionsActivity_search.length > 0
    ) {
      this.setState({
        // userInputActivity:this.state.filteredSuggestionsActivity[this.state.activeSuggestion]
        userInputActivity_search: e.currentTarget.innerText,
        filteredSuggestionsActivity_search: [],
        BtnActive_search: true,
      });
    }
    if (
      this.state.filteredSuggestionsDiagnosis &&
      this.state.filteredSuggestionsDiagnosis.length > 0
    ) {
      this.setState({
        userInputDiagnosis: e.currentTarget.innerText,
        filteredSuggestionsDiagnosis: [],
        BtnActive: true,
      });
    }
    if (
      this.state.filteredSuggestionsDiagnosis_noncovered &&
      this.state.filteredSuggestionsDiagnosis_noncovered.length > 0
    ) {
      this.setState({
        userInputDiagnosis_noncovred: e.currentTarget.innerText,
        filteredSuggestionsDiagnosis_noncovered: [],
        BtnActive_noncovered: true,
      });
    }
    //push to code
    this.setState({
      code: { ...this.state.code, Text: code },
    });
  };

  addNewCode = (event, listName, recordId) => {
    event.preventDefault();

    this.setState({
      BtnActive: false,
      BtnActive_noncovered: false,
      BtnActive_search: false,
    });
    let {
      DiagnosisCodes,
      DiagnosisCodes1,
      ActivityCodes,
      code,
      editableRow,
      isAddNewRule,
      ActivitySearch,
    } = this.state;

    let newcode = code.Text.split("-");

    let noncovered_icds = this.state.noncovered_icds;
    let coverd_icds = this.state.coverd_icds;

    switch (listName) {
      case "DiagnosisCodes":
        if (isAddNewRule == true) {
          DiagnosisCodes = DiagnosisCodes || [];
          DiagnosisCodes1 = DiagnosisCodes1 || [];
          if (DiagnosisCodes) {
            if (DiagnosisCodes1) {
              if (DiagnosisCodes1.indexOf(newcode[0]) == -1) {
                if (DiagnosisCodes.indexOf(newcode[0]) == -1) {
                  DiagnosisCodes.push(newcode[0]);
                } else {
                  alert("you try to add duplicate code");
                }
              } else {
                alert("Code can't be in covered & non covered");
              }
            }
          }
        }
        if (editableRow) {
          coverd_icds = editableRow.coverd_icds || [];
          noncovered_icds = editableRow.noncovered_icds || [];
        }

        if (coverd_icds) {
          if (noncovered_icds) {
            if (noncovered_icds.indexOf(newcode[0]) == -1) {
              if (coverd_icds.indexOf(newcode[0]) == -1) {
                coverd_icds.push(newcode[0]);
              } else {
                alert("you try to add duplicate code");
              }
            } else {
              alert("Code can't be in covered & non covered");
            }
          }
        }

        this.setState({
          DiagnosisCodes,
          code: {},
          data: {
            ...this.state.data,
            coverd_icds: DiagnosisCodes,
          },
          editableRow: { ...this.state.editableRow, coverd_icds: coverd_icds },
          userInputDiagnosis: "",
        });

        break;

      case "DiagnosisCodes1":
        if (isAddNewRule == true) {
          DiagnosisCodes = DiagnosisCodes || [];
          DiagnosisCodes1 = DiagnosisCodes1 || [];

          if (DiagnosisCodes1) {
            if (DiagnosisCodes) {
              if (DiagnosisCodes.indexOf(newcode[0]) == -1) {
                if (DiagnosisCodes1.indexOf(newcode[0]) == -1) {
                  DiagnosisCodes1.push(newcode[0]);
                } else {
                  alert("you try to add duplicate code");
                }
              } else {
                alert("Code can't be in covered & non covered");
              }
            }
          }
        }
        //  let noncovered_icds = this.state.noncovered_icds;
        if (editableRow) {
          coverd_icds = editableRow.coverd_icds || [];
          noncovered_icds = editableRow.noncovered_icds || [];
        }

        if (noncovered_icds) {
          if (coverd_icds != undefined) {
            if (coverd_icds.indexOf(newcode[0]) == -1) {
              if (noncovered_icds.indexOf(newcode[0]) == -1) {
                noncovered_icds.push(newcode[0]);
              } else {
                alert("you try to add duplicate code");
              }
            } else {
              alert("Code can't be in covered & non covered");
            }
          }
        }

        this.setState({
          DiagnosisCodes1,
          code: {},
          data: {
            ...this.state.data,
            noncovered_icds: DiagnosisCodes1,
          },
          editableRow: {
            ...this.state.editableRow,
            noncovered_icds: noncovered_icds,
          },
          userInputDiagnosis_noncovred: "",
        });

        break;

      case "ActivityCodes1":
        if (ActivitySearch.length < 1) {
          ActivitySearch.push(newcode[0]);
        } else {
          ActivitySearch = newcode[0];
        }

        this.setState({
          ActivitySearch,
          code: {},
          userInputActivity_search: "",
        });

        break;

      default:
        if (ActivityCodes.length < 1) {
          ActivityCodes = newcode[0];
        } else {
          ActivityCodes = newcode[0];
        }

        this.setState({
          ActivityCodes,
          code: {},
          data: { ...this.state.data, activity: ActivityCodes },
          editableRow: { ...this.state.editableRow, activity: ActivityCodes },
          userInputActivity_search: "",
          userInputActivity: "",
        });
    }
  };

  handleSubmit = async (event) => {
    let coverdCode = this.state.DiagnosisCodes;
    let coverdCodes = [];
    for (let i = 0; i < coverdCode.length; i++) {
      coverdCodes.push(coverdCode[i].split("-")[0]);
    }
    ////////////////////////////
    let nonCoverdCode = this.state.DiagnosisCodes1;
    let nonCoverdCodes = [];
    for (let i = 0; i < nonCoverdCode.length; i++) {
      nonCoverdCodes.push(nonCoverdCode[i].split("-")[0]);
    }
    //////////////////////////
    this.state.data = {
      ...this.state.data,
      coverd_icds: coverdCodes,
      noncovered_icds: nonCoverdCodes,
    };
    let object = JSON.stringify(this.state.data);
    let str = "[" + object + "]";
    const data = await axios.post(KayanURL + "api/sama/uploadRules", {
      data: str,
      apiToken: Cookies.get("SamaId"),
    });
    let responsedata = data["data"];
    if (this.state.data.freq && !this.state.data.freq_per_time) {
      alert("you must fill freq_per_time");
    } else if (!this.state.data.freq && this.state.data.freq_per_time) {
      alert("you must fill frequency");
    } else if (responsedata.success) {
      this.setState({
        success: true,
        isAddNewRule: false,
        data: {},
        DiagnosisCodes: [],
        DiagnosisCodes1: [],
        ActivityCodes: [],
      });
      alert("Rule added successfully");
      this.search();
    } else {
      this.setState({ failed: data.error });
      if (this.state.data == null) {
        alert("fill required data");
      }
      alert(responsedata.error);
    }
  };
  getNetworksForPayer = async (payerid) => {
    //end point to get the networks of the payer
    this.setState({ predefinedNetworks: [] });
    let result = await axios.put(
      KayanURL + "api/sama/networks/getNetworksByPayerID",
      {
        apiToken: Cookies.get("SamaId"),
        payer_id: payerid,
      }
    );

    if (result) {
      let response = result["data"];
      if (response && response.success) {
        let predefinedNetworks = response["Networks"];

        this.setState({ predefinedNetworks });

        if (predefinedNetworks && predefinedNetworks.length > 0) {
          let predefinedNetworksMenue = [];

          predefinedNetworksMenue = predefinedNetworks.map((element) => {
            let { network_name } = element;
            return <MenuItem value={network_name}>{network_name}</MenuItem>;
          });
          this.setState({ predefinedNetworksMenue });
        } else {
          this.setState({ predefinedNetworksMenue: [] });
        }
      }
    }
  };

  handleInputTextChange = ({ target: { name, value } }) => {
    switch (name) {
      case "payer_id":
        this.setState({
          data: { ...this.state.data, payer_id: value },
        });

        this.setState({ payer_id: value });
        this.getNetworksForPayer(value);
        break;

      case "networks":
        this.setState({
          data: { ...this.state.data, [name]: "{" + value + "}" },
        });
        break;

      case "denail_codes":
        this.setState({
          data: { ...this.state.data, [name]: "{" + value + "}" },
        });
        break;

      case "type":
        this.setState({
          data: { ...this.state.data, [name]: value },
        });
        if (value) {
          this.getAllActivity(value);
        }

        break;
      default:
        this.setState({ data: { ...this.state.data, [name]: value } });
        break;
    }
  };
  handleSelectChange = (e) => {
    this.setState({ NetworkID: e.target.value });
  };
  handleSelectPayerChange = (e) => {
    this.setState({ PayerID: e.target.value });
  };
  handleSelectTypeChange = (e) => {
    this.setState({ Type: e.target.value });

    if (e.target.value) {
      this.getAllActivity_Search(e.target.value);
    }
  };

  handleNewInputTextChange = ({ target: { name, value } }) => {
    switch (name) {
      case "payer_id":
        this.setState({
          editableRow: { ...this.state.editableRow, payer_id: value },
        });
        this.setState({ payer_id: value });

        this.getNetworksForPayer(value);
        break;

      case "networks":
        this.setState({
          editableRow: { ...this.state.editableRow, [name]: [value] },
        });
        break;

      case "denail_codes":
        this.setState({
          editableRow: { ...this.state.editableRow, [name]: [value] },
        });

        break;

      case "type":
        this.setState({
          editableRow: { ...this.state.editableRow, [name]: value },
        });
        if (value) {
          this.getAllActivity(value);
        }

        break;
      case "is_active_rule":
        if (value) {
          this.setState({
            active: false,
            editableRow: {
              ...this.state.editableRow,
              ["is_active_rule"]: [true],
            },
          });
        }
        if (value == "on") {
          this.setState({
            active: true,
            editableRow: {
              ...this.state.editableRow,
              ["is_active_rule"]: [false],
            },
          });
        }

        break;
      default:
        this.setState({
          editableRow: { ...this.state.editableRow, [name]: value },
        });
        break;
    }
  };
  async search(event) {
    let { ActivitySearch, NetworkID, PayerID, Type } = this.state;
    //let typeString = this.mappingActivityType(Type);
    this.setState({ isSearch: true });

    const data = await axios.put(KayanURL + "api/sama/getRules", {
      activity: ActivitySearch[0],
      payer_id: PayerID,
      networks: NetworkID,
      type: Type,
      apiToken: Cookies.get("SamaId"),
    });
    let response = data["data"];
    if (response) {
      this.setState({ AllRules: response, isSearch: false });
      this.setState({
        ActivitySearch: [],
        PayerID: "",
        NetworkID: "",
        Type: "",
      });
    }
  }

  async update(event) {
    event.preventDefault();
    let { editableRow, denileValues, olddata } = this.state;
    olddata = cloneDeep(editableRow);
    if (denileValues) {
      editableRow.denail_codes = denileValues;
    } else {
      let arrayOfDenail_codes = [];
      let JsonOfDenail_codes = editableRow.denail_codes;
      for (let i = 0; i < JsonOfDenail_codes.length; i++) {
        arrayOfDenail_codes.push(JsonOfDenail_codes[i].value);
      }
      editableRow.denail_codes = arrayOfDenail_codes;
    }
    const response = await axios.put(
      KayanURL + "api/sama/updateKareemBenefitsRule",
      {
        parameters: editableRow,
        apiToken: Cookies.get("SamaId"),
      }
    );

    if (editableRow.freq && !editableRow.freq_per_time) {
      alert("you must fill freq_per_time");
    } else if (!editableRow.freq && editableRow.freq_per_time) {
      alert("you must fill frequency");
    } else if (response) {
      let { data } = response;
      if (data.success) {
        delete Cookies.get("KareemId");
        delete editableRow["id"];

        this.search();
      }
    } else {
      alert("Change one value at least");
    }
    this.setState({
      updateRule: false,
    });
  }

  handleDeleteCoverd(index1) {
    let { coverd_icds, editableRow } = this.state;
    if (editableRow) {
      coverd_icds = editableRow.coverd_icds;
    }
    let newcoverd_icd = coverd_icds.splice(index1, 1);
    this.setState({
      coverd_icds: newcoverd_icd,
    });
  }

  handleDeleteNonCoverd(index1) {
    let { noncovered_icds, editableRow } = this.state;
    if (editableRow) {
      noncovered_icds = editableRow.noncovered_icds;
    }
    let newnoncoverd_icd = noncovered_icds.splice(index1, 1);
    this.setState({
      noncovered_icds: newnoncoverd_icd,
    });
  }

  async getPayersList() {
    let response = await axios.put(KayanURL + "api/sama/getPayers", {
      apiToken: Cookies.get("SamaId"),
    });

    if (response) {
      let { data } = response;

      if (data && data.success) {
        let { payers } = data;

        this.setState({ payers });
      }
    }
  }
  renderEditView = async () => {
    let { editableRow } = this.state;

    //convert editableRow["denial_codes"] from array of string to array of json

    if (editableRow.denail_codes && editableRow.denail_codes.length > 0) {
      let newSelectedDenialCodes = [];

      if (typeof editableRow.denail_codes[0] == "string") {
        for (let index = 0; index < editableRow.denail_codes.length; index++) {
          let code = editableRow.denail_codes[index];
          newSelectedDenialCodes.push({ name: code, value: code });
        }
        editableRow["denail_codes"] = newSelectedDenialCodes;
        await this.setState({ editableRow });
      }
    }

    //get the predefined networks for the selected payer
    if (editableRow && this.state.getNetworksFlag) {
      let { payer_id } = editableRow;

      this.getNetworksForPayer(payer_id);
      this.setState({ getNetworksFlag: false });
    }
  };
  /////////////////////////////////
  render() {
    let {
      isAddNewRule,
      isUpdateCoverdicd,
      isUpdatenonCoverdicd,

      isSearch,
      AllRules,
      DiagnosisCodes,
      DiagnosisCodes1,
      ActivityCodes,
      editableRow,
      noncovered_icds,
      coverd_icds,
      payers,
      predefinedNetworks,
      UploadRule,
      updateRule,
    } = this.state;

    let payersList;
    if (payers && payers.length > 0) {
      payersList = payers.map((payer) => {
        return (
          <option value={payer["eclaimlink_id"]}>
            {payer["eclaimlink_id"]}
          </option>
        );
      });
    }
    let payerList;
    if (payers && payers.length > 0) {
      payerList = payers.map((payer, idx) => {
        return (
          <MenuItem key={idx} value={payer["eclaimlink_id"]}>
            {payer["eclaimlink_id"]}
          </MenuItem>
        );
      });
    }

    let RuleListTable;

    if (AllRules && AllRules.length > 0) {
      RuleListTable = AllRules.map((row, index) => {
        if (editableRow) {
          coverd_icds = editableRow.coverd_icds;
        }
        if (editableRow) {
          noncovered_icds = editableRow.noncovered_icds;
        }
        let coverd;
        if (row["coverd_icds"]) {
          coverd = row["coverd_icds"].join(",");
        }
        let noncovered;

        if (row["noncovered_icds"]) {
          noncovered = row["noncovered_icds"].join(",");
        }
        let denialCodesStringToShowInTable = "";
        if (row.denail_codes && row.denail_codes.length > 0) {
          //check if the element of denial codes array of type object

          if (typeof row.denail_codes[0] == "object") {
            let newDenialCodesArray = [];
            row.denail_codes.forEach((code) => {
              newDenialCodesArray.push(code["value"]);
            });
            denialCodesStringToShowInTable = "";
            denialCodesStringToShowInTable += newDenialCodesArray;
          } else {
            denialCodesStringToShowInTable += row.denail_codes;
          }
        }

        return (
          <tr key={index}>
            {/* <td>
              {row["id"]}
            </td> */}
            <td>{row["payer_id"]}</td>
            <td>
              {row["networks"]
                ? row["networks"].map((network, idx) => {
                    return (
                      <p style={{ marginBottom: "0" }} key={idx}>
                        {network}
                      </p>
                    );
                  })
                : null}
            </td>
            <td>{row["activity"]}</td>
            <td>{row["type"].toUpperCase()}</td>
            <td>{coverd}</td>
            <td>{noncovered}</td>
            <td>{row["freq"]}</td>
            <td>{row["freq_per_time"]}</td>
            <td>{row["benefit_message"]}</td>
            <td>{row["age_group"]}</td>
            <td>{row["gender_group"]}</td>
            <td>{row["result"]}</td>
            <td>{row["responce_kayan"]}</td>
            <td>{denialCodesStringToShowInTable}</td>

            <td>{row["is_active_rule"] == true ? "Active" : "Not Active"}</td>

            <td>
              <button
                style={{
                  color: "red",
                  border: "none",
                  backgroundColor: "transparent",
                }}
                onClick={() =>
                  this.setState({
                    DeleteRule: true,
                    Ruleid: row["id"],
                  })
                }
              >
                Delete
              </button>
            </td>
            <td>
              <button
                style={{
                  color: "red",
                  border: "none",
                  backgroundColor: "transparent",
                }}
                onClick={async () => {
                  await this.setState({ editableRow: row, updateRule: true });

                  this.renderEditView();
                }}
              >
                Edit
              </button>
            </td>
          </tr>
        );
      });
    }
    let coverd_icdsTable;
    if (AllRules && AllRules.length > 0) {
      AllRules.map((row, index) => {
        if (coverd_icds && coverd_icds.length > 0) {
          return (
            <tr>
              {
                (coverd_icdsTable = coverd_icds.map((code, index1) => (
                  <tr key={index1}>
                    <td>{code}</td>
                    <td>
                      {" "}
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => {
                            this.handleDeleteCoverd(index1);
                          }}
                        >
                          <Icon color="red" icon="fluent:delete-12-regular" />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                )))
              }
            </tr>
          );
        }
      });
    }

    let noncoverd_icdsTable;
    if (AllRules && AllRules.length > 0) {
      AllRules.map((row, index) => {
        if (noncovered_icds && noncovered_icds.length > 0) {
          return (
            <tr>
              {
                (noncoverd_icdsTable = noncovered_icds.map((code, index1) => (
                  <tr key={index1}>
                    <td>{code}</td>
                    <td>
                      {" "}
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => {
                            this.handleDeleteNonCoverd(index1);
                          }}
                        >
                          <Icon color="red" icon="fluent:delete-12-regular" />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                )))
              }
            </tr>
          );
        }
      });
    }

    return (
      <>
        <>
          <div className="container" style={{ marginTop: "1%", width: "100%" }}>
            {isAddNewRule ? (
              <div className="popup">
                <div
                  className="form"
                  style={{
                    width: "70%",
                    marginTop: "2%",
                    marginLeft: "15%",
                    backgroundColor: "white",
                    borderRadius: 5 + "px",
                    paddingTop: "0.5rem",
                  }}
                >
                  <div
                    className="container"
                    style={{ marginTop: "0px", width: "100%" }}
                  >
                    <div className="row">
                      <div className="col">
                        <table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Coverd ICDs</th>
                            </tr>
                          </thead>
                          <td style={{ fontSize: "0.9rem" }}>
                            {DiagnosisCodes}
                          </td>
                        </table>
                      </div>

                      <div className="col">
                        <table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Non Covered ICDs</th>
                            </tr>
                          </thead>
                          <td style={{ fontSize: "0.9rem" }}>
                            {DiagnosisCodes1}
                          </td>
                        </table>
                      </div>

                      <div className="col">
                        {ActivityCodes !== null ? (
                          <table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Activity Code</th>
                              </tr>
                            </thead>
                            <td style={{ fontSize: "0.9rem" }}>
                              {ActivityCodes}
                            </td>
                          </table>
                        ) : null}
                      </div>
                    </div>
                    {/*  11-m */}
                    <div className="row" style={{ padding: "1rem 0" }}>
                      <Grid
                        container
                        style={{
                          marginTop: "0px",
                          width: "90%",
                          marginLeft: "5%",
                        }}
                      >
                        <Grid item xs={5}>
                          <InputLabel
                            style={{ fontWeight: "bold", color: "#212529" }}
                          >
                            Payer ID
                          </InputLabel>
                          <MuiSelect
                            variant="outlined"
                            name="payer_id"
                            style={{ width: "100%", marginBottom: "0.7rem" }}
                            value={
                              this.state.payer_id ? this.state.payer_id : ""
                            }
                            onChange={(e) => this.handleInputTextChange(e)}
                          >
                            {payerList}
                          </MuiSelect>

                          <InputLabel
                            style={{ fontWeight: "bold", color: "#212529" }}
                          >
                            Coverd ICDs
                          </InputLabel>
                          <CustomAutocomplete
                            multiple
                            value={this.state.DiagnosisCodes}
                            options={
                              this.state.filteredSuggestionsDiagnosis || []
                            }
                            onChange={(ev, newValue) => {
                              this.setState({
                                DiagnosisCodes: newValue,
                              });
                            }}
                            onInputChange={(event) => {
                              event.target.name = "coverd_icds";
                              if (
                                event.target.value &&
                                event.target.value != ""
                              ) {
                                this.onChange(event, "DiagnosisCodes");
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Coverd ICDs"
                                variant="outlined"
                                style={{ marginBottom: "0.5rem" }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {this.state.isLoadingDiagnosis ? (
                                        <Spinner
                                          animation="grow"
                                          variant="success"
                                          size="sm"
                                        />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                            variant="contained"
                          />
                          <InputLabel
                            style={{ fontWeight: "bold", color: "#212529" }}
                          >
                            Type
                          </InputLabel>
                          <MuiSelect
                            variant="outlined"
                            name="type"
                            style={{ width: "100%", marginBottom: "0.7rem" }}
                            onChange={(e) => this.handleInputTextChange(e)}
                          >
                            <MenuItem value="cpt">CPT</MenuItem>
                            <MenuItem value="hcpcs">HCPCS</MenuItem>
                            <MenuItem value="dental">Dental</MenuItem>
                            <MenuItem value="drug">Drug</MenuItem>
                            <MenuItem value="drg">DRG</MenuItem>
                            <MenuItem value="service">Service</MenuItem>
                          </MuiSelect>

                          <Controls.InputNumber
                            name="freq"
                            label="Frequency"
                            onChange={(e) => this.handleInputTextChange(e)}
                          />
                          <InputLabel
                            style={{ fontWeight: "bold", color: "#212529" }}
                          >
                            Denail
                          </InputLabel>
                          <div
                            style={{
                              marginTop: "1.15rem",
                              marginBottom: "0.5rem",
                            }}
                          >
                            <Multiselect
                              // className="multiselectContainer"
                              style={{ padding: "1rem" }}
                              options={dCodes1}
                              selectedValues={this.state.selectedValue}
                              displayValue="name"
                              onSelect={this.selectDenileID}
                              onRemove={this.removeDenileID}
                            />
                          </div>

                          <Controls.InputNumber
                            name="age_group"
                            label="Age Group"
                            onChange={(e) => this.handleInputTextChange(e)}
                          />
                          <FormControl margin="normal" fullWidth>
                            <InputLabel htmlFor="image">
                              Benefit Message
                            </InputLabel>
                            <Input
                              name="benefit_message"
                              onChange={(e) => this.handleInputTextChange(e)}
                              multiline
                              rows={5}
                            />
                          </FormControl>
                          <Button
                            variant="contained"
                            onClick={() =>
                              this.setState({ isAddNewRule: false })
                            }
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              float: "left",
                              marginTop: "1rem",
                              padding: "0.5rem 1rem",
                              fontSize: "1rem",
                            }}
                          >
                            Close
                          </Button>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={5}>
                          <InputLabel
                            style={{ fontWeight: "bold", color: "#212529" }}
                          >
                            Network
                          </InputLabel>

                          {this.state.payer_id ? (
                            <MuiSelect
                              variant="outlined"
                              name="networks"
                              style={{ width: "100%", marginBottom: "0.5rem" }}
                              onChange={(e) => this.handleInputTextChange(e)}
                            >
                              <MenuItem value="ALL">ALL</MenuItem>
                              <MenuItem value="C1">C1</MenuItem>
                              <MenuItem value="C2">C2</MenuItem>
                              <MenuItem value="B1">B1</MenuItem>
                              <MenuItem value="B2">B2</MenuItem>
                              <MenuItem value="A2">A2</MenuItem>
                              <MenuItem value="NW1">NW1</MenuItem>
                              <MenuItem value="NW2">NW2</MenuItem>
                              <MenuItem value="NW5">NW5</MenuItem>
                              {this.state.predefinedNetworksMenue}
                            </MuiSelect>
                          ) : (
                            <MuiSelect
                              disabled
                              variant="outlined"
                              name="networks"
                              style={{ width: "100%", marginBottom: "0.5rem" }}
                              onChange={(e) => this.handleInputTextChange(e)}
                            ></MuiSelect>
                          )}

                          <InputLabel
                            style={{ fontWeight: "bold", color: "#212529" }}
                          >
                            Non Coverd ICDs
                          </InputLabel>
                          <CustomAutocomplete
                            multiple
                            value={this.state.DiagnosisCodes1}
                            options={
                              this.state
                                .filteredSuggestionsDiagnosis_noncovered || []
                            }
                            onChange={(ev, newValue) => {
                              this.setState({
                                DiagnosisCodes1: newValue,
                              });
                            }}
                            onInputChange={(event) => {
                              event.target.name = "Non_Coverd_icds";
                              if (
                                event.target.value &&
                                event.target.value != ""
                              ) {
                                this.onChange(event, "DiagnosisCodes1");
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Non Coverd ICDs"
                                variant="outlined"
                                style={{ marginBottom: "0.5rem" }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {this.state.isLoadingDiagnosis ? (
                                        <Spinner
                                          animation="grow"
                                          variant="success"
                                          size="sm"
                                        />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                            variant="contained"
                          />
                          <NewForm
                            inputsTypes={["Search2"]}
                            elementsNames={["ActivityCodes"]}
                            inputStyle="ITRule"
                            submitStyle="BS1"
                            section={this.state.section}
                            addNewCode={this.addNewCode}
                            onChange={this.onChange}
                            onClick={this.onClick}
                            BtnActive={this.state.BtnActive}
                            activeSuggestion={this.state.activeSuggestion}
                            allActivity={this.state.ActivityItems}
                            userInputActivity={this.state.userInputActivity}
                            filteredSuggestionsActivity={
                              this.state.filteredSuggestionsActivity
                            }
                            showActivitySuggestions={
                              this.state.showActivitySuggestions
                            }
                          />
                          <Controls.InputNumber
                            name="freq_per_time"
                            label="Frequency Per Time"
                            onChange={(e) => this.handleInputTextChange(e)}
                          />
                          <Controls.Input
                            name="result"
                            label="Result"
                            onChange={(e) => this.handleInputTextChange(e)}
                          />

                          {/* <FormLabel>Gender</FormLabel> */}
                          <InputLabel
                            style={{ fontWeight: "bold", color: "#212529" }}
                          >
                            Gender
                          </InputLabel>
                          <MuiSelect
                            variant="outlined"
                            name="gender_group"
                            style={{ width: "100%", marginBottom: "0.5rem" }}
                            onChange={(e) => this.handleInputTextChange(e)}
                          >
                            <MenuItem value="M">Male</MenuItem>
                            <MenuItem value="F">Female</MenuItem>
                          </MuiSelect>

                          <FormControl margin="normal" fullWidth>
                            <InputLabel htmlFor="image">
                              Kayan Response
                            </InputLabel>
                            <Input
                              name="responce_kayan"
                              onChange={(e) => this.handleInputTextChange(e)}
                              multiline
                              rows={5}
                            />
                          </FormControl>
                          <Button
                            variant="contained"
                            onClick={this.handleSubmit}
                            style={{
                              backgroundColor: "#3ab449",
                              color: "white",
                              float: "right",
                              marginTop: "1rem",
                              padding: "0.5rem 1rem",
                              fontSize: "1rem",
                            }}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                    {/*  11-m */}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>

        <>
          <div style={{ marginTop: "1%" }}>
            {this.state.DeleteRule ? (
              <div className="popup">
                <div
                  className="form"
                  style={{
                    width: "50%",
                    margin: "auto",
                    backgroundColor: "white",
                  }}
                >
                  <form>
                    <h4>Are you sure you want to delete this rule?</h4>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "60%",
                        margin: "0 auto",
                      }}
                    >
                      <button
                        style={{
                          padding: "0.5rem 1rem",
                          fontSize: "1rem",
                          backgroundColor: "#3ab449",
                          color: "white",
                        }}
                        Value="Yes"
                        onClick={this.DeleteRuleByID}
                      >
                        Yes
                      </button>
                      <button
                        style={{
                          padding: "0.5rem 1rem",
                          fontSize: "1rem",
                          backgroundColor: "#e53939",
                          color: "white",
                        }}
                        Value="No"
                        onClick={() =>
                          this.setState({
                            DeleteRule: false,
                          })
                        }
                      >
                        NO
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            ) : null}
          </div>
        </>
        <>
          <div className="container" style={{ marginTop: "1%", width: "100%" }}>
            {updateRule ? (
              <div className="popup">
                <div
                  className="form"
                  style={{
                    width: "70%",
                    marginTop: "2%",
                    marginLeft: "25%",
                    backgroundColor: "white",
                    borderRadius: 5 + "px",
                    paddingTop: "1rem",
                  }}
                >
                  <div style={{ marginTop: "0px", width: "100%" }}>
                    {/*  11-m */}
                    <div className="row" style={{ padding: "1rem 0" }}>
                      <Grid
                        container
                        style={{
                          marginTop: "0px",
                          width: "90%",
                          marginLeft: "5%",
                        }}
                      >
                        <Grid item xs={5}>
                          <InputLabel
                            style={{ fontWeight: "bold", color: "#212529" }}
                          >
                            Payer ID
                          </InputLabel>
                          <MuiSelect
                            variant="outlined"
                            name="payer_id"
                            style={{ width: "100%", marginBottom: "0.7rem" }}
                            value={
                              this.state.payer_id
                                ? this.state.payer_id
                                : editableRow["payer_id"]
                            }
                            onChange={(e) => this.handleNewInputTextChange(e)}
                          >
                            {payerList}
                          </MuiSelect>
                          <InputLabel
                            style={{ fontWeight: "bold", color: "#212529" }}
                          >
                            Type
                          </InputLabel>
                          <MuiSelect
                            variant="outlined"
                            name="type"
                            style={{ width: "100%", marginBottom: "0.7rem" }}
                            onChange={(e) => this.handleNewInputTextChange(e)}
                            defaultValue={editableRow["type"]}
                          >
                            <MenuItem value="cpt">CPT</MenuItem>
                            <MenuItem value="hcpcs">HCPCS</MenuItem>
                            <MenuItem value="dental">Dental</MenuItem>
                            <MenuItem value="drug">Drug</MenuItem>
                            <MenuItem value="drg">DRG</MenuItem>
                            <MenuItem value="service">Service</MenuItem>
                          </MuiSelect>
                          <Controls.InputNumber
                            name="freq"
                            label="Frequency"
                            value={editableRow["freq"]}
                            onChange={(e) => this.handleNewInputTextChange(e)}
                          />
                          <InputLabel
                            style={{ fontWeight: "bold", color: "#212529" }}
                          >
                            Denail
                          </InputLabel>
                          <div
                            style={{
                              marginTop: "1.15rem",
                              marginBottom: "0.5rem",
                            }}
                          >
                            <Multiselect
                              style={{ padding: "10px" }}
                              options={dCodes1}
                              selectedValues={editableRow["denail_codes"]}
                              displayValue="name"
                              onSelect={this.selectDenileID}
                              onRemove={this.removeDenileID}
                            />
                          </div>
                          <Controls.InputNumber
                            name="age_group"
                            label="Age Group"
                            value={editableRow["age_group"]}
                            onChange={(e) => this.handleNewInputTextChange(e)}
                          />
                          <FormControl margin="normal" fullWidth>
                            <InputLabel htmlFor="image">
                              Benefit Message
                            </InputLabel>
                            <Input
                              name="benefit_message"
                              onChange={(e) => this.handleNewInputTextChange(e)}
                              multiline
                              defaultValue={editableRow["benefit_message"]}
                              rows={5}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={5}>
                          <InputLabel
                            style={{ fontWeight: "bold", color: "#212529" }}
                          >
                            Network
                          </InputLabel>
                          <MuiSelect
                            variant="outlined"
                            name="networks"
                            style={{ width: "100%", marginBottom: "0.5rem" }}
                            onChange={(e) => this.handleNewInputTextChange(e)}
                            defaultValue={editableRow["networks"]}
                          >
                            <MenuItem value="ALL">ALL</MenuItem>
                            <MenuItem value="C1">C1</MenuItem>
                            <MenuItem value="C2">C2</MenuItem>
                            <MenuItem value="B1">B1</MenuItem>
                            <MenuItem value="B2">B2</MenuItem>
                            <MenuItem value="A2">A2</MenuItem>
                            <MenuItem value="NW1">NW1</MenuItem>
                            <MenuItem value="NW2">NW2</MenuItem>
                            <MenuItem value="NW5">NW5</MenuItem>
                            {this.state.predefinedNetworksMenue}
                          </MuiSelect>
                          <NewForm
                            defaultValue={editableRow["activity"]}
                            placeholder={editableRow.activity}
                            inputsTypes={["Search2"]}
                            elementsNames={["ActivityCodes"]}
                            inputStyle="ITRule"
                            submitStyle="BS1"
                            section={this.state.section}
                            addNewCode={this.addNewCode}
                            onChange={this.onChange}
                            onClick={this.onClick}
                            BtnActive={this.state.BtnActive}
                            activeSuggestion={this.state.activeSuggestion}
                            allActivity={this.state.ActivityItems}
                            userInputActivity={this.state.userInputActivity}
                            filteredSuggestionsActivity={
                              this.state.filteredSuggestionsActivity
                            }
                            showActivitySuggestions={
                              this.state.showActivitySuggestions
                            }
                          />
                          <Controls.InputNumber
                            name="freq_per_time"
                            label="Frequency Per Time"
                            onChange={(e) => this.handleNewInputTextChange(e)}
                            value={editableRow["freq_per_time"]}
                          />
                          <Controls.Input
                            name="result"
                            label="Result"
                            onChange={(e) => this.handleNewInputTextChange(e)}
                            value={editableRow["result"]}
                          />

                          {/* <FormLabel>Gender</FormLabel> */}
                          <InputLabel
                            style={{ fontWeight: "bold", color: "#212529" }}
                          >
                            Gender
                          </InputLabel>
                          <MuiSelect
                            variant="outlined"
                            name="gender_group"
                            style={{ width: "100%", marginBottom: "0.5rem" }}
                            onChange={(e) => this.handleNewInputTextChange(e)}
                            defaultValue={editableRow["gender_group"]}
                          >
                            <MenuItem value="M">Male</MenuItem>
                            <MenuItem value="F">Female</MenuItem>
                          </MuiSelect>

                          <FormControl margin="normal" fullWidth>
                            <InputLabel htmlFor="image">
                              Kayan Response
                            </InputLabel>
                            <Input
                              name="responce_kayan"
                              defaultValue={editableRow["responce_kayan"]}
                              onChange={(e) => this.handleNewInputTextChange(e)}
                              multiline
                              rows={5}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                    {/*  11-m */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "20px",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <Typography
                          style={{ textAlign: "left" }}
                          variant="h6"
                          gutterBottom
                        >
                          Non Coverd ICDs
                        </Typography>
                        <MuiButton
                          variant="contained"
                          startIcon={<Icon icon="carbon:add-alt" />}
                          onClick={() => {
                            this.setState({
                              isUpdatenonCoverdicd: true,
                            });
                          }}
                          style={{
                            backgroundColor: "#37ab45",
                            color: "white",
                            marginBottom: "0.5rem",
                            marginRight: "725px",
                            width: "192px",
                          }}
                        >
                          Add New Code
                        </MuiButton>
                        {editableRow.noncovered_icds &&
                        editableRow.noncovered_icds.length > 0 ? (
                          <table
                            striped
                            bordered
                            hover
                            style={{ width: "90%" }}
                          >
                            <thead>
                              <tr>
                                <th>Code</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>{noncoverd_icdsTable}</tbody>
                          </table>
                        ) : null}
                      </div>
                      <div style={{ width: "50%", marginLeft: "47px" }}>
                        <Typography
                          style={{ textAlign: "left" }}
                          variant="h6"
                          gutterBottom
                        >
                          Coverd ICDs
                        </Typography>
                        <MuiButton
                          variant="contained"
                          startIcon={<Icon icon="carbon:add-alt" />}
                          onClick={() => {
                            this.setState({
                              isUpdateCoverdicd: true,
                            });
                          }}
                          style={{
                            backgroundColor: "#37ab45",
                            color: "white",
                            marginBottom: "0.5rem",
                            marginRight: "725px",
                            width: "192px",
                          }}
                        >
                          Add New Code
                        </MuiButton>
                        {editableRow.coverd_icds &&
                        editableRow.coverd_icds.length > 0 ? (
                          <table
                            striped
                            bordered
                            hover
                            style={{ width: "90%" }}
                          >
                            <thead>
                              <tr>
                                <th>Code</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>{coverd_icdsTable}</tbody>
                          </table>
                        ) : null}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "60%",
                        margin: "auto",
                        marginTop: "5px",
                      }}
                    >
                      {editableRow["is_active_rule"] == true ? (
                        <div>
                          <input
                            checked
                            type="checkbox"
                            name="is_active_rule"
                            // value="true"
                            onChange={(e) => this.handleNewInputTextChange(e)}
                          />{" "}
                          De/Activate
                        </div>
                      ) : (
                        <div>
                          <input
                            type="checkbox"
                            name="is_active_rule"
                            value="false"
                            onChange={(e) => this.handleNewInputTextChange(e)}
                          />{" "}
                          De/Activate
                        </div>
                      )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0.5rem 1rem",
                        marginBottom: "1rem",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() =>
                          this.setState({
                            updateRule: false,
                            isEditMode: !this.state.isEditMode,
                            payer_id: "",
                            getNetworksFlag: true,
                          })
                        }
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          padding: "0.5rem 1rem",
                          fontSize: "1rem",
                        }}
                      >
                        Close
                      </Button>
                      <Button
                        variant="contained"
                        onClick={this.update.bind(this)}
                        style={{
                          backgroundColor: "green",
                          color: "white",
                          padding: "0.5rem 1rem",
                          fontSize: "1rem",
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
        <>
          <div className="container" style={{ marginTop: "1%" }}>
            {isUpdatenonCoverdicd ? (
              <div className="popup">
                <div
                  className="form"
                  style={{
                    width: "40%",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    margin: "auto",
                    marginLeft: "37%",
                    marginTop: "40PX",
                    backgroundColor: "white",
                  }}
                >
                  <div className="container" style={{ marginTop: "30px" }}>
                    <FormSearch
                      name={this.name}
                      inputsTypes={["SamaDiagnosisList5"]}
                      elementsNames={["DiagnosisCodes1"]}
                      inputStyle="IT3"
                      submitStyle="BS1"
                      addNewCode={this.addNewCode}
                      onChange={this.onChange}
                      onClick={this.onClick}
                      BtnActive_noncovered={this.state.BtnActive_noncovered}
                      diagnosisNow={100}
                      section={this.state.section}
                      allDiagnosis={this.state.diagnosisItems_noncovered}
                      userInputDiagnosis_noncovred={
                        this.state.userInputDiagnosis_noncovred
                      }
                      /*   activeSuggestion={this.state.activeSuggestion} */
                      filteredSuggestionsDiagnosis_noncovered={
                        this.state.filteredSuggestionsDiagnosis_noncovered
                      }
                      showDiagnosisSuggestions_noncovered={
                        this.state.showDiagnosisSuggestions_noncovered
                      }
                    />
                  </div>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      textAlign: "right",
                      cursor: "pointer",
                      margin: "0 2%",
                    }}
                    onClick={() =>
                      this.setState({
                        isUpdatenonCoverdicd: false,
                        noncovered_icds: [],
                      })
                    }
                  >
                    {" "}
                    Close{" "}
                  </p>
                </div>
              </div>
            ) : null}
            {isUpdateCoverdicd ? (
              <div className="popup">
                <div
                  className="form"
                  style={{
                    width: "40%",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    margin: "auto",
                    marginLeft: "37%",
                    marginTop: "40PX",
                    backgroundColor: "white",
                  }}
                >
                  <div style={{ marginTop: "30px" }}>
                    <FormSearch
                      inputsTypes={["SamaDiagnosisList4"]}
                      elementsNames={["DiagnosisCodes"]}
                      inputStyle="IT3"
                      submitStyle="BS1"
                      addNewCode={this.addNewCode}
                      onChange={this.onChange}
                      onClick={this.onClick}
                      BtnActive={this.state.BtnActive}
                      diagnosisNow={100}
                      section={this.state.section}
                      allDiagnosis={this.state.diagnosisItems}
                      userInputDiagnosis={this.state.userInputDiagnosis}
                      /*   activeSuggestion={this.state.activeSuggestion} */
                      filteredSuggestionsDiagnosis={
                        this.state.filteredSuggestionsDiagnosis
                      }
                      showDiagnosisSuggestions={
                        this.state.showDiagnosisSuggestions
                      }
                    />
                  </div>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      textAlign: "right",
                      cursor: "pointer",
                      margin: "0 2%",
                    }}
                    onClick={() =>
                      this.setState({
                        isUpdateCoverdicd: false,
                        coverd_icds: [],
                      })
                    }
                  >
                    {" "}
                    Close{" "}
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        </>

        <>
          <div
            className="container"
            style={{
              width: "95%",
              boxShadow:
                "0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)",
              marginTop: "40px",
              padding: "3% 0",
            }}
          >
            <div>
              <h4 style={{ color: "green" }}>
                Update Existing Rules / Adding New Rule
              </h4>
              <br />
            </div>
            <div className="row">
              <div className="col">
                <select
                  value={this.state.NetworkID}
                  onChange={(e) => this.handleSelectChange(e)}
                >
                  <option>Network</option>
                  <option value="NW1">NW1</option>
                  <option value="NW2">NW2</option>
                  <option value="NW3">NW3</option>
                  <option value="ALL">ALL</option>
                </select>
                <select
                  onChange={(e) => this.handleSelectTypeChange(e)}
                  value={this.state.Type}
                >
                  <option>Type</option>
                  <option value="cpt">CPT</option>
                  <option value="hcpcs">HCPCS</option>
                  <option value="dental">Dental</option>
                  <option value="drug">Drug</option>
                  <option value="drg">DRG</option>
                  <option value="service">Service</option>
                </select>
                <select
                  // value={this.state.PayerID}
                  onChange={(e) => this.handleSelectPayerChange(e)}
                  value={this.state.PayerID ? this.state.PayerID : ""}
                >
                  <option value="">Payer ID</option>
                  {payersList}
                </select>
                <input
                  type="button"
                  onClick={() =>
                    this.setState({
                      isAddNewRule: true,
                      isEditMode: false,
                      data: {},
                      payer_id: "",
                      ActivityCodes: "",
                    })
                  }
                  className="BS1"
                  value="Add New Rule"
                />
                <input
                  type="button"
                  className="BS1"
                  value="Upload Rule"
                  onClick={this.onClickUploadRule}
                />
              </div>{" "}
              <div className="col">
                <FormSearch
                  formStyle="ruleScreen"
                  style={{ width: "100%", marginTop: "20px !important" }}
                  inputsTypes={["SamaActivityList4"]}
                  elementsNames={["ActivityCodes1"]}
                  inputStyle="ITRule"
                  placeholder="Search For Activity Code"
                  submitStyle="BS1"
                  ActivityNow1={this.state.ActivityNow1}
                  section={this.state.section}
                  addNewCode={this.addNewCode}
                  onChange={this.onChange}
                  onClick={this.onClick}
                  BtnActive_search={this.state.BtnActive_search}
                  activeSuggestion={this.state.activeSuggestion}
                  allActivity={this.state.ActivityItems_Search}
                  //ActivityNow_Search={100}
                  userInputActivity_search={this.state.userInputActivity_search}
                  filteredSuggestionsActivity_search={
                    this.state.filteredSuggestionsActivity_search
                  }
                  showActivitySuggestions_search={
                    this.state.showActivitySuggestions_search
                  }
                />
                {this.state.MSG ? <h2>{this.state.MSG}</h2> : null}
                <input
                  type="submit"
                  value="Search"
                  style={{
                    marginTop: "10px",
                    padding: "0.5rem 1.7rem",
                    fontSize: "1rem",
                    border: "none",
                    backgroundColor: "#3ab449",
                    color: "white",
                  }}
                  onClick={this.search.bind(this)}
                  name="name"
                />
              </div>
            </div>
            {UploadRule ? (
              <div className="popup">
                <div
                  className="form5"
                  style={{
                    width: "60%",
                    marginLeft: "25%",
                    backgroundColor: "white",
                  }}
                >
                  <FileUploadRule />
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      cursor: "pointer",
                      textAlign: "right",
                    }}
                    onClick={() => {
                      this.setState({
                        UploadRule: false,
                      });
                    }}
                  >
                    {" "}
                    Close{" "}
                  </p>
                </div>
              </div>
            ) : null}

            <div style={{ maxWidth: "100%" }}>
              <div style={{ boxSizing: "border-box", maxWidth: "100%" }}>
                {isSearch ? (
                  <Spinner animation="border" variant="success" />
                ) : null}
                {AllRules && AllRules.length > 0 ? (
                  <Table
                    responsive
                    striped
                    bordered
                    hover
                    size="sm"
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    <thead
                      style={{
                        background: "#3ab449",
                        color: " white",
                        verticalAlign: "middle",
                      }}
                    >
                      <tr>
                        {/* <th>ID</th> */}
                        <th>Payer ID</th>
                        <th>Networks</th>
                        <th>Activity</th>
                        <th>Type</th>

                        <th>Covered ICDs</th>
                        <th>Non Covered ICDs</th>
                        <th>Frequency</th>
                        <th>Frequency Per Time</th>

                        <th>Benefit Message</th>
                        <th>Age Group</th>
                        <th>Gender Group</th>

                        <th>result</th>
                        <th>Kayan Response</th>
                        <th>Denail Codes</th>
                        <th>Status</th>
                        <th>Delete Rule</th>
                        <th>Edit Rule</th>
                      </tr>
                    </thead>
                    <tbody>{RuleListTable}</tbody>
                  </Table>
                ) : !isSearch && AllRules && AllRules.length == 0 ? (
                  <h6>There is no Rule with this status</h6>
                ) : null}
              </div>
            </div>
          </div>
        </>

        <div style={{ marginTop: "50px" }}></div>
      </>
    );
  }
}
export default UpdateRule;
