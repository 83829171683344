import React, { Component } from "react";
import NavBar from "./../Components/navbar";

import Footer from "./../Components/footer";

import Sample from "./../Components/sample";

class BayanSample extends Component {
  render() {
    return (
      <>
        <NavBar />

        <Sample />

        <Footer />
      </>
    );
  }
}

export default BayanSample;
