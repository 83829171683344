import React from "react";
import "./Codes.css";
import SamaListView from "../SamaListView";
import Button from "../Button";
import Input from "../InputText";
import DropList from "../DropList";
import ProgressBar from "react-bootstrap/ProgressBar";
import { TextField,InputLabel } from '@material-ui/core';
function defaultClick(e) {
  e.preventDefault();
}

function Search(props) {


  let {
      lable,
    Items,
    addNewCode,
    inputStyle,
    listName,
    onSelect,
    section,
    BtnActive_noncovered,
    attributes,
    suggestions,
    onChange,
    onClick,
    userInputDiagnosis_noncovred,
    activeSuggestion,
    filteredSuggestionsDiagnosis_noncovered,
    showDiagnosisSuggestions_noncovered,
    handleItemDeletion,
    diagnosisNow,
    name,
    index
  } = props;
  


  let Options;



  let suggestionsListComponentDiagnosis;

  if (showDiagnosisSuggestions_noncovered && userInputDiagnosis_noncovred) {
    if (filteredSuggestionsDiagnosis_noncovered && filteredSuggestionsDiagnosis_noncovered.length) {
      suggestionsListComponentDiagnosis = (
        <ul className="suggestions">
          {filteredSuggestionsDiagnosis_noncovered.map((suggestion, index) => {
            let className;

            // Flag the active suggestion with a class
            if (index === activeSuggestion) {
              className = "suggestion-active";
            }

            return (
              <li className={className} key={suggestion} onClick={onClick}>
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    } else {
      suggestionsListComponentDiagnosis = (
        <div className="no-suggestions">
          <em>No suggestions...</em>
        </div>
      );
    }
  }

  let InputId = "",
    BtnValue = "";
  if (listName === "DiagnosisCodes1") {
    InputId = "DiagnosisCodes1";
    BtnValue = "Confirm Code";
  }


  return (
    <>
    <InputLabel style={{fontWeight:"bold",color:"#212529"}}>Non Coverd ICDs</InputLabel>
      <TextField
      type="search"
        variant="outlined"
        name="noncovered_icds"
        key={index}
        style={{marginBottom:"0.5rem",width:"100%"}}
        id={InputId}
        disabled={suggestions === undefined ? true : false}
        onChange={(e) => onChange(e,listName)} 
        hint="Search For ICD code"
        value={listName === "DiagnosisCodes1"  ? userInputDiagnosis_noncovred : null}
      />


      {listName === "DiagnosisCodes1" ? suggestionsListComponentDiagnosis : null}

      {listName === "DiagnosisCodes1" && section === "Diagnosis" && BtnActive_noncovered ? (

        <>
          {attributes && attributes.length > 0
            ? attributes.map((attribute, index) => (
              <DropList
                key={index}
                id="diagnosisType"
                Title="Diagnosis Type"
                name="DiagnosisType"
                Options={Options}
                Values={Options}
                defaultValue={Options[0]}
                onSelect={onSelect}
                Style="requestSelect"
              />
            ))
            : null}
        <Button
            Style="enabledStyle"
            Value={BtnValue}
            onClick={(e) => addNewCode(e, listName, InputId)}
          />
        </>
      ) : (
         null
        )}
    </>
  );
}
export default Search;