import React, { Component } from "react";
import NavBar from "../Components/navbar";
import Footer from "../Components/footer";
import Cookies from "js-cookie";

class BayanKSALogin extends Component {
  componentDidMount = () => {
    const { history } = this.props;

    /*   if (Cookies.get("BayanKSAEmployeeId")) {
          history.push(`/BayanKSA`);
        } */
  };
  render() {
    return (
      <>
        <NavBar />
        <div style={{ height: "60px" }}></div>

        <div className="BayanLinks">
          <a href="/BayanKSAAsEmployee">Login As Employee</a>
          {/* <a href="/KareemAsCompany">Login As Company</a> */}
        </div>

        <div style={{ height: "205px" }}></div>

        <Footer />
      </>
    );
  }
}

export default BayanKSALogin;
