import React, { Component } from "react";
//import Nav from "react-bootstrap/Nav";
//import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import "../App.css";
//import Carousel from "react-bootstrap/Carousel";

class Sample extends Component {
  render() {
    return (
      <div className="Sample">
        <Container>
        <h2>Request XML Sample</h2>
          <p style={{ textAlign: "left" }}>
          The following example is for a request that contains an issue with the diagnosis code, where this code can’t come alone and need to be supported with another code. The request contains one encounter, one principle diagnosis, and one activity recognized as CPT because it’s from source “3”.

          </p>
          <pre>
            {`
        
        <?xml version="1.0" encoding="UTF-8" standalone="yes"?>
        <Claim.Submission>
            <Header>
                <SenderID>11111111</SenderID>
                <ReceiverID>1111111</ReceiverID>
                <TransactionDate>18/11/2019 11:43</TransactionDate>
                <RecordCount>1</RecordCount>
                <DispositionFlag>PRODUCTION</DispositionFlag>
            </Header>
            <Claim>
                <IDPayer>11</IDPayer>
                <MemberID>1111</MemberID>
                <PayerID>11111</PayerID>
                <ProviderID>1111111</ProviderID>
                <EmiratesIDNumber>999-9999-9999999-9</EmiratesIDNumber>
                <Gross>53.1</Gross>
                <PatientShare>0</PatientShare>
                <Net>53.1</Net>
                <Encounter>
                    <FacilityID>111111</FacilityID>
                    <PatientID>111111</PatientID>
                    <PatientAge>53</PatientAge>
                    <PatientGender>F</PatientGender>
                    <Start>18/12/2018 20:09</Start>
                    <End>18/12/2018 20:49</End>
                    <StartType>1</StartType>
                </Encounter>
                <Diagnosis>
                    <Type>Principal</Type>
                    <Code>I25.10</Code>
                </Diagnosis>
                <Diagnosis>
                    <Type>Secondary</Type>
                </Diagnosis>
                <Activity>
                    <ID>418300</ID>
                    <Start></Start>
                    <Type>3</Type>
                    <Code>33536</Code>
                    <Quantity></Quantity>
                    <Net>8.1</Net>
                    <Clinician>11111111</Clinician>
                </Activity>
                <Activity>
                    <ID>418300</ID>
                    <Start>18/12/2018 20:09</Start>
                    <Type>3</Type>
                    <Code></Code>
                    <Quantity>1</Quantity>
                    <Net>8.1</Net>
                    <Clinician>11111111</Clinician>
                </Activity>
                <Activity>
                    <ID>418300</ID>
                    <Start>18/12/2018 20:09</Start>
                    <Type></Type>
                    <Code>33536</Code>
                    <Quantity>1</Quantity>
                    <Net>8.1</Net>
                    <Clinician>11111111</Clinician>
                </Activity>
                <Activity>
                    <ID>418300</ID>
                    <Start>18/12/2018 20:09</Start>
                    <Code>33536</Code>
                    <Quantity>1</Quantity>
                    <Net>8.1</Net>
                    <Clinician>11111111</Clinician>
                </Activity>
            </Claim>		
        </Claim.Submission>
            `}
          </pre>
          <h2>Response Sample</h2>

<p style={{ textAlign: "left" }}>
The following XML represent the response for the previous request. The response will contain a status tag that indicates the status of the request. The next tag is for errors list. If the status of the request is success, then the list will be empty, otherwise list of errors per encounter will be shown. The first child tag inside the encounter tag represents the line where does this encounter starts at in the request file. The second tag represents the id of the encounter, or the index of the encounter in the XML request. After that, a list of data errors and/or schema errors show up if there are issues with data or schema. For more information and examples please visit www.kayan-healthcare.com/Bayan.          </p>
<pre>
  {`
 <Response-Status>
 <Status>Failed</Status>
 <Request-Errors>
     <Claim>
         <EncounterId>1</EncounterId>
         <Line>10</Line>
         <Data-Errors>
             <Code>
                 <ActivityID>418300</ActivityID>
                 <ClaimID></ClaimID>
                 <ActivityCode>33536</ActivityCode>
                 <Level>Edit</Level>
                 <Domain>Activity</Domain>
                 <List>CPT</List>
                 <CodeId>1</CodeId>
                 <Line>39</Line>
                 <Type>Incorrect CPT-ICD Relation</Type>
                 <Message>The procedure code (33536) does not match with any of the diagnoses (I25.10).Suggested diagnoses(I25.110, I25.111, I25.118).</Message>
             </Code>
         </Data-Errors>
         <Schema-Errors>
             <Claim>
                 <ClaimId></ClaimId>
                 <Element>Claim</Element>
                 <Line>10</Line>
                 <Message>Element Claim: Missing child element(s). Expected is ( ID ).</Message>
             </Claim>
             <Encounter>
                 <ClaimId></ClaimId>
                 <Element>Encounter</Element>
                 <Domain>Claim</Domain>
                 <Line>19</Line>
                 <Message>Element Encounter: Missing child element(s). Expected is ( Type ).</Message>
             </Encounter>
             <Diagnosis>
                 <ActivityID></ActivityID>
                 <ClaimId></ClaimId>
                 <Element>Diagnosis</Element>
                 <DiagnosisId>2</DiagnosisId>
                 <Line>32</Line>
                 <Message>Element Diagnosis: Missing child element(s). Expected is ( Code, Type ).</Message>
             </Diagnosis>
             <Start>
                 <ActivityID>418300</ActivityID>
                 <ClaimId></ClaimId>
                 <Element>Start</Element>
                 <Line>37</Line>
                 <Message>Element Start: The start date () has incorrect date format</Message>
             </Start>
             <Quantity>
                 <ActivityID>418300</ActivityID>
                 <ClaimId></ClaimId>
                 <Element>Quantity</Element>
                 <Line>40</Line>
                 <Message>Element Quantity: The quantity value () is invalid.</Message>
             </Quantity>
             <Code>
                 <ActivityID>418300</ActivityID>
                 <ClaimId></ClaimId>
                 <Element>Code</Element>
                 <Domain>Activity</Domain>
                 <CodeId>2</CodeId>
                 <Line>47</Line>
                 <Message>Element Code, Parent activity: Element value () is invalid</Message>
             </Code>
             <Type>
                 <ActivityID>418300</ActivityID>
                 <ClaimId></ClaimId>
                 <Element>Type</Element>
                 <Domain>Activity</Domain>
                 <CodeId>3</CodeId>
                 <Line>57</Line>
                 <Message>Element Type, Parent activity: Element value () out of the activity type values range { 3, 4, 5, 6, 8, 9, 10 }</Message>
             </Type>
             <Activity>
                 <ActivityID>418300</ActivityID>
                 <ClaimId></ClaimId>
                 <Element>Activity</Element>
                 <ActivityId>4</ActivityId>
                 <Line>62</Line>
                 <Message>Element Activity: Missing child element(s). Expected is ( Code, Type ).</Message>
             </Activity>
         </Schema-Errors>
     </Claim>
 </Request-Errors>
</Response-Status>

`}
</pre>


   </Container>
      </div>
    );
  }
}

export default Sample;
