import React, { Component } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Spinner from "react-bootstrap/Spinner";

import settings from "../helpers/Settings";
let { KayanURL } = settings;
var XLSX = require("xlsx");

class UploadExcelPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount = async () => {};

  handleInputTextChange = ({ target: { name, value, files } }) => {
    //  this.setState({ responseReady: false });
    this.setState({ fileName: files[0].name });

    const fileUpload = document.getElementById("file");
    const regex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
    if (regex.test(fileUpload.value.toLowerCase())) {
      let fileName = fileUpload.files[0].name;
      if (typeof FileReader !== "undefined") {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = (e) => {
            this.processExcel(reader.result);
          };
          reader.readAsBinaryString(fileUpload.files[0]);
        }
      } else {
        console.log("This browser does not support HTML5.");
      }
    } else {
      alert("Please upload a valid Excel file.");
    }
  };

  processExcel(data) {
    const workbook = XLSX.read(data, { type: "binary" });

    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[firstSheet]
    );
    // let excelRows = JSON.stringify(excelRows1);

    this.setState({ excelRows });
  }

  async onSubmit(e) {
    e.preventDefault();
    let { excelRows } = this.state;
    this.setState({ sent: true });
    let response = await axios.post(
      KayanURL + "api/KareemBenefits/pricelist/upload",
      {
        apiToken: Cookies.get("KareemId"),
        rows: excelRows,
      }
    );
    if (response) {
      let { data } = response;

      if (data.success) {
        this.setState({ success: true, sent: false });
      } else {
        this.setState({ success: false, sent: false, error: data.error });
      }
      this.props.getAllPriceLists();
      this.setState({ responseReady: true });
    }
  }

  render() {
    let { sent, success, responseReady, error } = this.state;
    let errorMap = [];
    if (error) {
      errorMap = error.map((err, index) => {
        return <h5 style={{ color: "red" }}>{err}</h5>;
      });
    }
    return (
      <>
        <div className="container">
          <form onSubmit={this.onSubmit}>
            <input
              type="file"
              name="file"
              id="file"
              onChange={this.handleInputTextChange}
            />

            <input type="submit" value="Upload" className="BS1" />
          </form>
          {sent ? <Spinner animation="border" variant="success" /> : null}

          {responseReady && success ? (
            <h5 style={{ color: "green" }}>
              Price list was uploaded successfully
            </h5>
          ) : (
            <h5 style={{ color: "red" }}>{errorMap}</h5>
          )}
        </div>
      </>
    );
  }
}

export default UploadExcelPrice;
