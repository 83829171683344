import React from 'react'
import { TextField,InputLabel } from '@material-ui/core';

export default function DateInput(props) {

    const { name, label, value, onChange } = props;
    return (
        <>
        <InputLabel style={{fontWeight:"bold",color:"#212529"}}>{label}</InputLabel>
        <TextField
            variant="outlined"
            type="datetime-local"
            name={name}
            value={value}
            onChange={onChange}
            style={{marginBottom:"0.5rem",width:"100%"}}
        />
        </>
    )
}
