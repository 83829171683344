import React, { Component } from "react";
import NavBar from "./../Components/navbar";

import Footer from "./../Components/footer";

import Schema from "./../Components/schema";

class BayanSchema extends Component {
  render() {
    return (
      <>
        <NavBar />

        <Schema />

        <Footer />
      </>
    );
  }
}

export default BayanSchema;
