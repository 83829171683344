import React, { Component, Fragment } from "react";
import "../App.css";
import settings from "../helpers/Settings";
let { samaApiToken } = settings;

class SamaLinks extends Component {
  render() {
    let name = localStorage.getItem("SamaUser");
    let projectType = this.props.history.location.pathname.split("/")[1];

    return (
      <Fragment>
        <div className="BayanLinks">
          {/*   <a href="/Sama/#documentation"> {projectType} Documentation</a>
          <a href="/Sama/#api">API End Points</a> */}
          <a href="/Sama/#schema">Schema</a>
          <a href="/Sama/#sample">Sample</a>
          {samaApiToken ? (
            <a href="/Sama/employee" style={{ color: "black" }}>
              {name} Profile
            </a>
          ) : (
            <a href="/Sama/login">Login</a>
          )}
        </div>
      </Fragment>
    );
  }
}

export default SamaLinks;
