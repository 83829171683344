import React, { Component } from "react";
import axios from "axios";
import NavBar from "./../Components/navbar";
import Footer from "./../Components/footer";
import Cookies from "js-cookie";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import settings from "../helpers/Settings";
let { KayanURL } = settings;

class loginEmployeeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      error: null,
      serverError: null,
      usertype: null,
      isLogin: false,
      errorMsg: "",
      loginStatus: false,
      loading: true,
    };

    this.handelLogIn = this.handelLogIn.bind(this);
  }

  componentDidMount = () => {
    const { history, location } = this.props;
    let errorMsg = location.state ? location.state.errorMsg : null;
    if (Cookies.get("SamaCompanyId")) {
      history.push(`/Sama/company`);
    } else {
      this.setState({
        loading: false,
        error: errorMsg ? errorMsg : null,
      });
    }
  };

  //handle login
  handelLogIn = (e) => {
    e.preventDefault();
    this.setState({ loginStatus: true });

    const { history } = this.props;

    axios
      .post(KayanURL + "api/sama/company/login", {
        username: this.state.username,
        password: this.state.password,
      })

      .then(({ data }) => {
        if (data.success) {
          Cookies.set("SamaCompanyId", data.apitoken);
          localStorage.setItem("SamaCompanyUser", data.name);

          localStorage.setItem("UserLicenses", data.license);
          history.push("/Sama/company");
        } else {
          this.setState({
            error: data.error,
            inputsHaveErrors: data.inputsHaveErrors,
            loginStatus: false,
          });
        }
      })
      .catch(() => {
        this.setState({ serverError: "There is a server error!" });
      });
  };

  // Main
  render() {
    let { loginStatus, error } = this.state;

    return (
      <>
        <NavBar />
        <div id="main-registration-container" className="registerDiv">
          <form onSubmit={this.handelLogIn}>
            <div className="ToMargin">
              <label>User Name</label>

              <input
                type="text"
                placeholder="User Name"
                required
                className="form-control"
                onChange={(event) => {
                  this.setState({ username: event.target.value });
                }}
              />
            </div>

            <div className="ToMargin">
              <label style={{ marginRight: "10px" }}>Password</label>
              <input
                type="password"
                placeholder="Password"
                className="form-control"
                required
                onChange={(event) => {
                  this.setState({ password: event.target.value });
                }}
              />
            </div>

            {loginStatus === false ? (
              <input
                type="submit"
                style={{
                  backgroundColor: "rgb(67, 183, 81)",
                  color: "#ffffff",
                  border: "none",
                  padding: "5px 38px",
                  marginTop: "20px",
                  borderRadius: "5px",
                }}
                value="Login"
                className="reg"
              />
            ) : (
              <Button
                style={{
                  backgroundColor: "#43b751",
                  color: "white",
                  marginTop: "20px",
                }}
                variant="light"
                disabled
              >
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading...
              </Button>
            )}
          </form>
        </div>
        {error ? (
          <div
            style={{
              backgroundColor: "red",
              color: "#ffffff",
              padding: "10px",
              margin: "2vh 30vw",
            }}
          >
            {" "}
            {error}{" "}
          </div>
        ) : null}

        <Footer />
      </>
    );
  }
}

export default loginEmployeeForm;
