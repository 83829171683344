import React from "react";
import "./Form1.css";
import Button from "../Button";
import Input from "../InputText";
import Label from "../Label";
import Radio from "../Radio";
import Date from "../Date";
import Number from "../Number";
import Section from "../Section";
import DropList from "../DropList";
import CodesList from "../CodesList";
import CheckBox from "../CheckBox";
import ActivityCodeList from "../ActivityCodeList";
import Link from "../Link";

import SamaActivityList from "../SamaActivityCodeList";
import SamaActivityList1 from "../SamaActivityCodeList/index2";
import SamaActivityList2 from "../SamaActivityCodeList/index3";
import SamaActivityList3 from "../SamaActivityCodeList/index4-rule";
import SamaActivityList4 from "../SamaActivityCodeList/index5-rulesearch";

import SamaDiagnosisList from "../SamaDiagnosisCodesList";
import SamaDiagnosisList1 from "../SamaDiagnosisCodesList/index2";
import SamaDiagnosisList2 from "../SamaDiagnosisCodesList/index3";
import SamaDiagnosisList3 from "../SamaDiagnosisCodesList/index4";
import SamaDiagnosisList4 from "../SamaDiagnosisCodesList/index5-rule";
import SamaDiagnosisList5 from "../SamaDiagnosisCodesList/index6-rule-noncovered";
import SamaActivityList10 from "../SamaActivityCodeList/index5-rule";
import NewSearch from "../SamaDiagnosisCodesList/NewSearch";

function Form(props) {
  return (
    <form onSubmit={props.onSubmit} className={props.formStyle}>
      {props.inputsTypes.map((item, index) => {
        switch (item) {
          case "radio":
            return <Radio key={index} onChange={props.onRadioChange} />;

          case "date":
            return (
              <Date
                key={index}
                id={props.elementsNames[index]}
                label={props.elementsValues[index]}
                inputStyle={props.inputStyle}
                onChange={props.onInputTextChange}
                name={props.elementsNames[index]}
              />
            );

          case "number":
            return (
              <Number
                key={index}
                id={props.elementsNames[index]}
                hint={props.elementsNames[index]}
                min={props.elementsValues[index][0].min}
                max={props.elementsValues[index][0].max}
                step={props.elementsValues[index][0].step}
                Style={props.inputStyle}
                onChange={props.onInputTextChange}
                name={props.elementsNames[index]}
              />
            );
          case "section":
            return <Section key={index} text={props.elementsValues[index]} />;

          case "select":
            return (
              <DropList
                key={index}
                id={props.elementsNames[index]}
                name={props.elementsNames[index]}
                Title={props.elementsNames[index]}
                Options={props.elementsValues[index][0]}
                Values={props.elementsValues[index][1]}
                onSelect={props.onSelectOption}
                Style="requestSelect"
              />
            );

          case "list":
            return (
              <CodesList
                key={index}
                Items={props.elementsValues[index]}
                addNewCode={props.addNewCode}
                listName={props.elementsNames[index]}
                inputStyle={props.inputStyle}
                submitStyle={props.submitStyle}
                onInputTextChange={props.onInputTextChange}
                handleItemDeletion={props.handleItemDeletion}
                handleClaimDeletion={props.handleClaimDeletion}
                // onChange={props.onAddNewCodeInputChange}
                section={props.section}
                BtnActive={props.BtnActive}
                isPrincipalChecked={props.isPrincipalChecked}
                onSelect={props.onSelectOption}
                attributes={props.attributes}
                onChange={props.onChange}
                onClick={props.onClick}
                diagnosisNow={props.diagnosisNow}
                suggestions={props.allDiagnosis}
                userInputDiagnosis={props.userInputDiagnosis}
                userInputActivity={props.userInputActivity}
                activeSuggestion={props.activeSuggestion}
                filteredSuggestionsActivity={props.filteredSuggestionsActivity}
                filteredSuggestionsDiagnosis={
                  props.filteredSuggestionsDiagnosis
                }
                showActivitySuggestions={props.showActivitySuggestions}
                showDiagnosisSuggestions={props.showDiagnosisSuggestions}
              />
            );

          case "SamaDiagnosisList":
            return (
              <SamaDiagnosisList
                key={index}
                Items={props.elementsValues[index]}
                addNewCode={props.addNewCode}
                listName={props.elementsNames[index]}
                inputStyle={props.inputStyle}
                submitStyle={props.submitStyle}
                onInputTextChange={props.onInputTextChange}
                handleItemDeletion={props.handleItemDeletion}
                handleClaimDeletion={props.handleClaimDeletion}
                // onChange={props.onAddNewCodeInputChange}
                placeholder={props.placeholder}
                section={props.section}
                BtnActive={props.BtnActive}
                isPrincipalChecked={props.isPrincipalChecked}
                onSelect={props.onSelectOption}
                attributes={props.attributes}
                onChange={props.onChange}
                onClick={props.onClick}
                diagnosisNow={props.diagnosisNow}
                suggestions={props.allDiagnosis}
                userInputDiagnosis={props.userInputDiagnosis}
                userInputActivity={props.userInputActivity}
                activeSuggestion={props.activeSuggestion}
                filteredSuggestionsActivity={props.filteredSuggestionsActivity}
                filteredSuggestionsDiagnosis={
                  props.filteredSuggestionsDiagnosis
                }
                showActivitySuggestions={props.showActivitySuggestions}
                showDiagnosisSuggestions={props.showDiagnosisSuggestions}
              />
            );

          case "SamaDiagnosisList1":
            return (
              <SamaDiagnosisList1
                key={index}
                codeIndex={props.codeIndex}
                editIndex={props.editIndex}
                name={props.name}
                addNewCode={props.addNewCode}
                addICDsCode={props.addICDsCode}
                // onSubmit={props.addICDsCode}
                handleInputTextChangeForAddNewDiag={
                  props.handleInputTextChangeForAddNewDiag
                }
                placeholder={props.placeholder}
                AddNewDiagCode={props.AddNewDiagCode}
                listName={props.elementsNames[index]}
                inputStyle={props.inputStyle}
                submitStyle={props.submitStyle}
                onInputTextChange={props.onInputTextChange}
                handleItemDeletion={props.handleItemDeletion}
                handleClaimDeletion={props.handleClaimDeletion}
                // onChange={props.onAddNewCodeInputChange}
                section={props.section}
                BtnActive={props.BtnActive}
                isPrincipalChecked={props.isPrincipalChecked}
                onSelect={props.onSelectOption}
                attributes={props.attributes}
                onChange={props.onChange}
                onClick={props.onClick}
                diagnosisNow={props.diagnosisNow}
                suggestions={props.allDiagnosis}
                userInputDiagnosis={props.userInputDiagnosis}
                userInputActivity={props.userInputActivity}
                activeSuggestion={props.activeSuggestion}
                filteredSuggestionsActivity={props.filteredSuggestionsActivity}
                filteredSuggestionsDiagnosis={
                  props.filteredSuggestionsDiagnosis
                }
                showActivitySuggestions={props.showActivitySuggestions}
                showDiagnosisSuggestions={props.showDiagnosisSuggestions}
              />
            );

          case "SamaDiagnosisList2":
            return (
              <SamaDiagnosisList2
                key={index}
                name={props.name}
                addNewCode={props.addNewCode}
                addICDsCode={props.addICDsCode}
                handleInputTextChangeForAddNewDiag={
                  props.handleInputTextChangeForAddNewDiag
                }
                AddNewDiagCode={props.AddNewDiagCode}
                listName={props.elementsNames[index]}
                inputStyle={props.inputStyle}
                submitStyle={props.submitStyle}
                onInputTextChange={props.onInputTextChange}
                handleItemDeletion={props.handleItemDeletion}
                handleClaimDeletion={props.handleClaimDeletion}
                // onChange={props.onAddNewCodeInputChange}
                section={props.section}
                BtnActive={props.BtnActive}
                isPrincipalChecked={props.isPrincipalChecked}
                onSelect={props.onSelectOption}
                attributes={props.attributes}
                onChange={props.onChange}
                onClick={props.onClick}
                diagnosisNow={props.diagnosisNow}
                suggestions={props.allDiagnosis}
                userInputDiagnosis={props.userInputDiagnosis}
                userInputActivity={props.userInputActivity}
                activeSuggestion={props.activeSuggestion}
                filteredSuggestionsActivity={props.filteredSuggestionsActivity}
                filteredSuggestionsDiagnosis={
                  props.filteredSuggestionsDiagnosis
                }
                showActivitySuggestions={props.showActivitySuggestions}
                showDiagnosisSuggestions={props.showDiagnosisSuggestions}
              />
            );

          case "SamaDiagnosisList3":
            return (
              <SamaDiagnosisList3
                key={index}
                name={props.name}
                addNewCode={props.addNewCode}
                addNewICDsCode={props.addNewICDsCode}
                AddNewDiagCode={props.AddNewDiagCode}
                listName={props.elementsNames[index]}
                inputStyle={props.inputStyle}
                submitStyle={props.submitStyle}
                onInputTextChange={props.onInputTextChange}
                handleItemDeletion={props.handleItemDeletion}
                handleClaimDeletion={props.handleClaimDeletion}
                // onChange={props.onAddNewCodeInputChange}
                section={props.section}
                BtnActive={props.BtnActive}
                isPrincipalChecked={props.isPrincipalChecked}
                onSelect={props.onSelectOption}
                attributes={props.attributes}
                onChange={props.onChange}
                onClick={props.onClick}
                diagnosisNow={props.diagnosisNow}
                suggestions={props.allDiagnosis}
                userInputDiagnosis={props.userInputDiagnosis}
                userInputActivity={props.userInputActivity}
                activeSuggestion={props.activeSuggestion}
                filteredSuggestionsActivity={props.filteredSuggestionsActivity}
                filteredSuggestionsDiagnosis={
                  props.filteredSuggestionsDiagnosis
                }
                showActivitySuggestions={props.showActivitySuggestions}
                showDiagnosisSuggestions={props.showDiagnosisSuggestions}
              />
            );

          case "SamaDiagnosisList4":
            return (
              <SamaDiagnosisList4
                className={props.formStyle}
                key={index}
                name={props.name}
                addNewCode={props.addNewCode}
                listName={props.elementsNames[index]}
                inputStyle={props.inputStyle}
                formStyle
                submitStyle={props.submitStyle}
                onInputTextChange={props.onInputTextChange}
                handleItemDeletion={props.handleItemDeletion}
                handleClaimDeletion={props.handleClaimDeletion}
                // onChange={props.onAddNewCodeInputChange}
                section={props.section}
                BtnActive={props.BtnActive}
                isPrincipalChecked={props.isPrincipalChecked}
                onSelect={props.onSelectOption}
                attributes={props.attributes}
                onChange={props.onChange}
                onClick={props.onClick}
                diagnosisNow={props.diagnosisNow}
                suggestions={props.allDiagnosis}
                userInputDiagnosis={props.userInputDiagnosis}
                userInputActivity={props.userInputActivity}
                activeSuggestion={props.activeSuggestion}
                filteredSuggestionsActivity={props.filteredSuggestionsActivity}
                filteredSuggestionsDiagnosis={
                  props.filteredSuggestionsDiagnosis
                }
                showActivitySuggestions={props.showActivitySuggestions}
                showDiagnosisSuggestions={props.showDiagnosisSuggestions}
              />
            );

          case "SamaDiagnosisList5":
            return (
              <SamaDiagnosisList5
                className={props.formStyle}
                key={index}
                name={props.name}
                addNewCode={props.addNewCode}
                listName={props.elementsNames[index]}
                inputStyle={props.inputStyle}
                submitStyle={props.submitStyle}
                // onChange={props.onAddNewCodeInputChange}
                section={props.section}
                BtnActive_noncovered={props.BtnActive_noncovered}
                isPrincipalChecked={props.isPrincipalChecked}
                onSelect={props.onSelectOption}
                attributes={props.attributes}
                onChange={props.onChange}
                onClick={props.onClick}
                diagnosisNow={props.diagnosisNow}
                suggestions={props.allDiagnosis}
                userInputDiagnosis_noncovred={
                  props.userInputDiagnosis_noncovred
                }
                userInputActivity={props.userInputActivity}
                activeSuggestion={props.activeSuggestion}
                filteredSuggestionsActivity={props.filteredSuggestionsActivity}
                filteredSuggestionsDiagnosis_noncovered={
                  props.filteredSuggestionsDiagnosis_noncovered
                }
                showActivitySuggestions={props.showActivitySuggestions}
                showDiagnosisSuggestions_noncovered={
                  props.showDiagnosisSuggestions_noncovered
                }
              />
            );
          case "Search":
            return (
              <NewSearch
                lable={props.lable}
                className={props.formStyle}
                key={index}
                name={props.name}
                addNewCode={props.addNewCode}
                listName={props.elementsNames[index]}
                inputStyle={props.inputStyle}
                submitStyle={props.submitStyle}
                // onChange={props.onAddNewCodeInputChange}
                section={props.section}
                BtnActive_noncovered={props.BtnActive_noncovered}
                isPrincipalChecked={props.isPrincipalChecked}
                onSelect={props.onSelectOption}
                attributes={props.attributes}
                onChange={props.onChange}
                onClick={props.onClick}
                diagnosisNow={props.diagnosisNow}
                suggestions={props.allDiagnosis}
                userInputDiagnosis_noncovred={
                  props.userInputDiagnosis_noncovred
                }
                userInputActivity={props.userInputActivity}
                activeSuggestion={props.activeSuggestion}
                filteredSuggestionsActivity={props.filteredSuggestionsActivity}
                filteredSuggestionsDiagnosis_noncovered={
                  props.filteredSuggestionsDiagnosis_noncovered
                }
                showActivitySuggestions={props.showActivitySuggestions}
                showDiagnosisSuggestions_noncovered={
                  props.showDiagnosisSuggestions_noncovered
                }
              />
            );

          case "ActivityList":
            return (
              <ActivityCodeList
                key={index}
                Items={props.elementsValues[index]}
                addNewCode={props.addNewCode}
                listName={props.elementsNames[index]}
                inputStyle={props.inputStyle}
                submitStyle={props.submitStyle}
                onInputTextChange={props.onInputTextChange}
                handleItemDeletion={props.handleItemDeletion}
                handleClaimDeletion={props.handleClaimDeletion}
                // onChange={props.onAddNewCodeInputChange}
                ActivityNow={props.ActivityNow}
                ActivitySuggestions={props.allActivity}
                //
                section={props.section}
                BtnActive={props.BtnActive}
                isPrincipalChecked={props.isPrincipalChecked}
                onSelect={props.onSelectOption}
                attributes={props.attributes}
                onChange={props.onChange}
                onClick={props.onClick}
                suggestions={props.allDiagnosis}
                userInputDiagnosis={props.userInputDiagnosis}
                userInputActivity={props.userInputActivity}
                activeSuggestion={props.activeSuggestion}
                filteredSuggestionsActivity={props.filteredSuggestionsActivity}
                filteredSuggestionsDiagnosis={
                  props.filteredSuggestionsDiagnosis
                }
                showActivitySuggestions={props.showActivitySuggestions}
                showDiagnosisSuggestions={props.showDiagnosisSuggestions}
              />
            );

          case "SamaActivityList":
            return (
              <SamaActivityList
                key={index}
                Items={props.elementsValues[index]}
                addNewCode={props.addNewCode}
                listName={props.elementsNames[index]}
                inputStyle={props.inputStyle}
                submitStyle={props.submitStyle}
                onInputTextChange={props.onInputTextChange}
                handleItemDeletion={props.handleItemDeletion}
                handleClaimDeletion={props.handleClaimDeletion}
                // onChange={props.onAddNewCodeInputChange}
                ActivityNow={props.ActivityNow}
                ActivitySuggestions={props.allActivity}
                //
                section={props.section}
                BtnActive={props.BtnActive}
                isPrincipalChecked={props.isPrincipalChecked}
                onSelect={props.onSelectOption}
                attributes={props.attributes}
                onChange={props.onChange}
                onClick={props.onClick}
                suggestions={props.allDiagnosis}
                userInputDiagnosis={props.userInputDiagnosis}
                userInputActivity={props.userInputActivity}
                activeSuggestion={props.activeSuggestion}
                filteredSuggestionsActivity={props.filteredSuggestionsActivity}
                filteredSuggestionsDiagnosis={
                  props.filteredSuggestionsDiagnosis
                }
                showActivitySuggestions={props.showActivitySuggestions}
                showDiagnosisSuggestions={props.showDiagnosisSuggestions}
              />
            );
          case "SamaActivityList1":
            return (
              <SamaActivityList1
                key={index}
                /*   Items={props.elementsValues[index]} */
                addCPTsCode={props.addCPTsCode}
                codeIndex={props.codeIndex}
                editIndex={props.editIndex}
                addNewCode={props.addNewCode}
                listName={props.elementsNames[index]}
                inputStyle={props.inputStyle}
                submitStyle={props.submitStyle}
                onInputTextChange={props.onInputTextChange}
                handleItemDeletion={props.handleItemDeletion}
                handleClaimDeletion={props.handleClaimDeletion}
                placeholder={props.placeholder}
                ActivityNow={props.ActivityNow}
                ActivitySuggestions={props.allActivity}
                //
                section={props.section}
                BtnActive={props.BtnActive}
                isPrincipalChecked={props.isPrincipalChecked}
                onSelect={props.onSelectOption}
                attributes={props.attributes}
                onChange={props.onChange}
                onClick={props.onClick}
                suggestions={props.allDiagnosis}
                userInputDiagnosis={props.userInputDiagnosis}
                userInputActivity={props.userInputActivity}
                activeSuggestion={props.activeSuggestion}
                filteredSuggestionsActivity={props.filteredSuggestionsActivity}
                filteredSuggestionsDiagnosis={
                  props.filteredSuggestionsDiagnosis
                }
                showActivitySuggestions={props.showActivitySuggestions}
                showDiagnosisSuggestions={props.showDiagnosisSuggestions}
              />
            );

          case "SamaActivityList2":
            return (
              <SamaActivityList2
                key={index}
                /*   Items={props.elementsValues[index]} */
                addCPTsCode={props.addCPTsCode}
                addNewCode={props.addNewCode}
                listName={props.elementsNames[index]}
                inputStyle={props.inputStyle}
                submitStyle={props.submitStyle}
                onInputTextChange={props.onInputTextChange}
                handleItemDeletion={props.handleItemDeletion}
                handleClaimDeletion={props.handleClaimDeletion}
                // onChange={props.onAddNewCodeInputChange}
                ActivityNow={props.ActivityNow}
                ActivitySuggestions={props.allActivity}
                //
                section={props.section}
                BtnActive={props.BtnActive}
                isPrincipalChecked={props.isPrincipalChecked}
                onSelect={props.onSelectOption}
                attributes={props.attributes}
                onChange={props.onChange}
                onClick={props.onClick}
                suggestions={props.allDiagnosis}
                userInputDiagnosis={props.userInputDiagnosis}
                userInputActivity={props.userInputActivity}
                activeSuggestion={props.activeSuggestion}
                filteredSuggestionsActivity={props.filteredSuggestionsActivity}
                filteredSuggestionsDiagnosis={
                  props.filteredSuggestionsDiagnosis
                }
                showActivitySuggestions={props.showActivitySuggestions}
                showDiagnosisSuggestions={props.showDiagnosisSuggestions}
              />
            );

          case "SamaActivityList10":
            return (
              <SamaActivityList10
                key={index}
                /*   Items={props.elementsValues[index]} */
                addNewCode={props.addNewCode}
                listName={props.elementsNames[index]}
                inputStyle={props.inputStyle}
                submitStyle={props.submitStyle}
                placeholder={props.placeholder}
                ActivityNow={props.ActivityNow}
                ActivitySuggestions={props.allActivity}
                //
                section={props.section}
                BtnActive={props.BtnActive}
                onSelect={props.onSelectOption}
                attributes={props.attributes}
                onChange={props.onChange}
                onClick={props.onClick}
                suggestions={props.allDiagnosis}
                userInputDiagnosis={props.userInputDiagnosis}
                userInputActivity={props.userInputActivity}
                activeSuggestion={props.activeSuggestion}
                filteredSuggestionsActivity={props.filteredSuggestionsActivity}
                filteredSuggestionsDiagnosis={
                  props.filteredSuggestionsDiagnosis
                }
                showActivitySuggestions={props.showActivitySuggestions}
                showDiagnosisSuggestions={props.showDiagnosisSuggestions}
              />
            );

          case "SamaActivityList3":
            return (
              <SamaActivityList3
                key={index}
                /*   Items={props.elementsValues[index]} */
                addNewCode={props.addNewCode}
                listName={props.elementsNames[index]}
                inputStyle={props.inputStyle}
                submitStyle={props.submitStyle}
                placeholder={props.placeholder}
                ActivityNow={props.ActivityNow}
                ActivitySuggestions={props.allActivity}
                //
                section={props.section}
                BtnActive={props.BtnActive}
                onSelect={props.onSelectOption}
                attributes={props.attributes}
                onChange={props.onChange}
                onClick={props.onClick}
                suggestions={props.allDiagnosis}
                userInputDiagnosis={props.userInputDiagnosis}
                userInputActivity={props.userInputActivity}
                activeSuggestion={props.activeSuggestion}
                filteredSuggestionsActivity={props.filteredSuggestionsActivity}
                filteredSuggestionsDiagnosis={
                  props.filteredSuggestionsDiagnosis
                }
                showActivitySuggestions={props.showActivitySuggestions}
                showDiagnosisSuggestions={props.showDiagnosisSuggestions}
              />
            );

          case "SamaActivityList4":
            return (
              <SamaActivityList4
                key={index}
                /*   Items={props.elementsValues[index]} */
                addNewCode={props.addNewCode}
                listName={props.elementsNames[index]}
                inputStyle={props.inputStyle}
                submitStyle={props.submitStyle}
                placeholder={props.placeholder}
                ActivityNow_Search={props.ActivityNow1}
                suggestions={props.allActivity}
                //
                section={props.section}
                BtnActive_search={props.BtnActive_search}
                onSelect={props.onSelectOption}
                attributes={props.attributes}
                onChange={props.onChange}
                onClick={props.onClick}
                userInputActivity_search={props.userInputActivity_search}
                activeSuggestion={props.activeSuggestion}
                filteredSuggestionsActivity_search={
                  props.filteredSuggestionsActivity_search
                }
                showActivitySuggestions_search={
                  props.showActivitySuggestions_search
                }
              />
            );

          case "checkbox":
            return (
              <CheckBox
                key={index}
                id={props.elementsNames[index]}
                Style={props.inputStyle}
                value={props.elementsValues[index]}
                onChange={props.onInputTextChange}
                name={props.elementsNames[index]}
              />
            );

          case "link":
            return (
              <Link
                key={index}
                Style={props.dangerStyle}
                Text={props.elementsNames[index]}
                onAction={props.handleClear}
              />
            );

          default:
            return (
              <Input
                key={index}
                id={props.elementsNames[index]}
                Style={props.inputStyle}
                type={item}
                hint={props.elementsValues[index]}
                onChange={props.onInputTextChange}
                name={props.elementsNames[index]}
              />
            );
        }
      })}{" "}
      {null}
    </form>
  );
}
export default Form;
