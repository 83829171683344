//import React from "react";
import React, { Component, Fragment } from "react";
import Form from "../Components/Form/index"
import axios from "axios";
import settings from "../helpers/Settings";
import Table from "react-bootstrap/Table";

let { KayanURL } = settings;
class RAscreeen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            claimId: ""
        };
    }


    async search(event) {
        event.preventDefault()
        let records = []
        const data = await axios.put(KayanURL + "api/sama/retriveRA", {
            claimId: this.state.claimId,
            apiToken:/*  Cookies.get("SamaId") */'eyJhbGciOiJIUzI1NiJ9.ZW1wbG95ZWU6MQ.CLEKBu7BpY9VxZVXhGR6BMFYWPJ1FWMR3-s9kwkcHXs',
        });

        let response = data['data']
        if (response.success) {

            let { info } = response
            console.log(info);
            let { claimid, providerid, idpayer, comments, paymentreference, datesettlement } = info
            let activities = info.activities
            activities.forEach(activity => {
                console.log(activity);
                let code = Object.keys(activity)
                //   console.log(code);
                let { ActivityId, Type, Start, Quantity, PaymentAmount, PatientShare, Net, DenialCode, PaymentStatus } = activity[code]

                let record = { claimid, providerid, idpayer, paymentreference, datesettlement, code, ActivityId, Net, PatientShare, PaymentAmount, PaymentStatus, comments, DenialCode }

                records.push(record)
            });

            this.setState({ rows: records, responseReady: true })
        }
    }



    onInputTextChange({ target: { name, value } }) {



        this.setState({ responseReady: false, claimId: value })

    }



    render() {

        let { rows, responseReady } = this.state
        let ClaimListTable
        if (rows && rows.length > 0) {
            ClaimListTable = rows.map((row, index) => {
                console.log("row");
                console.log(row);
                return (
                    <tr key={index}>
                        <td>{row["claimid"]}</td>

                        <td>{row["providerid"]}</td>
                        <td>{row["idpayer"]}</td>
                        <td>{row["paymentreference"]}</td>
                        <td >{row["datesettlement"]}</td>

                        <td>{row["code"][0]}</td>
                        <td>{row["ActivityId"]}</td>

                        <td>{row["Net"]}</td>
                        <td>{row["PatientShare"]}</td>

                        <td>{row["PaymentAmount"]}</td>

                        <td>{row["PaymentStatus"]}</td>


                        <td>{row["comments"]}</td>
                        <td>{row["DenialCode"]}</td>

                    </tr>
                );
            });
        }






        return (
            <div className="container">
                <Form
                    onSubmit={this.search.bind(this)}

                    inputStyle="IT3"
                    submitStyle="BS10"
                    inputsTypes={["input"]}
                    elementsValues={["Search"]}
                    elementsNames={["claimIdInputText"]}
                    onInputTextChange={this.onInputTextChange.bind(this)}


                />

                {responseReady ? <Table responsive striped bordered hover size="sm">
                    <thead style={{ background: "#3ab449", color: " white" }}>
                        <tr>
                            <th
                                style={{
                                    verticalAlign: "middle",
                                    paddingLeft: "30px",
                                    paddingRight: "30px",
                                }}
                            >
                                Claim ID
                      </th>
                            <th style={{ verticalAlign: "middle", width: "1px" }}>
                                Provider ID
                      </th>
                            <th style={{ verticalAlign: "middle" }}>IDPayer</th>
                            <th style={{ verticalAlign: "middle" }}>paymentreference</th>
                            <th style={{ verticalAlign: "middle" }}>datesettlement</th>


                            <th style={{ verticalAlign: "middle" }}>Activity</th>

                            <th style={{ verticalAlign: "middle" }}>Activity ID</th>
                            <th style={{ verticalAlign: "middle" }}>Net</th>
                            <th style={{ verticalAlign: "middle" }}>PatientShare</th>
                            <th style={{ verticalAlign: "middle" }}>PaymentAmount</th>
                            <th style={{ verticalAlign: "middle" }}>Payment Status</th>
                            <th style={{ verticalAlign: "middle" }}>Comments</th>

                            <th style={{ verticalAlign: "middle" }}>Denial Code</th>

                        </tr>
                    </thead>
                    <tbody>{ClaimListTable}</tbody>
                </Table>
                    : null}

            </div>
        )
    }


}


export default RAscreeen;