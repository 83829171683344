import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import React from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const MoreOptions = ({ record, actions }) => {
  const ref = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleMenuAction = (action) => () => {
    action(record);
    setIsOpen(false);
  };
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertIcon width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          style: { maxWidth: 250 },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {(actions || []).map((action) => {
          return (
            <div key={`${record._id}_${action.name}`}>
              {!action.condition && (
                <MenuItem onClick={handleMenuAction(action.handler)}>
                  <ListItemIcon>{action.icon}</ListItemIcon>
                  <ListItemText
                    primary={action.name}
                    primaryTypographyProps={{ variant: "body2" }}
                    style={{ color: action.variant || "primary" }}
                  />
                </MenuItem>
              )}
            </div>
          );
        })}
      </Menu>
    </>
  );
};

export default MoreOptions;
