import { Button } from "@material-ui/core";
import React from "react";
import FormModal from "./FormModal";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  Stack: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  submitButton: {
    backgroundColor: "#37ab45",
    color: "white",
  },
  cancelButton: {
    backgroundColor: "red",
    color: "white",
  },
}));

const DialogActions = ({ onClose, open, dialog, action }) => {
  const classes = useStyles();

  const actionHandler = () => {
    action();
  };
  return (
    <FormModal open={open} onClose={onClose} title={dialog}>
      {" "}
      <div className={classes.Stack}>
        <Button
          variant="contained"
          size="large"
          className={classes.cancelButton}
          onClick={onClose}
        >
          No
        </Button>
        <Button
          variant="contained"
          size="large"
          className={classes.submitButton}
          onClick={actionHandler}
        >
          Yes
        </Button>
      </div>
    </FormModal>
  );
};

export default DialogActions;
