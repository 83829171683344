import React, { Component, Fragment } from "react";
import StandAlone from "./StandAlone";
import Header from "../Components/Header";
import NavBar from "../Components/navbar";
import SamaNavBar from "../Components/samanavbar";
import settings from "../helpers/Settings";
let { samaApiToken } = settings;

class SamaRequest extends Component {
  componentDidMount = () => {
    const { history } = this.props;
    if (!samaApiToken) {
      history.push(`/Sama/loginAsEmployee`, {
        errorMsg: "You need to sign in or sign up",
      });
    }
  };

  render() {
    return (
      <Fragment>
        <NavBar />
        <Header
          name={localStorage.getItem("SamaName")}
          usertype="employee"
          history={this.props.history}
        />
        <SamaNavBar selected="1" />
        <StandAlone />
      </Fragment>
    );
  }
}

export default SamaRequest;
