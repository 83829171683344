import React, { Component } from "react";
import axios from "axios";
import Cookies from "js-cookie";

import Spinner from "react-bootstrap/Spinner";
import { Multiselect } from "multiselect-react-dropdown";

import settings from "../helpers/Settings";
let { KayanURL } = settings;

class KareemNetworks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      discounts: false,
      patientShare: false,
      // authorizing_amount: null,
    };
    this.handleInputTextChange = this.handleInputTextChange.bind(this);
    this.onScelectPayerName = this.onScelectPayerName.bind(this);
    this.handleNetGrossSelection = this.handleNetGrossSelection.bind(this);
    this.onClickChekBox = this.onClickChekBox.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleDiscounts = this.handleDiscounts.bind(this);
    this.handlePatientShare = this.handlePatientShare.bind(this);
    this.onSelectPayerUnderTPA = this.onSelectPayerUnderTPA.bind(this);
    this.onRemovePayerUnderTPA = this.onRemovePayerUnderTPA.bind(this);
  }
  componentDidMount = async () => {
    let payers = localStorage.getItem("payers");

    if (!payers) {
      this.getPayersList();
    } else {
      this.setState({ payers: JSON.parse(payers) });
    }
  };
  async getPayersList() {
    let response = await axios.put(
      KayanURL+"api/KareemBenefits/getPayers",
       {
      apiToken: Cookies.get("KareemId"),
    });

    if (response) {
      let { data } = response;
      if (data && data.success) {
        let { payers } = data;

        this.setState({ payers: payers });

        localStorage.setItem("payers", JSON.stringify(payers));
      }
    }
  }

  onClickChekBox() {
    let { active } = this.state;

    if (!active) {
      this.setState({ active: true });
    } else {
      this.setState({ active: false });
    }
  }

  /*   onScelectPayerName({ target: { name, value } }) {
    this.setState({ payer_id: value });
  } */

  onScelectPayerName({ target: { name, value } }) {
    this.setState({ payer_id: value, isTPA: false });
    if (value.includes("TPA")) {
      this.setState({ isTPA: true });
    }
  }
  onScelectNetwork({ target: { name, value } }) {
    this.setState({ network_name: value });
  }
  handleInputTextChange({ target: { name, value } }) {
    this.setState({ [name]: value });
  }

  handleNetGrossSelection({ target: { name, value } }) {
    this.setState({ [name]: value });
  }
  handleDiscounts() {
    this.setState({ discounts: !this.state.discounts });
  }
  handlePatientShare() {
    this.setState({ patientShare: !this.state.patientShare });
  }

  onSelectPayerUnderTPA(selectedList, selectedItem) {
    let Values = [];
    selectedList.forEach((item) => {
      Values.push(item.eclaimlink_id);
    });

    this.setState({ payersUnderTPA: Values });
  }

  onRemovePayerUnderTPA(selectedList, removedItem) {
    let Values = [];
    selectedList.forEach((item) => {
      if (item.eclaimlink_id != removedItem.eclaimlink_id) {
        Values.push(item.eclaimlink_id);
      }
    });

    this.setState({ payersUnderTPA: Values });
  }

  onSubmit = async (e) => {
    let { Networks } = this.props;
    // console.log(this.props.networks)
    this.setState({ sent: true });

    e.preventDefault();

    if (Networks && Networks.length > 0) {
      let result = Networks.filter(
        (n) =>
          n.payer_id == this.state.payer_id &&
          n.network_name == this.state.network_name
      );

      if (result && result.length > 0) {
        alert("The entered network already exist");
        this.setState({ sent: false });

        return;
      }
    }
    let discount;
    if (this.state.discounts) {
      discount = {
        consultation_discount: this.state.discount,
        laboratory_discount: this.state.discount,
        radiology_discount: this.state.discount,
        procedure_discount: this.state.discount,
        consumables_discount: this.state.discount,
        medication_discount: this.state.discount,
        dental_discount: this.state.discount,
      };
    } else {
      discount = {
        consultation_discount: this.state.consultation_discount,
        laboratory_discount: this.state.laboratory_discount,
        radiology_discount: this.state.radiology_discount,
        procedure_discount: this.state.procedure_discount,
        consumables_discount: this.state.consumables_discount,
        medication_discount: this.state.medication_discount,
        dental_discount: this.state.dental_discount,
      };
    }
    let patient_share;
    if (this.state.patientShare) {
      patient_share = {
        consultation_share: this.state.patient_share_percent,
        laboratory_share: this.state.patient_share_percent,
        radiology_share: this.state.patient_share_percent,
        procedure_share: this.state.patient_share_percent,
        consumables_share: this.state.patient_share_percent,
        medication_share: this.state.patient_share_percent,
        dental_share: this.state.patient_share_percent,
      };
    } else {
      patient_share = {
        consultation_share: this.state.consultation_share,
        laboratory_share: this.state.laboratory_share,
        radiology_share: this.state.radiology_share,
        procedure_share: this.state.procedure_share,
        consumables_share: this.state.consumables_share,
        medication_share: this.state.medication_share,
        dental_share: this.state.dental_share,
      };
    }

    let payers = [];
    let tpa = "";
    if (this.state.isTPA) {
      // payers = [this.state.payer_id];
      let { payersUnderTPA } = this.state;
      tpa = this.state.payer_id;
      payers = [payersUnderTPA];
    } else {
      payers = [this.state.payer_id];
    }

    let response;
    response = await axios.post(
      KayanURL+"api/KareemBenefits/networks/AddNewNetwork",
      {
        apiToken: Cookies.get("KareemId"),
        networks: [
          {
            /*  payer_id: this.state.payer_id, */
            tpa,
            payer_id: /*  this.state.payer_id */ payers,
            network_name: this.state.network_name,
            discount: discount,
            patient_share: patient_share,
            network_description: this.state.network_description,
            patient_share_max_amount: this.state.patient_share_max_amount,
            max_number_of_days_for_submission:
              this.state.max_number_days_submission,
            max_number_of_days_for_resubmissions:
              this.state.max_number_days_resubmission,
            max_number_of_resubmission: this.state.max_number_of_resubmission,
            authorizing_amount: this.state.authorizing_amount,
            active: this.state.active,
          },
        ],
      }
    );
    if (response) {
      let { data } = response;
      if (data.success) {
        this.setState({ success: true, sent: false });
        this.props.getAllNetworks();
      } else {
        this.setState({ success: false, error: data.error, sent: false });
      }
    }

    //  this.setState({  });
  };
  
  render() {
    let { payers, sent } = this.state;

    let payersList;
    if (payers && payers.length > 0) {
      payersList = payers.map((payer, index) => {
        return (
          <option value={payer["eclaimlink_id"]} key={index}>
            {payer["eclaimlink_id"]}
          </option>
        );
      });
    }
    return (
      <>
        <div className="container">
          <form onSubmit={this.onSubmit}>
            <h5>Add New Payer/Network</h5>
            <select
              required
              style={{ width: "70%" }}
              onChange={this.onScelectPayerName}
              value={this.state.payer_id ? this.state.payer_id : ""}
            >
              <option value="">Select Insurance Company</option>
              {payersList}
            </select>

            {this.state.isTPA ? (
              <div
                style={{
                  width: "70%",
                  margin: "auto",
                }} /* style={{ marginTop: "1.15rem", marginBottom: "0.9rem" }} */
              >
                <Multiselect
                  placeholder="Payers that follow the TPA"
                  options={payers} // Options to display in the dropdown
                  selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                  onSelect={this.onSelectPayerUnderTPA} // Function will trigger on select event
                  onRemove={this.onRemovePayerUnderTPA} // Function will trigger on remove event
                  displayValue="eclaimlink_id" // Property name to display in the dropdown options
                />
                <br />
              </div>
            ) : null}

            <input
              required
              onChange={this.handleInputTextChange}
              type="text"
              name="network_name"
              placeholder="Network Name"
              style={{ width: "70%", padding: "8px" }}
            />
            <br />
            <br />
            <input
              required
              onChange={this.handleInputTextChange}
              type="text"
              name="network_description"
              placeholder="Network Description"
              style={{ width: "70%", padding: "8px" }}
            />
            <br />
            <div style={{ width: "70%", padding: "8px", marginLeft: "15%" }}>
              <input
                type="checkbox"
                value="discounts"
                onChange={this.handleDiscounts}
              />
              <label
                style={{
                  float: "none",
                  color: "#28a745",
                  marginLeft: "10px",
                  fontSize: "large",
                }}
              >
                Equal Discounts
              </label>
            </div>
            {this.state.discounts ? (
              <>
                <input
                  onChange={this.handleInputTextChange}
                  type="number"
                  name="discount"
                  placeholder="Discount(%)"
                  style={{ width: "70%", padding: "8px" }}
                  min="0"
                />
                <br />
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "70%",
                    marginLeft: "15%",
                  }}
                >
                  <input
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="consultation_discount"
                    placeholder="Consultation Discount(%)"
                    style={{ width: "45%", padding: "8px" }}
                    min="0"
                  />
                  <input
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="laboratory_discount"
                    placeholder="Laboratory Discount(%)"
                    style={{ width: "45%", padding: "8px" }}
                    min="0"
                  />
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "70%",
                    marginLeft: "15%",
                  }}
                >
                  <input
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="radiology_discount"
                    placeholder="Radiology Discount(%)"
                    style={{ width: "45%", padding: "8px" }}
                    min="0"
                  />
                  <input
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="procedure_discount"
                    placeholder="Procedure Discount(%)"
                    style={{ width: "45%", padding: "8px" }}
                    min="0"
                  />
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "70%",
                    marginLeft: "15%",
                  }}
                >
                  <input
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="consumables_discount"
                    placeholder="Consumables Discount(%)"
                    style={{ width: "45%", padding: "8px" }}
                    min="0"
                  />
                  <input
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="medication_discount"
                    placeholder="Medication Discount(%)"
                    style={{ width: "45%", padding: "8px" }}
                    min="0"
                  />
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "70%",
                    marginLeft: "15%",
                  }}
                >
                  <input
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="dental_discount"
                    placeholder="Dental Discount(%)"
                    style={{ width: "100%", padding: "8px", margin: "0 auto" }}
                    min="0"
                  />
                </div>
              </>
            )}
            <br />
            <div style={{ width: "70%", padding: "8px", marginLeft: "15%" }}>
              <input
                type="checkbox"
                value="discounts"
                onChange={this.handlePatientShare}
              />
              <label
                style={{
                  float: "none",
                  color: "#28a745",
                  marginLeft: "10px",
                  fontSize: "large",
                }}
              >
                Equal PatientShare
              </label>
            </div>
            {this.state.patientShare ? (
              <>
                <input
                  onChange={this.handleInputTextChange}
                  type="number"
                  name="patient_share_percent"
                  placeholder="Patient Share(%)"
                  style={{ width: "70%", padding: "8px" }}
                  min="0"
                />
                <br />
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "70%",
                    marginLeft: "15%",
                  }}
                >
                  <input
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="consultation_share"
                    placeholder="Consultation Share(%)"
                    style={{ width: "45%", padding: "8px" }}
                    min="0"
                  />
                  <input
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="laboratory_share"
                    placeholder="Laboratory Share(%)"
                    style={{ width: "45%", padding: "8px" }}
                    min="0"
                  />
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "70%",
                    marginLeft: "15%",
                  }}
                >
                  <input
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="radiology_share"
                    placeholder="Radiology Share(%)"
                    style={{ width: "45%", padding: "8px" }}
                    min="0"
                  />
                  <input
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="procedure_share"
                    placeholder="Procedure Share(%)"
                    style={{ width: "45%", padding: "8px" }}
                    min="0"
                  />
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "70%",
                    marginLeft: "15%",
                  }}
                >
                  <input
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="consumables_share"
                    placeholder="Consumables Share(%)"
                    style={{ width: "45%", padding: "8px" }}
                    min="0"
                  />
                  <input
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="medication_share"
                    placeholder="Medication Share(%)"
                    style={{ width: "45%", padding: "8px" }}
                    min="0"
                  />
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "70%",
                    marginLeft: "15%",
                  }}
                >
                  <input
                    onChange={this.handleInputTextChange}
                    type="number"
                    name="dental_share"
                    placeholder="Dental Share(%)"
                    style={{ width: "100%", padding: "8px", margin: "0 auto" }}
                    min="0"
                  />
                </div>
              </>
            )}
            <br />
            <input
              onChange={this.handleInputTextChange}
              type="number"
              name="patient_share_max_amount"
              placeholder="Patient Share Maximum Amount"
              style={{ width: "70%", padding: "8px" }}
              min="0"
            />
            <br />
            <br />
            <input
              onChange={this.handleInputTextChange}
              type="number"
              name="max_number_days_submission"
              placeholder="Maximum Number Of Days For Submission"
              style={{ width: "70%", padding: "8px" }}
              min="0"
            />
            <br />
            <br />
            <input
              onChange={this.handleInputTextChange}
              type="number"
              name="max_number_days_resubmission"
              placeholder="Maximum Number Of Days For Resubmissions"
              style={{ width: "70%", padding: "8px" }}
              min="0"
            />
            <br />
            <br />
            <input
              onChange={this.handleInputTextChange}
              type="number"
              name="max_number_of_resubmission"
              placeholder="Maximum Number Of Resubmissions"
              style={{ width: "70%", padding: "8px" }}
              min="0"
            />
            <br />
            <br />
            <input
              onChange={this.handleInputTextChange}
              type="number"
              name="authorizing_amount"
              placeholder="Authorizing after x- amount"
              style={{ width: "70%", padding: "8px" }}
              min="0"
            />
            <br />
            <br />
            <div
              style={{
                width: "70%",
                marginLeft: "15%",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <div>
                <input
                  type="checkbox"
                  name="active"
                  onChange={this.onClickChekBox}
                />{" "}
                Activate
              </div>
            </div>
            <br />
            <input
              type="submit"
              value="Add New Network"
              style={{
                padding: "0.5rem 0.5rem",
                backgroundColor: "#4caf50",
                color: "white",
                border: "none",
              }}
            />
            <br />
            {sent ? (
              <Spinner animation="border" variant="success" />
            ) : this.state.success ? (
              <h5 style={{ color: "green" }}>Network was added sucessfully</h5>
            ) : this.state.error && this.state.error.length > 0 ? (
              <h5 style={{ color: "red" }}>{this.state.error[0]}</h5>
            ) : null}
          </form>
        </div>
      </>
    );
  }
}

export default KareemNetworks;
