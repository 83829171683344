import React, { Component } from "react";
import NavBar from "./../Components/navbar";
import Header from "./../Components/Header";
import Cookies from "js-cookie";

class Employee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount = () => {
    const { history } = this.props;
    if (!Cookies.get("SanadId")) {
      history.push(`/Sanad/loginAsEmployee`, {
        errorMsg: "You need to sign in or sign up",
      });
    } else {
      this.setState({ loading: false });
    }
  };

  render() {
    if (!this.state.loading) {
      return (
        <>
          <NavBar />
          <Header
            name={localStorage.getItem("SanadName")}
            usertype="employee"
            history={this.props.history}
          />
          <div className="BayanLinks">
            <a href="/Sanad/calculator">Calculate your DRG</a>
            <a href="/Sanad/priceManagement">Price Management</a>
            <a href="/Sanad/factorManagement">Factor Management</a>
            {localStorage.getItem("SanadCompany") === "Kayan" ? (
              <a href="/Sanad/userManagement">User Management</a>
            ) : null}
          </div>
        </>
      );
    } else {
      return <h1>Loading ... </h1>;
    }
  }
}
export default Employee;
