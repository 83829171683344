import React, { Component, Fragment } from "react";
import Spinner from "react-bootstrap/Spinner";

class Autocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: "",
    };
  }
  componentDidMount = async () => {
   if(this.props.value){
    this.setState({activity_code:true})
   }
   else{
    this.setState({activity_code:false})
   }
  };
  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      activeSuggestion,
      filteredSuggestions,
      showSuggestions,
      userInput,
      wait,
      endWait
    } = this.props;

    let suggestionsListComponent;
    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul class="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }
              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div class="no-suggestions">
            <em>No suggestions available.</em>
          </div>
        );
      }
    }
    return (
      <Fragment>
        {wait ? <Spinner animation="border" variant="success" /> : null}
        {wait ? <br /> : null}
        {this.state.activity_code?(
          <input
            type="text"
            name="activity_code"
            required
            onChange={onChange}
            onKeyDown={onKeyDown}
            defaultValue = {this.props.value}
            style={{ width: "70%", padding: "8px" }}
          />
        ): wait?(
          <input
            disabled
            type="text"
            name="activity_code"
            required
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={userInput}
            style={{ width: "70%", padding: "8px" }}
            placeholder="Activity Code"
          />
        ) : (
          <input
          disabled={!endWait}
            type="text"
            name="activity_code"
            required
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={userInput}
            style={{ width: "70%", padding: "8px" }}
            placeholder="Activity Code"
          />
        )
  }

        {suggestionsListComponent}
      </Fragment>
    );
  }
}

export default Autocomplete;
