import React, { Component } from "react";
import axios from "axios";
import NavBar from "../../../Components/navbar";
import Header from "../../../Components/Header/index";
import Form from "../../../Components/Form/index";
import Button from "../../../Components/Button";
import DropList from "../../../Components/DropList";
import Table from "react-bootstrap/Table";
import Cookies from "js-cookie";
import settings from "../../../helpers/Settings";
import Spinner from "react-bootstrap/Spinner";
let { KayanURL } = settings;

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      employees: [],
      fieldType: "text",
      fieldName: "username",
    };
  }
  componentDidMount = () => {
    const { history } = this.props;
    if (Cookies.get("BayanAdminID") == null) {
      var errorMsg = "You need to sign in or sign up";
      history.push(`/loginAsEmployee`, errorMsg);
    } else {
      this.getAllCompanies();
    }
  };

  getAllCompanies = (e) => {
    axios
      .put(KayanURL + "api/user/info", {
        apiToken: Cookies.get("BayanAdminID"),
        companyId: this.state.selectedCompanyId,
      })
      .then((data) => {
        let companies = data.data.admin.companies;
        let employees = data.data.admin.employees;

        let selectedCompany = companies.filter(
          (company) => company[0].id == this.state.selectedCompanyId
        );
        this.setState({
          selectedCompany: selectedCompany,
          companies,
          employees,
        });
      });
  };

  onCompanyNameSelection = (e) => {
    let select = document.getElementById(e);
    let value = select.options[select.selectedIndex].value;
    this.setState({ selectedCompanyId: value });

    axios
      .put(KayanURL + "api/user/info", {
        apiToken: Cookies.get("BayanAdminID"),
        companyId: value,
      })
      .then((data) => {
        let companies = data.data.admin.companies;
        let employees = data.data.admin.employees;

        //call generate token

        let selectedCompany = companies.filter(
          (company) => company[0].id == value
        );
        this.setState({ selectedCompany: selectedCompany, employees });
      });
  };
  //handle input text change in all forms
  handleInputTextChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      data: { ...this.state.data, [name]: value },
      success: false,
      failed: false,
    });
  };
  //on company name selection
  onSelection = (e) => {
    let select = document.getElementById(e);
    let name = select.name;
    let value = select.options[select.selectedIndex].value;

    this.setState({ data: { ...this.state.data, [name]: value } });
  };
  //on submit Company registration
  onSubmit = (event) => {
    event.preventDefault();
    this.setState({
      CompanyRegistrationSucccess: false,
      CompanyRegistrationFailed: false,
      flag: true,
    });
    let user = this.state.data;
    axios.post(KayanURL + "api/company/register", user).then(({ data }) => {
      if (data.success) {
        this.setState({ CompanyRegistrationSucccess: true, data: {} });
        this.getAllCompanies();
      } else {
        this.setState({ CompanyRegistrationFailed: data.error });
      }
    });
  };

  //activate/deactivate company
  onActivationChangeCompany = (companyId, index) => {
    const { selectedCompany } = this.state;
    axios
      .put(KayanURL + "api/admin/changeActivationCompany", {
        apiToken: Cookies.get("BayanAdminID"),
        companyId,
        changeActivation: !selectedCompany[index][2]["active"],
      })
      .then(({ data }) => {
        if (data.success) {
          this.getAllCompanies();
        }
      })
      .catch(() => {
        this.setState({ serverError: "server error" });
      });
  };

  /* async onDeleteCompany(companyId) {
    console.log("delete company");
    console.log("id");
    console.log(companyId);

    let response = await axios.put(KayanURL + "api/admin/" + "deleteCompany", {
      apiToken: Cookies.get("BayanAdminID"),

      companyId,
    });
    let { data } = response;
    if (data.success) {
      window.location.reload();
    } else {
    }
  } */

  //to select which field you want to update in company info
  onChangeCompanyUpdateField = (e) => {
    this.setState({ success: false, failed: false, flag: false });
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    this.setState({ fieldName, fieldValue });
  };
  //on submit change to company info
  onSubmitUpdateCompanyInfo = (e) => {
    e.preventDefault();
    this.setState({ flag: true });
    let { fieldName, fieldValue, selectedCompany } = this.state;
    let companyId = selectedCompany[0][0].id;
    let action = "";
    switch (fieldName) {
      case "username":
        action = "updateCompanyUsername";
        break;
      case "password":
        action = "updateCompanyPassword";
        break;
      case "transactions":
        action = "updateTransNumbers";
        break;
      case "enddate":
        action = "updateEndDate";
        break;
    }

    axios
      .put(KayanURL + "api/admin/" + action, {
        apiToken: Cookies.get("BayanAdminID"),
        [fieldName]: fieldValue,
        companyId,
      })
      .then(({ data }) => {
        if (data.success) {
          this.setState({ success: true });
          this.getAllCompanies();
        } else {
          this.setState({ failed: true });
        }
      })
      .catch(() => {
        this.setState({ serverError: "server error" });
      });
  };

  onSelectFieldName = (e) => {
    this.setState({
      success: false,
      failed: false,
      flag: false,
    });

    let select = document.getElementById(e);
    let value = select.options[select.selectedIndex].value;
    let fieldType =
      value == "password"
        ? "password"
        : value == "transactions"
        ? "number"
        : "text";
    this.setState({
      fieldType: fieldType,
      fieldName: value,
    });
  };

  /*--------                   Employee section             -----------*/

  //Employee Registration
  onSubmitAddEmployee = (event) => {
    event.preventDefault();
    this.setState({ addEmployeeFlag: true });
    let user = this.state.data;
    user.apiToken = Cookies.get("BayanAdminID");
    axios.post(KayanURL + "api/employee/register", user).then(({ data }) => {
      if (data.success) {
        this.setState({
          success: true,
        });
        this.getAllCompanies();
      } else {
        this.setState({ failed: data.error });
      }
    });
  };

  onActivationChangeEmployee = (employeeId, index) => {
    const { employees } = this.state;
    axios
      .put(KayanURL + "api/admin/changeActivationEmployee", {
        apiToken: Cookies.get("BayanAdminID"),
        employeeId,
        changeActivation: !employees[index][3]["active"],
      })
      .then(({ data }) => {
        if (data.success) {
          this.getAllCompanies();
          this.onCompanyNameSelection("companyname");
        }
      })
      .catch(() => {
        this.setState({ serverError: "There are an server error" });
      });
  };

  onSubmitUpdateEmployeeInfo = (e) => {
    e.preventDefault();
    this.setState({ flag: true });
    let { fieldName, fieldValue, selectedEmployee } = this.state;
    let action;
    switch (fieldName) {
      case "username":
        action = "updateEmployeeUsername";

        break;
      case "password":
        action = "updateEmployeePassword";
        break;

      default:
        break;
    }

    axios
      .put(KayanURL + "api/admin/" + action, {
        apiToken: Cookies.get("BayanAdminID"),
        employeeId: selectedEmployee[0].id,
        [fieldName]: fieldValue,
      })
      .then(({ data }) => {
        if (data.success) {
          this.setState({ success: true });
          this.getAllCompanies();
        } else {
          this.setState({ failed: data.error });
        }
      });
  };

  render() {
    let {
      companies,
      employees,
      fieldName,
      fieldType,
      selectedCompany,
      flag,
    } = this.state;

    var companyTable;
    if (selectedCompany && selectedCompany.length > 0) {
      companyTable = selectedCompany.map((row, index) => {
        let ISODateTime = row[6]["endedat"];
        let date = ISODateTime.split("T")[0];
        date = date.split("-").reverse().join("/");
        let time = ISODateTime.split("T")[1].slice(0, -8);
        let datetime = date + " " + time;
        return (
          <tr key={index}>
            <td>{row[1].name}</td>
            <td>{row[7].username}</td>
            <td>{row[2]["active"] === true ? "Active" : "Not Active"}</td>
            <td>{row[3]["approve"] === true ? "Approved" : "Not Approved"}</td>

            <td>{datetime}</td>
            <td>{row[5]["transactions"]}</td>
            <td>
              {!row[2]["active"] ? (
                <Button
                  onClick={() =>
                    this.onActivationChangeCompany(row[0].id, index)
                  }
                  Style="BS12"
                  Value="Activate"
                />
              ) : (
                <Button
                  onClick={() =>
                    this.onActivationChangeCompany(row[0].id, index)
                  }
                  Style="BS12"
                  Value="Deactivate"
                />
              )}

              <Button
                Style="BS12"
                Value="Update"
                onClick={() =>
                  this.setState({
                    startUpdateCompany: true,
                    success: false,
                    failed: false,
                    flag: false,
                  })
                }
              />

              {/*     <a
                style={{ color: "red", textDecoration: "underline" }}
                onClick={() => {
                  this.onDeleteCompany(row[0].id);
                }}
              >
                Delete
              </a> */}
            </td>
          </tr>
        );
      });
    }

    let companyNames = [],
      companyIds = [];
    if (companies.length > 0) {
      companies.forEach((c) => {
        companyNames.push(c[1]["name"]);
        companyIds.push("" + c[0]["id"]);
      });
    }

    //employees table
    var employeesTable;
    if (employees && employees.length > 0) {
      employeesTable = employees.map((row, index) => {
        console.log("row");
        console.log(row);
        return (
          <tr key={index}>
            <td>{row[1].name}</td>
            <td>{row[2].username}</td>
            <td>{row[4].token}</td>
            <td>{row[3].active === true ? "Active" : "Not Active"}</td>
            <td>
              {!row[3]["active"] ? (
                <Button
                  onClick={() =>
                    this.onActivationChangeEmployee(row[0].id, index)
                  }
                  Style="BS12"
                  Value="Activate"
                />
              ) : (
                <Button
                  onClick={() =>
                    this.onActivationChangeEmployee(row[0].id, index)
                  }
                  Style="BS12"
                  Value="Deactivate"
                />
              )}

              <Button
                onClick={() =>
                  this.setState({
                    startUpdateEmployeeForm: true,
                    selectedEmployee: row,
                    flag: false,
                  })
                }
                Style="BS12"
                Value="Update"
              />
            </td>
          </tr>
        );
      });
    }

    return (
      <>
        <NavBar />

        <Header
          projectType="admin"
          name={"super"}
          history={this.props.history}
        />

        <div style={{ height: "60px" }}></div>

        <div className="container">
          {/*main screen*/}
          <div
            style={{
              width: "70%",
              margin: "auto",
              border: "0",
              padding: "50px",
              boxShadow:
                "0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)",
            }}
          >
            <DropList
              id="companyname"
              name="companyid"
              Title="company Name"
              Options={companyNames}
              Values={companyIds}
              onSelect={this.onCompanyNameSelection}
            />

            <p
              style={{
                fontWeight: "bold",
                fontSize: "15px",
                textAlign: "center",
                cursor: "pointer",
                "margin-bottom": "-20px",
              }}
              onClick={() =>
                this.setState({ startAddCompany: true, error: null })
              }
            >
              {" "}
              Create new company{" "}
            </p>
          </div>

          {/*pop up company registration screen */}
          {this.state.startAddCompany ? (
            <div className="popup">
              <div
                className="form"
                style={{
                  width: "50%",
                  margin: "auto",
                  backgroundColor: "white",
                }}
              >
                <Form
                  onSubmit={this.onSubmit}
                  inputStyle="IT3"
                  submitStyle="BS10"
                  onSelectOption={this.onSelection}
                  onInputTextChange={this.handleInputTextChange}
                  inputsTypes={[
                    "text",
                    "select",
                    "text",
                    "text",
                    "text",
                    "text",
                    "email",
                    "tel",
                    "text",
                    "text",
                    "password",
                    "password",
                  ]}
                  elementsValues={[
                    "Company Name",
                    [
                      ["Payer", "Provider", "TPA"],
                      ["Payer", "Provider", "TPA"],
                    ],
                    "License",
                    "Country",
                    "City",
                    "Address",
                    "E-Mail",
                    "Phone",
                    "Contact Person",
                    "User Name",
                    "Password ",
                    "Re-password",
                    "Sign Up",
                  ]}
                  elementsNames={[
                    "name",
                    "type",
                    "license",
                    "country",
                    "city",
                    "address",
                    "email",
                    "phone",
                    "contactperson",
                    "username",
                    "password",
                    "repassword",
                  ]}
                />

                {flag &&
                !this.state.CompanyRegistrationSucccess &&
                !this.state.CompanyRegistrationFailed ? (
                  <Spinner size="lg" animation="grow" variant="success" />
                ) : this.state.CompanyRegistrationSucccess ? (
                  <p style={{ color: "green" }}>
                    company registered successfully
                  </p>
                ) : this.state.CompanyRegistrationFailed ? (
                  <p style={{ color: "red", width: "70%", margin: "auto" }}>
                    {this.state.CompanyRegistrationFailed}
                  </p>
                ) : null}

                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "right",
                    cursor: "pointer",
                    margin: "-30px 2%",
                  }}
                  onClick={() => this.setState({ startAddCompany: false })}
                >
                  {" "}
                  Close{" "}
                </p>
              </div>
            </div>
          ) : null}

          {/*pop up employee registration screen*/}
          {this.state.startAddNewEmployee ? (
            <div className="popup">
              <div
                className="form"
                style={{
                  width: "50%",
                  margin: "auto",
                  backgroundColor: "white",
                }}
              >
                <Form
                  onSubmit={this.onSubmitAddEmployee}
                  inputStyle="IT3"
                  submitStyle="BS10"
                  onSelectOption={this.onSelection}
                  onInputTextChange={this.handleInputTextChange}
                  inputsTypes={[
                    "select",
                    "text",
                    "text",
                    "password",
                    "password",
                  ]}
                  elementsValues={[
                    [companyNames, companyIds],
                    "Name",
                    "User Name",
                    "Password",
                    "Re-Password",
                    "Add Employee",
                  ]}
                  elementsNames={[
                    "companyid",
                    "name",
                    "username",
                    "password",
                    "repassword",
                  ]}
                />

                {this.state.addEmployeeFlag &&
                !this.state.success &&
                !this.state.failed ? (
                  <Spinner size="lg" animation="grow" variant="success" />
                ) : this.state.success ? (
                  <p style={{ color: "green" }}>
                    new employee added successfully
                  </p>
                ) : this.state.failed ? (
                  <p style={{ color: "red" }}>{this.state.failed}</p>
                ) : null}

                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "right",
                    cursor: "pointer",
                    margin: "0 2%",
                  }}
                  onClick={() =>
                    this.setState({
                      startAddNewEmployee: false,
                      success: false,
                      failed: false,
                    })
                  }
                >
                  {" "}
                  Close{" "}
                </p>
              </div>
            </div>
          ) : null}

          {/*company update screen*/}

          {this.state.startUpdateCompany ? (
            <div className="popup">
              <div
                className="form"
                style={{
                  width: "50%",
                  margin: "auto",
                  backgroundColor: "white",
                }}
              >
                <Form
                  onSubmit={this.onSubmitUpdateCompanyInfo}
                  inputsTypes={["select", fieldType]}
                  inputStyle="IT3"
                  submitStyle="BS10"
                  onSelectOption={this.onSelectFieldName}
                  onInputTextChange={this.onChangeCompanyUpdateField}
                  elementsValues={[
                    [
                      ["username", "password", "transactions", "enddate"],
                      ["username", "password", "transactions", "enddate"],
                    ],
                    fieldName,
                    "Update",
                  ]}
                  elementsNames={["field", fieldName]}
                />

                {flag && !this.state.success && !this.state.failed ? (
                  <Spinner size="lg" animation="grow" variant="success" />
                ) : this.state.success ? (
                  <p style={{ color: "green" }}>
                    {fieldName} updated successfully
                  </p>
                ) : this.state.failed ? (
                  <p style={{ color: "red", width: "70%", margin: "auto" }}>
                    failed
                  </p>
                ) : null}

                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "right",
                    cursor: "pointer",
                    margin: "0 2%",
                  }}
                  onClick={() =>
                    this.setState({
                      startUpdateCompany: false,
                      fieldName: "username",
                      fieldType: "text",
                      success: false,
                      failed: false,
                      flag: false,
                    })
                  }
                >
                  {" "}
                  Close{" "}
                </p>
              </div>
            </div>
          ) : null}

          {/*pop up employee update screen*/}
          {this.state.startUpdateEmployeeForm ? (
            <div className="popup">
              <div
                className="form"
                style={{
                  width: "50%",
                  margin: "auto",
                  backgroundColor: "white",
                }}
              >
                <Form
                  onSubmit={this.onSubmitUpdateEmployeeInfo}
                  inputsTypes={["select", fieldType]}
                  inputStyle="IT3"
                  submitStyle="BS10"
                  onSelectOption={this.onSelectFieldName}
                  onInputTextChange={this.onChangeCompanyUpdateField}
                  elementsValues={[
                    [
                      ["username", "password"],
                      ["username", "password"],
                    ],
                    fieldName,
                    "Update",
                  ]}
                  elementsNames={["field", fieldName]}
                />

                {flag && !this.state.success && !this.state.failed ? (
                  <Spinner size="lg" animation="grow" variant="success" />
                ) : this.state.success ? (
                  <p style={{ color: "green" }}>
                    {fieldName} updated successfully
                  </p>
                ) : this.state.failed ? (
                  <p style={{ color: "red", width: "70%", margin: "auto" }}>
                    failed
                  </p>
                ) : null}
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "right",
                    cursor: "pointer",
                    margin: "0 2%",
                  }}
                  onClick={() =>
                    this.setState({
                      startUpdateEmployeeForm: false,
                      success: false,
                      failed: false,
                      fieldName: "username",
                      fieldType: "text",
                      success: false,
                      failed: false,
                      flag: false,
                    })
                  }
                >
                  {" "}
                  Close{" "}
                </p>
              </div>
            </div>
          ) : null}

          {/*selected company information*/}
          {selectedCompany && selectedCompany.length > 0 ? (
            <div style={{ marginTop: "60px" }}>
              <p style={{ textAlign: "left" }}>Company Information:</p>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Company Name</th>
                    <th>User Name</th>
                    <th>Active Status</th>
                    <th>Approve Status</th>
                    <th>Activation End Date</th>
                    <th>Transactions</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{companyTable}</tbody>
              </Table>
            </div>
          ) : null}

          {/*employees of selected comapny*/}
          {employees.length > 0 ? (
            <div style={{ marginTop: "60px" }}>
              <p style={{ textAlign: "left" }}>
                {" "}
                Compnay Employees Information:
              </p>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Employee Name</th>
                    <th>User Name</th>
                    <th>API token</th>
                    <th>Active Status</th>
                    <th>Update</th>
                  </tr>
                </thead>
                <tbody>{employeesTable}</tbody>
              </Table>
            </div>
          ) : selectedCompany && selectedCompany.length > 0 ? (
            <h5 style={{ color: "rgb(58, 180, 73)", margin: "30px" }}>
              There are no employees in this company
            </h5>
          ) : null}

          {selectedCompany && selectedCompany.length > 0 ? (
            <p
              className="BS10"
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.setState({
                  startAddNewEmployee: true,
                  success: false,
                  failed: false,
                })
              }
            >
              Add New Employee{" "}
            </p>
          ) : null}
        </div>

        <div style={{ height: "60px" }}></div>
      </>
    );
  }
}

export default Admin;
