import React, { Component } from "react";
import axios from "axios";
import Form from "../Components/Form/index.js";
import Spinner from "react-bootstrap/Spinner";
import Cookies from "js-cookie";
import settings from "../helpers/Settings";
import style from '../Components/Styling/plan.module.css'
let { KayanURL } = settings;
var XLSX = require("xlsx");
class plan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sent: false,
    };
  }
  render() {
    
    return (
      <>
        <div className="container d-flex justify-content-center vh-100">
          <div className="row h-50">
            <div className="col-md-12">
              <div className={style.table1}>
              <table>
                <thead>
                  <tr>
                    <th scope="col" className="p-2">Insurance Company</th>
                    <th scope="col" className="p-2">Payer ID</th>
                    <th scope="col" className="p-2">Plans</th>
                    <th scope="col" className="p-2">Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className={style.tdBody}>
                    <td>Daman Health Insurance</td>
                    <td>INS026</td>
                    <td>Core Silver</td>
                    <td rowSpan={5}>Jan-22</td>
                  </tr>
                  <tr className={style.tdBody}>
                    <td></td>
                    <td></td>
                    <td>Enhanced Bronze Plan</td>
                  </tr>
                  <tr className={style.tdBody}>
                    <td></td>
                    <td></td>
                    <td>Enhanced Silver Plan</td>
                  </tr>
                  <tr className={style.tdBody}>
                    <td></td>
                    <td></td>
                    <td>Enhanced Gold Plan</td>
                  </tr>
                  <tr className={style.tdBody}>
                    <td></td>
                    <td></td>
                    <td>Enhanced Platinium Plan</td>
                  </tr>
                  <tr className="bg-warning" style={{height: "20px"}}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
                <tbody>
                <tr className={style.tdBody}>
                    <td>Oman Insurance Company</td>
                    <td>INS012</td>
                    <td>Comprehensive</td>
                    <td rowSpan={6}>Jan-22</td>
                  </tr>
                  <tr className={style.tdBody}>
                    <td></td>
                    <td></td>
                    <td>Comprehensive Plus</td>
                  </tr>
                  <tr className={style.tdBody}>
                    <td></td>
                    <td></td>
                    <td>Premium Standard</td>
                  </tr>
                  <tr className={style.tdBody}>
                    <td></td>
                    <td></td>
                    <td>Premium Signiture</td>
                  </tr>
                  <tr className={style.tdBody}>
                    <td></td>
                    <td></td>
                    <td>Advanced </td>
                  </tr>
                  <tr className={style.tdBody}>
                    <td></td>
                    <td></td>
                    <td>Bupa</td>
                  </tr>
                  <tr className="bg-warning" style={{height: "20px"}}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
                <tbody>
                <tr className={style.tdBody} style={{height: "20px"}}>
                    <td>Saada</td>
                    <td>INS129</td>
                    <td></td>
                    <td>Jan-20</td>
                  </tr>
                  <tr className="bg-warning" style={{height: "20px"}}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
                <tbody>
                <tr className={style.tdBody}>
                    <td>DEWA</td>
                    <td>TPA001</td>
                    <td>Categroy A2</td>
                    <td rowSpan={4}>Dec-19</td>
                  </tr>
                  <tr className={style.tdBody}>
                    <td></td>
                    <td></td>
                    <td>Category B1</td>
                  </tr>
                  <tr className={style.tdBody}>
                    <td></td>
                    <td></td>
                    <td>Category B2</td>
                  </tr>
                  <tr className={style.tdBody}>
                    <td></td>
                    <td></td>
                    <td>Category C1</td>
                  </tr>
                  <tr className="bg-warning" style={{height: "20px"}}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              </div>
            
            </div>
            <div className="col-md-12 d-flex justify-content-center mt-2 ">
              <div className="w-50 mb-5">
              <table>
                <thead>
                  <tr>
                    <th scope="col" className="p-2">General List</th>
                  </tr>
                </thead>
                  <tbody>
                    <tr className={style.tbodyTable2}>
                      <td>Dubai General Exclusions</td>
                    </tr>
                    <tr className={style.tbodyTable2}>
                      <td>Abu Dhabi General Exclusions</td>
                    </tr>
                    <tr className={style.tbodyTable2}>
                      <td>Dewa Exclusion Services</td>
                    </tr>
                    <tr className={style.tbodyTable2}>
                      <td>General Services require pre-approval</td>
                    </tr>
                    <tr className={style.tbodyTable2}>
                      <td>Diagnose Exclusions as Primary Diagnosis</td>
                    </tr>
                  </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default plan;
