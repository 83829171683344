import React, { Component } from "react";
import NavBar from "./../Components/navbar";

import Footer from "./../Components/footer";

import SayanDocument from "./../Components/sayandoc";

class SayanDoc extends Component {
  render() {
    return (
      <>
        <NavBar userType={localStorage.getItem("type")} />

        <SayanDocument />

        <Footer />
      </>
    );
  }
}

export default SayanDoc;
