import React from "react";
import "./Codes.css";
import Button from "../Button";
import DropList from "../DropList";
import { TextField,InputLabel } from '@material-ui/core';
function defaultClick(e) {
    e.preventDefault();
  }
  
  function Search2(props) {
    let {
      Items,
      inputStyle,
      event,
       listName,
        inputId,
        addNewCode,
  
      section,
      BtnActive,
  
      ActivitySuggestions,
      onChange,
      onClick,
      placeholder,
  
      userInputActivity,
      activeSuggestion,
  
      filteredSuggestionsActivity,
      showActivitySuggestions,
      handleItemDeletion,
      ActivityNow,
      value
    } = props;
    let suggestionsListComponentActivity;
    if (showActivitySuggestions && userInputActivity) {
      if (filteredSuggestionsActivity && filteredSuggestionsActivity.length) {
        suggestionsListComponentActivity = (
          <ul className="suggestions">
            {filteredSuggestionsActivity.map((suggestion, index) => {
              let className;
  
              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }
  
              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponentActivity = (
          <div className="no-suggestions">
            <em>No suggestions...</em>
          </div>
        );
      }
    }
  
    let InputId = "",
      BtnValue = "";
    if (listName === "ActivityCodes") {
      InputId = "activityCode";
      BtnValue = "Confirm Code";
    }
  
    return (
      <React.Fragment>

        <InputLabel style={{fontWeight:"bold",color:"#212529"}}>Activity</InputLabel>
        {value?(
          <>
       <TextField
        type="search"
             variant="outlined"
            id={InputId}
            name="Text"
            style={{marginBottom:"0.5rem",width:"100%"}}
            onChange={(e) => onChange(e, listName)}
            // hint={value}
            hint={placeholder}
            placeholder={value}
            value={userInputActivity}
          />
          {listName === "ActivityCodes" ? suggestionsListComponentActivity : null}

        {listName === "ActivityCodes" && section === "activities" && BtnActive ? (
          <React.Fragment>
            <Button
              Style="enabledStyle"
              Value={BtnValue}
              onClick={(e) => addNewCode(e, listName, InputId)}
            />
          </React.Fragment>
        ) : null}
          </>
        ):(
          <>
           <TextField
        type="search"
             variant="outlined"
            id={InputId}
            name="Text"
            disabled={
              ActivitySuggestions && ActivitySuggestions.length === 0
                ? true
                : false
            }
            style={{marginBottom:"0.5rem",width:"100%"}}
            onChange={(e) => onChange(e, listName)}
            hint={placeholder}
            value={userInputActivity}
          />
          {listName === "ActivityCodes" ? suggestionsListComponentActivity : null}
  
        {listName === "ActivityCodes" && section === "activities" && BtnActive ? (
          <React.Fragment>
            <Button
              Style="enabledStyle"
              Value={BtnValue}
              onClick={(e) => addNewCode(e, listName, InputId)}
            />
          </React.Fragment>
        ) : null}
          </>
        )}
       
      </React.Fragment>
    );
  }
export default Search2;