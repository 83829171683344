import React from "react";
import "./Codes.css";
import ListView from "../ListView";
import Button from "../Button";
import Input from "../InputText";
import DropList from "../DropList";
import ProgressBar from "react-bootstrap/ProgressBar";

function defaultClick(e) {
  e.preventDefault();
}

function CodesList(props) {
  let {
    Items,
    inputStyle,
    addNewCode,
    listName,
    onSelect,
    section,
    BtnActive,
    attributes,
    suggestions,
    onChange,
    onClick,
    userInputDiagnosis,
    activeSuggestion,
    filteredSuggestionsDiagnosis,
    showDiagnosisSuggestions,
    handleItemDeletion,
    diagnosisNow,
    codesMgt,
    codeType,
  } = props;

  let Options;

  let types = [];
  if (Items && Items.length && listName === "DiagnosisCodes") {
    types = Items.filter(function (item) {
      return item.DiagnosisType === "Principal";
    });
  }
  if (types.length > 0) {
    Options = ["Secondary", "Admitting"];
  } else {
    Options = ["Principal", "Secondary", "Admitting"];
  }

  let suggestionsListComponentDiagnosis;

  if (showDiagnosisSuggestions && userInputDiagnosis) {
    if (filteredSuggestionsDiagnosis && filteredSuggestionsDiagnosis.length) {
      suggestionsListComponentDiagnosis = (
        <ul className="suggestions">
          {filteredSuggestionsDiagnosis.map((suggestion, index) => {
            let className;

            // Flag the active suggestion with a class
            if (index === activeSuggestion) {
              className = "suggestion-active";
            }

            return (
              <li className={className} key={suggestion} onClick={onClick}>
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    } else {
      suggestionsListComponentDiagnosis = (
        <div className="no-suggestions">
          <em>No suggestions...</em>
        </div>
      );
    }
  }

  let InputId = "",
    BtnValue = "";
  if (listName === "DiagnosisCodes" || codesMgt) {
    if (codesMgt && codeType && codeType != "icd") {
      BtnValue = "add new activity";
    } else {
      BtnValue = "add new diagnosis";
    }
    InputId = "diagnosisCode";
  }

  return (
    <React.Fragment>
      {Items && Items.length > 0 ? (
        <ListView
          Items={Items}
          name="codes"
          handleItemDeletion={handleItemDeletion}
          listName={listName}
          codesMgt={codesMgt}
        />
      ) : null}

      {!(diagnosisNow === 100 && suggestions) ? (
        <ProgressBar
          style={{ width: "70%", margin: "25px auto" }}
          animated
          variant="success"
          now={diagnosisNow}
        />
      ) : null}

      <Input
        id={InputId}
        disabled={suggestions === undefined ? true : false}
        name="Text"
        Style={inputStyle}
        onChange={(e) => onChange(e, listName)}
        hint="Code"
        value={
          listName === "DiagnosisCodes" || codesMgt ? userInputDiagnosis : null
        }
      />

      {listName === "DiagnosisCodes" || codesMgt
        ? suggestionsListComponentDiagnosis
        : null}

      {((listName === "DiagnosisCodes" && section === "Diagnosis") ||
        codesMgt) &&
      BtnActive ? (
        <React.Fragment>
          {attributes && attributes.length > 0
            ? attributes.map((attribute, index) => (
                <DropList
                  key={index}
                  id="diagnosisType"
                  Title="Diagnosis Type"
                  name="DiagnosisType"
                  Options={Options}
                  Values={Options}
                  defaultValue={Options[0]}
                  onSelect={onSelect}
                  Style="requestSelect"
                />
              ))
            : null}

          <Button
            Style="enabledStyle"
            Value={BtnValue}
            onClick={(e) => addNewCode(e, listName, InputId)}
          />
        </React.Fragment>
      ) : (
        <Button
          Style="disabledStyle"
          Value={BtnValue}
          onClick={(e) => defaultClick(e)}
        />
      )}
    </React.Fragment>
  );
}
export default CodesList;
