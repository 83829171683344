import React from "react";
import "./Form.css";
import Button from "../Button";
import Input from "../InputText";
import Label from "../Label";
import Radio from "../Radio";
import Date from "../Date";
import Number from "../Number";
import Text from "../Number/Text";
import Section from "../Section";
import DropList from "../DropList";
import CodesList from "../CodesList";
import CheckBox from "../CheckBox";
import ActivityCodeList from "../ActivityCodeList";
import Link from "../Link";
import SamaActivityList from "../SamaActivityCodeList";
import SamaDiagnosisList from "../SamaDiagnosisCodesList";
import SamaDiagnosisList1 from "../SamaDiagnosisCodesList/index2";

function Form(props) {
  return (
    <form onSubmit={props.onSubmit} className={props.formStyle}>
      {props.children}
      {props.inputsTypes.map((item, index) => {
        switch (item) {
          case "radio":
            return <Radio key={index} onChange={props.onRadioChange} />;

          case "date":
            return (
              <Date
                key={index}
                id={props.elementsNames[index]}
                label={props.elementsValues[index]}
                inputStyle={props.inputStyle}
                onChange={props.onInputTextChange}
                name={props.elementsNames[index]}
              />
            );

          case "number":
            return (
              <Number
                key={index}
                id={props.elementsNames[index]}
                hint={props.elementsNames[index]}
                min={props.elementsValues[index][0].min}
                max={props.elementsValues[index][0].max}
                step={props.elementsValues[index][0].step}
                Style={props.inputStyle}
                onChange={props.onInputTextChange}
                name={props.elementsNames[index]}
              />
            );
          case "text":
            return (
              <Text
                key={index}
                id={props.elementsNames[index]}
                hint={props.elementsNames[index]}
                Style={props.inputStyle}
                onChange={props.onInputTextChange}
                name={props.elementsNames[index]}
              />
            );

          case "networkIdText":
            return (
              <Text
                key={index}
                id={props.elementsNames[index]}
                hint={props.elementsNames[index]}
                Style={props.inputStyle}
                onChange={props.onNetworkIDChange}
                name={props.elementsNames[index]}
              />
            );

          case "section":
            return <Section key={index} text={props.elementsValues[index]} />;

          case "select":
            return (
              <DropList
                key={index}
                id={props.elementsNames[index]}
                name={props.elementsNames[index]}
                Title={props.elementsNames[index]}
                Options={props.elementsValues[index][0]}
                Values={props.elementsValues[index][1]}
                onSelect={props.onSelectOption}
                Style="requestSelect"
              />
            );

          case "list":
            return (
              <CodesList
                key={index}
                Items={props.elementsValues[index]}
                addNewCode={props.addNewCode}
                listName={props.elementsNames[index]}
                inputStyle={props.inputStyle}
                submitStyle={props.submitStyle}
                onInputTextChange={props.onInputTextChange}
                handleItemDeletion={props.handleItemDeletion}
                handleClaimDeletion={props.handleClaimDeletion}
                // onChange={props.onAddNewCodeInputChange}
                section={props.section}
                BtnActive={props.BtnActive}
                isPrincipalChecked={props.isPrincipalChecked}
                onSelect={props.onSelectOption}
                attributes={props.attributes}
                onChange={props.onChange}
                onClick={props.onClick}
                diagnosisNow={props.diagnosisNow}
                suggestions={props.allDiagnosis}
                userInputDiagnosis={props.userInputDiagnosis}
                userInputActivity={props.userInputActivity}
                activeSuggestion={props.activeSuggestion}
                filteredSuggestionsActivity={props.filteredSuggestionsActivity}
                filteredSuggestionsDiagnosis={
                  props.filteredSuggestionsDiagnosis
                }
                showActivitySuggestions={props.showActivitySuggestions}
                showDiagnosisSuggestions={props.showDiagnosisSuggestions}
              />
            );

          case "SamaDiagnosisList":
            return (
              <SamaDiagnosisList
                key={index}
                Items={props.elementsValues[index]}
                addNewCode={props.addNewCode}
                listName={props.elementsNames[index]}
                inputStyle={props.inputStyle}
                submitStyle={props.submitStyle}
                onInputTextChange={props.onInputTextChange}
                handleItemDeletion={props.handleItemDeletion}
                handleClaimDeletion={props.handleClaimDeletion}
                // onChange={props.onAddNewCodeInputChange}
                section={props.section}
                BtnActive={props.BtnActive}
                isPrincipalChecked={props.isPrincipalChecked}
                onSelect={props.onSelectOption}
                attributes={props.attributes}
                onChange={props.onChange}
                onClick={props.onClick}
                diagnosisNow={props.diagnosisNow}
                suggestions={props.allDiagnosis}
                userInputDiagnosis={props.userInputDiagnosis}
                userInputActivity={props.userInputActivity}
                activeSuggestion={props.activeSuggestion}
                filteredSuggestionsActivity={props.filteredSuggestionsActivity}
                filteredSuggestionsDiagnosis={
                  props.filteredSuggestionsDiagnosis
                }
                showActivitySuggestions={props.showActivitySuggestions}
                showDiagnosisSuggestions={props.showDiagnosisSuggestions}
              />
            );

          case "SamaDiagnosisList1":
            return (
              <SamaDiagnosisList1
                key={index}
                addNewCode={props.addNewCode}
                listName={props.elementsNames[index]}
                inputStyle={props.inputStyle}
                submitStyle={props.submitStyle}
                onInputTextChange={props.onInputTextChange}
                handleItemDeletion={props.handleItemDeletion}
                handleClaimDeletion={props.handleClaimDeletion}
                // onChange={props.onAddNewCodeInputChange}
                section={props.section}
                BtnActive={props.BtnActive}
                isPrincipalChecked={props.isPrincipalChecked}
                onSelect={props.onSelectOption}
                attributes={props.attributes}
                onChange={props.onChange}
                onClick={props.onClick}
                diagnosisNow={props.diagnosisNow}
                suggestions={props.allDiagnosis}
                userInputDiagnosis={props.userInputDiagnosis}
                userInputActivity={props.userInputActivity}
                activeSuggestion={props.activeSuggestion}
                filteredSuggestionsActivity={props.filteredSuggestionsActivity}
                filteredSuggestionsDiagnosis={
                  props.filteredSuggestionsDiagnosis
                }
                showActivitySuggestions={props.showActivitySuggestions}
                showDiagnosisSuggestions={props.showDiagnosisSuggestions}
              />
            );

          case "ActivityList":
            return (
              <ActivityCodeList
                key={index}
                Items={props.elementsValues[index]}
                addNewCode={props.addNewCode}
                listName={props.elementsNames[index]}
                inputStyle={props.inputStyle}
                submitStyle={props.submitStyle}
                onInputTextChange={props.onInputTextChange}
                handleItemDeletion={props.handleItemDeletion}
                handleClaimDeletion={props.handleClaimDeletion}
                // onChange={props.onAddNewCodeInputChange}
                ActivityNow={props.ActivityNow}
                ActivitySuggestions={props.allActivity}
                //
                section={props.section}
                BtnActive={props.BtnActive}
                isPrincipalChecked={props.isPrincipalChecked}
                onSelect={props.onSelectOption}
                attributes={props.attributes}
                onChange={props.onChange}
                onClick={props.onClick}
                suggestions={props.allDiagnosis}
                userInputDiagnosis={props.userInputDiagnosis}
                userInputActivity={props.userInputActivity}
                activeSuggestion={props.activeSuggestion}
                filteredSuggestionsActivity={props.filteredSuggestionsActivity}
                filteredSuggestionsDiagnosis={
                  props.filteredSuggestionsDiagnosis
                }
                showActivitySuggestions={props.showActivitySuggestions}
                showDiagnosisSuggestions={props.showDiagnosisSuggestions}
              />
            );

          case "SamaActivityList":
            return (
              <SamaActivityList
                key={index}
                Items={props.elementsValues[index]}
                addNewCode={props.addNewCode}
                listName={props.elementsNames[index]}
                inputStyle={props.inputStyle}
                submitStyle={props.submitStyle}
                onInputTextChange={props.onInputTextChange}
                handleItemDeletion={props.handleItemDeletion}
                handleClaimDeletion={props.handleClaimDeletion}
                // onChange={props.onAddNewCodeInputChange}
                ActivityNow={props.ActivityNow}
                ActivitySuggestions={props.allActivity}
                //
                section={props.section}
                BtnActive={props.BtnActive}
                isPrincipalChecked={props.isPrincipalChecked}
                onSelect={props.onSelectOption}
                attributes={props.attributes}
                onChange={props.onChange}
                onClick={props.onClick}
                suggestions={props.allDiagnosis}
                userInputDiagnosis={props.userInputDiagnosis}
                userInputActivity={props.userInputActivity}
                activeSuggestion={props.activeSuggestion}
                filteredSuggestionsActivity={props.filteredSuggestionsActivity}
                filteredSuggestionsDiagnosis={
                  props.filteredSuggestionsDiagnosis
                }
                showActivitySuggestions={props.showActivitySuggestions}
                showDiagnosisSuggestions={props.showDiagnosisSuggestions}
              />
            );

          case "checkbox":
            return (
              <CheckBox
                key={index}
                id={props.elementsNames[index]}
                Style={props.inputStyle}
                value={props.elementsValues[index]}
                onChange={props.onInputTextChange}
                name={props.elementsNames[index]}
              />
            );

          case "link":
            return (
              <Link
                key={index}
                Style={props.dangerStyle}
                Text={props.elementsNames[index]}
                onAction={props.handleClear}
              />
            );

          /* case 'textarea':
                             return (<TextArea key={index}
                                 id={props.elementsNames[index]}
                                 name={props.elementsNames[index]}
                                 hint={`Copy and paste your ${props.elementsValues[index]}`}
                                 onChange={props.onInputTextChange}
                             />)*/

          default:
            return (
              <Input
                key={index}
                id={props.elementsNames[index]}
                Style={props.inputStyle}
                type={item}
                hint={props.elementsValues[index]}
                onChange={props.onInputTextChange}
                name={props.elementsNames[index]}
              />
            );
        }
      })}
      {props.SubmitButtonComponent ? (
        props.SubmitButtonComponent
      ) : (
        <Button
          Style={props.submitStyle}
          Value={props.elementsValues[props.elementsValues.length - 1]}
          /* onClick={props.onSubmit} */
        />
      )}
    </form>
  );
}
export default Form;
