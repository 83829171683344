import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './Styling/services.module.css'
const Services = () => {
    return (
        <div id="services" className={styles.cont} >
            <h1 className={styles.header}>SERVICES</h1> 
            <div className={styles.borderBottom}></div>
            <div className="container-fluid d-flex justify-content-center">
            <div className="row all " style={{justifyContent:"space-evenly"}}>
                    <div className="col-md4 service-home">
                        <div className="card text-left" style={{width:"20rem",minHeight:"29rem",marginBottom:"3em",backgroundColor:"#f7f7f7"}}>
                            <div className="overflow">
                                <img className="card-img-top imgService" alt="service1" src={require("./../imgs/service1.webp")}/>
                            </div>
                            <div className="card-body">
                                <h4 className="card-title cardHeader" style={{fontSize:18+"px",color:"#509c35"}}>Payer Solutions</h4>
                                <p className="card-text">Our solutions help payers in quickly identifying errors in claims and settling them.</p>
                            </div>
                        </div>

                    </div>
                    <div className="col-md4 service-home">
                        <div className="card text-left" style={{width:"20rem",minHeight:"29rem",marginBottom:"3em",backgroundColor:"#f7f7f7"}}>
                            <div className="overflow">
                                <img className="card-img-top imgService" alt="service1" src={require("./../imgs/service2.webp")}/>
                            </div>
                            <div className="card-body">
                                <h4 className="card-title cardHeader" style={{fontSize:18+"px",color:"#509c35"}}>Provider Solutions</h4>
                                <p className="card-text">Our software provide top of the line improved clinical edit checks, removes vague codes and comes with claim management features.</p>
                            </div>
                        </div>

                    </div>
                    <div className="col-md4 service-home">
                        <div className="card text-left" style={{width:"20rem",minHeight:"29rem",marginBottom:"3em",backgroundColor:"#f7f7f7"}}>
                            <div className="overflow">
                                <img className="card-img-top imgService" alt="service1" src={require("./../imgs/service3.webp")}/>
                            </div>
                            <div className="card-body">
                                <h4 className="card-title cardHeader" style={{fontSize:18+"px",color:"#509c35"}}>Machine Learning solutions</h4>
                                <p className="card-text">our software uses the technique of machine learning to show  predictions and statistics to doctors, vendors and even payers to reach the optimal decision.</p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Services
