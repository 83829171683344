import Cookies from "js-cookie";

// const KayanURL = '/';
const KayanURL = "https://www.kayan-healthcare.com/";
//const KayanURL = "http://localhost:2000/";
const apiToken = Cookies.get("SanadId");
const user = Cookies.get("user");

const samaApiToken = Cookies.get("SamaId");
const samaUser = Cookies.get("SamaUser");

let settings = {
  KayanURL,
  apiToken,
  user,
  samaApiToken,
  samaUser,
};

export default settings;
