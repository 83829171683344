import React, { useState } from "react";
// import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
// import styles from './Styling/navbar.module.css'
import "./Styling/navbar.css";
const NavBar = () => {
  const [showAboutMenu, setShowAboutMenu] = useState(false);
  const handleHover = () => {
    setShowAboutMenu(true);
  };

  const handleLeave = () => {
    setShowAboutMenu(false);
  };
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     name: null,
  //   };
  // }

  // let userType = localStorage.getItem("type");
  // let id = Cookies.get("id");
  const Submenu = () => {
    return (
      <div className="hoverMenue-nav">
        <div className="dropDownItem-nav">
          <Link className="linkDropDown-nav" to="/providerSolutions">
            Provider Solutions
          </Link>
        </div>

        <hr className="line-nav" />
        <div className="dropDownItem-nav">
          <Link className="linkDropDown-nav" to="/payerSolutions">
            Payer Solutions
          </Link>
        </div>
      </div>
    );
  };
  return (
    <>
      <Navbar
        bg="dark"
        variant="dark"
        sticky="top"
        expand="md"
        collapseOnSelect
      >
        <Navbar.Brand href="/">
          {/*   <img
            alt="Kayan Logo"
            src={require("./../imgs/Kayan-Healthcare-Logo.svg")}
            width="140vh"
            height="70vh"
            className="d-inline-block align-top"
          /> */}
        </Navbar.Brand>
        <Navbar.Toggle className="coloring" />
        <Navbar.Collapse>
          <Nav className="justify-content-end" style={{ width: "100%" }}>
            <Nav.Item>
              <Nav.Link
                className="link-nav"
                href="/"
                style={{
                  color: "white",
                  marginLeft: "13px",
                  marginTop: "5px",
                  fontSize: "1.13em",
                }}
              >
                Home
              </Nav.Link>
            </Nav.Item>

            <Nav.Item onMouseLeave={handleLeave}>
              <Nav.Item
                className="link-nav"
                style={{
                  color: "white",
                  marginLeft: "13px",
                  marginTop: "13.5px",
                  fontSize: "1.13em",
                }}
                onMouseEnter={handleHover}
              >
                Solutions <i className="fas fa-caret-down" />
                {showAboutMenu && <Submenu />}
              </Nav.Item>
            </Nav.Item>
            {/* <NavDropdown style={{
                            color: "#1e2d3b",
                            marginLeft:"13px",
                            marginTop: "5px",
                            fontSize: "1.13em",
                            textAlign:"center",
                          }}
                      id="nav-dropdown-dark-example"
                      title="Solutions"
                      // menuVariant="light"
                      
                    >
                        <Nav.Item>
                        <Nav.Link className="link" href="/providerSolutions" style={{
                            color: "#1e2d3b",
                            fontSize: "1em",
                            textAlign:"center",
                            fontWeight:"600"
                            }}>Provider Solutions
                        </Nav.Link>
                      </Nav.Item>
                      <hr/>
                      <Nav.Item>
                        <Nav.Link className="link" href="/payerSolutions" style={{
                            color: "#1e2d3b",
                            fontSize: "1em",
                            textAlign:"center",
                            fontWeight:"600"
                            }}>Payer Solutions
                        </Nav.Link>
                     </Nav.Item>
                     <hr/>
                     <Nav.Item>
                        <Nav.Link className="link" href="/tools" style={{
                            color: "#1e2d3b",
                            fontSize: "1em",
                            textAlign:"center",
                            fontWeight:"600"
                            }}>Tools
                        </Nav.Link>
                     </Nav.Item>
                    </NavDropdown> */}

            <Nav.Item>
              <Nav.Link
                className="link-nav"
                href="/research"
                style={{
                  color: "white",
                  marginLeft: "13px",
                  marginTop: "5px",
                  fontSize: "1.13em",
                }}
              >
                Market Research
              </Nav.Link>
            </Nav.Item>
            <Nav.Item></Nav.Item>

            <Nav.Item>
              <Nav.Link
                className="link-nav"
                href="/#about"
                style={{
                  color: "white",
                  marginLeft: "13px",
                  marginTop: "5px",
                  fontSize: "1.13em",
                }}
              >
                About
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                className="link-nav"
                href="/info/#contact"
                style={{
                  color: "white",
                  marginLeft: "13px",
                  marginTop: "5px",
                  fontSize: "1.13em",
                }}
              >
                Contact Us
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default NavBar;
