import React, { Component, Fragment } from "react";
import Header from "../Components/Header";
import NavBar from "../Components/navbar";
import SanadNavBar from "../Components/sanadnavbar";
import ProviderFactorManagement from "./ProviderFactorManagement";
import PayerFactorManagement from "./PayerFactorManagement";

class FactorManagement extends Component {
  render() {
    let CompanyType = localStorage.getItem("SanadCompanyType");

    return (
      <Fragment>
        <NavBar />
        <Header
          name={localStorage.getItem("SanadName")}
          usertype="employee"
          history={this.props.history}
        />
        <SanadNavBar selected="3" />
        <div className=" container">
          {CompanyType === "Provider" ? (
            <ProviderFactorManagement />
          ) : (
            <PayerFactorManagement />
          )}
        </div>
      </Fragment>
    );
  }
}
export default FactorManagement;
