import React from "react";
import "./ListView.css";
import Table from "react-bootstrap/Table";
//const randomstring = require('randomstring')

function ListView(props) {
  let Items = [],
    text = "";

  let types = {
    1: "No Bed + No emergency room(outpatient)",
    2: "No Bed + Emergency room(outpatient)",
    3: "Inpatient Bed + No emergency room",
    4: "Inpatient Bed + Emergency room",
    5: "Daycase Bed + No emergency room",
    6: "Daycase Bed + Emergency room",
    7: "Nationals Screening",
    8: "New Visa Screening",
    9: "Renewal Visa Screening",
    12: "Home",
    13: "Assisted Living Facility",
    15: "Mobile Unit",
    41: "Ambulance  Land",
    42: "Ambulance  Air or Water",
  };

  switch (props.name) {
    case "codes":
      props.Items.forEach((item, index) => {
        Items.push(item);
      });
      break;

    case "encounters":
      props.Items.forEach((item, index) => {
        Items.push(item.Claim);
      });
      break;
    default:
      Items = props.Items;
      break;
  }

  return (
    <div style={{marginLeft:"30px"}}>
      {props.name === "encounters" ? (
        <Table responsive striped bordered hover size="sm" id="scrollTable">
          <thead>
            <tr>
              <th>#</th>
              <th>Patient</th>
              <th>Diagnosis</th>
              <th>Activity</th>
              <th></th>
            </tr>
          </thead>
          {Items && Items.length > 0
            ? Items.map((item, index) => (
                <tbody key={index}>
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {item.Patient && item.Patient.length > 0
                        ? item.Patient.map((item, index) => (
                            <ul key={index}>
                              <li>
                                {item.PatientAge !== undefined
                                  ? "Age: " + item.PatientAge
                                  : ""}
                              </li>
                              <li>
                                {item.PatientGender !== undefined
                                  ? "Gender: " + item.PatientGender
                                  : ""}
                              </li>
                              <li>
                                {item.Type !== undefined
                                  ? "Type: " + types[item.Type]
                                  : ""}
                              </li>
                            </ul>
                          ))
                        : null}
                    </td>
                    <td>
                      {item.Diagnosis && item.Diagnosis.length > 0
                        ? item.Diagnosis.map((dia, ind) => (
                            <ul key={ind}>
                              <li>{"Code: " + dia.Code}</li>
                              <li>{"Type: " + dia.Type}</li>
                            </ul>
                          ))
                        : null}
                    </td>
                    <td>
                      {item.Activity && item.Activity.length > 0
                        ? item.Activity.map((dia, ind) => (
                            <ul key={ind}>
                              <li>{"Code :" + dia.Code}</li>
                              <li>
                                {dia.Type === "3"
                                  ? "Type: CPT"
                                  : dia.Type === "6"
                                  ? "Type: Dental"
                                  : dia.Type === "4"
                                  ? "Type: HCPCS"
                                  : dia.Type === "9"
                                  ? "Type: DRG"
                                  : "Type: SERVICE"}
                              </li>
                              <li>{"Start :" + dia.Start}</li>
                              <li>{"Quantity :" + dia.Quantity}</li>
                              {dia.Source === "6" ? (
                                <li>{"ToothNumber :" + dia.ToothNumber}</li>
                              ) : null}
                              <li>{"Net :" + dia.NET}</li>
                            </ul>
                          ))
                        : null}
                    </td>
                    <td
                      className="del"
                      onClick={() => props.handleClaimDeletion(item, index)}
                    >
                      delete
                    </td>
                  </tr>
                </tbody>
              ))
            : null}
        </Table>
      ) : (
        <Table
          style={{ width: "70%", margin: "10px auto" }}
          responsive
          striped
          bordered
          hover
          size="sm"
        >
          <thead style={{ background: "#3ab449", color: "white" }}>
            <tr>
              <th>
                {props.listName === "DiagnosisCodes"
                  ? "Diagnosis Code"
                  : "Activity Code"}
              </th>
              {!props.codesMgt ? <th>Type</th> : null}
              <th></th>
            </tr>
          </thead>

          {Items && Items.length > 0
            ? Items.map((item, index) => (
                <tbody key={index}>
                  <tr key={index}>
                    <td>{item.Text}</td>

                    {!props.codesMgt ? (
                      <td>
                        {props.listName === "DiagnosisCodes"
                          ? item.DiagnosisType
                          : item.Source === "3"
                          ? "CPT"
                          : item.Source === "6"
                          ? "Dental"
                          : item.Source === "4"
                          ? "HCPCS"
                          : item.Source === "9"
                          ? "DRG"
                          : item.Source === "5"
                          ? "Drug"
                          : "SERVICE"}
                      </td>
                    ) : null}
                    <td
                      onClick={() =>
                        props.handleItemDeletion(item, props.listName, index)
                      }
                      style={{
                        color: "red",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      delete
                    </td>
                  </tr>
                </tbody>
              ))
            : null}
        </Table>
      )}
    </div>
  );
}
export default ListView;
