import React from "react";
import "./Radio.css";

function Radio(props) {
  return (
    <div
      className="Radio"
      onChange={props.onChange}
      style={{ width: "50%", margin: "auto", padding: "20px", border: "0" }}
    >
      <div>
        <input
          className="radioInput"
          type="radio"
          value="1"
          name="type"
          defaultChecked
        />
        <span style={{ color: "rgb(58, 180, 73)" }}>Bayan Request</span>
      </div>

      <div>
        <input type="radio" className="radioInput" value="2" name="type" />
        <span style={{ color: "rgb(58, 180, 73)" }}>Claim Request</span>
      </div>
    </div>
  );
}
export default Radio;
