import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import LogoutIcon from "@mui/icons-material/Logout";
//import FileUploadIcon from "@mui/icons-material/FileUpload";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DescriptionIcon from "@mui/icons-material/Description";
import CircularProgress from "@mui/material/CircularProgress";
import EditsTable from "./EditsTable";
import Link from "@mui/material/Link";
import * as XLSX from "xlsx";
import axios from "axios";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import FileUpload from "./fileUpload";
import FormUpload from "./formUpload";
import settings from "../helpers/Settings";
import "./BayanKSA.css";

let { KayanURL } = settings;
//let dataObj = {};
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [screenName, setScreenName] = React.useState("fileUpload");
  const [excelRows1, setExcelRows] = React.useState("");
  const [requestSent, setRequestSent] = React.useState(false);
  const [responseReady, setResponseReady] = React.useState(false);
  const [freeOfEdits, setFreeOfEdits] = React.useState(false);
  const [validationEdits, setValidationEdits] = React.useState([]);
  const [age, setAge] = React.useState(0);
  const [ageUnit, setAgeUnit] = React.useState("");

  const [gender, setGender] = React.useState("");
  const [dataObj, setDataObj] = React.useState({});

  const [diagnosisObj, setDiagnosisObj] = React.useState({});
  let [AllDiagnosis, setAllDiagnosis] = React.useState([]);

  const [activityObj, setActivityObj] = React.useState({});
  const [AllActivity, setAllActivity] = React.useState([]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  ///////////////////////
  function switchPage(selectName) {
    if (selectName && selectName == "Bulk Validation") {
      setScreenName("fileUpload");
    } else if (selectName && selectName == "Claim Validation") {
      setScreenName("formUpload");
    }
    setResponseReady(false);
    setRequestSent(false);
    setDataObj({});
  }
  /////////////////////
  async function onUploadFile(event) {
    //console.log("on Upload File");
    event.preventDefault();
    setRequestSent(true);
    const result = await axios.post(KayanURL + "api/ksa/validation/", {
      data: excelRows1,
    }); //try

    if (result) {
      setRequestSent(false);
      setResponseReady(true);
      let { data } = result;
      if (data && data.success) {
        console.log("free of edits ");
        setFreeOfEdits(true);
      } else {
        let { validationErrors } = data;
        console.log("validationErrors", validationErrors);
        if (validationErrors && validationErrors.length > 0) {
          setValidationEdits(validationErrors);
        }
      }
    }
  }
  /////////////////////

  async function onValidateForm(event) {
    event.preventDefault();
    setRequestSent(true);

    /***{"data":[{"PROVCLAIMNO":"8387","MEMBERAGE":"20","GENDER":"M","UNITAGE":"Year","ICD":[{"ICD":"M98.0"}],"SERVICECODE":[{"SERVICECODE":"73050-35-2","QTY":"2","UNITSERVICEPRICE":"100","type":"SBS"}]}]} */
    let generatedClaimNo = Math.floor(1000 + Math.random() * 9000).toString();

    var row = {};

    row["PROVCLAIMNO"] = generatedClaimNo;
    row["MEMBERAGE"] = age;
    row["UNITAGE"] = ageUnit;
    row["GENDER"] = gender;

    let diagnosisArray = [];
    if (AllDiagnosis && AllDiagnosis.length > 0) {
      AllDiagnosis.forEach((element) => {
        diagnosisArray.push({
          ICD: element["diagnosisCode"],
          /* Type: element["diagnosisType"], */
        });
      });
    }

    row["ICD"] = diagnosisArray;

    let activityArray = [];
    if (AllActivity && AllActivity.length > 0) {
      AllActivity.forEach((element) => {
        activityArray.push({
          SERVICECODE: element.activityCode,
          type: element["activityType"],
          QTY: element["activityQuantity"],
          UNITSERVICEPRICE: element["activityPrice"],
        });
      });
    }

    row["SERVICECODE"] = activityArray;

    const result = await axios.post(KayanURL + "api/ksa/validation/", {
      data: [row],
    }); //try

    console.log("result", result);
    displayResponse(result);
  }

  function displayResponse(result) {
    if (result) {
      setRequestSent(false);
      setResponseReady(true);
      let { data } = result;
      if (data && data.success) {
        console.log("free of edits ");
        setFreeOfEdits(true);
      } else {
        let { validationErrors } = data;
        console.log("validationErrors", validationErrors);
        if (validationErrors && validationErrors.length > 0) {
          setValidationEdits(validationErrors);
        }
      }
    }
  }

  //////////////////

  function processExcel(data) {
    const workbook = XLSX.read(data, { type: "binary" });
    const firstSheet = workbook.SheetNames[0];
    const excelRows1 = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[firstSheet]
    );
    let excelRows = JSON.stringify(excelRows1);
    console.log("excelRows");
    console.log(excelRows);

    setExcelRows(excelRows);
  }

  /////////////////////////
  const handleInputTextChange = ({ target: { name, value, files } }) => {
    console.log("handle input text change");
    // this.setState({ responseReady: false });
    // this.setState({ fileName: files[0].name });
    switch (name) {
      case "file":
        const fileUpload = document.getElementById("file");
        const regex = /^.*\.(xls|xlsx|csv)$/;
        if (regex.test(fileUpload.value.toLowerCase())) {
          let fileName = fileUpload.files[0].name;
          if (typeof FileReader !== "undefined") {
            const reader = new FileReader();
            if (reader.readAsBinaryString) {
              reader.onload = (e) => {
                processExcel(reader.result);
              };
              reader.readAsBinaryString(fileUpload.files[0]);
            }
          }
        } else {
          console.log("Please upload a valid Excel file.");
          alert("Please upload a valid Excel file.");
        }
        break;
      default:
        break;
    }
  };
  /////////////////////

  const handleInputChangeFormScreen = ({ target: { name, value, files } }) => {
    console.log(name);
    console.log(value);
    switch (name) {
      case "age":
        setAge(value);
        dataObj["age"] = value;

        setDataObj(dataObj);
        break;

      case "ageUnit":
        setAgeUnit(value);
        dataObj["ageUnit"] = value;
        setDataObj(dataObj);
        break;

      case "gender":
        dataObj["gender"] = value;
        setGender(value);
        setDataObj(dataObj);
        break;

      case "diagnosisCode":
        diagnosisObj["diagnosisCode"] = value;
        setDiagnosisObj(diagnosisObj);
        break;

      case "diagnosisType":
        diagnosisObj["diagnosisType"] = value;
        setDiagnosisObj(diagnosisObj);

        break;

      case "activityType":
        activityObj["activityType"] = value;
        setActivityObj(activityObj);
        break;

      case "activityCode":
        activityObj["activityCode"] = value;
        setActivityObj(activityObj);
        break;

      case "activityPrice":
        activityObj["activityPrice"] = value;
        setActivityObj(activityObj);
        break;

      case "activityQuantity":
        activityObj["activityQuantity"] = value;
        setActivityObj(activityObj);
        break;

      default:
        break;
    }
  };

  const onClickAddDiagnosis = (e) => {
    console.log("add new diagnosis obj");

    //console.log(diagnosisObj);

    AllDiagnosis.push(diagnosisObj);
    setAllDiagnosis(AllDiagnosis);
    dataObj["AllDiagnosis"] = AllDiagnosis;
    setDataObj(dataObj);
    setDiagnosisObj({});
  };

  const onClickAddActivity = (e) => {
    console.log("add new activity obj");

    AllActivity.push(activityObj);
    setAllActivity(AllActivity);
    dataObj["AllActivity"] = AllActivity;
    setDataObj(dataObj);
    setActivityObj({});
  };

  const clearResponse = (e) => {
    //console.log("clear response");
    setRequestSent(false);
    setResponseReady(false);
    setDataObj({});
  };

  const deleteDiagnosisElement = (index) => {
    let newArray = [];
    if (AllDiagnosis && AllDiagnosis.length > 0) {
      AllDiagnosis.forEach((element, i) => {
        if (i != index) {
          newArray.push(element);
        }
      });

      setAllDiagnosis(newArray);
      dataObj["AllDiagnosis"] = newArray;
      setDataObj(dataObj);
    }
  };

  const deleteActivityElement = (index) => {
    let newArray = [];
    if (AllActivity && AllActivity.length > 0) {
      AllActivity.forEach((element, i) => {
        if (i != index) {
          newArray.push(element);
        }
      });

      setAllActivity(newArray);
      dataObj["AllActivity"] = newArray;
      setDataObj(dataObj);
    }
  };

  const logout = (e) => {
    console.log("logouuuuuuut");
    window.location.href = "/";
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant="h6" noWrap component="div">
            Mini variant drawer
          </Typography> */}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} style={{ color: "white" }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem key="Welcome" disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Welcome User" />
            </ListItemButton>
          </ListItem>
          {["Bulk Validation", "Claim Validation"].map((text, index) => (
            <ListItem key={text} disablePadding>
              <Link
                style={{ color: "white", textDecoration: "none" }}
                component="button"
                onClick={() => switchPage(text)}
              >
                <ListItemButton>
                  <ListItemIcon>
                    {index == 0 ? <UploadFileIcon /> : <DescriptionIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
          <Divider />

          <ListItem
            key="sign out"
            disablePadding
            sx={{ position: "fixed", bottom: 0 }}
          >
            <ListItemButton
              onClick={(e) => {
                logout(e);
              }}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Sign Out" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {screenName == "fileUpload" ? (
          <FileUpload
            onUploadFile={onUploadFile}
            handleInputTextChange={handleInputTextChange}
          />
        ) : (
          <FormUpload
            clearResponse={clearResponse}
            onValidate={onValidateForm}
            onChange={handleInputChangeFormScreen}
            claimData={dataObj}
            onClickAddDiagnosis={onClickAddDiagnosis}
            onClickAddActivity={onClickAddActivity}
            step={0}
            deleteDiagnosisElement={deleteDiagnosisElement}
            deleteActivityElement={deleteActivityElement}
            diagnosisObj={diagnosisObj}
            activityObj={activityObj}
          />
        )}

        {requestSent && !responseReady ? (
          <CircularProgress />
        ) : responseReady && !requestSent && freeOfEdits ? (
          <Typography variant="h5" sx={{ color: "green" }}>
            Free of Edits.
          </Typography>
        ) : responseReady && !requestSent && !freeOfEdits ? (
          <EditsTable rows={validationEdits} />
        ) : null}
      </Box>
    </Box>
  );
}
