import React, { Component } from "react";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import Cookies from "js-cookie";
import settings from "../helpers/Settings";
import Form from "../Components/Form/index";

//ayman commit
var XLSX = require("xlsx");

let { KayanURL } = settings;
class KareemRules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: NaN,
    };
  }
  readXlsxFile = (reader) => {
    // To Do: 1- Handling error (reader.onerror); 2- clear Input file for each change on input file.
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ requestXlsx: reader.result });
      }
    };
  };

  handleInputTextChange = ({ target: { name, value, files } }) => {
    this.setState({ responseReady: false });
    this.setState({ fileName: files[0].name });

    switch (name) {
      case "requestFile":
        const fileUpload = document.getElementById("requestFile");
        const regex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
        if (regex.test(fileUpload.value.toLowerCase())) {
          let fileName = fileUpload.files[0].name;
          if (typeof FileReader !== "undefined") {
            const reader = new FileReader();
            if (reader.readAsBinaryString) {
              reader.onload = (e) => {
                this.processExcel(reader.result);
              };
              reader.readAsBinaryString(fileUpload.files[0]);
            }
          } else {
          }
        } else {
          alert("Please upload a valid Excel file.");
        }

        break;

      default:
        break;
    }
  };

  processExcel(data) {
    const workbook = XLSX.read(data, { type: "binary" });

    const firstSheet = workbook.SheetNames[0];
    const excelRows1 = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[firstSheet]
    );
    let excelRows = JSON.stringify(excelRows1);
    console.log(excelRows)
    this.setState({ excelRows });
  }

  doRequest = async (event) => {
    event.preventDefault();
    this.setState({ responseReady: false, sent: true });

    const data = await axios.post(KayanURL + "api/KareemBenefits/uploadRules", {
      data: this.state.excelRows,
      apiToken: Cookies.get("KareemId"),
    });

    if (data) {
      let res = data.data;

      let result = {};

      if (res.success === true) {
        result = {
          message: "Custom Rules were uploaded successfully",
          res: true,
        };
      } else {
        result = { message: res.error, res: false };
      }

      this.setState({
        status: result,
        sent: false,
        responseReady: true,
      });
      document.getElementById("requestFile").value = null;
    }
  };
  render() {
    let { responseReady, sent, status } = this.state;

    return (
      <>
        <div className="container">
          <Form
            submitStyle="BS10"
            labelStyle="BayanLabelStyle"
            onSubmit={this.doRequest}
            linkStyle="Link2"
            dangerStyle="BS1"
            onInputTextChange={this.handleInputTextChange}
            inputsTypes={["file"]}
            elementsValues={["file", "Upload Rules"]}
            elementsNames={["requestFile"]}
          />

          {sent && !responseReady ? (
            <Spinner animation="border" variant="success" />
          ) : null}

          {responseReady && status.res ? (
            <h5 style={{ color: "green" }}>{status.message}</h5>
          ) : responseReady ? (
            Array.isArray(status.message) ? (
              status.message.map((err, idx) => {
                return (
                  <h5 style={{ color: "red" }} key={idx}>
                    {err}
                  </h5>
                );
              })
            ) : (
              <h5 style={{ color: "red" }}>{status.message}</h5>
            )
          ) : null}
        </div>
      </>
    );
  }
}
export default KareemRules;
