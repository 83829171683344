import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import { Icon } from "@iconify/react";

const useStyles = makeStyles((theme) => ({
  RootStyle: {
    height: "4.5rem",
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
    padding: theme.spacing(0, 1, 0, 3),
    color: "primary.main",
    backgroundColor: "#dee2e6",
    borderTopLeftRadius: "0.4rem",
    borderTopRightRadius: "0.4rem",
  },
}));

const ValidateClaimsTableToolbar = ({
  selectedClaims,
  handleValidateSelectedClaims,
}) => {
  const classes = useStyles();

  const handleValidation = () => {
    handleValidateSelectedClaims(selectedClaims);
  };

  return (
    <>
      {selectedClaims.length > 0 && (
        <>
          <div className={classes.RootStyle}>
            <Typography
              component="div"
              variant="h6"
              style={{ color: "#3f51b5", marginTop: "1.4rem" }}
            >
              {selectedClaims.length} Selected
            </Typography>
            <Tooltip title="Validate Selected Claims">
              <IconButton onClick={handleValidation}>
                <Box
                  component={Icon}
                  icon="bi:clipboard-check-fill"
                  color="#3f51b5"
                />
              </IconButton>
            </Tooltip>
          </div>
        </>
      )}
    </>
  );
};

export default ValidateClaimsTableToolbar;
