import React, { Component } from "react";
import NavBar from "./../Components/navbar";
import FileUpload from "./fileupload";
import MainScreen from "./MainScreen";
import SamaSubNavBar from "../Components/BayanSubNavBar/SamaSubNavBar";
import ValidateClaims from "./ValidateClaims";
import Submissions from "./Submission";
import ReSubmission from "./ReSubmission";
import RAscreen from "./RAscreen";
import FormUpload from "./formupload";
import axios from "axios";
import ProcessManagementScreen from "./ProcessManagementScreen";
import Alert from "react-bootstrap/Alert";
import ClaimUpload from "./claimupload";
import Cookies from "js-cookie";
import UploadCustomRoles from "./uploadcustomroles";
import ClaimUploadForm from "./claim";
import FileUploadSamaValidation from "./FileUploadSamaValidation";
import RuleScreen from "./RuleScreen";
import PriceListScreen from "./PriseListScreen";
import PriceFileUpload from "./priseListUpload";
import ContractManagement from "./ContractManagementChoice";
import UploadClaimsCSV from "./UploadClaimsCSV";
import settings from "../helpers/Settings";
// import Footer from "../Components/footer";
import NetworksModule from "../SamaViews/NetworkViews/NetworksModule";
import PriceListModule from "../SamaViews/NetworkViews/PriceListModule";
import Plan from "../KareemBenefits/plansTables";
import NotCoveredICD from "./NonCoveredIcd/nonCoveredIcd";
import NotAllowedPrincipalICD from "./NotAllowedPrincipalIcd/notAllowedPrincipalICD";
import PreAuthorizationCodes from "./PreAuthCodesICD/pre-authCodeScreen";
//import NotCoveredICD from "./NonCoveredIcd/nonCoveredIcd";
//import NotAllowedPrincipalICD from "./NotAllowedPrincipalIcd/notAllowedPrincipalICD";

let { KayanURL } = settings;
class Employee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //default interface
      toggleState: 1,
    };
  }

  componentDidMount = async () => {
    const { history } = this.props;
    if (!Cookies.get("SamaId")) {
      history.push(`/Sama/loginAsEmployee`, {
        errorMsg: "You need to sign in or sign up",
      });
    } else {
      //if logged in ,,
      let payers = localStorage.getItem("payers");
      if (payers) {
        this.setState({ payers: JSON.parse(payers) });
      } else {
        await this.getPayersList();
      }

      axios
        .put(KayanURL + "api/sama/getUserState", {
          apiToken: Cookies.get("SamaId"),
        })
        .then(({ data }) => {
          if (data.success) {
            this.setState({ activeProcess: data.activeProcess });
            localStorage.setItem("SamaCompanyType", data.type);
            //localStorage.setItem("privileges", data.privileges);
          }
        });
    }
  };
  toggleTab = (index) => {
    this.setState({ toggleState: index });
  };
  async getPayersList() {
    let response = await axios.put(KayanURL + "api/sama/getPayers", {
      apiToken: Cookies.get("SamaId"),
    });

    if (response) {
      let { data } = response;
      if (data && data.success) {
        let { payers } = data;

        localStorage.setItem("payers", JSON.stringify(payers));
      }
    }
  }

  onChangeRulesStatus = (e) => {
    //on click on each check box
    this.setState({ isUpdated: false });
    let checkBoxId = e.target.id;
    let activeProcess = this.state.activeProcess;
    activeProcess[checkBoxId] = !activeProcess[checkBoxId];
    this.setState({ activeProcess });
  };
  onUpdateRulesStatus = (e) => {
    //on click on update Button in Process Flow Management screen
    let activeProcess = this.state.activeProcess;
    axios
      .post(KayanURL + "api/sama/updateProcessState", {
        apiToken: Cookies.get("SamaId"),
        ProcessStatus: JSON.stringify(activeProcess),
      })
      .then(({ data }) => {
        let { success } = data;
        //if success veiw the Success Alert div
        if (success) {
          this.setState({ isUpdated: true });
          document.getElementById("Alert").scrollIntoView();
        }
      });
  };
  handlingLinkAction1 = () => {
    this.setState({ isChoiced: false, screenName: null });
  };
  handleSwitch = (screenName) => {
    if (this.state.reset && screenName == "Contract Management") {
      this.setState({ selectedAgain: true });
    }
    this.setState({ screenName });
  };

  render() {
    let { activeProcess, isUpdated } = this.state;

    return (
      <>
        <NavBar />
        <SamaSubNavBar
          history={this.props.history}
          projectType="Sama"
          toggleState={this.state.toggleState}
          usertype="employee"
          toggleTab={this.toggleTab}
          name={localStorage.getItem("SamaUser")}
        >
          {this.state.toggleState == 8 ? (
            <div>
              <div>
                {isUpdated ? (
                  <Alert id="Alert" variant="success">
                    Process flow status updated Successfully
                  </Alert>
                ) : null}
              </div>
              <ProcessManagementScreen
                activeProcess={activeProcess}
                onChange={this.onChangeRulesStatus}
                onClick={this.onUpdateRulesStatus}
              />
            </div>
          ) : this.state.toggleState == 1 ? (
            <MainScreen />
          ) : this.state.toggleState == 3 ? (
            <ValidateClaims />
          ) : this.state.toggleState == 4 ? (
            <Submissions />
          ) : this.state.toggleState == 5 ? (
            <ReSubmission />
          ) : this.state.toggleState == 6 ? (
            <RuleScreen />
          ) : this.state.toggleState == "File Upload" ? (
            <FileUpload />
          ) : this.state.toggleState == "Form Upload" ? (
            <FormUpload />
          ) : this.state.toggleState == "Remittance Advice" ? (
            <RAscreen />
          ) : this.state.toggleState == "Claims Upload" ? (
            <ClaimUpload />
          ) :this.state.toggleState == 10 ? (
            <ClaimUpload />
          ) : this.state.toggleState == 11 ? (
            <ClaimUploadForm />
          ) : this.state.toggleState == 12 ? (
            <UploadClaimsCSV />
          ) : this.state.toggleState == 9 ? (
            <FileUploadSamaValidation />
          ) : this.state.toggleState == "Upload Master Price List" ? (
            <PriceFileUpload />
          ) : this.state.toggleState == "Price List Update" ? (
            <PriceListScreen />
          ) : this.state.toggleState == 2 ? (
            <ContractManagement />
          ) : this.state.toggleState == 13 ? (
            <NetworksModule />
          ) : this.state.toggleState == 14 ? (
            <PriceListModule />
          ) : this.state.toggleState == 20 ? (
            <Plan />
          ) : this.state.toggleState == 21 ? (
            <NotCoveredICD />
          ) : this.state.toggleState == 22 ? (
            <NotAllowedPrincipalICD />
          ) : this.state.toggleState == 23 ? (
            <PreAuthorizationCodes />
          ) : null}
        </SamaSubNavBar>
      </>
    );
  }
}
export default Employee;
