import React, { Component, useEffect } from "react";
import "./Styling/TeamQuery.css";
import styles from "./Styling/team.module.css";
import AOS from "aos";
import "aos/dist/aos.css";
const Team = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div
        id="about"
        className={styles.container}
        style={{
          backgroundImage: "url(" + require("../imgs/teamCover.webp") + ")",
        }}
      >
        <div className="team-home">
          {/*  <h1 className={styles.header}>Kayan Healthcare</h1> */}
          <p>
            As technological advancements and mandates for the healthcare system
            start to involve,{/*  Kayan Healthcare technologies (KHCT) */} we
            started with an aspiration to implement new algorithms and
            technologies to be the most efficient healthcare service counseling
            service in the UAE following the Gulf Cooperation Council.{" "}
            {/* KHCT */}We started to develop dynamic solutions with modernized
            systems to meet various requirements in the healthcare market and to
            ensure the delivery of quality health services as well as to keep
            all the elements of the healthcare system including authorities,
            providers and payers fully engaged and connected. {/* KHCT */}We are
            looking to raise the benchmark for quality and efficiency in the
            healthcare industry by capitalizing on new tested algorithms to take
            Healthcare processes to the next level and to set an example on how
            healthcare services are to be managed with our new and improved
            systems.
          </p>
          <p>
            {/*  KHCT is */}we are offering services including first stage
            clinical edits, benefit edits and diagnosis-related group calculator
            (DRG) service. Moreover, in the second phase we are activating a
            specialized machine learning system to support the health sector in
            planning the future of long term and efficient health policies.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          backgroundColor: "#edcd1f",
          color: "#3d5975",
          padding: 35 + "px",
          textAlign: "center",
        }}
      >
        <div
          className="row"
          style={{ width: "78%", marginLeft: "10%" }}
          data-aos="fade-up"
          data-aos-anchor-placement="center-bottom"
        >
          <div className="col-sm detail-home">
            <h3 className={styles.companyHeader}>2019</h3>
            <span className={styles.companyText}>Year Established</span>
          </div>
          <div className="col-sm detail-home">
            <h3 className={styles.companyHeader}>6</h3>
            <span className={styles.companyText}>
              Software Solutions Provided
            </span>
          </div>
          <div className="col-sm detail-home">
            <h3 className={styles.companyHeader}>6</h3>
            <span className={styles.companyText}>Clients</span>
          </div>
          <div className="col-sm">
            <h3 className={styles.companyHeader}>6</h3>
            <span className={styles.companyText}>Partners </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
