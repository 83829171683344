import React from "react";
import "./DropList.css";

function DropList(props) {
  if (
    props.name.toLowerCase() === "encountertype" ||
    props.name.toLowerCase() === "type" ||
    props.name.toLowerCase() === "companyid"
  ) {
    return (
      <select
        required
        name={props.name}
        title={props.Title}
        id={props.id}
        className={props.Style}
        onChange={() => props.onSelect(props.id)}
      >
        <option className={props.optionStyle} value="">
          {" "}
          {props.Title[0].toUpperCase() + props.Title.slice(1)}{" "}
        </option>
        {props.Options.map((option, index) => (
          <option
            className={props.optionStyle}
            key={index}
            value={props.Values[index]}
          >
            {" "}
            {option}{" "}
          </option>
        ))}
      </select>
    );
  } else if (props.name.toLowerCase() === "diagnosistype") {
    return (
      <select
        required
        name={props.name}
        title={props.Title}
        value={props.defaultValue}
        id={props.id}
        className={props.Style}
        onChange={() => props.onSelect(props.id)}
      >
        {/* <option className={props.optionStyle} value="Principal">
          {" "}
          {props.Title}{" "}
        </option> */}
        {props.Options.map((option, index) => (
          <option
            value={option}
            className={props.optionStyle}
            key={index}
            value={props.Values[index]}
          >
            {" "}
            {option}{" "}
          </option>
        ))}
      </select>
    );
  } else {
    return (
      <select
        name={props.name}
        id={props.id}
        className={props.Style}
        onChange={() => props.onSelect(props.id)}
      >
        {props.name == "field" ? null : (
          <option className={props.optionStyle} value="">
            {" "}
            {props.Title}{" "}
          </option>
        )}
        {props.Options.map((option, index) => (
          <option
            className={props.optionStyle}
            key={index}
            value={props.Values[index]}
          >
            {" "}
            {option}{" "}
          </option>
        ))}
      </select>
    );
  }
}
export default DropList;
