import React, { Component } from "react";
import DropList from "../Components/DropList"
import CodeList from "../Components/CodesList"
import Button from "../Components/Button"
import Alert from "react-bootstrap/Alert";

class PreferedCodesScreen extends Component {
    render() {
        let { operation, codeType, status, existInDb } = this.props

        let suggestionsArray = operation == 'add' ?


            this.props.allDiagnosis

            :

            operation == 'delete' && codeType == 'icd' ?
                this.props.allPreferedDiagnosis :
                operation == 'delete' && codeType && codeType != 'icd' ?
                    this.props.allPreferedActivity
                    : null


        return (
            <>
                <div className="container">
                    <DropList
                        name="operationType"
                        Title="Select operation"
                        Options={['Add a preferred codes', 'Delete a preferred code']}
                        Values={['add', 'delete']}
                        optionStyle=""
                        id="operationType"
                        onSelect={this.props.onSelect}
                    />

                    <DropList
                        name="codeType"
                        Title="Code Type"
                        Options={['ICD', 'CPT', 'Dental', 'HCPCS']}
                        Values={['icd', 'cpt', 'dental', 'hcpcs']}
                        optionStyle=""
                        id="codeType"
                        onSelect={this.props.onSelect}
                    />

                    {
                        operation && codeType && suggestionsArray ?
                            <CodeList


                                suggestions={

                                    operation == 'add' ?


                                        this.props.allDiagnosis

                                        :

                                        operation == 'delete' && codeType == 'icd' ?
                                            this.props.allPreferedDiagnosis :
                                            operation == 'delete' && codeType && codeType != 'icd' ?
                                                this.props.allPreferedActivity
                                                : null




                                }
                                addNewCode={this.props.addNewCode}
                                onInputTextChange={this.props.onInputTextChange}
                                onChange={this.props.onChange}
                                userInputDiagnosis={this.props.userInputDiagnosis}
                                showDiagnosisSuggestions={this.props.showDiagnosisSuggestions}
                                filteredSuggestionsDiagnosis={
                                    this.props.filteredSuggestionsDiagnosis
                                }
                                activeSuggestion={this.props.activeSuggestion}
                                diagnosisNow={this.props.diagnosisNow}
                                listName={this.props.listName}
                                onClick={this.props.onClick}
                                Items={this.props.Items}
                                BtnActive={this.props.BtnActive}
                                handleItemDeletion={this.props.handleItemDeletion}

                                Style="Search"
                                inputStyle="IT3"
                                submitStyle="BS1"
                                section="Diagnosis"
                                codesMgt={true}
                                codeType={codeType}
                            />
                            : operation == 'delete' && codeType && !suggestionsArray ?
                                <h6>You don't have preferred codes to delete from</h6>
                                : null





                    }
                    {
                        operation != undefined && codeType != undefined && suggestionsArray != undefined ?
                            <Button
                                Value={operation == 'add' ? "Add Codes" : operation == 'delete' ? "Delete Codes" : ""}
                                Style={this.props.submitStyle}
                                onClick={this.props.addPreferedCode}

                            />
                            : null


                    }


                    {status && operation == 'add' && !existInDb
                        ?
                        <Alert variant="success" style={{
                            width: "50%",
                            margin: "15px auto"
                        }}>
                            Codes added successfully to your preferred codes
                  </Alert>

                        : status && operation == 'add' && existInDb ? <Alert variant="danger" style={{
                            width: "50%",
                            margin: "15px auto"
                        }}>
                            Codes already added
              </Alert> : status && operation == 'delete' ?
                                <Alert variant="success" style={{
                                    width: "50%",
                                    margin: "15px auto"
                                }}>
                                    Preferred Codes updated successfully
      </Alert>
                                : null}






                </div>
            </>
        );
    }
}

export default PreferedCodesScreen;
