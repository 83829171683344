import React, { Component } from "react";
import axios from "axios";
// import Form from "./../Components/Form/index.js";
// import Spinner from "react-bootstrap/Spinner";
import Button from "../../Components/Button/index";
import Cookies from "js-cookie";
import settings from "../../helpers/Settings";
import UploadNonCoveredICD from "./uploadNonCoveredICD"
import AddNewCodeOfICD from "./addNewCode";
import Table from "react-bootstrap/Table";
let { KayanURL } = settings;
var XLSX = require("xlsx");
class NotCoveredICD extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onClickAddNewCode = this.onClickAddNewCode.bind(this);
    this.onClickUploadICD = this.onClickUploadICD.bind(this);
    this.getAllNotAllowedPrincipalICDList = this.getAllNotCoveredICDList.bind(this);
  }
  componentDidMount = async () => {
    this.getAllNotCoveredICDList();
  };
 
  async getAllNotCoveredICDList() {
    let response = await axios.put(
    KayanURL+"api/sama/getIcdExclusionList",
      {
        apiToken: Cookies.get("SamaId"),
      }
    );
    if (response) {
    let {data}=response
    //  console.log(data);
      if (data&&data.success) {
        let {result}=data
          this.setState({ AllNotCoveredICD:  result});
        
      }
      else if(data&&!data.success){
        let { error } = data;
        if (error) {
          if (Array.isArray(error) && error.length > 0) {
            this.setState({ error: error[0] });
          } else {
            this.setState({ error: error });
          }
        }

      }
    }
  }
  DeleteNotCoveredICDByID = async (e) => {
    e.preventDefault();
    let { NotCoveredICDid} = this.state;

    let response = await axios.put(
      KayanURL+"api/sama/deleteIcdExclusion",
      {
        apiToken: Cookies.get("SamaId"),
        data: NotCoveredICDid,
      }
    );

    if (response) {
      let { data } = response;
      if (data.success) {
        this.setState({
          success: true,
          DeleteNotCoveredICD: false,
        });
        this.getAllNotCoveredICDList();
      }else{
        let { error } = data;
        if (error) {
          if (Array.isArray(error) && error.length > 0) {
            this.setState({ error: error[0] });
          } else {
            this.setState({ error: error });
          }
        }
      }
    }
  };
  onClickAddNewCode() {
    this.setState({ AddNewCode: true});
  }
  onClickUploadICD() {
    this.setState({ UploadICD: true });
  }
  render() {
    let {AllNotCoveredICD,UploadICD,AddNewCode} = this.state;
    let AllNotCoveredICDTable;
    if (AllNotCoveredICD && AllNotCoveredICD.length > 0) {
        AllNotCoveredICDTable = AllNotCoveredICD.map((row, index) => {
        return (
          <tr key={index}>
            <td>{row.icd}</td>
            <td>
              <button
                style={{
                  color: "red",
                  border: "none",
                }}
                onClick={() =>
                  this.setState({
                    DeleteNotCoveredICD: true,
                    NotCoveredICDid: row.id,
                  })
                }
              >
                Delete
              </button>
            </td>
          </tr>
        );
      });
    }
    return (
      <>
      {this.state.DeleteNotCoveredICD ? (
          <div className="popup">
            <div
              className="form"
              style={{
                width: "50%",
                margin: "10px auto",
                marginLeft:"33%",
                backgroundColor: "white",
              }}
            >
              <form>
                <h4>Are you sure you want to delete this ICD?</h4>
                <Button
                  Style="BS12"
                  Value="Yes"
                  onClick={this.DeleteNotCoveredICDByID}
                />
                <Button
                  Style="BS12"
                  Value="No"
                  onClick={() =>
                    this.setState({
                      DeleteNotCoveredICD: false,
                    })
                  }
                />
              </form>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  textAlign: "right",
                  cursor: "pointer",
                  margin: "0 2%",
                }}
              ></p>
            </div>
          </div>
        ) : null}
        <div className="container">
        <div style={{ marginTop: "20px", textAlign: "center" }}>
            <h3 style={{ color: "green", margin: "1rem 0" }}>
              Not Covered ICD
            </h3>
            </div>
            <input
              type="button"
              className="BS1"
              value="Add New Code"
              onClick={this.onClickAddNewCode}
            />

            <input
              type="button"
              className="BS1"
              value="Upload File Of Non Covered ICD"
              onClick={this.onClickUploadICD}
            />
         {UploadICD ? (
            <div className="popup">
            <div
              className="form5"
              style={{
                width: "60%",
                marginLeft: "25%",
                backgroundColor: "white",
              }}
            >
              <UploadNonCoveredICD getAllNotAllowedPrincipalICDList={this.getAllNotAllowedPrincipalICDList}
              
              />
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  cursor: "pointer",
                  textAlign: "right",
                }}
                onClick={() => {
                  this.setState({
                    UploadICD: false,
                  });
                }}
              >
                {" "}
                Close{" "}
              </p>
            </div>
          </div>
          ) : null}
          {AddNewCode ? (
            <div className="popup">
              <div
                className="form5"
                style={{
                  width: "50%",
                  marginLeft: "33%",
                  backgroundColor: "white",
                }}
              >
                <AddNewCodeOfICD getAllNotAllowedPrincipalICDList={this.getAllNotAllowedPrincipalICDList}
               
                />
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    cursor: "pointer",
                    textAlign: "right",
                  }}
                  onClick={() => {
                    this.setState({
                      AddNewCode: false,
                    });
                  }}
                >
                  {" "}
                  Close{" "}
                </p>
              </div>
            </div>
          ) : null}
          <hr />
          <div style={{width: "50%", margin: "20px auto"}}>
          {AllNotCoveredICD && AllNotCoveredICD.length > 0 ? (
           <Table
              responsive
              striped
              bordered
              hover
              size="sm"
              style={{ marginTop: "20px" }}
            >
              <thead style={{ background: "#3ab449", color: " white" }}>
                <tr>
                  <th style={{ verticalAlign: "middle",}}>
                    ICD
                  </th>
                  <th style={{ verticalAlign: "middle"}}>
                    Action
                  </th>
                  </tr>
              </thead>
              <tbody>{AllNotCoveredICDTable}</tbody>
            </Table>
          ):<h5>there is no records in DB</h5>}
            </div>
        </div>
      </>
    );
  }
}

export default NotCoveredICD;
